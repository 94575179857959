<template>
  <div id="right">
    <projectTeamContractChart :district-level="districtLevel" :org-level="orgLevel" />
    <contractWarningBarChart :district-level="districtLevel" :org-level="orgLevel" />
    <hireStaffBarChart :district-level="districtLevel" :org-level="orgLevel" />
  </div>
</template>

<script>
  import projectTeamContractChart from "./projectTeamContractChart";
  import contractWarningBarChart from "./contractWarningBarChart";
  import hireStaffBarChart from "./hireStaffBarChart";

  export default {
    name: 'HrIndexRightBar',
    components: {
      projectTeamContractChart,
      contractWarningBarChart,
      hireStaffBarChart,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
  }
</script>

<style lang="less">
</style>
