const L = require('leaflet');
const sprites = require('./sprites.json');

let map = null
let jsonGroup = null
let mapLayer;
let beacon_marker_list = []
let beacons;
let createBeaconPopup;
let editBeaconPopup;
let ta = null

window.createBeacon = function(x, y) {
    if (createBeaconPopup) {
        createBeaconPopup.remove();
    }
    ta.addBeaconPopupClickHandler(x, y);
}

window.editBeacon = function(beacon_id) {
    if (editBeaconPopup) {
        editBeaconPopup.remove();
    }
    ta.editBeaconPopupClickHandler(beacons[beacon_id]);
}

/**
 * 初始化leaflet地图
 * @param {*} id
 * @param {*} t
 */
export function init(id, t) {
    ta = t;
    map = L.map(id, {
        crs: L.CRS.Simple,
        minZoom: -2,
        maxZoom: 1,
        zoomControl: false,
        attributionControl: false
    });

    jsonGroup = new L.FeatureGroup();
    map.addLayer(jsonGroup);
}

export function initOnClick(danger_name) {
    map.on('click', function (e) {
        if (e.latlng.lat >= 0 && e.latlng.lat <= 1575 && e.latlng.lng >= 0 && e.latlng.lng <= 2427) {
            newBeacon({
                x: e.latlng.lat,
                y: e.latlng.lng,
                icon: '默认-0',
            }, danger_name);
        }
    });
}

export function drawGroundMap(map_img) {
    if (mapLayer) {
        map.removeLayer(mapLayer);
    }
    for (let i = 0; i < beacon_marker_list.length; i++) {
        map.removeLayer(beacon_marker_list[i].m);
    }

    const bounds = [[0, 0], [1575, 2427]];
    mapLayer = L.imageOverlay(map_img, bounds).addTo(map);
    map.fitBounds(bounds);
    map.setView([788, 1214], -1);
}

export function drawFloorMap(floorData, saveParams) {
    if (mapLayer) {
        map.removeLayer(mapLayer);
    }
    for (let i = 0; i < beacon_marker_list.length; i++) {
        map.removeLayer(beacon_marker_list[i].m);
    }

    mapLayer = new L.FeatureGroup();
    map.addLayer(mapLayer);

    // 做坐标系转换 (服务器坐标系 => Leaflet)
    // 首先为该楼层图计算转换公式，然后将系数保存到Vue端
    const bounds = [[0, 0], [1575, 2427]];
    let minX = 1000000000000000, minY = 1000000000000000000, maxX = -100000000000000000, maxY = -100000000000000000000;
    const outline = floorData.outline.map(([x, y]) => [-y, -x]);
    const rooms = floorData.rooms.map(room => {
        return room.map(([x, y]) => [-y, -x]);
    });

    const setMinMax = ([x, y]) => {
        if (x < minX) minX = x;
        if (x > maxX) maxX = x;
        if (y < minY) minY = y;
        if (y > maxY) maxY = y;
    }
    outline.forEach(setMinMax);
    rooms.forEach(room => room.forEach(setMinMax));

    // 将图形转换到bounds范围内
    let ratioX = (bounds[1][0] - bounds[0][0]) / (maxX - minX);
    let ratioY = (bounds[1][1] - bounds[0][1]) / (maxY - minY);
    let ratio = Math.min(ratioX, ratioY);

    let offsetX = bounds[0][0] - minX * ratio + ((bounds[1][0] - bounds[0][0]) - (maxX - minX) * ratio) / 2;
    let offsetY = bounds[0][1] - minY * ratio + ((bounds[1][1] - bounds[0][1]) - (maxY - minY) * ratio) / 2;

    saveParams(ratio, offsetX, offsetY);

    L.polyline(outline.map(([x, y]) => [x * ratio + offsetX, y * ratio + offsetY]), {
        stroke: false,
        fill: true,
        fillColor: '#aaa',
        noClip: true,
    }).addTo(mapLayer);
    for (const room of rooms) {
        L.polyline(room.map(([x, y]) => [x * ratio + offsetX, y * ratio + offsetY]), {
            color: '#965a6e',
            weight: 2
        }).addTo(mapLayer);
    }

    map.fitBounds(bounds);
    map.setView([788, 1214], -1);
}

/**
 * 绘制地图上的beacons
 * @param {*} list
 */
export function drawBeacons(list, floorData, allowEdit=true) {
    // 首先清空已有的beacons
    for (let i = 0; i < beacon_marker_list.length; i++) {
        map.removeLayer(beacon_marker_list[i].m);
    }

    beacons = {};
    for (let i = 0; i < list.length; i++) {
        let v = list[i];
        beacons[v.innerid] = v;

        let x, y;
        if (floorData) {
            x = -v.y;
            y = -v.x;
            x = x * floorData.ratio + floorData.offsetX;
            y = y * floorData.ratio + floorData.offsetY;
        } else {
            x = v.x;
            y = v.y;
        }

        if (x && y) {
            const html = `<div style="text-align: center;"><a style='cursor:pointer' id='editBeacon' onclick="editBeacon('${v.innerid}')">编辑信标</a></div>`;

            const icon = L.divIcon({
                className: 'beacon-sprite-div',
                iconSize: [32, 32],
                bgPos: [sprites[`beacon-${v.icon}`].x, sprites[`beacon-${v.icon}`].y],
            });

            let marker_obj = L.marker([x, y], { icon: icon }).addTo(jsonGroup);
            if (allowEdit) {
                marker_obj.on('click', function () {
                    editBeaconPopup = L.popup({
                        offset: [0, -5],
                        closeOnClick: true,
                        autoClose:false,
                        closeOnEscapeKey:false
                    }).setLatLng({ lat: x, lng: y }).setContent(html).openOn(map)
                });
            }
            beacon_marker_list.push({ m: marker_obj })
        }
    }
}

/**
 * 绘制待新建的beacon
 * @param {*} list
 */
export function newBeacon(v, danger_name) {
    if (v.x && v.y) {
        let html;
        if (danger_name) {
            html = `<div style="text-align: center;"><a style='cursor:pointer' id='createBeacon' onclick="createBeacon(${v.x}, ${v.y})">放置${danger_name}</a></div>`;
        } else {
            html = `<div style="text-align: center;"><a style='cursor:pointer' id='createBeacon' onclick="createBeacon(${v.x}, ${v.y})">添加信标</a></div>`;
        }

        const icon = L.divIcon({
            className: 'beacon-sprite-div',
            iconSize: [32, 32],
            bgPos: [sprites[`beacon-${v.icon}`].x, sprites[`beacon-${v.icon}`].y],
        });

        let marker_obj = L.marker([v.x, v.y], { icon: icon }).addTo(jsonGroup).on('popupclose', function() {
            map.removeLayer(marker_obj);
        });
        createBeaconPopup = L.popup({
            offset: [0, -5],
            closeOnClick: true,
        }).setLatLng({ lat: v.x, lng: v.y }).setContent(html);

        marker_obj.bindPopup(createBeaconPopup).openPopup();
    }
}
