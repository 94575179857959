<template>
  <div>
    <div
      id="item"
      :class="{'blue':color==='blue','purple':color==='purple','green':color==='green','darkblue':color==='darkblue',}"
    >
      <div class="icon">
        <slot> </slot>
      </div>
      <div class="desc">
        <p class="number">{{number}}</p>
        <p class="text">{{text}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "infoItem",
  props: {
    color: {
      type: String,
      required: true,
    },
    number: {
      type: [String, Number],
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
#item {
  width: 13rem;
  height: 8rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.icon {
  flex: 1;
  text-align: center;
}
.desc {
  flex: 1.25;
  padding-bottom: 0.5rem;
}
.number {
  font-size: 2.5rem;
  color: aliceblue;
  font-family:Tahoma,Verdana,STHeiTi,simsun,sans-serif;
  font-weight: bold;
}
.text {
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: aliceblue;
}

.blue {
  background: url("../assets/bg_blue.png") no-repeat;
  background-size: 100% 100%;
}
.purple {
  background: url("../assets/bg_purple.png") no-repeat;
  background-size: 100% 100%;
}
.green {
  background: url("../assets/bg_green.png") no-repeat;
  background-size: 100% 100%;
}
.darkblue {
  background: url("../assets/bg_darkblue.png") no-repeat;
  background-size: 100% 100%;
}
</style>