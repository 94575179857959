<template>
  <div id="left">
    <CumulativeNetFlowChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <UsableFundBalanceChart v-if="!isHead" :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" :org-options="orgOptions" />
    <BranchYieldChart v-if="isHead" :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" :org-options="orgOptions" />
    <CurrentYearNetFlowCompletionChart v-if="!isHead" :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <CompanyCostCalendarChart v-if="isHead"  :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
  </div>
</template>

<script>
  import CumulativeNetFlowChart from "./cumulativeNetFlowChart";
  import UsableFundBalanceChart from "./usableFundBalanceChart";
  import CurrentYearNetFlowCompletionChart from "./currentYearNetFlowCompletionChart";
  import BranchYieldChart from "./branchYieldChart";
  import CompanyCostCalendarChart from "./companyCostCalendarChart";

  export default {
    name: 'QualityIndexLeftBar',
    components: {
      CumulativeNetFlowChart,
      UsableFundBalanceChart,
      CurrentYearNetFlowCompletionChart,
      BranchYieldChart,
      CompanyCostCalendarChart
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
    data(){
      return{
        isHead:true
      }
    },
    mounted(){
      if(window.$orgName == "中建一局集团第一建筑有限公司"){
          this.isHead= true;
        }else{
          this.isHead= false;
      }
    },
    watch:{
      orgLevel:function(){
        if(window.$orgName == "中建一局集团第一建筑有限公司"){
          this.isHead= true;
        }else{
          this.isHead= false;
        }
      }
    }
  }
</script>

<style lang="less">
</style>
