import ECharts from 'vue-echarts';

export const SimpleBarChartTemplate = {
  color: ['#1bd080', '#1bd080'],
  grid: {
    contentLabel: true,
    top: '5%',
    left: '12%',
    right: '6%',
    bottom: '18%'
  },
  legend: {
    data: [],
    textStyle:{
      fontSize: 11,//字体大小
      color: '#ffffff'//字体颜色
    },
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'none'
    },
    formatter: '{b}<br>{a0}: {c0}'
  },
  xAxis: [
    {
      axisLabel: {
        color: '#e6f2f3',
        fontWeight: 'bold',
      },
      show: true,
      axisLine: { show: true, lineStyle: { color: '#36445f' } },
      splitLine: { show: false },
      axisTick: { show: false },
      type: 'category',
      data: []
    }
  ],
  yAxis: [
    {
      nameTextStyle: {
        padding: [0, 40, 0, 0],
        color: '#e6f2f3',
        fontWeight: 'bold',
      },
      boundaryGap: [0, '20%'],
      axisLabel: {
        color: '#e6f2f3',
        fontWeight: 'bold',
      },
      axisLine: { show: true, lineStyle: { color: '#36445f' } },
      splitLine: { show: false },
      axisTick: { show: false },
      type: 'value',
    },
    {
      min: 0,
      max: 100,
      interval: 25,
      nameTextStyle: {
        color: '#e6f2f3',
        fontWeight: 'bold',
      },
      boundaryGap: [0, '20%'],
      axisLabel: {
        color: '#e6f2f3',
        fontWeight: 'bold',
        formatter: function(value) {
          return value ? value + '%' : value;
        },
      },
      axisLine: { show: true, lineStyle: { color: '#36445f' } },
      splitLine: { show: false },
      axisTick: { show: false },
      type: 'value',
    }
  ],
  series: [
    {
      name: '数量',
      type: 'bar',
      barWidth: 10,
      data: []
    },
    {
      name: '数量',
      type: 'line',
      itemStyle: {
        normal: {
          lineStyle: {
            width: 2
          }
        }
      },
      areaStyle: {
        color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
          {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
          {offset: 1, color: 'rgba(27, 208, 128, 0)'}
        ])
      },
      symbolSize: 0,
      yAxisIndex: 1,
      data: [],
    }
  ]
}

export const stackedBarChartTemplate = {
  color: ['#23cf9e', '#eab91e', '#20bbee'],
  grid: {
    left: '5%',
    right: '1%',
    top: '20%',
    bottom: '1%',
    containLabel: true
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'none'
    },
  },
  legend: {
    icon: 'rect',
    itemWidth: 12,
    itemHeight: 12,
    itemGap: 12,
    textStyle: {
      color: '#ccc',
      fontWeight: 'bold',
      fontSize: '11'
    },
    padding: [8, 0, 0, 0],
  },
  xAxis: {
    axisLabel: {
      color: '#e6f2f3',
      rotate: 40,
      fontWeight: 'bold',
    },
    show: true,
    axisLine: { show: true, lineStyle: { color: '#36445f' } },
    splitLine: {
      show: false,
      lineStyle: {
        color: "#36445f",
        width: 1,
        type: "solid",
      },
    },
    axisTick: { show: false },
    type: 'category',
    data: []
  },
  yAxis: [{
    type: 'value',
    show: true,
    name: '数量',
    nameTextStyle: {
      padding: [0, 40, 0, 0],
      color: '#e6f2f3',
      fontWeight: 'bold',
    },
    axisLine: {
      show: true,
      lineStyle: { color: '#36445f' }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: '#36445f',
        type: [1, 1],
        width: 1,
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: '#e6f2f3',
      fontWeight: 'bold',
      formatter: function(value) {
        return value ? value + '%' : value;
      }
    }
  }],
  dataZoom:[],
  series: [
    {
      type: 'bar',
      stack: 'samestack',
      barMaxWidth: 9,
      data: []
    },
    {
      type: 'bar',
      stack: 'samestack',
      data: [],
    },
    {
      type: 'bar',
      stack: 'samestack',
      data: [],
    }
  ]
};

export const cubeBarChartTemplate = {
  grid: {
    left: "11%",
    right: '1%',
    top: '11%',
    bottom: "24%"
  },
  xAxis: [{
    data:  [],
    axisLabel: {
      show: true,
      textStyle: {
        color: '#cad6d9',
        fontSize: 12,
        fontWeight: 'bold'
      },
      rotate: 35,
    },
    axisLine: {
      show: true,
      lineStyle: { color: '#263851' }
    },
    axisTick: {
      show: false
    },
    boundaryGap: true,
    splitLine: {
      show: false,
    }
  }],
  yAxis: [{
    show: false,
  }],
  series: [
    //背景柱体
    {
      name: '',
      type: 'pictorialBar',
      symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAADCAYAAACXr/8TAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM1MUJFMjMwRDdFQjExRUJBOEVEQkI4REY3REU1REQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM1MUJFMjMxRDdFQjExRUJBOEVEQkI4REY3REU1REQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzUxQkUyMkVEN0VCMTFFQkE4RURCQjhERjdERTVERDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzUxQkUyMkZEN0VCMTFFQkE4RURCQjhERjdERTVERDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4T+HrhAAAAH0lEQVR42mI0nb5LioFIIGjvQKxSBiYGGgGaGQwQYAAuUgI2DyvgDAAAAABJRU5ErkJggg==',
      symbolSize: [22, 1],//调整截面形状
      symbolOffset: [0, -3],
      symbolRepeat: true,
      symbolMargin: 0,
      zlevel: 101,
      //symbolPosition: "end",
      "itemStyle": {
        'normal': {
          color: '#C2E1F0',
        }
      },
      data: []
    },
    //背景柱顶圆片
    {
      name: "",
      type: "pictorialBar",
      symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAMCAYAAABm+U3GAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkYwQUEyMkIwRDg3QjExRUI4ODYxQjMwM0RCODM4RThCIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkYwQUEyMkIxRDg3QjExRUI4ODYxQjMwM0RCODM4RThCIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RjBBQTIyQUVEODdCMTFFQjg4NjFCMzAzREI4MzhFOEIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RjBBQTIyQUZEODdCMTFFQjg4NjFCMzAzREI4MzhFOEIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7lVmK8AAAA2UlEQVR42qzTIQwBURzH8bubyRcEwSSEE5RLEptwEUFQNSJN1ogUm6kK0abQaCZITFUElZn5vu1uM9vdu7v5b5/07v3e//33TtXNsuKjCuhihilesg2aZD2FOdYoYYwDrLDBOvo4ovKzZmBpM/wGR9DECW1EPZqy7O6HiHkFe37oUq6NaH6vJqnv0dWgiuAEksp/Ko606FwEr5BDC7eQgW9MkEEPD2fG4l2O7NMGeAYI3cBEA1e3V3FHB1ksJIFnVFHE3u87djblsQtzuOzP29rhdVyCjOsjwADBwS4pOZ4QvwAAAABJRU5ErkJggg==',
      symbolSize: [22, 12],//调整截面形状
      symbolOffset: [0, -10],
      zlevel: 102,
      symbolPosition: "end",
      "itemStyle": {
        'normal': {
          color: '#C2E1F0',
        }
      },
      data: []
    },
    //柱底圆片
    {
      name: "",
      type: "pictorialBar",
      symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAGCAYAAADHYm6gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjUxOUI4RDZGRDdFQjExRUJBMjlBRjA2Rjg4NkJEMThGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjUxOUI4RDcwRDdFQjExRUJBMjlBRjA2Rjg4NkJEMThGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NTE5QjhENkREN0VCMTFFQkEyOUFGMDZGODg2QkQxOEYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NTE5QjhENkVEN0VCMTFFQkEyOUFGMDZGODg2QkQxOEYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7xYircAAAAhUlEQVR42mI0nb7LnYGBoR2IDRkIAEF7BwYiwHkgrmQCEjuB2ASI44D4EQP54BHUDJBZO5mggv+AeDEQawBxCRB/IMHAD1A96lAzQGYxMKEp+g7EvUCsBKV/4DEQplYRm1omHJreI7liCcwVSL5bRMh3TESEWyw03HYB8SooO55QfAAEGACFZSAV4V74KAAAAABJRU5ErkJggg==',
      symbolSize: [22, 6],//调整截面形状
      symbolOffset: [0, 3],
      zlevel: 103,
      itemStyle: {
        'normal': {
          color: "#4898FF",

        }
      },
      data: []
    },
    //柱体
    {
      name: '',
      type: 'pictorialBar',
      symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAADCAIAAAAYzWhEAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjMwMDlDOTkyRDdFQjExRUI4MUEzQjJBMTEzMjI5RTM3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjMwMDlDOTkzRDdFQjExRUI4MUEzQjJBMTEzMjI5RTM3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzAwOUM5OTBEN0VCMTFFQjgxQTNCMkExMTMyMjlFMzciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzAwOUM5OTFEN0VCMTFFQjgxQTNCMkExMTMyMjlFMzciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6Q0a7GAAAAHUlEQVR42mI0nb6LAS8QtHfAr4CJgWJABSMAAgwAjwkCHJJ6CX8AAAAASUVORK5CYII=',
      symbolSize: [22, 1],//调整截面形状
      symbolOffset: [0, -3],
      symbolRepeat: true,
      symbolMargin: 0,
      zlevel: 104,
      label: {
        normal: {
          show: true,
          position: 'top',
          distance: 10,
          color: '#22cfea',
          fontSize: 14,
          fontWeight: 'bold',
        }
      },
      //symbolPosition: "end",
      "itemStyle": {
        'normal': {
          color: '#C2E1F0',
        }
      },
      data:  []
    },
    //柱顶圆片
    {
      name: "",
      type: "pictorialBar",
      symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAMCAYAAABm+U3GAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjZDMjY3RTMwRDdFQjExRUJCNjMwQjcxRDU0MzdBQkVCIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjZDMjY3RTMxRDdFQjExRUJCNjMwQjcxRDU0MzdBQkVCIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NkMyNjdFMkVEN0VCMTFFQkI2MzBCNzFENTQzN0FCRUIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NkMyNjdFMkZEN0VCMTFFQkI2MzBCNzFENTQzN0FCRUIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6VYr3xAAAA/0lEQVR42mK0evyXgQhgCMSdQPwNiKuA+BohDUwE5OWAeDEQnwFiVyD2B+KLQDwHiCXJMVgAiHuA+CYQx6CpYwHiZCC+C8QtULUEDWYH4mIgvgelOfA4ihOIq4H4NhAXADEbNoNBdBQQ34K6VJCBeCACxP1AfB2II4GYEWagMzQMl0LDlFygBMTLgPgsyEwmaJhRE4BczAoyeCcQm0Aj6REFBoL0JgCxMRDvgIXxP2hQqAJxCRC/IcHAD0BcDsQaQLwQahZGqvgFxL1QCzqA+DseA5HVdqGrZcLjikogVgPieTBXQMF/IF4OxOr4fMdIZJbWgmZpbqhh5whpAAgwACLpMv7dHUWgAAAAAElFTkSuQmCC',
      symbolSize: [22, 12],//调整截面形状
      symbolOffset: [0, -10],
      zlevel: 105,
      symbolPosition: "end",
      "itemStyle": {
        'normal': {
          color: '#246FF4',
        }
      },
      data: []
    }
  ]
};

export const barNegativeChartTemplate = {
  color: ['#E02F37', '#34C59E'],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    data: [],
    icon: 'rect',
    itemWidth: 12,
    itemHeight: 12,
    itemGap: 12,
    textStyle: {
      color: '#ccc',
      fontWeight: 'bold',
      fontSize: '11'
    },
    padding: [8, 0, 0, 0],
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    top: '15%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'value'
    }
  ],
  yAxis: [
    {
      type: 'category',
      axisTick: {
        show: false
      },
      data: [],
    }
  ],
  series: [
    {
      name: '',
      type: 'bar',
      stack: 'Total',
      label: {
        show: true
      },
      emphasis: {
        focus: 'series'
      },
      data: []
    },
    {
      name: '',
      type: 'bar',
      stack: 'Total',
      label: {
        show: true,
        position: 'left'
      },
      emphasis: {
        focus: 'series'
      },
      data: []
    }
  ]
};