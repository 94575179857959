<template>
  <div id="right">
    <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
      <div class="info-block-title" style="display: flex;justify-content: space-between;align-items:center;width: 90%;margin-top: -5px;">
        <div>报警情况</div>
        <div style="display:flex;justify-content:flex-end;">
          <CustomSelect :options="companyNameList" :value="selectedVal1" @input="companySelect($event, 'val1')" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;" />
        </div>
      </div>
      <div style="height:18rem;padding-top:3rem">
        <div
          v-if="!companyTroublePieChart.series || !companyTroublePieChart.series.length || !companyTroublePieChart.series[0].data.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyTroublePieChart" autoresize />
      </div>
    </div>
    <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
      <div class="info-block-title" style="display: flex;justify-content: space-between;align-items:center;width: 90%;margin-top: -5px;">
        <div>预警情况</div>
        <div style="display:flex;justify-content:flex-end;">
          <CustomSelect :options="companyNameList" :value="selectedVal2" @input="companySelect($event, 'val2')" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;" />
        </div>
      </div>
      <div style="height:18rem;padding-top:3rem">
        <div
          v-if="!todayNewTroubleBarChart.series || !todayNewTroubleBarChart.series.length || !todayNewTroubleBarChart.series[0].data.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="todayNewTroubleBarChart" autoresize />
      </div>
    </div>
    <div class="info-block" style="min-height:5rem">
      <div class="info-block-title" style="display: flex;justify-content: space-between;align-items:center;width: 90%">
        <div>各{{(districtScale == 0 && orgType == 1) ? '分公司' : (districtScale == 2 ? '项目' : '城市')}}预/报警数</div>
        <div style="display: flex;">
          <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': check_type == 1 }"
            @click="() => getSafetyOrgCityWarningAlarmCount(1)"
          >报警</div>
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': check_type == 2 }"
            @click="() => getSafetyOrgCityWarningAlarmCount(2)"
          >预警</div>
        </div>
        </div>
      </div>
      <div style="height:18rem;padding-top:3rem">
        <div
          v-if="!noActionTroubleBarChart.series || !noActionTroubleBarChart.series[0].data.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="noActionTroubleBarChart" @click="noActionTroubleBarChartClick" autoresize />
      </div>
    </div>
    
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="60rem"
      top="15rem"
    >
      <wrapper style="height:28rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle}}
            </div>
            <div class="tabbar">
              <div
                :class="{ 'tabar-select': popupSelect == 1 }"
                @click="getAttendance(1)"
              >报警</div>
              <div
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >预警</div>
            </div>
            <div style="width:10rem"></div>
          </div>
          <div style="display:flex; align-items: center;margin-top:1rem;justify-content: space-between;">
            <div></div>
            <div>

            </div>
            <div style="font-size:12px;color:#fff;">次数：次</div>
          </div>
          <!-- <div style="border:1px solid #112563;margin:1.5rem 1rem"> -->
          <div style="height:20rem;padding:1rem 0 0 1rem">
            <div style="height:100%;overflow-y:scroll">
              <div
                v-for="(item, index) in warningTable"
                :key="index"
                style="margin-bottom:1rem"
              >

                <div style="color: #A7B6BC;">
                  {{item.project_name}}
                </div>
                <div class="linebar">

                  <div
                    style="flex: 5"
                    class="line-bar"
                  >
                    <div
                      class="real-bar"
                      :style="{'width': (item.count / avgAttendanceMax * 100) + '%' }"
                    ></div>

                  </div>

                  <div style="color: #57C7B6;width:60px">
                    {{item.count}}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { nation } from '../../api'
  // import { attendancePieChartTemplate, colors } from './pieChartTemplates';
  import { SimpleBarChartTemplate } from './barChartTemplates';
  import { singleLineScrollChartTemplate } from './lineChartTemplates';
  import { applyUpdate, truncateStr } from "../../utils";
  import CustomSelect from '../../components/select';
import wrapper from "../../components/wrap";

  export default {
    name: 'SecurityPersonnelRightBar',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect, 
      wrapper,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
      orgOptions: Array,
      districtScale: Number,
      orgType: Number,
    },
    data () {
      return {
        companyNameList: [''],
        companyIdList: [''],
        selectedVal1: '',
        selectedVal1Id: '',
        selectedVal2Id: '',
        selectedVal2: '',
        companyTroublePieChart: {},
        option: {
         color: [
            '#f32c2c',
            '#f38e2c',
            '#2ce4ff',
            '#19b6fb',
            '#5dd5c3',
            '#ffed21',
            '#159172',
            '#fb9c5c'
          ],
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            icon: 'circle',
            itemWidth: 12,
            itemHeight: 12,
            itemGap: 12,
            textStyle: {
              color: '#ccc',
              fontWeight: 'bold',
              fontSize: '11'
            },
            padding: [8, 0, 0, 0],
            top: '4%',
            right: '3%',
            data: []
          },
          grid: {
            left: '8%',
            right: '4%',
            bottom: '1%',
            top: '19%',
            containLabel: true
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                 rotate: 40
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              name: '数量:次',
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              type: 'value',
              show: true,
              axisLine: {
                show: false,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: [5, 10],
                  color: '#14253f',
                  width: 2,
                }
              },
              axisTick: { show: false },
              axisLabel: {
                color: '#e6f2f3',
                fontSize: 14,
                fontWeight: 'bold',
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // }
              }
            }
          ],
          series: [
            // {
            //   name: 'Email',
            //   type: 'line',
            //   areaStyle: {},
            //   data: [120, 132, 101, 134, 90, 230, 210]
            // }
          ]
        },
        companyTroublePieChartHighlightIndex: 0,
        todayNewTroubleBarChart: {},
        option2: {
          color: [
            '#f32c2c',
            '#f38e2c',
            '#2ce4ff',
            '#19b6fb',
            '#5dd5c3',
            '#ffed21',
            '#159172',
            '#fb9c5c'
          ],
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            type: 'scroll', // 添加这一行代码即可实现图例分页功能
            orient: 'horizontal', // 'vertical'
            width: '75%',
            left: '25%',
            x: 'center',
            icon: 'circle',
            itemWidth: 14,
            itemHeight: 14,
            itemGap: 12,
            textStyle: {
              color: '#ccc',
              fontWeight: 'bold',
              fontSize: '11'
            },
            pageIconColor: '#fff',
            // padding: [8, 0, 0, 60],
            // top: '4%',
            // right: '3%',
            data: []
          },
          grid: {
            left: '6%',
            right: '4%',
            bottom: '1%',
            top: '19%',
            containLabel: true
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                 rotate: 40
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              name: '数量:次',
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              type: 'value',
              show: true,
              axisLine: {
                show: false,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: [5, 10],
                  color: '#14253f',
                  width: 2,
                }
              },
              axisTick: { show: false },
              axisLabel: {
                color: '#e6f2f3',
                fontSize: 14,
                fontWeight: 'bold',
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // }
              }
            }
          ],
          series: [
            // {
            //   name: 'Email',
            //   type: 'line',
            //   areaStyle: {},
            //   data: [120, 132, 101, 134, 90, 230, 210]
            // }
          ]
        },
        noActionTroubleBarChart: {},
        option3: {
          color: ['#E5B353'],
          grid: {
            containLabel: true,
            right: '6%',
            bottom: "1%",
            top: "13%",
            left: "16%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          tooltip: {
            trigger: 'axis',
            formatter: "{b}<br>{a0}: {c0}"
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 40,
                formatter: value => truncateStr(value, 10),
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              show: true,
              name: "数量：次",
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
            }
          ],
          series: [
            {
              name: "数量",
              type: 'bar',
              barWidth: 10,
              data: []
            }
          ]
        },
        check_type: 1,
        org_id: '',

        
        // districtScale: 0,
        popupShow: false,
        popupTitle: "",
        popupSelect: 1,
        avgAttendanceMax:0,
        warningTable:[],
        searchKey:[],
        activeIndex:0
      }
    },
    watch: {
      orgOptions(newValue, oldValue) {
        if (newValue != oldValue) {
          this.companyNameList = newValue.map(item => truncateStr(item.org_name, 10));
          this.companyIdList = newValue.map(item => item.org_id);

          let idx = this.companyIdList.indexOf(this.orgLevel);
          if (idx == -1) {
            idx = 0;
          }
          this.companySelect(this.companyNameList[idx], 'val1')
          this.companySelect(this.companyNameList[idx], 'val2')
        }
      },
      orgLevel: function (newValue, oldValue) {
        if (newValue != oldValue) {
          const idx = this.companyIdList.indexOf(newValue);
          if (idx >= 0) {
            this.companySelect(this.companyNameList[idx], 'val1');
            this.companySelect(this.companyNameList[idx], 'val2');
          }
          this.org_id=this.orgLevel;

          this.getAll();
        }
      },
      districtLevel: function (newValue, oldValue) {
        if (newValue != oldValue) {
          if(newValue === '100000'){
            this.districtScale = 0;
            this.companySelect(this.companyNameList[0], 'val1');
            this.companySelect(this.companyNameList[0], 'val2');
          }

          this.getAll();
        }
      },
    districtScale: function (newValue) {
      if (newValue) {
        this.districtScale = newValue;
      }
    },
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.companyNameList = this.orgOptions.map(item => truncateStr(item.org_name, 10));
      this.companyIdList = this.orgOptions.map(item => item.org_id);
      let idx = this.companyIdList.indexOf(this.orgLevel);
      if (idx == -1) {
        idx = 0;
      }
      if (idx >= 0) {
        this.selectedVal1 = this.companyNameList[idx];
        this.selectedVal2 = this.companyNameList[idx];
        this.companySelect(this.companyNameList[idx], 'val1');
        this.companySelect(this.companyNameList[idx], 'val2');
      }
      this.org_id=this.orgLevel;

      this.getAll();

    },
    methods: {
      noActionTroubleBarChartClick(params){
      if (this.districtScale !== 2) {
        this.activeIndex=params.dataIndex;
        this.popupShow=true;
        this.popupTitle = params.name + "预/报警情况";
        this.popupSelect = this.check_type;
        this.getPopData();
      }
      },
      getAttendance(popupSelect) {
        this.popupSelect = popupSelect;
        this.getPopData();
      },
      getPopData() {
        nation.getSafetyWarningAlarmCollect(this.popupSelect,1, 100,this.districtScale==0&&this.orgType == 1?this.searchKey[this.activeIndex]:'',this.districtScale==1||this.orgType !== 1?this.searchKey[this.activeIndex]:'').then((d) => {
          // console.log(d);
          this.warningTable = d;
          this.avgAttendanceMax = Math.max.apply(
            null,
            this.warningTable.map((item) => item.count)
          );
        });
      },
      getAll(){
        // let idx = this.companyIdList.indexOf(this.orgLevel);
        // if (idx == -1) {
        //   idx = 0;
        // }
        // this.selectedVal1 = this.companyNameList[idx];
        // this.selectedVal2 = this.companyNameList[idx];

        this.getSafetyOrgCityWarningAlarmCount(1)
        this.getCallPoliceInfo();
        this.getWarningInfo();
      },
      companySelect(option, val) {
        const idx = this.companyNameList.indexOf(option);
        if (idx >= 0) {
          this.org_id = this.companyIdList[idx];
          // window.$orgLevel = this.org_id;
          if(val === 'val1'){
            this.selectedVal1 = option;
            this.selectedVal1Id=this.org_id;
            this.getCallPoliceInfo();
          }else if(val === 'val2'){
            // eslint-disable-next-line
            this.selectedVal2 = option;
            this.selectedVal2Id=this.org_id;
            this.getWarningInfo();
          }
        }
      },
      getCallPoliceInfo(){
        nation.getSafetyMonth12WarningAlarmCount(1, this.selectedVal1Id ? this.selectedVal1Id:'')
          .then(d => {
            // console.log(d);
            const chart = JSON.parse(JSON.stringify(singleLineScrollChartTemplate));
            this.option.legend.data = d.legend_data;
            this.option.xAxis[0].data = d.xAxis_data;
            this.option.series = [];
            d.series.forEach(v => {
              this.option.series.push({
                name: v.name,
                type: 'line',
                areaStyle: {},
                data: v.data
              })
            })
            this.companyTroublePieChart = applyUpdate(chart, this.option);
          })
      },
      getWarningInfo(){
        nation.getSafetyMonth12WarningAlarmCount(2, this.selectedVal2Id ? this.selectedVal2Id:'')
          .then(d => {
            // console.log(d);
            const chart = JSON.parse(JSON.stringify(singleLineScrollChartTemplate));
            this.option2.legend.data = d.legend_data;
            this.option2.xAxis[0].data = d.xAxis_data;
            this.option2.series = [];
            d.series.forEach(v => {
              this.option2.series.push({
                name: v.name,
                type: 'line',
                areaStyle: {},
                data: v.data
              })
            })
            this.todayNewTroubleBarChart = applyUpdate(chart, this.option2);
          })
      },
      getSafetyOrgCityWarningAlarmCount(check_type){
        this.check_type = check_type;
        nation.getSafetyOrgCityWarningAlarmCount(this.check_type)
          .then(d => {
            // console.log(d);
            this.searchKey=d.search_keys
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option3.xAxis[0].data = d.xAxis_data;
            this.option3.series[0].data = d.series_data
            this.noActionTroubleBarChart = applyUpdate(chart, this.option3);
          })
      },
    },
  }
</script>

<style scoped>
.linebar {
  width: 100%;
}
.line-bar {
  display: flex;
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #57c7b6;
  border-radius: 12px;
}
  .option-block2 {
    color: #919192;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 4px;
    min-width: 3.4rem;
    border: 1px solid #1b8ebd;
    text-align: center;
    cursor: pointer;
  }
  .option-block-selected2 {
    background-color: #4191e5;
    color: #fff;
  }

  .custom-select .selected {
    background-color: #0b1d39 !important;
    border: 1px solid #49a0e6 !important;
  }

  .custom-select .selected.open {
    border: 1px solid #49a0e6 !important;
  }

  .custom-select .items {
    border-right: 1px solid #49a0e6 !important;
    border-left: 1px solid #49a0e6 !important;
    border-bottom: 1px solid #49a0e6 !important;
    background-color: #0b1d39 !important;
  }
</style>
