<template>
  <div id="left">
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;top: 0.2rem;"
      >
        <div>系统安装率</div>
        <div style="display:flex;align-items:center;">
          <CustomSelect
            :options="antiList"
            :value="selectedVal1"
            @input="antiSelect($event, 'val1')"
            style="width:8rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
          />
          <div
            v-if="!(systemInstallChart.series && [].concat.apply([], systemInstallChart.series.map(x => x.data)).length == 0)"
            class="detail"
            style="margin-left: 0.5rem;"
            @click="showDetail(1)"
          >
            详情
          </div>
        </div>
      </div>

      <div style="height:18rem;min-height:12rem;padding-top:3rem">
        <div
          v-if="systemInstallChart.series && [].concat.apply([], systemInstallChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="systemInstallChart"
          @click="systemInstallChartClick"
          ref="troubleType"
          autoresize
        />
      </div>
    </div>

    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;top: 0.2rem;"
      >
        <div>系统在线率</div>
        <div style="display:flex;align-items:center;">
          <CustomSelect
            :options="antiList"
            :value="selectedVal2"
            @input="antiSelect($event, 'val2')"
            style="width:8rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
          />
          <div
            v-if="!(systemOnLineChart.series && [].concat.apply([], systemOnLineChart.series.map(x => x.data)).length == 0)"
            class="detail"
            style="margin-left: 0.5rem;"
            @click="showDetail(2)"
          >
            详情
          </div>
        </div>
      </div>

      <div style="height:18rem;min-height:12rem;padding-top:3rem">
        <div
          v-if="systemOnLineChart.series && [].concat.apply([], systemOnLineChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="systemOnLineChart"
          @click="systemOnLineChartClick"
          ref="troubleType"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div class="info-block-title">吊装统计</div>
      <div style="height:19rem">

        <div style="padding-top: 3rem;">

          <div style="display:flex;justify-content: center">
            <div class="now">
              <div style="color: #1DFDFD;font-size: 18px;font-weight:bold;">{{craneStatisticsData.total_hcount}}</div>
              <div>总吊装次数</div>
            </div>
            <div class="now">
              <div style="color: #3E89DB;font-size: 18px;font-weight:bold;">{{craneStatisticsData.total_hweight}}吨</div>
              <div>总吊重</div>
            </div>
          </div>
        </div>
        <div style="height:16rem;">
          <div
            v-if="craneStatisticsChart.series && [].concat.apply([], craneStatisticsChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart
            v-else
            :options="craneStatisticsChart"
            autoresize
          />
        </div>
      </div>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow1"
      width="90rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:42rem;backgroundColor:#0e1328;position:relative"
      >
        <div
          class="stats-close-btn"
          @click="popupShow1=false"
        />
        <div class="attendance">
          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="display:flex;justify-content:space-between;height: 3rem;display: flex;align-items: baseline;">

              <div style="font-size:1rem;font-weight:bold;margin:1rem 0 0 2rem">系统安装情况</div>
              <CustomSelect
                :options="antiList"
                :value="selectedVal3"
                @input="antiSelect($event, 'val3')"
                style="width:8rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold;margin-right:1rem"
              />
            </div>
            <div style="height:15rem;padding-top:1rem">
              <div
                v-if="installDetailChart.series && [].concat.apply([], installDetailChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart
                v-else
                :options="installDetailChart"
                @click="installDetailChartClick"
                autoresize
              />
            </div>
          </div>
          <div style="margin:0.5rem 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName1}}</div>
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:20rem;text-align:center">项目名称</div>
              <div style="width:12rem;text-align:center">塔机总数</div>
              <div style="width:12rem;text-align:center">防碰撞安装数</div>
              <div style="width:12rem;text-align:center">安装率</div>
              <div style="width:12rem;text-align:center">未安装防碰撞系统编号</div>
            </div>
            <div style="height:10rem;overflow:auto;">
              <div
                v-for="(item, index) in popupData1"
                :key="item.project_id"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;cursor: pointer;"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:20rem;text-align:center">{{item.project_name}}</div>
                <div style="width:12rem;text-align:center">
                  {{item.dcount}}
                </div>
                <div style="width:12rem;text-align:center">{{item.icount}}</div>
                <div style="width:12rem;text-align:center">{{item.rate}}</div>
                <div style="width:12rem;text-align:center">{{item.uninstall_no}}</div>
              </div>
              <div
                v-if="!(popupData1 && popupData1.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无安装数据
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="securityStarPage1"
              :page-count="securityStarPages1"
              @current-change="securityStarPageChange1"
              style="text-align:center;margin-top: 0.5rem;"
            ></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="90rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:42rem;backgroundColor:#0e1328;position:relative"
      >
        <div
          class="stats-close-btn"
          @click="popupShow2=false"
        />
        <div class="attendance">
          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="display:flex;justify-content:space-between;height: 3rem;display: flex;align-items: baseline;">

              <div style="font-size:1rem;font-weight:bold;margin:1rem 0 0 2rem">系统在线情况</div>
              <CustomSelect
                :options="antiList"
                :value="selectedVal4"
                @input="antiSelect($event, 'val4')"
                style="width:8rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold;margin-right:1rem"
              />
            </div>
            <div style="height:15rem;padding-top:1rem">
              <div
                v-if="craneDetailChart.series && [].concat.apply([], craneDetailChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart
                v-else
                :options="craneDetailChart"
                @click="craneDetailChartClick"
                autoresize
              />
            </div>
          </div>
          <div style="margin:0.5rem 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName2}}</div>
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:20rem;text-align:center">项目名称</div>
              <div style="width:12rem;text-align:center">塔机总数</div>
              <div style="width:12rem;text-align:center">防碰撞安装数</div>
              <div style="width:12rem;text-align:center">在线数</div>
              <div style="width:12rem;text-align:center">在线率</div>
            </div>
            <div style="height:10rem;overflow:auto;">
              <div
                v-for="(item, index) in popupData2"
                :key="item.project_id"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;cursor: pointer;"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:20rem;text-align:center">{{item.project_name}}</div>
                <div style="width:12rem;text-align:center">
                  {{item.dcount}}
                </div>
                <div style="width:12rem;text-align:center">{{item.icount}}</div>
                <div style="width:12rem;text-align:center">{{item.ocount}}</div>
                <div style="width:12rem;text-align:center">{{item.rate}}</div>
              </div>
              <div
                v-if="!(popupData2 && popupData2.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="securityStarPage2"
              :page-count="securityStarPages2"
              @current-change="securityStarPageChange2"
              style="text-align:center;margin-top: 0.5rem;"
            ></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation } from "../../api";
import CustomSelect from "../../components/select";
import { applyUpdate } from "../../utils";
import { singleLineChartTemplate } from "./lineChartTemplates";
import { SimpleBarChartTemplate } from "./barChartTemplates";
// import { singleLineScrollChartTemplate } from "./lineChartTemplates";
import wrapper from "../../components/wrap";

export default {
  name: "SecurityLeftBar",
  components: {
    "v-chart": ECharts,
    wrapper,
    CustomSelect: CustomSelect,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    orgType: Number,
  },
  data() {
    return {
      antiList: ["防碰撞"],
      antiIdList: [""],
      selectedVal1: "",
      selectedId1: "",
      selectedVal2: "",
      selectedId2: "",
      selectedVal3: "",
      selectedId3: "",
      selectedVal4: "",
      selectedId4: "",

      systemInstallChart: {},
      option: {
        color: ["#4270EE"],
        tooltip: {
          // show: false,
          // trigger: "none",
        },
        legend: {
          data: [],
        },
        grid: {
          top: "10%",
          left: "2%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          // 显示分隔线
          splitLine: {
            show: false,
          },
          axisTick: { show: false },
        },
        yAxis: [
          {
            data: [],
            nameRotate: "",
            nameTextStyle: {
              color: "#ccc",
              align: "right",
            },
            type: "category",
            axisLabel: {
              color: "#ffffff",
              formatter: function (value) {
                return value.length > 8 ? value.slice(0, 8) + "..." : value;
              },
            },
            axisLine: { show: false, lineStyle: { color: "#36445f" } },
            axisTick: { show: false },
          },
          {
            name: "安装率：%",
            type: "category",
            position: "center",
            offset: 0,
            nameGap: 10,
            nameTextStyle: {
              color: "#fff",
              align: "left",
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              color: "#fff",
            },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: "安装率",
            type: "bar",
            barWidth: 10,
            data: [],
          },
        ],
      },

      systemOnLineChart: {},
      option2: {
        color: ["#38B6A2"],
        legend: {
          data: [],
        },
        grid: {
          top: "10%",
          left: "2%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          // 显示分隔线
          splitLine: {
            show: false,
          },
          axisTick: { show: false },
        },
        yAxis: [
          {
            data: [],
            nameRotate: "",
            nameTextStyle: {
              color: "#ccc",
              align: "right",
            },
            type: "category",
            axisLabel: {
              color: "#ffffff",
              formatter: function (value) {
                return value.length > 8 ? value.slice(0, 8) + "..." : value;
              },
            },
            axisLine: { show: false, lineStyle: { color: "#36445f" } },
            axisTick: { show: false },
          },
          {
            name: "在线率：%",
            type: "category",
            position: "center",
            offset: 0,
            nameGap: 10,
            nameTextStyle: {
              color: "#fff",
              align: "left",
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              color: "#fff",
            },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: "在线率",
            type: "bar",
            barWidth: 10,
            data: [],
          },
        ],
      },

      craneStatisticsData: {},
      craneStatisticsChart: {},
      option3: {
        color: ["#1DFDFD", "#19B5FB"],
        grid: {
          contentLabel: true,
          left: "8%",
          bottom: "15%",
          top: "15%",
          right: "8%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        tooltip: {
          trigger: "axis",
          // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}<br>` +
                params[0].marker +
                " " +
                params[0].seriesName +
                " : " +
                (params[0].data.value ? params[0].data.value : params[0].data);
              if (params[1]) {
                result +=
                  "</br>" +
                  params[1].marker +
                  " " +
                  params[1].seriesName +
                  " : " +
                  params[1].data;
              }
            }
            return result;
          },
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              rotate: 35,
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            name: "平均吊次：次",
            type: "value",
            nameTextStyle: {
              padding: [0, 0, 0, 10],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: false },
            // splitLine: { show: false },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#1E2573",
                width: 1,
              },
            },
            axisTick: { show: false },
          },
          {
            name: "平均吊重：吨",
            type: "value",
            nameTextStyle: {
              padding: [0, 10, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: false },
            splitLine: { show: false },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: "数量",
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                },
              },
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(32, 252, 254, 0.5)" },
                { offset: 1, color: "rgba(32, 252, 254, 0)" },
              ]),
            },
            symbolSize: 0,
            yAxisIndex: 0,
            data: [],
          },
          {
            name: "数量",
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                },
              },
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(28, 162, 251, 0.5)" },
                { offset: 1, color: "rgba(28, 162, 251, 0)" },
              ]),
            },
            symbolSize: 0,
            yAxisIndex: 1,
            data: [],
          },
        ],
      },

      installDetailChart: {},
      installDetailChartTemplate: {
        color: ["#2DAAEB", "#C0C0C0", "#23CF9E"],
        grid: {
          contentLabel: true,
          right: "5%",
          top: "13%",
          left: "5%",
          bottom: "15%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        tooltip: {
          trigger: "axis",
          // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}<br>` +
                params[0].marker +
                " " +
                params[0].seriesName +
                " : " +
                (params[0].data.value || params[0].data.value == 0
                  ? params[0].data.value
                  : params[0].data);
              if (params[1]) {
                result +=
                  "</br>" +
                  params[1].marker +
                  " " +
                  params[1].seriesName +
                  " : " +
                  params[1].data;
              }
              if (params[2]) {
                result +=
                  "</br>" +
                  params[2].marker +
                  " " +
                  params[2].seriesName +
                  " : " +
                  params[2].data;
              }
            }
            return result;
          },
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              // rotate: 35
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            name: "数量：个",
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#36445f",
                width: 1,
              },
            },
            axisTick: { show: false },
            type: "value",
            show: true,
          },
          {
            show: true,
            name: "安装率：%",
            min: 0,
            max: 100,
            interval: 25,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              // formatter: function(value) {
              //   return value ? value + '%' : value;
              // },
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        series: [
          {
            name: "安装数量",
            type: "bar",
            stack: "samestack",
            barWidth: 10,
            z: 10,
            data: [],
          },
          {
            name: "塔机总数",
            type: "bar",
            barGap: "-100%",
            barWidth: 10,
            data: [],
          },
          {
            name: "安装率",
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                },
              },
            },
            symbolSize: 0,
            yAxisIndex: 1,
            data: [],
          },
        ],
      },

      popupShow1: false,
      popupBottomName1: "",
      popupSearchkeys1: [],
      popupXAxisData1: [],
      popupBarData: [],
      popupData1: [],
      securityStarPage1: 1,
      securityStarPages1: 1,
      dataIndex: -1,

      craneDetailChart: {},
      craneDetailChartTemplate: {
        color: ["#2DAAEB", "#C0C0C0", "#23CF9E"],
        grid: {
          contentLabel: true,
          right: "5%",
          top: "13%",
          left: "5%",
          bottom: "15%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        tooltip: {
          trigger: "axis",
          // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}<br>` +
                params[0].marker +
                " " +
                params[0].seriesName +
                " : " +
                (params[0].data.value || params[0].data.value == 0
                  ? params[0].data.value
                  : params[0].data);
              if (params[1]) {
                result +=
                  "</br>" +
                  params[1].marker +
                  " " +
                  params[1].seriesName +
                  " : " +
                  params[1].data;
              }
              if (params[2]) {
                result +=
                  "</br>" +
                  params[2].marker +
                  " " +
                  params[2].seriesName +
                  " : " +
                  params[2].data;
              }
            }
            return result;
          },
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              // rotate: 35
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            name: "数量：个",
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#36445f",
                width: 1,
              },
            },
            axisTick: { show: false },
            type: "value",
            show: true,
          },
          {
            show: true,
            name: "在线率：%",
            min: 0,
            max: 100,
            interval: 25,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              // formatter: function(value) {
              //   return value ? value + '%' : value;
              // },
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        series: [
          {
            name: "防碰撞安装数量",
            type: "bar",
            barWidth: 10,
            z: 10,
            data: [],
          },
          {
            name: "塔机总数",
            type: "bar",
            barGap: "-100%",
            barWidth: 10,
            data: [],
          },
          {
            name: "安装率",
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                },
              },
            },
            symbolSize: 0,
            yAxisIndex: 1,
            data: [],
          },
        ],
      },

      popupShow2: false,
      popupBottomName2: "",
      popupSearchkeys2: [],
      popupXAxisData2: [],
      popupBarData2: [],
      popupData2: [],
      securityStarPage2: 1,
      securityStarPages2: 1,
      dataIndex2: -1,
    };
  },
  watch: {
    orgLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.getData();
      }
    },
    districtLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        if (newValue == "100000") {
          this.districtScale = 0;
        }
        this.getData();
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.districtScale = newValue;
      }
    },
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;

    this.getData();
  },
  methods: {
    antiSelect(option, val) {
      const idx = this.antiList.indexOf(option);
      if (idx >= 0) {
        if (val === "val1") {
          this.selectedVal1 = option;
          this.selectedId1 = this.antiIdList[idx];
          this.getFirstChart();
          // this.getData();
        } else if (val === "val2") {
          this.selectedVal2 = option;
          this.selectedId2 = this.antiIdList[idx];
          this.getSeconedChart();
        } else if (val === "val3") {
          this.selectedVal3 = option;
          this.selectedId3 = this.antiIdList[idx];
        } else if (val === "val4") {
          this.selectedVal4 = option;
          this.selectedId4 = this.antiIdList[idx];
        }
      }
    },
    showDetail(val) {
      if (val == 1) {
        const idx = this.antiList.indexOf(this.selectedVal1);
        this.selectedVal3 = this.antiList[idx];
        this.selectedId3 = this.antiIdList[idx];

        this.securityStarPage1 = 1;
        this.popupShow1 = true;
        this.dataIndex = -1;
        this.getFirstPopData();
      } else {
        const idx = this.antiList.indexOf(this.selectedVal2);
        this.selectedVal4 = this.antiList[idx];
        this.selectedId4 = this.antiIdList[idx];

        this.securityStarPage2 = 1;
        this.popupShow2 = true;
        this.dataIndex2 = -1;
        this.getSeconedPopData();
      }
    },
    getData() {
      // project
      //   .getGeneralParamsSelect(31)
      //   .then((d) => {
      //     this.antiList = ["防碰撞"].concat(d.map((item) => item.value));
      //     this.antiIdList = [""].concat(d.map((item) => item.innerid));
      //   })
      //   .catch((e) => console.error(e.msg || e.message));
      this.getFirstChart();
      this.getSeconedChart();

      nation
        .getSafetyAnti7DaysCollect()
        .then((d) => {
          this.craneStatisticsData = {
            total_hcount: d.total_hcount,
            total_hweight: d.total_hweight,
          };
          const chart = JSON.parse(JSON.stringify(singleLineChartTemplate));
          this.option3.legend.data = d.legend_data;
          this.option3.xAxis[0].data = d.xAxis_data;
          this.option3.series[0].name = d.legend_data[0];
          this.option3.series[0].data = d.series[0].data;
          this.option3.series[1].data = d.series[1].data;
          this.option3.series[1].name = d.legend_data[1];
          this.craneStatisticsChart = applyUpdate(chart, this.option3);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    getFirstChart() {
      nation
        .getSafetyAntiInstallationRates()
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option.yAxis[0].data = d.yAxis_data;
          this.option.yAxis[1].data = d.series;
          this.option.series[0].data = d.series;
          this.systemInstallChart = applyUpdate(chart, this.option);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    getSeconedChart() {
      nation
        .getSafetyAntiOnlineRates()
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option2.yAxis[0].data = d.yAxis_data;
          this.option2.yAxis[1].data = d.series;
          this.option2.series[0].data = d.series;
          this.systemOnLineChart = applyUpdate(chart, this.option2);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },

    systemInstallChartClick() {
      // systemInstallChartClick(params) {
      //   const idx = this.antiList.indexOf(this.selectedVal1);
      //   this.selectedVal3 = this.antiList[idx];
      //   this.selectedId3 = this.antiIdList[idx];
      //   this.dataIndex = params.dataIndex;
      //   this.securityStarPage1 = 1;
      //   this.popupShow1 = true;
      //   this.getFirstPopData();
    },
    getFirstPopData() {
      nation.getSafetyAntiInstallationCollect().then((d) => {
        if (this.dataIndex < 0) {
          this.dataIndex = d.search_key.length - 1;
        }

        const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
        this.installDetailChartTemplate.legend.data = d.legend_data;
        this.installDetailChartTemplate.xAxis[0].data = d.xAxis_data;
        d.series[0].data[this.dataIndex] = {
          value: d.series[0].data[this.dataIndex],
          itemStyle: {
            color: "#FFD153",
          },
        };
        this.installDetailChartTemplate.series[0].data = d.series[0].data;
        this.installDetailChartTemplate.series[0].name = d.legend_data[0];
        this.installDetailChartTemplate.series[1].data = d.series[1].data;
        this.installDetailChartTemplate.series[1].name = d.legend_data[1];
        this.installDetailChartTemplate.series[2].data = d.series[2].data;
        this.installDetailChartTemplate.series[2].name = d.legend_data[2];
        this.installDetailChart = applyUpdate(
          chart,
          this.installDetailChartTemplate
        );

        this.popupBottomName1 =
          `${this.installDetailChartTemplate.xAxis[0].data[this.dataIndex]}` +
          "安装情况";
        this.getLaborAttendanceDateOrgCountCollect(
          d.search_key[this.dataIndex]
        );
        this.popupSearchkeys1 = d.search_key;
        this.popupXAxisData1 = d.xAxis_data;
        this.popupBarData = d.series[0].data;
      });
    },
    installDetailChartClick(params) {
      let subData = this.popupBarData;
      this.installDetailChart.series[0].data = subData.map(function (
        item,
        index
      ) {
        return index === params.dataIndex
          ? {
              value:
                Object.prototype.toString.call(item) === "[object Object]"
                  ? item.value
                  : item,
              itemStyle: {
                color: "#FFD153",
              },
            }
          : Object.prototype.toString.call(item) === "[object Object]"
          ? item.value
          : item;
      });
      this.popupBottomName1 =
        `${this.popupXAxisData1[params.dataIndex]}` + "安装情况";
      this.getLaborAttendanceDateOrgCountCollect(
        this.popupSearchkeys1[params.dataIndex]
      );
      this.dataIndex = params.dataIndex;
      this.securityStarPages1 = 1;
    },
    getLaborAttendanceDateOrgCountCollect(search_keys) {
      nation
        .getSafetyAntiInstallationProjectCollect(
          search_keys.search_type,
          search_keys.search_key,
          this.securityStarPage1,
          5
        )
        .then((d) => {
          this.popupData1 = d;
          this.securityStarPages1 = Math.ceil(d.__count__ / 5);
        });
    },
    securityStarPageChange1(currPage) {
      // console.log(currPage);
      this.securityStarPage1 = currPage;
      this.getLaborAttendanceDateOrgCountCollect(
        this.popupSearchkeys1[this.dataIndex]
      );
    },
    systemOnLineChartClick() {
      // systemOnLineChartClick(params) {
      // const idx = this.antiList.indexOf(this.selectedVal2);
      // this.selectedVal4 = this.antiList[idx];
      // this.selectedId4 = this.antiIdList[idx];
      // this.dataIndex2 = params.dataIndex;
      // this.securityStarPage2 = 1;
      // this.popupShow2 = true;
      // this.getFirstPopData();
    },
    getSeconedPopData() {
      nation.getSafetyAntiOnlineCollect().then((d) => {
        if (this.dataIndex2 < 0) {
          this.dataIndex2 = d.search_key.length - 1;
        }

        const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
        this.craneDetailChartTemplate.legend.data = d.legend_data;
        this.craneDetailChartTemplate.xAxis[0].data = d.xAxis_data;
        d.series[0].data[this.dataIndex2] = {
          value: d.series[0].data[this.dataIndex2],
          itemStyle: {
            color: "#FFD153",
          },
        };
        this.craneDetailChartTemplate.series[0].data = d.series[0].data;
        this.craneDetailChartTemplate.series[0].name = d.legend_data[0];
        this.craneDetailChartTemplate.series[1].data = d.series[1].data;
        this.craneDetailChartTemplate.series[1].name = d.legend_data[1];
        this.craneDetailChartTemplate.series[2].data = d.series[2].data;
        this.craneDetailChartTemplate.series[2].name = d.legend_data[2];
        this.craneDetailChart = applyUpdate(
          chart,
          this.craneDetailChartTemplate
        );

        this.popupBottomName2 =
          `${this.craneDetailChartTemplate.xAxis[0].data[this.dataIndex2]}` +
          "在线情况";
        this.getCraneDetailTableCollect(d.search_key[this.dataIndex2]);
        this.popupSearchkeys2 = d.search_key;
        this.popupXAxisData2 = d.xAxis_data;
        this.popupBarData2 = d.series[0].data;
      });
    },
    craneDetailChartClick(params) {
      let subData = this.popupBarData2;
      this.craneDetailChart.series[0].data = subData.map(function (
        item,
        index
      ) {
        return index === params.dataIndex
          ? {
              value:
                Object.prototype.toString.call(item) === "[object Object]"
                  ? item.value
                  : item,
              itemStyle: {
                color: "#FFD153",
              },
            }
          : Object.prototype.toString.call(item) === "[object Object]"
          ? item.value
          : item;
      });
      this.popupBottomName2 =
        `${this.popupXAxisData2[params.dataIndex]}` + "在线情况";
      this.getCraneDetailTableCollect(this.popupSearchkeys2[params.dataIndex]);
      this.dataIndex2 = params.dataIndex;
      this.securityStarPages2 = 1;
    },
    getCraneDetailTableCollect(search_keys) {
      nation
        .getSafetyAntiOnlineProjectCollect(
          search_keys.search_type,
          search_keys.search_key,
          this.securityStarPage2,
          5
        )
        .then((d) => {
          this.popupData2 = d;
          this.securityStarPages2 = Math.ceil(d.__count__ / 5);
        });
    },
    securityStarPageChange2(currPage) {
      this.securityStarPage2 = currPage;
      this.getCraneDetailTableCollect(this.popupSearchkeys2[this.dataIndex2]);
    },
  },
};
</script>

<style scoped lang="less">
.now {
  width: 9rem;
  border: 1px solid #224d7e;
  padding: 0.8rem 0;
  color: #fff;
  background-color: rgba(34, 77, 126, 0.2);
  text-align: center;
  margin-right: 1rem;
}
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
.linebar {
  width: 100%;
}
.line-bar {
  display: flex;
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #57c7b6;
  border-radius: 12px;
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>