import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    '产业楼': [-796.4147814579117,137.7106342362039,500],
  };

  const buildingBoundaries = {
    '产业楼': [[-10000,-10000],[-10000,10000],[10000,10000],[10000,-10000]],
  };

  const buildingFinishedHeights = {
    '产业楼': 650,
  };

  const doneFloors = {
    '产业楼': 0,
  };

  const traceViews = {
    '_': {
      center: [-1324.3953636512563, 1224.5230414543257],
      pitch: 0,
      zoom: 1.81,
      bearing: -45.4,
    },
    '产业楼': {
      center: [-7051.71341567486, 12345.27071338828],
      pitch: 81.6,
      zoom: 2,
      bearing: -7.0,
    },
  };

  function getUserZValue(user) {
    return (user.floor == 'ground' ? 0 : user.height) * 5.54 + 8;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 5.54;
  }

  function getZValueFromHeight(height) {
    return height * 5.54;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/lanjian2.glb',
    modelType: 'gltf',
    modelSubType: 'draco',

    //fullOpacity: 1,
    //revealOpacity: 0.2,
    //deepRevealOpacity: 0,

    /*
    groundHeightRanges: [
      [-0.0001, 0],
      [153.9731, 153.9732],
      [-1425.0001, -1424.9999],
    ],
    */

    modelOptions: {
      scale: 18,
      rotZ: 0,
      moveX: -200,
      moveY: -450,
      moveZ: 0,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: 0,
      z: 0
    },

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
