<template>
  <div class="info-block" style="min-height:5rem">
    <div class="info-block-title">观摩工地情况</div>
    <div style="height:20rem;padding-top:3rem">
      <div
        v-if="seeSiteChart.series && [].concat.apply([], seeSiteChart.series.map(x => x.data)).length == 0"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart v-else :options="seeSiteChart" autoresize />
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { quality } from '../../../../api_quality'
  import { stackedBarChartTemplate } from "../../barChartTemplates";
  import { applyUpdate } from "../../../../utils";

  export default {
    name: 'SeeSiteConditionChart',
    components: {
      'v-chart': ECharts,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerLevelChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerLevelChart();
        }
      }
    },
    data () {
      return {
        seeSiteChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.updateSafetyDangerLevelChart();
    },
    methods: {
      updateSafetyDangerLevelChart() {
        quality.getSeeSiteCondition()
          .then(d => {
            const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
            const option = {
              color: ["#46A0FF", "#FFED21", "#FF8521", "rgba(0,0,0,0)"],
              legend: {
              },
              grid: {
                right: "5%",
                bottom: "5%"
              },
              xAxis: {
                type: "category",
                axisLabel: {
                  color: 'white',
                  fontWeight: 'bold',
                  rotate: 40,
                  // formatter: value => truncateStr(value, 10),
                },
                data:[]
              },
              yAxis: [
                {
                  nameRotate: "",
                  nameTextStyle: {
                    color: "#ccc",
                    align: "right",
                  },
                  type: "value",
                  axisLabel: {
                    color: "#ffffff"
                  },
                  splitLine: { show: false },
                }
              ],
              series:[
                {
                  name: "观摩工地数量",
                  type: "bar",
                  stack: "total",
                  barMaxWidth: 18,
                  label:{
                    show:true
                  },
                  emphasis: {
                    focus: "series",
                  },
                  data: [],
                }
              ]
            };
            for (let i = 0; i < d.length; i++) {
              option.xAxis.data.push(d[i].orgName);
              option.series[0].data.push(d[i].gmgdNum);
            }

            this.seeSiteChart = applyUpdate(chart, option);
          })
          // eslint-disable-next-line
          .catch((e) => console.error(e.msg || e.message));
      },
    },
  }
</script>

<style lang="less">
</style>
