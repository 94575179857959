<template>
    <div id = "left">
        <div class="info-block" style="min-height:5rem">
        <div class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >总部本年费用日历
            <div
              class="detail"
              style="margin-left: 0.5rem;"
              @click=" showDetail()"
            >
              详情
            </div>
        </div>
        <div style="height:20rem;padding-top:3rem">
        <div
            v-if="chiefCaseChart.series && [].concat.apply([], chiefCaseChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="chiefCaseChart" @click="chiefCaseChartClick" autoresize />
        </div>
    </div>
    <!-- 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
                <div style="display: flex;  height: 5rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                    <div style="width:8rem;text-align:center">支出类别</div>
                    <div style="width:8rem;text-align:center">一季度</div>
                    <div style="width:8rem;text-align:center">二季度</div>
                    <div style="width:8rem;text-align:center">三季度</div>
                    <div style="width:8rem;text-align:center">四季度</div>
                    <div style="width:8rem;text-align:center">四季度-春节</div>
                    <div style="width:8rem;text-align:center">全年合计</div>
                </div>
                <div style="height:20rem;overflow:auto;">
                    <div
                  v-for="(item, index) in tableData"
                  :key="index"
                  style="display:flex; height: 3rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:8rem;text-align:center">{{item.name}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.season1,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.season2,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.season3,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.season4,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.season5,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.total,2)}}</div>
                </div>
                </div>
            </div>
        </div>
      </wrapper>
    </el-dialog>
</div>
</template>
<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import {finance} from '../../../../api_finance';
  import wrapper from "../../../../components/wrap";
  export default {
    name:"CompanyCostCalendarChart",
    components:{
        'v-chart':ECharts,
        wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateChiefCaseChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateChiefCaseChart();
        }
      }
    },
    data(){
        return{
            chiefCaseChart:{},
            popupShow:false,
            tableData:[]
        }
    },
    mounted(){
        // const self = this
      const token = this.$route.query.token;
      window.token = token;
      
      this.updateChiefCaseChart();
    },
    methods:{
        toFixed(val,fat){
            return finance.formatDecimal(val,fat);
        },
        showDetail(){
            this.popupShow = true;
        },
        chiefCaseChartClick(){
            this.popupShow = true;
        },
        tableRowStyle({rowIndex}){
            if(rowIndex%2 === 0)
                return {backgroundColor:'#213a77',height: '0'}; 
            else   
                return {backgroundColor:'#102855',height: '0'}; 
        },
        tableHeaderColor(){
            return 'color:#4fb5da;background:#102855';
        },
        updateChiefCaseChart(){
            finance.queryCompanyCapitalSeasonly().then((res=>{
                // 格式化数据
                const tempData = [
                    {
                        "name":"到期票证/还款",
                        "season1":res[0].expireRefund,
                        "season2":res[1].expireRefund,
                        "season3":res[2].expireRefund,
                        "season4":res[3].expireRefund,
                        "season5":res[4].expireRefund,
                        "total":res[0].expireRefund+res[1].expireRefund+res[2].expireRefund+res[3].expireRefund+res[4].expireRefund,
                    },{
                        "name":"所得税",
                        "season1":res[0].incomeTax,
                        "season2":res[1].incomeTax,
                        "season3":res[2].incomeTax,
                        "season4":res[3].incomeTax,
                        "season5":res[4].incomeTax,
                        "total":res[0].incomeTax+res[1].incomeTax+res[2].incomeTax+res[3].incomeTax+res[4].incomeTax,
                    },{
                        "name":"上交收益",
                        "season1":res[0].yield,
                        "season2":res[1].yield,
                        "season3":res[2].yield,
                        "season4":res[3].yield,
                        "season5":res[4].yield,
                        "total":res[0].yield+res[1].yield+res[2].yield+res[3].yield+res[4].yield,
                    },{
                        "name":"总部管理费",
                        "season1":res[0].managerCost,
                        "season2":res[1].managerCost,
                        "season3":res[2].managerCost,
                        "season4":res[3].managerCost,
                        "season5":res[4].managerCost,
                        "total":res[0].managerCost+res[1].managerCost+res[2].managerCost+res[3].managerCost+res[4].managerCost,
                    },
                ];
                var season1Sum = 0;
                var season2Sum = 0;
                var season3Sum = 0;
                var season4Sum = 0;
                var season5Sum = 0;
                var totalSum = 0;
                for (let k = 0; k < tempData.length; k++) {
                    season1Sum += tempData[k].season1;
                    season2Sum += tempData[k].season2;
                    season3Sum += tempData[k].season3;
                    season4Sum += tempData[k].season4;
                    season5Sum += tempData[k].season5;
                    totalSum += tempData[k].total;
                }
                tempData.push({"name":"合计",
                        "season1":season1Sum,
                        "season2":season2Sum,
                        "season3":season3Sum,
                        "season4":season4Sum,
                        "season5":season5Sum,
                        "total":totalSum})
                this.tableData = tempData;
                const option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            lineStyle: {
                                color: '#57617B',
                            },
                        },
                    },
                    legend:{
                        textStyle:{
                            color:"#fff",
                            fontSize:10
                        }
                    },
                    xAxis: {
                        type: 'category',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#3585d5',
                            },
                        },
                        axisTick: {
                            show: false,
                            alignWithLabel: true,
                            lineStyle: {
                                color: '#3585d5',
                            },
                        },
                        axisLabel: {
                            color: 'white',
                            fontWeight: 'bold',
                            rotate: 40,
                            // formatter: value => truncateStr(value, 10),
                        },
                        data:[],
                    },
                    yAxis: {
                        name: '金额:亿',
                        nameTextStyle:{
                            color: '#A2D4E6',
                        },
                        type: 'value',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#3585d5',
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                type: 'dotted',
                                color: '#3585d5',
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            fontSize: 14,
                            color:'#fff'
                        },
                    },
                    series: [
                        {
                            name: "到期票证/还款",
                            type: 'bar',
                            stack: 'total',
                            smooth: true,
                            symbol: 'none',
                            showSymbol: false,
                            symbolSize: 8,
                            itemStyle: {
                                color: {
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    type: 'linear',
                                    global: false,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#FFC31F',
                                        },
                                        {
                                            offset: 1,
                                            color: '#FF5A1F',
                                        },
                                    ],
                                },
                            },
                            data: [],
                        },{
                            name: "所得税",
                            type: 'bar',
                            stack: 'total',
                            smooth: true,
                            symbol: 'none',
                            showSymbol: false,
                            symbolSize: 8,
                            itemStyle: {
                                color: {
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    type: 'linear',
                                    global: false,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#28D8E8',
                                        },
                                        {
                                            offset: 1,
                                            color: '#0489F0',
                                        },
                                    ],
                                },
                            },
                            data: [],
                        },{
                            name: "上交收益",
                            type: 'bar',
                            stack: 'total',
                            smooth: true,
                            symbol: 'none',
                            showSymbol: false,
                            symbolSize: 8,
                            itemStyle: {
                                color: {
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    type: 'linear',
                                    global: false,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#6FFF00',
                                        },
                                        {
                                            offset: 1,
                                            color: '#0489F0',
                                        },
                                    ],
                                },
                            },
                            data: [],
                        },{
                            name: "总部管理费",
                            type: 'bar',
                            stack: 'total',
                            smooth: true,
                            symbol: 'none',
                            showSymbol: false,
                            symbolSize: 8,
                            itemStyle: {
                                color: {
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    type: 'linear',
                                    global: false,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#9C58FF',
                                        },
                                        {
                                            offset: 1,
                                            color: '#0489F0',
                                        },
                                    ],
                                },
                            },
                            data: [],
                        }
                    ],
                }
                for (let i = 0; i < res.length; i++) {
                   switch(res[i].season){
                    case 1:
                        option.xAxis.data.push("一季度");
                        break;
                    case 2:
                        option.xAxis.data.push("二季度");
                        break;
                    case 3:
                        option.xAxis.data.push("三季度");
                        break;
                    case 4:
                        option.xAxis.data.push("四季度");
                        break;
                    case 5:
                        option.xAxis.data.push("四季度-春节");
                        break;
                   }
                   option.series[0].data.push(finance.formatToMyriadDecimal(res[i].expireRefund,2));
                   option.series[1].data.push(finance.formatToMyriadDecimal(res[i].incomeTax,2));
                   option.series[2].data.push(finance.formatToMyriadDecimal(res[i].yield,2));
                   option.series[3].data.push(finance.formatToMyriadDecimal(res[i].managerCost,2));
                }
                this.chiefCaseChart = option;
            }))
        }
    }

}
</script>