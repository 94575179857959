<template>
  <div id="attendance" class="global-bg">
    <div id="title">
      <div class="date">
        <div>{{dateStr}}</div>
        <div style="margin-left: 1rem;">{{timeStr}}</div>
        <div style="margin-left: 1rem;">{{weekdayStr}}</div>
      </div>
      <span class="project">{{$route.params.project_name ? decodeURIComponent($route.params.project_name) : '项目考勤'}}</span>
    </div>

    <div id="main">
      <div id="top">
        <div style="width: 27rem;display: flex;flex-direction: column;justify-content:center">
          <div style="display: flex">
            <div style="margin: 0.75rem">
              <item color="blue" :number="projectDetail.checkinCount" text="今日出勤">
                  <img src="../../assets/01-1.png" width="60px">
              </item>
            </div>
            <div style="margin: 0.75rem">
              <item color="purple" :number="projectDetail.hatCount" text="安全帽领取数">
                  <img src="../../assets/02-2.png" width="60px">
              </item>
            </div>
          </div>
          <div style="display: flex ">
            <div style="margin: 0.75rem">
              <item color="green" :number="`${parseInt(projectDetail.checkinCount / projectDetail.hatCount * 100)}%`" text="今日出勤率">
                  <img src="../../assets/03-3.png" width="60px">
              </item>
            </div>

            <div style="margin: 0.75rem">
              <item color="darkblue" :number="projectDetail.checkinOnSiteCount" text="当前在场">
                  <img src="../../assets/04-4.png" width="60px">
              </item>
            </div>
          </div>
        </div>
        <wrapper2 :width="24" :height="22" style="padding: 4rem 2rem 0 2rem;margin-left:2rem;">
          <s-title title="当前进场" />
          <div style="width: 18rem; margin-top: 2rem; overflow: scroll; display: flex; position: relative;">
            <div style="flex:1">
              <img v-if="!!currCheckinWorker.photo" :src="currCheckinWorker.files_host + currCheckinWorker.photo" style="width:100%;height:100%" />
              <img v-if="!currCheckinWorker.photo" src="../../assets/noPeople.png" style="width:100%;height:100%" />
            </div>
            <div style="flex:1;padding-left:1.5rem;font-weight:500">
              <div style="font-size:1.5em">{{currCheckinWorker.worker_name || '暂无进场'}}</div>
              <div style="margin-top:1.2rem;color:#adb4bc;min-height:1em;">{{currCheckinWorker.in_time || ''}}</div>
              <div style="margin-top:0.5rem;color:#adb4bc;min-height:1em;">{{currCheckinWorker.team_name || ''}}</div>
              <div style="width:6.4rem; height: 0.5rem; margin-top: 1.2rem; background: #152d58" />
              <div style="width:7rem; height: 0.5rem; margin-top: 0.6rem; background: #152d58" />
              <div style="width:7.6rem; height: 0.5rem; margin-top: 0.6rem; background: #152d58" />
              <div style="width:8.2rem; height: 0.5rem; margin-top: 0.6rem; background: #152d58" />
            </div>
          </div>
        </wrapper2>
        <wrapper3 :width="31" :height="22" style="padding: 4rem 2rem;margin-left:0.25rem;">
          <s-title title="当前在场人员" />
          <div style="width: 28rem; margin-top: 1.5rem; margin-left: -0.5rem; overflow: scroll; font-weight: 500; position:relative;">
            <div v-if="!!onSiteWorkerTypeInfo.length">
              <div style="display: flex; height: 2rem; align-items: center; padding: 0 2rem; color: #32dfd1; margin-bottom: 3px;">
                <div style="flex:0.5"></div>
                <div style="flex:3">工种</div>
                <div style="flex:1">人数</div>
              </div>
              <div style="height:10rem;overflow:auto;">
                <div v-for="(item, index) in onSiteWorkerTypeInfo" :key="item.worker_type" style="display:flex; height: 2rem; align-items: center; padding: 0 2rem" :style="{ background: index%2 ? '#111d43' : '#091337'}">
                  <div style="flex:0.5"></div>
                  <div style="flex:3">{{item.worker_type}}</div>
                  <div style="flex:1">{{item.wtcount}}</div>
                </div>
              </div>
            </div>
            <div v-if="!onSiteWorkerTypeInfo.length" style="margin:0.5rem 8rem">
              <img src="../../assets/noData.png" style="width:100%;height:100%" />
              <div style="text-align:center;font-size:1.1em;margin-top:1rem;color:#18b0a4;">当前暂无人员在场</div>
            </div>
          </div>
        </wrapper3>
        <wrapper3 :width="31" :height="22" style="padding: 4rem 2rem;margin-left:0.25rem;">
          <s-title title="当前人员区域分布" />
          <div style="width: 28rem; margin-top: 1.5rem; margin-left: -0.5rem; overflow: scroll; font-weight: 500; position:relative;">
            <div v-if="!!onSiteBuildingsInfo.length">
              <div style="display: flex; height: 2rem; align-items: center; padding: 0 2rem; color: #32dfd1; margin-bottom: 3px;">
                <div style="flex:0.5"></div>
                <div style="flex:3">区域</div>
                <div style="flex:1">人数</div>
              </div>
              <div style="height:10rem;overflow:auto;">
                <div v-for="(item, index) in onSiteBuildingsInfo" :key="item.build_name" style="display:flex; height: 2rem; align-items: center; padding: 0 2rem" :style="{ background: index%2 ? '#111d43' : '#091337'}">
                  <div style="flex:0.5"></div>
                  <div style="flex:3">{{item.build_name}}</div>
                  <div style="flex:1">{{item.count}}</div>
                </div>
              </div>
            </div>
            <div v-if="!onSiteBuildingsInfo.length" style="margin:0.5rem 8rem">
              <img src="../../assets/noData.png" style="width:100%;height:100%" />
              <div style="text-align:center;font-size:1.1em;margin-top:1rem;color:#18b0a4;">当前暂无人员在场</div>
            </div>
          </div>
        </wrapper3>
      </div>

      <div id="bottom">
        <div id="people-attendance" style="">
            <wrapper3 :width="56.5" :height="36" style="padding: 4rem 2rem 0 2rem">
              <s-title title="近七日出勤率"/>
              <v-chart :options="workerShowUpRatioChart" autoresize style="width: 50rem; height: 27rem; margin-left: 1.5rem; margin-top: 1rem;"/>
            </wrapper3>
        </div>
        <div id="people-warning-list" style="">
            <wrapper3 :width="56.5" :height="36" style="padding: 4rem 2rem 0 2rem">
              <s-title title="出勤工人工种统计"/>
              <v-chart :options="workerShowUpTypeChart" ref="workerShowUpTypeChart" @mouseover="workerShowUpTypeChartMouseOver" @mouseout="workerShowUpTypeChartMouseOut" autoresize style="width: 53.5rem; height: 27rem; margin-left: 0 margin-top: 1rem;"/>
            </wrapper3>
        </div>
      </div>
    </div>
    <div id="logo">
      <span>星璇项目管理系统</span>
      <img src="../../assets/logo.png" alt="" class="watermark">
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import wrapper2 from '../../components/wrap2'
  import wrapper3 from '../../components/wrap3'
  import smallTitle from '../../components/small-title2'
  import item from '../../components/item'
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { attendance } from '../../api'
  import { createWebSocket } from '../../../public/websocket'

  const wsUrl = process.env.VUE_APP_WS_KQ_URL;

  function pad0(s) {
    return s < 10 ? '0' + s : s;
  }

  const weekdays = ['日', '一', '二', '三', '四', '五', '六'];

  export default {
    name: 'attendance',
    components: {
      // wrapper,
      item,
      'v-chart': ECharts,
      's-title': smallTitle,
      'wrapper2': wrapper2,
      'wrapper3': wrapper3,
    },
    data () {
      return {
        projectId: '',
        projectName: '智慧工地管理系统',
        dateStr: '',
        timeStr: '',
        weekdayStr: '',
        workerShowUpRatioChart: {
          color: ['#43c59e', '#fb8111'],
          legend: {
            icon: 'circle',
            itemHeight: 18,
            itemGap: 40,
            textStyle: {
              color: 'white',
              fontWeight: 'bold',
              fontSize: '18'
            },
            data: ['考勤人数', '考勤率']
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter(data){
              return data.map((data) => {
                if (data.seriesName === '考勤率') {
                  return `</br> ${data.marker} ${data.seriesName}: ${data.data}%`
                } else {
                  return `${data.name}</br> ${data.marker} ${data.seriesName}: ${data.data}`
                }
              }).join('');
            }
          },
          grid: {
            left: 0,
            right: 0,
            top: '9%',
            bottom: 0,
            containLabel: true
          },
          yAxis: [{
            type: 'value',
            show: true,
            axisLine: { show: true },
            splitLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false }
          },{
            type: 'value',
            show: true,
            max: 100,
            axisLine: { show: true },
            splitLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false }
          }],
          xAxis: {
            type: 'category',
            data: [],
            axisLabel: {
              color: '#8b8e9d',
              fontWeight: 'bold',
              fontSize: 16
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true
            }
          },
          series: [
            {
              name: '考勤人数',
              type: 'bar',
              yAxisIndex: 0,
              barMaxWidth: 20,
              data: []
            },
            {
              name: '考勤率',
              type: 'line',
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 4
                  }
                }
              },
              symbol: 'circle',
              symbolSize: 12,
              data: []
            },
          ]
        },
        workerShowUpTypeChart: {
          title: {
            text: '',
            subtext: '',
            x: 'center'
          },
          /*
          tooltip: {
            trigger: 'item',
            formatter: "{c} ({d}%)<br/>{b}",
            align: 'center'
          },
          */
          legend: {
            orient: 'vertical',
            left: '53%',  //图例距离左的距离
            height: '80%',
            y: 'center',  //图例上下居中
            symbol: 'rect',
            itemWidth: 15,
            itemHeight: 15,
            itemGap: 20,
            formatter: null,
            textStyle: {
                rich: {
                    a: {
                        color: '#878b94',
                        fontSize: 20,
                    },
                    b: {
                        color: '#ffffff',
                        fontSize: 20,
                    }
                }
            },
            data: []
          },
          color: ['#47C49D', '#47C0C4', '#4792C4', '#4771C4', '#6447C4', '#CC783B', '#CDAE3C', '#ED6279', '#D8ED61', '#8261ED', '#E99E5D', '#DF8FB5', '#6D90E9', '#589150'],
          series: [
            {
              name: '',
              type: 'pie',
              radius: ['50%', '85%'],  //图的大小
              center: ['25%', '53%'], //图的位置，距离左跟上的位置
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 22,
                  fontWeight: 'bold',
                  formatter: "{c} ({d}%)\n\n{b}",
                  align: 'center'
                },
              },
              data:[]
            }
          ]
        },
        projectDetail: {
          hatCount: 0,
          checkinCount: 0,
          checkinOnSiteCount: 0,
        },
        onSiteWorkerTypeInfo: [],
        onSiteBuildingsInfo: [],
        currCheckinWorker: {},
        timer: null,
        workerShowUpTypeChartHighlightIndex: 0,
      }
    },
    mounted() {
      this.projectId = this.$route.params.id;
      this.updateNowStr(new Date());
      this.updateProjectDetail();
      this.updateOnSiteWorkerTypeInfo();
      this.updateOnSiteBuildingsInfo();
      this.updateSevenDayAttendance();
      this.updateWorkerTypeInfo();
      this.timer = setInterval(() => {
        const now = new Date();
        // 每秒更新时间显示
        this.updateNowStr(now);

        // 定时更新数据
        const secs = parseInt(now / 1000);
        if (secs % 10 === 0) {
          this.updateOnSiteBuildingsInfo();
        }
        if (secs % 30 === 0) {
          this.updateWorkerTypeInfo();
          this.updateSevenDayAttendance();
        }
        if (secs % 300 === 0) {
          this.updateProjectDetail();
        }
      }, 1000);
      createWebSocket(wsUrl, this.projectId, message => {
        if (message && message.errcode === 0) {
          if (message.source == "customer_change") {
            const data = message.data;
            this.projectDetail.checkinCount = data.checkin_count;
            this.projectDetail.checkinOnSiteCount = data.checkin_on_site_count;
            this.onSiteWorkerTypeInfo = data.in_team_list;
            if (data.type === 1) {
              this.currCheckinWorker = data.worker_detail;
            }
          }
        }
      });
    },
    methods: {
      updateNowStr(now) {
        this.dateStr = `${now.getFullYear()}-${pad0(now.getMonth() + 1)}-${pad0(now.getDate())}`;
        this.timeStr = `${pad0(now.getHours())}:${pad0(now.getMinutes())}:${pad0(now.getSeconds())}`;
        this.weekdayStr = '星期' + weekdays[now.getDay()];
      },
      updateProjectDetail() {
        attendance.getProjectDetail(this.projectId).then(d => {
          this.projectDetail.hatCount = d.hat_count;
          this.projectDetail.checkinCount = d.checkin_count;
          this.projectDetail.checkinOnSiteCount = d.checkin_on_site_count;
        });
      },
      updateOnSiteWorkerTypeInfo() {
        attendance.getWorkerTypeInfo(this.projectId, 1).then(d => {
          this.onSiteWorkerTypeInfo = d;
        });
      },
      updateOnSiteBuildingsInfo() {
        attendance.getOnSiteBuildingsInfo(this.projectId).then(d => {
          this.onSiteBuildingsInfo = d;
        });
      },
      updateSevenDayAttendance() {
        attendance.getSevenDayAttendance(this.projectId).then(d => {
          if (d.length) {
            this.workerShowUpRatioChart.yAxis[0].max = Math.round(d[0].attendacne_count / d[0].rate * 100);
          }
          this.workerShowUpRatioChart.xAxis.data = d.map(x => x.date);
          this.workerShowUpRatioChart.series[0].data = d.map(x => x.attendacne_count);
          this.workerShowUpRatioChart.series[1].data = d.map(x => parseInt(x.rate));
        });
      },
      updateWorkerTypeInfo() {
        attendance.getWorkerTypeInfo(this.projectId).then(d => {
          const numPerColumn = Math.floor((this.$refs.workerShowUpTypeChart.height + 25) / 50) || 1;
          const maxEntries = Math.min(numPerColumn * 2, 14);
          const workerTypeMap = {};
          let maxTypeLen = 0;
          let otherCt = 0;
          for (let i = 0; i < d.length; i++) {
            if (d.length > maxEntries && i >= maxEntries - 1) {
              otherCt += d[i].wtcount;
            } else {
              workerTypeMap[d[i].worker_type] = d[i].wtcount;
              if (d[i].worker_type.length > maxTypeLen) {
                maxTypeLen = d[i].worker_type.length;
              }
            }
          }
          if (otherCt) {
            workerTypeMap['其它'] = otherCt;
            d = d.slice(0, maxEntries - 1);
            d.push({
              worker_type: '其它',
              wtcount: otherCt
            });
            if (maxTypeLen < 2) {
              maxTypeLen = 2;
            }
          }

          this.workerShowUpTypeChart.legend.left = d.length > numPerColumn ? '53%' : '63%';
          this.workerShowUpTypeChart.legend.data = d.map(x => x.worker_type);
          this.workerShowUpTypeChart.legend.formatter = (name) => {
            let fillStr = '';
            for (let i = 0; i < maxTypeLen + 2 - name.length; i++) {
              fillStr += '\u3000  ';
            }
            if (d.length <= numPerColumn) {
              for (let i = 0; i < 4; i++) {
                fillStr += '\u3000';
              }
            }
            return `{a|${name}}` + fillStr + `{b|${workerTypeMap[name]}}`;
          };
          this.workerShowUpTypeChart.series[0].data = d.map(x => ({
            name: x.worker_type,
            value: x.wtcount
          }));
          setTimeout(
            () =>
              this.$refs.workerShowUpTypeChart &&
              this.$refs.workerShowUpTypeChart.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: this.workerShowUpTypeChartHighlightIndex
              }),
            1000
          )
        });
      },
      workerShowUpTypeChartMouseOver(params) {
        if (params.dataIndex != this.workerShowUpTypeChartHighlightIndex) {
          this.$refs.workerShowUpTypeChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.workerShowUpTypeChartHighlightIndex
          });
          this.workerShowUpTypeChartHighlightIndex = params.dataIndex;
        }
      },
      workerShowUpTypeChartMouseOut() {
        this.$refs.workerShowUpTypeChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.workerShowUpTypeChartHighlightIndex
        });
      },
    },
    beforeDestroy () {
      if (this.timer) {
        clearInterval(this.timer);
      }
    }
  }
</script>

<style scoped lang="less">
#attendance {
  font-family:Tahoma,Verdana,STHeiTi,simsun,sans-serif;
}
#title {
  line-height: 6rem;
  height: 6rem;
  margin-bottom: -4rem;
  background: url("../../assets/up.png") no-repeat transparent;
  background-size: contain;
  position: relative;
}
.date{
  display: flex;
  position: absolute;
  font-size: 1.2rem;
  font-weight: 500;
  top: -25%;
  left: 3%;
}
.project{
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#main {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 5rem 0;
  height: calc(100vh - 2rem);
  overflow-y: scroll;

  #top {
    display: flex;
    height: 22rem;
    margin-top: 1.5rem;
    justify-content: space-between;
  }

  #bottom {
    display: flex;
    height: 30rem;
    // margin-top: 2rem;
    justify-content: space-between;
    #equipment-status {
      .content {
        background: url("../../assets/shebei.png") no-repeat 4px 8px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 90px;
        height: 5.375rem;
        font-size: 0.875rem;

        p {
          line-height: 1.5rem;
        }
      }
    }

    #people-status {
      .content {
        display: flex;
        padding: 1rem 0 .5rem;

        .left {
          width: 45%;
          text-align: center;

          .score {
            padding: 0.4rem 0;
            font-size: 1.875rem;
            color: #1fc86b;
          }

          .name {
            font-size: 0.875rem;
          }
        }

        .right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          font-size: 0.875rem;
        }
      }
    }
  }

}

#logo {
  position: absolute;
  right: -1rem;
  bottom: 1.5rem;
  // align-items: center;
  line-height: 1.5rem;
  .watermark{
    vertical-align:text-bottom;
    width:24%;
    height:16%
  }
  span {
    font-size: 0.85rem;
    font-weight: bold;
    color: rgba(255, 255, 255, .6);
    margin-right: 1rem;
  }
}
</style>
