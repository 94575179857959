<template>
  <div id="left">
    <managerStatucBarChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <sailingTalentBarChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" :org-options="orgOptions" />
    <reserveTalentBarChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
  </div>
</template>

<script>
  import managerStatucBarChart from "./managerStatucBarChart";
  import sailingTalentBarChart from "./sailingTalentBarChart";
  import reserveTalentBarChart from "./reserveTalentBarChart";

  export default {
    name: 'HrIndexLeftBar',
    components: {
      managerStatucBarChart,
      sailingTalentBarChart,
      reserveTalentBarChart,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
  }
</script>

<style lang="less">
</style>
