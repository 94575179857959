<template>
  <div id = "left">
    <div class="info-block" style="min-height:5rem">
    <div class="info-block-title"  style="display:flex;width:100%;padding-right:3.1rem">
      <div style = "flex:1">累计净流/收益</div>
      <div class="detail" style="cursor:pointer" @click="showDetail()">详情</div>
    </div>
    <div style="height:20rem;padding-top:3rem">
      <!-- 总部 -->
      <div v-if="isHead" style="display:flex">
        <table style="width:400px;height:15rem;font-size:1rem" rules=rows>
          <tr>
            <td style="text-align:center">净流</td>
            <td><span class="linetitle2">截至年初累计</span><p><span class="value1">{{toFixed(subData.byAccNetCapitalFlow,2)}}</span>亿</p></td>
            <td><span class="linetitle2">本年</span><p><span class="value2">{{toFixed(subData.netFlowAmount,1)}}</span>亿</p></td>
            <td><span class="linetitle2">未来</span><p><span class="value3">{{toFixed(subData.futNetFlow,1)}}</span>亿</p></td>
          </tr>
          <tr>
            <td style="text-align:center">收益</td>
            <td><span class="linetitle2">截至年初累计</span><p><span class="value1">{{toFixed(subData.byAccBranchIncome,1)}}</span>亿</p></td>
            <td><span class="linetitle2">本年</span><p><span class="value2">{{toFixed(subData.curIncome,1)}}</span>亿</p></td>
            <td><span class="linetitle2">未来</span><p><span class="value3">{{toFixed(subData.futIncome,1)}}</span>亿</p></td>
          </tr>
        </table>
      </div>
      <!--分公司 -->
      <div v-if="!isHead" style="display:flex">
        <table style="width:400px;height:15rem;font-size:1rem"  rules=rows>
          <tr>
            <td style="text-align:center">净流</td>
            <td><span class="linetitle2">截至年初累计</span><p><span class="value1">{{toFixed(subData.byAccNetCapitalFlow,2)}}</span>亿</p></td>
            <td><span class="linetitle2">本年</span><p><span class="value2">{{toFixed(subData.netFlowAmount,1)}}</span>亿</p></td>
            <td><span class="linetitle2">未来</span><p><span class="value3">{{toFixed(subData.futNetFlow,1)}}</span>亿</p></td>
          </tr>
          <tr>
            <td style="text-align:center">收益</td>
            <td><span class="linetitle2">截至年初累计</span><p><span class="value1">{{toFixed(subData.byAccBranchIncome,1)}}</span>亿</p></td>
            <td><span class="linetitle2">本年</span><p><span class="value2">{{toFixed(subData.curIncome,1)}}</span>亿</p></td>
            <td><span class="linetitle2">未来</span><p><span class="value3">{{toFixed(subData.futIncome,1)}}</span>亿</p></td>
          </tr>
          <tr>
            <td style="text-align:center">现金上交收益</td>
            <td><span class="linetitle2">截至年初累计</span><p><span class="value1">{{toFixed(subData.byAccPaidIncomeByCash,1)}}</span>亿</p></td>
            <td><span class="linetitle2">本年</span><p><span class="value2">{{toFixed(subData.paidCashIncome,1)}}</span>亿</p></td>
            <td><span class="linetitle2">未来</span><p><span class="value3">{{toFixed(subData.furIncomePaidByCash,1)}}</span>亿</p></td>
          </tr>
        </table>
      </div>
    </div>
    <!-- 分公司净流详情 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 5rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:8rem;text-align:center">分公司</div>
                <div style="width:10rem;text-align:center">考核分类机构</div>
                <div style="width:8rem;text-align:center"><a style="color:#4fb5da;text-decoration: none"  href="#" title = "给分公司剔除了一些合并导致的垫资额,给分公司减轻包袱.以安徽分为例,肥京商为分公司成立前项目,垫资3.9亿,如果不剔除此项目,安徽分累计净流为-5.79,考虑分公司实际情况,把3.9亿垫资给剔除了,其他分公司类似">公司承担垫资</a></div>
                <div style="width:8rem;text-align:center"><a style="color:#4fb5da;text-decoration: none"  href="#" title = "自分公司成立以来所有项目收款-支付-分公司管理费。此指标为负，说明分公司整体是垫资，入不敷出。注：办理信用证当月即算支出，已扣除，到期偿还时不再重复扣">截至年初累计资金净流</a></div>
                <div style="width:8rem;text-align:center"><a style="color:#4fb5da;text-decoration: none"  href="#" title = "监管户、农民工工资户、抵入未抵出房产余额">年初受限资金</a></div>
                <div style="width:8rem;text-align:center"><a style="color:#4fb5da;text-decoration: none"  href="#" title = "可上存资金就是截至年初累计资金净流减去年初受限资金。
此指标可理解为银行活期资金，使用灵活，这部分优先交公司收益，如交完收益后有剩余可以作为分公司存款也可以请款使用。
比如南方分，资金净流为21.4亿，受限资金0.7亿，那么可上存资金为20.7亿，上交完公司收益10.9亿后还有9.8可用，9.8亿可作为定存也可以请款">截止年初累计剩余可上存资金</a></div>
                <div style="width:8rem;text-align:center"><a style="color:#4fb5da;text-decoration: none"  href="#" title = "自分公司成立以来所有项目累计账面收入*实际收益率，实际收益率是项目预计的项目整体结束后可获得的收益率，总部商务管理部审核。">截止年初项目累计收益</a></div>
                <div style="width:8rem;text-align:center"><a style="color:#4fb5da;text-decoration: none"  href="#" title = "从分公司成立以来每年管理费之和">截止年初分公司累计管理费</a></div>
                <div style="width:8rem;text-align:center"><a style="color:#4fb5da;text-decoration: none"  href="#" title = "自分公司成立以来所有项目累计账面收入*实际收益率-分公司累计管理费">截止年初分公司累计收益</a></div>
                <div style="width:8rem;text-align:center"><a style="color:#4fb5da;text-decoration: none"  href="#" title = "自分公司成立以来上缴给公司的钱
注：上缴后分公司以后年度再不可以用这部分钱，以江苏分为例，累计净流6.95亿，累计上缴收益6.14亿，则分公司剩余可用为0.81亿（即6.95减去6.14），也就是分公司自今年春节后回来，分公司只有0.81亿可请款，已经缴纳的6.14亿不可以再请回。">截止年初累计现金形式上交收益</a></div>
                <div style="width:8rem;text-align:center"><a style="color:#4fb5da;text-decoration: none"  href="#" title = "本年收款-支出-管理费">本年净流</a></div>
                <div style="width:8rem;text-align:center"><a style="color:#4fb5da;text-decoration: none"  href="#" title = "本年账面收入*实际收益率">本年收益</a></div>
                <div style="width:8rem;text-align:center"><a style="color:#4fb5da;text-decoration: none"  href="#" title = "根据分公司资金余额与收益情况，与分公司确认上交的本年收益">本年已交现金收益</a></div>
                <div style="width:8rem;text-align:center"><a style="color:#4fb5da;text-decoration: none"  href="#" title = "项目目前开始到竣工结算期间还会产生的净流。比如，一个项目工期5年，目前已经干了3年，未来净流就是剩下的2年产生的净流。项目最终结束后，项目总收益就等于项目总净流。分公司未来净流，就是分公司所有项目预计总净流减去截止目前累计资金净流。">未来净流</a></div>
                <div style="width:8rem;text-align:center"><a style="color:#4fb5da;text-decoration: none"  href="#" title = "分公司未来收益与未来净流期间一致，就是分公司所有项目预计总收益减去截止目前分公司累计收益。">未来收益</a></div>
                <div style="width:8rem;text-align:center"><a style="color:#4fb5da;text-decoration: none"  href="#" title = "项目总收益减去累计已上交收益">未来现金形式上交收益</a></div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popProjectsList"
                  :key="index"
                  style="display:flex; height: 4rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:8rem;text-align:center">{{item.branchName}}</div>
                  <div style="width:10rem;text-align:center">{{item.assessUnit}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.companyPayIn,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.byAccNetCapitalFlow,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.byLimitedCapital,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.byAccCanDepositCapital,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.byAccProjectIncome,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.byAccBranchManageFee,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.byAccBranchIncome,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.byAccPaidIncomeByCash,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.netFlowAmount,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.curIncome,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.paidCashIncome,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.futNetFlow,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.futIncome,2)}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(item.furIncomePaidByCash,2)}}</div>
                </div>
                <div
                  v-if="!(popProjectsList && popProjectsList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>
        </div>
      </wrapper>
    </el-dialog>
    <!-- 总部净流详情 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow2=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
                <el-table style="background-color:#0E1328" height="28rem" :data="tableData" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor" show-summary :summary-method="getSummaries">
                    <el-table-column
                        prop="branchName"
                        label="分公司">
                        </el-table-column>
                    <el-table-column
                        prop="companyPayIn"
                        label="公司承担垫资"
                        :formatter="Formatter">
                        <template slot-scope="scope" slot="header">
                            <span>
                                公司承担垫资
                            <el-tooltip :aa="scope" class="item" effect="dark" content="给分公司剔除了一些合并导致的垫资额，给分公司减轻“包袱”。以安徽分为例，合肥京商为分公司成立前项目，垫资3.9亿，如果不剔除此项目，安徽分累计净流为-5.79，考虑分公司实际情况，把3.9亿垫资给剔除了，其他分公司类似" placement="top-start">
                                // class指定icon图标 style可以调图标颜色等样式
                                <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                            </el-tooltip>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="byAccNetCapitalFlow"
                    label="截至年初累计资金净流"
                    :formatter="Formatter">
                        <template slot-scope="scope" slot="header">
                            <span>
                                截至年初累计资金净流
                            <el-tooltip :aa="scope" class="item" effect="dark" content="自分公司成立以来所有项目收款-支付-分公司管理费。此指标为负，说明分公司整体是垫资，入不敷出。注：办理信用证当月即算支出，已扣除，到期偿还时不再重复扣" placement="top-start">
                                // class指定icon图标 style可以调图标颜色等样式
                                <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                            </el-tooltip>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="byAccBranchIncome"
                        label="截至年初分公司累计收益"
                        :formatter="Formatter">
                        <template slot-scope="scope" slot="header">
                            <span>
                                截至年初分公司累计收益
                            <el-tooltip :aa="scope" class="item" effect="dark" content="自分公司成立以来所有项目到年初累计账面收入*实际收益率-分公司累计管理费" placement="top-start">
                                // class指定icon图标 style可以调图标颜色等样式
                                <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                            </el-tooltip>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="byAccPaidIncomeByCash"
                        label="截至年初累计现金形式上交收益"
                        :formatter="Formatter">
                        <template slot-scope="scope" slot="header">
                                <span>
                                截至年初累计现金形式上交收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="自分公司成立以来上缴给公司的钱
注：上缴后分公司以后年度再不可以用这部分钱，以江苏分为例，累计净流6.95亿，累计上缴收益6.14亿，则分公司剩余可用为0.81亿（即6.95减去6.14），也就是分公司自今年春节后回来，分公司只有0.81亿可请款，已经缴纳的6.14亿不可以再请回。" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                      </el-table-column>
                      <el-table-column
                        prop="byUnpaidIncome"
                        label="截至年初欠交公司收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                截至年初欠交公司收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="从有现金形式上缴收益考核（2020年）开始每年预算数之和-每年实际已上缴数之和" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    <el-table-column label="截至年初资金余额（交收益后）">
                        <el-table-column
                        prop="byBalanceAmount"
                        label="合计"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                合计
                                <el-tooltip :aa="scope" class="item" effect="dark" content="在公司存/借款减去欠交收益加上受限资金" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceDeposit"
                        label="其中：公司存/借款"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：公司存/借款
                                <el-tooltip :aa="scope" class="item" effect="dark" content="分公司截止年初可上存资金减去累计上交收益余额。正数表示在公司存款，负数表示欠公司钱" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceUnpaid"
                        label="其中：欠交公司收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：欠交公司收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="从有现金形式上缴收益考核（2020年）开始每年预算数之和-每年实际已上缴数之和" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceLimited"
                        label="其中：受限资金"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：受限资金
                                <el-tooltip :aa="scope" class="item" effect="dark" content="监管户、农民工工资户、抵入未抵出房产余额" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                    prop="netFlowAmount"
                    label="本年净流"
                    :formatter="Formatter">
                        <template slot-scope="scope" slot="header">
                            <span>
                                本年净流
                            <el-tooltip :aa="scope" class="item" effect="dark" content="本年收款-支出-管理费" placement="top-start">
                                // class指定icon图标 style可以调图标颜色等样式
                                <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                            </el-tooltip>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="curIncome"
                    label="本年收益"
                    :formatter="Formatter">
                        <template slot-scope="scope" slot="header">
                            <span>
                                本年收益
                            <el-tooltip :aa="scope" class="item" effect="dark" content="本年账面收入*实际收益率" placement="top-start">
                                // class指定icon图标 style可以调图标颜色等样式
                                <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                            </el-tooltip>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="paidCashIncome"
                    label="本年已交现金收益"
                    :formatter="Formatter">
                        <template slot-scope="scope" slot="header">
                            <span>
                                本年已交现金收益
                            <el-tooltip :aa="scope" class="item" effect="dark" content="根据分公司资金余额与收益情况，与分公司确认上交的本年收益" placement="top-start">
                                // class指定icon图标 style可以调图标颜色等样式
                                <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                            </el-tooltip>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="futNetFlow"
                    label="未来净流"
                    :formatter="Formatter">
                        <template slot-scope="scope" slot="header">
                            <span>
                                未来净流
                            <el-tooltip :aa="scope" class="item" effect="dark" content="项目目前开始到竣工结算期间还会产生的净流。比如，一个项目工期5年，目前已经干了3年，未来净流就是剩下的2年产生的净流。项目最终结束后，项目总收益就等于项目总净流。分公司未来净流，就是分公司所有项目预计总净流减去截止目前累计资金净流。" placement="top-start">
                                // class指定icon图标 style可以调图标颜色等样式
                                <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                            </el-tooltip>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="futIncome"
                    label="未来收益"
                    :formatter="Formatter">
                        <template slot-scope="scope" slot="header">
                            <span>
                                未来收益
                            <el-tooltip :aa="scope" class="item" effect="dark" content="分公司未来收益与未来净流期间一致，就是分公司所有项目预计总收益减去截止目前分公司累计收益。" placement="top-start">
                                // class指定icon图标 style可以调图标颜色等样式
                                <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                            </el-tooltip>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
  </div>
</template>

<script>
  require('echarts-gl')
  // import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { finance } from '../../../../api_finance'
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'CumulativeNetFlowChart',
    components: {
      wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if(window.$orgName == "中建一局集团第一建筑有限公司"){
          this.isHead= true;
        }else{
          this.isHead= false;
        }
        if (newValue != oldValue) {
          this.updateChart();
        }
      }
    },
    data () {
      return {
        dangerLevelsChart: {},
        subData:{},
        popupShow:false,
        popupShow2:false,
        popProjectsList:[],
        isHead:true,
        tableData:[]
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.updateChart();
    },
    methods: {
      getSummaries(param){
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '合计';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = finance.formatDecimal(sums[index],2);
          } else {
            sums[index] = 'N/A';
          }
        });

        return sums;
      },
       Formatter(row, column, cellValue){
            return finance.formatDecimal(cellValue,2);
        },
      tableRowStyle({rowIndex}){
        if(rowIndex%2 === 0)
            return {backgroundColor:'#213a77',height: '0'}; 
        else   
            return {backgroundColor:'#102855',height: '0'}; 
      },
      tableHeaderColor(){
        return 'color:#4fb5da;background:#102855';
      },
      showDetail(){
        if(!this.isHead){
          this.popupShow = true;
          finance.queryBranchCapitalStatisticLine().then((res=>{
            this.popProjectsList = res;
          }))
        }else{
          this.popupShow2 = true;
        }
      },
      toFixed(value,ptn){
        return finance.formatDecimal(value,ptn);
      },
      updateChart() {
        if(this.isHead){
          finance.queryAllBranchCapitalSum().then((d=>{
            this.subData = d;
          }));
          finance.queryBranchCapitalStatisticSum().then((res)=>{
            this.tableData = res;
          })
        }else{
          finance.queryBranchCapitalStatisticSum().then((res)=>{
            this.subData = res[0];
          })
        }
      },
    },
  }
</script>

<style lang="less">
div > dd{
  margin-top:1rem;
}
.head{
  margin-top:2.5rem;
}
.value1{
  font-size:2rem;
  font-weight:400 ;
  color:#F4EA27
}
.value2{
  font-size:2rem;
  font-weight:400 ;
  color:#779DFA
}
.value3{
  font-size:1.5rem;
  font-weight:400 ;
  color:#CB4F43
}
.linetitle2{
  font-size: 1rem;
  color:#ACACAC
}
</style>
