<template>
  <div class="__changer" v-show="showComponent">
    <transition name="building-list">
      <ul class="__changer__ul" :class="[showList ? 'show': '__changer_hide']">
        <li v-for="b in listData" :key="b.innerid" :style="mergedCellStyle"
            :class="[b.innerid === selectedItemID ? 'active' : '']"
            class="__changer__li"
            @click="clickListItem(b)">{{b.name}}
        </li>
        <li style="height: 1.75rem"></li>
      </ul>
    </transition>
    <div class="__changer__button" :style="mergedCellStyle" @click="showList = !showList">{{(listData.find(b => b.innerid ===
      selectedItemID) ||
      {}).name}}
    </div>
  </div>
</template>

<script>

  export default {
    name: 'FloorChanger',
    props: {
      listData: {
        type: Array,
        validator (list) {
          return list.every(t => t.name !== undefined && t.innerid !== undefined)
        },
        required: true,
      },
      checkLength: Boolean,
      initId: {
        type: String,
      },
      cellStyle: Object,
    },
    data () {
      return {
        showList: false,
        selectedItemID: this.initId || this.listData[0].innerid
      }
    },
    mounted () {
      const self = this
      document.addEventListener('click', function (e) {
        if (!e.target.classList.contains('__changer__button') && !e.target.classList.contains('__changer__li')) {
          self.showList = false
        }
      })
      document.querySelector('#iFrame').contentDocument.addEventListener('click', function () {
        self.showList = false
      })
    },
    computed: {
      showComponent () {
        if (this.checkLength) {
          return this.listData.length > 1
        } else {
          return this.listData.length
        }
      },
      // selectedItemID: {
      //   get () {
      //     return this.initId || this.listData[0].innerid
      //   },
      //   set (val) {
      //     return val
      //   }
      // },
      mergedCellStyle () {
        return { ...{ width: '7.6rem', height: '3.5rem', lineHeight: '3.5rem' }, ...this.cellStyle }
      }
    },
    methods: {
      clickListItem (item) {
        this.selectedItemID = item.innerid
        this.showList = false
        this.$emit('change', item)
      },

    }
  }
</script>

<style scoped lang="less">
  .building-list-enter-active {
    animation: trans-max-height 0.5s;
  }

  .building-list-leave-active, .building-list-leave-to {
    animation: trans-max-height 0.8s reverse;
  }

  @keyframes trans-max-height {
    0% {
      max-height: 0;
    }
    50% {
      max-height: 0.8rem;
    }
    100% {
      max-height: 15rem;
    }
  }

  .__changer {
    text-align: center;
    position: relative;

    .__changer__button {
      box-shadow: 0 0 24px 0 #21c1f5;
      background: rgba(8, 18, 43, 1);
      font-size: 0.9rem;
      cursor: pointer;
      color: rgba(90, 204, 241, 1);
      margin-left:10px;
    }

    .__changer__ul {
      z-index: -1;
      max-height: 15rem;
      position: absolute;
      transition: max-height 0.5s;
      border: 0.06rem solid #5F7EC9;
      bottom: 1.5rem;
      padding-top: 0.8rem;
      overflow-y: scroll;
      background: rgba(8, 18, 43, 1);
      border-radius: 5px;
      margin-left:10px;


      &::-webkit-scrollbar {
        width: 0;
      }

      .__changer__li {
        cursor: pointer;

        &.active {
          color: rgba(90, 204, 241, 1);
        }
      }
    }

    .__changer_hide {
      max-height: 0;
    }
  }
</style>
