<template>
  <div id="left">
    <wrapper style="padding:0">

      <div
        class="info-block"
        style="min-height:5rem"
      >
        <div
          class="info-block-title"
          style="display: flex;justify-content: space-between;align-items:center;width: 85%"
        >
          <div>{{check_type==1?'报警':'预警'}}情况</div>
          <div style="display: flex;">
            <div style="display:flex;align-items:center;margin-right:1rem">
              <div
                class="option-block2"
                style="min-width: 4rem;"
                :class="{ 'option-block-selected2': check_type == 2 }"
                @click="() => getSafetyOrgCityWarningAlarmCount(2)"
              >预警</div>
              <div
                class="option-block2"
                style="min-width: 4rem;"
                :class="{ 'option-block-selected2': check_type == 1 }"
                @click="() => getSafetyOrgCityWarningAlarmCount(1)"
              >报警</div>
            </div>
            <div
              class="detail"
              @click="showDetail()"
            >
              详情
            </div>
          </div>
        </div>
        <div style="height:16rem;padding-top:3rem">
          <div
            v-if="SafetyDay7WarningAlarm.series && [].concat.apply([], SafetyDay7WarningAlarm.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart
            v-else
            :options="SafetyDay7WarningAlarm"
            autoresize
          />
        </div>
      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem;padding:0">
      <!-- <div style="font-size:1em">安全行为之星</div>
      <div style="height:14rem;margin:0.5rem 0;padding:0;display:flex;flex-direction:column;font-size:0.7rem;overflow:hidden">
        <div
          v-for="(star, index) in securityStarList"
          :key="star.innerid"
          :style="{ background: index%2 ? '#1b2d5d' : '#152554', height: '1.6rem', 'line-height': '1.6rem', display: 'flex', justifyContent: 'space-between' }"
        >
          <div style="flex:1;margin:0 0.5rem">{{index + 1}}</div>
          <div style="flex:4;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{star.name}}</div>
          <div style="flex:6;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{star.team_name}}</div>
          <div style="flex:2;margin-right:0.5rem;text-align:right"><a
              @click="() => showWorkerInfo(star.innerid)"
              style="color:#48b6e2;text-decoration:underline;cursor:pointer"
            >{{star.total_source}}分</a></div>
        </div>
      </div>
      <div style="width:100%;text-align:right"><a
          @click="showMoreStars"
          style="font-size:0.9rem;color:#48b6e2;text-decoration:underline;cursor:pointer"
        >更多>></a></div> -->

      <div
        class="info-block"
        style="min-height:5rem"
      >
        <div
          class="info-block-title"
          style="display: flex;justify-content: space-between;align-items:center;width: 85%"
        >
          <div>安全行为之星</div>

        </div>
        <div style="height:18rem;padding-top:2.5rem">
          <div
            v-if="!securityStarList.length"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem; margin-top:1rem"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <div
            v-else
            style="height:14rem;padding:0;display:flex;flex-direction:column;font-size:0.7rem;overflow:hidden"
          >
            <div
              v-for="(star, index) in securityStarList"
              :key="star.innerid"
              :style="{ background: index%2 ? '#1b2d5d' : '#152554', height: '1.9rem', 'line-height': '1.6rem', display: 'flex', justifyContent: 'space-between',padding:'0 1rem' }"
            >
              <div style="flex:1;margin:0 0.5rem">{{index + 1}}</div>
              <div style="flex:4;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{star.worker_name}}</div>
              <div style="flex:6;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{star.team_name}}</div>
              <div style="flex:2;margin-right:0.5rem;text-align:right"><a
                  @click="() => showWorkerInfo(star.worker_id)"
                  style="color:#48b6e2;text-decoration:underline;cursor:pointer"
                >{{star.total_score}}分</a></div>
            </div>
          </div>
          <div
            v-if="securityStarList.length"
            style="width:100%;text-align:right"
          ><a
              @click="showMoreStars"
              style="font-size:0.9rem;color:#48b6e2;text-decoration:underline;cursor:pointer"
            >更多>></a></div>
        </div>
      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem;padding:0">
      <!-- <div style="font-size:1em">今日劳务进出场记录</div>
      <div style="height:14rem;margin:0.5rem 0;padding:0;display:flex;flex-direction:column;font-size:0.7rem;overflow:hidden">
        <div
          v-for="(worker, index) in workerInOutList"
          :key="worker.worker_id + worker.check_time"
          :style="{ background: index%2 ? '#1b2d5d' : '#152554', height: '1.6rem', 'line-height': '1.6rem', display: 'flex', justifyContent: 'space-between' }"
        >
          <div style="flex:2;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{worker.worker_name}}</div>
          <div style="flex:3;text-align:center;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{worker.team_name}}</div>
          <div :style="{flex:4, marginRight:'0.5rem', textAlign:'right', color: worker.type == 1 ? '#20ae53' : '#ba440d'}">{{worker.type == 1 ? '进场' : '出场'}} {{worker.check_time.slice(5, -3)}}</div>
        </div>
      </div>
      <div style="width:100%;text-align:right"><a
          @click="showMoreAttendance"
          style="font-size:0.9rem;color:#48b6e2;text-decoration:underline;cursor:pointer"
        >更多>></a></div> -->

      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display: flex;justify-content: space-between;align-items:center;width: 85%"
        >
          <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              style="min-width: 5rem;"
              :class="{ 'option-block-selected2': mode == 1 }"
              @click="() => changeChart(1)"
            >进场教育完成率</div>
            <div
              class="option-block2"
              style="min-width: 5rem;"
              :class="{ 'option-block-selected2': mode == 2 }"
              @click="() => changeChart(2)"
            >培训考核合格率</div>
            <div
              class="option-block2"
              style="min-width: 5rem;"
              :class="{ 'option-block-selected2': mode == 3 }"
              @click="() => changeChart(3)"
            >人均培训次数</div>
          </div>

        </div>
        <div style="height:19rem;padding-top:3rem">
          <!-- <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"><CustomSelect :options="companyNameList" @input="companySelect2" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold" /></div> -->
          <div
            v-if="sevenDayAlarmsLineChart.series && [].concat.apply([], sevenDayAlarmsLineChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart
            v-else
            :options="sevenDayAlarmsLineChart"
            autoresize
          />
        </div>
      </div>
    </wrapper>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="alarmDetailShow"
      width="1500px"
      top="13rem"
    >
      <wrapper style="height:657px;backgroundColor:#0e1328;position:relative">
        <iframe
          :src="alarmDetailShow ? `http://smarthat.comprehend.com.cn/html/screen/warning_list.html?project_id=${$route.params.id}&type_index=${check_type}&token=${$route.query.token}` : ''"
          width="1500px"
          height="657px"
          style="border:0;position:absolute;left:0;top:0"
        ></iframe>
        <div
          class="stats-close-btn"
          @click="alarmDetailShow = false"
        />
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="securityStarPopupShow"
      width="50rem"
      top="15rem"
    >
      <wrapper style="height:32.5rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="securityStarPopupShow = false"
        />
        <div style="margin:1rem;padding-top:1rem;background-color:#051236">
          <div style="font-size:1rem;margin-left:1rem;">安全行为之星</div>
          <div
            class="stats-chart-block"
            style="height:26rem;width:46.75rem;"
          >
            <div style="margin:1rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:3rem">序号</div>
                <div style="width:6rem">姓名</div>
                <div style="width:10rem">班组</div>
                <div style="width:3rem">得分</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in securityStarInfo"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:3rem">{{(securityStarPage - 1) * 10 + index + 1}}</div>
                  <div style="width:6rem">{{item.worker_name}}</div>
                  <div style="width:10rem">{{item.team_name}}</div>
                  <div style="width:3rem">
                    <a
                      @click="() => showWorkerInfo(item.worker_id)"
                      style="color:#48b6e2;text-decoration:underline;cursor:pointer"
                    >{{item.total_score}}分</a>
                  </div>
                </div>
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="securityStarPage"
              :page-count="securityStarPages"
              @current-change="securityStarPageChange"
              style="text-align:center"
            ></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="personalInfoPopupShow"
      width="1500px"
      top="15rem"
    >
      <wrapper style="height:622px;backgroundColor:#0e1328;position:relative">
        <iframe
          :src="personalInfoPopupShow ? `http://smarthat.comprehend.com.cn/html/screen/worker_detail.html?worker_id=${personalInfoWorkerId}&type_index=${personalInfoTabIndex}&token=${$route.query.token}` : ''"
          width="1500px"
          height="622px"
          style="border:0;position:absolute;left:0;top:0"
        ></iframe>
        <div
          class="stats-close-btn"
          @click="personalInfoPopupShow = false"
        />
      </wrapper>
    </el-dialog>

  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { project } from "../../api";
// import CustomSelect from '../../components/select'
import wrapper from "../../components/wrap";
import { SimpleBarChartTemplate } from "./barChartTemplates";
import { singleLineScrollChartTemplate } from "./lineChartTemplates";
import { applyUpdate } from "../../utils";

export default {
  name: "SecurityPersonnelLeftBar",
  components: {
    "v-chart": ECharts,
    // 'CustomSelect': CustomSelect,
    wrapper,
  },
  props: {
    projectDetail: Object,
    showTrace: Function,
  },
  data() {
    return {
      projectId: null,
      // deptNameList: ["全部部门"],
      // deptIdList: [""],
      // mgmStatsOption: "all",
      // mgmStatsSelectedDeptIdx: null,
      // mgmStatsBarChart: {},
      // mgmDetailShow: false,
      // mgmSubWindowUrl: null,
      // mgmSubWindowShow: false,
      // workerInOutList: [],
      personalInfoPopupShow: false,
      personalInfoWorkerId: null,
      personalInfoTabIndex: 1,
      securityStarPage: 1,
      securityStarPages: 1,
      // inOutPopupShow: false,
      // workerInOutPage: 1,
      // workerInOutPages: 1,
      // workerInOutInfo: [],
      // companyAttendanceBarChart: {},
      // timer: null,

      check_type: 2,
      SafetyDay7WarningAlarm: {},
      option: {
        color: [
          "#f32c2c",
          "#f38e2c",
          "#2ce4ff",
          "#19b6fb",
          "#5dd5c3",
          "#ffed21",
          "#159172",
          "#fb9c5c",
        ],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          type: "scroll", // 添加这一行代码即可实现图例分页功能
          orient: "horizontal", // 'vertical'
          x: "center",
          icon: "circle",
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 12,
          textStyle: {
            color: "#ccc",
            fontWeight: "bold",
            fontSize: "11",
          },
          // padding: [8, 0, 0, 60],
          // top: '4%',
          // right: '3%',
          data: [],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "1%",
          top: "19%",
          containLabel: true,
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              rotate: 40,
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            name: "数量:次",
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            type: "value",
            show: true,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: [5, 10],
                color: "#14253f",
                width: 2,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontSize: 14,
              fontWeight: "bold",
              // formatter: function(value) {
              //   return value ? value + '%' : value;
              // }
            },
          },
        ],
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   areaStyle: {},
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // }
        ],
      },
      alarmDetailShow: false,
      securityStarList: [],
      securityStarInfo: [],
      securityStarPopupShow: false,

      mode: 1,
      sevenDayAlarmsLineChart: {},
      option3: {
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0}: {c0}<br>",
        },
        color: ["#4191e5", "#1bd080"],
        grid: {
          contentLabel: true,
          left: "12%",
          right: "6%",
          bottom: "26%",
          top: "13%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              rotate: 35,
              formatter: function(value) {
                return value.length>6 ? value.slice(0,6)+'...' : value;
              }
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
            show: true,
          },
          {
            min: 0,
            max: 100,
            interval: 25,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              formatter: function (value) {
                return value ? value + "%" : value;
              },
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
            show: false,
          },
        ],
        series: [
          {
            name: "数量",
            type: "bar",
            barWidth: 10,
            data: [],
            markLine: {
              symbol: ["none", "none"], //去掉箭头
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "dashed",
                    color: "#238778",
                  },
                  label: {
                    show: false,
                    position: "middle",
                  },
                },
              },
              data: [
                {
                  yAxis: -1, //这里设置false是隐藏不了的，可以设置为-1
                },
              ],
            },
          },
          {
            name: "数量",
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                },
              },
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(27, 208, 128, 0.3)" },
                { offset: 1, color: "rgba(27, 208, 128, 0)" },
              ]),
            },
            symbolSize: 0,
            yAxisIndex: 1,
            data: [],
          },
        ],
      },
    };
  },
  mounted() {
    // const self = this
    const { id: projectID } = this.$route.params;
    this.projectId = projectID;
    const token = this.$route.query.token;
    window.token = token;

    // project
    //   .getDeptList()
    //   .then((d) => {
    //     this.deptNameList = ["全部部门"].concat(d.map((item) => item.value));
    //     this.deptIdList = [""].concat(d.map((item) => item.innerid));
    //   })
    //   // eslint-disable-next-line
    //   .catch((e) => console.error(e.msg || e.message));

    // this.updateSecurityStarList(projectID);
    // this.updateWorkerInOutList(projectID);

    // window.addEventListener(
    //   "message",
    //   (event) => {
    //     if (event.data.key == "merge") {
    //       if (event.data.page == "trace") {
    //         this.showTrace(
    //           event.data.query.worker_id,
    //           event.data.query.worker_name
    //         );
    //       } else {
    //         this.showMgmSubWindow(event.data.url);
    //       }
    //     }
    //   },
    //   false
    // );

    // this.timer = setInterval(() => {
    //   this.updateSecurityStarList(projectID);
    //   this.updateWorkerInOutList(projectID);
    // }, 1000 * 15);
    this.getData();
  },
  methods: {
    getData() {
      this.getCallWarningInfo();
      this.getSafetyPsBehaviorStarList();
      this.changeChart(this.mode);
    },
    getCallWarningInfo() {
      project
        .getSafetyDay7WarningAlarmCollect(this.projectId, this.check_type)
        .then((d) => {
          const chart = JSON.parse(
            JSON.stringify(singleLineScrollChartTemplate)
          );
          this.option.legend.data = d.legend_data;
          this.option.xAxis[0].data = d.xAxis_data;
          this.option.series = [];
          d.series.forEach((v) => {
            this.option.series.push({
              name: v.name,
              type: "line",
              areaStyle: {},
              data: v.data,
            });
          });
          this.SafetyDay7WarningAlarm = applyUpdate(chart, this.option);
        });
    },
    getSafetyOrgCityWarningAlarmCount(mode) {
      this.check_type = mode;
      this.getCallWarningInfo();
    },
    getSafetyPsBehaviorStarList() {
      project.getSafetyPsBehaviorStarList(this.projectId, 1, 7).then((d) => {
        this.securityStarList = d;
      });
    },
    showMoreStars() {
      this.securityStarPopupShow = true;
      project
        .getSafetyPsBehaviorStarList(this.projectId, 1, 10)
        .then((d) => {
          this.securityStarInfo = d;
          this.securityStarPage = 1;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    securityStarPageChange(currPage) {
      project
        .getSafetyPsBehaviorStarList(this.$route.params.id, currPage, 10)
        .then((d) => {
          this.securityStarInfo = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },

    showWorkerInfo(workerId) {
      this.personalInfoWorkerId = workerId;
      this.personalInfoTabIndex = 2;
      this.personalInfoPopupShow = true;
    },
    changeChart(mode) {
      this.mode = mode;
      project.getSafetyPsWorkerTrainCollect(this.projectId, mode).then((d) => {
        const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
        this.option3.xAxis[0].data = d.xAxis_data;
        this.option3.series[0].data = d.series_data;
        // eslint-disable-next-line no-empty
        if (this.mode === 1) {
          this.option3.series[0].name = "完成率";
          this.option3.yAxis[0].name = "完成率：%";
          this.option3.yAxis[0].max = "100";
          // eslint-disable-next-line no-empty
        } else if (this.mode === 2) {
          this.option3.series[0].name = "合格率";
          this.option3.yAxis[0].name = "合格率：%";
          this.option3.yAxis[0].max = "100";
        } else {
          // eslint-disable-next-line no-empty
          this.option3.series[0].name = "次数";
          this.option3.yAxis[0].name = "次数：次";
          this.option3.yAxis[0].max = undefined;
        }
        // eslint-disable-next-line no-empty
        if (d.config.theta) {
          this.option3.series[0].markLine.data[0].yAxis = d.config.theta;
        }
        this.sevenDayAlarmsLineChart = applyUpdate(chart, this.option3);
      });
    },
    showDetail() {
      // this.alarmDetailType = type;
      this.alarmDetailShow = true;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>