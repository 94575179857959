// import { data } from 'jquery';
const baseUrl = process.env.VUE_APP_API_BASE_ZGSZJ_URL

const areaCodeMap = require('/src/area-code-map.json');

const handleRes = (res) => {
    if (res.statusCode < 200 || res.statusCode >= 300) {
      return res.response.text().then(e => Promise.reject(e))
    } else {
      return res.response.json().then(_d => {
        if (_d.code !== 0 && _d.code !== 200) {
          return Promise.reject(_d)
        } else if (_d.data) {
          _d.data.__count__ = _d.count;
          return _d.data;
        } else{
          return _d;
        }
      })
    }
  }
// function addFilterParams(url) {
//       // for(var key in areaCodeMap){
//       //   if(key == window.$districtLevel){
//       //     console.log(areaCodeMap[key]);
//       //   }
//       // }
//       verifyOrgId();
//       const containsArea = url.indexOf('areaLvl=') >= 0;
//       const containsOrg = url.indexOf('orgLvl=') >= 0;
//       if(url.indexOf("unit_list")!=-1)
//         return url;
//       if (!containsArea || !containsOrg) {
//         const sign = url.indexOf('?') >= 0 ? '&' : '?';
//         const areaStr = `areaLvl=${window.$districtLevel || '100000'}`;
//         const orgStr = `orgLvl=${window.$orgLevel || ''}`;
//         if (!containsArea && !containsOrg) {
//           return url + sign + areaStr + '&' + orgStr;
//         } else {
//           return url + sign + (!containsArea ? areaStr : orgStr);
//         }
//       } else {
//         return url;
//       }
//     // const sFIDArea = url.indexOf('sFID=') >= 0;
//     // if (!sFIDArea) {
//     //   // const sign = url.indexOf('?') >= 0 ? '&' : '?';
//     //   formattingOrgId();
//     //   return url;
//     //   // const orgStr = `orgLvl=${window.$sFid || ''}`;
//     //   // return url + sign + orgStr;
//     // } else {
//     //   return url;
//     // }
//   }

  function addFilterParams2(url,body) {
    verifyOrgId();
    // 忽略 接口
    if(url.indexOf("directMap")!=-1 || url.indexOf("projectOrgTree")!=-1|| url.indexOf("empAmountCondition")!=-1
     || url.indexOf("empExamCondition")!=-1 || url.indexOf("operationConditionCompare")!=-1
     || url.indexOf("empTrainCondition")!=-1 || url.indexOf("overstaffingCondition")!=-1){
      return body;
    }
    var provinceName = ''; 
    var cityName = '';
    for(var key in areaCodeMap){
      if(key == window.$districtLevel){
        let str = areaCodeMap[key];
        if(str != '' && str != null){
          var datalist = str.split("/");
          if(datalist.length >= 1){
            provinceName = datalist[0];
            cityName = datalist[1];
          }
        }
      }
    }
    const provinceExist = body["province"] ==null
    const cityExist = body["cityName"] ==null
    if(provinceExist || cityExist) {
      body["province"] = provinceName;
      body["cityName"] = cityName;
      return body
    } else {
      return body;
    }
}

/** @param url {string}
 * @return Promise*/
//  const get = url => fetch(addFilterParams(baseUrl + url)).then(res => ({ response: res, statusCode: res.status })).then(handleRes)

const post = (url, body, type = 'json') => {
    if(window.$orgName == '一公司'){
      window.$orgName = '中建一局集团第一建筑有限公司';
    }
    let headers, _body = addFilterParams2(url,body);
    if (type === 'json') {
      headers = { 'Content-Type': 'application/json','traditional':true}
      // headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
      _body = JSON.stringify(body)
    } else if (type === 'file') {
      _body = new FormData()
      _body.append('files', body)
    } else {
      _body = body
    }
    if (url.slice(0, 4) != 'http') {
      url =baseUrl + url;
    }
    return fetch(url, { method: 'POST', headers, body: _body })
      .then(res => ({ response: res, statusCode: res.code })).then(handleRes)
  }
function verifyOrgId(){
  if(window.$sFid === '' || window.$sFid === undefined || window.$sFid === null)
    window.$sFid = "7B867F6775E143F8B1F3131BFA85C13E";
}
const finance = {
  // 金额转亿元
  formatDecimal(num, decimal) {
    num = (num/10000).toString()
    let index = num.indexOf('.')
    if (index !== -1) {
        num = num.substring(0, decimal + index + 1)
    } else {
        num = num.substring(0)
    }
    return parseFloat(parseFloat(num).toFixed(decimal))
  },
  // 金额转万元
  formatToMyriadDecimal(num, decimal) {
    num = (num/10000).toString()
    let index = num.indexOf('.')
    if (index !== -1) {
        num = num.substring(0, decimal + index + 1)
    } else {
        num = num.substring(0)
    }
    return parseFloat(num).toFixed(decimal)
  },
  // 查询考核机构列表
  queryAssessUnitList:()=>post(`bi/finance/branch/queryAssessUnitList`,{'branchName':window.$orgName}),
  // 公司资金数据
  queryCompanyCapitalStatistic:()=>post(`bi/finance/company/queryCompanyCapitalStatistic`,{}),
  // 公司资金情况
  queryAllBranchCapitalSum:()=>post(`bi/finance/branch/queryAllBranchCapitalSum`,{}),
  // 公司季度数据
  queryCompanyCapitalSeasonly:()=>post(`bi/finance/company/queryCompanyCapitalSeasonly`,{}),
  // 分公司月度资金情况
  queryBranchCapitalMonthlyLine:()=>post(`bi/finance/branch/queryBranchCapitalMonthlyLine`,{'branchName':window.$orgName}),
  // 分公司资金情况列表
  queryBranchCapitalStatisticLine:()=>post(`bi/finance/branch/queryBranchCapitalStatisticLine`,{'branchName':window.$orgName,'assessUnit':window.$assessUnit}),
  // 分公司资金情况汇总
  queryBranchCapitalStatisticSum:()=>post(`bi/finance/branch/queryBranchCapitalStatisticSum`,{'branchName':window.$orgName=="中建一局集团第一建筑有限公司"?"":window.$orgName,'assessUnit':window.$assessUnit}),
  //分公司月度净流情况
  queryBranchCapitalMonthlySum:()=>post(`bi/finance/branch/queryBranchCapitalMonthlySum`,{'branchName':window.$orgName,'assessUnit':window.$assessUnit}),
   //项目资金情况
   queryProjCapitalStatistic:(projCode,pageNum,pageSize)=>post(`bi/finance/proj/queryProjCapitalStatistic`,{'branchName':window.$orgName=="中建一局集团第一建筑有限公司"?"":window.$orgName,"profitCode":projCode,'assessUnit':window.$assessUnit,"pageNum":pageNum,"pageSize":pageSize}),
   // 项目资金情况详情
   queryProjCapitalDetail:(projCode)=>post(`bi/finance/proj/queryProjCapitalDetail`,{'branchName':window.$orgName,"profitCode":projCode,'assessUnit':window.$assessUnit}),
   // 查询城市/区域数据
   queryProjCapitalAreaGroup:()=>post(`bi/finance/proj/queryProjCapitalAreaGroup`,{'branchName':window.$orgName=="中建一局集团第一建筑有限公司"?"":window.$orgName,'assessUnit':window.$assessUnit}),
   //投资项目情况
   queryProjCapitalStatisticSum:(year)=>post(`bi/finance/proj/queryProjCapitalStatisticSum`,{'branchName':window.$orgName=="中建一局集团第一建筑有限公司"?"":window.$orgName,"year":year,'assessUnit':window.$assessUnit}),
   //公司维度资金情况
   queryProjCapitalSum:()=>post(`bi/finance/proj/queryProjCapitalSum`,{'branchName':window.$orgName,'assessUnit':window.$assessUnit}),
   //公司维度资金交流情况
   queryProjFutureNetFlowSum:()=>post(`bi/finance/proj/queryProjFutureNetFlowSum`,{'branchName':window.$orgName,'assessUnit':window.$assessUnit}),
   //项目净流贡献情况
   queryProjDistributionDetail:()=>post(`bi/finance/proj/queryProjDistributionDetail`,{'branchName':window.$orgName,'assessUnit':window.$assessUnit}),
   //模糊查询项目信息
   queryProjInfo:(projName)=>post(`bi/finance/proj/queryProjInfo`,{'projName':projName}),
   //查询项目净流 收款明细
   queryProjNetFlowLine:(projCode)=>post(`bi/finance/proj/queryProjNetFlowLine`,{"profitCode":projCode}),
   // 项目全期现金流
   queryProjCycleSum:(projCode,year)=>post(`bi/finance/proj/queryProjCycleSum`,{"profitCode":projCode,"year":year}),
   // 项目年度净流情况
   queryProjCapitalYearly:(projCode,year)=>post(`bi/finance/proj/queryProjCapitalYearly`,{'branchName':window.$orgName =="中建一局集团第一建筑有限公司"?"":window.$orgName,"profitCode":projCode,"year":year}),
   // 项目收款情况汇总
   queryProjNetFlowSum:(year)=>post(`bi/finance/proj/queryProjNetFlowSum`,{'branchName':window.$orgName =="中建一局集团第一建筑有限公司"?"":window.$orgName,"year":year}),
   // 分公司净流汇总
   queryAllBranchCapitalMonthlySum:(year)=>post(`bi/finance/branch/queryAllBranchCapitalMonthlySum`,{"year":year}),
   // 按分公司分组查询项目净流收款月度数据
   queryBranchProjNetFlow:()=>post(`bi/finance/proj/queryBranchProjNetFlow`,{}),
   // 按分公司分组查询项目数据
   queryProjCapitalBranchGroup:()=>post(`bi/finance/proj/queryProjCapitalBranchGroup`,{'branchName':window.$orgName =="中建一局集团第一建筑有限公司"?"":window.$orgName}),
   
  }

export { baseUrl, finance }
