<template>
  <div>
    <wrapper style="padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>项目截止目前可用资金余额</div>
          <div class="detail" @click="showDetail(1)">
            详情
          </div>
        </div>
        <div
          style="height:17rem;padding-top:3rem;"
          class="machine_use"
        >
          <el-row :gutter="12" style="margin-top:2rem;padding:0 1rem;">
          <el-col :span="8">
            <div class="card">
            </div>
          </el-col>
          <el-col :span="8">
            <div class="card"  style="display:flex;align-items:center">
              <img src="../../../../assets/nation/icons/资金余额.png"/>
              <div>
                 <div class="stats-label" style="fontSize: 1rem">资金余额</div>
                 <div style="display:flex">
                    <div
                    style="font-family:digital;margin:0 0 0 0.3rem;fontSize:2rem;color:#40f8ff">
                    {{zeroFomatter(subData.curBalanceAmount)}}
                    </div>
                 </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="card"  style="display:flex;align-items:center">
              <img src="../../../../assets/nation/icons/年底目标.png"/>
              <div>
                 <div class="stats-label" style="fontSize: 1rem">年底目标</div>
                 <div style="display:flex">
                    <div
                    style="font-family:digital;margin:0 0 0 0.3rem;fontSize:2rem;color:#f4ea2a">
                    {{zeroFomatter(subData.eyBalanceTarget)}}
                    </div>
                 </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="12" style="margin-top:1rem;padding:0 1rem;">
          <el-col :span="8">
            <div class="card"  style="display:flex;align-items:center">
              <img src="../../../../assets/nation/icons/其中存款借款.png"/>
              <div>
                 <div class="stats-label" style="fontSize: 1rem">其中存款/借款</div>
                 <div style="display:flex">
                    <div
                    style="font-family:digital;margin:0 0 0 0.3rem;fontSize:2rem;color:#38c59e">
                    {{zeroFomatter(subData.curBalanceDeposit)}}
                    </div>
                 </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="card"  style="display:flex;align-items:center">
              <img src="../../../../assets/nation/icons/自存资金.png"/>
              <div>
                 <div class="stats-label" style="fontSize: 1rem">其中项目自存资金</div>
                 <div style="display:flex">
                    <div
                    style="font-family:digital;margin:0 0 0 0.3rem;fontSize:2rem;color:#d4237a">
                    {{zeroFomatter(subData.curBalanceProj)}}
                    </div>
                 </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="card"  style="display:flex;align-items:center">
              <img src="../../../../assets/nation/icons/项目欠交收益.png"/>
              <div>
                 <div class="stats-label" style="fontSize: 1rem">(减)其中欠交公司收益</div>
                 <div style="display:flex">
                    <div
                    style="font-family:digital;margin:0 0 0 0.3rem;fontSize:2rem;color:#FF8C8F">
                    {{zeroFomatter(subData.curBalanceUnpaid)}}
                    </div>
                 </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      </div>
    </wrapper>
    <wrapper style="padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>项目主要数据</div>
          <div class="detail" @click="showDetail(2)">
            详情
          </div>
        </div>
        <div
          style="height:17rem;padding-top:1.5rem;"
          class="machine_use"
        >
          <el-row :gutter="20"  style="margin-top:1.5rem;padding:0 1rem;">
            <el-col :span="6">
                <div class="grid-content bg-purple">
                  <div class="stats-label" style="fontSize: 1rem;display:flex;align-items:center;justify-content:center">项目合同额</div>
                  <div style="display:flex;align-items:center;justify-content:center;margin-top:5px;fontSize:1.5rem;color:#41F8FD">
                    {{zeroFomatter(subData.projContractCapital)}}万
                  </div>
                </div>
              </el-col>
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="stats-label" style="fontSize: 1rem;display:flex;align-items:center;justify-content:center">项目预计总收入</div>
                  <div style="display:flex;align-items:center;justify-content:center;margin-top:5px;fontSize:1.5rem;color:#41F8FD">
                    {{zeroFomatter(subData.expectedIncome)}}万
                  </div>
              </div></el-col>
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="stats-label" style="fontSize: 1rem;display:flex;align-items:center;justify-content:center">项目总收益</div>
                  <div style="display:flex;align-items:center;justify-content:center;margin-top:5px;fontSize:1.5rem;color:#41F8FD">
                    {{zeroFomatter(subData.totalIncome)}}万
                  </div>
              </div></el-col>
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="stats-label" style="fontSize: 1rem;display:flex;align-items:center;justify-content:center">项目预计总收益率</div>
                  <div style="display:flex;align-items:center;justify-content:center;margin-top:5px;fontSize:1.5rem;color:#41F8FD">
                    {{zeroFomatter(subData.projTotalEarningRate)}}%
                  </div>
              </div></el-col>
          </el-row>
          <el-row :gutter="20"  style="margin-top:1rem;padding:0 1rem;">
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="stats-label" style="fontSize: 1rem;display:flex;align-items:center;justify-content:center">累计目前收入</div>
                  <div style="display:flex;align-items:center;justify-content:center;margin-top:5px;fontSize:1.5rem;color:#41F8FD">
                    {{zeroFomatter(subData.monthAccProjGaining)}}万
                  </div>
              </div></el-col>
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="stats-label" style="fontSize: 1rem;display:flex;align-items:center;justify-content:center">累计目前成本</div>
                  <div style="display:flex;align-items:center;justify-content:center;margin-top:5px;fontSize:1.5rem;color:#41F8FD">
                    {{zeroFomatter(subData.curAccCostAmount)}}万
                  </div>
              </div></el-col>
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="stats-label" style="fontSize: 1rem;display:flex;align-items:center;justify-content:center">其中间接费</div>
                  <div style="display:flex;align-items:center;justify-content:center;margin-top:5px;fontSize:1.5rem;color:#41F8FD">
                    {{zeroFomatter(subData.projTotalEarningRate)}}万
                  </div>
              </div></el-col>
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="stats-label" style="fontSize: 1rem;display:flex;align-items:center;justify-content:center">累计项目账面收益</div>
                  <div style="display:flex;align-items:center;justify-content:center;margin-top:5px;fontSize:1.5rem;color:#41F8FD">
                    {{zeroFomatter(subData.projAccPaperIncome)}}万
                  </div>
              </div></el-col>
          </el-row>
          <el-row :gutter="20"  style="margin-top:1rem;padding:0 1rem;">
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="stats-label" style="fontSize: 1rem;display:flex;align-items:center;justify-content:center">累计业主确权</div>
                  <div style="display:flex;align-items:center;justify-content:center;margin-top:5px;fontSize:1.5rem;color:#41F8FD">
                    {{zeroFomatter(subData.curAccConfirm)}}万
                  </div>
              </div></el-col>
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="stats-label" style="fontSize: 1rem;display:flex;align-items:center;justify-content:center">累计已收款</div>
                  <div style="display:flex;align-items:center;justify-content:center;margin-top:5px;fontSize:1.5rem;color:#41F8FD">
                    {{zeroFomatter(subData.accGathered)}}万
                  </div>
              </div></el-col>
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="stats-label" style="fontSize: 1rem;display:flex;align-items:center;justify-content:center">业主支付比例</div>
                  <div style="display:flex;align-items:center;justify-content:center;margin-top:5px;fontSize:1.5rem;color:#41F8FD">
                    {{zeroFomatter(subData.ownerPayRatio)}}%
                  </div>
              </div></el-col>
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="stats-label" style="fontSize: 1rem;display:flex;align-items:center;justify-content:center">过程结算率</div>
                  <div style="display:flex;align-items:center;justify-content:center;margin-top:5px;fontSize:1.5rem;color:#41F8FD">
                    {{zeroFomatter(subData.processSettlementRate)}}%
                  </div>
              </div></el-col>
          </el-row>
      </div>
      </div>
    </wrapper>

    <wrapper style="margin-top: 1rem;padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div style="display:flex;">项目欠款</div>
          <div class="detail" @click="showDetail(3)">
            详情
          </div>
        </div>
        <div
          style="height:17rem;padding-top:4rem"
          class="machine_use"
        >
          <div v-if="!UnpaidChart.series || !UnpaidChart.series[0].data.length" style="padding-top:1rem">
          <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 35rem; height: 12rem;margin-top: 0.5rem;">
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
        </div>
        <v-chart v-else :options="UnpaidChart" autoresize />
      </div>
      </div>
    </wrapper>
    <!-- 项目截止目前可用资金余额 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                <el-table style="background-color:#0E1328" height="28rem" :data="tableData" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column
                        prop="projName"
                        label="项目">
                        </el-table-column>
                    <el-table-column label="截至年初资金余额（交收益后）">
                        <el-table-column
                        prop="byBalanceAmount"
                        label="合计"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                合计
                                <el-tooltip :aa="scope" class="item" effect="dark" content="在公司存/借款减去欠交收益加上受限资金" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceDeposit"
                        label="其中：公司存/借款"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：公司存/借款
                                <el-tooltip :aa="scope" class="item" effect="dark" content="截止年初可上存资金减去累计上交收益余额。正数表示在公司存款，负数表示欠公司钱" placement="top-start">
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceUnpaid"
                        label="其中：欠交公司收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：欠交公司收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="累计收益减去已交收益" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceProj"
                        label="其中：项目自存资金"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：项目自存资金
                                <el-tooltip :aa="scope" class="item" effect="dark" content="项目年初监管户、农民工工资户、抵入未抵出房产余额" placement="top-start">
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="本年净流">
                      <el-table-column
                        prop="curNetFlowAmount"
                        label="合计"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                合计
                                <el-tooltip :aa="scope" class="item" effect="dark" content="本年收款-支出" placement="top-start">
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="curNetFlowDeposit"
                        label="其中：存款/借款"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：存款/借款
                                <el-tooltip :aa="scope" class="item" effect="dark" content="本年净流减去本年受限资金增减额" placement="top-start">
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="curNetFlowLimited"
                        label="其中：受限"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：受限
                                <el-tooltip :aa="scope" class="item" effect="dark" content="本年受限资金增减额" placement="top-start">
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                        prop="curPaidCashIncome"
                        label="本年已交现金收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                本年已交现金收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="根据预算要求截止目前时点已交收益，此金额须经项目经理确认" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                      </el-table-column>
                      <el-table-column
                        prop="curUnpaidCashIncome"
                        label="本年欠交现金收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                本年欠交现金收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="根据预算要求截止目前时点应交未交收益，此金额须经项目经理领导确认" placement="top-start">
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                      </el-table-column>
                      <el-table-column label="截止目前资金余额">
                        <el-table-column
                        prop="curBalanceAmount"
                        label="合计"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                合计
                                <el-tooltip :aa="scope" class="item" effect="dark" content="在公司存/借款减去欠交收益加上受限资金" placement="top-start">
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="curBalanceDeposit"
                        label="其中：公司存/借款"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：公司存/借款
                                <el-tooltip :aa="scope" class="item" effect="dark" content="截止年初在公司存款/借款加上本年在公司存款/借款" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="curBalanceUnpaid"
                        label="其中：欠交公司收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：欠交公司收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="年初欠交收益加上本年欠缴现金收益" placement="top-start">
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="curBalanceProj"
                        label="其中：项目自存资金"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：项目自存资金
                                <el-tooltip :aa="scope" class="item" effect="dark" content="项目本月监管户、专户、抵入未抵出房产余额" placement="top-start">
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                      </el-table-column>
                      <el-table-column
                        prop="eyBalanceTarget"
                        label="截至年底资金余额目标"
                        :formatter="Formatter">
                        </el-table-column>
                </el-table>
            </div>
        </div>
      </wrapper>
    </el-dialog>
    <!-- 项目主要数据 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow2=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                <el-table style="background-color:#0E1328" height="28rem" :data="tableData" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column
                        prop="projName"
                        label="项目">
                    </el-table-column>
                    <el-table-column
                    prop="projContractCapital"
                    label="项目合同额"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="expectedIncome"
                    label="项目预计总收入"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="totalIncome"
                    label="项目总收益"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="projTotalEarningRate"
                    label="项目预计总收益率"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="monthAccProjGaining"
                    label="累计目前收入"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="curAccCostAmount"
                    label="累计目前成本"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="curAccCostIndirect"
                    label="其中间接费"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="projAccPaperIncome"
                    label="累计账面收益"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="curAccConfirm"
                    label="累计业主确权"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="accGathered"
                    label="累计已收款"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="ownerPayRatio"
                    label="业主支付比例"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="processSettlementRate"
                    label="过程结算率"
                    :formatter="Formatter">
                    </el-table-column>
                </el-table>
            </div>
        </div>
      </wrapper>
    </el-dialog>
    <!-- 项目欠款 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow3"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow3=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                <el-table style="background-color:#0E1328" height="28rem" :data="tableData" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column
                        prop="projName"
                        label="项目">
                    </el-table-column>
                    <el-table-column
                    prop="projUnpaidAmount"
                    label="欠款合计"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="rebarUnpaid"
                    label="钢筋欠款"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="concreteUnpaid"
                    label="混凝土欠款"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="otherUnpaid"
                    label="其他分包欠款"
                    :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                    prop="labourServiceUnpaid"
                    label="劳务欠款"
                    :formatter="Formatter">
                    </el-table-column>
                </el-table>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from 'vue-echarts'
  import wrapper from "../../../../components/wrap";
import { finance } from '../../../../api_finance';
export default {
    name:"middleBar",
    components:{
      'v-chart': ECharts,
      wrapper,
    },
    props:{
        worProjectId: String,
    },
    data () {
      return {
        datas:[],
        UnpaidChart:{},
        projectId:"",
        subData:{},
        tableData:[],
        popupShow:false,
        popupShow2:false,
        popupShow3:false,
      }
    },
    watch:{
       $route: function(newValue, oldValue) {
        if (newValue.params.id != oldValue.params.id) {
          this.getData();
        }
      },
    },
    mounted(){
      this.getData();
    },
    methods:{
      showDetail(choose){
        if(choose == 1){
          this.popupShow = true;
        }else if(choose ==2){
          this.popupShow2 = true;
        }else if(choose ==3){
          this.popupShow3 = true;
        }
      },
      Formatter(row, column, cellValue){
      return this.zeroFomatter(cellValue);
    },
      tableRowStyle({rowIndex}){
        if(rowIndex%2 === 0)
            return {backgroundColor:'#213a77',height: '0'}; 
        else   
            return {backgroundColor:'#102855',height: '0'}; 
      },
      tableHeaderColor(){
        return 'color:#4fb5da;background:#102855';
      },
        getData(){
          this.projectId = this.$route.params.id;
          finance.queryProjCapitalStatistic(this.projectId).then((res=>{
            this.subData = res.data[0];
            this.tableData = res.data;
          }))
          this.updateUnpaidChart();
        },
        zeroFomatter(val){
          return val==null || val==undefined? 0:(parseInt( val * 100 ) / 100).toFixed(2);
        },
        updateUnpaidChart(){
          const echartData = [
            {name:"钢筋欠款",value:this.zeroFomatter(this.subData.rebarUnpaid)},
            {name:"混凝土欠款",value:this.zeroFomatter(this.subData.concreteUnpaid)},
            {name:"劳务欠款",value:this.zeroFomatter(this.subData.labourServiceUnpaid)},
            {name:"其他分包欠款",value:this.zeroFomatter(this.subData.otherUnpaid)},
          ];
          var totalAmount = this.zeroFomatter(this.subData.projUnpaidAmount);
          const option = {
            tooltip: {
                trigger: 'item',
                extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
                backgroundColor: 'rgba(255,255,255,0.8)',
                textStyle: {
                    color: '#333',
                },
                formatter: function (params) {
                    var html = [];
                    html.push('<div class = "echart-tip-box">');
                    html.push('<div>' + params.name + '</div>');
                    html.push('<div> 金额：' + params.value + '万元</div>');
                    html.push('</div>');
                    return html.join('');
                },
            },
            // 图例用作中心总数值
            legend: {
                selectedMode: false,
                formatter: function () {
                    return '总计\n'+totalAmount + '万元';
                },
                data: [echartData[0].name],
                left: 'center',
                top: 'center',
                icon: 'none',
                align: 'center',
                textStyle: {
                    color: '#FFFFFF',
                    fontSize: 16 * 1,
                    // rich: rich,
                },
            },
            series: [
                {
                    type: 'pie',
                    radius: ['45', '75'],
                    center: ['50%', '50%'],
                    // hover弹啊弹效果
                    hoverAnimation: false,
                    color: ['#5d7092', '#f7c32b', '#62daaa', '#a66ece', '#5b8ff9', '#68dafb'],
                    itemStyle: {
                        normal: {
                            borderWidth: 1,
                            borderColor: '#fff',
                        },
                    },
                    data: echartData,
                },
            ],
          }
          this.UnpaidChart = option;
        }
    }
}
</script>
<style lang="less">
@colors:#1FAAEB;
  .card{
     border:1px solid transparent;
  }
  .grid-content{
    height:50px;
  }
  .info-block {
    position: relative;
    width: 100%;
    background: url("../../../../assets/nation/title_finance.png") no-repeat;
    background-size: 700px 40px;
  }
  .stats-label {
  margin: 10px 0 -3px 3px;
  font-weight: bold;
  color: #cececf;
}
</style>