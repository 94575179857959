<template>
  <div id="admin">
    <!--<header>{{projectName}}</header>-->
    <div class="global-floor-name" v-show="floor.name">{{building.buildingMap[building.currentBuildingID].name}}-{{floor.name}}</div>
    <div class="project_map" id="mapid" style="width: 100vw; height: 100vh" />
    <div id="btn-change-building">
      <img src="../../assets/louceng.png" alt="" width="21" height="17" @click="showBuildingList = !showBuildingList">
      <ul class="building-list" v-show="showBuildingList">
        <li v-for="building in building.buildingList" :key="building.innerid" @click="changeBuilding(building)"
            :class="[building.active ? 'active' : '']">
          {{building.name}}
        </li>
      </ul>
    </div>

    <div id="btn-change-floor" v-if="floor.name && building.buildingMap[building.currentBuildingID].floor.length > 1">
      <div class="upward" @click="changeFloor('up')"></div>
      <div class="floor-name">{{floor.name}}</div>
      <div class="downward" @click="changeFloor('down')"></div>
    </div>

    <el-dialog title="新增信标" width="fit-content" center
               :close-on-click-modal="false"
               custom-class="new-beacon-dialog"
               :close-on-press-escape="false"
               :visible.sync="newBeaconInfo.showAddNewBeaconPopup">
      <el-form label-width="128px" :label-position="'left'">
        <el-form-item label="信标类型">
          <el-radio-group v-model="newBeaconInfo.category" @change="changeNewBeaconCategory">
            <el-radio :label="1" border>固定式</el-radio>
            <el-radio :label="2" border>临时性</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="危险名称">
          <el-select v-model="newBeaconInfo.type.text" @change="changeNewBeaconType">
            <el-option v-for="item in beaconTypeByCategory" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>

        <el-form-item :label="p.p_name + '：'" v-for="p in beaconExtra" :key="p.p_key">
          <el-input v-model="newBeaconInfo.content[p.p_key]"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitNewBeacon">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="修改信标" width="fit-content" center
               :close-on-click-modal="false"
               custom-class="edit-beacon-dialog"
               :close-on-press-escape="false"
               :visible.sync="editBeaconInfo.showEditBeaconPopup">
      <el-form :model="editBeaconInfo" label-width="128px" :label-position="'left'">
        <el-form-item label="信标类型：">
          <span>{{(beaconTypes.filter(t => t.id === editBeaconInfo.type)[0] || {}).name}}</span>
<!--          <el-select v-model="editBeaconInfo.type" @change="changeEditbeaconType">-->
<!--            <el-option v-for="item in beaconTypes" :key="item.id" :label="item.name" :value="item.id"/>-->
<!--          </el-select>-->
        </el-form-item>
        <!--        <el-form-item label="备注" label-width="100px">-->
        <!--          <el-input v-model="editBeaconInfo.remark" autocomplete="off"/>-->
        <!--        </el-form-item>-->
        <el-form-item v-for="p in editBeaconInfo.extra" :key="p[0]"
                      :label="beaconTypes.filter(t => t.id === editBeaconInfo.type)[0].extra.filter(t => t.p_key === p[0])[0].p_name + '：'">
          <el-input v-model="editBeaconInfo.content[p[0]]"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" @click="submitEditBeacon(true)">删 除</el-button>
        <el-button type="primary" @click="submitEditBeacon(false)">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
  import { admin } from '../../api2';
  import { project } from '../../api';
  import {
    init,
    initOnClick,
    drawGroundMap,
    drawFloorMap,
    drawBeacons,
  } from '../../../public/leaflet_beacon.js';

  export default {
    data () {
      return {
        token: '',
        projectID: '',
        map_list: [],
        img_host: '',
        showBuildingList: false,
        newBeaconInfo: {
          category: 1,
          id: 'new-beacon-default',
          remark: '',
          x: 0, y: 0,
          status: 0,
          iconid: '默认',
          type: { text: '默认', value: 0 },
          showAddNewBeaconPopup: false,
          content: {}
        },
        projectName: '智能安全帽系统',
        beaconTypes: [],
        editBeaconInfo: {
          type: '',
          showEditBeaconPopup: false,
          content: {},
          extra: [],
          x: 0,
          y: 0,
          remark: '',
          id: '',
        },
        floor: {
          name: '',
          id: '',
          floorData: null,
        },
        building: {
          currentBuildingID: '',
          buildingMap: {'': {floor: []}},
          buildingList: [],
        },
        beacons: [],
        beaconTypeByCategory: [],
        beaconExtra: [],
      }
    },
    mounted () {
      this.projectID = this.$route.query.projectID;
      this.token = this.$route.query.token;
      admin.token = this.token;

      if (!this.token) {
        return this.$alert('缺少必要的URL参数', '警告', {
          confirmButtonText: '返回上一页',
          showClose: false,
          type: 'warning',
          center: true,
          callback: () => {
            history.go(-1)
          }
        })
      }

      this.init()
      admin.getBeaconTypes()
        .then(type => {
          this.beaconTypes = type.map(t => ({ id: t.innerid, name: t.name, category: t.type, extra: t.params }))
          this.beaconTypeByCategory = this.beaconTypes.filter(b => b.category === this.newBeaconInfo.category)
        })
        .catch(e => console.error(e.msg || e.message))
    },
    methods: {
      // 服务器坐标系 => Leaflet坐标系
      convertXY (x, y) {
        let x2 = -y;
        let y2 = -x;
        x2 = x2 * this.floor.floorData.ratio + this.floor.floorData.offsetX;
        y2 = y2 * this.floor.floorData.ratio + this.floor.floorData.offsetY;
        return [x2, y2];
      },
      // Leaflet坐标系 => 服务器坐标系
      convertXYReverse (x, y) {
        let x2 = (x - this.floor.floorData.offsetX) / this.floor.floorData.ratio;
        let y2 = (y - this.floor.floorData.offsetY) / this.floor.floorData.ratio;
        return [-y2, -x2];
      },
      changeContent (value, key) {
        this.newBeaconInfo.content[key] = value
        console.log(value, key, this.newBeaconInfo.content)
      },
      init () {
        const self = this;
        admin.getBuildingList()
          .then(list => {
            self.building = {
              buildingList: list,
              buildingMap: {},
              currentBuildingID: list[0].innerid
            }
            list.forEach(item => {
              self.building.buildingMap[item.innerid] = item
            })
            self.building.buildingList[0].active = true
            self.floor.name = list[0].floor[0].name
            self.floor.id = list[0].floor[0].innerid;

            const projectPromise = project.getBuildingBaseInfo(self.projectID);
            const beaconsPromise = admin.getAllBeacons({floorID: self.floor.id, buildingID: self.building.currentBuildingID});
            Promise.all([projectPromise, beaconsPromise]).then(([d, beacons]) => {
              self.map_list = d.show_map;
              self.img_host = d.img_host;
              //加载地图
              init('mapid', self);
              initOnClick();
              drawGroundMap(self.img_host + self.map_list[0].image_url);

              beacons.forEach(b => b.icon = (b.iconid > 50 ? 50 : b.iconid) + '-' + b.status);
              self.beacons = beacons;
              // 绘制已有信标
              drawBeacons(beacons, this.floor.floorData);
            });
          });
      },
      changeNewBeaconCategory () {
        this.newBeaconInfo.type = {text: '默认', value: 0}
        this.newBeaconInfo.content = {}
        this.beaconExtra = []
        this.beaconTypeByCategory = this.beaconTypes.filter(b => b.category === this.newBeaconInfo.category)
      },
      changeNewBeaconType (type) {
        this.newBeaconInfo.content = {}
        this.newBeaconInfo.type.value = type
        this.beaconExtra = this.beaconTypes.filter(t => t.id === this.newBeaconInfo.type.text).map(a => a.extra)[0]
        this.beaconExtra.forEach(v => this.$set(this.newBeaconInfo.content, v.p_key,  ''))
      },
      submitNewBeacon () {
        if (!this.newBeaconInfo.type.text || this.newBeaconInfo.type.text === '默认') {
          return this.$message.warning('设备类型不能为默认，请选择其他类型')
        }
        this.newBeaconInfo.showAddNewBeaconPopup = false;
        let xy;
        if (this.floor.floorData) {
          xy = this.convertXYReverse(this.newBeaconInfo.x, this.newBeaconInfo.y);
        } else {
          xy = [this.newBeaconInfo.x, this.newBeaconInfo.y];
        }
        admin.addNewBeacon(
          {
            build_id: this.building.currentBuildingID,
            map_id: this.floor.id,
            type: this.newBeaconInfo.type.value,
            x: xy[0],
            y: xy[1],
            remark: this.newBeaconInfo.remark,
            content: this.newBeaconInfo.content
          })
          .then(() => {
            this.$message.success('添加成功')
            return admin.getAllBeacons({
              buildingID: this.building.currentBuildingID,
              floorID: this.floor.id
            })
          })
          .then(beacons => {
            beacons.forEach(b => b.icon = (b.iconid > 50 ? 50 : b.iconid) + '-' + b.status);
            this.beacons = beacons;
            drawBeacons(beacons, this.floor.floorData);
          })
          // .catch(e => this.$message.error(e.msg || e.message))
      },
      submitEditBeacon (isDel) {
        this.editBeaconInfo.showEditBeaconPopup = false
        if (isDel) {
          admin.deleteBeacon(this.editBeaconInfo.innerid)
            .then(() => {
              this.$message.success('删除成功')
              return admin.getAllBeacons({
                buildingID: this.building.currentBuildingID,
                floorID: this.floor.id
              })
            })
            .then(beacons => {
              beacons.forEach(b => b.icon = (b.iconid > 50 ? 50 : b.iconid) + '-' + b.status);
              this.beacons = beacons;
              drawBeacons(beacons, this.floor.floorData);
            })
            // .catch(e => this.$message.error(e.msg || e.message))
        } else {
          let xy;
          if (this.floor.floorData) {
            xy = this.convertXYReverse(this.editBeaconInfo.x, this.editBeaconInfo.y);
          } else {
            xy = [this.editBeaconInfo.x, this.editBeaconInfo.y];
          }
          admin.addNewBeacon({
            build_id: this.building.currentBuildingID,
            map_id: this.floor.id,
            type: this.editBeaconInfo.type,
            x: xy[0],
            y: xy[1],
            remark: this.editBeaconInfo.remark,
            content: this.editBeaconInfo.content,
            innerid: this.editBeaconInfo.innerid
          })
            .then(() => {
              this.$message.success('修改成功')
              return admin.getAllBeacons({
                buildingID: this.building.currentBuildingID,
                floorID: this.floor.id,
              })
            })
            .then(beacons => {
              beacons.forEach(b => b.icon = (b.iconid > 50 ? 50 : b.iconid) + '-' + b.status);
              this.beacons = beacons;
              drawBeacons(beacons, this.floor.floorData);
            })
            .catch(e => console.error(e.msg || e.message))
        }
      },
      editBeaconPopupClickHandler (beacon) {
        if (!beacon.extra) {
          beacon.json = beacon.content;
          beacon.content = JSON.parse(beacon.content) || {};
          beacon.extra = Object.entries(beacon.content);
        } else {
          beacon.content = JSON.parse(beacon.json) || {};
        }
        this.editBeaconInfo = {
          showEditBeaconPopup: true,
          ...beacon
        }
      },
      addBeaconPopupClickHandler (x, y) {
        this.newBeaconInfo.x = x
        this.newBeaconInfo.y = y
        this.newBeaconInfo.showAddNewBeaconPopup = true
        this.newBeaconInfo.remark = ''
        this.newBeaconInfo.content = {}
        this.beaconExtra = []
        this.newBeaconInfo.type = {text: '默认', value: 0}
      },
      changeBuilding (building) {
        this.showBuildingList = false
        this.building.currentBuildingID = building.innerid
        Object.values(this.building.buildingMap).forEach(b => {
          b.active = b.innerid === building.innerid
        })
        this.floor.name = building.floor[0].name;
        this.floor.id = building.floor[0].innerid;
        this.floor.floorData = null;

        if (building.innerid === this.building.buildingList[0].innerid) {
          // 地面图
          drawGroundMap(this.img_host + this.map_list[0].image_url);
          admin.getAllBeacons({floorID: this.floor.id, buildingID: this.building.currentBuildingID})
          .then(beacons => {
            beacons.forEach(b => b.icon = (b.iconid > 50 ? 50 : b.iconid) + '-' + b.status);
            this.beacons = beacons;
            drawBeacons(beacons, this.floor.floorData);
          });
        } else {
          // 楼栋图
          this.changeFloor(this.floor.id);
        }
      },
      /** @param floorIDOrDirection {string} uuid or 'up' or 'down'*/
      changeFloor (floorIDOrDirection) {
        let floorIndex
        let floorID
        const floorArr = this.building.buildingMap[this.building.currentBuildingID].floor
        if (floorIDOrDirection === 'up') {
          floorIndex = floorArr.findIndex(f => f.innerid === this.floor.id) + 1
          if (floorIndex > floorArr.length - 1) floorIndex = floorArr.length
          floorID = floorArr[floorIndex].innerid
          this.floor.name = floorArr[floorIndex].name
        } else if (floorIDOrDirection === 'down') {
          floorIndex = floorArr.findIndex(f => f.innerid === this.floor.id) - 1
          if (floorIndex < 0) floorIndex = 0
          floorID = floorArr[floorIndex].innerid
          this.floor.name = floorArr[floorIndex].name
        } else {
          floorID = floorIDOrDirection
        }
        this.floor.id = floorID;
        admin.getFloorData(floorID)
        .then(floorData => {
          this.floor.floorData = floorData;
          drawFloorMap(floorData, (ratio, offsetX, offsetY) => {
            floorData.ratio = ratio;
            floorData.offsetX = offsetX;
            floorData.offsetY = offsetY;
          });
        });
        admin.getAllBeacons({floorID, buildingID: this.building.currentBuildingID})
        .then(beacons => {
          beacons.forEach(b => b.icon = (b.iconid > 50 ? 50 : b.iconid) + '-' + b.status);
          this.beacons = beacons;
          drawBeacons(beacons, this.floor.floorData);
        });
      },
    },
    computed: {
    },
  }
</script>
<style scoped lang="less">

  #admin {
    font-size: 16px;

    .global-floor-name {
      position: fixed;
      z-index: 1;
      left: 30px;
      top: 120px;
      font-size: 32px;
      color: #398EE3;
    }

    header {
      background-color: #398EE3;
      font-size: 20px;
      font-weight: bold;
      padding-left: 30px;
      color: #ffffff;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      height: 60px;
      box-shadow: 0 2px 7px rgba(10, 33, 57, 0.39);
      line-height: 60px;
    }

    #btn-change-building {
      position: fixed;
      width: 40px;
      cursor: pointer;
      height: 40px;
      border-radius: 50%;
      background-color: #ffffff;
      border: 1px solid #ECECEC;
      right: 24px;
      bottom: 60px;
      text-align: center;
      line-height: 46px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);

      .building-list {
        position: absolute;
        right: 120%;
        bottom: -50%;
        padding: 5px;
        border-radius: 5px;
        width: max-content;
        background-color: #fff;
        border-bottom: 1px solid #D6D6D6;

        li {
          padding: 5px;
          line-height: 15px;
          color: #000000;
          cursor: pointer;
          border-bottom: 1px solid #D6D6D6;

          &:last-of-type {
            border-bottom: none;
          }

          &.active {
            color: #398EE3;
          }
        }
      }
    }

    #btn-change-floor {
      position: fixed;
      right: 16px;
      bottom: 120px;
      padding: 6px 8px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.7);

      .upward, .downward {
        cursor: pointer;
        border: solid transparent;
        border-width: 22px 16px;
        width: 0;
        height: 0;
      }

      .upward {
        border-bottom-color: #398EE3;
        margin-bottom: 8px;
        border-top-width: 0;
      }

      .downward {
        border-top-color: #398EE3;
        margin-top: 8px;
        border-bottom-width: 0;
      }

      .floor-name {
        color: #398EE3;
        width: 32px;
        height: 16px;
        text-align: center;
        line-height: 16px;
      }
    }
  }
</style>

<style>
  .beacon-sprite-div {
    background-image: url("../../assets/sprites.png");
  }

  #admin .new-beacon-dialog, #admin .edit-beacon-dialog {
    margin-right: 5vw;
    border-radius: 6px;
  }

  .add-beacon-p, .edit-beacon-p {
    padding: 5px;
    border-radius: 5px;
    margin: 0;
    cursor: pointer;
    background-color: #fff;
  }

  .edit-beacon-p {
    color: #ff998e;
  }

  .el-select {
    width: 100%;
  }
</style>
