<template>
  <div id="right">
    <OverdueRiskChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel"/>
    <LatentLossesRiskChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel"/>
    <ScheduleRiskChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel"/>
  </div>
</template>

<script>
import OverdueRiskChart from './overdueRiskChart'
import LatentLossesRiskChart from './latentLossesRiskChart'
import ScheduleRiskChart from './scheduleRiskChart'
  export default {
    name: 'LawRespondRightBar',
    components: {
      OverdueRiskChart,
      LatentLossesRiskChart,
      ScheduleRiskChart
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
  }
</script>

<style lang="less">
</style>
