/**
 * 创建webSocket对接
 * @param {*} wsUrl
 * @param {*} projectID
 */
export function createWebSocket(wsUrl, projectID, onMessage, wsObj) {
    try {
        const ws = new WebSocket(wsUrl)
        if (wsObj) {
            wsObj.ws = ws;
        }

        ws.id = projectID
        //连接成功后，发送当前项目的project_id
        ws.onopen = function () {
            ws.send(JSON.stringify({
                'action': 'init',
                'values': { 'project_id': projectID }
            }))
        }

        ws.onclose = function () {
            createWebSocket(wsUrl, projectID, onMessage, wsObj);
        }
        ws.onerror = function () {
            createWebSocket(wsUrl, projectID, onMessage, wsObj);
        }

        //获取ws服务器推送的数据
        ws.onmessage = function (e) {
            try {
                const message = JSON.parse(e.data)
                if (onMessage) {
                    onMessage(message);
                }
            } catch (e) {
                console.warn(e.message)
            }
        }
    } catch (e) {
        createWebSocket(wsUrl, projectID, onMessage, wsObj);
    }
}
