<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  />
</template>

<script>
  import Broadcasts from "../../../../components/broadcasts";
  import { tech } from "../../../../api_tech";

  export default {
    name: 'QualitySub1Broadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['在施项目数量', '成果目标', '成果完成'],
        broadcastColors: ['#40f8ff', '#38c59e', '#ffd454'],
        broadcastIcons: ['在施项目数量', '成果目标', '成果完成'],
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    },
  }

  export function updateData(container, scheduleNextUpdate) {
    tech
      .getScienceStatistic()
      .then((d) => {
        container.broadcastData.techSub1 = d;
        container.broadcastData.techSub1.value1 = d.orgNum;
        container.broadcastData.techSub1.value2 = d.cgmb;
        container.broadcastData.techSub1.value3 = d.cgwc;
        container.statsData = d;
        // scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
