<template>
  <div style="position:absolute;width:100%">
    <el-menu
      class="menu-block"
      mode="horizontal"
      :default-active="navMenuIndex"
      @select="handleNavMenuSelect"
      background-color="transparent"
      text-color="#fff"
      active-text-color="#fff"
    >
      <el-menu-item
        v-for="(compTitle, index) in componentList"
        :index="index + 1 + ''"
        :style="{'position': 'absolute', 'left': navMenuLeftMargins[index] + 'rem'}"
        :key="compTitle"
      >{{compTitle}}</el-menu-item>
    </el-menu>

    <div class="sub-menu" :style="{'position': 'absolute', 'left': subMenuLeftMargins[navMenuIndex - 1], 'right': subMenuRightMargins[navMenuIndex - 1]}" v-if="hasSubMenus[navMenuIndex - 1]">
      <div class="sub-menu-item" v-for="(key, index) in Object.keys(components[componentList[navMenuIndex - 1]].children)" :key="key" :style="{color: navSubMenuIndex == index ? '#02e0dc' : '#ffffff'}" @click="switchSubMenu(navMenuIndex, index)">{{key}}</div>
    </div>

    <div id="main" v-show="!(components[componentList[navMenuIndex - 1]].children ? components[componentList[navMenuIndex - 1]].children[subComponentLists[navMenuIndex - 1][navSubMenuIndex - 0]].pageContent : components[componentList[navMenuIndex - 1]].pageContent) && sceneReady" :style="{marginTop: '3.2rem'}">
      <div v-show="!isZk && (components[componentList[navMenuIndex - 1]].children ? components[componentList[navMenuIndex - 1]].children[subComponentLists[navMenuIndex - 1][navSubMenuIndex - 0]].left : components[componentList[navMenuIndex - 1]].left)">
        <component
          :is="`Comp${navMenuIndex}Sub${navSubMenuIndex}Left`"
          :projectDetail="projectDetail"
          :wearMap="wearMap"
          :motionMap="motionMap"
          :batteryMap="batteryMap"
          :statusColorMap="statusColorMap"
          :retrieveStatusMap="retrieveStatusMap"
          :groupList="groupList"
          :statsData="statsData"
          :event="event"
          :eventCounter="eventCounter"
          :eventData="eventData"
          :dispatchEvent="dispatchEvent"
          :showTrace="(workerId, workerName) => showTrace(workerId, workerName)"
          :worProjectId="worProjectId"
        />
      </div>

      <div id="middle" :style="{width: isZk ? '110rem' : '67rem'}">
        <div v-if="(components[componentList[navMenuIndex - 1]].children ? components[componentList[navMenuIndex - 1]].children[subComponentLists[navMenuIndex - 1][navSubMenuIndex - 0]].map : components[componentList[navMenuIndex - 1]].map) == 'hrMap'">
          <div v-if="components[componentList[navMenuIndex - 1]].children ? components[componentList[navMenuIndex - 1]].children[subComponentLists[navMenuIndex - 1][navSubMenuIndex - 0]].broadcast : components[componentList[navMenuIndex - 1]].broadcast">
              <component
                :is="`Comp${navMenuIndex}Sub${navSubMenuIndex}Broadcast`"
                :broadcastData="broadcastData[currPageName] || {}"
                :worProjectId="worProjectId"
                :event="event"
                :eventCounter="eventCounter"
                :eventData="eventData"
                :dispatchEvent="dispatchEvent"
              />
          </div>
          <hrMap :worProjectId="worProjectId"></hrMap>
          <div class="menus">
              <div class="zk-btn" :class="{'zk-btn-sq': isZk}" @click="isZk = !isZk" />
            </div>
        </div>
        <div id="map" v-show="(components[componentList[navMenuIndex - 1]].children ? components[componentList[navMenuIndex - 1]].children[subComponentLists[navMenuIndex - 1][navSubMenuIndex - 0]].map : components[componentList[navMenuIndex - 1]].map) == ''">
          <wrapper>
            <div class="broadcast-items" :style="{width: isZk ? '97.05rem' : '50rem'}" v-show="components[componentList[navMenuIndex - 1]].children ? components[componentList[navMenuIndex - 1]].children[subComponentLists[navMenuIndex - 1][navSubMenuIndex - 0]].broadcast : components[componentList[navMenuIndex - 1]].broadcast">
              <component
                :is="`Comp${navMenuIndex}Sub${navSubMenuIndex}Broadcast`"
                :broadcastData="broadcastData[currPageName] || {}"
                :worProjectId="worProjectId"
                :event="event"
                :eventCounter="eventCounter"
                :eventData="eventData"
                :dispatchEvent="dispatchEvent"
              />
            </div>
            <div class="build_list" v-show="!tracePlaying3D && map_list.length<3">
              <div class="building-info" v-for="info in buildingInfo" :key="info.build_id">
                <h3>
                  <router-link  v-if="!use3D && info.type==1" class="building-link" :to="`/building/${info.build_id}/${$route.params.id}?token=${$route.query.token}&project=${encodeURIComponent(projectInfo.name)}&nav=${navMenuIndex}&sub=${navSubMenuIndex}&from_org=${fromOrg}&use3d=${$window.use3D}`">
                    {{info.build_name}}
                  </router-link>
                  <a class="building-link" v-if="use3D && info.type==1" :style="{textDecoration: info.build_name == '地下室' ? 'none' : 'underline'}" @click="sceneBldgSwitch(info.build_name)">{{info.build_name}}</a>
                  <a class="building-link" v-if="info.type==2">{{info.build_name}}</a>
                </h3>
                <p v-if="info.type==1">
                  <span class="title">总楼层：</span>
                  <span class="light-blue-text value">{{info.map_count}}层</span>
                </p>
                <p>
                  <span class="title">当前人数：</span>
                  <span class="light-blue-text value">{{info.worker_count}}</span>
                </p>
                <!-- <div>
                  <p>人员分布
                    <span class="light-blue-text">TOP3</span>：</p>
                  <p class="light-blue-text">{{info.map_worker_list}}</p>
                </div> -->
              </div>
            </div>
            <div v-show="!use3D" class="project_map" id="mapid" :style="{backgroundImage: `url(${projectImage})`, height: '55rem'}" />
            <div v-show="use3D" class="project_map" id="mapid_3d" :style="{backgroundImage: `url(${projectImage})`, height: '55rem'}" />
            <TraceHistory v-show="tracePlaying3D" :workerId="pathWorkerId3D" :workerName="pathWorkerName3D" :stop="stopTracePlaying3D" />
            <div class="map_list">
              <div style="width:11rem;">
                <CustomSelect v-if="!!map_list.length" :options="map_list.map(item=>item.name)" @input="mapSelect" />
              </div>
              <el-checkbox v-if="use3D" v-model="globalTransparent3D" @change="sceneSwitchTransparency" style="margin-left:1rem"><span style="font-size:0.95rem">全场景透明</span></el-checkbox>
              <el-checkbox v-if="use3D && config3D.groundHeightRanges" v-model="hideGround3D" @change="sceneShowHideGround" style="margin-left:1rem"><span style="font-size:0.95rem">隐藏地面</span></el-checkbox>
              <div class="stats_btn" @click="statsShow = true;">工期统计</div>
            </div>
            <div v-if="use3D" class="map_compass" :style="{transform: `rotate(${compassRotation}deg)`}" style="cursor:pointer" @click="resetScene">
              <img src="../../assets/compass.png" alt="" />
            </div>
            <div v-if="currPageName == 'security' || currPageName == 'securityCheck'" id="xunjian" :style="{left: use3D ? '110px' : '15px'}" @click="patrolShow = true;">
              <div style="font-size:1.2rem;font-weight:bold;color:#e5c358" :style="{opacity: statsData.ebox_check_rate == null ? 0 : 1}">{{statsData.ebox_check_rate}}%</div>
              <div style="font-size:0.8rem;margin-top:0.3rem">电箱巡检率</div>
            </div>
            <div v-if="currPageName == 'labor'" id="chuqin" :style="{left: use3D ? '110px' : '15px'}">
              <div style="font-size:1.2rem;font-weight:bold;color:#27e6ab" :style="{opacity: statsData.attendance_rate == null ? 0 : 1}">{{Math.round(statsData.attendance_rate)}}%</div>
              <div style="font-size:0.8rem;margin-top:0.3rem">今日出勤率</div>
            </div>
            <div class="menus">
              <div class="zk-btn" :class="{'zk-btn-sq': isZk}" @click="isZk = !isZk" />
            </div>
          </wrapper>
        </div>
      </div>

      <div v-show="!isZk && (components[componentList[navMenuIndex - 1]].children ? components[componentList[navMenuIndex - 1]].children[subComponentLists[navMenuIndex - 1][navSubMenuIndex - 0]].right : components[componentList[navMenuIndex - 1]].right)">
        <component
          :is="`Comp${navMenuIndex}Sub${navSubMenuIndex}Right`"
          :projectDetail="projectDetail"
          :liveData="liveData"
          :onSiteWorkerTypeInfo="onSiteWorkerTypeInfo"
          :warningStats="warningStats"
          :warningList="warningList"
          :statusColorMap="statusColorMap"
          :event="event"
          :eventCounter="eventCounter"
          :eventData="eventData"
          :dispatchEvent="dispatchEvent"
          :showTrace="(workerId, workerName, date) => showTrace(workerId, workerName, date)"
          :wsObj="wsObj"
        />
      </div>
    </div>

    <div v-for="pcc in pageContentComps" :key="pcc[0]" class="main-block" v-show="navMenuIndex == pcc[1] && navSubMenuIndex == pcc[2]">
      <component
        :is="pcc[0]"
        :heatmapReady="heatmapReady"
        :event="event"
        :eventCounter="eventCounter"
        :eventData="eventData"
        :dispatchEvent="dispatchEvent"
        :compData="components[componentList[pcc[1] - 1]].children ? components[componentList[pcc[1] - 1]].children[subComponentLists[pcc[1] - 1][pcc[2] - 0]] : components[componentList[pcc[1] - 1]]"
      />
    </div>
    <div class="mantle" v-if="show_alarm.is_show"></div>
    <div id="show_alarm" class="show_alarm" v-if="show_alarm.is_show">
      <div class="show_alarm_title">{{show_alarm.title}}</div>
      <div><img src="../../assets/sos.png" class="show_alarm_img" v-if="show_alarm.img_show_alarm"></div>
      <div><img src="../../assets/down.png" class="show_alarm_img" v-if="!show_alarm.img_show_alarm"></div>
      <div class="show_alarm_content">
        <div>{{show_alarm.name}}</div>
        <div>{{show_alarm.location}}</div>
        <div>{{show_alarm.mobile}}</div>
      </div>
      <div class="show_alarm_sure" @click="closealarm">确定</div>
    </div>
    <el-dialog custom-class="stats-layer" :visible.sync="pathShow" :close-on-click-modal="true" width="90rem" top="5rem">
      <wrapper style="backgroundColor:#0e1328;position:relative;padding:0">
        <div class="stats-close-btn" @click="pathShow = false" />
        <div style="position:absolute;top:1rem;left:1rem;font-size:1.5em;">人员轨迹 - {{pathWorkerName}}</div>
        <WorkerPath :workerId="pathWorkerId" :date="pathDate" :mapUrl="pathShow ? (img_host + (travel_url || map_list[0].image_url)) : ''" :mapOptions="extraMapOptions" />
      </wrapper>
    </el-dialog>
    <el-dialog custom-class="stats-layer" :visible.sync="workerInfoShow" width="1500px" top="12rem">
      <wrapper style="height:690px;backgroundColor:#0e1328;position:relative">
        <iframe :src="workerInfoPType == 1 ? `http://smarthat.comprehend.com.cn/html/screen/manager_detail.html?worker_id=${workerInfoId}&token=${$route.query.token}` : `http://smarthat.comprehend.com.cn/html/screen/worker_detail.html?worker_id=${workerInfoId}&type_index=1&token=${$route.query.token}`" width="1500px" height="690px" style="border:0;position:absolute;left:0;top:0"></iframe>
        <div class="stats-close-btn" @click="workerInfoShow = false" />
      </wrapper>
    </el-dialog>
    <div id="callback-dialog">
      <el-dialog :visible.sync="showCallBackDialog" :show-close="false" center custom-class="recall-dialog">
        <div style="display:flex;align-items:center">
          <div style="flex:1" />
          <p class="warning-icon"></p>
          <p style="margin-bottom:3.5rem;margin-left:3rem;">是否确认召回{{callBackPerson}}？</p>
          <div style="flex:1" />
        </div>
        <div style="text-align:center">
          <div class="cancel-button" @click="showCallBackDialog = false">取消</div>
          <div class="confirm-button" @click="callBack">确定</div>
        </div>
      </el-dialog>
    </div>
    <div id="recall-dialog">
      <el-dialog :visible.sync="showRecallDialog" :show-close="false" center custom-class="recall-dialog">
        <div style="display:flex;align-items:center">
          <div style="flex:1" />
          <p class="warning-icon"></p>
          <p style="margin-bottom:3.5rem;margin-left:3rem;">是否确认执行全体撤离操作？</p>
          <div style="flex:1" />
        </div>
        <div style="text-align:center">
          <div class="cancel-button" @click="showRecallDialog = false">取消</div>
          <div class="confirm-button" @click="retrieveAll">确定</div>
        </div>
      </el-dialog>
    </div>
    <el-dialog custom-class="stats-layer" :visible.sync="patrolShow" :close-on-click-modal="true" width="90rem" top="5rem">
      <wrapper style="backgroundColor:#0e1328;position:relative;padding:0;height: 60rem;">
        <div
            class="stats-close-btn"
            @click="patrolShow = false"
        />
        <div style="padding: 2rem;">
          <div style="font-size:1.2rem;font-weight:600">电箱详情</div>
          <Patrol />
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require('echarts-gl')
import Patrol from './patrol';
import WorkerPath from './path';
import TraceHistory from './trace_history';
import wrapper from '../../components/wrap'
import CustomSelect from '../../components/select'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import { project, attendance, building, wsUrl } from '../../api'
import {
  init,
  init_mapid,
  changeMap,
  createWebSocket,
  setMapMarker,
//  loadBuildingFloors,
  showHideLayer,
  wcallback
} from '../../../public/leaflet.js'
import getConfig from '../../../public/projects/index';
import { createWebSocket as createWs } from '../../../public/websocket'
//import {mapRatio} from '../../conf';
import '../../../public/blinkmarker.css';
import hrMap from './hr/index/hrMap'

const wsUrl2 = process.env.VUE_APP_WS_KQ_URL;

const color = ['#FC619D', '#FF904D', '#48BFE3', '#1990ff', '#28abc2']
const rich = {}
color.forEach((c, i) => {
  rich['a' + i] = {
    color: '#fff',
    backgroundColor: c,
    borderRadius: 100,
    padding: [6, 8]
  }
})

const workerTypeColorTemplates = {
  '电梯司机': '#86fbff',
  '机电工': '#5d96ff',
  '水电工': '#4169ff',
  '信号工': '#3595e7',
  '木工': '#fdf600',
  '泥工': '#f68800',
  '钢筋工': '#58ffc4',
  '混凝土工': '#c081ff',
  '其他': '#9eff6a',
  '杂工': '#f88ff0',
  '塔司': '#47cdff',
  '架子工': '#0060ff',
  '项目部管理员': '#ffffff',
  '_': ['#ff756e', '#ff6600', '#f12fd6', '#8c4dff', '#d1b8ff', '#a4ffde', '#bbf9d1', '#1aff76', '#00ff8a', '#049a63', '#178724', '#379e9f', '#a76d10', '#a78910', '#faca39', '#f5d984', '#f5ff9e', '#fffac1'],
};

const workerOnThreeColorMap = {
  '': [0, 255 / 255, 0],
  '黄色': [233 / 255, 191 / 255, 5 / 255],
  '红色': [220 / 255, 40 / 255, 43 / 255],
  '白色': [255 / 255, 255 / 255, 255 / 255],
  '蓝色': [55 / 255, 162 / 255, 218 / 255],
}

function addOverflowTooltip(selector) {
  setTimeout(() => {
    document.querySelectorAll(selector).forEach(function(item) {
      if (!item.getAttribute('listener')) {
        item.addEventListener('mouseenter', function() {
          let newAttribute;
          if (item.offsetWidth < item.scrollWidth) {
            if (item.getAttribute('title') !== item.innerHTML) {
              newAttribute = item.innerHTML;
            } else {
              return;
            }
          } else {
            newAttribute = '';
          }
          item.setAttribute('title', newAttribute);
        });
        item.setAttribute('listener', true);
      }
    });
  }, 30);
}

export default {
  name: 'projectMain',
  components: {
    'v-chart': ECharts,
    wrapper,
    CustomSelect,
    Patrol,
    WorkerPath,
    TraceHistory,
    hrMap
  },
  props: {
    fromOrg: Number,
    componentList: Array,
    subComponentLists: Array,
    components: Object,
    compDict: Object,
    hasSubMenus: Array,
    pageContentComps: Array,
    navMenuLeftMargins: Array,
    subMenuLeftMargins: Array,
    subMenuRightMargins: Array,
    projectInfo: Object,
  },
  data() {
    return {
      navMenuIndex: "1",
      navSubMenuIndex: "0",
      currPageName: 'project',
      isZk: false,
      broadcastData: {},
      statsData: {},
      event: '',
      eventData: null,
      eventCounter: 0,
      use3D: false,
      config3D: {},
      last3DMap: null,
      last2DMap: null,
      globalTransparent3D: false,
      hideGround3D: false,
      sceneReady: true,
      sceneLoaded: false,
      heatmapReady: false,
      sceneToolbox: null,
      compassRotation: 0,
      tracePlaying3D: false,
      stopTracePlaying3D: null,
      workerTypeColors: {},
      show_alarm: {
        is_show:false,
        title:"紧急呼救",
        name:"吴德龙（浇铸工组）",
        location:"3号楼32层",
        mobile:"18625893654",
        img_show_alarm:true,
      },
      s_build_id: '',
      s_index: 0,
      statusColorMap: {
        正常: { value: '#1fc86b', cls: 'green' },
        呼救: { value: '#f34e53', cls: 'red2' },
        坠落: { value: '#e62c31', cls: 'red1' },
        预警: { value: '#eebc34', cls: 'yellow2' },
        禁区预警: { value: '#eebc34', cls: 'yellow2' },
        危险预警: { value: '#eebc34', cls: 'yellow2' },
        闯入禁区: { value: '#eebc34', cls: 'yellow2' },
        静止: { value: '#eebc34', cls: 'yellow2' },
        倒地: { value: '#F89132', cls: 'yellow1' },
        离线: { value: '#9ca0aa', cls: 'grey' },
        没电: { value: '#eebc34', cls: 'yellow2' }
      },
      extraMapOptions: {},
      warningList: [],
      warningStats: {},
      liveData: {
        worker_count: 0,
        check_worker_count: 0,
        hat_count: 0,
        point_count: 0
      },
      equipStatusChart: {
        title: [
          {
            text: '安全帽',
            left: '23%',
            top: '88%',
            textAlign: 'center',
            textStyle: {
              fontWeight: 'normal',
              fontSize: '14',
              textAlign: 'center',
              color: '#fff'
            }
          },
          {
            text: '围栏设备',
            left: '77%',
            top: '88%',
            textAlign: 'center',
            textStyle: {
              fontWeight: 'normal',
              fontSize: '14',
              textAlign: 'center',
              color: '#fff'
            }
          }
        ],
        graphic: {
          elements: [
            {
              type: 'image',
              id: 'hat',
              style: {
                image: require('../../assets/anquanmao.png'),
                width: 44,
                height: 44
              },
              left: '15%',
              top: '35%'
            },
            {
              id: 'equip',
              type: 'image',
              style: {
                image: require('../../assets/shebei1.png'),
                width: 44,
                height: 44
              },
              left: '69%',
              top: '37%'
            }
          ]
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '58%'],
            center: ['23%', '50%'],
            color: ['#687387', '#db2866'],
            data: [],
            labelLine: {
              normal: {
                show: false,
                length: 20,
                length2: 20,
                lineStyle: {
                  color: '#12EABE',
                  width: 2
                }
              }
            },
            label: {
              normal: {
                show: false
              }
            }
          },
          {
            type: 'pie',
            radius: ['50%', '58%'],
            center: ['77%', '50%'],
            color: ['#687387', '#db2866'],
            data: [],
            labelLine: {
              normal: {
                show: false,
                length: 20,
                length2: 20,
                lineStyle: {
                  color: '#12EABE',
                  width: 2
                }
              }
            },
            label: {
              normal: {
                show: false
                // formatter: '{c|{c}}\n{hr|}\n{d|{d}%}',
              }
            }
          }
        ]
      },
      mostAlertPeopleChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: '{b}<br/> {c}'
        },
        grid: {
          top: '9%',
          left: -25,
          right: 0,
          bottom: 0,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            color: '#ccc',
            fontSize: 14
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          show: false
        },
        label: {
          show: true,
          position: 'top',
          distance: 5,
          color: '#51bcf5',
          formatter: '{c}'
        },
        series: [
          {
            data: [],
            type: 'bar',
            itemStyle: {
              color: '#B43038',
              barBorderRadius: 100
            },
            barCategoryGap: '50%'
            // barWidth: 12,
          }
        ]
      },
      classAttendanceChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: 0,
          right: 0,
          top: '9%',
          bottom: 0,
          containLabel: true
        },
        yAxis: {
          type: 'value',
          show: true,
          axisLine: { show: true, lineStyle: { color: '#36445f', width: 2 } },
          splitLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            color: '#fff',
            fontSize: 12
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: { color: '#36445f', width: 2 }
          }
        },
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: 30,
            itemStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#f99e34' },
                { offset: 1, color: '#f5c551' }
              ])
            },
            label: {
              normal: {
                show: true,
                position: 'top',
                color: '#eaa24e'
              }
            },
            data: []
          }
        ]
      },
      building: {
        buildingList: [],
      },
      buildingInfo: [
        {
          build_id: '',
          build_name: '',
          location: {},
          floor_height: '',
          map_count: 0,
          worker_count: 0,
          map_worker_list: ''
        }
      ],
      projectImage: '',
      timer: null,
      timer2: null,
      broadcastTimer: null,
      map_list: [],
      img_host: '',
      travel_url: '',
      pathShow: false,
      pathWorkerId: '',
      pathWorkerName: '',
      pathWorkerId3D: '',
      pathWorkerName3D: '',
      pathDate: undefined,
      workerInfoId: '',
      workerInfoPType: 2,
      workerInfoShow: false,
      statsShow: false,
      wearMap: [{ cls: 'red2', text: '未佩戴' }, { cls: 'green', text: '佩戴' }],
      retrieveStatusMap: {
        11: { text: '召回', cls: '' },
        7: { text: '已收到', cls: 'disabled' },
        8: { text: '已确认', cls: 'disabled' },
        10: { text: '发送中', cls: 'disabled' }
      },
      motionMap: {
        运动: 'green',
        静止: 'green',
        倒地: 'yellow1',
        坠落: 'red1',
        没电:'yellow1',
      },
      showCallBackDialog: false,
      showRecallDialog: false,
      showPrompt: false,
      groupList: [{ worker_list: [], show: true }],
      workerMap: {},
      lastUpdatedSceneWorkers: 0,
      projectDetail: {
        hatCount: 0,
        checkinCount: 0,
        checkinOnSiteCount: 0,
      },
      onSiteWorkerTypeInfo: [],
      wsObj: {},
      callBackPerson: '',
      callBackFunc: null,
      patrolShow: false,
      worProjectId:""
    }
  },
  watch: {
    navMenuIndex: function(newValue, oldValue) {
      this.commonBroadcastWatch(newValue, oldValue);
    },
    navSubMenuIndex: function(newValue, oldValue) {
      this.commonBroadcastWatch(newValue, oldValue);
    },
    $route: function(newValue, oldValue) {
      if (newValue.params.id != oldValue.params.id) {
        this.initProject();
      } else if (newValue.params.nav != this.navMenuIndex) {
        this.navMenuIndex = newValue.params.nav;
      }
    },
  },
  created() {
    this.$options.components = Object.assign(this.$options.components, this.compDict);
  },
  mounted() {
    this.initProject();
  },
  methods: {
    initProject() {
      const { id: projectID } = this.$route.params;
      const self = this;
      const token = this.$route.query.token;
      window.token = token;
      window.beaconMode = this.$route.query.beacon;
      window.use3D = parseInt(this.$route.query.use3d || 0);
      window.selectedBldg = this.$route.query.bldg;
      window.selectedFloor = this.$route.query.floor;
      window.debugMode = this.$route.query.debug;
      window.projectAPI = project;
      window.buildingAPI = building;
      this.worProjectId = this.$route.query.xmid;

      this.navMenuIndex = this.$route.params.nav || "1";
      this.navSubMenuIndex = this.$route.params.subNav || "0";

      if (this.components[this.componentList[this.navMenuIndex - 1]].children && this.navSubMenuIndex != '0') {
        this.switchSubMenu(this.navMenuIndex, this.navSubMenuIndex, true);
      } else {
        this.navSubMenuIndex = "0";
        this.handleNavMenuSelect(this.navMenuIndex, null, null, true);
      }

      createWs(wsUrl2, projectID, message => {
        if (message && message.errcode === 0) {
          if (message.source == "customer_change") {
            const data = message.data;
            this.onSiteWorkerTypeInfo = data.in_team_list;
          }
        }
      });
      this.updateProjectDetail(projectID);
      this.updateOnSiteWorkerTypeInfo(projectID);

      createWs(wsUrl, projectID, message => {
        try {
          self.dataParser(message)
        } catch (e) {
          console.warn(e.message)
          self.$message.error('服务器返回数据错误')
        }
      }, this.wsObj);

      this.updateBroadcast();

      project.getAllWorkers(projectID)
        .then(d => {
          const workerMap = {};
          for (const w of d) {
            workerMap[w.innerid] = w;
          }
          this.workerMap = workerMap;
          setTimeout(() => {
            this.updateSceneWorkers();
          }, 3000);
        })
        .catch(e => console.error(e.msg || e.message));


      building.getWorkList(projectID)
        .then(list => {
          self.groupList = list
          self.groupList.forEach((group, idx) => {
            if (group.worker_list.length) {
              group.show = idx === 0;
              group.worker_list.forEach(worker => {
                worker.orderID = 100
                self.dataParser({ data: [worker], errcode: 0, source: 'customer_update' }, true)
              })
            }
          })
        })
        .catch(e => console.error(e.msg || e.message));

      project
        .getWarningList(projectID)
        .then(d => {
          this.warningList = d;
          addOverflowTooltip('#people-warning-list .value');
        })
        .catch(e => console.error(e.msg || e.message))
      project.getWarningStats(projectID)
        .then(d => {
          for (const item of d) {
            this.warningStats[item.alarm_type] = item.count;
          }
        })
        .catch(e => console.error(e.msg || e.message))
      project
        .getLiveData(projectID)
        .then(d => (this.liveData = d))
        .catch(e => console.error(e.msg || e.message))

      this.renderMap();

      if (this.timer) {
        clearInterval(this.timer);
      }
      if (this.timer2) {
        clearInterval(this.timer2);
      }
      if (this.broadcastTimer) {
        clearTimeout(this.broadcastTimer);
      }

      this.timer = setInterval(() => {
        self.updateProjectDetail(projectID);
        self.updateOnSiteWorkerTypeInfo(projectID);
        project
          .getWarningList(projectID)
          .then(d => {
            this.warningList = d;
            addOverflowTooltip('#people-warning-list .value');
          })
          .catch(e => console.error(e.msg || e.message))
        project.getWarningStats(projectID)
          .then(d => {
            for (const item of d) {
              this.warningStats[item.alarm_type] = item.count;
            }
          })
          .catch(e => console.error(e.msg || e.message))
        project
          .getLiveData(projectID)
          .then(d => (this.liveData = d))
          .catch(e => console.error(e.msg || e.message))
        project
          .getBuildingBaseInfo(projectID)
          .then(d => {
            this.buildingInfo = d.build_list
            //this.map_list = d.show_map
            this.travel_url = d.travel_url;
            this.use3D && this.sceneToolbox && this.sceneToolbox.updateBuildingWorkerCount && this.sceneToolbox.updateBuildingWorkerCount();

            // L.imageOverlay(d.project_map, bounds).addTo(map)
            // map.fitBounds(bounds)
            // this.projectImage = d.project_map
          })
          .catch(e => console.error(e.msg || e.message))
      }, 1000*15);
      this.timer2 = setInterval(()=>{
        project.getAllWorkers(projectID)
          .then(d => {
            const workerMap = {};
            for (const w of d) {
              workerMap[w.innerid] = w;
            }
            this.workerMap = workerMap;
            this.updateSceneWorkers();
          })
          .catch(e => console.error(e.msg || e.message));

        building.getWorkList(projectID)
        .then(list => {
          self.groupList = list
          self.groupList.forEach((group, idx) => {
            if (group.worker_list.length) {
              group.show = idx === 0;
              group.worker_list.forEach(worker => {
                worker.orderID = 100
                self.dataParser({ data: [worker], errcode: 0, source: 'customer_update' }, true)
              })
            }
          })
        })
        .catch(e => console.error(e.msg || e.toString()))
      }, 1000*60);
    },
    renderMap() {
      const { id: projectID } = this.$route.params;
      //const self = this;
      project
        .getBuildingBaseInfo(projectID)
        .then(d => {
          this.buildingInfo = d.build_list
          this.map_list = d.show_map
          this.img_host = d.img_host
          this.travel_url = d.travel_url;
          this.s_index = 0;
          this.s_build_id = this.map_list[0].build_id;

          this.extraMapOptions = {
            mapWidth: this.map_list[0].width,
            mapHeight: this.map_list[0].height,
          };

          //创建ws socket连接
          createWebSocket('mapid', wsUrl, projectID)
          //加载地图
          let use3D = window.use3D;
          let configIdx;
          if (use3D) {
            const config3D = getConfig(projectID);
            if (Array.isArray(config3D)) {
              // Update this.map_list and add 2d variants to 3d maps.
              for (let i = this.map_list.length - 1; i >= 0; i--) {
                for (let j = 0; j < config3D.length; j++) {
                  const item = config3D[j];
                  if (!!item && item.show2DVariant && (!item.buildId || item.buildId == this.map_list[i].build_id)) {
                    this.map_list.splice(i, 0, {...this.map_list[i]})
                    this.map_list[i].name += '（3D）';
                    this.map_list[i + 1].name += '（2D）';
                    this.map_list[i + 1].force2D = true;
                    break;
                  }
                }
              }

              // Determine whether the current map is 3d or 2d.
              for (let i = 0; i < config3D.length; i++) {
                const item = config3D[i];
                use3D = !!item && (!item.buildId || item.buildId == this.s_build_id);
                if (use3D) {
                  this.config3D = item;
                  configIdx = i;
                  break;
                }
              }
            } else {
              // Update this.map_list and add 2d variants to 3d maps.
              for (let i = this.map_list.length - 1; i >= 0; i--) {
                if (!!config3D && config3D.show2DVariant && (!config3D.buildId || config3D.buildId == this.map_list[i].build_id)) {
                  this.map_list.splice(i, 0, {...this.map_list[i]})
                  this.map_list[i].name += '（3D）';
                  this.map_list[i + 1].name += '（2D）';
                  this.map_list[i + 1].force2D = true;
                  break;
                }
              }

              // Determine whether the current map is 3d or 2d.
              use3D = !!config3D && (!config3D.buildId || config3D.buildId == this.s_build_id);
              if (use3D) {
                this.config3D = config3D;
              }
            }
          }

          this.use3D = use3D;
          if (use3D) {
            const self = this;
            this.last3DMap = this.s_build_id;
            init(
              'mapid_3d',
              this.img_host + this.map_list[0].image_url,
              this.s_build_id,
              this,
              function() {
                self.sceneLoaded = true;
              }, {
                enableImage: false,
                enableMarkers: false,
                enable3D: true,
                configIdx: configIdx,
                sceneData: {
                  ta: this,
                  projectId: projectID,
                  getBuildingList: () => { return this.buildingInfo },
                  is3DSceneVisible: () => {
                    return this.use3D && this.sceneReady && this.sceneLoaded;
                  },
                  setGlobalTransparencyChecked: (val) => {
                    this.globalTransparent3D = val;
                  }
                }
              }
            );
            init_mapid(
              'mapid',
              this.img_host + this.map_list[0].image_url,
              this.s_build_id,
              this
            );
          } else {
            this.config3D = {};
            this.last2DMap = this.s_build_id;
            init(
              'mapid',
              this.img_host + this.map_list[0].image_url,
              this.s_build_id,
              this,
              function(e) {
                //加载当前楼层的人员名单
                project
                  .getWorkerList(e)
                  .then(list => {
                    setMapMarker('mapid', list, "oninit");
                  })
                  .catch(e => console.error(e.msg || e.toString()));

                //self.loadBuildings();
              },
              this.extraMapOptions,
            )
          }
        })
        .catch(e => console.error(e.msg || e.message));
    },
    dispatchEvent(event, data) {
      this.event = event;
      this.eventData = data;
      this.eventCounter++;
    },
    commonBroadcastWatch(newValue, oldValue) {
      if (newValue != oldValue) {
        if (this.timer) {
          clearTimeout(this.timer);
        }

        this.updateBroadcast();
      }
    },
    scheduleNextBroadcast() {
      this.broadcastTimer = setTimeout(() => {
        this.updateBroadcast();
      }, 5000);
    },
    updateBroadcast() {
      let comp = this.components[this.componentList[this.navMenuIndex - 1]];
      if (comp.children) {
        comp = this.components[this.componentList[this.navMenuIndex - 1]].children[this.subComponentLists[this.navMenuIndex - 1][this.navSubMenuIndex - 0]];
      }

      if (comp.broadcast) {
        comp.updateBroadcastData(this, () => {
          this.scheduleNextBroadcast();
        });
      }
    },
    switchSubMenu(navMenuIndex, option, skipURLChange) {
      if (!skipURLChange) {
        const { id: projectID } = this.$route.params;
        this.$router.replace({
          path:`/project2/${projectID}/${navMenuIndex}/${option}?token=${window.token}&use3d=${window.use3D}`,
          query:{
            xmid:this.$route.query.xmid
          }
        });
      }
      this.navMenuIndex = navMenuIndex;
      this.navSubMenuIndex = option;

      try {
        let comp = this.components[this.componentList[navMenuIndex - 1]];
        if (comp.children) {
          comp = this.components[this.componentList[navMenuIndex - 1]].children[this.subComponentLists[navMenuIndex - 1][option - 0]];
        }
        this.currPageName = comp.pageName;
      } catch(e) {
        this.currPageName = '';
      }
    },
    handleNavMenuSelect(key, keyPath, component, skipURLChange) {
      if (!skipURLChange) {
        const { id: projectID } = this.$route.params;
        this.$router.replace({
          path:`/project2/${projectID}/${key}/0?token=${window.token}&use3d=${window.use3D}`,
          query:{
            xmid:this.$route.query.xmid
          }
        });
      }
      this.navMenuIndex = key;
      this.navSubMenuIndex = '0';

      try {
        let comp = this.components[this.componentList[key - 1]];
        if (comp.children) {
          comp = this.components[this.componentList[key - 1]].children[this.subComponentLists[key - 1][this.navSubMenuIndex - 0]];
        }
        this.currPageName = comp.pageName;

        if (!comp.pageContent) {
          //this.renderMap();
          this.sceneReady = true;
          setTimeout(() => showHideLayer('mapid_3d', 'sceneLayer', true), 100);
        } else {
          showHideLayer('mapid_3d', 'sceneLayer', false);
          this.sceneReady = false;
        }
      } catch(e) {
        this.currPageName = '';
      }

      if (this.currPageName == 'heatmap') {
        this.heatmapReady = true;
        setTimeout(() => {
          showHideLayer('heatmapid', 'sceneLayer', true);
          showHideLayer('heatmapid', 'heatmapLayer', true);
        }, 100);
      } else {
        showHideLayer('heatmapid', 'sceneLayer', false);
        showHideLayer('heatmapid', 'heatmapLayer', false);
        this.heatmapReady = false;
      }
    },
    /*
    loadBuildings() {
      const self = this;
      building.getBuildings()
        .then(d => {
          d.slice(0, 10).forEach(l => {
            const res = [];
            let floorStart = '';
            let floorEnd = '';
            let ids = [];
            l.floor.forEach((a, i) => {
              if (i % 50 === 0) {
                ids = [a.innerid];
                floorStart = a.name;
              } else {
                ids.push(a.innerid);
              }
              if (i % 50 === 49 || i === l.floor.length - 1) {
                floorEnd = a.name;
                res.push({ name: `${floorStart}~${floorEnd}`, innerid: i, ids });
              }
            });
            l.floor = res;
            self.changeFloor(l.innerid, l.name, res[0]);
          });
          self.building.buildingList = d;
        })
        .catch(e => console.error(e.msg || e.toString()))
    },
    changeFloor(buildingId, buildingName, floor) {
      //if (this.curFloorList === floor.ids) return
      //this.curFloorList = floor.ids
      building.getBuildingFloorData(floor.ids)
        .then(data => {
          const floorNameArr = [];
          const floorDataArr = [];
          const floorIDArr = [];
          data.forEach(d => {
            floorDataArr.push(d.map_data);
            floorNameArr.push(d.name);
            floorIDArr.push(d.innerid);
          });
          const workerPosition = Object.values(this.workerMap).filter(w => floor.ids.filter(id => id === w.floor).length).map(w => ({
            id: w.innerid,
            name: w.name,
            floor: w.floor_name,
            floorID: w.floor,
            position: { x: w.x / mapRatio, y: w.y / mapRatio },
            color: workerOnThreeColorMap[w.color] || workerOnThreeColorMap[''],
            group: w.worker_type,
            status_id: w.status_id,
            hat_code: w.hat_code || '',
            height: w.height,
            mobile: w.mobile,
          }));

          loadBuildingFloors('mapid', workerPosition, { buildingId, buildingName, floorNameArr, floorDataArr, floorIDArr });
        });
    },
    */
    dataParser (data, first) {
      if (data && data.errcode === 0) {
        switch (data.source) {
          case 'customer_update':
            if (this.groupList.length) {
              const user = data.data[0]
              this.groupList.forEach((group, oi) => {
                group.show = group.show === undefined ? (oi === 0) : group.show
                if (group.worker_list.length) {
                  group.worker_list.forEach((worker, ii) => {
                    if (worker.innerid === user.innerid) {
                      if (this.wearMap[user.wear] === undefined || this.statusColorMap[user.alarm]=== undefined || this.retrieveStatusMap[user.recalled] === undefined) {
                        throw new Error(JSON.stringify(user))
                      }
                      let orderID
                      switch (user.status_id) {
                        case 1:
                          orderID = 0
                          break
                        case 2:
                          orderID = 1
                          break
                        case 3:
                          orderID = 1
                          break
                        case 5:
                          orderID = 2
                          break
                        case 12:
                          orderID = 3
                          break
                        case 9:
                          orderID = 4
                          break
                        default:
                          orderID = 100
                      }
                      this.$set(this.groupList[oi].worker_list, ii, Object.assign({ orderID }, user))
                    }
                  })
                  group.worker_list.sort((a, b) => a.orderID - b.orderID)
                }
              });

              if (!first) {
                if (user.x && user.y) {
                  if (user['3d_x'] != '' && user['3d_y'] != '') {
                    this.workerMap[user.innerid] = user;
                  }
                } else {
                  delete this.workerMap[user.innerid];
                }

                if (this.use3D && new Date() / 1000 - this.lastUpdatedSceneWorkers >= 3) {
                  this.updateSceneWorkers();
                  this.lastUpdatedSceneWorkers = new Date() / 1000;
                }
              }
            }
            break
          default:
            console.warn(data.source)
        }
      } else {
        console.error('error code', data.errcode)
      }
    },
    getWorkerPosition () {
      let workers = Object.values(this.workerMap);

      if (this.config3D.buildId) {
        workers = workers.filter(w => w.build == this.s_build_id);
      }

      if (!window.debugMode) {
        workers = workers.filter(w => w['3d_x'] || w['3d_y']);
      }

      workers = workers.filter(w => w['3d_x'] != '' && w['3d_y'] != '');

      return workers.map(w => ({
        id: w.innerid,
        name: w.name,
        area: w.area,
        area_name: w.area_name,
        build_name: w.build_name,
        floor: w.floor_name,
        floorID: w.floor,
        position: {
          x: w['3d_x'], y: w['3d_y']
        },
        color: workerOnThreeColorMap[w.color] || workerOnThreeColorMap[''],
        group: w.worker_type,
        status_id: w.status_id,
        hat_code: w.hat_code || '',
        height: w.height,
        bim_z_height: w.bim_z_height,
        mobile: w.mobile,
        ptype: w.ptype,
      }));
    },
    updateSceneWorkers () {
      this.use3D && this.sceneToolbox && this.sceneToolbox.updateWorkers(this.getWorkerPosition());
    },
    batteryMap (number) {
      if (number * 1 <= 20) {
        return 'red2'
      } else if (number * 1 > 20 && number * 1 < 60) {
        return 'yellow2'
      } else {
        return 'green'
      }
    },
    callBack() {
      // console.log('点击了召回');
      this.showCallBackDialog = false;
      this.callBackFunc && this.callBackFunc();
    },
    changeMap(map_image, type, build_id, m_index, force2D) {
      if (map_image && type && build_id) {
        this.s_index = m_index
        this.s_build_id = build_id

        this.extraMapOptions = {
          mapWidth: this.map_list[m_index].width,
          mapHeight: this.map_list[m_index].height,
        };

        const { id: projectID } = this.$route.params;
        //加载地图
        let use3D = window.use3D && !force2D;
        let configIdx;
        if (use3D) {
          const config3D = getConfig(projectID);
          if (Array.isArray(config3D)) {
            for (let i = 0; i < config3D.length; i++) {
              const item = config3D[i];
              use3D = !!item && (!item.buildId || item.buildId == this.s_build_id);
              if (use3D) {
                this.config3D = item;
                configIdx = i;
                break;
              }
            }
          } else {
            use3D = !!config3D && (!config3D.buildId || config3D.buildId == this.s_build_id);
            if (use3D) {
              this.config3D = config3D;
            }
          }
        }

        if (use3D) {
          if (this.last3DMap != build_id) {
            this.last3DMap = build_id;
            const self = this;
            init(
              'mapid_3d',
              this.img_host + map_image,
              this.s_build_id,
              this,
              function() {
                self.sceneLoaded = true;
                project.getAllWorkers(projectID)
                  .then(d => {
                    const workerMap = {};
                    for (const w of d) {
                      workerMap[w.innerid] = w;
                    }
                    self.workerMap = workerMap;
                    self.updateSceneWorkers();
                  })
                  .catch(e => console.error(e.msg || e.message));
              }, {
                enableImage: false,
                enableMarkers: false,
                enable3D: true,
                configIdx: configIdx,
                sceneData: {
                  ta: this,
                  projectId: projectID,
                  getBuildingList: () => { return this.buildingInfo },
                  is3DSceneVisible: () => {
                    return this.use3D && this.sceneReady && this.sceneLoaded;
                  },
                  setGlobalTransparencyChecked: (val) => {
                    this.globalTransparent3D = val;
                  }
                }
              }
            );
            init_mapid(
              'mapid',
              this.img_host + map_image,
              this.s_build_id,
              this
            );
          }
        } else if (this.use3D) {
          if (this.last2DMap != build_id) {
            this.last2DMap = build_id;
            this.config3D = {};
            this.sceneLoaded = false;
            init(
              'mapid',
              this.img_host + map_image,
              this.s_build_id,
              this,
              function(e) {
                //加载当前楼层的人员名单
                project
                  .getWorkerList(e)
                  .then(list => {
                    setMapMarker('mapid', list, "oninit");
                  })
                  .catch(e => console.error(e.msg || e.toString()));

                //self.loadBuildings();
              },
              this.extraMapOptions,
            )
          }
        } else {
          this.last2DMap = build_id;
          this.config3D = {};
          changeMap('mapid', this.img_host + map_image, type, build_id)

          project
            .getWorkerList(build_id)
            .then(list => {
              setMapMarker('mapid', list)
            })
            .catch(e => console.error(e.msg || e.toString()))
        }

        this.use3D = use3D;
      }
    },
    showTrace(workerId, workerName, date) {
      this.pathWorkerId = workerId;
      this.pathWorkerName = workerName;
      this.pathDate = date;
      this.pathShow = true;
    },
    mapSelect(name) {
      const idx = this.map_list.findIndex(item => item.name == name);
      if (idx >= 0 && idx !== this.s_index) {
        const m = this.map_list[idx];
        this.changeMap(m.image_url, m.type, m.build_id, idx, m.force2D);
      }
    },
    wcallback(item) {
      wcallback('mapid', item.hat_id, item.ctrl_mac, item.channel);
    },
    closealarm() {
      this.show_alarm.is_show = false
    },
    updateProjectDetail(projectID) {
      attendance.getProjectDetail(projectID).then(d => {
        this.projectDetail.hatCount = d.hat_count;
        this.projectDetail.checkinCount = d.checkin_count;
        this.projectDetail.checkinOnSiteCount = d.checkin_on_site_count;
      });
    },
    updateOnSiteWorkerTypeInfo(projectID) {
      attendance.getWorkerTypeInfo(projectID, 1).then(d => {
        this.onSiteWorkerTypeInfo = d;
        const colorMap = {};
        let _index = 0;
        for (const item of d) {
          colorMap[item.worker_type] = workerTypeColorTemplates[item.worker_type] || workerTypeColorTemplates['_'][_index++];
        }
        this.workerTypeColors = colorMap;
      });
    },
    getWorkerTypeColor(worker_type) {
      return this.workerTypeColors[worker_type] || '#ffff00';
    },
    toggleGroup (group) {
      group.show = !group.show
      this.$forceUpdate()
    },
    retrieveAll () {
      this.showRecallDialog = false
      if (this.wsObj.ws) {
        this.wsObj.ws.send(JSON.stringify({ action: 'retreat', values: {project_id: this.wsObj.ws.id}}))
        this.showPrompt = false
      }
    },
    prepareRecall(worker) {
      this.callBackFunc = () => { this.recall(worker); };
      this.callBackPerson = worker.name;
      this.showCallBackDialog = true;
    },
    recall (worker) {
      if (worker.recalled !== 11) return
      if (worker.hat_id === 'null' || worker.hat_id === null) return this.$message.warning('用户帽子id不正确')
      if (this.wsObj.ws) {
        this.wsObj.ws.send(JSON.stringify({
          action: 'recall',
          values: { hat_id: worker.hat_id, ctrl_mac: worker.ctrl_mac, ch: worker.ch }
        }))
      }
    },
    sceneBldgSwitch(build_name) {
      if (this.use3D && this.sceneToolbox) {
        this.sceneToolbox.revealBuilding(build_name);
      }
    },
    sceneShowHideGround(revealIdx) {
      if (this.use3D && this.sceneToolbox) {
        this.sceneToolbox.revealBuilding('ground', revealIdx ? 2 : 0);
      }
    },
    sceneSwitchTransparency(isReveal) {
      if (this.use3D && this.sceneToolbox) {
        this.sceneToolbox.switchGlobalTransparency(isReveal);
      }
    },
    resetScene() {
      if (this.use3D && this.sceneToolbox) {
        this.sceneToolbox.resetScene();
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.timer2) {
      clearInterval(this.timer2);
    }
    if (this.broadcastTimer) {
      clearTimeout(this.broadcastTimer);
    }
  }
}
</script>

<style scoped lang="less">
.show_alarm_sure {
  margin-top: 20px;
  font-size: 15px;
  background-color: #3182ff;
  border-radius: 16px;
  width: 80px;
  height: 32px;
  line-height: 32px;
  margin-left: 120px;
}
.show_alarm_content {
  font-family: NotoSansHans-Medium;
  font-size: 14px;
  color: #ffffff;
  line-height: 23px;
}
.show_alarm_img {
  width: 185px;
  height: 135px;
  margin-top: 23px;
}
.mantle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 999;
}
.show_alarm_title {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  font-family: NotoSansHans-Bold;
  margin-top: 23px;
}
.show_alarm {
  text-align: center;
  top: 50%;
  position: absolute;
  border: 1px solid #151c48;
  left: 50%;
  height: 340px;
  margin-top: -160px;
  width: 320px;
  margin-left: -170px;
  z-index: 1000;
  background-color: #151c48;
  border-radius: 4px;
  opacity: 0.8;
  box-shadow: 0 0 0.2em #ffffff;
}
.broadcast-items {
  top: 0;
  position: absolute;
  width: 50rem;
  z-index: 1;
}
.build_list {
  z-index: 1;
  position: absolute;
  top: 0px;
  right: 5px;
  max-height: 93%;
  overflow-y: scroll;
  padding-right: 5px;
}
.map_compass {
  z-index: 999;
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: 54px;
  height: 54px;
}
#xunjian {
  width: 88px;
  height: 88px;
  background: url("../../assets/nation/巡检率.png");
  background-size: cover;
  position: absolute;
  z-index: 999;
  bottom: 13px;
  left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#chuqin {
  width: 88px;
  height: 88px;
  background: url("../../assets/nation/出勤率.png");
  background-size: cover;
  position: absolute;
  z-index: 999;
  bottom: 13px;
  left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pline {
  background-color: #399a75;
  border-radius: 24px;
  border: none;
  color: #fff;
  padding: 3px 10px;
  cursor: pointer;
  outline: none;
  margin-right: 8px;
}

.map_list {
  z-index: 999;
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
}

.stats_btn {
  color: #fff;
  overflow: hidden;
  border-top: 1px solid #334c88;
  border-right: 1px solid #334c88;
  border-bottom: 1px solid #334c88;
  background-color: #2a4278;
  padding: 0 15px;
  line-height: 1.8rem;
  display: none;
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
  z-index: 30000;
}

.zk-btn {
  background-image: url("../../assets/zk.png");
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 30000;
}

.zk-btn.zk-btn-sq {
  background-image: url("../../assets/sq.png");
}

.text-green {
  color: #1fc86b !important;
}

.text-yellow1 {
  color: #f89132 !important;
  border-color:#f89132 !important;
}

.text-yellow2 {
  color: #eebc34 !important;
  border-color:#eebc34 !important;
}

.text-red1 {
  color: #e62c31 !important;
}

.text-red2 {
  color: #f34e53 !important;
}

.text-grey {
  color: #9ca0aa !important;
}

.building-link,
.building-link:active,
.building-link:link,
.building-link:hover,
.building-link:visited {
  color: #51bbf5;
}

.building-info {
  margin-top: 5px;
  border: 1px solid #5f7ec9;
  padding: 0.5rem;
  z-index: 1;
  border-radius: 0.5rem;
  // display: inline-block;
  // position: absolute;
  // background-image: url("../../assets/bg1.png");
  background: #1e2c61;

  h3 {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0;
  }

  p {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.375rem;

    .title {
      width: 5.5rem;
    }

    .value {
      width: 2.5rem;
      text-align: right;
    }
  }
}

.building-info-other {
  border: 1px solid #5f7ec9;
  padding: 0.23rem;
  z-index: 1;
  display: inline-block;
  position: absolute;
  background: #1e2c61;
  border-radius: 50%;
  opacity: 0.1;

  h3 {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0;
  }

  p {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.375rem;

    .title {
      width: 7rem;
    }

    .value {
      width: 4rem;
      text-align: right;
    }
  }
}

.menu-block {
  position: fixed;
  top: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: none !important;
  pointer-events: none;

  .el-menu-item {
    pointer-events: all;
    padding: 0 12px;
    font-size: 15px;
    border-bottom: none;
    width: 120px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    background: url("../../assets/nation/button.png");
  }

  .el-menu-item.is-active {
    border-bottom: none;
    background: url("../../assets/nation/button_select.png");
  }

  .el-menu-item:not(.is-disabled):hover,
  .el-menu-item:not(.is-disabled):focus {
    border-bottom: none;
    background-color: transparent !important;
  }
}

#system-info {
  position: fixed;
  top: 1rem;
  padding: 0 3rem;
  left: -2rem;
  width: 9rem;
  display: flex;

  .item {
    position: relative;
    flex: 1;
    text-align: center;

    .upper {
      font-size: 1.25rem;
    }

    .lower {
      font-size: 0.75rem;
      color: #cccccc;
      line-height: 1rem;
    }
  }

  .left::after,
  .middle::after {
    content: " ";
    width: 0.125rem;
    height: 0.625rem;
    background-color: #244871;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  .right {
    display: flex;
    justify-content: center;
  }
}

.main-block {
  margin-top: 3.2rem;
  padding: 0rem 1.875rem 0.5rem 1.875rem;
  height: calc(100vh - 2rem);
  overflow-y: scroll;
}

.sub-menu {
  display: flex;
  margin-top: 0.3rem;
}

.sub-menu-item {
  cursor: pointer;
  border: 1px #1a96c4 solid;
  margin-left: 0.2rem;
  font-size: 0.85rem;
  padding: 0.3rem 0.8rem;
}

#main {
  display: flex;
  // padding: 4.75rem 1.875rem 3.5rem 1.875rem;
  padding: 0 1.875rem 3.5rem 1.875rem;
  margin-top: 4.75rem;
  height: calc(100vh - 10rem);
  overflow-y: scroll;

  #left {
    width: 26.5rem;
  }

  #right {
    width: 26.5rem;
  }

  #middle {
    width: 67rem;
    position: relative;
    padding: 0 1rem;
    margin: 0 auto;

    #map {
      // margin-bottom: 1rem;
      .project_map {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
      }

      .menus {
        z-index: 999;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #21315b;
        cursor: pointer;

        > div {
          padding: 0.58rem 0.73rem;
          font-size: 0.82rem;
        }

        > div:first-child {
          border-right: 1px solid #5e7ec9;
        }

        .img {
          margin-left: 0.47rem;
          width: 0.76rem;
          height: 0.7rem;
        }
      }
    }
  }
}

#mapid {
  width: 100%;
  height: 100vh;
}

.map-angle {
  background-color: red;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.leaflet-popup-content-wrapper {
  background-color: #122040;
  text-align: center;
  border: 2px solid #5f7ec9;
}

.leaflet-popup-content > div:not(:last-child) {
  margin-bottom: 5px;
}

.leaflet-popup-tip-container {
  display: none;
}

  .borderColor-green {
    border-color: #1fc86b !important;
  }

  .borderColor-grey {
    border-color: #9ca0aa !important;
  }

  .borderColor-yellow1 {
    border-color: #F89132 !important;
  }

  .borderColor-yellow2 {
    border-color: #eebc34 !important;
  }

  .borderColor-red1 {
    border-color: #e62c31 !important;
  }

  .borderColor-red2 {
    border-color: #f34e53 !important;
  }

// .btn1{
//     background-color:#399A75;
//     border-radius:24px;
//     border:none;
//     color:#fff;
//     padding: 3px 10px;
//     cursor: pointer;
//     outline: none;
//     margin-right: 8px;
// }

// .btn2{
//     background-color:#5186F5;
//     border-radius:24px;
//     border:none;
//     color:#fff;
//     padding: 3px 10px;
//     cursor: pointer;
//     outline: none;
// }
</style>

<style lang="less">
.maptalks-all-layers {
  z-index: auto!important;
}

.maptalks-canvas-layer {
  z-index: auto!important;
}

.info-block {
  position: relative;
  width: 100%;
  background: url("../../assets/nation/title.png") no-repeat;
  background-size: contain;
}

.info-block-title {
  position: absolute;
  color: #c8dbf4;
  font-weight: bold;
  left: 2.8rem;
  top: 0.6rem;
}
.stats-layer .el-dialog__header {
  display: none;
}
.stats-layer .el-dialog__body {
  padding: 0;
  color: unset;
}

.option-block {
  color: #919192;
  background-color: #172142;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 6rem;
  border: 0;
  text-align: center;
  cursor: pointer;
}

.option-block-selected {
  color: #fcfcfc;
  background-color: #0a57be;
}

.el-dialog.recall-dialog {
  width: 31.25rem !important;
  background-color: rgba(21, 28, 72, .8) !important;
}

.recall-dialog .el-dialog__header, .recall-dialog .el-dialog__footer {
  display: none;
}

.recall-dialog .el-dialog__body {
  text-align: center;
  font-size: 1.25rem;
  color: #fff;
  padding: 1.5rem;
}

.recall-dialog .warning-icon {
  display: inline-block;
  background: url("../../assets/weixian.png") no-repeat;
  width: 5rem;
  height: 5rem;
  background-size: cover;
  margin-bottom: 3.5rem;
}

.recall-dialog .cancel-button, .recall-dialog .confirm-button {
  width: 6.13rem;
  height: 2rem;
  border-radius: 1rem;
  color: #ffffff;
  line-height: 2rem;
  font-size: 1rem;
  display: inline-block;
  background: rgba(117, 120, 167, 1);
  cursor: pointer;
  text-align: center;
}

.recall-dialog .confirm-button {
  margin-left: 2.5rem;
  background: linear-gradient(90deg, rgba(81, 189, 245, 1), rgba(81, 112, 245, 1));
}
</style>
