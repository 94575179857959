import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    '儿童住院楼': [-290.2841630778275,-574.6516364340397,300],
    '门诊医技楼': [236.38050621665133,-456.12116436555186,250],
    '妇产保健楼': [224.42327190313603,-58.89314867005581,350],
    '后勤行政楼': [-362.27234145450177,-261.85153723230485,250],
  };

  const buildingBoundaries = {
    '儿童住院楼': [[-496.8815116086474,-423.67903377217806],[-495.735893078736,-666.5994835925394],[-50.701844158764686,-678.579262837599],[-498.78936724134263,-420.29313548847773]],
    '门诊医技楼': [[116.0478216909801,-609.5415766900264],[368.3246294230731,-612.7636738260514],[361.1988747241092,-268.67682430663183],[94.29383801561505,-283.8904926381197]],
    '妇产保健楼': [[12.894447714223688,-256.1230039572491],[377.61026316991234,-252.98945345575476],[384.44409008425373,91.35987152554286],[20.583866273774618,90.31160186358267]],
    '后勤行政楼': [[-523.3611140604703,-150.48266121798247],[-212.47777754737547,-148.4307146292388],[-232.8288714435023,-321.17966237921894],[-520.6189450928944,-328.9838830380708]],
  };

  const buildingBlurBoundaries = {
    '儿童住院楼': [[-493.11667468977225,-688.3932719953525],[-493.30885774188124,-428.5799002022265],[67.95108494370385,-418.57803083413506],[30.624289836929364,-73.74309545319969],[30.821812291494027,56.296757083335905],[339.3480339230178,53.058980634190476],[358.18446997887196,-264.1530269692194],[355.1972935964391,-628.527797440324],[-75.49574543384236,-667.3395459571275]],
    '门诊医技楼': [[-493.11667468977225,-688.3932719953525],[-493.30885774188124,-428.5799002022265],[67.95108494370385,-418.57803083413506],[30.624289836929364,-73.74309545319969],[30.821812291494027,56.296757083335905],[339.3480339230178,53.058980634190476],[358.18446997887196,-264.1530269692194],[355.1972935964391,-628.527797440324],[-75.49574543384236,-667.3395459571275]],
    '妇产保健楼': [[-493.11667468977225,-688.3932719953525],[-493.30885774188124,-428.5799002022265],[67.95108494370385,-418.57803083413506],[30.624289836929364,-73.74309545319969],[30.821812291494027,56.296757083335905],[339.3480339230178,53.058980634190476],[358.18446997887196,-264.1530269692194],[355.1972935964391,-628.527797440324],[-75.49574543384236,-667.3395459571275]],
    '后勤行政楼': [[-523.3611140604703,-150.48266121798247],[-212.47777754737547,-148.4307146292388],[-232.8288714435023,-321.17966237921894],[-520.6189450928944,-328.9838830380708]],
  };

  const buildingFinishedHeights = {
    '儿童住院楼': 0,
    '门诊医技楼': 0,
    '妇产保健楼': 0,
    '后勤行政楼': 0,
  };

  const doneFloors = {
    '儿童住院楼': 0,
    '门诊医技楼': 0,
    '妇产保健楼': 0,
    '后勤行政楼': 0,
  };

  const traceViews = {
    '_': {
      center: [1635.224119855427, -601.1675118168415],
      pitch: 20,
      zoom: 2,
      bearing: -3.4,
    },
    '儿童住院楼': {
      center: [-257.8119269375256, -1362.3040281341512],
      pitch: 77.5,
      zoom: 2.94,
      bearing: 23.6,
    },
    '门诊医技楼': {
      center: [5769.132737493672, -6119.882956276863],
      pitch: 80.8,
      zoom: 2.94,
      bearing: 115.4,
    },
    '妇产保健楼': {
      center: [5509.857238578222, 230.77877331002352],
      pitch: 78.4,
      zoom: 2.94,
      bearing: 69.8,
    },
    '后勤行政楼': {
      center: [-2033.923515049627, -4839.385335205914],
      pitch: 77.9,
      zoom: 3.66,
      bearing: 154.4,
    },
  };

  function getUserZValue(user) {
    return (user.floor == 'ground' ? 0 : user.height) * 5.55 + 8;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 5.55;
  }

  function getZValueFromHeight(height) {
    return height * 5.55;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/danzhoufuyou2.glb',
    modelType: 'gltf',
    modelSubType: 'draco',

    fullOpacity: 1,
    revealOpacity: 0.2,
    deepRevealOpacity: 0,

    /*
    groundHeightRanges: [
      [-0.0001, 0],
      [153.9731, 153.9732],
      [-1425.0001, -1424.9999],
    ],
    */

    revealUnderground: 0.15,

    modelOptions: {
      scale: 5.5,
      rotZ: 0,
      moveX: -475,
      moveY: -720,
      moveZ: -0.2186,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: 0,
      z: 0
    },

    bldgGroups: [['儿童住院楼', '门诊医技楼', '妇产保健楼']],

    lastBldgSwitchIndicesFile: 'danzhoufuyou.json',
    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingBlurBoundaries: buildingBlurBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
