<template>
  <div id="right">
    <PermitStateChart :district-level="districtLevel" :org-level="orgLevel" />
    <JobTileChart :district-level="districtLevel" :org-level="orgLevel" />
    <!-- <SequenceChart :district-level="districtLevel" :org-level="orgLevel" /> -->
  </div>
</template>

<script>
  import PermitStateChart from './permitStateChart'
  import JobTileChart from "./jobTitleChart.vue";
  // import SequenceChart from "./personalSequenceChart";

  export default {
    name: 'HrIndexRightBar',
    components: {
      PermitStateChart,
      JobTileChart,
      // SequenceChart,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
  }
</script>

<style lang="less">
</style>
