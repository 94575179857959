import * as THREE from 'three';
import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    '1栋': [-760.0128276962778, -135.91092788790144, 520],
    '2栋': [-759.0133710268947, 202.3269395078607, 520],
    '3栋': [-735.007937720728, 530.5374658236502, 420],
    '4栋': [-290.1334470931809, 522.54156698564594, 520],
    '5栋': [-78, -377, 140],
  };

  const buildingBoundaries = {
  //  '1栋': [[-947, -58], [-577, -58], [-577, -147], [-947, -147]],
  //  '2栋': [[-943, 255], [-579, 255], [-579, 163], [-943, 163]],
  //  '3栋': [[-847, 565], [-617, 565], [-617, 472], [-847, 472]],
  //  '4栋': [[-438, 558], [-172, 558], [-172, 468], [-438, 468]],

    '1栋': [[-963, -81], [-579, -81], [-579, -188], [-963, -188]],
    '2栋': [[-967, 256], [-579, 256], [-579, 145], [-967, 145]],
    '3栋': [[-897, 586], [-584, 586], [-584, 471], [-897, 471]],
    '4栋': [[-432, 577], [-150, 577], [-150, 467], [-432, 467]],
    '5栋': [[-953, -272], [-310, -218], [42, 583], [480, 593], [64, -630], [-984, -638]],
  };

  const buildingFinishedHeights = {
    '1栋': 600,
    '2栋': 600,
    '3栋': 600,
    '4栋': 600,
    '5栋': 400,
  };

  const doneFloors = {
    '1栋': 28,
    '2栋': 29,
    '3栋': 23,
    '4栋': 28,
    '5栋': 3,
  };

  const traceViews = {
    '_': {
      center: [-1496.4433765976828, 1654],
      pitch: 0,
      zoom: 1.9322325206960818,
    },
    /*
    '1栋': {
      center: [-5343, 7608],
      pitch: 76,
      zoom: 2.579393532412232,
      bearing: 0.8,
    },
    '2栋': {
      center: [-5625, 11836],
      pitch: 79.2,
      zoom: 2.338723727313347,
      bearing: -1.6,
      needsTransparent: ['1栋'],
    },
    '3栋': {
      center: [-5381, 9861],
      pitch: 75.2,
      zoom: 2.669554472183526,
      bearing: -0.4,
      needsTransparent: ['1栋', '2栋'],
    },
    '4栋': {
      center: [-2818, 14433],
      pitch: 80,
      zoom: 1.9753605158972583,
      bearing: -0.4,
    },
    */
    '1栋': {
      center: [-5542, 1654],
      pitch: 55.2,
      zoom: 3.1045288637618613,
      bearing: -11.8,
    },
    '2栋': {
      center: [-6594, 3777],
      pitch: 45.2,
      zoom: 3.463410168625182,
      bearing: -28,
      needsTransparent: ['1栋'],
    },
    '3栋': {
      center: [-6436, 5775],
      pitch: 46.8,
      zoom: 3.7543422504599655,
      bearing: -28.6,
      needsTransparent: ['2栋'],
    },
    '4栋': {
      center: [-3420, 6804],
      pitch: 53.2,
      zoom: 2.9473917001069214,
      bearing: -23.2,
    },
  };

  function getUserZValue(user) {
    const floor = parseInt((user.floor_name || user.floor).slice(0, -1));
    if (floor > 0 && traceViews[user.build_name]) {
      if (floor <= 2) {
        return -3 + (floor - 1) * 26.4 + 5;
      } else {
        return 49.8 + (floor - 3) * 17.7 + 5;
      }
    } else {
      return (user.floor == 'ground' ? 0 : user.height) * 5.64 + 5;
    }
  }

  function getBuildingFloor(build_name, height) {
    if (traceViews[build_name]) {
      build_name = '1栋';
    }
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 5.64;
  }

  function getZValueFromHeight(height) {
    return height * 5.64;
  }

  function getFloorFromZValue(bldg, zValue) {
    if (traceViews[bldg] && zValue >= -3) {
      if (zValue >= 49.8) {
        return `${Math.floor((zValue - 49.8) / 17.7) + 3}F`;
      } else if (zValue >= 23.4) {
        return '2F';
      } else {
        return '1F';
      }
    } else {
      return getBuildingFloor(bldg, getHeightFromZValue(zValue));
    }
  }

  function modelPostProc(scene, meshMap) {
    meshMap['WB1'].material[0].color.r = 0.372;
    meshMap['WB1'].material[0].color.g = 0.372;
    meshMap['WB1'].material[0].color.b = 0.372;
    meshMap['墙___100mm___米白色真石漆_1'].material[0] = meshMap['WB1'].material[0];
    meshMap['墙___100mm___米白色真石漆_1'].material[1] = meshMap['WB1'].material[0];

    let geo = new THREE.BoxGeometry(200, 1100, 21);
    let cube = new THREE.Mesh(geo, meshMap['高空作业体验_1'].material[1]);
    cube.position.x = 310;
    cube.position.y = 37;
    cube.position.z = -14;
    cube.rotation.z = -80 / Math.PI;
    scene.add(cube);
    geo = new THREE.BoxGeometry(200, 384, 21);
    cube = new THREE.Mesh(geo, meshMap['高空作业体验_1'].material[1]);
    cube.position.x = 187;
    cube.position.y = -442;
    cube.position.z = -14;
    cube.rotation.z = -30 / Math.PI;
    scene.add(cube);
    geo = new THREE.BoxGeometry(300, 560, 21);
    cube = new THREE.Mesh(geo, meshMap['高空作业体验_1'].material[1]);
    cube.position.x = 260;
    cube.position.y = 310;
    cube.position.z = -14;
    scene.add(cube);
    geo = new THREE.BoxGeometry(200, 67, 21);
    cube = new THREE.Mesh(geo, meshMap['高空作业体验_1'].material[1]);
    cube.position.x = 483;
    cube.position.y = 556;
    cube.position.z = -14;
    scene.add(cube);
    geo = new THREE.BoxGeometry(1116, 1224, 21);
    cube = new THREE.Mesh(geo, meshMap['高空作业体验_1'].material[1]);
    cube.position.x = -408;
    cube.position.y = -22;
    cube.position.z = -14;
    scene.add(cube);
    geo = new THREE.BoxGeometry(626, 120, 21);
    cube = new THREE.Mesh(geo, meshMap['高空作业体验_1'].material[1]);
    cube.position.x = -653;
    cube.position.y = -690;
    cube.position.z = -14;
    scene.add(cube);
    /*
    geo = new THREE.BoxGeometry(700, 700, 1);
    cube = new THREE.Mesh(geo, meshMap['高空作业体验_1'].material[1]);
    cube.position.x = -620;
    cube.position.y = 240;
    scene.add(cube);
    geo = new THREE.BoxGeometry(730, 180, 1);
    cube = new THREE.Mesh(geo, meshMap['高空作业体验_1'].material[1]);
    cube.position.x = -595;
    cube.position.y = -200;
    scene.add(cube);
    geo = new THREE.BoxGeometry(100, 300, 1);
    cube = new THREE.Mesh(geo, meshMap['高空作业体验_1'].material[1]);
    cube.position.x = -240;
    cube.position.y = 50;
    scene.add(cube);
    geo = new THREE.BoxGeometry(300, 800, 1);
    cube = new THREE.Mesh(geo, meshMap['高空作业体验_1'].material[1]);
    cube.position.x = -200;
    cube.position.y = 230;
    scene.add(cube);
    */

    addFloorNames(scene, [
//      ['1F', -520, -160, -90],
      ['5F', -555, -160, -3],
      ['10F', -555, -160, 85.5],
      ['15F', -555, -160, 174],
      ['20F', -555, -160, 262.5],
      ['25F', -555, -160, 351],
//      ['1F', -520, 170, -90],
      ['5F', -555, 170, -3],
      ['10F', -555, 170, 85.5],
      ['15F', -555, 170, 174],
      ['20F', -555, 170, 262.5],
      ['25F', -555, 170, 351],
//      ['1F', -580, 500, -90],
      ['5F', -598, 500, -3],
      ['10F', -598, 500, 85.5],
      ['15F', -598, 500, 174],
      ['20F', -598, 500, 262.5],
//      ['1F', -120, 500, -90],
      ['5F', -130, 500, -3],
      ['10F', -130, 500, 85.5],
      ['15F', -130, 500, 174],
      ['20F', -130, 500, 262.5],
      ['25F', -130, 500, 351],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/op105.fbx',

    modelOptions: {
      scale: 4.3,
      rotZ: 0,
  //    moveX: -1550,
  //    moveY: -335,
      moveX: -75,
      moveY: 0,
      moveZ: 195
    },

    sceneRotation: {
      x: 0,
      y: 0,
      z: 0
    },

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    modelPostProc: modelPostProc,
  };
}
