import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    '城建大厦': [309.05591469388463,-550.4936207366453,750],
  };

  const buildingBoundaries = {
    '城建大厦': [[67.87039927337253,-721.3003160902902],[556.2393450101819,-721.8314558726161],[549.794225623502,-104.93669652591404],[36.78707593954351,-96.20568276209099]],
  };

  const buildingFinishedHeights = {
    '城建大厦': 650,
  };

  const doneFloors = {
    '城建大厦': 0,
  };

  const traceViews = {
    '_': {
      center: [3707.8196721114277, -2977.5502753528],
      pitch: 9.2,
      zoom: 2.5,
      bearing: -24.4,
    },
    '城建大厦': {
      center: [-999.4535067107333, 5566.07794716837],
      pitch: 76.8,
      zoom: 2,
      bearing: -24.4,
    },
  };

  function getUserZValue(user) {
    return (user.floor == 'ground' ? 0 : user.height) * 6 + 8;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 6;
  }

  function getZValueFromHeight(height) {
    return height * 6;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/cjds3.glb',
    modelType: 'gltf',
    modelSubType: 'draco',

    fullOpacity: 1,
    revealOpacity: 0.2,
    deepRevealOpacity: 0,

    /*
    groundHeightRanges: [
      [-0.0001, 0],
      [153.9731, 153.9732],
      [-1425.0001, -1424.9999],
    ],
    */

    modelOptions: {
      scale: 6,
      rotZ: 0,
      moveX: 100,
      moveY: -850,
      moveZ: 0,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: 0,
      z: 0
    },

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
