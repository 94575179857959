<template>
  <div id="project" class="global-bg">
    <div id="main">
      <div id="middle">
        <div id="map">
          <div class="project_map" id="mapid" :style="{backgroundImage: `url(${projectImage})`, height: '55rem'}">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { project, wsUrl } from '../../api'
import {
  init,
  createWebSocket,
  setMapMarker,
} from '../../../public/leaflet.js'
import '../../../public/blinkmarker.css';


export default {
  name: 'disclosure2',
  components: {
  },
  data() {
    return {
      buildingInfo: [
        {
          build_id: '',
          build_name: '',
          location: {},
          floor_height: '',
          map_count: 0,
          worker_count: 0,
          map_worker_list: ''
        }
      ],
      projectImage: '',
      map_list: [],
      img_host: '',
    }
  },
  mounted() {
    const { projectID: projectID } = this.$route.params

    const token = this.$route.query.token;
    window.token = token;

    project
      .getBuildingBaseInfo(projectID)
      .then(d => {
        this.buildingInfo = d.build_list
        this.map_list = d.show_map
        this.img_host = d.img_host
        //创建ws socket连接
        createWebSocket('mapid', wsUrl, projectID)
        //加载地图
        init(
          'mapid',
          this.img_host + this.map_list[0].image_url,
          this.map_list[0].build_id,
          this,
          function(e) {
            //加载当前楼层的人员名单
            project
              .getWorkerList(e)
              .then(list => {
                setMapMarker('mapid', list, "oninit");
              })
              .catch(e => console.error(e.msg || e.toString()))
          },
          {
            hideWorkerPopupButtons: true,
            mapWidth: this.map_list[0].width,
            mapHeight: this.map_list[0].height,
          }
        )
      })
      .catch(e => console.error(e.msg || e.message))
  },
  methods: {
  },
}
</script>

<style scoped lang="less">
.map_list {
  z-index: 999;
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  display: flex;
}

#main {
  display: flex;
  padding: 4.75rem 1.875rem 3.5rem 1.875rem;
  height: calc(100vh - 2rem);
  overflow-y: scroll;

  #middle {
    width: 85rem;
    position: relative;
    padding: 0 1rem;

    #map {
      // margin-bottom: 1rem;

      .project_map {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
      }
    }
  }
}

#mapid {
  width: 100%;
  height: 100vh;
}
</style>
