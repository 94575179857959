<template>
  <div id="right">
    <wrapper style="padding:0">
        <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>项目本年收款</div>
          <div class="detail" @click="showDetail(1)">
            详情
          </div>
        </div>
        <div style="height:17rem;padding-top:3rem;"
          class="machine_use">
           <div v-if="!curProceddsChart.series || !curProceddsChart.series[0].data.length" style="padding-top:1rem">
          <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 35rem; height: 12rem;margin-top: 0.5rem;">
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
        </div>
        <v-chart v-else :options="curProceddsChart" autoresize />
        </div>
      </div>
    </wrapper>
    <wrapper style="padding:0">
        <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>项目本年净流</div>
        </div>
        <div style="height:17rem;padding-top:3rem;"
          class="machine_use">
           <div v-if="!curNetFlowChart.series || !curNetFlowChart.series[0].data.length" style="padding-top:1rem">
            <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 35rem; height: 12rem;margin-top: 0.5rem;">
              <div class="empty-chart-icon" />
              <div style="margin-top:20px;color:#ccc">暂无数据</div>
            </div>
          </div>
          <v-chart v-else :options="curNetFlowChart" autoresize />
        </div>
      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem;padding:0">
        <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>全周期现金流</div>
        </div>
        <div style="height:17rem;padding-top:3rem;"
          class="machine_use">
           <div v-if="!fullCashFlowChart.series || !fullCashFlowChart.series[0].data.length" style="padding-top:1rem">
            <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 35rem; height: 12rem;margin-top: 0.5rem;">
              <div class="empty-chart-icon" />
              <div style="margin-top:20px;color:#ccc">暂无数据</div>
            </div>
          </div>
          <v-chart v-else :options="fullCashFlowChart" autoresize />
        </div>
      </div>
    </wrapper>
    <!-- 项目本年收款详情 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                <el-table style="background-color:#0E1328" height="450px" :data="tableData" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column
                        prop="branchName"
                        label="项目">
                        </el-table-column>
                    <!-- <el-table-column v-for="(item,index) in tableData[0].monthData" :key="index" :label="item.month"> 
                        <el-table-column label="计划回收">
                          <template slot-scope="scope">
                              {{scope.row.monthData[index].jhhs}}
                          </template>
                        </el-table-column>
                        <el-table-column label="实际回收">
                          <template slot-scope="scope">
                              {{scope.row.monthData[index].sjhs}}
                          </template>
                        </el-table-column>
                    </el-table-column> -->
                    <el-table-column label="当年">
                        <el-table-column
                          label="计划回收"
                          prop="yearGatherPlan"
                          :formatter="Formatter">
                          </el-table-column>
                          <el-table-column
                          prop="yearGatherActual"
                          label="实际回收"
                          :formatter="Formatter">
                          </el-table-column>
                          <el-table-column
                          prop="yearGatherOffset"
                          label="预算偏差"
                          :formatter="Formatter">
                          </el-table-column>
                      </el-table-column>
                </el-table>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from 'vue-echarts';
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import wrapper from "../../../../components/wrap";
import { finance } from '../../../../api_finance';

export default {
  name: "financeRightBar",
  components: {
    'v-chart': ECharts,
    wrapper,
  },
  data() {
    return {
      projectId: null,
      curProceddsChart:{},
      curNetFlowChart:{},
      fullCashFlowChart:{},
      trainList:[],
      tableData:[],
      popupShow:false
    };
  },
  watch:{
      $route: function(newValue, oldValue) {
      if (newValue.params.id != oldValue.params.id) {
        this.getData();
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    showDetail(choose){
      if(choose == 1){
        this.popupShow = true;
      }
    },
      tableRowStyle({rowIndex}){
        if(rowIndex%2 === 0)
            return {backgroundColor:'#213a77',height: '0'}; 
        else   
            return {backgroundColor:'#102855',height: '0'}; 
      },
      tableHeaderColor(){
        return 'color:#4fb5da;background:#102855';
      },
    randomNum(min,max){
      return Math.floor(Math.random()*(max-min+1)+min);
    },
    getData() {
      this.projectId = this.$route.params.id;
      this.getChart1();
      this.getChart2();
      this.getChart3();
    },
      numFormatter(val){
        return (Math.floor(val * 100) / 100).toFixed(2);
      },
    getChart1(){
      finance.queryProjNetFlowLine(this.projectId).then((d=>{
        const xData = [];
        const seriesData1 = [];
        const seriesData2 = [];
        for (let i = 1; i <= d.projCapitalMonthlyVOList.length; i++) {
          xData.push(d.projCapitalMonthlyVOList[i]+"月");
          seriesData1.push(this.numFormatter(d.projCapitalMonthlyVOList[i].overdueGatherPlan,2));
          seriesData2.push(this.numFormatter(d.projCapitalMonthlyVOList[i].overdueGatherActual,2));
        }
        const option = {
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'cross',
                  crossStyle: {
                      color: '#999'
                  }
              }
          },
          legend: {
              padding: [10, 0, 0, 0],
              //图例滚动
              // type: 'scroll',
              //图例文字样式
              textStyle: {
                  color: '#fff',
                  fontSize:"1rem"
              }
          },
          xAxis: [
              {
                  type: 'category',
                  axisTick: {
                      show: false,
                  },
                  axisLabel: {
                      color: '#fff',
                      fontSize:"1rem",
                      rotate: 45
                  },
                  axisLine: {
                      show: false
                  },
                  data: xData,
              }
          ],
          yAxis: [
              {
                  type: 'value',
                  name: '',
                  axisLabel: {
                      textStyle: {
                          //坐标轴颜色
                          color: '#fff',
                          fontSize:"1rem"
                      }
                  },
                  //坐标轴线样式
                  splitLine: {
                      show: true,
                      lineStyle: {
                          type: 'solid', //solid实线;dashed虚线
                          color: 'rgba(36, 173, 254, 0.2)'
                      }
                  },
              }
              
          ],
          series: [
              {
                  name: "计划回收",
                  data: seriesData1,
                  type: 'line',
                  smooth: true, //true曲线; false折线
                  itemStyle: {
                      normal: {
                          color: '#ffc600', //改变折线点的颜色
                          lineStyle: {
                              color: '#ffc600', //改变折线颜色
                              type: 'solid'
                          }
                      }
                  },
                  areaStyle: {
                      //折线图颜色半透明
                      color: {
                          type: 'linear',
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 1,
                          colorStops: [{
                              offset: 0, color: 'rgba(255,198,0, 0.5)' // 0% 处的颜色
                          }, {
                              offset: 1, color: 'rgba(255,198,0, 0.1)' // 100% 处的颜色
                          }],
                          global: false // 缺省为 false
                      }
                  }
              },
              {
                  name: "实际回收",
                  data: seriesData2,
                  type: 'line',
                  smooth: true, //true曲线; false折线
                  itemStyle: {
                      normal: {
                          color: '#24adfe', //改变折线点的颜色
                          lineStyle: {
                              color: '#24adfe', //改变折线颜色
                              type: 'solid'
                          }
                      }
                  },
                  areaStyle: {
                      //折线图颜色半透明
                      color: {
                          type: 'linear',
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 1,
                          colorStops: [{
                              offset: 0, color: 'rgba(36,173,254, 0.5)' // 0% 处的颜色
                          }, {
                              offset: 1, color: 'rgba(52,112,252, 0.1)' // 100% 处的颜色
                          }],
                          global: false // 缺省为 false
                      }
                  }
              }
          ]
        }
        this.curProceddsChart = option;
      }))
    },
    getChart2(){
      finance.queryProjNetFlowLine(this.projectId,new Date().getFullYear()).then((res=>{
        console.log(res);
        const xData = [];
        const seriesData1 = [];
        const seriesData2 = [];
        for (let i = 1; i <= res.projCapitalMonthlyVOList.length; i++) {
          xData.push(res.projCapitalMonthlyVOList[i].month+"月");
          seriesData1.push(this.numFormatter(res.projCapitalMonthlyVOList[i].netFlowBudget,2));
          seriesData2.push(this.numFormatter(res.projCapitalMonthlyVOList[i].netFlowFinished,2));
        }
        const option = {
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'cross',
                  crossStyle: {
                      color: '#999'
                  }
              }
          },
          legend: {
              padding: [10, 0, 0, 0],
              //图例滚动
              // type: 'scroll',
              //图例文字样式
              textStyle: {
                  color: '#fff',
                  fontSize:"1rem"
              }
          },
          xAxis: [
              {
                  type: 'category',
                  axisTick: {
                      show: false,
                  },
                  interval: 1,
                  axisLabel: {
                      color: '#fff',
                      fontSize:"1rem",
                      rotate: 45
                  },
                  axisLine: {
                      show: false
                  },
                  data: xData,
              }
          ],
          yAxis: [
              {
                  type: 'value',
                  name: '',
                  axisLabel: {
                      textStyle: {
                          //坐标轴颜色
                          color: '#fff',
                          fontSize:"1rem"
                      }
                  },
                  //坐标轴线样式
                  splitLine: {
                      show: true,
                      lineStyle: {
                          type: 'solid', //solid实线;dashed虚线
                          color: 'rgba(36, 173, 254, 0.2)'
                      }
                  },
              }
              
          ],
          series: [
              {
                  name: "净流预算",
                  data: seriesData1,
                  type: 'line',
                  smooth: true, //true曲线; false折线
                  itemStyle: {
                      normal: {
                          color: '#ffc600', //改变折线点的颜色
                          lineStyle: {
                              color: '#ffc600', //改变折线颜色
                              type: 'solid'
                          }
                      }
                  },
                  areaStyle: {
                      //折线图颜色半透明
                      color: {
                          type: 'linear',
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 1,
                          colorStops: [{
                              offset: 0, color: 'rgba(255,198,0, 0.5)' // 0% 处的颜色
                          }, {
                              offset: 1, color: 'rgba(255,198,0, 0.1)' // 100% 处的颜色
                          }],
                          global: false // 缺省为 false
                      }
                  }
              },
              {
                  name: "净流完成",
                  data: seriesData2,
                  type: 'line',
                  smooth: true, //true曲线; false折线
                  itemStyle: {
                      normal: {
                          color: '#24adfe', //改变折线点的颜色
                          lineStyle: {
                              color: '#24adfe', //改变折线颜色
                              type: 'solid'
                          }
                      }
                  },
                  areaStyle: {
                      //折线图颜色半透明
                      color: {
                          type: 'linear',
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 1,
                          colorStops: [{
                              offset: 0, color: 'rgba(36,173,254, 0.5)' // 0% 处的颜色
                          }, {
                              offset: 1, color: 'rgba(52,112,252, 0.1)' // 100% 处的颜色
                          }],
                          global: false // 缺省为 false
                      }
                  }
              }
          ]
        }
        this.curNetFlowChart = option;
      }))
    },
    getChart3(){
      finance.queryProjCycleSum(this.projectId,new Date().getFullYear()).then((res=>{
        console.log(res);
        const xData = [];
        const seriesData1 = [];
        const seriesData2 = [];
        for (let i = 1; i <= res.length; i++) {
          xData.push(res[i].month+"月");
          seriesData1.push(this.numFormatter(res[i].planCashFlow,2));
          seriesData2.push(this.numFormatter(res[i].actualCashFlow,2));
        }
        const option = {
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'cross',
                  crossStyle: {
                      color: '#999'
                  }
              }
          },
          legend: {
              padding: [10, 0, 0, 0],
              //图例滚动
              // type: 'scroll',
              //图例文字样式
              textStyle: {
                  color: '#fff',
                  fontSize:"1rem"
              }
          },
          xAxis: [
              {
                  type: 'category',
                  axisTick: {
                      show: false,
                  },
                  interval: 1,
                  axisLabel: {
                      color: '#fff',
                      fontSize:"1rem",
                      rotate: 45
                  },
                  axisLine: {
                      show: false
                  },
                  data: xData,
              }
          ],
          yAxis: [
              {
                  type: 'value',
                  name: '',
                  axisLabel: {
                      textStyle: {
                          //坐标轴颜色
                          color: '#fff',
                          fontSize:"1rem"
                      }
                  },
                  //坐标轴线样式
                  splitLine: {
                      show: true,
                      lineStyle: {
                          type: 'solid', //solid实线;dashed虚线
                          color: 'rgba(36, 173, 254, 0.2)'
                      }
                  },
              }
              
          ],
          series: [
              {
                  name: "现金流预算",
                  data: seriesData1,
                  type: 'line',
                  smooth: true, //true曲线; false折线
                  itemStyle: {
                      normal: {
                          color: '#ffc600', //改变折线点的颜色
                          lineStyle: {
                              color: '#ffc600', //改变折线颜色
                              type: 'solid'
                          }
                      }
                  },
                  areaStyle: {
                      //折线图颜色半透明
                      color: {
                          type: 'linear',
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 1,
                          colorStops: [{
                              offset: 0, color: 'rgba(255,198,0, 0.5)' // 0% 处的颜色
                          }, {
                              offset: 1, color: 'rgba(255,198,0, 0.1)' // 100% 处的颜色
                          }],
                          global: false // 缺省为 false
                      }
                  }
              },
              {
                  name: "实际现金流",
                  data: seriesData2,
                  type: 'line',
                  smooth: true, //true曲线; false折线
                  itemStyle: {
                      normal: {
                          color: '#24adfe', //改变折线点的颜色
                          lineStyle: {
                              color: '#24adfe', //改变折线颜色
                              type: 'solid'
                          }
                      }
                  },
                  areaStyle: {
                      //折线图颜色半透明
                      color: {
                          type: 'linear',
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 1,
                          colorStops: [{
                              offset: 0, color: 'rgba(36,173,254, 0.5)' // 0% 处的颜色
                          }, {
                              offset: 1, color: 'rgba(52,112,252, 0.1)' // 100% 处的颜色
                          }],
                          global: false // 缺省为 false
                      }
                  }
              }
          ]
        }
        this.fullCashFlowChart = option;
      }))
    }
  },
};
</script>

<style scoped lang="less">
.el-dialog.recall-dialog {
  width: 31.25rem !important;
  background-color: rgba(21, 28, 72, 0.8) !important;
}

.recall-dialog .el-dialog__header,
.recall-dialog .el-dialog__footer {
  display: none;
}

.recall-dialog .el-dialog__body {
  text-align: center;
  font-size: 1.25rem;
  color: #fff;
  padding: 1.5rem;
}

  .info-block {
    position: relative;
    width: 100%;
    background: url("../../../../assets/nation/title_finance.png") no-repeat;
  }

.recall-dialog .cancel-button,
.recall-dialog .confirm-button {
  width: 6.13rem;
  height: 2rem;
  border-radius: 1rem;
  color: #ffffff;
  line-height: 2rem;
  font-size: 1rem;
  display: inline-block;
  background: rgba(117, 120, 167, 1);
  cursor: pointer;
  text-align: center;
}

.recall-dialog .confirm-button {
  margin-left: 2.5rem;
  background: linear-gradient(
    90deg,
    rgba(81, 189, 245, 1),
    rgba(81, 112, 245, 1)
  );
}
#people-status {
  #retreat-btn {
    margin: 1rem 0;
    height: 1.875rem;
    text-align: center;
    line-height: 1.875rem;
    cursor: pointer;
    background: linear-gradient(to right, #51bbf5, #5170f5);
  }

  .group {
    height: 29rem;
    padding: 0 1rem;
    overflow-y: scroll;

    .group-item {
      .group-title {
        margin-bottom: 1rem;

        .group-title-icon {
          width: 1rem;
          height: 1rem;
          display: inline-block;
          margin-right: 0.25rem;
          cursor: pointer;
          position: relative;

          &.active {
            transform: rotate(-90deg);
          }

          &::before {
            content: " ";
            position: absolute;
            width: 1rem;
            font-size: 1rem;
            transform: rotate(0deg);
            transition: transform 0.3s ease-in-out;
            height: 1rem;
            background: url("../../../../assets/sanjiao.png") no-repeat;
          }
        }
      }

      .people-card-list {
        .card-item {
          padding: 0.4rem 1rem;
          display: flex;
          margin-bottom: 0.75rem;
          background-color: #1e2c61;
          border-radius: 0.25rem;

          .left {
            flex: 1;
            // display: flex;
            font-size: 14px;
            line-height: 2rem;

            .title {
              color: #999999;
            }
          }

          .right {
            width: 5rem;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .status-card {
              border-width: 0.5rem 1px 1px;
              border-style: solid;
              border-radius: 0.25rem;
              margin-bottom: 1rem;
              padding: 0.7rem;

              .zt {
                font-size: 0.88rem;
                color: #999;
                padding: 3px 0;
              }

              .status-text {
                padding-top: 5px;
                font-size: 1.13rem;
                font-weight: bold;
              }
            }

             .op {
              width: 3rem;
              height: 1.5rem;
              background: linear-gradient(
                90deg,
                rgba(81, 189, 245, 1),
                rgba(81, 112, 245, 1)
              );
              line-height: 1.5rem;
              cursor: pointer;
              font-size: 0.75rem;
              text-align: center;

              &.disabled {
                background: #8a8a8a;
                cursor: not-allowed;
              }
            }
            .op2 {
              margin-left: .5rem;
              background: linear-gradient(
                90deg,
                #f5c151,#ef873b
              );
            }


          }
        }
      }
    }
  }
}
.text-green {
  color: #1fc86b !important;
}

.text-yellow1 {
  color: #f89132 !important;
  border-color: #f89132 !important;
}

.text-yellow2 {
  color: #eebc34 !important;
  border-color: #eebc34 !important;
}

.text-red1 {
  color: #e62c31 !important;
}

.text-red2 {
  color: #f34e53 !important;
}

.text-grey {
  color: #9ca0aa !important;
}

.borderColor-green {
  border-color: #1fc86b !important;
}

.borderColor-grey {
  border-color: #9ca0aa !important;
}

.borderColor-yellow1 {
  border-color: #f89132 !important;
}

.borderColor-yellow2 {
  border-color: #eebc34 !important;
}

.borderColor-red1 {
  border-color: #e62c31 !important;
}

.borderColor-red2 {
  border-color: #f34e53 !important;
}

.stats-close-btn {
  z-index: 30000;
  position: absolute;
  background-image: url("../../../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}

#people-warning-list {
  .warning-item {
    margin-bottom: 0.75rem;
    padding: 8px 6px;
    font-size: 0.75rem;
    line-height: 1.5rem;
    background-color: #1e2c61;
    border-radius: 0.25rem;
    color: #ffffff;
    position: relative;
    display: flex;
    div.name {
      color: #999999;
      width: 2rem;
    }
    div.value {
      width: 3.7rem;
      padding-left: 0.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    time {
      background-color: #577acc;
      border-radius: 4px;
      padding: 5px;
    }
  }
}
</style>
<style lang="less">
.input-with-select {
  width: 90% !important;
  margin: 0 1rem;

  .el-input__inner {
    background-color: #0c2341 !important;
    border: 1px solid #1782a6 !important;
    color: #fff !important;
    height: 2rem !important;
  }
  .cancel {
    color: #ffffff !important;
    border-left: 1px solid #1782a6 !important;
  }
  .el-input-group__append {
    color: #51bbf5 !important;
    background-color: #0c2341 !important;
    border: 1px solid #1782a6 !important;
    height: 2rem !important;
  }
}
</style>