// import { data } from 'jquery';
const baseUrl = process.env.VUE_APP_API_BASE_ZGSHR_URL


const areaCodeMap = require('/src/area-code-map.json');

const handleRes = (res) => {
    if (res.statusCode < 200 || res.statusCode >= 300) {
      return res.response.text().then(e => Promise.reject(e))
    } else {
      return res.response.json().then(_d => {
        if (_d.code !== 0 && _d.code !== 200) {
          return Promise.reject(_d)
        } else if (_d.data) {
          _d.data.__count__ = _d.count;
          return _d.data;
        } else{
          return _d;
        }
      })
    }
  }
// function addFilterParams(url) {
//       // for(var key in areaCodeMap){
//       //   if(key == window.$districtLevel){
//       //     console.log(areaCodeMap[key]);
//       //   }
//       // }
//       verifyOrgId();
//       const containsArea = url.indexOf('areaLvl=') >= 0;
//       const containsOrg = url.indexOf('orgLvl=') >= 0;
//       if(url.indexOf("unit_list")!=-1)
//         return url;
//       if (!containsArea || !containsOrg) {
//         const sign = url.indexOf('?') >= 0 ? '&' : '?';
//         const areaStr = `areaLvl=${window.$districtLevel || '100000'}`;
//         const orgStr = `orgLvl=${window.$orgLevel || ''}`;
//         if (!containsArea && !containsOrg) {
//           return url + sign + areaStr + '&' + orgStr;
//         } else {
//           return url + sign + (!containsArea ? areaStr : orgStr);
//         }
//       } else {
//         return url;
//       }
//     // const sFIDArea = url.indexOf('sFID=') >= 0;
//     // if (!sFIDArea) {
//     //   // const sign = url.indexOf('?') >= 0 ? '&' : '?';
//     //   formattingOrgId();
//     //   return url;
//     //   // const orgStr = `orgLvl=${window.$sFid || ''}`;
//     //   // return url + sign + orgStr;
//     // } else {
//     //   return url;
//     // }
//   }

  function addFilterParams2(url,body) {
    verifyOrgId();
    // 忽略 接口
    if(url.indexOf("directMap")!=-1 || url.indexOf("projectOrgTree")!=-1|| url.indexOf("empAmountCondition")!=-1
     || url.indexOf("empExamCondition")!=-1 || url.indexOf("operationConditionCompare")!=-1
     || url.indexOf("empTrainCondition")!=-1 || url.indexOf("overstaffingCondition")!=-1){
      return body;
    }
    var provinceName = ''; 
    var cityName = '';
    for(var key in areaCodeMap){
      if(key == window.$districtLevel){
        let str = areaCodeMap[key];
        if(str != '' && str != null){
          var datalist = str.split("/");
          if(datalist.length >= 1){
            provinceName = datalist[0];
            cityName = datalist[1];
          }
        }
      }
    }
    const provinceExist = body["provinceName"] ==null
    const cityExist = body["cityName"] ==null
    if(provinceExist || cityExist) {
      body["provinceName"] = provinceName;
      body["cityName"] = cityName;
      return body
    } else {
      return body;
    }
}

/** @param url {string}
 * @return Promise*/
//  const get = url => fetch(addFilterParams(baseUrl + url)).then(res => ({ response: res, statusCode: res.status })).then(handleRes)

const post = (url, body, type = 'json') => {
    let headers, _body = addFilterParams2(url,body);
    if (type === 'json') {
      headers = { 'Content-Type': 'application/json','traditional':true}
      // headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
      _body = JSON.stringify(body)
    } else if (type === 'file') {
      _body = new FormData()
      _body.append('files', body)
    } else {
      _body = body
    }
    if (url.slice(0, 4) != 'http') {
      url =baseUrl + url;
    }
    return fetch(url, { method: 'POST', headers, body: _body })
      .then(res => ({ response: res, statusCode: res.code })).then(handleRes)
  }
function verifyOrgId(){
  if(window.$sFid === '' || window.$sFid === undefined || window.$sFid === null)
    window.$sFid = "7B867F6775E143F8B1F3131BFA85C13E";
}
const human = {
  // 处理数据
  capsulationData(res){
    let data1 = [];
    let data2 = [];
    for (let index = 0; index < res.length; index++) {
      data1 = res[index].name;
      data2 = res[index].value;
    }
    let lastData = [];
    lastData[0] = data1;
    lastData[1] = data2;
    return lastData;
  },
  // 获取单位level
  getOrgLevel:function(){
    this.getUnitList(3).then(rs=>{
      for (let i = 0; i < rs.length; i++) {
        if(rs[i].SID  === window.$sFid){
         return rs[i].SLEVEL;
        }
      }
    })
  },
  // 获取单位数据
  getUnitList:(orgLevel) => post(`bi/hr/unit_list`,{"level":orgLevel}),
  // 人员规模
  getStaffScale: function() {
    return post(`bi/hr/user_kinds`,{"orgId":window.$sFid})
  },
  // 学历统计
  getPersonEducation:() =>post(`bi/hr/education_static`,{"orgId":window.$sFid}),
  // 人员来源
  getPersonalSource:() => post(`bi/hr/user_source`,{"orgId":window.$sFid}),
  // 全周期人员曲线
  getPersonnelAllocation: function(year){
    return post(`bi/hr/project_cycle_curve`,{"orgId":window.$sFid,"year":year});
  },
  // 人均营收
  getPerCapitaRevenue: function(year){
    return post(`bi/hr/get_revenue_per_capita`,{"orgId":window.$sFid,"year":year});
  },
  // 人均营收对比`
  getPerCapitaRevenueCompare: function(year){
    return post(`bi/hr/get_revenue_compare`,{"orgId":window.$sFid,"year":year});
  },
  // 人均利润
  getPerCapitaProfit: function(year){
    return post(`bi/hr/get_per_capita_profit`,{"orgId":window.$sFid,"year":year});
  },
  // 人均利润对比
  getPerCapitaProfitCompare: function(year){
    return post(`bi/hr/get_profit_compare`,{"orgId":window.$sFid,"year":year});
  },
  // 预估工资产值率
  getPerWageRate: function(year){
    return post(`bi/hr/get_wage_rate`,{"orgId":window.$sFid,"year":year});
  },
  // 预估工资产值率对比
  getPerWageRateCompare: function(year){
    return post(`bi/hr/get_wage_rate_compare`,{"orgId":window.$sFid,"year":year});
  },
  // 预估工资利润率
  getPerWageProfitRate: function(year){
    return post(`bi/hr/get_wage_rate_profit`,{"orgId":window.$sFid,"year":year});
  },
  // 预估工资利润率对比
  getPerWageProfitRateCompare: function(year){
    return post(`bi/hr/get_wage_rate_profit_compare`,{"orgId":window.$sFid,"year":year});
  },
  getArchitectPermit:() => post(`bi/hr/constructor_static`,{"orgId":window.$sFid}),
  getPermitState:() => post(`bi/hr/certification_static`,{"orgId":window.$sFid}),
  getCompetenceStrutureBroadCast: function(){
    return post(`bi/hr/hold_status_of_pm_and_project_nums`,{"orgId":window.$sFid})
  },
  // 职称统计
  getJobTitieState: ()=> post(`bi/hr/professional_title_statistics`,{"orgId":window.$sFid}),
  // 序列分析
  getPersonalSequence: () => post(`bi/hr/profession_rank_static`,{"orgId":window.$sFid}),
  // 各层级人员数量
  getLevelPersonalNums: (level) => post(`bi/hr/level_personalNums_static`,{"orgId":window.$sFid,"orgLevel":level}),
  //人员年龄分析
  getPersonalAgeCondition: () => post(`bi/hr/age_info`,{"orgId":window.$sFid}),
  // 项目班子队伍
  getProjectTeam: ()=>post(`bi/hr/project_leaders_statistic`,{"orgId":window.$sFid}),
  // 项目班子学历统计
  getProjectTeamEducation: ()=>post(`bi/hr/education_background_of_project_leaders`,{"orgId":window.$sFid}),
  // 项目班子持证率
  getProjectTeamPermitOdds: ()=>post(`bi/hr/percentage_of_certification`,{"orgId":window.$sFid}),
   // 项目班子来源
  getPmPromotionSouce: ()=>post(`bi/hr/project_team_source`,{"orgId":window.$sFid}),
  // 在施项目数量/持证项目经理数量
  getPersonNumByMap:()=>post(`bi/hr/user_num_of_all_provinces`,{"orgId":window.$sFid}),
  getPersonNumByProvince:(provinceName)=>post(`bi/hr/user_num_of_all_cities`,{"orgId":window.$sFid,"provinceName":provinceName}),
  getPersonNumByCity:(cityName)=>post(`bi/hr/county_user_nums`,{"orgId":window.$sFid,"cityName":cityName}),
  // 项目经理队伍
  getProjectMgrTeam:()=>post(`bi/hr/project_nums_and_project_manager_nums`,{"orgId":window.$sFid}),
  // 项目经理持证率对比
  getProjectMgrPremitOdds:()=>post(`bi/hr/projectManager_certification_rate`,{"orgId":window.$sFid}),
  // 项目经理学历情况
  getProjectMgrEducation:()=>post(`bi/hr/education_background_of_project_manager`,{"orgId":window.$sFid}),
   // 项目经理晋升来源
   getProjectMgrPromotionSource:()=>post(`bi/hr/pm_promotion_source`,{"orgId":window.$sFid}),
  // 管理人员序列分析
  getMannagerBySequence:()=>post(`bi/hr/queryRankStateByTier`,{"orgLevel":2}),
  // 离职率分析
  getPersonDimissionOdds:(selectedYear)=>{
    return post(`bi/hr/user_quit_rate`,{"orgId":window.$sFid,"year":selectedYear})
  },
  // 离职原因
  getPersonDimissionReason:(selectedYear)=>{
    let dateInterval = new Array();
    dateInterval.push(selectedYear+"-01-01");
    dateInterval.push(selectedYear+"-12-31");
    return post(`bi/hr/user_quit_reason`,{"orgId":window.$sFid,"startDate":dateInterval[0],"endDate":dateInterval[1]})
  },
  // 离职去向
  getPersonDimissionDirection:(selectedYear)=>{
    let dateInterval = new Array();
    dateInterval.push(selectedYear+"-01-01");
    dateInterval.push(selectedYear+"-12-31");
    return post(`bi/hr/user_quit_direction`,{"orgId":window.$sFid,"startDate":dateInterval[0],"endDate":dateInterval[1]})
  },
  // 招聘情况学历对比
  getPersonRecruitEducation:(selectedYear)=>{
    return post(`bi/hr/recruit_education_statistic`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid ,"year":selectedYear})
  },
  // 招聘情况工龄对比
  getPersonRecruitWorkAge:(selectedYear)=>{
    return post(`bi/hr/recruit_workAge_statistic`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid ,"year":selectedYear})
  },
  // 招聘情况来源对比
  getPersonRecruitSource:(selectedYear)=>{
    return post(`bi/hr/recruit_source_statistic`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid ,"year":selectedYear})
  },
  // 合同类型
  getPersonContractType:(selectedYear)=>{
    return post(`bi/hr/contract_type_statistic`,{"orgId":window.$sFid,"year":selectedYear})
  },
  // 项目班子合同类型
  getProjectTeamContractType:(selectedYear)=>{
    return post(`bi/hr/query_project_team_contract_property`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid ,"year":selectedYear})
  },
  // 项目班子合同类型
  getPersonContractWarning:(selectedYear)=>{
    return post(`bi/hr/contract_worning_statistic`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid ,"year":selectedYear})
  },
  // 返聘人员
  getPersonHireStaff:(selectedYear)=>{
    return post(`bi/hr/hire_employees_statistic`,{"orgId":window.$sFid,"year":selectedYear})
  },
  // 人才队伍 导播图
  getTalentStatistic:(level)=>{
    return post(`bi/hr/talent_statistic`,{"orgId":window.$sFid,"orgLevel":level})
  },
  // 管理人员序列分析
  getManagerSequenceAnalyse:(level)=>{
    return post(`bi/hr/num_whose_level_beyond_vice_level_of_branch_company`,{"orgId":window.$sFid,"orgLevel":level})
  },
  // 管理人员年龄分析
  getManagerAgeAnalyse:(selectedYear)=>{
    return post(`bi/hr/manange_age_statistic`,{"orgId":window.$sFid,"year":selectedYear})
  },
  // 管理人员学历分析
  getManagerEducationAnalyse:(selectedYear)=>{
    return post(`bi/hr/manange_education_statistic`,{"orgId":window.$sFid,"year":selectedYear})
  },
  // 管理人员任职分析
  getManagerServeceAnalyse:(level,selectedYear)=>{
    return post(`bi/hr/manange_service_statistic`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"orgLevel":level,"year":selectedYear})
  },
  // 远航后备-人数统计
  getSailingTalentNums:(selectedYear)=>{
    return post(`bi/hr/reserve_talents_num_statistic`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"year":selectedYear})
  },
  // 远航后备-学历统计
  getSailingEducation:(selectedYear)=>{
    return post(`bi/hr/reserve_talents_education_statistic`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"year":selectedYear})
  },
  // 远航后备-来源统计
  getSailingSource:(selectedYear)=>{
    return post(`bi/hr/reserve_talents_source_statistic`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"year":selectedYear})
  },
  // 后备成才情况-远航后备
  getSailingTalentYield:(selectedYear)=>{
    return post(`bi/hr/saling_reserve_talent_yield`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"year":selectedYear})
  },
  // 后备成才情况-启航后备
  getSetSailingTalentYield:(selectedYear)=>{
    return post(`bi/hr/set_sali_reserve_talent_yield`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"year":selectedYear})
  },
  // 成长缓慢青年员工
  getGrowthSlowEmp:(selectedYear)=>{
    return post(`bi/hr/manange_service_statistic`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"year":selectedYear})
  },
  // 薪酬考核
  // 人员成本
  getEmployeeCost:(selectedYear)=>{
    return post(`bi/hr/get_labor_cost`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"year":selectedYear})
  },
  // 各单位人员薪酬结构
  getSalaryStructure:(selectedYear)=>{
    return post(`bi/hr/get_salary_structure`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"year":selectedYear})
  },
  // 各单位薪酬分位值
  getSalaryTantile:(selectedYear)=>{
    return post(`bi/hr/get_salary_tantile`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"year":selectedYear})
  },
  // 各单位平均薪酬
  getSalaryAverage:(selectedYear)=>{
    return post(`bi/hr/get_salary_average`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"year":selectedYear})
  },
  // 各层级人员薪酬结构
  getSalaryStructureOfStaffLevel:(selectedYear,orgLevel)=>{
    return post(`bi/hr/get_salary_average`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"year":selectedYear,"orgLevel":orgLevel})
  },
  // 关键人员薪酬结构-5年内校招生
  getSalaryStructureOfSchoolAdmissions:(selectedYear)=>{
    return post(`bi/hr/queryFiveYearSchoolSource`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"year":selectedYear})
  },
  // 关键人员薪酬结构-后备人才
  getSalaryStructureOfReserve:(selectedYear)=>{
    return post(`bi/hr/get_salary_structure_reserve`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"year":selectedYear})
  },
  // 各层级人员考核分析
  getPersonalOfLevelsExamine:(selectedYear,orgLevel)=>{
    return post(`bi/hr/get_emp_assess`,{"orgId":window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid,"year":selectedYear,"orgLevel":orgLevel})
  },
  // 项目部层面接口
  // 导播图
  getProjectDirecctMap:(xmid)=>{
    return post(`bi/hr/pro/directMap`,{"sid":xmid})
  },
  // 项目部层面接口
  // 组织机构图
  getProjectOrgChart:(xmid)=>{
    return post(`bi/hr/pro/projectOrgTree`,{"sid":xmid})
  },
  // 项目部层面接口
  // 全周期人员曲线
  getProjectCycleCurve:(xmid,year)=>{
    return post(`bi/hr/pro/empAmountCondition`,{"sid":xmid,"year":year})
  },
  getOverstaffingCondition:(xmid,dateStr)=>{
    return post(`bi/hr/pro/overstaffingCondition`,{"sid":xmid,"yearMonth":dateStr})
  },
  // 项目部层面接口
  // 考核分析
  getProjectExamine:(xmid)=>{
    return post(`bi/hr/pro/examCondition`,{"sid":xmid})
  },
  // 项目部层面接口
  // 考核人员台账
  getProjectExamineDetail:(xmid,examLevel,examYear,name)=>{
    return post(`bi/hr/pro/empExamCondition`,{"sid":xmid,"examLevel":examLevel,"examYear":examYear,"name":name})
  },
  // 项目部层面接口
  // 经营情况对比
  getProjectBusinessDetail:(xmid)=>{
    return post(`bi/hr/pro/operationConditionCompare`,{"sid":xmid})
  },
  // 项目部层面接口
  // 培训情况
  getProjectTrain:(xmid)=>{
    return post(`bi/hr/pro/empTrainCondition`,{"sid":xmid})
  }
}

export { baseUrl, human }
