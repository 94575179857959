<template>
  <div id = "right">
    <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title" style="display: flex;justify-content: space-between;align-items:center;width: 90%;margin-top: -5px;">
      <div>成长缓慢青年员工情况</div>    
      <div style="display:flex;justify-content:flex-end"
      >
        <CustomSelect
          :options="yearList"
          :value="selectedYear"
          @input="categorySelect"
          style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;"
        />
      </div>
    </div>
    <div style="height:25rem">
      <div v-if="!passRateBarChart.series || !passRateBarChart.series[0].data.length" style="padding-top:5rem">
        <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;">
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
      </div>
      <v-chart v-else :options="passRateBarChart" autoresize />
    </div>
  </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { human } from '../../../../api_hr'
  import CustomSelect from '../../../../components/select'
  // import { SimpleBarChartTemplate } from "../../barChartTemplates";
  // import { applyUpdate } from "../../../../utils";

  export default {
    name: 'gorwthSlowEmpChart',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerPassRateChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerPassRateChart();
        }
      }
    },
    data () {
      return {
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear(),
        passRateBarChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
    },
    methods: {
      categorySelect(option) {
        this.selectedYear = option;
        this.updateSafetyDangerPassRateChart();
      },
      updateSafetyDangerPassRateChart() {
        human.getGrowthSlowEmp(this.selectedYear)
          .then(d => {
            console.log(d);
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
    },
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
