<template>
  <!-- <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  /> -->
  <div style="width:100%;height:10rem;display:flex;justify-content:center">
    <div style="display:flex;align-items:center;cursor: pointer;">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[0]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[0]}}</div>
        <div style="display:flex;align-items: baseline;" @click="handleClick(1)">
          <v-number
            v-model="(broadcastData || {}).value1"
            :speed="500"
            style="font-family:digital;font-size:3.5rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[0]}"
          ></v-number>
          <span v-if="(broadcastData || {}).value1 != null" style="font-family:digital;font-size:1.5rem;line-height:3rem;color:#40F8FF;margin-left:0.2rem">人</span>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:3rem;cursor: pointer;">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[1]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[1]}}</div>
        <div style="display:flex;align-items: baseline;" @click="handleClick(2)">
          <v-number
            v-model="(broadcastData || {}).value2"
            :speed="500"
            style="font-family:digital;font-size:3.5rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[1]}"
          ></v-number>
          <span v-if="(broadcastData || {}).value2 != null" style="font-family:digital;font-size:1.5rem;line-height:3rem;color:#2D7DFF;margin-left:0.2rem">顶</span>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:3rem;cursor: pointer;">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[2]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[2]}}</div>
        <div style="display:flex;align-items: baseline;" @click="handleClick(3)">
          <v-number
            v-model="(broadcastData || {}).value3"
            :speed="500"
            style="font-family:digital;font-size:3.5rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[2]}"
          ></v-number>
          <span v-if="(broadcastData || {}).value3 != null" style="font-family:digital;font-size:1.5rem;line-height:3rem;color:#50F194;margin-left:0.2rem">顶</span>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:3rem;cursor: pointer;">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[3]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[3]}}</div>
        <div style="display:flex" @click="handleClick(4)">
          <v-number
            v-model="(broadcastData || {}).value4"
            :speed="500"
            style="font-family:digital;font-size:3.5rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[3]}"
          ></v-number>
          <span v-if="(broadcastData || {}).value4 != null" style="font-family:digital;font-size:3.5rem;line-height:3rem;color:#20bbee;">%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import Broadcasts from "../../components/broadcasts";
  import { nation } from "../../api";
  import VNumber from "../../components/v-number";

  export default {
    name: 'hatBroadcasts',
    components: {
      // Broadcasts,
      VNumber,
    }, 
    data() {
      return {
        broadcastTitles: ['应上项目劳务人数', '采购帽子数', '帽子已发放数', '工人领用率'],
        broadcastColors: ['#40F8FF', '#2D7DFF', '#50F194', '#20bbee'],
        broadcastIcons: ['应上项目劳务人数', '采购帽子数', '帽子已发放数', '工人领用率'],
      }
    },
    props: {
      broadcastData: Object,
      dispatchEvent: Function,
    },
    methods: {
      handleClick(type) {
          this.dispatchEvent('hatClick',type);
      }
    }
  }
// /dashboard/hat/broadcast/detail
// 实时播报（项目上劳务人数、安全帽使用情况）

  export function updateData(container, scheduleNextUpdate) {
    nation
      .getHatBroadcast()
      .then((d) => {
        container.broadcastData.hat = d[0];
        container.broadcastData.hat.value1 = d[0].total_worker_count;
        container.broadcastData.hat.value2 = d[0].hat_purchased;
        container.broadcastData.hat.value3 = d[0].hat_used;
        container.broadcastData.hat.value4 = d[0].hat_use_ratio;

        container.statsData = d[0];
        scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
  
}
</style>
