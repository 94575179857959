<template>
  <div id="project" :key="$route.params.id" class="global-bg" style="overflow:hidden">
    <div
      id="title-container"
      style="margin-bottom:-3rem;position:relative;width:570px;margin:auto"
      @click="showProject"
    >
      <div id="title-left2" />
      <div
        id="title-center2"
        style="position:relative"
      >
        <div>{{projectInfo.name}}</div>
        <div v-if="!!fromOrg" style="position:absolute;top:0.45rem;left:0;cursor:pointer;width:40px" @click.stop="goBack">
          <img src="../../assets/back.png" style="width:28px;height:28px;margin-left:0.1rem;margin-top:0.5rem" alt="" />
        </div>
      </div>
      <div id="title-right2" />
      <div
        id="title-light"
      />
    </div>

    <el-autocomplete
      v-if = "!!fromOrg"
      class="inline-input"
      style="position:absolute;width:24rem;left:48rem;top:4.4rem;pointer-events:all;z-index:50000;"
      v-model="searchParams"
      :fetch-suggestions="querySearch"
      placeholder=""
      :trigger-on-focus="false"
      @select="handleSelect"
    >
      <i slot="suffix" class="el-input__icon"><img src="../../assets/nation/search.png" style="margin-right:0.7rem;margin-top:0.75rem" alt=""></i>
    </el-autocomplete>

    <div v-if="componentsReady">
      <IndexMain
        :fromOrg="fromOrg"
        :componentList="componentList"
        :subComponentLists="subComponentLists"
        :components="components"
        :compDict="compDict"
        :hasSubMenus="hasSubMenus"
        :pageContentComps="pageContentComps"
        :navMenuLeftMargins="navMenuLeftMargins"
        :subMenuLeftMargins="subMenuLeftMargins"
        :subMenuRightMargins="subMenuRightMargins"
        :projectInfo="projectInfo"
      />
    </div>
    <el-dialog custom-class="stats-layer" :visible.sync="popupShow" :close-on-click-modal="true" width="40rem" top="10rem">
      <wrapper style="backgroundColor:#0e1328;position:relative;padding:0;height: 33rem;">
        <div
            class="stats-close-btn"
            @click="popupShow = false"
        />
        <div style="padding: 2rem;">
          <div style="font-size: 20px;margin-bottom: 1rem;">项目详情</div>
          <div style="display:flex;align-items:center;justify-content: space-between;margin-bottom: 1rem;">
            <div style="color: #3deef6">负责人</div>
            <div>{{projectInfos.head_person}}</div>
          </div>
          <div style="display:flex;align-items:center;justify-content: space-between;margin-bottom: 1rem;">
            <div style="color: #3deef6">类型</div>
            <div>{{projectInfos.type}}</div>
          </div>
          <div style="display:flex;align-items:center;justify-content: space-between;margin-bottom: 1rem;">
            <div style="color: #3deef6">建筑面积</div>
            <div>{{projectInfos.build_up_area}}</div>
          </div>
          <div style="display:flex;align-items:center;justify-content: space-between;margin-bottom: 1rem;">
            <div style="color: #3deef6">地址</div>
            <div style="width: 70%">{{projectInfos.location}}</div>
          </div>
           <div style="display:flex;align-items:center;justify-content: space-between;margin-bottom: 1rem;">
            <div style="color: #3deef6">项目概况</div>
            <div style="width: 70%">{{projectInfos.summary}}</div>
          </div>
          <div style="margin-bottom: 1rem;">
            <div style="color: #3deef6">施工进度</div>
            <div>
              <v-chart :options="projectProcess" ref="projectProcess" autoresize style="height: 4rem;" />
            </div>
          </div>
          <div style="display:flex;align-items:center;justify-content: space-between;color: #A6A9B1">
            <div>
              <div>开工时间</div>
              <div>{{projectInfos.starttime}}</div>
            </div>
            <div>
              <div>预计竣工时间</div>
              <div>{{projectInfos.endtime}}</div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require('echarts-gl')
import wrapper from '../../components/wrap'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import { nation, project, filters } from '../../api'
import IndexMain from './indexMain'
const componentRegistry = require("./components.json");

const navMenuLeftMargins = [1.5, 9.5, 17.5, 25.5, 33.5, 78.5, 86.5, 94.5, 102.5, 110.5];
const subMenuLeftMargins = ['1.5rem', '1.5rem', '1.5rem', null, null, '78.5rem', '78.5rem', '78.5rem', null, null];
const subMenuRightMargins = [null, null, null, '78.5rem', '78.5rem', null, null, null, '1.5rem', '1.5rem'];

export default {
  name: 'project',
  components: {
    'v-chart': ECharts,
    wrapper,
    IndexMain,
  },
  data() {
    return {
      fromOrg: 0,
      componentsReady: false,
      componentList: [],
      subComponentLists: [],
      components: {},
      compDict: {},
      hasSubMenus: [],
      pageContentComps: [],
      navMenuLeftMargins: [],
      subMenuLeftMargins: [],
      subMenuRightMargins: [],
      searchParams: '',
      projects: [],
      projectInfo: {
        name: '',
        location: '',
        head_person: '',
        head_mobile: '',
        type: '',
        starttime: '',
        endtime: '',
        built_up_area: '',
        summary: ''
      },
      projectProcess: {
        grid: {
          top: 0,
          bottom: -15,
          right: 0,
          contentLabel: true,
          left: 0
        },
        xAxis: {
          type: 'value',
          show: false,
          max: 1
        },
        yAxis: {
          type: 'category',
          show: false,
          data: ['']
        },
        series: [
          {
            showBackground: true,
            backgroundStyle: {
              color: '#344a7a',
              barBorderRadius: 30
            },
            type: 'bar',
            data: [0],
            barWidth: 11,
            itemStyle: {
              barBorderRadius: 30,
              color: '#51bcf5'
            },
            markPoint: {
              symbol: 'pin',
              symbolSize: 45,
              value: 0,
              itemStyle: {
                color: '#51bcf5'
              },
              data: [
                {
                  name: '进度',
                  type: 'max',
                  label: {
                    show: true,
                    formatter: '{c}%'
                  }
                }
              ]
            }
          }
        ]
      },
      projectInfos: {},
      popupShow: false,
    }
  },
  mounted() {
    const { id: projectID } = this.$route.params;
    const token = this.$route.query.token;
    this.fromOrg = parseInt(this.$route.query.from_org || 0);
    window.token = token;
    window.use3D = parseInt(this.$route.query.use3d || 0);

    filters
      .getMenuList()
      .then((d) => {
        const components = {};
        const componentList = [];
        for (const item of d) {
          componentList.push(item.name);
          if (item.children && item.children.length) {
            const children = {};
            for (const child of item.children) {
              if (componentRegistry[child.pagename]) {
                children[child.name] = Object.assign({pageName: child.pagename}, componentRegistry[child.pagename]);
                if (child.pagename == 'iframe') {
                  children[child.name].url = child.url.replace('${fid}', this.$route.query.xmid);
                  console.log('wtff', children[child.name].url)
                }
              }
            }
            components[item.name] = { children };
          } else if (componentRegistry[item.pagename]) {
            components[item.name] = Object.assign({pageName: item.pagename}, componentRegistry[item.pagename]);
            if (item.pagename == 'iframe') {
              components[item.name].url = item.url.replace('${fid}', this.$route.query.xmid);
            }
          }
        }
        const subComponentLists = [];
        const hasSubMenus = [];
        const pageContentComps = [];
        const compDict = {};
        for (let i = 0; i < componentList.length; i++) {
          if (!components[componentList[i]].children) {
            subComponentLists.push([]);
            hasSubMenus.push(false);

            if (components[componentList[i]].pageContent) {
              const pageContentComp = require(`./${components[componentList[i]].pageContent}.vue`).default;
              components[componentList[i]].pageContentComp = pageContentComp;
              const compName = `Comp${i + 1}Sub0PageContent`;
              compDict[compName] = pageContentComp;
              pageContentComps.push([compName, `${i + 1}`, '0']);
            }

            if (components[componentList[i]].left) {
              const leftComp = require(`./${components[componentList[i]].left}.vue`).default;
              components[componentList[i]].leftComp = leftComp;
              compDict[`Comp${i + 1}Sub0Left`] = leftComp;
            }

            if (components[componentList[i]].right) {
              const rightComp = require(`./${components[componentList[i]].right}.vue`).default;
              components[componentList[i]].rightComp = rightComp;
              compDict[`Comp${i + 1}Sub0Right`] = rightComp;
            }

            if (components[componentList[i]].broadcast) {
              const broadcastModule = require(`./${components[componentList[i]].broadcast}.vue`);
              console.log(broadcastModule);
              const broadcastComp = broadcastModule.default;
              components[componentList[i]].broadcastComp = broadcastComp;
              components[componentList[i]].updateBroadcastData = broadcastModule.updateData;
              compDict[`Comp${i + 1}Sub0Broadcast`] = broadcastComp;
            }
          } else {
            subComponentLists.push(Object.keys(components[componentList[i]].children));
            hasSubMenus.push(true);

            let k = 0;
            for (const key in components[componentList[i]].children) {
              if (components[componentList[i]].children[key].pageContent) {
                const pageContentComp = require(`./${components[componentList[i]].children[key].pageContent}.vue`).default;
                components[componentList[i]].children[key].pageContentComp = pageContentComp;
                const compName = `Comp${i + 1}Sub${k}PageContent`;
                compDict[compName] = pageContentComp;
                pageContentComps.push([compName, `${i + 1}`, `${k}`]);
              }

              if (components[componentList[i]].children[key].left) {
                const leftComp = require(`./${components[componentList[i]].children[key].left}.vue`).default;
                components[componentList[i]].children[key].leftComp = leftComp;
                compDict[`Comp${i + 1}Sub${k}Left`] = leftComp;
              }

              if (components[componentList[i]].children[key].right) {
                const rightComp = require(`./${components[componentList[i]].children[key].right}.vue`).default;
                components[componentList[i]].children[key].rightComp = rightComp;
                compDict[`Comp${i + 1}Sub${k}Right`] = rightComp;
              }

              if (components[componentList[i]].children[key].broadcast) {
                const broadcastModule = require(`./${components[componentList[i]].children[key].broadcast}.vue`);
                const broadcastComp = broadcastModule.default;
                components[componentList[i]].children[key].broadcastComp = broadcastComp;
                components[componentList[i]].children[key].updateBroadcastData = broadcastModule.updateData;
                compDict[`Comp${i + 1}Sub${k}Broadcast`] = broadcastComp;
              }

              k++;
            }
          }
        }

        this.componentList = componentList;
        this.subComponentLists = subComponentLists;
        this.components = components;
        this.compDict = compDict;
        this.hasSubMenus = hasSubMenus;
        this.pageContentComps = pageContentComps;
        const startIdx = Math.floor((10 - componentList.length) / 2);
        const endIdx = startIdx + componentList.length;
        this.navMenuLeftMargins = navMenuLeftMargins.slice(startIdx, endIdx);
        this.subMenuLeftMargins = subMenuLeftMargins.slice(startIdx, endIdx);
        this.subMenuRightMargins = subMenuRightMargins.slice(startIdx, endIdx);
        this.componentsReady = true;
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));

    project
      .getProjectInfo(projectID)
      .then(d => {
        this.projectInfo = d[0]
        const rate = Math.trunc(
          (new Date() - new Date(d[0].starttime)) /
            (new Date(d[0].endtime) - new Date(d[0].starttime)) *
            100
        )
        this.projectProcess.xAxis.max = 100
        this.projectProcess.series[0].data = [rate]
        this.projectProcess.series[0].markPoint.value = rate
      })
      .catch(e => console.error(e.msg || e.message))

    this.searchProject();
  },
  methods: {
    searchProject() {
      project
        .getGeneralProjectSelect(this.searchParams)
        .then((d) => {
          this.projects = d.map(item=>{
            return {
              value: item.name,
              id: item.innerid
            }
          });
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    querySearch(queryString, cb) {
      var projects = this.projects;
      var results = queryString ? projects.filter(this.createFilter(queryString)) : projects;
      // 调用 callback 返回建议列表的数据
      // console.log(results)
      cb(results);
    },
    goBack() {
      this.$router.back();
    },
    createFilter(queryString) {
      return (project) => {
        return (project.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    handleSelect(item) {
      this.searchParams = '';
      this.gotoProject(item.id);
    },
    gotoProject(projectId) {
      nation.getProjectToken(projectId)
        .then((d) => {
          this.$router.push({
            path:`/project2/${projectId}/${parseInt(this.navMenuIndex) || 1}/${this.navSubMenuIndex || '0'}`,
            query:{
              token: d.token,
              from_org: this.fromOrg,
              xmid: d.fid,
              use3d: window.use3D
            }
          })
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    showProject(){
      const { id: projectID } = this.$route.params;
      this.projectId = projectID;

      this.popupShow = true;
      //  this.projectId = '48146e7e-b027-4bf4-8520-0d935405b681';
      project.getProjLaborProjectBasicInfo(this.projectId)
        .then(d => {
          // console.log(d)
          this.projectInfos = d;
          const rate = Math.trunc(
            (new Date() - new Date(d.starttime)) /
              (new Date(d.endtime) - new Date(d.starttime)) *
              100
          )
          this.projectProcess.xAxis.max = 100
          this.projectProcess.series[0].data = [rate]
          this.projectProcess.series[0].markPoint.value = rate
        })
    }
  },
}
</script>

<style scoped lang="less">
#title-center2 {
  width: 520px;
  height: 64px;
  display: inline-block;
  line-height: 64px;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  background: url("../../assets/nation/mid.png") transparent;
  background-size: contain;
}

#title-light {
  position: absolute;
  width: 377px;
  height: 53px;
  background: url("../../assets/nation/light2.png") transparent;
  bottom: -26.5px;
  z-index: 20000;
}

#title-left2 {
  width: 32px;
  height: 64px;
  background: url("../../assets/nation/left.png") no-repeat transparent;
  background-size: contain;
}

#title-right2 {
  width: 32px;
  height: 64px;
  background: url("../../assets/nation/right.png") no-repeat transparent;
  background-size: contain;
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
  z-index: 30000;
}

#project {
  background-size: cover;
}

#logo {
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  display: flex;
  align-items: center;
  line-height: 2rem;

  span {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.6);
    margin-right: 1rem;
  }
}
</style>

<style lang="less">
.inline-input {
  .el-input__inner{
    background-color: #0c2341 !important;
    border: 1px solid #1782a6 !important;
    height:2.5rem !important;
  }
}
.el-autocomplete-suggestion{
  // top: 3rem !important;
  border: .5px solid #1782a6 !important;
  margin-top: 0 !important;
}
.el-autocomplete-suggestion__wrap{
  background-color: #0c2341 !important;
}

.stats-layer .el-dialog__header {
  display: none;
}
.stats-layer .el-dialog__body {
  padding: 0;
  color: unset;
}
</style>
