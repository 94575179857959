<template>
  <div id ="left">
    <div class="info-block" style="min-height:5rem">
    <div class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >方案滞后情况
      <div class="detail" @click="showDetail()">
        详情
      </div>  
    </div>
    <div style="height:20rem;padding-top:3rem">
      <div
        v-if="!SchemeLagChart.series || !SchemeLagChart.series[0].data.length"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart v-else :options="SchemeLagChart" @click="showDetail()" autoresize />
    </div>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <!-- <div class="tabbar" style="width:22rem">
              <div
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >正常（{{normal}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 5 }"
                @click="getAttendance(5)"
              >缓建（{{wait}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 4 }"
                @click="getAttendance(4)"
              >停工（{{stop}}）</div>
            </div> -->
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <el-table style="background-color:#0E1328" height="450px" :data="popProjectsList" 
              :row-style="tableRowStyle" :header-cell-style="tableHeaderColor" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)"> 
                    <el-table-column type="index" label="序号" width="50">
                    </el-table-column>
                    <el-table-column
                        prop="orgName"
                        label="分公司">
                        </el-table-column>
                    <el-table-column
                        prop="num"
                        label="到期未执行方案数">
                    </el-table-column>
                    <el-table-column
                        prop="numA"
                        label="A类">
                    </el-table-column>
                    <el-table-column
                        prop="numB"
                        label="B类">
                    </el-table-column>
                    <el-table-column
                        prop="numC"
                        label="C类">
                    </el-table-column>
                    <el-table-column
                        prop="numD"
                        label="D类">
                    </el-table-column>
                    <el-table-column
                        prop="qtNUm"
                        label="其它方案数量">
                    </el-table-column>
              </el-table>
            </div>
        </div>
      </wrapper>
    </el-dialog>
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { stackedBarChartTemplate } from "../../barChartTemplates";
  import { tech } from '../../../../api_tech'
  import { applyUpdate } from "../../../../utils";
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'SchemeLagChart',
    components: {
      'v-chart': ECharts,
      wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
    watch: {
      orgOptions(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSchemeLagChart();
        }
      },
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSchemeLagChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSchemeLagChart();
        }
      }
    },
    data () {
      return {
        SchemeLagChart: {},
        popupShow:false,
        popProjectsList:[],
        loading:true
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.updateSchemeLagChart();
    },
    methods: {
        tableRowStyle({rowIndex}){
            if(rowIndex%2 === 0)
                return {backgroundColor:'#213a77',height: '0'}; 
            else   
                return {backgroundColor:'#102855',height: '0'}; 
        },
        tableHeaderColor(){
            return 'color:#4fb5da;background:#102855';
        },
      updateSchemeLagChart() {
        const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
        tech.getSchemeLag().then((d)=>{
          var orgList = [];
          var aData = [];
          var bData = [];
          var cData = [];
          var dData = [];
          const len = d.length>12?12:d.length;
          for (let index = 0; index < len; index++) {
            orgList.push(d[index].orgName);
            aData.push(d[index].numA);
            bData.push(d[index].numB);
            cData.push(d[index].numC);
            dData.push(d[index].numD);
          }
          const option = {
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                }
              },
              legend: {},
              grid: {
                left: '5%',
                right: '6%',
                bottom: "5%",
                top: "15%"
              },
              xAxis: {
                type: 'category',
                data: orgList,
                axisLabel: {
                    color: 'white',
                    fontWeight: 'bold',
                    rotate: 40,
                    // formatter: value => truncateStr(value, 10),
                  }
              },
              yAxis: {
                type: 'value',
                axisLabel:{
                  color:"white"
                }
              },
              series: [
                {
                  name: 'A类',
                  type: 'bar',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: aData
                },
                {
                  name: 'B类',
                  type: 'bar',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: bData
                },
                {
                  name: 'C类',
                  type: 'bar',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: cData
                },
                {
                  name: 'D类',
                  type: 'bar',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: dData
                }
              ]
            };
          this.SchemeLagChart = applyUpdate(chart, option);
        })
      },
      showDetail(){
        this.popupShow = true;
        tech.getSchemeLag().then(res=>{
          this.popProjectsList = res;
          this.loading = false;
        })
      }
    },
  }
</script>

<style lang="less">

</style>
