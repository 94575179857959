import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    '办公大楼': [285.1136580212993,-426.64183403627317,700],
  };

  const buildingBoundaries = {
    '办公大楼': [[135.93901867370192,-701.2115897092482],[751.2748024046255,-692.1275499145605],[728.5928752050863,-308.1147895062129],[191.6702374104005,-319.5815863636757]],
  };

  const buildingFinishedHeights = {
    '办公大楼': 650,
  };

  const doneFloors = {
    '办公大楼': 30,
  };

  const traceViews = {
    '_': {
      center: [2955.5292599014865, -3701.251296819387],
      pitch: 0,
      zoom: 2.4,
      bearing: -35.8,
    },
    '办公大楼': {
      center: [19.304108571276174, 4050.977976980418],
      pitch: 73.6,
      zoom: 2,
      bearing: -29.8,
    },
  };

  function getUserZValue(user) {
    return (user.floor == 'ground' ? 0 : user.height) * 4.02 + 5;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 4.02;
  }

  function getZValueFromHeight(height) {
    return height * 4.02;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/hengtai-citizen.glb',
    modelType: 'gltf',
    modelSubType: 'draco',

    //fullOpacity: 1,
    revealOpacity: 0.15,
    deepRevealOpacity: 0,

    groundHeightRanges: [
      [-1.2001, -0.5],
    ],

    modelOptions: {
      scale: 4,
      rotZ: 0,
      moveX: 200,
      moveY: -350,
      moveZ: 0,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: 0,
      z: 0
    },

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
