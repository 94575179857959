<template>
  <div id="right">
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>产值完成率</div>
        <div
          class="detail"
          @click="showDetail(1)"
        >
          详情
        </div>
      </div>
      <div style="height:18rem;padding-top:3rem">
        <div
          v-if="OutputCompletionRateChart.series && [].concat.apply([], OutputCompletionRateChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="OutputCompletionRateChart"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div
        class="info-block-title"
        style="display: flex;align-items:center;width: 85%;justify-content: space-between;"
      >
        <div>产值排名</div>

        <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode == 1 }"
            @click="() => getRanking(1,'mode')"
          >月度</div>
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode == 2 }"
            @click="() => getRanking(2,'mode')"
          >年度</div>
          <div
            class="detail"
            style="margin-left:1rem"
            @click=" showDetail(2)"
          >详情</div>
        </div>
      </div>

      <div style="padding-top:3rem">
        <div style="display:flex;align-items:center;justify-content:center;margin:1rem 0">
          <div
            class="option-block2"
            style="min-width: 5rem;border-top-left-radius: 2em;border-bottom-left-radius: 2em;"
            :class="{ 'option-block-selected2': mode2 == 1 }"
            @click="getRanking(1,'mode2')"
          >总产值</div>
          <div
            class="option-block2"
            style="min-width: 5rem;border-top-right-radius: 2em;border-bottom-right-radius: 2em; "
            :class="{ 'option-block-selected2': mode2 == 2 }"
            @click="getRanking(2,'mode2')"
          >人均产值</div>
        </div>
        <div
          v-if="!threeDayManagementTopProjects.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <div
          v-else
          style="height:34rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;padding-left: 1rem;"
        >
          <div class="table-title">
            <div class="tab-item-first">项目部</div>
            <div class="tab-item">产值(万元)</div>
            <div class="on tab-item">
              <a
                class="js_category"
                @click="changeSort('sort')"
              >

                <span
                  class="rank"
                  style="margin-right:1.5rem"
                >排名</span>
                <i :class="sort==2?'angle_top_desc':'angle_top'"></i>
                <i :class="sort==1?'angle_bottom_asc':'angle_bottom'"></i>
              </a>
            </div>

          </div>
          <div
            v-for="project in threeDayManagementTopProjects"
            :key="project.name"
            style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;color:#1782A6"
          >
            <div
              class="tab-item-first"
              style="color: #A7B6BC;cursor: pointer;"
              @click="handleProject(project)"
            >{{project.project_name.length>14?project.project_name.slice(0,14)+'...':project.project_name}}</div>
            <div
              class="tab-item"
              style="margin-left:0.5rem"
            >{{project.value}}</div>
            <div
              class="tab-item"
              style="margin-left:0.5rem"
            >{{project.output_rank}}</div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow1"
      width="70rem"
      top="18rem"
    >
      <wrapper
        class="popup-bg"
        style="height:28rem;backgroundColor:#0e1328;position:relative"
      >
        <div
          class="stats-close-btn"
          @click="popupShow1 = false"
        />
        <div class="attendance">
          <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">产值完成率统计
          </div>
          <div class="mon-change">

            <img
              class="img"
              src="../../assets/nation/left_mon.png"
              @click="changeMon(0)"
              alt=""
            >
            <div
              v-for="(item,index) in monList"
              :key="index"
              @click="changeActive(index)"
              class="mon-item"
              :class="[index==activeMon ? 'active-mon' : '']"
            >{{item.name}}</div>
            <img
              class="img"
              src="../../assets/nation/right_mon.png"
              @click="changeMon(1)"
              alt=""
            >
          </div>

          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="height:19rem;padding-top:1rem">
              <div
                v-if="outputRateDetailChart.series && [].concat.apply([], outputRateDetailChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 64rem; height: 12rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart
                v-else
                :options="outputRateDetailChart"
                autoresize
              />
            </div>
          </div>

        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="50rem"
      top="10rem"
    >
      <wrapper
        class="popup-bg"
        style="height:40rem;"
      >
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div
            class="stats-chart-block"
            style="height:16rem;width:86.25rem;"
          >
            <div
              class="stats-close-btn"
              @click="popupShow = false"
            />

            <div style="display:flex;align-items:center;">
              <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">产值排名详情
              </div>
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode3 == 1 }"
                @click="() => getRanking(1,'mode3')"
              >月度</div>
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode3 == 2 }"
                @click="() => getRanking(2,'mode3')"
              >年度</div>
            </div>

            <div class="mon-change">

              <img
                class="img"
                src="../../assets/nation/left_mon.png"
                @click="changeMon2(0)"
                alt=""
              >
              <div
                v-for="(item,index) in showList"
                :key="index"
                @click="changeActive2(index)"
                class="mon-item"
                :class="[index==activeMon ? 'active-mon' : '']"
              >{{item.name}}</div>
              <img
                class="img"
                src="../../assets/nation/right_mon.png"
                @click="changeMon2(1)"
                alt=""
              >
            </div>

            <div style="display:flex;align-items:center;justify-content:center;margin:1rem 0">
              <div
                class="option-block2"
                style="min-width: 5rem;border-top-left-radius: 2em;border-bottom-left-radius: 2em;"
                :class="{ 'option-block-selected2': mode4 == 1 }"
                @click="getRanking(1,'mode4')"
              >总产值</div>
              <div
                class="option-block2"
                style="min-width: 5rem;border-top-right-radius: 2em;border-bottom-right-radius: 2em; "
                :class="{ 'option-block-selected2': mode4 == 2 }"
                @click="getRanking(2,'mode4')"
              >人均产值</div>
            </div>
            <div
              v-if="!popData2.length"
              style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 44rem; height: 20rem;margin-top: 0.5rem;"
            >
              <div class="empty-chart-icon" />
              <div style="margin-top:20px;color:#ccc">暂无数据</div>
            </div>
            <div
              v-else
              style="height:28rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;padding-left: 1rem;"
            >
              <div class="table-title">
                <div class="tab-item-first">项目部</div>
                <div class="tab-item">产值(万元)</div>
                <div class="on tab-item">
                  <a
                    class="js_category"
                    @click="changeSort('sort2')"
                  >

                    <span class="rank">排名</span>
                    <i :class="sort2==2?'angle_top_desc':'angle_top'"></i>
                    <i :class="sort2==1?'angle_bottom_asc':'angle_bottom'"></i>
                  </a>
                </div>

              </div>
              <div
                v-for="project in popData2"
                :key="project.name"
                style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;color:#1782A6"
              >
                <div class="tab-item-first">{{project.project_name}}</div>
                <div
                  class="tab-item"
                  style="margin-left:0.5rem"
                >{{project.value}}</div>
                <div
                  class="tab-item"
                  style="margin-left:0.5rem"
                >{{project.output_rank}}</div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>

  </div>
</template>

<script>
require("echarts-gl");
import wrapper from "../../components/wrap";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation } from "../../api";
import { stackedBarChartTemplate } from "./barChartTemplates";
// import { cubeBarChartTemplate } from "./barChartTemplates";
import { applyUpdate, truncateStr } from "../../utils";

export default {
  name: "IndexRightBar",
  components: {
    "v-chart": ECharts,
    wrapper,
  },
  props: {
    broadcastDetails: Object,
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    gotoProject: Function,
  },
  data() {
    return {
      popupShow: false,
      OutputCompletionRateChart: {},

      option: {
        color: ["#20bbee"],
        grid: {
          left: "5%",
          right: 0,
          top: "15%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          formatter(param) {
            var s = "";
            s =
              param[0].name +
              "<br />" +
              param[0].seriesName +
              "：" +
              param[0].value +
              "<br />" +
              param[1].seriesName +
              "：" +
              param[1].value +
              "kw" +
              "<br />" +
              param[2].seriesName +
              "：" +
              param[2].value +
              "kw";
            return s;
          },
        },
        legend: {
          show: false,
        },
        xAxis: {
          axisLabel: {
            color: "#e6f2f3",
            rotate: 40,
            fontWeight: "bold",
            formatter: (value) => truncateStr(value, 10),
          },
          show: true,
          axisLine: { show: true, lineStyle: { color: "#36445f" } },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#36445f",
              width: 1,
              type: "solid",
            },
          },
          axisTick: { show: false },
          type: "category",
          data: [],
        },
        yAxis: [
          {
            name: "完成率：%",
            min: 0,
            max: 100,
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
            show: true,
          },
        ],
        dataZoom: [],
        series: [
          {
            name: "完成率",
            type: "bar",
            barMaxWidth: 9,
            stack: "Ad",
            data: [],
          },
          {
            name: "计划产值",
            type: "bar",
            barMaxWidth: 9,
            stack: "Ad",
            itemStyle: {
              color: "rgba(0,0,0,0)",
            },
            data: [],
          },
          {
            name: "实际产值",
            type: "bar",
            barMaxWidth: 9,
            stack: "Ad",
            itemStyle: {
              color: "rgba(0,0,0,0)",
            },
            data: [],
          },
        ],
      },

      mode: 1,
      mode2: 1,
      mode3: 1,
      mode4: 1,
      sort: 0, //0不做排序，1升序，2降序
      sort2: 0, //0不做排序，1升序，2降序
      threeDayManagementTopProjects: [],
      popThreeDayManagementTopProjects: [],
      securityStarPage: 1,
      securityStarPages: 1,

      search_keys: [],
      activeIndex: 0,
      mapLevel: 0,
      popupShow2: false,

      popupShow1: false,
      outputRateDetailChart: {},
      companyTroublePieSearchkeys: [],

      option6: {
        color: ["#20bbee"],
        grid: {
          left: "5%",
          right: 0,
          top: "15%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          formatter(param) {
            var s = "";
            s =
              param[0].name +
              "<br />" +
              param[0].seriesName +
              "：" +
              param[0].value +
              "<br />" +
              param[1].seriesName +
              "：" +
              param[1].value +
              "kw" +
              "<br />" +
              param[2].seriesName +
              "：" +
              param[2].value +
              "kw";
            return s;
          },
        },
        legend: {
          show: false,
        },
        xAxis: {
          axisLabel: {
            color: "#e6f2f3",
            rotate: 40,
            fontWeight: "bold",
            formatter: (value) => truncateStr(value, 10),
          },
          show: true,
          axisLine: { show: true, lineStyle: { color: "#36445f" } },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#36445f",
              width: 1,
              type: "solid",
            },
          },
          axisTick: { show: false },
          type: "category",
          data: [],
        },
        yAxis: [
          {
            name: "完成率：%",
            min: 0,
            max: 100,
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
            show: true,
          },
        ],
        dataZoom: [],
        series: [
          {
            name: "完成率",
            type: "bar",
            barMaxWidth: 9,
            stack: "Ad",
            data: [],
          },
          {
            name: "计划产值",
            type: "bar",
            barMaxWidth: 9,
            stack: "Ad",
            itemStyle: {
              color: "rgba(0,0,0,0)",
            },
            data: [],
          },
          {
            name: "实际产值",
            type: "bar",
            barMaxWidth: 9,
            stack: "Ad",
            itemStyle: {
              color: "rgba(0,0,0,0)",
            },
            data: [],
          },
        ],
      },
      monList: [],
      showList: [
        "2021-09",
        "2021-09",
        "2021-10",
        "2021-11",
        "2021-12",
        "2022-01",
        "2022-02",
        "2022-03",
        "2022-04",
        "2022-05",
      ],
      activeMon: 10,
      datePage: 1,
      datePage2: 1,

      popData2: [],
    };
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;
    window.$districtLevel = "";
    this.getData();
  },
  watch: {
    orgLevel: function (newValue) {
      if (newValue) {
        this.getData();
      }
    },
    districtLevel: function (newValue) {
      if (newValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
        this.getData();
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.mapLevel = newValue;
        if (newValue == 2) {
          this.getData();
        }
      }
    },
  },
  methods: {
    handleProject(item) {
      this.gotoProject(item.project_id);
    },
    getRanking(mode, type) {
      this[type] = mode;
      if (type == "mode" || type == "mode2") {
        this.getOutputProjectSortChart();
      } else if (type == "mode4") {
        this.getPopData2();
      } else if (type == "mode3") {
        this.datePage2 = 1;
        this.getValidDate2();
      }
    },
    changeSort(data) {
      if (this[data] < 2) {
        this[data] += 1;
      } else {
        this[data] = 0;
      }
      if (data == "sort") {
        this.getOutputProjectSortChart();
      } else if (data == "sort2") {
        this.getPopData2();
      }
    },
    getPopData1() {
      nation
        .getOutputCompletionRate(this.monList[this.activeMon].value)
        .then((d) => {
          let chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));

          this.option6.xAxis.data = d.xAxis_data;
          this.option6.series[0].data = d.series;
          this.option6.series[1].data = d.hide_series.output_value_plan;
          this.option6.series[2].data = d.hide_series.output_value_real;

          this.outputRateDetailChart = applyUpdate(chart, this.option6);
        });
    },

    getPopData2() {
      nation
        .getOutputProjectSort(
          this.mode3,
          this.mode4,
          this.sort2,
          this.showList[this.activeMon].value,
          1,
          30
        )
        .then((d) => {
          this.popData2 = d;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },

    showDetail(val) {
      if (val === 1) {
        // eslint-disable-next-line no-empty
        this.popupShow1 = true;
        this.datePage = 1;
        this.getValidDate();
      } else {
        this.popupShow = true;
        this.datePage2 = 1;
        this.mode3 = this.mode;
        this.mode4 = this.mode2;

        this.getValidDate2();
      }
    },
    changeMon(type) {
      if (type == 0) {
        this.datePage--;
      } else {
        this.datePage++;
      }
      this.getValidDate();
    },
    changeMon2(type) {
      if (type == 0) {
        this.datePage2--;
      } else {
        this.datePage2++;
      }
      this.getValidDate2();
    },
    changeActive(index) {
      this.activeMon = index;
      this.getPopData1();
    },
    changeActive2(index) {
      this.activeMon = index;
      this.getPopData2();
    },
    getValidDate() {
      nation.getOutputValidDateCollect(1, this.datePage, 12).then((d) => {
        this.monList = d;
        this.activeMon = d.length - 2;
        this.getPopData1();
      });
    },
    getValidDate2() {
      nation
        .getOutputValidDateCollect(this.mode3, this.datePage2, 6)
        .then((d) => {
          this.showList = d;
          if (this.mode3 == 1) {
            this.activeMon = d.length - 2;
          } else {
            this.activeMon = d.length - 1;
          }
          this.getPopData2();
        });
    },
    getData() {
      nation
        .getOutputCompletionRate("")
        .then((d) => {
          let chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
          this.option.xAxis.data = d.xAxis_data;
          this.option.series[0].data = d.series;
          this.option.series[1].data = d.hide_series.output_value_plan;
          this.option.series[2].data = d.hide_series.output_value_real;

          this.OutputCompletionRateChart = applyUpdate(chart, this.option);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
      this.getOutputProjectSortChart();
    },
    getOutputProjectSortChart() {
      nation
        .getOutputProjectSort(this.mode, this.mode2, this.sort, "", 1, 15)
        .then((d) => {
          this.threeDayManagementTopProjects = d;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
  },
};
</script>

<style scoped lang="less">
.mon-change {
  display: flex;
  margin: 1rem;
  justify-content: space-between;
  align-items: center;
}
.mon-item {
  padding: 0.5rem;
  border: 1px solid #52b3ff;
  border-radius: 4px;
}
.active-mon {
  background-color: #52b3ff;
}
.img {
  width: 2.5rem;
}
.table-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: #fff;
}
.tab-item-first {
  //   flex: 3;
  width: 60%;
}
.tab-item {
  //   flex: 1;
  width: 20%;
  text-align: center;
}
.on {
  display: table-cell;
  position: relative;
}

.on a {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
}

.angle_top {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #5e5e5e;
  position: absolute;
  transform: rotate(180deg);
  bottom: 0.5rem;
  right: 30%;
}
.angle_top_desc {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #4191e5;
  position: absolute;
  transform: rotate(180deg);
  bottom: 0.5rem;
  right: 30%;
}
.angle_bottom {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #5e5e5e;
  position: absolute;
  top: 0.6rem;
  right: 30%;
}
.angle_bottom_asc {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #4191e5;
  position: absolute;
  top: 0.6rem;
  right: 30%;
}
.alarm-icon {
  width: 60px;
  height: 59px;
}

.option-detail {
  position: absolute;
  color: #c8dbf4;
  font-weight: bold;
  right: 0.5rem;
  top: 0.6rem;
  font-size: 0.6rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  border: 1px solid #0d2b50;
  text-align: center;
  cursor: pointer;
  background-color: #4999d9;
  // background-color: #0a57be;
}
#alarm-drop {
  background: url("../../assets/nation/坠落报警.png");
  background-size: contain;
}
#alarm-help {
  background: url("../../assets/nation/紧急呼救.png");
  background-size: contain;
}
#alarm-danger {
  background: url("../../assets/nation/危险预警.png");
  background-size: contain;
}
#alarm-static {
  background: url("../../assets/nation/静止报警.png");
  background-size: contain;
}

.alarm-icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
