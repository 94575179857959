<template>
  <div id="left">
    <div
      id="project-base-info"
      style="margin-bottom: 1rem;"
    >
      <wrapper style="padding: 0">
        <div
          class="info-block"
          style="min-height:5rem"
        >
          <div
            class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
          >
            <div>工期进度</div>
            <div
              class="detail"
              @click="showDetail(1)"
            >
              详情
            </div>
          </div>
          <div style="padding-top: 3rem">
            <div style="height: 10.8rem; overflow-y: scroll; overflow-x: hidden;">
              <div
                v-if="gongqijindu.series && [].concat.apply([], gongqijindu.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 10rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart
                :options="gongqijindu"
                ref="gongqijindu"
                autoresize
                :style="{height: gongqijindu.domHeight || '10.8rem'}"
              />
            </div>
          </div>

        </div>
      </wrapper>
    </div>

    <wrapper style="padding:0">
      <div
        class="info-block"
        style="min-height:5rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>产值统计</div>
          <div
            class="detail"
            @click="showDetail(2)"
          >
            详情
          </div>
        </div>
        <div style="padding-top: 3rem;height:20rem;">
          <div style="height: 10rem;position:relative">
            <div
              v-if="option4.series && [].concat.apply([], option4.series.map(x => x.data)).length == 0"
              style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 4rem;margin-top: 0.5rem;"
            >
              <div class="empty-chart-icon" />
              <div style="margin-top:20px;color:#ccc">暂无数据</div>
            </div>
            <v-chart
              v-else
              :options="option4"
              autoresize
            />
            <div class="rank">
              <span>产值自施率占比</span>
            </div>
          </div>
          <div style="display:flex;justify-content: center;margin-top: 1rem">
            <div class="total">
              <div style="color: #29C798;font-size: 18px;font-weight:bold;">{{config.total_output}}万元</div>
              <div>自开工总产值</div>
            </div>
            <div class="now">
              <div style="color: #3E89DB;font-size: 18px;font-weight:bold;">{{config.now_year_output}}万元</div>
              <div>本月总产值</div>
            </div>
          </div>
          <div style="display:flex;justify-content: center;padding-top:2rem">
            <div
              class="option-block2"
              style="min-width: 5rem;"
              :class="{ 'option-block-selected2': mode == 1 }"
              @click="() => outputChange(1)"
            >累计</div>
            <div
              class="option-block2"
              style="min-width: 5rem;"
              :class="{ 'option-block-selected2': mode == 2 }"
              @click="() => outputChange(2)"
            >月度</div>
          </div>
        </div>
        <div style="height:22rem;padding-top:3rem">
          <div
            v-if="outputChart.series && [].concat.apply([], outputChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart
            v-else
            :options="outputChart"
            autoresize
          />
        </div>
      </div>
    </wrapper>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="90rem"
      top="12rem"
      :destroy-on-close='true'
    >
      <wrapper style="height:20rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow = false"
        />
        <div>
          <div style="font-size:1rem;margin:2rem 0 0.5rem 1rem;">工期进度详情</div>
          <div
            class="stats-chart-block"
            style="height:13rem;width: 100%;overflow-y:hidden;overflow:auto"
          >
            <div style="margin:0.5rem 0.5rem 0 0.5rem; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="min-width:10rem">楼栋</div>
                <div style="min-width:10rem">工期进度</div>
                <div style="min-width:10rem">每层平均工期</div>
                <div
                  v-if="outputLists.length"
                  style="display:flex"
                >
                  <div
                    style="width:4rem;display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855"
                    v-for="title in outputLists[0].floor_info"
                    :key="title.num"
                  >{{title.num}}层</div>
                </div>
              </div>
              <div style="min-height:10rem;">
                <div
                  v-for="(item, index) in outputLists"
                  :key="item.worker_name"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="min-width:10rem">{{item.build_name}}</div>
                  <div style="min-width:10rem">{{item.now_floors}}/{{item.total_floors}}</div>
                  <div style="min-width:10rem">{{item.avg_days}}</div>
                  <div
                    v-if="outputLists.length"
                    style="display:flex"
                  >
                    <div
                      style="width:4rem;display:flex; height: 2rem; align-items: center;padding:0 1rem;justify-content: space-between;"
                      :style="{ background: index%2 ? '#102855' : '#213a77'}"
                      v-for="floor in item.floor_info"
                      :key="floor.num"
                    >
                      <i v-if="floor.label !== 1 && floor.label !== 4 && floor.days">{{floor.days}}天</i>
                      <i
                        v-else-if="floor.label === 4"
                        style="color: #B7952F;cursor: pointer;"
                        @click="open(floor)"
                      >{{floor.days}}天</i>
                      <i v-else-if="floor.label == 1">-</i>
                      <i v-else></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <el-pagination background layout="prev, pager, next" :current-page.sync="securityStarPage" :page-count="securityStarPages" @current-change="securityStarPageChange" style="text-align:center"></el-pagination> -->
          </div>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="90rem"
      top="8rem"
      :destroy-on-close='true'
    >
      <wrapper style="height:52rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow2 = false"
        />
        <div class="attendance">
          <div class="attendance-top">
            <div class="tabbar">
              <div
                :class="{ 'tabar-select': popupSelect == 1 }"
                @click="getAttendance(1)"
              >按季</div>
              <div
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >按月</div>
            </div>
            <div style="font-size:1rem;margin:1rem 0 0.5rem 2rem;">产值统计详情</div>
            <div style="height:16rem;padding-top:1rem">
              <v-chart
                :options="outputChart2"
                @click="attenceWorkersBarChartClick"
                autoresize
              />
            </div>
          </div>
          <div style="font-size:1.3rem;margin:1rem 0 1.2rem;text-align:center">{{popupBottomName}}</div>
          <div style="margin:0.5rem 0.5rem 0 0.5rem; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:20rem">共建方</div>
              <div style="width:10rem">总产值</div>
              <div style="width:10rem">预计产值</div>
              <div style="width:10rem">人均产值</div>
            </div>
            <div style="min-height:10rem;">
              <div
                v-for="(item, index) in outputChartLists"
                :key="item.worker_name"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:20rem">{{item.name}}</div>
                <div style="width:10rem">{{item.total_count}}万元</div>
                <div style="width:10rem">{{item.plan_count}}万元</div>
                <div style="width:10rem">{{item.avg_count}}万元</div>
              </div>
            </div>
          </div>
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page.sync="securityStarPage"
            :page-count="securityStarPages"
            @current-change="securityStarPageChange"
            style="text-align:center;margin-top: 16px"
          ></el-pagination>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import wrapper from "../../components/wrap";
import { project } from "../../api";
import { SimpleBarChartTemplate } from "./barChartTemplates";
import { gaugeChartTemplate } from "./pieChartTemplates";
import { applyUpdate } from "../../utils";

export default {
  name: "ScheduleLeftBar",
  components: {
    "v-chart": ECharts,
    wrapper,
  },
  props: {
    projectDetail: Object,
    wearMap: Array,
    motionMap: Object,
    batteryMap: Function,
    statusColorMap: Object,
    retrieveStatusMap: Object,
    groupList: Array,
  },
  data() {
    return {
      gongqijindu: {
        domHeight: "10.5rem",
        xAxis: {
          max: 100,
          show: false,
        },
        tooltip: {
          trigger: "item",
          position: "bottom",
          formatter: (p) =>
            `${p.data.name}: ${p.data.cur}层 / ${p.data.total}层 (${p.data.rate}%)`,
        },
        grid: {
          left: 80,
          top: 0,
          right: 70,
          bottom: 0,
          contentLabel: true,
        },
        yAxis: {
          inverse: true,
          type: "category",
          show: false,
          data: [],
        },
        series: [
          {
            type: "bar",
            barWidth: 18,
            barMinWidth: 18,
            barGap: "30%",
            barCategoryGap: "30%",
            legendHoverLink: false,
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  colorStops: [
                    {
                      offset: 0,
                      color: "#2980d9",
                    },
                    {
                      offset: 1,
                      color: "#7440ea",
                    },
                  ],
                },
              },
            },
            label: {
              show: true,
              position: "left",
              formatter: "{b}",
              color: "white",
            },
            data: [],
            z: 1,
            animationEasing: "elasticOut",
          },
          {
            type: "pictorialBar",
            itemStyle: {
              normal: {
                color: "#000",
              },
            },
            label: {
              normal: {
                formatter: (v) => `${v.data.cur}层 / ${v.data.total}层`,
                color: "white",
                position: "right",
                show: true,
              },
            },
            symbolRepeat: "fixed",
            symbolMargin: 6,
            symbol: "rect",
            symbolClip: true,
            symbolSize: [1, 21],
            symbolPosition: "start",
            symbolOffset: [1, -1],
            symbolBoundingData: 100,
            data: [],
            z: 2,
            animationEasing: "elasticOut",
          },
          {
            type: "bar",
            barGap: "-120%",
            barCategoryGap: "20%",
            data: [],
            barWidth: 25,
            barMinWidth: 25,
            itemStyle: {
              color: "transparent",
              barBorderColor: "#595ef5",
              barBorderWidth: 1,
              label: { show: false },
            },
            z: 0,
          },
        ],
      },
      outputChart: {},
      option2: {
        tooltip: {
          trigger: "axis",
          // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data +
                (params[0].seriesIndex == 2 ? "%" : "");
              if (params[1]) {
                result +=
                  "</br>" +
                  " " +
                  params[1].seriesName +
                  " : " +
                  params[1].data +
                  (params[1].seriesIndex == 2 ? "%" : "");
              }
              if (params[2]) {
                result +=
                  "</br>" +
                  " " +
                  params[2].seriesName +
                  " : " +
                  params[2].data +
                  "%";
              }
            }
            return result;
          },
        },
        color: ["#3b87f8", "#FBC71E"],
        grid: {
          contentLabel: true,
          left: "13%",
          right: "4%",
          bottom: "10%",
          top: "13%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              rotate: 35,
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            name: "产值：Kw",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
            show: true,
          },
        ],
        series: [
          {
            name: "实际产值",
            type: "bar",
            barWidth: 10,
            stack: "Ad",
            data: [],
          },
          {
            name: "预计产值",
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                },
              },
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(27, 208, 128, 0.3)" },
                { offset: 1, color: "rgba(27, 208, 128, 0)" },
              ]),
            },
            symbolSize: 0,
            // yAxisIndex: 1,
            data: [],
          },
          {
            type: "bar",
            barMaxWidth: 9,
            stack: "Ad",
            itemStyle: {
              color: "rgba(0,0,0,0)",
            },
            data: [],
          },
        ],
      },
      mode: 1,
      config: {},
      popupShow: false,
      outputLists: [],
      popupShow2: false,
      popupSelect: 1,
      outputChart2: {},
      option3: {
        color: ["#23CF9E", "#1ACFDB", "#1490CA"],
        grid: {
          contentLabel: true,
          top: "25%",
          left: "7%",
          right: "8%",
          bottom: "10%",
        },
        legend: {
          data: [],
          itemWidth: 20,
          itemHeight: 10,
          textStyle: {
            fontSize: 10,
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          // formatter: '{b}<br>{a0}: {c0}<br>{a1}: {c1}<br>{a2}: {c2}'

          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                params[0].marker +
                " " +
                params[0].seriesName +
                " : " +
                (params[0].data.value ? params[0].data.value : params[0].data);
              if (params[1]) {
                result +=
                  "</br>" +
                  params[1].marker +
                  " " +
                  params[1].seriesName +
                  " : " +
                  params[1].data;
              }
              if (params[2]) {
                result +=
                  "</br>" +
                  params[2].marker +
                  " " +
                  params[2].seriesName +
                  " : " +
                  params[2].data;
              }
            }
            return result;
          },
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: false, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
            name: "",
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: 10,
            yAxisIndex: 0,
            data: [],
          },
          {
            name: "",
            type: "line",
            // itemStyle: {
            //   normal: {
            //     lineStyle: {
            //       width: 2
            //     }
            //   }
            // },
            // symbolSize: 0,
            data: [],
          },
          {
            name: "",
            type: "line",
            // itemStyle: {
            //   normal: {
            //     lineStyle: {
            //       width: 2
            //     }
            //   }
            // },
            // symbolSize: 0,
            data: [],
          },
        ],
      },
      outputChartLists: [],
      popupBottomName: "",
      popupSearchkeys: [],
      popupXAxisData: [],
      securityStarPage: 1,
      securityStarPages: 1,
      dataIndex: -1,
      popupData: [],
      option4: {},
    };
  },
  mounted() {
    // const self = this
    const { id: projectID } = this.$route.params;
    this.projectId = projectID;
    const token = this.$route.query.token;
    window.token = token;

    // this.projectId = '48146e7e-b027-4bf4-8520-0d935405b681';
    this.getAll();
  },
  methods: {
    getAll() {
      project.getProjBuildScheduleCollect(this.projectId).then((d) => {
        // console.log(d);
        if (!d.length) return;
        const names = [];
        const outer = [];
        const inner = [];
        d.forEach((v) => {
          names.push(v.build_name);
          outer.push({
            name: v.build_name,
            total: v.total_floors,
            cur: v.now_floors,
            rate: v.value,
            value: 100,
          });
          inner.push({
            name:
              v.build_name.length >= 7
                ? v.build_name.slice(0, 5) + "..."
                : v.build_name,
            total: v.total_floors,
            cur: v.now_floors,
            rate: v.value,
            value: v.value,
          });
        });
        this.gongqijindu.domHeight =
          names.length > 6 ? 2.21 * names.length + "rem" : "10.5rem";
        this.gongqijindu.yAxis.data = names;
        this.gongqijindu.series[0].data = inner;
        this.gongqijindu.series[1].data = inner;
        this.gongqijindu.series[2].data = outer;
        this.$nextTick(
          () => this.$refs.gongqijindu && this.$refs.gongqijindu.resize()
        );
      });

      this.outputChange(this.mode);
    },
    outputChange(mode) {
      this.mode = mode;
      project
        .getProjBuildMonth12OutputCollect(this.projectId, mode)
        .then((d) => {
          // console.log(d);
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option2.legend.data = d.legend;
          this.option2.xAxis[0].data = d.xAxis_data;
          this.option2.series[0].data = d.bar_series;
          this.option2.series[0].name = d.legend[1];
          this.option2.series[1].data = d.line_series;
          this.option2.series[1].name = d.legend[0];
          this.option2.series[2].data = d.hide_series.rate;
          this.option2.series[2].name = "完成率";
          this.config = d.config[0];
          this.outputChart = applyUpdate(chart, this.option2);

          const chart2 = JSON.parse(JSON.stringify(gaugeChartTemplate));
          chart2.series[0].data[0].value = d.config[0].rate;
          chart2.series[0].data[0].name = d.config[0].rate + "%";
          chart2.series[0].data[1].value = d.config[0].rate;
          chart2.series[0].data[2].value = 100 - d.config[0].rate;
          this.option4 = chart2;
        });
    },
    showDetail(val) {
      // eslint-disable-next-line no-empty
      if (val === 1) {
        this.popupShow = true;
        project.getProjBuildScheduleDetailCollect(this.projectId).then((d) => {
          // console.log(d);
          this.outputLists = d;
        });
        // eslint-disable-next-line no-empty
      } else if (val === 2) {
        this.popupShow2 = true;
        this.getAttendance(this.popupSelect);
      }
    },
    open(item) {
      this.$alert(item.note[1], item.note[0], {
        confirmButtonText: "确定",
      });
    },
    getAttendance(mode) {
      this.popupSelect = mode;
      project
        .getProjScheduleOutputDetailCollect(this.projectId, mode)
        .then((d) => {
          // console.log(d);
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option3.legend.data = d.legend;
          this.option3.xAxis[0].data = d.xAxis_data;
          this.popupData = d.bar_series;
          d.bar_series[d.bar_series.length - 1] = {
            value: d.bar_series[d.bar_series.length - 1],
            itemStyle: {
              color: "#FFD153",
            },
          };
          this.option3.series[0].data = d.bar_series;
          this.option3.series[0].name = d.legend[0];
          this.option3.series[1].data = d.line_series[0];
          this.option3.series[1].name = d.legend[1];
          this.option3.series[2].data = d.line_series[1];
          this.option3.series[2].name = d.legend[2];
          this.outputChart2 = applyUpdate(chart, this.option3);
          this.popupBottomName = d.xAxis_data[d.xAxis_data.length - 1];
          this.getLaborAttendanceDateOrgProjectCollect(
            d.search_keys[d.search_keys.length - 1]
          );
          this.popupSearchkeys = d.search_keys;
          this.popupXAxisData = d.xAxis_data;
        });
    },
    attenceWorkersBarChartClick(params) {
      let subData = this.popupData;
      this.outputChart2.series[0].data = subData.map(function (item, index) {
        return index === params.dataIndex
          ? {
              value:
                Object.prototype.toString.call(item) === "[object Object]"
                  ? item.value
                  : item,
              itemStyle: {
                color: "#FFD153",
              },
            }
          : Object.prototype.toString.call(item) === "[object Object]"
          ? item.value
          : item;
      });
      this.popupBottomName = this.popupXAxisData[params.dataIndex];
      this.dataIndex = params.dataIndex;
      this.getLaborAttendanceDateOrgProjectCollect(
        this.popupSearchkeys[params.dataIndex]
      );
    },
    getLaborAttendanceDateOrgProjectCollect(search_keys) {
      project
        .getProjScheduleOutputCompanyDetailCollect(
          this.projectId,
          this.popupSelect,
          search_keys,
          this.securityStarPage,
          10
        )
        .then((d) => {
          // console.log(d);
          this.outputChartLists = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        });
    },
    securityStarPageChange(currPage) {
      this.securityStarPage = currPage;
      this.getLaborAttendanceDateOrgProjectCollect(
        this.popupSearchkeys[this.dataIndex]
      );
    },
  },
};
</script>

<style scoped lang="less">
.rank {
  position: absolute;
  bottom: 1rem;
  left: 36%;
}
#project-base-info {
  p {
    font-size: 0.875rem;
    line-height: 1.1rem;
    margin: 0.5rem 0;
    display: flex;

    .title {
      width: 4.6rem;
      color: #5accf1;
    }

    .value {
      flex: 1;
      text-align: right;
      color: #ffffff;
    }
  }
}

#people-status {
  #retreat-btn {
    margin: 1rem 0;
    height: 1.875rem;
    text-align: center;
    line-height: 1.875rem;
    cursor: pointer;
    background: linear-gradient(to right, #51bbf5, #5170f5);
  }

  .group {
    height: 31.2rem;
    overflow-y: scroll;

    .group-item {
      .group-title {
        margin-bottom: 1rem;

        .group-title-icon {
          width: 1rem;
          height: 1rem;
          display: inline-block;
          margin-right: 0.25rem;
          cursor: pointer;
          position: relative;

          &.active {
            transform: rotate(-90deg);
          }

          &::before {
            content: " ";
            position: absolute;
            width: 1rem;
            font-size: 1rem;
            transform: rotate(0deg);
            transition: transform 0.3s ease-in-out;
            height: 1rem;
            background: url("../../assets/sanjiao.png") no-repeat;
          }
        }
      }

      .people-card-list {
        .card-item {
          padding: 0.4rem 1rem;
          display: flex;
          margin-bottom: 0.75rem;
          background-color: #1e2c61;
          border-radius: 0.25rem;

          .left {
            flex: 1;
            font-size: 0.88rem;
            line-height: 1.7rem;

            .title {
              color: #999999;
            }
          }

          .right {
            width: 3.8rem;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .status-card {
              border-width: 0.5rem 1px 1px;
              border-style: solid;
              border-radius: 0.25rem;
              margin-bottom: 1rem;
              padding: 0.7rem;

              .zt {
                font-size: 0.88rem;
                color: #999;
                padding: 3px 0;
              }

              .status-text {
                padding-top: 5px;
                font-size: 1.13rem;
                font-weight: bold;
              }
            }

            .op {
              width: 100%;
              height: 1.5rem;
              background: linear-gradient(
                90deg,
                rgba(81, 189, 245, 1),
                rgba(81, 112, 245, 1)
              );
              line-height: 1.5rem;
              cursor: pointer;
              font-size: 0.75rem;
              text-align: center;

              &.disabled {
                background: #8a8a8a;
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }
  }
}
.total {
  width: 9rem;
  border: 1px solid #20a584;
  padding: 0.8rem 0;
  color: #fff;
  background-color: rgba(32, 165, 132, 0.2);
  text-align: center;
}
.now {
  width: 9rem;
  border: 1px solid #224d7e;
  padding: 0.8rem 0;
  color: #fff;
  background-color: rgba(34, 77, 126, 0.2);
  text-align: center;
  margin-left: 1rem;
}
i {
  font-style: normal;
}
</style>

<style>
.el-message-box {
  color: #000 !important;
  background-color: #fff !important;
}
.el-message-box__title,
.el-message-box__content {
  color: #000 !important;
}
</style>