<template>
  <div id="left">
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;top: 0.2rem;"
      >
        <div>数据情况总览</div>
        <div
          class="detail"
          style="margin-left: 0.5rem;"
          @click="showDetail(1)"
        >
          详情
        </div>

      </div>
      <div style="height:18rem;padding-top:3rem">
        <div
          v-if="overviewData.series && [].concat.apply([], overviewData.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="overviewData"
          @click="overviewDataClick"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div
        class="info-block-title"
        style="display: flex;align-items:center;width: 85%;justify-content: space-between;"
      >
        <div>劳动力周计划使用情况</div>
        <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode2 == 1 }"
            @click="() => getLaborWeekPlanChart(1)"
          >本周</div>
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode2 == 2 }"
            @click="() => getLaborWeekPlanChart(2)"
          >近四周</div>
          <div
            class="detail"
            style="margin-left: 0.5rem;"
            @click="showDetail(2)"
          >
            详情
          </div>
        </div>
      </div>
      <div style="height:20rem;padding-top:3rem">
        <div
          v-if="laborWeekPlanChart.series && [].concat.apply([], laborWeekPlanChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="laborWeekPlanChart"
          @click="laborWeekPlanChartClick"
          autoresize
        />
      </div>

    </div>
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>帽子领用情况预览</div>
        <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode == 1 }"
            @click="() => getHatUsedChart(1)"
          >项目</div>
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode == 2 }"
            @click="() => getHatUsedChart(2)"
          >人员</div>
          <!-- <div
            class="detail"
            style="margin-left: 0.5rem;"
            @click="showDetail(3)"
          >
            详情
          </div> -->
        </div>
      </div>
      <div style="height:20rem;padding-top:3rem">
        <div
          v-if="hatUsedPie.series && [].concat.apply([], hatUsedPie.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="hatUsedPie"
          ref="troubleType"
          @mouseover="hatUsedPieMouseOver"
          @mouseout="hatUsedPieMouseOut"
          autoresize
        />
      </div>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow1"
      width="60rem"
      top="12rem"
      :destroy-on-close='true'
    >
      <wrapper
        class="popup-bg"
        style="height:42rem;backgroundColor:#0e1328;position:relative"
      >
        <div
          class="stats-close-btn"
          @click="closePop1"
        />
        <div class="attendance">
          <div class="attendance-top">
            <div style="display:flex;justify-content: space-between; margin: 1rem;">
              <div></div>
              <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle1}}</div>
              <div></div>
            </div>
            <div style="height:37rem;padding-top:1rem;position:relative">

              <div
                v-if="overviewDataDetail.series && [].concat.apply([], overviewDataDetail.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;height: 12rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart
                v-else
                :options="overviewDataDetail"
                autoresize
              />
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="60rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:42rem;backgroundColor:#0e1328;position:relative"
      >
        <div
          class="stats-close-btn"
          @click="closePop2"
        />
        <div class="attendance">
          <div style="display:flex;justify-content: space-between; margin: 1rem;">
            <div style="min-width:7rem"></div>
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle2}}
            </div>
            <div style="display:flex;align-items:center;">
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode4 == 1 }"
                @click="() => getPopData2(1)"
              >本周</div>
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode4 == 2 }"
                @click="() => getPopData2(2)"
              >近四周</div>
            </div>

          </div>

          <div style="margin:1.5rem 1rem">
            <div style="height:37rem;padding-top:1rem">
              <div
                v-if="laborWeekDetailChart.series && [].concat.apply([], laborWeekDetailChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;height: 16rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart
                v-else
                :options="laborWeekDetailChart"
                autoresize
              />
            </div>
          </div>

        </div>
      </wrapper>
    </el-dialog>

  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation, project } from "../../api";
// import CustomSelect from "../../components/select";
import { popupChartTemplate, colors } from "./pieChartTemplates";
import {
  SimpleBarChartTemplate,
  stackedBarChartTemplate,
} from "./barChartTemplates";
import { applyUpdate, truncateStr } from "../../utils";
import wrapper from "../../components/wrap";

export default {
  name: "SecurityCheckLeftBar",
  components: {
    "v-chart": ECharts,
    wrapper,
    // CustomSelect: CustomSelect,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    orgType: Number,
    orgOptions: Array,
    gotoProject: Function,
  },
  data() {
    return {
      mapLevel: 0,
      org_id: "",
      companyNameList: [""],
      companyIdList: [""],
      selectVal1: "",
      selectVal0: "",

      // 图表1
      overviewData: {},
      option: {
        color: ["#2662f9", "#4ab27c", "#ff9308", "#ecd61d"],
        grid: {
          left: "5%",
          right: "5%",
          top: "25%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data;
              if (params[1]) {
                result +=
                  "</br>" + " " + params[1].seriesName + " : " + params[1].data;
              }
              if (params[2]) {
                result +=
                  "</br>" + " " + params[2].seriesName + " : " + params[2].data;
              }
              if (params[3]) {
                result +=
                  "</br>" + " " + params[3].seriesName + " : " + params[3].data;
              }
            }
            return result;
          },
        },
        legend: {
          //   show: false,
          type:'scroll',
          data: [],
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              rotate: 40,
              fontWeight: "bold",
              formatter: (value) => truncateStr(value, 10),
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#36445f",
                width: 1,
                type: "solid",
              },
            },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            show: true,
            name: "数量",
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#36445f" },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                type: [1, 1],
                width: 1,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
          },
          {
            min: 0,
            max: 100,
            name: "百分率:%",
            interval: 25,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        dataZoom: [],
        series: [
          {
            type: "bar",
            barMaxWidth: 9,
            barGap: 0,
            data: [],
          },
          {
            type: "bar",
            barMaxWidth: 9,
            data: [],
          },
          {
            type: "bar",
            barMaxWidth: 9,
            data: [],
          },
          {
            type: "line",
            yAxisIndex: 1,
            data: [],
          },
        ],
      },
      search_keys1: [],
      // 图表2
      mode2: 1,
      laborWeekPlanChart: {},
      option2: {
        color: ["#2662f9", "#4ab27c", "#ecd61d"],
        grid: {
          left: "8%",
          right: "5%",
          top: "24%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data;
              if (params[1]) {
                result +=
                  "</br>" + " " + params[1].seriesName + " : " + params[1].data;
              }
              if (params[2]) {
                result +=
                  "</br>" + " " + params[2].seriesName + " : " + params[2].data;
              }
            }
            return result;
          },
        },
        legend: {
          data: [],
        },
        xAxis: {
          axisLabel: {
            color: "#e6f2f3",
            rotate: 40,
            fontWeight: "bold",
            formatter: (value) => truncateStr(value, 10),
          },
          show: true,
          axisLine: { show: true, lineStyle: { color: "#36445f" } },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#36445f",
              width: 1,
              type: "solid",
            },
          },
          axisTick: { show: false },
          type: "category",
          data: [],
        },
        yAxis: [
          {
            type: "value",
            show: true,
            name: "产值：kw",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#36445f" },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                type: [1, 1],
                width: 1,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
          },
          {
            min: 0,
            max: 100,
            name: "百分率:%",
            interval: 25,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        dataZoom: [],
        series: [
          {
            type: "bar",
            barMaxWidth: 9,
            barGap: 0,
            stack: "Ad",
            data: [],
          },
          {
            type: "bar",
            barMaxWidth: 9,
            data: [],
          },
          {
            type: "line",
            yAxisIndex: 1,
            data: [],
          },
        ],
      },
      search_keys2: [],

      // 图表3
      mode: 1,
      hatUsedPie: {},
      option3: {
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          containLabel: false,
        },
        legend: {
          orient: "vertical",
          left: "70%", //图例距离左的距离
          height: "80%",
          y: "center", //图例上下居中
          symbol: "rect",
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 14,
          formatter: null,
          textStyle: {
            color: "#fff",
            rich: {
              a: {
                color: "#878b94",
                fontSize: 14,
              },
              b: {
                color: "#ffffff",
                fontSize: 14,
              },
              c: {
                color: "transparent",
                fontSize: 14,
              },
            },
          },
          data: [],
        },
        color: colors,
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            //center: ['46%', '60%'],
            center: ["38%", "53%"], //图的位置，距离左跟上的位置
            data: [],
            label: {
              fontSize: 11,
              formatter: "",
              normal: {
                show: false,
                color: "#fff",
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                formatter: "{value|{@total}}\n{text|在施项目}",
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
          },
        ],
      },
      OutputTotalHighlightIndex: 0,

      // 弹窗1
      popupShow1: false,
      popupTitle1:'',
      overviewDataDetail: {},
      option5: {
        color: ["#2662f9", "#4ab27c", "#ff9308", "#ecd61d"],
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data;
              if (params[1]) {
                result +=
                  "</br>" + " " + params[1].seriesName + " : " + params[1].data;
              }
              if (params[2]) {
                result +=
                  "</br>" + " " + params[2].seriesName + " : " + params[2].data;
              }
              if (params[3]) {
                result +=
                  "</br>" + " " + params[3].seriesName + " : " + params[3].data;
              }
            }
            return result;
          },
        },
        legend: {
          data: [],
        },
        grid: {
          left: "3%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside", // 放大和缩小
            orient: "vertical",
            maxValueSpan: 5,
            minValueSpan: 5,
          },
        ],
        xAxis: [
          {
            name: "数量",
            type: "value",
            position: "top",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            //   splitLine: { show: false },
            axisTick: { show: false },
          },
          {
            name: "百分率:%",
            min: 0,
            max: 100,
            type: "value",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
          },
        ],
        yAxis: [
          {
            type: "category",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              formatter: (value) => truncateStr(value, 15),
            },
            axisTick: {
              show: false,
            },
            data: [],
          },
        ],
        series: [
          {
            xAxisIndex: 0,
            name: "",
            barMaxWidth: 15,
            type: "bar",
            barGap: 0,
            data: [],
          },
          {
            xAxisIndex: 0,
            name: "",
            barMaxWidth: 15,
            type: "bar",
            data: [],
          },
          {
            xAxisIndex: 0,
            name: "",
            barMaxWidth: 15,
            type: "bar",
            data: [],
          },
          {
            xAxisIndex: 1,
            data: [],
            type: "line",
          },
        ],
      },
      activeIndex1: -1,
      showInfoType1: 1,

      // 弹窗2
      mode4: 1,
      popupTitle2:'',
      popupShow2: false,
      laborWeekDetailChart: {},
      activeIndex2: -1,
      option6: {
        color: ["#2662f9", "#4ab27c", "#ecd61d"],
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data;
              if (params[1]) {
                result +=
                  "</br>" + " " + params[1].seriesName + " : " + params[1].data;
              }
              if (params[2]) {
                result +=
                  "</br>" + " " + params[2].seriesName + " : " + params[2].data;
              }
            }
            return result;
          },
        },
        legend: {
          data: [],
        },
        grid: {
          left: "3%",
          top: "5%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside", // 放大和缩小
            orient: "vertical",
            maxValueSpan: 5,
            minValueSpan: 5,
          },
        ],
        xAxis: [
          {
            name: "数量",
            minInterval: 1,
            type: "value",
            position: "top",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            //   splitLine: { show: false },
            axisTick: { show: false },
          },
          {
            name: "百分率:%",
            min: 0,
            max: 100,
            type: "value",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
          },
        ],
        yAxis: [
          {
            type: "category",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              formatter: (value) => truncateStr(value, 15),
            },
            axisTick: {
              show: false,
            },
            data: [],
          },
        ],
        series: [
          {
            xAxisIndex: 0,
            name: "",
            type: "bar",
            barMaxWidth: 15,
            barGap: 0,
            data: [],
          },
          {
            xAxisIndex: 0,
            name: "",
            type: "bar",
            barMaxWidth: 15,
            data: [],
          },
          {
            name: "",
            xAxisIndex: 1,
            data: [],
            type: "line",
          },
        ],
      },
      showInfoType2: 1,
    };
  },
  watch: {
    orgOptions(newValue, oldValue) {
      if (newValue != oldValue) {
        console.log(this.orgOptions);
      }
    },
    orgLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.org_id = this.orgLevel;
        this.getAll();
      }
    },
    districtLevel: function (newValue) {
      if (newValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
        this.getAll();
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.mapLevel = newValue;
      }
    },
  },
  mounted() {
    const token = this.$route.query.token;
    window.token = token;

    this.getAll();
  },
  methods: {
    showDetail(val) {
      if (val === 1) {
        this.popupShow1 = true;
        this.popupTitle1='数据情况总览';
        this.showInfoType1 = 1;
        this.getpopData1(1);
      } else if (val === 2) {
        this.popupShow2 = true;
        this.popupTitle2='劳动力周计划功能使用情况';
        this.showInfoType2 = 1;
        this.getPopData2(1);
      }
    },
    // 获取页面图表左边三个
    getAll() {
      this.getLeftOneChart();
      this.getLaborWeekPlanChart(1);
      this.getHatUsedChart(1);
    },
    getLeftOneChart() {
      nation
        .getHatOrgCityCollect(2, this.orgLevel, this.districtLevel)
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option.legend.data = d.legend_data;
          this.option.xAxis[0].data = d.xAxis_data;
          this.option.series[0].data = d.bar_series[0];
          this.option.series[0].name = d.legend_data[1];

          this.option.series[1].data = d.bar_series[1];
          this.option.series[1].name = d.legend_data[2];
          this.option.series[2].data = d.bar_series[2];
          this.option.series[2].name = d.legend_data[3];
          this.option.series[3].data = d.line_series;
          this.option.series[3].name = d.legend_data[0];
          this.search_keys1 = d.search_keys;
          this.overviewData = applyUpdate(chart, this.option);
        });
    },
    getLaborWeekPlanChart(mode) {
      this.mode2 = mode;
      nation
        .getHatOrgCityPlanCollect(
          2,
          this.mode2,
          this.orgLevel,
          this.districtLevel
        )
        .then((d) => {
          let chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option2.legend.data = d.legend_data;
          this.option2.xAxis.data = d.xAxis_data;
          this.option2.series[0].name = d.legend_data[1];
          this.option2.series[0].data = d.bar_series[0];
          this.option2.series[1].name = d.legend_data[2];
          this.option2.series[1].data = d.bar_series[1];
          this.option2.series[2].name = d.legend_data[0];
          this.option2.series[2].data = d.line_series;
          this.search_keys2 = d.search_keys;

          this.laborWeekPlanChart = applyUpdate(chart, this.option2);
        });
    },
    getHatUsedChart(mode) {
      this.mode = mode;
      nation
        .getHatUseCollect(mode, this.orgLevel, this.districtLevel)
        .then((d) => {
          let chart = JSON.parse(JSON.stringify(popupChartTemplate));
          this.option3.color = colors;

          if (this.OutputTotalHighlightIndex != null) {
            this.$refs.troubleType &&
              this.$refs.troubleType.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: this.OutputTotalHighlightIndex,
              });
          }

          // 默认高亮第一个值不是0的分片，否则默认第一片
          this.OutputTotalHighlightIndex = 0;
          for (let i = 0; i < d.length; i++) {
            if (d[i].people_count) {
              this.OutputTotalHighlightIndex = i;
              break;
            }
          }
          d.forEach((e) => {
            this.option3.legend.data.push(e.label, e.value);
          });
          this.option3.series[0].data = d.map((x) => ({
            name: x.label,
            value: x.value,
            total: x.total,
          }));
          if (window.innerWidth < 2250) {
            this.option3.series[0].radius = [55, 65];
          }

          this.option3.legend.formatter = function (name) {
            for (var i = 0; i < d.length; i++) {
              if (d[i].label === name) {
                var info = name + " " + d[i].ratio + "%";
                let tip = "";
                let le = info.length; //图例文本的长度
                if (le > 4) {
                  tip += info.slice(0, 4) + "\n" + info.slice(5, le); //字符串拼接
                  return tip;
                } else {
                  tip = info;
                  return tip;
                }
              }
            }
          };
          this.option3.series[0].label.normal.formatter = function (params) {
            return params.data.total + "\n" + "实施项目";
          };
          this.option3.series.push(
            Object.assign({}, this.option3.series[0], {
              label: {
                fontSize: 12,
                formatter: function (params) {
                  let info = params.name + "：" + params.value;
                  let tip = "";
                  let le = info.length; //图例文本的长度
                  if (le > 4) {
                    tip += info.slice(0, 4) + "\n" + info.slice(5, le) + "个"; //字符串拼接
                    return tip;
                  } else {
                    tip = info;
                    return tip;
                  }
                },
              },
              silent: true,
            })
          );
          setTimeout(
            () =>
              this.$refs.troubleType &&
              this.$refs.troubleType.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: this.OutputTotalHighlightIndex,
              }),
            100
          );
          // this.hatUsedPie = chart;
          this.hatUsedPie = applyUpdate(chart, this.option3);
        });
    },
    // 图表1点击事件
    overviewDataClick(params) {
      this.popupShow1 = true;
      this.popupTitle1='数据情况总览（'+this.overviewData.xAxis[0].data[params.dataIndex]+'）';
      this.activeIndex1 = params.dataIndex;
      this.showInfoType1 = 2;
      this.getpopData1(2);
    },
    // 图表2点击事件
    laborWeekPlanChartClick(params) {
      this.popupShow2 = true;
      this.popupTitle2='劳动力周计划功能使用情况（'+this.laborWeekPlanChart.xAxis.data[params.dataIndex]+'）';

      this.activeIndex2 = params.dataIndex;
      this.showInfoType2 = 2;
      this.getPopData2(1);
    },
    // 图表3鼠标移动事件

    hatUsedPieMouseOver(params) {
      if (params.dataIndex != this.OutputTotalHighlightIndex) {
        this.$refs.troubleType.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.OutputTotalHighlightIndex,
        });
        this.OutputTotalHighlightIndex = params.dataIndex;
      }
    },
    hatUsedPieMouseOut() {
      this.$refs.troubleType.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.OutputTotalHighlightIndex,
      });
    },

    // 弹窗1
    closePop1() {
      this.popupShow1 = false;
      this.activeIndex1 = -1;
    },

    getpopData1() {
      if (
        (this.mapLevel == 2 && this.showInfoType1 == 2) ||
        (this.orgType == 2 && this.showInfoType1 == 2)
      ) {
        project
          .getHatProjCollect(
            1,
            this.activeIndex1 > -1 ? this.search_keys1[this.activeIndex1] : ""
          )
          .then((d) => {
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option5.series.forEach((item) => (item.data = []));
            this.overviewDataDetail = {};
            this.option5.legend.data = d.legend;
            this.option5.yAxis[0].data = d.xAxis_data;
            this.option5.series[0].data = d.bar_series[0];
            this.option5.series[0].name = d.legend[1];

            this.option5.series[1].data = d.bar_series[1];
            this.option5.series[1].name = d.legend[2];
            // this.option5.series[2].data = d.bar_series[2];
            // this.option5.series[2].name = d.legend_data[3];
            this.option5.series[3].data = d.line_series;
            this.option5.series[3].name = d.legend[0];
            this.overviewDataDetail = applyUpdate(chart, this.option5);
          });
      } else {
        nation
          .getHatOrgCityCollect(
            1,
            this.mapLevel == 0 && this.activeIndex1 > 1
              ? this.search_keys1[this.activeIndex1]
              : this.orgLevel,
            this.mapLevel == 1 && this.activeIndex1 > 1
              ? this.search_keys1[this.activeIndex1]
              : this.districtLevel
          )
          .then((d) => {
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option5.series.forEach((item) => (item.data = []));
            this.overviewDataDetail = {};
            this.option5.legend.data = d.legend_data;
            this.option5.yAxis[0].data = d.xAxis_data;
            this.option5.series[0].data = d.bar_series[0];
            this.option5.series[0].name = d.legend_data[1];

            this.option5.series[1].data = d.bar_series[1];
            this.option5.series[1].name = d.legend_data[2];
            this.option5.series[2].data = d.bar_series[2];
            this.option5.series[2].name = d.legend_data[3];
            this.option5.series[3].data = d.line_series;
            this.option5.series[3].name = d.legend_data[0];
            this.overviewDataDetail = applyUpdate(chart, this.option5);
          });
      }
    },

    // 弹窗2
    closePop2() {
      this.popupShow2 = false;
      this.activeIndex2 = -1;
    },
    getPopData2(mode) {
      this.mode4 = mode;
      if (
        (this.mapLevel == 2 && this.showInfoType1 == 2) ||
        (this.orgType == 2 && this.showInfoType1 == 2)
      ) {
        project
          .getHatCompanyPlanCollect(
            1,
            this.search_keys2[this.activeIndex2],
            this.mode4
          )
          .then((d) => {
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option6.series.forEach((item) => (item.data = []));
            this.laborWeekDetailChart = {};
            this.option6.legend.data = d.legend_data;
            this.option6.yAxis[0].data = d.xAxis_data;
            this.option6.series[0].data = d.bar_series[0];
            this.option6.series[0].name = d.legend_data[1];

            this.option6.series[1].data = d.bar_series[1];
            this.option6.series[1].name = d.legend_data[2];
            this.option6.series[2].data = d.line_series;
            this.option6.series[2].name = d.legend_data[0];
            this.laborWeekDetailChart = applyUpdate(chart, this.option6);
          });
      } else {
        nation
          .getHatOrgCityPlanCollect(
            1,
            this.mode4,
            this.mapLevel == 0 && this.activeIndex2 > -1
              ? this.search_keys2[this.activeIndex2]
              : this.orgLevel,
            this.mapLevel == 1 && this.activeIndex2 > -1
              ? this.search_keys2[this.activeIndex2]
              : this.districtLevel
          )
          .then((d) => {
            let chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
            this.option6.series.forEach((item) => (item.data = []));
            this.laborWeekDetailChart = {};
            this.option6.legend.data = d.legend_data;
            this.option6.yAxis[0].data = d.xAxis_data;
            this.option6.series[0].name = d.legend_data[1];
            this.option6.series[0].data = d.bar_series[0];
            this.option6.series[1].name = d.legend_data[2];
            this.option6.series[1].data = d.bar_series[1];
            this.option6.series[2].name = d.legend_data[0];
            this.option6.series[2].data = d.line_series;

            this.laborWeekDetailChart = applyUpdate(chart, this.option6);
          });
      }
    },
  },
};
</script>

<style scoped lang="less">
.mon-change {
  display: flex;
  margin: 1rem;
  justify-content: space-between;
  align-items: center;
}
.mon-item {
  padding: 0.5rem;
  border: 1px solid #52b3ff;
  border-radius: 4px;
}
.active-mon {
  background-color: #52b3ff;
}
.img-left {
  position: absolute;
  top: 5rem;
  left: 3rem;
  width: 3rem;
  z-index: 9;
  cursor: pointer;
}
.img-right {
  position: absolute;
  top: 5rem;
  right: 3rem;
  width: 3rem;
  z-index: 9;
  cursor: pointer;
}
.img {
  width: 2.5rem;
  cursor: pointer;
}
.attendance-bottom {
  margin: 0 2rem;
}
.tooltips {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  // margin-bottom: 1rem;
  font-size: 0.6rem;
  color: #abbbbf;
  font-weight: bold;
}
.items {
  display: flex;
  align-items: center;
}
.tooltip-item {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.3rem;
}
.tooltip-item1 {
  background-color: #23cf9e;
}
.tooltip-item2 {
  background-color: #eab91e;
}
.tooltip-item3 {
  background-color: #e02f37;
}
.linebar {
  width: 100%;
}
.line-bar {
  display: flex;
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #4ebb8c;
  border-radius: 12px;
}
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  z-index: 99 !important;
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>