import * as THREE from 'three';

export function addFloorNames(scene, names) {
  const floorNameGroup = new THREE.Group();
  scene.add(floorNameGroup);

  if (floorNameGroup.children) {
    let len = floorNameGroup.children.length
    while (len) {
      floorNameGroup.remove(floorNameGroup.children[len - 1])
      len = floorNameGroup.children.length
    }
  }
  if (!names.length) return
  const fontLoader = new THREE.FontLoader()
   fontLoader.load('threejs/helvetiker_regular.json', function (font) {
  //fontLoader.load('./MicrosoftYaHei_Regular.json', function (font) {
    const color = new THREE.Color(0xffffff)

    const material = new THREE.MeshBasicMaterial({
      color: color,
      transparent: true,
      // opacity: 0.4,
      side: THREE.DoubleSide
    })
    for (let i = 0; i < names.length; i++) {
      const name = names[i]
      const shapes = font.generateShapes(name[0], 3)
      const geometry = new THREE.ShapeBufferGeometry(shapes)
      geometry.computeBoundingBox()
      const xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x)
      geometry.translate(xMid, 18, 0)
      const text = new THREE.Mesh(geometry, material)
      text.position.x = name[1];
      text.position.y = name[2];
      text.position.z = name[3];
      text.scale.x = 5;
      text.scale.y = 5;
      text.scale.z = 5;
      text.rotation.x = Math.PI / 2;
      floorNameGroup.add(text)
    }
  })
}
