<template>
<div id="right">
    <div class="info-block" style="min-height:5rem">
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>方案执行情况</div>
        <div class="detail" @click="showDetail()">
          详情
        </div>
      </div>
      <div style="height:19rem;padding-top:3rem">
        <div
          style="display:flex;justify-content:space-around;margin-bottom:0.6rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')"
          >
            交底情况
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')"
          >
            检查情况
          </div>
        </div>
        <div
          v-if="companyWorkersPieChart.series && [].concat.apply([], companyWorkersPieChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyWorkersPieChart" @click="companyWorkersPieChartClick" autoresize/>
      </div>
    </div>
     <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <!-- <div class="tabbar" style="width:22rem">
              <div
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >正常（{{normal}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 5 }"
                @click="getAttendance(5)"
              >缓建（{{wait}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 4 }"
                @click="getAttendance(4)"
              >停工（{{stop}}）</div>
            </div> -->
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:8rem;text-align:center">序号</div>
                <div style="width:15rem;text-align:center">单位名称</div>
                <div style="width:8rem;text-align:center">在执行方案数</div>
                <div style="width:8rem;text-align:center">其中交底数量</div>
                <div style="width:8rem;text-align:center">未交底数量</div>
                <div style="width:8rem;text-align:center">交底率</div>
                <div style="width:8rem;text-align:center">其中检查数量</div>
                <div style="width:12rem;text-align:center">未检查数量</div>
                <div style="width:12rem;text-align:center">检查率</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popProjectsList"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:8rem;text-align:center">{{index+1}}</div>
                  <div style="width:15rem;text-align:center">{{item.orgName}}</div>
                  <div style="width:8rem;text-align:center">{{item.zxfa}}</div>
                  <div style="width:8rem;text-align:center">{{item.jdwc}}</div>
                  <div style="width:8rem;text-align:center">{{item.wjdwc}}</div>
                  <div style="width:8rem;text-align:center">{{item.jdRate ==null?0:toFixed(item.jdRate*100,2)}}%</div>
                  <div style="width:8rem;text-align:center">{{item.jcwcNum}}</div>
                  <div style="width:12rem;text-align:center">{{item.wjcwcNum}}</div>
                  <div style="width:12rem;text-align:center">{{item.jcRate ==null?0:toFixed(item.jcRate*100,2)}}%</div>
                </div>
                <div
                  v-if="!(popProjectsList && popProjectsList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>

        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { tech } from '../../../../api_tech'
//  import CustomSelect from '../../../../components/select'
  // import { truncateStr } from "../../../../utils";
// import func from 'vue-editor-bridge'
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'PermitStateChart',
    components: {
      'v-chart': ECharts,
      wrapper
    //   'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      }
    },
    data () {
      return {
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear(),
        companyWorkersData: {},
        companyWorkersOption: "1",
        companyWorkersPieChart: {},
        popProjectsList:[],
        popupShow:false
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.getAll();
    },
    methods: {
      getAll(){
          this.switchCompanyWorkersOption(this.companyWorkersOption);
      },
      switchCompanyWorkersOption(choose){
          this.companyWorkersOption = choose;
          switch (this.companyWorkersOption) {
              case "1":
                  this.updateSchemeCheck();
                  break;
              case "2":
                  this.updateschemeClarificaiton();
                  break;
              default:
                  break;
          }
      },
      categorySelect(option) {
        this.selectedYear = option;
        this.updateschemeClarificaiton();
      },
      updateSchemeCheck(){
        tech.getSchemeClarificaiton().then((res=>{
          const orgList = [];
          const jdwcs = [];
          const jdOdds = [];
          const excuteNums = [];
          const len = res.length>12?12:res.length;
          for (let i = 0; i < len; i++) {
            orgList.push(res[i].orgName);
            jdwcs.push(res[i].jdwc);
            const jdOds = tech.formatDecimal(res[i].jdwc/res[i].zxfa*100,2);
            jdOdds.push(jdOds);
            excuteNums.push(res[i].zxfa);
          }
          const option = {
              color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  },
                  formatter:function(param){
                    let str = param[0].name+"<br/>";
                    for (let index = 0; index < param.length; index++) {
                      if(index == 2)
                        str += param[index].seriesName+":"+param[index].value+"%<br/>";
                      else
                        str += param[index].seriesName+":"+param[index].value+"<br/>";
                    }
                    return str;
                  }
              },
              grid: {
                containLabel: true,
                left: '5%',
                right: '6%',
                bottom: "5%",
                top: "12%"
              },
              xAxis: [
                {
                  data: orgList,
                  axisLabel: {
                    color: 'white',
                    fontWeight: 'bold',
                    rotate: 40,
                    // formatter: value => truncateStr(value, 10),
                  },
                  show: true,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'category',
                }
              ],
              yAxis: [
                {
                  name:'数量',
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  boundaryGap: [0, '20%'],
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'value'
                },
                {
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  min: 0,
                  max: 100,
                  interval: 10,
                  boundaryGap: [0, '10%'],
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                    formatter: function(value) {
                      return value ? value + '%' : value;
                    },
                  },
                  saxisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'value',
                  show: true
                }
              ],
              series: [
                {
                  name: '在执行且交底数量',
                  type: 'bar',
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: jdwcs
                },
                {
                  name: '在执行方案数量',
                  type: 'bar',
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: excuteNums,
                },
                {
                  name: '交底率',
                  type: 'line',
                  yAxisIndex: 1,
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: jdOdds,
                }
              ]
          }
          this.companyWorkersPieChart = option;
        }))
      },
      updateschemeClarificaiton() {
        tech.getSchemeClarificaiton().then((res =>{
          const orgList = [];
          const zxfa_nums = [];
          const jcRate = [];
          const len = res.length>12?12:res.length;
          for (let i = 0; i < len; i++) {
            orgList.push(res[i].orgName);
            zxfa_nums.push(res[i].zxfa);
            const jcRat = tech.formatDecimal(res[i].jcRate==null? 0:res[i].jcRate*100,2);
            jcRate.push(jcRat);
          }
          const option = {
              color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  },
                  formatter:function(param){
                    let str = param[0].name+"<br/>";
                    for (let index = 0; index < param.length; index++) {
                       if(index == 1)
                        str += param[index].seriesName+":"+param[index].value+"%<br/>";
                      else
                        str += param[index].seriesName+":"+param[index].value+"<br/>";
                    }
                    return str;
                  }
              },
              grid: {
                containLabel: true,
                left: '5%',
                right: '6%',
                bottom: "5%",
                top: "12%"
              },
              xAxis: [
                {
                  data: orgList,
                  axisLabel: {
                    color: 'white',
                    fontWeight: 'bold',
                    rotate: 40,
                    // formatter: value => truncateStr(value, 10),
                  },
                  show: true,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'category',
                }
              ],
              yAxis: [
                {
                  name:'数量',
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  boundaryGap: [0, '20%'],
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'value'
                },
                {
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  min: 0,
                  max: 100,
                  interval: 10,
                  boundaryGap: [0, '10%'],
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                    formatter: function(value) {
                      return value ? value + '%' : value;
                    },
                  },
                  saxisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'value',
                  show: true
                }
              ],
              series: [
                {
                  name: '在执行数量',
                  type: 'bar',
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: zxfa_nums
                },
                {
                  name: '检查率',
                  type: 'line',
                  yAxisIndex: 1,
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: jcRate,
                }
              ]
          }
          this.companyWorkersPieChart = option;
        }))
      },
      showDetail(){
        tech.getSchemeClarificaiton().then(res=>{
          this.popupShow = true;
          this.popProjectsList = res;
        })
      },
      toFixed(value,ptn){
        return tech.formatDecimal(value,ptn);
      },
      companyWorkersPieChartClick(){
        this.showDetail();
      }
    }
  }
</script>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}

.option-block2 {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3.4rem;
  border: 1px solid #1b8ebd;
  text-align: center;
  cursor: pointer;
}
.option-block-selected{
  background-color: transparent !important;
  border: 1px solid #274C7D !important;
}
.option-block-selected2 {
  background-color: #4191e5;
  color: #fff;
}
.attendance{

}
.attendance-top{
  padding-top: 10px;
}
.tabbar{
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  border: 1px solid #157EAB;
  width: 210px;
  margin: auto;
  cursor: pointer;
}
.tabbar div{
  flex: 1;
  text-align: center;
}
.tabar-select{
  background-color: #4191E5;
  font-weight: bold;
}
.items div{
  color: #fff !important;
}
.selected{
  color: #fff !important;
}
.attendance-bottom{

}
.linebar{
  margin-top: 5rem;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto 10px;
}
.real-bar{
  height: 5px;
  background-color: #57C7B6;
  border-radius: 12px;
}
</style>

