<template>
  <div id="project" :key="$route.params.id" class="global-bg" style="overflow:hidden">
    <div id="main" v-show="sceneReady">
      <div id="middle">
        <div id="map">
          <wrapper style="height:100vh">
            <div class="build_list" v-if="!tracePlaying3D && map_list.length<3">
              <div class="building-info" v-for="info in buildingInfo" :key="info.build_id">
                <h3>
                  <a v-if="!use3D && info.type==1" class="building-link">
                    {{info.build_name}}
                  </a>
                  <a class="building-link" v-if="use3D && info.type==1" :style="{textDecoration: info.build_name == '地下室' ? 'none' : 'underline'}" @click="sceneBldgSwitch(info.build_name)">{{info.build_name}}</a>
                  <a class="building-link" v-if="info.type==2">{{info.build_name}}</a>
                </h3>
                <p v-if="info.type==1">
                  <span class="title">总楼层：</span>
                  <span class="light-blue-text value">{{info.map_count}}层</span>
                </p>
                <p>
                  <span class="title">当前人数：</span>
                  <span class="light-blue-text value">{{info.worker_count}}</span>
                </p>
                <!-- <div>
                  <p>人员分布
                    <span class="light-blue-text">TOP3</span>：</p>
                  <p class="light-blue-text">{{info.map_worker_list}}</p>
                </div> -->
              </div>
            </div>
            <div class="project_map" id="mapid" :style="{backgroundImage: `url(${projectImage})`}" />
            <TraceHistory v-show="tracePlaying3D" :workerId="pathWorkerId3D" :workerName="pathWorkerName3D" :stop="stopTracePlaying3D" />
            <div class="map_list">
              <div style="width:160px;">
                <CustomSelect v-if="!!map_list.length" :options="map_list.map(item=>item.name)" @input="mapSelect" />
              </div>
              <el-checkbox v-if="use3D && config3D.groundHeightRanges" v-model="hideGround3D" @change="sceneShowHideGround" style="margin-left:1rem"><span style="font-size:0.95rem">隐藏地面</span></el-checkbox>
            </div>
            <div v-if="use3D" class="map_compass" :style="{transform: `rotate(${compassRotation}deg)`}" style="cursor:pointer" @click="resetScene">
              <img src="../../assets/compass.png" alt="" />
            </div>
            <!-- <div v-else class="building-info-other"  v-for="info in buildingInfo" :key="info.build_id"
                   :style="{left: info.location.x / 2 + 'px', top: info.location.y / 2 + 'px'}">
                   <router-link class="building-link"
                               :to="`/building/${info.build_id}/${$route.params.id}?token=${$route.query.token}&project=${projectInfo.name}`">
                    {{info.build_name}}
                  </router-link>
                  </div>
                </div> -->
            <div id="logo">
              <span>星璇项目管理系统</span>
              <img src="../../assets/logo.png" alt="" height="26">
            </div>
            <div class="mantle" v-if="show_alarm.is_show"></div>
            <div id="show_alarm" class="show_alarm" v-if="show_alarm.is_show">
              <div class="show_alarm_title">{{show_alarm.title}}</div>
              <div><img src="../../assets/sos.png" class="show_alarm_img" v-if="show_alarm.img_show_alarm"></div>
              <div><img src="../../assets/down.png" class="show_alarm_img" v-if="!show_alarm.img_show_alarm"></div>
              <div class="show_alarm_content">
                <div>{{show_alarm.name}}</div>
                <div>{{show_alarm.location}}</div>
                <div>{{show_alarm.mobile}}</div>
              </div>
              <div class="show_alarm_sure" @click="closealarm">确定</div>
            </div>
            <el-dialog custom-class="stats-layer" :visible.sync="pathShow" :close-on-click-modal="true" width="90rem" top="5rem">
              <wrapper style="backgroundColor:#0e1328;position:relative;padding:0">
                <div class="stats-close-btn" @click="pathShow = false" />
                <div style="position:absolute;top:1rem;left:1rem;font-size:1.5em;">人员轨迹 - {{pathWorkerName}}</div>
                <WorkerPath :workerId="pathWorkerId" :date="pathDate" :mapUrl="pathShow ? (img_host + (travel_url || map_list[0].image_url)) : ''" :mapOptions="extraMapOptions" />
              </wrapper>
            </el-dialog>
          </wrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
require('echarts-gl')
import wrapper from '../../components/wrap'
import WorkerPath from './path';
import TraceHistory from './trace_history';
import CustomSelect from '../../components/select'
import { project, attendance, building, wsUrl } from '../../api'
import {
  init,
  changeMap,
  createWebSocket,
  setMapMarker,
//  loadBuildingFloors,
} from '../../../public/leaflet.js'
import getConfig from '../../../public/projects/index';
import { createWebSocket as createWs } from '../../../public/websocket'
//import {mapRatio} from '../../conf';
import '../../../public/blinkmarker.css';

const color = ['#FC619D', '#FF904D', '#48BFE3', '#1990ff', '#28abc2']
const rich = {}
color.forEach((c, i) => {
  rich['a' + i] = {
    color: '#fff',
    backgroundColor: c,
    borderRadius: 100,
    padding: [6, 8]
  }
})

const workerOnThreeColorMap = {
  '': [0, 255 / 255, 0],
  '黄色': [233 / 255, 191 / 255, 5 / 255],
  '红色': [220 / 255, 40 / 255, 43 / 255],
  '白色': [255 / 255, 255 / 255, 255 / 255],
  '蓝色': [55 / 255, 162 / 255, 218 / 255],
}

function addOverflowTooltip(selector) {
  setTimeout(() => {
    document.querySelectorAll(selector).forEach(function(item) {
      if (!item.getAttribute('listener')) {
        item.addEventListener('mouseenter', function() {
          let newAttribute;
          if (item.offsetWidth < item.scrollWidth) {
            if (item.getAttribute('title') !== item.innerHTML) {
              newAttribute = item.innerHTML;
            } else {
              return;
            }
          } else {
            newAttribute = '';
          }
          item.setAttribute('title', newAttribute);
        });
        item.setAttribute('listener', true);
      }
    });
  }, 30);
}

export default {
  name: 'project',
  components: {
    wrapper,
    WorkerPath,
    TraceHistory,
    'CustomSelect': CustomSelect,
  },
  data() {
    return {
      currPageName: 'project',
      onlineWorkerClickCounter: 0,
      onsiteWorkerClickCounter: 0,
      onlineWorkerErrorCounter: 0,
      use3D: false,
      config3D: {},
      hideGround3D: false,
      sceneReady: true,
      sceneLoaded: false,
      heatmapReady: false,
      sceneToolbox: null,
      compassRotation: 0,
      tracePlaying3D: false,
      stopTracePlaying3D: null,
      searchParams: '',
      show_alarm: {
        is_show:false,
        title:"紧急呼救",
        name:"吴德龙（浇铸工组）",
        location:"3号楼32层",
        mobile:"18625893654",
        img_show_alarm:true,
      },
      s_build_id: '',
      s_index: 0,
      statusColorMap: {
        正常: { value: '#1fc86b', cls: 'green' },
        呼救: { value: '#f34e53', cls: 'red2' },
        坠落: { value: '#e62c31', cls: 'red1' },
        预警: { value: '#eebc34', cls: 'yellow2' },
        禁区预警: { value: '#eebc34', cls: 'yellow2' },
        危险预警: { value: '#eebc34', cls: 'yellow2' },
        闯入禁区: { value: '#eebc34', cls: 'yellow2' },
        静止: { value: '#eebc34', cls: 'yellow2' },
        倒地: { value: '#F89132', cls: 'yellow1' },
        离线: { value: '#9ca0aa', cls: 'grey' },
        没电: { value: '#eebc34', cls: 'yellow2' }
      },
      extraMapOptions: {},
      warningList: [],
      warningStats: {},
      liveData: {
        worker_count: 0,
        check_worker_count: 0,
        hat_count: 0,
        point_count: 0
      },
      systemInfo: {
        time: '',
        date: '',
        temperature: '',
        weather: '1',
        timer: null
      },
      building: {
        buildingList: [],
      },
      buildingInfo: [
        {
          build_id: '',
          build_name: '',
          location: {},
          floor_height: '',
          map_count: 0,
          worker_count: 0,
          map_worker_list: ''
        }
      ],
      projectImage: '',
      timer: null,
      timer2: null,
      isUp: false,
      isSelect: false,
      map_list: [],
      img_host: '',
      travel_url: '',
      pathShow: false,
      pathWorkerId: '',
      pathWorkerName: '',
      pathWorkerId3D: '',
      pathWorkerName3D: '',
      pathDate: undefined,
      classEfficientHighlightIndex: 0,
      wearMap: [{ cls: 'red2', text: '未佩戴' }, { cls: 'green', text: '佩戴' }],
      retrieveStatusMap: {
        11: { text: '召回', cls: '' },
        7: { text: '已收到', cls: 'disabled' },
        8: { text: '已确认', cls: 'disabled' },
        10: { text: '发送中', cls: 'disabled' }
      },
      motionMap: {
        运动: 'green',
        静止: 'green',
        倒地: 'yellow1',
        坠落: 'red1',
        没电:'yellow1',
      },
      groupList: [{ worker_list: [], show: true }],
      workerMap: {},
      lastUpdatedSceneWorkers: 0,
      projectDetail: {
        hatCount: 0,
        checkinCount: 0,
        checkinOnSiteCount: 0,
      },
      wsObj: {},
      callBackPerson: '',
      callBackFunc: null,
      projectInfos: {},
    }
  },
  watch: {
    $route: function(newValue, oldValue) {
      if (newValue.params.id != oldValue.params.id) {
        this.initProject();
      }
    },
  },
  mounted() {
    this.initProject();
  },
  methods: {
    initProject() {
      const { id: projectID } = this.$route.params;

      const self = this;
      const token = this.$route.query.token;
      window.withTrace = parseInt(this.$route.query.trace || 0);
      window.token = token;
      window.beaconMode = this.$route.query.beacon;
      window.use3D = parseInt(this.$route.query.use3d || 0);
      window.selectedBldg = this.$route.query.bldg;
      window.selectedFloor = this.$route.query.floor;
      window.projectAPI = project;
      window.buildingAPI = building;

      this.updateProjectDetail(projectID);

      createWs(wsUrl, projectID, message => {
        try {
          self.dataParser(message)
        } catch (e) {
          console.warn(e.message)
          self.$message.error('服务器返回数据错误')
        }
      }, this.wsObj);

      project.getAllWorkers(projectID)
        .then(d => {
          const workerMap = {};
          for (const w of d) {
            workerMap[w.innerid] = w;
          }
          this.workerMap = workerMap;
          setTimeout(() => {
            this.updateSceneWorkers();
          }, 3000);
        })
        .catch(e => console.error(e.msg || e.message));


      building.getWorkList(projectID)
        .then(list => {
          self.groupList = list
          self.groupList.forEach((group, idx) => {
            if (group.worker_list.length) {
              group.show = idx === 0;
              group.worker_list.forEach(worker => {
                worker.orderID = 100
                self.dataParser({ data: [worker], errcode: 0, source: 'customer_update' }, true)
              })
            }
          })
        })
        .catch(e => console.error(e.msg || e.message));

      if (this.systemInfo.timer) {
        clearInterval(this.systemInfo.timer);
      }

      this.systemInfo.timer = setInterval(() => {
        const d = new Date()
        this.systemInfo.time =
          d
            .getHours()
            .toString()
            .padStart(2, '0') +
          ':' +
          d
            .getMinutes()
            .toString()
            .padStart(2, '0') +
          ':' +
          d
            .getSeconds()
            .toString()
            .padStart(2, '0')
        if (((d * 1) % 864000) * 1000 < 1000 || !this.systemInfo.date) {
          this.systemInfo.date =
            d.getFullYear() +
            '-' +
            (d.getMonth() + 1).toString().padStart(2, '0') +
            '-' +
            d
              .getDate()
              .toString()
              .padStart(2, '0')
        }
      }, 1000)
      project
        .getWarningList(projectID)
        .then(d => {
          this.warningList = d;
          addOverflowTooltip('#people-warning-list .value');
        })
        .catch(e => console.error(e.msg || e.message))
      project.getWarningStats(projectID)
        .then(d => {
          for (const item of d) {
            this.warningStats[item.alarm_type] = item.count;
          }
        })
        .catch(e => console.error(e.msg || e.message))
      project
        .getLiveData(projectID)
        .then(d => (this.liveData = d))
        .catch(e => console.error(e.msg || e.message))

      this.renderMap();

      if (this.timer) {
        clearInterval(this.timer);
      }
      if (this.timer2) {
        clearInterval(this.timer2);
      }

      this.timer = setInterval(() => {
        self.updateProjectDetail(projectID);
        project
          .getWarningList(projectID)
          .then(d => {
            this.warningList = d;
            addOverflowTooltip('#people-warning-list .value');
          })
          .catch(e => console.error(e.msg || e.message))
        project.getWarningStats(projectID)
          .then(d => {
            for (const item of d) {
              this.warningStats[item.alarm_type] = item.count;
            }
          })
          .catch(e => console.error(e.msg || e.message))
        project
          .getLiveData(projectID)
          .then(d => (this.liveData = d))
          .catch(e => console.error(e.msg || e.message))
        project
          .getBuildingBaseInfo(projectID)
          .then(d => {
            this.buildingInfo = d.build_list
            this.map_list = d.show_map
            this.travel_url = d.travel_url;
            this.use3D && this.sceneToolbox && this.sceneToolbox.updateBuildingWorkerCount && this.sceneToolbox.updateBuildingWorkerCount();

            // L.imageOverlay(d.project_map, bounds).addTo(map)
            // map.fitBounds(bounds)
            // this.projectImage = d.project_map
          })
          .catch(e => console.error(e.msg || e.message))
      }, 1000*15);
      this.timer2 = setInterval(()=>{
        project.getAllWorkers(projectID)
          .then(d => {
            const workerMap = {};
            for (const w of d) {
              workerMap[w.innerid] = w;
            }
            this.workerMap = workerMap;
            this.updateSceneWorkers();
          })
          .catch(e => console.error(e.msg || e.message));

        building.getWorkList(projectID)
        .then(list => {
          self.groupList = list
          self.groupList.forEach((group, idx) => {
            if (group.worker_list.length) {
              group.show = idx === 0;
              group.worker_list.forEach(worker => {
                worker.orderID = 100
                self.dataParser({ data: [worker], errcode: 0, source: 'customer_update' }, true)
              })
            }
          })
        })
        .catch(e => console.error(e.msg || e.toString()))
      }, 1000*60);
    },
    renderMap() {
      const { id: projectID } = this.$route.params;
      //const self = this;
      project
        .getBuildingBaseInfo(projectID)
        .then(d => {
          this.buildingInfo = d.build_list
          this.map_list = d.show_map
          this.img_host = d.img_host
          this.travel_url = d.travel_url;
          this.s_index = 0;
          this.s_build_id = this.map_list[0].build_id;

          this.extraMapOptions = {
            hideWorkerPopupButtons: true,
            mapWidth: this.map_list[0].width,
            mapHeight: this.map_list[0].height,
          };

          //创建ws socket连接
          createWebSocket('mapid', wsUrl, projectID)
          //加载地图
          let use3D = window.use3D;
          let configIdx;
          if (use3D) {
            const config3D = getConfig(projectID);
            if (Array.isArray(config3D)) {
              for (let i = 0; i < config3D.length; i++) {
                const item = config3D[i];
                use3D = !!item && (!item.buildId || item.buildId == this.s_build_id);
                if (use3D) {
                  this.config3D = item;
                  configIdx = i;
                  break;
                }
              }
            } else {
              use3D = !!config3D && (!config3D.buildId || config3D.buildId == this.s_build_id);
              if (use3D) {
                this.config3D = config3D;
              }
            }
          }

          this.use3D = use3D;
          if (use3D) {
            const self = this;
            init(
              'mapid',
              this.img_host + this.map_list[0].image_url,
              this.s_build_id,
              this,
              function() {
                self.sceneLoaded = true;
              }, {
                enableImage: false,
                enableMarkers: false,
                enable3D: true,
                configIdx: configIdx,
                hideWorkerPopupButtons: true,
                hideBldgDetails: true,
                sceneData: {
                  ta: this,
                  projectId: projectID,
                  getBuildingList: () => { return this.buildingInfo },
                  is3DSceneVisible: () => {
                    return this.use3D && this.sceneReady && this.sceneLoaded;
                  },
                }
              }
            );
          } else {
            this.config3D = {};
            init(
              'mapid',
              this.img_host + this.map_list[0].image_url,
              this.s_build_id,
              this,
              function(e) {
                //加载当前楼层的人员名单
                project
                  .getWorkerList(e)
                  .then(list => {
                    setMapMarker('mapid', list, "oninit");
                  })
                  .catch(e => console.error(e.msg || e.toString()));

                //self.loadBuildings();
              },
              this.extraMapOptions,
            )
          }
        })
        .catch(e => console.error(e.msg || e.message));
    },
    /*
    loadBuildings() {
      const self = this;
      building.getBuildings()
        .then(d => {
          d.slice(0, 10).forEach(l => {
            const res = [];
            let floorStart = '';
            let floorEnd = '';
            let ids = [];
            l.floor.forEach((a, i) => {
              if (i % 50 === 0) {
                ids = [a.innerid];
                floorStart = a.name;
              } else {
                ids.push(a.innerid);
              }
              if (i % 50 === 49 || i === l.floor.length - 1) {
                floorEnd = a.name;
                res.push({ name: `${floorStart}~${floorEnd}`, innerid: i, ids });
              }
            });
            l.floor = res;
            self.changeFloor(l.innerid, l.name, res[0]);
          });
          self.building.buildingList = d;
        })
        .catch(e => console.error(e.msg || e.toString()))
    },
    changeFloor(buildingId, buildingName, floor) {
      //if (this.curFloorList === floor.ids) return
      //this.curFloorList = floor.ids
      building.getBuildingFloorData(floor.ids)
        .then(data => {
          const floorNameArr = [];
          const floorDataArr = [];
          const floorIDArr = [];
          data.forEach(d => {
            floorDataArr.push(d.map_data);
            floorNameArr.push(d.name);
            floorIDArr.push(d.innerid);
          });
          const workerPosition = Object.values(this.workerMap).filter(w => floor.ids.filter(id => id === w.floor).length).map(w => ({
            id: w.innerid,
            name: w.name,
            floor: w.floor_name,
            floorID: w.floor,
            position: { x: w.x / mapRatio, y: w.y / mapRatio },
            color: workerOnThreeColorMap[w.color] || workerOnThreeColorMap[''],
            group: w.worker_type,
            status_id: w.status_id,
            hat_code: w.hat_code || '',
            height: w.height,
            mobile: w.mobile,
          }));

          loadBuildingFloors('mapid', workerPosition, { buildingId, buildingName, floorNameArr, floorDataArr, floorIDArr });
        });
    },
    */
    dataParser (data, first) {
      if (data && data.errcode === 0) {
        switch (data.source) {
          case 'customer_update':
            if (this.groupList.length) {
              const user = data.data[0]
              this.groupList.forEach((group, oi) => {
                group.show = group.show === undefined ? (oi === 0) : group.show
                if (group.worker_list.length) {
                  group.worker_list.forEach((worker, ii) => {
                    if (worker.innerid === user.innerid) {
                      if (this.wearMap[user.wear] === undefined || this.statusColorMap[user.alarm]=== undefined || this.retrieveStatusMap[user.recalled] === undefined) {
                        throw new Error(JSON.stringify(user))
                      }
                      let orderID
                      switch (user.status_id) {
                        case 1:
                          orderID = 0
                          break
                        case 2:
                          orderID = 1
                          break
                        case 3:
                          orderID = 1
                          break
                        case 5:
                          orderID = 2
                          break
                        case 12:
                          orderID = 3
                          break
                        case 9:
                          orderID = 4
                          break
                        default:
                          orderID = 100
                      }
                      this.$set(this.groupList[oi].worker_list, ii, Object.assign({ orderID }, user))
                    }
                  })
                  group.worker_list.sort((a, b) => a.orderID - b.orderID)
                }
              });

              if (!first) {
                if (user.x && user.y) {
                  if (user['3d_x'] != '' && user['3d_y'] != '') {
                    this.workerMap[user.innerid] = user;
                  }
                } else {
                  delete this.workerMap[user.innerid];
                }

                if (this.use3D && new Date() / 1000 - this.lastUpdatedSceneWorkers >= 3) {
                  this.updateSceneWorkers();
                  this.lastUpdatedSceneWorkers = new Date() / 1000;
                }
              }
            }
            break
          default:
            console.warn(data.source)
        }
      } else {
        console.error('error code', data.errcode)
      }
    },
    getWorkerPosition () {
      let workers = Object.values(this.workerMap);

      if (this.config3D.buildId) {
        workers = workers.filter(w => w.build == this.s_build_id);
      }

      workers = workers.filter(w => w['3d_x'] != '' && w['3d_y'] != '');

      return workers.map(w => ({
        id: w.innerid,
        name: w.name,
        area: w.area,
        build_name: w.build_name,
        floor: w.floor_name,
        floorID: w.floor,
        position: {
          x: w['3d_x'], y: w['3d_y']
        },
        color: workerOnThreeColorMap[w.color] || workerOnThreeColorMap[''],
        group: w.worker_type,
        status_id: w.status_id,
        hat_code: w.hat_code || '',
        height: w.height,
        mobile: w.mobile,
        ptype: w.ptype,
      }));
    },
    updateSceneWorkers () {
      this.use3D && this.sceneToolbox && this.sceneToolbox.updateWorkers(this.getWorkerPosition());
    },
    batteryMap (number) {
      if (number * 1 <= 20) {
        return 'red2'
      } else if (number * 1 > 20 && number * 1 < 60) {
        return 'yellow2'
      } else {
        return 'green'
      }
    },
    changeTrouble() {
      this.isSelect = !this.isSelect
    },
    getTrack() {
      // console.log('点击了轨迹');
    },
    changeMap(map_image, type, build_id, m_index) {
      if (map_image && type && build_id) {
        this.s_index = m_index
        this.s_build_id = build_id

        this.extraMapOptions = {
          hideWorkerPopupButtons: true,
          mapWidth: this.map_list[m_index].width,
          mapHeight: this.map_list[m_index].height,
        };

        const { id: projectID } = this.$route.params;
        //加载地图
        let use3D = window.use3D;
        let configIdx;
        if (use3D) {
          const config3D = getConfig(projectID);
          if (Array.isArray(config3D)) {
            for (let i = 0; i < config3D.length; i++) {
              const item = config3D[i];
              use3D = !!item && (!item.buildId || item.buildId == this.s_build_id);
              if (use3D) {
                this.config3D = item;
                configIdx = i;
                break;
              }
            }
          } else {
            use3D = !!config3D && (!config3D.buildId || config3D.buildId == this.s_build_id);
            if (use3D) {
              this.config3D = config3D;
            }
          }
        }

        if (use3D) {
          const self = this;
          init(
            'mapid',
            this.img_host + map_image,
            this.s_build_id,
            this,
            function() {
              self.sceneLoaded = true;
              project.getAllWorkers(projectID)
                .then(d => {
                  const workerMap = {};
                  for (const w of d) {
                    workerMap[w.innerid] = w;
                  }
                  self.workerMap = workerMap;
                  self.updateSceneWorkers();
                })
                .catch(e => console.error(e.msg || e.message));
            }, {
              enableImage: false,
              enableMarkers: false,
              enable3D: true,
              configIdx: configIdx,
              hideWorkerPopupButtons: true,
              hideBldgDetails: true,
              sceneData: {
                ta: this,
                projectId: projectID,
                getBuildingList: () => { return this.buildingInfo },
                is3DSceneVisible: () => {
                  return this.use3D && this.sceneReady && this.sceneLoaded;
                },
              }
            }
          );
        } else if (this.use3D) {
          this.config3D = {};
          this.sceneLoaded = false;
          init(
            'mapid',
            this.img_host + map_image,
            this.s_build_id,
            this,
            function(e) {
              //加载当前楼层的人员名单
              project
                .getWorkerList(e)
                .then(list => {
                  setMapMarker('mapid', list, "oninit");
                })
                .catch(e => console.error(e.msg || e.toString()));

              //self.loadBuildings();
            },
            this.extraMapOptions,
          )
        } else {
          this.config3D = {};
          changeMap('mapid', this.img_host + map_image, type, build_id)

          project
            .getWorkerList(build_id)
            .then(list => {
              setMapMarker('mapid', list)
            })
            .catch(e => console.error(e.msg || e.toString()))
        }

        this.use3D = use3D;
      }
    },
    showTrace(workerId, workerName, date) {
      this.pathWorkerId = workerId;
      this.pathWorkerName = workerName;
      this.pathDate = date;
      this.pathShow = true;
    },
    mapSelect(name) {
      const idx = this.map_list.findIndex(item => item.name == name);
      if (idx >= 0 && idx !== this.s_index) {
        const m = this.map_list[idx];
        this.changeMap(m.image_url, m.type, m.build_id, idx);
      }
    },
    closealarm() {
      this.show_alarm.is_show = false
    },
    updateProjectDetail(projectID) {
      attendance.getProjectDetail(projectID).then(d => {
        this.projectDetail.hatCount = d.hat_count;
        this.projectDetail.checkinCount = d.checkin_count;
        this.projectDetail.checkinOnSiteCount = d.checkin_on_site_count;
      });
    },
    toggleGroup (group) {
      group.show = !group.show
      this.$forceUpdate()
    },
    sceneBldgSwitch(build_name) {
      if (this.use3D && this.sceneToolbox) {
        this.sceneToolbox.revealBuilding(build_name);
      }
    },
    sceneShowHideGround(revealIdx) {
      if (this.use3D && this.sceneToolbox) {
        this.sceneToolbox.revealBuilding('ground', revealIdx ? 2 : 0);
      }
    },
    resetScene() {
      if (this.use3D && this.sceneToolbox) {
        this.sceneToolbox.resetScene();
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.timer2) {
      clearInterval(this.timer2);
    }
    if (this.systemInfo.timer) {
      clearInterval(this.systemInfo.timer);
    }
  }
}
</script>

<style scoped lang="less">
.show_alarm_sure {
  margin-top: 20px;
  font-size: 15px;
  background-color: #3182ff;
  border-radius: 16px;
  width: 80px;
  height: 32px;
  line-height: 32px;
  margin-left: 120px;
}
.show_alarm_content {
  font-family: NotoSansHans-Medium;
  font-size: 14px;
  color: #ffffff;
  line-height: 23px;
}
.show_alarm_img {
  width: 185px;
  height: 135px;
  margin-top: 23px;
}
.mantle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 999;
}
.show_alarm_title {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  font-family: NotoSansHans-Bold;
  margin-top: 23px;
}
.show_alarm {
  text-align: center;
  top: 50%;
  position: absolute;
  border: 1px solid #151c48;
  left: 50%;
  height: 340px;
  margin-top: -160px;
  width: 320px;
  margin-left: -170px;
  z-index: 1000;
  background-color: #151c48;
  border-radius: 4px;
  opacity: 0.8;
  box-shadow: 0 0 0.2em #ffffff;
}
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1rem;
  font-weight: bold;
  color: #cececf;
}
.build_list {
  z-index: 1;
  position: absolute;
  top: 0px;
  right: 5px;
  max-height: 93%;
  overflow-y: scroll;
  padding-right: 5px;
}
.map_compass {
  z-index: 999;
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: 54px;
  height: 54px;
}
#xunjian {
  width: 88px;
  height: 88px;
  background: url("../../assets/nation/巡检率.png");
  background-size: cover;
  position: absolute;
  z-index: 999;
  bottom: 13px;
  left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#chuqin {
  width: 88px;
  height: 88px;
  background: url("../../assets/nation/出勤率.png");
  background-size: cover;
  position: absolute;
  z-index: 999;
  bottom: 13px;
  left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.map_list {
  z-index: 999;
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
}

.stats_btn {
  color: #fff;
  overflow: hidden;
  border-top: 1px solid #334c88;
  border-right: 1px solid #334c88;
  border-bottom: 1px solid #334c88;
  background-color: #2a4278;
  padding: 0 15px;
  line-height: 1.8rem;
  display: none;
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
  z-index: 30000;
}

.zk-btn {
  background-image: url("../../assets/zk.png");
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 30000;
}

.zk-btn.zk-btn-sq {
  background-image: url("../../assets/sq.png");
}

#project {
  background-size: cover;
}

.building-link,
.building-link:active,
.building-link:link,
.building-link:hover,
.building-link:visited {
  color: #51bbf5;
}

.building-info {
  margin-top: 5px;
  border: 1px solid #5f7ec9;
  padding: 8px;
  z-index: 1;
  border-radius: 8px;
  // display: inline-block;
  // position: absolute;
  // background-image: url("../../assets/bg1.png");
  background: #1e2c61;

  h3 {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }

  p {
    display: flex;
    font-size: 14px;
    line-height: 22px;

    .title {
      width: 88px;
    }

    .value {
      width: 40px;
      text-align: right;
    }
  }
}

.building-info-other {
  border: 1px solid #5f7ec9;
  padding: 0.23rem;
  z-index: 1;
  display: inline-block;
  position: absolute;
  background: #1e2c61;
  border-radius: 50%;
  opacity: 0.1;

  h3 {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0;
  }

  p {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.375rem;

    .title {
      width: 7rem;
    }

    .value {
      width: 4rem;
      text-align: right;
    }
  }
}

#main {
  display: flex;
  // padding: 4.75rem 1.875rem 3.5rem 1.875rem;
  padding: 0;
  margin-top: 0;
  height: 100vh;
  overflow: hidden;

  #middle {
    width: 100%;
    height: 100vh;
    position: relative;

    #map {
      height: 100vh;
      // margin-bottom: 1rem;
      .project_map {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
      }

      .menus {
        z-index: 999;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #21315b;
        cursor: pointer;

        > div {
          padding: 0.58rem 0.73rem;
          font-size: 0.82rem;
        }

        > div:first-child {
          border-right: 1px solid #5e7ec9;
        }

        .img {
          margin-left: 0.47rem;
          width: 0.76rem;
          height: 0.7rem;
        }
      }
    }
  }
}

#logo {
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  display: flex;
  align-items: center;
  line-height: 2rem;
  z-index: 1000000;

  span {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.6);
    margin-right: 1rem;
  }
}

#mapid {
  width: 100%;
  height: 96vh;
}

.map-angle {
  background-color: red;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.leaflet-popup-content-wrapper {
  background-color: #122040;
  text-align: center;
  border: 2px solid #5f7ec9;
}

.leaflet-popup-content > div:not(:last-child) {
  margin-bottom: 5px;
}

.leaflet-popup-tip-container {
  display: none;
}
</style>

<style lang="less">
.maptalks-all-layers {
  z-index: auto!important;
}

.maptalks-canvas-layer {
  z-index: auto!important;
}

.info-block {
  position: relative;
  width: 100%;
  background: url("../../assets/nation/title.png") no-repeat;
  background-size: contain;
}

.info-block-title {
  position: absolute;
  color: #c8dbf4;
  font-weight: bold;
  left: 2.8rem;
  top: 0.6rem;
}
.stats-layer .el-dialog__header {
  display: none;
}
.stats-layer .el-dialog__body {
  padding: 0;
  color: unset;
}

.option-block {
  color: #919192;
  background-color: #172142;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 6rem;
  border: 0;
  text-align: center;
  cursor: pointer;
}

.option-block-selected {
  color: #fcfcfc;
  background-color: #0a57be;
}

.el-dialog.recall-dialog {
  width: 31.25rem !important;
  background-color: rgba(21, 28, 72, .8) !important;
}

.recall-dialog .el-dialog__header, .recall-dialog .el-dialog__footer {
  display: none;
}

.recall-dialog .el-dialog__body {
  text-align: center;
  font-size: 1.25rem;
  color: #fff;
  padding: 1.5rem;
}

.recall-dialog .warning-icon {
  display: inline-block;
  background: url("../../assets/weixian.png") no-repeat;
  width: 5rem;
  height: 5rem;
  background-size: cover;
  margin-bottom: 3.5rem;
}

.recall-dialog .cancel-button, .recall-dialog .confirm-button {
  width: 6.13rem;
  height: 2rem;
  border-radius: 1rem;
  color: #ffffff;
  line-height: 2rem;
  font-size: 1rem;
  display: inline-block;
  background: rgba(117, 120, 167, 1);
  cursor: pointer;
  text-align: center;
}

.recall-dialog .confirm-button {
  margin-left: 2.5rem;
  background: linear-gradient(90deg, rgba(81, 189, 245, 1), rgba(81, 112, 245, 1));
}
</style>
