<template>
    <div id = "right">
        <div class="info-block" style="min-height:5rem">
        <div class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >案件类型
        <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '1' }"
              @click="() => switchAvgAttendanceTopListOption('1')"
            >存量案件</div>
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '2' }"
              @click="() => switchAvgAttendanceTopListOption('2')"
            >新发案件</div>
          </div>
        </div>
        <div style="height:20rem;padding-top:3rem">
        <div
            v-if="caseTypeChart.series && [].concat.apply([], caseTypeChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="caseTypeChart" @click="caseTypeChartClick" autoresize />
        </div>
    </div>
    
    <!-- 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                <el-table style="background-color:#0E1328" height="28rem" :data="tableData" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column type="index" label="序号">
                    </el-table-column>
                    <el-table-column
                        prop="FYSAJH"
                        label="案件号">
                        </el-table-column>
                    <el-table-column
                        prop="FCJTLQ"
                        label="从集团领取">
                    </el-table-column>
                    <el-table-column
                        prop="FDWMC"
                        label="涉诉公司">
                    </el-table-column>
                    <el-table-column
                        prop="FXMMC"
                        label="涉案项目">
                    </el-table-column>
                    <el-table-column
                        prop="FYG"
                        label="原告/申请">
                    </el-table-column>
                    <el-table-column
                        prop="FBG"
                        label="被告/申请">
                    </el-table-column>
                    <el-table-column
                        prop="FDSR"
                        label="第三人">
                    </el-table-column><el-table-column
                        prop="FAFRQ"
                        label="案发时间">
                    </el-table-column>
                    <el-table-column
                        prop="FAFLX"
                        label="案发类型"
                        :formatter="formatterCaseType">
                    </el-table-column>
                    <el-table-column
                        prop="FGXFYZCW"
                        label="管辖法院/仲裁委">
                    </el-table-column>
                    <el-table-column
                        prop="FYSBDE"
                        label="标的额(元)">
                    </el-table-column>
                     <el-table-column
                        prop="FYSBJ"
                        label="本金(元)">
                    </el-table-column>
                    <el-table-column
                        prop="FYSLX"
                        label="利息(元)">
                    </el-table-column>
                    <el-table-column
                        prop="FCJJE"
                        label="裁判金额">
                    </el-table-column>
                    <el-table-column
                        prop="FJSJE"
                        label="减损金额">
                    </el-table-column>
                    <el-table-column
                        prop="FAY"
                        label="案由"
                        :formatter="formatterFay">
                    </el-table-column>
                    <el-table-column
                        prop="FAJZT"
                        label="案件状态">
                    </el-table-column>
                </el-table>
            </div>
        </div>
      </wrapper>
    </el-dialog>
    </div>
</template>
<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import {law} from '../../../../api_law';
  import wrapper from "../../../../components/wrap";
export default {
    name:"CaseTypeChart",
    components:{
        'v-chart':ECharts,
        wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateCaseTypeChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateCaseTypeChart();
        }
      }
    },
    data(){
        return{
            caseTypeChart:{},
            mode:"1",
            popupShow:false,
            tableData:[]
        }
    },
    mounted(){
        const token = this.$route.query.token;
      window.token = token;
      
      this.updateCaseTypeChart();
    },
    methods:{
      formatterFay(row,column,cellvalue){
            return law.formatterFay(cellvalue);
        },
        formatterCaseType(row,column,cellvalue){
             return cellvalue=="01"?"仲裁":"诉讼";
        },
      switchAvgAttendanceTopListOption(choose){
        this.mode = choose;
        this.updateCaseTypeChart();
      },
        updateCaseTypeChart(){
            law.queryCaseType().then((res=>{
                const option = {
                  color:['#5d9cec', '#62c87f', '#f15755', '#fc863f', '#7053b6'],
                  title: {
                  top: 10,
                  left: 'center',
                  textStyle: {
                      fontSize: 18,
                      fontWeight: 400
                  }
                },
                textStyle: {// 全局字体样式
                    fontSize: 14
                },
                tooltip: {
                    trigger: 'item',
                },
                series: [
                    {
                        name:'数量',
                        type:'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            normal: {
                                formatter: function(param) {
                                     return param.name + ':\n' + Math.round(param.percent) + '%';
                                }
                            }
                        },
                        data:[]
                    }
                ]
                }
                if(this.mode == "1"){
                  for (let k = 0; k < res.clList.length; k++) {
                    option.series[0].data.push({name:law.formatterFay(res.clList[k].FAY),value:res.clList[k].AJH,fay:res.clList[k].FAY}); 
                  }
                }else{
                  for (let i = 0; i < res.xfList.length; i++) {
                    option.series[0].data.push({name:law.formatterFay(res.xfList[i].FAY),value:res.xfList[i].AJH,fay:res.xfList[i].FAY}); 
                  }
                }
                this.caseTypeChart = option;
            }))
        },
        tableRowStyle({rowIndex}){
            if(rowIndex%2 === 0)
                return {backgroundColor:'#213a77',height: '0'}; 
            else   
                return {backgroundColor:'#102855',height: '0'}; 
        },
        tableHeaderColor(){
            return 'color:#4fb5da;background:#102855';
        },
        caseTypeChartClick(option){
          this.popupShow = true;
          var status = this.mode == "1"?3:2;
          law.queryRespondentCaseDetail(status,"",option.data.fay).then((res=>{
            this.tableData = res;
          }))
        }
    }
}
</script>