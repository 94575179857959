<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  />
</template>

<script>
  import Broadcasts from "../../../../components/broadcasts";
  import { projectMgr } from "../../../../api_projectMgr";

  export default {
    name: 'QualitySub1Broadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['组建完成率', '项目策划率', '首次资源配置完成率'],
        broadcastColors: ['#fea453', '#ffd454', '#46a0ff'],
        broadcastIcons: ['组建完成率', '项目策划率', '首次资源配置完成率'],
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    },
  }

  export function updateData(container, scheduleNextUpdate) {
    projectMgr.queryMapTitleInfo()
      .then((d) => {
        container.broadcastData.resourceAllocation = d;
        container.broadcastData.resourceAllocation.value1 = (d.formFinishRate*100).toFixed(2)+"%";
        container.broadcastData.resourceAllocation.value2 = (d.projPlanRate*100).toFixed(2)+"%";
        container.broadcastData.resourceAllocation.value3 = (d.resourceConfigRate*100).toFixed(2)+"%";

        container.statsData = d;
        // scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
