import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    'T9': [0, 0, 540],
  };

  const buildingBoundaries = {
    'T9': [[-105, 73], [105, 73], [105, -73], [-105, -73]],
  };

  const buildingFinishedHeights = {
    'T9': 376.967,
  };

  const doneFloors = {
    'T9': 24,
  };

  const traceViews = {
    '_': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    'T9': {
      center: [-335.4858166829759, 2997.882],
      pitch: 52,
      zoom: 2.94,
      bearing: -11.2,
    },
  };

  function getUserZValue(user) {
    return (user.floor == 'ground' ? 0 : user.height) * 4.92 + 5;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 4.92;
  }

  function getZValueFromHeight(height) {
    return height * 4.92;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function modelPostProc(scene/*, meshMap*/) {
    /*
    for (const mesh in meshMap) {
      let materials = meshMap[mesh].material;
      if (!Array.isArray(materials)) {
        materials = [materials];
      }

      for (const material of materials) {
        if (material.color.r == 0.8 && material.color.g == 0.8 && material.color.b == 0.8) {
          material.color.r = 0.372;
          material.color.g = 0.372;
          material.color.b = 0.372;
        }
      }
    }
    */

    addFloorNames(scene, [
      ['5F', 135, 0, -10],
      ['10F', 135, 0, 63.8],
      ['15F', 135, 0, 137.6],
      ['20F', 135, 0, 211.4],
      ['25F', 135, 0, 285.2],
      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/no9-2-1.fbx',

    modelOptions: {
      scale: 1.5,
      rotZ: 0,
      moveX: 0,
      moveY: 0,
      moveZ: 218.13
    },

    sceneRotation: {
      x: 0,
      y: 0,
      z: 0
    },

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    modelPostProc: modelPostProc,
  };
}
