import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    //'主楼': [149.7636150523846,-164.72444259156129,570],
  };

  const buildingBoundaries = {
    '主楼': [[-110000, -110000], [-110000, -10000], [-10000, -10000], [-10000, -10000]],
  };

  const buildingFinishedHeights = {
    '主楼': 44.67,
  };

  const doneFloors = {
    '主楼': 1,
  };

  const traceViews = {
    '_': {
      center: [0, 0],
      pitch: 0,
      zoom: 1.8,
    },
    '主楼': {
      center: [-4793.5101010431, 5203.411499274705],
      pitch: 75.2,
      zoom: 2.50,
      bearing: -21.4,
    },
  };

  function getUserZValue(user) {
    return (user.floor == 'ground' ? 0 : user.height) * 7.55 + 5;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 7.55;
  }

  function getZValueFromHeight(height) {
    return height * 7.55;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/anding-burning2.glb',
    modelType: 'gltf',
    modelSubType: 'draco',

    //fullOpacity: 1,
    revealOpacity: 0.25,
    deepRevealOpacity: 0,

    groundHeightRanges: [
      [-48, -1.6],
//      [-0.0001, 0],
//      [153.9731, 153.9732],
//      [-1425.0001, -1424.9999],
    ],

    modelOptions: {
      scale: 4,
      rotZ: 0,
      moveX: -350,
      moveY: -650,
      moveZ: 0,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: 0,
      z: 0
    },

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
