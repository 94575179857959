<template>
  <div id="left">
    <wrapper style="padding: 0">
      <div
        class="info-block"
        style="min-height:5rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>近七日现场管理统计</div>
          <div
            class="detail"
            @click="showMgmDetail"
          >
            详情
          </div>
        </div>

        <div style="max-height:23rem;min-height:12rem;padding-top:3rem">
          <div style="display:flex;justify-content:space-between;align-items:center;margin:0 1rem">
            <CustomSelect
              :options="deptNameList"
              @input="deptSelect"
              style="width:6rem;line-height:1.6rem;font-size:0.7rem;font-weight:bold"
            />
            <div style="display:flex;align-items:center">
              <div
                class="option-block"
                style="font-size:0.7rem;min-width:0"
                :class="{ 'option-block-selected': mgmStatsOption == 'all' }"
                @click="() => switchMgmStatsOption('all')"
              >全员</div>
              <div
                class="option-block"
                style="font-size:0.7rem;min-width:0"
                :class="{ 'option-block-selected': mgmStatsOption == 'mgm' }"
                @click="() => switchMgmStatsOption('mgm')"
              >领导</div>
            </div>
          </div>
          <div style="height:12.5rem;line-height:1.6rem;font-size:0.9rem;color:#bfcfd2;font-weight:bold;overflow:hidden;margin-bottom:-0.5rem">
            <v-chart
              :options="mgmStatsBarChart"
              autoresize
            />
          </div>
        </div>

      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem;padding:0">

      <div
        class="info-block"
        style="min-height:5rem"
      >
        <div class="info-block-title">各楼栋管理时长</div>

        <div style="height:17rem;padding-top:3rem;display: flex; align-items: center;">
          <div
            v-if="managerBuildAttendanceCollect.series && [].concat.apply([], managerBuildAttendanceCollect.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart
            v-else
            :options="managerBuildAttendanceCollect"
            @click="managerBuildAttendanceClick"
            autoresize
          />
        </div>
      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem;padding:0">

      <div
        class="info-block"
        style="min-height:5rem"
      >
        <div
          class="info-block-title"
          style="display: flex;justify-content: space-between;align-items:center;width: 90%;"
        >
          <div>现场管理时长Top人员</div>

        </div>
        <div style="height:20.5rem;padding:3rem 1rem 0;display: flex; align-items: center;">
          <div
            v-if="!managerAttendanceTopList.length"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 24rem; height: 12rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <div
            v-else
            style="height: 100%;width:100%;line-height:1.6rem;font-size:0.9rem;color:#abbbbf;font-weight:bold;overflow:scroll;"
          >

            <div
              v-for="project in managerAttendanceTopList"
              :key="project.worker_id"
              style="height:1.8rem;display:flex;justify-content:space-between"
              @click="managerAttendanceTopClick(project)"
            >
              <div>{{project.worker_name}}</div>
              <div style="margin-left:0.5rem;color:#32B7C0;">{{project.attendance_hours}}h</div>
            </div>
          </div>
        </div>
      </div>
    </wrapper>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="mgmDetailShow"
      width="1500px"
      top="4.3rem"
    >
      <wrapper style="height:960px;backgroundColor:#0e1328;position:relative">
        <iframe
          ref="mgmDetailIframe"
          :src="`http://smarthat.comprehend.com.cn/html/screen/manager_attendance_collect.html?project_id=${$route.params.id}&token=${$route.query.token}`"
          width="1500px"
          height="960px"
          style="border:0;position:absolute;left:0;top:0"
        ></iframe>
        <div
          class="stats-close-btn"
          @click="mgmDetailShow = false"
        />
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="mgmSubWindowShow"
      width="1200px"
      top="18rem"
    >
      <wrapper style="height:370px;backgroundColor:#0e1328;position:relative">
        <iframe
          ref="mgmDetailIframe"
          :src="mgmSubWindowUrl"
          width="1200px"
          height="370px"
          style="border:0;position:absolute;left:0;top:0"
        ></iframe>
        <div
          class="stats-close-btn"
          @click="mgmSubWindowShow = false"
        />
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="buildDetailShow"
      width="70rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:32rem;"
      >
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div
            class="stats-chart-block"
            style="height:16rem;width:66rem;"
          >
            <div
              class="stats-close-btn"
              @click="buildDetailShow = false"
            />
            <div style="display:flex;justify-content: space-between;">
              <div style="font-size:18px;font-weight:bold">{{buildDetailTitle}} 今日管理情况
              </div>
              <CustomSelect
                :options="deptNameList"
                @input="deptSelect2"
                style="width:6rem;line-height:1.6rem;font-size:0.7rem;font-weight:bold"
              />
            </div>
            <div style="margin-top:2rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:5rem">序号</div>
                <div style="width:15rem;text-align:center">姓名</div>
                <div style="width:10rem">部门</div>
                <div style="width:8rem">岗位</div>
                <div style="width:8rem">一级区域</div>
                <div style="width:18rem">施工现场管理时长(h)</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in managerBuildAttendanceDetail"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:5rem">
                    {{index>8?index+1:'0'+(index+1)}}
                  </div>
                  <div style="width:15rem;text-align:center">{{item.manager_name}}</div>
                  <div style="width:10rem">{{item.department_name}}</div>
                  <div style="width:8rem;">{{item.position_name}}</div>
                  <div style="width:8rem;">{{item.build_name}}</div>
                  <div style="width:18rem;">{{item.attendance_hours}}</div>
                </div>
                <div
                  v-if="!(managerBuildAttendanceDetail && managerBuildAttendanceDetail.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无{{buildDetailTitle}} 今日管理情况
                </div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="managerTopListDetailShow"
      width="50rem"
      top="18rem"
    >
      <wrapper
        class="popup-bg"
        style="height:22rem;"
      >
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div
            class="stats-chart-block"
            style="height:16rem;width:66rem;"
          >
            <div
              class="stats-close-btn"
              @click="managerTopListDetailShow = false"
            />
            <div style="display:flex;justify-content: space-between;">
              <div style="font-size:18px;font-weight:bold">{{managerTopTitle.name}} 今日各区域管理情况
              </div>
              <div style="font-size:18px;font-weight:bold"> 总时长：{{managerTopTitle.count}} h
              </div>
            </div>
            <div style="margin-top:2rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:5rem">序号</div>
                <div style="width:18rem">一级区域</div>
                <div style="width:10rem">时长（h）</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in managerTopListDetail"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:5rem">
                    {{index>8?index+1:'0'+(index+1)}}
                  </div>
                  <div style="width:18rem;">{{item.area_list}}</div>
                  <div style="width:10rem;">{{item.hour}}</div>
                </div>
                <div
                  v-if="!(managerTopListDetail && managerTopListDetail.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无{{buildDetailTitle}} 今日管理情况
                </div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>

  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { project, nation } from "../../api";
import CustomSelect from "../../components/select";
import wrapper from "../../components/wrap";
import { SimpleBarChartTemplate } from "./barChartTemplates";
import { applyUpdate } from "../../utils";

export default {
  name: "ManagerLeftBar",
  components: {
    "v-chart": ECharts,
    CustomSelect: CustomSelect,
    wrapper,
  },
  props: {
    projectDetail: Object,
    showTrace: Function,
  },
  data() {
    return {
      projectId: null,
      deptNameList: ["全部部门"],
      deptIdList: [""],
      mgmStatsOption: "all",
      mgmStatsSelectedDeptIdx: null,
      mgmStatsBarChart: {},

      option1: {
        color: ["#23cf9e", "#fdc81e"],
        grid: {
          contentLabel: true,
          left: "10%",
          bottom: "20%",
          // top: "15%",
          right: "10%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        tooltip: {
          trigger: "axis",
          // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
          formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                  result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + params[0].data;
                  if(params[1]){
                      result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                  }

              }
              return result;
            }
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              rotate: 35,
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
            minInterval: 1,
            show: true,
            name: "小时",
          },
          {
            name: "比例",
            show: true,
            min: 0,
            max: 100,
            interval: 25,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              // formatter: function (value) {
              //   return value ? value + "%" : value;
              // },
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        series: [
          {
            name: "数量",
            type: "bar",
            barWidth: 10,
            data: [],
          },
          {
            name: "数量",
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                },
              },
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(27, 208, 128, 0.3)" },
                { offset: 1, color: "rgba(27, 208, 128, 0)" },
              ]),
            },
            symbolSize: 0,
            yAxisIndex: 1,
            data: [],
          },
        ],
      },
      managerBuildAttendanceCollect: {},
      mgmDetailShow: false,
      mgmSubWindowUrl: null,
      mgmSubWindowShow: false,

      option2: {
        color: ["#e5b353"],
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0}: {c0}",
        },
        legend: {
          show: false,
        },
        grid: {
          top: "15%",
          left: "5%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisLabel: {
              color: "#e6f2f3",
              rotate: 45,
              fontWeight: "normal",
              formatter: function (value) {
                return value.length > 4 ? value.slice(0, 3) + "..." : value;
              },
            },
          },
        ],
        yAxis: [
          {
            show: true,
            name: "时长：h",
            type: "value",
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
              align: "center",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                width: 1,
                type: "solid",
              },
            },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: "时长",
            type: "bar",
            barWidth: 10,
            barMaxWidth: 18,
            data: [],
          },
        ],
      },
      managerBuildAttendanceDetail: [],
      managerBuildAttendanceSearchKey: [],
      buildDetailShow: false,
      buildDetailTitle: "",
      mbaStatsSelectedDeptIdx: 0,
      activeBuildDetail: 0,

      managerAttendanceTopList: [],
      managerTopListDetailShow: false,
      managerTopTitle: {
        name: "",
        count: 0,
      },
      managerTopListDetail: [],
    };
  },
  mounted() {
    // const self = this
    const { id: projectID } = this.$route.params;
    this.projectId = projectID;
    const token = this.$route.query.token;
    window.token = token;
    this.getData();

    nation
      .getGeneralParamsSelect(4)
      .then((d) => {
        this.deptNameList = ["全部部门"].concat(d.map((item) => item.value));
        this.deptIdList = [""].concat(d.map((item) => item.innerid));
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));

    window.addEventListener(
      "message",
      (event) => {
        if (event.data.key == "merge") {
          if (event.data.page == "trace") {
            this.showTrace(
              event.data.query.worker_id,
              event.data.query.worker_name,
              new Date(event.data.query.check_date),
            );
          } else {
            this.showMgmSubWindow(event.data.url);
          }
        }
      },
      false
    );
  },
  methods: {
    getData() {
      this.updateMgmStatsChart("");
      this.getEcharts2();
      this.getList();
    },
    updateMgmStatsChart(deptId) {
      project
        .getProjMgmStats(
          this.$route.params.id,
          deptId,
          this.mgmStatsOption == "mgm" ? 1 : 0
        )
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option1.legend.data = d.legend;
          this.option1.xAxis[0].data = d.xAxis_data;
          this.option1.series[0].name = d.series[0].name;
          this.option1.series[1].name = d.series[1].name;
          this.option1.series[0].data = d.series[0].data;
          this.option1.series[1].data = d.series[1].data;
          this.mgmStatsBarChart = applyUpdate(chart, this.option1);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },

    deptSelect(option) {
      const idx = this.deptNameList.indexOf(option);
      if (idx >= 0) {
        this.mgmStatsSelectedDeptIdx = idx;
        this.updateMgmStatsChart(this.deptIdList[idx]);
      }
    },
    switchMgmStatsOption(option) {
      if (option != this.mgmStatsOption) {
        this.mgmStatsOption = option;
        this.updateMgmStatsChart(this.deptIdList[this.mgmStatsSelectedDeptIdx]);
      }
    },
    showMgmDetail() {
      this.mgmDetailShow = true;
    },
    showMgmSubWindow(url) {
      this.mgmSubWindowUrl = url;
      this.mgmSubWindowShow = true;
    },

    getEcharts2() {
      project
        .getProjManagerBuildAttendanceCollect(this.projectId)
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option2.xAxis[0].data = d.xAxis_data;
          this.option2.series[0].data = d.series_data;
          this.managerBuildAttendanceCollect = applyUpdate(chart, this.option2);
          this.managerBuildAttendanceSearchKey = d.parameter_list;
        })
        .catch((e) => console.error(e.msg || e.message));
    },

    getList() {
      project
        .getProjManagerAttendanceTopList(this.projectId, 1, 9)
        .then((d) => {
          // console.log(d)
          this.managerAttendanceTopList = d;
        });
    },

    deptSelect2(option) {
      const idx = this.deptNameList.indexOf(option);
      if (idx >= 0) {
        this.mbaStatsSelectedDeptIdx = idx;
        this.securityStarPageChange(this.activeBuildDetail);
      }
    },
    managerBuildAttendanceClick(params) {
      // console.log(this.managerBuildAttendanceSearchKey);
      this.activeBuildDetail = params.dataIndex;
      this.buildDetailShow = true;
      this.buildDetailTitle =
        this.managerBuildAttendanceCollect.xAxis[0].data[params.dataIndex];
      this.securityStarPageChange(params.dataIndex);
    },
    securityStarPageChange(mode) {
      project
        .getProjManagerBuildAttendanceDetail(
          this.projectId,
          this.managerBuildAttendanceSearchKey[mode].build_id,
          this.deptIdList[this.mbaStatsSelectedDeptIdx]
        )
        .then((d) => {
          this.managerBuildAttendanceDetail = d;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },

    managerAttendanceTopClick(item) {
      this.managerTopListDetailShow = true;
      this.managerTopTitle.name = item.worker_name;
      this.getProjManagerPopDetail(item.worker_id);
    },
    getProjManagerPopDetail(worker_id) {
      project
        .getProjManagerAttendanceTopDetail(this.projectId, worker_id)
        .then((d) => {
          // console.log(d)
          this.managerTopTitle.count = d.total_hours;
          this.managerTopListDetail = d.area_list;
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.option-block-selected {
  color: #fcfcfc;
  background-color: #4999d9 !important;
  border: 1px solid #4999d9 !important;
}
.option-block{
  border: 1px solid #4999d9 !important;
}
.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>