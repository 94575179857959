// import { data } from 'jquery';

const baseUrl = process.env.VUE_APP_API_BASE_ZGS_URL


const areaCodeMap = require('/src/area-code-map.json');

const handleRes = (res) => {
    if (res.statusCode < 200 || res.statusCode >= 300) {
      return res.response.text().then(e => Promise.reject(e))
    } else {
      return res.response.json().then(_d => {
        if (_d.code !== 0 && _d.code !== 200) {
          return Promise.reject(_d)
        } else if (_d.data) {
          _d.data.__count__ = _d.count;
          return _d.data;
        } else{
          return _d;
        }
      })
    }
  }
// function addFilterParams(url) {
//       // for(var key in areaCodeMap){
//       //   if(key == window.$districtLevel){
//       //     console.log(areaCodeMap[key]);
//       //   }
//       // }
//       formattingOrgId();
//       const containsArea = url.indexOf('areaLvl=') >= 0;
//       const containsOrg = url.indexOf('orgLvl=') >= 0;
//       if (!containsArea || !containsOrg) {
//         const sign = url.indexOf('?') >= 0 ? '&' : '?';
//         const areaStr = `areaLvl=${window.$districtLevel || '100000'}`;
//         const orgStr = `orgLvl=${window.$orgLevel || ''}`;
//         if (!containsArea && !containsOrg) {
//           return url + sign + areaStr + '&' + orgStr;
//         } else {
//           return url + sign + (!containsArea ? areaStr : orgStr);
//         }
//       } else {
//         return url;
//       }
//     // const sFIDArea = url.indexOf('sFID=') >= 0;
//     // if (!sFIDArea) {
//     //   // const sign = url.indexOf('?') >= 0 ? '&' : '?';
//     //   formattingOrgId();
//     //   return url;
//     //   // const orgStr = `orgLvl=${window.$sFid || ''}`;
//     //   // return url + sign + orgStr;
//     // } else {
//     //   return url;
//     // }
//   }

  function addFilterParams2(url) {
    //忽略地区接口
    if(window.$orgName == "中建一局集团第一建筑有限公司" || window.$orgName == undefined){
      url = changeURLArg(url,"orgName","一公司");
    }
    var provinceName = '';
    var cityName = '';
    for(var key in areaCodeMap){
      if(key == window.$districtLevel){
        let str = areaCodeMap[key];
        if(str != '' && str != null){
          var datalist = str.split("/");
          if(datalist.length >= 1){
            provinceName = datalist[0];
            cityName = datalist[1];
          }
        }
      }
    }
    const provinceExist = url.indexOf('provinceName=') >= 0;
    const cityExist = url.indexOf('cityName=') >= 0;
    if(!provinceExist || !cityExist) {
      const sign = url.indexOf('?') >= 0 ? '&' : '?';
      const provinceStr = `provinceName=${provinceName || ''}`;
      const cityStr = `cityName=${cityName || ''}`;
      if (!provinceExist && !cityExist) {
        return url + sign + provinceStr + '&' + cityStr;
      } else {
        return url + sign + (!provinceExist ? provinceStr : cityStr);
      }
    } else {
      return url;
    }
}

function changeURLArg(url, arg, arg_val) {
  var pattern = arg + '=([^&]*)';
  var replaceText = arg + '=' + arg_val;
  if (url.match(pattern)) {
      var retuenUrl = url;
      var temp = '/(\\&' + arg + '=)([^&]*)/gi';
      if (eval(temp).test(retuenUrl)) {
      retuenUrl = retuenUrl.replace(eval(temp), '&' + replaceText);
      }
      var temps = '/([\\?])(' + arg + '=)([^&]*)/gi';
      if (eval(temps).test(retuenUrl)) {
      retuenUrl = retuenUrl.replace(eval(temps), '?' + replaceText);
      }
      return retuenUrl;
  }else {
      // if (url.match('[\?]')) {
      if (url.indexOf('?') > 0) {
          return url + "&" + replaceText;
      } else {
          return url + "?" + replaceText;
      }
  }
}

/** @param url {string}
 * @return Promise*/
 const get = url => fetch(addFilterParams2(baseUrl + url)).then(res => ({ response: res, statusCode: res.status })).then(handleRes)

// const post = (url, body, type = 'json') => {
//     let headers, _body
//     if (type === 'json') {
//       headers = { 'Content-Type': 'application/json','traditional':true}
//       // headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
//       _body = JSON.stringify(body)
//     } else if (type === 'file') {
//       _body = new FormData()
//       _body.append('files', body)
//     } else {
//       _body = body
//     }
//     if (url.slice(0, 4) != 'http') {
//       url =baseUrl + url;
//     }
//     return fetch(addFilterParams2(url), { method: 'POST', headers, body: _body })
//       .then(res => ({ response: res, statusCode: res.code })).then(handleRes)
//   }
// function formattingOrgId(){
//   let orgId = window.$sFid;
//   if(orgId !== null && orgId !== undefined && orgId!=='' ){
//     if(orgId.indexOf(".ogn")==0){
//         window.$sFid = '/'+window.$sFid+'.ogn';
//     }
//   }else{
//     window.$sFid = "/7B867F6775E143F8B1F3131BFA85C13E.ogn";
//   }
// }
// function verifyOrgId(sFID){
//   if(sFID === '' || sFID === undefined || sFID === null)
//       sFID = "/7B867F6775E143F8B1F3131BFA85C13E.ogn";
//   return sFID
// }
// 通过年处理日期范围
// function formattingDateIntervalByYear(year){
//   if(year != null && year !=""){
//     let dateInterval = new Array();
//     dateInterval.push(year+"-01");
//     dateInterval.push(year+"-12");
//     return dateInterval;
//   }
// }
const law = {
  // 金额转万元
  formatToMyriadDecimal(num, decimal) {
    num = (num/10000).toString()
    let index = num.indexOf('.')
    if (index !== -1) {
        num = num.substring(0, decimal + index + 1)
    } else {
        num = num.substring(0)
    }
    return parseFloat(num).toFixed(decimal)
  },
  // 案由数据转换
  formatterFay(fay){
    var result = "";
    switch (fay) {
      case "01":
        result = "买卖合同纠纷";
        break;
      case "02":
        result = "建筑工程施工合同纠纷";
        break;
      case "03":
        result = "租赁合同纠纷";
        break;
      case "04":
        result = "劳动人事争议纠纷";
        break;
      case "05":
        result = "劳务(工资)纠纷";
        break;
      case "06":
        result = "劳务(工伤)纠纷";
        break;
      case "07":
        result = "其它类纠纷";
        break;
      default:
        break;
    }
    return result;
  },
  // 案件数量
  queryCaseStatistics:()=>get(`faWuZsCase/zsCaseTitle?orgName=${window.$orgName}`),
  // 确权、回款金额
  queryAuthenticStatistics:()=>get(`faWuZsCase/zsCaseMid?orgName=${window.$orgName}`),
  // 主诉案件总览
  queryChiefCaseTotal:()=>get(`faWuZsCase/countZsCaseNum?orgName=${window.$orgName}`),
  // 主诉案件详情
  queryChiefCaseDetail:(caseType)=>get(`faWuZsCase/countZsCaseXq?orgName=${window.$orgName}&caseProperties=${caseType}`),
  // 案件数量与金额
  queryCaseLoadSum:()=>get(`faWuZsCase/countCaseNumAndSum?orgName=${window.$orgName}`),
  // 确权与回款
  queryRightAndCollection:()=>get(`faWuZsCase/zsCaseQqAndHk?orgName=${window.$orgName}`),
  // 确权与回款 详情页面
  queryRightAndCollectionDetail:()=>get(`faWuZsCase/zsCaseQqAndHkXX?orgName=${window.$orgName}`),
   // 案件进展
   queryCaseProgress:()=>get(`faWuZsCase/countZsCasebyRq?orgName=${window.$orgName}`),
   // 案件进展详情
   queryCaseProgressDetail:(status)=>get(`faWuZsCase/countZsCaseXqbyRq?orgName=${window.$orgName}&status=${status}`),
   // 应诉案件模块标题
   queryRespondentStatistics:()=>get(`faWuYsCase/ysCaseTitle?orgName=${window.$orgName}`),
   // 应诉案件数据总览
   queryRespondentCaseView:()=>get(`faWuYsCase/countYsCaseNum?orgName=${window.$orgName}`),  
   // 应诉案件数据详情
   queryRespondentCaseDetail:(caseType,xmmc,fay)=>get(`faWuYsCase/countYsCaseXq?orgName=${window.$orgName}&caseProperties=${caseType}&xmmc=${xmmc}&fay=${fay}`),  
   // 应诉案件数量与金额
   queryRespondentCaseLoadSum:()=>get(`faWuYsCase/countYsCaseNumAndSum?orgName=${window.$orgName}`), 
   // 应诉案件项目排名
   queryRespondentCaseRank:()=>get(`faWuYsCase/queryYsXfCaseQk?orgName=${window.$orgName}`),  
   // 生效法律文书每月应付金额
   queryLegalInstryumentCope:()=>get(`faWuYsCase/flfkBymonth?orgName=${window.$orgName}`),  
   // 生效法律文书每月应付金额
   queryLegalInstryumentCopeDetail:(month)=>get(`faWuYsCase/flfkBymonthXX?orgName=${window.$orgName}&month=${month}`),
   // 分公司生效法律文书付款数据  
   queryFilialeLegalInstryument:()=>get(`faWuYsCase/flfkOrgName?orgName=${window.$orgName}`),  
   // 分公司生效法律文书付款详情  
   queryFilialeLegalInstryumentDetail:()=>get(`faWuYsCase/flfkOrgNameXX?orgName=${window.$orgName}`),  
   // 案件类型统计  
   queryCaseType:()=>get(`faWuYsCase/queryAjlx?orgName=${window.$orgName}`),
   // 风险项目标题总览
   queryRiskOverview:()=>get(`FaWuFxxm/queryFxxmTitle?orgId=${window.$sFid}`),
   // 各类风险项目数量总览
   queryRiskProjectNum:(client)=>get(`FaWuFxxm/queryFxxmNum?orgId=${window.$sFid}&fmc=${client}`),
   // 大客户风险项目总览
   queryMajorClientRiskProj:()=>get(`FaWuFxxm/queryDkhFxQk?orgId=${window.$sFid}`),
   // 风险销项进展缓慢项目
   queryOutputRiskSlowProj:()=>get(`FaWuFxxm/queryJzxm?orgId=${window.$sFid}`),
   // 逾期应收、潜亏风险
   queryOverdueRisk:()=>get(`FaWuFxxm/yqfxQk?orgId=${window.$sFid}`),
   // 工期风险
   queryScheduleRisk:()=>get(`FaWuFxxm/queryGqfxxm?orgId=${window.$sFid}`),
   // 风险项目详情
   queryRiskProjectDetail:(type,client,fxlx)=>get(`FaWuFxxm/queryFxxmXq?orgId=${window.$sFid}&type=${type}&fmc=${client}&fxlx=${fxlx}`),
}

export { baseUrl, law }
