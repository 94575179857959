<template>
<div style="height:10rem;display:flex;justify-content:center">
    <div style="display:flex;align-items:center">
      <img :src="require(`../../../../assets/nation/icons/${broadcastIcons[0]}.png`)" />
      <div>
        <div class="stats-label" style="font-size: 0.9rem">{{broadcastTitles[0]}}</div>
        <div style="display:flex" @click="handleClick(1)">
          <v-number
            v-model="(broadcastData || {}).value1"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[0]}"
          ></v-number>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VNumber from "../../../../components/v-number"
  import { law } from "../../../../api_law";

  export default {
    name: 'hrBroadcasts',
    components: {
      VNumber,
    },
    data() {
      return {
        broadcastTitles: ['案件数'],
        broadcastIcons: ['案件数'],
        broadcastColors: ['#40f8ff'],
        broadcastSep: '0.3rem',
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    }
  }

  export function updateData(container, scheduleNextUpdate) {
    law.queryCaseStatistics()
      .then(res=>{
        container.broadcastData.law = res;
        container.broadcastData.law.value1 = res.zsCaseNum;
        container.statsData = res;
        // scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1rem;
  font-weight: bold;
  color: #cececf;
}
</style>
