<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  />
</template>

<script>
  import Broadcasts from "../../components/broadcasts";
  import { nation } from "../../api";

  export default {
    name: 'SecurityBroadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['大型器械总数', '危大工程总数', '未整改隐患数'],
        broadcastColors: ['#40f8ff', '#ffd454', '#e02f37'],
        broadcastIcons: ['大型器械总数', '危大工程总数', '未整改隐患数'],
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    },
  }

  export function updateData(container, scheduleNextUpdate) {
    nation
      .getSafetyBroadcast()
      .then((d) => {
        container.broadcastData.security = d[0];
        container.broadcastData.security.value1 = d[0].machine_count;
        container.broadcastData.security.value2 = d[0].md_count;
        container.broadcastData.security.value3 = d[0].trouble_undo_count;

        container.statsData = d[0];
        scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
