<template>
  <div id="right">
    <ProjectCycleCurveChart :district-level="districtLevel" :org-level="orgLevel" />
    <perCapitaProfitsChart :district-level="districtLevel" :org-level="orgLevel" />
    <wageProfitOddsChart :district-level="districtLevel" :org-level="orgLevel" />
  </div>
</template>

<script>
  import ProjectCycleCurveChart from "./projectCycleCurve"
  import perCapitaProfitsChart from "./perCapitaProfitsChart";
  import wageProfitOddsChart from "./wageProfitOddsChart";

  export default {
    name: 'HrIndexRightBar',
    components: {
      ProjectCycleCurveChart,
      perCapitaProfitsChart,
      wageProfitOddsChart,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
  }
</script>

<style lang="less">
</style>
