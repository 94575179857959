import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    'A栋': [139.61209930011213,-452.26280298123163,200],
    'B栋': [-234.9453170499034,-453.71269563130204,200],
    'C栋': [566.372796284933,-456.25783673722765,200],
    'D栋': [-48.2548972664047,-115.60468764417331,200],
    'E栋': [356.58856105195485,-46.34398192942359,200],
    'F栋': [140.1094525219213,-100.8479851275284,200],
  };

  const buildingBoundaries = {
    'A栋': [[301.12665779206344,-363.70074618758423],[302.2123811310379,-594.959817389155],[-39.79047064593274,-594.959817389155],[-39.7904706459328,-365.8721928655333]],
    'B栋': [[-161.42970671772053,-308.5413986681136],[-371.7199447464351,-282.53409245021777],[-384.32755342643463,-491.6723205639038],[-369.69881861750923,-670.28411499769],[-278.74425702554765,-665.9242413430634],[-85.82618039943566,-607.9793192422359],[-85.24770703116866,-494.43714962130565],[-238.62754262536345,-498.6322917775379],[-235.23719661911133,-372.0586472644027],[-111.64217806521458,-382.27537168101793]],
    'C栋': [[433.4383412376075,-299.9044632059381],[717.1017052113973,-276.7325967119681],[725.8065891208203,-430.1714248422559],[716.646075164083,-687.4653567704208],[325.5923494483186,-592.9834154298183],[328.91248064574893,-493.46975037660036],[407.23161367440764,-491.3735768883763],[408.50073044936573,-373.9951511373175],[379.85668418690227,-355.93661243231986],[358.13997137170924,-323.20186546674535]],
    'D栋': [[22.752396982296517,-125.11686093893584],[-80.93707558488143,-189.96630234534783],[-72.64085537663047,-205.95039482962835],[-148.09510922075597,-251.16773528666917],[-232.74427317915197,-125.48722244699962],[51.09074269160894,50.75589626047889],[97.53764633825358,-90.30738867994961]],
    'E栋': [[354.8110688693354,-187.31767914867822],[255.97000226695081,-123.16539947978629],[239.83540556274232,-140.92019588082752],[196.970578273794,-86.86525579186542],[295.62751635415583,115.73553878380096],[562.0329845628834,-71.36606135496982],[396.60641763036523,-240.83918353755945]],
    'F栋': [[46.20437724821312,-13.640907952650593],[99.27877287758167,20.588454994224833],[183.3256943784038,21.872875123693177],[233.66580985375242,-12.847210634001272],[189.7805240402766,-96.4456349911332],[221.74128284224548,-166.2442896672212],[23.027496474671835,-166.7216723059389],[2.6658954686811,-133.86851705816343],[96.68135204009585,-78.34609013951737]],
  };

  const buildingFinishedHeights = {
  };

  const doneFloors = {
  };

  const traceViews = {
    '_': {
      center: [1595.6489223379904, -984.1613574067821],
      pitch: 0,
      zoom: 2,
      bearing: -43,
    },
    'A栋': {
      center: [-2060.176532884396, 4726.844837370178],
      pitch: 75,
      zoom: 2,
      bearing: -27.4,
    },
    'B栋': {
      center: [-4777.13643344857, 2368.54286130841],
      pitch: 75,
      zoom: 2.54,
      bearing: -34.6,
    },
    'C栋': {
      center: [717.4967603734724, 2123.242634975021],
      pitch: 75,
      zoom: 2.54,
      bearing: -34.6,
    },
    'D栋': {
      center: [-3139.7128470144376, 2259.811906084085],
      pitch: 75,
      zoom: 3.08,
      bearing: -47.2,
    },
    'E栋': {
      center: [-1264.0138217705162, 452.0335141363854],
      pitch: 75,
      zoom: 3.08,
      bearing: -80.2,
    },
    'F栋': {
      center: [-250.11566625374155, 3798.5230033291973],
      pitch: 75,
      zoom: 3.08,
      bearing: -23.2,
    },
  };

  function getUserZValue(user) {
    return (user.floor == 'ground' ? 0 : user.height) * 4 + 6;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 4;
  }

  function getZValueFromHeight(height) {
    return height * 4;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/xiongan2.glb',
    modelType: 'gltf',
    modelSubType: 'draco',

    //fullOpacity: 1,
    //revealOpacity: 0.2,
    //deepRevealOpacity: 0,

    /*
    groundHeightRanges: [
      [-0.0001, 0],
      [153.9731, 153.9732],
      [-1425.0001, -1424.9999],
    ],
    */

    revealUnderground: 0,

    modelOptions: {
      scale: 4,
      rotZ: 0,
      moveX: 150,
      moveY: -400,
      moveZ: 0,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: 0,
      z: 0
    },

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
