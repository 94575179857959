<template>
  <div id="path" class="global-bg">
    <div id="map">
      <div class="project_map" id="trace-mapid" />
      <div class="area_list">
        <div class="area-info" v-for="info in areaInfo" :key="info.area_id">
          <h3>
            {{info.loc_name}}
          </h3>
          <p>
            <span class="title">进入时间：</span>
            <span class="light-blue-text value">{{formatTime(info.start_time)}}</span>
          </p>
          <p>
            <span class="title">停留时间：</span>
            <span class="light-blue-text value">{{formatMins(info.t)}}</span>
          </p>
        </div>
      </div>
    </div>
    <div :key="workerId + date + mapUrl" />
  </div>
</template>

<script>
import { initTraceMap, drawTrace } from '../../../public/leaflet.js'
import { project } from '../../api'

export default {
  name: 'WorkerPath',
  props: {
    workerId: String,
    date: {
      type: Date,
      default: function() { return new Date(); }
    },
    mapUrl: String,
    mapOptions: Object,
  },
  data() {
    return {
      mapObjects: {},
      areaInfo: []
    };
  },
  mounted() {
    //加载地图和人员轨迹
    const startDate = new Date(this.date);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(startDate / 1 + 86400000);
    project.getProjWorkerTraceArea(this.workerId, startDate.toISOString(), endDate.toISOString()).then(d => this.areaInfo = d).catch(e => console.error(e.msg || e.toString()));
    initTraceMap('trace-mapid', this.mapUrl, project.getProjWorkerTrace(this.workerId, startDate.toISOString(), endDate.toISOString()), this.workerId, this.date, this.mapObjects, this.mapOptions);
  },
  beforeUpdate() {
    //更新地图和人员轨迹
    let listPromise;
    if (this.mapObjects['trace'] && (this.workerId !== this.mapObjects['trace'].worker_id || this.date / 1 !== this.mapObjects['trace'].date / 1)) {
      this.mapObjects['trace'].worker_id = this.workerId;
      this.mapObjects['trace'].date = this.date;
      const startDate = new Date(this.date);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(startDate / 1 + 86400000);
      project.getProjWorkerTraceArea(this.workerId, startDate.toISOString(), endDate.toISOString()).then(d => this.areaInfo = d).catch(e => console.error(e.msg || e.toString()));
      listPromise = project.getProjWorkerTrace(this.workerId, startDate.toISOString(), endDate.toISOString());
      drawTrace('trace-mapid', this.mapUrl, listPromise, this.workerId, this.date, this.mapObjects);
    }
  },
  methods: {
    formatTime(time) {
      const hour = time.slice(0, 2);
      const min = time.slice(-2);
      return `${hour.replace(/^0/, '')}点${min.replace(/^0/, '')}分`;
    },
    formatMins(mins) {
      if (mins < 1) {
        const secs = Math.round(mins * 60);
        return `${secs}秒钟`;
      } else {
        mins = Math.round(mins);
        if (mins >= 60) {
          return `${Math.floor(mins / 60)}小时${mins % 60}分钟`;
        } else {
          return `${mins}分钟`;
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
#path {
  width: 90rem;
  height: 60rem;
}

#map {
  position: relative;
  .project_map {
    position: relative;
    background: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
  }
}

#trace-mapid {
  width: 90rem;
  height: 60rem;
}

.area_list {
  z-index: 999;
  position: absolute;
  top: 0px;
  right: 5px;
  max-height: 100%;
  overflow-y: scroll;
  padding-right: 5px;
}

.area-info {
  margin-top: 5px;
  border: 1px solid #5f7ec9;
  padding: 0.5rem;
  z-index: 1;
  border-radius: 0.5rem;
  // display: inline-block;
  // position: absolute;
  // background-image: url("../../assets/bg1.png");
  background: #1e2c61;

  h3 {
    text-align: center;
    font-size: 0.9rem;
    line-height: 1.5rem;
    margin: 0;
  }

  p {
    display: flex;
    font-size: 0.9rem;
    line-height: 1.5rem;

    .title {
      width: 5rem;
    }

    .value {
      width: 6rem;
      text-align: right;
    }
  }
}

.map-angle {
  background-color: red;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.leaflet-popup-content-wrapper {
  background-color: #122040;
  text-align: center;
  border: 2px solid #5f7ec9;
}

.leaflet-popup-content > div:not(:last-child) {
  margin-bottom: 5px;
}

.leaflet-popup-tip-container {
  display: none;
}

</style>
