import getWujiangConfig from './wujiang';
import getFoShanTaiFu1Config from './foshan-taifu1';
import getHaikouNoTaxConfig from './haikou-notax';
import getBozhouSchool5Config from './bozhou-school5';
import getGuihuayuanConfig from './guihuayuan';
import getXianSubway15Config from './xian-subway-15';
import getXianSubway15HanZhongConfig from './xian-subway-15-hanzhong';
import getXianSubway15DongHanConfig from './xian-subway-15-donghan';
import getNanNongConfig from './nannong';
import getShengzeConfig from './shengze';
import getXianHejingConfig from './xian-hejing';
import getHengtaiCitizenConfig from './hengtai-citizen';
import getZheshangConfig from './zheshang';
import getQuzhouWentiConfig from './quzhou-wenti';
import getLanjianConfig from './lanjian';
import getDanzhouFuyouConfig from './danzhou-fuyou';
import getAndingBurningConfig from './anding-burning';
import getYibinShangwuConfig from './yibin-shangwu';
import getNanjingFoodConfig from './nanjing-food';
import getBeijingChengjianConfig from './beijing-chengjian';
import getAnzhenHospitalConfig from './anzhen-hospital';
import getGuangmingHospitalConfig from './guangming-hospital';
import getXionganSatNetConfig from './xiongan-satnet';

export const configMap = {
  '48146e7e-b027-4bf4-8520-0d935405b681': getWujiangConfig,
  'a96b6404-9b68-466b-b657-44a69897c6fc': getFoShanTaiFu1Config,
  '14321c38-45c6-4bd7-ad4a-131d71a4c955': getHaikouNoTaxConfig,
  'c348ce80-cbab-43fc-8700-1946df71643c': getBozhouSchool5Config,
  'e8e8b2c9-3960-426f-9736-1e351159c21a': getGuihuayuanConfig,
  'fb6cee7e-6ba5-4f71-8c6d-c052ac0ce63c': [getXianSubway15Config, getXianSubway15HanZhongConfig, getXianSubway15DongHanConfig],
  'b28fc4c8-b4f5-497f-842c-ccb15ea28a60': getNanNongConfig,
  '23e4b93c-7f12-443c-a8c0-b670465a16d5': getShengzeConfig,
  'a143b647-bf7f-40a5-ada5-ef3ef9aadf13': getXianHejingConfig,
  '8bbaa9ef-4e45-4a7f-9a71-e217da71fe6a': getHengtaiCitizenConfig,
  '32e6f8d4-4664-48ed-90fa-0c1de23399e6': getZheshangConfig,
  '9e937441-a8b1-41a7-b591-56f521eb61d2': getQuzhouWentiConfig,
  'fc31b3c4-4dec-43b7-ba88-d2613f8c8f6c': getLanjianConfig,
  '6b2dc5f5-11ed-11eb-9c52-98039b1586ea': getDanzhouFuyouConfig,
  'cbd38eaa-e835-4b1d-a57a-4640213bdf85': getAndingBurningConfig,
  '583eb291-5e69-4f8a-9aca-f309b61e6876': getYibinShangwuConfig,
  '4e1c8291-c2c2-48de-a253-70fff4cabcda': getNanjingFoodConfig,
  'b9c574df-d247-426d-9dbb-a9ee2aafe8a7': getBeijingChengjianConfig,
  'f00405f9-c0f1-45a3-ac0e-ec363b62a1f1': getAnzhenHospitalConfig,
  '0dcbf3cf-1a71-422e-b832-54e157934260': getGuangmingHospitalConfig,
  '591674bd-3c3a-4457-a795-92d3d182e15e': getXionganSatNetConfig,
};

export default function getConfig(projectId, data) {
  const configFunc = configMap[projectId];
  if (Array.isArray(configFunc)) {
    return configFunc.map(func => func(data || {}));
  } else if (configFunc) {
    return configMap[projectId](data || {});
  } else {
    return null;
  }
}
