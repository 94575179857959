import * as THREE from 'three';
import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    '生环学部楼': [-437.85109701704636,48.05450566066378, 350],
    '理学院': [-792.0913814275863,41.37493665064727, 350],
    '园艺学院': [-447.23675110242664,-380.02949033304344, 350],
    '植物保护学院': [-487.73131753996284,-794.2816953136149, 350],
    '交叉学科中心': [-43.8314311307956,-826.5400083014355, 570],
    '农学院': [311.4539513333417,-799.8926374181176, 350],
    '食品科学学院': [273.60557692081716,-382.7149291722985, 350],
    '动物学部楼': [274.6487328203174,55.38459187571698, 350],
    '展览馆': [620.3455302087634,383.08073316462014, 350],
  };

  const buildingBoundaries = {
    '生环学部楼': [[-618.9132561011324,227.28769624282458],[-235.11135139656534,229.54535450579257],[-234.3587986422427,-136.19528409503016],[-619.665808855455,-132.43252032341678]],
    '理学院': [[-935.0024112177198,211.470266373474],[-676.1242637307178,208.46005535618335],[-669.3512889418136,-133.95144786063634],[-940.2702804979784,-134.70400061495903]],
    '园艺学院': [[-619.6683360977718,-210.43920120346496],[-314.1319178427634,-214.95451772940112],[-315.63702335140886,-547.582835140026],[-618.9157833434492,-552.0981516659622]],
    '植物保护学院': [[-687.3996418759125,-645.9313182271856],[-305.85539543431344,-647.4364237358308],[-306.6079481886361,-943.1896561846443],[-686.6470891215898,-937.1692341500628]],
    '交叉学科中心': [[-291.5372981867307,-702.3463412306071],[100.54005558123778,-701.5925779899155],[100.54005558123778,-971.0064640374352],[-293.04503492952415,-972.5115695460804]],
    '农学院': [[121.63001965291801,-644.4204932584405],[467.80428664135104,-644.4204932584405],[466.2991811327058,-944.6890422331902],[121.63001965291804,-944.6890422331901]],
    '食品科学学院': [[132.9163376415658,-214.96595664960068],[393.2995906372133,-220.2338259298595],[394.80469614585866,-552.1095905861617],[140.44186518479262,-553.614696094807]],
    '动物学部楼': [[41.10504009322966,228.03977237547198],[425.65949755211955,224.27700860385866],[429.42226132373276,-131.68044419076932],[40.35248733890705,-136.19576071670537]],
    '展览馆': [[405.9427514991761,464.06517608498405],[822.1250657031951,464.06517608498405],[822.796327500298,302.9623447802026],[405.9427514991761,303.63360657730595]],
  };

  const buildingFinishedHeights = {
    '生环学部楼': 45.3,
    '理学院': 0,
    '园艺学院': 45.3,
    '植物保护学院': 0,
    '交叉学科中心': 0,
    '农学院': 0,
    '食品科学学院': 0,
    '动物学部楼': 0,
    '展览馆': 0,
  };

  const doneFloors = {
    '生环学部楼': 2,
    '理学院': 0,
    '园艺学院': 2,
    '植物保护学院': 0,
    '交叉学科中心': 0,
    '农学院': 0,
    '食品科学学院': 0,
    '动物学部楼': 0,
    '展览馆': 0,
  };

  const traceViews = {
    '_': {
      center: [810.1544449703002, -1938.3979965610804],
      pitch: 35,
      zoom: 1.63,
    },
    '生环学部楼': {
      center: [-9902.282838515157, 5709.744787866835],
      pitch: 84.0,
      zoom: 2.54,
      bearing: -55.6,
    },
    '理学院': {
      center: [-8681.389759698495, 10351.621440818766],
      pitch: 84.8,
      zoom: 2.54,
      bearing: -14.8,
    },
    '园艺学院': {
      center: [-9805.737476352944, 1899.89120466831],
      pitch: 84.0,
      zoom: 2.69,
      bearing: -55.6,
    },
    '植物保护学院': {
      center: [-9162.746382368608, 5072.756807744775],
      pitch: 84.4,
      zoom: 2.20,
      bearing: -26.8,
    },
    '交叉学科中心': {
      center: [-10802.267408283427, 6858.395113975391],
      pitch: 84.4,
      zoom: 1.89,
      bearing: -39.4,
    },
    '农学院': {
      center: [-3096.716675269737, 1129.4213891682457],
      pitch: 84.4,
      zoom: 2.54,
      bearing: -39.4,
    },
    '食品科学学院': {
      center: [-1556.919849339881, 1254.7680854842615],
      pitch: 80.0,
      zoom: 2.93,
      bearing: -44.8,
    },
    '动物学部楼': {
      center: [-2860.2259734398285, 5381.090225784156],
      pitch: 79.6,
      zoom: 2.69,
      bearing: -49,
    },
    '展览馆': {
      center: [1536.4545304989947, 9132.806249013],
      pitch: 80.0,
      zoom: 2.69,
      bearing: -35.2,
    },
  };

  function getUserZValue(user) {
    return (user.floor == 'ground' ? 0 : user.height) * 4.318 + 5;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 4.318;
  }

  function getZValueFromHeight(height) {
    return height * 4.318;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    if (window.beaconMode) {
      let geo = new THREE.BoxGeometry(2400, 2400, 2);
      let cube = new THREE.Mesh(geo, new THREE.MeshBasicMaterial({ color: 0xffffff }));
      cube.position.x = 0;
      cube.position.y = 0;
      cube.position.z = -1;
      scene.add(cube);
      window.extraObjects = [cube];
    }

    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/nannong2.glb',
    modelType: 'gltf',
    modelSubType: 'meshopt',

    fullOpacity: 0.85,
    revealOpacity: 0.15,
    deepRevealOpacity: 0,

    modelOptions: {
      scale: 4.2,
      rotZ: 0,
      moveX: -1220,
      moveY: -2850,
      moveZ: -23.15,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: 0,
      z: 0
    },

    lastBldgSwitchIndicesFile: '/nannong.json',
    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
