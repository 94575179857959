<template>
  <div id="left">
    <employeeCostChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <emolumentConditionChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" :org-options="orgOptions" />
    <emolumentConditionOfStaffLevelChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
  </div>
</template>

<script>
  import employeeCostChart from "./employeeCostChart";
  import emolumentConditionChart from "./emolumentConditionChart";
  import emolumentConditionOfStaffLevelChart from "./emolumentConditionOfStaffLevelChart";

  export default {
    name: 'HrIndexLeftBar',
    components: {
      employeeCostChart,
      emolumentConditionChart,
      emolumentConditionOfStaffLevelChart,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
  }
</script>

<style lang="less">
</style>
