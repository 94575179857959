<template>
  <div id="right">
    <wrapper style="padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display: flex;align-items:center;width: 85%;justify-content: space-between;"
        >
          <div>安全巡检率</div>

          <div
            class="detail"
            @click="showDetail(1)"
          >
            详情
          </div>
        </div>
        <div style="height:15rem;padding-top:3rem;display: flex; align-items: center;">
          <div
            v-if="companyTroublePieChart.series && [].concat.apply([], companyTroublePieChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 24rem; height: 12rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart
            v-else
            :options="companyTroublePieChart"
            autoresize
          />
        </div>
      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem;padding:0">

      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>带班检查情况</div>
          <div
            class="detail"
            @click="showDetail(2)"
          >
            详情
          </div>
        </div>
        <div style="height:15rem;padding-top:3rem;display: flex; align-items: center;">
          <div
            v-if="todayNewTroubleBarChart.series && [].concat.apply([], todayNewTroubleBarChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 24rem; height: 12rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>

          <v-chart
            v-else
            :options="todayNewTroubleBarChart"
            autoresize
          />
        </div>
      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem;padding:0">

      <div
        class="info-block"
        style="min-height:5rem"
      >
        <div
          class="info-block-title"
          style="display: flex;justify-content: space-between;align-items:center;width: 90%;"
        >
          <div>一级隐患点TOP10</div>

        </div>
        <div style="height:22rem;padding:3rem 1rem 0;display: flex; align-items: center;">
          <div
            v-if="!noActionTroubleBarChartList.length"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 24rem; height: 12rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <div
            v-else
            style="height: 100%;width:100%;line-height:1.6rem;font-size:0.9rem;color:#abbbbf;font-weight:bold;overflow:scroll;"
          >

            <div
              v-for="(project,index) in noActionTroubleBarChartList"
              :key="index"
              style="height:1.8rem;display:flex;justify-content:space-between"
            >
              <div :title="project.name">{{project.name.length>22?project.name.slice(0,22)+'...':project.name}}</div>
              <div style="margin-left:0.5rem;color:#32B7C0;">{{project.count}}</div>
            </div>
          </div>
        </div>
      </div>
    </wrapper>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="90rem"
      top="12rem"
      @closed="cleanupPopup"
    >
      <wrapper style="height:42rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow = false"
        />
        <div class="attendance">
          <div class="attendance-top">
            <div class="tabbar">
              <div
                :class="{ 'tabar-select': popupSelect == '1' }"
                @click="getAttendance('1')"
              >按月</div>
              <div
                :class="{ 'tabar-select': popupSelect == '2' }"
                @click="getAttendance('2')"
              >按周</div>
              <div
                :class="{ 'tabar-select': popupSelect == '3' }"
                @click="getAttendance('3')"
              >按日</div>
            </div>

            <div style="height:16rem;padding-top:1rem">
              <v-chart
                :options="attenceWorkersBarChart"
                @click="attenceWorkersBarChartClick"
                autoresize
              />
            </div>
          </div>
          <div style="margin-top:0.5rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName}}</div>
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:12rem;text-align:center">检查人</div>

              <div style="width:12rem;text-align:center">检查次数</div>
              <div style="width:12rem;text-align:center">整改率</div>
            </div>
            <div style="height:12rem;overflow:auto;">
              <div
                v-for="(item, index) in popupData"
                :key="item.person_id"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
                @click="getDetail(item)"
              >
                <div style="width:12rem;text-align:center">{{item.name}}</div>
                <div style="width:12rem;text-align:center">
                  {{item.count}}
                </div>
                <div style="width:12rem;text-align:center">{{item.rate}}</div>
              </div>
              <div
                v-if="!(popupData && popupData.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="securityStarPage"
              :page-count="securityStarPages"
              @current-change="securityStarPageChange"
              style="text-align:center;margin-top: 0.5rem;"
            ></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="50rem"
      top="22rem"
      @closed="cleanupPopup2"
    >
      <wrapper style="height:22rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow2 = false"
        />
        <div class="attendance">
          <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName2}}</div>

          <div style="margin-top:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:10rem;text-align:center">检查时间</div>
              <div style="width:10rem;text-align:center">岗位</div>
              <div style="width:5rem;text-align:center">隐患数</div>
              <div style="width:5rem;text-align:center">整改率</div>
            </div>
            <div style="height:12rem;overflow:auto;">
              <div
                v-for="(item, index) in popupData3"
                :key="index"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:10rem;text-align:center">
                  {{item.check_date}}
                </div>
                <div style="width:10rem;text-align:center">{{item.position_name}}</div>
                <div style="width:5rem;text-align:center;">{{item.count}}</div>
                <div style="width:5rem;text-align:center">{{item.rate}}</div>
              </div>
              <div
                v-if="!(popupData3 && popupData3.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="securityStarPage3"
              :page-count="securityStarPages3"
              @current-change="securityStarPageChange3"
              style="text-align:center;margin-top: 0.5rem;"
            ></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>

  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import wrapper from "../../components/wrap";

import { project } from "../../api";
import { SimpleBarChartTemplate } from "./barChartTemplates";
import { applyUpdate } from "../../utils";
// import { singleLineChartTemplate } from './lineChartTemplates';

export default {
  name: "SecurityCheckRightBar",
  components: {
    "v-chart": ECharts,
    wrapper,
  },
  data() {
    return {
      projectId: null,

      companyTroublePieChart: {},
      option: {
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0}: {c0}",
        },
        color: ["#4191e5", "#1bd080"],
        grid: {
          contentLabel: true,
          left: "12%",
          right: "6%",
          bottom: "15%",
          top: "18%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              rotate: 35,
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            max: 100,
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
            show: true,
            name: "巡检率:%",
          },
        ],
        series: [
          {
            name: "巡检率",
            type: "bar",
            barWidth: 10,
            data: [],
            markLine: {
              symbol: ["none", "none"], //去掉箭头
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "dashed",
                    color: "#238778",
                  },
                  label: {
                    show: false,
                    position: "middle",
                  },
                },
              },
              data: [
                {
                  yAxis: -1, //这里设置false是隐藏不了的，可以设置为-1
                },
              ],
            },
          },
        ],
      },

      todayNewTroubleBarChart: {},
      option2: {
        color: ["#fee21d", "#4191e5"],
        grid: {
          contentLabel: true,
          left: "12%",
          bottom: "18%",
          // top: "15%",
          right: "10%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        tooltip: {
          trigger: "axis",
          // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
          formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                  result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + params[0].data;
                  if(params[1]){
                      result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                  }
                  
              }
              return result;
            }
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              rotate: 35,
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
            show: true,
            name: "平均次数",
          },
          {
            name: "整改率：%",
            show: true,
            min: 0,
            max: 100,
            interval: 25,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        series: [
          {
            name: "数量",
            type: "bar",
            barWidth: 10,
            data: [],
          },
          {
            name: "数量",
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                },
              },
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(27, 208, 128, 0.3)" },
                { offset: 1, color: "rgba(27, 208, 128, 0)" },
              ]),
            },
            symbolSize: 0,
            yAxisIndex: 1,
            data: [],
          },
        ],
      },
      popupShow: false,
      popupShow2: false,

      attenceWorkersBarChart: {},
      option3: {
        color: ["#23CF9E", "#14B0BB"],
        grid: {
          contentLabel: true,
          right: "10%",
          top: "13%",
          left: "15%",
          bottom: "15%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        tooltip: {
          trigger: "axis",
          // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}%",
          
            formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                  result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + (params[0].data.value?params[0].data.value:params[0].data);
                  if(params[1]){
                      result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                  }
                  
              }
              return result;
            }
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              // rotate: 35
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            name: "平均次数",
            nameTextStyle: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
            show: true,
          },
          {
            show: true,
            name: "整改率：%",
            min: 0,
            max: 100,
            interval: 25,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              // formatter: function (value) {
              //   return value ? value + "%" : value;
              // },
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        series: [
          {
            name: "数量",
            type: "bar",
            barWidth: 10,
            data: [],
          },
          {
            name: "数量",
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                },
              },
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(27, 208, 128, 0.3)" },
                { offset: 1, color: "rgba(27, 208, 128, 0)" },
              ]),
            },
            symbolSize: 0,
            yAxisIndex: 1,
            data: [],
          },
        ],
      },
      popupData: [],
      popupSearchkeys: [],
      securityStarPage: 1,
      securityStarPages: 1,
      securityStarPage3: 1,
      securityStarPages3: 1,
      dataIndex: -1,
      popupData3: [],
      popupSelect: "1",
      person_id: "",
      popupBottomName: "",
      popupBottomName2: "",

      noActionTroubleBarChartList: [],
    };
  },
  mounted() {
    const { id: projectID } = this.$route.params;
    this.projectId = projectID;
    const token = this.$route.query.token;
    window.token = token;
    this.getData();
  },
  methods: {
    getData() {
      this.getChart1();
      this.getChart2();
      this.getList();
    },
    showDetail(type) {
      // console.log(type);
      if (type == 2) {
        // console.log("hhh");
        this.popupShow = true;
        this.getAttendance(1);
      }
    },
    getDetail(item) {
      this.popupShow2 = true;
      // console.log(item);
      this.person_id = item.person_id;
      // eslint-disable-next-line no-empty

      this.popupBottomName2 = `${item.name}-${
        this.popupXAxisData[this.dataIndex]
      }-日常检查项目情况`;
      this.getLists2();
    },
    getLists2() {
      // getSafetyHeadCheckManagerDetailCollect
      project
        .getSafetyHeadCheckManagerDetailCollect(
          this.projectId,
          this.popupSelect,
          this.popupSearchkeys[this.dataIndex],
          this.person_id,
          this.securityStarPage3,
          10
        )
        .then((d) => {
          // console.log(d);
          this.popupData3 = d;
          this.securityStarPages3 = Math.ceil(d.__count__ / 10);
        });
    },
    cleanupPopup2() {
      this.popupData3 = [];
    },
    securityStarPageChange3(currPage) {
      // console.log(currPage);
      this.securityStarPage3 = currPage;
      this.getLists2();
    },
    cleanupPopup() {
      this.attenceWorkersBarChart = {};
      this.popupData = [];
      this.dataIndex = -1;
      this.popupSearchkeys = [];
      this.popupXAxisData = [];
      this.popupBarData = [];
      this.head_name1 = "分公司";
      this.head_name2 = "分公司";
    },
    getAttendance(popupSelect) {
      this.popupSelect = popupSelect;
      project
        .getSafetyHeadCheckDateCollect(this.projectId, popupSelect)
        .then((d) => {
          // console.log(d);
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option3.legend.data = d.legend;
          this.option3.xAxis[0].data = d.xAxis_data;
          d.bar_series[d.bar_series.length - 1] = {
            value: d.bar_series[d.bar_series.length - 1],
            itemStyle: {
              color: "#FFD153",
            },
          };
          this.option3.series[0].data = d.bar_series;
          this.option3.series[0].name = d.legend[0];
          this.option3.series[1].data = d.line_series;
          this.option3.series[1].name = d.legend[1];
          this.attenceWorkersBarChart = applyUpdate(chart, this.option3);

          this.popupBottomName = `${
            this.option3.xAxis[0].data[this.option3.xAxis[0].data.length - 1]
          }检查详情`;

          this.getLaborAttendanceDateOrgCountCollect(
            d.search_keys[d.search_keys.length - 1]
          );
          this.dataIndex =d.search_keys.length - 1
          this.popupSearchkeys = d.search_keys;
          this.popupXAxisData = d.xAxis_data;
          this.popupBarData = d.bar_series;
        });
    },
    getLaborAttendanceDateOrgCountCollect(search_keys) {
      // window.$orgLevel = this.org_id;
      project
        .getSafetyHeadCheckCountCollect(
          this.projectId,
          this.popupSelect,
          search_keys,
          this.securityStarPage,
          10
        )
        .then((d) => {
          // console.log(d);
          this.popupData = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        });
    },
    securityStarPageChange(currPage) {
      // console.log(currPage);
      this.securityStarPage = currPage;
      this.getLaborAttendanceDateOrgCountCollect(
        this.popupSearchkeys[this.dataIndex]
      );
    },

    attenceWorkersBarChartClick(params) {
      // console.log(params);
      // console.log(params.dataIndex);
      let subData = this.popupBarData;
      this.attenceWorkersBarChart.series[0].data = subData.map(function (
        item,
        index
      ) {
        return index === params.dataIndex
          ? {
              value:
                Object.prototype.toString.call(item) === "[object Object]"
                  ? item.value
                  : item,
              itemStyle: {
                color: "#FFD153",
              },
            }
          : Object.prototype.toString.call(item) === "[object Object]"
          ? item.value
          : item;
      });

      // eslint-disable-next-line
      this.popupBottomName = `${
        this.popupXAxisData[params.dataIndex]
      }项目日常检查详情`;

      this.getLaborAttendanceDateOrgCountCollect(
        this.popupSearchkeys[params.dataIndex]
      );
      this.dataIndex = params.dataIndex;
    },

    getChart1() {
      project.getSafetyMonth12InspectionCollect(this.projectId).then((d) => {
        // console.log(d);
        const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
        this.option.xAxis[0].data = d.xAxis_data;
        this.option.series[0].data = d.series;
        if (d.config.theta) {
          this.option.series[0].markLine.data[0].yAxis = d.config.theta;
        }
        this.companyTroublePieChart = applyUpdate(chart, this.option);
      });
    },
    getChart2() {
      project.getSafetyHeadCheckCollect(this.projectId).then((d) => {
        const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
        this.option2.legend.data = d.legend;
        this.option2.xAxis[0].data = d.xAxis_data;
        this.option2.series[0].name = d.legend[0];
        this.option2.series[0].data = d.bar_series;
        this.option2.series[1].data = d.line_series;
        this.option2.series[1].name = d.legend[1];
        this.todayNewTroubleBarChart = applyUpdate(chart, this.option2);
      });
    },
    getList() {
      project.getSafetyTroubleItem10List(this.projectId, 1, 10).then((d) => {
        // console.log(d)
        this.noActionTroubleBarChartList = d;
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.text-green {
  color: #1fc86b !important;
}

.text-yellow1 {
  color: #f89132 !important;
  border-color: #f89132 !important;
}

.text-yellow2 {
  color: #eebc34 !important;
  border-color: #eebc34 !important;
}

.text-red1 {
  color: #e62c31 !important;
}

.text-red2 {
  color: #f34e53 !important;
}

.text-grey {
  color: #9ca0aa !important;
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}

#people-warning-list {
  .warning-item {
    margin-bottom: 0.75rem;
    padding: 8px 6px;
    font-size: 0.75rem;
    line-height: 1.5rem;
    background-color: #1e2c61;
    border-radius: 0.25rem;
    color: #ffffff;
    position: relative;
    display: flex;
    div.name {
      color: #999999;
      width: 2rem;
    }
    div.value {
      width: 3.7rem;
      padding-left: 0.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    time {
      background-color: #577acc;
      border-radius: 4px;
      padding: 5px;
    }
  }
}
</style>
