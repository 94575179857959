<template>
  <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title" style="display:flex;width:100%;padding-right:3.1rem">
      <div style="flex:1">危大工程巡检率</div>
      <div class="detail" style="cursor:pointer">项目</div>
    </div>
    <div style="height:19rem;padding-top:3rem">
      <div
        v-if="!dangerCheckRateChart.series || !dangerCheckRateChart.series[0].data.length"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart :options="dangerCheckRateChart" autoresize />
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { nation } from '../../../../api'
  import { SimpleBarChartTemplate } from "../../barChartTemplates";
  import { applyUpdate } from "../../../../utils";

  export default {
    name: 'DangerCheckRateChart',
    components: {
      'v-chart': ECharts,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerCheckRateChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerCheckRateChart();
        }
      }
    },
    data () {
      return {
        dangerCheckRateChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.updateSafetyDangerCheckRateChart();
    },
    methods: {
      updateSafetyDangerCheckRateChart() {
        nation.getSafetyDangerCheckRates()
          .then(d => {
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));

            const option = {
              color: ["#2ad156"],
              tooltip: {
                trigger: "axis",
                formatter: "{b}<br>{a0}: {c0}",
              },
              grid: {
                top: "15%",
                left: "3%",
                right: "5%",
                bottom: "20%",
                containLabel: true,
              },
              xAxis: [
                {
                  type: "category",
                  data: [],
                  axisLabel: {
                    color: "#e6f2f3",
                    rotate: 45,
                    fontWeight: "normal",
                    formatter: function (value) {
                      return value.length > 8 ? value.slice(0, 8) + "..." : value;
                    },
                  },
                },
              ],
              yAxis: [
                {
                  show: true,
                  name: "巡检率：%",
                  type: "value",
                  max: 100,
                  nameTextStyle: {
                    padding: [0, 40, 0, 0],
                    color: "#e6f2f3",
                    fontWeight: "bold",
                    align: "left",
                  },
                  boundaryGap: [0, "20%"],
                  axisLabel: {
                    color: "#e6f2f3",
                    fontWeight: "bold",
                  },
                  axisLine: { show: true, lineStyle: { color: "#36445f" } },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: "#36445f",
                      width: 1,
                      type: "solid",
                    },
                  },
                  axisTick: { show: false },
                },
              ],
              series: [
                {
                  name: "巡检率",
                  type: "bar",
                  barWidth: 10,
                  barMaxWidth: 18,
                  markLine:{
                    symbol: ["none", "none"],
                    itemStyle: {
                      normal: {
                        lineStyle: {
                          type: "dashed",
                          color: "#238778",
                        },
                        label: {
                          show: false,
                          position: "middle",
                        },
                      },
                    },
                    data: [{}],
                  }
                }
              ],
            };

            option.xAxis[0].data = d.xAxis_data;
            option.series[0].data = d.series_data;
            option.series[0].markLine.data[0].yAxis = d.config.theta;
            this.dangerCheckRateChart = applyUpdate(chart, option);
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
    },
  }
</script>

<style lang="less">
</style>
