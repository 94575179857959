<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ selected }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option;
          open = false;
          $emit('input', option);
        "
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.value
        ? this.value
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
    };
  },
  watch: {
    value: function(newVal, oldVal) {
      if (newVal != oldVal && newVal != this.selected) {
        this.selected = newVal;
      }
    }
  },
  mounted() {
    this.$emit("input", this.selected);
  },
};
</script>

<style scoped>

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 28px;
  line-height: 28px;
}

.custom-select .selected {
  background-color: #2a4278;
  border: 1px solid #334c88;
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .selected.open {
  border: 1px solid #5e7ec9;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 12px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.custom-select .items {
  color: #fff;
  overflow: hidden;
  border-right: 1px solid #5e7ec9;
  border-left: 1px solid #5e7ec9;
  border-bottom: 1px solid #5e7ec9;
  position: absolute;
  background-color: #2a4278;
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
}

.custom-select .items div {
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #5e7ec9;
}

.selectHide {
  display: none ! important;
}
</style>
