<template>
  <div id="left">
    <div class="info-block" style="min-height:5rem">
      <div class="info-block-title" style="display:flex;align-items:center;justify-content: space-between;width: 85%;">
        <div>产值统计</div>
        <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            :class="{ 'option-block-selected2': mode == 1 }"
            @click="() => getChart(1)"
          >总</div>
          <div
            class="option-block2"
            :class="{ 'option-block-selected2': mode == 2 }"
            @click="() => getChart(2)"
          >人均</div>
          <div
            class="detail"
            style="margin-left: 0.5rem;"
             @click="showDetail(1)"
          >
            详情
          </div>
        </div>
      </div>
      <div style="height:19rem;padding-top:3rem">
        <div
          v-if="companyWorkersBarChart.series && [].concat.apply([], companyWorkersBarChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyWorkersBarChart" autoresize />
      </div>
    </div>
    <div class="info-block" style="min-height:5rem">
      <div class="info-block-title" style="display:flex;align-items:center;justify-content:space-between;width: 85%;">
        <div>临近竣工项目</div>
        <div class="detail" @click="showDetail(2)">
          详情
        </div>
      </div>
      <div v-if="attendanceBarChart.length" style="position:absolute;margin-top:3.2rem;font-size:12px;color:#fff;padding-left:20px;">剩余天数：天</div>
      <div style="height:19rem;padding-top:5rem;">
        <!-- <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"><CustomSelect :options="companyNameList" @input="companySelect" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold" /></div> -->
        <div
          v-if="!attendanceBarChart.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <div v-else style="height:100%;overflow-y:auto">
          <div class="linebar" v-for="(item, index) in attendanceBarChart" :key="index">
            <div style="color: #A7B6BC;width: 180px">
              {{item.name}}
            </div>
            <div style="flex: 5" class="line-bar">
              <div class="real-bar" :style="{'width': (Math.min(item.value / 365, 1) * 100) + '%' }"></div>
            </div>
            <div style="color: #57C7B6;max-width:40px">
              {{item.value}}天
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
      <div class="info-block-title" style="display:flex;align-items:center;justify-content: space-between;width: 85%;">
        <div>工期延误项目数</div>
        <!-- <div class="detail" @click="showDetail(3)">
          详情
        </div> -->
      </div>
      <div v-if="avgAttendanceTopLists.length" style="position:absolute;margin-top:3.2rem;font-size:12px;color:#fff;padding-left:20px;">项目数：个</div>
      <div style="height:19rem;padding-top:5rem">
        <!-- <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"><CustomSelect :options="companyNameList" @input="companySelect" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold" /></div> -->
        <div style="height:100%;overflow-y:auto">
        <div
          v-if="!avgAttendanceTopLists.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
          <div v-else class="linebar" v-for="(item, index) in avgAttendanceTopLists" :key="index" @click="avgAttendanceTopListsClick(item, index)">
            <div style="color: #A7B6BC;" :style="{'width': districtScale === 2 ? '180px':'100px'}">
              {{item.name}}
            </div>
            <div style="flex: 5" class="line-bar">
              <div class="real-bar" :style="{'width': (item.value / avgAttendanceMax * 100) + '%' }" style="background-color: #87D55D;cursor: pointer;"></div>
            </div>
            <div style="color: #87D55D;width:20px">
              {{item.value}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="70rem"
      top="12rem"
    >
      <wrapper style="min-height:18rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow2 = false"
        />
        <div class="attendance">
          <div v-if="districtScale < 2" class="attendance-top" style="flex：1">

            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">各{{(districtScale == 1 || orgType === 2) ? '城市' : '分公司'}}临近竣工项目数</div>
            <div style="height:16rem;padding-top:1rem;">
              <v-chart :options="attenceWorkersBarChart" autoresize />
            </div>
          </div>
          <div class="attendance-bottom" style="flex：1">
            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">临近竣工项目</div>
            <div style="height: 260px; overflow-y: auto;">
              <div class="linebar" v-for="(item, index) in this.popupData2" :key="index">
              <div style="color: #A7B6BC;width: 300px">
                {{item.name}}
              </div>
              <div style="flex: 5;margin-right: 10px;" class="line-bar">
                <div class="real-bar" :style="{'width': (item.value/10) + '%' }" style="background-color: #22D3EE"></div>
              </div>
              <div style="color: #22D3EE">
                {{item.value}}天
              </div>
            </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="60rem"
      top="15rem"
    >
      <wrapper style="height:28rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle}}
            </div>

          </div>
          <div style="display:flex; align-items: center;margin-top:1rem;justify-content: space-between;">
            <div></div>
            <div>
            </div>

            <div style="font-size:12px;color:#fff;">延误天数：天</div>
          </div>
          <!-- <div style="border:1px solid #112563;margin:1.5rem 1rem"> -->
          <div style="height:20rem;padding:1rem 0 0 1rem">
            <div style="height:100%;overflow-y:scroll">
              <div
                v-for="(item, index) in avgAttendanceTopItemTable"
                :key="index"
              >

                <div style="color: #A7B6BC;">
                  {{item.name}}
                </div>
                <div class="linebar" style="width:100%">

                  <div
                    style="flex: 5"
                    class="line-bar"
                  >
                    <div
                      class="real-bar"
                      :style="{'width': (item.value / avgAttendanceMax2 * 100) + '%' }"  style="background-color: #F98100"
                    ></div>

                  </div>

                  <div style="color: #57C7B6;width:40px">
                    {{item.value}}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow3"
      width="90rem"
      top="10rem"
      :destroy-on-close='true'
    >
      <wrapper style="height:47rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow3 = false"
        />
        <div class="attendance">
          <div class="attendance-top">
            <div class="tabbar">
              <div :class="{ 'tabar-select': popupSelect == 1 }" @click="getAttendance(1)">按季</div>
              <div :class="{ 'tabar-select': popupSelect == 2 }" @click="getAttendance(2)">按月</div>
            </div>
            <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem;">
              <CustomSelect :options="companyNameList" :value="selectVal1" @input="companySelect($event, 'val1')" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;" />
            </div>
            <div style="border: 1px solid #13296B;padding: 1rem 0.5rem;">
              <div style="margin:0 0 0 1rem;font-size:18px;font-weight:bold">产值统计详情</div>
              <div style="height:18rem;">
                <v-chart :options="attenceWorkersBarChart2" @click="attenceWorkersBarChart2Click" autoresize />
              </div>
            </div>  
          </div>
          <div style="margin:0.5rem 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupTitle2}}</div>
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:12rem;text-align:center">{{mapLevel==2?'项目':(selectVal1=='全部分公司'?'分公司':'项目')}}</div>
              <div style="width:12rem;text-align:center">总产值</div>
              <div style="width:12rem;text-align:center">预计产值</div>
              <div style="width:12rem;text-align:center">人均产值</div>
            </div>
            <div style="height:10rem;overflow:auto;">
              <div
                v-for="(item, index) in popupData"
                :key="item.name"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;cursor: pointer;"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:12rem;text-align:center">{{item.name}}</div>
                <div style="width:12rem;text-align:center">
                  {{item.total_count}}
                </div>
                <div style="width:12rem;text-align:center">{{item.plan_count}}</div>
                <div style="width:12rem;text-align:center">{{item.avg_count}}</div>
              </div>
              <div
                v-if="!(popupData && popupData.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无产值数据
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="securityStarPage"
              :page-count="securityStarPages"
              @current-change="securityStarPageChange"
              style="text-align:center;margin-top: 1rem;"
            ></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { nation } from '../../api'
  import CustomSelect from '../../components/select'
  import { SimpleBarChartTemplate }  from './barChartTemplates';
  import { applyUpdate, truncateStr } from "../../utils";
  import wrapper from '../../components/wrap';

  export default {
    name: 'ScheduleLeftBar',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
      wrapper
    },
    props: {
      districtLevel: String,
      orgLevel: String,
      districtScale: Number,
      orgType: Number,
      orgOptions: Array,
    },
    data () {
      return {
        companyNameList: ['全部分公司'],
        companyIdList: [''],
        companyWorkersData: {},
        companyWorkersOption: 'org_workerNumber_list',
        companyWorkersBarChart: {},
        mode: 1,
        option: {
          tooltip: {
            trigger: 'axis',
            // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
            formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                  result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + (params[0].data.value?params[0].data.value:params[0].data);
                  if(params[1]){
                      result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                  }
                  
              }
              return result;
            }
          },
          color: ['#20BBEE', '#FF9E05'],
          grid: {
            contentLabel: true,
            left: '12%',
            right: '6%',
            bottom:" 15%",
            top: "13%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              name: '产值:kw',
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true
            },
            // {
            //   name: '产值:kw',
            //   min: 0,
            //   max: 100,
            //   interval: 25,
            //   nameTextStyle: {
            //     color: '#e6f2f3',
            //     fontWeight: 'bold',
            //   },
            //   boundaryGap: [0, '20%'],
            //   axisLabel: {
            //     color: '#e6f2f3',
            //     fontWeight: 'bold',
            //     formatter: function(value) {
            //       return value ? value + '%' : value;
            //     },
            //   },
            //   axisLine: { show: true, lineStyle: { color: '#36445f' } },
            //   splitLine: { show: false },
            //   axisTick: { show: false },
            //   type: 'value',
            //   show: false
            // }
          ],
          series: [
            {
              name: '',
              type: 'bar',
              barWidth: 10,
              data: []
            },
            {
              name: '',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              // symbolSize: 0,
              // yAxisIndex: 1,
              data: [],
            }
          ]
        },
        attendanceBarChart: [],
        avgAttendanceTopLists: [],
        avgAttendanceMax: 0,
        avgAttendanceTopListOption: 'project_avg_h_top',
        popupShow2: false,
        attenceWorkersBarChart: {},
        popupData2: [],
        option2: {
          color: [
            '#1ADDE3'
          ],
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            left: '3%',
            right: '4%',
            top: '15%',
            bottom: '15%',
            containLabel: true
          },
          yAxis: [{
            name: '数量',
            nameTextStyle: {
              color: '#fff'
            },
            type: 'value',
            show: true,
            minInterval: 1,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: [5, 10],
                color: '#14253f',
                width: 2,
              }
            },
            axisTick: { show: false },
            axisLabel: {
              color: '#e6f2f3',
              fontSize: 14,
              fontWeight: 'bold',
              // formatter: function(value) {
              //   return value ? value + '%' : value;
              // }
            }
          }],
          xAxis: {
            type: 'category',
            data: [],
            boundaryGap: true,
            axisLabel: {
              color: '#e6f2f3',
              fontSize: 14,
              fontWeight: 'bold',
              rotate: 30,
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#22344b',
              }
            },
          },
          series: [{
            name: '数量',
            type: 'line',
            barWidth: 10,
            data: [],
          }]
        },
        
        mapLevel: 0,
        popupShow: false,
        popupTitle: "",
        avgAttendanceTopItemTable: [],
        avgAttendanceMax2: 0,
        search_keys:[],
        activeIndex:0,
        popupShow3: false,
        popupSelect: 1,
        attenceWorkersBarChart2: {},
        option3: {
          tooltip: {
            trigger: 'axis',
            // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
            formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                  result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + (params[0].data.value?params[0].data.value:params[0].data);
                  if(params[1]){
                    result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                  }
                  if(params[2]){
                    result += "</br>"+params[2].marker + " " + params[2].seriesName + " : " + params[2].data;
                  }
              }
              return result;
            }
          },
          color: ['#4CCF9D', '#1AE3E8', '#18A9EB'],
          grid: {
            contentLabel: true,
            left: '5%',
            right: '5%',
            bottom:"22%",
            top: "13%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              name: '产值:kw',
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true
            }
          ],
          series: [
            {
              name: '',
              type: 'bar',
              barWidth: 10,
              data: []
            },
            {
              name: '',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              // symbolSize: 0,
              // yAxisIndex: 1,
              data: [],
            },
            {
              name: '',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              // symbolSize: 0,
              // yAxisIndex: 1,
              data: [],
            }
          ]
        },
        search_keys_new: [],
        selectVal1: '全部分公司',
        popupTitle2: '',
        activeIndex2: 0,
        popupData: [],
        securityStarPage: 0,
        securityStarPages: 0,
        popupXAxisData: [],
        activeIndex_new: 0,
        bar_series: []
      }
    },
    watch: {
      orgOptions(newValue, oldValue) {
        if (newValue != oldValue) {
          this.companyNameList = newValue.map(item => truncateStr(item.org_name, 10));
          this.companyIdList = newValue.map(item => item.org_id);
        }
      },
      orgLevel: function (newValue) {
        if (newValue) {
          this.getAll();
        }
      },
      districtLevel: function (newValue) {
        if (newValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
          this.getAll();
        }
      },
    districtScale: function (newValue) {
      if (newValue) {
        this.mapLevel = newValue;
      }
    },
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.companyNameList = this.orgOptions.map(item => truncateStr(item.org_name, 10));
      this.companyIdList = this.orgOptions.map(item => item.org_id);

      this.getAll();
    },
    methods: {
      avgAttendanceTopListsClick(item, index){
        if(this.mapLevel!==2){
          // console.log(item)
          this.popupShow = true;
          this.popupTitle = item.name + "工期延误项目";
          this.activeIndex=index;
          nation.getProjectDelayedDaysCollect(1, 100000,this.mapLevel==0&&this.orgType == 1?this.search_keys[this.activeIndex]:this.orgLevel,this.mapLevel==1||this.orgType !== 1?this.search_keys[this.activeIndex]:'').then((d) => {
              // console.log(d);
              this.avgAttendanceTopItemTable = d.series.map((item, index) => {
                return { value: item, name: d.yAxis_data[index] };
              });
              this.avgAttendanceMax2 = Math.max.apply(
                null,
                this.avgAttendanceTopItemTable.map((item) => item.value)
              );
            });
        }
      },
      getAll(){
        this.getChart(this.mode);

        nation.getScheduleProjectNearEndCollect(6)
          .then(d => {
            // console.log(d)
            this.attendanceBarChart = d.series.map((item,index) => {
              return {value: item, name: d.yAxis_data[index]};
            });
          })

        nation.getScheduleProjectDelayedCountCollect()
          .then(d => {
            // console.log(d);
            this.avgAttendanceTopLists = d.series.map((item,index) => {
              return {value: item, name: d.yAxis_data[index]};
            });
            this.search_keys = d.search_keys;
            this.avgAttendanceMax = Math.max.apply(null, this.avgAttendanceTopLists.map(item => item.value));
          })
      },
      getChart(mode){
        this.mode = mode;
        nation.getScheduleMonth12OutputCollect(mode)
          .then(d => {
            // console.log(d);
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option.legend.data = d.legend;
            this.option.xAxis[0].data = d.xAxis_data;
            this.option.series[0].data = d.bar_series;
            this.option.series[0].name = d.legend[1];
            this.option.series[1].data = d.line_series;
            this.option.series[1].name = d.legend[0];
            this.companyWorkersBarChart = applyUpdate(chart, this.option);
          })
      },
      companySelect(option, val) {
        const idx = this.companyNameList.indexOf(option);
        if (idx >= 0) {
          this.org_id = this.companyIdList[idx];
          if(val === 'val1'){
            this.securityStarPage=1;
            this.selectVal1 = option;
            this.getAttendance(this.popupSelect)
          }
        }
      },
      showDetail(val){
        // console.log(val)
        // eslint-disable-next-line no-empty
        if(val === 1){
        // eslint-disable-next-line no-empty
          const idx = this.companyIdList.indexOf(this.orgLevel);
          this.selectVal1 = this.companyNameList[idx];
          this.org_id = this.orgLevel;
          this.popupShow3 = true;
          this.getAttendance(this.popupSelect)
        }else if(val === 2){
          this.popupShow2 = true;
          nation.getScheduleOrgCityNearEndCollect()
            .then(d => {
              // console.log(d);
              const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
              this.option2.xAxis.data = d.xAxis_data;
              this.option2.series[0].data = d.series;
              this.attenceWorkersBarChart = applyUpdate(chart, this.option2);
            })
          nation.getScheduleProjectNearEndCollect(1000000)
            .then(d => {
              // console.log(d);
              this.popupData2 = d.series.map((item,index) => {
                return {value: item, name: d.yAxis_data[index]};
              });
            })
        // eslint-disable-next-line no-empty
        }else {

        }
      },
      getAttendance(val){
        this.popupSelect = val;
        nation.getScheduleOutputDateCollect(1, 7, this.popupSelect, this.org_id ? this.org_id:'')
          .then(d => {
            // console.log(d);
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option3.legend.data = d.legend;
            this.option3.xAxis[0].data = d.xAxis_data;
            d.bar_series[d.bar_series.length-1] = {
              value: d.bar_series[d.bar_series.length-1],
              itemStyle: {
                color: '#FFD153'
              }
            };
            this.option3.series[0].data = d.bar_series;
            this.option3.series[0].name = d.legend[0];
            this.option3.series[1].data = d.line_series[0];
            this.option3.series[1].name = d.legend[1];
            this.option3.series[2].data = d.line_series[1];
            this.option3.series[2].name = d.legend[2];
            this.attenceWorkersBarChart2 = applyUpdate(chart, this.option3);
            this.search_keys_new = d.search_keys;
            this.popupTitle2 = d.xAxis_data[d.xAxis_data.length-1];
            this.popupXAxisData = d.xAxis_data;
            this.bar_series = d.bar_series;
            this.activeIndex_new = d.search_keys.length-1;
            this.getTableList(1, d.search_keys[d.search_keys.length-1])
          })
      },
      attenceWorkersBarChart2Click(params){
        // console.log(params);
        let subData = this.bar_series;
        this.attenceWorkersBarChart2.series[0].data = subData.map(function (item, index) {
          return index === params.dataIndex ? {
              value: Object.prototype.toString.call(item) === '[object Object]' ? item.value:item,
              itemStyle: {
                color: '#FFD153'
              }
            }:(Object.prototype.toString.call(item) === '[object Object]' ? item.value:item)
        });
        this.popupTitle2 = this.popupXAxisData[params.dataIndex];
        this.activeIndex_new = params.dataIndex;
        this.getTableList(1, this.search_keys_new[params.dataIndex]);
      },
      getTableList(currPage, search_keys){
        nation.getScheduleOutputOrgProjectCollect(currPage, 5, this.popupSelect, this.org_id ? this.org_id:'', search_keys)
          .then(d => {
            // console.log(d);
            this.popupData = d;
            this.securityStarPages = Math.ceil(d.__count__ / 5);
          })
      },
      securityStarPageChange(currPage) {
        this.getTableList(currPage, this.search_keys_new[this.activeIndex_new]);
      }
    },
  }
</script>

<style scoped lang="less">
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
.linebar{
  display: flex;
  align-items: center;
  margin: 3px auto 12px;
  font-size: 12px;
}
.line-bar{
  height: 5px;
  background-color: #0C1931;
  margin-right: 10px;
}
.real-bar{
  height: 5px;
  background-color: #57C7B6;
  border-radius: 12px;
}
.option-block2 {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3.4rem;
  border: 1px solid #1b8ebd;
  text-align: center;
  cursor: pointer;
}
.option-block-selected2 {
  background-color: #4191e5;
  color: #fff;
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>