<template>
  <div id="content">
    <div class="top-item">
      <div
        class="people-status-proj"
        style="margin-top:0rem"
      >
        <wrapper style="padding: 0;height:100%">
          <div style="min-height:5rem">

            <div
              class="point-name sure-click"
              v-for="point in videoList"
              :key="point.serial_number"
              @click="changePoint(point)"
            >
              {{point.video_name}}
            </div>
          </div>
        </wrapper>
      </div>

      <div class="big-content">
        <div
          v-show="divisionType==1"
          class="one-screen"
        >
          <div class="content-title">
            <div style="display:flex;">
              {{activePonit.video_name}}
            </div>

          </div>
          <div
            class="board"
            style="padding-top:3rem"
          >
            <div
              v-show="Object.keys(activePonit).length === 0"
              style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
              class="no-video"
            >
              <img
                src="../../assets/nation/no_video.png"
                alt=""
              >
            </div>

            <div
              v-show="Object.keys(activePonit).length !== 0">
              <webrtc-streamer
                v-if="activePonit.type==1"
                :key="activePonit && activePonit.version"
                class="streamer"
                id="stream1"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=830&bitrate=0&rotation=0"
                height="830"
                notitle="true"
                :tooltip="activePonit.video_name"
              ></webrtc-streamer>
              <div
                v-show="activePonit.type==2||activePonit.type==3"
                ref="video1"
                id="video1"
              ></div>
            </div>
          </div>

        </div>
        <div
          v-show="divisionType==4"
          class="more-screen"
        >

          <div class="four-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="videoList[securityStarPage * 4 - 4]"
            >
              <div style="display:flex;">
                {{videoList[securityStarPage * 4 - 4].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="videoList[securityStarPage * 4 - 4]"
                :key="videoList[securityStarPage * 4 - 4] && videoList[securityStarPage * 4 - 4].version"
                class="streamer"
                id="stream2"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=405&bitrate=0&rotation=0"
                height="405"
                notitle="true"
                :tooltip="videoList[securityStarPage * 4 - 4].video_name"
              ></webrtc-streamer>

              <div
                v-show="videoList[securityStarPage * 4 - 4]&&(videoList[securityStarPage * 4 - 4].type==2||videoList[securityStarPage * 4 - 4].type==3)"
                ref="video2"
                id="video2"
              ></div>
            </div>
          </div>
          <div class="four-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="videoList[securityStarPage * 4 - 3]"
            >
              <div style="display:flex;">
                {{videoList[securityStarPage * 4 - 3].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="videoList[securityStarPage * 4 - 3]"
                :key="videoList[securityStarPage * 4 - 3] && videoList[securityStarPage * 4 - 3].version"
                class="streamer"
                id="stream3"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=405&bitrate=0&rotation=0"
                height="405"
                notitle="true"
                :tooltip="videoList[securityStarPage * 4 - 3].video_name"
              ></webrtc-streamer>
              <div
                v-show="videoList[securityStarPage * 4 - 3]&&(videoList[securityStarPage * 4 - 3].type==2||videoList[securityStarPage * 4 - 3].type==3)"
                ref="video3"
                id="video3"
              ></div>

            </div>
          </div>
          <div class="four-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="videoList[securityStarPage * 4 - 2]"
            >
              <div style="display:flex;">
                {{videoList[securityStarPage * 4 - 2].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="videoList[securityStarPage * 4 - 2]"
                :key="videoList[securityStarPage * 4 - 2] && videoList[securityStarPage * 4 - 2].version"
                class="streamer"
                id="stream4"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=405&bitrate=0&rotation=0"
                height="405"
                notitle="true"
                :tooltip="videoList[securityStarPage * 4 - 2].video_name"
              ></webrtc-streamer>
              <div
                v-show="videoList[securityStarPage * 4 - 2]&&(videoList[securityStarPage * 4 - 2].type==2||videoList[securityStarPage * 4 - 2].type==3)"
                ref="video4"
                id="video4"
              ></div>

            </div>
          </div>
          <div class="four-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="videoList[securityStarPage * 4 - 1]"
            >
              <div style="display:flex;">
                {{videoList[securityStarPage * 4 - 1].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="videoList[securityStarPage * 4 - 1]"
                :key="videoList[securityStarPage * 4 - 1] && videoList[securityStarPage * 4 - 1].version"
                class="streamer"
                id="stream5"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=405&bitrate=0&rotation=0"
                height="405"
                notitle="true"
                :tooltip="videoList[securityStarPage * 4 - 4].video_name"
              ></webrtc-streamer>
              <div
                v-show="videoList[securityStarPage * 4 - 1]&&(videoList[securityStarPage * 4 - 1].type==2||videoList[securityStarPage * 4 - 1].type==3)"
                ref="video5"
                id="video5"
              ></div>

            </div>
          </div>

        </div>
        <div
          v-show="divisionType==9"
          class="more-screen"
        >

          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="videoList[securityStarPage * 9 - 9]"
            >
              <div style="display:flex;">
                {{videoList[securityStarPage * 9 - 9].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="videoList[securityStarPage * 9 - 9]&&videoList[securityStarPage * 9 - 9].type==1"
                :key="videoList[securityStarPage * 9 - 9] && videoList[securityStarPage * 9 - 9].version"
                class="streamer"
                id="stream6"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="videoList[securityStarPage * 9 - 9].video_name"
              ></webrtc-streamer>
              <div
                v-show="videoList[securityStarPage * 9 - 9]&&(videoList[securityStarPage * 9 - 9].type==2||videoList[securityStarPage * 9 - 9].type==3)"
                ref="video6"
                id="video6"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="videoList[securityStarPage * 9 - 8]"
            >
              <div style="display:flex;">
                {{videoList[securityStarPage * 9 - 8].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="videoList[securityStarPage * 9 - 8]&&videoList[securityStarPage * 9 - 8].type==1"
                :key="videoList[securityStarPage * 9 - 8] && videoList[securityStarPage * 9 - 8].version"
                class="streamer"
                id="stream7"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="videoList[securityStarPage * 9 - 8].video_name"
              ></webrtc-streamer>
              <div
                v-show="videoList[securityStarPage * 9 - 8]&&(videoList[securityStarPage * 9 - 8].type==2||videoList[securityStarPage * 9 - 8].type==3)"
                ref="video7"
                id="video7"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="videoList[securityStarPage * 9 - 7]"
            >
              <div style="display:flex;">
                {{videoList[securityStarPage * 9 - 7].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="videoList[securityStarPage * 9 - 7]&&videoList[securityStarPage * 9 - 7].type==1"
                :key="videoList[securityStarPage * 9 - 7] && videoList[securityStarPage * 9 - 7].version"
                class="streamer"
                id="stream8"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="videoList[securityStarPage * 9 - 7].video_name"
              ></webrtc-streamer>
              <div
                v-show="videoList[securityStarPage * 9 - 7]&&(videoList[securityStarPage * 9 - 7].type==2||videoList[securityStarPage * 9 - 7].type==3)"
                ref="video8"
                id="video8"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="videoList[securityStarPage * 9 - 6]"
            >
              <div style="display:flex;">
                {{videoList[securityStarPage * 9 - 6].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="videoList[securityStarPage * 9 - 6]&&videoList[securityStarPage * 9 - 6].type==1"
                :key="videoList[securityStarPage * 9 - 6] && videoList[securityStarPage * 9 - 6].version"
                class="streamer"
                id="stream9"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="videoList[securityStarPage * 9 - 6].video_name"
              ></webrtc-streamer>
              <div
                v-show="videoList[securityStarPage * 9 - 6]&&(videoList[securityStarPage * 9 - 6].type==2||videoList[securityStarPage * 9 - 6].type==3)"
                ref="video9"
                id="video9"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="videoList[securityStarPage * 9 - 5]"
            >
              <div style="display:flex;">
                {{videoList[securityStarPage * 9 - 5].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="videoList[securityStarPage * 9 - 5]&&videoList[securityStarPage * 9 - 5].type==1"
                :key="videoList[securityStarPage * 9 - 5] && videoList[securityStarPage * 9 - 5].version"
                class="streamer"
                id="stream10"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="videoList[securityStarPage * 9 - 5].video_name"
              ></webrtc-streamer>
              <div
                v-show="videoList[securityStarPage * 9 - 5]&&(videoList[securityStarPage * 9 - 5].type==2||videoList[securityStarPage * 9 - 5].type==3)"
                ref="video10"
                id="video10"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="videoList[securityStarPage * 9 - 4]"
            >
              <div style="display:flex;">
                {{videoList[securityStarPage * 9 - 4].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="videoList[securityStarPage * 9 - 4]&&videoList[securityStarPage * 9 - 4].type==1"
                :key="videoList[securityStarPage * 9 - 4] && videoList[securityStarPage * 9 - 4].version"
                class="streamer"
                id="stream11"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="videoList[securityStarPage * 9 - 4].video_name"
              ></webrtc-streamer>
              <div
                v-show="videoList[securityStarPage * 9 - 4]&&(videoList[securityStarPage * 9 - 4].type==2||videoList[securityStarPage * 9 - 4].type==3)"
                ref="video11"
                id="video11"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="videoList[securityStarPage * 9 - 3]"
            >
              <div style="display:flex;">
                {{videoList[securityStarPage * 9 - 3].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="videoList[securityStarPage * 9 - 3]&&videoList[securityStarPage * 9 - 3].type==1"
                :key="videoList[securityStarPage * 9 - 3] && videoList[securityStarPage * 9 -3].version"
                class="streamer"
                id="stream12"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="videoList[securityStarPage * 9 - 3].video_name"
              ></webrtc-streamer>
              <div
                v-show="videoList[securityStarPage * 9 - 3]&&(videoList[securityStarPage * 9 - 3].type==2||videoList[securityStarPage * 9 - 3].type==3)"
                ref="video12"
                id="video12"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="videoList[securityStarPage * 9 - 2]"
            >
              <div style="display:flex;">
                {{videoList[securityStarPage * 9 - 2].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="videoList[securityStarPage * 9 - 2]&&videoList[securityStarPage * 9 - 2].type==1"
                :key="videoList[securityStarPage * 9 - 2] && videoList[securityStarPage * 9 - 2].version"
                class="streamer"
                id="stream13"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="videoList[securityStarPage * 9 - 2].video_name"
              ></webrtc-streamer>
              <div
                v-show="videoList[securityStarPage * 9 - 2]&&(videoList[securityStarPage * 9 - 2].type==2||videoList[securityStarPage * 9 - 2].type==3)"
                ref="video13"
                id="video13"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="videoList[securityStarPage * 9 - 1]"
            >
              <div style="display:flex;">
                {{videoList[securityStarPage * 9 - 1].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="videoList[securityStarPage * 9 - 1]&&videoList[securityStarPage * 9 - 1].type==1"
                :key="videoList[securityStarPage * 9 - 1] && videoList[securityStarPage * 9 - 1].version"
                class="streamer"
                id="stream14"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="videoList[securityStarPage * 9 - 1].video_name"
              ></webrtc-streamer>
              <div
                v-show="videoList[securityStarPage * 9 - 1]&&(videoList[securityStarPage * 9 - 1].type==2||videoList[securityStarPage * 9 - 1].type==3)"
                ref="video14"
                id="video14"
              ></div>

            </div>
          </div>
        </div>

        <div
          class="screen-division"
          v-if="videoList.length"
        >
          <div
            class="division-item"
            :class="divisionType==1?'active-division-type':''"
            @click="changeDivisionType(1)"
          >单屏</div>
          <div
            class="division-item"
            :class="divisionType==4?'active-division-type':''"
            @click="changeDivisionType(4)"
          >四分屏</div>
          <div
            class="division-item"
            :class="divisionType==9?'active-division-type':''"
            @click="changeDivisionType(9)"
          >九分屏</div>
        </div>

        <el-pagination
          v-if="divisionType!=1"
          class="page-change"
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :current-page.sync="securityStarPage"
          :page-count="securityStarPages"
          @current-change="securityStarPageChange"
          style="text-align:center;margin-top:0.5rem"
        ></el-pagination>

      </div>
    </div>

  </div>
</template>

<script>
var self;
require("echarts-gl");
import wrapper from "../../components/wrap";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { project } from "../../api";
import hlsPlayer from "xgplayer-hls.js";
import FlvPlayer from "xgplayer-flv.js";
// import { cubeBarChartTemplate } from "./barChartTemplates";
// import { truncateStr } from "../../utils";

export default {
  name: "Video",
  components: {
    wrapper,
  },
  props: {
    broadcastDetails: Object,
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    gotoProject: Function
  },
  data() {
    return {
      projectName: "",
      videoList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      divisionType: 1,
      activePonit: {},

      securityStarPage: 1,
      securityStarPages: 1,

      activeType: "精益成本建造",

      //视频播放
      isPlay: false,
      player: [],

      testVideoPlayingInterval: null,
      inited: false,
      lastPageSwitched: new Date() / 1000,
      videoPlayingTestsDone: 0,
      nextVideoPlayingTestDelay: 0,
    };
  },
  mounted() {
    self = this;
    const token = this.$route.query.token;
    window.token = token;
    window.$districtLevel = "";
    this.videoPlayingTestsDone = 0;
    this.lastPageSwitched = new Date() / 1000;
    this.getData();
  },
  beforeDestroy() {
    this.disconnectFlv();
    this.disconnectStreams();
    if (this.testVideoPlayingInterval) {
      clearInterval(this.testVideoPlayingInterval);
    }
  },
  methods: {
    getData() {
      project
        .getVideoList()
        .then((d) => {
          d.forEach((item) => (item.version = 0));
          this.videoList = d;
          this.activePonit = d[0];
          this.updateVideos();
        })
        .catch((e) => console.error(e.msg || e.message));
    },

    changePoint(point) {
      this.activePonit = point;
      // 当项目下无视频点位时销毁视频，显示无视频点位图片
      if (this.divisionType == 1 && !this.videoList.length > 0) {
        return this.disconnectStreams();
      }
      //   测试
      this.divisionType = 1;
      // this.clearStatus();
      if (point.type == 1) {
        this.clearStatus();
      } else{
        this.disconnectFlv();
      }
      this.updateVideos();
    },

    changeDivisionType(val) {
      this.disconnectFlv();
      this.divisionType = val;
      this.securityStarPages = Math.ceil(
        this.videoList.length / this.divisionType
      );
      // this.clearStatus();
      this.securityStarPageChange(1);
      // this.updateVideos();
    },
    clearStatus() {
      this.videoPlayingTestsDone = 0;
      this.lastPageSwitched = new Date() / 1000;
      this.videoList = this.videoList.map((item) => {
        return {
          url: "",
          serial_number: item.serial_number,
          video_name: item.video_name,
          type: item.type,
        };
      });
      this.activePonit.url = "";
      this.inited = false;
    },

    securityStarPageChange(currPage) {
      this.securityStarPage = currPage;

      this.videoPlayingTestsDone = 0;
      this.lastPageSwitched = new Date() / 1000;
      this.disconnectFlv();
      this.updateVideos();
    },
    disconnectStreams() {
      // if (this.divisionType == 1) {
      customElements.whenDefined("webrtc-streamer").then(() => {
        let streamElement = document.getElementById("stream1");
        if (streamElement) {
          streamElement.disconnectStream();
        }
      });
      // } else if (this.divisionType == 4) {
      [0, 1, 2, 3].forEach((idx) => {
        customElements.whenDefined("webrtc-streamer").then(() => {
          let streamElement2 = document.getElementById("stream" + (idx + 2));
          if (streamElement2) {
            streamElement2.disconnectStream();
          }
        });
      });
      // } else if (this.divisionType == 9) {
      [0, 1, 2, 3, 4, 5, 6, 7, 8].forEach((idx) => {
        customElements.whenDefined("webrtc-streamer").then(() => {
          let streamElement3 = document.getElementById("stream" + (idx + 6));
          if (streamElement3) {
            streamElement3.disconnectStream();
          }
        });
      });
      // }
    },

    updateVideos() {
      if (this.testVideoPlayingInterval) {
        window.clearInterval(this.testVideoPlayingInterval);
        this.testVideoPlayingInterval = null;
      }
      if (this.inited) {
        this.disconnectStreams();
      } else {
        this.inited = true;
      }

      if (this.divisionType == 1) {
        const currPage = 0;

        if (this.activePonit.type == 1) {
          this.activePonit.currentTime = 0;
          this.activePonit.realTime = 0;
          this.playVideo(this.activePonit, 0, currPage);
          this.testVideoPlayingInterval = window.setInterval(() => {
            this.testVideoPlaying(currPage);
          }, 500);
        } else if (this.activePonit.type == 2) {
          this.initPlayer(this.activePonit.url, "video1");
        } else if (this.activePonit.type == 3) {
          this.inithlvPlayer(this.activePonit.url, "video1");
        }
      } else if (this.divisionType == 4) {
        const currPage = this.securityStarPage;
        this.videoList
          .slice(this.securityStarPage * 4 - 4, this.securityStarPage * 4)
          .forEach((camera, index) => {
            if (camera.type == 1) {
              camera.currentTime = 0;
              camera.realTime = 0;
              this.playVideo(camera, index + 1, currPage);
              this.testVideoPlayingInterval = window.setInterval(() => {
                this.testVideoPlaying(currPage);
              }, 500);
            } else if (camera.type == 2) {
              this.initPlayer(camera.url, "video" + (index + 2));
            }else if (camera.type == 3) {
              this.inithlvPlayer(camera.url, "video" + (index + 2));
            }
          });

      } else if (this.divisionType == 9) {
        const currPage = this.securityStarPage;
        this.videoList
          .slice(this.securityStarPage * 9 - 9, this.securityStarPage * 9)
          .forEach((camera, index) => {
            if (camera.type == 1) {
              camera.currentTime = 0;
              camera.realTime = 0;
              this.playVideo(camera, index + 5, currPage);
              this.testVideoPlayingInterval = window.setInterval(() => {
                this.testVideoPlaying(currPage);
              }, 500);
            } else if (camera.type == 2) {
              this.initPlayer(camera.url, "video" + (index + 6));
            }else if (camera.type == 3) {
              this.inithlvPlayer(camera.url, "video" + (index + 6));
            }
          });

      }
    },

    playVideo(camera, index, currPage) {
      // console.log(camera, index, currPage, "=22222=");
      customElements.whenDefined("webrtc-streamer").then(() => {
        let streamElement = document.getElementById("stream" + (index + 1));
        if (streamElement) {
          streamElement.shadowDOM
            .getElementById("video")
            .addEventListener("waiting", function () {
              if (camera.reloadOnWaiting) {
                camera.reloadOnWaiting = false;
                camera.url = null;
                self.playVideo(camera, index, currPage);
              }
            });
          streamElement.shadowDOM
            .getElementById("video")
            .addEventListener("playing", function () {
              camera.reloadOnWaiting = true;
            });
        }
      });
      if (camera.url) {
        // if (currPage !== this.securityStarPage) return;
        let streamElement = document.getElementById("stream" + (index + 1));
        let url = { video: camera.url, audio: undefined };
        let urlJson = JSON.stringify(url);
        if (streamElement.getAttribute("url") != urlJson) {
          streamElement.disconnectStream();
        } else {
          streamElement.setAttribute("url", null);
        }
        setTimeout(() => {
          streamElement.setAttribute("url", urlJson);
        }, 100);
      } else {
        project
          .getVideoUrl(camera.serial_number)
          .then((res) => {
            if (res.result && res.result.url) {
              customElements
                .whenDefined("webrtc-streamer")
                .then(() => {
                  //   if (currPage !== this.securityStarPage) return;
                  camera.url = res.result.url;
                  let streamElement = document.getElementById(
                    "stream" + (index + 1)
                  );
                  let url = { video: res.result.url, audio: undefined };
                  let urlJson = JSON.stringify(url);
                  if (streamElement.getAttribute("url") != urlJson) {
                    streamElement.disconnectStream();
                  } else {
                    streamElement.setAttribute("url", null);
                  }
                  setTimeout(() => {
                    streamElement.setAttribute("url", urlJson);
                  }, 100);
                })
                .catch((e) => {
                  console.log(
                    "webrtc-streamer webcomponent fails to initialize error:" +
                      e
                  );
                });
            }
          })
          .catch((e) => console.error(e.msg || e.message));
      }
    },

    testVideoPlaying(currPage) {
      //   if (currPage !== this.securityStarPage) return;

      let delay;
      if (this.videoPlayingTestsDone == 0) {
        this.nextVideoPlayingTestDelay = 0;
        delay = 5;
      } else if (this.videoPlayingTestsDone < 3) {
        delay = 5;
      } else if (this.videoPlayingTestsDone < 10) {
        delay = 10;
      } else {
        delay = 15;
      }

      let delayPassed = false;
      if (
        new Date() / 1000 - this.lastPageSwitched >=
        this.nextVideoPlayingTestDelay + delay
      ) {
        delayPassed = true;
        this.videoPlayingTestsDone++;
        this.nextVideoPlayingTestDelay =
          new Date() / 1000 - this.lastPageSwitched;
      }

      customElements.whenDefined("webrtc-streamer").then(() => {
        if (this.divisionType == 1) {
          //   if (currPage !== this.securityStarPage) return;
          let streamElement = document.getElementById("stream1");
          if (streamElement) {
            const videoElem = streamElement.shadowDOM.getElementById("video");
            const online =
              videoElem.readyState > 0 ||
              videoElem.getBoundingClientRect().height > 155;
            const stalled =
              online &&
              videoElem.currentTime == (this.activePonit.currentTime || 0) &&
              new Date() / 1000 - (this.activePonit.realTime || 8000000000) >=
                7;

            this.activePonit.online = online;
            if (
              !online ||
              stalled ||
              this.activePonit.currentTime != videoElem.currentTime
            ) {
              this.activePonit.currentTime = videoElem.currentTime;
              this.activePonit.realTime = new Date() / 1000;
            }

            if (stalled || (delayPassed && !online)) {
              this.activePonit.version++;

              if (!online && this.videoPlayingTestsDone >= 3) {
                this.activePonit.url = null;
              }
              this.activePonit = JSON.parse(JSON.stringify(this.activePonit));
              setTimeout(() => {
                this.playVideo(this.activePonit, 0, currPage);
              }, 1000);
            }
          }
        } else if (this.divisionType == 4) {
          [0, 1, 2, 3].forEach((idx) => {
            if (currPage !== this.securityStarPage) return;
            if (this.securityStarPage * 4 - 4 + idx < this.videoList.length) {
              let streamElement = document.getElementById("stream" + (idx + 2));
              if (streamElement) {
                const videoElem =
                  streamElement.shadowDOM.getElementById("video");
                const online =
                  videoElem.readyState > 0 ||
                  videoElem.getBoundingClientRect().height > 155;
                const stalled =
                  online &&
                  videoElem.currentTime ==
                    (this.videoList[this.securityStarPage * 4 - 4 + idx]
                      .currentTime || 0) &&
                  new Date() / 1000 -
                    (this.videoList[this.securityStarPage * 4 - 4 + idx]
                      .realTime || 8000000000) >=
                    7;

                this.videoList[this.securityStarPage * 4 - 4 + idx].online =
                  online;
                if (
                  !online ||
                  stalled ||
                  this.videoList[this.securityStarPage * 4 - 4 + idx]
                    .currentTime != videoElem.currentTime
                ) {
                  this.videoList[
                    this.securityStarPage * 4 - 4 + idx
                  ].currentTime = videoElem.currentTime;
                  this.videoList[this.securityStarPage * 4 - 4 + idx].realTime =
                    new Date() / 1000;
                }

                if (stalled || (delayPassed && !online)) {
                  this.videoList[this.securityStarPage * 4 - 4 + idx].version++;
                  if (!online && this.videoPlayingTestsDone >= 3) {
                    this.videoList[this.securityStarPage * 4 - 4 + idx].url =
                      null;
                  }
                  this.videoList = JSON.parse(JSON.stringify(this.videoList));
                  setTimeout(() => {
                    this.playVideo(
                      this.videoList[this.securityStarPage * 4 - 4 + idx],
                      idx + 1,
                      currPage
                    );
                  }, 1000);
                }
              }
            }
          });
        } else if (this.divisionType == 9) {
          [0, 1, 2, 3, 4, 5, 6, 7, 8].forEach((idx) => {
            if (currPage !== this.securityStarPage) return;
            if (this.securityStarPage * 9 - 9 + idx < this.videoList.length) {
              let streamElement = document.getElementById("stream" + (idx + 6));
              if (streamElement) {
                const videoElem =
                  streamElement.shadowDOM.getElementById("video");
                const online =
                  videoElem.readyState > 0 ||
                  videoElem.getBoundingClientRect().height > 155;
                const stalled =
                  online &&
                  videoElem.currentTime ==
                    (this.videoList[this.securityStarPage * 9 - 9 + idx]
                      .currentTime || 0) &&
                  new Date() / 1000 -
                    (this.videoList[this.securityStarPage * 9 - 9 + idx]
                      .realTime || 8000000000) >=
                    7;

                this.videoList[this.securityStarPage * 9 - 9 + idx].online =
                  online;
                if (
                  !online ||
                  stalled ||
                  this.videoList[this.securityStarPage * 9 - 9 + idx]
                    .currentTime != videoElem.currentTime
                ) {
                  this.videoList[
                    this.securityStarPage * 9 - 9 + idx
                  ].currentTime = videoElem.currentTime;
                  this.videoList[this.securityStarPage * 9 - 9 + idx].realTime =
                    new Date() / 1000;
                }

                if (stalled || (delayPassed && !online)) {
                  this.videoList[this.securityStarPage * 9 - 9 + idx].version++;
                  if (!online && this.videoPlayingTestsDone >= 3) {
                    this.videoList[this.securityStarPage * 9 - 9 + idx].url =
                      null;
                  }
                  this.videoList = JSON.parse(JSON.stringify(this.videoList));
                  setTimeout(() => {
                    this.playVideo(
                      this.videoList[this.securityStarPage * 9 - 9 + idx],
                      idx + 5,
                      currPage
                    );
                  }, 1000);
                }
              }
            }
          });
        }
      });
    },
    initPlayer(url, id) {
      console.log("this.playerhhhhh");
      this.isPlay = true;
      this.player.push(
        new FlvPlayer({
          id: id,
          url: url,
          isLive: true,
          autoplay: true, //自动播放，设置自动播放必要参数
          autoplayMuted: true, //自动播放静音，设置自动播放参数必要参数
          cors: true,
          fluid: true,
        })
      );
      this.player.forEach((item) => {
        item.once("complete", () => {
          console.log("complete"); //以下这段我没成功。
          try {
            item.play(); // 尝试再次执行播放
            setTimeout(() => {
              // 500毫秒后检测
              if (!item.hasStart && item.currentTime === 0) {
                // hasStart返回false，并且播放时间还是0，那么就可以认为自动播放失效了
                // isAutoPlay = false;
                item.replay()
              }
            }, 500);
          } catch (e) {
            console.log(e);
          }
        });
      });
    },
    inithlvPlayer(url, id) {

      this.isPlay = true;
      this.player.push(
        new hlsPlayer({
          id: id,
          url: url,//'//sf1-cdn-tos.huoshanstatic.com/obj/media-fe/xgplayer_doc_video/hls/xgplayer-demo.m3u8'
          isLive: true,
          autoplay: true, //自动播放，设置自动播放必要参数
          autoplayMuted: true, //自动播放静音，设置自动播放参数必要参数
          cors: true,
          fluid: true,
        })
      );
      this.player.forEach((item) => {
        item.once("complete", () => {
          console.log("complete"); //以下这段我没成功。
          try {
            item.play(); // 尝试再次执行播放
            setTimeout(() => {
              // 500毫秒后检测
              if (!item.hasStart && item.currentTime === 0) {
                // hasStart返回false，并且播放时间还是0，那么就可以认为自动播放失效了
                // isAutoPlay = false;
                item.replay();
              }
            }, 500);
          } catch (e) {
            console.log(e);
          }
        });
      });
    },
    disconnectFlv() {
      [0, 1, 2, 3, 4, 5, 6, 7, 8].forEach((idx) => {
        if (this.player[idx]) {
          this.player[idx].destroy();
        }
      });
      this.player = [];
      console.log("销毁了");
    },
  },
};
</script>
<style lang="less">
.people-status-proj {
  .inline-input {
    margin: 1rem;
    width: 90% !important;
    .el-input__inner {
      background-color: #0c2341 !important;
      border: 1px solid #1782a6 !important;
      height: 2.5rem !important;
    }
  }
  .el-tree-node__content {
    color: #5accf1;
    background-color: #0f1f3c;
  }
}
.big-content {
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .btn-next {
    padding: 0;
    border: 1px solid #5f7ec9;
    background-color: #21325c;
    padding: 0 0.5rem;
    margin: 0;
  }
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .el-pager li {
    margin: 0;
    border: 1px solid #5f7ec9;
    background-color: #21325c;
    border-radius: 0;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #21325c;
    color: #5accf1;
  }
}
#main{
  // height: calc(100vh - 2rem) !important;
}
</style>
<style scoped lang="less">
#content {
  margin-top: 0rem;
}
.screen-division {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
}
.division-item {
  width: 4rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  background: #21325c;
  border: 1px solid #5f7ec9;
  cursor: pointer;
}
.page-change {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 999;
}
.active-division-type {
  color: #5accf1;
}
.content-title {
  z-index: 99;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to right, #0f1f3c, rgba(255, 255, 255, 0));
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
}
.board {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  padding-top: 3rem;
}
.no-video {
  position: absolute;
  top: 30%;
  left: 40%;
}

.people-status-proj {
  width: 23rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
  height: 57rem;
}

.top-item {
  height: 57rem;
  //   height: 39rem;

  width: calc(100vw - 2rem);
  display: flex;
}
.point-name {
  font-size: 16px;
  line-height: 1.8rem;
  font-weight: 400;
  color: #5accf1;
  margin-left: 1rem;
  text-decoration: underline;
}
.sure-click {
  cursor: pointer;
}
.big-content {
  width: calc(100vw - 27rem);
  height: 57rem;
  margin-right: 1rem;
  border: 1px solid #0d2b50;
  position: relative;
}
.one-screen {
  width: 100%;
  height: 100%;
}
.more-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
}
.four-screen-item {
  width: 49.5%;
  height: 49%;
  position: relative;
}
.nine-screen-item {
  width: 33%;
  height: 33%;
  position: relative;
}

.img {
  width: 2.5rem;
}
</style>
