<template>
  <div class="info-block" style="min-height:5rem">
    <div class="info-block-title" style="display:flex;width:100%;padding-right:3.1rem">
        <div style="flex:1">共建方情况</div>
      <div style="display:flex;align-items:center;">
            <div
              class="detail"
              style="margin-left:0.5rem;"
              @click="showDetail()"
            >
              详情
            </div>
          </div>
        </div>
    <div style="height:19rem;padding-top:3rem">
        <div
          style="display:flex;justify-content:space-around;margin-bottom:0.6rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')"
          >
            共建方分级
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')"
          >
            共建方数量分布
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '3' }"
            @click="() => switchCompanyWorkersOption('3')"
          >
            共建方禁用情况
          </div>
        </div>
      <div v-if="!companyOptionChart.series || !companyOptionChart.series[0].data.length">
        <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;">
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
      </div>
      <v-chart v-else :options="companyOptionChart" @click="companyOptionChartClick" autoresize />
    </div>
    <!-- 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                <el-tabs id ="tabs">
                    <el-tab-pane label="共建方分级">
                        <el-table style="background-color:#0E1328" height="350px" :data="projData" 
                            :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                            <el-table-column type="index" :index="getIndex" label="序号">
                            </el-table-column>
                            <el-table-column
                                    prop="distName"
                                    label="分包名称">
                                </el-table-column>
                                <el-table-column
                                    prop="distType"
                                    label="分包类型">
                                </el-table-column>
                                <el-table-column
                                    prop="creditCode"
                                    label="统一社会信用代码">
                                </el-table-column>
                                <el-table-column
                                    prop="importUnit"
                                    label="引入单位">
                                </el-table-column>
                                <el-table-column
                                    prop="levelName"
                                    label="分包定级">
                                </el-table-column>
                                <el-table-column
                                    prop="companyProperty"
                                    label="公司性质">
                                </el-table-column>
                                <el-table-column
                                    prop="mainIntelli"
                                    label="主项资质">
                                </el-table-column>
                                <el-table-column
                                    prop="specialIntelli"
                                    label="专项资质">
                                </el-table-column>
                                <el-table-column
                                    prop="linkMan"
                                    label="分包联系人">
                                </el-table-column>
                                <el-table-column
                                    prop="phoneNumber"
                                    label="联系电话">
                                </el-table-column>
                        </el-table>
                        <el-pagination
                        background
                        layout="prev, pager, next"
                        :current-page.sync="currentPage"
                        :page-count="pageSize"
                        :total="totalCount"
                        @current-change="pageChange"
                        style="text-align:center;margin-top: 0.5rem;"
                        ></el-pagination>
                    </el-tab-pane>
                    <el-tab-pane label="共建方数量">
                        <el-table style="background-color:#0E1328" height="350px" :data="projData2" 
                            :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                            <el-table-column type="index" :index="getIndex2" label="序号">
                            </el-table-column>
                            <el-table-column
                                    prop="distName"
                                    label="分包名称">
                                </el-table-column>
                                <el-table-column
                                    prop="distType"
                                    label="分包类型">
                                </el-table-column>
                                <el-table-column
                                    prop="creditCode"
                                    label="统一社会信用代码">
                                </el-table-column>
                                <el-table-column
                                    prop="importUnit"
                                    label="引入单位">
                                </el-table-column>
                                <el-table-column
                                    prop="levelName"
                                    label="分包定级">
                                </el-table-column>
                                <el-table-column
                                    prop="companyProperty"
                                    label="公司性质">
                                </el-table-column>
                                <el-table-column
                                    prop="mainIntelli"
                                    label="主项资质">
                                </el-table-column>
                                <el-table-column
                                    prop="specialIntelli"
                                    label="专项资质">
                                </el-table-column>
                                <el-table-column
                                    prop="linkMan"
                                    label="分包联系人">
                                </el-table-column>
                                <el-table-column
                                    prop="phoneNumber"
                                    label="联系电话">
                                </el-table-column>
                        </el-table>
                        <el-pagination
                        background
                        layout="prev, pager, next"
                        :current-page.sync="currentPage2"
                        :page-count="pageSize2"
                        :total="totalCount2"
                        @current-change="pageChange2"
                        style="text-align:center;margin-top: 0.5rem;"
                        ></el-pagination>
                    </el-tab-pane>
                    <el-tab-pane label="共建方禁用情况">
                        <el-table style="background-color:#0E1328" height="350px" :data="projData3" 
                            :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                            <el-table-column type="index" :index="getIndex3" label="序号">
                            </el-table-column>
                            <el-table-column
                                    prop="partnerName"
                                    label="共建方名称">
                                </el-table-column>
                                <el-table-column
                                    prop="qualityType"
                                    label="资质类别">
                                </el-table-column>
                                <el-table-column
                                    prop="empName"
                                    label="考核人">
                                </el-table-column>
                                <el-table-column
                                    prop="dptName"
                                    label="考核部门">
                                </el-table-column>
                                <el-table-column
                                    prop="examDate"
                                    label="考核日期">
                                </el-table-column>
                        </el-table>
                        <el-pagination
                        background
                        layout="prev, pager, next"
                        :current-page.sync="currentPage3"
                        :page-count="pageSize3"
                        :total="totalCount3"
                        @current-change="pageChange3"
                        style="text-align:center;margin-top: 0.5rem;"
                        ></el-pagination>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { stackedBarChartTemplate } from "../../barChartTemplates";
  import { applyUpdate } from "../../../../utils";
import { projectMgr } from '../../../../api_projectMgr'
import { finance } from '../../../../api_finance'
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'PartnerConditionChart',
    components: {
      'v-chart': ECharts,
      wrapper
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
            this.switchCompanyWorkersOption(this.companyWorkersOption);
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
            this.switchCompanyWorkersOption(this.companyWorkersOption);
        }
      }
    },
    data () {
      return {
        mode:'1',
        companyOptionChart: {},
        companyWorkersOption:'1',
        popupShow:false,
        currentPage:1,
        pageSize:10,
        totalCount:0,
        currentPage2:1,
        pageSize2:10,
        totalCount2:0,
        currentPage3:1,
        pageSize3:10,
        totalCount3:0,
        projData:[],
        projData2:[],
        projData3:[],
        branchName:''
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.switchCompanyWorkersOption(this.companyWorkersOption);
    },
    methods: {
        switchCompanyWorkersOption(op){
            this.companyWorkersOption = op;
            switch (this.companyWorkersOption) {
                case '1':
                    this.queryPartnerLevelChart();
                    break;
                case '2':
                    this.queryPartnerDistCountChart();
                    break;
                case '3':
                    this.queryPartnerBanChart();
                    break;
                default:
                    this.queryPartnerLevelChart();
                    break;
            }
        },
        queryPartnerBanChart(){
            projectMgr.queryPartnerBanChart()
                .then((d=>{
                    console.log(d);
                    this.companyOptionChart = {};
                }))
        },
        queryPartnerDistCountChart(){
            projectMgr.queryPartnerDistCountChart()
                .then((d=>{
                    const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
                    const option = {
                    color: ["#46A0FF", "#FFED21", "#FF8521", "rgba(0,0,0,0)"],
                    legend: {
                    },
                    grid: {
                        bottom: "5%"
                    },
                    xAxis: {
                        name:"引入单位",
                        show:true,
                        type: "category",
                        axisLabel: {
                        color: "#e6f2f3",
                            show:true,
                            lineStyle: {
                                color: "#rgb(77,83,141)",
                            },
                            rotate: 40,
                            fontWeight: "normal",
                        },
                        axisLine: { show: false, lineStyle: { color: '#36445f' } },
                        splitLine: { show: false },
                        axisTick: { show: false },
                        data:[]
                    },
                    yAxis: [
                        {
                        nameTextStyle: {
                            color: "#ccc",
                            align: "right",
                        },
                        type: "value",
                        axisLabel: {
                            color: "#ffffff"
                        },
                        axisLine: { show: false, lineStyle: { color: "#36445f" } },
                        splitLine: { show: false },
                        axisTick: { show: false },
                        boundaryGap: [0, '20%'],
                        },
                    ],
                    series: [
                        {
                        name: "劳务分包",
                        type: "bar",
                        stack:'total',
                        data:[]
                        },{
                        name: "专业分包",
                        type: "bar",
                        stack:'total',
                        data:[]
                        }
                    ],
                    };

                    for (let i = 0; i < d.length; i++) {
                    option.xAxis.data.push(d[i].importUnit);   
                    option.series[0].data.push(d[i].labCount);         
                    option.series[1].data.push(d[i].proCount);         
                    }
                    this.companyOptionChart = applyUpdate(chart, option);
                }))
        },
        queryPartnerLevelChart(){
            projectMgr.queryPartnerLevelChart().then((d=>{
               const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
                const option = {
                color: ["#46A0FF", "#FFED21", "#FF8521", "rgba(0,0,0,0)"],
                legend: {
                },
                grid: {
                    bottom: "5%"
                },
                xAxis: {
                    show:true,
                    type: "category",
                    axisLabel: {
                    color: "#e6f2f3",
                        show:true,
                        lineStyle: {
                            color: "#rgb(77,83,141)",
                        },
                        rotate: 40,
                        fontWeight: "normal",
                    },
                    axisLine: { show: false, lineStyle: { color: '#36445f' } },
                    splitLine: { show: false },
                    axisTick: { show: false },
                    data:[]
                },
                yAxis: [
                    {
                    nameTextStyle: {
                        color: "#ccc",
                        align: "right",
                    },
                    type: "value",
                    axisLabel: {
                        color: "#ffffff"
                    },
                    axisLine: { show: false, lineStyle: { color: "#36445f" } },
                    splitLine: { show: false },
                    axisTick: { show: false },
                    boundaryGap: [0, '20%'],
                    },
                ],
                series: [
                    {
                    name: "A类分包",
                    type: "bar",
                    data:[]
                    },{
                    name: "B类分包",
                    type: "bar",
                    data:[]
                    }
                ],
                };

                for (let i = 0; i < d.length; i++) {
                option.xAxis.data.push(d[i].branchName);   
                option.series[0].data.push(d[i].levelACount);         
                option.series[1].data.push(d[i].levelBCount);         
                }
                this.companyOptionChart = applyUpdate(chart, option);
            }))
        },
      pageChange(curPage){
        this.currentPage = curPage;
        this.getTableData();
      },
      pageChange2(curPage){
        this.currentPage2 = curPage;
        this.getTableData();
      },
      pageChange3(curPage){
        this.currentPage3 = curPage;
        this.getTableData();
      },
      companyOptionChartClick(op){
        this.branchName = op.name;
        this.getTableData();
        this.popupShow = true;
      },
      getTableData(){
        projectMgr.queryPartnerLevelInfoList(this.branchName,this.currentPage,this.pageSize).then((d=>{
            this.projData = d.data;
            this.totalCount = d.totalRecords;
        }))
        projectMgr.queryPartnerDistInfoList(this.branchName,this.currentPage2,this.pageSize2).then((d=>{
            this.projData2 = d.data;
            this.totalCount2 = d.totalRecords;
        }))
        projectMgr.queryPartnerBanInfoList(this.branchName,this.currentPage3,this.pageSize3).then((d=>{
            this.projData3 = d.data;
            this.totalCount3 = d.totalRecords;
        }))
      },
      showDetail(){
        this.getTableData();
        this.popupShow = true;
      },
      dateFormat(row, column, cellValue){
        let dt = new Date(cellValue);
        console.log(dt);
        return dt.getFullYear()+"-"+(dt.getMonth()+1)+"-"+(dt.getDate()-1);
      },
      getIndex(index){
        return (this.currentPage-1)*this.pageSize+index+1;
      },
      getIndex2(index){
        return (this.currentPage2-1)*this.pageSize2+index+1;
      },
      getIndex3(index){
        return (this.currentPage3-1)*this.pageSize3+index+1;
      },
      tableRowStyle({rowIndex}){
        if(rowIndex%2 === 0)
            return {backgroundColor:'#213a77',height: '0'}; 
        else   
            return {backgroundColor:'#102855',height: '0'}; 
      },
      tableHeaderColor(){
        return 'color:#4fb5da;background:#102855';
      },
      switchAvgAttendanceTopListOption(op){
        this.mode = op;
        if(this.mode == '1'){
          this.updateRiskDepositChart();
        }else{
          this.updateTargetDutyChart();
        }
      },
      updateTargetDutyChart(){
          projectMgr.queryProjRespDocCondition()
            .then((d=>{
              
            const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
            const option = {
              color: ["#46A0FF", "#FFED21", "#FF8521", "rgba(0,0,0,0)"],
              tooltip: {
                trigger: "axis",
              },
              legend:{
              },
              grid: {
                top: "15%",
                left: "10%",
                right: "9%",
                bottom: "15%",
                containLabel: true,
              },
            dataZoom: [{
              type: 'slider',
              show: true,
              start: 0,
              end: 100,
              yAxisIndex: 0,
              filterMode: 'empty',
              width: 6,
              height: '78%',
              left: '95%',
              handleSize: 0,
              zoomLock: true,
              top: '8%',
              fillerColor: '#323d86',
              borderColor: '#323d86',
              textStyle: {
                color: "#fff",
                fontSize: 10,
              }
            }, {
              type: 'inside',
              yAxisIndex: 0,
              start: 0,
              end: 100,
              zoomOnMouseWheel: false,
              moveOnMouseMove: true,
              moveOnMouseWheel: true
            }],
              xAxis: [
                {
                  name: "金额:万元",
                  type: "value",
                  axisLabel: {
                    color: "#ffffff",
                  },
                  axisTick: { show: false },
                  splitLine: {show: false,}
                },
              ],
              yAxis: 
                {
                  show: true,
                  type: "category",
                  nameTextStyle: {
                    color: "#e6f2f3",
                    fontWeight: "bold",
                    align: "left",
                  },
                  axisLabel: {
                    color: "#e6f2f3",
                    fontWeight: "normal",
                  },
                  axisLine: { show: true, lineStyle: { color: "#36445f" } },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: "#36445f",
                      width: 1,
                      type: "solid",
                    },
                  },
                  axisTick: { show: false },
                  data:[]
                },
              series: [
                {
                  name: "已完成",
                  type: "bar",
                  stack:"total",
                  data:[]
                },{
                  name: "未完成",
                  type: "bar",
                  stack:"total",
                  data:[]
                },{
                  name: "超期数",
                  type: "bar",
                  stack:"total",
                  data:[]
                }
              ],
            };
            for (let i = 0; i < d.length; i++) {
              option.yAxis.data.push(d[i].branchName);
              option.series[0].data.push(d[i].finishedCount);
              option.series[1].data.push(d[i].unfinishedCount);
              option.series[2].data.push(d[i].expiredCount);
            }
            if (option.yAxis.data.length <= 6) {
              option.dataZoom[0].show = false;
            } else {
              option.dataZoom[0].show = true;
              option.dataZoom[0].start = option.dataZoom[1].start = 100 - Math.min(100, (6 / option.yAxis.data.length) * 100);
            }
            this.companyOptionChart = applyUpdate(chart, option);
            }))
      },
      updateRiskDepositChart() {
        projectMgr.queryRiskGuaranteeChart()
          .then(d => {
            const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
            const option = {
              color: ["#46A0FF", "#FFED21", "#FF8521", "rgba(0,0,0,0)"],
              tooltip: {
                trigger: "axis",
              },
              legend:{

              },
              grid: {
                top: "15%",
                left: "10%",
                right: "9%",
                bottom: "15%",
                containLabel: true,
              },
              xAxis: [
                {
                  name: "金额:万元",
                  type: "value",
                  axisLabel: {
                    color: "#ffffff",
                  },
                  axisTick: { show: false },
                  splitLine: {show: false,}
                },
              ],
              yAxis: 
                {
                  show: true,
                  type: "category",
                  nameTextStyle: {
                    padding: [0, 40, 0, 0],
                    color: "#e6f2f3",
                    fontWeight: "bold",
                    align: "left",
                  },
                  axisLabel: {
                    color: "#e6f2f3",
                    fontWeight: "normal",
                  },
                  axisLine: { show: false, lineStyle: { color: "#36445f" } },
                  splitLine: {
                    show: false,
                    lineStyle: {
                      color: "#36445f",
                      width: 1,
                      type: "solid",
                    },
                  },
                  axisTick: { show: false },
                  data:[]
                },
              series: [
                {
                  name: "已缴纳",
                  type: "bar",
                  stack:"total",
                  data:[]
                },{
                  name: "未欠缴",
                  type: "bar",
                  stack:"total",
                  data:[]
                }
              ],
            };
            for (let i = 0; i < d.length; i++) {
              option.yAxis.data.push(d[i].branchName);
              option.series[0].data.push(finance.formatDecimal(d[i].payableAmount,2));
              option.series[1].data.push(finance.formatDecimal(d[i].unpaidAmount,2));
            }
            this.companyOptionChart = applyUpdate(chart, option);
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
    },
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
