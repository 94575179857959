<template>
    <div id = "left">
        <div class="info-block" style="min-height:5rem">
        <div class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >案件数量与金额
        <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '1' }"
              @click="() => switchAvgAttendanceTopListOption('1')"
            >存量案件</div>
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '2' }"
              @click="() => switchAvgAttendanceTopListOption('2')"
            >新发案件</div>
            <div
              class="detail"
              style="margin-left: 0.5rem;"
              @click=" showDetail()"
            >
              详情
            </div>
          </div>
        </div>
        <div style="height:20rem;padding-top:3rem">
        <div
            v-if="respondCaseChart.series && [].concat.apply([], respondCaseChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="respondCaseChart" @click="respondCaseChartClick" autoresize />
        </div>
    </div>
    <!-- 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                <el-table style="background-color:#0E1328" height="28rem" :data="tableData" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column
                        type="index"
                        label="序号">
                    </el-table-column>
                    <el-table-column
                        prop="FYSAJH"
                        label="案件号">
                        </el-table-column>
                    <el-table-column
                        prop="FCJTLQ"
                        label="从集团领取">
                    </el-table-column>
                    <el-table-column
                        prop="FDWMC"
                        label="涉诉公司">
                    </el-table-column>
                    <el-table-column
                        prop="FXMMC"
                        label="涉案项目">
                    </el-table-column>
                    <el-table-column
                        prop="FYG"
                        label="原告/申请">
                    </el-table-column>
                    <el-table-column
                        prop="FBG"
                        label="被告/申请">
                    </el-table-column>
                    <el-table-column
                        prop="FDSR"
                        label="第三人">
                    </el-table-column><el-table-column
                        prop="FAFRQ"
                        label="案发时间">
                    </el-table-column>
                    <el-table-column
                        prop="FAFLX"
                        label="案发类型"
                        :formatter="formatterCaseType">
                    </el-table-column>
                    <el-table-column
                        prop="FGXFYZCW"
                        label="管辖法院/仲裁委">
                    </el-table-column>
                    <el-table-column
                        prop="FYSBDE"
                        label="标的额(元)">
                    </el-table-column>
                     <el-table-column
                        prop="FYSBJ"
                        label="本金(元)">
                    </el-table-column>
                    <el-table-column
                        prop="FYSLX"
                        label="利息(元)">
                    </el-table-column>
                    <el-table-column
                        prop="FCJJE"
                        label="裁判金额">
                    </el-table-column>
                    <el-table-column
                        prop="FJSJE"
                        label="减损金额">
                    </el-table-column>
                    <el-table-column
                        prop="FAY"
                        label="案由"
                        :formatter="formatterFay">
                    </el-table-column>
                    <el-table-column
                        prop="FAJZT"
                        label="案件状态">
                    </el-table-column>
                </el-table>
            </div>
        </div>
      </wrapper>
    </el-dialog>
</div>
</template>
<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import {law} from '../../../../api_law';
  import wrapper from "../../../../components/wrap";
  export default {
    name:"RespondCaseLoadSumChart",
    components:{
        'v-chart':ECharts,
        wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updaterespondCaseChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updaterespondCaseChart();
        }
      }
    },
    data(){
        return{
            respondCaseChart:{},
            popupShow:false,
            tableData:[],
            mode:"1"
        }
    },
    mounted(){
        // const self = this
      const token = this.$route.query.token;
      window.token = token;
      
      this.updaterespondCaseChart();
    },
    methods:{
        formatterFay(row,column,cellvalue){
            return law.formatterFay(cellvalue);
        },
        formatterCaseType(row,column,cellvalue){
             return cellvalue=="01"?"仲裁":"诉讼";
        },
        switchAvgAttendanceTopListOption(choose){
            this.mode = choose;
            this.updaterespondCaseChart();
        },
        showDetail(){
            this.respondCaseChartClick();
        },
        respondCaseChartClick(){
            this.popupShow = true;
            const caseType = this.mode == "1"?3:this.mode=="2"?2:1;
            law.queryChiefCaseDetail(caseType).then((res=>{
                this.tableData = res;
            }))
        },
        tableRowStyle({rowIndex}){
            if(rowIndex%2 === 0)
                return {backgroundColor:'#213a77',height: '0'}; 
            else   
                return {backgroundColor:'#102855',height: '0'}; 
        },
        tableHeaderColor(){
            return 'color:#4fb5da;background:#102855';
        },
        updaterespondCaseChart(){
            law.queryRespondentCaseLoadSum().then((res=>{
                const option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            lineStyle: {
                                color: '#57617B',
                            },
                        },
                    },
                    legend: {
                        right: '8%',
                        top: '2%',
                        textStyle: {
                            color: '#5195da',
                        },
                    },
                    xAxis: {
                        type: 'category',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#3585d5',
                            },
                        },
                        axisTick: {
                            show: false,
                            alignWithLabel: true,
                            lineStyle: {
                                color: '#3585d5',
                            },
                        },
                        axisLabel: {
                            color: 'white',
                            fontWeight: 'bold',
                            rotate: 40,
                            // formatter: value => truncateStr(value, 10),
                        },
                        data: [],
                    },
                    yAxis: [
                        {
                        name: '数量',
                        nameTextStyle:{
                            color: '#A2D4E6',
                        },
                        type: 'value',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#3585d5',
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                type: 'dotted',
                                color: '#3585d5',
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            fontSize: 16,
                            color:'#A2D4E6'
                        }
                        },
                        {
                        name: '金额',
                        nameTextStyle:{
                            color: '#A2D4E6',
                        },
                        type: 'value',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#3585d5',
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                type: 'dotted',
                                color: '#3585d5',
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            fontSize: 16,
                            color:'#A2D4E6'
                        }
                        },
                    ],
                    series: [
                        {
                            name: "数量",
                            type: 'bar',
                            smooth: true,
                            symbol: 'none',
                            showSymbol: false,
                            symbolSize: 8,
                            yAxisIndex:0,
                            itemStyle: {
                                color: {
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    type: 'linear',
                                    global: false,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#FFC31F',
                                        },
                                        {
                                            offset: 1,
                                            color: '#FF5A1F',
                                        },
                                    ],
                                },
                            },
                            data: [],
                        },{
                            name: "金额",
                            type: 'line',
                            smooth: true,
                            symbol: 'none',
                            showSymbol: false,
                            yAxisIndex:1,
                            symbolSize: 8,
                            itemStyle: {
                                color: {
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    type: 'linear',
                                    global: false,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#28D8E8',
                                        },
                                        {
                                            offset: 1,
                                            color: '#0489F0',
                                        },
                                    ],
                                },
                            },
                            data: [],
                        }
                    ],
                }
                for (let i = 0; i < res.length; i++) {
                    option.xAxis.data.push(res[i].orgName);
                    if(this.mode == "1"){
                        option.series[0].data.push(res[i].clCaseNum);
                        option.series[1].data.push(res[i].clCaseSum!=null?law.formatToMyriadDecimal(res[i].clCaseSum,2):0);
                    }else{
                        option.series[0].data.push(res[i].bnCaseNum);
                        option.series[1].data.push(res[i].bnCaseSum!=null?law.formatToMyriadDecimal(res[i].bnCaseSum,2):0);
                    }
                }
                this.respondCaseChart = option;
            }))
        }
    }

}
</script>