<template>
  <div>
    <div style="position:relative">
      <div class="project_map" id="heatmapid" :style="{backgroundImage: `url(${projectImage})`, height: `${projectId ? 46 : 48}rem`}"></div>
      <div style="position:absolute;top:2rem;left:3.1rem;font-size:20px;color:#ccc;z-index:100000">{{heatmapPlaying ? '时间' : ''}}</div>
      <div style="position:absolute;top:3.6rem;left:3rem;font-size:48px;z-index:100000">{{heatmapPlaying ? playTime : ''}}</div>
      <div :style="{top: heatmapPlaying ? '9rem' : '2rem'}" style="position:absolute;left:3.1rem;font-size:20px;color:#ccc;z-index:100000">人数</div>
      <div :style="{top: heatmapPlaying ? '10.6rem' : '3.6rem'}" style="position:absolute;left:3rem;font-size:48px;z-index:100000">{{playWorkerCount}}</div>
      <div :style="{top: heatmapPlaying ? '15rem' : '8rem'}" style="position:absolute;left:3.1rem;font-size:14px;color:#ccc;z-index:100000" v-on-clickaway="clearExpandedFilter">
        <div class="qiehuan-button" :style="{marginBottom: expandedFilter == 'company' ? '0.3rem' : 0 }" @click="toggleCompanyExpanding()">{{selectedCompany ? selectedCompany.name : '全部共建方'}}</div>
        <div v-if="expandedFilter == 'company'" :style="{maxHeight: heatmapPlaying ? '30rem' : '37rem'}" style="display:flex;flex-direction:column;overflow-y:scroll">
          <div class="item-button" v-if="selectedCompany" @click="companyClick(null)">全部共建方</div>
          <div class="item-button" v-for="company in companies" :key="company.innerid" @click="companyClick(company)">{{company.name}}</div>
        </div>
        <div class="qiehuan-button" :style="{marginBottom: expandedFilter == 'workerType' ? '0.3rem' : 0 }" style="margin-top:0.3rem" @click="toggleWorkerTypeExpanding()">{{selectedWorkerType ? selectedWorkerType.value : '全部工种'}}</div>
        <div v-if="expandedFilter == 'workerType'" :style="{maxHeight: heatmapPlaying ? '29rem' : '36rem'}" style="display:flex;flex-direction:column;overflow-y:scroll">
          <div class="item-button" v-if="selectedWorkerType" @click="workerTypeClick(null)">全部工种</div>
          <div class="item-button" v-for="workerType in workerTypes" :key="workerType.innerid" @click="workerTypeClick(workerType)">{{workerType.value}}</div>
        </div>
      </div>
    </div>
    <div class="video-player">
      <div class="controls" style="width:80rem;margin:0 auto">
        <Slider
          class="media-slider"
          :value="dynamicHeatmapData.war_list ? Math.min(1, currDynamicStep / dynamicHeatmapData.war_list.length) : 0"
          @input="onProgressChange"
        >
          <div class="slider-trail" :style="{background: `linear-gradient(to right, #75e6f5 ${dynamicHeatmapData.war_list ? Math.min(1, currDynamicStep / dynamicHeatmapData.war_list.length) * 100 : 0}%, #1e79c0 0)`}" style="width:100%" />
          <div class="handle" :style="{left: `${dynamicHeatmapData.war_list ? Math.min(1, currDynamicStep / dynamicHeatmapData.war_list.length) * 100 : 0}%`}" />
          <div slot="handle" />
        </Slider>
        <div style="margin:0 auto;font-size:24px;margin-top:0.5rem;width:144px;display:flex;justify-content: space-between;">
          <font-awesome-icon :icon="['fas', 'backward']" :style="{color: heatmapPlaying ? 'white' : 'gray'}" style="cursor:pointer" @click="goBack" />
          <font-awesome-icon :icon="heatmapPlaying && !heatmapPaused ? ['fas', 'pause'] : ['fas', 'play']" style="cursor:pointer" @click="togglePlay" />
          <font-awesome-icon :icon="['fas', 'forward']" :style="{color: heatmapPlaying ? 'white' : 'gray'}" style="cursor:pointer" @click="goForward" />
          <font-awesome-icon :icon="['fas', 'stop']" :style="{color: heatmapPlaying ? 'white' : 'gray'}" style="cursor:pointer" @click="stopHeatmap" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//require('echarts-gl')
//import ECharts from 'vue-echarts'
//import 'echarts/lib/chart/bar'
//import 'echarts/lib/component/tooltip'
import { Slider } from '@adrianhurt/vue-media-manager';
import { directive as onClickaway } from 'vue-clickaway';
import { project, filters, wsUrl } from '../../api';
import {
  init,
  changeMap,
  createWebSocket,
  showHideLayer,
  setHeatmapData,
  clearHeatmapData,
  removeHeatmap,
  convertPoint,
  getMapData,
} from '../../../public/leaflet.js';
import '@adrianhurt/vue-media-manager/dist/vue-media-manager.css';

function xmur3(str) {
    for (var i = 0, h = 1779033703 ^ str.length; i < str.length; i++)
        h = Math.imul(h ^ str.charCodeAt(i), 3432918353),
            h = h << 13 | h >>> 19;
    return function () {
        h = Math.imul(h ^ h >>> 16, 2246822507);
        h = Math.imul(h ^ h >>> 13, 3266489909);
        return (h ^= h >>> 16) >>> 0;
    }
}

function sfc32(a, b, c, d) {
    return function () {
        a >>>= 0;
        b >>>= 0;
        c >>>= 0;
        d >>>= 0;
        var t = (a + b) | 0;
        a = b ^ b >>> 9;
        b = c + (c << 3) | 0;
        c = (c << 21 | c >>> 11);
        d = d + 1 | 0;
        t = t + d | 0;
        c = c + t | 0;
        return (t >>> 0) / 4294967296;
    }
}

export default {
  name: 'Heatmap',
  components: {
    Slider,
  },
  props: {
    entry: String,
    projectId: String,
    heatmapReady: Boolean,
  },
  directives: {
    onClickaway: onClickaway
  },
  data() {
    return {
      s_build_id: '',
      s_index: 0,
      buildingInfo: [
        {
          build_id: '',
          build_name: '',
          location: {},
          floor_height: '',
          map_count: 0,
          worker_count: 0,
          map_worker_list: ''
        }
      ],
      projectImage: '',
      mapInited: false,
      map_list: [],
      img_host: '',
      heatmapFilter: {},
      dynamicHeatmapData: {},
      dynamicHeatmapDataCache: {},
      staticHeatmapDataCache: {},
      dynamicHeatmapSearchParams: '',
      currDynamicStep: 0,
      heatmapPlaying: false,
      heatmapPaused: true,
      lastStepCalled: 0,
      lastStepJump: 1,
      playTime: '',
      playWorkerCount: '',
      dynamicHeatmapTimeout: null,
      dynamicHeatmapSpotWorkers: {},
      companies: [],
      workerTypes: [],
      selectedCompany: null,
      selectedWorkerType: null,
      expandedFilter: '',
    }
  },
  watch: {
    entry: function(newEntry, oldEntry) {
      if (newEntry != oldEntry && this.mapInited) {
        this.heatmapFilter.search_date = newEntry;

        if (this.heatmapPlaying) {
          this.stopHeatmapPlay();
        }
        this.heatmapPaused = false;
        this.playDynamicHeatmap();
      }
    },
    heatmapReady: function(newReady, oldReady) {
      if (newReady != oldReady && this.mapInited) {
        if (this.heatmapPlaying) {
          if (newReady) {
            setTimeout(() => {
              this.heatmapPaused = !newReady;
            }, 200);
          } else {
            this.heatmapPaused = !newReady;
          }
        } else if (newReady) {
          setTimeout(() => {
            this.heatmapPaused = false;
            this.playDynamicHeatmap();
          }, 200);
        }
      }
    }
  },
  mounted() {
    const projectID = this.projectId || this.$route.params.id;
    const token = this.$route.query.token;
    window.token = token;

    project
      .getBuildingBaseInfo(projectID)
      .then(d => {
        this.buildingInfo = d.build_list
        this.map_list = d.show_map
        this.img_host = d.img_host
        this.s_index = 0;
        this.s_build_id = this.map_list[0].build_id;
        //创建ws socket连接
        createWebSocket('heatmapid', wsUrl, projectID)
        //加载地图
        const use3D = false;
        if (use3D) {
          init(
            'heatmapid',
            this.img_host + this.map_list[0].image_url,
            this.s_build_id,
            this,
            null,
            {
              enableImage: false,
              enableMarkers: false,
              enable3D: true,
              sceneData: {
                workerPosition: []
              }
            }
          );
          showHideLayer('heatmapid', 'sceneLayer', false);
          showHideLayer('heatmapid', 'heatmapLayer', false);
        } else {
          init(
            'heatmapid',
            this.img_host + this.map_list[0].image_url,
            this.s_build_id,
            this,
            null,
            {
              initialZoom: 2.8,
              enableMarkers: false,
              enableHeatmap: true,
              mapWidth: this.map_list[0].width,
              mapHeight: this.map_list[0].height,
            }
          );
        }

        this.mapInited = true;

        if (this.entry) {
          this.heatmapFilter.search_date = this.entry;
          if (this.heatmapReady) {
            this.heatmapPaused = false;
            this.playDynamicHeatmap();
          }
        }
      })
      .catch(e => console.error(e.msg || e.message));

    filters
      .getProjectCompanyList(projectID)
      .then(d => {
        this.companies = d;
      })
      .catch(e => console.error(e.msg || e.message));

    filters
      .getProjectWorkerTypeList(projectID, '')
      .then(d => {
        this.workerTypes = d;
      })
      .catch(e => console.error(e.msg || e.message));
  },
  beforeDestroy() {
    // Stop any playing heatmap
    if (this.heatmapPlaying) {
      this.stopHeatmapPlay();
    }
    removeHeatmap('heatmapid');
  },
  methods: {
    changeMap(map_image, type, build_id, m_index) {
      if (map_image && type && build_id) {
        this.s_index = m_index
        this.s_build_id = build_id
        changeMap('heatmapid', this.img_host + map_image, type, build_id)
        this.showStaticHeatmap();
      }
    },
    mapSelect(name) {
      const idx = this.map_list.findIndex(item => item.name == name);
      if (idx >= 0 && idx !== this.s_index) {
        const m = this.map_list[idx];
        this.changeMap(m.image_url, m.type, m.build_id, idx);
      }
    },
    toggleCompanyExpanding() {
      if (this.expandedFilter == 'company') {
        this.expandedFilter = '';
      } else {
        this.expandedFilter = 'company';
      }
    },
    toggleWorkerTypeExpanding() {
      if (this.expandedFilter == 'workerType') {
        this.expandedFilter = '';
      } else {
        this.expandedFilter = 'workerType';
      }
    },
    clearExpandedFilter() {
      this.expandedFilter = '';
    },
    companyClick(company) {
      const projectID = this.projectId || this.$route.params.id;
      this.expandedFilter = '';
      if (this.selectedCompany != company) {
        this.selectedCompany = company;

        filters
          .getProjectWorkerTypeList(projectID, company ? company.innerid : '')
          .then(d => {
            this.workerTypes = d;
            if (this.selectedWorkerType) {
              const item = this.workerTypes.find(x => x.innerid == this.selectedWorkerType.innerid);
              if (item) {
                this.selectedWorkerType = item;
              } else {
                this.workerTypeClick(null);
                return;
              }
            }

            this.updateHeatmapFilter(company, this.selectedWorkerType);
          })
          .catch(e => console.error(e.msg || e.message));
      }
    },
    workerTypeClick(workerType) {
      this.expandedFilter = '';
      if (this.selectedWorkerType != workerType) {
        this.selectedWorkerType = workerType;
        this.updateHeatmapFilter(this.selectedCompany, workerType);
      }
    },
    updateHeatmapFilter(company, workerType) {
      this.heatmapFilter.company_id = company && company.innerid;
      this.heatmapFilter.worker_type = workerType && workerType.innerid;

      if (this.heatmapPlaying) {
        this.stopHeatmapPlay();
      }
      this.heatmapPaused = false;
      this.playDynamicHeatmap();
    },
    stopHeatmap() {
      if (this.heatmapPlaying) {
        this.showStaticHeatmap();
      }
    },
    togglePlay() {
      if (this.heatmapPlaying) {
        this.heatmapPaused = !this.heatmapPaused;
      } else {
        this.heatmapPaused = false;
        this.playDynamicHeatmap();
      }
    },
    goBack() {
      if (this.heatmapPlaying) {
        const step = Math.max(this.currDynamicStep - 6, 0);
        if (this.heatmapPaused) {
          this.playDynamicStep(Math.max(step - 1, 0));
        }
        this.currDynamicStep = step;
      }
    },
    goForward() {
      if (this.heatmapPlaying) {
        const step = Math.min(this.currDynamicStep + 6, this.dynamicHeatmapData.war_list.length - 1);
        if (this.heatmapPaused) {
          this.playDynamicStep(Math.max(step - 1, 0));
        }
        this.currDynamicStep = step;
      }
    },
    onProgressChange(progress) {
      if (this.heatmapPlaying) {
        const step = Math.round(this.dynamicHeatmapData.war_list.length * progress);
        if (this.heatmapPaused) {
          this.playDynamicStep(Math.max(step - 1, 0));
        }
        this.currDynamicStep = step;
      }
    },
    stopHeatmapPlay() {
      if (this.dynamicHeatmapTimeout) {
        clearTimeout(this.dynamicHeatmapTimeout);
      }
      this.heatmapPlaying = false;
      this.playTime = '';
      this.playWorkerCount = '';
      this.currDynamicStep = 0;
    },
    showStaticHeatmap() {
      const projectID = this.projectId || this.$route.params.id;
      var self = this;
      var params = JSON.stringify(Object.assign({}, this.heatmapFilter, {
        project_id: projectID
      }));
      var cacheKey = this.s_build_id + JSON.stringify(this.heatmapFilter);

      // Stop any playing heatmap
      this.stopHeatmapPlay();

      if (this.staticHeatmapDataCache[cacheKey] && (new Date() / 1000 - this.staticHeatmapDataCache[cacheKey].ts <= 600)) {
        this.updateHeatmapData(this.staticHeatmapDataCache[cacheKey], params);
      } else {
        clearHeatmapData('heatmapid');
        project
          .getHeatmapData(params)
          .then(data => {
            data.ts = new Date() / 1000;
            self.staticHeatmapDataCache[cacheKey] = data;
            self.updateHeatmapData(data, params);
          })
          .catch(e => console.error(e.msg || e.toString()));
      }
    },
    updateHeatmapData(data, params, randomPoints) {
      const heatmapData = [];
      const heatmapAreas = [];

      let max = 0;
      if (data) {
        const self = this;
        this.playWorkerCount = data.reduce((a, b) => a + b.count, 0);

        data.forEach(function (spot) {
          var seed = xmur3(self.s_build_id + spot.area_name + params);
          var random = sfc32(seed(), seed(), seed(), seed());

          let point_list;
          if (randomPoints) {
            // Dynamic
            point_list = JSON.parse((randomPoints[spot.key] || {}).point_list || 'null');
          } else if (spot.point_list) {
            // Static
            point_list = JSON.parse(spot.point_list);
          }

          if (point_list) {
            point_list = point_list.map(x => convertPoint(x, getMapData('heatmapid')));

            heatmapAreas.push({
              name: spot.area_name,
              count: spot.count,
              point_list: point_list
            });

            var i, x, y, iterLen;
            if (randomPoints) {
              if (!self.dynamicHeatmapSpotWorkers[spot.key]) {
                self.dynamicHeatmapSpotWorkers[spot.key] = [];
              }
              for (i = 0; i < spot.worker_list.length; i++) {
                if (self.dynamicHeatmapSpotWorkers[spot.key].indexOf(spot.worker_list[i]) == -1) {
                  self.dynamicHeatmapSpotWorkers[spot.key].push(spot.worker_list[i]);
                }
              }
              iterLen = self.dynamicHeatmapSpotWorkers[spot.key].length;
            } else {
              iterLen = spot.count;
            }

            const size = Math.round(Math.sqrt(spot.count)) * 2;
            const res = {};
            for (i = 0; i < iterLen; i++) {
              const pt_idx = Math.floor(random() * point_list.length);
              if (!randomPoints || spot.worker_list.indexOf(self.dynamicHeatmapSpotWorkers[spot.key][i]) >= 0) {
                for (x = -size; x <= size; x++) {
                  for (y = -size; y <= size; y++) {
                    const key = `${point_list[pt_idx][0] + x}-${point_list[pt_idx][1] + y}`;
                    if (res[key]) {
                      res[key].count += 1;
                    } else {
                      res[key] = {
                        x: point_list[pt_idx][0] + x,
                        y: point_list[pt_idx][1] + y,
                        count: 1
                      }
                    }
                  }
                }
              }
            }

            for (const item of Object.values(res)) {
              heatmapData.push([item.x, item.y, item.count]);

              if (item.count > max) {
                max = item.count;
              }
            }
          }
        });

        setHeatmapData('heatmapid', heatmapData, heatmapAreas, max);
      }
    },
    playDynamicStep(playStep) {
      let stepJump = 1;
      const now = new Date() / 1;
      if (playStep == null) {
        if (this.lastStepCalled) {
          const delay = now - this.lastStepCalled - this.lastStepJump * 333;
          if (delay >= 100) {
            stepJump = this.lastStepJump + 1;
          } else if (delay >= 30) {
            stepJump = this.lastStepJump;
          } else {
            stepJump = Math.max(1, this.lastStepJump - 1);
          }
        }
        this.lastStepCalled = now;
        this.lastStepJump = stepJump;
      }

      if (!this.heatmapPlaying) {
        return;
      } else if ((playStep != null || !this.heatmapPaused) && this.currDynamicStep < this.dynamicHeatmapData.war_list.length) {
        const randomPoints = this.dynamicHeatmapData.map_random_point;
        const item = this.dynamicHeatmapData.war_list[playStep || this.currDynamicStep];
        this.playTime = item.time || '';
        this.updateHeatmapData(item.area_count, this.dynamicHeatmapSearchParams, randomPoints);

        if (playStep == null) {
          this.currDynamicStep += stepJump;
        }
      }

      if (playStep == null) {
        if (this.currDynamicStep < this.dynamicHeatmapData.war_list.length) {
          this.dynamicHeatmapTimeout = setTimeout(this.playDynamicStep, 333 * stepJump - (new Date() / 1 - now));
        } else {
          this.dynamicHeatmapTimeout = setTimeout(this.showStaticHeatmap, 333 * stepJump - (new Date() / 1 - now));
        }
      }
    },
    playDynamicHeatmap() {
      const projectID = this.projectId || this.$route.params.id;
      var self = this;
      this.heatmapPlaying = true;
      this.playWorkerCount = '';
      var params = JSON.stringify(Object.assign({}, this.heatmapFilter, {
        project_id: projectID,
        t_increment: 10
      }));
      var cacheKey = this.s_build_id + JSON.stringify(this.heatmapFilter);

      if (this.dynamicHeatmapDataCache[cacheKey] && (new Date() / 1000 - this.dynamicHeatmapDataCache[cacheKey].ts <= 600)) {
        this.dynamicHeatmapData = this.dynamicHeatmapDataCache[cacheKey];
        this.currDynamicStep = 0;
        this.dynamicHeatmapSearchParams = params;
        this.dynamicHeatmapSpotWorkers = {};
        this.lastStepCalled = 0;
        this.lastStepJump = 1;
        this.dynamicHeatmapTimeout = setTimeout(this.playDynamicStep, 0);
      } else {
        clearHeatmapData('heatmapid');
        project
          .getDynamicHeatmapData(params)
          .then(dataObj => {
            dataObj.ts = new Date() / 1000;
            self.dynamicHeatmapDataCache[cacheKey] = dataObj;
            self.dynamicHeatmapData = dataObj;
            self.currDynamicStep = 0;
            self.dynamicHeatmapSearchParams = params;
            self.dynamicHeatmapSpotWorkers = {};
            self.lastStepCalled = 0;
            self.lastStepJump = 1;
            self.dynamicHeatmapTimeout = setTimeout(self.playDynamicStep, 0);
          })
          .catch(e => console.error(e.msg || e.toString()));
      }
    }
  },
}
</script>

<style scoped lang="less">
#heatmapid {
  width: 100%;
  height: 100vh;
}

.media-slider {
  height: 20px;
  margin-top: 10px;
}
.slider-trail {
  height: 2px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
.handle {
  position: absolute;
  top: 50%;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: -6px;
  margin-left: -6px;
  background-color: #75e6f5;
}

.qiehuan-button {
  background: url("../../assets/qiehuan2.png") no-repeat;
  width: 10rem;
  cursor: pointer;
  line-height: 16px;
  background-size: 100% 100%;
  text-align: center;
  padding: 5px;
}

.item-button {
  background-color: #181649;
  margin-top: -1px;
  border: 1px solid #365c83;
  width: 10rem;
  cursor: pointer;
  line-height: 16px;
  text-align: center;
  padding: 5px;
}
.item-button:first-child {
  margin-top: 0;
}
</style>
