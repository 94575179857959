const baseUrl = process.env.VUE_APP_API_BASE_URL
const wsUrl = process.env.VUE_APP_WS_BASE_URL

const handleRes = (res) => {
  if (res.statusCode < 200 || res.statusCode >= 300) {
    return res.response.text().then(e => Promise.reject(e))
  } else {
    return res.response.json().then(_d => {
      if (_d.code !== 0 && _d.code !== 200) {
        return Promise.reject(_d)
      } else if (_d.data) {
        _d.data.__count__ = _d.count;
        return _d.data;
      } else {
        return _d;
      }
    })
  }
}

function addFilterParams(url) {
  const containsArea = url.indexOf('areaLvl=') >= 0;
  const containsOrg = url.indexOf('orgLvl=') >= 0;
  if (!containsArea || !containsOrg) {
    const sign = url.indexOf('?') >= 0 ? '&' : '?';
    const areaStr = `areaLvl=${window.$districtLevel || '100000'}`;
    const orgStr = `orgLvl=${window.$orgLevel || ''}`;
    if (!containsArea && !containsOrg) {
      return url + sign + areaStr + '&' + orgStr;
    } else {
      return url + sign + (!containsArea ? areaStr : orgStr);
    }
  } else {
    return url;
  }
}

const get = url => fetch(addFilterParams(baseUrl + url)).then(res => ({ response: res, statusCode: res.status })).then(handleRes)

const post = (url, body, type = 'json') => {
  let headers, _body

  if (type === 'json') {
    headers = { 'Content-Type': 'application/json' }
    _body = JSON.stringify(body)
  } else if (type === 'file') {
    _body = new FormData()
    _body.append('files', body)
  } else {
    _body = body
  }

  if (url.slice(0, 4) != 'http') {
    url = addFilterParams(baseUrl + url);
  }

  return fetch(url, { method: 'POST', headers, body: _body })
    .then(res => ({ response: res, statusCode: res.status })).then(handleRes)
}

const nation = {
  getAttendance: orgID => get(`bigscreen/country/checktime/collect/` + orgID),
  getTop10LevelOneTroublePoints: (orgID) => get(`nationwide/trouble/one/level/list?org_id=${orgID}&token=${window.token}`),
  getHeatmapProjects: () => get(`nationwide/heatmap/project/list?token=${window.token}`),

  getProjectToken: (projectId) => post(`dashboard/proj/old/login/${projectId}?token=${window.token}`, '', ''),
  getLaborCompareOrgProjectCollect: (mode, search_key, limit, orgLvl) => get(`dashboard/schedule/labor_compare/date/org_project/collect?token=${window.token}&mode=${mode}&search_key=${search_key}&limit=${limit}&orgLvl=${orgLvl}`),
  getLaborCompareCollect: (mode,orgLvl) => get(`dashboard/schedule/labor_compare/date/collect?token=${window.token}&mode=${mode}&orgLvl=${orgLvl}`),
  getOrgSelect: () => get(`dashboard/general/org/select?areaLvl=100000&orgLvl=&token=${window.token}`),
  getMonth12AttendanceInfo: (mode) => get(`dashboard/labor/month12/attendance_info?token=${window.token}&mode=${mode}`),
  getLaborMap: () => get(`dashboard/labor/area/worker_count?token=${window.token}`),
  getLaborOrgWorkerCount: (mode) => get(`dashboard/labor/org/worker_count?token=${window.token}&mode=${mode}`),
  getLaborSalaryCollect: (mode) => get(`dashboard/labor/salary/collect?token=${window.token}&mode=${mode}`),
  getLaborCompanyWorkerProjectInfo: (mode) => get(`dashboard/labor/company/worker_project_info?token=${window.token}&mode=${mode}`),
  getLaborMonth12PetitionCollect: (mode) => get(`dashboard/labor/month12/petition/collect?token=${window.token}&mode=${mode}`),
  getLaborMonth12SporadicCollect: (mode) => get(`dashboard/labor/month12/sporadic/collect?token=${window.token}&mode=${mode}`),
  getProjectMap: () => get(`dashboard/project/area/project_count?token=${window.token}`),
  getProjectList: () => get(`dashboard/labor/city/project/worker_count?token=${window.token}`),
  getLaborAttendanceDateCollect: (mode, orgLvl, mode1) => get(`dashboard/labor/attendance/date/collect?token=${window.token}&mode=${mode}&orgLvl=${orgLvl}&mode1=${mode1}`),
  getLaborAttendanceDateOrgProjectCollect: (mode, search_key, limit, orgLvl, mode1) => get(`dashboard/labor/attendance/date/org_project/collect?token=${window.token}&mode=${mode}&search_key=${search_key}&limit=${limit}&orgLvl=${orgLvl}&mode1=${mode1}`),
  getLaborWorkerCountOrgCollect: () => get(`dashboard/labor/workercount/org/collect?token=${window.token}`),
  getLaborWorkerCountWorkerAttrCollect: (mode, orgLvl) => get(`dashboard/labor/workercount/worker_attr/collect?token=${window.token}&mode=${mode}&orgLvl=${orgLvl}`),
  getSafetyBroadcast: () => get(`dashboard/safety/broadcast/detail?token=${window.token}`),
  getSafetyOrgCityHatChargingRateCollect: () => get(`dashboard/safety/org_city/hat_charging_rate/collect?token=${window.token}`),
  getSafetyOrgCitySafeBehaviorStarCount: () => get(`dashboard/safety/org_city/safe_behavior_star/count?token=${window.token}`),
  getSafetyOrgCityWarningAlarmCount: (check_type) => get(`dashboard/safety/org_city/warning_alarm/count?token=${window.token}&check_type=${check_type}`),
  getSafetyMonth12WarningAlarmCount: (check_type, orgLvl) => get(`dashboard/safety/month12/warning_alarm/collect?token=${window.token}&check_type=${check_type}&orgLvl=${orgLvl}`),
  getSafetyWorkerTrainCollect: (mode) => get(`dashboard/safety/worker/train/collect?token=${window.token}&mode=${mode}`),
  getSafetyDangerBroadcast: () => get(`dashboard/safety/major_dangerous/broadcast/detail?token=${window.token}`),
  getSafetyMechBroadcast: () => get(`dashboard/safety/machine/broadcast/detail?token=${window.token}`),
  getSafetyCheckBroadcast: () => get(`dashboard/safety/trouble/broadcast/detail?token=${window.token}`),
  getSafetyPersonnelBroadcast: () => get(`dashboard/safety/personnel_security/broadcast/detail?token=${window.token}`),
  getSafetyDangerMap: () => get(`dashboard/safety/area/major_dangerous_count?token=${window.token}`),
  getSafetyMechMap: () => get(`dashboard/safety/area/machine_count?token=${window.token}`),
  getSafetyCheckMap: () => get(`dashboard/safety/area/trouble_count?token=${window.token}`),
  getSafetyPersonnelMap: () => get(`dashboard/safety/area/alarm_count?token=${window.token}`),
  getSafetyDangerList: () => get(`dashboard/safety/city/project/major_dangerous_count?token=${window.token}`),
  getSafetyMechList: () => get(`dashboard/safety/city/project/machine_count?token=${window.token}`),
  getSafetyCheckList: () => get(`dashboard/safety/city/project/undo_trouble_count?token=${window.token}`),
  getSafetyPersonnelList: () => get(`dashboard/safety/city/project/alarm_count?token=${window.token}`),
  getSafetyOrgCityTroubleCollect: (mode) => get(`dashboard/safety/org_city/trouble/collect?token=${window.token}&mode=${mode}`),
  getSafetyOrgCityUndoCollect: () => get(`dashboard/safety/trouble/org_city/undo/collect?token=${window.token}`),
  getSafetyOrgCityCheckItemCollect: () => get(`dashboard/safety/trouble/check_item/collect?token=${window.token}`),
  getSafetyMonth12InspectionCollect: (mode) => get(`dashboard/safety/month12/inspect/collect?token=${window.token}&mode=${mode}`),
  getSafetyMonth12HeadCheckCollect: (mode) => get(`dashboard/safety/trouble/head_check/collect?token=${window.token}&mode=${mode}`),
  getSafeTroubleTroubleItem10Collect: (limit,orgLvl) => get(`dashboard/safety/trouble/trouble_item10/collect?token=${window.token}&limit=${limit}&orgLvl=${orgLvl}`),
  getSafetyDangerTypes: () => get(`dashboard/general/major_dangerous_type/select?token=${window.token}`),
  getSafetyDangerLevels: () => get(`dashboard/safety/org_city/major_dangerous/collect?token=${window.token}`),
  getSafetyDangerTypeCounts: (orgId) => get(`dashboard/safety/major_dangerous/type/count?orgLvl=${orgId}&token=${window.token}`),
  getSafetyDangerCheckRates: () => get(`dashboard/safety/month12/major_dangerous_check/collect?token=${window.token}`),
  // getSafetyDangerPassRates: () => get(`dashboard/safety/org_city/major_dangerous_pass_rate/collect?token=${window.token}`),
  // getSafetyDangerTroubleTypeCounts: () => get(`dashboard/safety/major_dangerous/template_trouble/count?token=${window.token}`),
  getScheduleMonth12OutputCollect: (mode) => get(`dashboard/schedule/month12/output/collect?token=${window.token}&mode=${mode}`),
  getScheduleProjectNearEndCollect: (limit) => get(`dashboard/schedule/project/near_end/collect?token=${window.token}&limit=${limit}`),
  getScheduleProjectDelayedCountCollect: () => get(`dashboard/schedule/project/delayed_count/collect?token=${window.token}`),
  getScheduleOrgCityNearEndCollect: () => get(`dashboard/schedule/org_city/near_end/collect?token=${window.token}`),

  getScheduleProjectDelayedDaysCollect: () => get(`dashboard/schedule/project/delayed_days/collect?token=${window.token}`),
  getScheduleProjectLaborCompareCollect: (orgLvl) => get(`dashboard/schedule/labor_compare/collect?token=${window.token}&orgLvl=${orgLvl}`),
  getScheduleProjectLaborSurplusCollect: () => get(`dashboard/schedule/labor/surplus/collect?token=${window.token}`),
  getSafetyDangerPassRates: (categoryId) => get(`dashboard/safety/org_city/major_dangerous_pass_rate/collect?eng_type_id=${categoryId}&token=${window.token}`),
  getSafetyDangerTroubleTypeCounts: (categoryId) => get(`dashboard/safety/major_dangerous/template_trouble/count?eng_type_id=${categoryId}&token=${window.token}`),
  getScheduleBroadcast: () => get(`dashboard/schedule/broadcast/detail?token=${window.token}`),
  getScheduleMap: () => get(`dashboard/schedule/area/project_delayed_count?token=${window.token}`),
  getScheduleList: () => get(`dashboard/schedule/city/project/project_delayed_count?token=${window.token}`),
  getManagerBroadcast: () => get(`dashboard/manager/broadcast/detail?token=${window.token}`),
  getManagerMap: () => get(`dashboard/manager/area/manager_attendance_rate?token=${window.token}`),
  getManagerList: () => get(`dashboard/manager/city/project/manager_attendance_rate?token=${window.token}`),
  getEmergencyAlarmCountLevelCollect: () => get(`dashboard/emergency/alarm_count/level/collect?token=${window.token}`),
  getEmergencyAlarmCountSourceCollect: () => get(`dashboard/emergency/alarm_count/source/collect?token=${window.token}`),
  getEmergencyParamsSelect: (type) => get(`dashboard/emergency/params/select?token=${window.token}&type=${type}`),
  getSafetyTroubleHeadCheckDateCollect: (mode,date_mode,orgLvl) => get(`dashboard/safety/trouble/head_check/date/collect?token=${window.token}&mode=${mode}&orgLvl=${orgLvl}&date_mode=${date_mode}`),
  getSafetyTroubleHeadCheckCountCollect: (mode, search_key, orgLvl, page, limit, date_mode) => get(`dashboard/safety/trouble/head_check/count/collect?token=${window.token}&mode=${mode}&search_key=${search_key}&orgLvl=${orgLvl}&limit=${limit}&page=${page}&date_mode=${date_mode}`),
  getSafetyTroubleHeadCheckDetailCollect: (mode, search_key, page, limit, person_id, orgLvl, date_mode) => get(`dashboard/safety/trouble/head_check/detail/collect?token=${window.token}&orgLvl=${orgLvl}&mode=${mode}&search_key=${search_key}&limit=${limit}&page=${page}&person_id=${person_id}&date_mode=${date_mode}`),
  getSafetyTroubleHeadCheckProjectDetailCollect: (mode, search_key, page, limit, person_id, orgLvl) => get(`dashboard/safety/trouble/head_check/project_detail/collect?token=${window.token}&orgLvl=${orgLvl}&date_mode=${mode}&search_key=${search_key}&limit=${limit}&page=${page}&person_id=${person_id}`),
  getScheduleLaborSurplusWorkerTypeCollect: (mode) => get(`dashboard/schedule/labor/surplus/worker_type/collect?token=${window.token}&mode=${mode}`),
  getGeneralParamsSelect: (type_id) => get(`dashboard/general/params/select?token=${window.token}&type_id=${type_id}`),
  getScheduleLaborSurplusWorkerTypeDetailCollect: (limit, mode, search_key, status) => get(`dashboard/schedule/labor/surplus/worker_type/detail/collect?token=${window.token}&limit=${limit}&mode=${mode}&search_key=${search_key}&status=${status}`),

  getEmergencyMap: () => get(`dashboard/emergency/area/alarm_count?token=${window.token}`),
  getEmergencyAlarmDetailCollect: (alarm_id) => get(`dashboard/emergency/alarm/detail/collect?token=${window.token}&alarm_id=${alarm_id}`),
  getEmergencyAlarmRequireCollect: (alarm_id) => get(`dashboard/emergency/alarm/require/collect?token=${window.token}&alarm_id=${alarm_id}`),
  getEmergencyAlarmHandleCollect: (require_id) => get(`dashboard/emergency/alarm/handle/collect?token=${window.token}&require_id=${require_id}`),
  getEmergencyAlarmHandleFinishCollect: (handle_id) => get(`dashboard/emergency/alarm/handle/finish/collect?token=${window.token}&handle_id=${handle_id}`),

  // 12-16新增页面接口
  getLaborWorkerCountCollect: (mode, page, limit, orgLvl, areaLvl) => get(`dashboard/labor/project/worker_count/detail/collect?token=${window.token}&mode=${mode}&limit=${limit}&page=${page}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getLaborSalaryDateCollect: (mode,  orgLvl) => get(`dashboard/labor/salary/date/collect?token=${window.token}&mode=${mode}&orgLvl=${orgLvl}`),
  getLaborSalaryOrgProjectCollect: (mode, page, limit, orgLvl, search_key) => get(`dashboard/labor/salary/org_project/collect?token=${window.token}&mode=${mode}&limit=${limit}&page=${page}&orgLvl=${orgLvl}&search_key=${search_key}`),
  getLaborSporadicDateCollect: (mode,date_mode,orgLvl, page, limit,) => get(`dashboard/labor/sporadic/date/collect?token=${window.token}&mode=${mode}&date_mode=${date_mode}&orgLvl=${orgLvl}&limit=${limit}&page=${page}`),
  getLaborSporadicOrgProjectCollect: (mode, date_mode, page, limit, orgLvl, search_key) => get(`dashboard/labor/sporadic/org_project/collect?token=${window.token}&mode=${mode}&date_mode=${date_mode}&limit=${limit}&page=${page}&orgLvl=${orgLvl}&search_key=${search_key}`),
  getLaborPetitionDateCollect: () => get(`dashboard/labor/petition/date/collect?token=${window.token}`),
  getLaborPetitionDetailCollect: (search_key, page, limit,) => get(`dashboard/labor/petition/detail/collect?token=${window.token}&search_key=${search_key}&limit=${limit}&page=${page}`),

  getProjectOrgCityDetailCollect: (status, page, limit, orgLvl, areaLvl) => get(`dashboard/project/org_city/detail/collect?token=${window.token}&status=${status}&limit=${limit}&page=${page}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getProjectHistoryStatusDetailCollect: (status, page, limit,search_key) => get(`dashboard/project/history/status/detail/collect?token=${window.token}&status=${status}&limit=${limit}&page=${page}&search_key=${search_key}`),
  getSafetyInspectOrgCityCollect: (mode,search_key) => get(`dashboard/safety/inspect/org_city/detail/collect?token=${window.token}&mode=${mode}&search_key=${search_key}`),
  getSafetyInspectProjectCollect: (search_key, page, limit, orgLvl, areaLvl) => get(`dashboard/safety/inspect/project/detail/collect?token=${window.token}&search_key=${search_key}&limit=${limit}&page=${page}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getSafetyProjectTroubleCollect: (mode, page, limit, orgLvl, areaLvl) => get(`dashboard/safety/project/trouble/detail/collect?token=${window.token}&mode=${mode}&limit=${limit}&page=${page}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getSafetyProjectTroubleUndoCollect: (orgLvl, areaLvl) => get(`dashboard/safety/project/trouble/undo/detail/collect?token=${window.token}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getSafetyTroubleCheckItem2Collect: (search_key) => get(`dashboard/safety/trouble/check_item2/detail/collect?token=${window.token}&search_key=${search_key}`),
  getSafetyTroubleCheckItem3Collect: (search_key, page, limit,) => get(`dashboard/safety/trouble/check_item3/detail/collect?token=${window.token}&search_key=${search_key}&limit=${limit}&page=${page}`),
  getWorkerTrainDetailCollect: (mode, page, limit, orgLvl, areaLvl) => get(`dashboard/safety/worker/train/detail/collect?token=${window.token}&mode=${mode}&limit=${limit}&page=${page}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getSafetyHatChargingRateCollect: ( page, limit, orgLvl, areaLvl) => get(`dashboard/safety/org_city/hat_charging_rate/detail/collect?token=${window.token}&limit=${limit}&page=${page}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getSafetySafeBehaviorStarCollect: ( page, limit, orgLvl, areaLvl) => get(`dashboard/safety/org_city/safe_behavior_star/detail/collect?token=${window.token}&limit=${limit}&page=${page}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getSafetyWarningAlarmCollect: ( check_type,page, limit, orgLvl, areaLvl) => get(`dashboard/safety/org_city/warning_alarm/detail/collect?token=${window.token}&check_type=${check_type}&limit=${limit}&page=${page}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getManagerQuarterCheckListCollect: ( page, limit) => get(`dashboard/manager/quarter_check/org/check/list?token=${window.token}&limit=${limit}&page=${page}`),
  getManagerQuarterSourceList: ( check_id) => get(`dashboard/manager/quarter_check/source/list?token=${window.token}&check_id=${check_id}`),
  getManagerQuarterOrgCityCollect: ( check_id,source_id ) => get(`dashboard/manager/quarter_check/org_city/detail/collect?token=${window.token}&check_id=${check_id}&source_id=${source_id}`),
  getManagerQuarterProjectCollect: (check_id, mode, page, limit, orgLvl, areaLvl) => get(`dashboard/manager/quarter_check/project/detail/collect?token=${window.token}&check_id=${check_id}&mode=${mode}&limit=${limit}&page=${page}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),

  getProjectDelayedDaysCollect: (page, limit, orgLvl, areaLvl) => get(`dashboard/schedule/project/delayed_days/collect?token=${window.token}&limit=${limit}&page=${page}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getScheduleOutputDateCollect: (page, limit, mode, orgLvl) => get(`dashboard/schedule/output/date/collect?token=${window.token}&limit=${limit}&page=${page}&mode=${mode}&orgLvl=${orgLvl}`),
  getScheduleOutputOrgProjectCollect: (page, limit, mode, orgLvl, search_key) => get(`dashboard/schedule/output/org_project/collect?token=${window.token}&limit=${limit}&page=${page}&mode=${mode}&orgLvl=${orgLvl}&search_key=${search_key}`),
  getManagerAttendanceRateDetailCollect: (mode, page, limit, orgLvl, areaLvl) => get(`dashboard/manager/org_city/manager_attendance_rate/detail/collect?token=${window.token}&is_dpt_leader=${mode}&limit=${limit}&page=${page}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),

  // 防碰撞接口:

  getSafetyAntiCollision: () => get(`dashboard/safety/collision/broadcast/detail?token=${window.token}`),
  getSafetyAntiMap: () => get(`dashboard/safety/area/collision/list?token=${window.token}`),
  getSafetyAntiList: () => get(`dashboard/safety/city/project/collision/list?token=${window.token}`),
  getSafetyAntiInstallationRates: () => get(`dashboard/safety/org_city/collision/installation/rates?token=${window.token}`),
  getSafetyAntiOnlineRates: () => get(`dashboard/safety/org_city/collision/online/rates?token=${window.token}`),
  getSafetyAnti7DaysCollect: () => get(`dashboard/safety/org_city/collision/7days/collect?token=${window.token}`),
  getSafetyAlarm7DaysList: (start_date,end_date) => get(`dashboard/safety/org_city/collision_alarm/7days/list?token=${window.token}&start_date=${start_date}&end_date=${end_date}`),
  getSafetyAlarmTypeCollect: (start_date,end_date,orgLvl) => get(`dashboard/safety/org_city/collision_alarm_type/collect?token=${window.token}&start_date=${start_date}&end_date=${end_date}&orgLvl=${orgLvl}`),
  getSafetyAntiInstallationCollect: () => get(`dashboard/safety/collision/installation_situation/collect?token=${window.token}`),
  getSafetyAntiInstallationProjectCollect: (search_type,search_key,page,limit) => get(`dashboard/safety/collision/installation_project/collect?token=${window.token}&limit=${limit}&page=${page}&search_type=${search_type}&search_key=${search_key}`),
  getSafetyAntiOnlineCollect: () => get(`dashboard/safety/collision/online_situation/collect?token=${window.token}`),
  getSafetyAntiOnlineProjectCollect: (search_type,search_key,page,limit) => get(`dashboard/safety/collision/online_project/collect?token=${window.token}&limit=${limit}&page=${page}&search_type=${search_type}&search_key=${search_key}`),
  getSafetyAntiProjectAlarmTypeCollect: (start_date,end_date,orgLvl,page,limit) => get(`dashboard/safety/collision_project_alarm_type/collect?token=${window.token}&limit=${limit}&page=${page}&start_date=${start_date}&end_date=${end_date}&orgLvl=${orgLvl}`),
  //产值接口

  // 实时播报（年度新开项目数，年度已竣工项目数，计划竣工项目数，在施项目数，出勤率）
  getOutputBroadcast: () => get(`dashboard/output/broadcast/detail?token=${window.token}`),
  getOutputMap: () => get(`dashboard/output/area/month_output_count?token=${window.token}`),
  getOutputList: () => get(`dashboard/output/city/project/month_output_count?token=${window.token}`),
  getOutputTotalPie: (mode) => get(`dashboard/output/total/pie/collect?token=${window.token}&mode=${mode}`),
  getOutputTotalPieDetail: (mode, page, limit, orgLvl, areaLvl) => get(`dashboard/output/total/pie/detail?token=${window.token}&mode=${mode}&limit=${limit}&page=${page}&areaLvl=${areaLvl}&orgLvl=${orgLvl}`),
  getOutputMonth12Collect: (start_date, end_date, orgLvl) => get(`dashboard/output/month12/total/collect?token=${window.token}&start_date=${start_date}&end_date=${end_date}&orgLvl=${orgLvl}`),
  getOutputMonth12CollectDetail: (search_key,page,limit, orgLvl) => get(`dashboard/output/month12/total/detail?token=${window.token}&limit=${limit}&page=${page}&search_key=${search_key}&orgLvl=${orgLvl}`),
  getOutputSerialCollect: (search_date) => get(`dashboard/output/serial/collect?token=${window.token}&search_date=${search_date}`),
  getOutputValidDateCollect: (mode, page, limit,) => get(`dashboard/output/valid_date/collect?token=${window.token}&mode=${mode}&limit=${limit}&page=${page}`),
  getOutputCompletionRate: (search_date) => get(`dashboard/output/completion_rate/collect?token=${window.token}&search_date=${search_date}`),
  getOutputProjectSort: (t_mode,mode,sort_mode,search_date,page,limit) => get(`dashboard/output/project_sort/collect?token=${window.token}&mode=${mode}&limit=${limit}&page=${page}&t_mode=${t_mode}&sort_mode=${sort_mode}&search_date=${search_date}`),

  // ///赛马平台
  getHorseRacingBuildList:()  =>get(`dashboard/org/horse/racing/jyjz/list?token=${window.token}`),
// 视频监控
  getOrgList: (project_name) => get(`dashboard/common/org/list?token=${window.token}&project_name=${project_name}`),
  getOrgVideoList: (project_id) => get(`dashboard/proj/video/video/collect?token=${window.token}&project_id=${project_id}`),

  // 安全帽使用接口
  // /
  getHatBroadcast: () => get(`dashboard/hat/broadcast/detail?token=${window.token}`),
  getHatAreaCount: (type) => get(`dashboard/hat/area/count?token=${window.token}&type=${type}`),
  getHatOrgCityCollect: (is_cut, orgLvl, areaLvl) => get(`dashboard/hat/org_city/ratio/collect?token=${window.token}&is_cut=${is_cut}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getHatOrgCityPlanCollect: (is_cut,type, orgLvl, areaLvl) => get(`dashboard/hat/org_city/plan/collect?token=${window.token}&is_cut=${is_cut}&type=${type}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getHatUseCollect: (type, orgLvl, areaLvl) => get(`dashboard/hat/use/collect?token=${window.token}&type=${type}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getHatOrgCityAttendanceCollect: (is_cut,type, orgLvl, areaLvl) => get(`dashboard/hat/org_city/attendance/collect?token=${window.token}&is_cut=${is_cut}&type=${type}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getHatOrgCityNeWorkerCollect: (is_cut,type, orgLvl, areaLvl) => get(`dashboard/hat/org_city/ne/worker/collect?token=${window.token}&is_cut=${is_cut}&type=${type}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  getHatOrgCityManagerCollect: (is_cut,type, orgLvl, areaLvl) => get(`dashboard/hat/org_city/ne/manager/collect?token=${window.token}&is_cut=${is_cut}&type=${type}&orgLvl=${orgLvl}&areaLvl=${areaLvl}`),
  
// ////








}

const project = {
  //getBuildingBaseInfo: projectID => get(`bigscreen/project/build/collect/` + projectID),
  getProjectInfo: projectID => get(`bigscreen/project/info/` + projectID),
  getLiveData: projectID => get(`bigscreen/project/detail/collect/` + projectID),
  getAttendance: projectID => get(`bigscreen/project/check/collect/` + projectID),
  getWarningList: projectID => get(`bigscreen/project/alarm/list/${projectID}`),
  getWarningStats: projectID => get(`bigscreen/project/alarm/type/collect?project_id=${projectID}`),
  getElectricBoxPatrolData: (searchParams) => get(`dashboard/proj/old/safepatrol/electricity/get_electricity_box_patrol_collect?searchParams=${searchParams}&token=${window.token}`),

  getBuildingFloorHeights: projectID => get(`bigscreen/bim/project/floor_info?project_id=${projectID}`),
  getDeptList: projectID => get(`bigscreen/pcmerge/select/list?type_id=4&project_id=${projectID}`),
  getMgmStats: searchParams => get(`bigscreen/pcmerge/manager/attendance/date_range?searchParams=${searchParams}&token=${window.token}`),
  getSecurityStars: (projectID, page, pageSize) => get(`bigscreen/safe/appraise/worker/${projectID}?page=${page}&limit=${pageSize}`),
  getWorkerInOuts: (projectID, page, pageSize) => get(`bigscreen/pcmerge/worker/attendance/inout_info?project_id=${projectID}&page=${page}&limit=${pageSize}`),
  getSevenDayAlarms: projectID => get(`bigscreen/safe/alarm/type/${projectID}`),
  getProjectCompanyAttendance: projectID => get(`bigscreen/pcmerge/worker/analysis/get_project_company_attendance_collect?project_id=${projectID}`),

  //新接口///
  getBuildingBaseInfo: projectID => get(`dashboard/proj/general/build/collect?project_id=${projectID}&token=${window.token}`),
  getLaborBroadcast: () => get(`dashboard/proj/labor/broadcast/detail?token=${window.token}`),
  getSafetyBroadcast: () => get(`dashboard/proj/safety/broadcast/detail?token=${window.token}`),
  getSafetyDangerBroadcast: () => get(`dashboard/proj/safety/md/broadcast/detail?token=${window.token}`),
  getSafetyMechBroadcast: () => get(`dashboard/proj/safety/machine/broadcast/detail?token=${window.token}`),
  getSafetyCheckBroadcast: () => get(`dashboard/proj/safety/trouble/broadcast/detail?token=${window.token}`),
  getSafetyPersonnelBroadcast: () => get(`dashboard/proj/safety/personnel/broadcast/detail?token=${window.token}`),
  getScheduleBroadcast: () => get(`dashboard/proj/schedule/broadcast/detail?token=${window.token}`),
  getManagerBroadcast: () => get(`dashboard/proj/manager/broadcast/detail?token=${window.token}`),

  getWorkerList: buildid => get(`dashboard/proj/old/project/online/worker/list/${buildid}?token=${window.token}`),
  getAllWorkers: projectID => get(`dashboard/proj/old/bim/project/online/worker/list?project_id=${projectID}&token=${window.token}`),
  getWorkerTrace: (worker_id) => get(`dashboard/proj/old/checktime/worker/trace?worker_id=${worker_id}&token=${window.token}`),
  getWorkerTrace3D: (worker_id) => get(`dashboard/proj/old/bim/project/online/worker/trace?worker_id=${worker_id}&token=${window.token}`),
  getWorkerTraceArea: (worker_id) => get(`dashboard/proj/old/checktime/worker/trace/areacount?worker_id=${worker_id}&token=${window.token}`),
  getHeatmapData: (searchParams) => get(`dashboard/proj/old/hatmap/areav1?searchParams=${searchParams}&token=${window.token}`),
  getDynamicHeatmapData: (searchParams) => get(`dashboard/proj/old/hotmap/get_hotmap_areacount_day?searchParams=${searchParams}&token=${window.token}`),

  getGeneralProjectSelect: (project_name) => get(`dashboard/general/project/select?project_name=${project_name}&token=${window.token}`),
  getGeneralParamsSelect: (type_id) => get(`dashboard/general/params/select?type_id=${type_id}&token=${window.token}`),
  getLaborBroadcastDetail: () => get(`dashboard/labor/broadcast/detail?token=${window.token}`),
  getLaborCompanyList: (page, pageSize,mode,orgLvl,pro_category) => get(`dashboard/labor/company/list?page=${page}&limit=${pageSize}&mode=${mode}&orgLvl=${orgLvl}&pro_category=${pro_category}&token=${window.token}`),
  getLaborCompanyProjectList: (page, pageSize,company_id) => get(`dashboard/labor/company/project_list?page=${page}&limit=${pageSize}&company_id=${company_id}&token=${window.token}`),
  getDashboardBroadcastDetail: () => get(`dashboard/project/broadcast/detail?token=${window.token}`),
  getDashboardAreaProjectList: () => get(`dashboard/project/area/project_count?token=${window.token}`),
  getDashboardOrgCityCollect: () => get(`dashboard/project/org_city/collect?token=${window.token}`),
  getDashboardMonth12Collect: () => get(`dashboard/project/month12/status/collect?token=${window.token}`),
  getDashboardProjectNewCollect: (page, pageSize) => get(`dashboard/project/new/collect?page=${page}&limit=${pageSize}&token=${window.token}`),
  getDashboardMachineCollect: () => get(`dashboard/safety/org_city/machine/collect?token=${window.token}`),
  getDashboardMachineProduceYearCollect: (machine_type) => get(`dashboard/safety/machine/produce_year/collect?machine_type=${machine_type}&token=${window.token}`),
  getDashboardMachineJointRateCollect: (machine_type) => get(`dashboard/safety/machine/joint_rate/collect?machine_type=${machine_type}&token=${window.token}`),
  getDashboardMachineCheckQualifiedRateCollect: (machine_type) => get(`dashboard/safety/machine/check_qualified_rate/collect?machine_type=${machine_type}&token=${window.token}`),
  getDashboardMachineTroubleCollect: (machine_type) => get(`dashboard/safety/machine/trouble/collect?machine_type=${machine_type}&token=${window.token}`),
  getDashboardMachineRegisterCollect: (machine_type) => get(`dashboard/safety/machine/register/collect?machine_type=${machine_type}&token=${window.token}`),
  getDashboardManagerAttendanceRateCollect: (is_dpt_leader) => get(`dashboard/manager/org_city/manager_attendance_rate/collect?is_dpt_leader=${is_dpt_leader}&token=${window.token}`),
  getDashboardManagerHeadSinkAttendanceCollect: (check_type) => get(`dashboard/manager/month12/head_sink_attendance/collect?check_type=${check_type}&token=${window.token}`),
  getDashboardManagerQuarterCheckTroubleCollect: () => get(`dashboard/manager/org_city/quarter_check_trouble/collect?token=${window.token}`),
  getDashboardManageOrgCityCount: () => get(`dashboard/manager/org_city/manager/count?token=${window.token}`),
  getDashboardManageDeptManagerDistributeCount: () => get(`dashboard/manager/org_city/dept_manager_distribute/count?token=${window.token}`),
  getDashboardManageDeptManagerCount: (areaLvl,orgLvl,project_id) => get(`dashboard/manager/org_city/dept_manager_count/list?&token=${window.token}&areaLvl=${areaLvl}&orgLvl=${orgLvl}&project_id=${project_id}`),
  getDashboardManageProjectManagerCount: (areaLvl,orgLvl,project_id,page, pageSize) => get(`dashboard/manager/org_city/project_manager_count/list?token=${window.token}&areaLvl=${areaLvl}&orgLvl=${orgLvl}&project_id=${project_id}&page=${page}&limit=${pageSize}`),
  getDashboardSurplusTotalCollect: (orgLvl) => get(`dashboard/schedule/labor/surplus/total/collect?token=${window.token}&orgLvl=${orgLvl}`),
  getDashboardSurplusWorkerTypeCollect: (orgLvl,mode) => get(`dashboard/schedule/labor/surplus/worker_type/collect?token=${window.token}&orgLvl=${orgLvl}&mode=${mode}`),
  getDashboardSurplusWorkerTypeOrgCollect: (search_key,mode) => get(`dashboard/schedule/labor/surplus/worker_type/org/collect?token=${window.token}&search_key=${search_key}&mode=${mode}`),
  getDashboardSurplusWorkerTypeDetailCollect: (search_key,mode,status,orgLvl) => get(`dashboard/schedule/labor/surplus/worker_type/detail/collect?token=${window.token}&search_key=${search_key}&mode=${mode}&status=${status}&orgLvl=${orgLvl}`),

  // 劳务模块新接口/////
  getDashProjWorkerAttendanceList: (page, limit) => get(`dashboard/proj/worker/attendance/error/list?token=${window.token}&page=${page}&limit=${limit}`),
  getDashProjLaborInoutCollect: (page, limit, project_id) => get(`dashboard/proj/labor/inout/collect?token=${window.token}&page=${page}&limit=${limit}&project_id=${project_id}`),
  getDashProjLaborAttenceDate7Collect: (project_id) => get(`dashboard/proj/labor/attendance/date7/collect?token=${window.token}&project_id=${project_id}`),
  getDashProjLaborAttenceDate10Collect: (project_id) => get(`dashboard/proj/labor/attendance/date10/collect?token=${window.token}&project_id=${project_id}`),
  getDashProjLaborOnlineWorkerTypeCollect: (limit, project_id) => get(`dashboard/proj/labor/online/worker_type/collect?token=${window.token}&limit=${limit}&project_id=${project_id}`),
  getDashProjLaborAttendanceCompanyCollect: (limit, project_id, mode) => get(`dashboard/proj/labor/attendance/company/collect?token=${window.token}&limit=${limit}&project_id=${project_id}&mode=${mode}`),
  getDashProjLaborMonth12SporadicCollect: (mode, project_id) => get(`dashboard/proj/labor/month12/sporadic/collect?token=${window.token}&mode=${mode}&project_id=${project_id}`),
  getProjTodayWorkersPerType: (projectID, searchParams) => get(`dashboard/proj/labor/get_project_workertype_attendance_collect?token=${window.token}&project_id=${projectID}&searchParams=${searchParams}`),
  getProjTodayWorkersPerCompany: (projectID) => get(`dashboard/proj/labor/get_project_company_attendance_collect?token=${window.token}&project_id=${projectID}`),
  getProjTodayWorkersPerTeam: (projectID, searchParams) => get(`dashboard/proj/labor/get_project_team_attendance_collect?token=${window.token}&project_id=${projectID}&searchParams=${searchParams}`),
  getProjTodayWorkersPerArea: (projectID, searchParams) => get(`dashboard/proj/labor/get_project_build_attendance_collect?token=${window.token}&project_id=${projectID}&searchParams=${searchParams}`),
  getProjTodayAbsentWorkersPerCompany: (projectID) => get(`dashboard/proj/labor/get_project_company_noattendance_collect?token=${window.token}&project_id=${projectID}`),
  getProjTodayAbsentWorkersPerTeam: (projectID, searchParams) => get(`dashboard/proj/labor/get_project_team_noattendance_collect?token=${window.token}&project_id=${projectID}&searchParams=${searchParams}`),
  getProjHistoryWorkerNumData: (projectID) => get(`dashboard/proj/labor/get_project_attendance_history?token=${window.token}&project_id=${projectID}`),
  getProjHistoryWorkerDataPerCompany: (projectID) => get(`dashboard/proj/labor/get_project_company_attendance_history?token=${window.token}&project_id=${projectID}`),
  getProjLaborTodayWorkersPerType: (projectID, searchParams) => get(`dashboard/proj/labor/get_project_workertype_attendance_collect?token=${window.token}&project_id=${projectID}&searchParams=${searchParams}`),
  getProjLaborTodayWorkersPerArea: (projectID, searchParams) => get(`dashboard/proj/labor/get_project_build_attendance_collect?token=${window.token}&project_id=${projectID}&searchParams=${searchParams}`),
  getProjAttendance10Days: (projectID) => get(`dashboard/proj/labor/get_project_attendance_10days?token=${window.token}&project_id=${projectID}`),
  getProjAttendance10DaysPerWorkerType: (projectID, searchParams) => get(`dashboard/proj/labor/get_project_workertype_attendance_10days?token=${window.token}&project_id=${projectID}&searchParams=${searchParams}`),
  getProjAttendance10DaysPerCompany: (projectID, searchParams) => get(`dashboard/proj/labor/get_project_company_attendance_10days?token=${window.token}&project_id=${projectID}&searchParams=${searchParams}`),
  getProjAttendance10DaysPerTeam: (projectID, searchParams) => get(`dashboard/proj/labor/get_project_team_attendance_10days?token=${window.token}&project_id=${projectID}&searchParams=${searchParams}`),
  getProjAttendance10DaysWorkerList: (projectID, searchParams) => get(`dashboard/proj/labor/get_project_attendance_10days_list?token=${window.token}&project_id=${projectID}&searchParams=${searchParams}&limit=10000`),
  getProjLaborDateSporadicCollect: (projectID, mode, date_mode) => get(`dashboard/proj/labor/date/sporadic/collect?token=${window.token}&project_id=${projectID}&mode=${mode}&date_mode=${date_mode}`),
  getProjLaborCompanyBasicInfo: (projectID, company_id) => get(`dashboard/proj/labor/company/basic_info?token=${window.token}&project_id=${projectID}&company_id=${company_id}`),
  getProjLaborCompanyAttendanceWorkerTypeCollect: (projectID, company_id) => get(`dashboard/proj/labor/company/attendance/worker_type/collect?token=${window.token}&project_id=${projectID}&company_id=${company_id}`),
  getProjLaborCompanyAttendanceTeamCollect: (projectID, company_id) => get(`dashboard/proj/labor/company/attendance/team/collect?token=${window.token}&project_id=${projectID}&company_id=${company_id}`),
  getProjLaborCompanyAttendanceDate10Collect: (projectID, company_id) => get(`dashboard/proj/labor/company/attendance/date10/collect?token=${window.token}&project_id=${projectID}&company_id=${company_id}`),
  getProjLaborCompanyWorkerCollect: (projectID, company_id) => get(`dashboard/proj/labor/company/worker/collect?token=${window.token}&project_id=${projectID}&company_id=${company_id}&limit=100000`),
  getProjLaborProjectBasicInfo: (projectID) => get(`dashboard/proj/labor/project/basic_info?token=${window.token}&project_id=${projectID}`),
  getProjLaborWorkerCompanyCollect: (projectID) => get(`dashboard/proj/labor/worker/company/collect?token=${window.token}&project_id=${projectID}`),
  getProjLaborWorkercountCollect: (projectID, mode) => get(`dashboard/proj/labor/workercount/worker_attr/collect?token=${window.token}&project_id=${projectID}&mode=${mode}`),
  getProjLaborBraodcastDetail: (projectID) => get(`dashboard/proj/labor/broadcast/detail?token=${window.token}&project_id=${projectID}`),



  // 项目部安全模块新接口///////
  getSafetyMachineTypeCount: projectID => get(`dashboard/proj/safety/machine_type/count?project_id=${projectID}&token=${window.token}`),
  getSafetyMajorDangerousTypeCount: projectID => get(`dashboard/proj/safety/major_dangerous_type/count?project_id=${projectID}&token=${window.token}`),
  getSafetyDay7InspectionCollect: projectID => get(`dashboard/proj/safety/day7/inspect/collect?project_id=${projectID}&token=${window.token}`),
  getSafetyDay7WarningAlarmCollect: (projectID,check_type) => get(`dashboard/proj/safety/day7/warning_alarm/collect?project_id=${projectID}&token=${window.token}&check_type=${check_type}`),
  getSafetyPresentWorkerList: (projectID,build_id,worker_name) => get(`dashboard/proj/safety/present_worker/list?project_id=${projectID}&build_id=${build_id}&worker_name=${worker_name}&token=${window.token}`),
  getSafetyPsBehaviorStarList: (projectID,page,limit) => get(`dashboard/proj/safety/personnel/behavior_star/list?project_id=${projectID}&token=${window.token}&page=${page}&limit=${limit}`),
  getSafetyPsWorkerTrainCollect: (projectID,check_type) => get(`dashboard/proj/safety/personnel/worker_train/collect?project_id=${projectID}&token=${window.token}&check_type=${check_type}`),
  getSafetyPsWarningAlarmTypeCollect: (projectID) => get(`dashboard/proj/safety/personnel/warning_alarm_type/collect?project_id=${projectID}&token=${window.token}`),
  getSafetyPsAlarmList: (projectID,page,limit) => get(`dashboard/proj/safety/personnel/alarm/list?project_id=${projectID}&token=${window.token}&page=${page}&limit=${limit}`),
  getSafetyPsTroubleLevelCollect: (projectID,mode) => get(`dashboard/proj/safety/trouble/level/collect?project_id=${projectID}&mode=${mode}&token=${window.token}`),
  getSafetyPsTroubleLevelDetailCollect: (projectID,mode,search_key,page,limit) => get(`dashboard/proj/safety/trouble/level/detail/collect?project_id=${projectID}&mode=${mode}&search_key=${search_key}&token=${window.token}&page=${page}&limit=${limit}`),
  getSafetyPsTroublePdUndoCollect: (projectID,mode) => get(`dashboard/proj/safety/trouble/pd/undo/collect?project_id=${projectID}&mode=${mode}&token=${window.token}`),
  getSafetyCheckItemCollect: projectID => get(`dashboard/proj/safety/trouble/check_item/collect?project_id=${projectID}&token=${window.token}`),
  getSafetyCheckItem2Collect: (projectID,search_key) => get(`dashboard/proj/safety/trouble/check_item2/collect?project_id=${projectID}&search_key=${search_key}&token=${window.token}`),
  getSafetyCheckItem3Collect: (projectID,search_key) => get(`dashboard/proj/safety/trouble/check_item3/collect?project_id=${projectID}&search_key=${search_key}&token=${window.token}`),
  getSafetyMonth12InspectionCollect: projectID => get(`dashboard/proj/safety/month12/inspect/collect?project_id=${projectID}&token=${window.token}`),
  getSafetyHeadCheckCollect: projectID => get(`dashboard/proj/safety/trouble/head_check/collect?project_id=${projectID}&token=${window.token}`),

  getSafetyHeadCheckDateCollect: (projectID,date_mode) => get(`dashboard/proj/safety/trouble/head_check/date/collect?project_id=${projectID}&token=${window.token}&date_mode=${date_mode}`),
  getSafetyHeadCheckCountCollect: (projectID,date_mode,search_key,page,limit) => get(`dashboard/proj/safety/trouble/head_check/count/collect?project_id=${projectID}&token=${window.token}&date_mode=${date_mode}&search_key=${search_key}&page=${page}&limit=${limit}`),
  getSafetyHeadCheckManagerDetailCollect: (projectID,date_mode,search_key,person_id,page,limit) => get(`dashboard/proj/safety/trouble/head_check/manager_detail/collect?project_id=${projectID}&token=${window.token}&date_mode=${date_mode}&search_key=${search_key}&person_id=${person_id}&page=${page}&limit=${limit}`),

  getSafetyTroubleItem10List: (projectID,page,limit) => get(`dashboard/proj/safety/trouble/trouble_item10/collect?project_id=${projectID}&token=${window.token}&page=${page}&limit=${limit}`),
  getSafetyTroubleUndoDetailCollect: (projectID,mode,search_key,page,limit) => get(`dashboard/proj/safety/trouble/pd/undo/detail/collect?project_id=${projectID}&token=${window.token}&mode=${mode}&search_key=${search_key}&page=${page}&limit=${limit}`),



  // 项目部工期模块新接口
  getProjBuildScheduleCollect: (projectID) => get(`dashboard/proj/schedule/build/schedule/collect?project_id=${projectID}&token=${window.token}`),
  getProjBuildMonth12OutputCollect: (projectID, mode) => get(`dashboard/proj/schedule/month12/output/collect?project_id=${projectID}&token=${window.token}&mode=${mode}`),
  getProjBuildLaborCompareCollect: (projectID) => get(`dashboard/proj/schedule/labor_compare/collect?project_id=${projectID}&token=${window.token}`),
  getProjBuildLaborSurplusCollect: (projectID) => get(`dashboard/proj/schedule/labor/surplus/collect?project_id=${projectID}&token=${window.token}`),
  getProjBuildScheduleDetailCollect: (projectID) => get(`dashboard/proj/schedule/build/schedule/detail/collect?project_id=${projectID}&token=${window.token}`),
  getProjScheduleOutputDetailCollect: (projectID, mode) => get(`dashboard/proj/schedule/output/date/detail/collect?project_id=${projectID}&token=${window.token}&mode=${mode}`),
  getProjScheduleOutputCompanyDetailCollect: (projectID, mode, search_key, page, limit) => get(`dashboard/proj/schedule/output/company/detail/collect?project_id=${projectID}&token=${window.token}&mode=${mode}&search_key=${search_key}&page=${page}&limit=${limit}`),
  getProjScheduleLaborCompareDateDetailCollect: (projectID, mode) => get(`dashboard/proj/schedule/labor_compare/date/detail/collect?project_id=${projectID}&token=${window.token}&mode=${mode}`),
  getProjScheduleLaborCompareCompanyDetailCollect: (projectID, mode, search_key, limit) => get(`dashboard/proj/schedule/labor_compare/company/detail/collect?project_id=${projectID}&token=${window.token}&mode=${mode}&search_key=${search_key}&limit=${limit}`),
  getProjMachineCheckQualifiedRateCollect: (projectID,machine_type) => get(`dashboard/proj/safety/machine/check_qualified_rate/collect?project_id=${projectID}&machine_type=${machine_type}&token=${window.token}`),
  getProjMachineTroubleCollect: (projectID,machine_type) => get(`dashboard/proj/safety/machine/trouble/collect?project_id=${projectID}&machine_type=${machine_type}&token=${window.token}`),
  getProjMachineRegisterCollect: (projectID,machine_type) => get(`dashboard/proj/safety/machine/register/collect?project_id=${projectID}&machine_type=${machine_type}&token=${window.token}`),
  getProjMachineMaintenanceCollect: (projectID) => get(`dashboard/proj/safety/machine/maintenance/collect?project_id=${projectID}&token=${window.token}`),
  getProjMachineProduceYearCollect: (projectID) => get(`dashboard/proj/safety/machine/produce_year/collect?project_id=${projectID}&token=${window.token}`),
  getProjMdMonth12CheckCollect: (projectID,eng_type_id) => get(`dashboard/proj/safety/md/month12_check/collect?project_id=${projectID}&eng_type_id=${eng_type_id}&token=${window.token}`),
  getProjMdTypePassRateCollect: (projectID,eng_type_id) => get(`dashboard/proj/safety/md/type_pass_rate/collect?project_id=${projectID}&eng_type_id=${eng_type_id}&token=${window.token}`),
  getProjMdTemplateTroubleCollect: (projectID,eng_type_id) => get(`dashboard/proj/safety/md/template_trouble/count?project_id=${projectID}&eng_type_id=${eng_type_id}&token=${window.token}`),
  getProjBuildingBaseInfo: projectID => get(`dashboard/proj/general/build/collect?project_id=${projectID}&token=${window.token}`),
  getProjWorkerTraceArea: (worker_id, start_time, end_time) => get(`dashboard/proj/old/checktime/worker/trace/areacount?worker_id=${worker_id}&starttime=${start_time}&endtime=${end_time}&token=${window.token}`),
  getProjWorkerTrace: (worker_id, start_time, end_time) => get(`dashboard/proj/old/checktime/worker/trace?worker_id=${worker_id}&starttime=${start_time}&endtime=${end_time}&token=${window.token}`),

  // 管理模块新接口

  getProjMgmStats: (projectID, department_id, is_only_leader) => get(`dashboard/proj/manager/attendance/date_range?project_id=${projectID}&department_id=${department_id}&is_only_leader=${is_only_leader}&token=${window.token}`),
  getProjManagerBuildAttendanceCollect: (projectID) => get(`dashboard/proj/manager/build/attendance/collect?project_id=${projectID}&token=${window.token}`),
  getProjManagerBuildAttendanceDetail: (projectID,build_id,department_id) => get(`dashboard/proj/manager/build/attendance/detail?project_id=${projectID}&build_id=${build_id}&department_id=${department_id}&token=${window.token}`),
  getProjManagerAttendanceTopList: (projectID,page,limit) => get(`dashboard/proj/manager/attendance/list?project_id=${projectID}&token=${window.token}&page=${page}&limit=${limit}`),
  getProjManagerAttendanceTopDetail: (projectID,worker_id) => get(`dashboard/proj/manager/attendance_area/detail?project_id=${projectID}&worker_id=${worker_id}&token=${window.token}`),
  getProjTroubleHeadCheckCollect: (projectID) => get(`dashboard/proj/safety/trouble/head_check/collect?project_id=${projectID}&token=${window.token}`),
  getProjManagerDeptDistributionCollect: (projectID) => get(`dashboard/proj/manager/dept_distribution/collect?project_id=${projectID}&token=${window.token}`),
  getProjManagerDeptManagerList: (projectID, department_id) => get(`dashboard/proj/manager/dept_manager/list?project_id=${projectID}&token=${window.token}&department_id=${department_id}`),
  getProjManagerBeforeAfterWorkRateCollect: (projectID) => get(`dashboard/proj/manager/before_after_work_rate/collect?project_id=${projectID}&token=${window.token}`),

  // 视频模块
  getVideoList: () => get(`dashboard/proj/video/video/collect?token=${window.token}`),
  getVideoUrl: (serialNumber) => post('http://124.71.178.44:9000/xmgl/videoItem/getVideoUrl', { "serialNumber": serialNumber, "streamType": "1", "type": "rtsp" }),

  // 视频ai
  // //
  getProjVideoAlarm: () => get(`dashboard/proj/video/alarm/collect?token=${window.token}`),
  getProjVideoDays7: () => get(`dashboard/proj/video/alarm/days7/collect?token=${window.token}`),
  getProjVideoImages: (video_id,search_date,alarm_type,page,limit) => get(`dashboard/proj/video/alarm/images/collect?token=${window.token}&video_id=${video_id}&search_date=${search_date}&alarm_type=${alarm_type}&page=${page}&limit=${limit}`),
  getProjVideoAlarmType: () => get(`dashboard/proj/video/alarm/type/collect?token=${window.token}`),

  // 安全帽使用
  
  getHatProjCollect: (is_cut, project_id) => get(`dashboard/proj/hat/ratio/collect?token=${window.token}&is_cut=${is_cut}&project_id=${project_id}`),
  getHatCompanyPlanCollect: (is_cut, project_id,type) => get(`dashboard/proj/hat/company/plan/collect?token=${window.token}&is_cut=${is_cut}&project_id=${project_id}&type=${type}`),
  getHatAttendanceCollect: (is_cut, project_id,type) => get(`dashboard/proj/hat/attendance/collect?token=${window.token}&is_cut=${is_cut}&project_id=${project_id}&type=${type}`),
  getHatNeWorkerCollect: (is_cut, project_id, type) => get(`dashboard/proj/hat/ne/worker/collect?token=${window.token}&is_cut=${is_cut}&project_id=${project_id}&type=${type}`),
  
  getProjHatBroadcast: () => get(`dashboard/proj/hat/broadcast/detail?token=${window.token}`),
  getProjHatUseList: (company_id,type,page,limit) => get(`dashboard/proj/hat/use/list?token=${window.token}&company_id=${company_id}&type=${type}&page=${page}&limit=${limit}`),
  getProjHatPlan: (type) => get(`dashboard/proj/hat/plan/collect?token=${window.token}&type=${type}`),
  getProjHatNeWorkerList: (type, page, limit) => get(`dashboard/proj/hat/ne/worker/list?token=${window.token}&type=${type}&page=${page}&limit=${limit}`),
  getProjHatNeManagerList: (type, page, limit) => get(`dashboard/proj/hat/ne/manager/list?token=${window.token}&type=${type}&page=${page}&limit=${limit}`),
  
  // //


  // //////




}

const building = {
  /*
  getLegend: () => get(`bigscreen/work/type/list`),
  getWarnList: buildingID => get(`app/bigscreen/floor/alarm/list/${buildingID}?token=${window.token}`),
  getFloorInfo: (projectID, buildingID) => get(`bigscreen/floor/map/list/${projectID}/` + buildingID),
  getWorkerOnlineStatus: buildingID => get(`app/bigscreen/floor/worker/status/collect/${buildingID}?token=${window.token}`),
  */
  getWorkList: () => get(`dashboard/proj/old/worker/list?token=${window.token}`),
  getBuildings: () => get(`dashboard/proj/old/build/list?show_source=1&token=${window.token}`),
  getWorkListForBuild: (build_id) => get(`dashboard/proj/old/worker/list?build_id=${build_id}&token=${window.token}`),
  getFloorWorkerCount: buildingID => get(`dashboard/proj/old/worker/collect/${buildingID}?token=${window.token}`),
  getdWorkTypeForBuild: (build_id) => get(`dashboard/proj/old/project/build/worker/type/collect?build_id=${build_id}&token=${window.token}`),
  getWorkTypePerFloor: (build_id) => get(`dashboard/proj/old/build/floor/worker/type/collect?build_id=${build_id}&token=${window.token}`),
  getBuildingFloorData: (floorList) => post(`dashboard/proj/old/map/data?token=${window.token}`, {map_list: floorList}),
}

const attendance = {
  getProjectDetail: projectID => get(`checktime/bigscreen/project/detail/${projectID}`),
  getOnSiteTeamsInfo: projectID => get(`checktime/bigscreen/team/insite/${projectID}`),
  getSevenDayAttendance: projectID => get(`checktime/bigscreen/attendance/sevendaycollect/${projectID}`),
  getWorkerTypeInfo: (projectID, status) => {
    let url = `checktime/bigscreen/workertype/collect/${projectID}`;
    if (status === 1) {
      url += `?status=${status}`;
    }
    return get(url);
  },
  getOnSiteBuildingsInfo: projectID => get(`checktime/bigscreen/build/collect/${projectID}`),
}

const filters = {
  getMenuList: () => get(`dashboard/common/menu/list?token=${window.token}`),
  getProjectCompanyList: projectID => get(`dashboard/proj/old/company/select/list?project_id=${projectID}&token=${window.token}`),
  getProjectWorkerTypeList: (projectID, companyId) => get(`dashboard/proj/old/worktype/list/forsearch/select?project_id=${projectID}&company_id=${companyId}&team_id=&token=${window.token}`),
};

export {
  building,
  nation,
  project,
  attendance,
  filters,
  wsUrl,
}

