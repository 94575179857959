import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    '客房楼': [-70.30249343511022,-242.73028668557652,250],
    '餐厅': [185.2139608809956,-322.54466225681807,250],
    '30人会议室': [378.0550419201895,-415.75499778102164,250],
    '100人会议室': [188.87137303485065,-519.9532418620597,250],
    '序厅': [-27.871633920279166,-465.11077442763576,250],
    '300人会议室': [-253.13902937247875,-469.0377670728125,250],
  };

  const buildingBoundaries = {
    '客房楼': [[-226.60678922630098,-318.70631247095577],[-227.2521963066801,-163.575605477848],[78.75100291659669,-170.38219023088243],[74.96674874591162,-321.20462879436894]],
    '餐厅': [[69.67861771347441,-397.5080666103239],[77.59577427513528,-247.88845724450687],[319.6972470674219,-245.89896436383523],[322.95968778537684,-400.4239167352656]],
    '30人会议室': [[322.6165972156913,-295.265675320372],[432.77309194462407,-291.9223667540193],[432.38660652593563,-542.2965645884778],[322.17346609383753,-541.619924203824]],
    '100人会议室': [[303.97593565113044,-451.2968716616153],[304.3447453856662,-584.2935070152583],[59.895688198997405,-584.3016184927175],[66.96710047134403,-451.09582978631664]],
    '序厅': [[41.54600752233706,-367.49820331070197],[42.19768654798571,-561.9481531278174],[-95.73557746287209,-565.0205180448999],[-95.67757953414511,-368.15385124371886]],
    '300人会议室': [[-124.49828817977553,-366.48975966475564],[-124.78520582472417,-574.8005634901405],[-395.83535371645866,-575.5132544059456],[-396.605372074187,-367.42747412305573]],
  };

  const buildingBlurBoundaries = {
    '客房楼': [[-241.36954091179337,-165.16330485246567],[-249.64435580706572,-312.56844206625505],[-377.1249725830162,-311.42990499442584],[-404.3548833359465,-378.74215465049144],[-396.9766463020573,-572.5632326521026],[-77.29849920777026,-573.954708070902],[-75.11191505137688,-627.139567870772],[20.88867629106779,-628.5649270608716],[23.78113008575372,-591.8971824899535],[310.92956184495273,-590.8806191676217],[312.74601078440213,-543.3977471913058],[440.21637697430793,-539.9587551596443],[443.2342351512911,-246.7178733451829],[124.38011435505277,-248.30368202462137],[120.17936106335725,-153.85009697302752]],
    '餐厅': [[-241.36954091179337,-165.16330485246567],[-249.64435580706572,-312.56844206625505],[-377.1249725830162,-311.42990499442584],[-404.3548833359465,-378.74215465049144],[-396.9766463020573,-572.5632326521026],[-77.29849920777026,-573.954708070902],[-75.11191505137688,-627.139567870772],[20.88867629106779,-628.5649270608716],[23.78113008575372,-591.8971824899535],[310.92956184495273,-590.8806191676217],[312.74601078440213,-543.3977471913058],[440.21637697430793,-539.9587551596443],[443.2342351512911,-246.7178733451829],[124.38011435505277,-248.30368202462137],[120.17936106335725,-153.85009697302752]],
    '30人会议室': [[-241.36954091179337,-165.16330485246567],[-249.64435580706572,-312.56844206625505],[-377.1249725830162,-311.42990499442584],[-404.3548833359465,-378.74215465049144],[-396.9766463020573,-572.5632326521026],[-77.29849920777026,-573.954708070902],[-75.11191505137688,-627.139567870772],[20.88867629106779,-628.5649270608716],[23.78113008575372,-591.8971824899535],[310.92956184495273,-590.8806191676217],[312.74601078440213,-543.3977471913058],[440.21637697430793,-539.9587551596443],[443.2342351512911,-246.7178733451829],[124.38011435505277,-248.30368202462137],[120.17936106335725,-153.85009697302752]],
    '100人会议室': [[-241.36954091179337,-165.16330485246567],[-249.64435580706572,-312.56844206625505],[-377.1249725830162,-311.42990499442584],[-404.3548833359465,-378.74215465049144],[-396.9766463020573,-572.5632326521026],[-77.29849920777026,-573.954708070902],[-75.11191505137688,-627.139567870772],[20.88867629106779,-628.5649270608716],[23.78113008575372,-591.8971824899535],[310.92956184495273,-590.8806191676217],[312.74601078440213,-543.3977471913058],[440.21637697430793,-539.9587551596443],[443.2342351512911,-246.7178733451829],[124.38011435505277,-248.30368202462137],[120.17936106335725,-153.85009697302752]],
    '序厅': [[-241.36954091179337,-165.16330485246567],[-249.64435580706572,-312.56844206625505],[-377.1249725830162,-311.42990499442584],[-404.3548833359465,-378.74215465049144],[-396.9766463020573,-572.5632326521026],[-77.29849920777026,-573.954708070902],[-75.11191505137688,-627.139567870772],[20.88867629106779,-628.5649270608716],[23.78113008575372,-591.8971824899535],[310.92956184495273,-590.8806191676217],[312.74601078440213,-543.3977471913058],[440.21637697430793,-539.9587551596443],[443.2342351512911,-246.7178733451829],[124.38011435505277,-248.30368202462137],[120.17936106335725,-153.85009697302752]],
    '300人会议室': [[-241.36954091179337,-165.16330485246567],[-249.64435580706572,-312.56844206625505],[-377.1249725830162,-311.42990499442584],[-404.3548833359465,-378.74215465049144],[-396.9766463020573,-572.5632326521026],[-77.29849920777026,-573.954708070902],[-75.11191505137688,-627.139567870772],[20.88867629106779,-628.5649270608716],[23.78113008575372,-591.8971824899535],[310.92956184495273,-590.8806191676217],[312.74601078440213,-543.3977471913058],[440.21637697430793,-539.9587551596443],[443.2342351512911,-246.7178733451829],[124.38011435505277,-248.30368202462137],[120.17936106335725,-153.85009697302752]],
  };

  const buildingFinishedHeights = {
  };

  const doneFloors = {
  };

  const traceViews = {
    '_': {
      center: [0, 0],
      pitch: 37.6,
      zoom: 2,
      bearing: -37.6,
    },
    '客房楼': {
      center: [-435.75044089621804, -1168.4329566460299],
      pitch: 61,
      zoom: 3.81,
      bearing: -28.6,
    },
    '餐厅': {
      center: [1434.4160005678975, -1396.1786824540427],
      pitch: 64.2,
      zoom: 3.81,
      bearing: -25,
    },
    '30人会议室': {
      center: [2753.866955298542, -2024.9938696406834],
      pitch: 68.2,
      zoom: 3.81,
      bearing: -25,
    },
    '100人会议室': {
      center: [1452.3826683459633, -2741.6032916783433],
      pitch: 68.2,
      zoom: 3.81,
      bearing: -25,
    },
    '序厅': {
      center: [-109.76328668124977, -2620.1011852211523],
      pitch: 67.4,
      zoom: 3.81,
      bearing: -15.4,
    },
    '300人会议室': {
      center: [-1857.3824069983352, -2761.7934641696265],
      pitch: 67.4,
      zoom: 3.81,
      bearing: -15.4,
    },
  };

  function getUserZValue(user) {
    return (user.floor == 'ground' ? 0 : user.height) * 4.1 + 7;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 4.1;
  }

  function getZValueFromHeight(height) {
    return height * 4.1;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/ybswzx2.glb',
    modelType: 'gltf',
    modelSubType: 'draco',

    fullOpacity: 1,
    revealOpacity: 0.3,
    deepRevealOpacity: 0,

    /*
    groundHeightRanges: [
      [-0.0001, 0],
      [153.9731, 153.9732],
      [-1425.0001, -1424.9999],
    ],
    */

    modelOptions: {
      scale: 6,
      rotZ: 0,
      moveX: -450,
      moveY: -350,
      moveZ: 0,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: 0,
      z: 0
    },

    bldgGroups: [['客房楼', '餐厅', '30人会议室', '100人会议室', '序厅', '300人会议室']],

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingBlurBoundaries: buildingBlurBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
