<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :sep-size="broadcastSep"
    :title-font-size="'1rem'"
    :value-font-size="'3rem'"
  />
</template>

<script>
  import Broadcasts from "../../../../components/broadcasts";
  import { tech } from "../../../../api_tech";

  var xmid = "";

  export default {
    name: 'QualityIndexBroadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['方案总数', 'A类方案', 'B类方案'],
        broadcastColors: ['#40f8ff', '#ffd454', '#35c59e'],
        broadcastIcons: ['方案总数', 'A类方案', 'B类方案'],
        broadcastSep: '3rem',
      }
    },
    props: {
      broadcastData: Object,
      worProjectId: String,
    },
    mounted(){
      xmid = this.worProjectId;
    }
  }

  export function updateData(container, scheduleNextUpdate) {
    tech.getProjectSchemeStatic(xmid)
      .then(res=>{
        container.broadcastData.tech = res;
        container.broadcastData.tech.value1 = res.ffa+res.ffb;
        container.broadcastData.tech.value2 = res.ffa;
        container.broadcastData.tech.value3 = res.ffb;

        container.statsData = res;
        // scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1rem;
  font-weight: bold;
  color: #cececf;
}
</style>
