<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  />
</template>

<script>
  import Broadcasts from "../../../../components/broadcasts";
  import { human } from "../../../../api_hr";
  // import { nation } from "../../../../api";
  export default {
    name: 'HrEmployeeRelationBroadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['整体员工离职率', '5年内校招生离职率', '返聘人员数量'],
        broadcastColors: ['#ffffff', '#40f8ff', '#ffd454'],
        broadcastIcons: ['整体员工离职率', '5年内校招生离职率', '返聘人员数量'],
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    },
  }

  export function updateData(container) {
    human
      .getPersonDimissionOdds(new Date().getFullYear())
      .then((d) => {
        let dataMap = {};
        var hire_staff_nums = 0;
        human.getPersonHireStaff(new Date().getFullYear()).then(res=>{
          for (let i = 0; i < res.length; i++) {
            hire_staff_nums += res[i].subData[0].value;
          }
           dataMap["all_person_quit"] = Math.floor(d[0].value*100).toString()+"%";
            dataMap["enrollment_quit"] = Math.floor(d[3].value*100).toString()+"%";
            dataMap["hire_staff_nums"] = hire_staff_nums;
            container.broadcastData.employeeRelation = dataMap;
            container.broadcastData.employeeRelation.value1 = dataMap["all_person_quit"];
            container.broadcastData.employeeRelation.value2 = dataMap["enrollment_quit"];
            container.broadcastData.employeeRelation.value3 = dataMap["hire_staff_nums"]
            container.statsData = dataMap;
        })
        // scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
