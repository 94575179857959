<template>
  <div id="content">
    <div class="top-item">

      <div :class="isAll?'big-content-full':'big-content'">
        <div class="content-title">
          <div style="display:flex;">
            {{activeType}}-
            <div v-if="isAll">
              {{activeProject.project_name}}-720实时进度
            </div>
            <div
              v-else
              style=" text-decoration: underline;cursor: pointer;"
              @click="dialogShow"
            >
              {{activeProject.project_name}}
            </div>
          </div>

          <div style="display: flex; align-items: center;">
            <div v-if="!isAll">
              <img
                src="../../assets/nation/camera.png"
                class="img-left"
                v-if="activeContent==2"
                @click="changeType(1)"
              >
              <img
                src="../../assets/nation/camera_active.png"
                class="img-left"
                v-else
              >
              <!-- <img
                src="../../assets/nation/list.png"
                class="img-right"
                style=" margin-right: 0.5rem;"
                v-if="activeContent==1"
                @click="changeType(2)"
              >
              <img
                src="../../assets/nation/list_active.png"
                style=" margin-right: 0.5rem;"
                class="img-right"
                v-else
              > -->

            </div>
            <img
              v-if="activeContent==1&&activeProject.url"
              src="../../assets/nation/full.png"
              style="width:33px;"
              @click="changeContent"
              class="img-right"
            >

          </div>
        </div>
        <div :class="isAll?'board-full':'board'">
          <!-- src="https://720yun.com/t/33vkbw8bgqh?scene_id=91080560" -->
          <iframe
            v-if="activeContent==1&&activeProject.url"
            :src="activeProject.url"
            style="height:100%; width: 100%;z-index:10000"
            frameborder="0"
            id="iFrame"
            ref="frame"
            allowfullscreen="true"
          ></iframe>
          <img
            v-else-if="activeContent==1&&!activeProject.url"
            src="../../assets/nation/no_vr.png"
            alt=""
            class="no_data_set"
          >

          <div
            style="width:100%;height:100%;overflow:auto;margin-top:4rem"
            v-else
          >
            <div
              v-for="(item, index) in projectDetailList"
              :key="item.id"
              class="detail-item"
              :style="{ background: index%2 ? '#213a77' : '#102855'}"
            >
              <div style="text-align:center">{{item.name}}</div>

              <div style="width:3rem;">详情></div>
            </div>
            <div
              v-if="!(projectDetailList && projectDetailList.length)"
              style="height:2.5rem;padding:0 1rem;font-size:0.9rem;line-height:2.5rem;background:#081337;text-align:center"
            >
              暂无新开项目
            </div>
          </div>

        </div>

        <!-- <div
          class="full-screen"
        >{{isAll?'收起':'全屏'}}</div> -->
      </div>
      <div :class="isAll?'hidden':'right-item'">
        <div
          class="people-status"
          style="margin-top:0rem"
        >
          <wrapper style="padding: 0;">
            <div
              class="info-block"
              style="min-height:5rem"
            >
              <div
                class="info-block-title"
                style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
              >
                <div>精益成本建造</div>
              </div>
              <div style="height:19rem;padding-top:2.5rem">
                <div
                  v-if="!projectList[1]||!projectList[1].length"
                  style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 23rem; height: 14rem;margin-top: 0.5rem;"
                >
                  <div class="empty-chart-icon" />
                  <div style="margin-top:20px;color:#ccc">暂无数据</div>
                </div>
                <div
                  v-else
                  style="height:16.5rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;"
                >
                  <div
                    v-for="(project,index) in projectList[1]"
                    :key="project.project_id"
                    style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;padding-left: 1rem;"
                    :class="[activeFirstIndex==1&&activeSeconedIndex==index?'active-project':'']"
                    @click="handleSelect(project,index,1)"
                  >
                    <div style="display:flex;align-items: center;">
                      <img
                        src="../../assets/nation/rocket.png"
                        class="active-img-left"
                        v-if="activeFirstIndex==1&&activeSeconedIndex==index"
                      >
                      <div>{{project.project_name.length>22?project.project_name.slice(0,22)+'...':project.project_name}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </wrapper>
        </div>
        <div
          class="people-status"
          style="margin-top:1rem"
        >
          <wrapper style="padding: 0;">
            <div
              class="info-block"
              style="min-height:5rem"
            >
              <div
                class="info-block-title"
                style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
              >
                <div>科技建造</div>
              </div>
              <div style="height:19rem;padding-top:2.5rem">
                <div
                  v-if="!projectList[2]||!projectList[2].length"
                  style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 23rem; height: 14rem;margin-top: 0.5rem;"
                >
                  <div class="empty-chart-icon" />
                  <div style="margin-top:20px;color:#ccc">暂无数据</div>
                </div>
                <div
                  v-else
                  style="height:16.5rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;"
                >
                  <div
                    v-for="(project,index) in projectList[2]"
                    :key="project.project_id"
                    style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;padding-left: 1rem;"
                    :class="[activeFirstIndex==2&&activeSeconedIndex==index?'active-project':'']"
                    @click="handleSelect(project,index,2)"
                  >
                    <div style="display:flex;align-items: center;">
                      <img
                        src="../../assets/nation/rocket.png"
                        class="active-img-left"
                        v-if="activeFirstIndex==2&&activeSeconedIndex==index"
                      >
                      <div>{{project.project_name.length>22?project.project_name.slice(0,22)+'...':project.project_name}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </wrapper>
        </div>

      </div>
    </div>
    <div :class="isAll?'hidden':'bottom-item'">
      <div class="people-status">
        <wrapper style="padding: 0;">
          <div
            class="info-block"
            style="min-height:5rem"
          >
            <div
              class="info-block-title"
              style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
            >
              <div>智慧建造</div>
            </div>
            <div style="height:19rem;padding-top:2.5rem">
              <div
                v-if="!projectList[3]||!projectList[3].length"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 23rem; height: 14rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <div
                v-else
                style="height:16.5rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;"
              >
                <div
                  v-for="(project,index) in projectList[3]"
                  :key="project.project_id"
                  style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;padding-left: 1rem;"
                  :class="[activeFirstIndex==3&&activeSeconedIndex==index?'active-project':'']"
                  @click="handleSelect(project,index,3)"
                >
                  <div style="display:flex;align-items: center;">
                    <img
                      src="../../assets/nation/rocket.png"
                      class="active-img-left"
                      v-if="activeFirstIndex==3&&activeSeconedIndex==index"
                    >
                    <div>{{project.project_name.length>22?project.project_name.slice(0,22)+'...':project.project_name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </wrapper>
      </div>
      <div class="people-status">
        <wrapper style="padding: 0;">
          <div
            class="info-block"
            style="min-height:5rem"
          >
            <div
              class="info-block-title"
              style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
            >
              <div>精品建造</div>
            </div>
            <div style="height:19rem;padding-top:2.5rem">
              <div
                v-if="!projectList[4]||!projectList[4].length"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 23rem; height: 14rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <div
                v-else
                style="height:16.5rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;"
              >
                <div
                  v-for="(project,index) in projectList[4]"
                  :key="project.project_id"
                  style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;padding-left: 1rem;"
                  :class="[activeFirstIndex==4&&activeSeconedIndex==index?'active-project':'']"
                  @click="handleSelect(project,index,4)"
                >
                  <div style="display:flex;align-items: center;">
                    <img
                      src="../../assets/nation/rocket.png"
                      class="active-img-left"
                      v-if="activeFirstIndex==4&&activeSeconedIndex==index"
                    >
                    <div>{{project.project_name.length>22?project.project_name.slice(0,22)+'...':project.project_name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </wrapper>
      </div>
      <div class="people-status">
        <wrapper style="padding: 0;">
          <div
            class="info-block"
            style="min-height:5rem"
          >
            <div
              class="info-block-title"
              style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
            >
              <div>快速建造</div>
            </div>
            <div style="height:19rem;padding-top:2.5rem">
              <div
                v-if="!projectList[5]||!projectList[5].length"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 23rem; height: 14rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <div
                v-else
                style="height:16.5rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;"
              >
                <div
                  v-for="(project,index) in projectList[5]"
                  :key="project.project_id"
                  style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;padding-left: 1rem;"
                  :class="[activeFirstIndex==5&&activeSeconedIndex==index?'active-project':'']"
                  @click="handleSelect(project,index,5)"
                >
                  <div style="display:flex;align-items: center;">
                    <img
                      src="../../assets/nation/rocket.png"
                      class="active-img-left"
                      v-if="activeFirstIndex==5&&activeSeconedIndex==index"
                    >
                    <div>{{project.project_name.length>22?project.project_name.slice(0,22)+'...':project.project_name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </wrapper>
      </div>
      <div class="people-status">
        <wrapper style="padding: 0;">
          <div
            class="info-block"
            style="min-height:5rem"
          >
            <div
              class="info-block-title"
              style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
            >
              <div>绿色建造</div>
            </div>
            <div style="height:19rem;padding-top:2.5rem">
              <div
                v-if="!projectList[6]||!projectList[6].length"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 23rem; height: 14rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <div
                v-else
                style="height:16.5rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;"
              >
                <div
                  v-for="(project,index) in projectList[6]"
                  :key="project.project_id"
                  style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;padding-left: 1rem;"
                  :class="[activeFirstIndex==6&&activeSeconedIndex==index?'active-project':'']"
                  @click="handleSelect(project,index,6)"
                >
                  <div style="display:flex;align-items: center;">
                    <img
                      src="../../assets/nation/rocket.png"
                      class="active-img-left"
                      v-if="activeFirstIndex==6&&activeSeconedIndex==index"
                    >
                    <div>{{project.project_name.length>22?project.project_name.slice(0,22)+'...':project.project_name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </wrapper>
      </div>
      <div class="people-status">
        <wrapper style="padding: 0;">
          <div
            class="info-block"
            style="min-height:5rem"
          >
            <div
              class="info-block-title"
              style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
            >
              <div>本质安全建造</div>
            </div>
            <div style="height:19rem;padding-top:2.5rem">
              <div
                v-if="!projectList[7]||!projectList[7].length"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 23rem; height: 14rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <div
                v-else
                style="height:16.5rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;"
              >
                <div
                  v-for="(project,index) in projectList[7]"
                  :key="project.project_id"
                  style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;padding-left: 1rem;"
                  :class="[activeFirstIndex==7&&activeSeconedIndex==index?'active-project':'']"
                  @click="handleSelect(project,index,7)"
                >
                  <div style="display:flex;align-items: center;">
                    <img
                      src="../../assets/nation/rocket.png"
                      class="active-img-left"
                      v-if="activeFirstIndex==7&&activeSeconedIndex==index"
                    >
                    <div>{{project.project_name.length>22?project.project_name.slice(0,22)+'...':project.project_name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </wrapper>
      </div>

    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow1"
      width="86rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:45rem;backgroundColor:#0e1328;position:relative;z-index:20002"
      >
        <div
          class="stats-close-btn"
          @click="popupShow1 = false"
        />
        <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
          {{activeProject.project_name}}
        </div>

        <div style="padding:1rem;display:flex;flex-wrap:wrap">
          <div
            class="project-files"
            style="margin-top:0rem"
          >
            <div style="padding: 0;border: 1px solid #1A3281;">
              <div style="min-height:5rem">
                <div class="files-detail-tiltle">
                  <div>精益成本建造</div>
                </div>
                <div style="height:17rem;">
                  <div
                    v-if="!project1.length"
                    style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 23rem; height: 14rem;margin-top: 0.5rem;"
                  >
                    <div class="empty-chart-icon" />
                    <div style="margin-top:20px;color:#ccc">暂无数据</div>
                  </div>
                  <div
                    v-else
                    style="height:16.5rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;"
                  >
                    <div
                      v-for="(project,index) in projectDetailList"
                      :key="project.id"
                      style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;padding-left: 1rem;"
                      @click="handleSelectFile(project,index,1)"
                    >
                      <div style="display:flex;align-items: center;">
                        <div>{{project.name.length>18?project.name.slice(0,18)+'...':project.name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="project-files"
            style="margin-top:0rem"
          >
            <div style="padding: 0;border: 1px solid #1A3281;">
              <div style="min-height:5rem">
                <div class="files-detail-tiltle">
                  <div>精益成本建造</div>
                </div>
                <div style="height:17rem;">
                  <div
                    v-if="!project1.length"
                    style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 23rem; height: 14rem;margin-top: 0.5rem;"
                  >
                    <div class="empty-chart-icon" />
                    <div style="margin-top:20px;color:#ccc">暂无数据</div>
                  </div>
                  <div
                    v-else
                    style="height:16.5rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;"
                  >
                    <div
                      v-for="(project,index) in project1"
                      :key="project.project_id"
                      style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;padding-left: 1rem;"
                      @click="handleSelectFile(project,index,1)"
                    >
                      <div style="display:flex;align-items: center;">
                        <div>{{project.project_name.length>18?project.project_name.slice(0,18)+'...':project.project_name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="project-files"
            style="margin-top:0rem"
          >
            <div style="padding: 0;border: 1px solid #1A3281;">
              <div style="min-height:5rem">
                <div class="files-detail-tiltle">
                  <div>精益成本建造</div>
                </div>
                <div style="height:17rem;">
                  <div
                    v-if="!project1.length"
                    style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 23rem; height: 14rem;margin-top: 0.5rem;"
                  >
                    <div class="empty-chart-icon" />
                    <div style="margin-top:20px;color:#ccc">暂无数据</div>
                  </div>
                  <div
                    v-else
                    style="height:16.5rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;"
                  >
                    <div
                      v-for="(project,index) in project1"
                      :key="project.project_id"
                      style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;padding-left: 1rem;"
                      @click="handleSelectFile(project,index,1)"
                    >
                      <div style="display:flex;align-items: center;">
                        <div>{{project.project_name.length>18?project.project_name.slice(0,18)+'...':project.project_name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="project-files"
            style="margin-top:0rem"
          >
            <div style="padding: 0;border: 1px solid #1A3281;">
              <div style="min-height:5rem">
                <div class="files-detail-tiltle">
                  <div>精益成本建造</div>
                </div>
                <div style="height:17rem;">
                  <div
                    v-if="!project1.length"
                    style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 23rem; height: 14rem;margin-top: 0.5rem;"
                  >
                    <div class="empty-chart-icon" />
                    <div style="margin-top:20px;color:#ccc">暂无数据</div>
                  </div>
                  <div
                    v-else
                    style="height:16.5rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;"
                  >
                    <div
                      v-for="(project,index) in project1"
                      :key="project.project_id"
                      style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;padding-left: 1rem;"
                      @click="handleSelectFile(project,index,1)"
                    >
                      <div style="display:flex;align-items: center;">
                        <div>{{project.project_name.length>18?project.project_name.slice(0,18)+'...':project.project_name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="project-files"
            style="margin-top:1rem"
          >
            <div style="padding: 0;border: 1px solid #1A3281;">
              <div style="min-height:5rem">
                <div class="files-detail-tiltle">
                  <div>精益成本建造</div>
                </div>
                <div style="height:17rem;">
                  <div
                    v-if="!project1.length"
                    style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 23rem; height: 14rem;margin-top: 0.5rem;"
                  >
                    <div class="empty-chart-icon" />
                    <div style="margin-top:20px;color:#ccc">暂无数据</div>
                  </div>
                  <div
                    v-else
                    style="height:16.5rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;"
                  >
                    <div
                      v-for="(project,index) in project1"
                      :key="project.project_id"
                      style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;padding-left: 1rem;"
                      @click="handleSelectFile(project,index,1)"
                    >
                      <div style="display:flex;align-items: center;">
                        <div>{{project.project_name.length>18?project.project_name.slice(0,18)+'...':project.project_name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="project-files"
            style="margin-top:1rem"
          >
            <div style="padding: 0;border: 1px solid #1A3281;">
              <div style="min-height:5rem">
                <div class="files-detail-tiltle">
                  <div>精益成本建造</div>
                </div>
                <div style="height:17rem;">
                  <div
                    v-if="!project1.length"
                    style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 23rem; height: 14rem;margin-top: 0.5rem;"
                  >
                    <div class="empty-chart-icon" />
                    <div style="margin-top:20px;color:#ccc">暂无数据</div>
                  </div>
                  <div
                    v-else
                    style="height:16.5rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;"
                  >
                    <div
                      v-for="(project,index) in project1"
                      :key="project.project_id"
                      style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;padding-left: 1rem;"
                      @click="handleSelectFile(project,index,1)"
                    >
                      <div style="display:flex;align-items: center;">
                        <div>{{project.project_name.length>18?project.project_name.slice(0,18)+'...':project.project_name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="project-files"
            style="margin-top:1rem"
          >
            <div style="padding: 0;border: 1px solid #1A3281;">
              <div style="min-height:5rem">
                <div class="files-detail-tiltle">
                  <div>精益成本建造</div>
                </div>
                <div style="height:17rem;">
                  <div
                    v-if="!project1.length"
                    style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 23rem; height: 14rem;margin-top: 0.5rem;"
                  >
                    <div class="empty-chart-icon" />
                    <div style="margin-top:20px;color:#ccc">暂无数据</div>
                  </div>
                  <div
                    v-else
                    style="height:16.5rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;"
                  >
                    <div
                      v-for="(project,index) in project1"
                      :key="project.project_id"
                      style="height:2rem;display:flex;justify-content:space-between;cursor:pointer;padding-left: 1rem;"
                      @click="handleSelectFile(project,index,1)"
                    >
                      <div style="display:flex;align-items: center;">
                        <div>{{project.project_name.length>18?project.project_name.slice(0,18)+'...':project.project_name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>

  </div>
</template>

<script>
require("echarts-gl");
import wrapper from "../../components/wrap";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation } from "../../api";
// import { cubeBarChartTemplate } from "./barChartTemplates";
// import { truncateStr } from "../../utils";

export default {
  name: "IndexRightBar",
  components: {
    wrapper,
  },
  props: {
    broadcastDetails: Object,
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    gotoProject: Function,
  },
  data() {
    return {
      isAll: false,
      activeContent: 1,
      activeType: "精益成本建造",
      activeProject: {},

      projectDetailList: [
        {
          name: "项目名称件项目名称文件1.pdf",
          id: 9087797,
        },
        {
          name: "吴江交通枢纽项目项目名称文件项目名称文件项目名称文件项目名称文件项目名称文件项目名称文件.pdf",
          id: 8904797,
        },
      ],
      //
      projectList: {},
      project1: [
        {
          project_name: "那大运河新城西城桂花苑工程总承包",
          project_id: "15455",
        },
        {
          project_name: "那大钱投天城单元安置房项目",
          project_id: "15405",
        },
        {
          project_name: "儋州康居棉厂四期安置房项目",
          project_id: "15495",
        },
        {
          project_name: "儋州文体健康产业园项目",
          project_id: "15458",
        },
        {
          project_name: "那大运河新城西城桂花苑工程总承包",
          project_id: "154455",
        },
        {
          project_name: "那大钱投天城单元安置房项目",
          project_id: "154305",
        },
        {
          project_name: "儋州康居棉厂四期安置房项目",
          project_id: "154695",
        },
        {
          project_name: "儋州文体健康产业园项目",
          project_id: "158458",
        },
      ],
      activeFirstIndex: 1,
      activeSeconedIndex: 0,

      mapLevel: 0,

      popupShow1: false,
    };
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;
    window.$districtLevel = "";
    this.getData();
  },
  watch: {
    orgLevel: function (newValue) {
      if (newValue) {
        this.getData();
      }
    },
    districtLevel: function (newValue) {
      if (newValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
        this.getData();
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.mapLevel = newValue;
        if (newValue == 2) {
          this.getData();
        }
      }
    },
  },
  methods: {
    dialogShow() {
      // this.popupShow1 = true
    },
    changeContent() {
      this.isAll = !this.isAll;
    },
    changeType(val) {
      this.activeContent = val;
    },
    handleProject(item) {
      this.gotoProject(item.project_id);
    },
    handleSelect(project, index, val) {
      console.log(project, index, val);
      this.activeFirstIndex = val;
      this.activeSeconedIndex = index;
      this.activeProject = project;
      this.activeType = this.searchType(val);
    },
    searchType(val) {
      if (val == 1) {
        return "精益成本建造";
      } else if (val == 2) {
        return "科技建造";
      } else if (val == 3) {
        return "智慧建造";
      } else if (val == 4) {
        return "精品建造";
      } else if (val == 5) {
        return "快速建造";
      } else if (val == 6) {
        return "绿色建造";
      } else if (val == 7) {
        return "本质安全建造";
      }
    },

    getData() {
      nation
        .getHorseRacingBuildList()
        .then((d) => {
          if(d.length){
            this.projectList = {};
            d.map((item) => {
              this.projectList[item.key] = item.list;
            });
            this.activeProject = d[0].list[0];
            this.activeType = this.searchType(d[0].key);
            this.activeFirstIndex = d[0].key;
            this.activeSeconedIndex = 0;
          }
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
  },
};
</script>

<style scoped lang="less">
#content {
}
.no_data_set{
  position: absolute;
  top: 30%;
  left: 45%;
}
.content-title {
  z-index: 99;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to right, #0f1f3c, rgba(255, 255, 255, 0));
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
}
.board {
  width: 100%;
  height: 100%;
}
.board-full {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}
.detail-item {
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  cursor: pointer;
}
.img-left {
  width: 26px;
  margin-right: 0.5rem;
  cursor: pointer;
}
.img-right {
  width: 26px;
  cursor: pointer;
}
.active-img-left {
  width: 15px;
  height: 16px;
  margin-right: 0.5rem;
}

.full-screen {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 4rem;
  height: 2rem;
  line-height: 2rem;
  background-color: #21325c;
  border: 1px solid #5f7ec9;
  text-align: center;
  cursor: pointer;
}
.people-status {
  width: 23rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
.project-files {
  width: 20rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
.files-detail-tiltle {
  height: 2.5rem;
  line-height: 2.5rem;
  background: #0c2341;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: #00ffff;
  padding: 0 1rem;
}
.top-item {
  //   height: calc(100vh - 30rem);
  height: 39rem;

  width: calc(100vw-2rem);
  display: flex;
}
.bottom-item {
  width: calc(100vw-2rem);
  height: 18rem;
  display: flex;
}
.right-item {
  display: flex;
  flex-direction: column;
  width: 23rem;
}
.hidden {
  display: none;
}
.big-content {
  width: calc(100vw - 27rem);
  height: 39rem;
  margin-right: 1rem;
  border: 1px solid #0d2b50;
  position: relative;
}
.big-content-full {
  // width: calc(100vw - 4rem);
  // height: calc(100vh - 10rem);
  // margin-right: 1rem;
  // border: 1px solid #0d2b50;
  // position: fixed;
  height: 100%;
  width: 100%;
  margin: 0 0 0 -1.875rem;
  z-index: 20001;
}
.active-project {
  color: #40f8ff;
  background-color: #102855;
}

.mon-change {
  display: flex;
  margin: 1rem;
  justify-content: space-between;
  align-items: center;
}
.mon-item {
  padding: 0.5rem;
  border: 1px solid #52b3ff;
  border-radius: 4px;
}
.active-mon {
  background-color: #52b3ff;
}
.img {
  width: 2.5rem;
}
.table-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: #fff;
}
.tab-item-first {
  //   flex: 3;
  width: 60%;
}
.tab-item {
  //   flex: 1;
  width: 20%;
  text-align: center;
}
.on {
  display: table-cell;
  position: relative;
}

.on a {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
}

.angle_top {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #5e5e5e;
  position: absolute;
  transform: rotate(180deg);
  bottom: 0.5rem;
  right: 30%;
}
.angle_top_desc {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #4191e5;
  position: absolute;
  transform: rotate(180deg);
  bottom: 0.5rem;
  right: 30%;
}
.angle_bottom {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #5e5e5e;
  position: absolute;
  top: 0.6rem;
  right: 30%;
}
.angle_bottom_asc {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #4191e5;
  position: absolute;
  top: 0.6rem;
  right: 30%;
}
.alarm-icon {
  width: 60px;
  height: 59px;
}

.option-detail {
  position: absolute;
  color: #c8dbf4;
  font-weight: bold;
  right: 0.5rem;
  top: 0.6rem;
  font-size: 0.6rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  border: 1px solid #0d2b50;
  text-align: center;
  cursor: pointer;
  background-color: #4999d9;
  // background-color: #0a57be;
}
#alarm-drop {
  background: url("../../assets/nation/坠落报警.png");
  background-size: contain;
}
#alarm-help {
  background: url("../../assets/nation/紧急呼救.png");
  background-size: contain;
}
#alarm-danger {
  background: url("../../assets/nation/危险预警.png");
  background-size: contain;
}
#alarm-static {
  background: url("../../assets/nation/静止报警.png");
  background-size: contain;
}

.alarm-icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
