import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import VueRouter from 'vue-router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faTimes, faPlay, faStop, faPause, faForward, faBackward, faHardHat } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'element-theme-dark/lib/index.css';
import 'leaflet/dist/leaflet.css';
import 'swiper/swiper-bundle.min.js';
import 'swiper/swiper-bundle.css'
import Vue2OrgTree from 'vue2-org-tree'

library.add(faCircle);
library.add(faTimes);
library.add(faPlay);
library.add(faStop);
library.add(faPause);
library.add(faForward);
library.add(faBackward);
library.add(faHardHat);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(Vue2OrgTree);

const req = require.context('../node_modules/echarts/map/js/province',false,/\.js$/);
const requireAll = requireContext => requireContext.keys().map(requireContext);
requireAll(req);
require('element-theme-dark');

import router from './routers'

import store from './store'

Vue.config.productionTip = false;
Vue.prototype.$window = window;
window.app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
