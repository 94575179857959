<template>
  <div id="right">
    <emolumentStructureOfKeyPersonalChart :district-level="districtLevel" :org-level="orgLevel" />
    <staffAppraisalChart :district-level="districtLevel" :org-level="orgLevel" />
  </div>
</template>

<script>
  import emolumentStructureOfKeyPersonalChart from "./emolumentStructureOfKeyPersonalChart";
  import staffAppraisalChart from "./staffAppraisalChart";

  export default {
    name: 'HrIndexRightBar',
    components: {
      emolumentStructureOfKeyPersonalChart,
      staffAppraisalChart,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
  }
</script>

<style lang="less">
</style>
