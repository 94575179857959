import * as THREE from 'three';
import { OBJLoader2 } from 'wwobjloader2';
import { WEBGL } from './threejs/WebGL.js';
import buildingConfig from './building_config';

const mapRatio = 25
/**
 * {userID: {modelID, name, position, floor, color}}
 * */
THREE.Object3D.prototype.clear = function () {
  const children = this.children
  for (let i = children.length - 1; i >= 0; i--) {
    const child = children[i]
    child.clear()
    this.removeChild(child)
  }
}
const tooltip = document.querySelector('#tooltip')
const floorConfig = {
  floorHeight: 50,
  floorThickness: 0.08,
  floorHeightBase: 0.8
}

// 支持的options:
// 1. options.rotZ: 模型围绕Z轴顺时针旋转的角度
// 2. options.scale: 模型缩放比例
// 3. options.moveX/moveY/moveZ: 模型在三个轴上的移动距离
export function loadFloors(threeLayer, scene, workerPosition, floorData, options) {
  if (WEBGL.isWebGL2Available() === false) {
    document.body.appendChild(WEBGL.getWebGL2ErrorMessage())
  }

  let floorDataCache = {
    floorNameArr: [],
    floorDataArr: [],
    name: '',
    floorIDArr: [],
  }

  const UserCache = new Map()

  console.log(floorData);

  options = options || buildingConfig[floorData.buildingId] || {};
  options.scale = options.scale || 1;
  options.rotZ = options.rotZ || 0;
  options.moveX = options.moveX || 0;
  options.moveY = options.moveY || 0;
  options.moveZ = options.moveZ || 0;

  // lights
  const hLight = new THREE.HemisphereLight(0xffffff, 0x444444, 0.5)
  hLight.position.set(0, 200, 0)
  hLight.translateY(-100)
  scene.add(hLight)

  const aLight = new THREE.AmbientLight(0x404040) // soft white light
  scene.add(aLight)
  const dLight = new THREE.DirectionalLight(0xffffff, 0.3)
  dLight.position.set(0, 90, 100)
  dLight.target.y = -100
  scene.add(dLight)
  scene.add(dLight.target)

  // const mesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(2000, 2000), new THREE.MeshPhongMaterial({
  //  color: 0x555555,
  //  depthWrite: false
  // }))
  // mesh.rotation.x = -Math.PI / 2
  // scene.add(mesh)
  // const grid = new THREE.GridHelper(400, 40, 0x555555, 0x555555)
  // grid.material.opacity = 0.2
  // grid.material.transparent = false
  // scene.add(grid)

  window.scene = scene

  const floorGroup = new THREE.Group()
  const areaDetailsGroup = new THREE.Group()
  const modelGroup = new THREE.Group()
  const floorNameGroup = new THREE.Group()

  scene.add(modelGroup)
  scene.add(floorGroup)
  scene.add(areaDetailsGroup)
  scene.add(floorNameGroup)

  const addShape = (floorDataArr) => {
    if (floorGroup.children) {
      let len = floorGroup.children.length
      while (len) {
        floorGroup.remove(floorGroup.children[len - 1])
        len = floorGroup.children.length
      }
    }

    // scene.translateY()

    function addShape_inner(shape, color, x, y, z, rx, ry, rz, bevelThickness) {
      const extrudeSettings = { depth: floorConfig.floorHeightBase, bevelEnabled: false, bevelThickness }
      const geometry = new THREE.ExtrudeBufferGeometry(shape, extrudeSettings)

      const mesh = new THREE.Mesh(geometry, new THREE.MeshPhongMaterial({ color: color }))
      mesh.rotation.set(rx, ry, rz);
      mesh.scale.set(options.scale, options.scale, options.scale);
      mesh.position.set(options.moveX + x, options.moveY + y, options.moveZ + z);
      floorGroup.add(mesh)
    }

    /*
    function addShape_wall(shape, color, x, y, z, rx, ry, rz, bevelThickness) {
      const extrudeSettings = { depth: floorConfig.floorHeightBase, bevelEnabled: false, bevelThickness }
      const geometry = new THREE.ExtrudeBufferGeometry(shape, extrudeSettings)

      const mesh = new THREE.Mesh(geometry, new THREE.MeshPhongMaterial({ color: color }))
      mesh.rotation.set(rx, ry, rz);
      mesh.scale.set(options.scale, options.scale, options.scale);
      mesh.position.set(options.moveX + x, options.moveY + y, options.moveZ + z);
      floorGroup.add(mesh)
    }
    */

    const buildShape = (points) => {
      const s = new THREE.Shape().moveTo(points[0][0], points[0][1])
      for (let i = 1; i < points.length; i++) {
        s.lineTo(points[i][0], points[i][1])
      }
      return s
    }

    const buildBuildings = () => {
      for (let i = 0; i < floorDataArr.length; i++) {
        const { outline, rooms } = floorDataArr[i]
        addShape_inner(buildShape(outline.map(k => ([k[0] / mapRatio, k[1] / mapRatio]))), 0x1A3147, 0, 0, floorConfig.floorHeightBase + i * floorConfig.floorHeight, Math.PI, 0, options.rotZ / 180 * Math.PI, 1)
        /*
        for (let j = 0; j < outline.length; j++) {
          addShape_inner(buildShape(outline.map(k => ([k[0] / mapRatio, k[1] / mapRatio]))), 0x1A3147, 0, 0, floorConfig.floorHeightBase + i * floorConfig.floorHeight, Math.PI, 0, options.rotZ / 180 * Math.PI, 1)
        }
        */
        for (let h of rooms) {
          addShape_inner(buildShape(h.map(k => ([k[0] / mapRatio, k[1] / mapRatio]))), 0x0CBE9FE, 0, 0, floorConfig.floorHeightBase + i * floorConfig.floorHeight + floorConfig.floorThickness, Math.PI, 0, options.rotZ / 180 * Math.PI, .1)
        }
      }
    }
    buildBuildings()
  }

  /** @param names {[{name: string, id: string}]}*/
  const addFloorName = names => {
    if (floorNameGroup.children) {
      let len = floorNameGroup.children.length
      while (len) {
        floorNameGroup.remove(floorNameGroup.children[len - 1])
        len = floorNameGroup.children.length
      }
    }
    if (!names.length) return
    const fontLoader = new THREE.FontLoader()
     fontLoader.load('./threejs/helvetiker_regular.json', function (font) {
    //fontLoader.load('./MicrosoftYaHei_Regular.json', function (font) {
      const color = new THREE.Color(0xffffff)

      const material = new THREE.MeshBasicMaterial({
        color: color,
        transparent: true,
        // opacity: 0.4,
        side: THREE.DoubleSide
      })
      for (let i = 0; i < names.length; i++) {
        const name = names[i]
        const { areaCenters, nameAnchor } = floorDataCache.floorDataArr[i]
        const center = (nameAnchor && nameAnchor.length) ? nameAnchor[0] : areaCenters[0]
        const shapes = font.generateShapes(name, 3)
        const geometry = new THREE.ShapeBufferGeometry(shapes)
        geometry.computeBoundingBox()
        const xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x)
        geometry.translate(xMid, 18, 0)
        const text = new THREE.Mesh(geometry, material)
        text.rotation.x = Math.PI / 2;
        text.position.x = options.moveX + center[0] / mapRatio - 15;
        text.position.y = options.moveY + center[1] / mapRatio;
        text.position.z = options.moveZ + i * floorConfig.floorHeight - floorConfig.floorHeight / 2 + 5;
        floorNameGroup.add(text)
      }
    })
  }

  function convertXY(x, y) {
    var resX, resY;
    var dist = Math.sqrt(x * x + y * y);
    var angle = Math.atan2(-y, x) - options.rotZ / 180 * Math.PI;

    resX = options.moveX + dist * Math.cos(angle) * options.scale;
    resY = options.moveY + dist * Math.sin(angle) * options.scale;

    return [resX, resY];
  }

  /**
   * @param users {[{name: string, color: [number, number, number], position: {x: number, y: number}, floor: string, id: string, floorID: string}]}
   * */
  const loadModels = (users) => {
    for (let user of users) {
      const cached = UserCache.get(user.id)
      if (cached) {
        cached.traversed = true
        const object = modelGroup.getObjectByName(user.id)
        if (cached.floor !== user.floor) {
          console.log(`update ${user.name}(${user.id}) floor: ${cached.floor} => ${user.floor}`)
          const floorIndex = floorDataCache.floorIDArr.findIndex(f => f === user.floorID)
          object.position.z = options.moveZ + floorIndex * floorConfig.floorHeight + 6;
          cached.floor = user.floor
        } else if (cached.position.x !== user.position.x || cached.position.y !== user.position.y) {
          console.log(`update ${user.name}(${user.id}) x/y: ${cached.position.x}/${cached.position.y} => ${user.position.x}/${user.position.y}`);

          const res = convertXY(user.position.x, user.position.y);
          object.position.x = res[0];
          object.position.y = res[1];

          cached.position.x = user.position.x;
          cached.position.y = user.position.y;
        } else if (cached.color[0] !== user.color[0] || cached.color[1] !== user.color[1] || cached.color[2] !== user.color[2]) {
          console.log(`update ${user.name}(${user.id}) color: ${cached.color} => ${user.color}`)
          object.traverse(function (child) {
            if (child instanceof THREE.Mesh) child.material.color.setRGB(user.color[0], user.color[1], user.color[2])
          })
          cached.color = user.color
        } else if (cached.status_id !== user.status_id) {
          console.log(`update ${user.name}(${user.id}) status_id: ${cached.status_id} => ${user.status_id}`);
          if (object.emissiveAnimationInterval) {
            clearInterval(object.emissiveAnimationInterval);
          }
          object.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              child.material.emissiveIntensity = 0;
            }
          });
          if (user.status_id == 1 || user.status_id == 3) {
            object.traverse(function (child) {
              if (child instanceof THREE.Mesh) {
                child.material.emissive.setRGB(1, 0, 0);
                child.material.emissiveIntensity = 1;
              }
            });
            object.emissiveAnimationInterval = setInterval(function() {
              object.traverse(function (child) {
                if (child instanceof THREE.Mesh) {
                  if (child.material.emissiveIntensity) {
                    child.material.emissiveIntensity = 0;
                  } else {
                    child.material.emissiveIntensity = 1;
                  }
                }
              });
            }, 500);
          }
        } else {
          console.log(`${user.name}(${user.id}) on ${user.floor} nothing changed`)
        }
      } else {
        // 新增
        console.log(`add new worker: ${user.name}(${user.id}) on floor ${user.floor}`)
        const objLoader = new OBJLoader2()
        objLoader.load('./threejs/robot.obj', function (object3d) {
          const floorIndex = floorDataCache.floorIDArr.findIndex(f => f === user.floorID)
          const object = object3d.clone()
          object.name = user.id
          object.scale.set(2, 2, 2)
          object.rotation.x = Math.PI / 2;
          object.children[0].material.color.needsUpdate = true
          object.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              child.material.color.setRGB(user.color[0], user.color[1], user.color[2]);
            }
          })
          if (user.status_id == 1 || user.status_id == 3) {
            object.traverse(function (child) {
              if (child instanceof THREE.Mesh) {
                child.material.emissive.setRGB(1, 0, 0);
                child.material.emissiveIntensity = 1;
              }
            });
            object.emissiveAnimationInterval = setInterval(function() {
              object.traverse(function (child) {
                if (child instanceof THREE.Mesh) {
                  if (child.material.emissiveIntensity) {
                    child.material.emissiveIntensity = 0;
                  } else {
                    child.material.emissiveIntensity = 1;
                  }
                }
              });
            }, 500);
          }
          object.userData = { ...user}
          const res = convertXY(user.position.x, user.position.y);
          object.position.x = res[0];
          object.position.y = res[1];
          object.position.z = options.moveZ + floorIndex * floorConfig.floorHeight + 6;
          UserCache.set(user.id, user)
          modelGroup.add(object)
        }, null, function (err) {
          console.error('objLoader:', err)
        })
      }
    }

    for (let cached of UserCache.values()) {
      if (cached.traversed) {
        cached.traversed = false
      } else {
        const obj = modelGroup.children.find(obj => obj.name === cached.id)
        modelGroup.remove(obj)
        if (obj.emissiveAnimationInterval) {
          clearInterval(obj.emissiveAnimationInterval);
        }
        console.log(`remove ${cached.name}(${cached.id}) from cache, find in modelGroup: ${obj !== undefined}`)
        UserCache.delete(cached.id)
      }
    }
  }

  let mouse = new THREE.Vector2()
  let raycaster = new THREE.Raycaster()

  window.showPathClick = function(worker_id, worker_name) {
    if (worker_id) {
      window.ta.pathParams = `${worker_id}/${encodeURIComponent(window.mapUrl)}`;
      window.ta.pathWorkerName = worker_name;
      window.ta.pathShow = true;
    }
  }

  function fillTemplate(templateString, templateVars){
    return new Function("return `"+templateString +"`;").call(templateVars);
  }

  loadModels(workerPosition);
  floorDataCache = floorData;
  addShape(floorData.floorDataArr);
  addFloorName(floorData.floorNameArr);

  const html = `<div style="text-align: center;">
    <div>姓名：\${this.name}(\${this.hat_code})
    <br/>班组：\${this.group}</div>
    <button style='background-color:#399A75;border-radius: 4px;border: none;color: #fff;font-size:13px;line-height:18px;padding-bottom:3px;
    cursor: pointer;outline: none;margin-top: 4px;' id='showPath' onclick="showPathClick('\${this.id}', '\${this.name}')">轨迹</button>`;

  document.addEventListener('click', function (event) {
    event.preventDefault();
    tooltip.style.visibility = 'hidden';
    const x = (event.clientX / window.innerWidth) * 2 - 1;
    const y = -(event.clientY / window.innerHeight) * 2 + 1;
    mouse.x = x;
    mouse.y = y;
    raycaster.setFromCamera(mouse, threeLayer.getCamera());
    const intersects = raycaster.intersectObjects(modelGroup.children, true);

    if (intersects.length) {
      const userData = intersects[0].object.parent.userData;
      console.log(userData);
      tooltip.innerHTML = fillTemplate(html, userData);
      const {width, height} = getComputedStyle(tooltip);
      tooltip.style.left = event.clientX - parseInt(width) / 1.5 + 'px';
      tooltip.style.top = event.clientY - parseInt(height) - 30 + 'px';
      tooltip.style.visibility = 'visible';
    }
  });
}
