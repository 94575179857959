<template>
  <div id="right">
    <div id="onsite-people" style="margin-bottom: 1rem">
      <wrapper style="padding: 0;">
        <div class="info-block" style="min-height:5rem">
          <div
            class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
          >
            <div>现场工人工种分布</div>
          </div>
          <div style="padding-top: 3rem; font-size:0.875rem; font-weight: 500; position:relative;height: 16rem;">
            <div v-if="!!onSiteWorkerTypeList.length">
              <div style="display: flex;  height: 1.4rem; align-items: center; justify-content: space-between; padding:0 1rem; color: #42a9da; margin-bottom: 3px;">
                <div style="flex:1">工种</div>
                <div style="width:3rem;text-align:center">人数</div>
              </div>
              <div style="height:10.6rem;overflow:auto;">
                <div v-for="(item, index) in onSiteWorkerTypeList" :key="item.name" style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem" :style="{ background: index%2 ? '#1b2d5d' : '#152554'}">
                  <div style="flex:1">
                    {{item.name}}
                  </div>
                  <div style="width:3rem;text-align:center">{{item.count}}</div>
                </div>
              </div>
            </div>
            <div v-if="!onSiteWorkerTypeList.length" style="margin:2rem 2rem 0.5rem 2rem;text-align:center">
              <img src="../../assets/noData.png" style="width:40%;height:40%" />
              <div style="text-align:center;font-size:1.1em;margin-top:1rem;color:#18b0a4;">当前暂无人员在场</div>
            </div>
          </div>
        </div>
      </wrapper>
    </div>
    <div id="people-warning-list" style="margin-bottom: 1rem">
      <wrapper style="padding: 0;">
        <div class="info-block" style="min-height:5rem">
          <div
            class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
          >
            <div>共建方情况</div>
            <div style="display:flex;align-items:center;">
              <div
                class="option-block2"
                style="min-width: 5rem;"
                :class="{ 'option-block-selected2': mode2 == '1' }"
                @click="() => companyChange('1')"
              >出勤率</div>
              <div
                class="option-block2"
                style="min-width: 5rem;"
                :class="{ 'option-block-selected2': mode2 == '2' }"
                @click="() => companyChange('2')"
              >人数</div>
            </div>
          </div>
          <div style="height:19.5rem;padding:3rem 1rem 0;">
            <div style="height:15.5rem;overflow-y:scroll;">
              <div v-for="(item, index) in attendanceCompanyLists" :key="item.company_id" style="margin-bottom: 1rem;cursor: pointer;" @click="showDetail(1, item)">
                <div style="display:flex;align-items:center;justify-content: space-between;margin-bottom: 0.5rem;">
                  <div style="display:flex;align-items:center;">
                    <i class="flag" :style="{'backgroundColor': colors[index]}">{{index+1}}</i>
                    <i :style="{'color': colors[index]}">{{item.company_name}}</i>
                    </div>
                  <div>{{item.count}}{{mode2 == '1' ? '%':'人'}}</div>
                </div>
                <div style="flex: 5" class="line-bar">
                  <div class="real-bar" :style="{'width': item.count + '%' , 'backgroundColor': colors[index]}" v-if="!flag"></div>
                  <div class="real-bar" :style="{'width': (item.count/5) + '%' , 'backgroundColor': colors[index]}" v-else></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </div>
    <div>
      <wrapper style="padding: 0;">
        <div
          class="info-block"
          style="min-height:5rem;margin-bottom:1rem"
        >
          <div
            class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
          >
            <div style="display:flex;align-items:center;">
              <div
                class="option-block2"
                style="min-width: 5rem;"
                :class="{ 'option-block-selected2': mode == '1' }"
                @click="() => switchworkerTypePieChartOption('1')"
              >突击队</div>
              <div
                class="option-block2"
                style="min-width: 5rem;"
                :class="{ 'option-block-selected2': mode == '2' }"
                @click="() => switchworkerTypePieChartOption('2')"
              >零星用工</div>
            </div>
            <div class="detail" @click="showDetail(2, '')">
              详情
            </div>
          </div>
          <div style="height:18rem;padding-top:3rem">
            <v-chart
              :options="workerTypePieChart"
              autoresize
            />
          </div>
        </div>
      </wrapper>
    </div>

     <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="80rem"
      top="8rem"
      :destroy-on-close='true'
    >
      <wrapper style="height:49rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow2 = false"
        />
        <div style="padding-top: 1rem;">
          <div class="panells">
            <div class="panel">
              <div style="font-size: 18px">{{popupDetail2.company_name}}</div>
              <div style="margin-top: 2rem">
                <div style="margin-bottom: 0.7rem;">社会信用代码：{{popupDetail2.credit_code}}</div>
                <div style="margin-bottom: 0.7rem;">注册资金：{{popupDetail2.capital}}W</div>
                <div style="margin-bottom: 0.7rem;">专业类别：{{popupDetail2.pro_category}}</div>
                <div style="margin-bottom: 0.7rem;">资源类型：{{popupDetail2.type}}</div>
                <div>合作项目：{{popupDetail2.project_count}}</div>
              </div>
            </div>
            <div class="panel">
              <div style="height:12rem;">
                <div style="color: #fff">近十日出勤人数/出勤率</div>
                <v-chart
                  :options="companyAttendanceChart1"
                  autoresize
                />
              </div>
            </div>
            <div class="panel" style="height: 16rem;">
              <div style="height:13rem;">
                <div style="color: #fff;padding-bottom: 0.5rem">{{popupDetail2.company_name}}工种分布</div>
                <v-chart :options="companyAttendanceChart2" ref="addtenceType1" @mouseover="attenceTypeMouseOver1" @mouseout="attenceTypeMouseOut1" autoresize />
              </div>
            </div>
            <div class="panel" style="height: 16rem;">
              <div style="height:13rem;">
                <div style="color: #fff">{{popupDetail2.company_name}}班组分布</div>
                <v-chart :options="companyAttendanceChart3" ref="addtenceType2" @mouseover="attenceTypeMouseOver2" @mouseout="attenceTypeMouseOut2" autoresize />
              </div>
            </div>
            <div class="panel" style="width:100%">
              <div style="height:12rem;">
                <div style="color: #fff">{{popupDetail2.company_name}}工人详情</div>
                <div style="margin:1rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
                  <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                    <div style="width:5rem;text-align:center">姓名</div>
                    <div style="width:15rem;text-align:center">共建方</div>
                    <div style="width:8rem;text-align:center">班组</div>
                    <div style="width:15rem;text-align:center">工种</div>
                    <div style="width:8rem;text-align:center">状态</div>
                    <div style="width:15rem;text-align:center">进场时间</div>
                  </div>
                  <div style="height:8rem;overflow:auto;">
                    <div
                      v-for="(item, index) in popManagementTopProjects"
                      :key="item.worker_id"
                      style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                      :style="{ background: index%2 ? '#102855' : '#213a77'}"
                    >
                      <div style="width:5rem;text-align:center">
                        {{item.worker_name}}
                      </div>
                      <div style="width:15rem;text-align:center">{{item.company_name}}</div>
                      <div
                        style="width:8rem;text-align:center"
                      >{{item.team_name}}</div>
                      <div style="width:15rem;text-align:center">{{item.work_type}}</div>
                      <div
                        style="width:8rem;text-align:center"
                      >{{item.status}}</div>
                      <div style="width:15rem;text-align:center">{{item.in_time}}</div>
                    </div>
                    <div
                      v-if="!(popManagementTopProjects && popManagementTopProjects.length)"
                      style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                    >
                      暂无详情记录
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
     </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow3"
      width="90rem"
      top="12rem"
      :destroy-on-close='true'
    >
      <wrapper style="height:35rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow3 = false"
        />
        <div class="attendance">
          <div class="attendance-top">
            <div class="tabbar">
              <div :class="{ 'tabar-select': popupSelect == '1' }" @click="getAttendance('1')">按季</div>
              <div :class="{ 'tabar-select': popupSelect == '2' }" @click="getAttendance('2')">按月</div>
              <div :class="{ 'tabar-select': popupSelect == '3' }" @click="getAttendance('3')">按周</div>
            </div>
            <div style="text-align:center;font-size: 18px;margin: 1rem 0;">{{popupBottomName}}</div>
            <div style="height:24rem;padding-top:1rem">
              <v-chart :options="attenceWorkersBarChart" autoresize />
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow4"
      width="90rem"
      top="8rem"
      :destroy-on-close='true'
    >
      <wrapper style="height:50rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow4 = false"
        />
        <div class="attendance">
          <div class="attendance-top">
            <div style="font-size: 18px;margin: 1rem 0 1rem 1rem;">在场工人数：<i style="color: #38DDE6">{{popupNum}}人</i></div>
            <div style="height:20rem;border: 1px solid #122768">
               <div style="font-size: 18px;margin: 1rem 0 1rem 1rem;">各共建方工人数</div>
              <v-chart :options="companyWorkersBarChart" autoresize />
            </div>
          </div>
          <div style="display: flex;margin-top: 1rem;">
            <div style="height:22rem;padding: 0 1rem;flex: 1;border: 1px solid #122768">
              <div style="margin: 1rem 0 1rem 1rem;">在场工人工种分布</div>
              <v-chart style="height:18rem;" ref="workerType1" :options="workPieChart1" @mouseover="workTypeMouseOver1" @mouseout="workTypeMouseOut1" autoresize />
            </div>
            <div style="height:22rem;padding: 0 1rem;flex: 1;border: 1px solid #122768;margin: 0 0.5rem;">
              <div style="margin: 1rem 0 1rem 1rem;">在场工人年龄段分布</div>
              <v-chart style="height:18rem;" ref="workerType2" :options="workPieChart2" @mouseover="workTypeMouseOver2" @mouseout="workTypeMouseOut2" autoresize />
            </div>
             <div style="height:22rem;padding:0 1rem;flex: 1;border: 1px solid #122768">
               <div style="margin: 1rem 0 1rem 1rem;">在场工人男女比例</div>
              <v-chart style="height:18rem;" ref="workerType3" :options="workPieChart3" @mouseover="workTypeMouseOver3" @mouseout="workTypeMouseOut3" autoresize />
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow5"
      width="70rem"
      top="12rem"
      :destroy-on-close='true'
    >
      <wrapper style="height:35rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow5 = false"
        />
        <div style="padding: 1rem">
          <div style="display:flex;font-size: 18px">
            现场总人数：<i style="color: #38DDE6;font-style:normal;margin-right: 20px">{{InlineWorkerInfo.worker_now_online_count+InlineWorkerInfo.manager_now_online_count}}人</i>现场工人数：<i style="color: #38DDE6;font-style:normal;margin-right: 20px">{{InlineWorkerInfo.worker_now_online_count}}人</i>现场管理人员：<i style="color: #38DDE6;font-style:normal;">{{InlineWorkerInfo.manager_now_online_count}}人</i>
          </div>
          <div style="display:flex;align-items:center;justify-content: space-between;">
            <div style="padding-top: 1rem; font-size:0.875rem; font-weight: 500; position:relative;height: 30rem;width: 30%">
              <div v-if="!!onSiteWorkerTypeList2.length">
                <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem; color: #42a9da;background-color: #1b2d5d">
                  <div style="flex:1">工种</div>
                  <div style="width:3rem;text-align:center">人数</div>
                </div>
                <div style="height:28rem;overflow:auto;">
                  <div v-for="(item, index) in onSiteWorkerTypeList2" :key="item.name" style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem" :style="{ background: index%2 ? '#1b2d5d' : '#152554'}">
                    <div style="flex:1">
                      {{item.name}}
                    </div>
                    <div style="width:3rem;text-align:center">{{item.count}}</div>
                  </div>
                </div>
              </div>
              <div v-if="!onSiteWorkerTypeList2.length" style="margin:2rem 2rem 0.5rem 2rem;text-align:center">
                <img src="../../assets/noData.png" style="width:40%;height:40%" />
                <div style="text-align:center;font-size:1.1em;margin-top:1rem;color:#18b0a4;">当前暂无人员在场</div>
              </div>
            </div>

            <div style="height: 25rem;width: 65%">
              <div style="font-size: 18px">
                各共建方当前工人数
              </div>
              <v-chart :options="InLineWorkChart" autoresize />
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  import wrapper from '../../components/wrap'
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { project } from '../../api'
  import { SimpleBarChartTemplate } from './barChartTemplates'
  import { applyUpdate } from "../../utils";
  import { attendancePieChartTemplate, colors, popupChartTemplate } from './pieChartTemplates'

  export default {
    name: 'laborRightBar',
    components: {
      'v-chart': ECharts,
      wrapper,
    },
    props: {
      projectDetail: Object,
      liveData: Object,
      onSiteWorkerTypeInfo: Array,
      warningStats: Object,
      warningList: Array,
      statusColorMap: Object,
      event: String,
      eventCounter: Number,
    },
    watch: {
      eventCounter(newValue, oldValue) {
        if (newValue > oldValue) {
          if (this.event == 'onlineWorkerClick') {
            this.getCompanyWorkInfo();
          } else if (this.event == 'onsiteWorkerClick') {
            this.getInlineWorkerInfo();
          }
        }
      },
      navMenuIndex: function(newValue, oldValue) {
        this.commonBroadcastWatch(newValue, oldValue);
      },
    },
    data () {
      return {
        onSiteWorkerTypeList: [],
        attendanceCompanyLists: [],
        colors: ['#FC619D', '#FF904D', '#48BFE3', '#1990FF', '#2d7dff', '#994deb', '#33a5e2', '#604deb', '#42cea6', '#4d77eb','#58b97a',   '#12c9b7','#89bf67',   '#18a95c','#aebf67',   '#61c549','#dbb97f',   '#ebdf59','#dba47f',   '#ebae59','#ed85a8',   '#eb8a59','#dc5c72',   '#ea4f2c'],
        workerTypePieChart: {},
        option: {
          color: ["#23cf9e", "#fdc81e"],
          grid: {
            contentLabel: true,
            left: '12%',
            bottom: "15%",
            top: "15%",
            right: "10%",
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          tooltip: {
            trigger: 'axis',
            // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
            formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                  result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + params[0].data;
                  if(params[1]){
                      result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                  }

              }
              return result;
            }
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true,
              name: "人数：人"
            },
            {
              name: "工时：h",
              show: true,
              min: 0,
              max: 100,
              interval: 25,
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // },
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              barWidth: 10,
              data: []
            },
            {
              name: '数量',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                  {offset: 1, color: 'rgba(27, 208, 128, 0)'}
                ])
              },
              symbolSize: 0,
              yAxisIndex: 1,
              data: [],
            }
          ]
        },
        mode: '1',
        mode2: '1',
        flag: false,
        popupShow2: false,
        popupDetail2: {},
        companyAttendanceChart1: {},
        option2: {
          color: ['#1bd080', '#FDC81E'],
          grid: {
            contentLabel: true,
            top: '25%',
            left: '8%',
            right: '8%',
            bottom: '12%'
          },
          legend: {
            data: [],
            itemWidth: 20,
            itemHeight: 10,
            textStyle: {
              fontSize: 10,
              color: '#fff'
            }
          },
          tooltip: {
            trigger: 'axis',
            // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
            formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                  result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + params[0].data;
                  if(params[1]){
                      result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                  }

              }
              return result;
            }
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: false, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              name: '',
            },
            {
              nameTextStyle: {
                padding: [0, 0, 0, 40],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              min: 0,
              max: 100,
              interval: 25,
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // },
              },
              axisLine: { show: false, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              name: '',
            }
          ],
          series: [
            {
              name: '',
              type: 'bar',
              barWidth: 10,
              yAxisIndex: 0,
              data: []
            },
            {
              name: '',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                  {offset: 1, color: 'rgba(27, 208, 128, 0)'}
                ])
              },
              symbolSize: 0,
              yAxisIndex: 1,
              data: [],
            }
          ]
        },
        popManagementTopProjects: [],
        companyAttendanceChart2: {},
        companyAttendanceChartHighlightIndex2: 0,
        companyAttendanceChart3: {},
        companyAttendanceChartHighlightIndex3: 0,
        popupSelect: '1',
        attenceWorkersBarChart: {},
        popupShow3: false,
        popupBottomName: '',
        option3: {
          color: ['#1bd080', '#EA8B1B'],
          grid: {
            contentLabel: true,
            top: '25%',
            left: '15%',
            right: '18%',
            bottom: '7%'
          },
          legend: {
            data: [],
            itemWidth: 20,
            itemHeight: 10,
            textStyle: {
              fontSize: 10,
              color: '#fff'
            }
          },
          tooltip: {
            trigger: 'axis',
            // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
            formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                  result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + params[0].data;
                  if(params[1]){
                      result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                  }

              }
              return result;
            }
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: false, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              name: '',
            },
            {
              nameTextStyle: {
                padding: [0, 0, 0, 40],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              min: 0,
              max: 100,
              interval: 25,
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // },
              },
              axisLine: { show: false, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              name: '',
            }
          ],
          series: [
            {
              name: '',
              type: 'bar',
              barWidth: 10,
              yAxisIndex: 0,
              data: []
            },
            {
              name: '',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                  {offset: 1, color: 'rgba(27, 208, 128, 0)'}
                ])
              },
              symbolSize: 0,
              yAxisIndex: 1,
              data: [],
            }
          ]
        },
        popupShow4: false,
        popupNum: 0,
        companyWorkersBarChart: {},
        option4: {
          color: ['#4191E5'],
          grid: {
            contentLabel: true,
            top: '9%',
            left: '6%',
            right: '6%',
            bottom: '40%'
          },
          tooltip: {
            trigger: 'axis',
            formatter: "{b}<br>{a0}: {c0}"
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35,
                formatter: function (params) {
                  return params.length > 6 ? (params.substr(0, 6) + '...'):params;
                }
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: false, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              name: '人数',
            }
          ],
          series: [
            {
              name: '工人数',
              type: 'bar',
              barWidth: 10,
              yAxisIndex: 0,
              data: []
            }
          ]
        },
        workPieChart1: {},
        workerTypePieChartHighlightIndex1: 0,
        workPieChart2: {},
        workerTypePieChartHighlightIndex2: 0,
        workPieChart3: {},
        workerTypePieChartHighlightIndex3: 0,
        popupShow5: false,
        onSiteWorkerTypeList2: [],
        InlineWorkerInfo: {},
        InLineWorkChart: {},
        option5: {
          color: ['#4191E5'],
          grid: {
            contentLabel: true,
            top: '25%',
            left: '8%',
            right: '6%',
            bottom: '30%'
          },
          tooltip: {
            trigger: 'axis',
            formatter: "{b}<br>{a0}: {c0}"
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 45,
                formatter: function (params) {
                    var val = "";
                    if (params.length > 6) {
                        val = params.substr(0, 6) + '...';
                        return val;
                    } else {
                        return params;
                    }
                }
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: false, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              name: '人数',
            }
          ],
          series: [
            {
              name: '工人数',
              type: 'bar',
              barWidth: 10,
              yAxisIndex: 0,
              data: []
            }
          ]
        },
        timer: null
      }
    },
    mounted () {
      // const self = this
      const { id: projectID } = this.$route.params;
      this.projectId = projectID;
      const token = this.$route.query.token;
      window.token = token;

      // this.projectId = '48146e7e-b027-4bf4-8520-0d935405b681';

      this.getList();
      // setInterval(() => {
      //   this.getList();
      // }, 5000)

      this.companyChange(this.mode2)

      this.switchworkerTypePieChartOption(this.mode)

      // this.getCompanyWorkInfo()

      // this.getInlineWorkerInfo();
    },
    methods: {
      commonBroadcastWatch(newValue, oldValue) {
        if (newValue != oldValue) {

          if (this.timer) {
            clearTimeout(this.timer);
          }

          this.getList();
        }
      },
      scheduleNextBroadcast() {
        this.timer = setTimeout(() => {
          this.getList();
        }, 5000);
      },
      getList(){
        project.getDashProjLaborOnlineWorkerTypeCollect(1000000, this.projectId)
          .then(d => {
            // console.log(d);
            this.onSiteWorkerTypeList = d;
          })
      },
      switchworkerTypePieChartOption(mode){
        this.mode = mode;
        project.getDashProjLaborMonth12SporadicCollect(this.mode, this.projectId)
          .then(d => {
            // console.log(d);
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option.legend.data = d.legend;
            this.option.xAxis[0].data = d.xAxis_data;
            this.option.series[0].name = d.legend[0];
            this.option.series[0].data = d.bar_series;
            this.option.series[1].data = d.line_series;
            this.option.series[1].name = d.legend[1];
            this.workerTypePieChart = applyUpdate(chart, this.option);
          })
      },
      companyChange(mode){
        this.mode2 = mode;
        project.getDashProjLaborAttendanceCompanyCollect(1000000, this.projectId, this.mode2)
          .then(d => {
            // console.log(d);
            this.attendanceCompanyLists = d;
            this.flag = this.attendanceCompanyLists.some(vv => {
              return vv.count > 100
            })
          })
      },
      showDetail(val, item){
        // eslint-disable-next-line no-empty
        if(val === 1){
          this.popupShow2 = true;
          if(item){
            project.getProjLaborCompanyBasicInfo(this.projectId, item.company_id)
              .then(d => {
                // console.log(d);
                this.popupDetail2 = d;
              })

            project.getProjLaborCompanyAttendanceDate10Collect(this.projectId, item.company_id)
              .then(d => {
                // console.log(d);
                const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
                this.option2.legend.data = d.legend;
                this.option2.xAxis[0].data = d.xAxis_data;
                this.option2.series[0].data = d.bar_series;
                this.option2.series[0].name = d.legend[0];
                this.option2.series[1].data = d.line_series;
                this.option2.series[1].name = d.legend[1];
                this.option2.yAxis[0].name = d.legend[0];
                this.option2.yAxis[1].name = `${d.legend[1]}%`;
                this.companyAttendanceChart1 = applyUpdate(chart, this.option2);
              })

            project.getProjLaborCompanyAttendanceWorkerTypeCollect(this.projectId, item.company_id)
              .then(d => {
                // console.log(data);
                let chart = JSON.parse(JSON.stringify(popupChartTemplate));
                chart.series[0].label.normal.formatter = attendancePieChartTemplate.series[0].label.normal.formatter;
                chart.color = colors;

                // const maxPieces = 14;
                // if (d.length > maxPieces) {
                //   d[maxPieces - 1].worker_count = d.slice(maxPieces - 1).reduce((accumulator, currentValue) => accumulator + currentValue.worker_count, 0);
                //   d[maxPieces - 1].work_type = '其它';
                //   d = d.slice(0, maxPieces);
                // }
                chart.legend.data = [];
                d.forEach(e => {
                  chart.legend.data.push(e.work_type)
                });
                chart.legend = Object.assign({}, chart.legend, {
                  type: 'scroll',
                  textStyle: {
                    color: '#fff',
                    padding: [2, 0, 0, 0]
                  },
                  pageIconColor: "#fff",
                  left: '70%'
                  // padding: [0, 0, 0, 100]
                })
                chart.series[0].data = d.map(x => ({
                  name: x.work_type,
                  value: x.worker_count
                }));
                if (window.innerWidth < 2250) {
                  chart.series[0].radius = [35, 65];
                }

                // 默认高亮第一个值不是0的分片，否则默认第一片
                this.companyAttendanceChartHighlightIndex2 = 0;
                for (let i = 0; i < d.length; i++) {
                  if (d[i].people_count) {
                    this.companyAttendanceChartHighlightIndex2 = i;
                    break;
                  }
                }

                chart.series.push(Object.assign({}, chart.series[0], { label: {
                  fontSize: 11,
                  formatter: '{b}：{c}',
                }, silent: true }));

                setTimeout(
                  () =>
                    this.$refs.addtenceType1 &&
                    this.$refs.addtenceType1.dispatchAction({
                      type: 'highlight',
                      seriesIndex: 0,
                      dataIndex: this.companyAttendanceChartHighlightIndex2
                    }),
                  100
                );
                this.companyAttendanceChart2 = chart;
              })

            project.getProjLaborCompanyAttendanceTeamCollect(this.projectId, item.company_id)
              .then(d => {
                // console.log(data);
                let chart = JSON.parse(JSON.stringify(popupChartTemplate));
                chart.series[0].label.normal.formatter = attendancePieChartTemplate.series[0].label.normal.formatter;
                chart.color = colors;

                // const maxPieces = 14;
                // if (d.length > maxPieces) {
                //   d[maxPieces - 1].worker_count = d.slice(maxPieces - 1).reduce((accumulator, currentValue) => accumulator + currentValue.worker_count, 0);
                //   d[maxPieces - 1].team_name = '其它';
                //   d = d.slice(0, maxPieces);
                // }
                chart.legend.data = [];
                d.forEach(e => {
                  chart.legend.data.push(e.team_name)
                });
                chart.legend = Object.assign({}, chart.legend, {
                  type: 'scroll',
                  textStyle: {
                    color: '#fff',
                    padding: [2, 0, 0, 0]
                  },
                  pageIconColor: "#fff",
                  left: '70%'
                })
                chart.series[0].data = d.map(x => ({
                  name: x.team_name,
                  value: x.worker_count
                }));
                if (window.innerWidth < 2250) {
                  chart.series[0].radius = [35, 65];
                }

                // 默认高亮第一个值不是0的分片，否则默认第一片
                this.companyAttendanceChartHighlightIndex3 = 0;
                for (let i = 0; i < d.length; i++) {
                  if (d[i].people_count) {
                    this.companyAttendanceChartHighlightIndex3 = i;
                    break;
                  }
                }

                chart.series.push(Object.assign({}, chart.series[0], { label: {
                  fontSize: 11,
                  formatter: '{b}：{c}',
                }, silent: true }));

                setTimeout(
                  () =>
                    this.$refs.addtenceType2 &&
                    this.$refs.addtenceType2.dispatchAction({
                      type: 'highlight',
                      seriesIndex: 0,
                      dataIndex: this.companyAttendanceChartHighlightIndex3
                    }),
                  100
                );
                this.companyAttendanceChart3 = chart;
              })

            project.getProjLaborCompanyWorkerCollect(this.projectId, item.company_id)
              .then(d => {
                // console.log(d);
                this.popManagementTopProjects = d;
              })
          }
        // eslint-disable-next-line no-empty
        }else if(val === 2){
          this.popupShow3 = true;
          this.getAttendance(this.popupSelect)
        }
      },
      getAttendance(popupSelect){
        this.popupSelect = popupSelect;
        project.getProjLaborDateSporadicCollect(this.projectId, this.mode, this.popupSelect)
          .then(d => {
            // console.log(d);
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option3.legend.data = d.legend;
            this.option3.xAxis[0].data = d.xAxis_data;
            this.option3.yAxis[0].name = `${d.legend[0]}：人`
            this.option3.yAxis[1].name = `${d.legend[1]}：h`
            this.option3.series[0].data = d.bar_series;
            this.option3.series[0].name = d.legend[0];
            this.option3.series[1].data = d.line_series;
            this.option3.series[1].name = d.legend[1];
            this.attenceWorkersBarChart = applyUpdate(chart, this.option3);
            this.popupBottomName = `${this.mode === '1' ? '突击队':'零星用工'}情况详情`;
          })
      },
      attenceTypeMouseOver1(params) {
        if (params.dataIndex != this.companyAttendanceChartHighlightIndex2) {
          this.$refs.addtenceType1.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.companyAttendanceChartHighlightIndex2
          });
          this.companyAttendanceChartHighlightIndex2 = params.dataIndex;
        }
      },
      attenceTypeMouseOut1() {
        this.$refs.addtenceType1.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.companyAttendanceChartHighlightIndex2
        });
      },
      attenceTypeMouseOver2(params) {
        if (params.dataIndex != this.companyAttendanceChartHighlightIndex3) {
          this.$refs.addtenceType2.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.companyAttendanceChartHighlightIndex3
          });
          this.companyAttendanceChartHighlightIndex3 = params.dataIndex;
        }
      },
      attenceTypeMouseOut2() {
        this.$refs.addtenceType2.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.companyAttendanceChartHighlightIndex3
        });
      },
      getCompanyWorkInfo(){
        this.popupShow4 = true;
        project.getProjLaborWorkerCompanyCollect(this.projectId)
          .then(d => {
            // console.log(d);
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option4.xAxis[0].data = d.xAxis_data;
            this.option4.series[0].data = d.series;
            this.popupNum = eval(d.series.join("+"));
            this.companyWorkersBarChart = applyUpdate(chart, this.option4);
          })

        project.getProjLaborWorkercountCollect(this.projectId, 1)
          .then(data => {
            // console.log(d)
            let d = data.data;
            const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
            chart.series[0].label.normal.formatter = attendancePieChartTemplate.series[0].label.normal.formatter;
            chart.color = colors;

            const maxPieces = 14;
            if (d.length > maxPieces) {
              d[maxPieces - 1].value = d.slice(maxPieces - 1).reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
              d[maxPieces - 1].name = '其它';
              d = d.slice(0, maxPieces);
            }
            chart.series[0].data = d;

            // 默认高亮第一个值不是0的分片，否则默认第一片
            this.workerTypePieChartHighlightIndex1 = 0;
            for (let i = 0; i < d.length; i++) {
              if (d[i].value) {
                this.workerTypePieChartHighlightIndex1 = i;
                break;
              }
            }

            chart.series.push(Object.assign({}, chart.series[0], { label: {
              rich: {
                text: {
                  fontSize: 11
                }
              },
              formatter: function (params) {
                return `${params.name} ${Math.round(params.percent)}%`;
              },
            }, silent: true }));

            setTimeout(
              () =>
                this.$refs.workerType1 &&
                this.$refs.workerType1.dispatchAction({
                  type: 'highlight',
                  seriesIndex: 0,
                  dataIndex: this.workerTypePieChartHighlightIndex1
                }),
              100
            );
            this.workPieChart1 = chart;
          })

        project.getProjLaborWorkercountCollect(this.projectId, 2)
          .then(data => {
            // console.log(d)
            let d = data.data;
            const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
            chart.series[0].label.normal.formatter = attendancePieChartTemplate.series[0].label.normal.formatter;
            chart.color = colors;

            const maxPieces = 14;
            if (d.length > maxPieces) {
              d[maxPieces - 1].value = d.slice(maxPieces - 1).reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
              d[maxPieces - 1].name = '其它';
              d = d.slice(0, maxPieces);
            }
            chart.series[0].data = d;

            // 默认高亮第一个值不是0的分片，否则默认第一片
            this.workerTypePieChartHighlightIndex2 = 0;
            for (let i = 0; i < d.length; i++) {
              if (d[i].value) {
                this.workerTypePieChartHighlightIndex2 = i;
                break;
              }
            }

            chart.series.push(Object.assign({}, chart.series[0], { label: {
              rich: {
                text: {
                  fontSize: 11
                }
              },
              formatter: function (params) {
                return `${params.name} ${Math.round(params.percent)}%`;
              },
            }, silent: true }));

            setTimeout(
              () =>
                this.$refs.workerType2 &&
                this.$refs.workerType2.dispatchAction({
                  type: 'highlight',
                  seriesIndex: 0,
                  dataIndex: this.workerTypePieChartHighlightIndex2
                }),
              100
            );
            this.workPieChart2 = chart;
          })

        project.getProjLaborWorkercountCollect(this.projectId, 3)
          .then(data => {
            // console.log(d)
            let d = data.data;
            const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
            chart.series[0].label.normal.formatter = attendancePieChartTemplate.series[0].label.normal.formatter;
            chart.color = colors;

            const maxPieces = 14;
            if (d.length > maxPieces) {
              d[maxPieces - 1].value = d.slice(maxPieces - 1).reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
              d[maxPieces - 1].name = '其它';
              d = d.slice(0, maxPieces);
            }
            chart.series[0].data = d;

            // 默认高亮第一个值不是0的分片，否则默认第一片
            this.workerTypePieChartHighlightIndex3 = 0;
            for (let i = 0; i < d.length; i++) {
              if (d[i].value) {
                this.workerTypePieChartHighlightIndex3 = i;
                break;
              }
            }

            chart.series.push(Object.assign({}, chart.series[0], { label: {
              rich: {
                text: {
                  fontSize: 11
                }
              },
              formatter: function (params) {
                return `${params.name} ${Math.round(params.percent)}%`;
              },
            }, silent: true }));

            setTimeout(
              () =>
                this.$refs.workerType3 &&
                this.$refs.workerType3.dispatchAction({
                  type: 'highlight',
                  seriesIndex: 0,
                  dataIndex: this.workerTypePieChartHighlightIndex3
                }),
              100
            );
            this.workPieChart3 = chart;
          })
      },
      workTypeMouseOver1(params) {
        if (params.dataIndex != this.workerTypePieChartHighlightIndex1) {
          this.$refs.workerType1.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.workerTypePieChartHighlightIndex1
          });
          this.workerTypePieChartHighlightIndex1 = params.dataIndex;
        }
      },
      workTypeMouseOut1() {
        this.$refs.workerType1.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.workerTypePieChartHighlightIndex1
        });
      },
      workTypeMouseOver2(params) {
        if (params.dataIndex != this.workerTypePieChartHighlightIndex2) {
          this.$refs.workerType2.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.workerTypePieChartHighlightIndex2
          });
          this.workerTypePieChartHighlightIndex2 = params.dataIndex;
        }
      },
      workTypeMouseOut2() {
        this.$refs.workerType2.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.workerTypePieChartHighlightIndex2
        });
      },
      workTypeMouseOver3(params) {
        if (params.dataIndex != this.workerTypePieChartHighlightIndex3) {
          this.$refs.workerType3.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.workerTypePieChartHighlightIndex3
          });
          this.workerTypePieChartHighlightIndex3 = params.dataIndex;
        }
      },
      workTypeMouseOut3() {
        this.$refs.workerType3.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.workerTypePieChartHighlightIndex3
        });
      },
      getInlineWorkerInfo(){
        this.popupShow5 = true;
        project.getDashProjLaborOnlineWorkerTypeCollect(100000, this.projectId)
          .then(d => {
            // console.log(d);
            this.onSiteWorkerTypeList2 = d;
          })

        project.getProjLaborBraodcastDetail(this.projectId)
          .then(d => {
            // console.log(d);
            this.InlineWorkerInfo = d[0];
          })

        project.getDashProjLaborAttendanceCompanyCollect(1000000, this.projectId, 4)
          .then(d => {
            // console.log(d);
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option5.xAxis[0].data = [];
            this.option5.series[0].data = [];
            d.forEach(vv => {
              this.option5.xAxis[0].data.push(vv.company_name)
              this.option5.series[0].data.push(vv.count)
            })
            this.InLineWorkChart = applyUpdate(chart, this.option5);
          })
      }
    }
  }
</script>

<style scoped lang="less">
#live-data {
  div.content {
    padding-top: 1rem;
    margin: 0 -6px;
    overflow: hidden;

    p.item {
      display: flex;
      width: 50%;
      float: left;

      .img {
        height: 2.5rem;
        width: 2.5rem;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 0.4rem;
      }

      .value {
        flex: 1;

        .title {
          font-size: 0.8rem;
          display: block;
          color: #999999;
        }

        .number {
          display: block;
          font-size: 1.6rem;
          color: #ffffff;
        }
      }

      &:first-of-type {
        .img {
          background-image: url(../../assets/01.png);
        }
      }

      &:nth-of-type(2) {
        .img {
          background-image: url(../../assets/02.png);
        }
      }

      &:nth-of-type(3) {
        .img {
          background-image: url(../../assets/03.png);
        }
      }

      &:nth-of-type(4) {
        .img {
          background-image: url(../../assets/04.png);
        }
      }

      &:nth-of-type(5) {
        .img {
          background-image: url(../../assets/05.png);
        }
      }

      &:nth-of-type(6) {
        .img {
          background-image: url(../../assets/06.png);
        }
      }
    }
  }
}

#equipment-status {
  .content {
    background: url('../../assets/shebei.png') no-repeat 4px 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 90px;
    height: 5.375rem;
    font-size: 0.875rem;

    p {
      line-height: 1.5rem;
    }
  }
}

#people-warning-list {
  .warning-item {
    margin-bottom: 0.75rem;
    padding: 8px 6px;
    font-size: 0.75rem;
    line-height: 1.5rem;
    background-color: #1e2c61;
    border-radius: 0.25rem;
    color: #ffffff;
    position: relative;
    display: flex;
    div.name {
      color: #999999;
      width: 2rem;
    }
    div.value {
      width: 3.7rem;
      padding-left: 0.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    time {
      background-color: #577acc;
      border-radius: 4px;
      padding: 5px;
    }
  }
}
.linebar{
  display: flex;
  align-items: center;
  margin: 1.3rem auto 10px;
  font-size: 12px;
}
.line-bar{
  height: 5px;
  background-color: #344A7A;
  margin-right: 10px;
}
.real-bar{
  height: 5px;
  background-color: #57C7B6;
  border-radius: 12px;
}
.flag{
  display: inline-block;
  background-color: #57C7B6;
  color: #fff;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
}
.panells{
  display:flex;
  align-items:center;
  justify-content: space-between;
  flex-wrap:wrap;
}
.panel{
  width: 49%;
  height: 14rem;
  background-color: #031A46;
  padding: 1rem;
  margin-bottom: 1rem;
}
</style>
