<template>
  <iframe :src="compData.url" :style="{'width': '100%', 'height': '100%', 'border': 0}" />
</template>

<script>

export default {
  name: "Iframe",
  components: {
  },
  props: {
    compData: Object,
  },
  data() {
    return {
    };
  },
};
</script>
<style lang="less">
</style>
