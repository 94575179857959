<template>
  <div class="info-block" style="min-height:5rem">
    <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>方案分类统计</div>
        <div class="detail" @click="showDetail()">
          详情
        </div></div>
    <div style="height:20rem;padding-top:3rem">
      <div
        v-if="schemeApprovaChart.series && [].concat.apply([], schemeApprovaChart.series.map(x => x.data)).length == 0"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart v-else :options="schemeApprovaChart" autoresize />
    </div>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">方案分类统计
            </div>
            <!-- <div class="tabbar" style="width:22rem">
              <div
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >正常（{{normal}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 5 }"
                @click="getAttendance(5)"
              >缓建（{{wait}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 4 }"
                @click="getAttendance(4)"
              >停工（{{stop}}）</div>
            </div> -->
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:8rem;text-align:center">序号</div>
                <div style="width:15rem;text-align:center">单位名称</div>
                <div style="width:8rem;text-align:center">方案数量</div>
                <div style="width:8rem;text-align:center">A类</div>
                <div style="width:8rem;text-align:center">B类</div>
                <div style="width:8rem;text-align:center">C类</div>
                <div style="width:8rem;text-align:center">D类</div>
                <div style="width:12rem;text-align:center">其它</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popProjectsList"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:8rem;text-align:center">{{index+1}}</div>
                  <div style="width:15rem;text-align:center">{{item.orgName}}</div>
                  <div style="width:8rem;text-align:center">{{item.fa}}</div>
                  <div style="width:8rem;text-align:center">{{item.faA}}</div>
                  <div style="width:8rem;text-align:center">{{item.faB}}</div>
                  <div style="width:8rem;text-align:center">{{item.faC}}</div>
                  <div style="width:8rem;text-align:center">{{item.faD}}</div>
                  <div style="width:12rem;text-align:center">{{item.faX}}</div>
                </div>
                <div
                  v-if="!(popProjectsList && popProjectsList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>

        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { tech } from '../../../../api_tech'
  import { stackedBarChartTemplate } from "../../barChartTemplates";
  import { applyUpdate } from "../../../../utils";
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'SchemeCategoryChart',
    components: {
      'v-chart': ECharts,
      wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSchemeCategoryChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSchemeCategoryChart();
        }
      }
    },
    data () {
      return {
        schemeApprovaChart: {},
        popProjectsList:[],
        popupShow:false
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.updateSchemeCategoryChart();
    },
    methods: {
      updateSchemeCategoryChart() {
        tech.getSchemeCategory()
          .then(d => {
            var len = d.length;
            if(window.$sFid != "7B867F6775E143F8B1F3131BFA85C13E"){
              len = 10;
            }
            // 封装数据
            var orgData = [];
            var aData = [];
            var bData = [];
            var cData = [];
            var dData = [];
            for (let i = 0; i < len; i++) {
              orgData.push(d[i].orgName);
              aData.push(d[i].faA);
              bData.push(d[i].faB);
              cData.push(d[i].faC);
              dData.push(d[i].faD);
            }
            const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
            const option = {
              color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  },
                  formatter:function(param){
                    let str = param[0].name+"<br/>";
                    for (let index = 0; index < param.length; index++) {
                      str += param[index].seriesName+":"+param[index].value+"个<br/>";
                    }
                    return str;
                  }
              },
              grid: {
                containLabel: true,
                left: '5%',
                right: '6%',
                bottom: "5%",
                top: "12%"
              },
              xAxis: [
                {
                  name:"单位",
                  data: orgData,
                  axisLabel: {
                    color: 'white',
                    fontWeight: 'bold',
                    rotate: 40,
                    // formatter: value => truncateStr(value, 10),
                  },
                  show: true,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'category',
                }
              ],
              yAxis: [
                {
                  name:'数量:个',
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  boundaryGap: [0, '20%'],
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'value'
                },
                {
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  boundaryGap: [0, '10%'],
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                    formatter: function(value) {
                      return value ? value + '%' : value;
                    },
                  },
                  saxisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'value',
                  show: false
                }
              ],
              series: [
                {
                  name: 'A类',
                  type: 'bar',
                  stack: 'total',
                  barWidth: 10,
                  label: {
                    show: true,
                    color:"white"
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: aData
                },
                {
                  name: 'B类',
                  type: 'bar',
                  stack: 'total',
                  barWidth: 10,
                  label: {
                    show: true,
                    color:"white"
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: bData
                },
                {
                  name: 'C类',
                  type: 'bar',
                  stack: 'total',
                  barWidth: 10,
                  label: {
                    show: true,
                    color:"white"
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: cData
                },
                {
                  name: 'D类',
                  type: 'bar',
                  stack: 'total',
                  barWidth: 10,
                  label: {
                    show: true,
                    color:"white"
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: dData
                }
              ]
            };
            this.schemeApprovaChart = applyUpdate(chart, option);
          })
          // eslint-disable-next-line
          .catch((e) => console.error(e.msg || e.message));
      },
      showDetail(){
        tech.getSchemeCategory()
          .then(res=>{
            this.popupShow = true;
            this.popProjectsList = res;
          })
      }
    },
  }
</script>

<style lang="less">
</style>
