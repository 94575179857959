<template>
  <div style="width:100%;height:10rem;display:flex;justify-content:center">
    <div style="display:flex;align-items:center">
      <img :src="require(`../assets/nation/icons/${broadcastIcons[0]}.png`)" />
      <div>
        <div class="stats-label" :style="{fontSize: titleFontSize}">{{broadcastTitles[0]}}</div>
        <div style="display:flex" @click="handleClick && handleClick(1)">
          <v-number
            v-model="(broadcastData || {}).value1"
            :speed="500"
            style="font-family:digital;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[0], fontSize: valueFontSize}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center" :style="{marginLeft: sepSize}">
      <img :src="require(`../assets/nation/icons/${broadcastIcons[1]}.png`)" />
      <div>
        <div class="stats-label" :style="{fontSize: titleFontSize}">{{broadcastTitles[1]}}</div>
        <div style="display:flex" @click="handleClick && handleClick(2)">
          <v-number
            v-model="(broadcastData || {}).value2"
            :speed="500"
            style="font-family:digital;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[1], fontSize: valueFontSize}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center" :style="{marginLeft: sepSize}">
      <img :src="require(`../assets/nation/icons/${broadcastIcons[2]}.png`)" />
      <div>
        <div class="stats-label" :style="{fontSize: titleFontSize}">{{broadcastTitles[2]}}</div>
        <div style="display:flex" @click="handleClick && handleClick(3)">
          <v-number
            v-model="(broadcastData || {}).value3"
            :speed="500"
            style="font-family:digital;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[2], fontSize: valueFontSize}"
          ></v-number>
        </div>
      </div>
    </div>
    <div v-if="broadcastTitles.length >= 4" style="display:flex;align-items:center" :style="{marginLeft: sepSize}">
      <img :src="require(`../assets/nation/icons/${broadcastIcons[3]}.png`)" />
      <div>
        <div class="stats-label" :style="{fontSize: titleFontSize}">{{broadcastTitles[3]}}</div>
        <div style="display:flex" @click="handleClick && handleClick(4)">
          <v-number
            v-model="(broadcastData || {}).value4"
            :speed="500"
            style="font-family:digital;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[3], fontSize: valueFontSize}"
          ></v-number>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VNumber from "./v-number";

  export default {
    name: 'GenericBroadcasts',
    components: {
      VNumber,
    },
    props: {
      broadcastTitles: Array,
      broadcastColors: Array,
      broadcastIcons: Array,
      broadcastData: Object,
      handleClick: Function,
      titleFontSize: {
        default: '1.2rem',
        type: String,
      },
      valueFontSize: {
        default: '3.5rem',
        type: String,
      },
      sepSize: {
        default: '3rem',
        type: String,
      }
    },
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-weight: bold;
  color: #cececf;
}
</style>
