<template>
<div id="left">
    <div class="info-block" style="min-height:5rem">
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>项目经理情况</div>
        <!-- <div class="detail" @click="showDetail(1)">
          详情
        </div> -->
      </div>
      <div style="height:19rem;padding-top:3rem">
        <div
          style="display:flex;justify-content:space-around;margin-bottom:0.6rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')">
            项目经理队伍
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')">
            持证率对比
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '3' }"
            @click="() => switchCompanyWorkersOption('3')">
            学历情况
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '4' }"
            @click="() => switchCompanyWorkersOption('4')">
            晋升来源
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '5' }"
            @click="() => switchCompanyWorkersOption('5')">
            业绩情况
          </div>
        </div>
        <div
          v-if="!companyWorkersPieChart.series || !companyWorkersPieChart.series[0].data.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyWorkersPieChart" @click="companyWorkersPieChartClick" autoresize/>
      </div>
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { human } from '../../../../api_hr'
  import { colors } from '../../pieChartTemplates';
//  import CustomSelect from '../../../../components/select'
  import { truncateStr } from "../../../../utils";
// import func from 'vue-editor-bridge'

  export default {
    name: 'ProjectManagerChart',
    components: {
      'v-chart': ECharts,
    //   'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      }
    },
    data () {
      return {
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear(),
        companyWorkersData: {},
        companyWorkersOption: "1",
        companyWorkersPieChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.getAll();
    },
    methods: {
      getAll(){
          this.switchCompanyWorkersOption(this.companyWorkersOption);
      },
      switchCompanyWorkersOption(choose){
          this.companyWorkersOption = choose;
          switch (this.companyWorkersOption) {
              case "1":
                  this.updateTroopChart();
                  break;
              case "2":
                  this.updatePermitOddsChart();
                  break;
              case "3":
                  this.updateEducationChart();
                  break;
              case "4":
                  this.updatePromotionSourceChart();
                  break;
              case "5":
                  this.updatePerformanceChart();
                  break;
              default:
                  break;
          }
      },
      categorySelect(option) {
        this.selectedYear = option;
        this.updatePermitOddsChart();
      },
      updateTroopChart(){
        human.getProjectMgrTeam().then((res => {
          var xList = [];
          var projectNums = [];
          var pmgrNums = [];
          for (let i = 0; i < res.length; i++) {
            xList.push(res[i].name);
            projectNums.push(res[i].subData[0].value);
            if(res[i].subData[1]!=null)
              pmgrNums.push(res[i].subData[1].value);
            else
              pmgrNums.push(0);
          }
          let option = option = {
            color: colors,
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['在施项目数量', '项目经理数量'],
              textStyle:{
                fontSize: 11,//字体大小
                color: '#ffffff'//字体颜色
              },
            },
            calculable: true,
            xAxis: [
              {
                type: 'category',
                // prettier-ignore
                data:xList,
                 axisLabel: {
                  color: '#e6f2f3',
                  fontWeight: 'bold',
                  rotate: 40,
                  formatter: value => truncateStr(value, 10),
                },
                show: true,
                axisLine: { show: true, lineStyle: { color: '#36445f' } },
                splitLine: { show: false },
                axisTick: { show: false }
              }
            ],
            yAxis: [
              {
                nameTextStyle: {
                  padding: [0, 40, 0, 0],
                  color: '#e6f2f3',
                  fontWeight: 'bold',
                },
                boundaryGap: [0, '20%'],
                axisLabel: {
                  color: '#e6f2f3',
                  fontWeight: 'bold',
                },
                axisLine: { show: false, lineStyle: { color: '#36445f' } },
                splitLine: { show: false },
                axisTick: { show: false },
                type: 'value',
                show: true
              }
            ],
            series: [
              {
                name: '在施项目数量',
                type: 'bar',
                data: projectNums
              },
              {
                name: '项目经理数量',
                type: 'bar',
                data: pmgrNums
              }
            ]
          };
          this.companyWorkersPieChart = option;
        }))
      },
      updatePermitOddsChart() {
        human.getProjectMgrPremitOdds().then(res=>{
          let datas = [
            {"name":"一公司","value":Math.floor(res[0].subData[0].value*100,2)}
          ]
          let option = {
            color:colors,
            tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                color: '#999'
                }
              }
            },
            legend:{
              data:['持证率','目标值'],
              textStyle:{
                fontSize:12,
                color:"white"
              }
            },
            xAxis:[
              {
                type:'category',
                data:['一公司'],
                axisPointer: {
                  type: 'shadow'
                  },
                axisLabel:{
                  color:'white'
                }
              }
            ],
            yAxis:[
              {
                type:'value',
                name:'持证率',
                nameTextStyle:{
                    color:"white",
                },
                axisLabel: {
                  color:'white',
                  formatter: '{value} %'
                }
              }
            ],
            series:[
              {
                name:"持证率",
                type:'bar',
                data:[datas[0].value]
              },
              {
                name:"目标值",
                type:'line',
                data:[75]
              }
            ]
          }
          this.companyWorkersPieChart = option;
        })
      },
      updateEducationChart(){
         // 学历情况
         human.getProjectMgrEducation().then((res) =>{
             let datas = [
                 {"name":res[0].name,"value":res[0].value},
                 {"name":res[1].name,"value":res[1].value},
                 {"name":res[2].name,"value":res[2].value},
                 {"name":res[3].name,"value":res[3].value},
             ]
             let option = {
                 color: colors,
                 tooltip: {
                     trigger: 'item',
                     formatter: '{a} <br/>{b} : {c} ({d}%)'
                     },
                     series: [{
                         label: {
                             show: true,
                             fontSize:'14',
                             formatter: '{b} {d}%  ',
                             },
                          name: '学历分析',
                          type: 'pie',
                          radius: ['30%','55%'],
                          data: datas,
                          emphasis: {
                                itemStyle: {
                                    fontSize: '12',
                                    fontWeight: 'bold'
                                }
                            }
                        }
                    ]
             }
             this.companyWorkersPieChart = option;
         })
      },
      updatePromotionSourceChart(){
        // 晋升来源
        human.getProjectMgrPromotionSource().then((res) => {
          var lastData = [];
          var xmfzr = 0,swfzr = 0,jsfzr = 0,jdjl = 0,scjl = 0,aqzj = 0,zlzj = 0,qtgw=0;
          for (let index = 0; index < res.length; index++) {
            if(res[index].name == '大项目部经理' || res[index].name == '项目经理' || 
            res[index].name == '项目负责人' || res[index].name == '执行经理' || 
            res[index].name == '储备项目经理' || res[index].name == '项目执行经理'){
              xmfzr += res[index].value;
            }else if(res[index].name == '商务经理' || res[index].name == '商务部负责人' ||
            res[index].name == '商务负责人' || res[index].name == '储备商务经理'){
              swfzr += res[index].value;
            }else if(res[index].name == '项目总工程师' || res[index].name == '技术负责人' || 
            res[index].name == '项目副总工程师' || res[index].name == '储备项目总工程师'){
              jsfzr += res[index].value;
            }else if(res[index].name == '机电经理' || res[index].name == '储备机电经理' ){
              jdjl += res[index].value;
            }else if(res[index].name == '生产经理' || res[index].name == '储备生产经理' 
            ||res[index].name == '现场经理' ){
              scjl += res[index].value;
            }else if(res[index].name == '安全总监' || res[index].name == '储备安全总监' ){
              aqzj += res[index].value;
            }else if(res[index].name == '质量总监' || res[index].name == '储备质量总监' 
            || res[index].name == '质量经理' ){
              zlzj += res[index].value;
            }else{
              qtgw+= res[index].value;
              // lastData.push({"name":res[index].name,"value":res[index].value});
            }
          }
          lastData.push({"name":"项目负责人","value":xmfzr});
          lastData.push({"name":"商务负责人","value":swfzr});
          lastData.push({"name":"技术负责人","value":jsfzr});
          lastData.push({"name":"机电经理","value":jdjl});
          lastData.push({"name":"生产经理","value":scjl});
          lastData.push({"name":"安全总监","value":aqzj});
          lastData.push({"name":"质量总监","value":zlzj});
          lastData.push({"name":"其它岗位","value":qtgw});
               let option = {
                 color: colors,
                 tooltip: {
                     trigger: 'item',
                     formatter: '{a} <br/>{b} : {c} ({d}%)'
                     },
                     series: [{
                         label: {
                             show: true,
                             fontSize:'14',
                             formatter: '{b} {d}%  ',
                             },
                          name: '晋升来源',
                          type: 'pie',
                          radius: "50%",
                          data: lastData,
                          emphasis: {
                                itemStyle: {
                                    fontSize: '12',
                                    fontWeight: 'bold'
                                }
                            }
                        }
                    ]
             }
             this.companyWorkersPieChart = option;
          })
      },
      updatePerformanceChart(){
        this.companyWorkersPieChart = {};
      },
      showDetails(param){
        console.log(param);
      },
      companyWorkersPieChartClick(){
        console.log("companyWorkersPieChartClick");
      }
    }
  }
</script>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}

.option-block2 {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3.4rem;
  border: 1px solid #1b8ebd;
  text-align: center;
  cursor: pointer;
}
.option-block-selected{
  background-color: transparent !important;
  border: 1px solid #274C7D !important;
}
.option-block-selected2 {
  background-color: #4191e5;
  color: #fff;
}
.attendance-top{
  padding-top: 10px;
}
.tabbar{
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  border: 1px solid #157EAB;
  width: 210px;
  margin: auto;
  cursor: pointer;
}
.tabbar div{
  flex: 1;
  text-align: center;
}
.tabar-select{
  background-color: #4191E5;
  font-weight: bold;
}
.items div{
  color: #fff !important;
}
.selected{
  color: #fff !important;
}
.linebar{
  margin-top: 5rem;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto 10px;
}
.real-bar{
  height: 5px;
  background-color: #57C7B6;
  border-radius: 12px;
}
</style>

