<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :sep-size="broadcastSep"
    :title-font-size="'1rem'"
    :value-font-size="'3rem'"
  />
</template>

<script>
  import Broadcasts from "../../../../components/broadcasts";
  import { tech } from "../../../../api_tech";

  var xmid = "";
  export default {
    name: 'QualitySub1Broadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['成果指标', '实际完成', '未完成数'],
        broadcastIcons: ['成果目标', '成果完成', '成果未完成'],
        broadcastColors: ['#fea453', '#40f8ff', '#e02f37'],
        broadcastSep: '3rem',
      }
    },
    props: {
      broadcastData: Object,
      worProjectId: String,
    },
    mounted(){
      xmid = this.worProjectId;
    }
  }

  export function updateData(container, scheduleNextUpdate) {
    tech.getProjectScienceStatistic(xmid)
      .then(res=>{
        container.broadcastData.techScience = res;
        container.broadcastData.techScience.value1 = res.cgmb;
        container.broadcastData.techScience.value2 = res.cgwc;
        container.broadcastData.techScience.value3 = res.cgmb-res.cgwc;
        container.statsData = res;
        // scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1rem;
  font-weight: bold;
  color: #cececf;
}
</style>
