<template>
  <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title">职称统计</div>
    <div
      class="detail-select"
      style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
    >
      <!-- <CustomSelect
        :options="yearList"
        :value="selectedYear"
        @input="categorySelect"
        style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
      /> -->
    </div>
    <div style="height:25rem;padding-top:3rem">
      <div v-if="!JobTileChart.series || !JobTileChart.series[0].data.length">
        <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;">
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
      </div>
      <v-chart v-else :options="JobTileChart" autoresize />
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { human } from '../../../../api_hr'
  import { colors } from "../../../project2/pieChartTemplates";
//   import CustomSelect from '../../../../components/select'

  export default {
    name: 'JobTileChart',
    components: {
      'v-chart': ECharts,
    //   'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateJobTitleChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateJobTitleChart();
        }
      }
    },
    data () {
      return {
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear(),
        JobTileChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.updateJobTitleChart();
    },
    methods: {
      categorySelect(option) {
        this.selectedYear = option;
        this.updateJobTitleChart();
      },
      updateJobTitleChart() {
        human.getJobTitieState().then((result) => {
          let datas = [
              {"name":result[0].NAME,"value":result[0].VALUE},
              {"name":result[1].NAME,"value":result[1].VALUE},
              {"name":result[2].NAME,"value":result[2].VALUE}
          ]
          let option = {
                color:colors,
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} {d}%'
                },
                legend:{
                  position:'inner',
                  textStyle:{
                      fontSize:12,
                      color:"white"
                  }
                },
                grid: {
                  top: "5%",
                  left: "10%",
                  right: "9%",
                  bottom: "5%",
                  containLabel: true,
                },
                series: [
                    {
                        name:"职称统计",
                        type: 'pie',
                        minAngle: 2,
                        radius: "50%",
                        avoidLabelOverlap: true,
                        data: datas,
                        label: {
                            formatter: '{b}:{d}% ',
                            borderWidth: 1,
                            borderRadius: 2,
                            fontSize:14
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0
                            }
                        }
                    }
                ]
            };
            this.JobTileChart = option;
        })
      }
    }
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
