<template>
  <div id="left">
    <wrapper style="padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>项目经营情况</div>
        </div>
        <div
          style="height:17rem;padding-top:3rem;"
          class="machine_use"
        >
          <div v-if="!businessChart.series || !businessChart.series[0].data.length" style="padding-top:1rem">
          <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;">
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
        </div>
        <v-chart v-else :options="businessChart" autoresize />
      </div>
      </div>
    </wrapper>
    <wrapper style="padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>项目全周期人员曲线</div>
          <CustomSelect
          :options="yearList"
          :value="selectedYear"
          @input="categorySelect"
          style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
        />
        </div>
        <div
          style="height:17rem;padding-top:3rem;"
          class="machine_use"
        >
          <div v-if="!ProjectCycleCurveChart.series || !ProjectCycleCurveChart.series[0].data.length" style="padding-top:1rem">
          <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;">
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
        </div>
        <v-chart v-else :options="ProjectCycleCurveChart" @click="projectCycleCurveChartClick" autoresize />
      </div>
      </div>
    </wrapper>

    <wrapper style="padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>人员考核分析</div>
          <div class="detail" @click="showDetail(2)">
            详情
          </div>
        </div>
        <div
          style="height:17rem;padding-top:2rem"
          class="machine_use"
        >
          <div v-if="!examineChart.series || !examineChart.series[0].data.length" style="padding-top:1rem">
          <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;">
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
        </div>
        <v-chart v-else :options="examineChart" autoresize />
      </div>
      </div>
    </wrapper>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold;width:30rem">项目人员考核台账
            </div>
            <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem;">
              <label style="width:2rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;">年份</label>
              <CustomSelect :options="yearList" :value="examYear" @input="yearSelect" 
              style="width:5rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;"/>
              <label style="width:4rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;margin-left:2rem">考核等级</label>
              <CustomSelect :options="gradeList" :value="selectedGrade" @input="gradeSelect" 
              style="width:5rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;"/>
              <div>
              <el-input
                placeholder="请输入姓名"
                v-model="personName"
                class="input-with-select"
                clearable
              >
                <el-button
                  slot="append"
                  @click="examSearch"
                >搜索</el-button>
              </el-input>
            </div>
            </div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:2rem;text-align:center">序号</div>
                <div style="width:6rem;text-align:center">姓名</div>
                <div style="width:10rem;text-align:center">所属单位</div>
                <div style="width:8rem;text-align:center">岗位</div>
                <div style="width:8rem;text-align:center">考核周期</div>
                <div style="width:8rem;text-align:center">考核得分</div>
                <div style="width:8rem;text-align:center">考核等级</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popProjectsList"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:2rem">
                    {{securityStarPage>1?(securityStarPage-1)*10+index+1:(index+1>9?(index+1):'0'+(index+1))}}
                  </div>
                  <div style="width:6rem;text-align:center">{{item.name}}</div>
                  <div style="width:10rem;text-align:center">{{item.dpt}}</div>
                  <div style="width:8rem;text-align:center">{{item.pos}}</div>
                  <div style="width:8rem;text-align:center">{{item.examInterval}}</div>
                  <div style="width:8rem;text-align:center">{{item.score}}</div>
                  <div style="width:8rem;text-align:center">{{item.examLevel}}</div>
                </div>
                <div
                  v-if="!(popProjectsList && popProjectsList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from 'vue-echarts'
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { human } from "../../../../api_hr";
import CustomSelect from '../../../../components/select'
import wrapper from "../../../../components/wrap";

export default {
  name: "hrLeftBar",
  components: {
    'v-chart': ECharts,
    'CustomSelect': CustomSelect,
    wrapper,
  },
  props: {
    projectDetail: Object,
    showTrace: Function,
  },
  data() {
    return {
      projectId: null,
      yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
      selectedYear: new Date().getFullYear(),
      gradeList:["全部","优秀","良好","一般"],
      selectedGrade:"",
      mgmStatsOption: "all",
      mgmStatsSelectedDeptIdx: null,
      mgmStatsBarChart: {},
      mgmDetailShow: false,
      mgmSubWindowUrl: null,
      mgmSubWindowShow: false,
      personalInfoPopupShow: false,
      personalInfoWorkerId: null,
      personalInfoTabIndex: 1,
      securityStarPopupShow: false,
      securityStarPage: 1,
      securityStarPages: 1,
      securityStarInfo: [],
      inOutPopupShow: false,
      workerInOutPage: 1,
      workerInOutPages: 1,
      workerInOutInfo: [],
      ProjectCycleCurveChart: {},
      businessChart: {},
      timer: null,
      examineChart:{},
      popupShow:false,
      popProjectsList:[],
      personName:"",
      examYear:new Date().getFullYear(),
      workerTypePieChartHighlightIndex: 0,
    };
  },
  mounted() {
    // const self = this
    const { id: projectID } = this.$route.params;
    this.projectId = projectID;
    const token = this.$route.query.token;
    window.token = token;
    this.xmid = this.$route.query.xmid;
    this.getData();
  },
  methods: {
    getData() {
      // getSafetyMachineTypeCount
      human
        .getProjectCycleCurve(this.xmid,this.selectedYear)
        .then((d) => {
          var planNums = [];
          var actualNums = [];
          var mouthList = [];
          for (let i = 0; i < d.data[0].value.length; i++) {
            mouthList.push(d.data[0].value[i].DATA_MONTH);
            planNums.push(d.data[0].value[i].DATA_NUM);
            if(d.data[1].value[i]!=null)
              actualNums.push(d.data[1].value[i].DATA_NUM);
            else
              actualNums.push(0);
          }
          const option = {
            color:[
              '#f32c2c',
              '#f38e2c',
              '#2ce4ff',
              '#19b6fb',
              '#5dd5c3',
              '#ffed21',
              '#159172',
              '#fb9c5c'
            ],
            tooltip: {
              trigger: 'axis'
              },
              legend: {
                  data: ['实际人数', '计划人员'],
                  textStyle: {
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '12'
                },
              },
              grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
              },
              xAxis: {
                  name:'月',
                  type: 'category',
                  data:mouthList,
                  axisLabel:{
                    interval:0,
                    rotate:40,
                    color:'white'
                  }
              },
              yAxis: {
                  name:'人数',
                  nameTextStyle:{
                    color:"white",
                  },
                  axisLine: {
                    show: false,
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      type: [5, 10],
                      color: '#14253f',
                      width: 2,
                    }
                  },
                  type: 'value',
                  axisLabel: {
                     color: 'white'
                  }
              },
              series: [
                  {
                      name: '实际人数',
                      type: 'line',
                      data: actualNums,
                      areaStyle:{}
                  },{
                      name: '计划人员',
                      type: 'line',
                      data: planNums,
                      areaStyle:{}
                  }
                ]
          }
          this.ProjectCycleCurveChart = option;
        });
      this.getEcharts2();
      this.getEcharts3();
    },
    projectCycleCurveChartClick(option){
      console.log(option);
      human.getOverstaffingCondition(this.xmid,this.selectedYear+"-"+option.name).then(res=>{
        console.log(res);
      })
    },
    getEcharts2() {
        human.getProjectExamine(this.xmid).then(res=>{
            const option = {
            color:[
              '#f32c2c',
              '#f38e2c',
              '#2ce4ff',
              '#19b6fb',
              '#5dd5c3',
              '#ffed21',
              '#159172',
              '#fb9c5c'
            ],
            tooltip: {
              trigger: 'axis'
              },
            legend: {
                data:['1年内3次及以上为A的人', '1年内2次及以上为C的人'],
                textStyle: {
                color: 'white',
                fontWeight: 'bold',
                fontSize: '12'
              },
            },
              xAxis: {
                  type: 'category',
                  data:['1年内3次及以上为A的人', '1年内2次及以上为C的人'],
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisLabel:{
                    interval:0,
                    rotate:20,
                    color:'white'
                  }
              },
              yAxis: {
                  name:'人数',
                  nameTextStyle:{
                    color:"white",
                  },
                  axisLine: {
                    show: false,
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      type: [5, 10],
                      color: '#14253f',
                    }
                  },
                  type: 'value',
                  axisLabel: {
                     color: 'white'
                  }
              },
              series: [
                  {
                      name: '人数',
                      type: 'bar',
                      barWidth:10,
                      data: [res[0].value,res[1].value],
                  }
                ]
            }
            this.examineChart = option;
        })
    },
    showDetail(){
      this.popupShow = true;
      var grade = "";
      if(this.selectedGrade == '全部'){
        grade = "";
      }else{
        grade = this.selectedGrade;
      }
      human.getProjectExamineDetail(this.xmid,grade,this.examYear,this.personName).then(res=>{
        this.popProjectsList = res;
      })
    },
    gradeSelect(option){
      this.selectedGrade = option;
      this.showDetail();
    },
    yearSelect(option){
      this.examYear = option;
      this.showDetail();
    },
    examSearch(){
      this.showDetail();
    },
    getEcharts3() {
        human.getProjectBusinessDetail(this.xmid).then(res=>{
          console.log(res);
        })
    },
    categorySelect(option){
      this.selectedYear = option;
      this.getData();
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.machine_use {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.machine_use_item {
  width: 40%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.8rem;
}
.machine_use_item_1 {
  border: 1px solid #29e8a8;
  background-color: rgba(41, 232, 168, 0.2);
}
.machine_num_1 {
  font-size: 1.5rem;
  color: #29e8a8;
}
.machine_use_item_2 {
  border: 1px solid #fee21d;
  background-color: rgba(254, 226, 29, 0.2);
}
.machine_num_2 {
  font-size: 1.5rem;
  color: #fee21d;
}
.machine_use_item_3 {
  border: 1px solid #4191e5;
  background-color: rgba(65, 145, 229, 0.2);
}
.machine_num_3 {
  font-size: 1.5rem;
  color: #4191e5;
}
.machine_use_item_4 {
  border: 1px solid #f38e2c;
  background-color: rgba(243, 142, 44, 0.2);
}
.machine_num_4 {
  font-size: 1.5rem;
  color: #f38e2c;
}
.stats-close-btn {
  position: absolute;
  background-image: url("../../../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>