import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    '免税城': [0, 0, 540],
  };

  const buildingBoundaries = {
    '免税城': [[-10000, 10000], [10000, 10000], [10000, -10000], [-10000, -10000]],
  };

  const buildingFinishedHeights = {
    '免税城': 540,
  };

  const doneFloors = {
    '免税城': 4,
  };

  const traceViews = {
    '_': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    '免税城': {
      center: [212.5586750441628, 840.0729503350088],
      pitch: 50.8,
      zoom: 2.59,
      bearing: -19,
    },
  };

  function getUserZValue(user) {
    return (user.floor == 'ground' ? 0 : user.height) * 3.833 + 5;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 3.833;
  }

  function getZValueFromHeight(height) {
    return height * 3.833;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene, meshMap) {
    /*
    for (const mesh in meshMap) {
      let materials = meshMap[mesh].material;
      if (!Array.isArray(materials)) {
        materials = [materials];
      }

      for (const material of materials) {
        if (material.color.r == 0.8 && material.color.g == 0.8 && material.color.b == 0.8) {
          material.color.r = 0.372;
          material.color.g = 0.372;
          material.color.b = 0.372;
        }
      }
    }
    */

    for (const mesh in meshMap) {
      const color = meshMap[mesh].material.color;
      if (Math.round(color.r * 1000) == 180 && Math.round(color.g * 1000) == 306 && Math.round(color.b * 1000) == 275) {
        meshMap[mesh].material.transparent = true;
        meshMap[mesh].material.opacity = 0;
      }
    }

    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/haikouff-blout.glb',
    modelType: 'gltf',

    //fullOpacity: 0.85,
    revealOpacity: 0.15,
    deepRevealOpacity: 0,

    modelOptions: {
      scale: 4.5,
      rotZ: 0,
      moveX: -1882,
      moveY: -1075,
      moveZ: -616,
    },

    sceneRotation: {
      x: 0,
      y: 0,
      z: 0
    },

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
