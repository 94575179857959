<template>
  <div id="right">
    <div
      class="info-block"
      style="min-height:5rem"
    >

      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>{{title}}</div>
        <div
          class="detail"
          v-if="mapLevel!==2&&!(quarterCheckTrouble.series && [].concat.apply([], quarterCheckTrouble.series.map(x => x.data)).length == 0)"
          @click="showDetail"
        >
          详情
        </div>
      </div>
      <div style="height:24rem;padding-top:3rem">
        <div
          v-if="quarterCheckTrouble.series && [].concat.apply([], quarterCheckTrouble.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="quarterCheckTrouble"
          @click="quarterCheckTroubleClick"
          autoresize
        />
      </div>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow4"
      @close="dialogClose"
      width="70rem"
      top="12rem"
    >
      <wrapper style="height:45rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="dialogClose"
        />
        <div class="attendance">

          <div
            class="swiper-c"
            style="position:relative"
          >

            <swiper
              class="swiper"
              :options="swiperOption"
              ref="mySwiper"
            >
              <swiper-slide
                class="swiper-slide"
                v-for="(item,index) in courseList"
                :key="index"
              >
                <div
                  class="swiper-content"
                  @click="activeName(item)"
                  :style="{backgroundColor:(item.check_name==active?'#4191E5':'')}"
                >{{item.check_name}}</div>
              </swiper-slide>
              <div
                class="swiper-pagination"
                slot="pagination"
              ></div>
            </swiper>
            <div
              class="swiper-button-prev"
              slot="button-prev"
              @click="prev"
            ></div>
            <div
              class="swiper-button-next"
              slot="button-next"
              @click="next"
            ></div>
          </div>

          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="position:relative;top:1rem;right:1rem;display:flex;justify-content:flex-end;">
              <CustomSelect
                :options="modelNameList"
                :value="selectedVal2"
                @input="companySelect"
                style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;"
              />
            </div>
            <div style="height:15rem;padding-top:1rem;margin-top:1rem">
              <v-chart
                :options="workerMoneysBarChart"
                @click="workerMoneysBarChartClick"
                autoresize
              />
            </div>
          </div>
          <div style="margin:0.5rem 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName3}}</div>
            <div class="attendance-top">
              <div
                class="tabbar"
                style="margin:0 0 1rem 0;width:20rem"
              >
                <div
                  :class="{ 'tabar-select': popupSelect3 == '1' }"
                  @click="getBottomTable('1')"
                >按整改率排序</div>
                <div
                  :class="{ 'tabar-select': popupSelect3 == '2' }"
                  @click="getBottomTable('2')"
                >按分数排序</div>
              </div>
            </div>
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:5rem;text-align:center">序号</div>
              <div style="width:20rem;text-align:center">项目名称</div>
              <div style="width:12rem;text-align:center">分数</div>
              <div style="width:12rem;text-align:center">整改率</div>
            </div>
            <div style="height:10rem;overflow:auto;">
              <div
                v-for="(item, index) in popupData3"
                :key="item.person_id"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;cursor: pointer;"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:5rem;text-align:center">{{index>8?index+1:'0'+(index+1)}}</div>
                <div style="width:20rem;text-align:center">{{item.project_name}}</div>
                <div style="width:12rem;text-align:center">
                  {{item.total_score}}
                </div>
                <div style="width:12rem;text-align:center">{{item.rate}}</div>
              </div>
              <div
                v-if="!(popupData3 && popupData3.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>

          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="70rem"
      top="18rem"
    >
      <wrapper
        style="height:26rem;backgroundColor:#0e1328;position:relative"
        class="popup-bg"
      >
        <div
          class="stats-close-btn"
          @click="popupShow = false"
        />
        <div class="attendance">
          <div class="attendance-top">

            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">管理人员数：{{(broadcastData || {}).value1 || ''}}
            </div>
            <div style="padding:1rem 2rem;display:flex;width:100%;justify-content: space-between;">
              <div style="height:20rem;width:30rem;border:1px solid #1a3281;">
                <div style="margin:1rem 1rem 0 1rem;font-size:18px;font-weight:bold">各{{districtScale == 2 ? '项目':((districtScale === 1 || orgType === 2) ? '城市' : '分公司')}}项目人员数
                <!-- <div style="margin:1rem 1rem 0 1rem;font-size:18px;font-weight:bold">{{(orgType === 1) ? '各分公司':'各城市'}}项目人员数 -->
                </div>
                <div
          v-if="!DashboardManageOrgCityCount.series || !DashboardManageOrgCityCount.series[0].data.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
                <v-chart
                  ref="laborCompareCollect"
                  :options="DashboardManageOrgCityCount"
                  @click="DashboardManageOrgCityCountClick"
                  autoresize
                />
              </div>
              <div style="height:20rem;width:30rem;border:1px solid #1a3281;">
                <div style="margin:1rem 1rem 0 1rem;font-size:18px;font-weight:bold">各部门项目人员分布</div>
                <div v-if="!DashboardManageDeptManagerDistributeCount.series || !DashboardManageDeptManagerDistributeCount.series[0].data.length"
                    style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;">
                  <div class="empty-chart-icon" />
                  <div style="margin-top:20px;color:#ccc">暂无数据</div>
                </div>
                <v-chart :options="DashboardManageDeptManagerDistributeCount" autoresize style="height: 17rem" />
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupToShow"
      width="100rem"
      top="18rem"
    >
      <wrapper
        style="height:26rem;backgroundColor:#0e1328;position:relative"
        class="popup-bg"
      >
        <div
          class="stats-close-btn"
          @click="popupToShow = false"
        />
        <div class="attendance">
          <div class="attendance-bottom" style="backgroundColor:#031a46;padding-top:1rem">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupBottomName}}各部门管理人员数
            </div>
            <div style="margin:1rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div
                  style="width:6rem;text-align:center"
                  v-for="item in deptManagerCount"
                  :key="item.department"
                >{{item.department_name}}</div>
              </div>
              <div style="height:2rem;overflow:auto;">
                <div
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;background:#213a77"
                >
                  <div
                    style="width:5rem;text-align:center"
                    v-for="(item, index) in deptManagerCount"
                    :key="index"
                  >
                    {{item.count}}
                  </div>
                </div>
                <div
                  v-if="!(deptManagerCount && deptManagerCount.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无巡检记录
                </div>
              </div>
            </div>
          </div>
          <div class="attendance-bottom" style="backgroundColor:#031a46;margin-top:1rem;padding-top:1rem">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupBottomName}}各项目管理人员数
            </div>
            <div style="margin:1rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:32rem;padding-left: 3rem">项目名称</div>
                <div style="width:12rem;text-align:center">管理人员总数</div>
              </div>
              <div style="height:8rem;overflow:auto;">
                <div
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                  v-for="(item, index) in projectManagerCount"
                  :key="index"
                >
                  <div style="width:32rem;padding-left: 3rem">
                    {{item.project_name}}
                  </div>
                  <div style="width:12rem;text-align:center">
                    {{item.count}}
                  </div>
                </div>
                <div
                  v-if="!(projectManagerCount && projectManagerCount.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无巡检记录
                </div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { project, nation } from "../../api";
import { applyUpdate } from "../../utils";
import { SimpleBarChartTemplate } from "./barChartTemplates";
import { singleLineChartTemplate } from "./lineChartTemplates";
import { areaPieChartTemplate } from "./pieChartTemplates.js";
import CustomSelect from "../../components/select";
import wrapper from "../../components/wrap";
// import Swiper from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/dist/css/swiper.css";
export default {
  name: "ManagerRightBar",
  components: {
    "v-chart": ECharts,
    CustomSelect: CustomSelect,
    wrapper,
    Swiper,
    SwiperSlide,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    orgType: Number,
    broadcastData: Object,
    event: String,
    eventCounter: Number,
  },
  data() {
    return {
      mapLevel: 0,

      title: "",
      quarterCheckTrouble: {},
      modelNameList: ["全部模块"],
      modelIdList: [""],
      selectModelId: "",
      selectedVal2: "全部模块",
      option1: {
        color: ["#5dd5c3"],
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0}: {c0}",
        },
        grid: {
          top: "12%",
          left: "12%",
          right: "9%",
          bottom: "30%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            axisTick: {
              //y轴刻度线
              show: false,
            },
            axisLabel: {
              color: "#e6f2f3",
              rotate: 45,
              fontWeight: "normal",
              formatter: function (value) {
                return value.length > 8 ? value.slice(0, 8) + "..." : value;
              },
            },
          },
        ],
        yAxis: [
          {
            show: true,
            max: 100,
            name: "整改率：%",
            type: "value",
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
              align: "left",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                width: 1,
                type: "solid",
              },
            },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: "整改率",
            type: "bar",
            barWidth: 10,
            barMaxWidth: 18,
            data: [],
          },
        ],
      },
      quarterCheckTroubleSearchKey: [],
      activeItem: -1,
      courseList: [],
      active: "",
      activeCheckId: "",
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 20,
        slidesPerGroup: 4,
        loop: false,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      popupShow4: false,
      popupSelect3: "1",
      workerMoneysBarChart: {},
      workerMoneysBarChartTemplate: {
        color: ["#23CF9E", "#14B0BB"],
        grid: {
          contentLabel: true,
          right: "5%",
          top: "13%",
          left: "5%",
          bottom: "35%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        tooltip: {
          trigger: "axis",
          // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}",
            formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + (params[0].data.value?params[0].data.value:params[0].data);
                if(params[1]){
                  result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                }
              }
              return result
            }
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              rotate: 45,
              fontWeight: "normal",
              formatter: function (value) {
                return value.length > 8 ? value.slice(0, 8) + "..." : value;
              },
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            name: "整改率：%",
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
            show: true,
          },
          {
            show: true,
            name: "平均分：分",
            min: 0,
            max: 100,
            interval: 25,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              // formatter: function(value) {
              //   return value ? value + '%' : value;
              // },
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        series: [
          {
            name: "工资总额",
            type: "bar",
            barWidth: 10,
            data: [],
          },
          {
            name: "代付率",
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                },
              },
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(27, 208, 128, 0.3)" },
                { offset: 1, color: "rgba(27, 208, 128, 0)" },
              ]),
            },
            symbolSize: 0,
            yAxisIndex: 1,
            data: [],
          },
        ],
      },
      // org_id: '',
      popupBottomName3: "",
      popupSearchkeys3: [],
      popupXAxisData3: [],
      popupBarData: [],
      popupData3: [],
      dataIndex: 0,

      popupShow: false,
      popupToShow: false,
      popupBottomName: "",
      clickItemInfo: [],
      deptManagerCount: [],
      projectManagerCount: [],
      DashboardManageOrgCityCount: {},
      DashboardManageDeptManagerDistributeCount: {},
      ManageOrgCityCountTemplateOption: {
        color: ["#5dd5c3"],
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0}: {c0}",
        },
        legend: {
          data: []
        },
        grid: {
          top: "15%",
          left: "5%",
          right: "9%",
          bottom: "15%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            axisTick: {
              //y轴刻度线
              show: false,
            },
            axisLabel: {
              color: "#e6f2f3",
              rotate: 45,
              fontWeight: "normal",
              formatter: function (value) {
                return value.length > 8 ? value.slice(0, 8) + "..." : value;
              },
            },
          },
        ],
        yAxis: [
          {
            show: true,
            name: "人数",
            type: "value",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
              align: "left",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: {
              show: false,
            },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: "人数",
            type: "bar",
            barWidth: 10,
            barMaxWidth: 18,
            data: [],
          },
        ],
      },
      ManageDeptManagerDistributeTemplateOption: {
        legend: {
          type: 'scroll',
          orient: "vertical",
          height:'60%',
          width:'40%',
          x: "left",
          left: "50%",
          top: "middle",
          itemHeight: 12,
          itemGap: 6,
          textStyle: {
            fontSize: 12,
            color: "#ffffff",
          },
          pageIconColor: '#fff',
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            // radius: ["40%", "70%"],
            radius: [20, 100],
            roseType: "radius",
            center: ["20%", "50%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function (params) {
                  return `{value|${Math.round(params.percent)}%}\n{text|${
                    params.name
                  }}`;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  watch: {
    eventCounter: function(newValue, oldValue) {
      if (newValue > oldValue) {
        if (this.event == 'managerClick') {
          this.popupShow = true;
          this.getDashboardManageOrgCityCount();
        }
      }
    },
    orgLevel: function (newValue) {
      if (newValue) {
        this.getData();
      }
    },
    districtLevel: function (newValue) {
      if (newValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
        this.getData();
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.mapLevel = newValue;
      }
    },
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;

    this.getData();
    this.getPopData();
  },
  methods: {
    getDashboardManageOrgCityCount() {
      project.getDashboardManageOrgCityCount().then((d) => {
        // console.log(d)
        const chart = JSON.parse(JSON.stringify(singleLineChartTemplate));
        this.ManageOrgCityCountTemplateOption.xAxis[0].data = d.xAxis_data;
        this.ManageOrgCityCountTemplateOption.series[0].data = d.series_data;
        this.DashboardManageOrgCityCount = applyUpdate(
          chart,
          this.ManageOrgCityCountTemplateOption
        );
        this.clickItemInfo = d.parameter_list;
      });
      project.getDashboardManageDeptManagerDistributeCount().then((d) => {
        // console.log(d)
        const chart = JSON.parse(JSON.stringify(areaPieChartTemplate));
        // this.ManageDeptManagerDistributeTemplateOption.xAxis[0].data = d.xAxis_data;
        this.ManageDeptManagerDistributeTemplateOption.series[0].data =
          d.series_data;
        this.DashboardManageDeptManagerDistributeCount = applyUpdate(
          chart,
          this.ManageDeptManagerDistributeTemplateOption
        );
      });
    },
    DashboardManageOrgCityCountClick(params) {
      this.popupToShow = true;
      // this.clickItemInfo[params.dataIndex]

      this.popupBottomName = `${
        this.ManageOrgCityCountTemplateOption.xAxis[0].data[params.dataIndex]
      }`;
      //
      //

      project
        .getDashboardManageDeptManagerCount(
          this.clickItemInfo[params.dataIndex].area_code,
          this.clickItemInfo[params.dataIndex].org_id,
          this.clickItemInfo[params.dataIndex].project_id
        )
        .then((d) => {
          this.deptManagerCount = d;
        });
      project
        .getDashboardManageProjectManagerCount(
          this.clickItemInfo[params.dataIndex].area_code,
          this.clickItemInfo[params.dataIndex].org_id,
          this.clickItemInfo[params.dataIndex].project_id,
          1,
          10000
        )
        .then((d) => {
          // console.log(d)
          this.projectManagerCount = d;
        });
    },
    getPopData() {
      // getManagerQuarterCheckListCollect
      nation.getManagerQuarterCheckListCollect(1, 100).then((d) => {
        this.courseList = d;
        this.activeCheckId =
          this.courseList[this.courseList.length - 1].check_id;
        this.active = this.courseList[this.courseList.length - 1].check_name;
        this.getModeList(this.courseList[this.courseList.length - 1].check_id);
      });
    },
    getModeList(id) {
      // getManagerQuarterSourceList
      nation.getManagerQuarterSourceList(id).then((d) => {
        this.modelNameList = ["全部模块"].concat(
          d.map((item) => {
            return item.source_name;
          })
        );
        this.modelIdList = [""].concat(
          d.map((item) => {
            return item.source_id;
          })
        );
        this.getAttendance3();
      });
    },
    companySelect(option) {
      const idx = this.modelNameList.indexOf(option);
      this.selectModelId = this.modelIdList[idx];
      this.selectedVal2=option;
      this.getAttendance3();
    },
    activeName(item) {
      this.active = item.check_name;
      this.activeCheckId = item.check_id;
      this.activeItem = -1;
      this.getModeList(item.check_id);
    },
    prev() {
      this.$refs.mySwiper.$swiper.slidePrev();
    },
    next() {
      this.$refs.mySwiper.$swiper.slideNext();
    },
    dialogClose() {
      this.popupShow4 = false;
      this.workerMoneysBarChart = {};
      this.popupData3 = [];
      this.activeItem = -1;
      this.activeCheckId = this.courseList[this.courseList.length - 1].check_id;
      this.active = this.courseList[this.courseList.length - 1].check_name;

      this.selectedVal2='全部模块';
      this.selectModelId = '';
      this.popupBottomName3='';
    },
    showDetail() {
      this.popupShow4 = true;
      this.getAttendance3();
    },
    quarterCheckTroubleClick(params) {
      if (this.mapLevel !== 2) {
        this.popupShow4 = true;
        this.activeItem = params.dataIndex;
        this.getAttendance3();
      }
    },
    getAttendance3() {
      nation
        .getManagerQuarterOrgCityCollect(this.activeCheckId, this.selectModelId)
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          // quarterCheckTroubleSearchKey
          if (this.activeItem > -1) {
            var nowData = d.search_keys.indexOf(
              this.quarterCheckTroubleSearchKey[this.activeItem]
            );
            if (nowData == -1) {
              this.dataIndex = 0;
            } else {
              this.dataIndex = nowData;
            }
          }
          this.workerMoneysBarChartTemplate.legend.data = d.legend_data;
          this.workerMoneysBarChartTemplate.xAxis[0].data = d.xAxis_data;
          d.bar_series[this.dataIndex] = {
            value: d.bar_series[this.dataIndex],
            itemStyle: {
              color: "#FFD153",
            },
          };
          this.workerMoneysBarChartTemplate.series[0].data = d.bar_series;
          this.workerMoneysBarChartTemplate.series[0].name = d.legend_data[0];
          this.workerMoneysBarChartTemplate.series[1].data = d.line_series;
          this.workerMoneysBarChartTemplate.series[1].name = d.legend_data[1];
          this.workerMoneysBarChart = applyUpdate(
            chart,
            this.workerMoneysBarChartTemplate
          );


          this.popupBottomName3 = (this.workerMoneysBarChartTemplate.xAxis[0].data.length?this.workerMoneysBarChartTemplate.xAxis[0].data[this.dataIndex]:'') +'项目情况';
          this.getLaborAttendanceDateOrgCountCollect(
            d.search_keys[this.dataIndex]
          );
          this.popupSearchkeys3 = d.search_keys;
          this.popupXAxisData3 = d.xAxis_data;
          this.popupBarData = d.bar_series;
        });
    },
    workerMoneysBarChartClick(params) {
      let subData = this.popupBarData;
      this.workerMoneysBarChart.series[0].data = subData.map(function (
        item,
        index
      ) {
        return index === params.dataIndex
          ? {
              value:
                Object.prototype.toString.call(item) === "[object Object]"
                  ? item.value
                  : item,
              itemStyle: {
                color: "#FFD153",
              },
            }
          : Object.prototype.toString.call(item) === "[object Object]"
          ? item.value
          : item;
      });
      this.popupBottomName3 = `${
        this.popupXAxisData3[params.dataIndex]
      }项目情况`;
      this.getLaborAttendanceDateOrgCountCollect(
        this.popupSearchkeys3[params.dataIndex]
      );
      this.dataIndex = params.dataIndex;
    },
    getLaborAttendanceDateOrgCountCollect(search_keys) {
      // getManagerQuarterProjectCollect
      nation
        .getManagerQuarterProjectCollect(
          this.activeCheckId,
          this.popupSelect3,
          1,
          100,
          this.mapLevel == 0&&this.orgType == 1 ? search_keys : "",
          this.mapLevel == 1||this.orgType !== 1 ? search_keys : ""
        )
        .then((d) => {
          this.popupData3 = d;
        });
    },
    getBottomTable(popupSelect) {
      this.popupSelect3 = popupSelect;
      this.getLaborAttendanceDateOrgCountCollect(
        this.popupSearchkeys3[this.dataIndex]
      );
    },
    getData() {
      this.getEcharts1();
    },
    getEcharts1() {
      project
        .getDashboardManagerQuarterCheckTroubleCollect(this.mode)
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.title = d.check_name;
          this.option1.xAxis[0].data = d.xAxis_data;
          // this.option1.series = [chart.series[0]];
          this.option1.series[0].data = d.series_data;
          this.quarterCheckTrouble = applyUpdate(chart, this.option1);
          this.quarterCheckTroubleSearchKey = d.search_keys;
        })
        .catch((e) => console.error(e.msg || e.message));
    },
  },
};
</script>
<style scoped>
.swiper {
  width: 62rem;
}
.swiper-content {
  font-weight: bold;
}
.swiper-slide {
  cursor:pointer;
  width: 15rem;
  height: 3rem;
  line-height: 3rem;
  border: 1px solid #112563;
  text-align: center;
  margin-right: 2rem;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 1rem;
  font-weight: bold;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  border: 1px solid;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  border: 1px solid;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
}
.swiper-button-prev,
.swiper-button-next {
  top: 60%;
}
</style>