<template>
  <div id="left">
    <wrapper style="padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>累计净流/收益</div>
          <div class="detail" @click="showDetail(1)">
            详情
          </div>
        </div>
        <div
          style="height:17rem;padding-top:3rem;"
          class="machine_use"
        >
          <table style="width:100%;height:15rem"  rules=rows>
          <tr>
            <td style="text-align:center">净流</td>
            <td><span class="linetitle2">截至年初累计</span><p><span class="value1">{{zeroFomatter(subData.byAccNetCapitalFlow)}}</span>万元</p></td>
            <td><span class="linetitle2">本年</span><p><span class="value2">{{zeroFomatter(subData.curNetFlowAmount)}}</span>万元</p></td>
            <td><span class="linetitle2">未来</span><p><span class="value3">{{zeroFomatter(subData.futNetFlow)}}</span>万元</p></td>
          </tr>
          <tr>
            <td style="text-align:center">收益</td>
            <td><span class="linetitle2">截至年初累计</span><p><span class="value1">{{zeroFomatter(subData.byAccProjectIncome)}}</span>万元</p></td>
            <td><span class="linetitle2">本年</span><p><span class="value2">{{zeroFomatter(subData.curIncome)}}</span>万元</p></td>
            <td><span class="linetitle2">未来</span><p><span class="value3">{{zeroFomatter(subData.futIncome)}}</span>万元</p></td>
          </tr>
          <tr>
            <td style="text-align:center">现金上交收益</td>
            <td><span class="linetitle2">截至年初累计</span><p><span class="value1">{{zeroFomatter(subData.byAccPaidIncomeByCash)}}</span>万元</p></td>
            <td><span class="linetitle2">本年</span><p><span class="value2">{{zeroFomatter(subData.paidCashIncome)}}</span>万元</p></td>
            <td><span class="linetitle2">未来</span><p><span class="value3">{{zeroFomatter(subData.furIncomePaidByCash)}}</span>万元</p></td>
          </tr>
        </table>
      </div>
      </div>
    </wrapper>
    <wrapper style="padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>项目截止年初可用资金余额</div>
          <div class="detail" @click="showDetail(2)">
            详情
          </div>
        </div>
        <div
          style="height:17rem;padding-top:3rem;"
          class="machine_use"
        >
          <div v-if="!ProjectFundBalanceChart.series || !ProjectFundBalanceChart.series[0].data.length" style="padding-top:1rem">
          <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 35rem; height: 12rem;margin-top: 0.5rem;">
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
        </div>
        <v-chart v-else :options="ProjectFundBalanceChart" @click="projectFundBalanceChartClick" autoresize />
      </div>
      </div>
    </wrapper>

    <wrapper style="margin-top: 1rem;padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>项目应收工程款</div>
        </div>
        <div
          style="height:17rem;padding-top:4rem"
          class="machine_use"
        >
          <div v-if="!receivableChart.series || !receivableChart.series[0].data.length" style="padding-top:1rem">
          <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;">
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
        </div>
        <v-chart v-else :options="receivableChart" autoresize />
      </div>
      </div>
    </wrapper>
    <!-- 累计净流/收款 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold;width:30rem"> 
            </div>
            <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem;">
            </div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <el-table style="background-color:#0E1328" height="28rem" :data="tableData" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column
                        prop="projName"
                        label="项目名称">
                        </el-table-column>
                        <el-table-column
                        prop="byAccNetCapitalFlow"
                        label="截至年初累计资金净流"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                    截至年初累计资金净流
                                <el-tooltip :aa="scope" class="item" effect="dark" content="自项目开工以来累计收款-累计付款，付款不含上交预收益。
此指标为负，说明项目是垫资，入不敷出。
注：办理信用证当月即算支出，已扣除，到期偿还时不再重复扣" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byLimitedCapital"
                        label="年初受限资金"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                    年初受限资金
                                <el-tooltip :aa="scope" class="item" effect="dark" content="监管户、农民工工资户、抵入未抵出房产余额" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byAccCanDepositCapital"
                        label="截至年初累计剩余可上存资金"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                    截至年初累计剩余可上存资金
                                <el-tooltip :aa="scope" class="item" effect="dark" content="可上存资金就是截至年初累计资金净流减去年初受限资金。
此指标可理解为银行活期资金，使用灵活，这部分优先交公司收益，如交完收益后有剩余可以作为存款也可以请款使用。" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byAccProjectIncome"
                        label="截至年初项目累计收益"
                        :formatter="Formatter">
                          <template slot-scope="scope" slot="header">
                                <span>
                                    截至年初项目累计收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="自项目开工以来累计账面收入*实际收益率，实际收益率是项目预计的项目整体结束后可获得的收益率，总部商务管理部审核。" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byAccPaidIncomeByCash"
                        label="截至年初累计现金形式上交收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                    截至年初累计现金形式上交收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="自项目开工以来上缴给公司的钱，一般是可上存资金与累计收益的较小者，也就是有净流支撑的收益。
注：上缴后项目以后年度再不可以用这部分钱。" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="curNetFlowAmount"
                        label="本年净流"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                本年净流
                                <el-tooltip :aa="scope" class="item" effect="dark" content="本年收款-支出-管理费" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="curIncome"
                        label="本年收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                本年收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="本年账面收入*实际收益率" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="paidCashIncome"
                        label="本年已交现金收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                本年已交现金收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="根据分公司资金余额与收益情况，与分公司确认上交的本年收益" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="futNetFlow"
                        label="未来净流"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                未来净流
                                <el-tooltip :aa="scope" class="item" effect="dark" content="项目目前开始到竣工结算期间还会产生的净流。比如，一个项目工期5年，目前已经干了3年，未来净流就是剩下的2年产生的净流。项目最终结束后，项目总收益就等于项目总净流。分公司未来净流，就是分公司所有项目预计总净流减去截止目前累计资金净流。" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="futIncome"
                        label="未来收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                未来收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="分公司未来收益与未来净流期间一致，就是分公司所有项目预计总收益减去截止目前分公司累计收益。" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="furIncomePaidByCash"
                        label="未来现金形式上交收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                未来现金形式上交收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="项目总收益减去累计已上交收益" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                </el-table>
            </div>
        </div>
      </wrapper>
    </el-dialog>
    <!-- 截止年初可用资金余额 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow2=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                <el-table style="background-color:#0E1328" height="28rem" :data="tableData" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column
                        prop="projName"
                        label="项目">
                        </el-table-column>
                    <el-table-column label="截止年初净流情况">
                        <el-table-column
                        prop="byAccNetCapitalFlow"
                        label="截至年初累计资金净流"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                    截至年初累计资金净流
                                <el-tooltip :aa="scope" class="item" effect="dark" content="自项目开工以来累计收款-累计付款，付款不含上交预收益。
此指标为负，说明项目是垫资，入不敷出。
注：办理信用证当月即算支出，已扣除，到期偿还时不再重复扣" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byLimitedCapital"
                        label="年初受限资金"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                    年初受限资金
                                <el-tooltip :aa="scope" class="item" effect="dark" content="监管户、农民工工资户、抵入未抵出房产余额" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byAccCanDepositCapital"
                        label="截至年初累计剩余可上存资金"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                    截至年初累计剩余可上存资金
                                <el-tooltip :aa="scope" class="item" effect="dark" content="可上存资金就是截至年初累计资金净流减去年初受限资金。
此指标可理解为银行活期资金，使用灵活，这部分优先交公司收益，如交完收益后有剩余可以作为存款也可以请款使用。" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="截至年初收益情况">
                        <el-table-column
                        prop="byAccProjectIncome"
                        label="截至年初项目累计收益"
                        :formatter="Formatter">
                        </el-table-column>
                        <el-table-column
                        prop="byPayableIncome"
                        label="截至年初应交收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                    截至年初应交收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="截至年初累计收益" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byAccPaidIncomeByCash"
                        label="截至年初累计现金形式上交收益"
                        :formatter="Formatter">
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceUnpaid"
                        label="截至年初欠交公司收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                截至年初欠交公司收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="累计收益减去已交收益" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="截至年初资金余额（交收益后）">
                        <el-table-column
                        prop="byBalanceAmount"
                        label="合计"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                合计
                                <el-tooltip :aa="scope" class="item" effect="dark" content="在公司存/借款减去欠交收益加上受限资金" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceDeposit"
                        label="其中：公司存/借款"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：公司存/借款
                                <el-tooltip :aa="scope" class="item" effect="dark" content="截止年初可上存资金减去累计上交收益余额。正数表示在公司存款，负数表示欠公司钱" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceUnpaid"
                        label="其中：欠交公司收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：欠交公司收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="从有现金形式上缴收益考核（2020年）开始每年预算数之和-每年实际已上缴数之和" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceProj"
                        label="其中：项目自存资金"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：项目自存资金
                                <el-tooltip :aa="scope" class="item" effect="dark" content="监管户、农民工工资户、抵入未抵出房产余额" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from 'vue-echarts'
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import wrapper from "../../../../components/wrap";
import { finance } from '../../../../api_finance';
import { colors } from '../../../nation/pieChartTemplates';

export default {
  name: "financeLeftBar",
  components: {
    'v-chart': ECharts,
    wrapper,
  },
  props: {
    projectDetail: Object,
    showTrace: Function,
    projcode:String
  },
  data() {
    return {
      projectId: null,
      mgmStatsSelectedDeptIdx: null,
      mgmStatsBarChart: {},
      mgmDetailShow: false,
      mgmSubWindowUrl: null,
      mgmSubWindowShow: false,
      personalInfoPopupShow: false,
      personalInfoWorkerId: null,
      personalInfoTabIndex: 1,
      securityStarPopupShow: false,
      securityStarPage: 1,
      securityStarPages: 1,
      securityStarInfo: [],
      inOutPopupShow: false,
      workerInOutPage: 1,
      workerInOutPages: 1,
      workerInOutInfo: [],
      ProjectFundBalanceChart: {},
      businessChart: {},
      timer: null,
      receivableChart:{},
      popupShow:false,
      popupShow2:false,
      popProjectsList:[],
      personName:"",
      workerTypePieChartHighlightIndex: 0,
      subData:{},
      tableData:[]
    };
  },
  watch:{
       $route: function(newValue, oldValue) {
        if (newValue.params.id != oldValue.params.id) {
          this.getData();
        }
      },
    },
  mounted() {
    // const self = this\
    this.getData();
  },
  methods: {
    Formatter(row, column, cellValue){
      return this.zeroFomatter(cellValue);
    },
      tableRowStyle({rowIndex}){
        if(rowIndex%2 === 0)
            return {backgroundColor:'#213a77',height: '0'}; 
        else   
            return {backgroundColor:'#102855',height: '0'}; 
      },
      tableHeaderColor(){
        return 'color:#4fb5da;background:#102855';
      },
    getData() {
      this.projectId = this.$route.params.id;
      // getSafetyMachineTypeCount
      finance.queryProjCapitalStatistic(this.projectId).then((res=>{
        this.subData = res.data[0];
        this.tableData = res.data;
        this.getEcharts2();
        this.getEcharts3();
      }))
    },
    getEcharts2() {
      const data = [
        {
          name:"欠交公司收益",
          value:this.zeroFomatter(this.subData.byBalanceUnpaid)
        },
         {
          name:"在公司存款",
          value:this.zeroFomatter(this.subData.byBalanceDeposit)
        },
         {
          name:"项目自存资金",
          value:this.zeroFomatter(this.subData.byBalanceProj)
        },
         {
          name:"合计",
          value:this.zeroFomatter(this.subData.byBalanceAmount)
        },
      ];
      const arrName = this.getArrayValue(data, "name");
      const objData = this.array2obj(data, "name");
      const optionData = this.getSeriesData(data);
      const option = {
        legend: {
            show: true,
            icon:"circle",
            top: "center",
            left: '70%',
            data: arrName,
            width:50,
            formatter: function(name) {
                return "{title|" + name + "}\n{value|" + (objData[name].value) +"}  {title|元}"
            },
            textStyle: {
                rich: {
                    title: {
                        fontSize: 14,
                        lineHeight: 15,
                        color: "rgb(0, 178, 246)"
                    },
                    value: {
                        fontSize: 18,
                        lineHeight: 20,
                        color: "#fff"
                    }
                }
            },
        },
        tooltip: {
            show: true,
            trigger: "item",
            formatter: "{a}<br>{b}:{c}"
        },
        color:colors,
        grid: {
            top: '16%',
            bottom: '53%',
            left: "30%",
            containLabel: false
        },
        yAxis: [{
            type: 'category',
            inverse: true,
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                interval: 0,
                inside: true,
                textStyle: {
                    color: "#fff",
                    fontSize: 14,
                },
                show: true
            },
            data: optionData.yAxis
        }],
        xAxis: [{
            show: false
        }],
        series: optionData.series
      }
      this.ProjectFundBalanceChart = option;
    },
    getEcharts3(){
      const echartData = [
        {name:"逾期应收",value:this.zeroFomatter(this.subData.projReceivableRight)},
        {name:"无收款权",value:this.zeroFomatter(this.subData.projReceivableNoRight)}
      ];
      var totalAmount = this.zeroFomatter(this.subData.projReceivableAmount);
      const option = {
        tooltip: {
            trigger: 'item',
            extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
            backgroundColor: 'rgba(255,255,255,0.8)',
            textStyle: {
                color: '#333',
            },
            formatter: function (params) {
                var html = [];
                html.push('<div class = "echart-tip-box">');
                html.push('<div>' + params.name + '</div>');
                html.push('<div> 金额：' + params.value + '万元</div>');
                html.push('</div>');
                return html.join('');
            },
        },
        // 图例用作中心总数值
        legend: {
            selectedMode: false,
            formatter: function () {
                return '应收总计\n'+totalAmount + '万元';
            },
            data: [echartData[0].name],
            left: 'center',
            top: 'center',
            icon: 'none',
            align: 'center',
            textStyle: {
                color: '#FFFFFF',
                fontSize: 16 * 1,
                // rich: rich,
            },
        },
        series: [
            {
                type: 'pie',
                radius: ['45', '75'],
                center: ['50%', '50%'],
                // hover弹啊弹效果
                hoverAnimation: false,
                color: ['#f4320b', '#f7c32b', '#62daaa', '#a66ece', '#5b8ff9', '#68dafb'],
                itemStyle: {
                    normal: {
                        borderWidth: 1,
                        borderColor: '#fff',
                    },
                },
                data: echartData,
            },
        ],
      }
      this.receivableChart = option;
    },
    showDetail(choose){
      if(choose == 1){
        this.popupShow = true;
      }else if(choose ==2){
        this.popupShow2 = true;
      }
    },
    zeroFomatter(val){
      return val==null? 0:(parseInt(val*100)/100).toFixed(2);
    },
    getArrayValue(array, key) {
        var _key = key || "value";
        var res = [];
        if (array) {
            array.forEach(function(t) {
                res.push(t[_key]);
            });
        }
        return res;
    }, 
    array2obj(array,key) {
      var resObj = {};
      for(var i=0;i<array.length;i++){
        resObj[array[i][key]] = array[i];
      }
      return resObj;
    },
    getSeriesData(data) {
      const arrValue = this.getArrayValue(data, "value");
      const sumValue = eval(arrValue.join('+'));
        var res = {
            series: [],
            yAxis: []
        };
        for (let i = 0; i < data.length; i++) {
            // console.log([70 - i * 15 + '%', 67 - i * 15 + '%']);
            res.series.push({
                name: '',
                type: 'pie',
                clockWise: false, //顺时加载
                hoverAnimation: false, //鼠标移入变大
                radius: [73 - i * 15 + '%', 68 - i * 15 + '%'],
                center: ["30%", "55%"],
                label: {
                    show: false
                },
                itemStyle: {
                    label: {
                        show: false,
                    },
                    labelLine: {
                        show: false
                    },
                    borderWidth: 5,
                },
                data: [{
                    value: data[i].value,
                    name: data[i].name
                }, {
                    value: sumValue - data[i].value,
                    name: '',
                    itemStyle: {
                        color: "rgba(0,0,0,0)",
                        borderWidth: 0
                    },
                    tooltip: {
                        show: false
                    },
                    hoverAnimation: false
                }]
            });
            res.series.push({
                name: '',
                type: 'pie',
                silent: true,
                z: 1,
                clockWise: false, //顺时加载
                hoverAnimation: false, //鼠标移入变大
                radius: [73 - i * 15 + '%', 68 - i * 15 + '%'],
                center: ["30%", "55%"],
                label: {
                    show: false
                },
                itemStyle: {
                    label: {
                        show: false,
                    },
                    labelLine: {
                        show: false
                    },
                    borderWidth: 5,
                },
                data: [{
                    value: 7.5,
                    itemStyle: {
                        color: "rgb(3, 31, 62)",
                        borderWidth: 0
                    },
                    tooltip: {
                        show: false
                    },
                    hoverAnimation: false
                }, {
                    value: 2.5,
                    name: '',
                    itemStyle: {
                        color: "rgba(0,0,0,0)",
                        borderWidth: 0
                    },
                    tooltip: {
                        show: false
                    },
                    hoverAnimation: false
                }]
            });
            res.yAxis.push(data[i].value + "万元");
        }
        return res;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.machine_use {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.machine_use_item {
  width: 40%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.8rem;
}
.machine_use_item_1 {
  border: 1px solid #29e8a8;
  background-color: rgba(41, 232, 168, 0.2);
}
.machine_num_1 {
  font-size: 1.5rem;
  color: #29e8a8;
}
.info-block {
  position: relative;
  width: 100%;
  background: url("../../../../assets/nation/title_finance.png") no-repeat;
}
.machine_use_item_2 {
  border: 1px solid #fee21d;
  background-color: rgba(254, 226, 29, 0.2);
}
.machine_num_2 {
  font-size: 1.5rem;
  color: #fee21d;
}
.machine_use_item_3 {
  border: 1px solid #4191e5;
  background-color: rgba(65, 145, 229, 0.2);
}
.machine_num_3 {
  font-size: 1.5rem;
  color: #4191e5;
}
.machine_use_item_4 {
  border: 1px solid #f38e2c;
  background-color: rgba(243, 142, 44, 0.2);
}
.machine_num_4 {
  font-size: 1.5rem;
  color: #f38e2c;
}
.stats-close-btn {
  position: absolute;
  background-image: url("../../../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}

.value1{
  font-size:1.5rem;
  font-weight:400 ;
  color:#F4EA27
}
.value2{
  font-size:1.5rem;
  font-weight:400 ;
  color:#779DFA
}
.value3{
  font-size:1.5rem;
  font-weight:400 ;
  color:#CB4F43
}
</style>