<template>
  <div style="width:100%;height:10rem;display:flex;justify-content:center">
    <div style="display:flex;align-items:center">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[0]}.png`)" />
      <div>
        <div class="stats-label" style="font-size: 0.9rem">{{broadcastTitles[0]}}</div>
        <div style="display:flex" @click="handleClick(1)">
          <v-number
            v-model="(broadcastData || {}).value1"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[0], cursor: 'pointer'}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:0.3rem">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[1]}.png`)" />
      <div>
        <div class="stats-label" style="font-size: 0.9rem">{{broadcastTitles[1]}}</div>
        <div style="display:flex" @click="handleClick(2)">
          <v-number
            v-model="(broadcastData || {}).value2"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[1]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:0.3rem">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[2]}.png`)" />
      <div>
        <div class="stats-label" style="font-size: 0.9rem">{{broadcastTitles[2]}}</div>
        <div style="display:flex" @click="handleClick(3)">
          <v-number
            v-model="(broadcastData || {}).value3"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[2], cursor: 'pointer'}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:0.3rem">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[3]}.png`)" />
      <div>
        <div class="stats-label" style="font-size: 0.9rem">{{broadcastTitles[3]}}</div>
        <div style="display:flex" @click="handleClick(4)">
          <v-number
            v-model="(broadcastData || {}).value4"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[3]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:0.3rem">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[4]}.png`)" />
      <div>
        <div class="stats-label" style="font-size: 0.9rem">{{broadcastTitles[4]}}</div>
        <div style="display:flex" @click="handleClick(5)">
          <v-number
            v-model="(broadcastData || {}).value5"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[4]}"
          ></v-number>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VNumber from "../../components/v-number";
  import { project } from "../../api";

  export default {
    name: 'LaborBroadcasts',
    components: {
      VNumber,
    },
    data() {
      return {
        broadcastTitles: ['在场工人数', '出勤工人数', '现场工人数', '现场管理人员总数', '今日出勤率'],
        broadcastIcons: ['在场工人', '出勤工人', '当前在场工人', '现场管理人员总数', '今日出勤率'],
        broadcastColors: ['#2ffaff', '#ccac44', '#35c59e', '#ffbf7b', '#40b6fe'],
        broadcastSep: '0.3rem',
      }
    },
    props: {
      broadcastData: Object,
      dispatchEvent: Function,
    },
    methods: {
      handleClick(idx) {
        if (idx == 1) {
          this.dispatchEvent('onlineWorkerClick');
        } else if (idx == 3) {
          this.dispatchEvent('onsiteWorkerClick');
        }
      }
    }
  }

  export function updateData(container, scheduleNextUpdate) {
    project
      .getLaborBroadcast()
      .then((d) => {
        container.broadcastData.labor = d[0];
        container.broadcastData.labor.value1 = d[0].worker_online_count;
        container.broadcastData.labor.value2 = d[0].attendance_worker_count;
        container.broadcastData.labor.value3 = d[0].worker_now_online_count;
        container.broadcastData.labor.value4 = d[0].manager_now_online_count;
        container.broadcastData.labor.value5 = Math.round(d[0].attendance_rate);

        container.statsData = d[0];
        scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1rem;
  font-weight: bold;
  color: #cececf;
}
</style>
