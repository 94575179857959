import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    '医疗综合楼': [-27.166592719687124,46.0919890859312,200],
    '动物实验楼': [-476.62744412879016,-553.9407548601896,200],
    '科研楼': [-270.37710067315123,-576.2516085346837,200],
    '会议中心': [-33.38231311399004,-550.6054873056082,200],
    '教学宿舍楼': [173.36482136944306,-598.451182700309,200],
  };

  const buildingBoundaries = {
    '医疗综合楼': [[236.79998982329442,-206.1414721897],[77.68343535354026,-253.78215949820125],[-63.714933731164095,-266.7647869751506],[-247.64731854054935,-231.71500999113854],[-391.9576735596138,-180.34128987208834],[-407.4830437007914,-52.4141045166452],[-271.3911573157145,-38.80374771317247],[-272.1416761076056,52.433126039912295],[-329.6975449318684,53.627214122169136],[-366.9793935068068,99.28814998781351],[-455.2069605863551,111.36787567106133],[-451.65756563941267,200.83215433588862],[-168.1055864043435,213.9871403604406],[-154.48044233402635,348.76341724645346],[17.854380633262593,363.87020096100446],[238.2574495605691,352.35085051770847]],
    '动物实验楼': [[-527.974276944868,-494.7091861335565],[-532.6722543514709,-613.6836731929667],[-428.7194105883905,-615.2850551502739],[-432.37221754525535,-498.4153622240915]],
    '科研楼': [[-388.50713837390515,-490.44294717349266],[-392.0523722640935,-640.1716119664197],[-150.42293735563803,-645.7136482146258],[-151.78205661768533,-481.88606892149556],[-188.82840139481237,-478.6233915807349]],
    '会议中心': [[-93.47087477701376,-497.1350162477853],[-95.0042627452907,-622.1518858362681],[16.52212097911047,-624.10413273215],[16.181412208705183,-491.13251198736646]],
    '教学宿舍楼': [[274.82120733485027,-632.8740936344694],[275.66217924777914,-560.2301096297222],[234.98554758790834,-484.8079471676317],[78.84540759961692,-484.44121235088136],[79.67920181632584,-631.5966917317063]],
  };

  const buildingFinishedHeights = {
  };

  const doneFloors = {
  };

  const traceViews = {
    '_': {
      center: [-96.22792401209888, 80.60444163072056],
      pitch: 2.8,
      zoom: 1.89,
      bearing: 3.2,
    },
    '医疗综合楼': {
      center: [-3749.414230612843, 7436.637938762226],
      pitch: 75,
      zoom: 2.2,
      bearing: -32.8,
    },
    '动物实验楼': {
      center: [-2857.4584564100114, -2243.886803032111],
      pitch: 74.2,
      zoom: 3.27,
      bearing: -9.4,
    },
    '科研楼': {
      center: [-2703.857009216072, -1265.0049830901087],
      pitch: 75,
      zoom: 3.27,
      bearing: -18.4,
    },
    '会议中心': {
      center: [-674.3667241677257, -1017.6741812077802],
      pitch: 75,
      zoom: 3.27,
      bearing: -18.4,
    },
    '教学宿舍楼': {
      center: [707.4491943083308, -975.9471711963088],
      pitch: 75,
      zoom: 3.27,
      bearing: -18.4,
    },
  };

  function getUserZValue(user) {
    return (user.floor == 'ground' ? 0 : user.height) * 2.34 + 4;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 2.34;
  }

  function getZValueFromHeight(height) {
    return height * 2.34;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/guangming-hospital2.glb',
    modelType: 'gltf',
    modelSubType: 'draco',

    //fullOpacity: 1,
    //revealOpacity: 0.2,
    //deepRevealOpacity: 0,

    /*
    groundHeightRanges: [
      [-0.0001, 0],
      [153.9731, 153.9732],
      [-1425.0001, -1424.9999],
    ],
    */

    modelOptions: {
      scale: 6,
      rotZ: 0,
      moveX: -150,
      moveY: -400,
      moveZ: 0,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: 0,
      z: 0
    },

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
