<template>
<div id="left">
    <div class="info-block" style="min-height:5rem">
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>远航人才情况</div>
        </div>
          <div
        style="display:flex;justify-content:flex-end"
      >
          <CustomSelect
            :options="yearList"
            :value="selectedYear"
            @input="categorySelect"
            style="width:10rem;font-size:0.8rem;font-weight:bold"
          />
        </div>
      
      <div style="height:19rem;padding-top:1rem">
        <div
          style="display:flex;justify-content:space-around;margin-bottom:-0.8rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')">
            人数统计
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')">
            学历统计
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '3' }"
            @click="() => switchCompanyWorkersOption('3')">
            来源统计
          </div>
        </div>
        <div
          v-if="companyWorkersPieChart.series && [].concat.apply([], companyWorkersPieChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyWorkersPieChart" @click="companyWorkersPieChartClick" autoresize/>
      </div>
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { human } from '../../../../api_hr'
  import { colors } from '../../pieChartTemplates';
 import CustomSelect from '../../../../components/select'
  // import { truncateStr } from "../../../../utils";
// import func from 'vue-editor-bridge'

  export default {
    name: 'sailingTalentBarChart',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      }
    },
    data () {
      return {
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear(),
        companyWorkersData: {},
        companyWorkersOption: "1",
        companyWorkersPieChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.getAll();
    },
    methods: {
      getAll(){
          this.switchCompanyWorkersOption(this.companyWorkersOption);
      },
      switchCompanyWorkersOption(choose){
          this.companyWorkersOption = choose;
          switch (this.companyWorkersOption) {
              case "1":
                  this.updateTalentNumsChart();
                  break;
              case "2":
                  this.updateEducaitonChart();
                  break;
              case "3":
                  this.updateMgrSourceChart();
                  break;
              default:
                  break;
          }
      },
      categorySelect(option) {
        this.selectedYear = option;
        this.switchCompanyWorkersOption(this.companyWorkersOption);
      },
      updateTalentNumsChart(){
        human.getSailingTalentNums(this.selectedYear).then((res)=>{
          var xmjl = 0;
          var scjl = 0;
          var swjl = 0;
          var xmzg = 0;
          var aqzj = 0;
          var zlzj = 0;
          for (let i = 0; i < res.length; i++) {
            if(res[i].name == '项目经理后备' || res[i].name.indexOf("执行经理") != -1){
              xmjl += res[i].value;
            }else if(res[i].name.indexOf("生产经理") != -1){
              scjl += res[i].value;
            }else if(res[i].name.indexOf("商务经理")!=-1 || res[i].name.indexOf("机电经理") != -1){
              swjl += res[i].value;
            }else if(res[i].name.indexOf("项目总工") != -1){
               xmzg += res[i].value;
            }else if(res[i].name.indexOf("安全总监") != -1){
               aqzj += res[i].value;
            }else if(res[i].name.indexOf("质量总监") != -1){
               zlzj += res[i].value;
            }
          }
          var dataList = [
            {name:"项目/执行经理后备",value:xmjl},
            {name:"生产经理后备",value:scjl},
            {name:"商务经理后备",value:swjl},
            {name:"项目总工后备",value:xmzg},
            {name:"安全总监后备",value:aqzj},
            {name:"质量总监后备",value:zlzj},
          ]
            const option = {
                color:colors,
                tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                series: [
                   {
                        name:"人数统计",
                        type: 'pie',
                        radius: '50%',
                        data: dataList,
                        label: {
                            formatter: '{b}:{d}% ',
                            borderWidth: 1,
                            borderRadius: 2,
                            fontSize:14,
                            color:"white"
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0
                            }
                        }
                    }
                ]
            };
          this.companyWorkersPieChart = option;
        })
      },
      // 学历统计
      updateEducaitonChart() {
        human.getSailingEducation(this.selectedYear).then(res=>{
          const option = {
            color:colors,
            backgroundColor: '',
              tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b}: {c} ({d}%)'
              },
              legend: {
                data: [],
                position:'inner',
                textStyle:{
                    fontSize:12,
                    color:"white"
                }
              },
              series: [
                  {
                      name: '学历统计',
                      type: 'pie',
                      selectedMode: 'single',
                      radius: ["25%", "45%"],
                      center: ["50%", "50%"],
                      label: {
                          position: 'inner',
                          fontSize: 12,
                          color:"white"
                      },
                      labelLine: {
                          show: false
                      },
                      data: res
                  }
              ]
          }
          this.companyWorkersPieChart = option;
        })
      },
      // 管理人员-来源统计
      updateMgrSourceChart(){
        human.getSailingSource(this.selectedYear).then((res) => {
          const option = {
            
                color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
                tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                series: [
                   {
                        name:"人数统计",
                        type: 'pie',
                        radius: '50%',
                        data: res,
                        label: {
                            formatter: '{b}:{d}% ',
                            borderWidth: 1,
                            borderRadius: 2,
                            fontSize:14,
                            color:"white"
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0
                            }
                        }
                    }
                ]
            
          }
          this.companyWorkersPieChart = option;
        })
      },
      updatePerformanceChart(){
      },
      showDetails(param){
        console.log(param);
      },
      companyWorkersPieChartClick(){
        console.log("companyWorkersPieChartClick");
      }
    }
  }
</script>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}

.option-block2 {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3.4rem;
  border: 1px solid #1b8ebd;
  text-align: center;
  cursor: pointer;
}
.option-block-selected{
  background-color: transparent !important;
  border: 1px solid #274C7D !important;
}
.option-block-selected2 {
  background-color: #4191e5;
  color: #fff;
}
.attendance-top{
  padding-top: 10px;
}
.tabbar{
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  border: 1px solid #157EAB;
  width: 210px;
  margin: auto;
  cursor: pointer;
}
.tabbar div{
  flex: 1;
  text-align: center;
}
.tabar-select{
  background-color: #4191E5;
  font-weight: bold;
}
.items div{
  color: #fff !important;
}
.selected{
  color: #fff !important;
}
.linebar{
  margin-top: 5rem;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto 10px;
}
.real-bar{
  height: 5px;
  background-color: #57C7B6;
  border-radius: 12px;
}
</style>

