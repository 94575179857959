<template>
  <div style="width:100%;height:10rem;display:flex;justify-content:center">
    <div style="display:flex;align-items:center">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[0]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[0]}}</div>
        <div
          style="display:flex"
          @click="handleClick(1)"
        >
          <v-number
            v-model="(broadcastData || {}).value1"
            :speed="500"
            style="font-family:digital;font-size:2.5rem;margin:-0.25rem 0 0 -1rem"
            :style="{color: broadcastColors[0]}"
          ></v-number>
          <span
            v-if="(broadcastData || {}).value1 != null"
            style="font-family:digital;font-size:2.5rem;color:#cececf;line-height:2.5rem"
          >/</span>
          <v-number
            v-model="(broadcastData || {}).value4"
            :speed="500"
            style="font-family:digital;font-size:2.5rem;color:#cececf;margin:-0.25rem 0 0 0.3rem"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:1rem">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[1]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[1]}}</div>
        <div
          style="display:flex"
          @click="handleClick(2)"
        >
          <v-number
            v-model="(broadcastData || {}).value2"
            :speed="500"
            style="font-family:digital;font-size:2.5rem;margin:-0.25rem 0 0 0.3rem"
            :style="{color: broadcastColors[1]}"
          ></v-number>
          <span
            v-if="(broadcastData || {}).value5 != null"
            style="font-size:1.5rem;color:#ffd454;line-height:3.4rem"
          >{{(broadcastData || {}).value2_text}}</span>
          <span
            v-if="(broadcastData || {}).value2 != null"
            style="font-family:digital;font-size:2.5rem;color:#cececf;line-height:2.5rem"
          >/</span>
          <v-number
            v-model="(broadcastData || {}).value5"
            :speed="500"
            style="font-family:digital;font-size:2.5rem;color:#cececf;margin:-0.25rem 0 0 0.3rem"
          ></v-number>
          <span
            v-if="(broadcastData || {}).value5 != null"
            style="font-size:1.5rem;color:#cececf;line-height:3.4rem"
          >{{(broadcastData || {}).value5_text}} </span>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:1rem">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[2]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[2]}}</div>
        <div
          style="display:flex"
          @click="handleClick(3)"
        >
          <v-number
            v-model="(broadcastData || {}).value3"
            :speed="500"
            style="font-family:digital;font-size:2.5rem;margin:-0.25rem 0 0 -1rem"
            :style="{color: broadcastColors[2]}"
          ></v-number>
          <span
            v-if="(broadcastData || {}).value6 != null"
            style="font-size:1.5rem;color:#35c59e;line-height:3.4rem"
          >万</span>
          <span
            v-if="(broadcastData || {}).value3 != null"
            style="font-family:digital;font-size:2.5rem;color:#cececf;line-height:2.5rem"
          >/</span>
          <v-number
            v-model="(broadcastData || {}).value6"
            :speed="500"
            style="font-family:digital;font-size:2.5rem;color:#cececf;margin:-0.25rem 0 0 0.3rem"
          ></v-number>
          <span
            v-if="(broadcastData || {}).value6 != null"
            style="font-size:1.5rem;color:#cececf;line-height:3.4rem"
          >万</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VNumber from "../../components/v-number";
import { nation } from "../../api";

export default {
  name: "ScheduleBroadcasts",
  components: {
    VNumber,
  },
  data() {
    return {
      broadcastTitles: [
        "工期延误项目数",
        "今年实际/目标总产值",
        "今年实际/目标人均产值",
      ],
      broadcastColors: ["#e02f37", "#ffd454", "#35c59e"],
      broadcastIcons: [
        "工期延误项目数",
        "今年实际目标总产值",
        "今年实际目标人均产值",
      ],
    };
  },
  props: {
    broadcastData: Object,
    handleClick: Function,
  },
};

export function updateData(container, scheduleNextUpdate) {
  function simplifyNum(number) {
    if (!number && number != 0) return number;
    var str_num;
    if (number >= 1e3 && number < 1e4) {
      //  + '&千元'
      str_num = number / 1e3;
      return str_num;
    } else if (number >= 1e4 && number < 1e6) {
      //  + '&万元'
      str_num = number / 1e4;
      return str_num;
    } else if (number >= 1e6 && number < 1e7) {
      //  + '&百万'
      str_num = number / 1e6;
      return str_num;
    } else if (number >= 1e7 && number < 1e8) {
      //  + '&千万'
      str_num = number / 1e7;
      return str_num;
    } else if (number >= 1e8 && number < 1e10) {
      //  + '&亿元'
      str_num = number / 1e8;
      return str_num;
    } else if (number >= 1e10 && number < 1e11) {
      //  + '&百亿'
      str_num = number / 1e10;
      return str_num;
    } else if (number >= 1e11 && number < 1e12) {
      //  + '&千亿'
      str_num = number / 1e11;
      return str_num;
    } else if (number >= 1e12) {
      //  + '&万亿'
      str_num = number / 1e12;
      return str_num;
    } else {
      //一千以下
      return number;
    }
  }
  function simplifyNumText(number) {
    if (!number && number != 0) return "暂无";
    var str_text = "";
    if (number >= 1e3 && number < 1e4) {
      str_text = "千元";
    } else if (number >= 1e4 && number < 1e6) {
      str_text = "万";
    } else if (number >= 1e6 && number < 1e7) {
      str_text = "百万";
    } else if (number >= 1e7 && number < 1e8) {
      str_text = "千万";
    } else if (number >= 1e8 && number < 1e10) {
      str_text = "亿";
    } else if (number >= 1e10 && number < 1e11) {
      str_text = "百亿";
    } else if (number >= 1e11 && number < 1e12) {
      str_text = "千亿";
    } else if (number >= 1e12) {
      str_text = "万亿";
    } else {
      //一千以下
      str_text = "元";
    }

    return str_text;
  }
  nation
    .getScheduleBroadcast()
    .then((d) => {
      container.broadcastData.schedule = d[0];
      container.broadcastData.schedule.value1 = d[0].project_delayed_count;
      container.broadcastData.schedule.value4 = d[0].project_online_count;
      container.broadcastData.schedule.value2 = simplifyNum(
        Number(d[0].total_real_output)
      ).toFixed(2);
      container.broadcastData.schedule.value2_text = simplifyNumText(
        Number(d[0].total_real_output + "0000")
      );
      console.log(container.broadcastData.schedule.value2_text ,Number(d[0].total_real_output + "0000"),'====')
      container.broadcastData.schedule.value5 = simplifyNum(
        Number(d[0].total_goal_output)
      ).toFixed(2);
      container.broadcastData.schedule.value5_text = simplifyNumText(
        Number(d[0].total_goal_output + "0000")
      );
      container.broadcastData.schedule.value3 = d[0].avg_real_output;
      container.broadcastData.schedule.value6 = d[0].avg_goal_output;

      container.statsData = d[0];
      scheduleNextUpdate();
    })
    // eslint-disable-next-line
    .catch((e) => console.error(e.msg || e.message));
}
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
