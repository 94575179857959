<template>
  <div id="left">
    <staffStructureChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <ProjectTeamChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" :org-options="orgOptions" />
    <ProjectManagerChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
  </div>
</template>

<script>
  import staffStructureChart from "./staffStructrueChart";
  import ProjectTeamChart from "./projectTeamConditionChart";
  import ProjectManagerChart from "./projectManagerChart";

  export default {
    name: 'HrIndexLeftBar',
    components: {
      staffStructureChart,
      ProjectTeamChart,
      ProjectManagerChart,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
  }
</script>

<style lang="less">
</style>
