<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  />
</template>

<script>
  import Broadcasts from "../../../../components/broadcasts";
  import { human } from "../../../../api_hr";

  export default {
    name: 'HrCompetenceStructueBroadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['在施项目数量', '持证项目经理数量', '未持证项目经理数量'],
        broadcastColors: ['#4BD8DE', '#40f8ff', '#ffd454'],
        broadcastIcons: ['在施项目', '持证项目经理数量', '未持证项目经理数量'],
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    },
  }

  export function updateData(container) {
    human
      .getCompetenceStrutureBroadCast()
      .then((d) => {
        let res = {};
        res["onProjectNums"] = d[2].VALUE;
        res["permitPmNums"] = d[0].VALUE;
        res["notPermitPmNums"] = d[1].VALUE;
        container.broadcastData.competenceStructure= res;
        container.broadcastData.competenceStructure.value1= res["onProjectNums"];
        container.broadcastData.competenceStructure.value2= res["permitPmNums"];
        container.broadcastData.competenceStructure.value3= res["notPermitPmNums"];
        container.statsData = res;
        // scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
