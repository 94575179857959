<template>
<div style="height:10rem;display:flex;justify-content:center">
    <div style="display:flex;align-items:center">
      <img :src="require(`../../../../assets/nation/icons/${broadcastIcons[0]}.png`)" />
      <div>
        <div class="stats-label" style="font-size: 0.9rem">{{broadcastTitles[0]}}</div>
        <div style="display:flex" @click="handleClick(1)">
          <v-number
            v-model="(broadcastData || {}).value1"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[0]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:0.3rem">
      <img :src="require(`../../../../assets/nation/icons/${broadcastIcons[1]}.png`)" />
      <div>
        <div class="stats-label" style="font-size: 0.9rem">{{broadcastTitles[1]}}</div>
        <div style="display:flex" @click="handleClick(2)">
          <v-number
            v-model="(broadcastData || {}).value2"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[1]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:0.3rem">
      <img :src="require(`../../../../assets/nation/icons/${broadcastIcons[2]}.png`)" />
      <div>
        <div class="stats-label" style="font-size: 0.9rem">{{broadcastTitles[2]}}</div>
        <div style="display:flex" @click="handleClick(3)">
          <v-number
            v-model="(broadcastData || {}).value3"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[2]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:0.3rem">
      <img :src="require(`../../../../assets/nation/icons/${broadcastIcons[3]}.png`)" />
      <div>
        <div class="stats-label" style="font-size: 0.9rem">{{broadcastTitles[3]}}</div>
        <div style="display:flex" @click="handleClick(4)">
          <v-number
            v-model="(broadcastData || {}).value4"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[3]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:0.3rem">
      <img :src="require(`../../../../assets/nation/icons/${broadcastIcons[4]}.png`)" />
      <div>
        <div class="stats-label" style="font-size: 0.9rem">{{broadcastTitles[4]}}</div>
        <div style="display:flex" @click="handleClick(5)">
          <v-number
            v-model="(broadcastData || {}).value5"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[4]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:0.3rem">
      <img :src="require(`../../../../assets/nation/icons/${broadcastIcons[5]}.png`)" />
      <div>
        <div class="stats-label" style="font-size: 0.9rem">{{broadcastTitles[5]}}</div>
        <div style="display:flex" @click="handleClick(5)">
          <v-number
            v-model="(broadcastData || {}).value6"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[5]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:0.3rem">
      <img :src="require(`../../../../assets/nation/icons/${broadcastIcons[6]}.png`)" />
      <div>
        <div class="stats-label" style="font-size: 0.9rem">{{broadcastTitles[6]}}</div>
        <div style="display:flex" @click="handleClick(6)">
          <v-number
            v-model="(broadcastData || {}).value7"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[6]}"
          ></v-number>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VNumber from "../../../../components/v-number"
  import { human } from "../../../../api_hr";

  var xmid = "";
  export default {
    name: 'hrBroadcasts',
    components: {
      VNumber,
    },
    data() {
      return {
        broadcastTitles: ['总人数','自有员工', 'A派员工', 'B派员工','人才梯队','人力资源池','人才公海'],
        broadcastIcons: ['总人数','自有员工', 'A派员工','B派员工','人才梯队','人力资源池','人才公海'],
        broadcastColors: ['#fea453','#40f8ff', '#ffd454', '#35c59e','#d3adf7', '#ffbf7b', '#40b6fe'],
        broadcastSep: '0.3rem',
      }
    },
    props: {
      broadcastData: Object,
      worProjectId: String,
    },
    mounted(){
      xmid = this.worProjectId;
    },
    methods: {
      handleClick() {
      }
    }
  }

  export function updateData(container, scheduleNextUpdate) {
    human.getProjectDirecctMap(xmid)
      .then(res=>{
        container.broadcastData.hr = res;
        container.broadcastData.hr.value1 = res.selfAmount+res.groupAAmount+res.groupBAmount;
        container.broadcastData.hr.value2 = res.selfAmount;
        container.broadcastData.hr.value3 = res.groupAAmount;
        container.broadcastData.hr.value4 = res.groupBAmount;
        container.broadcastData.hr.value5 = res.hrEchelonAmount;
        container.broadcastData.hr.value6 = res.hrPoolAmount;
        container.broadcastData.hr.value7 = res.hrSeaAmount;

        container.statsData = res;
        // scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1rem;
  font-weight: bold;
  color: #cececf;
}
</style>
