<template>
  <div id="right">
    <TargetDutyChart :district-level="districtLevel" :org-level="orgLevel" />
    <TalentNumChart :district-level="districtLevel" :org-level="orgLevel" />
    <PartnerConditionChart :district-level="districtLevel" :org-level="orgLevel" />
  </div>
</template>

<script>
  import TargetDutyChart from "./targetDutyChart";
  import TalentNumChart from "./talentNumChart";
  import PartnerConditionChart from "./partnerConditionChart";

  export default {
    name: 'QualityIndexRightBar',
    components: {
      TargetDutyChart,
      TalentNumChart,
      PartnerConditionChart
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
  }
</script>

<style lang="less">
</style>
