<template>
  <div id="right">
    <!-- <wrapper>
      <div style="display:flex;align-items:center">
        <div style="font-size:1em;flex:1">近七日报警数</div>
        <div class="option-block option-block-selected" style="font-size:0.7rem;min-width:0" @click="() => showAlarmDetail(1)">详情</div>
      </div>
      <div style="height:15.5rem;padding-top:1rem;margin-bottom:-0.3rem">
        <v-chart :options="sevenDayAlarmsLineChart" autoresize />
      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem">
      <div style="display:flex;align-items:center">
        <div style="font-size:1em;flex:1">近七日预警数</div>
        <div class="option-block option-block-selected" style="font-size:0.7rem;min-width:0" @click="() => showAlarmDetail(2)">详情</div>
      </div>
      <div style="height:15.5rem;padding-top:1rem;margin-bottom:-0.3rem">
        <v-chart :options="sevenDayWarningsLineChart" autoresize />
      </div>
    </wrapper> -->
    <div id="people-warning-list">

      <wrapper style="padding:0">

        <div
          class="info-block"
          style="min-height:5rem;margin-bottom:1rem"
        >
          <div
            class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
          >
            <div>今日预警记录</div>

          </div>
          <div style="height:18rem;padding:3rem 1rem 0">

            <div style="height:3.5rem;background-color:#151c48;padding:0.3rem;display:flex;flex-direction:column;justify-content:space-around;font-size:0.75rem;">
              <div style="display:flex">
                <div
                  style="margin:0.3rem 0.5rem;flex:1;display:flex;flex-direction: column;align-items: center;"
                  v-for="item in warningStats"
                  :key="item.alarm_type"
                >
                  <div style="flex:1;text-align:right;font-size:1.4rem;margin-bottom:0.2rem">{{item.count}}</div>
                  <div style="flex:3;font-weight:600;color:#42a9da">{{item.alarm_type}}</div>
                </div>
              </div>
            </div>
            <div
              v-if="!warningList.length"
              style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;"
            >
              <div class="empty-chart-icon" />
              <div style="margin-top:10px;color:#ccc">暂无数据</div>
            </div>
            <div
              v-else
              style="height: 11.2rem; overflow-y: scroll;margin-top:1rem;cursor: pointer;"
            >
              <div
                class="warning-item"
                v-for="item in warningList"
                :key="item.id"
              @click="() => showWorkerInfo(item)">
                <div>
                  <div style="display:flex">
                    <div class="name">姓名:</div>
                    <div class="value">{{item.worker_name}}</div>
                  </div>
                  <div style="display:flex">
                    <div class="name">位置:</div>
                    <div class="value">{{item.location}}</div>
                  </div>
                </div>
                <div style="margin-left:0.3rem;">
                  <div style="display:flex">
                    <div class="name">班组:</div>
                    <div class="value">{{item.team_name}}</div>
                  </div>
                  <div style="display:flex">
                    <div class="name">状态:</div>
                    <div
                      class="value"
                      :class="'text-' + (statusColorMap[item.alarm_type] || {}).cls"
                    >{{item.alarm_type}}</div>
                  </div>
                </div>
                <div style="width:2.5rem;margin-left:0.3rem;align-self:center"><time>{{item.createdtime}}</time></div>
              </div>
            </div>
          </div>

        </div>
      </wrapper>
      <el-dialog
        custom-class="stats-layer"
        :visible.sync="alarmDetailShow"
        width="1500px"
        top="15rem"
      >
        <wrapper style="height:457px;backgroundColor:#0e1328;position:relative">
          <iframe
            :src="alarmDetailShow ? `http://smarthat.comprehend.com.cn/html/screen/warning_list.html?project_id=${$route.params.id}&type_index=${alarmDetailType}&token=${$route.query.token}` : ''"
            width="1500px"
            height="457px"
            style="border:0;position:absolute;left:0;top:0"
          ></iframe>
          <div
            class="stats-close-btn"
            @click="alarmDetailShow = false"
          />
        </wrapper>
      </el-dialog>
    </div>

    <div id="people-status">
      <wrapper style="margin-top: 1rem;padding:0;height:37rem">

        <div
          class="info-block"
          style="min-height:3rem;"
        >
          <div
            class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
          >
            <div>在场人员状态</div>
            <div
              class="detail"
              @click="showSearch=!showSearch"
            >
              搜索
            </div>
          </div>
        </div>
        <div v-if="showSearch">
          <el-input
            placeholder="请输入内容"
            v-model="workerName"
            class="input-with-select"
            clearable
            @clear="getWorkList"
          >
            <el-button
              slot="append"
              @click="getWorkList"
            >搜索</el-button>
            <!-- <el-button slot="append" class="cancel">取消</el-button> -->
          </el-input>
        </div>
        <div
          id="retreat-btn"
          style="margin:.5rem 1rem 1rem 1rem "
          @click="showRecallDialog = true"
          v-if="groupList.length"
        >全员召回</div>
        <div
          v-if="!groupList.length>0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <div
          v-else
          class="group"
        >
          <div
            class="group-item"
            v-for="group in groupList"
            :key="group.team_name"
          >
            <div class="group-title light-blue-text"><span
                class="group-title-icon"
                :class="[group.show ? '' : 'active']"
                @click="toggleGroup(group)"
              > </span>{{group.team_name}}（总人数：{{group.worker_list.length}}）
            </div>
            <ul
              class="people-card-list"
              v-show="group.show"
            >

              <li
                class="card-item"
                v-for="worker in group.worker_list"
                :key="worker.innerid"
              >
                <div class="left">
                  <div style="display:flex">
                    <div>
                    <p>
                      <span class="title">姓名：</span>
                      <span class="value">{{worker.name}}({{worker.hat_code}})</span>
                    </p>
                    <p>
                      <span class="title">运动状态：</span>
                      <span
                        class="value"
                        v-if="worker.from==1"
                      >----</span>
                      <span
                        class="value"
                        v-if="worker.from!=1"
                        :class="'text-' + motionMap[worker.motion]"
                      >{{worker.motion || '--'}}</span>
                    </p>
                  </div>
                  <div style="margin-left:1rem">
                    <p>
                      <span class="title">工种：</span>
                      <span class="value">{{worker.worker_type}}</span>
                    </p>
                    <p>
                      <span class="title">剩余电量：</span>
                      <span
                        class="value"
                        v-if="worker.from==1"
                      >----</span>
                      <span
                        class="value"
                        v-if="worker.from!=1"
                        :class="'text-' + batteryMap(worker.battery_level)"
                      >{{worker.battery_level}}%</span>
                    </p>


                  </div>
                  </div>
                    <p>
                      <span class="title">区域：</span>
                      <span
                        class="value"
                        v-if="worker.from==1"
                      >----</span>
                      <span
                        v-if="worker.from!=1"
                        class="value"
                      >{{worker.area === 0 ? `地面` : `${worker.build_name} - ${worker.floor_name=='ground'?worker.area_name:worker.floor_name}`}}</span>
                    </p>

                </div>
                <div class="right">
                  <div
                    class="status-card"
                    :class="'borderColor-' + statusColorMap[worker.alarm].cls"
                  >
                    <p class="zt">状态</p>
                    <p
                      class="status-text"
                      :class="'text-' + statusColorMap[worker.alarm].cls"
                    >{{worker.alarm}}</p>
                  </div>

                  <div style="display:flex">
                    <div
                        v-if="worker.from!=1"
                        class="op"
                        :class="retrieveStatusMap[worker.recalled].cls"
                        @click="prepareRecall(worker)"
                      >
                        {{retrieveStatusMap[worker.recalled].text}}
                      </div>
                      <div
                        style="background-color: #fee21d;"
                        class="op op2"
                        v-if="worker.battery_level!==0"
                        :class="retrieveStatusMap[worker.recalled].cls"
                        @click="showTrace(worker.innerid,worker.name)"
                      >
                        轨迹
                      </div>
                  </div>

                </div>
              </li>
            </ul>
          </div>
        </div>
      </wrapper>
    </div>

    <div id="callback-dialog">
      <el-dialog
        :visible.sync="showCallBackDialog"
        :show-close="false"
        center
        custom-class="recall-dialog"
      >
        <div style="display:flex;align-items:center">
          <div style="flex:1" />
          <p class="warning-icon"></p>
          <p style="margin-bottom:3.5rem;margin-left:3rem;">是否确认召回{{callBackPerson}}？</p>
          <div style="flex:1" />
        </div>
        <div style="text-align:center">
          <div
            class="cancel-button"
            @click="showCallBackDialog = false"
          >取消</div>
          <div
            class="confirm-button"
            @click="callBack"
          >确定</div>
        </div>
      </el-dialog>
    </div>
    <div id="recall-dialog">
      <el-dialog
        :visible.sync="showRecallDialog"
        :show-close="false"
        center
        custom-class="recall-dialog"
      >
        <div style="display:flex;align-items:center">
          <div style="flex:1" />
          <p class="warning-icon"></p>
          <p style="margin-bottom:3.5rem;margin-left:3rem;">是否确认执行全体撤离操作？</p>
          <div style="flex:1" />
        </div>
        <div style="text-align:center">
          <div
            class="cancel-button"
            @click="showRecallDialog = false"
          >取消</div>
          <div
            class="confirm-button"
            @click="retrieveAll"
          >确定</div>
        </div>
      </el-dialog>
    </div>
     <el-dialog
      custom-class="stats-layer"
      :visible.sync="personalInfoPopupShow"
      width="1500px"
      top="15rem"
    >
      <wrapper style="height:622px;backgroundColor:#0e1328;position:relative">
        <iframe
          :src="personalInfoPopupShow ? `http://smarthat.comprehend.com.cn/html/screen/worker_detail.html?worker_id=${personalInfoWorkerId}&type_index=${personalInfoTabIndex}&token=${$route.query.token}` : ''"
          width="1500px"
          height="622px"
          style="border:0;position:absolute;left:0;top:0"
        ></iframe>
        <div
          class="stats-close-btn"
          @click="personalInfoPopupShow = false"
        />
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
// import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { project } from "../../api";
import wrapper from "../../components/wrap";
// import { singleLineChartTemplate } from "./lineChartTemplates";

function addOverflowTooltip(selector) {
  setTimeout(() => {
    document.querySelectorAll(selector).forEach(function (item) {
      if (!item.getAttribute("listener")) {
        item.addEventListener("mouseenter", function () {
          let newAttribute;
          if (item.offsetWidth < item.scrollWidth) {
            if (item.getAttribute("title") !== item.innerHTML) {
              newAttribute = item.innerHTML;
            } else {
              return;
            }
          } else {
            newAttribute = "";
          }
          item.setAttribute("title", newAttribute);
        });
        item.setAttribute("listener", true);
      }
    });
  }, 30);
}

export default {
  name: "SecurityPersonnelRightBar",
  components: {
    // 'v-chart': ECharts,
    wrapper,
  },
  props: {
    showTrace: Function,
    wsObj: Object,
  },
  data() {
    return {
      sevenDayAlarmsLineChart: {},
      sevenDayWarningsLineChart: {},
      warningStats: {},
      warningList: [],
      statusColorMap: {
        正常: { value: "#1fc86b", cls: "green" },
        呼救: { value: "#f34e53", cls: "red2" },
        坠落: { value: "#e62c31", cls: "red1" },
        预警: { value: "#eebc34", cls: "yellow2" },
        禁区预警: { value: "#eebc34", cls: "yellow2" },
        危险预警: { value: "#eebc34", cls: "yellow2" },
        闯入禁区: { value: "#eebc34", cls: "yellow2" },
        静止: { value: "#eebc34", cls: "yellow2" },
        倒地: { value: "#F89132", cls: "yellow1" },
        离线: { value: "#9ca0aa", cls: "grey" },
        没电: { value: "#eebc34", cls: "yellow2" },
      },
      alarmDetailShow: false,
      alarmDetailType: 1,
      timer: null,

      showSearch: false,
      projectId: null,
      workerName: "",
      wearMap: [
        { cls: "red2", text: "未佩戴" },
        { cls: "green", text: "佩戴" },
      ],
      motionMap: {
        运动: "green",
        静止: "green",
        倒地: "yellow1",
        坠落: "red1",
        没电: "yellow1",
      },
      retrieveStatusMap: {
        11: { text: "召回", cls: "" },
        7: { text: "已收到", cls: "disabled" },
        8: { text: "已确认", cls: "disabled" },
        10: { text: "发送中", cls: "disabled" },
      },
      groupList: [{ worker_list: [], show: true }],
      callBackPerson: "",
      callBackFunc: null,
      showCallBackDialog: false,
      showRecallDialog: false,
      showPrompt: false,

      map_list: [],
      img_host: "",
      travel_url: "",
      s_build_id: "",
      extraMapOptions: {},
      sceneReady: true,
      personalInfoPopupShow: false,
      personalInfoWorkerId: null,
      personalInfoTabIndex: 1,
    };
  },
  mounted() {
    const { id: projectID } = this.$route.params;
    this.projectId = projectID;
    const token = this.$route.query.token;
    window.token = token;

    this.getData();
  },
  methods: {
    getData() {
      this.getWorkList();
      this.getWarningInfo();
    },
    getWarningInfo() {
      project
        .getSafetyPsWarningAlarmTypeCollect(this.projectId)
        .then((d) => {
          this.warningStats = d;
          // for (const item of d) {
          //   this.warningStats[item.alarm_type] = item.count;
          // }
        })
        .catch((e) => console.error(e.msg || e.message));

      project
        .getSafetyPsAlarmList(this.projectId, 1, 100)
        .then((d) => {
          this.warningList = d;
          addOverflowTooltip("#people-warning-list .value");
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    getWorkList() {
      project
        .getSafetyPresentWorkerList(this.projectId, "", this.workerName)
        .then((list) => {
          // console.log(list, "0000000000000000");
          this.groupList = list.map((item, index) => {
            return {
              ...item,
              show: index == 0 ? true : false,
            };
          });
        })
        .catch((e) => console.error(e.msg || e.toString()));
    },
    batteryMap(number) {
      if (number * 1 <= 20) {
        return "red2";
      } else if (number * 1 > 20 && number * 1 < 60) {
        return "yellow2";
      } else {
        return "green";
      }
    },
    toggleGroup(group) {
      group.show = !group.show;
      this.$forceUpdate();
    },

    prepareRecall(worker) {
      this.callBackFunc = () => {
        this.recall(worker);
      };
      this.callBackPerson = worker.name;
      this.showCallBackDialog = true;
    },
    recall(worker) {
      if (worker.recalled !== 11) return;
      if (worker.hat_id === "null" || worker.hat_id === null)
        return this.$message.warning("用户帽子id不正确");
      if (this.wsObj.ws) {
        this.wsObj.ws.send(
          JSON.stringify({
            action: "recall",
            values: {
              hat_id: worker.hat_id,
              ctrl_mac: worker.ctrl_mac,
              ch: worker.ch,
            },
          })
        );
      }
    },
    callBack() {
      // console.log('点击了召回');
      this.showCallBackDialog = false;
      this.callBackFunc && this.callBackFunc();
    },
    retrieveAll() {
      this.showRecallDialog = false;
      if (this.wsObj.ws) {
        this.wsObj.ws.send(
          JSON.stringify({
            action: "retreat",
            values: { project_id: this.wsObj.ws.id },
          })
        );
        this.showPrompt = false;
      }
    },
    showWorkerInfo(item) {
      // console.log(item);
      if(item.type === 2){
        this.personalInfoWorkerId = item.worker_id;
        this.personalInfoTabIndex = 3;
        this.personalInfoPopupShow = true;
      }

    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
#people-status {
  #retreat-btn {
    margin: 1rem 0;
    height: 1.875rem;
    text-align: center;
    line-height: 1.875rem;
    cursor: pointer;
    background: linear-gradient(to right, #51bbf5, #5170f5);
  }

  .group {
    height: 28rem;
    padding: 0 1rem;
    overflow-y: scroll;

    .group-item {
      .group-title {
        margin-bottom: 1rem;

        .group-title-icon {
          width: 1rem;
          height: 1rem;
          display: inline-block;
          margin-right: 0.25rem;
          cursor: pointer;
          position: relative;

          &.active {
            transform: rotate(-90deg);
          }

          &::before {
            content: " ";
            position: absolute;
            width: 1rem;
            font-size: 1rem;
            transform: rotate(0deg);
            transition: transform 0.3s ease-in-out;
            height: 1rem;
            background: url("../../assets/sanjiao.png") no-repeat;
          }
        }
      }

      .people-card-list {
        .card-item {
          padding: 0.4rem 1rem;
          display: flex;
          margin-bottom: 0.75rem;
          background-color: #151c48;
          border-radius: 0.25rem;


          .left {
            flex: 1;
            // display: flex;
            font-size: 14px;
            line-height: 2rem;

            .title {
              color: #999999;
            }
          }

          .right {
            width: 5rem;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .status-card {
              border-width: 0.5rem 1px 1px;
              border-style: solid;
              border-radius: 0.25rem;
              margin-bottom: 1rem;
              padding: 0.7rem;

              .zt {
                font-size: 0.88rem;
                color: #999;
                padding: 3px 0;
              }

              .status-text {
                padding-top: 5px;
                font-size: 1.13rem;
                font-weight: bold;
              }
            }

             .op {
              width: 3rem;
              height: 1.5rem;
              background: linear-gradient(
                90deg,
                rgba(81, 189, 245, 1),
                rgba(81, 112, 245, 1)
              );
              line-height: 1.5rem;
              cursor: pointer;
              font-size: 0.75rem;
              text-align: center;

              &.disabled {
                background: #8a8a8a;
                cursor: not-allowed;
              }
            }
            .op2 {
              margin-left: .5rem;
              background: linear-gradient(
                90deg,
                #f5c151,#ef873b
              );
            }


          }
        }
      }
    }
  }
}

.el-dialog.recall-dialog {
  width: 31.25rem !important;
  background-color: rgba(21, 28, 72, 0.8) !important;
}

.recall-dialog .el-dialog__header,
.recall-dialog .el-dialog__footer {
  display: none;
}

.recall-dialog .el-dialog__body {
  text-align: center;
  font-size: 1.25rem;
  color: #fff;
  padding: 1.5rem;
}

.recall-dialog .warning-icon {
  display: inline-block;
  background: url("../../assets/weixian.png") no-repeat;
  width: 5rem;
  height: 5rem;
  background-size: cover;
  margin-bottom: 3.5rem;
}

.recall-dialog .cancel-button,
.recall-dialog .confirm-button {
  width: 6.13rem;
  height: 2rem;
  border-radius: 1rem;
  color: #ffffff;
  line-height: 2rem;
  font-size: 1rem;
  display: inline-block;
  background: rgba(117, 120, 167, 1);
  cursor: pointer;
  text-align: center;
}

.recall-dialog .confirm-button {
  margin-left: 2.5rem;
  background: linear-gradient(
    90deg,
    rgba(81, 189, 245, 1),
    rgba(81, 112, 245, 1)
  );
}
.text-green {
  color: #1fc86b !important;
}

.text-yellow1 {
  color: #f89132 !important;
  border-color: #f89132 !important;
}

.text-yellow2 {
  color: #eebc34 !important;
  border-color: #eebc34 !important;
}

.text-red1 {
  color: #e62c31 !important;
}

.text-red2 {
  color: #f34e53 !important;
}

.text-grey {
  color: #9ca0aa !important;
}

.borderColor-green {
  border-color: #1fc86b !important;
}

.borderColor-grey {
  border-color: #9ca0aa !important;
}

.borderColor-yellow1 {
  border-color: #f89132 !important;
}

.borderColor-yellow2 {
  border-color: #eebc34 !important;
}

.borderColor-red1 {
  border-color: #e62c31 !important;
}

.borderColor-red2 {
  border-color: #f34e53 !important;
}

.stats-close-btn {
  z-index: 30000;
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}

#people-warning-list {
  .warning-item {
    margin-bottom: 0.75rem;
    padding: 8px 6px;
    font-size: 0.75rem;
    line-height: 1.5rem;
    background-color: #151c48;
    border-radius: 0.25rem;
    color: #ffffff;
    position: relative;
    display: flex;
    justify-content: space-around;
    div.name {
      color: #999999;
      width: 2rem;
    }
    div.value {
      width: 5rem;
      padding-left: 0.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    time {
      background-color: #577acc;
      border-radius: 4px;
      padding: 5px;
    }
  }
}
</style>
