<template>
<div id="right">
    <div class="info-block" style="min-height:5rem">
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>工作室项目情况</div>
        <div class="detail" @click="showDetail()">
          详情
        </div>
      </div>
      <div style="height:22rem;padding-top:3rem">
        <div
          style="display:flex;justify-content:space-around;margin-bottom:0.6rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')"
          >
            项目统计
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')"
          >
            成果统计
          </div>
        </div>
        <div
          v-if="companyWorkersPieChart.series && [].concat.apply([], companyWorkersPieChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyWorkersPieChart" @click="companyWorkersPieChartClick" autoresize/>
      </div>
    </div>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle}}
            </div>
            <div class="tabbar" style="width:22rem">
              <div
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >项目统计</div>
              <div
                :class="{ 'tabar-select': popupSelect == 5 }"
                @click="getAttendance(5)"
              >成果统计</div>
            </div>
            <!-- <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem;">
              <CustomSelect :options="companyNameList" :value="selectVal1" @input="companySelect($event, 'val1')" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;" />
            </div> -->
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:15rem;text-align:center">分公司</div>
                <div style="width:8rem;text-align:center">深基坑</div>
                <div style="width:8rem;text-align:center">钢结构</div>
                <div style="width:8rem;text-align:center">装配式</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popProjectsList"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:15rem;text-align:center">{{item.orgName}}</div>
                  <div style="width:8rem;text-align:center">{{item.sjk}}</div>
                  <div style="width:8rem;text-align:center">{{item.gjg}}</div>
                  <div style="width:8rem;text-align:center">{{item.zps}}</div>
                </div>
                <div
                  v-if="!(popProjectsList && popProjectsList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>

        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { tech } from '../../../../api_tech'
  import wrapper from "../../../../components/wrap";
  // import { truncateStr } from "../../../../utils";
// import func from 'vue-editor-bridge'

  export default {
    name: 'studioAchiChart',
    components: {
      'v-chart': ECharts,
      wrapper
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      }
    },
    data () {
      return {
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear(),
        companyWorkersData: {},
        companyWorkersOption: "1",
        companyWorkersPieChart: {},
        popupShow:false,
        popProjectsList:[],
        popupTitle:"",
        popupSelect:2
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.getAll();
    },
    methods: {
      getAll(){
          this.switchCompanyWorkersOption(this.companyWorkersOption);
      },
      switchCompanyWorkersOption(choose){
          this.companyWorkersOption = choose;
          switch (this.companyWorkersOption) {
              case "1":
                  this.updateStudioProject();
                  break;
              case "2":
                  this.updateStudioAchi();
                  break;
              default:
                  break;
          }
      },
      updateStudioProject(){
        tech.getStudioProject().then((res=>{
          const orgList = [];
          const deepExca = [];
          const fabri = [];
          const len = res.length>12?12:res.length;
          for (let i = 0; i < len; i++) {
            orgList.push(res[i].orgName);
            deepExca.push(res[i].sjk);
            fabri.push(res[i].zps);
          }
          const option = {
              color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  },
                  formatter:function(param){
                    let str = param[0].name+"<br/>";
                    for (let index = 0; index < param.length; index++) {
                      str += param[index].seriesName+":"+param[index].value+"<br/>";
                    }
                    return str;
                  }
              },
              grid: {
                containLabel: true,
                left: '5%',
                right: '6%',
                bottom: "5%",
                top: "12%"
              },
              xAxis: [
                {
                  name:"单位",
                  data: orgList,
                  axisLabel: {
                    color: 'white',
                    fontWeight: 'bold',
                    rotate: 40,
                    // formatter: value => truncateStr(value, 10),
                  },
                  show: true,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'category',
                }
              ],
              yAxis: [
                {
                  name:'数量',
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  boundaryGap: [0, '20%'],
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'value'
                }
              ],
              series: [
                {
                  name: '深基坑',
                  type: 'bar',
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: deepExca
                },
                {
                  name: '装配式',
                  type: 'bar',
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: fabri
                }
              ]
          }
          this.companyWorkersPieChart = option;
        }))
      },
      updateStudioAchi() {
        tech.getStudioAchi().then((res =>{
          const orgList = [];
          const deepExca = [];
          const fabri = [];
          const len = res.length>12?12:res.length;
          for (let i = 0; i < len; i++) {
            orgList.push(res[i].orgName);
            deepExca.push(res[i].sjk);
            fabri.push(res[i].zps);
          }
          const option = {
              color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
               legend: {
                icon: 'rect',
                itemWidth: 12,
                itemHeight: 12,
                itemGap: 12,
                textStyle: {
                  color: '#ccc',
                  fontWeight: 'bold',
                  fontSize: '11'
                },
                padding: [8, 0, 0, 0],
              },
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  },
                  formatter:function(param){
                    let str = param[0].name+"<br/>";
                    for (let index = 0; index < param.length; index++) {
                      str += param[index].seriesName+":"+param[index].value+"<br/>";
                    }
                    return str;
                  }
              },
              grid: {
                containLabel: true,
                left: '5%',
                right: '6%',
                bottom: "5%",
                top: "12%"
              },
              xAxis: [
                {
                  name:"单位",
                  data: orgList,
                  axisLabel: {
                    color: 'white',
                    fontWeight: 'bold',
                    rotate: 40,
                    // formatter: value => truncateStr(value, 10),
                  },
                  show: true,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'category',
                }
              ],
              yAxis: [
                {
                  name:'数量',
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  boundaryGap: [0, '20%'],
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'value'
                }
              ],
              series: [
                {
                  name: '深基坑',
                  type: 'line',
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: deepExca
                },
                {
                  name: '装配式',
                  type: 'line',
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: fabri
                }
              ]
          }
          this.companyWorkersPieChart = option;
        }))
      },
      showDetail(){
        if(this.companyWorkersOption == "1"){
          tech.getStudioProject().then(res=>{
          this.popupSelect = 2;
          this.popupShow = true;
          this.popupTitle = "工作室项目数量统计"
          this.popProjectsList = res;
          })
        }else{
           tech.getStudioAchi().then(res=>{
          this.popupSelect = 5;
          this.popupShow = true;
          this.popupTitle = "工作室成果数量统计"
          this.popProjectsList = res;
          })
        }
      },
      getAttendance(param){
        this.popupSelect = param;
        this.companyWorkersOption = this.popupSelect ==2?"1":"2";
        this.showDetail();
      },
      companyWorkersPieChartClick(){
        this.showDetail();
      }
    }
  }
</script>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}

.option-block2 {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3.4rem;
  border: 1px solid #1b8ebd;
  text-align: center;
  cursor: pointer;
}
.option-block-selected{
  background-color: transparent !important;
  border: 1px solid #274C7D !important;
}
.option-block-selected2 {
  background-color: #4191e5;
  color: #fff;
}
.attendance{

}
.attendance-top{
  padding-top: 10px;
}
.tabbar{
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  border: 1px solid #157EAB;
  width: 210px;
  margin: auto;
  cursor: pointer;
}
.tabbar div{
  flex: 1;
  text-align: center;
}
.tabar-select{
  background-color: #4191E5;
  font-weight: bold;
}
.items div{
  color: #fff !important;
}
.selected{
  color: #fff !important;
}
.attendance-bottom{

}
.linebar{
  margin-top: 5rem;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto 10px;
}
.real-bar{
  height: 5px;
  background-color: #57C7B6;
  border-radius: 12px;
}
</style>

