<template>
  <div id="left">
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div class="info-block-title">机械工程使用情况</div>

      <div style="height:20rem;min-height:12rem;padding-top:3rem">
        <div
          v-if="machineUse.series && [].concat.apply([], machineUse.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="machineUse"
          ref="troubleType"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div class="info-block-title">危大工程级别情况</div>
      <div style="height:20rem;padding-top:3rem">
        <div
          v-if="dangerLevelsChart.series && [].concat.apply([], dangerLevelsChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="dangerLevelsChart" autoresize />
      </div>
    </div>
    <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
       <div class="info-block-title" style="display: flex;align-items:center;">
          <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode == 1 }"
            @click="() => changeChart(1)"
          >进场教育完成率</div>
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode == 2 }"
            @click="() => changeChart(2)"
          >培训考核合格率</div>
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode == 3 }"
            @click="() => changeChart(3)"
          >人均培训次数</div>
        </div>
      </div>
      <div style="height:19rem;padding-top:3rem">
        <div
          v-if="sevenDayAlarmsLineChart.series && [].concat.apply([], sevenDayAlarmsLineChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="sevenDayAlarmsLineChart" @click="sevenDayAlarmsLineChartClick" autoresize />
      </div>
    </div>
    
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow3"
      width="60rem"
      top="15rem"
    >
      <wrapper style="height:28rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow3=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle3}}
            </div>
            <div
              class="tabbar"
              style="width:22rem"
            >
              <div 
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 1 }"
                @click="getAttendance(1)"
              >进场教育完成率</div>
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >培训考核合格率</div>
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 3 }"
                @click="getAttendance(3)"
              >人均培训次数</div>
            </div>
            <div style="width:10rem"></div>
          </div>
          <div style="display:flex; align-items: center;margin-top:1rem;justify-content: space-between;">
            <div></div>
            <div>

            </div>
            <div style="font-size:12px;color:#fff;">{{popupSelect == 1?'完成率：%':(popupSelect == 2)?'合格率：%':'次数：次'}}</div>
          </div>
          <!-- <div style="border:1px solid #112563;margin:1.5rem 1rem"> -->
          <div style="height:20rem;padding:1rem 0 0 1rem">
            <div style="height:100%;overflow-y:scroll">
              <div
                v-for="(item, index) in sevenDayAlarmsTable"
                :key="index"
                style="margin-bottom:1rem"
              >

                <div style="color: #A7B6BC;">
                  {{item.project_name}}
                </div>
                <div class="linebar">

                  <div
                    style="flex: 5"
                    class="line-bar"
                  >
                    <div
                    v-if="popupSelect == 3"
                      class="real-bar"
                      :style="{'width': (item.count / avgAttendanceMax * 100) + '%' }"
                    ></div>
                    <div
                    v-else
                      class="real-bar"
                      :style="{'width': (item.count / 100*100) + '%' }"
                    ></div>

                  </div>

                  <div style="color: #57C7B6;width:40px">
                    {{popupSelect == 3?item.count:item.count+'%'}}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation, project } from "../../api";
// import CustomSelect from "../../components/select";
import {
  stackedBarChartTemplate,
  // SimpleBarChartTemplate,
} from "./barChartTemplates";
import { applyUpdate } from "../../utils";
// import { singleLineChartTemplate } from "./lineChartTemplates";
import { SimpleBarChartTemplate } from './barChartTemplates';
import wrapper from "../../components/wrap";

export default {
  name: "SecurityLeftBar",
  components: {
    "v-chart": ECharts,
    wrapper,
    // CustomSelect: CustomSelect,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    orgType: Number,
  },
  data() {
    return {

      companyNameList: ["全部分公司"],
      companyIdList: [""],
      machineUse: {},
      option: {
        color: ["#4270ee", "#46a0ff", "#159172", "#2ad156"],
        tooltip: {
          // show: false,
          // trigger: "none",
        },
        legend: {
          data: []
        },
        dataZoom: [{
          type: 'slider',
          show: true,
          start: 0,
          end: 100,
          yAxisIndex: 0,
          filterMode: 'empty',
          width: 6,
          height: '78%',
          left: '95%',
          handleSize: 0,
          zoomLock: true,
          top: '8%',
          fillerColor: '#323d86',
          borderColor: '#323d86',
          textStyle: {
            color: "#fff",
            fontSize: 10,
          }
        }, {
          type: 'inside',
          yAxisIndex: 0,
          start: 0,
          end: 100,
          zoomOnMouseWheel: false,
          moveOnMouseMove: true,
          moveOnMouseWheel: true
        }],
        grid: {
          top: "23%",
          left: "2%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          // show: false,
          axisLabel: {
            color: "#e6f2f3",
            rotate: 0,
            fontWeight: "normal",
          },
          // 显示分隔线
          splitLine: {
            show: true,
            // lineStyle: {
            //   color: "#36445f",
            //   width: 1,
            //   type: "solid",
            // },
          },
        },
        yAxis: [
          {
            name: '数量：个',
            data: [],
            nameRotate: "",
            nameTextStyle: {
              color: "#ccc",
              align: "right",
            },
            type: "category",
            axisLabel: {
              color: "#ffffff",
              formatter: function (value) {
                return value.length > 8 ? value.slice(0, 8) + "..." : value;
              },
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            axisTick: { show: false },
          },
        ],
        series: [],
      },
      levelOneTroublePoints: [],
      troubleTypePieChart: {},
      troubleTypePieChartHighlightIndex: 0,
      sevenDayAlarmsLineChart: {},
      mode: 1,
      option3: {
          tooltip: {
            trigger: 'axis',
            formatter: "{b}<br>{a0}: {c0}"
          },
          color: ['#5DD5C3', '#1bd080'],
          grid: {
            contentLabel: true,
            left: '12%',
            right: '6%',
            bottom:" 28%",
            top: "13%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35,

                formatter: function(value) {
                  return value.length>8 ? value.slice(0,7) + '...' : value;
                },
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true
            },
            {
              min: 0,
              max: 100,
              interval: 25,
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                formatter: function(value) {
                  return value ? value + '%' : value;
                },
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: false
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              barWidth: 10,
              data: [],
              markLine: {
                symbol: ["none", "none"], //去掉箭头
                itemStyle: {
                  normal: {
                    lineStyle: {
                      type: "dashed",
                      color: "#238778",
                    },
                    label: {
                      show: false,
                      position: "middle",
                    },
                  },
                },
                data: [
                  {
                    yAxis: -1, //这里设置false是隐藏不了的，可以设置为-1
                  },
                ],
              }
            },
            {
              name: '数量',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                  {offset: 1, color: 'rgba(27, 208, 128, 0)'}
                ])
              },
              symbolSize: 0,
              yAxisIndex: 1,
              data: [],
            }
          ]
        },
      dangerLevelsChart: {},

      
      // districtScale: 0,
      popupShow3: false,
      popupSelect: 1,
      popupTitle3: "",
      sevenDayAlarmsTable: [],
      search_keys3:[],
      activeIndex3:0
    };
  },
  watch: {
    orgLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.getData();
        this.changeChart(1);
      }
    },
    districtLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        if (newValue == "100000") {
          this.districtScale = 0;
        }
        this.getData();
        this.changeChart(1);
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.districtScale = newValue;
      }
    },
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;

    this.getData();
    this.changeChart(1);
  },
  methods: {
    getData() {
      project
        .getGeneralParamsSelect(31)
        .then((d) => {
          this.companyNameList = ["全部设备"].concat(
            d.map((item) => item.value)
          );
          this.machineIdList = [""].concat(d.map((item) => item.innerid));
        })
        .catch((e) => console.error(e.msg || e.message));
      project
        .getDashboardMachineCollect()
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
          this.option.yAxis[0].data = d.yAxis_data;
          this.option.series = d.series.map((item, index) => {
            return {
              name: d.legend_data[index],
              type: "bar",
              stack: "total",
              barMaxWidth: 18,
              emphasis: {
                focus: "series",
              },
              data: item,
            };
          });
          this.option.legend.data = d.legend_data;

          if (this.option.series[0].data.length <= 6) {
            this.option.dataZoom[0].show = false;
          } else {
            this.option.dataZoom[0].show = true;
            this.option.dataZoom[0].start = this.option.dataZoom[1].start = 100 - Math.min(100, (6 / this.option.series[0].data.length) * 100);
          }

          this.machineUse = applyUpdate(chart, this.option);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
      nation.getSafetyDangerLevels()
          .then(d => {
            const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
            const option = {
              color: ["#46A0FF", "#FFED21", "#FF8521", "rgba(0,0,0,0)"],
              legend: {
              },
              grid: {
                right: "5%",
                bottom: "5%"
              },
              xAxis: {
                type: "value",
                axisLabel: {
                  color: "#e6f2f3",
                  rotate: 0,
                  fontWeight: "normal",
                },
                splitLine: {
                  show: true,
                },
              },
              yAxis: [
                {
                  nameRotate: "",
                  nameTextStyle: {
                    color: "#ccc",
                    align: "right",
                  },
                  type: "category",
                  axisLabel: {
                    color: "#ffffff",
                    formatter: function (value) {
                      return value.length > 8 ? value.slice(0, 8) + "..." : value;
                    },
                  },
                  axisLine: { show: true, lineStyle: { color: "#36445f" } },
                  axisTick: { show: false },
                }, {
                  name: '数量：个',
                  type: 'category',
                  position: 'right',
                  offset: 0,
                  nameGap: 0,
                  nameTextStyle: {
                    color: '#fff'
                  },
                  axisLine: {
                    show: false
                  },
                  axisLabel: {
                    show: true,
                    color: '#fff'
                  }
                }
              ],
            };

            const legends = d.legend_data.length;
            option.yAxis[0].data = d.yAxis_data;
            option.yAxis[1].data = d.series[legends];
            option.series = d.series.slice(0, legends).map((item, index) => {
              const res = {
                name: d.legend_data[index],
                type: "bar",
                stack: "total",
                barMaxWidth: 18,
                emphasis: {
                  focus: "series",
                },
                data: item,
              };

              return res;
            });

            this.dangerLevelsChart = applyUpdate(chart, option);
          })
          // eslint-disable-next-line
          .catch((e) => console.error(e.msg || e.message));
    },
    changeChart(mode){
      this.mode = mode;
      nation.getSafetyWorkerTrainCollect(mode)
        .then(d => {
          // console.log(d);
          this.search_keys3=d.search_keys;
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option3.xAxis[0].data = d.xAxis_data;
          this.option3.series[0].data = d.series;
          // eslint-disable-next-line no-empty
          if (this.mode === 1) {
            this.option3.series[0].name = "完成率";
            this.option3.yAxis[0].name = "完成率：%";
            this.option3.yAxis[0].max = 100;
            // eslint-disable-next-line no-empty
          } else if (this.mode === 2) {
            this.option3.series[0].name = "合格率";
            this.option3.yAxis[0].name = "合格率：%";
            this.option3.yAxis[0].max = 100;
          } else{
            // eslint-disable-next-line no-empty
            this.option3.series[0].name = "次数";
            this.option3.yAxis[0].name = "次数：次";
            this.option3.yAxis[0].max = undefined;
          }
          // eslint-disable-next-line no-empty
          if (d.config.theta) {
            this.option3.series[0].markLine.data[0].yAxis = d.config.theta;
          }
          this.sevenDayAlarmsLineChart = applyUpdate(chart, this.option3);
        })
    },
    
    sevenDayAlarmsLineChartClick(params) {
      if (this.districtScale !== 2) {
        this.popupSelect = this.mode;
        this.popupShow3 = true;
        this.popupTitle3 = params.name + "教育情况";
        this.activeIndex3=params.dataIndex;
        this.getPop3Data();
      }
    },
    getAttendance(popupSelect) {
      this.popupSelect = popupSelect;
      this.getPop3Data();
    },
    getPop3Data() {
      nation.getWorkerTrainDetailCollect(this.popupSelect,1, 100,this.districtScale==0&&this.orgType == 1?this.search_keys3[this.activeIndex3]:'',this.districtScale==1||this.orgType !== 1?this.search_keys3[this.activeIndex3]:'').then((d) => {
        // console.log(d,'---------');
        this.sevenDayAlarmsTable = d;
        this.avgAttendanceMax = Math.max.apply(
          null,
          this.sevenDayAlarmsTable.map((item) => item.count)
        );
      });
    },
  },
};
</script>

<style scoped lang="less">
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
.linebar {
  width: 100%;
}
.line-bar {
  display: flex;
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #57c7b6;
  border-radius: 12px;
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>