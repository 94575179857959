import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { OBJLoader2, MtlObjBridge } from 'wwobjloader2';

const cache = {};

function cloneMaterials(node) {
    for (let i = 0; i < node.children.length; i++) {
        if (node.children[i].type == 'Mesh') {
            if (!Array.isArray(node.children[i].material)) {
                node.children[i].material = node.children[i].material.clone();
            } else {
                for (let i = 0; i < node.children[i].material.length; i++) {
                    node.children[i].material[i] = node.children[i].material[i].clone();
                }
            }
        } else if (node.children[i].type == 'Group') {
            cloneMaterials(node.children[i]);
        }
    }
}

function cloneObject(object3d) {
    const object = object3d.clone();
    cloneMaterials(object);
    return object;
}

export function loadModel(objPath, mtlPath, callbackOnLoad, scene) {
    const key = objPath + '/' + mtlPath;
    const realCallbackOnLoad = function(object3d) {
        if (!cache[key]) {
            cache[key] = object3d;
        }

        const object = cloneObject(object3d);
        if (callbackOnLoad) {
            callbackOnLoad(object);
        } else {
            scene.add(object);
        }
    };

    if (cache[key]) {
        realCallbackOnLoad(cache[key]);
    } else {
        const objLoader2 = new OBJLoader2();

        const onLoadMtl = function ( mtlParseResult ) {
            objLoader2.setModelName(objPath);
            objLoader2.setLogging(true, true);
            objLoader2.materialStore.addMaterials(MtlObjBridge.addMaterialsFromMtlLoader(mtlParseResult), true);
            objLoader2.load(objPath, realCallbackOnLoad, null, null, null);
        };

        const mtlLoader = new MTLLoader();
        mtlLoader.load(mtlPath, onLoadMtl);
    }
}
