<template>
  <div id="left">
    <ChiefComplaintCaseChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <CaseLoadSumChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
  </div>
</template>

<script>
  import ChiefComplaintCaseChart from "./chiefComplaintCaseChart";
  import  CaseLoadSumChart from "./caseLoadSumChart";

  export default {
    name: 'LawIndexLeftBar',
    components: {
      ChiefComplaintCaseChart,
      CaseLoadSumChart
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
  }
</script>

<style lang="less">
</style>
