<template>
  <div id = "left">
    <div class="info-block" style="min-height:5rem">
    <div class="info-block-title"  style="display:flex;width:100%;padding-right:3.1rem">
      <div style = "flex:1">{{chartTitle}}</div>
      <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '1' }"
              @click="() => switchAvgAttendanceTopListOption('1')"
            >项目资金情况</div>
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '2' }"
              @click="() => switchAvgAttendanceTopListOption('2')"
            >投资项目数据</div>
            <div
              class="detail"
              style="margin-left: 0.5rem;"
              @click=" showDetail()"
            >
              详情
            </div>
          </div>
    </div>
    <div style="height:20rem;padding-top:3rem">
      <div v-if="Object.keys(subData).length !==0 && !Object.keys(subData2).length !==0" style="display:flex">
        <table style="width:26rem;height:15rem" rules=rows>
          <tr>
            <td><span class="linetitle">截止本月</span><br/><br/>
              <span  class="linetitle2">有可用资金项目</span>
              <p><span class="val1">{{toFixed(subData.balanceProj.amount,2)}}</span>亿
              <span class="val2" style="color:#F4EA27">{{subData.balanceProj.projCount}}</span>项目</p>
            </td>
            <td><span class="linetitle"></span><br/><br/>
              <span  class="linetitle2">欠公司资金项目</span>
              <p><span class="val3">{{toFixed(subData.unpaidProj.amount,2)}}</span>亿
                <span class="val2">{{subData.unpaidProj.projCount}}</span>项目</p>
            </td>
          </tr>
          <tr>
            <td><span class="linetitle">未来净流</span><br/><br/>
            <span  class="linetitle2">流入资金项目</span>
            <p><span class="val1">{{toFixed(subData2.inflowProj.amount,2)}}</span>亿
              <span class="val2" style="color:#F4EA27">{{subData2.inflowProj.projCount}}</span>项目
            </p>
            </td>
            <td><span class="linetitle"></span><br/><br/>
              <span class="linetitle2">流出资金项目</span>
            <p>
              <span class="val3">{{toFixed(subData2.outFlowProj.amount,1)}}</span>亿
                <span class="val2">{{subData2.outFlowProj.projCount}}</span>项目
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div  id ="touzi" v-if="Object.keys(touziData).length !==0" >
      <div style="position:absolute;left:4rem;top:4rem;display:flex">
          <div id="yjzsr">
            <div style="font-size:1.2rem;font-weight:bold;color:#25faf3">{{toFixed(touziData.expectedIncome,2)}}亿</div>
            <div style="font-size:0.8rem;margin-top:0.3rem">预计总收入</div>
          </div>
          <div id="xmzsy">
            <div style="font-size:1.2rem;font-weight:bold;color:#27e6ab">{{toFixed(touziData.totalIncome,2)}}亿</div>
            <div style="font-size:0.8rem;margin-top:0.3rem">项目总收益</div>
          </div>
          
      </div>
      <div style="position:absolute;left:7rem;top:12rem;display:flex">
        <div id="ljjl">
            <div style="font-size:1.2rem;font-weight:bold;color:#25faf3">{{toFixed(touziData.monthAccNetFlow,2)}}亿</div>
            <div style="font-size:0.8rem;margin-top:0.3rem">截至本月累计净流</div>
          </div>
          <div id="wljl">
            <div style="font-size:1.2rem;font-weight:bold;color:#27e6ab">{{toFixed(touziData.projFutureNetFlow,2)}}亿</div>
            <div style="font-size:0.8rem;margin-top:0.3rem">未来净流</div>
          </div>
      </div>
    </div>
    <!-- 项目资金情况详情 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <el-tabs id ="tabs">
                <el-tab-pane label="项目资金情况">
                  <el-table style="background-color:#0E1328" height="21rem" :data="tableData1" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column
                        prop="branchName"
                        label="分公司">
                        </el-table-column>
                    <el-table-column
                        prop="assessUnit"
                        label="考核分类机构">
                    </el-table-column>
                    <el-table-column
                        label="有可用资金项目">
                        <el-table-column
                          prop="keyongProjCount"
                          label="项目个数">
                        </el-table-column>
                        <el-table-column
                          prop="keyongAmount"
                          label="可用资金余额">
                          <template slot-scope="scope" slot="header">
                                <span>
                                    可用资金余额
                                <el-tooltip :aa="scope" class="item" effect="dark" content="项目交完收益后还有剩余资金可用" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                        label="欠公司资金项目">
                      <el-table-column
                          prop="oweProjCount"
                          label="项目个数">
                        </el-table-column>
                        <el-table-column
                          prop="oweAmount"
                          label="欠公司资金余额">
                          <template slot-scope="scope" slot="header">
                                <span>
                                    欠公司资金余额
                                <el-tooltip :aa="scope" class="item" effect="dark" content="项目欠交公司收益或公司垫资或两者都有" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane label="项目净流情况">
                  <el-table style="background-color:#0E1328" height="21rem" :data="tableData2" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column
                        prop="branchName"
                        label="分公司">
                        </el-table-column>
                    <el-table-column
                        prop="assessUnit"
                        label="考核分类机构">
                    </el-table-column>
                    <el-table-column
                        label="资金流入项目">
                        <el-table-column
                          prop="inflowProjCount"
                          label="项目个数">
                        </el-table-column>
                        <el-table-column
                          prop="inflowAmount"
                          label="未来流入资金金额">
                          <template slot-scope="scope" slot="header">
                                <span>
                                    未来流入资金金额
                                <el-tooltip :aa="scope" class="item" effect="dark" content="未来收款大于支出" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                        label="资金流出项目">
                      <el-table-column
                          prop="outFlowProjCount"
                          label="项目个数">
                        </el-table-column>
                        <el-table-column
                          prop="outFlowAmount"
                          label="未来流出资金金额">
                          <template slot-scope="scope" slot="header">
                                <span>
                                    未来流出资金金额
                                <el-tooltip :aa="scope" class="item" effect="dark" content="未来支出大于收款" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
              </el-tabs>
            </div>
        </div>
      </wrapper>
    </el-dialog>
    <!-- 投资项目详情 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow2=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <el-table style="background-color:#0E1328" height="23rem" :data="projData" 
                    :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column type="index" :index="getIndex" label="序号">
                    </el-table-column>
                      <el-table-column
                            prop="branchName"
                            label="分公司">
                        </el-table-column>
                        <el-table-column
                            prop="projName"
                            label="项目名称">
                        </el-table-column>
                        <el-table-column
                            prop="expectedIncome"
                            label="项目预计总收入"
                              :formatter="Formatter">
                        </el-table-column>
                        <el-table-column
                            prop="totalIncome"
                            label="项目总收益"
                              :formatter="Formatter">
                        </el-table-column>
                        <el-table-column
                            prop="monthAccNetFlow"
                            label="截止本月累计净流"
                              :formatter="Formatter">
                        </el-table-column>
                        <el-table-column
                            prop="projFutureNetFlow"
                            label="项目未来净流"
                              :formatter="Formatter">
                        </el-table-column>
                    </el-table>
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :current-page.sync="currentPage"
                      :page-count="pageSize"
                      :total="totalCount"
                      @current-change="pageChange"
                      style="text-align:center;margin-top: 0.5rem;"
                    ></el-pagination>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
  </div>
</template>

<script>
  require('echarts-gl')
  // import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { finance } from '../../../../api_finance'
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'ProjectFundDataChart',
    components: {
      wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerLevelChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerLevelChart();
        }
      }
    },
    data () {
      return {
        dangerLevelsChart: {},
        subData:{},
        subData2:{},
        touziData:{},
        popupShow:false,
        popupShow2:false,
        popProjectsList:[],
        mode:"1",
        tableData1:[],
        tableData2:[],
        chartTitle:"项目资金情况",
        projData:[],
        currentPage:1,
        pageSize:10,
        totalCount:0
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      
      this.updateSafetyDangerLevelChart();
    },
    methods: {
      getIndex(index){
        return (this.currentPage-1)*this.pageSize+index+1;
      },
      pageChange(curPage){
        this.currentPage = curPage;
        this.getTouziData();
      },
      getTouziData(){
        finance.queryProjCapitalStatistic("",this.currentPage,this.pageSize).then((d=>{
          this.totalCount = d.totalRecords;
          this.projData = d.data;
        }))
      },
      tableRowStyle({rowIndex}){
        if(rowIndex%2 === 0)
            return {backgroundColor:'#213a77',height: '0'}; 
        else   
            return {backgroundColor:'#102855',height: '0'}; 
      },
      tableHeaderColor(){
        return 'color:#4fb5da;background:#102855';
      },
      switchAvgAttendanceTopListOption(option){
        this.mode = option;
        if(option == "1"){
          this.chartTitle = "项目资金情况";
          this.updateSafetyDangerLevelChart();
        }else{
          this.chartTitle = "投资项目数据";
          this.updateInvestmentProject();
        }
      },
      showDetail(){
        if(this.mode == "1"){
          this.popupShow = true;
          this.updateSafetyDangerLevelChart();
          this.tableData1 =[],this.tableData2 =[];
          this.tableData1.push({
            "branchName":this.subData.branchName,
            "assessUnit":this.subData.assessUnit,
            "keyongProjCount":this.subData.balanceProj.projCount,
            "keyongAmount":this.toFixed(this.subData.balanceProj.amount,2),
            "oweProjCount":this.subData.unpaidProj.projCount,
            "oweAmount":this.toFixed(this.subData.unpaidProj.amount,2)
          });
          this.tableData2.push({
            "branchName":this.subData2.branchName,
            "assessUnit":this.subData2.assessUnit,
            "inflowProjCount":this.subData2.inflowProj.projCount,
            "inflowAmount":this.toFixed(this.subData2.inflowProj.amount,2),
            "outFlowProjCount":this.subData2.outFlowProj.projCount,
            "outFlowAmount":this.toFixed(this.subData2.outFlowProj.amount,2)
          });
        }else{
          this.popupShow2 = true;
          this.getTouziData();
        }
      },
       Formatter(row, column, cellValue){
            return finance.formatDecimal(cellValue,2);
        },
      toFixed(value,ptn){
        return finance.formatDecimal(value,ptn);
      },
      updateInvestmentProject(){
        finance.queryProjCapitalStatisticSum(2022).then((res=>{
          this.subData = {};
          this.subData2 ={};
          this.touziData = res;
        }))
      },
      updateSafetyDangerLevelChart() {
        this.touziData = {};
        finance.queryProjFutureNetFlowSum().then((res=>{
          this.subData2  =res;
        }))
        finance.queryProjCapitalSum().then((res)=>{
          this.subData = res;
        })
      },
    },
  }
</script>

<style lang="less">
div > dd{
  margin-top:1rem;
}
.head{
  margin-top:2.5rem;
}
table > tr > td{
  font-size:1rem;
}
.val1{
  font-size:2rem;
  font-weight:400 ;
  color:#F4EA27
}
.val2{
  font-size:1rem;
  margin-left:10px;
  font-weight:400 ;
  color:#CB4F43
}
.val3{
  font-size:2rem;
  font-weight:400;
  color:#CB4F43
}
.linetitle{
  font-size:1.1rem;
  font-weight: bold;
  color:#A0E5FB;
}
#yjzsr,#ljjl {
    width: 6.5rem;
    height: 6.5rem;
    background: url("../../../../assets/nation/在施项目数.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #xmzsy,#wljl {
    width: 6.5rem;
    height: 6.5rem;
    margin-left: 40px;
    background: url("../../../../assets/nation/出勤率.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #tabs{
    background: #0e1328;
  }
</style>
