<template>
  <div id="right">
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div class="info-block-title">项目工期延误天数</div>
      <div v-if="workerTypePieChart.length" style="position:absolute;margin-top:3.2rem;font-size:12px;color:#fff;padding-left:20px;">延误天数：天</div>
      <div style="height:18rem;padding-top:5rem">
        <!-- <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"><CustomSelect :options="companyNameList" @input="companySelect" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold" /></div> -->
        <!-- <v-chart :options="attendanceBarChart" autoresize /> -->
        <div style="height:100%;overflow-y:auto">
        <div
          v-if="!workerTypePieChart.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
          <div
            class="linebar"
            v-for="(item, index) in workerTypePieChart"
            :key="index"
          >
            <div style="color: #A7B6BC;width: 180px">
              {{item.name}}
            </div>
            <div
              style="flex: 5"
              class="line-bar"
            >
              <!-- <div
                class="real-bar"
                :style="{'width': (Math.min(item.value / 365, 1) * 100) + '%' }"
                style="background-color: #5BD0BF;"
              ></div> -->
              <div
                class="real-bar"
                :style="{'width': ((item.value / avgAttendanceMax) * 100) + '%' }"
                style="background-color: #5BD0BF;"
              ></div>
            </div>
            <div style="color: #5BD0BF;width:50px">
              {{item.value}}天
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>实际劳动力与计划对比详情</div>
        <div
          class="detail"
          @click="showDetail(1)"
        >
          详情
        </div>
      </div>
      <div style="height:18rem;padding-top:3rem">
        <div style="display:flex;justify-content:flex-end;">
          <CustomSelect
            :options="companyNameList"
            :value="selectedVal1"
            @input="companySelect($event, 'val1')"
            style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;"
          />
        </div>
        <div
          v-if="workerAgePieChart.series && [].concat.apply([], workerAgePieChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="workerAgePieChart"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>各{{districtScale == 2 ? '项目':((districtScale === 1 || orgType === 2) ? '城市' : '分公司')}}劳动力下阶段余缺统计</div>
        <div
          class="detail"
          @click="showDetail(2)"
        >
          详情
        </div>
      </div>

      <div style="height:18rem;padding-top:3rem;">
        <div class="chart-title" v-if="!(workerSurplusChart.series && [].concat.apply([], workerSurplusChart.series.map(x => x.data)).length == 0)">
          <div>缺</div>
          <div style="margin-left: 4rem">余</div>
        </div>
        <div
          v-if="workerSurplusChart.series && [].concat.apply([], workerSurplusChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="workerSurplusChart"
          @click="workerSurplusChartClick"
          autoresize
        />
      </div>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      :destroy-on-close='true'
      width="90rem"
      top="12rem"
    >
      <wrapper
        style="height:42rem;backgroundColor:#0e1328;position:relative"
        class="popup-bg"
      >
        <div
          class="stats-close-btn"
          @click="popupShow = false"
        />
        <div class="attendance">
          <div class="attendance-top">
            <div class="tabbar">
              <div
                :class="{ 'tabar-select': popupSelect == '1' }"
                @click="getAttendance('1')"
              >按月</div>
              <div
                :class="{ 'tabar-select': popupSelect == '2' }"
                @click="getAttendance('2')"
              >按周</div>
              <div
                :class="{ 'tabar-select': popupSelect == '3' }"
                @click="getAttendance('3')"
              >按日</div>
            </div>
            <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem;">
              <CustomSelect
                :options="companyNameList"
                :value="selectedVal2"
                @input="companySelect($event, 'val2')"
                style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;"
              />
            </div>
            <div style="border:1px solid #1a3281;padding:1rem 2rem">

              <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">平均计划/实际劳动力
              </div>
              <div style="height:16rem;padding-top:1rem">
                <v-chart
                  ref="laborCompareCollect"
                  :options="laborCompareCollect"
                  @click="attenceWorkersBarChartClick"
                  autoresize
                />
              </div>
            </div>
          </div>
          <div class="attendance-bottom">
            <div style="text-align:center;font-size: 18px;margin-top: 1rem;">{{popupBottomName}}</div>
            <div style="margin:1rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:5rem;text-align:center">序号</div>
                <div style="width:15rem;text-align:center">分公司</div>
                 <div style="width:15rem;text-align:center" v-if="selectedVal2 !== '全部分公司'">项目</div>
                <div style="width:8rem;text-align:center">实际计划比</div>
                <div style="width:15rem;text-align:center">备注/解决方案</div>
              </div>
              <div style="height:10rem;overflow:auto;">
                <div
                  v-for="(item, index) in popManagementTopProjects"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:5rem;text-align:center">
                    {{index+1}}
                  </div>
                  <div style="width:15rem;text-align:center">{{item.org_name}}</div>
                  <div style="width:15rem;text-align:center" v-if="selectedVal2 !== '全部分公司'">{{item.project_name}}</div>
                  <div
                    style="width:8rem;text-align:center"
                    :style="{ color: item.value<1 ? '#E02F37' : '#1bd080'}"
                  >{{item.value}}</div>
                  <div style="width:15rem;color: #ffbd1d;text-align:center">{{item.note}}</div>
                </div>
                <div
                  v-if="!(popManagementTopProjects && popManagementTopProjects.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无记录
                </div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupToShow"
      :destroy-on-close='true'
      width="70rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:38rem;"
      >
        <div style="display:flex;margin-top:1rem;">
          <div
            class="stats-chart-block"
            style="height:16rem;width:86.25rem;"
          >
            <div
              class="stats-close-btn"
              @click="popupToShow = false"
            />

            <div class="attendance">
              <div class="attendance-top">

                <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTotitle}}下阶段劳动力：
                  <span style="color:#E02F37">缺{{popupToMin}}</span>
                  <span style="color:#34C59E;margin-left:1rem">盈{{popupToMax}}</span>
                </div>
                <div style="padding:1rem;display:flex;width:100%;justify-content: space-between;">
                  <div style="height:18rem;width:30rem;border:1px solid #1a3281;">
                    <div style="margin:1rem 1rem 0 1rem;font-size:18px;font-weight:bold">短缺工种分布：
                    </div>
                    <v-chart
                      ref="machineTrouble"
                      :options="machineTrouble"
                      @mouseover="machineTroubleMouseOver"
                      @mouseout="machineTroubleMouseOut"
                      autoresize
                    />
                  </div>
                  <div style="height:18rem;width:30rem;border:1px solid #1a3281;">
                    <div style="margin:1rem 1rem 0 1rem;font-size:18px;font-weight:bold">盈余工种分布：
                    </div>
                    <v-chart
                      ref="machineTrouble2"
                      :options="machineTrouble2"
                      @mouseover="machineTroubleMouseOver2"
                      @mouseout="machineTroubleMouseOut2"
                      autoresize
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style="display:flex;margin-top:1rem">

              <div style="margin: 0 1rem;font-size:18px;font-weight:bold">各项目盈缺详情：
              </div>
              <div style="display:flex;justify-content:flex-end;margin-bottom:0.5rem;margin-right:0.8rem">
                <CustomSelect
                  :options="overDownList"
                  @input="popToSelect($event, 1)"
                  style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
                />
              </div>

              <div style="display:flex;justify-content:flex-end;margin-bottom:0.5rem">
                <CustomSelect
                  :options="workTypeList"
                  @input="popToSelect($event, 2)"
                  style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
                />
              </div>
              <div style="display:flex;justify-content:flex-end;margin-left:0.8rem;margin-bottom:0.5rem">
                <CustomSelect
                  :options="statusList"
                  @input="popToSelect($event, 3)"
                  style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
                />
              </div>
            </div>
            <div style="margin:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:15rem;">项目名称</div>
                <div style="width:8rem;text-align:center">盈缺劳动力</div>
                <div style="width:8rem;text-align:center">工种</div>
                <div style="width:10rem;text-align:center">时间</div>
                <div style="width:12rem;text-align:center">状态</div>
                <div style="width:6rem;text-align:center">操作</div>
              </div>
              <div style="height:6rem;overflow:auto;">
                <div
                  v-for="(item, index) in projectLaborDetail"
                  :key="item.worker_id"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem">
                    <div style="width:15rem;">{{item.project_name}}</div>
                    <div style="width:8rem;text-align:center" :style="{'color': item.label==1 ? '#C23146':'#44C07C'}">
                      <!-- {{item.label === 1 ? '缺':'余'}} -->
                      {{item.result}}
                    </div>
                    <div style="width:8rem;text-align:center">{{item.worker_type_name}}</div>
                    <div style="width:10rem;text-align:center">{{item.date_range}}</div>
                    <div style="width:12rem;text-align:center">{{item.status === 2 ? '已调度':(item.status ? '已解决':'未解决')}}</div>
                    <div style="width:6rem;text-align:center">
                      <div
                        v-if="item.status === 1"
                        class="show" style="margin: auto"
                        @click="showResult(item, index)"
                      >查看</div>
                    </div>
                  </div>
                  <div
                    style="background-color: #051B49;color: #E2CA65;padding: 1rem 0 1rem 1rem"
                    v-if="item.status && item.isResult"
                  >
                    解决方案：{{item.solution | '暂无'}}
                  </div>
                </div>
                <div
                  v-if="!(projectLaborDetail && projectLaborDetail.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无共建方情况
                </div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupThreeShow"
      :destroy-on-close='true'
      width="80rem"
      top="6rem"
    >
      <wrapper
        class="popup-bg"
        style="height:56rem;"
      >
        <div style="display:flex;">
          <div
            class="stats-chart-block"
            style="height:16rem;width:100%;"
          >
            <div
              class="stats-close-btn"
              @click="popupThreeShow = false"
            />

            <div class="attendance">
              <div style="padding:1rem;display:flex;width:100%;justify-content: space-between;">
                <div style="height:18rem;width:25rem;border:1px solid #1a3281;padding:0 1rem">
                  <div style="display:flex;justify-content:space-between;align-items: center;margin-bottom:0.5rem">

                    <div style="margin:1rem 0;font-size:18px;font-weight:bold">盈缺人数分布：
                    </div>

                    <CustomSelect
                      :options="companyNameList"
                      :value="selectedPop4"
                      @input="popToSelect($event, 4)"
                      style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
                    />
                  </div>
                  <v-chart
                    :options="popThreeOption1"
                    autoresize
                  />
                </div>
                <div style="height:18rem;width:25rem;border:1px solid #1a3281;padding:0 1rem">
                  <div style="display:flex;justify-content:space-between;align-items: center;margin-bottom:0.5rem">

                    <div style="margin:1rem 0;font-size:18px;font-weight:bold">各工种短缺人数分布：
                    </div>

                    <CustomSelect
                      :options="companyNameList"
                      :value="selectedPop5"
                      @input="popToSelect($event, 5)"
                      style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
                    />
                  </div>
                  <v-chart
                    :options="popThreeOption2"
                    ref="popThreeOption2Ref"
                    @click="popThreeOption2RefClick"
                    @mouseover="popThreeOption2MouseOver"
                    @mouseout="popThreeOption2MouseOut"
                    autoresize
                  />
                </div>
                <div style="height:18rem;width:25rem;border:1px solid #1a3281;padding:0 1rem">
                  <div style="margin:1rem 1rem 0 1rem;font-size:18px;font-weight:bold">各工种盈余人数分布：
                  </div>
                  <v-chart
                    :options="popThreeOption3"
                    ref="popThreeOption3Ref"
                    @click="popThreeOption3RefClick"
                    @mouseover="popThreeOption3MouseOver"
                    @mouseout="popThreeOption3MouseOut"
                    autoresize
                  />
                </div>
              </div>
            </div>
            <div class="attendance">
              <div style="margin-left:1rem;font-size:18px;font-weight:bold">{{popThreeMode==1?'短缺':'盈余'}}{{workNameType}}：{{workNameValue}}人
              </div>
              <div style="padding:1rem;display:flex;width:100%;justify-content: space-between;">
                <div style="height:18rem;width:100%;border:1px solid #1a3281;padding:0 1rem">
                  <div style="position: relative;top: 1rem;font-size:18px;font-weight:bold">{{popThreeMode==1?'短缺':'盈余'}}{{workNameType}}各分公司分布：
                  </div>
                  <v-chart
                    :options="popThreeOption4"
                    @mouseover="popThreeOption4MouseOver"
                    @mouseout="popThreeOption4MouseOut"
                    @click="popThreeOption4RefClick"
                    ref="popThreeOption4Ref"
                    autoresize
                  />
                </div>
              </div>
            </div>
            <div style="display:flex;margin-top:1rem">
              <div style="margin: 0 1rem;font-size:18px;font-weight:bold">各项目{{popThreeMode==1?'短缺':'盈余'}}{{workNameType}}详情：
              </div>
              <div style="display:flex;justify-content:flex-end;margin:0 1rem 0.5rem 0">
                <CustomSelect
                  :options="popThreeStatusList"
                  @input="poStatusSelect"
                  style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
                />
              </div>
              <div style="display:flex;justify-content:flex-end;margin-bottom:0.5rem">
                <CustomSelect
                  :options="companyNameList"
                  :value="selectedPop6"
                  @input="popToSelect($event, 6)"
                  style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
                />
              </div>
            </div>
            <div style="margin:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:8rem">分公司</div>
                <div style="width:15rem;text-align:center">项目名称</div>
                <div style="width:8rem">缺盈{{workNameType}}数</div>
                <div style="width:10rem">时间</div>
                <div style="width:12rem">状态</div>
                <div style="width:6rem">操作</div>
              </div>
              <div style="height:6rem;overflow:auto;">
                <div
                  v-for="(item, index) in popThreeTable"
                  :key="item.worker_id"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem">
                    <div style="width:8rem;">
                      {{item.org_name}}
                    </div>
                    <div style="width:15rem;text-align:center">{{item.project_name}}</div>
                    <div style="width:8rem" :style="{'color': popThreeMode==1 ? '#C23146':'#44C07C'}">{{item.result}}</div>
                    <div style="width:10rem;">{{item.date_range}}</div>
                    <div style="width:12rem;">{{item.status === 2 ? '已调度':(item.status ? '已解决':'未解决')}}</div>
                    <div style="width:6rem;">
                      <div
                        v-if="item.status === 1"
                        class="show"
                        @click="showResult2(item, index)"
                      >查看</div>
                    </div>
                  </div>

                  <div
                    style="background-color: #051B49;color: #E2CA65;padding: 1rem 0 1rem 1rem"
                    v-if="item.status && item.isResult"
                  >
                    解决方案：{{item.solution | '暂无'}}
                  </div>
                </div>
                <div
                  v-if="!(popThreeTable && popThreeTable.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation, project } from "../../api";
// import { attendancePieChartTemplate, colors } from './pieChartTemplates';
import { singleLineChartTemplate } from "./lineChartTemplates";
import { applyUpdate, truncateStr } from "../../utils";
import CustomSelect from "../../components/select";
import { barNegativeChartTemplate } from "./barChartTemplates";
import { attendancePieChartTemplate } from "./pieChartTemplates";
import { areaPieChartTemplate } from "./pieChartTemplates.js";
import wrapper from "../../components/wrap";

export default {
  name: "ScheduleRightBar",
  components: {
    "v-chart": ECharts,
    CustomSelect: CustomSelect,
    wrapper,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    orgOptions: Array,
    districtScale: Number,
    orgType: Number,
  },
  data() {
    return {
      popupShow: false,
      popupSelect: "1",
      popupToShow: false,
      popupTotitle: "",
      popupToMin: 0,
      popupToMax: 1,
      popupThreeShow: false,
      machineTrouble: {},
      machineTrouble2: {},
      option4: {
        legend: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["30%", "60%"],
            center: ["50%", "40%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function (params) {
                  return `{value|${Math.round(params.percent)}%}\n{text|${
                    params.name
                  }}`;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },
      option5: {
        legend: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["30%", "60%"],
            center: ["50%", "40%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function (params) {
                  return `{value|${Math.round(params.percent)}%}\n{text|${
                    params.name
                  }}`;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },
      overDownList: ["全部盈缺", "缺", "余"],
      overDownId: "",
      workTypeList: ["全部工种"],
      statusList: ["全部状态", "未解决", "已解决", "已调度"],
      overDownIdList: ["", "1", "2", "3"],
      workTypeIdList: [""],
      workTypeId: "",
      statusIdList: ["1", "2"],
      statusId: "",
      projectLaborDetail: [],

      popThreeStatus: "",
      popThreeCompany: "",
      popThreeStatusList: ["全部状态", "未解决", "已解决", "已调度"],
      popThreeStatusIdList: ["", 1, 2, 3],
      popThreeOption1: {},
      popThreeOption1Template: {
        color: ["#E02F37", "#34C59E"],
        legend: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["0%", "60%"],
            center: ["50%", "40%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                // position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function () {
                  return ``;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },
      popThreeTable: [],
      popThreeMode: 1,
      workNameType: "",
      workNameValue: 0,
      popThreeOption2HighlightIndex: 0,
      popThreeOption2SearchKeys: [],
      popThreeOption2: {},
      popThreeOption2Template: {
        legend: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["30%", "60%"],
            center: ["50%", "40%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function (params) {
                  return `{value|${Math.round(params.percent)}%}\n{text|${
                    params.name
                  }}`;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },
      popThreeOption3HighlightIndex: 0,
      popThreeOption3SearchKeys: [],
      popThreeOption3: {},
      popThreeOption3Template: {
        legend: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["30%", "60%"],
            center: ["50%", "40%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function (params) {
                  return `{value|${Math.round(params.percent)}%}\n{text|${
                    params.name
                  }}`;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },
      popThreeOption4HighlightIndex: 0,
      popThreeOption4: {},
      popThreeOption4List: [],
      popThreeOption4Template: {
        legend: {
          orient: "vertical",
          x: "left",
          left: "75%",
          top: "middle",
          itemHeight: 12,
          itemGap: 6,
          textStyle: {
            fontSize: 12,
            color: "#ffffff",
          },
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "25%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["40%", "70%"],
            // radius: [20, 100],
            // roseType: "radius",
            center: ["40%", "50%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function (params) {
                  return `{value|${Math.round(params.percent)}%}\n{text|${
                    params.name
                  }}`;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },

      popupBottomName: "",
      popupXAxisData: [],
      popupSearchkeys: [],
      popupData: [],
      popManagementTopProjects: [],
      option3: {
        color: ["#1bd080", "#53aeef"],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "circle",
          top: "2%",
          right: "50%",
          data: [],
        },
        grid: {
          left: "2%",
          right: "0%",
          top: "15%",
          bottom: "1%",
          containLabel: true,
        },
        yAxis: [
          {
            name: "人数：人",
            nameTextStyle: {
              color: "#fff",
              align: "right",
            },
            type: "value",
            show: true,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: [5, 10],
                color: "#14253f",
                width: 2,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontSize: 14,
              fontWeight: "bold",
              // formatter: function(value) {
              //   return value ? value + '%' : value;
              // }
            },
          },
        ],
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: true,
          splitNumber: 7,
          axisLabel: {
            color: "#e6f2f3",
            fontSize: 14,
            fontWeight: "bold",
            rotate: 30,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#22344b",
            },
          },
        },
        series: [],
      },
      laborCompareCollect: {},
      companyNameList: [""],
      companyIdList: [""],
      selectedVal1: '',
      selectedVal2: '',
      selectedPop4: '',
      selectedPop5: '',
      selectedPop6: '',
      provinceWorkers: [],
      provinceWorkersMax: 1,
      workerTypePieChart: [],
      workerAgePieChart: {},
      option: {
        color: ["#1bd080", "#ffbd1d", "#53aeef"],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "circle",
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 12,
          textStyle: {
            color: "#ccc",
            fontWeight: "bold",
            fontSize: "11",
          },
          padding: [8, 0, 0, 0],
          top: "4%",
          // right: '3%',
          data: [],
        },
        grid: {
          left: "0%",
          right: "0%",
          top: "15%",
          bottom: "1%",
          containLabel: true,
        },
        yAxis: [
          {
            name: "人数：人",
            nameTextStyle: {
              color: "#fff",
              padding: [0, 0, 0, 15]
            },
            type: "value",
            show: true,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: [5, 10],
                color: "#14253f",
                width: 2,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontSize: 14,
              fontWeight: "bold",
              // formatter: function(value) {
              //   return value ? value + '%' : value;
              // }
            },
          },
        ],
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: true,
          splitNumber: 7,
          axisLabel: {
            color: "#e6f2f3",
            fontSize: 14,
            fontWeight: "bold",
            rotate: 30,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#22344b",
            },
          },
        },
        series: [
          //   {
          //   name: '',
          //   type: 'line',
          //   itemStyle: {
          //     normal: {
          //       lineStyle: {
          //         width: 2
          //       }
          //     }
          //   },
          //   areaStyle: {
          //     color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
          //       {offset: 0, color: 'rgba(27, 208, 128, 0.5)'},
          //       {offset: 1, color: 'rgba(27, 208, 128, 0)'}
          //     ])
          //   },
          //   symbolSize: 0,
          //   data: [],
          // }
        ],
      },
      workerTypePieChartHighlightIndex: 0,
      workerAgePieChartHighlightIndex: 0,
      org_id: "",
      workerSurplusChart: {},
      option2: {
        color: ["#E02F37", "#34C59E"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: params => `${params[0].name}<br>${params[0].seriesName}: ${-params[0].value}<br>${params[1].seriesName}: ${params[1].value}`
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "3%",
          top: "13%",
          containLabel: true,
        },
        dataZoom: [],
        xAxis: [
          {
            type: "value",
            show:false,
          },
        ],
        yAxis: [
          {
            name: '人数：人',
            nameTextStyle: {
              color: "#cad6d9",
              padding: [0, 480, 0, 0]
            },
            type: "category",
            axisTick: {
              show: false,
            },
            offset: 30,
            data: [],
            axisLabel: {
              show: true,
              interval: 0,
              textStyle: {
                color: "#cad6d9",
                fontSize: 12,
                fontWeight: "bold",
              },
              formatter: params => params.length > 7 ? params.slice(0, 7) + '...' : params,
              // rotate: 35,
            },
            axisLine: {
              show: false,
              lineStyle: { color: "#263851" },
            },
            boundaryGap: true,
            splitLine: {
              show: false,
            },
          },
          {
            nameTextStyle: {
              color: "#cad6d9",
              padding: [0, 380, 0, 0]
            },
            position: 'left',
            offset:-10,
            type: "category",
            axisTick: {
              show: false,
            },
            data: [],
            axisLabel: {
              show: true,
              formatter: params => `${(params/1000).toFixed(1)}k`,
              textStyle: {
                color: "#cad6d9",
                fontSize: 12,
                fontWeight: "bold",
              },
              // rotate: 35,
            },
            axisLine: {
              show: false,
              lineStyle: { color: "#263851" },
            },
            boundaryGap: true,
            splitLine: {
              show: false,
            },
          },
          {
            nameTextStyle: {
              color: "#cad6d9",
              padding: [0, 480, 0, 0]
            },
            type: "category",
            axisTick: {
              show: false,
            },
            offset:20,
            data: [],
            axisLabel: {
              show: true,
              formatter: params => `${(params/1000).toFixed(1)}k`,
              textStyle: {
                color: "#cad6d9",
                fontSize: 12,
                fontWeight: "bold",
              },
              // rotate: 35,
            },
            axisLine: {
              show: false,
              lineStyle: { color: "#263851" },
            },
            boundaryGap: true,
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: 10,
            stack: "Total",
            label: {
              show: false,
              position: "left",
              textStyle: {
                color: "#fff",
              },
              formatter: params => `${-params.value}`
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          },
          {
            name: "",
            type: "bar",
            stack: "Total",
            label: {
              show: false,
              position: "right",
              textStyle: {
                color: "#fff",
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          },
        ],
      },
      avgAttendanceMax: 0
    };
  },
  watch: {
    // orgOptions(newValue, oldValue) {
    //   if (newValue != oldValue) {
    //     this.companyNameList = newValue.map(item => truncateStr(item.org_name, 10));
    //     this.companyIdList = newValue.map(item => item.org_id);

    //     let idx = this.companyIdList.indexOf(this.orgLevel);
    //     if (idx == -1) {
    //       idx = 0;
    //     }
    //     this.companySelect(this.companyNameList[idx], 'val1')
    //   }
    // },
    orgLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        const idx = this.companyIdList.indexOf(newValue);
        if (idx >= 0) {
          this.companySelect(this.companyNameList[idx], 'val1');
        }

        this.getAll();
      }
    },
    districtLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.getAll();
      }
    },
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;

    this.companyNameList = this.orgOptions.map(item => truncateStr(item.org_name, 10));
    this.companyIdList = this.orgOptions.map(item => item.org_id);

    let idx = this.companyIdList.indexOf(this.orgLevel);
    if (idx == -1) {
      idx = 0;
    }
    this.selectedVal1 = this.companyNameList[idx];

    this.getAll();
  },
  methods: {
    showDetail(val) {
      const idx = this.companyIdList.indexOf(this.orgLevel);
      // eslint-disable-next-line no-empty
      if (val === 1) {
        this.selectedVal2 = this.companyNameList[idx];
        this.popupShow = true;
        this.org_id = this.orgLevel;
        this.getAttendance(this.popupSelect);
      } else if (val === 2) {
        this.selectedPop4 = this.companyNameList[idx];
        this.selectedPop5 = this.companyNameList[idx];
        this.selectedPop6 = this.companyNameList[idx];
        this.popupThreeShow = true;
        this.getPopThreeData(this.orgLevel);
      }
    },
    getPopThreeData(org_id) {
      this.getPopThreeEcharts1(org_id);
      this.getPopThreeEcharts2(org_id, 1);
      this.getPopThreeEcharts3("", this.popThreeMode);
      this.getPopThreeEcharts4(
        this.popThreeOption2SearchKeys[0],
        this.popThreeMode
      );
      this.getPopThreeTable(
        this.popThreeOption2SearchKeys[0],
        this.popThreeMode,
        this.popThreeStatus,
        org_id
      );
    },
    getPopThreeEcharts1(org_id) {
      project
        .getDashboardSurplusTotalCollect(org_id)
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
          this.popThreeOption1Template.series[0].data = d.data;

          this.popThreeOption1Template.series[0].label = {
            rich: {
              text: {
                fontSize: 11,
              },
            },
            formatter: function (params) {
              return `${params.name} ${params.value}人`;
            },
          };

          this.popThreeOption1 = applyUpdate(
            chart,
            this.popThreeOption1Template
          );
        })
        .catch((e) => console.error(e.msg || e.message));
    },

    popThreeOption2RefClick(params) {
      this.popThreeMode = 1;

      this.workNameType =
        this.popThreeOption2.series[0].data[params.dataIndex].name;
      this.workNameValue =
        this.popThreeOption2.series[0].data[params.dataIndex].value;

      if (this.$refs.popThreeOption3Ref) {
        this.$refs.popThreeOption3Ref.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.popThreeOption3HighlightIndex,
        });
      }
      // params.dataIndex
      if (params.dataIndex !== this.popThreeOption2HighlightIndex) {
        this.$refs.popThreeOption2Ref.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.popThreeOption2HighlightIndex,
        });
      }
      this.$refs.popThreeOption2Ref.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: params.dataIndex,
      });
      this.popThreeOption2HighlightIndex = params.dataIndex;
      this.getPopThreeEcharts4(
        this.popThreeOption2SearchKeys[params.dataIndex],
        this.popThreeMode
      );
      this.getPopThreeTable(
        this.popThreeOption2SearchKeys[params.dataIndex],
        this.popThreeMode,
        "",
        ""
      );
    },
    popThreeOption3RefClick(params) {
      // console.log(params);
      this.popThreeMode = 2;
      this.workNameType =
        this.popThreeOption3.series[0].data[params.dataIndex].name;
      this.workNameValue =
        this.popThreeOption3.series[0].data[params.dataIndex].value;

      if (this.$refs.popThreeOption2Ref) {
        this.$refs.popThreeOption2Ref.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.popThreeOption2HighlightIndex,
        });
      }
      if (params.dataIndex !== this.popThreeOption3HighlightIndex) {
        this.$refs.popThreeOption3Ref.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.popThreeOption3HighlightIndex,
        });
      }
      this.$refs.popThreeOption3Ref.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: params.dataIndex,
      });
      this.popThreeOption3HighlightIndex = params.dataIndex;
      this.getPopThreeEcharts4(
        this.popThreeOption3SearchKeys[params.dataIndex],
        this.popThreeMode
      );
      this.getPopThreeTable(
        this.popThreeOption2SearchKeys[params.dataIndex],
        this.popThreeMode,
        "",
        ""
      );
      // params.dataIndex
    },
    popThreeOption4RefClick(params){
      // console.log(params);
      this.getPopThreeTable(
          this.popThreeOption2SearchKeys[0],
          this.popThreeMode,
          this.popThreeStatus,
          this.popThreeOption4List[params.dataIndex].org_id
        );
    },
    popThreeOption2MouseOver(params) {
      if (params.dataIndex != this.popThreeOption2HighlightIndex) {
        this.$refs.popThreeOption2Ref.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.popThreeOption2HighlightIndex,
        });
        // this.popThreeOption2HighlightIndex = params.dataIndex;
      }
    },
    popThreeOption2MouseOut() {
      this.$refs.popThreeOption2Ref.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.popThreeOption2HighlightIndex,
      });
    },
    popThreeOption3MouseOver(params) {
      if (params.dataIndex != this.popThreeOption3HighlightIndex) {
        this.$refs.popThreeOption3Ref.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.popThreeOption3HighlightIndex,
        });
        // this.popThreeOption2HighlightIndex = params.dataIndex;
      }
    },
    popThreeOption3MouseOut() {
      this.$refs.popThreeOption3Ref.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.popThreeOption3HighlightIndex,
      });
    },
    popThreeOption4MouseOver(params) {
      if (params.dataIndex !== this.popThreeOption4HighlightIndex) {
        this.$refs.popThreeOption4Ref.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.popThreeOption4HighlightIndex,
        });
        this.popThreeOption4HighlightIndex = params.dataIndex;
      }
    },
    popThreeOption4MouseOut() {
      this.$refs.popThreeOption4Ref.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.popThreeOption4HighlightIndex,
      });
    },
    getPopThreeEcharts2(org_id, mode) {
      project
        .getDashboardSurplusWorkerTypeCollect(org_id, mode)
        .then((d) => {
          this.popThreeOption2SearchKeys = d.search_keys;
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));

          this.popThreeOption2Template.series[0].data = d.data;

          this.popThreeOption2Template.series[1] = Object.assign(
            {},
            this.popThreeOption2Template.series[0],
            {
              label: {
                rich: {
                  text: {
                    fontSize: 11,
                  },
                },
                formatter: function (params) {
                  return `${params.name} ${params.value}人`;
                },
              },
              silent: true,
            }
          );

          setTimeout(
            () =>
              this.$refs.popThreeOption2Ref &&
              this.$refs.popThreeOption2Ref.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: this.popThreeOption2HighlightIndex,
              }),
            100
          );
          this.popThreeOption2 = applyUpdate(
            chart,
            this.popThreeOption2Template
          );
          this.workNameType = this.popThreeOption2.series[0].data[0].name;
          this.workNameValue = this.popThreeOption2.series[0].data[0].value;
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    getPopThreeEcharts3(org_id, mode) {
      project
        .getDashboardSurplusWorkerTypeCollect(org_id, mode)
        .then((d) => {
          this.popThreeOption3SearchKeys = d.search_keys;
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));

          this.popThreeOption3Template.series[0].data = d.data;

          this.popThreeOption3Template.series[1] = Object.assign(
            {},
            this.popThreeOption3Template.series[0],
            {
              label: {
                rich: {
                  text: {
                    fontSize: 11,
                  },
                },
                formatter: function (params) {
                  return `${params.name} ${params.value}人`;
                },
              },
              silent: true,
            }
          );

          setTimeout(
            () =>
              this.$refs.popThreeOption3Ref &&
              this.$refs.popThreeOption3Ref.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: this.popThreeOption3HighlightIndex,
              }),
            100
          );
          this.popThreeOption3 = applyUpdate(
            chart,
            this.popThreeOption3Template
          );
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    getPopThreeEcharts4(search_key, mode) {
      project
        .getDashboardSurplusWorkerTypeOrgCollect(search_key, mode)
        .then((d) => {
          // console.log(d, "-----");
          const chart = JSON.parse(JSON.stringify(areaPieChartTemplate));
          // this.popThreeOption4Template.xAxis[0].data = d.xAxis_data;
          this.popThreeOption4Template.series[0].data = d.map((item) => {
            return {
              name: item.org_name,
              value: item.count,
            };
          });

          // popThreeOption4HighlightIndex
          this.popThreeOption4 = applyUpdate(
            chart,
            this.popThreeOption4Template
          );
          if (this.$refs.popThreeOption4Ref) {
            this.$refs.popThreeOption4Ref.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: this.popThreeOption4HighlightIndex,
            });
          }
          this.popThreeOption4List = d;
        });
    },
    getPopThreeTable(search_key, mode, status, orgLvl) {
      project
        .getDashboardSurplusWorkerTypeDetailCollect(
          search_key,
          mode,
          status,
          orgLvl
        )
        .then((d) => {
          // console.log(d, "-----");
          this.popThreeTable = d.map((item) => {
            return {
              ...item,
              isResult: false,
            };
          });
        });
    },
    showResult2(item, index) {
      item.isResult = !item.isResult;
      this.$set(this.popThreeTable, index, item);
    },
    popToSelect(option, val) {
      // console.log(idx)
      // console.log(this.companyIdList)
      // console.log(this.companyIdList[idx])

      if (val === 1) {
        // this.getChart();
        const idx = this.overDownList.indexOf(option);
        if (idx >= 0) {
          // eslint-disable-next-line no-empty
          this.overDownId = this.overDownIdList[idx];
        }
        this.getBottomTable();
      } else if (val === 2) {
        const idx = this.workTypeList.indexOf(option);
        if (idx >= 0) {
          // eslint-disable-next-line no-empty
          this.workTypeId = this.workTypeIdList[idx];
        }
        this.getBottomTable();
      } else if (val === 3) {
        const idx = this.statusList.indexOf(option);
        if (idx >= 0) {
          // eslint-disable-next-line no-empty
          this.statusId = this.statusIdList[idx];
        }
        this.getBottomTable();
      } else if (val === 4) {
        this.selectedPop4 = option;
        const idx = this.companyNameList.indexOf(option);

        if (idx >= 0) {
          this.getPopThreeEcharts1(this.companyIdList[idx]);
        }
      } else if (val === 5) {
        this.selectedPop5 = option;
        const idx = this.companyNameList.indexOf(option);

        if (idx >= 0) {
          this.getPopThreeEcharts2(this.companyIdList[idx], 1);
        }
      } else if (val === 6) {
        this.selectedPop6 = option;
        const idx = this.companyNameList.indexOf(option);
        if (idx >= 0) {
          this.getPopThreeTable(
            this.popThreeOption2SearchKeys[0],
            this.popThreeMode,
            this.popThreeStatus,
            this.companyIdList[idx]
          );
          this.popThreeCompany = this.companyIdList[idx];
        }
      }
    },
    poStatusSelect(option) {
      const idx = this.popThreeStatusList.indexOf(option);
      if (idx >= 0) {
        this.getPopThreeTable(
          this.popThreeOption2SearchKeys[0],
          this.popThreeMode,
          this.popThreeStatusIdList[idx],
          this.popThreeCompany
        );
        this.popThreeStatus = this.popThreeStatusIdList[idx];
      }
    },
    workerSurplusChartClick(params) {
      this.popupToShow = true;
      this.popupTotitle = params.name;
      this.popupToMin =
        -this.workerSurplusChart.series[0].data[params.dataIndex];
      this.popupToMax =
        this.workerSurplusChart.series[1].data[params.dataIndex];
      // console.log(params);
      // console.log(params.dataIndex);
      // console.log(this.workerSurplusChart.series[0].data[params.dataIndex]);
      // console.log(this.workerSurplusChart.series[1].data[params.dataIndex]);

      // 工种
      nation.getGeneralParamsSelect(1).then((d) => {
        // statusIdList: [""],
        this.workTypeList = ["全部工种"].concat(d.map((item) => item.value));
        this.workTypeIdList = [""].concat(d.map((item) => item.innerid));
      });

      nation
        .getScheduleLaborSurplusWorkerTypeCollect(1)
        .then((d) => {
          if (this.$refs.machineTrouble) {
            //取消默认高亮
            this.$refs.machineTrouble.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: this.workerTypePieChartHighlightIndex,
            });
          }

          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
          const maxPieces = 14;
          if (d.data.length > maxPieces) {
            d.data[maxPieces - 1].value = d
              .slice(maxPieces - 1)
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
              );
            d[maxPieces - 1].name = "其它";
            d.data = d.data.slice(0, maxPieces);
          }
          this.option4.series[0].data = d.data;

          // 默认高亮第一个值不是0的分片，否则默认第一片
          this.workerTypePieChartHighlightIndex = 0;
          for (let i = 0; i < d.data.length; i++) {
            if (this.option4.series[0].data[i].value) {
              this.workerTypePieChartHighlightIndex = i;
              break;
            }
          }
          this.option4.series[1] = Object.assign({}, this.option4.series[0], {
            label: {
              rich: {
                text: {
                  fontSize: 11,
                },
              },
              formatter: function (params) {
                return `${params.name} ${Math.round(params.percent)}人`;
              },
            },
            silent: true,
          });

          setTimeout(
            () =>
              this.$refs.machineTrouble &&
              this.$refs.machineTrouble.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: this.workerTypePieChartHighlightIndex,
              }),
            100
          );
          this.machineTrouble = applyUpdate(chart, this.option4);
        })
        .catch((e) => console.error(e.msg || e.message));

      nation
        .getScheduleLaborSurplusWorkerTypeCollect(2)
        .then((d) => {
          if (this.$refs.machineTrouble) {
            //取消默认高亮
            this.$refs.machineTrouble.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: this.workerAgePieChartHighlightIndex,
            });
          }

          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
          const maxPieces = 14;
          if (d.data.length > maxPieces) {
            d.data[maxPieces - 1].value = d
              .slice(maxPieces - 1)
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
              );
            d[maxPieces - 1].name = "其它";
            d.data = d.data.slice(0, maxPieces);
          }
          this.option5.series[0].data = d.data;

          // 默认高亮第一个值不是0的分片，否则默认第一片
          this.workerAgePieChartHighlightIndex = 0;
          for (let i = 0; i < d.data.length; i++) {
            if (this.option5.series[0].data[i].value) {
              this.workerAgePieChartHighlightIndex = i;
              break;
            }
          }
          this.option5.series[1] = Object.assign({}, this.option5.series[0], {
            label: {
              rich: {
                text: {
                  fontSize: 11,
                },
              },
              formatter: function (params) {
                return `${params.name} ${Math.round(params.percent)}人`;
              },
            },
            silent: true,
          });

          setTimeout(
            () =>
              this.$refs.machineTrouble2 &&
              this.$refs.machineTrouble2.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: this.workerAgePieChartHighlightIndex,
              }),
            100
          );
          this.machineTrouble2 = applyUpdate(chart, this.option5);
        })
        .catch((e) => console.error(e.msg || e.message));

      this.getBottomTable();
    },
    machineTroubleMouseOver(params) {
      if (params.dataIndex != this.workerTypePieChartHighlightIndex) {
        this.$refs.machineTrouble.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.workerTypePieChartHighlightIndex,
        });
        this.workerTypePieChartHighlightIndex = params.dataIndex;
      }
    },
    machineTroubleMouseOut() {
      this.$refs.machineTrouble.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.workerTypePieChartHighlightIndex,
      });
    },
    machineTroubleMouseOver2(params) {
      if (params.dataIndex != this.workerAgePieChartHighlightIndex) {
        this.$refs.machineTrouble2.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.workerAgePieChartHighlightIndex,
        });
        this.workerAgePieChartHighlightIndex = params.dataIndex;
      }
    },
    machineTroubleMouseOut2() {
      this.$refs.machineTrouble2.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.workerAgePieChartHighlightIndex,
      });
    },
    getBottomTable() {
      nation
        .getScheduleLaborSurplusWorkerTypeDetailCollect(
          100,
          this.overDownId ? this.overDownId : "",
          this.overDownId ? this.workTypeId : "",
          this.statusId ? this.statusId : ""
        )
        .then((d) => {
          // console.log(d, "--------------------");
          this.projectLaborDetail = d;
          this.projectLaborDetail.forEach((v) => {
            v.isResult = false;
          });
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },

    getAttendance(popupSelect) {
      this.popupSelect = popupSelect;

      nation
        .getLaborCompareCollect(
          this.popupSelect,
          this.org_id ? this.org_id : ""
        )
        .then((d) => {
          // console.log(d)
          const chart = JSON.parse(JSON.stringify(singleLineChartTemplate));
          this.popupData = d.series;
          this.option3.legend.data = d.legend_data;
          this.option3.xAxis.data = d.xAxis_data;
          this.option3.series = [];
          d.series.forEach((vv, index) => {
            this.option3.series.push({
              name: d.legend_data[index],
              type: "line",
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                  },
                },
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(27, 208, 128, 0.5)" },
                  { offset: 1, color: "rgba(27, 208, 128, 0)" },
                ]),
              },
              // symbolSize: 0,
              data: vv,
            });
          });
          this.popupBottomName = `${
            d.xAxis_data[d.series.length - 1]
          }劳动力详情`;
          this.option3.series.push({
            type: "line",
            markLine: {},
          });
          this.laborCompareCollect = applyUpdate(chart, this.option3);
          this.popupXAxisData = d.xAxis_data;
          this.popupSearchkeys = d.search_keys;
          this.getLaborCompareOrgProjectCollect(
            d.search_keys[d.series.length - 1]
          );
          this.laborCompareCollect.series[2].markLine = {
            lineStyle: {
              color: "#ffbd1d",
            },
            name: "cc",
            data: [
              [
                {
                  coord: [
                    d.series.length - 1,
                    this.laborCompareCollect.series[0].data[
                      d.series.length - 1
                    ] >
                    this.laborCompareCollect.series[1].data[d.series.length - 1]
                      ? this.laborCompareCollect.series[0].data[
                          d.series.length - 1
                        ]
                      : this.laborCompareCollect.series[1].data[
                          d.series.length - 1
                        ],
                  ],
                },
                {
                  coord: [d.series.length - 1, 0],
                  symbol: "none", //去掉箭头
                },
              ],
            ],
          };
        });
    },
    attenceWorkersBarChartClick(params) {
      // console.log(params);

      this.laborCompareCollect.series[2].markLine = {
        lineStyle: {
          color: "#ffbd1d",
        },
        name: "cc",
        data: [
          [
            {
              coord: [
                params.dataIndex,
                this.laborCompareCollect.series[0].data[params.dataIndex] >
                this.laborCompareCollect.series[1].data[params.dataIndex]
                  ? this.laborCompareCollect.series[0].data[params.dataIndex]
                  : this.laborCompareCollect.series[1].data[params.dataIndex],
              ],
            },
            {
              coord: [params.dataIndex, 0],
              symbol: "none", //去掉箭头
            },
          ],
        ],
      };
      this.popupBottomName = `${
        this.popupXAxisData[params.dataIndex]
      }劳动力详情`;
      this.getLaborCompareOrgProjectCollect(
        this.popupSearchkeys[params.dataIndex]
      );
    },
    getLaborCompareOrgProjectCollect(search_keys) {
      nation
        .getLaborCompareOrgProjectCollect(
          this.popupSelect,
          search_keys,
          1000000,
          this.org_id ? this.org_id : ""
        )
        .then((d) => {
          // console.log(d);
          this.popManagementTopProjects = d;
        });
    },
    getAll() {
      nation.getScheduleProjectDelayedDaysCollect().then((d) => {
        // console.log(d)
        this.workerTypePieChart = d.series.map((item, index) => {
          return { value: item, name: d.yAxis_data[index] };
        });
        this.avgAttendanceMax = Math.max.apply(
          null,
          this.workerTypePieChart.map((item) => item.value)
        );
      });

      this.getChart();

      nation.getScheduleProjectLaborSurplusCollect().then((d) => {
        // console.log(d);
        const chart = JSON.parse(JSON.stringify(barNegativeChartTemplate));
        // this.option2.legend.data = d.legend_data;
        this.option2.yAxis[0].data = d.xAxis_data;
        this.option2.yAxis[1].data = d.series[0];
        this.option2.yAxis[2].data = d.series[1];
        this.option2.series[0].data = d.series[0];
        this.option2.series[0].data = this.option2.series[0].data.map((vv) => {
          return -vv;
        });
        this.option2.series[0].name = d.legend_data[0];
        this.option2.series[1].data = d.series[1];
        this.option2.series[1].name = d.legend_data[1];
        this.option2.xAxis[0].min = -Math.max.apply(null, d.series[0]) * 1.2;
        this.option2.xAxis[0].max = Math.max.apply(null, d.series[1]) * 1.2;

        if (this.option2.series[0].data.length) {
          if (this.option2.series[0].data.length <= 15) {
            this.option2.dataZoom = [];
          } else {
            this.option2.yAxis.map((item,index) => {
              this.option2.dataZoom[index] = {
                type: "inside",
                show: true,
                yAxisIndex: [index],
                //height: 15,
                //left: 100,
                //right: 100,
                start: 0,
                end:  (10 / this.option2.series[0].data.length) * 100,
              }
            })
          }
        }

        this.workerSurplusChart = applyUpdate(chart, this.option2);
      });
    },
    companySelect(option, val) {
      const idx = this.companyNameList.indexOf(option);
      if (idx >= 0) {
        this.org_id = this.companyIdList[idx];

        if (val === "val1") {
          this.selectedVal1 = option;
          this.getChart();
        } else if (val === "val2") {
          this.selectedVal2 = option;
          this.getAttendance(this.popupSelect);
        }
      }
    },
    getChart() {
      nation
        .getScheduleProjectLaborCompareCollect(this.org_id ? this.org_id : "")
        .then((d) => {
          // console.log(d)
          const chart = JSON.parse(JSON.stringify(singleLineChartTemplate));
          this.option.legend.data = d.legend_data;
          this.option.xAxis.data = d.xAxis_data;
          this.option.series = [];
          d.series.forEach((vv, index) => {
            this.option.series.push({
              name: d.legend_data[index],
              type: "line",
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                  },
                },
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(27, 208, 128, 0.5)" },
                  { offset: 1, color: "rgba(27, 208, 128, 0)" },
                ]),
              },
              // symbolSize: 0,
              data: vv,
            });
          });
          this.workerAgePieChart = applyUpdate(chart, this.option);
        });
    },
    showResult(item, index) {
      item.isResult = !item.isResult;
      this.$set(this.projectLaborDetail, index, item);
    },
  },
};
</script>

<style scoped>
.linebar {
  display: flex;
  align-items: center;
  margin: 3px auto 12px;
  font-size: 12px;
}
.line-bar {
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #57c7b6;
  border-radius: 12px;
}
.show {
  width: 40px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #4191e5;
  color: #fff;
  /* margin: auto; */
  cursor: pointer;

}
.chart-title{
  color: #cad6d9;
  font-size: 12px;
  display:flex;
  align-items:center;
  justify-content: flex-end;
  width: 20%;
  margin:auto;
  padding-left: 16rem;
  position: absolute;
  top: 20%;
  left: 22%;
}
</style>
