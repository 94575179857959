<template>
  <div class="info-block" style="min-height:5rem">
    <div class="info-block-title">合同类型</div>
    <div
      style="display:flex;justify-content:flex-end"
    >
      <CustomSelect
        :options="yearList"
        :value="selectedYear"
        @input="companySelect"
        style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
      />
    </div>
    <div style="height:17rem;position:relative;padding-top:1rem">
      <div
        v-if="!contractTypeBarChart.series || !contractTypeBarChart.series[0].data.length"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart v-else :options="contractTypeBarChart" ref="dangerType" @mouseover="dangerTypeMouseOver" @mouseout="dangerTypeMouseOut" autoresize />
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { human } from '../../../../api_hr'
  import CustomSelect from '../../../../components/select'
  import { stackedBarChartTemplate } from "../../barChartTemplates";
  import { applyUpdate, truncateStr } from "../../../../utils";

  export default {
    name: 'contractTypeBarChart',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
    watch: {
      orgOptions(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateContractTypeBarChart();
        }
      },
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateContractTypeBarChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateContractTypeBarChart();
        }
      }
    },
    data () {
      return {
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear(),
        contractTypeBarChart: {},
        contractTypeBarChartHighlightIndex: null,
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.companyNameList = this.orgOptions.map(item => truncateStr(item.org_name, 10));
      this.companyIdList = this.orgOptions.map(item => item.org_id);

      let idx = this.companyIdList.indexOf(this.orgLevel);
      if (idx == -1) {
        idx = 0;
      }
      this.companySelectedValue = this.companyNameList[idx];

      this.updateContractTypeBarChart();
    },
    methods: {
      updateContractTypeBarChart() {
        human.getPersonContractType(this.selectedYear)
          .then((res) => {
            var orgList = [];
            var gdxy = [];
            var wgdxy = [];
            var fpxy = [];
            for (let i = 0; i < res.length; i++) {
              orgList.push(res[i].orgName);
              gdxy.push(res[i].subData[0].value);
              wgdxy.push(res[i].subData[1].value);
              fpxy.push(res[i].subData[2].value);
            }
            const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));

            const option = {
              
              color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  },
                  formatter:function(param){
                    let str = param[0].name+"<br/>";
                    for (let index = 0; index < param.length; index++) {
                      str += param[index].seriesName+":"+param[index].value+"个<br/>";
                    }
                    return str;
                  }
              },
              grid: {
                containLabel: true,
                left: '5%',
                right: '6%',
                bottom: "5%",
                top: "12%"
              },
              xAxis: [
                {
                  name:"单位",
                  data: orgList,
                  axisLabel: {
                    color: 'white',
                    fontWeight: 'bold',
                    rotate: 40,
                    // formatter: value => truncateStr(value, 10),
                  },
                  show: true,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'category',
                }
              ],
              yAxis: [
                {
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  boundaryGap: [0, '20%'],
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'value'
                }
              ],
              series: [
                {
                  name: '签订固定期限人数',
                  type: 'bar',
                  stack: 'total',
                  barWidth: 10,
                  label: {
                    show: true,
                    color:"white"
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: gdxy
                },
                {
                  name: '签订无固定期限人数',
                  type: 'bar',
                  stack: 'total',
                  barWidth: 10,
                  label: {
                    show: true,
                    color:"white"
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: wgdxy
                },
                {
                  name: '签订返聘协议人数',
                  type: 'bar',
                  stack: 'total',
                  barWidth: 10,
                  label: {
                    show: true,
                    color:"white"
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: fpxy
                }
              ]
            };

            this.contractTypeBarChart = applyUpdate(chart, option);
          })
          .catch((e) => console.error(e.msg || e.message));
      },
      companySelect(option) {
        this.selectedYear = option;
        this.updateContractTypeBarChart();
      },
      dangerTypeMouseOver(params) {
        if (params.dataIndex != this.contractTypeBarChartHighlightIndex) {
          this.$refs.dangerType.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.contractTypeBarChartHighlightIndex
          });
          this.contractTypeBarChartHighlightIndex = params.dataIndex;
        }
      },
      dangerTypeMouseOut() {
        this.$refs.dangerType.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.contractTypeBarChartHighlightIndex
        });
      },
    },
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
