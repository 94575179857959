<template>
    <div id = "right">
        <div class="info-block" style="min-height:5rem">
        <div class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >潜亏风险
        <div class="detail" @click="showDetail()">
        详情
      </div>  
        </div>
        <div style="height:20rem;padding-top:3rem">
        <div
            v-if="!latentLossChart.series || !latentLossChart.series[0].data.length"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="latentLossChart" autoresize />
        </div>
    </div>
     <!-- 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                <el-table style="background-color:#0E1328" height="28rem" :data="tableData" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column type="index" label="序号" width="50">
                    </el-table-column>
                    <el-table-column
                        prop="FXMMC"
                        label="项目名称">
                        </el-table-column>
                    <el-table-column
                        prop="FFGS"
                        label="所属分公司">
                    </el-table-column>
                    <el-table-column
                        prop="FDWMC"
                        label="所属大客户">
                    </el-table-column>
                    <el-table-column
                        prop="FSGZTMYMC"
                        label="承包人名称">
                    </el-table-column>
                    <el-table-column
                        prop="FXMZTMC"
                        label="项目类型">
                    </el-table-column>
                    <el-table-column
                        prop="FXMWZ"
                        label="项目位置">
                    </el-table-column>
                    <el-table-column
                        prop="FJZMJ"
                        label="建筑面积">
                    </el-table-column>
                    <el-table-column
                        prop="FBAHTJE"
                        label="合同额（含甲指）">
                    </el-table-column>
                    <el-table-column
                        prop="FBAHTJE_ZS"
                        label="合同额（不含甲指）">
                    </el-table-column>
                    <el-table-column
                        prop="FBDE"
                        label="合同约定付款比例">
                    </el-table-column>
                    <el-table-column
                        prop="FGWMC"
                        label="合同约定付款形式">
                    </el-table-column>
                     <el-table-column
                        prop="FTGYD"
                        label="合同关于禁止停工的约定">
                    </el-table-column>
                    <el-table-column
                        prop="FQQGQ"
                        label="确权后工期延误（天）">
                    </el-table-column>
                    <el-table-column
                        prop="FWQQCZ"
                        label="未确权产值（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FQKJE"
                        label="潜亏金额（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FYQYSJE3"
                        label="逾期应收金额（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FHFXFX"
                        label="项目合法性风险">
                    </el-table-column>
                    <el-table-column
                        prop="FHFXFX"
                        label="优先受偿权风险">
                    </el-table-column>
                    <el-table-column
                        prop="FYQFX"
                        label="逾期应收风险">
                    </el-table-column>
                    <el-table-column
                        prop="FZXFX"
                        label="资信风险">
                    </el-table-column>
                    <el-table-column
                        prop="FBCSM1"
                        label="具体风险描述">
                    </el-table-column>
                    <el-table-column
                        prop="FYJSR"
                        label="预计总收入（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FSJCZ"
                        label="实际产值（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FYZQRCZ"
                        label="业主确认产值（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FSJCB"
                        label="实际成本（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FSSFHBQF"
                        label="过程结算率(%)">
                    </el-table-column>
                    <el-table-column
                        prop="FYJSR"
                        label="预计收入（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FYJCB"
                        label="预计成本（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FQKJE2"
                        label="潜亏金额（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FQTFY"
                        label="预计收益率(%)">
                    </el-table-column>
                    <el-table-column
                        prop="FAHTYSZK"
                        label="按合同应收账款（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FLJSK"
                        label="累计收款（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FYQYSJE3"
                        label="逾期应收金额(万元)">
                    </el-table-column>
                    <el-table-column
                        prop="FZQYE"
                        label="总权益额">
                    </el-table-column>
                    <el-table-column
                        prop="FXMSS"
                        label="是否存在商票收款">
                    </el-table-column>
                    <el-table-column
                        prop="FSPSK"
                        label="商票收款（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FQZDQWDF"
                        label="其中到期未兑付（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FQZWDQ"
                        label="其中未到期(万元)">
                    </el-table-column>
                    <el-table-column
                        prop="FSFGZ_LA"
                        label="是否存在抵房收款">
                    </el-table-column>
                    <el-table-column
                        prop="FFCDRJE"
                        label="房产抵入金额（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FQZDCJE"
                        label="其中抵出金额（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FQZWDCJE"
                        label="其中未抵出金额（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FYFXYDWJE"
                        label="应付下游单位金额（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FFCDRJE"
                        label="已付下游单位金额（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FYFXYDWJE"
                        label="欠付下游单位金额（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FCDC2"
                        label="存贷差(万元)">
                    </el-table-column>
                    <el-table-column
                        prop="FSJSY"
                        label="上缴收益">
                    </el-table-column>
                    <el-table-column
                        prop="FZJLX2"
                        label="资金利息">
                    </el-table-column>
                    <el-table-column
                        prop="FSFXYYXSCQ"
                        label="是否享有优先受偿权">
                    </el-table-column>
                    <el-table-column
                        prop="FBXYYYMC"
                        label="不享有原因">
                    </el-table-column>
                    <el-table-column
                        prop="FYXSCQSXRQ"
                        label="优先权起算时间">
                    </el-table-column>
                    <el-table-column
                        prop="FYXQDQ"
                        label="优先权到期时间">
                    </el-table-column>
                    <el-table-column
                        prop="FSFYYSXKZ"
                        label="预售许可证">
                    </el-table-column>
                    <el-table-column
                        prop="FZSFCWXSJE"
                        label="自施房产未销售金额（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FSFCZJGZH"
                        label="是否存在监管账户">
                    </el-table-column>
                    <el-table-column
                        prop="FJGZHYE"
                        label="监管账户余额（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FQTYXZHYE"
                        label="其他银行账户余额（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FQTXMFCGZ"
                        label="其他项目房产估值（万元））">
                    </el-table-column>
                    <el-table-column
                        prop="FQTWKFTDGZ"
                        label="其他未开发土地估值（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FDWTZGZ"
                        label="对外投资估值（万元）">
                    </el-table-column>
                    <el-table-column
                        prop="FQTZCQK"
                        label="其它资产情况">
                    </el-table-column>
                    <el-table-column
                        prop="FTDCRJJNQK"
                        label="土地出让金缴纳情况">
                    </el-table-column>
                    <el-table-column
                        prop="FJRJGRZQK"
                        label="金融机构融资情况">
                    </el-table-column>
                    <el-table-column
                        prop="FDYZYQK"
                        label="抵押质押情况">
                    </el-table-column>
                    <el-table-column
                        prop="FDWDBQK"
                        label="对外担保情况">
                    </el-table-column>
                    <el-table-column
                        prop="FHTKGRQ"
                        label="约定开工时间">
                    </el-table-column>
                    <el-table-column
                        prop="FHTJGRQ"
                        label="约定竣工时间">
                    </el-table-column>
                    <el-table-column
                        prop="FSJKGRQ"
                        label="实际开工时间">
                    </el-table-column>
                    <el-table-column
                        prop="FJGSJ"
                        label="预计/实际竣工/退场时间">
                    </el-table-column>
                    <el-table-column
                        prop="FJDGQ"
                        label="绝对工期延误（天）">
                    </el-table-column>
                    <el-table-column
                        prop="FYWGQ"
                        label="已确权延误（天）">
                    </el-table-column>
                    <el-table-column
                        prop="FBCSM2"
                        label="现场形象节点">
                    </el-table-column>
                    <el-table-column
                        prop="FBCSM3"
                        label="当地预售标准">
                    </el-table-column>
                    <el-table-column
                        prop="FSFYYSXKZ"
                        label="预售许可证">
                    </el-table-column>
                    <el-table-column
                        prop="FSFYGHXKZ"
                        label="规划许可证">
                    </el-table-column>
                    <el-table-column
                        prop="FSFYTDXKZ"
                        label="用地许可证">
                    </el-table-column>
                    <el-table-column
                        prop="FSFYSGXKZ"
                        label="施工许可证">
                    </el-table-column>
                </el-table>
            </div>
        </div>
      </wrapper>
    </el-dialog>
</div>
</template>
<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import {law} from '../../../../api_law';
  import wrapper from "../../../../components/wrap";
  export default {
    name:"LatentLossesRiskChart",
    components:{
        'v-chart':ECharts,
        wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updaterespondCaseChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updaterespondCaseChart();
        }
      }
    },
    data(){
        return{
            latentLossChart:{},
            popupShow:false,
            tableData:[],
            mode:"1"
        }
    },
    mounted(){
        // const self = this
      const token = this.$route.query.token;
      window.token = token;
      
      this.updaterespondCaseChart();
    },
    methods:{
        showDetail(){
            law.queryRiskProjectDetail(4,'',"潜亏").then((res=>{
                this.tableData =res;
            }))
            this.popupShow = true;
        },
        tableRowStyle({rowIndex}){
            if(rowIndex%2 === 0)
                return {backgroundColor:'#213a77',height: '0'}; 
            else   
                return {backgroundColor:'#102855',height: '0'}; 
        },
        tableHeaderColor(){
            return 'color:#4fb5da;background:#102855';
        },
        updaterespondCaseChart(){
            law.queryOverdueRisk().then((res=>{
                const option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            lineStyle: {
                                color: '#57617B',
                            },
                        },
                    },
                    legend: {
                        right: '8%',
                        top: '2%',
                        textStyle: {
                            color: '#fff',
                        },
                    },
                    xAxis: {
                        type: 'category',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#3585d5',
                            },
                        },
                        axisTick: {
                            show: false,
                            alignWithLabel: true,
                            lineStyle: {
                                color: '#3585d5',
                            },
                        },
                        axisLabel: {
                            color: 'white',
                            fontWeight: 'bold',
                            rotate: 40,
                            // formatter: value => truncateStr(value, 10),
                        },
                        data: [],
                    },
                    yAxis: [
                        {
                        name: '数量',
                        nameTextStyle:{
                            color: '#A2D4E6',
                        },
                        type: 'value',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#3585d5',
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                type: 'dotted',
                                color: '#3585d5',
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            fontSize: 12,
                            color:'#A2D4E6'
                        }
                        },
                        {
                        name: '金额',
                        nameTextStyle:{
                            color: '#A2D4E6',
                        },
                        type: 'value',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#3585d5',
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                type: 'dotted',
                                color: '#3585d5',
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            fontSize: 12,
                            color:'#A2D4E6'
                        }
                        },
                    ],
                    series: [
                        {
                            name: "潜亏项目数量",
                            type: 'bar',
                            smooth: true,
                            symbol: 'none',
                            showSymbol: false,
                            symbolSize: 8,
                            yAxisIndex:0,
                            itemStyle: {
                                color: {
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    type: 'linear',
                                    global: false,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#FFC31F',
                                        },
                                        {
                                            offset: 1,
                                            color: '#FF5A1F',
                                        },
                                    ],
                                },
                            },
                            data: [],
                        },{
                            name: "潜亏金额",
                            type: 'line',
                            smooth: true,
                            symbol: 'none',
                            showSymbol: false,
                            yAxisIndex:1,
                            symbolSize: 8,
                            itemStyle: {
                                color: {
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    type: 'linear',
                                    global: false,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#28D8E8',
                                        },
                                        {
                                            offset: 1,
                                            color: '#0489F0',
                                        },
                                    ],
                                },
                            },
                            data: [],
                        }
                    ],
                }
                for (let i = 0; i < res.length; i++) {
                    option.xAxis.data.push(res[i].fgs);
                    option.series[0].data.push(res[i].FQKFX);
                    option.series[1].data.push(res[i].QKJE!=null?res[i].QKJE:0);
                   
                }
                this.latentLossChart = option;
            }))
        }
    }

}
</script>