<template>
  <div id="right">
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;top: 0.2rem;"
      >
        <div>劳务出勤情况</div>
        <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode1 == 2 }"
            @click="() => getLaborAttendanceChart(2)"
          >近七日</div>
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode1 == 1 }"
            @click="() => getLaborAttendanceChart(1)"
          >今日</div>
          <div
            class="detail"
            style="margin-left: 0.5rem;"
            @click="showDetail(1)"
          >
            详情
          </div>
        </div>

      </div>
      <div style="height:18rem;padding-top:3rem">
        <div
          v-if="laborAttendanceChart.series && [].concat.apply([], laborAttendanceChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="laborAttendanceChart"
          @click="laborAttendanceChartClick"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div
        class="info-block-title"
        style="display: flex;align-items:center;width: 85%;justify-content: space-between;"
      >
        <div>劳务人员安全帽无电情况</div>
        <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode2 == 2 }"
            @click="() => getlaborHatNoEleChart(2)"
          >近七日</div>
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode2 == 1 }"
            @click="() => getlaborHatNoEleChart(1)"
          >今日</div>
          <div
            class="detail"
            style="margin-left: 0.5rem;"
            @click="showDetail(2)"
          >
            详情
          </div>
        </div>
      </div>
      <div style="height:20rem;padding-top:3rem">
        <div
          v-if="laborHatNoEleChart.series && [].concat.apply([], laborHatNoEleChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="laborHatNoEleChart"
          @click="laborHatNoEleChartClick"
          autoresize
        />
      </div>

    </div>
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>管理人员安全帽无电情况</div>
        <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode3 == 2 }"
            @click="() => getManagerHatNoEleChart(2)"
          >近七日</div>
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode3 == 1 }"
            @click="() => getManagerHatNoEleChart(1)"
          >今日</div>
          <div
            class="detail"
            style="margin-left: 0.5rem;"
            @click="showDetail(3)"
          >
            详情
          </div>
        </div>
      </div>
      <div style="height:20rem;padding-top:3rem">
        <div
          v-if="managerHatNoEleChart.series && [].concat.apply([], managerHatNoEleChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="managerHatNoEleChart"
          @click="managerHatNoEleChartCLick"
          ref="troubleType"
          autoresize
        />
      </div>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow1"
      width="60rem"
      top="12rem"
      :destroy-on-close='true'
    >
      <wrapper
        class="popup-bg"
        style="height:42rem;backgroundColor:#0e1328;position:relative"
      >
        <div
          class="stats-close-btn"
          @click="closePop1"
        />
        <div class="attendance">
          <div style="display:flex;justify-content: space-between; margin: 1rem;">
            <div style="min-width:7rem"></div>
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle1}}
            </div>
            <div style="display:flex;align-items:center;">
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode4 == 2 }"
                @click="() => getPopData1(2)"
              >近七日</div>
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode4 == 1 }"
                @click="() => getPopData1(1)"
              >今日</div>
            </div>

          </div>
          <div style="height:37rem;padding-top:1rem;position:relative">

            <div
              v-if="laborAttendanceDetailChart.series && [].concat.apply([], laborAttendanceDetailChart.series.map(x => x.data)).length == 0"
              style="display:flex;flex-direction:column;align-items:center;justify-content:center;height: 12rem;margin-top: 0.5rem;"
            >
              <div class="empty-chart-icon" />
              <div style="margin-top:20px;color:#ccc">暂无数据</div>
            </div>
            <v-chart
              v-else
              :options="laborAttendanceDetailChart"
              autoresize
            />
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="60rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:42rem;backgroundColor:#0e1328;position:relative"
      >
        <div
          class="stats-close-btn"
          @click="closePop2"
        />
        <div class="attendance">
          <div style="display:flex;justify-content: space-between; margin: 1rem;">
            <div style="min-width:7rem"></div>
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle2}}
            </div>
            <div style="display:flex;align-items:center;">
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode5 == 1 }"
                @click="() => getPopData2(1)"
              >近七日</div>
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode5 == 2 }"
                @click="() => getPopData2(2)"
              >今日</div>
            </div>

          </div>

          <div style="margin:1.5rem 1rem">
            <div style="height:37rem;padding-top:1rem">
              <div
                v-if="laborHatNoEleDetailChart.series && [].concat.apply([], laborHatNoEleDetailChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center; height: 16rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart
                v-else
                :options="laborHatNoEleDetailChart"
                autoresize
              />
            </div>
          </div>

        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow3"
      width="60rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:42rem;backgroundColor:#0e1328;position:relative"
      >
        <div
          class="stats-close-btn"
          @click="closePop3"
        />
        <div class="attendance">
          <div style="display:flex;justify-content: space-between; margin: 1rem;">
            <div style="min-width:7rem"></div>
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle3}}
            </div>
            <div style="display:flex;align-items:center;">
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode6 == 2 }"
                @click="() => getPopData3(2)"
              >近七日</div>
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode6 == 1 }"
                @click="() => getPopData3(1)"
              >今日</div>
            </div>

          </div>

          <div style="margin:1.5rem 1rem">
            <div style="height:37rem;padding-top:1rem">
              <div
                v-if="managerHatNoEleDetailChart.series && [].concat.apply([], managerHatNoEleDetailChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center; height: 16rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart
                v-else
                :options="managerHatNoEleDetailChart"
                autoresize
              />
            </div>
          </div>

        </div>
      </wrapper>
    </el-dialog>

  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation, project } from "../../api";
// import CustomSelect from "../../components/select";
// import { popupChartTemplate, colors } from "./pieChartTemplates";
import {
  SimpleBarChartTemplate,
  stackedBarChartTemplate,
} from "./barChartTemplates";
import { applyUpdate, truncateStr } from "../../utils";
import wrapper from "../../components/wrap";

export default {
  name: "SecurityCheckLeftBar",
  components: {
    "v-chart": ECharts,
    wrapper,
    // CustomSelect: CustomSelect,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    orgType: Number,
    orgOptions: Array,
    gotoProject: Function,
  },
  data() {
    return {
      mapLevel: 0,
      org_id: "",
      companyNameList: [""],
      companyIdList: [""],
      selectVal1: "",
      selectVal0: "",
      // 图表1
      mode1: 2,
      laborAttendanceChart: {},
      option: {
        color: ["#20bbee", "#618BF2", "#B6F261", "#F261E0", "#61F2DA"],
        grid: {
          left: "5%",
          right: "5%",
          top: "15%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data;
              if (params[1]) {
                result +=
                  "</br>" + " " + params[1].seriesName + " : " + params[1].data;
              }
              if (params[2]) {
                result +=
                  "</br>" + " " + params[2].seriesName + " : " + params[2].data;
              }
            }
            return result;
          },
        },
        legend: {
          //   show: false,
          data: [],
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              rotate: 40,
              fontWeight: "bold",
              formatter: (value) => truncateStr(value, 10),
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#36445f",
                width: 1,
                type: "solid",
              },
            },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            show: true,
            name: "数量",
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#36445f" },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                type: [1, 1],
                width: 1,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
          },
          {
            min: 0,
            max: 100,
            name: "百分率",
            interval: 25,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        dataZoom: [],
        series: [
          {
            type: "bar",
            barMaxWidth: 9,
            barGap: 0,
            data: [],
          },
          {
            type: "bar",
            barMaxWidth: 9,
            data: [],
          },
          {
            type: "line",
            yAxisIndex: 1,
            data: [],
          },
        ],
      },
      search_keys1: [],
      // 图表2
      mode2: 2,
      laborHatNoEleChart: {},
      option2: {
        color: ["#FF8645", "#23CF9E", "#20bbee"],
        grid: {
          left: "8%",
          right: "5%",
          top: "24%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data;
              if (params[1]) {
                result +=
                  "</br>" + " " + params[1].seriesName + " : " + params[1].data;
              }
              if (params[2]) {
                result +=
                  "</br>" + " " + params[2].seriesName + " : " + params[2].data;
              }
            }
            return result;
          },
        },
        legend: {
          data: [],
        },
        xAxis: {
          axisLabel: {
            color: "#e6f2f3",
            rotate: 40,
            fontWeight: "bold",
            formatter: (value) => truncateStr(value, 10),
          },
          show: true,
          axisLine: { show: true, lineStyle: { color: "#36445f" } },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#36445f",
              width: 1,
              type: "solid",
            },
          },
          axisTick: { show: false },
          type: "category",
          data: [],
        },
        yAxis: [
          {
            type: "value",
            show: true,
            name: "数量",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#36445f" },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                type: [1, 1],
                width: 1,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
          },
          {
            min: 0,
            max: 100,
            name: "百分率",
            interval: 25,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        dataZoom: [],
        series: [
          {
            type: "bar",
            barMaxWidth: 9,
            barGap: 0,
            stack: "Ad",
            data: [],
          },
          {
            type: "bar",
            barMaxWidth: 9,
            data: [],
          },
          {
            type: "line",
            yAxisIndex: 1,
            data: [],
          },
        ],
      },
      search_keys2: [],
      // 图表3
      mode3: 2,
      managerHatNoEleChart: {},
      option3: {
        color: ["#FF8645", "#23CF9E", "#20bbee"],
        grid: {
          left: "8%",
          right: "5%",
          top: "24%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data +
                (params[0].seriesIndex == 2 ? "%" : "kw");
              if (params[1]) {
                result +=
                  "</br>" +
                  " " +
                  params[1].seriesName +
                  " : " +
                  params[1].data +
                  (params[1].seriesIndex == 2 ? "%" : "kw");
              }
              if (params[2]) {
                result +=
                  "</br>" +
                  " " +
                  params[2].seriesName +
                  " : " +
                  params[2].data +
                  "%";
              }
            }
            return result;
          },
        },
        legend: {
          data: [],
        },
        xAxis: {
          axisLabel: {
            color: "#e6f2f3",
            rotate: 40,
            fontWeight: "bold",
            formatter: (value) => truncateStr(value, 10),
          },
          show: true,
          axisLine: { show: true, lineStyle: { color: "#36445f" } },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#36445f",
              width: 1,
              type: "solid",
            },
          },
          axisTick: { show: false },
          type: "category",
          data: [],
        },
        yAxis: [
          {
            type: "value",
            show: true,
            name: "产值：kw",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#36445f" },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                type: [1, 1],
                width: 1,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
          },
          {
            min: 0,
            max: 100,
            name: "百分率",
            interval: 25,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              formatter: function (value) {
                return value ? value + "%" : value;
              },
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        dataZoom: [],
        series: [
          {
            type: "bar",
            barMaxWidth: 9,
            barGap: 0,
            stack: "Ad",
            data: [],
          },
          {
            type: "bar",
            barMaxWidth: 9,
            data: [],
          },
          {
            type: "line",
            yAxisIndex: 1,
            data: [],
          },
        ],
      },
      search_keys3: [],

      // 弹窗1
      activeIndex1: -1,
      showInfoType1: 1,
      mode4: 2,
      popupTitle1: "",
      popupShow1: false,
      laborAttendanceDetailChart: {},

      option4: {
        color: ["#2662f9", "#4ab27c", "#ff9308", "#ecd61d"],
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data;
              if (params[1]) {
                result +=
                  "</br>" + " " + params[1].seriesName + " : " + params[1].data;
              }
              if (params[2]) {
                result +=
                  "</br>" + " " + params[2].seriesName + " : " + params[2].data;
              }
            }
            return result;
          },
        },
        legend: {
          data: ["Profit", "Income", "近七日平均出勤率"],
        },
        grid: {
          left: "3%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside", // 放大和缩小
            orient: "vertical",
            maxValueSpan: 5,
            minValueSpan: 5,
          },
        ],
        xAxis: [
          {
            name: "数量",
            type: "value",
            position: "top",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            //   splitLine: { show: false },
            axisTick: { show: false },
          },
          {
            name: "百分率:%",
            min: 0,
            max: 100,
            type: "value",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
          },
        ],
        yAxis: [
          {
            type: "category",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              formatter: (value) => truncateStr(value, 15),
            },
            axisTick: {
              show: false,
            },
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
        ],
        series: [
          {
            xAxisIndex: 0,
            name: "",
            type: "bar",
            barMaxWidth: 15,
            barGap: 0,
            data: [],
          },
          {
            xAxisIndex: 0,
            name: "",
            type: "bar",
            barMaxWidth: 15,
            data: [],
          },
          {
            name: "",
            xAxisIndex: 1,
            data: [],
            type: "line",
          },
        ],
      },

      // 弹窗2
      activeIndex2: -1,
      showInfoType2: 1,
      mode5: 1,
      popupShow2: false,
      popupTitle2: "",
      laborHatNoEleDetailChart: {},
      option5: {
        color: ["#2662f9", "#4ab27c", "#ff9308", "#ecd61d"],
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data;
              if (params[1]) {
                result +=
                  "</br>" + " " + params[1].seriesName + " : " + params[1].data;
              }
              if (params[2]) {
                result +=
                  "</br>" + " " + params[2].seriesName + " : " + params[2].data;
              }
            }
            return result;
          },
        },
        legend: {
          data: ["Profit", "Income", "近七日平均出勤率"],
        },
        grid: {
          left: "3%",
          top: "8%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside", // 放大和缩小
            orient: "vertical",
            maxValueSpan: 5,
            minValueSpan: 5,
          },
        ],
        xAxis: [
          {
            name: "数量",
            type: "value",
            position: "top",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            //   splitLine: { show: false },
            axisTick: { show: false },
          },
          {
            name: "百分率:%",
            min: 0,
            max: 100,
            type: "value",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
          },
        ],
        yAxis: [
          {
            type: "category",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              formatter: (value) => truncateStr(value, 15),
            },
            axisTick: {
              show: false,
            },
            data: [],
          },
        ],
        series: [
          {
            xAxisIndex: 0,
            name: "",
            type: "bar",
            barMaxWidth: 15,
            barGap: 0,
            data: [],
          },
          {
            xAxisIndex: 0,
            name: "",
            type: "bar",
            barMaxWidth: 15,
            data: [],
          },
          {
            name: "",
            xAxisIndex: 1,
            data: [],
            type: "line",
          },
        ],
      },

      // 弹窗3
      activeIndex3: -1,
      showInfoType3: 1,
      mode6: 1,
      popupShow3: false,
      popupTitle3: "",
      managerHatNoEleDetailChart: {},
      option6: {
        color: ["#2662f9", "#4ab27c", "#ff9308", "#ecd61d"],
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data;
              if (params[1]) {
                result +=
                  "</br>" + " " + params[1].seriesName + " : " + params[1].data;
              }
              if (params[2]) {
                result +=
                  "</br>" + " " + params[2].seriesName + " : " + params[2].data;
              }
            }
            return result;
          },
        },
        legend: {
          data: ["Profit", "Income", "近七日平均出勤率"],
        },
        grid: {
          left: "3%",
          top: "8%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside", // 放大和缩小
            orient: "vertical",
            maxValueSpan: 5,
            minValueSpan: 5,
          },
        ],
        xAxis: [
          {
            name: "数量",
            type: "value",
            position: "top",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            //   splitLine: { show: false },
            axisTick: { show: false },
          },
          {
            name: "百分率:%",
            min: 0,
            max: 100,
            type: "value",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
          },
        ],
        yAxis: [
          {
            type: "category",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              formatter: (value) => truncateStr(value, 10),
            },
            axisTick: {
              show: false,
            },
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
        ],
        series: [
          {
            xAxisIndex: 0,
            name: "",
            type: "bar",
            barGap: 0,
            barMaxWidth: 15,
            data: [],
          },
          {
            xAxisIndex: 0,
            name: "",
            type: "bar",
            barMaxWidth: 15,
            data: [],
          },
          {
            name: "",
            xAxisIndex: 1,
            data: [],
            type: "line",
          },
        ],
      },
    };
  },
  watch: {
    orgOptions(newValue, oldValue) {
      if (newValue != oldValue) {
        this.companyNameList = newValue.map((item) =>
          truncateStr(item.org_name, 10)
        );
        this.companyIdList = newValue.map((item) => item.org_id);

        let idx = this.companyIdList.indexOf(this.orgLevel);
        if (idx == -1) {
          idx = 0;
        }
      }
    },
    orgLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.org_id = this.orgLevel;
        this.getAll();
      }
    },
    districtLevel: function (newValue) {
      // console.log(newValue)
      if (newValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
        this.getAll();
      }
    },
    districtScale: function (newValue) {
      // console.log(newValue,oldValue)
      if (newValue) {
        this.mapLevel = newValue;
      }
    },
  },
  mounted() {
    const token = this.$route.query.token;
    window.token = token;
    this.getAll();
  },
  methods: {
    showDetail(val) {
      if (val === 1) {
        this.popupShow1 = true;
        this.showInfoType1 = 1;
        this.popupTitle1 = "劳动力出勤情况";
        this.getPopData1(2);
      } else if (val === 2) {
        this.popupShow2 = true;
        this.showInfoType2 = 1;
        this.popupTitle2 = "劳务人员安全帽无电情况";
        this.getPopData2(2);
      } else if (val === 3) {
        this.popupShow3 = true;
        this.showInfoType3 = 1;
        this.popupTitle3 = "管理人员安全帽无电情况";
        this.getPopData3(2);
        //
      }
    },
    // 获取页面图表左边三个
    getAll() {
      this.getLaborAttendanceChart(2);
      this.getlaborHatNoEleChart(2);
      this.getManagerHatNoEleChart(2);
    },
    getLaborAttendanceChart(mode) {
      this.mode1 = mode;

      nation
        .getHatOrgCityAttendanceCollect(
          2,
          this.mode1,
          this.orgLevel,
          this.districtLevel
        )
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.search_keys1 = d.search_keys;
          this.option.legend.data = d.legend_data;
          this.option.xAxis[0].data = d.xAxis_data;
          this.option.series[0].data = d.bar_series[0];
          this.option.series[0].name = d.legend_data[1];

          this.option.series[1].data = d.bar_series[1];
          this.option.series[1].name = d.legend_data[2];
          this.option.series[2].data = d.line_series;
          this.option.series[2].name = d.legend_data[0];
          this.laborAttendanceChart = applyUpdate(chart, this.option);
        });
    },
    getlaborHatNoEleChart(mode) {
      this.mode2 = mode;
      nation
        .getHatOrgCityNeWorkerCollect(
          2,
          this.mode2,
          this.orgLevel,
          this.districtLevel
        )
        .then((d) => {
          // this.troubleTypeAllSearchKey = d.search_keys;
          let chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.search_keys2 = d.search_keys;
          this.option2.legend.data = d.legend_data;
          this.option2.xAxis.data = d.xAxis_data;
          this.option2.series[0].name = d.legend_data[1];
          this.option2.series[0].data = d.bar_series[0];
          this.option2.series[1].name = d.legend_data[2];
          this.option2.series[1].data = d.bar_series[1];
          this.option2.series[2].name = d.legend_data[0];
          this.option2.series[2].data = d.line_series;

          this.laborHatNoEleChart = applyUpdate(chart, this.option2);
        });
    },
    getManagerHatNoEleChart(mode) {
      this.mode3 = mode;
      nation
        .getHatOrgCityManagerCollect(
          2,
          this.mode3,
          this.orgLevel,
          this.districtLevel
        )
        .then((d) => {
          // this.troubleTypeAllSearchKey = d.search_keys;
          let chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.search_keys3 = d.search_keys;
          this.option3.legend.data = d.legend_data;
          this.option3.xAxis.data = d.xAxis_data;
          this.option3.series[0].name = d.legend_data[1];
          this.option3.series[0].data = d.bar_series[0];
          this.option3.series[1].name = d.legend_data[2];
          this.option3.series[1].data = d.bar_series[1];
          this.option3.series[2].name = d.legend_data[0];
          this.option3.series[2].data = d.line_series;

          this.managerHatNoEleChart = applyUpdate(chart, this.option3);
        });
    },

    // 图表1点击事件
    laborAttendanceChartClick(params) {
      this.activeIndex1 = params.dataIndex;
      this.popupShow1 = true;
      this.popupTitle1 =
        "劳动力出勤情况详情（" +
        this.laborAttendanceChart.xAxis[0].data[params.dataIndex] +
        "）";
      this.showInfoType1 = 2;
      this.getPopData1(2);
    },
    // 图表2点击事件
    laborHatNoEleChartClick(params) {
      this.activeIndex2 = params.dataIndex;
      this.popupShow2 = true;
      this.popupTitle2 =
        "劳务人员安全帽无电情况（" +
        this.laborHatNoEleChart.xAxis.data[params.dataIndex] +
        "）";
      this.showInfoType2 = 2;
      this.getPopData2(2);
    },
    // 图表3点击事件
    managerHatNoEleChartCLick(params) {
      console.log(this.orgType, this.mapLevel);
      if (this.orgType != 2 && this.mapLevel != 2) {
        this.activeIndex3 = params.dataIndex;
        this.popupShow3 = true;
        this.popupTitle3 =
          "管理人员安全帽无电情况（" +
          this.managerHatNoEleChart.xAxis.data[params.dataIndex] +
          "）";
        this.showInfoType3 = 2;
        this.getPopData3(2);
      }
    },
    // 弹窗1

    getPopData1(mode) {
      this.mode4 = mode;

      if (
        (this.mapLevel == 2 && this.showInfoType1 == 2) ||
        (this.orgType == 2 && this.showInfoType1 == 2)
      ) {
        project
          .getHatAttendanceCollect(
            1,
            this.activeIndex1 > -1 ? this.search_keys1[this.activeIndex1] : "",
            this.mode4
          )
          .then((d) => {
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option4.series.forEach((item) => (item.data = []));
            this.laborAttendanceDetailChart = {};
            this.option4.legend.data = d.legend;
            this.option4.yAxis[0].data = d.xAxis_data;
            this.option4.series[0].data = d.bar_series[0];
            this.option4.series[0].name = d.legend[1];

            this.option4.series[1].data = d.bar_series[1];
            this.option4.series[1].name = d.legend[2];
            this.option4.series[2].data = d.line_series;
            this.option4.series[2].name = d.legend[0];
            this.laborAttendanceDetailChart = applyUpdate(chart, this.option4);
          });
      } else {
        nation
          .getHatOrgCityAttendanceCollect(
            1,
            this.mode4,
            this.mapLevel == 0 && this.activeIndex1 > -1
              ? this.search_keys1[this.activeIndex1]
              : this.orgLevel,
            this.mapLevel == 1 && this.activeIndex1 > -1
              ? this.search_keys1[this.activeIndex1]
              : this.districtLevel
          )
          .then((d) => {
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option4.legend.data = d.legend_data;
            this.option4.yAxis[0].data = d.xAxis_data;
            this.option4.series[0].data = d.bar_series[0];
            this.option4.series[0].name = d.legend_data[1];

            this.option4.series[1].data = d.bar_series[1];
            this.option4.series[1].name = d.legend_data[2];
            this.option4.series[2].data = d.line_series;
            this.option4.series[2].name = d.legend_data[0];
            this.laborAttendanceDetailChart = applyUpdate(chart, this.option4);
          });
      }
    },
    closePop1() {
      this.popupShow1 = false;
      this.activeIndex1 = -1;
    },

    // 弹窗2
    getPopData2(mode) {
      // getHatNeWorkerCollect
      console.log(this.mapLevel, this.orgType, this.showInfoType2);
      this.mode5 = mode;

      let chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
      this.laborHatNoEleDetailChart = applyUpdate(chart, this.option5);
      if (
        (this.mapLevel == 2 && this.showInfoType2 == 2) ||
        (this.orgType == 2 && this.showInfoType2 == 2)
      ) {
        project
          .getHatNeWorkerCollect(
            1,
            this.activeIndex2 > -1 ? this.search_keys2[this.activeIndex2] : "",
            this.mode5
          )
          .then((d) => {
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option5.series.forEach((item) => (item.data = []));
            this.laborHatNoEleDetailChart = {};
            this.option5.legend.data = d.legend;
            this.option5.yAxis[0].data = d.xAxis_data;
            this.option5.series[0].data = d.bar_series[0];
            this.option5.series[0].name = d.legend[1];

            this.option5.series[1].data = d.bar_series[1];
            this.option5.series[1].name = d.legend[2];
            this.option5.series[2].data = d.line_series;
            this.option5.series[2].name = d.legend[0];
            this.laborHatNoEleDetailChart = applyUpdate(chart, this.option5);
          });
      } else {
        nation
          .getHatOrgCityNeWorkerCollect(
            1,
            this.mode5,
            this.mapLevel == 0 && this.activeIndex2 > -1
              ? this.search_keys2[this.activeIndex2]
              : this.orgLevel,
            this.mapLevel == 1 && this.activeIndex2 > -1
              ? this.search_keys2[this.activeIndex2]
              : this.districtLevel
          )
          .then((d) => {
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option5.legend.data = d.legend_data;
            this.option5.yAxis[0].data = d.xAxis_data;
            this.option5.series[0].data = d.bar_series[0];
            this.option5.series[0].name = d.legend_data[1];

            this.option5.series[1].data = d.bar_series[1];
            this.option5.series[1].name = d.legend_data[2];
            this.option5.series[2].data = d.line_series;
            this.option5.series[2].name = d.legend_data[0];
            this.laborHatNoEleDetailChart = applyUpdate(chart, this.option5);
          });
      }
    },
    closePop2() {
      this.popupShow2 = false;
      this.activeIndex2 = -1;
    },

    // 弹窗3
    getPopData3(mode) {
      this.mode6 = mode;

      // let chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
      // this.managerHatNoEleDetailChart = applyUpdate(chart, this.option6);

      nation
        .getHatOrgCityManagerCollect(
          1,
          this.mode6,
          this.mapLevel == 0 && this.activeIndex3 > -1
            ? this.search_keys3[this.activeIndex3]
            : this.orgLevel,
          this.mapLevel == 1 && this.activeIndex3 > -1
            ? this.search_keys3[this.activeIndex3]
            : this.districtLevel
        )
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option6.legend.data = d.legend_data;
          this.option6.yAxis[0].data = d.xAxis_data;
          this.option6.series[0].data = d.bar_series[0];
          this.option6.series[0].name = d.legend_data[1];

          this.option6.series[1].data = d.bar_series[1];
          this.option6.series[1].name = d.legend_data[2];
          this.option6.series[2].data = d.line_series;
          this.option6.series[2].name = d.legend_data[0];
          this.managerHatNoEleDetailChart = applyUpdate(chart, this.option6);
        });
    },
    closePop3() {
      this.popupShow3 = false;
      this.activeIndex3 = -1;
    },
  },
};
</script>

<style scoped lang="less">
.mon-change {
  display: flex;
  margin: 1rem;
  justify-content: space-between;
  align-items: center;
}
.mon-item {
  padding: 0.5rem;
  border: 1px solid #52b3ff;
  border-radius: 4px;
}
.active-mon {
  background-color: #52b3ff;
}
.img-left {
  position: absolute;
  top: 5rem;
  left: 3rem;
  width: 3rem;
  z-index: 9;
  cursor: pointer;
}
.img-right {
  position: absolute;
  top: 5rem;
  right: 3rem;
  width: 3rem;
  z-index: 9;
  cursor: pointer;
}
.img {
  width: 2.5rem;
  cursor: pointer;
}
.attendance-bottom {
  margin: 0 2rem;
}
.tooltips {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  // margin-bottom: 1rem;
  font-size: 0.6rem;
  color: #abbbbf;
  font-weight: bold;
}
.items {
  display: flex;
  align-items: center;
}
.tooltip-item {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.3rem;
}
.tooltip-item1 {
  background-color: #23cf9e;
}
.tooltip-item2 {
  background-color: #eab91e;
}
.tooltip-item3 {
  background-color: #e02f37;
}
.linebar {
  width: 100%;
}
.line-bar {
  display: flex;
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #4ebb8c;
  border-radius: 12px;
}
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  z-index: 99 !important;
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>