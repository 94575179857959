// import { data } from 'jquery';

const baseUrl = process.env.VUE_APP_API_BASE_ZGS_URL


const areaCodeMap = require('/src/area-code-map.json');

const handleRes = (res) => {
    if (res.statusCode < 200 || res.statusCode >= 300) {
      return res.response.text().then(e => Promise.reject(e))
    } else {
      return res.response.json().then(_d => {
        if (_d.code !== 0 && _d.code !== 200) {
          return Promise.reject(_d)
        } else if (_d.data) {
          _d.data.__count__ = _d.count;
          return _d.data;
        } else{
          return _d;
        }
      })
    }
  }
// function addFilterParams(url) {
//       // for(var key in areaCodeMap){
//       //   if(key == window.$districtLevel){
//       //     console.log(areaCodeMap[key]);
//       //   }
//       // }
//       formattingOrgId();
//       const containsArea = url.indexOf('areaLvl=') >= 0;
//       const containsOrg = url.indexOf('orgLvl=') >= 0;
//       if (!containsArea || !containsOrg) {
//         const sign = url.indexOf('?') >= 0 ? '&' : '?';
//         const areaStr = `areaLvl=${window.$districtLevel || '100000'}`;
//         const orgStr = `orgLvl=${window.$orgLevel || ''}`;
//         if (!containsArea && !containsOrg) {
//           return url + sign + areaStr + '&' + orgStr;
//         } else {
//           return url + sign + (!containsArea ? areaStr : orgStr);
//         }
//       } else {
//         return url;
//       }
//     // const sFIDArea = url.indexOf('sFID=') >= 0;
//     // if (!sFIDArea) {
//     //   // const sign = url.indexOf('?') >= 0 ? '&' : '?';
//     //   formattingOrgId();
//     //   return url;
//     //   // const orgStr = `orgLvl=${window.$sFid || ''}`;
//     //   // return url + sign + orgStr;
//     // } else {
//     //   return url;
//     // }
//   }

  function addFilterParams2(url) {
    // 忽略地区接口
    if(url.indexOf("user_num_of_all_cities")!=-1
     || url.indexOf("county_user_nums")!=-1 ){
      return url;
    }
    var provinceName = '';
    var cityName = '';
    for(var key in areaCodeMap){
      if(key == window.$districtLevel){
        let str = areaCodeMap[key];
        if(str != '' && str != null){
          var datalist = str.split("/");
          if(datalist.length >= 1){
            provinceName = datalist[0];
            cityName = datalist[1];
          }
        }
      }
    }
    const provinceExist = url.indexOf('provinceName=') >= 0;
    const cityExist = url.indexOf('cityName=') >= 0;
    if(!provinceExist || !cityExist) {
      const sign = url.indexOf('?') >= 0 ? '&' : '?';
      const provinceStr = `provinceName=${provinceName || ''}`;
      const cityStr = `cityName=${cityName || ''}`;
      if (!provinceExist && !cityExist) {
        return url + sign + provinceStr + '&' + cityStr;
      } else {
        return url + sign + (!provinceExist ? provinceStr : cityStr);
      }
    } else {
      return url;
    }
}

/** @param url {string}
 * @return Promise*/
 const get = url => fetch(addFilterParams2(baseUrl + url)).then(res => ({ response: res, statusCode: res.status })).then(handleRes)

// const post = (url, body, type = 'json') => {
//     let headers, _body
//     if (type === 'json') {
//       headers = { 'Content-Type': 'application/json','traditional':true}
//       // headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
//       _body = JSON.stringify(body)
//     } else if (type === 'file') {
//       _body = new FormData()
//       _body.append('files', body)
//     } else {
//       _body = body
//     }
//     if (url.slice(0, 4) != 'http') {
//       url =baseUrl + url;
//     }
//     return fetch(addFilterParams2(url), { method: 'POST', headers, body: _body })
//       .then(res => ({ response: res, statusCode: res.code })).then(handleRes)
//   }
// function formattingOrgId(){
//   let orgId = window.$sFid;
//   if(orgId !== null && orgId !== undefined && orgId!=='' ){
//     if(orgId.indexOf(".ogn")==0){
//         window.$sFid = '/'+window.$sFid+'.ogn';
//     }
//   }else{
//     window.$sFid = "/7B867F6775E143F8B1F3131BFA85C13E.ogn";
//   }
// }
// function verifyOrgId(sFID){
//   if(sFID === '' || sFID === undefined || sFID === null)
//       sFID = "/7B867F6775E143F8B1F3131BFA85C13E.ogn";
//   return sFID
// }
// 通过年处理日期范围
// function formattingDateIntervalByYear(year){
//   if(year != null && year !=""){
//     let dateInterval = new Array();
//     dateInterval.push(year+"-01");
//     dateInterval.push(year+"-12");
//     return dateInterval;
//   }
// }
const quality = {
  // 导播图
  getMeritOrders: ()=> get(`pingZhi/gmgdTitle?orgId=${window.$sFid}`),
  // 地图数据
  getMeritMap: ()=> get(`pingZhi/gmgdMap?orgId=${window.$sFid=='7B867F6775E143F8B1F3131BFA85C13E'?"":window.$sFid}`),
  // 观摩工地情况
  getSeeSiteCondition: ()=> get(`pingZhi/gmgdQK?orgId=${window.$sFid=='7B867F6775E143F8B1F3131BFA85C13E'?"":window.$sFid}`),
  // 履约检查红榜
  getCheckRedList: (year,quarter)=> get(`pingZhi/showHb?orgId=${window.$sFid=='7B867F6775E143F8B1F3131BFA85C13E'?"":window.$sFid}&fnd=${year}&fyf=${quarter}`),
  // 履约检查黑榜
  getCheckBlackList: (year,quarter)=> get(`pingZhi/showHb2?orgId=${window.$sFid=='7B867F6775E143F8B1F3131BFA85C13E'?"":window.$sFid}&fnd=${year}&fyf=${quarter}`),
  
}

export { baseUrl, quality }
