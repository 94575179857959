<template>
  <div id="content">
    <div class="top-item">
      <div class="chart_left">

        <wrapper style="padding: 0; height: 49%;">
          <div
            class="info-block"
            style="min-height:5rem"
          >
            <div
              class="info-block-title"
              style="top:0.5rem"
            >
              违规预警统计
            </div>

            <div style="max-height:23rem;min-height:12rem;padding-top:2rem">
              <div style="height:15rem;line-height:1.6rem;font-size:0.9rem;color:#bfcfd2;font-weight:bold;overflow:hidden;margin-bottom:-0.5rem">
                <div
                  v-if="warningNoticeChart.series && [].concat.apply([], warningNoticeChart.series.map(x => x.data)).length == 0"
                  style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 22rem; height: 12rem;margin-top: 0.5rem;padding-top:3rem"
                >
                  <div class="empty-chart-icon" />
                  <div style="margin-top:20px;color:#ccc">暂无数据</div>
                </div>
                <v-chart
                  v-else
                  :options="warningNoticeChart"
                  ref="addtenceType1"
                  @mouseover="warningNoticeMouseOver1"
                  @mouseout="warningNoticeMouseOut1"
                  autoresize
                />
              </div>
            </div>

          </div>
        </wrapper>
        <wrapper style="padding: 0;margin-top:1rem;height: 49%;">
          <div
            class="info-block"
            style="min-height:5rem"
          >
            <div
              class="info-block-title"
              style="top:0.5rem"
            >
              近七日预警趋势
            </div>

            <div style="max-height:23rem;min-height:12rem;padding-top:3rem">
              <div style="height:15rem;line-height:1.6rem;font-size:0.9rem;color:#bfcfd2;font-weight:bold;overflow:hidden;margin-bottom:-0.5rem">
                <div
                  v-if="warningTrendChart.series && [].concat.apply([], warningTrendChart.series.map(x => x.data)).length == 0"
                  style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 22rem; height: 12rem;margin-top: 0.5rem;padding-top:2rem"
                >
                  <div class="empty-chart-icon" />
                  <div style="margin-top:20px;color:#ccc">暂无数据</div>
                </div>
                <v-chart
                  v-else
                  :options="warningTrendChart"
                  autoresize
                />
              </div>
            </div>

          </div>
        </wrapper>
      </div>

      <div class="big-content">
        <div class="one-screen">
          <div class="content-title">
            <div style="display:flex;">
              {{activePonit.video_name}}
            </div>

          </div>
          <div
            class="board"
            style="padding-top:1rem"
          >
            <div
              v-show="Object.keys(activePonit).length === 0||!activePonit.url"
              style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
              class="no-video"
            >
              <img
                src="../../assets/nation/no_video.png"
                alt=""
              >
            </div>

            <div v-show="Object.keys(activePonit).length !== 0&&activePonit.url">
              <webrtc-streamer
                v-if="activePonit.type==1"
                :key="activePonit && activePonit.version"
                class="streamer"
                id="streamn"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=830&bitrate=0&rotation=0"
                height="590"
                notitle="true"
                :tooltip="activePonit.video_name"
              ></webrtc-streamer>
              <div
                v-show="activePonit.type==2||activePonit.type==3"
                ref="videon"
                id="videon"
              ></div>
            </div>
          </div>

        </div>

      </div>
      <div
        class="people-status-ai"
        style="margin-top:0rem"
      >
        <wrapper style="padding: 0;height:100% !important">
          <div
            class="info-block"
            style="min-height:5rem;margin-bottom:1rem"
          >
            <div
              class="info-block-title"
              style="top:0.5rem"
            >
              视频列表
            </div>
            <div class="list-height">
              <div style="height:100%;overflow-y:auto">

                <div
                  class="point-name sure-click"
                  v-for="point in videoList"
                  :key="point.serial_number"
                  @click="changePoint(point)"
                >
                  {{point.video_name}}
                </div>
              </div>
            </div>
          </div>
        </wrapper>
      </div>
    </div>
    <div class="bottom-item">

      <wrapper style="padding: 0;height: 100%;">
        <div
          class="info-block info-block-special"
          style="min-height:5rem"
        >
          <div
            class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 96%;"
          >
            <div>实时抓拍记录</div>
            <div
              class="detail"
              @click="moreInfo"
            >
              更多记录
            </div>
          </div>

          <div
            v-if="courseList.length"
            class="swiper-c"
            style="position:relative"
          >

            <swiper
              class="swiper"
              :options="swiperOption"
              ref="mySwiper"
            >
              <swiper-slide
                class="swiper-slide"
                v-for="(item,index) in courseList"
                :key="index"
              >
                <div
                  @click="changeActive(item,index)"
                  class="content-title"
                  style="height:2.5rem;width:100%;bottom:2.5rem; top: auto !important;cursor: pointer;"
                >
                  <div style="display:flex;">
                    {{item.createdtime}}
                  </div>

                  <div>
                    {{item.type_name}}
                  </div>

                </div>
                <img
                  @click="changeActive(item,index)"
                  :src="item.img_url"
                  alt=""
                  class="img"
                >
              </swiper-slide>
              <div
                class="swiper-pagination"
                slot="pagination"
              ></div>
            </swiper>
            <div
              class="swiper-button-prev"
              slot="button-prev"
              @click="prev"
            ></div>
            <div
              class="swiper-button-next"
              slot="button-next"
              @click="next"
            ></div>
          </div>

          <div
            v-else
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;text-align: center; padding-top: 4rem;"
          >
            <img
              style="width: 14rem;"
              src="../../assets/empty_chart.png"
              alt=""
            >
            暂无相关实时抓拍记录
          </div>

        </div>
      </wrapper>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popImageShow"
      :destroy-on-close='true'
      width="60rem"
      top="19rem"
    >
      <wrapper
        style="height:32rem;backgroundColor:#0e1328;position:relative;padding:0"
        class="popup-bg"
      >
        <div
          class="stats-close-btn"
          style="z-index: 99;"
          @click="popImageShow=false"
        />
        <div
          class="swiper-button-prev"
          @click="changeimage(-1)"
        ></div>
        <div class="image_info">
          <div
            class="content-title"
            style="height:2.5rem;width:100%; top: 0;"
          >
            <div style="display:flex;">
              {{activeImage.createdtime}}-{{activeImage.type_name}}
            </div>

          </div>
          <img
            :src="activeImage.img_url"
            alt=""
            class="dialog_img"
          >

        </div>
        <div
          class="swiper-button-next"
          @click="changeimage(1)"
        ></div>

      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popImageListShow"
      width="84rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:45rem;"
      >
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div
            class="stats-chart-block"
            style="height:43rem;width:84rem;"
          >
            <div
              class="stats-close-btn"
              @click="popImageHiden"
            />
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">实时抓拍记录 </div>
            <div class="pop_select_info">

              <div style="display:flex;margin-top:1rem">

                <div style="display:flex;justify-content:flex-end;margin-bottom:0.5rem;margin-right:1rem">
                  <CustomSelect
                    :options="videoTypeLIst"
                    @input="changeSelect($event, 'val1')"
                    style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
                  />
                </div>

                <div style="display:flex;justify-content:flex-end;margin-bottom:0.5rem">
                  <CustomSelect
                    :options="proCategoryList"
                    @input="changeSelect($event, 'val2')"
                    style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
                  />
                </div>
                <div style="display:flex;justify-content:flex-end;margin-bottom:0.5rem">

                  <el-date-picker
                    v-model="date1"
                    type="date"
                    placeholder="选择日期"
                    size="mini"
                    value-format="yyyy-MM-dd"
                    @change="dateChange"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="image_list">

                <div
                  v-if="popImageList.length === 0"
                  style="display:flex;flex-direction:column;align-items:center;justify-content:center;position: absolute; top: 30%; left: 38%;"
                >
                  <img
                    src="../../assets/empty_chart.png"
                    alt=""
                  >
                  暂无相关实时抓拍记录
                </div>
                <div
                  v-else
                  v-for="(item,index) in popImageList"
                  :key="index"
                  style="position:relative;margin-top:1rem;margin-right: 1rem;width:300px;height:170px"
                >
                  <div
                    @click="changeActive(item,index)"
                    class="content-title"
                    style="height:2.5rem;width:100%;bottom:0rem; top: auto !important;z-index:1;cursor: pointer;"
                  >
                    <div style="display:flex;">
                      {{item.type_name}}
                    </div>

                  </div>
                  <img
                    @click="changeActive(item,index)"
                    :src="item.img_url"
                    alt=""
                    class="img_all"
                  >
                </div>
              </div>

              <div
                style="text-align: center; width: 100%; height: 2rem;position: absolute; bottom: 1.5rem;"
                v-if="popImageList.length"
              >
                <el-pagination
                  class="page-change"
                  background
                  layout="prev, pager, next"
                  :current-page.sync="securityStarPage"
                  :page-count="securityStarPages"
                  @current-change="securityStarPageChange"
                  style="text-align:center;margin-top:0.5rem"
                ></el-pagination>

              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
var self;
require("echarts-gl");
import wrapper from "../../components/wrap";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { project } from "../../api";
import hlsPlayer from "xgplayer-hls.js";
import FlvPlayer from "xgplayer-flv.js";
import { colors, popupChartTemplate } from "./pieChartTemplates";
import { singleLineChartTemplate } from "./lineChartTemplates";
import { applyUpdate } from "../../utils";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import CustomSelect from "../../components/select";
// import { cubeBarChartTemplate } from "./barChartTemplates";
// import { truncateStr } from "../../utils";
import EZUIKit from "./ezuikit.js";

export default {
  name: "Video",
  components: {
    "v-chart": ECharts,
    wrapper,
    Swiper,
    SwiperSlide,
    CustomSelect: CustomSelect,
  },
  props: {
    broadcastDetails: Object,
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    gotoProject: Function,
    compData: Object,
  },
  data() {
    const sDate = this.getDate();
    return {
      projectName: "",
      videoList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      activePonit: {},

      securityStarPage: 1,
      securityStarPages: 1,

      activeType: "精益成本建造",
      //视频播放
      isPlay: false,
      player: "",

      testVideoPlayingInterval: null,
      inited: false,
      lastPageSwitched: new Date() / 1000,
      videoPlayingTestsDone: 0,
      nextVideoPlayingTestDelay: 0,

      warningNoticeChart: {},
      warningNoticeChartHighlightIndex2: 0,
      warningTrendChart: {},
      warningTrendChartTemplate: {
        color: ["#FDC81E", "#2D7DFF"],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "circle",
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 12,
          textStyle: {
            color: "#ccc",
            fontWeight: "bold",
            fontSize: "11",
          },
          padding: [8, 0, 0, 0],
          top: "4%",
          // right: '3%',
          data: [],
        },
        grid: {
          left: "0%",
          right: "0%",
          top: "15%",
          bottom: "1%",
          containLabel: true,
        },
        yAxis: [
          {
            name: "预警数",
            nameTextStyle: {
              color: "#fff",
              padding: [0, 0, 0, 15],
            },
            type: "value",
            show: true,
            axisLine: {
              show: true,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: [5, 10],
                color: "#14253f",
                width: 2,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontSize: 14,
              fontWeight: "bold",
              // formatter: function(value) {
              //   return value ? value + '%' : value;
              // }
            },
          },
        ],
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: true,
          splitNumber: 7,
          axisLabel: {
            color: "#e6f2f3",
            fontSize: 14,
            fontWeight: "bold",
            rotate: 30,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#22344b",
            },
          },
        },
        series: [],
      },

      activeImageIndex: 0,
      activeImage: {},
      popImageShow: false,
      popImageListShow: false,
      courseList: [],
      popImageList: [],
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 20,
        slidesPerGroup: 5,
        loop: false,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      videoTypeLIst: [""],
      videoTypeIdLIst: [""],
      proCategoryList: [],
      proIdList: [],
      videoType: "",
      proCategory: "",
      date1: sDate,
      activePopShow: "courseList",
    };
  },
  mounted() {
    self = this;
    const token = this.$route.query.token;
    window.token = token;
    window.$districtLevel = "";
    this.videoPlayingTestsDone = 0;
    this.lastPageSwitched = new Date() / 1000;
    this.getData();
  },
  beforeDestroy() {
    if (this.activePonit.type == 1) {
      this.disconnectStreams();
    } else {
      this.disconnectFlv();
    }
    if (this.testVideoPlayingInterval) {
      clearInterval(this.testVideoPlayingInterval);
    }
  },
  methods: {
    getData() {
      project.getProjVideoAlarm().then((d) => {
        let chart = JSON.parse(JSON.stringify(popupChartTemplate));
        chart.series[0].label.normal.formatter = "{value|{c}}\n{text|{b}}";
        chart.color = colors;

        chart.legend.data = [];
        d.data.forEach((e) => {
          chart.legend.data.push(e.name, e.value);
        });
        console.log(chart);
        chart.legend = Object.assign({}, chart.legend, {
          type: "scroll",
          textStyle: {
            color: "#fff",
            padding: [2, 0, 0, 0],
          },
          pageIconColor: "#fff",
          left: "60%",
          formatter: function (name) {
            for (var i = 0; i < d.data.length; i++) {
              if (d.data[i].name === name) {
                return name + " " + d.data[i].value;
              }
            }
          },
        });

        chart.series[0].data = d.data;
        if (window.innerWidth < 2250) {
          chart.series[0].radius = [35, 65];
        }

        // 默认高亮第一个值不是0的分片，否则默认第一片
        this.warningNoticeChartHighlightIndex2 = 0;
        // for (let i = 0; i < d.data.length; i++) {
        //   if (d.data[i].value) {
        //     this.warningNoticeChartHighlightIndex2 = i;
        //     break;
        //   }
        // }

        // chart.series.push(
        //   Object.assign({}, chart.series[0], {
        //     label: {
        //       fontSize: 11,
        //       formatter: "{b}：{c}",
        //     },
        //     silent: true,
        //   })
        // );

        setTimeout(
          () =>
            this.$refs.addtenceType1 &&
            this.$refs.addtenceType1.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: this.warningNoticeChartHighlightIndex2,
            }),
          100
        );
        console.log(chart, "=999");
        this.warningNoticeChart = chart;
      });

      project.getProjVideoDays7().then((d) => {
        const chart = JSON.parse(JSON.stringify(singleLineChartTemplate));
        this.warningTrendChartTemplate.legend.data = d.legend_data;
        this.warningTrendChartTemplate.xAxis.data = d.xAxis_data;
        this.warningTrendChartTemplate.series = [];
        d.series.forEach((vv, index) => {
          this.warningTrendChartTemplate.series.push({
            name: d.legend_data[index],
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                },
              },
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(27, 208, 128, 0.5)" },
                { offset: 1, color: "rgba(27, 208, 128, 0)" },
              ]),
            },
            // symbolSize: 0,
            data: vv,
          });
        });
        this.warningTrendChart = applyUpdate(
          chart,
          this.warningTrendChartTemplate
        );
      });
      project
        .getVideoList()
        .then((d) => {
          d.forEach((item) => (item.version = 0));
          this.videoList = d;
          this.activePonit = d[0];
          var video = [...d];
          video.unshift({
            video_name: "所有视频",
            serial_number: "",
          });
          this.videoTypeLIst = video.map((item) => {
            return item.video_name;
          });
          this.videoTypeIdLIst = video.map((item) => {
            return item.serial_number;
          });
          this.updateVideos();
        })
        .catch((e) => console.error(e.msg || e.message));

      this.getBottomImage();

      project.getProjVideoAlarmType().then((d) => {
        d.unshift({
          value: "所有违规预警类型",
          key: "",
        });
        this.proCategoryList = d.map((item) => {
          return item.value;
        });
        this.proIdList = d.map((item) => {
          return item.key;
        });
      });
    },
    getBottomImage() {
      project.getProjVideoImages("", "", "", 1, 50).then((d) => {
        this.courseList = d;
      });
    },
    warningNoticeMouseOver1(params) {
      if (params.dataIndex != this.companyAttendanceChartHighlightIndex2) {
        this.$refs.addtenceType1.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.companyAttendanceChartHighlightIndex2,
        });
        this.companyAttendanceChartHighlightIndex2 = params.dataIndex;
      }
    },
    warningNoticeMouseOut1() {
      this.$refs.addtenceType1.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.companyAttendanceChartHighlightIndex2,
      });
    },
    getDate() {
      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      month = month > 9 ? month : "0" + month;
      day = day > 9 ? day : "0" + day;
      return `${year}-${month}-${day}`;
    },

    changePoint(point) {
      this.activePonit = point;
      // 当项目下无视频点位时销毁视频，显示无视频点位图片
      if (!this.videoList.length > 0) {
        return this.disconnectStreams();
      }
      //   测试
      // this.clearStatus();
      if (point.type == 1) {
        this.clearStatus();
      } else if (point.type == 4) {
        this.disconnectEZUKIT();
      } else {
        this.disconnectFlv();
      }
      this.updateVideos();
    },
    clearStatus() {
      this.videoPlayingTestsDone = 0;
      this.lastPageSwitched = new Date() / 1000;
      this.videoList = this.videoList.map((item) => {
        return {
          url: "",
          serial_number: item.serial_number,
          video_name: item.video_name,
          type: item.type,
        };
      });
      this.activePonit.url = "";
      this.inited = false;
    },

    disconnectStreams() {
      customElements.whenDefined("webrtc-streamer").then(() => {
        let streamElement = document.getElementById("streamn");
        if (streamElement) {
          streamElement.disconnectStream();
        }
      });
    },

    updateVideos() {
      if (this.testVideoPlayingInterval) {
        window.clearInterval(this.testVideoPlayingInterval);
        this.testVideoPlayingInterval = null;
      }
      if (this.inited) {
        this.disconnectStreams();
      } else {
        this.inited = true;
      }

      const currPage = 0;

      if (this.activePonit.type == 1) {
        this.activePonit.currentTime = 0;
        this.activePonit.realTime = 0;
        this.playVideo(this.activePonit, 0, currPage);
        this.testVideoPlayingInterval = window.setInterval(() => {
          this.testVideoPlaying(currPage);
        }, 500);
      } else if (this.activePonit.type == 2) {
        this.initPlayer(this.activePonit.url, "videon");
      } else if (this.activePonit.type == 3) {
        this.inithlvPlayer(this.activePonit.url, "videon");
      } else if (this.activePonit.type == 4) {
        if (this.activePonit.url) {
          this.initEZUIKitPlayer(
            this.activePonit.url,
            "videon",
            this.activePonit.token
          );
        }
      }
    },

    playVideo(camera, index, currPage) {
      customElements.whenDefined("webrtc-streamer").then(() => {
        let streamElement = document.getElementById("streamn");
        if (streamElement) {
          streamElement.shadowDOM
            .getElementById("video")
            .addEventListener("waiting", function () {
              if (camera.reloadOnWaiting) {
                camera.reloadOnWaiting = false;
                camera.url = null;
                self.playVideo(camera, index, currPage);
              }
            });
          streamElement.shadowDOM
            .getElementById("video")
            .addEventListener("playing", function () {
              camera.reloadOnWaiting = true;
            });
        }
      });
      if (camera.url) {
        let streamElement = document.getElementById("streamn");
        let url = { video: camera.url, audio: undefined };
        let urlJson = JSON.stringify(url);
        if (streamElement.getAttribute("url") != urlJson) {
          streamElement.disconnectStream();
        } else {
          streamElement.setAttribute("url", null);
        }
        setTimeout(() => {
          streamElement.setAttribute("url", urlJson);
        }, 100);
      } else {
        project
          .getVideoUrl(camera.serial_number)
          .then((res) => {
            if (res.result && res.result.url) {
              customElements
                .whenDefined("webrtc-streamer")
                .then(() => {
                  camera.url = res.result.url;
                  let streamElement = document.getElementById("streamn");
                  let url = { video: res.result.url, audio: undefined };
                  let urlJson = JSON.stringify(url);
                  if (streamElement.getAttribute("url") != urlJson) {
                    streamElement.disconnectStream();
                  } else {
                    streamElement.setAttribute("url", null);
                  }
                  setTimeout(() => {
                    streamElement.setAttribute("url", urlJson);
                  }, 100);
                })
                .catch((e) => {
                  console.log(
                    "webrtc-streamer webcomponent fails to initialize error:" +
                      e
                  );
                });
            }
          })
          .catch((e) => console.error(e.msg || e.message));
      }
    },

    testVideoPlaying(currPage) {
      let delay;
      if (this.videoPlayingTestsDone == 0) {
        this.nextVideoPlayingTestDelay = 0;
        delay = 5;
      } else if (this.videoPlayingTestsDone < 3) {
        delay = 5;
      } else if (this.videoPlayingTestsDone < 10) {
        delay = 10;
      } else {
        delay = 15;
      }

      let delayPassed = false;
      if (
        new Date() / 1000 - this.lastPageSwitched >=
        this.nextVideoPlayingTestDelay + delay
      ) {
        delayPassed = true;
        this.videoPlayingTestsDone++;
        this.nextVideoPlayingTestDelay =
          new Date() / 1000 - this.lastPageSwitched;
      }

      customElements.whenDefined("webrtc-streamer").then(() => {
        let streamElement = document.getElementById("streamn");
        if (streamElement) {
          const videoElem = streamElement.shadowDOM.getElementById("video");
          const online =
            videoElem.readyState > 0 ||
            videoElem.getBoundingClientRect().height > 155;
          const stalled =
            online &&
            videoElem.currentTime == (this.activePonit.currentTime || 0) &&
            new Date() / 1000 - (this.activePonit.realTime || 8000000000) >= 7;

          this.activePonit.online = online;
          if (
            !online ||
            stalled ||
            this.activePonit.currentTime != videoElem.currentTime
          ) {
            this.activePonit.currentTime = videoElem.currentTime;
            this.activePonit.realTime = new Date() / 1000;
          }

          if (stalled || (delayPassed && !online)) {
            this.activePonit.version++;

            if (!online && this.videoPlayingTestsDone >= 3) {
              this.activePonit.url = null;
            }
            this.activePonit = JSON.parse(JSON.stringify(this.activePonit));
            setTimeout(() => {
              this.playVideo(this.activePonit, 0, currPage);
            }, 1000);
          }
        }
      });
    },
    initPlayer(url, id) {
      // console.log("this.playerhhhhh");
      this.isPlay = true;
      this.player = new FlvPlayer({
        id: id,
        url: url,
        isLive: true,
        autoplay: true, //自动播放，设置自动播放必要参数
        autoplayMuted: true, //自动播放静音，设置自动播放参数必要参数
        cors: true,
        fluid: true,
      });
    },
    inithlvPlayer(url, id) {
      this.isPlay = true;
      this.player = new hlsPlayer({
        id: id,
        url: url, //'//sf1-cdn-tos.huoshanstatic.com/obj/media-fe/xgplayer_doc_video/hls/xgplayer-demo.m3u8'
        isLive: true,
        autoplay: true, //自动播放，设置自动播放必要参数
        autoplayMuted: true, //自动播放静音，设置自动播放参数必要参数
        cors: true,
        fluid: true,
      });
    },

    initEZUIKitPlayer(url, id, accessToken) {
      this.player = new EZUIKit.EZUIKitPlayer({
        id: id, // 视频容器ID
        accessToken: accessToken,
        url: url,
        template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
        // footer: ["fullScreen"], // 加载插件，talk-对讲
        audio:0,
        width: 1100,
        height: 600,
      });
    },
    disconnectEZUKIT() {
      if (this.player&&this.activePonit.url) {
        this.player.stop();
      }
    },
    disconnectFlv() {
      if (this.player) {
        this.player.destroy();
      }
      this.player = "";
      console.log("销毁了");
    },

    changeActive(item, index) {
      this.popImageShow = true;
      this.activeImageIndex = index;
      this.activeImage = item;
    },
    prev() {
      this.$refs.mySwiper.$swiper.slidePrev();
    },
    next() {
      this.$refs.mySwiper.$swiper.slideNext();
    },
    changeimage(type) {
      if (type == -1) {
        if (this.activeImageIndex + type >= 0) {
          this.activeImageIndex = this.activeImageIndex + type;
        }
      } else if (type == 1) {
        if (
          this.activeImageIndex + type <=
          this[this.activePopShow].length - 1
        ) {
          this.activeImageIndex = this.activeImageIndex + type;
        }
      }
      this.activeImage = this[this.activePopShow][this.activeImageIndex];
    },
    moreInfo() {
      this.popImageListShow = true;
      this.activePopShow = "popImageList";
      this.videoType = "";
      this.date1 = this.getDate();
      this.proCategory = "";
      this.securityStarPage = 1;
      this.securityStarPages = 1;
      this.getPopImageListInfo();
    },
    popImageHiden() {
      this.popImageListShow = false;
      this.activePopShow = "courseList";
    },
    getPopImageListInfo() {
      project
        .getProjVideoImages(
          this.videoType,
          this.date1,
          this.proCategory,
          this.securityStarPage,
          12
        )
        .then((d) => {
          this.popImageList = d;
          this.securityStarPages = Math.ceil(d.__count__ / 12);
        });
    },
    changeSelect(option, val) {
      if (val === "val1") {
        var index = this.videoTypeLIst.indexOf(option);
        this.videoType = this.videoTypeIdLIst[index];
      } else if (val === "val2") {
        var index2 = this.proCategoryList.indexOf(option);
        this.proCategory = this.proIdList[index2];
      }
      this.securityStarPage = 1;
      this.getPopImageListInfo();
    },
    dateChange() {
      this.securityStarPage = 1;
      this.getPopImageListInfo();
    },
    securityStarPageChange(currPage) {
      this.securityStarPage = currPage;
      this.getPopImageListInfo();
    },
  },
};
</script>
<style lang="less">
.pop_select_info .el-input__inner {
  justify-content: space-around;
  margin-left: 1rem;
  width: 12rem !important;
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
  width: 160px !important;
}
.el-input__prefix {
  left: 1rem !important;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 180px !important;
}
.people-status-ai {
  .inline-input {
    margin: 1rem;
    width: 90% !important;
    .el-input__inner {
      background-color: #0c2341 !important;
      border: 1px solid #1782a6 !important;
      height: 2.5rem !important;
    }
  }
  .el-tree-node__content {
    color: #5accf1;
    background-color: #0f1f3c;
  }
}
.big-content {
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .btn-next {
    padding: 0;
    border: 1px solid #5f7ec9;
    background-color: #21325c;
    padding: 0 0.5rem;
    margin: 0;
  }
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .el-pager li {
    margin: 0;
    border: 1px solid #5f7ec9;
    background-color: #21325c;
    border-radius: 0;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #21325c;
    color: #5accf1;
  }
}
#main {
  // height: calc(100vh - 2rem) !important;
}
</style>
<style scoped lang="less">
.detail {
  cursor: pointer;
  z-index: 9;
}
.image_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // justify-content: space-around;
  // align-content: space-around;
}
#content {
  margin-top: 0rem;
}
.screen-division {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
}
.division-item {
  width: 4rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  background: #21325c;
  border: 1px solid #5f7ec9;
  cursor: pointer;
}
.page-change {
  z-index: 999;
}
.active-division-type {
  color: #5accf1;
}
.content-title {
  z-index: 999;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to right, #0f1f3c, rgba(255, 255, 255, 0));
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
}
.board {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  padding-top: 3rem;
}
.no-video {
  position: absolute;
  top: 30%;
  left: 40%;
}

.top-item {
  height: 40rem;
  //   height: 39rem;

  width: calc(100vw - 4rem);
  display: flex;
}
.list-height {
  height: 40rem;
  padding-top: 3rem;
}
.people-status-ai {
  width: 23rem;
  margin-top: 1rem;
  margin-left: 0.5rem;
}
.chart_left {
  width: 23rem;
  margin-right: 0.5rem;
}
.big-content {
  width: calc(100vw - 50rem);
  height: 40rem;
  border: 1px solid #0d2b50;
  position: relative;
}
.bottom-item {
  height: 17rem;
  width: calc(100vw - 4rem);
  margin-top: 1rem;
}
.point-name {
  font-size: 16px;
  line-height: 1.8rem;
  font-weight: 400;
  color: #5accf1;
  margin-left: 1rem;
  text-decoration: underline;
}
.sure-click {
  cursor: pointer;
}
.one-screen {
  width: 100%;
  height: 100%;
}
.more-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
}
.info-block-special {
  background-image: url("../../assets/new_block.png");
}
.dialog_img {
  width: 100%;
  height: 31.8rem;
  position: relative;
}
.image_info {
  width: 100%;
}
.img {
  width: 100%;
  height: 200px;
  cursor: pointer;
}
.img_all {
  width: 302px;
  height: 170px;
  cursor: pointer;
}
.swiper-c {
  padding-top: 3rem;
  height: 19rem;
}
.swiper {
  width: 90%;
  height: 100%;
}
.swiper-content {
  font-weight: bold;
}
.swiper-slide {
  cursor: pointer;
  width: 15rem;
  height: 100%;
  text-align: center;
  margin-right: 2rem;
  position: relative;

  .content-title {
    background-image: linear-gradient(to right, rgb(33, 58, 119), #0f1f3c);
  }
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 1rem;
  font-weight: bold;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  border: 1px solid;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  border: 1px solid;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
}
.swiper-button-prev,
.swiper-button-next {
  top: 50%;
  color: #fff;
}
</style>
