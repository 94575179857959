<template>
    <div style="position:relative">
      <img
        src="../assets/spot.svg"
        class="dot"
      >
      <span class="title">{{title}}</span>
    </div>
</template>

<script>
export default {
  name: "infoItem",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.dot {
  width: 20px;
  vertical-align: middle;
}
.title {
  color: #44cac5;
  font-size: 1rem;
  font-weight: 400;
  margin-left: 0.5rem;
  vertical-align: middle;
}
</style>