<template>
  <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title">
      <div>预估工资产值率</div>
    </div>
       <div class = "detail-select"
        style="width:100%;display:flex;justify-content:flex-end"
      >
        <CustomSelect
          :options="yearList"
          :value="selectedYear"
          @input="categorySelect"
          style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
        />
      </div>
    
    <div style="height:17rem;padding-top:0.5rem">
      <div
          style="display:flex;justify-content:space-around;margin-bottom:-0.8rem"
        >
      <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')"
          >
            预估工资产值率
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')"
          >
            对比情况
          </div>
      </div>
      <div
        v-if="!wageRateChart.series || !wageRateChart.series[0].data.length"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart :options="wageRateChart" autoresize />
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import CustomSelect from '../../../../components/select'
  import {human} from '../../../../api_hr'
    import { colors } from '../../pieChartTemplates'

  export default {
    name: 'wageRateOddsChart',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.switchCompanyWorkersOption(this.companyWorkersOption);
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
         this.switchCompanyWorkersOption(this.companyWorkersOption);
        }
      }
    },
    data () {
      return {
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear()-1,
        wageRateChart: {},
        companyWorkersOption:"1"
        
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.switchCompanyWorkersOption(this.companyWorkersOption);
    },
    methods: {
      updateWageRateOddsChart() {
        human.getPerWageRate(this.selectedYear)
          .then(res=>{
          var gszt = res[0]==null?null:res[0].whole;
          var xList = [];
          var seriesData = [];
          for (let index = 0; index < res.length; index++) {
            xList.push(res[index].name);
            seriesData.push(res[index].value);
          }
          const option = {
            color:colors,
            title:{
              subtext:"公司整体:"+gszt,
              subtextStyle:{
                fontSize:14,
                color:'white'
              }
            },
            tooltip: {
              trigger: 'axis'
              },
              grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
              },
              xAxis: {
                  name:'单位',
                  type: 'category',
                  data:xList,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisLabel:{
                    interval:0,
                    rotate:40,
                    color:'white'
                  }
              },
              yAxis: {
                  nameTextStyle:{
                    color:"white",
                  },
                  axisLine: {
                    show: false,
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      type: [5, 10],
                      color: '#14253f',
                      width: 2,
                    }
                  },
                  type: 'value',
                  axisLabel: {
                     color: 'white'
                  }
              },
              series: [
                  {
                      name: '预估产值利润率',
                      type: 'line',
                      data: seriesData,
                      areaStyle:{}
                  }
                ]
          }
          this.wageRateChart = option;
          })
      },
      updateWageRateOddsCompareChart() {
        human.getPerWageRateCompare(this.selectedYear)
          .then(res=>{
            var xList = [];
            var seriesData = [];
            for (let i = 0; i < res.length; i++) {
              xList.push(res[i].name);
              seriesData.push(res[i].value);
              
            }
            const option = {
            color:colors,
            tooltip: {
              trigger: 'axis'
              },
              xAxis: {
                  name:'单位',
                  type: 'category',
                  data:xList,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisLabel:{
                    interval:0,
                    rotate:40,
                    color:'white'
                  }
              },
              yAxis: {
                  nameTextStyle:{
                    color:"white",
                  },
                  axisLine: {
                    show: false,
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      type: [5, 10],
                      color: '#14253f',
                      width: 2,
                    }
                  },
                  type: 'value',
                  axisLabel: {
                     color: 'white'
                  }
              },
              series: [
                  {
                      name: '预估产值利润率',
                      type: 'bar',
                      data: seriesData,
                      areaStyle:{}
                  }
                ]
            }
            this.wageRateChart = option;
          })
      },
      switchCompanyWorkersOption(choose){
        this.companyWorkersOption = choose;
        if(choose == "1"){
          this.updateWageRateOddsChart();
        }else{
          this.updateWageRateOddsCompareChart();
        }
      },
      categorySelect(option) {
        this.selectedYear = option;
        this.switchCompanyWorkersOption(this.companyWorkersOption);
      },
    },
  }
</script>

<style lang="less">
</style>
