import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
  };

  const buildingBoundaries = {
  };

  const buildingFinishedHeights = {
  };

  const doneFloors = {
  };

  const traceViews = {
    '_': {
      center: [0, 0],
      pitch: 60,
      zoom: 2,
      bearing: -40,
    },
  };

  function getUserZValue(/*user*/) {
    return 32;
  }

  function getFloorFromZValue(/*bldg, zValue*/) {
    return -1;
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/xian_subway_15_donghan.glb',
    modelType: 'gltf',
    modelSubType: 'meshopt',

    buildId: '1cb7b8e1-9de3-11ac-a6b1-98032b887f1a',

    //fullOpacity: 0.85,
    //revealOpacity: 0.15,
    //deepRevealOpacity: 0,

    modelOptions: {
      scale: 2.3,
      rotZ: 0,
      moveX: 900,
      moveY: -100,
      moveZ: -1108,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: Math.PI * 0.95,
      z: 0
    },

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,

    directionalLight1Intensity: 4,
    workerSizeMultiplier: 0.6,
  };
}
