<template>
  <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title" style="display:flex;width:100%;padding-right:3.1rem">
      <div style="flex:1">项目截止本月累计资金情况</div>
      <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '1' }"
              @click="() => switchAvgAttendanceTopListOption('1')"
            >可用资金</div>
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '2' }"
              @click="() => switchAvgAttendanceTopListOption('2')"
            >欠公司</div>
            <div
              class="detail"
              style="margin-left: 0.5rem;"
              @click=" showDetail()"
            >
              详情
            </div>
          </div>
    </div>
    <div style="height:19rem;padding-top:3rem">
      <div
        v-if="!dangerCheckRateChart.series || !dangerCheckRateChart.series[0].data.length"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart  v-else :options="dangerCheckRateChart"/>
    </div>
    <!-- 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                <el-tabs id ="tabs">
                <el-tab-pane label="可用资金项目">
                  <el-table style="background-color:#0E1328" height="25rem" :data="tableData1" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column
                        prop="branchName"
                        label="分公司">
                        </el-table-column>
                    <el-table-column
                        prop="assessUnit"
                        label="考核分类机构">
                    </el-table-column>
                      <el-table-column
                        prop="profitCode"
                        label="利润中心">
                      </el-table-column>
                    <el-table-column
                      prop="projName"
                      label="项目名称">
                    </el-table-column>
                    <el-table-column
                        prop="monthAccNetFlow"
                        label="截止本月项目累计净流"
                        :formatter="Formatter">
                      </el-table-column>
                      <el-table-column
                        prop="monthAccProjIncome"
                        label="截止本月项目累计收益"
                        :formatter="Formatter">
                      </el-table-column>
                      <el-table-column
                        prop="monthAccCashIncome"
                        label="截止本月累计现金形式上交收益"
                        :formatter="Formatter">
                      </el-table-column>
                      <el-table-column
                        prop="monthProjBalance"
                        label="截止本月项目可用资金"
                        :formatter="Formatter">
                      </el-table-column>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane label="欠公司资金项目">
                  <el-table style="background-color:#0E1328" height="24rem" :data="tableData2" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column
                        prop="branchName"
                        label="分公司">
                        </el-table-column>
                    <el-table-column
                        prop="assessUnit"
                        label="考核分类机构">
                    </el-table-column>
                      <el-table-column
                        prop="profitCode"
                        label="利润中心">
                      </el-table-column>
                    <el-table-column
                      prop="projName"
                      label="项目名称">
                    </el-table-column>
                    <el-table-column
                        prop="monthAccNetFlow"
                        label="截止本月项目累计净流"
                        :formatter="Formatter">
                      </el-table-column>
                      <el-table-column
                        prop="monthAccProjIncome"
                        label="截止本月项目累计收益"
                        :formatter="Formatter">
                      </el-table-column>
                      <el-table-column
                        prop="monthAccCashIncome"
                        label="截止本月项目欠交公司收益"
                        :formatter="Formatter">
                      </el-table-column>
                      <el-table-column
                        prop="monthProjUnpaidCapital"
                        label="截止本月项目欠公司资金"
                        :formatter="Formatter">
                      </el-table-column>
                  </el-table>
                </el-tab-pane>
              </el-tabs>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { finance } from '../../../../api_finance'
  import { SimpleBarChartTemplate } from "../../barChartTemplates";
  import { applyUpdate, truncateStr } from "../../../../utils";
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'ProjectFundStatusChart',
    components: {
      'v-chart': ECharts,
      wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateNetFlowChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateNetFlowChart();
        }
      }
    },
    data () {
      return {
        dangerCheckRateChart: {},
        mode:"1",
        companyWorkersOption:"1",
        popupShow:false,
        tableData1:[],
        tableData2:[],
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.updateNetFlowChart();
    },
    methods: {
      Formatter(row, column, cellValue){
            return (Math.floor(cellValue * 100) / 100).toFixed(2);
        },
      tableRowStyle({rowIndex}){
        if(rowIndex%2 === 0)
            return {backgroundColor:'#213a77',height: '0'}; 
        else   
            return {backgroundColor:'#102855',height: '0'}; 
      },
      tableHeaderColor(){
        return 'color:#4fb5da;background:#102855';
      },
      switchAvgAttendanceTopListOption(choose){
        this.mode = choose;
        this.updateNetFlowChart();
      },
      showDetail(){
        this.popupShow = true;
      },
      updateNetFlowChart() {
        finance.queryProjCapitalDetail()
          .then(d => {
            this.tableData1 = d.balanceProj;
            this.tableData2 = d.unpaidProj;
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            const option = {
              tooltip: {
                trigger: "axis",
                formatter: "{b}<br>{a0}: {c0}",
              },
              legend:{
                data:["可用资金","欠公司资金"]
              },
              grid: {
                top: "10%",
                left: "3%",
                right: "8%",
                containLabel: true,
              },
              xAxis: [
                {
                  show: true,
                  name: "万元",
                  type: "value",
                  nameTextStyle: {
                    padding: [0, 40, 0, 0],
                    color: "#e6f2f3",
                    fontWeight: "bold",
                    align: "left",
                  },
                  boundaryGap: [0, "20%"],
                  axisLabel: {
                    color: "#e6f2f3",
                    fontWeight: "bold",
                  },
                  axisLine: { show: true, lineStyle: { color: "#36445f" } },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: "#36445f",
                      width: 1,
                      type: "solid",
                    },
                  },
                  axisTick: { show: false },
                },
              ],
              yAxis: [
                {
                  type: "category",
                  data: [],
                  axisLabel: {
                    color: "#e6f2f3",
                    show:true,
                    lineStyle: {
                        color: "#rgb(77,83,141)",
                        width:4
                    },
                    fontWeight: "normal",
                    formatter:val => truncateStr(val,10)
                  },
                },
                
              ],
              series: [
                {
                  name: "可用资金",
                  type: "bar",
                  barWidth: 10,
                  barMaxWidth: 18,
                  markLine:{
                    symbol: ["none", "none"],
                    lineStyle:{
                      normal: {
                            width: 2,
                            color: "rgb(255,128,0)", // 线条颜色
                        },
                    },
                    itemStyle: {
                      normal: {
                        label: {
                          show: false,
                          position: "middle",
                        },
                      },
                    },
                    data: [],
                  }
                }
              ],
            };
            let xData = [];
            let seriesData1 = [];
            if(this.mode =="1"){
              d.balanceProj.sort((a,b)=>{return b.monthProjBalance-a.monthProjBalance});
              let len = d.balanceProj.length>10?10:d.balanceProj.length;
              for (let i = 0; i < len; i++) {
                xData.push(d.balanceProj[i].projName);
                seriesData1.push(d.balanceProj[i].monthProjBalance);
              }
              option.series[0].name = '可用资金';
            }else{
              d.unpaidProj.sort((a,b)=>{return b.monthProjUnpaidCapital-a.monthProjUnpaidCapital});
              let len = d.unpaidProj.length>10?10:d.unpaidProj.length;
              for (let i = 0; i < len; i++) {
                xData.push(d.unpaidProj[i].projName);
                seriesData1.push(d.unpaidProj[i].monthProjUnpaidCapital);
              }
              option.series[0].name = "欠公司资金";
            }
            option.yAxis[0].data = xData;
            option.series[0].data = seriesData1;
            this.dangerCheckRateChart = applyUpdate(chart, option);
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
    },
  }
</script>

<style lang="less">
  #tabs{
    background: #0e1328;
  }
</style>
