<template>
  <div id="right">
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div class="info-block-title">设备整机合格率</div>
      <div
        class="detail-select"
        style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
      >
        <CustomSelect
          :options="machineType"
          @input="companySelect2(1,$event)"
          style="width:8rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
        />
      </div>
      <div style="height:17rem;padding-top:.5rem">
        <div
          v-if="checkQualifiedRate.series && [].concat.apply([], checkQualifiedRate.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="checkQualifiedRate"
          ref="companyTrouble"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div class="info-block-title">设备隐患类型</div>
      <div
        class="detail-select"
        style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
      >
        <CustomSelect
          :options="machineType"
          @input="companySelect2(2,$event)"
          style="width:8rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
        />
      </div>
      <div style="height:14rem;position:relative">
        <div
          v-if="machineTrouble.series && [].concat.apply([], machineTrouble.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="machineTrouble"
          ref="workerType"
          @mouseover="workerTypeMouseOver"
          @mouseout="workerTypeMouseOut"
          autoresize
        />
        <div style="position:absolute;top:0.3rem;left:1rem;font-size:0.8rem">数量：个</div>
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div class="info-block-title">使用登记情况</div>
      <div
        class="detail-select"
        style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
      >
        <CustomSelect
          :options="machineType"
          @input="companySelect2(3,$event)"
          style="width:8rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
        />
      </div>
      <div style="height:19rem;padding-top:.5rem">
        <div
          v-if="machineRegister.series && [].concat.apply([], machineRegister.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="machineRegister"
          autoresize
        />
      </div>
    </div>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
// import { nation } from "../../api";
import { project } from "../../api";
import CustomSelect from "../../components/select";
import { applyUpdate } from "../../utils";
import { SimpleBarChartTemplate } from "./barChartTemplates";
import { attendancePieChartTemplate, colors } from "./pieChartTemplates";

export default {
  name: "SecurityMechRightBar",
  components: {
    "v-chart": ECharts,
    CustomSelect: CustomSelect,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
  },
  data() {
    return {
      machineType: ["全部设备"],
      machineIdList: [""],
      machineIndex1: 0,
      machineIndex2: 0,
      machineIndex3: 0,
      workerTypePieChartHighlightIndex: 0,
      checkQualifiedRate: {},
      machineTrouble: {},
      machineRegister: {},
      option1: {
        color: ["#5dd5c3"],
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0}: {c0}",
        },
        grid: {
          top: "12%",
          left: "10%",
          right: "9%",
          bottom: "20%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisLabel: {
              color: "#e6f2f3",
              rotate: 45,
              fontWeight: "normal",
              formatter: function (value) {
                return value.length > 8 ? value.slice(0, 8) + "..." : value;
              },
            },
          },
        ],
        yAxis: [
          {
            show: true,
            name: "合格率：%",
            type: "value",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
              align: "left",
            },
            max: 100,
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                width: 1,
                type: "solid",
              },
            },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: "合格率",
            type: "bar",
            barWidth: 10,
            barMaxWidth: 18,
            data: [],
          },
        ],
      },
      option2: {
        color: colors,
        legend: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["40%", "70%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function (params) {
                  return `{value|${Math.round(params.percent)}%}\n{text|${
                    params.name
                  }}`;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },
      option3: {
        color: ["#e5b353"],
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0}: {c0}",
        },
        grid: {
          top: "10%",
          left: "10%",
          right: "9%",
          bottom: "20%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisLabel: {
              color: "#e6f2f3",
              rotate: 45,
              fontWeight: "normal",
              formatter: function (value) {
                return value.length > 8 ? value.slice(0, 8) + "..." : value;
              },
            },
          },
        ],
        yAxis: [
          {
            show: true,
            name: "登记率：%",
            type: "value",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
              align: "left",
            },
            max: 100,
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                width: 1,
                type: "solid",
              },
            },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: "登记率",
            type: "bar",
            barWidth: 10,
            barMaxWidth: 18,
            data: [],
          },
        ],
      },
    };
  },
  watch: {
    orgLevel: function (newValue) {
      if (newValue) {
        this.getData();
      }
    },
    districtLevel: function (newValue) {
      if (newValue) {
        this.getData();
      }
    },
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;
    this.getData();
  },
  methods: {
    getData() {
      project
        .getGeneralParamsSelect(31)
        .then((d) => {
          this.machineType = ["全部设备"].concat(d.map((item) => item.value));
          this.machineIdList = [""].concat(d.map((item) => item.innerid));
          this.getEcharts1();
          this.getEcharts2();
          this.getEcharts3();
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    getEcharts1() {
      project
        .getDashboardMachineCheckQualifiedRateCollect(
          // machine_type
          this.machineIdList[this.machineIndex1]
        )
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option1.xAxis[0].data = d.xAxis_data;
          // this.option1.series = [chart.series[0]];
          this.option1.series[0].data = d.series;
          this.checkQualifiedRate = applyUpdate(chart, this.option1);
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    getEcharts2() {
      project
        .getDashboardMachineTroubleCollect(
          this.machineIdList[this.machineIndex2]
        )
        .then((d) => {
          if (this.$refs.workerType) {
            //取消默认高亮
            this.$refs.workerType.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: this.workerTypePieChartHighlightIndex,
            });
          }

          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
          const maxPieces = 14;
          if (d.data.length > maxPieces) {
            d.data[maxPieces - 1].value = d
              .slice(maxPieces - 1)
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
              );
            d[maxPieces - 1].name = "其它";
            d.data = d.data.slice(0, maxPieces);
          }
          // this.option2.series = [chart.series[0]];
          this.option2.series[0].data = d.data;

          // 默认高亮第一个值不是0的分片，否则默认第一片
          this.workerTypePieChartHighlightIndex = 0;
          for (let i = 0; i < d.data.length; i++) {
            if (this.option2.series[0].data[i].value) {
              this.workerTypePieChartHighlightIndex = i;
              break;
            }
          }
          this.option2.series[1] = Object.assign({}, this.option2.series[0], {
            label: {
              rich: {
                text: {
                  fontSize: 11,
                },
              },
              formatter: function (params) {
                return `${params.name} ${Math.round(params.value)}`;
              },
            },
            silent: true,
          });

          setTimeout(
            () =>
              this.$refs.workerType &&
              this.$refs.workerType.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: this.workerTypePieChartHighlightIndex,
              }),
            100
          );
          this.machineTrouble = applyUpdate(chart, this.option2);
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    workerTypeMouseOver(params) {
      if (params.dataIndex != this.workerTypePieChartHighlightIndex) {
        this.$refs.workerType.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.workerTypePieChartHighlightIndex,
        });
        this.workerTypePieChartHighlightIndex = params.dataIndex;
      }
    },
    workerTypeMouseOut() {
      this.$refs.workerType.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.workerTypePieChartHighlightIndex,
      });
    },
    getEcharts3() {
      project
        .getDashboardMachineRegisterCollect(
          // machine_type
          this.machineIdList[this.machineIndex3]
        )
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option3.xAxis[0].data = d.xAxis_data;
          // this.option3.series = [chart.series[0]];
          this.option3.series[0].data = d.series;
          this.machineRegister = applyUpdate(chart, this.option3);
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    companySelect2(mode, option) {
      this["machineIndex" + mode] = this.machineType.indexOf(option);
      if (mode == 1) {
        this.getEcharts1();
      } else if (mode == 2) {
        this.getEcharts2();
      } else {
        this.getEcharts3();
      }
    },
  },
};
</script>
