<template>
<div id="left">
  <div class="info-block" style="min-height:5rem">
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>科技成果情况</div>
        <div class="detail" @click="showDetail()">
          详情
        </div>
      </div>
      <div style="height:19rem;padding-top:3rem">
        <div
          style="display:flex;justify-content:space-around;margin-bottom:0.6rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')"
          >
            成果统计
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')"
          >
            完成情况
          </div>
        </div>
        <div
          v-if="companyWorkersPieChart.series && [].concat.apply([], companyWorkersPieChart.series.map(x => x.data)).length > 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyWorkersPieChart" @click="companyWorkersPieChartClick" autoresize/>
      </div>
  </div>
  <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <!-- <div class="tabbar" style="width:22rem">
              <div
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >正常（{{normal}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 5 }"
                @click="getAttendance(5)"
              >缓建（{{wait}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 4 }"
                @click="getAttendance(4)"
              >停工（{{stop}}）</div>
            </div> -->
            <!-- <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem;">
              <CustomSelect :options="companyNameList" :value="selectVal1" @input="companySelect($event, 'val1')" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;" />
            </div> -->
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:15rem;text-align:center">指标类型</div>
                <div style="width:8rem;text-align:center">指标等级</div>
                <div style="width:8rem;text-align:center">集团下达指标</div>
                <div style="width:8rem;text-align:center">公司下达指标</div>
                <div style="width:8rem;text-align:center">计划数</div>
                <div style="width:8rem;text-align:center">项目完成数</div>
                <div style="width:8rem;text-align:center">完成率</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popProjectsList"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:15rem;text-align:center">{{item.zblx}}</div>
                  <div style="width:8rem;text-align:center">{{item.zbdj}}</div>
                  <div style="width:8rem;text-align:center">{{item.ztzb}}</div>
                  <div style="width:8rem;text-align:center">{{item.gszb}}</div>
                  <div style="width:8rem;text-align:center">{{item.jhzb}}</div>
                  <div style="width:8rem;text-align:center">{{item.wcsl}}</div>
                  <div style="width:8rem;text-align:center">{{item.rate}}</div>
                </div>
                <div
                  v-if="!(popProjectsList && popProjectsList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>

        </div>
      </wrapper>
    </el-dialog>
</div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { tech } from '../../../../api_tech'
  import wrapper from "../../../../components/wrap";
  // import CustomSelect from '../../../../components/select'

  export default {
    name: 'cnTechResultChart',
    components: {
      'v-chart': ECharts,
      wrapper,
      // 'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      }
    },
    data () {
      return {
        companyWorkersPieChart: {},
        companyWorkersOption:"1",
        companyNameList: ['全部分公司'],
        companyIdList: [''],
        popupShow:false,
        popProjectsList:[]
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.getAll();
    },
    methods: {
      getAll(){
        this.switchCompanyWorkersOption(this.companyWorkersOption);
      },
      switchCompanyWorkersOption(choose){
        this.companyWorkersOption = choose;
        switch (this.companyWorkersOption) {
          case "1":
            this.updateCnResultStatic();
            break;
          case "2":
            this.updateCnResultSuccess();
            break;
          default:
            break;
        }
      },
      updateCnResultStatic() {
        tech.getCnTechResult()
          .then(d => {
            const typeList = [];
            const nums = [];
            for (let i = 0; i < d.length; i++) {
              typeList.push(d[i].zblx);
              nums.push(d[i].wcsl);
            }
            const option = {
              color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                }
              },
              legend: {},
              grid: {
                containLabel: true,
                left: '5%',
                right: '6%',
                bottom: "5%",
                top: "15%"
              },
              xAxis: {
                type: 'value',
                axisLabel:{ interval:3 }
              },
              yAxis: {
                type: 'category',
                data: typeList,
                axisLabel:{
                  color:"white"
                }
              },
              series: [
                {
                  name: '完成数量',
                  type: 'bar',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: nums
                }
              ]
            }
            this.companyWorkersPieChart = option;
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
      updateCnResultSuccess(){
        tech.getCnTechResult()
          .then(d => {
            const typeList = [];
            const complete_nums = [];
            const plan_nums = [];
            const complete_rate = [];
            for (let i = 0; i < d.length; i++) {
              typeList.push(d[i].zblx);
              complete_nums.push(d[i].wcsl);
              plan_nums.push(d[i].jhzb);
              complete_rate.push(d[i].rate);
            }
            const option = {
              color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                }
              },
              legend: {},
              grid: {
                containLabel: true,
                left: '5%',
                right: '6%',
                bottom: "5%",
                top: "15%"
              },
              xAxis: {
                type: 'value',
                axisLabel:{ interval:3 }
              },
              yAxis: {
                type: 'category',
                data: typeList,
                axisLabel:{
                  color:"white"
                }
              },
              series: [
                {
                  name: '计划数量',
                  type: 'bar',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: plan_nums
                },
                {
                  name: '完成数量',
                  type: 'bar',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: complete_nums
                },{
                  name: '完成率',
                  type: 'line',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: complete_rate
                }
              ]
            }
            this.companyWorkersPieChart = option;
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
      showDetail(){
        tech.getCnTechResult().then(res=>{
          this.popupShow = true;
          if(res.length ==0 || res[0].orgName == "")
            return;
          this.popProjectsList =res;
        })
      },
      companyWorkersPieChartClick(){
        this.showDetail();
      }
    },
  }
</script>

<style lang="less">
</style>
