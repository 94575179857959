<template>
  <div id="left">
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div class="info-block-title">{{title}}</div>
      <div style="height:56rem;padding-top:3rem;">
        <div
          v-if="mapLevel==2"
          style="width:100%;display: flex; flex-direction: column; align-items: center;"
        >
          <div class="tooltips">

            <div class="items">
              <div class="tooltip-item tooltip-item1"></div>在施
            </div>
            <div class="items">
              <div class="tooltip-item tooltip-item2"></div>缓建
            </div>
            <div class="items">
              <div class="tooltip-item tooltip-item3"></div>停工
            </div>
          </div>

        <div
          v-if="!projectListInfo.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
          <div v-else style="height:45rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;width:100%; padding-left: 1rem;">

            <div
              v-for="project in projectListInfo"
              :key="project.name"
              style="height:2rem;display:flex;justify-content:space-between; align-items: center;cursor:pointer"
              @click="handleSelect(project)"
            >
              <div>{{project.name.length>25?project.name.slice(0,20)+'...':project.name}}</div>
              <div style="margin-left:0.5rem">
                <div
                  class="tooltip-item "
                  :class="[project.status==2?'tooltip-item1':(project.status==5?'tooltip-item2':'tooltip-item3')]"
                ></div>
              </div>
            </div>
          </div>
        </div>
       <div v-else style="width:100%;height:100%">

        <div
          v-if="weekAttendanceRateChart.series && [].concat.apply([], weekAttendanceRateChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          @click="projectListClick"
          :options="weekAttendanceRateChart"
          @mouseover="weekAttendanceRateMouseOver"
          @mouseout="weekAttendanceRateMouseOut"
          autoresize
        />
       </div>
      </div>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle}}
            </div>
            <div class="tabbar" style="width:22rem">
              <div
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >在施（{{normal}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 5 }"
                @click="getAttendance(5)"
              >缓建（{{wait}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 4 }"
                @click="getAttendance(4)"
              >停工（{{stop}}）</div>
            </div>
            <div style="width:10rem"></div>
          </div>


            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:5rem">序号</div>
                <div style="width:8rem;text-align:center">分公司</div>
                <div style="width:26rem">项目名称</div>
                <div style="width:8rem">所在城市</div>
                <div style="width:12rem">建筑面积</div>
                <div style="width:8rem">开工时间</div>
                <div style="width:12rem;text-align:center">操作</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popProjectsList"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:5rem">
                    {{securityStarPage>1?(securityStarPage-1)*10+index+1:(index+1>9?(index+1):'0'+(index+1))}}
                  </div>
                  <div style="width:8rem;text-align:center">{{item.org_name}}</div>
                  <div style="width:26rem">{{item.project_name}}</div>
                  <div style="width:8rem;">{{item.city_name}}</div>
                  <div style="width:12rem;">{{item.built_up_area||'--'}} 万平</div>
                  <div style="width:8rem;">{{item.starttime}}</div>
                  <div style="width:12rem;text-align:center" @click="handleSelect(item)">
                    <a href="javascript:;" style="color:#4fb5da;">进入项目大屏</a>
                  </div>
                </div>
                <div
                  v-if="!(popProjectsList && popProjectsList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无{{popupSelect==2?'在施':(popupSelect==5?'缓建':'停工')}}项目
                </div>
              </div>
              <el-pagination
                background
                layout="prev, pager, next"
                :current-page.sync="securityStarPage"
                :page-count="securityStarPages"
                @current-change="securityStarPageChange"
                style="text-align:center;margin-top:0.5rem"
              ></el-pagination>
            </div>

        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation, project } from "../../api";
import { stackedBarChartTemplate } from "./barChartTemplates";
// import { attendancePieChartTemplate } from './pieChartTemplates';
import { applyUpdate } from "../../utils";
import wrapper from "../../components/wrap";

export default {
  name: "IndexLeftBar",
  components: {
    "v-chart": ECharts,
    wrapper,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    orgType: Number,
    gotoProject: Function,
  },
  data() {
    return {
      title: "各分公司项目数",
      weekAttendanceRateChart: {},
      isWeekAttendanceRateChartInteracting: false,
      option: {
        color: ["#23cf9e", "#eab91e", "#e02f37"],
        tooltip: {
          // show: false,
          // trigger: "none",
        },
        legend: {
          selectedMode: false,
          data: [],
        },
        dataZoom: [],
        grid: {
          top: "9%",
          left: "6%",
          right: "9%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          show: false,
        },
        yAxis: [
          {
            name: "项目数：个",
            nameRotate: "",
            nameTextStyle: {
              color: "#ccc",
              align: "right",
            },
            type: "category",
            // boundaryGap :true,
            data: [],
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: "#ffffff",
              formatter: function (value) {
                return value.length > 16 ? value.slice(0, 16) + "..." : value;
              },
            },
          },
        ],
        series: [
          {
            name: "在施",
            type: "bar",
            stack: "total",
            barMaxWidth: 18,
            label: {
              show: true,
              formatter: function (params) {
                if (params.value > 0) {
                  return params.value;
                } else {
                  return " ";
                }
              },
              position: "bottom",
              fontSize: 10,
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          },
          {
            name: "缓建",
            type: "bar",
            stack: "total",
            barMaxWidth: 18,
            label: {
              show: true,
              formatter: function (params) {
                if (params.value > 0) {
                  return params.value;
                } else {
                  return " ";
                }
              },
              position: "bottom",
              fontSize: 10,
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          },
          {
            name: "停工",
            type: "bar",
            stack: "total",
            barMaxWidth: 18,
            label: {
              show: true,
              formatter: function (params) {
                if (params.value > 0) {
                  return params.value;
                } else {
                  return " ";
                }
              },
              position: "bottom",
              fontSize: 10,
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          },
          {
            // name: "总计",
            type: "line",
            lineStyle: {
              opacity: 0,
            },
            label: {
              show: true,
              position: "right",
              offset: [10, 0],
              textStyle: {
                color: "#fff",
              },
            },
            tooltip: {
              show: false,
            },
            // symbol:'none',
            symbolSize: 0.1,
            data: [],
          },
        ],
      },
      mapLevel: 0,
      projectListInfo: [],

      search_keys:[],
      popupShow: false,
      popupSelect: 2,
      popupTitle: "",
      popProjectsList: [],
      securityStarPage: 1,
      securityStarPages: 1,
      normal:0,
      wait:0,
      stop:0,
      activeIndex:0
    };
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;
    this.getData();

    window.setInterval(() => {
      if (!this.isWeekAttendanceRateChartInteracting) {
        this.getData();
      }
    }, 5000);
  },
  watch: {
    orgLevel: function (newValue) {
      if (newValue) {
        this.getData();
      }
    },
    districtLevel: function (newValue) {
      if (newValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
        this.getData();
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.mapLevel = newValue;
        if (newValue == 2) {
          this.getData();
        }
      }
    },
  },
  methods: {
    handleSelect(item) {
      this.gotoProject(item.project_id || item.search_key);
    },
    projectListClick(params){
      // console.log(params)
      if(params.seriesIndex==0){
        this.popupSelect=2;
      }else if(params.seriesIndex==1){
        this.popupSelect=5;
      }else{
        this.popupSelect=4;
      }
      this.securityStarPage=1;
      this.activeIndex=params.dataIndex;
      this.popupShow = true;
      this.popupTitle = params.name + "项目";
      this.normal=this.weekAttendanceRateChart.series[0].data[params.dataIndex]
      this.wait=this.weekAttendanceRateChart.series[1].data[params.dataIndex]
      this.stop=this.weekAttendanceRateChart.series[2].data[params.dataIndex]
      this.securityStarPageChange(1);
    },
    securityStarPageChange(currPage) {
      // console.log(this.orgType)
      nation
        .getProjectOrgCityDetailCollect(this.popupSelect,currPage, 10,this.mapLevel==0&&this.orgType == 1?this.search_keys[this.activeIndex]:this.orgLevel,this.mapLevel==1||this.orgType !== 1?this.search_keys[this.activeIndex]:'')
        .then((d) => {
          this.popProjectsList = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },

    getAttendance(popupSelect) {
      this.popupSelect = popupSelect;
      this.securityStarPageChange(1)
    },
    weekAttendanceRateMouseOver() {
      this.isWeekAttendanceRateChartInteracting = true;
    },
    weekAttendanceRateMouseOut() {
      this.isWeekAttendanceRateChartInteracting = false;
    },

    getData() {
      project
        .getDashboardOrgCityCollect("")
        .then((d) => {
          //     d = d.slice(-7);
          if (this.mapLevel == 2) {
            this.title = "各项目状态";
            this.projectListInfo = d;
          } else {
            const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));

            if (this.districtScale == 2) {
              //
            } else {
              this.search_keys=d.search_keys;
              this.option.yAxis[0].name = "项目数：个";
              this.option.yAxis[0].axisLabel.rotate = 0;
              this.option.tooltip.show = true;
              if (this.districtScale == 0 && this.orgType == 1) {
                this.title = "各分公司项目数";
              } else {
                this.title = "各城市项目数";
              }
              this.option.dataZoom = [];
              this.option.series = this.option.series.map((item, index) => {
                if (index < 3) {
                  return {
                    ...item,
                    label: {
                      show: true,
                      formatter: function (params) {
                        if (params.value > 0) {
                          return params.value;
                        } else {
                          return " ";
                        }
                      },
                      position: "bottom",
                      fontSize: 10,
                      color: "#fff",
                    },
                  };
                } else {
                  return {
                    ...item,
                    label: {
                      show: true,
                      position: "right",
                      offset: [10, 0],
                      textStyle: {
                        color: "#fff",
                      },
                    },
                  };
                }
              });
            }

            this.option.yAxis[0].data = d.yAxis_data;
            this.option.series.map((item, index) => {
              item.data = d.series[index];
            });
            this.option.legend.data = d.legend_data;

            if (this.option.dataZoom.length) {
              if (this.option.series[0].data.length <= 20) {
                this.option.dataZoom = [];
              } else {
                this.option.dataZoom[0].start = this.option.dataZoom[1].start =
                  100 -
                  Math.min(100, (20 / this.option.series[0].data.length) * 100);
              }
            }

            this.weekAttendanceRateChart = applyUpdate(chart, this.option);
          }
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
  },
};
</script>
<style scoped>
.tooltips {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  margin-bottom: 1rem;
  font-size:0.6rem;
  color:#abbbbf;
  font-weight:bold;

}
.items {
  display: flex;
  align-items: center;
}
.tooltip-item {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.3rem;
}
.tooltip-item1 {
  background-color: #23cf9e;
}
.tooltip-item2 {
  background-color: #eab91e;
}
.tooltip-item3 {
  background-color: #e02f37;
}
</style>
