<template>
  <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title" style="display:flex;width:100%;padding-right:3.1rem">
      <div style="flex:1">{{chartTitle}}</div>
      <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '1' }"
              @click="() => switchAvgAttendanceTopListOption('1')"
            >月度显示</div>
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '2' }"
              @click="() => switchAvgAttendanceTopListOption('2')"
            >累计月度显示</div>
            <div
              class="detail"
              style="margin-left: 0.5rem;"
              @click=" showDetail()"
            >
              详情
            </div>
          </div>
    </div>
    <div style="height:19rem;padding-top:3rem">
      <div
          style="display:flex;justify-content:space-around;margin-bottom:0.6rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')"
          >
            本年净流
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')"
          >
            投资项目数据
          </div>
        </div>
        <div style="height:100%" v-if="companyWorkersOption=='1'">
          <div
            v-if="companyCurReceiptChart.series && [].concat.apply([], companyCurReceiptChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart v-else :options="companyCurReceiptChart" autoresize />
      </div>
      <div id ="touzi" v-if="Object.keys(touziData).length !==0 && companyWorkersOption=='2'" >
      <div style="position:absolute;left:4rem;top:5rem;display:flex">
          <div id="yjzsr">
            <div style="font-size:1.2rem;font-weight:bold;color:#25faf3">{{toFixed(touziData.expectedIncome,2)}}亿</div>
            <div style="font-size:0.8rem;margin-top:0.3rem">预计总收入</div>
          </div>
          <div id="xmzsy">
            <div style="font-size:1.2rem;font-weight:bold;color:#27e6ab">{{toFixed(touziData.totalIncome,2)}}亿</div>
            <div style="font-size:0.8rem;margin-top:0.3rem">项目总收益</div>
          </div>
          
      </div>
      <div style="position:absolute;left:7rem;top:12rem;display:flex">
        <div id="ljjl">
            <div style="font-size:1.2rem;font-weight:bold;color:#25faf3">{{toFixed(touziData.monthAccNetFlow,2)}}亿</div>
            <div style="font-size:0.8rem;margin-top:0.3rem">截至本月累计净流</div>
          </div>
          <div id="wljl">
            <div style="font-size:1.2rem;font-weight:bold;color:#27e6ab">{{toFixed(touziData.projFutureNetFlow,2)}}亿</div>
            <div style="font-size:0.8rem;margin-top:0.3rem">未来净流</div>
          </div>
      </div>
    </div>
    </div>
    <!-- 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                <el-tabs id = "tabs">
                  <el-tab-pane label="本年净流">
                    <el-table style="background-color:#0E1328" height="22rem" :data="tableData" 
                    :row-style="tableRowStyle" :header-cell-style="tableHeaderColor" show-summary :summary-method="getSummaries">
                      <el-table-column
                            prop="branchName"
                            label="分公司">
                        </el-table-column>
                          <el-table-column v-for="(item,index) in tableData[0].monthData" :key="index" :label="item.month">
                              <el-table-column label="净流预算">
                                    <template slot-scope="scope">
                                      {{scope.row.monthData[index].jlys}}
                                    </template>
                              </el-table-column>
                              <el-table-column label="净流完成">
                                    <template slot-scope="scope">
                                      {{scope.row.monthData[index].jlwc}}
                                    </template>
                              </el-table-column>
                          </el-table-column>
                        <el-table-column label="当年">
                            <el-table-column
                              label="净流预算"
                              prop="yearNetFlowBudget"
                              :formatter="Formatter">
                              </el-table-column>
                              <el-table-column
                              prop="yearNetFlowFinished"
                              label="净流完成"
                              :formatter="Formatter">
                              </el-table-column>
                              <el-table-column
                              prop="yearNetFlowOffset"
                              label="净流偏差"
                              :formatter="Formatter">
                              </el-table-column>
                          </el-table-column>
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="投资数据">
                    <el-table style="background-color:#0E1328" height="22rem" :data="projData" 
                    :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column type="index" :index="getIndex" label="序号">
                    </el-table-column>
                      <el-table-column
                            prop="branchName"
                            label="分公司">
                        </el-table-column>
                        <el-table-column
                            prop="projName"
                            label="项目名称">
                        </el-table-column>
                        <el-table-column
                            prop="expectedIncome"
                            label="项目预计总收入"
                              :formatter="Formatter">
                        </el-table-column>
                        <el-table-column
                            prop="totalIncome"
                            label="项目总收益"
                              :formatter="Formatter">
                        </el-table-column>
                        <el-table-column
                            prop="monthAccNetFlow"
                            label="截止本月累计净流"
                              :formatter="Formatter">
                        </el-table-column>
                        <el-table-column
                            prop="projFutureNetFlow"
                            label="项目未来净流"
                              :formatter="Formatter">
                        </el-table-column>
                    </el-table>
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :current-page.sync="currentPage"
                      :page-count="pageSize"
                      :total="totalCount"
                      @current-change="pageChange"
                      style="text-align:center;margin-top: 0.5rem;"
                    ></el-pagination>
                  </el-tab-pane>
                </el-tabs>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { finance } from '../../../../api_finance'
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'CompanyCurNetFlowChart',
    components: {
      'v-chart': ECharts,
      wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateNetFlowChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateNetFlowChart();
        }
      }
    },
    data () {
      return {
        companyCurReceiptChart: {},
        mode:"1",
        companyWorkersOption:"1",
        popupShow:false,
        tableData:[],
        touziData:{},
        chartTitle:"公司本年净流",
        projData:[],
        currentPage:1,
        pageSize:10,
        totalCount:0
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.switchCompanyWorkersOption(this.companyWorkersOption);
    },
    methods: {
      getIndex(index){
        return (this.currentPage-1)*this.pageSize+index+1;
      },
      pageChange(curPage){
        this.currentPage = curPage;
        this.getTouziData();
      },
      getTouziData(){
        finance.queryProjCapitalStatistic("",this.currentPage,this.pageSize).then((d=>{
          this.totalCount = d.totalRecords;
          this.projData = d.data;
        }))
      },
      getSummaries(param){
        const {data } = param;
        const sums = [];
        sums[0] = '合计';
        var skys1=0,skwc1=0,skys2=0,skwc2=0,skys3=0,skwc3=0,skys4=0,skwc4=0,
        skys5=0,skwc5=0,skys6=0,skwc6=0,skys7=0,skwc7=0,skys8=0,skwc8=0,skys9=0,skwc9=0,
        skys10=0,skwc10=0,skys11=0,skwc11=0,skys12=0,skwc12=0,yearSkys=0,yearSkwc=0,yearSkpc=0;
        data.forEach((item)=>{
          skys1 += item.monthData[0].jlys;
          skwc1 += item.monthData[0].jlwc;
          skys2 += item.monthData[1].jlys;
          skwc2 += item.monthData[1].jlwc;
          skys3 += item.monthData[2].jlys;
          skwc3 += item.monthData[2].jlwc;
          skys4 += item.monthData[3].jlys;
          skwc4 += item.monthData[3].jlwc;
          skys5 += item.monthData[4].jlys;
          skwc5 += item.monthData[4].jlwc;
          skys6 += item.monthData[5].jlys;
          skwc6 += item.monthData[5].jlwc;
          skys7 += item.monthData[6].jlys;
          skwc7 += item.monthData[6].jlwc;
          skys8 += item.monthData[7].jlys;
          skwc8 += item.monthData[7].jlwc;
          skys9 += item.monthData[8].jlys;
          skwc9 += item.monthData[8].jlwc;
          skys10 += item.monthData[9].jlys;
          skwc10 += item.monthData[9].jlwc;
          skys11 += item.monthData[10].jlys;
          skwc11 += item.monthData[10].jlwc;
          skys12 += item.monthData[11].jlys;
          skwc12 += item.monthData[11].jlwc;
          yearSkys += item.yearNetFlowBudget;
          yearSkwc += item.yearNetFlowFinished;
          yearSkpc += item.yearNetFlowOffset;
        })
        sums.push(skys1,skwc1,skys2,skwc2,skys3,skwc3,skys4,skwc4,skys5,skwc5,skys6,skwc6,
        skys7,skwc7,skys8,skwc8,skys9,skwc9,skys10,skwc10,skys11,skwc11,skys12,skwc12,yearSkys,yearSkwc,yearSkpc);
        var list = sums.map(item=>{
          if(typeof(item) == "number"){
            return this.numFormatter(item);
          }else{
            return item;
          }
        })
        return list;
      },
      numFormatter(val){
        return (Math.floor(val * 100) / 100).toFixed(2);
      },
       Formatter(row, column, cellValue){
            return finance.formatDecimal(cellValue,2);
        },
      tableRowStyle({rowIndex}){
        if(rowIndex%2 === 0)
            return {backgroundColor:'#213a77',height: '0'}; 
        else   
            return {backgroundColor:'#102855',height: '0'}; 
      },
      tableHeaderColor(){
        return 'color:#4fb5da;background:#102855';
      },
      switchCompanyWorkersOption(option){
        this.getTouziData();
        this.companyWorkersOption = option;
        if(this.companyWorkersOption == "1"){
          this.updateNetFlowChart();
          this.chartTitle = "公司本年净流";
        }else{
          this.chartTitle = "投资项目数据";
          this.getTouziData();
          this.updateInvestProChart();
        }
      },
      updateInvestProChart(){
        finance.queryProjCapitalStatisticSum(2022).then((res=>{
          this.touziData = res;
        }))
      },
      toFixed(value,ptn){
        return finance.formatDecimal(value,ptn);
      },
      switchAvgAttendanceTopListOption(choose){
        this.mode = choose;
        this.updateNetFlowChart();
      },
      showDetail(){
        this.popupShow = true;
      },
      updateNetFlowChart() {
        finance.queryBranchProjNetFlow().then((d=>{
          var tempData = [];
          var yearData = [];
          d.forEach(item=>{
            var monthData = [];
            yearData = item.projCapitalYearlyVO;
            for (let i = 0; i < item.projCapitalMonthlyVOList.length; i++) {
              monthData.push({month:item.projCapitalMonthlyVOList[i].month+"月",
                              jlys:finance.formatDecimal(item.projCapitalMonthlyVOList[i].netFlowBudget,2),
                              jlwc:finance.formatDecimal(item.projCapitalMonthlyVOList[i].netFlowBudget,2)});
            }
            tempData.push({branchName:yearData.branchName,
                              monthData:monthData,
                              yearNetFlowBudget:finance.formatDecimal(yearData.netFlowBudget,2),
                              yearNetFlowFinished:finance.formatDecimal(yearData.netFlowFinished,2),
                              yearNetFlowOffset:finance.formatDecimal(yearData.netFlowOffset,2)})
          })
          this.tableData = tempData;
        }))
        finance.queryAllBranchCapitalMonthlySum()
          .then(d => {
            const option = {
              color:[
              '#f32c2c',
              '#f38e2c',
              '#2ce4ff',
              '#19b6fb',
              '#5dd5c3',
              '#ffed21',
              '#159172',
              '#fb9c5c'
            ],
            title:{
              subtext:"本年净流预算:"+Number(finance.formatDecimal(d.branchCapitalYearlyVO.netFlowBudget,2))+"亿\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0本年收款完成:"+Number(finance.formatDecimal(d.branchCapitalYearlyVO.netFlowFinished,2))+"亿",
              subtextStyle:{
                fontSize:13,
                color:'#fff',
                fontWeight:700
              },
              left:'center',
              top:'6'
            },
              tooltip: {
                trigger: "axis",
                formatter: "{b}月<br>{a0}: {c0}亿",
              },
              legend:{
                textStyle:{
                    color:"#fff"
                },
                right:'10'
              },
              xAxis: 
                {
                  type: "category",
                  data: [],
                  axisLabel: {
                    color: "#e6f2f3",
                    show:true,
                    lineStyle: {
                        color: "#rgb(77,83,141)",
                        width:4
                    },
                    rotate: 45,
                    fontWeight: "normal",
                    formatter: function (value) {
                      return value+"月";
                    },
                  },
                },
              yAxis: [
                {
                  show: true,
                  type: "value",
                  nameTextStyle: {
                    padding: [0, 40, 0, 0],
                    color: "#e6f2f3",
                    fontWeight: "bold",
                    align: "left",
                  },
                  boundaryGap: [0, "20%"],
                  axisLabel: {
                    color: "#e6f2f3",
                    fontWeight: "bold",
                  },
                  axisLine: { show: true, lineStyle: { color: "#36445f" } },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: "#36445f",
                      width: 1,
                      type: "solid",
                    },
                  },
                  axisTick: { show: false },
                },
              ],
              series: [
                {
                  name: "净流预算",
                  type: "line",
                  barWidth: 10,
                  barMaxWidth: 18,
                  markLine:{
                    symbol: ["none", "none"],
                    lineStyle:{
                      normal: {
                            width: 2,
                            color: "rgb(255,128,0)", // 线条颜色
                        },
                    },
                    itemStyle: {
                      normal: {
                        label: {
                          show: false,
                          position: "middle",
                        },
                      },
                    },
                  },
                  data: [],
                },{
                  name: "净流完成",
                  type: "line",
                  barWidth: 10,
                  barMaxWidth: 18,
                  markLine:{
                    symbol: ["none", "none"],
                    lineStyle: {
                        normal: {
                            width: 2,
                            color: "rgba(25,163,223,1)", // 线条颜色
                        },
                    },
                    itemStyle: {
                      normal: {
                        label: {
                          show: false,
                          position: "middle",
                        },
                      },
                    },
                  },
                  data: []
                }
              ],
            };
            var monthList = d.branchCapitalMonthlyVOList;
            var seriesData1 = [];
            var seriesData2 = [];
            for (let i = 0; i < monthList.length; i++) {
                option.xAxis.data.push(monthList[i].month);
                seriesData1.push(finance.formatDecimal(monthList[i].netFlowBudget,2));
                seriesData2.push(finance.formatDecimal(monthList[i].netFlowFinished==null?0:monthList[i].netFlowFinished,2));
            }
            var seriesData1_acc = [];
            var seriesData2_acc = [];
            var currNum = 0;
            if(this.mode == "2"){
              seriesData1.forEach((item,index)=>{
                if(index == 0){
                  seriesData1_acc.push(seriesData1[index]);
                }else{
                  if(currNum){
                    currNum = seriesData1[index]+currNum;
                  }else{
                    currNum = seriesData1[index] + seriesData1[index-1];
                  }
                  seriesData1_acc.push(currNum.toFixed(2));
                }
              })
              seriesData2.forEach((item,index)=>{
                if(index == 0){
                  seriesData2_acc.push(seriesData2[index]);
                }else{
                  if(currNum){
                    currNum = seriesData2[index]+currNum;
                  }else{
                    currNum = seriesData2[index] + seriesData2[index-1];
                  }
                  seriesData2_acc.push(currNum.toFixed(2));
                }
              })
            }
            if(this.mode == "1"){
              option.series[0].data = seriesData1;
              option.series[1].data = seriesData2;
            }else{
              option.series[0].data = seriesData1_acc;
              option.series[1].data = seriesData2_acc;
            }
            this.companyCurReceiptChart = option;
          })
      },
    },
  }
</script>

<style lang="less">
</style>
