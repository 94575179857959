<template>
  <div id = "right">
    <div class="info-block" style="min-height:5rem">
    <div class="info-block-title"  style="display:flex;width:100%;padding-right:3.1rem">
      <div style = "flex:1">确权与回款</div>
      <div class="detail" style="cursor:pointer" @click="showDetail()">详情</div>
    </div>
    <div style="height:20rem;padding-top:3rem">
      <div style="display:flex">
        <table style="width:400px;height:242px"  rules=rows>
          <tr>
            <td style="text-align:center">本年</td>
            <td>涉及金额<p><span class="value1">{{toFixed(subData.bnSjje,2)}}</span>万元</p></td>
            <td>确权金额<p><span class="value2">{{toFixed(subData.bnQqje,2)}}</span>万元</p></td>
            <td>累计回款金额<p><span class="value3">{{toFixed(subData.bnHKje,2)}}</span>万元</p></td>
          </tr>
          <tr>
            <td style="text-align:center">累计</td>
            <td>涉及金额<p><span class="value1">{{toFixed(subData.sjje,2)}}</span>万元</p></td>
            <td>确权金额<p><span class="value2">{{toFixed(subData.qqje,2)}}</span>万元</p></td>
            <td>累计回款金额<p><span class="value3">{{toFixed(subData.hkje,2)}}</span>万元</p></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <!-- 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                <el-table style="background-color:#0E1328" height="28rem" :data="tableData" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column
                        type="index"
                        label="序号">
                    </el-table-column>
                    <el-table-column
                        prop="AJH"
                        label="案件号">
                    </el-table-column>
                    <el-table-column
                        prop="FDWMC"
                        label="涉诉公司">
                    </el-table-column>
                    <el-table-column
                        prop="FXMMC"
                        label="涉案项目">
                    </el-table-column>
                    <el-table-column
                        prop="FYG"
                        label="原告/申请">
                    </el-table-column>
                    <el-table-column
                        prop="FBG"
                        label="被告/申请"
                        :formatter="Formatter">
                    </el-table-column>
                    <el-table-column
                        prop="FDSR"
                        label="第三人"
                        :formatter="Formatter">
                    </el-table-column><el-table-column
                        prop="FAFRQ"
                        label="案发时间">
                    </el-table-column>
                    <el-table-column
                        prop="FAFLX"
                        label="案发类型"
                        :formatter="formatterCaseType">
                    </el-table-column>
                    <el-table-column
                        prop="YFJE"
                        label="生效法律文书金额">
                    </el-table-column>
                    <el-table-column
                        prop="HKJE"
                        label="回款总金额">
                    </el-table-column>
                    <el-table-column
                        prop="BNHKJE"
                        label="本年回款金额">
                    </el-table-column>
                </el-table>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  // import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { law } from '../../../../api_law'
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'RightAndCollectionChart',
    components: {
        wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerLevelChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerLevelChart();
        }
      }
    },
    data () {
      return {
        dangerLevelsChart: {},
        subData:{},
        popupShow:false,
        tableData:[]
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.updateSafetyDangerLevelChart();
    },
    methods: {
      Formatter(row,column,cellvalue){
        return cellvalue=="null"?"":cellvalue;
      },
        formatterCaseType(row,column,cellvalue){
             return cellvalue=="01"?"仲裁":"诉讼";
        },
      showDetail(){
        this.popupShow = true;
        law.queryRightAndCollectionDetail().then((res=>{
            this.tableData = res;
        }))
      },
        tableRowStyle({rowIndex}){
            if(rowIndex%2 === 0)
                return {backgroundColor:'#213a77',height: '0'}; 
            else   
                return {backgroundColor:'#102855',height: '0'}; 
        },
        tableHeaderColor(){
            return 'color:#4fb5da;background:#102855';
        },
      toFixed(value,ptn){
        return law.formatToMyriadDecimal(value,ptn);
      },
      updateSafetyDangerLevelChart() {
        law.queryRightAndCollection().then((res)=>{
          this.subData = res;
        })
      },
    },
  }
</script>

<style lang="less">
div > dd{
  margin-top:1rem;
}
.head{
  margin-top:2.5rem;
}
.value1{
  font-size:2rem;
  font-weight:400 ;
  color:#F4EA27
}
.value2{
  font-size:2rem;
  font-weight:400 ;
  color:#779DFA
}
.value3{
  font-size:2rem;
  font-weight:400 ;
  color:#CB4F43
}
</style>
