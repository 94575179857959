<template>
  <div style="position:absolute;width:100%">
    <el-menu
      class="menu-block"
      mode="horizontal"
      :default-active="navMenuIndex"
      @select="handleNavMenuSelect"
      background-color="transparent"
      text-color="#fff"
      active-text-color="#fff"
    >
      <el-menu-item
        v-for="(compTitle, index) in componentList"
        :index="index + 1 + ''"
        :style="{'position': 'absolute', 'left': navMenuLeftMargins[index] + 'rem'}"
        :key="compTitle"
      >{{compTitle}}</el-menu-item>
    </el-menu>

    <div class="sub-menu" :style="{'position': 'absolute', 'left': subMenuLeftMargins[navMenuIndex - 1], 'right': subMenuRightMargins[navMenuIndex - 1]}" v-if="hasSubMenus[navMenuIndex - 1]">
      <div class="sub-menu-item" v-for="(key, index) in Object.keys(components[componentList[navMenuIndex - 1]].children)" :key="key" :style="{color: navSubMenuIndex == index ? '#02e0dc' : '#ffffff'}" @click="switchSubMenu(navMenuIndex, index)">{{key}}</div>
    </div>

    <div id="main" :style="{marginTop: '1.7rem'}">
      <component
        :is="`Comp${navMenuIndex}Sub${navSubMenuIndex}Left`"
        :district-level="districtLevel"
        :district-scale="districtScale"
        :org-level="orgLevel"
        :org-type="orgType"
        :org-options="orgSelectOptions"
        :goto-project="gotoProject"
        :broadcastData="broadcastData[currPageName] || {}"
        :event="event"
        :eventCounter="eventCounter"
        :eventData="eventData"
        :dispatchEvent="dispatchEvent"
      />

      <div id="middle" :style="(components[componentList[navMenuIndex - 1]].children ? components[componentList[navMenuIndex - 1]].children[subComponentLists[navMenuIndex - 1][navSubMenuIndex - 0]].broadcast : components[componentList[navMenuIndex - 1]].broadcast) ? {width: '68rem', 'padding-top': '0'} : {width: '74rem', 'padding-top': '3rem'}">
        <component :v-if="components[componentList[navMenuIndex - 1]].children ? components[componentList[navMenuIndex - 1]].children[subComponentLists[navMenuIndex - 1][navSubMenuIndex - 0]].broadcast : components[componentList[navMenuIndex - 1]].broadcast" :is="`Comp${navMenuIndex}Sub${navSubMenuIndex}Broadcast`" :broadcastData="broadcastData[currPageName] || {}" :event="event" :eventCounter="eventCounter" :eventData="eventData" :dispatchEvent="dispatchEvent" />
        <component
          :is="`Comp${navMenuIndex}Sub${navSubMenuIndex}Map`"
          :stats-type="currPageName"
          :broadcast-details="statsData"
          :go-down-cb="goDownCb"
          :set-district-level="setDistrictLevel"
          :org-level="orgLevel"
          :goto-project="gotoProject"
          :selectedWeatherAlarmLevels="selectedWeatherAlarmLevels"
          :selectedWeatherAlarmTypes="selectedWeatherAlarmTypes"
          :event="event"
          :eventCounter="eventCounter"
          :eventData="eventData"
          :dispatchEvent="dispatchEvent"
          :key="navMenuIndex"
        />
      </div>

      <component
        :is="`Comp${navMenuIndex}Sub${navSubMenuIndex}Right`"
        :district-level="districtLevel"
        :district-scale="districtScale"
        :org-level="orgLevel"
        :org-type="orgType"
        :org-options="orgSelectOptions"
        :goto-project="gotoProject"
        :areaName="areaName"
        :changeWeatherAlarmLevels="changeWeatherAlarmLevels"
        :changeWeatherAlarmTypes="changeWeatherAlarmTypes"
        :broadcastData="broadcastData[currPageName] || {}"
        :event="event"
        :eventCounter="eventCounter"
        :eventData="eventData"
        :dispatchEvent="dispatchEvent"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "indexMain",
  components: {},
  props: {
    orgType: Number,
    orgLevel: String,
    orgSelectOptions: Array,
    componentList: Array,
    subComponentLists: Array,
    components: Object,
    compDict: Object,
    hasSubMenus: Array,
    navMenuLeftMargins: Array,
    subMenuLeftMargins: Array,
    subMenuRightMargins: Array,
    gotoProject: Function,
  },
  data() {
    return {
      navMenuIndex: "1",
      navSubMenuIndex: "0",
      currPageName: 'project',
      event: "",
      eventCounter: 0,
      eventData: null,
      selectedWeatherAlarmLevels: [],
      selectedWeatherAlarmTypes: [],
      districtLevel: "",
      districtScale: 0,
      areaName: '全国',
      broadcastData: {},
      statsData: {},
      companyProjects: [],
      timer: null,
    };
  },
  watch: {
    navMenuIndex: function(newValue, oldValue) {
      this.commonBroadcastWatch(newValue, oldValue);
    },
    navSubMenuIndex: function(newValue, oldValue) {
      this.commonBroadcastWatch(newValue, oldValue);
    },
    districtLevel: function(newValue, oldValue) {
      this.commonBroadcastWatch(newValue, oldValue);
    },
    orgLevel: function(newValue, oldValue) {
      this.commonBroadcastWatch(newValue, oldValue);
    },
    $route: function(newValue) {
      if (newValue.params.nav != this.navMenuIndex) {
        this.navMenuIndex = newValue.params.nav;
      }
    },
    currPageName:function(newValue,oldValue){
      if(newValue!=oldValue){
        this.$emit('changeName',this.currPageName);
      }
    }
  },
  created() {
    this.$options.components = Object.assign(this.$options.components, this.compDict);
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;

    this.navMenuIndex = this.$route.params.nav || "1";
    this.navSubMenuIndex = this.$route.params.subNav || "0";

    if (this.components[this.componentList[this.navMenuIndex - 1]].children && this.navSubMenuIndex != '0') {
      this.switchSubMenu(this.navMenuIndex, this.navSubMenuIndex, true);
    } else {
      this.navSubMenuIndex = "0";
      this.handleNavMenuSelect(this.navMenuIndex, null, null, true);
    }

    this.updateBroadcast();
  },
  methods: {
    dispatchEvent(event, data) {
      this.event = event;
      this.eventData = data;
      this.eventCounter++;
    },
    commonBroadcastWatch(newValue, oldValue) {
      if (newValue != oldValue) {
        if (this.timer) {
          clearTimeout(this.timer);
        }

        this.updateBroadcast();
      }
    },
    scheduleNextBroadcast() {
      this.timer = setTimeout(() => {
        this.updateBroadcast();
      }, 5000);
    },
    updateBroadcast() {
      let comp = this.components[this.componentList[this.navMenuIndex - 1]];
      if (comp.children) {
        comp = this.components[this.componentList[this.navMenuIndex - 1]].children[this.subComponentLists[this.navMenuIndex - 1][this.navSubMenuIndex - 0]];
      }

      if (comp.broadcast) {
        comp.updateBroadcastData(this, () => {
          this.scheduleNextBroadcast();
        });
      }
    },
    setDistrictLevel(areaCode, districtScale, areaName) {
      this.districtLevel = areaCode;
      this.districtScale = districtScale;
      this.areaName = areaName;
    },
    switchSubMenu(navMenuIndex, option, skipURLChange) {
      if (!skipURLChange) {
        this.$router.replace(`/nation/${navMenuIndex}/${option}?token=${window.token}`);
      }
      this.navMenuIndex = navMenuIndex;
      this.navSubMenuIndex = option;
      try {
        let comp = this.components[this.componentList[navMenuIndex - 1]];
        if (comp.children) {
          comp = this.components[this.componentList[navMenuIndex - 1]].children[this.subComponentLists[navMenuIndex - 1][option - 0]];
        }
        this.currPageName = comp.pageName;
      } catch(e) {
        this.currPageName = '';
      }
      // else if(option == '6'){
      //   this.currPageName = 'outputValue';
      //   this.broadcastTitles = [ '年度新开工项目数', '年度已竣工项目数', '计划竣工项目数'];
      //   this.broadcastIcons = [ '年度新开工项目数', '年度已竣工项目数', '计划竣工项目数'];
      //   this.broadcastColors = [ '#FFD454', '#50F194', '#2D7DFF'];
      // }
    },
    formatNumber(num) {
      num = "" + Math.round(num);
      const frags = [];
      while (num.length > 3) {
        frags.splice(0, 0, num.slice(-3));
        num = num.slice(0, -3);
      }
      frags.splice(0, 0, num);
      return frags.join(",");
    },
    handleNavMenuSelect(key, keyPath, component, skipURLChange) {
      if (!skipURLChange) {
        this.$router.replace(`/nation/${key}/0?token=${window.token}`);
      }
      this.navMenuIndex = key;
      this.navSubMenuIndex = '0';

      try {
        let comp = this.components[this.componentList[key - 1]];
        if (comp.children) {
          comp = this.components[this.componentList[key - 1]].children[this.subComponentLists[key - 1][this.navSubMenuIndex - 0]];
        }
        this.currPageName = comp.pageName;
      } catch(e) {
        this.currPageName = '';
      }
    },
    goDownCb() {},
    changeWeatherAlarmLevels(levels) {
      this.selectedWeatherAlarmLevels = levels;
    },
    changeWeatherAlarmTypes(types) {
      this.selectedWeatherAlarmTypes = types;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.menu-block {
  position: fixed;
  top: 0.5rem;
  width: 100%;
  display: flex;
  // justify-content: center;
  border-bottom: none !important;
  pointer-events: none;

  .el-menu-item {
    pointer-events: all;
    padding: 0 12px;
    font-size: 15px;
    border-bottom: none;
    width: 120px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    background: url("../../assets/nation/button.png");
  }

  .el-menu-item.is-active {
    border-bottom: none;
    background: url("../../assets/nation/button_select.png");
  }

  .el-menu-item:not(.is-disabled):hover,
  .el-menu-item:not(.is-disabled):focus {
    border-bottom: none;
    background-color: transparent !important;
  }
}

.sub-menu {
  display: flex;
  margin-top: 0.3rem;
}

.sub-menu-item {
  cursor: pointer;
  border: 1px #1a96c4 solid;
  margin-left: 0.2rem;
  font-size: 0.85rem;
  padding: 0.3rem 0.8rem;
  z-index:33333;
}

#main {
  display: flex;
  padding: 1.25rem 1.875rem 1.5rem 1.875rem;
  height: calc(100vh - 6rem);
  overflow: hidden;

  #left {
    width: 26rem;
  }

  #middle {
    position: relative;
  }

  #right {
    width: 26rem;

    #equipment-status {
      .content {
        background: url("../../assets/shebei.png") no-repeat 4px 8px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 90px;
        height: 5.375rem;
        font-size: 0.875rem;

        p {
          line-height: 1.5rem;
        }
      }
    }

    #people-status {
      .content {
        display: flex;
        padding: 1rem 0 0.5rem;

        .left {
          width: 45%;
          text-align: center;

          .score {
            padding: 0.4rem 0;
            font-size: 1.875rem;
            color: #1fc86b;
          }

          .name {
            font-size: 0.875rem;
          }
        }

        .right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          font-size: 0.875rem;
        }
      }
    }
  }
}
</style>

<style lang="less">
.empty-chart-icon {
  width: 10rem;
  height: 8rem;
  background: url("../../assets/empty_chart.png") no-repeat;
  background-size: contain;
}
.popup-bg {
  background-image: url("../../assets/stats-bg.png");
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
.info-block {
  position: relative;
  width: 100%;
  background: url("../../assets/nation/title.png") no-repeat;
  background-size: contain;
}

.info-block-title {
  position: absolute;
  color: #c8dbf4;
  font-weight: bold;
  left: 2.8rem;
  top: 0.6rem;
}

.option-block {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 6rem;
  border: 1px solid #0d2b50;
  text-align: center;
  cursor: pointer;
}

.option-block-selected {
  color: #fcfcfc;
  border-color: #4999d9;
}

.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}

.detail {
  background-color: #4191e5;
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}


.el-picker-panel {
  color: #eee !important;
  border: 1px solid #121820 !important;
  -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #222933 !important;
}
 .el-date-table th {
  color: #eee !important;
  border-bottom: solid 1px #121820 !important;
}
 .el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #222933 !important;
}
 .el-date-table td.start-date div,
.el-date-table td.end-date div {
  color: #222933 !important;
}
.el-date-table td.start-date span,
.el-date-table td.end-date span {
  background-color: #00adff !important;
}
 .el-date-table td.in-range div {
  background-color: #121820 !important;
}
.el-picker-panel__icon-btn {
  color: #ccc !important;
}
.icons{
  width: 61px;
  height: 61px;
}
</style>
