<template>
  <div id="right">
    <wrapper style="padding: 0;margin-bottom: 1rem;">
      <!-- <div style="display:flex;align-items:center">
        <div style="font-size:1em;flex:1">近七日报警数</div>
        <div class="option-block option-block-selected" style="font-size:0.7rem;min-width:0" @click="() => showAlarmDetail(1)">详情</div>
      </div>
      <div style="height:15.5rem;padding-top:1rem;margin-bottom:-0.3rem">
        <v-chart :options="sevenDayAlarmsLineChart" autoresize />
      </div> -->
      <div class="info-block" style="min-height:5rem">
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>项目班子带班检查情况</div>
        </div>
        <div style="height:18rem;padding-top:3rem;margin-bottom:-0.3rem">
          <div
            v-if="sevenDayAlarmsLineChart.series && [].concat.apply([], sevenDayAlarmsLineChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart v-else :options="sevenDayAlarmsLineChart" autoresize />
        </div>
      </div>
    </wrapper>
    <wrapper style="padding: 0;margin-bottom: 1rem;">
      <!-- <div style="display:flex;align-items:center">
        <div style="font-size:1em;flex:1">近七日预警数</div>
        <div class="option-block option-block-selected" style="font-size:0.7rem;min-width:0" @click="() => showAlarmDetail(2)">详情</div>
      </div>
      <div style="height:15.5rem;padding-top:1rem;margin-bottom:-0.3rem">
        <v-chart :options="sevenDayWarningsLineChart" autoresize />
      </div> -->
      <div class="info-block" style="min-height:5rem">
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>各部门管理人员分布</div>
        </div>
        <div style="height:19rem;padding-top:3rem;margin-bottom:-0.3rem">
          <div
            v-if="sevenDayWarningsLineChart.series && [].concat.apply([], sevenDayWarningsLineChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart v-else :options="sevenDayWarningsLineChart" @click="chartClick" autoresize />
        </div>
      </div>
    </wrapper>
    <div id="people-warning-list">
      <wrapper style="padding: 0">
        <div class="info-block" style="min-height:5rem">
          <div
            class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
          >
            <div>近七日班前班后完成率</div>
          </div>
          <div style="height:18.3rem;padding-top:3rem;margin-bottom:-0.3rem">
          <div
            v-if="sevenDayFinishBarChart.series && [].concat.apply([], sevenDayFinishBarChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
            <v-chart v-else :options="sevenDayFinishBarChart" autoresize />
          </div>
        </div>
        <!-- <div style="font-size:1em">今日预警记录</div>
        <div style="height:3.5rem;background-color:#1b2d5d;margin:0.5rem 0;padding:0.3rem;display:flex;flex-direction:column;justify-content:space-around;font-size:0.75rem;">
          <div style="display:flex">
            <div style="margin:0.3rem 0.5rem;flex:1;display:flex">
              <div style="flex:3;font-weight:600;color:#42a9da">坠落报警</div>
              <div style="flex:1;text-align:right">{{warningStats['坠落']}}</div>
            </div>
            <div style="margin:0.3rem 0.5rem 0.3rem 2rem;flex:1;display:flex">
              <div style="flex:3;font-weight:600;color:#42a9da">紧急呼救</div>
              <div style="flex:1;text-align:right">{{warningStats['呼救']}}</div>
            </div>
          </div>
          <div style="display:flex">
            <div style="margin:0.3rem 0.5rem;flex:1;display:flex">
              <div style="flex:3;font-weight:600;color:#42a9da">危险预警</div>
              <div style="flex:1;text-align:right">{{warningStats['预警']}}</div>
            </div>
            <div style="margin:0.3rem 0.5rem 0.3rem 2rem;flex:1;display:flex">
              <div style="flex:3;font-weight:600;color:#42a9da">静止预警</div>
              <div style="flex:1;text-align:right">{{warningStats['静止']}}</div>
            </div>
          </div>
        </div>
        <div style="height: 11.2rem; overflow-y: scroll">
          <div class="warning-item" v-for="item in warningList" :key="item.id">
            <div>
              <div style="display:flex">
                <div class="name">姓名:</div>
                <div class="value">{{item.worker_name}}</div>
              </div>
              <div style="display:flex">
                <div class="name">位置:</div>
                <div class="value">{{item.location}}</div>
              </div>
            </div>
            <div style="margin-left:0.3rem;">
              <div style="display:flex">
                <div class="name">班组:</div>
                <div class="value">{{item.team_name}}</div>
              </div>
              <div style="display:flex">
                <div class="name">状态:</div>
                <div class="value" :class="'text-' + (statusColorMap[item.alarm_type] || {}).cls">{{item.alarm_type}}</div>
              </div>
            </div>
            <div style="width:2.5rem;margin-left:0.3rem;align-self:center"><time>{{item.createdtime}}</time></div>
          </div>
        </div> -->
      </wrapper>
      <el-dialog custom-class="stats-layer" :visible.sync="alarmDetailShow" width="60rem" top="12rem">
        <wrapper style="height:30rem;backgroundColor:#0e1328;position:relative">
          <div
              class="stats-close-btn"
              @click="alarmDetailShow = false"
          />
          <div>
            <div style="font-size: 20px;padding: 1rem 0 0 0.4rem;">{{popupName}}管理人员</div>
            <div style="margin:3rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:10rem;text-align:center">序号</div>
                <div style="width:15rem;text-align:center">姓名</div>
                <div style="width:15rem;text-align:center">岗位</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div v-for="(item, index) in filteredPopupWorkerInfo" :key="item.innerid" style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem" :style="{ background: index%2 ? '#102855' : '#213a77'}">
                  <div style="width:10rem;text-align:center">{{index+1}}</div>
                  <div style="width:15rem;text-align:center">{{item.manager_name}}</div>
                  <div style="width:15rem;text-align:center">{{item.position_name}}</div>
                </div>
              </div>
            </div>
          </div>
        </wrapper>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { project } from '../../api'
  import wrapper from '../../components/wrap'
  // import { singleLineChartTemplate } from './lineChartTemplates';
  import { SimpleBarChartTemplate, stackedBarChartTemplate } from './barChartTemplates'
  import { applyUpdate } from "../../utils";

  // function addOverflowTooltip(selector) {
  //   setTimeout(() => {
  //     document.querySelectorAll(selector).forEach(function(item) {
  //       if (!item.getAttribute('listener')) {
  //         item.addEventListener('mouseenter', function() {
  //           let newAttribute;
  //           if (item.offsetWidth < item.scrollWidth) {
  //             if (item.getAttribute('title') !== item.innerHTML) {
  //               newAttribute = item.innerHTML;
  //             } else {
  //               return;
  //             }
  //           } else {
  //             newAttribute = '';
  //           }
  //           item.setAttribute('title', newAttribute);
  //         });
  //         item.setAttribute('listener', true);
  //       }
  //     });
  //   }, 30);
  // }

  export default {
    name: 'ManagerRightBar',
    components: {
      'v-chart': ECharts,
      wrapper,
    },
    data () {
      return {
        sevenDayAlarmsLineChart: {},
        sevenDayWarningsLineChart: {},
        warningStats: {},
        warningList: [],
        statusColorMap: {
          正常: { value: '#1fc86b', cls: 'green' },
          呼救: { value: '#f34e53', cls: 'red2' },
          坠落: { value: '#e62c31', cls: 'red1' },
          预警: { value: '#eebc34', cls: 'yellow2' },
          禁区预警: { value: '#eebc34', cls: 'yellow2' },
          危险预警: { value: '#eebc34', cls: 'yellow2' },
          闯入禁区: { value: '#eebc34', cls: 'yellow2' },
          静止: { value: '#eebc34', cls: 'yellow2' },
          倒地: { value: '#F89132', cls: 'yellow1' },
          离线: { value: '#9ca0aa', cls: 'grey' },
          没电: { value: '#eebc34', cls: 'yellow2' }
        },
        alarmDetailShow: false,
        alarmDetailType: 1,
        timer: null,
        option: {
          color: ['#55B6FA', '#FAC61E'],
          grid: {
            contentLabel: true,
            top: '15%',
            left: '12%',
            right: '14%',
            bottom: '20%'
          },
          legend: {
            data: [],
            itemWidth: 20,
            itemHeight: 10,
            textStyle: {
              fontSize: 10,
              color: '#fff'
            }
          },
          tooltip: {
            trigger: 'axis',
            // formatter: '{b}<br>{a0}: {c0}<br>{a1}: {c1}'

            formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                  result += `${params[0].name}`+"</br>"+params[0].marker + " " + params[0].seriesName + " : " + params[0].data;
                  if(params[1]){
                      result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                  }

              }
              return result;
            }
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: false, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              name: '',
            },
            {
              nameTextStyle: {
                padding: [0, 0, 0, 40],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              min: 0,
              max: 100,
              interval: 25,
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // },
              },
              axisLine: { show: false, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              name: '',
            }
          ],
          series: [
            {
              name: '',
              type: 'bar',
              barWidth: 10,
              yAxisIndex: 0,
              data: []
            },
            {
              name: '',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                  {offset: 1, color: 'rgba(27, 208, 128, 0)'}
                ])
              },
              symbolSize: 0,
              yAxisIndex: 1,
              data: [],
            }
          ]
        },
        option2: {
          color: ['#5DD5C3'],
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            left: '5%',
            right: '3%',
            top: '14%',
            bottom: '6%',
            containLabel: true
          },
          xAxis: {
            axisLabel: {
              color: '#e6f2f3',
              rotate: 40,
              fontWeight: 'bold',
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#36445f",
                width: 1,
                type: "solid",
              },
            },
            axisTick: { show: false },
            type: 'category',
            data: []
          },
          yAxis: [{
            type: 'value',
            show: true,
            name: '人数',
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: {
              show: true,
              lineStyle: { color: '#36445f' }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#36445f',
                type: [1, 1],
                width: 1,
              }
            },
            axisTick: { show: false },
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
              // formatter: function(value) {
              //   return value ? value + '%' : value;
              // }
            }
          }],
          series: [
            {
              type: 'bar',
              stack: 'samestack',
              barMaxWidth: 9,
              name: '人数',
              data: []
            }
          ]
        },
        sevenDayFinishBarChart: {},
        option3: {
          color: ['#23CF9E'],
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            left: '7%',
            right: '3%',
            top: '14%',
            bottom: '6%',
            containLabel: true
          },
          xAxis: {
            axisLabel: {
              color: '#e6f2f3',
              rotate: 40,
              fontWeight: 'bold',
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#36445f",
                width: 1,
                type: "solid",
              },
            },
            axisTick: { show: false },
            type: 'category',
            data: []
          },
          yAxis: [{
            type: 'value',
            show: true,
            name: '比例：%',
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: {
              show: true,
              lineStyle: { color: '#36445f' }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#36445f',
                type: [1, 1],
                width: 1,
              }
            },
            axisTick: { show: false },
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
              // formatter: function(value) {
              //   return value ? value + '%' : value;
              // }
            }
          }],
          series: [
            {
              type: 'bar',
              barMaxWidth: 9,
              name: '比例',
              data: []
            }
          ]
        },
        parameter_list: [],
        xAxis_data: [],
        popupName: '',
        filteredPopupWorkerInfo: []
      }
    },
    mounted () {
      const { id: projectID } = this.$route.params;
      const token = this.$route.query.token;
      window.token = token;
      this.projectId = projectID;

      this.getAll();
      // this.updateSevenDayAlarmsLineCharts(projectID);

      // project.getWarningStats(projectID)
      //   .then(d => {
      //     for (const item of d) {
      //       this.warningStats[item.alarm_type] = item.count;
      //     }
      //   })
      //   .catch(e => console.error(e.msg || e.message))

      // project
      //   .getWarningList(projectID)
      //   .then(d => {
      //     this.warningList = d;
      //     addOverflowTooltip('#people-warning-list .value');
      //   })
      //   .catch(e => console.error(e.msg || e.message))

      // this.timer = setInterval(() => {
      //   this.updateSevenDayAlarmsLineCharts(projectID);
      //   project
      //     .getWarningList(projectID)
      //     .then(d => {
      //       this.warningList = d;
      //       addOverflowTooltip('#people-warning-list .value');
      //     })
      //     .catch(e => console.error(e.msg || e.message))
      //   project.getWarningStats(projectID)
      //     .then(d => {
      //       for (const item of d) {
      //         this.warningStats[item.alarm_type] = item.count;
      //       }
      //     })
      //     .catch(e => console.error(e.msg || e.message))
      // }, 1000*15);
    },
    methods: {
      getAll(){
        project.getProjTroubleHeadCheckCollect(this.projectId)
          .then(d => {
            // console.log(d)
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option.legend.data = d.legend;
            this.option.xAxis[0].data = d.xAxis_data;
            this.option.series[0].data = d.bar_series;
            this.option.series[0].name = d.legend[0];
            this.option.yAxis[0].name = d.legend[0];
            this.option.series[1].data = d.line_series;
            this.option.series[1].name = d.legend[1];
            this.option.yAxis[1].name = `${d.legend[1]}：%`;
            this.sevenDayAlarmsLineChart = applyUpdate(chart, this.option);
          })

        project.getProjManagerDeptDistributionCollect(this.projectId)
          .then(d => {
            // console.log(d);
            const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
            this.option2.xAxis.data = d.xAxis_data;
            this.option2.series[0].data = d.series_data;
            this.sevenDayWarningsLineChart = applyUpdate(chart, this.option2);
            this.parameter_list = d.parameter_list;
            this.xAxis_data = d.xAxis_data;
          })

        project.getProjManagerBeforeAfterWorkRateCollect(this.projectId)
          .then(d => {
            // console.log(d)
            const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
            this.option3.xAxis.data = d.xAxis_data;
            this.option3.series[0].data = d.series;
            this.sevenDayFinishBarChart = applyUpdate(chart, this.option3);
          })
      },
      chartClick(params){
        // console.log(params.dataIndex);
        this.alarmDetailShow = true;
        this.popupName = this.xAxis_data[params.dataIndex];
        project.getProjManagerDeptManagerList(this.projectId, this.parameter_list[params.dataIndex].department_id)
          .then(d => {
            // console.log(d)
            this.filteredPopupWorkerInfo = d;
          })
      }
      // updateSevenDayAlarmsLineCharts(projectID) {
      //   project.getSevenDayAlarms(projectID)
      //     .then(d => {
      //       const rgb_colors = [
      //         ['227', '42', '44'],
      //         ['224', '132', '45'],
      //         ['40', '204', '232'],
      //         ['23', '166', '232']
      //       ];

      //       let chart = JSON.parse(JSON.stringify(singleLineChartTemplate));
      //       chart.color = ['#e32a2c', '#e0842d', '#28cce8', '#17a6e8'];
      //       chart.legend.top = 0;
      //       chart.grid.top = '26%';
      //       chart.grid.left = '8%';
      //       chart.grid.bottom = '0';
      //       chart.xAxis.data = d['active_alarm'].xAxis_data;
      //       chart.yAxis[0].minInterval = 1;
      //       chart.yAxis[0].name = '数量';
      //       chart.yAxis[0].nameTextStyle = {
      //         padding: [0, 40, 0, 0],
      //         color: '#e6f2f3',
      //         fontWeight: 'bold',
      //       };
      //       chart.series[0] = Object.assign({}, chart.series[0], d['active_alarm'].series_data[0], {
      //         areaStyle: {
      //           color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
      //             {offset: 0, color: 'rgba(' + rgb_colors[0].join(', ') + ', 0.5)'},
      //             {offset: 1, color: 'rgba(' + rgb_colors[0].join(', ') + ', 0)'}
      //           ])
      //         }
      //       });
      //       for (let i = 1; i < d['active_alarm'].series_data.length; i++) {
      //         chart.series.push(Object.assign({}, chart.series[0], d['active_alarm'].series_data[i], {
      //           areaStyle: {
      //             color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
      //               {offset: 0, color: 'rgba(' + rgb_colors[i % rgb_colors.length].join(', ') + ', 0.5)'},
      //               {offset: 1, color: 'rgba(' + rgb_colors[i % rgb_colors.length].join(', ') + ', 0)'}
      //             ])
      //           }
      //         }));
      //       }
      //       this.sevenDayAlarmsLineChart = chart;

      //       chart = JSON.parse(JSON.stringify(singleLineChartTemplate));
      //       chart.color = ['#e32a2c', '#e0842d', '#28cce8', '#17a6e8'];
      //       chart.legend.top = 0;
      //       chart.grid.top = '37%';
      //       chart.grid.left = '8%';
      //       chart.grid.bottom = '0';
      //       chart.xAxis.data = d['warning_alarm'].xAxis_data;
      //       chart.yAxis[0].minInterval = 1;
      //       chart.yAxis[0].name = '数量';
      //       chart.yAxis[0].nameTextStyle = {
      //         padding: [0, 40, 0, 0],
      //         color: '#e6f2f3',
      //         fontWeight: 'bold',
      //       };
      //       chart.series[0] = Object.assign({}, chart.series[0], d['warning_alarm'].series_data[0], {
      //         areaStyle: {
      //           color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
      //             {offset: 0, color: 'rgba(' + rgb_colors[0].join(', ') + ', 0.5)'},
      //             {offset: 1, color: 'rgba(' + rgb_colors[0].join(', ') + ', 0)'}
      //           ])
      //         }
      //       });
      //       for (let i = 1; i < d['warning_alarm'].series_data.length; i++) {
      //         chart.series.push(Object.assign({}, chart.series[0], d['warning_alarm'].series_data[i], {
      //           areaStyle: {
      //             color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
      //               {offset: 0, color: 'rgba(' + rgb_colors[i % rgb_colors.length].join(', ') + ', 0.5)'},
      //               {offset: 1, color: 'rgba(' + rgb_colors[i % rgb_colors.length].join(', ') + ', 0)'}
      //             ])
      //           }
      //         }));
      //       }
      //       this.sevenDayWarningsLineChart = chart;

      //     })
      //     // eslint-disable-next-line
      //     .catch(e => console.error(e.msg || e.message))
      // },
      // showAlarmDetail(type) {
      //   this.alarmDetailType = type;
      //   this.alarmDetailShow = true;
      // },
    },
    beforeDestroy() {
      clearInterval(this.timer);
    }
  }
</script>

<style scoped lang="less">
.text-green {
  color: #1fc86b !important;
}

.text-yellow1 {
  color: #f89132 !important;
  border-color:#f89132 !important;
}

.text-yellow2 {
  color: #eebc34 !important;
  border-color:#eebc34 !important;
}

.text-red1 {
  color: #e62c31 !important;
}

.text-red2 {
  color: #f34e53 !important;
}

.text-grey {
  color: #9ca0aa !important;
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}

#people-warning-list {
  .warning-item {
    margin-bottom: 0.75rem;
    padding: 8px 6px;
    font-size: 0.75rem;
    line-height: 1.5rem;
    background-color: #1e2c61;
    border-radius: 0.25rem;
    color: #ffffff;
    position: relative;
    display: flex;
    div.name {
      color: #999999;
      width: 2rem;
    }
    div.value {
      width: 3.7rem;
      padding-left: 0.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    time {
      background-color: #577acc;
      border-radius: 4px;
      padding: 5px;
    }
  }
}
</style>
