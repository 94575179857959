<template>
  <div id="left">
    <div class="info-block" style="min-height:5rem">
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>今日工人数</div>
        <div class="detail" @click="showDetail(1)">
          详情
        </div>
      </div>
      <div style="height:19rem;padding-top:3rem">
        <div
          style="display:flex;justify-content:space-around;margin-bottom:0.6rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')"
          >
            在场工人数
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')"
          >
            出勤工人数
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '3' }"
            @click="() => switchCompanyWorkersOption('3')"
          >
            现场工人数
          </div>
        </div>
        <div
          v-if="companyWorkersBarChart.series && [].concat.apply([], companyWorkersBarChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyWorkersBarChart" @click="companyWorkersBarChartClick" autoresize />
      </div>
    </div>
    <div class="info-block" style="min-height:5rem">
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 6rem;"
            :class="{ 'option-block-selected2': mode == '1' }"
            @click="() => swithAttendance('1')"
          >
            劳动力出勤率
          </div>
          <div
            class="option-block2"
            style="min-width: 6rem;"
            :class="{ 'option-block-selected2': mode == '2' }"
            @click="() => swithAttendance('2')"
          >
            工人月均值
          </div>
        </div>
        <div class="detail" @click="showDetail(2)">
          详情
        </div>
      </div>
      <div style="height:19rem;padding-top:3rem">
        <!-- <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem">
          <CustomSelect :options="companyNameList" @input="companySelect" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold" />
        </div> -->
        <div
          v-if="attendanceBarChart.series && [].concat.apply([], attendanceBarChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="attendanceBarChart" @click="attendanceBarChartClick" autoresize />
      </div>
    </div>
    <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
      <div
        class="info-block-title"
        style="width:85%;top:0.6rem;display:flex;align-items:center;justify-content: space-between;"
      >
        <div style="display:flex;align-items:center;padding-right:4.2rem">
          <div style="flex:1">工资情况</div>
        </div>
        <div class="detail" @click="showDetail(3)">
          详情
        </div>
      </div>
      <div style="height:19rem;padding-top:3rem">
        <div
          v-if="WorkersBarChart.series && [].concat.apply([], WorkersBarChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="WorkersBarChart" autoresize />
      </div>
    </div>

     <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow1"
      width="80rem"
      top="12rem"
      :destroy-on-close='true'
    >
      <wrapper style="height:42rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow1 = false"
        />
        <div>
          <div style="padding: 10px 0 0 40px;font-size: 18px;display:flex;align-items:center;">{{popupname}}：<div style="color: #3BE7EF">{{popupCount}}人</div></div>
          <div style="height:18rem;padding-top:1rem">
            <v-chart :options="noActionTroubleBarChart" autoresize />
          </div>
          <div style="display:flex;padding:0 1.5rem">
            <div style="height:19rem;margin:0 0.2rem;padding-top:1rem;padding-bottom:2rem;flex:1;border:1px solid #0e225e">
              <div style="width:100%;display:flex;justify-content:space-between;align-items:middle;margin-bottom:0.5rem">
                <div style="font-size:1rem;line-height:1.6rem;margin-left:1rem">在场工人工种分布</div>
                <CustomSelect :options="companyNameList" :value="selectVal2" @input="companySelect($event, 'val2')" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;margin-right:1rem" />
              </div>
              <div
                v-if="!workPieChart1.series || !workPieChart1.series[0].data.length"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%; height: 12rem;margin-top: 2rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart v-else :options="workPieChart1" autoresize />
            </div>
            <div style="height:19rem;margin:0 0.2rem;padding-top:1rem;padding-bottom:2rem;flex:1;border:1px solid #0e225e">
              <div style="width:100%;display:flex;justify-content:space-between;align-items:middle;margin-bottom:0.5rem">
                <div style="font-size:1rem;line-height:1.6rem;margin-left:1rem">在场工人年龄段分布</div>
                <CustomSelect :options="companyNameList" :value="selectVal3" @input="companySelect($event, 'val3')" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;margin-right:1rem" />
              </div>
              <div
                v-if="!workPieChart2.series || !workPieChart2.series[0].data.length"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%; height: 12rem;margin-top: 2rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart :options="workPieChart2" autoresize />
            </div>
            <div style="height:19rem;margin:0 0.2rem;padding-top:1rem;padding-bottom:2rem;flex:1;border:1px solid #0e225e">
              <div style="width:100%;display:flex;justify-content:space-between;align-items:middle;margin-bottom:0.5rem">
                <div style="font-size:1rem;line-height:1.6rem;margin-left:1rem">在场工人男女比例</div>
                <CustomSelect :options="companyNameList" :value="selectVal4" @input="companySelect($event, 'val4')" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;margin-right:1rem" />
              </div>
              <div
                v-if="!workPieChart3.series || !workPieChart3.series[0].data.length"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%; height: 12rem;margin-top: 2rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart v-else :options="workPieChart3" autoresize />
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="90rem"
      top="12rem"
      :destroy-on-close='true'
    >
      <wrapper style="height:42rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow2 = false"
        />
        <div class="attendance">
          <div class="attendance-top">
            <div class="tabbar">
              <div :class="{ 'tabar-select': popupSelect == '1' }" @click="getAttendance('1')">按月</div>
              <div :class="{ 'tabar-select': popupSelect == '2' }" @click="getAttendance('2')">按周</div>
              <div :class="{ 'tabar-select': popupSelect == '3' }" @click="getAttendance('3')">按日</div>
            </div>
            <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem;">
              <CustomSelect :options="companyNameList" :value="selectVal1" @input="companySelect($event, 'val1')" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;" />
            </div>
            <div style="height:16rem;padding-top:1rem">
              <v-chart :options="attenceWorkersBarChart" @click="attenceWorkersBarChartClick" autoresize />
            </div>
          </div>
          <div class="attendance-bottom">
            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName}}</div>
            <div style="height: 260px; overflow-y: auto;">
              <div class="linebar" v-for="(item, index) in this.popupData2" :key="index">
              <div style="color: #A7B6BC;width: 400px">
                {{item.name}}
              </div>
              <div style="flex: 5">
                <div class="real-bar" :style="{'width': ((item.value/popupData2Num)*100) + '%' }" style="cursor: pointer;" :title="item.name"></div>
              </div>
              <div style="color: #57C7B6">
                {{item.value}}
                <i style="font-style:normal">{{mode === '1' ? '%':'人'}}</i>
              </div>
            </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle}}
            </div>
            <div class="tabbar" style="width:26rem;margin:1rem 0 0 1rem">
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect2 == 1 }"
                @click="getAttendance2(1)"
              >按在场工人数排序</div>
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect2 == 2 }"
                @click="getAttendance2(2)"
              >按出勤工人数排序</div>
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect2 == 3 }"
                @click="getAttendance2(3)"
              >按现场工人数排序</div>
            </div>
          </div>


            <div style="margin:1rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:2rem">序号</div>
                <div style="width:8rem;text-align:center">分公司</div>
                <div style="width:26rem">项目名称</div>
                <div style="width:8rem">所在城市</div>
                <div style="width:9rem">在场工人数（人）</div>
                <div style="width:9rem">出勤工人数（人）</div>
                <div style="width:9rem">现场工人数（人）</div>
                <div style="width:12rem;text-align:center">操作</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popProjectsList"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:2rem">
                    {{securityStarPage>1?(securityStarPage-1)*10+index+1:(index+1>9?(index+1):'0'+(index+1))}}
                  </div>
                  <div style="width:8rem;text-align:center">{{item.org_name}}</div>
                  <div style="width:26rem">{{item.project_name}}</div>
                  <div style="width:8rem;">{{item.city_name}}</div>
                  <div style="width:9rem;">{{item.worker_online_count}}</div>
                  <div style="width:9rem;">{{item.attendance_worker_count}}</div>
                  <div style="width:9rem;">{{item.worker_now_online_count}}</div>
                  <div style="width:12rem;text-align:center" @click="handleSelect(item)">
                    <a href="javascript:;" style="color:#4fb5da;">进入项目大屏</a>
                  </div>
                </div>
                <div
                  v-if="!(popProjectsList && popProjectsList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无新开项目
                </div>
              </div>
              <el-pagination
                background
                layout="prev, pager, next"
                :current-page.sync="securityStarPage"
                :page-count="securityStarPages"
                @current-change="securityStarPageChange"
                style="text-align:center;margin-top:0.5rem"
              ></el-pagination>
            </div>

        </div>
      </wrapper>
    </el-dialog>


    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow4"
      width="90rem"
      top="12rem"
    >
      <wrapper style="height:42rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow4=false"
        />
        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="width:10rem"></div>
            <div class="tabbar" style="margin:0">
              <div :class="{ 'tabar-select': popupSelect3 == '1' }" @click="getAttendance3('1')">按季</div>
              <div :class="{ 'tabar-select': popupSelect3 == '2' }" @click="getAttendance3('2')">按月</div>
            </div>
            <div style="display:flex;justify-content:flex-end;" v-if="mapLevel==0">
              <CustomSelect :options="companyNameList" :value="selectedVal2" @input="companySelect($event, 'val5')" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;" />
            </div>
            <div v-else style="width:10rem"></div>
          </div>
          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="font-size:1rem;font-weight:600;margin:1rem 0 0 2rem">工资情况</div>
            <div style="height:15rem;padding-top:1rem">
              <v-chart :options="workerMoneysBarChart" @click="workerMoneysBarChartClick" autoresize />
            </div>
          </div>
          <div style="margin:0.5rem 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName3}}</div>
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:20rem;text-align:center">{{popupData3cols[0]}}</div>
              <div style="width:12rem;text-align:center">{{popupData3cols[1]}}</div>
              <div style="width:5rem;text-align:center">{{popupData3cols[2]}}</div>
            </div>
            <div style="height:10rem;">
              <div
                v-for="(item, index) in popupData3"
                :key="item.person_id"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:20rem;text-align:center">{{item.name}}</div>
                <div style="width:12rem;text-align:center">
                  {{item.total_count}}
                </div>
                <div style="width:5rem;text-align:center">{{item.rate}}</div>
              </div>
              <div
                v-if="!(popupData3 && popupData3.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无工资情况
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="securityStarPage3"
              :page-count="securityStarPages3"
              @current-change="securityStarPageChange3"
              style="text-align:center;margin-top: 0.5rem;"
            ></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation } from "../../api";
import CustomSelect from '../../components/select'
import { SimpleBarChartTemplate, stackedBarChartTemplate } from "./barChartTemplates";
import { attendancePieChartTemplate, colors } from './pieChartTemplates';
import wrapper from '../../components/wrap';
import { applyUpdate, truncateStr } from "../../utils";

export default {
  name: "LaborLeftBar",
  components: {
    "v-chart": ECharts,
    'CustomSelect': CustomSelect,
    wrapper
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    orgOptions: Array,
    districtScale: Number,
    orgType: Number,
    gotoProject: Function,
  },
  data() {
    return {
      companyNameList: [""],
      companyIdList: [""],
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      selectVal4: '',
      companyWorkersData: {},
      companyWorkersOption: "1",
      companyWorkersBarChart: {},
      option: {
        color: ['#1bd080', '#1bd080'],
        grid: {
          containLabel: true,
          left: '8%',
          right: '6%',
          bottom: "14%",
          top: "12%"
        },
        legend: {
          data: [],
          textStyle:{
            fontSize: 11,//字体大小
            color: '#ffffff'//字体颜色
          },
        },
        tooltip: {
          trigger: 'axis',
          formatter: '{b}<br>{a0}: {c0}'
        },
        xAxis: [
          {
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
              rotate: 40,
              formatter: value => truncateStr(value, 10),
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'category',
            data: []
          }
        ],
        yAxis: [
          {
            show: true,
            name: "人数：人",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            barWidth: 10,
            data: []
          }
        ]
      },
      attendanceBarChart: {},
      option2: {
        tooltip: {
          trigger: 'axis',
          formatter: "{b}<br>{a0}: {c0}"
        },
        color: ['#1bd080', '#1bd080'],
        grid: {
          contentLabel: true,
          left: '12%',
          right: '6%',
          bottom:" 15%",
          top: "13%"
        },
        legend: {
          data: [],
          textStyle:{
            fontSize: 11,//字体大小
            color: '#ffffff'//字体颜色
          },
        },
        xAxis: [
          {
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
              rotate: 35
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'category',
            data: []
          }
        ],
        yAxis: [
          {
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
            show: true
          },
          {
            min: 0,
            max: 100,
            interval: 25,
            nameTextStyle: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
              formatter: function(value) {
                return value ? value + '%' : value;
              },
            },
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
            show: false
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            barWidth: 10,
            data: [],
            markLine: {
              symbol: ["none", "none"], //去掉箭头
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "dashed",
                    color: "#238778",
                  },
                  label: {
                    show: false,
                    position: "middle",
                  },
                },
              },
              data: [
                {
                  yAxis: -1, //这里设置false是隐藏不了的，可以设置为-1
                },
              ],
            }
          },
          {
            name: '数量',
            type: 'line',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2
                }
              }
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                {offset: 1, color: 'rgba(27, 208, 128, 0)'}
              ])
            },
            symbolSize: 0,
            yAxisIndex: 1,
            data: [],
          }
        ]
      },
      avgAttendanceTopLists: {},
      avgAttendanceTopListOption: "project_avg_h_top",
      WorkersBarChart: {},
      option3: {
        color: ["#23cf9e", "#fdc81e"],
        grid: {
          contentLabel: true,
          right: '10%',
          top: "13%",
          left: "15%",
          bottom: "13%"
        },
        legend: {
          data: [],
          textStyle:{
            fontSize: 11,//字体大小
            color: '#ffffff'//字体颜色
          },
        },
        tooltip: {
          trigger: 'axis',
          // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
          formatter: function (params) {
            var result = ``;
            if(params&&params.length){
                result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + params[0].data;
                if(params[1]){
                    result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                }

            }
            return result;
          }
        },
        xAxis: [
          {
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
              rotate: 35
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'category',
            data: []
          }
        ],
        yAxis: [
          {
            name: "工资总额：万",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
            show: true
          },
          {
            show: true,
            name: "代付率：%",
            min: 0,
            max: 100,
            interval: 25,
            nameTextStyle: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            barWidth: 10,
            data: []
          },
          {
            name: '数量',
            type: 'line',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2
                }
              }
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                {offset: 1, color: 'rgba(27, 208, 128, 0)'}
              ])
            },
            symbolSize: 0,
            yAxisIndex: 1,
            data: [],
          }
        ]
      },
      mode: "1",
      popupShow1: false,
      noActionTroubleBarChart: {},
      option4: {
        color: ['#23cf9e', '#eab91e', '#20bbee'],
        grid: {
          top: '20%',
          bottom: '1%',
          left: "5%",
          right: "5%",
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          icon: 'rect',
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 12,
          textStyle: {
            color: '#ccc',
            fontWeight: 'bold',
            fontSize: '11'
          },
          padding: [8, 0, 0, 0],
          top: '4%',
          right: '3%',
        },
        xAxis: {
          axisLabel: {
            color: '#e6f2f3',
            rotate: 40,
            fontWeight: 'bold',
          },
          show: true,
          axisLine: { show: true, lineStyle: { color: '#36445f' } },
          splitLine: { show: false },
          axisTick: { show: false },
          type: 'category',
          data: []
        },
        yAxis: [{
          type: 'value',
          show: true,
          name: '人数：人',
          nameTextStyle: {
            padding: [0, 40, 0, 0],
            color: '#e6f2f3',
            fontWeight: 'bold',
          },
          minInterval: 1,
          axisLine: {
            show: true,
            lineStyle: { color: '#36445f' }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#36445f',
              type: [1, 1],
              width: 1,
            }
          },
          axisTick: { show: false },
          axisLabel: {
            color: '#e6f2f3',
            fontWeight: 'bold',
          }
        }],
        series: [
          {
            type: 'bar',
            stack: '',
            barMaxWidth: 9,
            barGap: 0,
            data: []
          },
          {
            type: 'bar',
            stack: '',
            barMaxWidth: 9,
            data: [],
          },
          {
            type: 'bar',
            stack: '',
            barMaxWidth: 9,
            data: [],
          }
        ]
      },
      popupCount: 0,
      popupname: '在场工人数',
      workPieChart1: {},
      workPieChart2: {},
      workPieChart3: {},
      popupShow2: false,
      popupSelect: '2',
      attenceWorkersBarChart: {},
      option5: {
        color: ['#1bd080', '#1bd080'],
        grid: {
          contentLabel: true,
          right: '6%',
          bottom: "15%",
          top: "13%",
          left: "8%"
        },
        legend: {
          data: [],
          textStyle:{
            fontSize: 11,//字体大小
            color: '#ffffff'//字体颜色
          },
        },
        tooltip: {
          trigger: 'axis',
          formatter: "{b}<br>{a0}: {c0}"
        },
        xAxis: [
          {
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'category',
            data: []
          }
        ],
        yAxis: [
          {
            show: true,
            name: "",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
          }
        ],
        series: [
          {
            name: "",
            type: 'bar',
            barWidth: 10,
            data: []
          }
        ]
      },
      popupBottomName: '',
      popupSearchkeys: [],
      popupData: [],
      popupXAxisData: [],
      popupData2: [],
      popupData2Num: 0,
      org_id: '',
      popupShow3: false,
      isFirst: true,

      mapLevel: 0,
      activeIndex:0,
      popupShow: false,
      popupSelect2: 1,
      popupShowSearchKey:[],
      popupTitle: "",
      popProjectsList: [],
      securityStarPage: 1,
      securityStarPages: 1,

      popupShow4: false,
      selectedVal2:'全部分公司',
        popupSelect3: '1',
        workerMoneysBarChart: {},
        workerMoneysBarChartTemplate: {
          color: ["#23CF9E", "#14B0BB"],
          grid: {
            contentLabel: true,
            right: '5%',
            top: "13%",
            left: "5%",
            bottom: "15%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          tooltip: {
            trigger: 'axis',
            // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
            formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + (params[0].data.value?params[0].data.value:params[0].data);
                if(params[1]){
                  result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                }
              }
              return result
            }
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              name: "工资总额：万元",
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true
            },
            {
              show: true,
              name: "代付率：%",
              min: 0,
              max: 100,
              interval: 25,
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // },
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
            }
          ],
          series: [
            {
              name: '工资总额',
              type: 'bar',
              barWidth: 10,
              data: []
            },
            {
              name: '代付率',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                  {offset: 1, color: 'rgba(27, 208, 128, 0)'}
                ])
              },
              symbolSize: 0,
              yAxisIndex: 1,
              data: [],
            }
          ]
        },
        // org_id: '',
        popupBottomName3: '',
        popupSearchkeys3: [],
        popupXAxisData3: [],
        popupBarData: [],
        popupData3: [],
        popupData3cols: [],
        securityStarPage3: 1,
        securityStarPages3: 1,
        dataIndex: -1,

    };
  },
  watch: {
    orgOptions(newValue, oldValue) {
      if (newValue != oldValue) {
        this.companyNameList = newValue.map(item => truncateStr(item.org_name, 10));
        this.companyIdList = newValue.map(item => item.org_id);
      }
    },
    orgLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.getAll();
      }
    },
    districtLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
        this.getAll();
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.mapLevel = newValue;
      }
    },
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;

    this.companyNameList = this.orgOptions.map(item => truncateStr(item.org_name, 10));
    this.companyIdList = this.orgOptions.map(item => item.org_id);

    this.getAll();

    // nation.getAvgAttendanceTopLists()
    //   .then(d => {
    //     this.avgAttendanceTopLists = d;
    //   })
    //   // eslint-disable-next-line
    //   .catch(e => console.error(e.msg || e.message))
  },
  methods: {
    handleSelect(item) {
      this.gotoProject(item.project_id);
    },

    companyWorkersBarChartClick(params){
      if(this.mapLevel!==2){
        // console.log(params)
        this.popupSelect2=this.companyWorkersOption;
        this.popupShow = true;
        this.popupTitle = params.name ;
        this.securityStarPage=1;
        this.activeIndex=params.dataIndex;

        this.securityStarPageChange(1);
      }

    },
    securityStarPageChange(currPage) {
      // console.log(this.orgType)
      nation
        .getLaborWorkerCountCollect(this.popupSelect2,currPage, 10,this.mapLevel==0&&this.orgType == 1?this.popupShowSearchKey[this.activeIndex]:'',this.mapLevel==1||this.orgType !== 1?this.popupShowSearchKey[this.activeIndex]:'')
        .then((d) => {
          this.popProjectsList = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },

    getAttendance2(popupSelect) {
      this.popupSelect2 = popupSelect;
      this.securityStarPage=1;
      this.securityStarPageChange(1)
    },
    getAll(){
      this.getLaborOrgWorkerCount();

      this.swithAttendance(this.mode);

       // 劳动力人数
      nation.getLaborSalaryCollect(2).then((d) => {
        // console.log(d);
        const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
        this.option3.legend.data = d.legend;
        this.option3.xAxis[0].data = d.xAxis_data;
        this.option3.series[0].name = d.legend[0];
        this.option3.series[0].data = d.bar_series;
        this.option3.series[1].name = d.legend[1];
        this.option3.series[1].data = d.line_series;
        // chart.grid.bottom = '30%';
        // chart.yAxis[1].axisLabel.color = '#fff';
        // chart.series[1].itemStyle.normal.lineStyle.color = '#AE9129';
        // chart.yAxis[0].max = Math.max.apply(null, d.map(x => Math.round(x.people_count / x.attendance_rate * 100)))
        // chart.yAxis[0].position = 'left'
        this.WorkersBarChart = applyUpdate(chart, this.option3);
      });
    },
    swithAttendance(mode) {
      this.mode = mode;
      this.getAttendanceOption();
    },
    attendanceBarChartClick(params){
        this.activeIndex=params.dataIndex;
        this.org_id = this.orgLevel;
        const idx = this.companyIdList.indexOf(this.orgLevel);
        this.selectVal1 = this.companyNameList[idx];
        this.popupShow2 = true;
        this.getAttendance(1);
    },
    getAttendanceOption() {
      nation.getMonth12AttendanceInfo(this.mode).then((d) => {
        // console.log(d);
        const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
        this.option2.xAxis[0].data = d.xAxis_data;
        this.option2.series[0].data = d.series;
        // eslint-disable-next-line no-empty
        if (this.mode === "1") {
          this.option2.series[0].name = "出勤率";
          this.option2.yAxis[0].name = "出勤率：%";
          // eslint-disable-next-line no-empty
        } else if (this.mode === "2") {
          this.option2.series[0].name = "人数";
          this.option2.yAxis[0].name = "人数：人";
        }
        // eslint-disable-next-line no-empty
        if (d.config.theta) {
          this.option2.series[0].markLine.data[0].yAxis = d.config.theta;
        }
        this.attendanceBarChart = applyUpdate(chart, this.option2);
      });
    },
    updateCompanyWorkersChart(type, data) {
      const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
      // const data = this.companyWorkersData[type];
      if (type == "1") {
        this.option.series[0].name = "在场工人数";
        this.popupname = "在场工人数";
      } else if (type == "2") {
        this.option.series[0].name = "出勤工人数";
        this.popupname = "出勤工人数";
      } else if (type == "3") {
        this.option.series[0].name = "现场工人数";
        this.popupname = "现场工人数";
      }
      this.option.xAxis[0].data = data.xAxis_data;
      this.option.series[0].data = data.series;
      // chart.series = [chart.series[0]];
      this.popupShowSearchKey=data.search_keys;

      if (this.districtScale == 2) {
        this.option.grid.left = '15%';
      } else {
        this.option.grid.left = '8%';
      }

      // chart.yAxis = [chart.yAxis[0]];
      this.companyWorkersBarChart = applyUpdate(chart, this.option);
    },
    switchCompanyWorkersOption(option) {
      this.companyWorkersOption = option;
      this.getLaborOrgWorkerCount();
      // this.updateCompanyWorkersChart(option);
    },
    getLaborOrgWorkerCount() {
      nation
        .getLaborOrgWorkerCount(this.companyWorkersOption)
        .then((d) => {
          // console.log(d);
          this.companyWorkersData = d;
          this.updateCompanyWorkersChart(
            this.companyWorkersOption,
            this.companyWorkersData
          );
        })

        .catch((e) => console.error(e.msg || e.message));
    },
    switchAvgAttendanceTopListOption(option) {
      this.avgAttendanceTopListOption = option;
    },
    companySelect(option, val) {
      const idx = this.companyNameList.indexOf(option);
      if (idx >= 0) {
        this.org_id = this.companyIdList[idx];
        // window.$orgLevel = this.org_id;
        if(val === 'val1'){
          this.selectVal1 = option;
          this.getAttendance(this.popupSelect);
        }else if(val === 'val2'){
          // eslint-disable-next-line
          this.selectVal2 = option;
          if(!this.isFirst){
            this.getPieChart();
          }
        }else if(val === 'val3'){
          // eslint-disable-next-line
          this.selectVal3 = option;
          if(!this.isFirst){
            this.getPieChart2();
          }
        }else if(val === 'val4'){
          this.selectVal4 = option;
          if(!this.isFirst){
            this.getPieChart3();
          }
          // eslint-disable-next-line
        }else{
          // 55555
          this.selectedVal2 = option;
          this.securityStarPage3 = 1;
          this.getAttendance3(this.popupSelect3);
        }
        // console.log(this.companyIdList[idx]);
      }
    },
    showDetail(val) {
      // eslint-disable-next-line no-empty
      const idx = this.companyIdList.indexOf(this.orgLevel);
      if (val === 1) {
        this.org_id = this.orgLevel;
        this.selectVal2 = this.companyNameList[idx];
        this.selectVal3 = this.companyNameList[idx];
        this.selectVal4 = this.companyNameList[idx];
        this.popupShow1 = true;
        this.getWorkCount();
        this.getPieChart();
        this.getPieChart2();
        this.getPieChart3();
        // eslint-disable-next-line no-empty
      } else if (val === 2) {
        this.org_id = this.orgLevel;
        this.selectVal1 = this.companyNameList[idx];
        this.popupShow2 = true;
        this.activeIndex=this.attendanceBarChart.xAxis[0].data.length-1;
        this.getAttendance(this.popupSelect);
        // eslint-disable-next-line no-empty
      } else {

        this.selectedVal2 = this.companyNameList[idx];
        this.org_id = this.orgLevel;
        this.popupShow4 = true;
        this.getAttendance3(this.popupSelect3);
      }
    },

      getAttendance3(popupSelect){
        this.popupSelect3 = popupSelect;
        nation.getLaborSalaryDateCollect( this.popupSelect3, this.org_id ? this.org_id:'')
          .then(d => {

            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.workerMoneysBarChartTemplate.legend.data = d.legend;
            this.workerMoneysBarChartTemplate.xAxis[0].data = d.xAxis_data;
            d.bar_series[d.bar_series.length-1] = {
              value: d.bar_series[d.bar_series.length-1],
              itemStyle: {
                color: '#FFD153'
              }
            };
            this.workerMoneysBarChartTemplate.series[0].data = d.bar_series;
            this.workerMoneysBarChartTemplate.series[0].name = d.legend[0];
            this.workerMoneysBarChartTemplate.series[1].data = d.line_series;
            this.workerMoneysBarChartTemplate.series[1].name = d.legend[1];
            this.workerMoneysBarChart = applyUpdate(chart, this.workerMoneysBarChartTemplate);

            this.popupBottomName3 = `${this.workerMoneysBarChartTemplate.xAxis[0].data[this.workerMoneysBarChartTemplate.xAxis[0].data.length-1]}工资情况`;
            this.getLaborAttendanceDateOrgCountCollect(d.search_keys[d.search_keys.length-1]);
            this.popupSearchkeys3 = d.search_keys;
            this.popupXAxisData3 = d.xAxis_data;
            this.popupBarData = d.bar_series;
          })
      },
      workerMoneysBarChartClick(params){
        // console.log(params);
        // console.log(params.dataIndex);
        let subData = this.popupBarData;
        this.workerMoneysBarChart.series[0].data = subData.map(function (item, index) {
          return index === params.dataIndex ? {
              value: Object.prototype.toString.call(item) === '[object Object]' ? item.value:item,
              itemStyle: {
                color: '#FFD153'
              }
            }:(Object.prototype.toString.call(item) === '[object Object]' ? item.value:item)
        });
        this.popupBottomName3 = `${this.popupXAxisData3[params.dataIndex]}工资情况`;
        this.getLaborAttendanceDateOrgCountCollect(this.popupSearchkeys3[params.dataIndex]);
        this.dataIndex = params.dataIndex;
        this.securityStarPage3=1;
      },
      getLaborAttendanceDateOrgCountCollect(search_keys){
        // window.$orgLevel = this.org_id;
        nation.getLaborSalaryOrgProjectCollect(this.popupSelect3,this.securityStarPage3, 5, this.org_id ? this.org_id:'',search_keys)
          .then(d => {
            // console.log(d);
            this.popupData3 = d.data;
            this.popupData3cols=d.cols;
            this.securityStarPages3 = Math.ceil(d.__count__ / 5);
          })
      },
      securityStarPageChange3(currPage) {
        // console.log(currPage);
        this.securityStarPage3 = currPage;
        this.getLaborAttendanceDateOrgCountCollect(this.popupSearchkeys3[this.dataIndex]);
      },
    getWorkCount(){
       nation.getLaborWorkerCountOrgCollect()
        .then(d => {
          // console.log(d);
          const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
          this.option4.xAxis.data = d.xAxis_data;
          this.option4.legend.data = d.legend_data;
          this.option4.series[0].name = d.legend_data[0]
          this.option4.series[0].data = d.series[0]
          this.option4.series[1].name = d.legend_data[1];
          this.option4.series[1].data = d.series[1]
          this.option4.series[2].name = d.legend_data[2];
          this.option4.series[2].data = d.series[2]
          this.popupCount = eval(d.series[Number(this.companyWorkersOption)-1].join("+"));
          this.noActionTroubleBarChart = applyUpdate(chart, this.option4);
        })
    },
    getPieChart(){
      nation.getLaborWorkerCountWorkerAttrCollect(1, this.org_id ? this.org_id:'')
        .then(d => {
          // console.log(d)
          this.isFirst = false;
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
          chart.series[0].label.normal.formatter = attendancePieChartTemplate.series[0].label.normal.formatter;
          chart.color = colors;

          const maxPieces = 14;
          if (d.length > maxPieces) {
            d[maxPieces - 1].value = d.slice(maxPieces - 1).reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
            d[maxPieces - 1].name = '其它工种';
            d = d.slice(0, maxPieces);
          }

          chart.series[0].data = d;

          // 默认高亮第一个值不是0的分片，否则默认第一片
          this.workerTypePieChartHighlightIndex = 0;
          for (let i = 0; i < d.length; i++) {
            if (d[i].value) {
              this.workerTypePieChartHighlightIndex = i;
              break;
            }
          }

          chart.series.push(Object.assign({}, chart.series[0], { label: {
            rich: {
              text: {
                fontSize: 11
              }
            },
            formatter: function (params) {
              return `${params.name} ${Math.round(params.percent)}%`;
            },
          }, silent: true }));

          setTimeout(
            () =>
              this.$refs.workerType &&
              this.$refs.workerType.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: this.workerTypePieChartHighlightIndex
              }),
            100
          );
          this.workPieChart1 = chart;
        })
    },
    getPieChart2(){
      nation.getLaborWorkerCountWorkerAttrCollect(2, this.org_id ? this.org_id:'')
        .then(d => {
          // console.log(d)
          this.isFirst = false;
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
          chart.series[0].label.normal.formatter = attendancePieChartTemplate.series[0].label.normal.formatter;
          chart.color = colors;

          const maxPieces = 14;
          if (d.length > maxPieces) {
            d[maxPieces - 1].value = d.slice(maxPieces - 1).reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
            d[maxPieces - 1].name = '其它';
            d = d.slice(0, maxPieces);
          }
          chart.series[0].data = d;

          // 默认高亮第一个值不是0的分片，否则默认第一片
          this.workerTypePieChartHighlightIndex = 0;
          for (let i = 0; i < d.length; i++) {
            if (d[i].value) {
              this.workerTypePieChartHighlightIndex = i;
              break;
            }
          }

          chart.series.push(Object.assign({}, chart.series[0], { label: {
            rich: {
              text: {
                fontSize: 11
              }
            },
            formatter: function (params) {
              return `${params.name} ${Math.round(params.percent)}%`;
            },
          }, silent: true }));

          setTimeout(
            () =>
              this.$refs.workerType &&
              this.$refs.workerType.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: this.workerTypePieChartHighlightIndex
              }),
            100
          );
          this.workPieChart2 = chart;
        })
    },
    getPieChart3(){
      nation.getLaborWorkerCountWorkerAttrCollect(3, this.org_id ? this.org_id:'')
        .then(d => {
          // console.log(d)
          this.isFirst = false;
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
          chart.series[0].label.normal.formatter = attendancePieChartTemplate.series[0].label.normal.formatter;
          chart.color = colors;

          const maxPieces = 14;
          if (d.length > maxPieces) {
            d[maxPieces - 1].value = d.slice(maxPieces - 1).reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
            d[maxPieces - 1].name = '其它';
            d = d.slice(0, maxPieces);
          }
          chart.series[0].data = d;

          // 默认高亮第一个值不是0的分片，否则默认第一片
          this.workerTypePieChartHighlightIndex = 0;
          for (let i = 0; i < d.length; i++) {
            if (d[i].value) {
              this.workerTypePieChartHighlightIndex = i;
              break;
            }
          }

          chart.series.push(Object.assign({}, chart.series[0], { label: {
            rich: {
              text: {
                fontSize: 11
              }
            },
            formatter: function (params) {
              return `${params.name} ${Math.round(params.percent)}%`;
            },
          }, silent: true }));

          setTimeout(
            () =>
              this.$refs.workerType &&
              this.$refs.workerType.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: this.workerTypePieChartHighlightIndex
              }),
            100
          );
          this.workPieChart3 = chart;
        })
    },
    getAttendance(popupSelect){
      this.popupSelect = popupSelect;
      nation.getLaborAttendanceDateCollect(this.popupSelect, this.org_id ? this.org_id:'', this.mode)
        .then(d => {
          // console.log(d);
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option5.xAxis[0].data = d.xAxis_data;
          this.popupData = d.series;
          d.series[this.activeIndex] = {
            value: d.series[this.activeIndex],
            itemStyle: {
              color: '#4191E5'
            }
          };
          this.option5.series[0].data = d.series;
          // chart.series = [chart.series[0]];
          // chart.xAxis[0].axisLabel.rotate = 45;
          // chart.yAxis = [chart.yAxis[0]];
          if(this.mode === '1'){
            this.option5.yAxis[0].name = '出勤率：%';
            this.option5.series[0].name = '出勤率';
            this.popupBottomName = `${d.xAxis_data[this.activeIndex]}考勤排行`;
          }else{
            this.option5.yAxis[0].name = '人数：人';
            this.option5.series[0].name = '人数';
            this.popupBottomName = `${d.xAxis_data[this.activeIndex]}均值排行`;
          }
          this.attenceWorkersBarChart = applyUpdate(chart, this.option5);
          this.getLaborAttendanceDateOrgProjectCollect(d.search_keys[this.activeIndex]);
          this.popupSearchkeys = d.search_keys;
          this.popupXAxisData = d.xAxis_data;
        })
    },
    attenceWorkersBarChartClick(params){
      // console.log(params);
      // console.log(params.dataIndex);
      let subData = this.popupData;
      // this.attenceWorkersBarChart = Object.assign({}, this.attenceWorkersBarChart, {
      //   series: {
      //     data: subData.map(function (item, index) {
      //       return index === params.dataIndex ? {
      //           value: Object.prototype.toString.call(item) === '[object Object]' ? item.value:item,
      //           itemStyle: {
      //             color: '#4191E5'
      //           }
      //         }:(Object.prototype.toString.call(item) === '[object Object]' ? item.value:item)
      //     }),
      //   }
      // });
      this.attenceWorkersBarChart.series[0].data = subData.map(function (item, index) {
        return index === params.dataIndex ? {
            value: Object.prototype.toString.call(item) === '[object Object]' ? item.value:item,
            itemStyle: {
              color: '#4191E5'
            }
          }:(Object.prototype.toString.call(item) === '[object Object]' ? item.value:item)
      });
      if(this.mode === '1'){
        this.popupBottomName = `${this.popupXAxisData[params.dataIndex]}考勤排行`;
      }else{
        this.popupBottomName = `${this.popupXAxisData[params.dataIndex]}均值排行`;    
      }
      this.getLaborAttendanceDateOrgProjectCollect(this.popupSearchkeys[params.dataIndex]);
    },
    getLaborAttendanceDateOrgProjectCollect(search_keys){
      // window.$orgLevel = this.org_id;
      nation.getLaborAttendanceDateOrgProjectCollect(this.popupSelect, search_keys, 1000000, this.org_id ? this.org_id:'', this.mode)
        .then(d => {
          // console.log(d);
          this.popupData2 = d.series.map((item,index) => {
              return {value: item, name: d.yAxis_data[index]};
          });
          // this.popupData2Num = eval(d.series.join("+"))
          this.popupData2Num = d.series.reduce((prev, cur) => {
              return prev + cur;
          },0);
        })
    }
  },
};
</script>

<style scoped lang="less">
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}

.option-block2 {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3.4rem;
  border: 1px solid #1b8ebd;
  text-align: center;
  cursor: pointer;
}
.option-block-selected{
  background-color: transparent !important;
  border: 1px solid #274C7D !important;
}
.option-block-selected2 {
  background-color: #4191e5;
  color: #fff;
}
.attendance{

}
.attendance-top{
  padding-top: 10px;
}
.tabbar{
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  border: 1px solid #157EAB;
  width: 210px;
  margin: auto;
  cursor: pointer;
}
.tabbar div{
  flex: 1;
  text-align: center;
}
.tabar-select{
  background-color: #4191E5;
  font-weight: bold;
}
.items div{
  color: #fff !important;
}
.selected{
  color: #fff !important;
}
.attendance-bottom{

}
.linebar{
  margin-top: 5rem;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto 10px;
}
.real-bar{
  height: 5px;
  background-color: #57C7B6;
  border-radius: 12px;
}
</style>
