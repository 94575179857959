<template>
  <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title" style="display:flex;width:100%;padding-right:3.1rem">
      <div style="flex:1">项目全周期净流贡献</div>
      <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '1' }"
              @click="() => switchAvgAttendanceTopListOption('1')"
            >正向贡献</div>
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '2' }"
              @click="() => switchAvgAttendanceTopListOption('2')"
            >负向贡献</div>
            <div
              class="detail"
              style="margin-left: 0.5rem;"
              @click=" showDetail()"
            >
              详情
            </div>
          </div>
    </div>
    <div style="height:19rem;padding-top:3rem">
      <div
        v-if="!projectContributionChart.series || !projectContributionChart.series[0].data.length"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart  v-else  :options="projectContributionChart" @click="projectContributionChartClick" autoresize />
    </div>
    <!-- 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div class="tabbar" style="margin:0">
              <div :class="{ 'tabar-select': popupSelect == '1' }" @click="getAttendance('1')">图表展示</div>
              <div :class="{ 'tabar-select': popupSelect == '2' }" @click="getAttendance('2')">表格展示</div>
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                <el-tabs id ="tabs" @tab-click = "handleClick">
                <el-tab-pane label="正向贡献">
                  <div  v-if="popupSelect == '1'" style="height:22rem;padding-top:1rem">
                    <div v-if="!forwardContributionChart.series || !forwardContributionChart.series[0].data.length"
                     style="margin-top:20px;color:#ccc">暂无数据</div>
                    <v-chart v-else :options="forwardContributionChart" autoresize />
                  </div>
                  <el-table v-if="popupSelect == '2'"  style="background-color:#0E1328" height="22rem" :data="tableData1" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column
                        prop="branchName"
                        label="分公司">
                        </el-table-column>
                    <el-table-column
                        prop="assessUnit"
                        label="考核分类机构">
                    </el-table-column>
                      <el-table-column
                        prop="profitCode"
                        label="利润中心">
                      </el-table-column>
                    <el-table-column
                      prop="projName"
                      label="项目名称">
                    </el-table-column>
                    <el-table-column
                        prop="totalNetFlow"
                        label="项目总净流"
                        :formatter="Formatter">
                      </el-table-column>
                      <el-table-column
                        prop="monthAccNetFlow"
                        label="截止本月项目累计净流"
                        :formatter="Formatter">
                      </el-table-column>
                      <el-table-column
                        prop="projFutureNetFlow"
                        label="项目未来净流"
                        :formatter="Formatter">
                      </el-table-column>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane label="负向贡献">
                  <div  v-if="popupSelect == '1'" style="height:22rem;padding-top:1rem">
                    <div v-if="!forwardContributionChart.series || !forwardContributionChart.series[0].data.length"
                     style="margin-top:20px;color:#ccc">暂无数据</div>
                    <v-chart v-else :options="forwardContributionChart" autoresize />
                  </div>
                  <el-table v-if="popupSelect == '2'"  style="background-color:#0E1328"  height="22rem" :data="tableData2" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column
                        prop="branchName"
                        label="分公司">
                        </el-table-column>
                    <el-table-column
                        prop="assessUnit"
                        label="考核分类机构">
                    </el-table-column>
                      <el-table-column
                        prop="profitCode"
                        label="利润中心">
                      </el-table-column>
                    <el-table-column
                      prop="projName"
                      label="项目名称">
                    </el-table-column>
                    <el-table-column
                        prop="totalNetFlow"
                        label="项目总净流"
                        :formatter="Formatter">
                      </el-table-column>
                      <el-table-column
                        prop="monthAccNetFlow"
                        label="截止本月项目累计净流"
                        :formatter="Formatter">
                      </el-table-column>
                      <el-table-column
                        prop="projFutureNetFlow"
                        label="项目未来净流"
                        :formatter="Formatter">
                      </el-table-column>
                  </el-table>
                </el-tab-pane>
              </el-tabs>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { finance } from '../../../../api_finance'
  import { SimpleBarChartTemplate } from "../../barChartTemplates";
  import { applyUpdate, truncateStr } from "../../../../utils";
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'ProjectNetFlowContributionChart',
    components: {
      'v-chart': ECharts,
      wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateNetFlowChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateNetFlowChart();
        }
      }
    },
    data () {
      return {
        projectContributionChart: {},
        mode:"1",
        companyWorkersOption:"1",
        popupShow:false,
        tableData1:[],
        tableData2:[],
        popupSelect:"1",
        forwardContributionChart:{}
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.updateNetFlowChart();
    },
    methods: {
      handleClick(tab){
        if(tab.index =="0")
          this.mode = "1";
        else
          this.mode = "2";
        this.showDetail();
      },
      getAttendance(choose){
        this.popupSelect = choose;
      },
      Formatter(row, column, cellValue){
            return (Math.floor(cellValue * 100) / 100).toFixed(2);
        },
      tableRowStyle({rowIndex}){
        if(rowIndex%2 === 0)
            return {backgroundColor:'#213a77',height: '0'}; 
        else   
            return {backgroundColor:'#102855',height: '0'}; 
      },
      tableHeaderColor(){
        return 'color:#4fb5da;background:#102855';
      },
      switchAvgAttendanceTopListOption(choose){
        this.mode = choose;
        this.updateNetFlowChart();
      },
      projectContributionChartClick(){
        this.showDetail();
      },
      showDetail(){
        this.popupShow = true;
        finance.queryProjDistributionDetail().then((d=>{
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            const option = {
              color:[
              '#f32c2c',
              '#f38e2c',
              '#2ce4ff',
              '#19b6fb',
              '#5dd5c3',
              '#ffed21',
              '#159172',
              '#fb9c5c'
            ],
              tooltip: {
                trigger: "axis",
                formatter: "{b}<br>{a0}: {c0}",
              },
              legend:{
                data:["总净流","剩余净流","累计净流"]
              },
              dataZoom: [{
                type: 'slider',
                show: true,
                start: 0,
                end: 100,
                yAxisIndex: 0,
                filterMode: 'empty',
                width: 6,
                height: '78%',
                left: '95%',
                handleSize: 0,
                zoomLock: true,
                top: '8%',
                fillerColor: '#323d86',
                borderColor: '#323d86',
                textStyle: {
                  color: "#fff",
                  fontSize: 10,
                }
              }, {
                type: 'inside',
                yAxisIndex: 0,
                start: 0,
                end: 100,
                zoomOnMouseWheel: false,
                moveOnMouseMove: true,
                moveOnMouseWheel: true
              }],
              xAxis: [
                {
                  show: true,
                  name: "单位：亿",
                  type: "value",
                  nameTextStyle: {
                    padding: [0, 40, 0, 0],
                    color: "#e6f2f3",
                    fontWeight: "bold",
                    align: "left",
                  },
                  axisLabel: {
                    color: "#e6f2f3",
                    fontWeight: "bold",
                  },
                  axisLine: { show: true, lineStyle: { color: "#36445f" } },
                  splitLine: {
                    show: false
                  },
                  axisTick: { show: false },
                },
              ],
              yAxis: [
                {
                  type: "category",
                  data: [],
                  axisLabel: {
                    color: "#e6f2f3",
                    show:true,
                    lineStyle: {
                        color: "#rgb(77,83,141)",
                    },
                    fontWeight: "normal",
                    formatter:value => truncateStr(value,10)
                  }
                }
              ],
              series: [
                {
                  name: "总净流",
                  type: "line",
                  barWidth: 10,
                  markLine:{
                    symbol: ["none", "none"],
                    lineStyle:{
                      normal: {
                            width: 2,
                            color: "rgb(255,128,0)", // 线条颜色
                        },
                    },
                    itemStyle: {
                      normal: {
                        label: {
                          show: false,
                          position: "middle",
                        },
                      },
                    },
                    data: [],
                  }
                },{
                  name: "剩余净流",
                  type: "bar",
                  barWidth: 10,
                  barMaxWidth: 18,
                  markLine:{
                    symbol: ["none", "none"],
                    lineStyle: {
                        normal: {
                            width: 2,
                            color: "rgba(25,163,223,1)", // 线条颜色
                        },
                    },
                    itemStyle: {
                      normal: {
                        label: {
                          show: false,
                          position: "middle",
                        },
                      },
                    },
                    data: [],
                  }
                },{
                  name: "累计净流",
                  type: "bar",
                  barWidth: 10,
                  barMaxWidth: 18,
                  markLine:{
                    symbol: ["none", "none"],
                    lineStyle: {
                        normal: {
                            width: 2,
                            color: "rgba(25,163,223,1)", // 线条颜色
                        },
                    },
                    itemStyle: {
                      normal: {
                        label: {
                          show: false,
                          position: "middle",
                        },
                      },
                    },
                    data: [],
                  }
                }
              ],
            };
            let xData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let seriesData3 = [];
            if(this.mode == "1"){
              d.negativeProj.sort((a,b)=>{return a.totalNetFlow-b.totalNetFlow});
              for (let i = 0; i < d.negativeProj.length; i++) {
                xData.push(d.negativeProj[i].projName);
                seriesData1.push(d.negativeProj[i].totalNetFlow);
                seriesData2.push(d.negativeProj[i].projFutureNetFlow);
                seriesData3.push(d.negativeProj[i].monthAccNetFlow);
              }
            }else{
              d.positiveProj.sort((a,b)=>{return b.totalNetFlow-a.totalNetFlow});
               for (let i = 0; i < d.positiveProj.length; i++) {
                xData.push(d.positiveProj[i].projName);
                seriesData1.push(d.positiveProj[i].totalNetFlow);
                seriesData2.push(d.positiveProj[i].projFutureNetFlow);
                seriesData3.push(d.positiveProj[i].monthAccNetFlow);
              }
            }
            option.yAxis[0].data = xData;
            option.series[0].data = seriesData1;
            option.series[1].data = seriesData2;
            option.series[2].data = seriesData3;
            option.dataZoom[0].start = option.dataZoom[1].start = 100 - Math.min(100, (6 / option.series[0].data.length) * 100);
            this.forwardContributionChart = applyUpdate(chart, option);
        }))
      },
      updateNetFlowChart() {
        finance.queryProjDistributionDetail()
          .then(d => {
            this.tableData1 = d.negativeProj;
            this.tableData2 = d.positiveProj;
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            const option = {
              color:[
              '#f32c2c',
              '#f38e2c',
              '#2ce4ff',
              '#19b6fb',
              '#5dd5c3',
              '#ffed21',
              '#159172',
              '#fb9c5c'
            ],
              tooltip: {
                trigger: "axis",
                formatter: "{b}<br>{a0}: {c0}",
              },
              legend:{
                data:["总净流","剩余净流","累计净流"]
              },
              grid: {
                top: "15%",
                left: "3%",
                right: "8%",
                bottom: "20%",
                containLabel: true,
              },
              xAxis: [
                {
                  show: true,
                  name: "万元",
                  type: "value",
                  nameTextStyle: {
                    padding: [0, 40, 0, 0],
                    color: "#e6f2f3",
                    fontWeight: "bold",
                    align: "left",
                  },
                  axisLabel: {
                    color: "#e6f2f3",
                    fontWeight: "bold",
                  },
                  axisLine: { show: true, lineStyle: { color: "#36445f" } },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: "#36445f",
                      width: 1,
                      type: "solid",
                    },
                  },
                  axisTick: { show: false },
                },
              ],
              yAxis: [
                {
                  type: "category",
                  data: [],
                  axisLabel: {
                    color: "#e6f2f3",
                    show:true,
                    lineStyle: {
                        color: "#rgb(77,83,141)",
                        width:4
                    },
                    fontWeight: "normal",
                    formatter:val => truncateStr(val,10)
                  },
                },
              ],
              series: [
                {
                  name: "总净流",
                  type: "line",
                  barWidth: 10,
                  barMaxWidth: 18,
                  markLine:{
                    symbol: ["none", "none"],
                    lineStyle:{
                      normal: {
                            width: 2,
                            color: "rgb(255,128,0)", // 线条颜色
                        },
                    },
                    itemStyle: {
                      normal: {
                        label: {
                          show: false,
                          position: "middle",
                        },
                      },
                    },
                    data: [],
                  }
                },{
                  name: "剩余净流",
                  type: "bar",
                  barWidth: 10,
                  barMaxWidth: 18,
                  markLine:{
                    symbol: ["none", "none"],
                    lineStyle: {
                        normal: {
                            width: 2,
                            color: "rgba(25,163,223,1)", // 线条颜色
                        },
                    },
                    itemStyle: {
                      normal: {
                        label: {
                          show: false,
                          position: "middle",
                        },
                      },
                    },
                    data: [],
                  }
                },{
                  name: "累计净流",
                  type: "bar",
                  barWidth: 10,
                  barMaxWidth: 18,
                  markLine:{
                    symbol: ["none", "none"],
                    lineStyle: {
                        normal: {
                            width: 2,
                            color: "rgba(25,163,223,1)", // 线条颜色
                        },
                    },
                    itemStyle: {
                      normal: {
                        label: {
                          show: false,
                          position: "middle",
                        },
                      },
                    },
                    data: [],
                  }
                }
              ],
            };
            let xData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let seriesData3 = [];
            let len = d.negativeProj.length>=10?9:d.negativeProj.length;
            if(this.mode == "1"){
              d.negativeProj.sort((a,b)=>{return b.totalNetFlow-a.totalNetFlow});
              len = d.negativeProj.length>=10?9:d.negativeProj.length;
              for (let i = 0; i < len; i++) {
                xData.push(d.negativeProj[i].projName);
                seriesData1.push(d.negativeProj[i].totalNetFlow);
                seriesData2.push(d.negativeProj[i].projFutureNetFlow);
                seriesData3.push(d.negativeProj[i].monthAccNetFlow);
              }
            }else{
              d.positiveProj.sort((a,b)=>{return a.totalNetFlow-b.totalNetFlow});
              len = d.positiveProj.length>=10?9:d.positiveProj.length;
               for (let i = 0; i < len; i++) {
                xData.push(d.positiveProj[i].projName);
                seriesData1.push(d.positiveProj[i].totalNetFlow);
                seriesData2.push(d.positiveProj[i].projFutureNetFlow);
                seriesData3.push(d.positiveProj[i].monthAccNetFlow);
              }
            }
            option.yAxis[0].data = xData;
            option.series[0].data = seriesData1;
            option.series[1].data = seriesData2;
            option.series[2].data = seriesData3;
            this.projectContributionChart = applyUpdate(chart, option);
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
    },
  }
</script>

<style lang="less">
  #tabs{
    background: #0e1328;
  }
  .tabbar{
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  border: 1px solid #157EAB;
  width: 210px;
  margin: auto;
  cursor: pointer;
}
.tabbar div{
  flex: 1;
  text-align: center;
}
.tabar-select{
  background-color: #4191E5;
  font-weight: bold;
}
</style>
