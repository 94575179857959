<template>
  <div id="left">
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div class="info-block-title">安全帽充电率</div>
      <div style="height:18rem;padding-top:3rem">
        <div
          v-if="!safeChargeChart.series || !safeChargeChart.series[0].data.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="safeChargeChart"
          @click="safeChargeChartClick"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div class="info-block-title">安全行为之星</div>
      <div style="height:18rem;padding-top:3rem">
        <div
          v-if="!safeBefaiorChart.series || !safeBefaiorChart.series[0].data.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="safeBefaiorChart"
          @click="safeBefaiorChartClick"
          ref="troubleType"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div
        class="info-block-title"
        style="display: flex;align-items:center;"
      >
        <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode == 1 }"
            @click="() => changeChart(1)"
          >进场教育完成率</div>
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode == 2 }"
            @click="() => changeChart(2)"
          >培训考核合格率</div>
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode == 3 }"
            @click="() => changeChart(3)"
          >人均培训次数</div>
        </div>
      </div>
      <div style="height:19rem;padding-top:3rem">
        <!-- <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"><CustomSelect :options="companyNameList" @input="companySelect2" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold" /></div> -->
        <div
          v-if="!sevenDayAlarmsLineChart.series || !sevenDayAlarmsLineChart.series[0].data.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="sevenDayAlarmsLineChart"
          @click="sevenDayAlarmsLineChartClick"
          autoresize
        />
      </div>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="60rem"
      top="15rem"
    >
      <wrapper style="height:28rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle}}
            </div>

          </div>
          <div style="display:flex; align-items: center;margin-top:1rem;justify-content: space-between;">
            <div></div>
            <div>
            </div>

            <div style="font-size:12px;color:#fff;">充电率：%</div>
          </div>
          <!-- <div style="border:1px solid #112563;margin:1.5rem 1rem"> -->
          <div style="height:20rem;padding:1rem 0 0 1rem">
            <div style="height:100%;overflow-y:scroll">
              <div
                v-for="(item, index) in troubleTypePieDetailTable"
                :key="index"
                style="margin-bottom:1rem"
              >

                <div style="color: #A7B6BC;">
                  {{item.project_name}}
                </div>
                <div class="linebar">

                  <div
                    style="flex: 5"
                    class="line-bar"
                  >
                    <div
                      class="real-bar"
                      :style="{'width': (item.count / 100 * 100) + '%' }"
                    ></div>

                  </div>

                  <div style="color: #57C7B6;width:50px">
                    {{item.count}}%
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="60rem"
      top="15rem"
    >
      <wrapper style="height:28rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow2=false"
        />

        <div class="attendance">
          <div class="attendance-top">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle2}}
            </div>

          </div>
          <div style="display:flex; align-items: center;margin-top:1rem;justify-content: space-between;">
            <div></div>
            <div>
            </div>

            <div style="font-size:12px;color:#fff;">次数：次</div>
          </div>
          <!-- <div style="border:1px solid #112563;margin:1.5rem 1rem"> -->
          <div style="height:20rem;padding:1rem 0 0 1rem">
            <div style="height:100%;overflow-y:scroll">
              <div
                v-for="(item, index) in safeBefaiorDetailTable"
                :key="index"
                style="margin-bottom:1rem"
              >

                <div style="color: #A7B6BC;width: 180px;">
                  {{item.project_name}}
                </div>
                <div class="linebar">

                  <div
                    style="flex: 5"
                    class="line-bar"
                  >
                    <div
                      class="real-bar"
                      :style="{'width': (item.count / avgAttendanceMax * 100) + '%' }"
                    ></div>

                  </div>

                  <div style="color: #57C7B6;width:30px">
                    {{item.count}}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow3"
      width="60rem"
      top="15rem"
    >
      <wrapper style="height:28rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow3=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle3}}
            </div>
            <div
              class="tabbar"
              style="width:22rem"
            >
              <div 
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 1 }"
                @click="getAttendance(1)"
              >进场教育完成率</div>
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >培训考核合格率</div>
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 3 }"
                @click="getAttendance(3)"
              >人均培训次数</div>
            </div>
            <div style="width:10rem"></div>
          </div>
          <div style="display:flex; align-items: center;margin-top:1rem;justify-content: space-between;">
            <div></div>
            <div>

            </div>
            <div style="font-size:12px;color:#fff;">{{popupSelect == 1?'完成率：%':(popupSelect == 2)?'合格率：%':'次数：次'}}</div>
          </div>
          <!-- <div style="border:1px solid #112563;margin:1.5rem 1rem"> -->
          <div style="height:20rem;padding:1rem 0 0 1rem">
            <div style="height:100%;overflow-y:scroll">
              <div
                v-for="(item, index) in sevenDayAlarmsTable"
                :key="index"
                style="margin-bottom:1rem"
              >

                <div style="color: #A7B6BC;">
                  {{item.project_name}}
                </div>
                <div class="linebar">

                  <div
                    style="flex: 5"
                    class="line-bar"
                  >
                    <div
                    v-if="popupSelect == 3"
                      class="real-bar"
                      :style="{'width': (item.count / avgAttendanceMax * 100) + '%' }"
                    ></div>
                    <div
                    v-else
                      class="real-bar"
                      :style="{'width': (item.count / 100*100) + '%' }"
                    ></div>

                  </div>

                  <div style="color: #57C7B6;width:40px">
                    {{popupSelect == 3?item.count:item.count+'%'}}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation } from "../../api";
// import CustomSelect from '../../components/select'
// import { attendancePieChartTemplate, colors } from './pieChartTemplates';
// import { singleLineChartTemplate } from './lineChartTemplates';
import { SimpleBarChartTemplate } from "./barChartTemplates";
import { applyUpdate, truncateStr } from "../../utils";
import wrapper from "../../components/wrap";

export default {
  name: "SecurityPersonnelLeftBar",
  components: {
    "v-chart": ECharts,
    wrapper,
    // 'CustomSelect': CustomSelect,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    orgType: Number,
  },
  data() {
    return {
      companyNameList: ["全部分公司"],
      companyIdList: [""],
      levelOneTroublePoints: [],
      safeChargeChart: {},
      option: {
        color: ["#1bd080", "#1bd080"],
        grid: {
          containLabel: true,
          right: "6%",
          bottom: "1%",
          top: "13%",
          left: "13%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0}: {c0}",
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              rotate: 40,
              formatter: (value) => truncateStr(value, 10),
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            show: true,
            name: "充电率：%",
            max: 100,
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        series: [
          {
            name: "充电率",
            type: "bar",
            barWidth: 10,
            data: [],
          },
        ],
      },
      safeBefaiorChart: {},
      option2: {
        color: ["#1bd080", "#1bd080"],
        grid: {
          contentLabel: true,
          right: "6%",
          bottom: "30%",
          top: "13%",
          left: "13%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0}: {c0}",
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              rotate: 40,
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            show: true,
            name: "人次：次",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        series: [
          {
            name: "人次",
            type: "bar",
            barWidth: 10,
            data: [],
          },
        ],
      },
      mode: 1,
      sevenDayAlarmsLineChart: {},
      option3: {
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0}: {c0}",
        },
        color: ["#5DD5C3", "#1bd080"],
        grid: {
          contentLabel: true,
          left: "12%",
          right: "6%",
          bottom: " 15%",
          top: "13%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              rotate: 35,
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
            show: true,
          },
          {
            min: 0,
            max: 100,
            interval: 25,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              formatter: function (value) {
                return value ? value + "%" : value;
              },
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
            show: false,
          },
        ],
        series: [
          {
            name: "数量",
            type: "bar",
            barWidth: 10,
            data: [],
            markLine: {
              symbol: ["none", "none"], //去掉箭头
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "dashed",
                    color: "#238778",
                  },
                  label: {
                    show: false,
                    position: "middle",
                  },
                },
              },
              data: [
                {
                  yAxis: -1, //这里设置false是隐藏不了的，可以设置为-1
                },
              ],
            },
          },
          {
            name: "数量",
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                },
              },
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(27, 208, 128, 0.3)" },
                { offset: 1, color: "rgba(27, 208, 128, 0)" },
              ]),
            },
            symbolSize: 0,
            yAxisIndex: 1,
            data: [],
          },
        ],
      },

      // districtScale: 0,
      popupShow: false,
      popupTitle: "",
      troubleTypePieDetailTable: [],
      avgAttendanceMax: 0,
      search_keys:[],

      popupShow2: false,
      popupTitle2: "",
      safeBefaiorDetailTable: [],
      search_keys2:[],

      popupShow3: false,
      popupSelect: 1,
      popupTitle3: "",
      sevenDayAlarmsTable: [],
      search_keys3:[],
      activeIndex3:0
    };
  },
  watch: {
    orgLevel: function (newValue) {
      if (newValue) {
        this.getAll();
      }
    },
    districtLevel: function (newValue) {
      if (newValue) {
        if (newValue == "100000") {
          this.districtScale = 0;
        }
        this.getAll();
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.districtScale = newValue;
      }
    },
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;

    this.getAll();

    // nation.getCompanyList()
    //   .then(d => {
    //     this.companyNameList = ['全部分公司'].concat(d.map(item => item.name));
    //     this.companyIdList = [''].concat(d.map(item => item.innerid));
    //   })
    //   // eslint-disable-next-line
    //   .catch(e => console.error(e.msg || e.message))
  },
  methods: {
    safeChargeChartClick(params) {
      if (this.districtScale !== 2) {
        this.popupShow = true;
        this.popupTitle = params.name + "安全帽充电率";
        nation.getSafetyHatChargingRateCollect(1, 100,this.districtScale==0&&this.orgType == 1?this.search_keys[params.dataIndex]:'',this.districtScale==1||this.orgType !== 1?this.search_keys[params.dataIndex]:'').then((d) => {
          // console.log(d);
          this.troubleTypePieDetailTable = d;
          
        });
      }
    },
    safeBefaiorChartClick(params) {
      if (this.districtScale !== 2) {
        this.popupShow2 = true;
        this.popupTitle2 = params.name + "安全行为之星次数";
        nation.getSafetySafeBehaviorStarCollect(1, 100,this.districtScale==0&&this.orgType == 1?this.search_keys2[params.dataIndex]:'',this.districtScale==1||this.orgType !== 1?this.search_keys2[params.dataIndex]:'').then((d) => {
          // console.log(d);
          this.safeBefaiorDetailTable = d;
          this.avgAttendanceMax = Math.max.apply(
            null,
            this.safeBefaiorDetailTable.map((item) => item.count)
          );
        });
      }
    },
    sevenDayAlarmsLineChartClick(params) {
      if (this.districtScale !== 2) {
        this.popupSelect = this.mode;
        this.popupShow3 = true;
        this.popupTitle3 = params.name + "教育情况";
        this.activeIndex3=params.dataIndex;
        this.getPop3Data();
      }
    },

    getAttendance(popupSelect) {
      this.popupSelect = popupSelect;
      this.getPop3Data();
    },
    getPop3Data() {
      nation.getWorkerTrainDetailCollect(this.popupSelect,1, 100,this.districtScale==0&&this.orgType == 1?this.search_keys3[this.activeIndex3]:'',this.districtScale==1||this.orgType !== 1?this.search_keys3[this.activeIndex3]:'').then((d) => {
        // console.log(d,'---------');
        this.sevenDayAlarmsTable = d;
        this.avgAttendanceMax = Math.max.apply(
          null,
          this.sevenDayAlarmsTable.map((item) => item.count)
        );
      });
    },
    getAll() {
      nation.getSafetyOrgCityHatChargingRateCollect().then((d) => {
        // console.log(d);
        this.search_keys=d.search_keys;
        const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
        this.option.xAxis[0].data = d.xAxis_data;
        this.option.series[0].data = d.series_data;
        if (this.districtScale == 2) {
          this.option.grid.left = "15%";
        } else {
          this.option.grid.left = "13%";
        }
        this.safeChargeChart = applyUpdate(chart, this.option);
      });

      nation
        .getSafetyOrgCitySafeBehaviorStarCount()
        .then((d) => {
          // console.log(d);
          this.search_keys2=d.search_keys;
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option2.xAxis[0].data = d.xAxis_data;
          this.option2.series[0].data = d.series_data;
          this.safeBefaiorChart = applyUpdate(chart, this.option2);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));

      this.changeChart(this.mode);
    },
    updateLevelOneTroublePoints(orgID) {
      nation
        .getTop10LevelOneTroublePoints(orgID)
        .then((d) => {
          this.levelOneTroublePoints = d;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    // updateSevenDayAlarmsLineChart(orgID) {
    //   nation.get7DayAlarms(orgID)
    //     .then(d => {
    //       const chart = JSON.parse(JSON.stringify(singleLineChartTemplate));
    //       chart.color = ['#e32a2c', '#e0842d', '#28cce8', '#17a6e8'];
    //       const rgb_colors = [
    //         ['227', '42', '44'],
    //         ['224', '132', '45'],
    //         ['40', '204', '232'],
    //         ['23', '166', '232']
    //       ];
    //       chart.legend.top = 0;
    //       chart.grid.top = '14%';
    //       chart.grid.left = '5%';
    //       chart.xAxis.data = d.xAxis_data;
    //       chart.yAxis[0].minInterval = 1;
    //       chart.yAxis[0].name = '数量';
    //       chart.yAxis[0].nameTextStyle = {
    //         padding: [0, 40, 0, 0],
    //         color: '#e6f2f3',
    //         fontWeight: 'bold',
    //       };
    //       chart.series[0] = Object.assign({}, chart.series[0], d.series[0], {
    //         areaStyle: {
    //           color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
    //             {offset: 0, color: 'rgba(' + rgb_colors[0].join(', ') + ', 0.5)'},
    //             {offset: 1, color: 'rgba(' + rgb_colors[0].join(', ') + ', 0)'}
    //           ])
    //         }
    //       });
    //       for (let i = 1; i < d.series.length; i++) {
    //         chart.series.push(Object.assign({}, chart.series[0], d.series[i], {
    //           areaStyle: {
    //             color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
    //               {offset: 0, color: 'rgba(' + rgb_colors[i % rgb_colors.length].join(', ') + ', 0.5)'},
    //               {offset: 1, color: 'rgba(' + rgb_colors[i % rgb_colors.length].join(', ') + ', 0)'}
    //             ])
    //           }
    //         }));
    //       }
    //       this.sevenDayAlarmsLineChart = chart;
    //     })
    //     // eslint-disable-next-line
    //     .catch(e => console.error(e.msg || e.message))
    // },
    companySelect(option) {
      const idx = this.companyNameList.indexOf(option);
      if (idx >= 0) {
        this.updateLevelOneTroublePoints(this.companyIdList[idx]);
      }
    },
    // companySelect2(option) {
    //   const idx = this.companyNameList.indexOf(option);
    //   if (idx >= 0) {
    //     this.updateSevenDayAlarmsLineChart(this.companyIdList[idx]);
    //   }
    // },
    changeChart(mode) {
      this.mode = mode;
      nation.getSafetyWorkerTrainCollect(mode).then((d) => {
        // console.log(d);
        this.search_keys3=d.search_keys;
        const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
        this.option3.xAxis[0].data = d.xAxis_data;
        this.option3.series[0].data = d.series;
        // eslint-disable-next-line no-empty
        if (this.mode === 1) {
          this.option3.series[0].name = "完成率";
          this.option3.yAxis[0].name = "完成率：%";
          this.option3.yAxis[0].max = 100;
          // eslint-disable-next-line no-empty
        } else if (this.mode === 2) {
          this.option3.series[0].name = "合格率";
          this.option3.yAxis[0].name = "合格率：%";
          this.option3.yAxis[0].max = 100;
        } else {
          // eslint-disable-next-line no-empty
          this.option3.series[0].name = "次数";
          this.option3.yAxis[0].name = "次数：次";
          this.option3.yAxis[0].max = undefined;
        }
        // eslint-disable-next-line no-empty
        if (d.config.theta) {
          this.option3.series[0].markLine.data[0].yAxis = d.config.theta;
        }
        this.sevenDayAlarmsLineChart = applyUpdate(chart, this.option3);
      });
    },
  },
};
</script>

<style scoped lang="less">
.linebar {
  width: 100%;
}
.line-bar {
  display: flex;
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #57c7b6;
  border-radius: 12px;
}
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>