<template>
  <div id="right">
    <InstrumentCopeChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel"/>
    <FilialeLegalInstrumentChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel"/>
    <CaseTypeChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel"/>
  </div>
</template>

<script>
  import  InstrumentCopeChart  from "./legalInstrumentCopeChart";
  import  FilialeLegalInstrumentChart  from "./filialeLegalInstrumentChart";
  import  CaseTypeChart  from "./caseTypeChart";
  export default {
    name: 'LawRespondRightBar',
    components: {
      InstrumentCopeChart,
      FilialeLegalInstrumentChart,
      CaseTypeChart
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
  }
</script>

<style lang="less">
</style>
