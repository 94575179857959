import * as THREE from 'three';
//import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { GLTFLoader } from './GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { MeshoptDecoder } from './meshopt_decoder.module.js';

// 支持的options:
// 1. options.rotZ: 模型围绕Z轴逆时针旋转的角度
// 2. options.scale: 模型缩放比例
// 3. options.moveX/moveY/moveZ: 模型在三个轴上的移动距离
export function loadModel(scene, modelPath, options, callback) {
    const objs = [];
    const loader = new GLTFLoader();
    if (options.subtype == 'draco') {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('./draco/');
        loader.setDRACOLoader(dracoLoader);
    } else if (options.subtype == 'meshopt') {
        loader.setMeshoptDecoder(MeshoptDecoder);
    }
    loader.load(modelPath, gltf => {
        // model is a THREE.Group (THREE.Object3D)
        /*
        const mixer = new THREE.AnimationMixer(gltf.scene);
        // animations is a list of THREE.AnimationClip
        for (const anim of gltf.animations) {
            mixer.clipAction(anim).play();
        }
        */

        options = options || {};

        // settings in `sceneList` "Monster"
        gltf.scene.scale.set(options.scale || 1, options.scale || 1, options.scale || 1);
        gltf.scene.rotation.copy(new THREE.Euler((options.rotX || 0) / 180 * Math.PI, (options.rotY || 0) / 180 * Math.PI, (options.rotZ || 0) / 180 * Math.PI));
        gltf.scene.position.set(options.moveX || 0, options.moveY || 0, options.moveZ || 0);

        scene.add(gltf.scene);
        objs.push({ gltf/*, mixer*/ });

        callback && callback();
    });
}
