<template>
  <div id="right">
    <ProjectFundDataChart v-if="!isHead" :district-level="districtLevel" :org-level="orgLevel" />
    <CompanyCurReceiptChart v-if="isHead" :district-level="districtLevel" :org-level="orgLevel" />
    <ProjectFundStatusChart v-if="!isHead"  :district-level="districtLevel" :org-level="orgLevel" />
    <CompanyCurNetFlowChart v-if="isHead"  :district-level="districtLevel" :org-level="orgLevel" />
    <ProjectNetFlowContributionChart v-if="!isHead" :district-level="districtLevel" :org-level="orgLevel" />
    <OverdueRecycleChart v-if="isHead" :district-level="districtLevel" :org-level="orgLevel" />
  </div>
</template>

<script>
  import ProjectFundDataChart from "./projectFundDataChart";
  import ProjectFundStatusChart from "./projectFundStatusChart";
  import ProjectNetFlowContributionChart from "./projectNetFlowContributionChart";
  import CompanyCurReceiptChart from "./companyCurReceiptChart";
  import CompanyCurNetFlowChart from "./companyCurNetFlowChart";
  import OverdueRecycleChart from "./overdueRecycleChart";

  export default {
    name: 'QualityIndexRightBar',
    components: {
      ProjectFundDataChart,
      ProjectFundStatusChart,
      ProjectNetFlowContributionChart,
      CompanyCurReceiptChart,
      CompanyCurNetFlowChart,
      OverdueRecycleChart
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    data(){
      return{
        isHead:true
      }
    },
    mounted(){
      if(window.$orgName == "中建一局集团第一建筑有限公司"){
          this.isHead= true;
        }else{
          this.isHead= false;
      }
    },
    watch:{
      orgLevel:function(){
        if(window.$orgName == "中建一局集团第一建筑有限公司"){
          this.isHead= true;
        }else{
          this.isHead= false;
        }
      }
    }
  }
</script>

<style lang="less">
</style>
