<template>
  <div id="left">
    <RespondCaseChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <RespondCaseLoadSumChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <RespondCaseRankChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
  </div>
</template>

<script>
  import RespondCaseChart from "./respondingCaseViewChart";
  import RespondCaseLoadSumChart from "./respondCaseLoadSumChart";
  import RespondCaseRankChart from "./respondCaseRankChart";

  export default {
    name: 'LawRespondLeftBar',
    components: {
      RespondCaseChart,
      RespondCaseLoadSumChart,
      RespondCaseRankChart
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
  }
</script>

<style lang="less">
</style>
