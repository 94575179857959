<template>
  <div class="info-block" style="min-height:5rem">
    <div class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
      <div>人员成本</div>
       <div
      style="display:flex;justify-content:flex-end"
    >
      <CustomSelect
          :options="yearList"
          :value="selectedYear"
          @input="categorySelect"
          style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
        />
    </div>
    </div>
    <div style="height:20rem;padding-top:3rem">
      <div
        v-if="EmployeeCostChart.series && [].concat.apply([], EmployeeCostChart.series.map(x => x.data)).length == 0"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart v-else :options="EmployeeCostChart" autoresize />
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { human } from '../../../../api_hr'
  // import { stackedBarChartTemplate } from "../../barChartTemplates";
  // import { applyUpdate } from "../../../../utils";
 import CustomSelect from '../../../../components/select'

  export default {
    name: 'employeeCostChart',
    components: {
      'v-chart': ECharts,
      CustomSelect
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateEmployeeCostChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateEmployeeCostChart();
        }
      }
    },
    data () {
      return {
        EmployeeCostChart: {},
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear(),
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.updateEmployeeCostChart();
    },
    methods: {
      updateEmployeeCostChart() {
        human.getEmployeeCost(this.selectedYear).then(res=>{
          let xStr = [];
          for (let i = 1; i <= 12; i++) {
            xStr.push(i+"月");
          }
          let option = {
            color:[
              '#f32c2c',
              '#f38e2c',
              '#2ce4ff',
              '#19b6fb',
              '#5dd5c3',
              '#ffed21',
              '#159172',
              '#fb9c5c'
            ],
            tooltip: {
              trigger: 'axis'
              },
              legend: {
                  data: ["工资总额", '其它人工成本'],
                  textStyle: {
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '12'
                },
              },
              grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
              },
              xAxis: {
                  name:'月',
                  type: 'category',
                  boundaryGap: false,
                  data:xStr,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisLabel:{
                    interval:0,
                    rotate:40,
                    color:'white'
                  }
              },
              yAxis: {
                  name:'金额(万元)',
                  nameTextStyle:{
                    color:"white",
                  },
                  axisLine: {
                    show: false,
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      type: [5, 10],
                      color: '#14253f',
                      width: 2,
                    }
                  },
                  type: 'value',
                  axisLabel: {
                     color: 'white'
                  }
              },
              series: [
                  {
                      name: res[0].name,
                      type: 'bar',
                      data: res[0].data,
                      areaStyle:{}
                  },{
                      name: res[1].name,
                      type: 'bar',
                      data: res[1].data,
                      areaStyle:{}
                  }
                ]
          }
          this.EmployeeCostChart = option;
        })
      },
    },
  }
</script>

<style lang="less">
</style>
