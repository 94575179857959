<template>
  <div id="right">
    <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
      <div class="info-block-title" style="display: flex;justify-content: space-between;align-items:center;width: 90%;margin-top: -5px;">
        <div>人员安全</div>
        <div style="display:flex;justify-content:flex-end;">
          <CustomSelect :options="companyNameList" :value="selectedCompany" @input="companySelect" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;" />
        </div>
      </div>
      <div style="height:18rem;padding-top:3rem">
        <div
          v-if="!companyTroublePieChart.series || !companyTroublePieChart.series.length || !companyTroublePieChart.series[0].data.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyTroublePieChart" autoresize />
      </div>
    </div>
    <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
      <div class="info-block-title" style="display: flex;align-items:center;width: 85%;justify-content: space-between;">
        <div>安全巡检率</div>
        <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode == 1 }"
            @click="() => getChart(1)"
          >分公司</div>
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode == 2 }"
            @click="() => getChart(2)"
          >项目</div>
        </div>
      </div>
      <div style="height:18rem;padding-top:3rem">
        <div
          v-if="todayNewTroubleBarChart.series && [].concat.apply([], todayNewTroubleBarChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="todayNewTroubleBarChart" @click="companyTroublePieChartClick" autoresize />
      </div>
    </div>
    <div class="info-block" style="min-height:5rem">
      <div class="info-block-title" style="display: flex;align-items:center;width: 85%;justify-content: space-between;">
          <div>各{{(districtScale == 0 && orgType == 1) ? '分公司' : (districtScale == 2 ? '项目' : '城市')}}隐患数</div>
          <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              style="min-width: 3rem;"
              :class="{ 'option-block-selected2': mode2 == 1 }"
              @click="() => getTrouble(1)"
            >总</div>
            <div
              class="option-block2"
              style="min-width: 3rem;"
              :class="{ 'option-block-selected2': mode2 == 2 }"
              @click="() => getTrouble(2)"
            >新增</div>
            <div
              class="option-block2"
              style="min-width: 3rem;"
              :class="{ 'option-block-selected2': mode2 == 3 }"
              @click="() => getTrouble(3)"
            >未整改</div>
          </div>
        </div>

      <div style="height:18rem;padding-top:3rem;" v-if="districtScale != 2">
        <div
          v-if="troubleTypePieChart.series && [].concat.apply([], troubleTypePieChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <div v-else style="width:100%;height:100%">
           <v-chart :options="troubleTypePieChart" ref="troubleType" @click="troubleTypePieChartClick" @mouseover="troubleTypeMouseOver" @mouseout="troubleTypeMouseOut" autoresize />
          <div style="position:absolute;top:3rem;left:1rem;font-size:0.8rem">隐患数：个</div>
        </div>
      </div>
      <div style="padding-top:3rem" v-else>

        <div
          v-if="!projectListInfo.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <div v-else style="max-height:20rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;padding-left: 1rem">
          <div
            v-for="project in projectListInfo"
            :key="project.name"
            style="height:1.6rem;display:flex;justify-content:space-between"
          >
            <div style="width: 300px" v-if="project.name.length > 20" :title="project.name">{{project.name.slice(0, 20)}}...</div>
            <div v-else>{{project.name}}</div>
            <div>{{project.value}}个({{project.rate}}%)</div>
          </div>
        </div>
      </div>
    </div>


    <el-dialog
      custom-class="stats-layer"
      :visible.sync="companyTroublePieChartDetailShow"
      width="60rem"
      top="18rem"
    >
      <wrapper style="height:26rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="companyTroublePieChartDetailShow = false"
        />
        <div class="attendance">

          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="font-size:1rem;font-weight:600;margin:1rem 0 0 1rem">{{companyTroublePieDetailTitle}}</div>
            <div style="height:19rem;padding-top:1rem">
              <v-chart :options="companyTroublePieDetailChart" autoresize />
            </div>
          </div>

        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow5"
      width="90rem"
      top="12rem"
    >
      <wrapper style="height:42rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow5=false"
        />
        <div class="attendance">

          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="font-size:1rem;font-weight:600;margin:1rem 0 0 2rem">{{companyTroublePieDetailTitle}}</div>
            <div style="height:15rem;padding-top:1rem">
              <v-chart :options="workerAgePieDetailChart" @click="workerAgePieDetailChartClick" autoresize />
            </div>
          </div>
          <div style="margin:0.5rem 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupShow5BottomName}}</div>
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:3rem;text-align:center">序号</div>
              <div style="width:20rem;">项目名称</div>
              <div style="width:5rem;text-align:center">巡检率</div>
            </div>
            <div style="height:14rem;overflow:auto;">
              <div
                v-for="(item, index) in popupShowData5"
                :key="item.person_id"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;cursor: pointer;"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:3rem;text-align:center">{{index>8?index+1:'0'+(index+1)}}</div>
                <div style="width:20rem;"> {{item.project_name}} </div>
                <div style="width:5rem;text-align:center"> {{item.rate}} </div>
              </div>
              <div
                v-if="!(popupShowData5 && popupShowData5.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>

          </div>
        </div>
      </wrapper>
    </el-dialog>


    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="60rem"
      top="15rem"
    >
      <wrapper style="height:28rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle}}
            </div>
            <div class="tabbar">
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 1 }"
                @click="getAttendance(1)"
              >总</div>
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >新增</div>
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 3 }"
                @click="getAttendance(3)"
              >未整改</div>
            </div>
            <div style="width:10rem"></div>
          </div>
          <div style="display:flex; align-items: center;margin-top:1rem;justify-content: space-between;">
            <div></div>
            <div
              class="tooltips"
              v-if="popupSelect==3"
            >

              <div class="items">
                <div class="tooltip-item tooltip-item1"></div>正常未整改
              </div>
              <div class="items">
                <div class="tooltip-item tooltip-item2"></div>未整改预警
              </div>
              <div class="items">
                <div class="tooltip-item tooltip-item3"></div>逾期未整改
              </div>
            </div>
            <div style="font-size:12px;color:#fff;">隐患数：个</div>
          </div>
          <!-- <div style="border:1px solid #112563;margin:1.5rem 1rem"> -->
          <div style="height:20rem;padding:1rem 0 0 1rem">
            <div style="height:100%;overflow-y:scroll">
              <div
                v-for="(item, index) in troubleTypePieDetailTable"
                :key="index"
              >

                <div style="color: #A7B6BC;">
                  {{item.project_name}}
                </div>
                <div class="linebar">
                  <div
                    style="flex: 5"
                    class="line-bar"
                    v-if="popupSelect==3"
                  >
                    <div
                      class="real-bar"
                      :style="{'width': (item.value1 / avgAttendanceMax * 100) + '%' }"
                      style="background-color: #23cf9e;"
                    ></div>
                    <div
                      class="real-bar"
                      :style="{'width': (item.value2 / avgAttendanceMax * 100) + '%' }"
                      style="background-color: #eab91e;"
                    ></div>
                    <div
                      class="real-bar"
                      :style="{'width': (item.value3 / avgAttendanceMax * 100) + '%' }"
                      style="background-color: #d02c36;"
                    ></div>
                  </div>
                  <div
                    style="flex: 5"
                    class="line-bar"
                    v-else
                  >
                    <div
                      class="real-bar"
                      :style="{'width': (item.count / avgAttendanceMax * 100) + '%' }"
                    ></div>

                  </div>

                  <div style="color: #57C7B6;width:40px">
                    {{item.count}}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow6"
      width="90rem"
      top="18rem"
    >
      <wrapper style="height:22rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow6=false"
        />
        <div class="attendance">

          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="font-size:1rem;font-weight:600;margin:1rem 0 0 2rem">{{companyTroublePieDetailTitle}}</div>
            <div style="height:15rem;padding-top:1rem">
              <v-chart :options="workerAgePieDetailChart" @click="workerAgePieDetailChartClick" autoresize />
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { nation } from '../../api'
  import { attendancePieChartTemplate, colors } from './pieChartTemplates';
  import { SimpleBarChartTemplate } from './barChartTemplates';
  import { singleLineScrollChartTemplate } from './lineChartTemplates';
  import { applyUpdate, truncateStr } from "../../utils";
  import CustomSelect from '../../components/select'
  import wrapper from '../../components/wrap';

  export default {
    name: 'SecurityRightBar',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
       wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
      orgType: Number,
    },
    data () {
      return {
        popupShow6:false,
        companyNameList: [''],
        companyIdList: [''],
        selectedCompany: '',
        companyTroublePieChart: {},
        option: {
          color: [
            '#f32c2c',
            '#f38e2c',
            '#2ce4ff',
            '#19b6fb',
            '#5dd5c3',
            '#ffed21',
            '#159172',
            '#fb9c5c'
          ],
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            type: 'scroll', // 添加这一行代码即可实现图例分页功能
            orient: 'horizontal', // 'vertical'
            width: '70%',
            left: '30%',
            x: 'center',
            icon: 'circle',
            itemWidth: 14,
            itemHeight: 14,
            itemGap: 12,
            textStyle: {
              color: '#ccc',
              fontWeight: 'bold',
              fontSize: '11'
            },
            pageIconColor: '#fff',
            // padding: [8, 0, 0, 60],
            // top: '4%',
            // right: '3%',
            data: []
          },
          grid: {
            left: '7%',
            right: '4%',
            bottom: '1%',
            top: '19%',
            containLabel: true
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                 rotate: 40
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              name: '数量:次',
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                padding: [0 , 5, 0, 0]
              },
              type: 'value',
              show: true,
              axisLine: {
                show: false,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: [5, 10],
                  color: '#14253f',
                  width: 2,
                }
              },
              axisTick: { show: false },
              axisLabel: {
                color: '#e6f2f3',
                fontSize: 14,
                fontWeight: 'bold',
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // }
              }
            }
          ],
          series: [
            // {
            //   name: 'Email',
            //   type: 'line',
            //   areaStyle: {},
            //   data: [120, 132, 101, 134, 90, 230, 210]
            // }
          ]
        },
        org_id: '',
        companyTroublePieChartHighlightIndex: 0,
        todayNewTroubleBarChart: {},
        mode: 1,
        option2: {
          tooltip: {
            trigger: 'axis',
            formatter: "{b}<br>{a0}: {c0}"
          },
          color: ['#4191E5', '#1bd080'],
          grid: {
            contentLabel: true,
            left: '12%',
            right: '6%',
            bottom:"11%",
            top: "13%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              max: 100,
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true,
              name: '巡检率:%'
            }
          ],
          series: [
            {
              name: '巡检率',
              type: 'bar',
              barWidth: 10,
              data: [],
              markLine: {
                symbol: ["none", "none"], //去掉箭头
                itemStyle: {
                  normal: {
                    lineStyle: {
                      type: "dashed",
                      color: "#238778",
                    },
                    label: {
                      show: false,
                      position: "middle",
                    },
                  },
                },
                data: [
                  {
                    yAxis: -1, //这里设置false是隐藏不了的，可以设置为-1
                  },
                ],
              }
            }
          ]
        },
        noActionTroubleBarChart: {},
        troubleTypePieChart: {},
        mode2: 1,
        troubleTypePieChartHighlightIndex: 0,
        // districtScale: 0,
        projectListInfo: [],


        companyTroublePieChartDetailShow:false,
        companyTroublePieDetailTitle:'',
        companyTroublePieDetailChart:{},
        companyTroublePieSearchkeys: [],
        activeSearchIndex:0,
        option4: {
          tooltip: {
            trigger: 'axis',
            formatter: "{b}<br>{a0}: {c0}"
          },
          color: ['#4191E5', '#1bd080'],
          grid: {
            contentLabel: true,
            left: '5%',
            right: '6%',
            bottom:"18%",
            top: "13%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35,
                formatter: function(value) {
                  return value.length >5 ? value.slice(0, 5) + '...' : value;
                },
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              max: 100,
              nameTextStyle: {
                // padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true,
              name: '巡检率：%'
            }
          ],
          series: [
            {
              name: '巡检率',
              type: 'bar',
              barWidth: 10,
              data: [],
              markLine: {
                symbol: ["none", "none"], //去掉箭头
                itemStyle: {
                  normal: {
                    lineStyle: {
                      type: "dashed",
                      color: "#238778",
                    },
                    label: {
                      show: false,
                      position: "middle",
                    },
                  },
                },
                data: [
                  {
                    yAxis: -1, //这里设置false是隐藏不了的，可以设置为-1
                  },
                ],
              }
            }
          ]
        },

      popupShow5: false,
      workerAgePieDetailChart:{},
      workerAgePieDetailChartTemplate:{
          color: ["#23CF9E", "#14B0BB"],
          grid: {
            contentLabel: true,
            right: '5%',
            top: "13%",
            left: "5%",
            bottom: "25%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          tooltip: {
            trigger: 'axis',
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35,

                formatter: function(value) {
                  return value.length >5 ? value.slice(0, 5) + '...' : value;
                },
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              name: "巡检率：%",
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              max:100,
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true
            },

          ],
          series: [
            {
              name: '巡检率',
              type: 'bar',
              barWidth: 10,
              data: []
            },
          ]
        },
      popupShow5BottomName: '',
      popupSearchkeys5: [],
      popupXAxisData5: [],
      popupBarData5: [],
      popupShowData5: [],
      dataIndex5: -1,

      avgAttendanceMax:0,
      popupShow: false,
      popupSelect: 1,
      popupTitle: "",
      troubleTypePieDetailSearchKey: [],
      troubleTypePieDetailTable: [],
      sevenDayAlarmsLineChart: {},
      checkTypePieChartHighlightIndex: 0,

      }
    },
    watch: {
      orgOptions(newValue, oldValue) {
        if (newValue != oldValue) {
          this.companyNameList = newValue.map(item => truncateStr(item.org_name, 10));
          this.companyIdList = newValue.map(item => item.org_id);

          let idx = this.companyIdList.indexOf(this.orgLevel);
          if (idx == -1) {
            idx = 0;
          }

          this.org_id = this.orgLevel;
          this.companySelect(this.companyNameList[idx])

        }
      },
      orgLevel: function (newValue, oldValue) {
        if (newValue != oldValue) {
          const idx = this.companyIdList.indexOf(newValue);
          if (idx >= 0) {
            this.companySelect(this.companyNameList[idx]);
          }
          this.org_id = this.orgLevel;

          this.getAll();
        }
      },
      districtLevel: function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (newValue == "100000") {
            this.districtScale = 0;
          }

          this.getAll();
        }
      },
      districtScale: function (newValue) {
        if (newValue) {
          this.districtScale = newValue;
          if (newValue == 2) {
            this.getAll();
          }
        }
      },
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.companyNameList = this.orgOptions.map(item => truncateStr(item.org_name, 10));
      this.companyIdList = this.orgOptions.map(item => item.org_id);

      let idx = this.companyIdList.indexOf(this.orgLevel);
      if (idx == -1) {
        idx = 0;
      }

      this.org_id = this.orgLevel;
      this.selectedCompany = this.companyNameList[idx];

      this.getAll();
    },
    methods: {
      getAll() {
        this.getCallWarningInfo();
        this.getChart(this.mode);
        this.getTrouble(this.mode2);

      },
      getChart(mode){
        this.mode = mode;
        nation.getSafetyMonth12InspectionCollect(mode)
          .then(d => {
            // console.log(d);
            this.companyTroublePieSearchkeys=d.search_keys;
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option2.xAxis[0].data = d.xAxis_data;
            this.option2.series[0].data = d.series;
            if (d.config.theta) {
              this.option2.series[0].markLine.data[0].yAxis = d.config.theta;
            }
            this.todayNewTroubleBarChart = applyUpdate(chart, this.option2);
          })
      },
      companySelect(option) {
        this.selectedCompany = option;
        const idx = this.companyNameList.indexOf(option);
        if (idx >= 0) {
          this.org_id = this.companyIdList[idx];
          this.getCallWarningInfo();
        }
      },
      getCallWarningInfo(){
        nation.getSafetyMonth12WarningAlarmCount(0, this.org_id ? this.org_id:'')
          .then(d => {
            // console.log(d);
            const chart = JSON.parse(JSON.stringify(singleLineScrollChartTemplate));
            this.option.legend.data = d.legend_data;
            this.option.xAxis[0].data = d.xAxis_data;
            this.option.series = [];
            d.series.forEach(v => {
              this.option.series.push({
                name: v.name,
                type: 'line',
                areaStyle: {},
                data: v.data
              })
            })
            this.companyTroublePieChart = applyUpdate(chart, this.option);
          })
      },
      getTrouble(mode){
        this.mode2 = mode;
        nation.getSafetyOrgCityTroubleCollect(mode)
          .then(data => {
            // console.log(data);
            this.troubleTypePieDetailSearchKey = data.search_keys;
            let d = data.data;
            let chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
            chart.series[0].label.normal.formatter = attendancePieChartTemplate.series[0].label.normal.formatter;
            chart.color = colors;

            const maxPieces = 14;
            if (d.length > maxPieces) {
              d[maxPieces - 1].value = d.slice(maxPieces - 1).reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
              d[maxPieces - 1].name = '其它';
              d = d.slice(0, maxPieces);
            }
            chart.series[0].data = d.map(x => ({
              name: x.name,
              value: x.value
            }));
            let sum = 0;
            d.forEach(v => {
              sum += v.value;
            })
            this.projectListInfo =  d.map(x => ({
              name: x.name,
              value: x.value,
              rate: ((Number(x.value)/sum) * 100).toFixed(0)
            }));
            if (window.innerWidth < 2250) {
              chart.series[0].radius = [35, 65];
            }

            if (this.troubleTypePieChartHighlightIndex != null) {
              this.$refs.troubleType && this.$refs.troubleType.dispatchAction({
                type: 'downplay',
                seriesIndex: 0,
                dataIndex: this.troubleTypePieChartHighlightIndex
              });
            }

            // 默认高亮第一个值不是0的分片，否则默认第一片
            this.troubleTypePieChartHighlightIndex = 0;
            for (let i = 0; i < d.length; i++) {
              if (d[i].people_count) {
                this.troubleTypePieChartHighlightIndex = i;
                break;
              }
            }

            chart.series.push(Object.assign({}, chart.series[0], { label: {
              fontSize: 11,
              formatter: '{b}：{c}',
            }, silent: true }));

            setTimeout(
              () =>
                this.$refs.troubleType &&
                this.$refs.troubleType.dispatchAction({
                  type: 'highlight',
                  seriesIndex: 0,
                  dataIndex: this.troubleTypePieChartHighlightIndex
                }),
              100
            );
            this.troubleTypePieChart = chart;
          })
      },
      companyTroubleMouseOver(params) {
        if (params.dataIndex != this.companyTroublePieChartHighlightIndex) {
          this.$refs.companyTrouble.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.companyTroublePieChartHighlightIndex
          });
          this.companyTroublePieChartHighlightIndex = params.dataIndex;
        }
      },
      companyTroubleMouseOut() {
        this.$refs.companyTrouble.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.companyTroublePieChartHighlightIndex
        });
      },
      troubleTypeMouseOver(params) {
        if (params.dataIndex != this.troubleTypePieChartHighlightIndex) {
          this.$refs.troubleType.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.troubleTypePieChartHighlightIndex
          });
          this.troubleTypePieChartHighlightIndex = params.dataIndex;
        }
      },
      troubleTypeMouseOut() {
        this.$refs.troubleType.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.troubleTypePieChartHighlightIndex
        });
      },

      companyTroublePieChartClick(params){
        // console.log('hhhhhhhhhh')
        this.activeSearchIndex=params.dataIndex;
        if(this.mode==1){
        this.companyTroublePieChartDetailShow=true;
        this.companyTroublePieDetailTitle=params.name+' - 分公司安全巡检率'

        nation.getSafetyInspectOrgCityCollect(this.mode,this.companyTroublePieSearchkeys[params.dataIndex])
          .then(d => {
            // console.log(d);
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option4.xAxis[0].data = d.xAxis_data;
            this.option4.series[0].data = d.bar_series;
            if (d.config.theta) {
              this.option4.series[0].markLine.data[0].yAxis = d.config.theta;
            }
            this.companyTroublePieDetailChart = applyUpdate(chart, this.option4);
          })

        }else{
          if(this.districtScale==2){
            this.popupShow6 = true;
          }else{
            this.popupShow5 = true;
          }
          this.getAttendance5();
          this.companyTroublePieDetailTitle=params.name+' - 项目安全巡检率'

        }
      },
      getAttendance5(){

        nation.getSafetyInspectOrgCityCollect(this.mode,this.companyTroublePieSearchkeys[this.activeSearchIndex])
          .then(d => {

            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.workerAgePieDetailChartTemplate.xAxis[0].data = d.xAxis_data;
            if(this.districtScale!==2){
            d.bar_series[d.bar_series.length-1] = {
              value: d.bar_series[d.bar_series.length-1],
              itemStyle: {
                color: '#FFD153'
              }
            };
            }
            this.workerAgePieDetailChartTemplate.series[0].data = d.bar_series;
            this.workerAgePieDetailChart = applyUpdate(chart, this.workerAgePieDetailChartTemplate);

            this.popupShow5BottomName = this.workerAgePieDetailChartTemplate.xAxis[0].data.length? this.workerAgePieDetailChartTemplate.xAxis[0].data[this.workerAgePieDetailChartTemplate.xAxis[0].data.length-1]:''+'项目安全巡检率';
            this.getworkerAgePieDetailTableCollect(d.search_keys[d.search_keys.length-1]);
            this.popupSearchkeys5 = d.search_keys;
            this.popupXAxisData5 = d.xAxis_data;
            this.popupBarData5 = d.bar_series;
          })
      },
      workerAgePieDetailChartClick(params){
        // console.log(params);
        // console.log(params.dataIndex);
        let subData = this.popupBarData5;
        this.workerAgePieDetailChart.series[0].data = subData.map(function (item, index) {
          return index === params.dataIndex ? {
              value: Object.prototype.toString.call(item) === '[object Object]' ? item.value:item,
              itemStyle: {
                color: '#FFD153'
              }
            }:(Object.prototype.toString.call(item) === '[object Object]' ? item.value:item)
        });
        this.popupShow5BottomName = `${this.popupXAxisData5[params.dataIndex]}项目安全巡检率`;
        this.getworkerAgePieDetailTableCollect(this.popupSearchkeys5[params.dataIndex]);
        this.dataIndex5 = params.dataIndex;
      },
      getworkerAgePieDetailTableCollect(search_keys){
        nation.getSafetyInspectProjectCollect(this.companyTroublePieSearchkeys[this.activeSearchIndex], 1, 100,this.districtScale == 0&&this.orgType == 1
              ? search_keys
              : this.orgLevel,

            this.districtScale == 1||this.orgType !== 1
              ? search_keys
              : (this.orgType == 2?search_keys:this.districtLevel))
          .then(d => {
            this.popupShowData5 = d;
          })
      },

    troubleTypePieChartClick(params) {
      if (this.districtScale !== 2) {
        this.activeIndex = params.dataIndex;
        this.popupSelect = this.mode2;
        this.popupShow = true;
        this.popupTitle = params.name + "隐患分布";
        this.getPopData();
      }
    },
    getPopData() {
      if (this.popupSelect == 3) {
        nation
          .getSafetyProjectTroubleUndoCollect(
            this.districtScale == 0&&this.orgType == 1
              ? this.troubleTypePieDetailSearchKey[this.activeIndex]
              : "",
            this.districtScale == 1||this.orgType !== 1
              ? this.troubleTypePieDetailSearchKey[this.activeIndex]
              : ""
          )
          .then((d) => {
            this.troubleTypePieDetailTable = d.series[0].map((item, index) => {
              return {
                value1: item,
                value2: d.series[1][index],
                value3: d.series[2][index],
                count: d.series[3][index],
                project_name: d.xAxis_data[index],
              };
            });
            this.avgAttendanceMax = Math.max.apply(
              null,
              this.troubleTypePieDetailTable.map((item) => item.count)
            );
          });
      } else {
        // troubleTypePieDetailSearchKey
        nation
          .getSafetyProjectTroubleCollect(
            this.popupSelect,
            1,
            100,
            this.districtScale == 0&&this.orgType == 1
              ? this.troubleTypePieDetailSearchKey[this.activeIndex]
              : "",
            this.districtScale == 1||this.orgType !== 1
              ? this.troubleTypePieDetailSearchKey[this.activeIndex]
              : ""
          )
          .then((d) => {
            this.troubleTypePieDetailTable = d;
            this.avgAttendanceMax = Math.max.apply(
              null,
              this.troubleTypePieDetailTable.map((item) => item.count)
            );
          });
      }
    },

    getAttendance(popupSelect) {
      this.popupSelect = popupSelect;
      this.getPopData();
    },
    },
  }
</script>


<style scoped>
  .custom-select .selected {
    background-color: #0b1d39 !important;
    border: 1px solid #49a0e6 !important;
  }

  .custom-select .selected.open {
    border: 1px solid #49a0e6 !important;
  }

  .custom-select .items {
    border-right: 1px solid #49a0e6 !important;
    border-left: 1px solid #49a0e6 !important;
    border-bottom: 1px solid #49a0e6 !important;
    background-color: #0b1d39 !important;
  }
.tooltips {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  font-size: 0.6rem;
  color: #abbbbf;
  font-weight: bold;
}
.items {
  display: flex;
  align-items: center;
}
.tooltip-item {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.3rem;
}
.tooltip-item1 {
  background-color: #23cf9e;
}
.tooltip-item2 {
  background-color: #eab91e;
}
.tooltip-item3 {
  background-color: #e02f37;
}
.linebar {
  width: 100%;
}
.line-bar {
  display: flex;
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #57c7b6;
  border-radius: 12px;
}
</style>