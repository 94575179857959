<template>
  <div id="admin">
    <!--<header>{{projectName}}</header>-->
    <div class="global-floor-name" v-show="floor.name">{{building.buildingMap[building.currentBuildingID].name}}-{{floor.name}}</div>
    <div class="project_map" id="mapid" style="width: 100vw; height: 100vh" />
    <div id="btn-change-building">
      <img src="../../assets/louceng.png" alt="" width="21" height="17" @click="showBuildingList = !showBuildingList">
      <ul class="building-list" v-show="showBuildingList">
        <li v-for="building in building.buildingList" :key="building.innerid" @click="changeBuilding(building)"
            :class="[building.active ? 'active' : '']">
          {{building.name}}
        </li>
      </ul>
    </div>

    <div id="btn-change-floor" v-if="floor.name && building.buildingMap[building.currentBuildingID].floor.length > 1">
      <div class="upward" @click="changeFloor('up')"></div>
      <div class="floor-name">{{floor.name}}</div>
      <div class="downward" @click="changeFloor('down')"></div>
    </div>

    <el-dialog :title="op === 'edit' ? '编辑危险源' : '放置危险源'" width="fit-content" top="5vh" :fullscreen="nomap" center
               :show-close="!nomap"
               :close-on-click-modal="false"
               custom-class="place-beacon-dialog"
               :close-on-press-escape="false"
               :visible.sync="beaconInfo.showBeaconEditPopup"
               @close="dialogClose">
      <div v-if="!beaconMeta" style="text-align:center">
        数据加载中...
      </div>
      <el-form v-if="beaconMeta" label-width="100px" :label-position="'left'">
        <div id="existing-info">
          <el-form-item label="危险源类型：">
            <span>{{beaconMeta.dangerous_source_name}}</span>
          </el-form-item>
          <div v-for="p in loopBy(Object.keys(beaconMeta.content || {}), 2)" :key="p[0]+'/'+p[1]" style="display:flex">
            <el-form-item :label="p[0] + '：'" style="flex:1">
              <span>{{beaconMeta.content[p[0]]}}</span>
            </el-form-item>
            <el-form-item v-if="p[1]" :label="p[1] + '：'" style="flex:1;margin-left:30px">
              <span>{{beaconMeta.content[p[1]]}}</span>
            </el-form-item>
          </div>
        </div>
        <div class="beacon-props" style="display:flex">
          <el-form-item v-if="!beaconMeta.snumber" label="编号：" style="flex:1">
            <el-input v-model="beaconInfo.snumber"></el-input>
          </el-form-item>
          <el-form-item v-if="beaconMeta.snumber" label="编号：" style="flex:1">
            <span style="line-height:40px">{{beaconMeta.snumber}}</span>
          </el-form-item>
          <el-form-item label="部位：" style="flex:1;margin-left:30px">
            <el-input v-model="beaconInfo.position"></el-input>
          </el-form-item>
        </div>
        <div class="beacon-props" style="display:flex">
          <el-form-item label="共建方：" style="flex:1">
            <multiselect v-model="beaconInfo.company_id" :options="companyList" :preserve-search="true" label="name" track-by="innerid"
              placeholder="请选择" selectedLabel="已选中" selectLabel="点击选取" deselectLabel="点击取消选取">
              <span slot="noResult">
                没有符合查询的结果
              </span>
              <span slot="noOptions">
                没有可选的共建方
              </span>
            </multiselect>
          </el-form-item>
          <el-form-item label="监测单位：" style="flex:1;margin-left:30px">
            <el-input v-model="beaconInfo.survey_unitt"></el-input>
          </el-form-item>
        </div>
        <div class="beacon-props" style="display:flex">
          <el-form-item label="责任工程师：" style="flex:1">
            <multiselect v-model="beaconInfo.rengineer_user" :options="managerList" :multiple="true" :preserve-search="true" label="name" track-by="innerid"
              placeholder="请选择或查询" selectedLabel="已选中" selectLabel="点击选取" deselectLabel="点击取消选取">
              <span slot="noResult">
                没有符合查询的结果
              </span>
              <span slot="noOptions">
                没有可选的责任工程师
              </span>
            </multiselect>
          </el-form-item>
          <el-form-item label="作业人员：" style="flex:1;margin-left:30px">
            <multiselect v-model="beaconInfo.operators_user" :options="workerList" :multiple="true" :preserve-search="true" label="name" track-by="innerid"
              placeholder="请选择或查询" selectedLabel="已选中" selectLabel="点击选取" deselectLabel="点击取消选取">
              <span slot="noResult">
                没有符合查询的结果
              </span>
              <span slot="noOptions">
                没有可选的作业人员
              </span>
            </multiselect>
          </el-form-item>
        </div>
        <div class="beacon-props" style="display:flex">
          <el-form-item label="工程规模：" style="flex:1">
            <el-input v-model="beaconInfo.project_scale"></el-input>
          </el-form-item>
          <el-form-item label="备注：" style="flex:1;margin-left:30px">
            <el-input v-model="beaconInfo.detail_remark"></el-input>
          </el-form-item>
        </div>
        <uploader
            v-model="beaconInfo.dinformation"
            title="交底资料："
            :url="baseUrl + 'pc/file/upload?token=' + token"
            multiple
            @on-success="imgUploadSuccess"
            @on-error="imgUploadError"
        ></uploader>
      </el-form>
      <div v-if="beaconMeta" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitNewBeacon">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import Multiselect from 'vue-multiselect';
  import Uploader from '../../components/image-uploader';
  import { baseUrl, admin } from '../../api2';
  import { project } from '../../api';
  import {
    init,
    initOnClick,
    drawGroundMap,
    drawFloorMap,
    drawBeacons,
  } from '../../../public/leaflet_beacon.js';

  export default {
    components: {
      Multiselect,
      Uploader
    },
    data () {
      return {
        baseUrl: baseUrl,
        token: '',
        op: '',
        nomap: false,
        projectID: '',
        sourceID: '',
        dangerID: '',
        map_list: [],
        img_host: '',
        showBuildingList: false,
        managerList: [],
        workerList: [],
        companyList: [],
        beaconMeta: null,
        beaconInfo: {
          showBeaconEditPopup: false,
          x: 0,
          y: 0,
          snumber: '',
          position: '',
          company_id: null,
          survey_unitt: '',
          operators_user: [],
          rengineer_user: [],
          project_scale: '',
          detail_remark: '',
          dinformation: [],
        },
        editBeaconData: {},
        projectName: '智能安全帽系统',
        floor: {
          name: '',
          id: '',
          floorData: null,
        },
        building: {
          currentBuildingID: '',
          buildingMap: {'': {floor: []}},
          buildingList: [],
        },
        beacons: [],
      }
    },
    mounted () {
      this.op = this.$route.params.op;
      this.projectID = this.$route.query.projectID;
      this.sourceID = this.$route.query.source_id;
      if (this.op == 'edit') {
        this.dangerID = this.$route.query.innerid;
      }
      this.nomap = this.$route.query.nomap && this.$route.query.nomap.toLowerCase() == 'true';
      this.token = this.$route.query.token;
      admin.token = this.token;

      if (!this.token) {
        return this.$alert('缺少必要的URL参数', '警告', {
          confirmButtonText: '返回上一页',
          showClose: false,
          type: 'warning',
          center: true,
          callback: () => {
            history.go(-1)
          }
        })
      }

      let editDataPromise;
      if (this.op == 'edit') {
        editDataPromise = admin.getDanerousInfo(this.sourceID, this.dangerID);
        editDataPromise.then(data => {
            data = data[0];
            if (!this.nomap) {
              this.init(data.build_id, data.map_id);
            }
            this.editBeaconData = data;
            this.beaconInfo.snumber = data.snumber;
            this.beaconInfo.position = data.position;
            this.beaconInfo.survey_unitt = data.survey_unitt;
            this.beaconInfo.dinformation = data.dinformation.split(';').map(filename => ({
              "name": filename,
              "type": "image/png",
              "url": data.host + filename,
              "remoteFileName": filename,
            }));
            this.beaconInfo.project_scale = data.project_scale;
            this.beaconInfo.detail_remark = data.detail_remark;
            this.beaconInfo.showBeaconEditPopup = true;
          })
          .catch(e => console.error(e.msg || e.message));
      } else {
        // op 'add'
        if (this.nomap) {
          this.beaconInfo.showBeaconEditPopup = true;
        } else {
          this.init();
        }
      }

      admin.getBeaconMeta(this.sourceID)
        .then(data => {
          this.beaconMeta = data[0];
          this.beaconMeta.content = JSON.parse(data[0].content);
          if (this.op == 'add') {
            this.beaconInfo.snumber = this.beaconMeta.snumber;
            initOnClick(data[0].dangerous_source_name);
          }
        })
        .catch(e => console.error(e.msg || e.message));

      const managerPromise = admin.getManagerList();
      managerPromise.then(data => {
          this.managerList = data;
        })
        .catch(e => console.error(e.msg || e.message));
      const workerPromise = admin.getWorkerList();
      workerPromise.then(data => {
          this.workerList = data;
        })
        .catch(e => console.error(e.msg || e.message));
      const companyPromise = admin.getCompanyList();
      companyPromise.then(data => {
          this.companyList = data;
        })
        .catch(e => console.error(e.msg || e.message));

      if (this.op == 'edit') {
        Promise.all([editDataPromise, managerPromise]).then(([editData, managerList]) => {
          this.beaconInfo.rengineer_user = [];
          for (const user of editData[0].rengineer_user) {
            this.beaconInfo.rengineer_user.push(managerList.find(item => item.innerid == user.people_id));
          }
        });
        Promise.all([editDataPromise, workerPromise]).then(([editData, workerList]) => {
          this.beaconInfo.operators_user = [];
          for (const user of editData[0].operators_user) {
            this.beaconInfo.operators_user.push(workerList.find(item => item.innerid == user.people_id));
          }
        });
        Promise.all([editDataPromise, companyPromise]).then(([editData, companyList]) => {
          this.beaconInfo.company_id = companyList.find(item => item.innerid == editData[0].company_id);
        });
      }
    },
    methods: {
      // 服务器坐标系 => Leaflet坐标系
      convertXY (x, y) {
        let x2 = -y;
        let y2 = -x;
        x2 = x2 * this.floor.floorData.ratio + this.floor.floorData.offsetX;
        y2 = y2 * this.floor.floorData.ratio + this.floor.floorData.offsetY;
        return [x2, y2];
      },
      // Leaflet坐标系 => 服务器坐标系
      convertXYReverse (x, y) {
        let x2 = (x - this.floor.floorData.offsetX) / this.floor.floorData.ratio;
        let y2 = (y - this.floor.floorData.offsetY) / this.floor.floorData.ratio;
        return [-y2, -x2];
      },
      loopBy (arr, n) {
        const res = [];
        for (let i = 0; i < arr.length; i += n) {
          const item = [arr[i]];
          for (let j = 1; j < n; j++) {
            if (i + j < arr.length) {
              item.push(arr[i + j]);
            }
          }
          res.push(item);
        }
        return res;
      },
      updateBeacons (beacons) {
        beacons.forEach(b => b.icon = (b.iconid > 50 ? 50 : b.iconid) + '-' + b.status);
        this.beacons = beacons;
        drawBeacons(beacons, this.floor.floorData, false);
      },
      init (build_id, floor_id) {
        const self = this;
        // 初始化地图
        init('mapid', self);
        admin.getBuildingList()
          .then(list => {
            self.building = {
              buildingList: list,
              buildingMap: {},
            }
            list.forEach(item => {
              self.building.buildingMap[item.innerid] = item
            })

            // 查找指定的平面图，或者没有指定的话，地面对应的平面图
            let targetIdx = list.findIndex(item => build_id ? (item.innerid == build_id) : (item.type == 2));
            if (targetIdx == -1) {
              targetIdx = 0;
            }

            self.building.currentBuildingID = list[targetIdx].innerid;
            // 查找指定的楼层地图，或者没有指定的话，第一个楼层的地图
            let floorIdx;
            if (floor_id) {
              floorIdx = list[targetIdx].floor.findIndex(item => item.innerid == floor_id);
            }
            if (!floorIdx || floorIdx == -1) {
              floorIdx = 0;
            }

            if (list[targetIdx].type == 2) {
              // 目标地图是地面对应的平面图
              const projectPromise = project.getBuildingBaseInfo(self.projectID);
              const beaconsPromise = admin.getAllBeacons({floorID: list[targetIdx].floor[floorIdx].innerid, buildingID: list[targetIdx].innerid});
              Promise.all([projectPromise, beaconsPromise]).then(([d, beacons]) => {
                self.map_list = d.show_map;
                self.img_host = d.img_host;
                self.changeBuilding(list[targetIdx], floorIdx, beacons);
              });
            } else {
              self.changeBuilding(list[targetIdx], floorIdx);
            }
          });
      },
      imgUploadSuccess (result, fileItem) {
        fileItem.remoteFileName = result.data;
      },
      imgUploadError () {
        this.$message.warning('图片上传失败！');
      },
      submitNewBeacon () {
        if (!this.beaconInfo.snumber) {
          return this.$message.warning('请填写危险源编号');
        }
        if (!this.beaconInfo.position) {
          return this.$message.warning('请填写危险源部位');
        }
        if (!this.beaconInfo.company_id) {
          return this.$message.warning('请填写共建方');
        }
        if (!this.beaconInfo.survey_unitt) {
          return this.$message.warning('请填写监测单位');
        }
        if (!this.beaconInfo.rengineer_user.length) {
          return this.$message.warning('请填写责任工程师');
        }
        if (!this.beaconInfo.operators_user.length) {
          return this.$message.warning('请填写作业人员');
        }
        if (!this.beaconInfo.dinformation.length) {
          return this.$message.warning('请上传至少一张交底照片');
        }

        this.beaconInfo.showBeaconEditPopup = false;
        let xy;
        if (this.floor.floorData) {
          xy = this.convertXYReverse(this.beaconInfo.x, this.beaconInfo.y);
        } else {
          xy = [this.beaconInfo.x, this.beaconInfo.y];
        }
        const params = {
          build_id: this.building.currentBuildingID,
          map_id: this.floor.id,
          x: xy[0],
          y: xy[1],
          source_id: this.sourceID,
          snumber: this.beaconMeta.snumber || this.beaconInfo.snumber,
          position: this.beaconInfo.position,
          company_id: this.beaconInfo.company_id.innerid,
          survey_unitt: this.beaconInfo.survey_unitt,
          operators_user: this.beaconInfo.operators_user.map((item, idx) => ({
            people_name: item.name,
            people_id: item.innerid,
            sort: idx
          })),
          rengineer_user: this.beaconInfo.rengineer_user.map((item, idx) => ({
            people_name: item.name,
            people_id: item.innerid,
            sort: idx
          })),
          project_scale: this.beaconInfo.project_scale,
          detail_remark: this.beaconInfo.detail_remark,
          dinformation: this.beaconInfo.dinformation.map(item => item.remoteFileName).join(';')
        };
        if (this.op == 'edit') {
          params.innerid = this.dangerID;
        }
        admin.addNewBeacon(params)
          .then(() => {
            window.parent.postMessage({ msg: 'done' }, '*');
          })
          // .catch(e => this.$message.error(e.msg || e.message))
      },
      dialogClose () {
        window.parent.postMessage({ msg: 'cancelled' }, '*');
      },
      addBeaconPopupClickHandler (x, y) {
        this.beaconInfo.x = x
        this.beaconInfo.y = y
        this.beaconInfo.showBeaconEditPopup = true
      },
      changeBuilding (building, floorIdx=0, beacons) {
        this.showBuildingList = false
        this.building.currentBuildingID = building.innerid
        Object.values(this.building.buildingMap).forEach(b => {
          b.active = b.innerid === building.innerid
        })
        this.floor.name = building.floor[floorIdx].name;
        this.floor.id = building.floor[floorIdx].innerid;
        this.floor.floorData = null;

        if (building.type == 2) {
          // 地面图
          drawGroundMap(this.img_host + this.map_list[0].image_url);
          if (beacons) {
            this.updateBeacons(beacons);
          } else {
            admin.getAllBeacons({floorID: this.floor.id, buildingID: this.building.currentBuildingID})
              .then(beacons => this.updateBeacons(beacons));
          }
        } else {
          // 楼栋图
          this.changeFloor(this.floor.id);
        }
      },
      /** @param floorIDOrDirection {string} uuid or 'up' or 'down'*/
      changeFloor (floorIDOrDirection) {
        let floorIndex
        let floorID
        const floorArr = this.building.buildingMap[this.building.currentBuildingID].floor
        if (floorIDOrDirection === 'up') {
          floorIndex = floorArr.findIndex(f => f.innerid === this.floor.id) + 1
          if (floorIndex > floorArr.length - 1) floorIndex = floorArr.length
          floorID = floorArr[floorIndex].innerid
          this.floor.name = floorArr[floorIndex].name
        } else if (floorIDOrDirection === 'down') {
          floorIndex = floorArr.findIndex(f => f.innerid === this.floor.id) - 1
          if (floorIndex < 0) floorIndex = 0
          floorID = floorArr[floorIndex].innerid
          this.floor.name = floorArr[floorIndex].name
        } else {
          floorID = floorIDOrDirection
        }
        this.floor.id = floorID;
        admin.getFloorData(floorID)
        .then(floorData => {
          this.floor.floorData = floorData;
          drawFloorMap(floorData, (ratio, offsetX, offsetY) => {
            floorData.ratio = ratio;
            floorData.offsetX = offsetX;
            floorData.offsetY = offsetY;
          });
        });
        admin.getAllBeacons({floorID, buildingID: this.building.currentBuildingID})
        .then(beacons => this.updateBeacons(beacons));
      },
    },
    computed: {
    },
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="less">
  #admin {
    font-size: 16px;

    .global-floor-name {
      position: fixed;
      z-index: 1;
      left: 30px;
      top: 120px;
      font-size: 32px;
      color: #398EE3;
    }

    header {
      background-color: #398EE3;
      font-size: 20px;
      font-weight: bold;
      padding-left: 30px;
      color: #ffffff;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      height: 60px;
      box-shadow: 0 2px 7px rgba(10, 33, 57, 0.39);
      line-height: 60px;
    }

    #btn-change-building {
      position: fixed;
      width: 40px;
      cursor: pointer;
      height: 40px;
      border-radius: 50%;
      background-color: #ffffff;
      border: 1px solid #ECECEC;
      right: 24px;
      bottom: 60px;
      text-align: center;
      line-height: 46px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);

      .building-list {
        position: absolute;
        right: 120%;
        bottom: -50%;
        padding: 5px;
        border-radius: 5px;
        width: max-content;
        background-color: #fff;
        border-bottom: 1px solid #D6D6D6;

        li {
          padding: 5px;
          line-height: 15px;
          color: #000000;
          cursor: pointer;
          border-bottom: 1px solid #D6D6D6;

          &:last-of-type {
            border-bottom: none;
          }

          &.active {
            color: #398EE3;
          }
        }
      }
    }

    #btn-change-floor {
      position: fixed;
      right: 16px;
      bottom: 120px;
      padding: 6px 8px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.7);

      .upward, .downward {
        cursor: pointer;
        border: solid transparent;
        border-width: 22px 16px;
        width: 0;
        height: 0;
      }

      .upward {
        border-bottom-color: #398EE3;
        margin-bottom: 8px;
        border-top-width: 0;
      }

      .downward {
        border-top-color: #398EE3;
        margin-top: 8px;
        border-bottom-width: 0;
      }

      .floor-name {
        color: #398EE3;
        width: 32px;
        height: 16px;
        text-align: center;
        line-height: 16px;
      }
    }
  }

  .vux-uploader {
    padding: 0
  }
</style>

<style>
  .beacon-sprite-div {
    background-image: url("../../assets/sprites.png");
  }

  #admin .place-beacon-dialog {
    border-radius: 6px;
  }

  #existing-info .el-form-item__label {
    line-height: 20px;
    width: unset !important;
  }

  #existing-info .el-form-item__content {
    line-height: 20px;
  }

  #admin .el-form-item {
    margin-bottom: 6px;
  }

  .beacon-props .el-form-item__content {
    line-height: 20px;
  }

  .add-beacon-p, .edit-beacon-p {
    padding: 5px;
    border-radius: 5px;
    margin: 0;
    cursor: pointer;
    background-color: #fff;
  }

  .edit-beacon-p {
    color: #ff998e;
  }

  .el-select {
    width: 100%;
  }
</style>
