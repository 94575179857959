<template>
  <div id="left">
    <DangerLevelsChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <DangerTypePieChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" :org-options="orgOptions" />
    <DangerCheckRateChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
  </div>
</template>

<script>
  import DangerLevelsChart from "./dangerLevelsChart";
  import DangerTypePieChart from "./dangerTypePieChart";
  import DangerCheckRateChart from "./dangerCheckRateChart";

  export default {
    name: 'QualityIndexLeftBar',
    components: {
      DangerLevelsChart,
      DangerTypePieChart,
      DangerCheckRateChart,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
  }
</script>

<style lang="less">
</style>
