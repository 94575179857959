<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  />
</template>

<script>
  import Broadcasts from "../../../../components/broadcasts";
  import { law } from "../../../../api_law";

  export default {
    name: 'QualityIndexBroadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['逾期应收风险项目', '潜亏风险项目', '资信风险项目','工期风险项目'],
        broadcastColors: ['#40f8ff', '#ffd454', '#38c59e','#fea453'],
        broadcastIcons: ['逾期应收风险项目', '潜亏风险项目', '资信风险项目','工期风险'],
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    }
  }

  export function updateData(container, scheduleNextUpdate) {
    law.queryRiskOverview()
      .then((d) => {
        container.broadcastData.riskProject = d;
        container.broadcastData.riskProject.value1 = d.FYQFX;
        container.broadcastData.riskProject.value2 = d.FQKFX;
        container.broadcastData.riskProject.value3 = d.FZXFX;
        container.broadcastData.riskProject.value4 = d.FGQFX;
        
        container.statsData = d;
        // scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
