<template>
  <div id="left">
    <wrapper style="padding:0">
      <!-- <div style="display:flex;align-items:center">
        <div style="font-size:1em;flex:1">近七日现场管理统计</div>
        <div class="option-block" style="font-size:0.7rem;min-width:0" :class="{ 'option-block-selected': mgmStatsOption == 'all' }" @click="() => switchMgmStatsOption('all')">全员</div>
        <div class="option-block" style="font-size:0.7rem;min-width:0" :class="{ 'option-block-selected': mgmStatsOption == 'mgm' }" @click="() => switchMgmStatsOption('mgm')">领导</div>
      </div>
      <div style="max-height:23rem;min-height:12rem;padding-top:0.8rem">
        <div style="width:100%;display:flex;justify-content:space-between;align-items:center;margin-bottom:0.5rem">
          <CustomSelect :options="deptNameList" @input="deptSelect" style="width:6rem;line-height:1.6rem;font-size:0.7rem;font-weight:bold" />
          <div class="option-block option-block-selected" style="font-size:0.7rem;min-width:0" @click="showMgmDetail">详情</div>
        </div>
        <div style="height:11.5rem;line-height:1.6rem;font-size:0.9rem;color:#bfcfd2;font-weight:bold;overflow:hidden;margin-bottom:-0.5rem">
          <v-chart :options="mgmStatsBarChart" autoresize />
        </div>
      </div> -->

      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>机械工程使用情况</div>

        </div>
        <div
          style="height:18rem;padding-top:3rem"
          class="machine_use"
        >
          <div
            class="machine_use_item "
            v-for="(item,index) in machineUseList"
            :key="index"
            :class="'machine_use_item_'+(index+1)"
          >
            <div :class="'machine_num_'+(index+1)">{{item.machine_count}}</div>
            <div>{{item.machine_type_name}}</div>
          </div>
        </div>
      </div>
    </wrapper>

    <wrapper style="margin-top: 1rem;padding:0">
      <!-- <div style="font-size:1em">安全行为之星</div>
      <div style="height:14rem;margin:0.5rem 0;padding:0;display:flex;flex-direction:column;font-size:0.7rem;overflow:hidden">
        <div v-for="(star, index) in securityStarList" :key="star.innerid" :style="{ background: index%2 ? '#1b2d5d' : '#152554', height: '1.6rem', 'line-height': '1.6rem', display: 'flex', justifyContent: 'space-between' }">
          <div style="flex:1;margin:0 0.5rem">{{index + 1}}</div>
          <div style="flex:4;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{star.name}}</div>
          <div style="flex:6;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{star.team_name}}</div>
          <div style="flex:2;margin-right:0.5rem;text-align:right"><a @click="() => showWorkerInfo(star.innerid)" style="color:#48b6e2;text-decoration:underline;cursor:pointer">{{star.total_source}}分</a></div>
        </div>
      </div>
      <div style="width:100%;text-align:right"><a @click="showMoreStars" style="font-size:0.9rem;color:#48b6e2;text-decoration:underline;cursor:pointer">更多>></a></div> -->

      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>危大工程类别</div>

        </div>

        <div style="height:16rem;display: flex; align-items: end;">
          <div
            v-if="dangerTypePieChart.series && [].concat.apply([], dangerTypePieChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart
            v-else
            :options="dangerTypePieChart"
            ref="dangerType"
            @mouseover="dangerTypeMouseOver"
            @mouseout="dangerTypeMouseOut"
            autoresize
          />
          <div
            v-if="!dangerTypePieChart.series || ![].concat.apply([], dangerTypePieChart.series.map(x => x.data)).length == 0"
            style="position:absolute;top:3rem;left:1rem;font-size:0.9rem"
          >数量：个</div>
        </div>
      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem;padding:0">
      <!-- <div style="font-size:1em">今日劳务进出场记录</div>
      <div style="height:14rem;margin:0.5rem 0;padding:0;display:flex;flex-direction:column;font-size:0.7rem;overflow:hidden">
        <div v-for="(worker, index) in workerInOutList" :key="worker.worker_id + worker.check_time" :style="{ background: index%2 ? '#1b2d5d' : '#152554', height: '1.6rem', 'line-height': '1.6rem', display: 'flex', justifyContent: 'space-between' }">
          <div style="flex:2;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{worker.worker_name}}</div>
          <div style="flex:3;text-align:center;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{worker.team_name}}</div>
          <div :style="{flex:4, marginRight:'0.5rem', textAlign:'right', color: worker.type == 1 ? '#20ae53' : '#ba440d'}">{{worker.type == 1 ? '进场' : '出场'}} {{worker.check_time.slice(5, -3)}}</div>
        </div>
      </div>
      <div style="width:100%;text-align:right"><a @click="showMoreAttendance" style="font-size:0.9rem;color:#48b6e2;text-decoration:underline;cursor:pointer">更多>></a></div> -->

      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>近七日安全巡检率</div>

        </div>
        <div style="height:17rem;padding-top:3rem;display: flex; align-items: end;">
          <div
            v-if="todayNewTroubleBarChart.series && [].concat.apply([], todayNewTroubleBarChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart
            v-else
            :options="todayNewTroubleBarChart"
            autoresize
          />
        </div>
      </div>
    </wrapper>
    <!-- <el-dialog
      custom-class="stats-layer"
      :visible.sync="mgmDetailShow"
      width="1500px"
      top="3rem"
    >
      <wrapper style="height:985px;backgroundColor:#0e1328;position:relative">
        <iframe
          ref="mgmDetailIframe"
          :src="`http://smarthat.comprehend.com.cn/html/screen/manager_attendance_collect.html?project_id=${$route.params.id}&token=${$route.query.token}`"
          width="1500px"
          height="985px"
          style="border:0;position:absolute;left:0;top:0"
        ></iframe>
        <div
          class="stats-close-btn"
          @click="mgmDetailShow = false"
        />
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="mgmSubWindowShow"
      width="1200px"
      top="18rem"
    >
      <wrapper style="height:370px;backgroundColor:#0e1328;position:relative">
        <iframe
          ref="mgmDetailIframe"
          :src="mgmSubWindowUrl"
          width="1200px"
          height="370px"
          style="border:0;position:absolute;left:0;top:0"
        ></iframe>
        <div
          class="stats-close-btn"
          @click="mgmSubWindowShow = false"
        />
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="personalInfoPopupShow"
      width="1500px"
      top="15rem"
    >
      <wrapper style="height:622px;backgroundColor:#0e1328;position:relative">
        <iframe
          :src="personalInfoPopupShow ? `http://smarthat.comprehend.com.cn/html/screen/worker_detail.html?worker_id=${personalInfoWorkerId}&type_index=${personalInfoTabIndex}&token=${$route.query.token}` : ''"
          width="1500px"
          height="622px"
          style="border:0;position:absolute;left:0;top:0"
        ></iframe>
        <div
          class="stats-close-btn"
          @click="personalInfoPopupShow = false"
        />
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="securityStarPopupShow"
      width="50rem"
      top="15rem"
    >
      <wrapper style="height:32.5rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="securityStarPopupShow = false"
        />
        <div style="margin:1rem;padding-top:1rem;background-color:#051236">
          <div style="font-size:1rem;margin-left:1rem;">安全行为之星</div>
          <div
            class="stats-chart-block"
            style="height:26rem;width:46.75rem;"
          >
            <div style="margin:1rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:3rem">序号</div>
                <div style="width:6rem">姓名</div>
                <div style="width:10rem">班组</div>
                <div style="width:3rem">得分</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in securityStarInfo"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:3rem">{{(securityStarPage - 1) * 10 + index + 1}}</div>
                  <div style="width:6rem">{{item.name}}</div>
                  <div style="width:10rem">{{item.team_name}}</div>
                  <div style="width:3rem">{{item.total_source}}</div>
                </div>
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="securityStarPage"
              :page-count="securityStarPages"
              @current-change="securityStarPageChange"
              style="text-align:center"
            ></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="inOutPopupShow"
      width="50rem"
      top="5rem"
      @closed="cleanupInOutPopup"
    >
      <wrapper style="height:57rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="inOutPopupShow = false"
        />
        <div style="font-size:1.2rem;margin-top:1rem;margin-left:1rem;">今日劳务出勤总人数：{{projectDetail.checkinCount}}人</div>
        <div style="margin:1rem;padding-top:1rem;background-color:#051236">
          <div style="font-size:1rem;margin-left:1rem;">今日各共建方出勤人数统计</div>
          <div style="width:46rem;height:19rem">
            <v-chart
              :options="companyAttendanceBarChart"
              autoresize
            />
          </div>
        </div>
        <div style="margin:1rem;padding-top:1rem;background-color:#051236">
          <div style="font-size:1rem;margin-left:1rem;">今日劳务进出场记录</div>
          <div
            class="stats-chart-block"
            style="height:26rem;width:46.75rem;"
          >
            <div style="margin:1rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:3rem">序号</div>
                <div style="width:6rem">姓名</div>
                <div style="width:10rem">班组</div>
                <div style="width:10rem">进出场时间</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in workerInOutInfo"
                  :key="item.worker_id + item.check_time"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:3rem">{{(workerInOutPage - 1) * 10 + index + 1}}</div>
                  <div style="width:6rem">{{item.worker_name}}</div>
                  <div style="width:10rem">{{item.team_name}}</div>
                  <div :style="{width: '10rem', color: item.type == 1 ? '#20ae53' : '#ba440d'}">{{item.type == 1 ? '进场' : '出场'}} {{item.check_time.slice(5)}}</div>
                </div>
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="workerInOutPage"
              :page-count="workerInOutPages"
              @current-change="workerInOutPageChange"
              style="text-align:center"
            ></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog> -->
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { project } from "../../api";
// import CustomSelect from '../../components/select'
import wrapper from "../../components/wrap";
import { SimpleBarChartTemplate } from "./barChartTemplates";
import { applyUpdate } from "../../utils";
import { attendancePieChartTemplate } from "./pieChartTemplates";

export default {
  name: "SecurityLeftBar",
  components: {
    "v-chart": ECharts,
    // 'CustomSelect': CustomSelect,
    wrapper,
  },
  props: {
    projectDetail: Object,
    showTrace: Function,
  },
  data() {
    return {
      projectId: null,
      deptNameList: ["全部部门"],
      deptIdList: [""],
      mgmStatsOption: "all",
      mgmStatsSelectedDeptIdx: null,
      mgmStatsBarChart: {},
      mgmDetailShow: false,
      mgmSubWindowUrl: null,
      mgmSubWindowShow: false,
      securityStarList: [],
      workerInOutList: [],
      personalInfoPopupShow: false,
      personalInfoWorkerId: null,
      personalInfoTabIndex: 1,
      securityStarPopupShow: false,
      securityStarPage: 1,
      securityStarPages: 1,
      securityStarInfo: [],
      inOutPopupShow: false,
      workerInOutPage: 1,
      workerInOutPages: 1,
      workerInOutInfo: [],
      companyAttendanceBarChart: {},
      timer: null,

      machineUseList: [],

      dangerTypePieChart: {},
      workerTypePieChartHighlightIndex: 0,
      option2: {
        legend: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["30%", "60%"],
            center: ["50%", "60%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function (params) {
                  return `{value|${Math.round(params.percent)}%}\n{text|${
                    params.name
                  }}`;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },
      
      todayNewTroubleBarChart: {},
        option3: {
          tooltip: {
            trigger: 'axis',
            formatter: "{b}<br>{a0}: {c0}"
          },
          color: ['#4191e5', '#1bd080'],
          grid: {
            contentLabel: true,
            left: '12%',
            right: '6%',
            bottom:"18%",
            top: "13%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              max: 100,
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true,
              name: '巡检率:%'
            }
          ],
          series: [
            {
              name: '巡检率',
              type: 'bar',
              barWidth: 10,
              data: [],
              markLine: {
                symbol: ["none", "none"], //去掉箭头
                itemStyle: {
                  normal: {
                    lineStyle: {
                      type: "dashed",
                      color: "#238778",
                    },
                    label: {
                      show: false,
                      position: "middle",
                    },
                  },
                },
                data: [
                  {
                    yAxis: -1, //这里设置false是隐藏不了的，可以设置为-1
                  },
                ],
              }
            }
          ]
        },
    };
  },
  mounted() {
    // const self = this
    const { id: projectID } = this.$route.params;
    this.projectId = projectID;
    const token = this.$route.query.token;
    window.token = token;

    // project.getDeptList()
    //   .then(d => {
    //     this.deptNameList = ['全部部门'].concat(d.map(item => item.value));
    //     this.deptIdList = [''].concat(d.map(item => item.innerid));
    //   })
    //   // eslint-disable-next-line
    //   .catch(e => console.error(e.msg || e.message));

    // this.updateSecurityStarList(projectID);
    // this.updateWorkerInOutList(projectID);

    // window.addEventListener('message', (event) => {
    //   if (event.data.key == 'merge') {
    //     if (event.data.page == 'trace') {
    //       this.showTrace(event.data.query.worker_id, event.data.query.worker_name);
    //     } else {
    //       this.showMgmSubWindow(event.data.url);
    //     }
    //   }
    // }, false);

    // this.timer = setInterval(() => {
    //   this.updateSecurityStarList(projectID);
    //   this.updateWorkerInOutList(projectID);
    // }, 1000*15);

    this.getData();
  },
  methods: {
    getData() {
      // getSafetyMachineTypeCount
      project
        .getSafetyMachineTypeCount(this.projectId)
        .then((d) => {
          // console.log(d);
          this.machineUseList = d;
        })
        .catch((e) => console.error(e.msg || e.message));

      this.getEcharts2();
      this.getEcharts3();
    },
    getEcharts2() {
      project
        .getSafetyMajorDangerousTypeCount(this.projectId)
        .then((d) => {
          if (this.$refs.dangerType) {
            //取消默认高亮
            this.$refs.dangerType.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: this.workerTypePieChartHighlightIndex,
            });
          }

          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
          // const maxPieces = 14;
          // if (d.data.length > maxPieces) {
          //   d.data[maxPieces - 1].value = d
          //     .slice(maxPieces - 1)
          //     .reduce(
          //       (accumulator, currentValue) => accumulator + currentValue.value,
          //       0
          //     );
          //   d[maxPieces - 1].name = "其它";
          //   d.data = d.data.slice(0, maxPieces);
          // }
          // this.option2.series = [chart.series[0]];
          this.option2.series[0].data = d.series_date;
          // console.log(d, "---");

          // 默认高亮第一个值不是0的分片，否则默认第一片
          // this.workerTypePieChartHighlightIndex = 0;
          // for (let i = 0; i < d.data.length; i++) {
          //   if (this.option2.series[0].data[i].value) {
          //     this.workerTypePieChartHighlightIndex = i;
          //     break;
          //   }
          // }
          this.option2.series[1] = Object.assign({}, this.option2.series[0], {
            label: {
              rich: {
                text: {
                  fontSize: 11,
                },
              },
              formatter: function (params) {
                return `${params.name} ${Math.round(params.percent)}%`;
              },
            },
            silent: true,
          });

          setTimeout(
            () =>
              this.$refs.dangerType &&
              this.$refs.dangerType.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: this.workerTypePieChartHighlightIndex,
              }),
            100
          );
          this.dangerTypePieChart = applyUpdate(chart, this.option2);
          // console.log(this.dangerTypePieChart);
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    dangerTypeMouseOver(params) {
      if (params.dataIndex != this.workerTypePieChartHighlightIndex) {
        this.$refs.dangerType.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.workerTypePieChartHighlightIndex,
        });
        this.workerTypePieChartHighlightIndex = params.dataIndex;
      }
    },
    dangerTypeMouseOut() {
      this.$refs.dangerType.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.workerTypePieChartHighlightIndex,
      });
    },
    getEcharts3() {
      
        project.getSafetyDay7InspectionCollect(this.projectId)
          .then(d => {
            // console.log(d,'kkkkkk');
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option3.xAxis[0].data = d.xAxis_data;
            this.option3.series[0].data = d.series;
            if (d.config.theta) {
              this.option3.series[0].markLine.data[0].yAxis = d.config.theta;
            }
            this.todayNewTroubleBarChart = applyUpdate(chart, this.option3);
          })
    },

    updateMgmStatsChart(deptId) {
      project
        .getMgmStats(
          JSON.stringify({
            project_id: this.$route.params.id,
            department_id: deptId,
            is_only_leader: this.mgmStatsOption == "mgm" ? 1 : 0,
          })
        )
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          chart.legend.selectedMode = false;
          chart.tooltip.formatter = "{b}<br>{a0}: {c0}<br>{a1}: {c1}%";
          chart.xAxis[0].data = d.map((x) => x.date);
          chart.series[0].name = "时长";
          chart.series[1].name = "比例";
          chart.series[0].data = d.map((x) => x.avg_attendance_hour);
          chart.series[1].data = d.map((x) => x.percent_rate.toFixed(1));
          chart.grid.bottom = "15%";
          chart.yAxis[0].max =
            Math.ceil(
              Math.max.apply(
                null,
                d.map((x) => x.avg_attendance_hour)
              ) / 10
            ) * 10;
          chart.yAxis[1].splitLine = {
            show: true,
            lineStyle: { type: "dashed", color: "#333333" },
          };
          chart.yAxis[1].axisLabel.formatter =
            SimpleBarChartTemplate.yAxis[1].axisLabel.formatter;
          this.mgmStatsBarChart = chart;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    updateSecurityStarList(projectID) {
      project
        .getSecurityStars(projectID, 1, 9)
        .then((d) => {
          this.securityStarList = d;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    updateWorkerInOutList(projectID) {
      project
        .getWorkerInOuts(projectID, 1, 9)
        .then((d) => {
          this.workerInOutList = d;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    updateProjectCompanyAttendanceChart() {
      project
        .getProjectCompanyAttendance(this.$route.params.id)
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          chart.legend = null;
          chart.xAxis[0].data = d.map((x) => x.company_name.slice(0, 16));
          chart.xAxis[0].axisLabel.rotate = 30;
          chart.series = [chart.series[0]];
          chart.series[0].name = "人数";
          chart.series[0].data = d.map((x) => x.worker_count);
          chart.grid.top = "15%";
          chart.grid.left = "20%";
          chart.grid.right = "5%";
          chart.grid.bottom = "40%";
          chart.yAxis[0].name = "人数";
          chart.yAxis[0].splitLine = {
            show: true,
            lineStyle: { type: "dashed", color: "#333333" },
          };
          chart.yAxis[1].show = false;
          this.companyAttendanceBarChart = chart;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    deptSelect(option) {
      const idx = this.deptNameList.indexOf(option);
      if (idx >= 0) {
        this.mgmStatsSelectedDeptIdx = idx;
        this.updateMgmStatsChart(this.deptIdList[idx]);
      }
    },
    switchMgmStatsOption(option) {
      if (option != this.mgmStatsOption) {
        this.mgmStatsOption = option;
        this.updateMgmStatsChart(this.deptIdList[this.mgmStatsSelectedDeptIdx]);
      }
    },
    showMgmDetail() {
      this.mgmDetailShow = true;
    },
    showMgmSubWindow(url) {
      this.mgmSubWindowUrl = url;
      this.mgmSubWindowShow = true;
    },
    showMoreStars() {
      project
        .getSecurityStars(this.$route.params.id, 1, 10)
        .then((d) => {
          this.securityStarInfo = d;
          this.securityStarPage = 1;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
      this.securityStarPopupShow = true;
    },
    securityStarPageChange(currPage) {
      project
        .getSecurityStars(this.$route.params.id, currPage, 10)
        .then((d) => {
          this.securityStarInfo = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    showWorkerInfo(workerId) {
      this.personalInfoWorkerId = workerId;
      this.personalInfoTabIndex = 2;
      this.personalInfoPopupShow = true;
    },
    showMoreAttendance() {
      project
        .getWorkerInOuts(this.$route.params.id, 1, 10)
        .then((d) => {
          this.workerInOutInfo = d;
          this.workerInOutPage = 1;
          this.workerInOutPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
      this.updateProjectCompanyAttendanceChart();
      this.inOutPopupShow = true;
    },
    workerInOutPageChange(currPage) {
      project
        .getWorkerInOuts(this.$route.params.id, currPage, 10)
        .then((d) => {
          this.workerInOutInfo = d;
          this.workerInOutPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    cleanupInOutPopup() {
      this.companyAttendanceBarChart = {};
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.machine_use {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.machine_use_item {
  width: 40%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.8rem;
}
.machine_use_item_1 {
  border: 1px solid #29e8a8;
  background-color: rgba(41, 232, 168, 0.2);
}
.machine_num_1 {
  font-size: 1.5rem;
  color: #29e8a8;
}
.machine_use_item_2 {
  border: 1px solid #fee21d;
  background-color: rgba(254, 226, 29, 0.2);
}
.machine_num_2 {
  font-size: 1.5rem;
  color: #fee21d;
}
.machine_use_item_3 {
  border: 1px solid #4191e5;
  background-color: rgba(65, 145, 229, 0.2);
}
.machine_num_3 {
  font-size: 1.5rem;
  color: #4191e5;
}
.machine_use_item_4 {
  border: 1px solid #f38e2c;
  background-color: rgba(243, 142, 44, 0.2);
}
.machine_num_4 {
  font-size: 1.5rem;
  color: #f38e2c;
}
.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>