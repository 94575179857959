<template>
  <div id="content" style="margin-top: 1.5rem">
    <div class="top-item">
      <div
        class="people-status"
        style="margin-top:0rem"
      >
        <wrapper style="padding: 0;">
          <div style="min-height:5rem">

            <el-input
              class="inline-input"
              placeholder="请输入项目名称"
              v-model="projectName"
              @input="getData"
            >
              <i
                slot="suffix"
                class="el-input__icon"
              ><img
                  src="../../assets/nation/search.png"
                  style="margin-right:0.7rem;margin-top:0.8rem"
                  alt=""
                ></i>
            </el-input>

            <!-- <el-tree
               class="project-list"
                :data="projectList"
                :props="defaultProps"
                accordion
                @node-click="handleNodeClick"
              >
              </el-tree> -->

            <div
              class="project-list"
              v-for="item in projectList"
              :key="item.value"
            >
              <!-- 总部名称 -->
              <div class="company-title">
                {{item.name}}
              </div>
              <div
                class="headquarters-name"
                v-for="(data,index) in item.children"
                :key="data.value"
              >
                <!-- 分公司名称 -->
                <div
                  @click="headquartersClick(index)"
                  class="sure-click"
                  style="font-size:16px"
                >
                  <i
                    v-if="activeHeadquarters==index"
                    class="el-icon-arrow-down"
                  ></i>
                  <i
                    v-else
                    class="el-icon-arrow-right"
                  ></i>
                  {{data.name}}
                </div>
                <!-- 项目部名称 -->
                <div v-if="activeHeadquarters==index">
                  <div
                    class="project-name"
                    v-for="(project,indey) in data.children"
                    :key="project.value"
                  >
                    <div
                      class="sure-click"
                      @click="getProjectPoint(project,indey)"
                    >
                      {{project.name}}
                    </div>
                    <div v-if="activeProject==indey">
                      <div
                        class="point-name sure-click"
                        v-for="point in project.children"
                        :key="point.value"
                        @click="changePoint(point)"
                      >
                        {{point.video_name}}
                      </div>

                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </wrapper>
      </div>

      <div class="big-content">
        <div
          v-show="divisionType==1"
          class="one-screen"
        >
          <div class="content-title">
            <div style="display:flex;">
              {{activeProjectInfo.name}}-{{activePonit.video_name}}
            </div>

          </div>
          <div
            class="board"
            style="padding-top:3rem"
          >
            <div
              v-if="Object.keys(activePonit).length === 0"
              style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
              class="no-video"
            >
              <img
                src="../../assets/nation/no_video.png"
                alt=""
              >
            </div>

            <!-- <iframe
              v-else
              src="https://720yun.com/t/33vkbw8bgqh?scene_id=91080560"
              style="height:100%; width: 100%;z-index:10000"
              frameborder="0"
              id="iFrame"
              ref="frame"
              allowfullscreen="true"
            ></iframe> -->
            <div v-else>
              <webrtc-streamer
                v-if="activePonit.type==1"
                :key="activePonit && activePonit.version"
                class="streamer"
                id="stream1"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=830&bitrate=0&rotation=0"
                height="830"
                notitle="true"
                :tooltip="activePonit.video_name"
              ></webrtc-streamer>
              <div
                v-show="activePonit.type==2||activePonit.type==3"
                ref="video1"
                id="video1"
              ></div>
            </div>
            <!-- camDOMid -->
          </div>

        </div>
        <div
          v-show="divisionType==4"
          class="more-screen"
        >

          <div class="four-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 4 - 4]"
            >
              <div style="display:flex;">
                {{activeProjectInfo.name}}-{{activeProjectInfo.children[securityStarPage * 4 - 4].video_name}}
              </div>

            </div>
            <div class="board">
              <webrtc-streamer
                v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 4 - 4]&&activeProjectInfo.children[securityStarPage * 4 - 4].type==1"
                :key="activeProjectInfo.children[securityStarPage * 4 - 4] && activeProjectInfo.children[securityStarPage * 4 - 4].version"
                class="streamer"
                id="stream2"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=405&bitrate=0&rotation=0"
                height="405"
                notitle="true"
                :tooltip="activeProjectInfo.children[securityStarPage * 4 - 4].video_name"
              ></webrtc-streamer>

              <div
                v-show="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 4 - 4]&&(activeProjectInfo.children[securityStarPage * 4 - 4].type==2||activeProjectInfo.children[securityStarPage * 4 - 4].type==3)"
                ref="video2"
                id="video2"
              ></div>

            </div>
          </div>
          <div class="four-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 4 - 3]"
            >
              <div style="display:flex;">
                {{activeProjectInfo.name}}-{{activeProjectInfo.children[securityStarPage * 4 - 3].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 4 - 3]&&activeProjectInfo.children[securityStarPage * 4 - 3].type==1"
                :key="activeProjectInfo.children[securityStarPage * 4 - 3] && activeProjectInfo.children[securityStarPage * 4 - 3].version"
                class="streamer"
                id="stream3"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=405&bitrate=0&rotation=0"
                height="405"
                notitle="true"
                :tooltip="activeProjectInfo.children[securityStarPage * 4 - 3].video_name"
              ></webrtc-streamer>
              <div
                v-show="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 4 - 3]&&(activeProjectInfo.children[securityStarPage * 4 - 3].type==2||activeProjectInfo.children[securityStarPage * 4 - 3].type==3)"
                ref="video3"
                id="video3"
              ></div>

            </div>
          </div>
          <div class="four-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 4 - 2]"
            >
              <div style="display:flex;">
                {{activeProjectInfo.name}}-{{activeProjectInfo.children[securityStarPage * 4 - 2].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 4 - 2]&&activeProjectInfo.children[securityStarPage * 4 - 2].type==1"
                :key="activeProjectInfo.children[securityStarPage * 4 - 2] && activeProjectInfo.children[securityStarPage * 4 - 2].version"
                class="streamer"
                id="stream4"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=405&bitrate=0&rotation=0"
                height="405"
                notitle="true"
                :tooltip="activeProjectInfo.children[securityStarPage * 4 - 2].video_name"
              ></webrtc-streamer>
              <div
                v-show="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 4 - 2]&&(activeProjectInfo.children[securityStarPage * 4 - 2].type==2||activeProjectInfo.children[securityStarPage * 4 - 2].type==3)"
                ref="video4"
                id="video4"
              ></div>

            </div>
          </div>
          <div class="four-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 4 - 1]"
            >
              <div style="display:flex;">
                {{activeProjectInfo.name}}-{{activeProjectInfo.children[securityStarPage * 4 - 1].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 4 - 1]&&activeProjectInfo.children[securityStarPage * 4 - 1].type==1"
                :key="activeProjectInfo.children[securityStarPage * 4 - 1] && activeProjectInfo.children[securityStarPage * 4 - 1].version"
                class="streamer"
                id="stream5"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=405&bitrate=0&rotation=0"
                height="405"
                notitle="true"
                :tooltip="activeProjectInfo.children[securityStarPage * 4 - 1].video_name"
              ></webrtc-streamer>
              <div
                v-show="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 4 - 1]&&(activeProjectInfo.children[securityStarPage * 4 - 1].type==2||activeProjectInfo.children[securityStarPage * 4 - 1].type==3)"
                ref="video5"
                id="video5"
              ></div>

            </div>
          </div>

        </div>
        <div
          v-show="divisionType==9"
          class="more-screen"
        >

          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 9]"
            >
              <div style="display:flex;">
                {{activeProjectInfo.name}}-{{activeProjectInfo.children[securityStarPage * 9 - 9].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 9]&&activeProjectInfo.children[securityStarPage * 9 - 9].type==1"
                :key="activeProjectInfo.children[securityStarPage * 9 - 9] && activeProjectInfo.children[securityStarPage * 9 - 9].version"
                class="streamer"
                id="stream6"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="activeProjectInfo.children[securityStarPage * 9 - 9].video_name"
              ></webrtc-streamer>
              <div
                v-show="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 9]&&(activeProjectInfo.children[securityStarPage * 9 - 9].type==2||activeProjectInfo.children[securityStarPage * 9 - 9].type==3)"
                ref="video6"
                id="video6"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 8]"
            >
              <div style="display:flex;">
                {{activeProjectInfo.name}}-{{activeProjectInfo.children[securityStarPage * 9 - 8].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 8]&&activeProjectInfo.children[securityStarPage * 9 - 8].type==1"
                :key="activeProjectInfo.children[securityStarPage * 9 - 8] && activeProjectInfo.children[securityStarPage * 9 - 8].version"
                class="streamer"
                id="stream7"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="activeProjectInfo.children[securityStarPage * 9 - 8].video_name"
              ></webrtc-streamer>
              <div
                v-show="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 8]&&(activeProjectInfo.children[securityStarPage * 9 - 8].type==2||activeProjectInfo.children[securityStarPage * 9 - 8].type==3)"
                ref="video7"
                id="video7"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 7]"
            >
              <div style="display:flex;">
                {{activeProjectInfo.name}}-{{activeProjectInfo.children[securityStarPage * 9 - 7].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 7]&&activeProjectInfo.children[securityStarPage * 9 - 7].type==1"
                :key="activeProjectInfo.children[securityStarPage * 9 - 7] && activeProjectInfo.children[securityStarPage * 9 - 7].version"
                class="streamer"
                id="stream8"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="activeProjectInfo.children[securityStarPage * 9 - 7].video_name"
              ></webrtc-streamer>
              <div
                v-show="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 7]&&(activeProjectInfo.children[securityStarPage * 9 - 7].type==2||activeProjectInfo.children[securityStarPage * 9 - 7].type==3)"
                ref="video8"
                id="video8"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 6]"
            >
              <div style="display:flex;">
                {{activeProjectInfo.name}}-{{activeProjectInfo.children[securityStarPage * 9 - 6].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 6]&&activeProjectInfo.children[securityStarPage * 9 - 6].type==1"
                :key="activeProjectInfo.children[securityStarPage * 9 - 6] && activeProjectInfo.children[securityStarPage * 9 - 6].version"
                class="streamer"
                id="stream9"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="activeProjectInfo.children[securityStarPage * 9 - 6].video_name"
              ></webrtc-streamer>
              <div
                v-show="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 6]&&(activeProjectInfo.children[securityStarPage * 9 - 6].type==2||activeProjectInfo.children[securityStarPage * 9 - 6].type==3)"
                ref="video9"
                id="video9"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 5]"
            >
              <div style="display:flex;">
                {{activeProjectInfo.name}}-{{activeProjectInfo.children[securityStarPage * 9 - 5].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 5]&&activeProjectInfo.children[securityStarPage * 9 - 5].type==1"
                :key="activeProjectInfo.children[securityStarPage * 9 - 5] && activeProjectInfo.children[securityStarPage * 9 - 5].version"
                class="streamer"
                id="stream10"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="activeProjectInfo.children[securityStarPage * 9 - 5].video_name"
              ></webrtc-streamer>
              <div
                v-show="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 5]&&(activeProjectInfo.children[securityStarPage * 9 - 5].type==2||activeProjectInfo.children[securityStarPage * 9 - 5].type==3)"
                ref="video10"
                id="video10"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 4]"
            >
              <div style="display:flex;">
                {{activeProjectInfo.name}}-{{activeProjectInfo.children[securityStarPage * 9 - 4].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 4]&&activeProjectInfo.children[securityStarPage * 9 - 4].type==1"
                :key="activeProjectInfo.children[securityStarPage * 9 - 4] && activeProjectInfo.children[securityStarPage * 9 - 4].version"
                class="streamer"
                id="stream11"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="activeProjectInfo.children[securityStarPage * 9 - 4].video_name"
              ></webrtc-streamer>
              <div
                v-show="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 4]&&(activeProjectInfo.children[securityStarPage * 9 - 4].type==2||activeProjectInfo.children[securityStarPage * 9 - 4].type==3)"
                ref="video11"
                id="video11"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 3]"
            >
              <div style="display:flex;">
                {{activeProjectInfo.name}}-{{activeProjectInfo.children[securityStarPage * 9 - 3].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 3]&&activeProjectInfo.children[securityStarPage * 9 - 3].type==1"
                :key="activeProjectInfo.children[securityStarPage * 9 - 3] && activeProjectInfo.children[securityStarPage * 9 -3].version"
                class="streamer"
                id="stream12"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="activeProjectInfo.children[securityStarPage * 9 - 3].video_name"
              ></webrtc-streamer>
              <div
                v-show="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 3]&&(activeProjectInfo.children[securityStarPage * 9 - 3].type==2||activeProjectInfo.children[securityStarPage * 9 - 3].type==3)"
                ref="video12"
                id="video12"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 2]"
            >
              <div style="display:flex;">
                {{activeProjectInfo.name}}-{{activeProjectInfo.children[securityStarPage * 9 - 2].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 2]&&activeProjectInfo.children[securityStarPage * 9 - 2].type==1"
                :key="activeProjectInfo.children[securityStarPage * 9 - 2] && activeProjectInfo.children[securityStarPage * 9 - 2].version"
                class="streamer"
                id="stream13"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="activeProjectInfo.children[securityStarPage * 9 - 2].video_name"
              ></webrtc-streamer>
              <div
                v-show="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 2]&&(activeProjectInfo.children[securityStarPage * 9 - 2].type==2||activeProjectInfo.children[securityStarPage * 9 - 2].type==3)"
                ref="video13"
                id="video13"
              ></div>

            </div>
          </div>
          <div class="nine-screen-item">
            <!-- {{item.name}} -->
            <div
              class="content-title"
              v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 1]"
            >
              <div style="display:flex;">
                {{activeProjectInfo.name}}-{{activeProjectInfo.children[securityStarPage * 9 - 1].video_name}}
              </div>

            </div>
            <div class="board">

              <webrtc-streamer
                v-if="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 1]&&activeProjectInfo.children[securityStarPage * 9 - 1].type==1"
                :key="activeProjectInfo.children[securityStarPage * 9 - 1] && activeProjectInfo.children[securityStarPage * 9 - 1].version"
                class="streamer"
                id="stream14"
                webrtcurl="http://58.210.96.206:8000"
                options="rtptransport=tcp&timeout=60&height=250&bitrate=0&rotation=0"
                height="250"
                notitle="true"
                :tooltip="activeProjectInfo.children[securityStarPage * 9 - 1].video_name"
              ></webrtc-streamer>
              <div
                v-show="activeProjectInfo.children&&activeProjectInfo.children[securityStarPage * 9 - 1]&&(activeProjectInfo.children[securityStarPage * 9 - 1].type==2||activeProjectInfo.children[securityStarPage * 9 - 1].type==3)"
                ref="video14"
                id="video14"
              ></div>

            </div>
          </div>
        </div>

        <div
          class="screen-division"
          v-if="activeProjectInfo.children&&activeProjectInfo.children.length"
        >
          <div
            class="division-item"
            :class="divisionType==1?'active-division-type':''"
            @click="changeDivisionType(1)"
          >单屏</div>
          <div
            class="division-item"
            :class="divisionType==4?'active-division-type':''"
            @click="changeDivisionType(4)"
          >四分屏</div>
          <div
            class="division-item"
            :class="divisionType==9?'active-division-type':''"
            @click="changeDivisionType(9)"
          >九分屏</div>
        </div>

        <el-pagination
          v-if="divisionType!=1"
          class="page-change"
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :current-page.sync="securityStarPage"
          :page-count="securityStarPages"
          @current-change="securityStarPageChange"
          style="text-align:center;margin-top:0.5rem"
        ></el-pagination>

      </div>
    </div>

  </div>
</template>

<script>
var self;
require("echarts-gl");
import wrapper from "../../components/wrap";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation, project } from "../../api";
import FlvPlayer from "xgplayer-flv.js";
import hlsPlayer from 'xgplayer-hls.js';
// import Player from "xgplayer";
// import { cubeBarChartTemplate } from "./barChartTemplates";
// import { truncateStr } from "../../utils";

export default {
  name: "video",
  components: {
    wrapper,
  },
  props: {
    broadcastDetails: Object,
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    gotoProject: Function,
  },
  data() {
    return {
      projectName: "",
      projectList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      divisionType: 1,
      activeHeadquarters: 0,
      activeProject: 0,
      activeProjectInfo: {},
      activePonit: {},

      securityStarPage: 1,
      securityStarPages: 1,

      activeContent: 1,
      activeType: "精益成本建造",

      currScreenList: [],

      //视频播放
      isPlay: false,
      player: [],

      testVideoPlayingInterval: null,
      inited: false,
      lastPageSwitched: new Date() / 1000,
      videoPlayingTestsDone: 0,
      nextVideoPlayingTestDelay: 0,
    };
  },
  mounted() {
    self = this;
    const token = this.$route.query.token;
    window.token = token;
    window.$districtLevel = "";
    this.videoPlayingTestsDone = 0;
    this.lastPageSwitched = new Date() / 1000;
    this.getData();
  },
  beforeDestroy() {
    this.disconnectFlv();
    this.disconnectStreams();
    if (this.testVideoPlayingInterval) {
      clearInterval(this.testVideoPlayingInterval);
    }
  },
  methods: {
    getData() {
      nation
        .getOrgList(this.projectName)
        .then((d) => {
          this.projectList = d;
          this.activeHeadquarters = 0;
          this.getProjectPoint(
            d[0].children[this.activeHeadquarters].children[this.activeProject],
            0
          );
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    headquartersClick(index) {
      if (this.activeHeadquarters == index) {
        this.activeHeadquarters = -1;
      } else {
        this.activeHeadquarters = index;
        //   获取分公司下第一个项目的视频列表
        this.getProjectPoint(
          this.projectList[0].children[this.activeHeadquarters].children[0],
          0
        );
      }
    },
    getProjectPoint(project, indey) {
      this.activeProject = indey;
      this.divisionType = 1;
      //   获取视频点位
      if (!project.children.length) {
        nation
          .getOrgVideoList(project.value)
          .then((d) => {
            d.forEach((item) => (item.version = 0));
            project.children = d;
            this.activeProjectInfo = project;
            if (d.length > 0) {
              this.changePoint(d[0]);
            } else {
              this.changePoint({});
            }
          })
          .catch((e) => console.error(e.msg || e.message));
      } else {
        this.changePoint(project.children[0]);
        this.activeProjectInfo = project;
      }
    },
    changePoint(point) {
      this.activePonit = point;
      // 当项目下无视频点位时销毁视频，显示无视频点位图片
      if (
        this.divisionType == 1 &&
        !this.activeProjectInfo.children.length > 0
      ) {
        return this.disconnectStreams();
      }
      //   测试
      this.divisionType = 1;
      if (point.type == 1) {
        this.clearStatus();
      } else{
        this.disconnectFlv();
      }
      this.updateVideos();
    },

    changeDivisionType(val) {
      this.disconnectFlv();
      // this.clearStatus();
      this.divisionType = val;
      this.securityStarPages = Math.ceil(
        this.activeProjectInfo.children.length / this.divisionType
      );
      this.securityStarPageChange(1);
      // this.updateVideos();
    },
    clearStatus() {
      this.videoPlayingTestsDone = 0;
      this.lastPageSwitched = new Date() / 1000;
      this.activeProjectInfo.children = this.activeProjectInfo.children.map(
        (item) => {
          return {
            url: "",
            serial_number: item.serial_number,
            video_name: item.video_name,
            type: item.type,
          };
        }
      );
      this.activePonit.url = "";
      this.inited = false;
    },

    securityStarPageChange(currPage) {
      this.securityStarPage = currPage;

      this.videoPlayingTestsDone = 0;
      this.lastPageSwitched = new Date() / 1000;
      this.disconnectFlv();
      this.updateVideos();
    },
    disconnectStreams() {
      // if (this.divisionType == 1) {
      customElements.whenDefined("webrtc-streamer").then(() => {
        let streamElement = document.getElementById("stream1");
        if (streamElement) {
          streamElement.disconnectStream();
        }
      });
      // } else if (this.divisionType == 4) {
      [0, 1, 2, 3].forEach((idx) => {
        customElements.whenDefined("webrtc-streamer").then(() => {
          let streamElement2 = document.getElementById("stream" + (idx + 2));
          if (streamElement2) {
            streamElement2.disconnectStream();
          }
        });
      });
      // } else if (this.divisionType == 9) {
      [0, 1, 2, 3, 4, 5, 6, 7, 8].forEach((idx) => {
        customElements.whenDefined("webrtc-streamer").then(() => {
          let streamElement3 = document.getElementById("stream" + (idx + 6));
          if (streamElement3) {
            streamElement3.disconnectStream();
          }
        });
      });
      // }
    },

    updateVideos() {
      if (this.testVideoPlayingInterval) {
        window.clearInterval(this.testVideoPlayingInterval);
        this.testVideoPlayingInterval = null;
      }
      if (this.inited) {
        this.disconnectStreams();
      } else {
        this.inited = true;
      }

      if (this.divisionType == 1) {
        const currPage = 0;

        if (this.activePonit.type == 1) {
          // video1
          // this.clearStatus();
          this.activePonit.currentTime = 0;
          this.activePonit.realTime = 0;
          this.playVideo(this.activePonit, 0, currPage);

          this.testVideoPlayingInterval = window.setInterval(() => {
            this.testVideoPlaying(currPage);
          }, 500);
        } else if (this.activePonit.type == 2) {
          this.initPlayer(this.activePonit.url, "video1");
        } else if (this.activePonit.type == 3) {
          this.inithlvPlayer(this.activePonit.url, "video1");
        }
      } else if (this.divisionType == 4) {
        const currPage = this.securityStarPage;
        this.activeProjectInfo.children
          .slice(this.securityStarPage * 4 - 4, this.securityStarPage * 4)
          .forEach((camera, index) => {
            if (camera.type == 1) {
              camera.currentTime = 0;
              camera.realTime = 0;
              this.playVideo(camera, index + 1, currPage);
            } else if (camera.type == 2) {
              this.initPlayer(camera.url, "video" + (index + 2));
            }else if (camera.type == 3) {
              this.inithlvPlayer(camera.url, "video" + (index + 2));
            }
          });
        this.testVideoPlayingInterval = window.setInterval(() => {
          this.testVideoPlaying(currPage);
        }, 500);
      } else if (this.divisionType == 9) {
        const currPage = this.securityStarPage;
        this.activeProjectInfo.children
          .slice(this.securityStarPage * 9 - 9, this.securityStarPage * 9)
          .forEach((camera, index) => {
            if (camera.type == 1) {
              camera.currentTime = 0;
              camera.realTime = 0;
              this.playVideo(camera, index + 5, currPage);
            } else if (camera.type == 2) {
              this.initPlayer(camera.url, "video" + (index + 6));
            }else if (camera.type == 3) {
              this.inithlvPlayer(camera.url, "video" + (index + 6));
            }
          });

        this.testVideoPlayingInterval = window.setInterval(() => {
          this.testVideoPlaying(currPage);
        }, 500);
      }
    },

    playVideo(camera, index, currPage) {
      customElements.whenDefined("webrtc-streamer").then(() => {
        let streamElement = document.getElementById("stream" + (index + 1));
        if (streamElement) {
          streamElement.shadowDOM
            .getElementById("video")
            .addEventListener("waiting", function () {
              if (camera.reloadOnWaiting) {
                camera.reloadOnWaiting = false;
                camera.url = null;
                self.playVideo(camera, index, currPage);
              }
            });
          streamElement.shadowDOM
            .getElementById("video")
            .addEventListener("playing", function () {
              camera.reloadOnWaiting = true;
            });
        }
      });
      if (camera.url) {
        // if (currPage !== this.securityStarPage) return;
        let streamElement = document.getElementById("stream" + (index + 1));
        let url = { video: camera.url, audio: undefined };
        let urlJson = JSON.stringify(url);
        if (streamElement.getAttribute("url") != urlJson) {
          streamElement.disconnectStream();
        } else {
          streamElement.setAttribute("url", null);
        }
        setTimeout(() => {
          streamElement.setAttribute("url", urlJson);
        }, 100);
      } else {
        project
          .getVideoUrl(camera.serial_number)
          .then((res) => {
            if (res.result && res.result.url) {
              customElements
                .whenDefined("webrtc-streamer")
                .then(() => {
                  //   if (currPage !== this.securityStarPage) return;
                  camera.url = res.result.url;
                  let streamElement = document.getElementById(
                    "stream" + (index + 1)
                  );
                  let url = { video: res.result.url, audio: undefined };
                  let urlJson = JSON.stringify(url);
                  if (streamElement.getAttribute("url") != urlJson) {
                    streamElement.disconnectStream();
                  } else {
                    streamElement.setAttribute("url", null);
                  }
                  setTimeout(() => {
                    streamElement.setAttribute("url", urlJson);
                  }, 100);
                })
                .catch((e) => {
                  console.log(
                    "webrtc-streamer webcomponent fails to initialize error:" +
                      e
                  );
                });
            }
          })
          .catch((e) => console.error(e.msg || e.message));
      }
    },

    testVideoPlaying(currPage) {
      //   if (currPage !== this.securityStarPage) return;

      let delay;
      if (this.videoPlayingTestsDone == 0) {
        this.nextVideoPlayingTestDelay = 0;
        delay = 5;
      } else if (this.videoPlayingTestsDone < 3) {
        delay = 5;
      } else if (this.videoPlayingTestsDone < 10) {
        delay = 10;
      } else {
        delay = 15;
      }

      let delayPassed = false;
      if (
        new Date() / 1000 - this.lastPageSwitched >=
        this.nextVideoPlayingTestDelay + delay
      ) {
        delayPassed = true;
        this.videoPlayingTestsDone++;
        this.nextVideoPlayingTestDelay =
          new Date() / 1000 - this.lastPageSwitched;
      }

      customElements.whenDefined("webrtc-streamer").then(() => {
        if (this.divisionType == 1) {
          //   if (currPage !== this.securityStarPage) return;
          let streamElement = document.getElementById("stream1");
          if (streamElement) {
            const videoElem = streamElement.shadowDOM.getElementById("video");
            const online =
              videoElem.readyState > 0 ||
              videoElem.getBoundingClientRect().height > 155;
            const stalled =
              online &&
              videoElem.currentTime == (this.activePonit.currentTime || 0) &&
              new Date() / 1000 - (this.activePonit.realTime || 8000000000) >=
                7;

            this.activePonit.online = online;
            if (
              !online ||
              stalled ||
              this.activePonit.currentTime != videoElem.currentTime
            ) {
              this.activePonit.currentTime = videoElem.currentTime;
              this.activePonit.realTime = new Date() / 1000;
            }

            if (stalled || (delayPassed && !online)) {
              this.activePonit.version++;

              if (!online && this.videoPlayingTestsDone >= 3) {
                this.activePonit.url = null;
              }
              this.activePonit = JSON.parse(JSON.stringify(this.activePonit));
              setTimeout(() => {
                this.playVideo(this.activePonit, 0, currPage);
              }, 1000);
            }
          }
        } else if (this.divisionType == 4) {
          [0, 1, 2, 3].forEach((idx) => {
            if (currPage !== this.securityStarPage) return;
            if (
              this.securityStarPage * 4 - 4 + idx <
              this.activeProjectInfo.children.length
            ) {
              let streamElement = document.getElementById("stream" + (idx + 2));
              if (streamElement) {
                const videoElem =
                  streamElement.shadowDOM.getElementById("video");
                const online =
                  videoElem.readyState > 0 ||
                  videoElem.getBoundingClientRect().height > 155;
                const stalled =
                  online &&
                  videoElem.currentTime ==
                    (this.activeProjectInfo.children[
                      this.securityStarPage * 4 - 4 + idx
                    ].currentTime || 0) &&
                  new Date() / 1000 -
                    (this.activeProjectInfo.children[
                      this.securityStarPage * 4 - 4 + idx
                    ].realTime || 8000000000) >=
                    7;

                this.activeProjectInfo.children[
                  this.securityStarPage * 4 - 4 + idx
                ].online = online;
                if (
                  !online ||
                  stalled ||
                  this.activeProjectInfo.children[
                    this.securityStarPage * 4 - 4 + idx
                  ].currentTime != videoElem.currentTime
                ) {
                  this.activeProjectInfo.children[
                    this.securityStarPage * 4 - 4 + idx
                  ].currentTime = videoElem.currentTime;
                  this.activeProjectInfo.children[
                    this.securityStarPage * 4 - 4 + idx
                  ].realTime = new Date() / 1000;
                }

                if (stalled || (delayPassed && !online)) {
                  this.activeProjectInfo.children[
                    this.securityStarPage * 4 - 4 + idx
                  ].version++;
                  if (!online && this.videoPlayingTestsDone >= 3) {
                    this.activeProjectInfo.children[
                      this.securityStarPage * 4 - 4 + idx
                    ].url = null;
                  }
                  this.activeProjectInfo.children = JSON.parse(
                    JSON.stringify(this.activeProjectInfo.children)
                  );
                  setTimeout(() => {
                    this.playVideo(
                      this.activeProjectInfo.children[
                        this.securityStarPage * 4 - 4 + idx
                      ],
                      idx + 1,
                      currPage
                    );
                  }, 1000);
                }
              }
            }
          });
        } else if (this.divisionType == 9) {
          [0, 1, 2, 3, 4, 5, 6, 7, 8].forEach((idx) => {
            if (currPage !== this.securityStarPage) return;
            if (
              this.securityStarPage * 9 - 9 + idx <
              this.activeProjectInfo.children.length
            ) {
              let streamElement = document.getElementById("stream" + (idx + 6));
              if (streamElement) {
                const videoElem =
                  streamElement.shadowDOM.getElementById("video");
                const online =
                  videoElem.readyState > 0 ||
                  videoElem.getBoundingClientRect().height > 155;
                const stalled =
                  online &&
                  videoElem.currentTime ==
                    (this.activeProjectInfo.children[
                      this.securityStarPage * 9 - 9 + idx
                    ].currentTime || 0) &&
                  new Date() / 1000 -
                    (this.activeProjectInfo.children[
                      this.securityStarPage * 9 - 9 + idx
                    ].realTime || 8000000000) >=
                    7;

                this.activeProjectInfo.children[
                  this.securityStarPage * 9 - 9 + idx
                ].online = online;
                if (
                  !online ||
                  stalled ||
                  this.activeProjectInfo.children[
                    this.securityStarPage * 9 - 9 + idx
                  ].currentTime != videoElem.currentTime
                ) {
                  this.activeProjectInfo.children[
                    this.securityStarPage * 9 - 9 + idx
                  ].currentTime = videoElem.currentTime;
                  this.activeProjectInfo.children[
                    this.securityStarPage * 9 - 9 + idx
                  ].realTime = new Date() / 1000;
                }

                if (stalled || (delayPassed && !online)) {
                  this.activeProjectInfo.children[
                    this.securityStarPage * 9 - 9 + idx
                  ].version++;
                  if (!online && this.videoPlayingTestsDone >= 3) {
                    this.activeProjectInfo.children[
                      this.securityStarPage * 9 - 9 + idx
                    ].url = null;
                  }
                  this.activeProjectInfo.children = JSON.parse(
                    JSON.stringify(this.activeProjectInfo.children)
                  );
                  setTimeout(() => {
                    this.playVideo(
                      this.activeProjectInfo.children[
                        this.securityStarPage * 9 - 9 + idx
                      ],
                      idx + 5,
                      currPage
                    );
                  }, 1000);
                }
              }
            }
          });
        }
      });
    },
    initPlayer(url, id) {
      console.log("this.playerhhhhh");
      this.isPlay = true;
      this.player.push(
        new FlvPlayer({
          id: id,
          url: url,
          isLive: true,
          autoplay: true, //自动播放，设置自动播放必要参数
          autoplayMuted: true, //自动播放静音，设置自动播放参数必要参数
          cors: true,
          fluid: true,
        })
      );
      this.player.forEach((item) => {
        item.once("complete", () => {
          console.log("complete"); //以下这段我没成功。
          try {
            item.play(); // 尝试再次执行播放
            setTimeout(() => {
              // 500毫秒后检测
              if (!item.hasStart && item.currentTime === 0) {
                // hasStart返回false，并且播放时间还是0，那么就可以认为自动播放失效了
                // isAutoPlay = false;
                item.replay()
              }
            }, 500);
          } catch (e) {
            console.log(e);
          }
        });
      });
    },
    inithlvPlayer(url, id) {
      console.log("this.playerhhhhh");
      this.isPlay = true;
      this.player.push(
        new hlsPlayer({
          id: id,
          url: url,
          isLive: true,
          autoplay: true, //自动播放，设置自动播放必要参数
          autoplayMuted: true, //自动播放静音，设置自动播放参数必要参数
          cors: true,
          fluid: true,
        })
      );
      this.player.forEach((item) => {
        item.once("complete", () => {
          console.log("complete"); //以下这段我没成功。
          try {
            item.play(); // 尝试再次执行播放
            setTimeout(() => {
              // 500毫秒后检测
              if (!item.hasStart && item.currentTime === 0) {
                // hasStart返回false，并且播放时间还是0，那么就可以认为自动播放失效了
                // isAutoPlay = false;
                item.replay()
              }
            }, 500);
          } catch (e) {
            console.log(e);
          }
        });
      });

    },
    disconnectFlv() {
      [0, 1, 2, 3, 4, 5, 6, 7, 8].forEach((idx) => {
        if (this.player[idx]) {
          this.player[idx].destroy();
        }
      });
      this.player = [];
      console.log("销毁了");
    },
  },
};
</script>
<style lang="less">
.people-status {
  .inline-input {
    margin: 1rem;
    width: 90% !important;
    .el-input__inner {
      width: 100% !important;
      background-color: #0c2341 !important;
      border: 1px solid #1782a6 !important;
      height: 2.5rem !important;
    }
  }
  .el-tree-node__content {
    color: #5accf1;
    background-color: #0f1f3c;
  }
}
.big-content {
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .btn-next {
    padding: 0;
    border: 1px solid #5f7ec9;
    background-color: #21325c;
    padding: 0 0.5rem;
    margin: 0;
  }
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .el-pager li {
    margin: 0;
    border: 1px solid #5f7ec9;
    background-color: #21325c;
    border-radius: 0;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #21325c;
    color: #5accf1;
  }
}
</style>
<style scoped lang="less">
video /deep/{
  height: 100% !important;
  width: 100% !important;
  object-fit: fill !important;
}
.screen-division {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
}
.division-item {
  width: 4rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  background: #21325c;
  border: 1px solid #5f7ec9;
  cursor: pointer;
}
.page-change {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 999;
}
.active-division-type {
  color: #5accf1;
}
.content-title {
  z-index: 99;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to right, #0f1f3c, rgba(255, 255, 255, 0));
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
}
.board {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  padding-top: 3rem;
}
.no-video {
  position: absolute;
  top: 30%;
  left: 40%;
}

.people-status {
  width: 23rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
.project-files {
  width: 20rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
.files-detail-tiltle {
  height: 2.5rem;
  line-height: 2.5rem;
  background: #0c2341;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: #00ffff;
  padding: 0 1rem;
}
.top-item {
  height: 57rem;

  width: calc(100vw - 2rem);
  display: flex;
}
.project-list {
  height: 52rem;
  padding: 0 1rem;
  overflow: scroll;
  .company-title {
    height: 2rem;
    line-height: 2rem;
    font-size: 16px;
    font-weight: bold;
    color: #5accf1;
  }
  .headquarters-name {
    line-height: 2rem;
    font-weight: 400;
    color: #5accf1;
  }
  .sure-click {
    cursor: pointer;
  }
  .project-name {
    font-size: 14px;
    line-height: 1.5rem;
    font-weight: 400;
    color: #c8dbf4;
    margin-left: 1rem;
  }
  .point-name {
    font-size: 12px;
    font-weight: 400;
    color: #5accf1;
    margin-left: 1rem;
    text-decoration: underline;
  }
}
.big-content {
  width: calc(100vw - 27rem);
  height: 57rem;
  margin-right: 1rem;
  border: 1px solid #0d2b50;
  position: relative;
}
.one-screen {
  width: 100%;
  height: 100%;
}
.more-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
}
.four-screen-item {
  width: 49.5%;
  height: 49%;
  position: relative;
}
.nine-screen-item {
  width: 33%;
  height: 33%;
  position: relative;
}

.img {
  width: 2.5rem;
}
</style>
