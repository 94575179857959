<template>
  <div id="left">
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div
        class="info-block-title"
        style="display: flex;align-items:center;width: 85%;justify-content: space-between;"
      >
        <div>产值统计</div>
        <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode == 1 }"
            @click="() => getOutputTotalChart(1)"
          >月度</div>
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode == 2 }"
            @click="() => getOutputTotalChart(2)"
          >季度</div>
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode == 3 }"
            @click="() => getOutputTotalChart(3)"
          >年度</div>
        </div>
      </div>
      <div
        style="height:20rem;padding-top:3rem"
        v-if="districtScale != 2"
      >
        <div
          v-if="totoalOutputPie.series && [].concat.apply([], totoalOutputPie.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="totoalOutputPie"
          @click="totoalOutputPieCLick"
          ref="troubleType"
          @mouseover="totoalOutputPieMouseOver"
          @mouseout="totoalOutputPieMouseOut"
          autoresize
        />
      </div>
      <div
        style="height:20rem;padding-top:3rem"
        v-else
      >

        <div
          v-if="!projectListInfo.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <div
          v-else
          style="max-height:16rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;padding-left: 1rem"
        >
          <div
            v-for="(project,indey) in projectListInfo"
            :key="project.name"
            style="height:1.6rem;display:flex;justify-content:space-between"
          >
            <div
              style="width: 300px ;cursor: pointer;" 
              @click="handleProject2(indey)"
              v-if="project.name.length > 20"
              :title="project.name"
            >{{project.name.slice(0, 20)}}...</div>
            <div v-else style="cursor: pointer;" @click="handleProject2(indey)">{{project.name}}</div>
            <div>{{project.value}}万元（{{project.rate}}%）</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;top: 0.2rem;"
      >
        <div>产值统计</div>
        <div style="display:flex;align-items:center;">
          <CustomSelect
            :options="companyNameList"
            :value="selectVal0"
            @input="companySelect($event, 'val0')"
            style="width:8rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold;"
          />
          <div
            class="detail"
            style="margin-left: 0.5rem;"
            @click="showDetail(1)"
          >
            详情
          </div>
        </div>

      </div>
      <div style="height:18rem;padding-top:3rem">
        <div
          v-if="OutputMonth12.series && [].concat.apply([], OutputMonth12.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="OutputMonth12"
          @click="OutputMonth12Click"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>序时产值</div>
        <div
          class="detail"
          @click="showDetail(2)"
        >
          详情
        </div>
      </div>
      <div style="height:20rem;padding-top:3rem">
        <div
          v-if="OutputSerialChart.series && [].concat.apply([], OutputSerialChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="OutputSerialChart"
          autoresize
        />
      </div>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="60rem"
      top="15rem"
    >
      <wrapper
        class="popup-bg"
        style="height:28rem;backgroundColor:#0e1328;position:relative"
      >
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div
            class="attendance-top"
            style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;"
          >
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle}}
            </div>
            <div class="tabbar">
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 1 }"
                @click="getAttendance(1)"
              >月度</div>
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >季度</div>
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 3 }"
                @click="getAttendance(3)"
              >年度</div>
            </div>
            <div style="width:10rem"></div>
          </div>
          <div style="display:flex; align-items: center;margin-top:1rem;justify-content: space-between;">
            <div></div>
            <div style="font-size:12px;color:#fff;">产值：kw</div>
          </div>
          <!-- <div style="border:1px solid #112563;margin:1.5rem 1rem"> -->
          <div style="height:20rem;padding:1rem 0 0 1rem">
            <div style="height:100%;overflow-y:scroll">
              <div
                v-for="(item, index) in totalOutputPieDetail"
                :key="index"
              >

                <div
                  style="color: #A7B6BC;cursor: pointer;"
                  @click="handleProject(item)"
                >
                  {{item.name}}
                </div>
                <div class="linebar">

                  <div
                    style="flex: 5"
                    class="line-bar"
                  >
                    <div
                      class="real-bar"
                      :style="{'width': (item.value / popupData1Num * 100) + '%' }"
                    ></div>

                  </div>

                  <div style="color: #57C7B6;width:40px">
                    {{item.value}}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="70rem"
      top="12rem"
      :destroy-on-close='true'
    >
      <wrapper
        class="popup-bg"
        style="height:42rem;backgroundColor:#0e1328;position:relative"
      >
        <div
          class="stats-close-btn"
          @click="popupShow2 = false"
        />
        <div class="attendance">
          <div class="attendance-top">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">产值统计详情
            </div>
            <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem;">
              <CustomSelect
                :options="companyNameList"
                :value="selectVal1"
                @input="companySelect($event, 'val1')"
                style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;"
              />
            </div>
            <div style="height:17rem;padding-top:1rem;position:relative">

              <img
                class="img-left"
                src="../../assets/nation/left_mon.png"
                @click="changeDate(0)"
                alt=""
              >
              <img
                class="img-right"
                src="../../assets/nation/right_mon.png"
                @click="changeDate(1)"
                alt=""
              >

              <div
                v-if="OutputMonth12Detail.series && [].concat.apply([], OutputMonth12Detail.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart
                v-else
                :options="OutputMonth12Detail"
                @click="OutputMonth12DetailClick"
                autoresize
              />
            </div>
          </div>
          <div class="attendance-bottom">
            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName}}</div>
            <div style="display:flex; align-items: center;margin:1rem 0;justify-content: space-between;">
              <div></div>
              <div
                style="font-size:12px;color:#fff;"
                v-if="popupData2.length >0"
              >产值：kw</div>
            </div>

            <div
              v-if="popupData2.length <1"
              style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 62rem; height: 12rem;margin-top: 0.5rem;"
            >
              <div class="empty-chart-icon" />
              <div style="margin-top:20px;color:#ccc">暂无数据</div>
            </div>
            <div
              v-else
              style="height: 16rem; overflow-y: auto;"
            >
              <div
                class="linebar"
                v-for="(item, index) in this.popupData2"
                :key="index"
              >
                <div style="color: #A7B6BC;width: 200px;">
                  {{item.name.length>12?item.name.slice(0,12)+'...':item.name}}
                </div>
                <div style="flex: 1">
                  <div
                    class="real-bar"
                    :style="{'width': ((item.value/popupData2Num)*100) + '%' }"
                    style="cursor: pointer;"
                    :title="item.name"
                  ></div>
                </div>
                <div style="color: #57C7B6;margin-left:1rem">
                  {{item.value}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow3"
      width="70rem"
      top="18rem"
    >
      <wrapper
        class="popup-bg"
        style="height:28rem;backgroundColor:#0e1328;position:relative"
      >
        <div
          class="stats-close-btn"
          @click="popupShow3 = false"
        />
        <div class="attendance">
          <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">序时产值详情
          </div>
          <div class="mon-change">

            <img
              class="img"
              src="../../assets/nation/left_mon.png"
              @click="changeMon(0)"
              alt=""
            >
            <div
              v-for="(item,index) in monList"
              :key="index"
              @click="changeActive(index)"
              class="mon-item"
              :class="[index==activeMon ? 'active-mon' : '']"
            >{{item.name}}</div>
            <img
              class="img"
              src="../../assets/nation/right_mon.png"
              @click="changeMon(1)"
              alt=""
            >
          </div>

          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="height:19rem;padding-top:1rem">
              <div
                v-if="OutputSerialPopChart.series && [].concat.apply([], OutputSerialPopChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 62rem; height: 16rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart
                v-else
                :options="OutputSerialPopChart"
                autoresize
              />
            </div>
          </div>

        </div>
      </wrapper>
    </el-dialog>

  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation } from "../../api";
import CustomSelect from "../../components/select";
import { attendancePieChartTemplate, colors } from "./pieChartTemplates";
import {
  SimpleBarChartTemplate,
  stackedBarChartTemplate,
} from "./barChartTemplates";
import { applyUpdate, truncateStr } from "../../utils";
import wrapper from "../../components/wrap";

export default {
  name: "SecurityCheckLeftBar",
  components: {
    "v-chart": ECharts,
    wrapper,
    CustomSelect: CustomSelect,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    orgType: Number,
    orgOptions: Array,
    gotoProject: Function,
  },
  data() {
    return {
      mapLevel: 0,
      org_id: "",
      companyNameList: [""],
      companyIdList: [""],
      selectVal1: "",
      selectVal0: "",

      // 图表1
      mode: 1,
      totoalOutputPie: {},
      projectListInfo: [],
      OutputTotalHighlightIndex: 0,
      // 图表2
      OutputMonth12: {},
      option: {
        color: ["#20bbee"],
        grid: {
          left: "5%",
          right: "5%",
          top: "15%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          show: false,
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              rotate: 40,
              fontWeight: "bold",
              formatter: (value) => truncateStr(value, 10),
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#36445f",
                width: 1,
                type: "solid",
              },
            },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            show: true,
            name: "产值：kw",
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#36445f" },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                type: [1, 1],
                width: 1,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
          },
        ],
        dataZoom: [],
        series: [
          {
            type: "bar",
            barMaxWidth: 9,
            data: [],
          },
        ],
      },
      OutputMonth12SearchKey: [],
      // 图表3
      OutputSerialChart: {},
      option2: {
        color: ["#FF8645", "#23CF9E"],
        grid: {
          left: "8%",
          right: "5%",
          top: "24%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data +
                (params[0].seriesIndex == 2 ? "%" : "kw");
              if (params[1]) {
                result +=
                  "</br>" +
                  " " +
                  params[1].seriesName +
                  " : " +
                  params[1].data +
                  (params[1].seriesIndex == 2 ? "%" : "kw");
              }
              if (params[2]) {
                result +=
                  "</br>" +
                  " " +
                  params[2].seriesName +
                  " : " +
                  params[2].data +
                  "%";
              }
            }
            return result;
          },
        },
        legend: {
          data: [],
        },
        xAxis: {
          axisLabel: {
            color: "#e6f2f3",
            rotate: 40,
            fontWeight: "bold",
            formatter: (value) => truncateStr(value, 10),
          },
          show: true,
          axisLine: { show: true, lineStyle: { color: "#36445f" } },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#36445f",
              width: 1,
              type: "solid",
            },
          },
          axisTick: { show: false },
          type: "category",
          data: [],
        },
        yAxis: [
          {
            type: "value",
            show: true,
            name: "产值：kw",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#36445f" },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                type: [1, 1],
                width: 1,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
          },
        ],
        dataZoom: [],
        series: [
          {
            type: "bar",
            barMaxWidth: 9,
            barGap: 0,
            stack: "Ad",
            data: [],
          },
          {
            type: "bar",
            barMaxWidth: 9,
            data: [],
          },
          {
            type: "bar",
            barMaxWidth: 9,
            stack: "Ad",
            itemStyle: {
              color: "rgba(0,0,0,0)",
            },
            data: [],
          },
        ],
      },

      // 弹窗1
      popupShow: false,
      popupTitle: "",
      popupSelect: 1,
      totalOutputPieDetail: [],
      popupData1Num: 0,
      activeIndex: 0,

      // 弹窗2
      popupShow2: false,
      startDate: "",
      endDate: "",
      OutputMonth12Detail: {},
      option5: {
        color: ["#2DAAEB"],
        grid: {
          contentLabel: true,
          right: "10%",
          bottom: "15%",
          top: "13%",
          left: "15%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0}: {c0}",
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            show: true,
            name: "产值：kw",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        series: [
          {
            name: "产值",
            type: "bar",
            barWidth: 10,
            data: [],
          },
        ],
      },
      popupData2: [],
      popupData2Num: 0,
      activeIndex2: 0,
      popupBottomName: "",
      popupSearchkeys: [],
      popupData: [],
      popupXAxisData: [],

      // 弹窗3
      popupShow3: false,
      monList: [],
      datePage: 1,
      activeMon: 10,
      OutputSerialPopChart: {},
      option6: {
        color: ["#FF8645", "#23CF9E"],
        grid: {
          left: "5%",
          right: "5%",
          top: "24%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data +
                (params[0].seriesIndex == 2 ? "%" : "kw");
              if (params[1]) {
                result +=
                  "</br>" +
                  " " +
                  params[1].seriesName +
                  " : " +
                  params[1].data +
                  (params[1].seriesIndex == 2 ? "%" : "kw");
              }
              if (params[2]) {
                result +=
                  "</br>" +
                  " " +
                  params[2].seriesName +
                  " : " +
                  params[2].data +
                  "%";
              }
            }
            return result;
          },
        },
        legend: {
          data: [],
        },
        xAxis: {
          axisLabel: {
            color: "#e6f2f3",
            rotate: 40,
            fontWeight: "bold",
            formatter: (value) => truncateStr(value, 10),
          },
          show: true,
          axisLine: { show: true, lineStyle: { color: "#36445f" } },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#36445f",
              width: 1,
              type: "solid",
            },
          },
          axisTick: { show: false },
          type: "category",
          data: [],
        },
        yAxis: [
          {
            type: "value",
            show: true,
            name: "产值：kw",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#36445f" },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                type: [1, 1],
                width: 1,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
          },
        ],
        dataZoom: [],
        series: [
          {
            type: "bar",
            barMaxWidth: 9,
            barGap: 0,
            stack: "Ad",
            data: [],
          },
          {
            type: "bar",
            barMaxWidth: 9,
            data: [],
          },
          {
            type: "bar",
            barMaxWidth: 9,
            stack: "Ad",
            itemStyle: {
              color: "rgba(0,0,0,0)",
            },
            data: [],
          },
        ],
      },

      troubleTypePieDetailSearchKey1: [],

      checkTypePieChartHighlightIndex: 0,

      allCheckTroubleTypeChartTitle: "",
      troubleTypeAllSearchKey: [],
      allCheckTroubleTypePopShow: false,
      allCheckTroubleTypePopChart: {},
      allCheckTroubleTypePopOption: {
        legend: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["40%", "70%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function (params) {
                  return `{value|${Math.round(params.percent)}%}\n{text|${
                    params.name
                  }}`;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },
      allCheckTroubleTypePopHighlightIndex: 0,
      troubleTypeAllPopSearchKey: [],
      allCheckTroubleTypePopTableTitle: "",
      allCheckTroubleTypePopTable: [],
    };
  },
  watch: {
    orgOptions(newValue, oldValue) {
      if (newValue != oldValue) {
        this.companyNameList = newValue.map((item) =>
          truncateStr(item.org_name, 10)
        );
        this.companyIdList = newValue.map((item) => item.org_id);

        let idx = this.companyIdList.indexOf(this.orgLevel);
        if (idx == -1) {
          idx = 0;
        }
        this.companySelect(this.companyNameList[idx], "val0");
        this.companySelect(this.companyNameList[idx], "val1");
      }
    },
    orgLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        const idx = this.companyIdList.indexOf(newValue);
        if (idx >= 0) {
          this.companySelect(this.companyNameList[idx], "val0");
          this.companySelect(this.companyNameList[idx], "val1");
        }
        this.org_id = this.orgLevel;
        this.getAll();
      }
    },
    districtLevel: function (newValue) {
      // console.log(newValue)
      if (newValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
          this.companySelect(this.companyNameList[0], "val0");
          this.companySelect(this.companyNameList[0], "val1");
        }
        this.getAll();
      }
    },
    districtScale: function (newValue) {
      // console.log(newValue,oldValue)
      if (newValue) {
        this.mapLevel = newValue;
      }
    },
  },
  mounted() {
    const token = this.$route.query.token;
    window.token = token;
    if (this.orgOptions) {
      this.companyNameList = this.orgOptions.map((item) =>
        truncateStr(item.org_name, 10)
      );
      this.companyIdList = this.orgOptions.map((item) => item.org_id);
      let idx = this.companyIdList.indexOf(this.orgLevel);
      if (idx == -1) {
        idx = 0;
      }
      if (idx >= 0) {
        this.selectedVal1 = this.companyNameList[idx];
        this.selectedVal2 = this.companyNameList[idx];
        this.companySelect(this.companyNameList[idx], "val0");
        this.companySelect(this.companyNameList[idx], "val1");
      }
      this.org_id = this.orgLevel;
    }

    this.getAll();
  },
  methods: {
    companySelect(option, val) {
      const idx = this.companyNameList.indexOf(option);
      if (idx >= 0) {
        this.org_id = this.companyIdList[idx];
        if (val === "val0") {
          this.selectVal0 = option;
          this.selectVal1 = option;
          this.getLeftSecondChart();
        } else if (val === "val1") {
          this.selectVal1 = option;
          this.getAttendance2();
        }
      }
    },
    showDetail(val) {
      if (val === 1) {
        this.selectVal1 =this.selectVal0;
        this.activeIndex2 = this.OutputMonth12.xAxis[0].data.length - 1;
        this.startDate = "";
        this.endDate = "";
        this.popupShow2 = true;
        this.getAttendance2();
      } else {
        this.popupShow3 = true;
        this.datePage=1;
        this.getValidDate();
      }
    },
    // 获取页面图表左边三个
    getAll() {
      this.getOutputTotalChart(1);
      this.getLeftSecondChart();

      nation.getOutputSerialCollect("").then((d) => {
        // this.troubleTypeAllSearchKey = d.search_keys;
        let chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
        this.option2.legend.data = d.legend;
        this.option2.xAxis.data = d.xAxis_data;
        this.option2.series[0].name = d.legend[0];
        this.option2.series[0].data = d.series[0];
        this.option2.series[1].name = d.legend[1];
        this.option2.series[1].data = d.series[1];
        this.option2.series[2].name = "完成率";
        this.option2.series[2].data = d.hide_series.rate;

        this.OutputSerialChart = applyUpdate(chart, this.option2);
      });
    },
    getOutputTotalChart(mode) {
      this.popupSelect = mode;
      this.mode = mode;
      nation.getOutputTotalPie(mode).then((data) => {
        // console.log(data);
        let d = data.data;
        this.troubleTypePieDetailSearchKey1 = data.search_keys;
        let chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
        if (this.mapLevel == 2) {
          chart.series[0].label.normal.formatter = function (params) {
            return `{value|${Math.round(params.value)}}`;
          };
          chart.series[0].label.normal.padding[0] = 0;
        } else {
          chart.series[0].label.normal.formatter =
            attendancePieChartTemplate.series[0].label.normal.formatter;
        }

        chart.color = colors;

        const maxPieces = 14;
        if (d.length > maxPieces) {
          d[maxPieces - 1].value = d
            .slice(maxPieces - 1)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue.value,
              0
            );
          d[maxPieces - 1].name = "其它";
          d = d.slice(0, maxPieces);
        }
        chart.series[0].data = d.map((x) => ({
          name: x.name,
          value: x.value,
        }));
        if (window.innerWidth < 2250) {
          chart.series[0].radius = [35, 65];
        }

        if (this.OutputTotalHighlightIndex != null) {
          this.$refs.troubleType &&
            this.$refs.troubleType.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: this.OutputTotalHighlightIndex,
            });
        }

        // 默认高亮第一个值不是0的分片，否则默认第一片
        this.OutputTotalHighlightIndex = 0;
        for (let i = 0; i < d.length; i++) {
          if (d[i].people_count) {
            this.OutputTotalHighlightIndex = i;
            break;
          }
        }

        chart.series.push(
          Object.assign({}, chart.series[0], {
            label: {
              fontSize: 11,
              formatter: "{b}：{c}",
            },
            silent: true,
          })
        );

        chart.title = {
          text: "总产值" + data.total + "（万元）",
          left: "center",
          textStyle: {
            fontSize: 18,
            color: "rgba(255, 255, 255, 1)",
            fontWeight: "normal",
          },
        };
        setTimeout(
          () =>
            this.$refs.troubleType &&
            this.$refs.troubleType.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: this.OutputTotalHighlightIndex,
            }),
          100
        );
        this.totoalOutputPie = chart;
        var sum = eval(d.map((item) => item.value).join("+"));
        this.projectListInfo = d.map((x) => ({
          name: x.name,
          value: x.value,
          rate: ((Number(x.value) / sum) * 100).toFixed(0),
        }));
      });
    },
    getLeftSecondChart() {
      const idx = this.companyNameList.indexOf(this.selectVal0);
      nation.getOutputMonth12Collect("", "", this.companyIdList[idx]).then((d) => {
        const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
        this.OutputMonth12SearchKey = d.search_keys;
        this.option.xAxis[0].data = d.xAxis_data;
        this.option.series[0].data = d.series;
        this.option.series[0].name = "产值";
        this.OutputMonth12 = applyUpdate(chart, this.option);
      });
    },
    // 图表1

    totoalOutputPieMouseOver(params) {
      if (params.dataIndex != this.OutputTotalHighlightIndex) {
        this.$refs.troubleType.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.OutputTotalHighlightIndex,
        });
        this.OutputTotalHighlightIndex = params.dataIndex;
      }
    },
    totoalOutputPieMouseOut() {
      this.$refs.troubleType.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.OutputTotalHighlightIndex,
      });
    },
    totoalOutputPieCLick(params) {
      if (this.mapLevel != 2) {
        this.activeIndex = params.dataIndex;
        this.popupSelect = this.mode;
        this.popupShow = true;
        this.popupTitle = params.name + "产值统计";
        this.getPopData();
      }
    },
    getPopData() {
      nation
        .getOutputTotalPieDetail(
          this.popupSelect,
          1,
          100,
          this.mapLevel == 0
            ? this.troubleTypePieDetailSearchKey1[this.activeIndex]
            : "",
          this.mapLevel == 1
            ? this.troubleTypePieDetailSearchKey1[this.activeIndex]
            : ""
        )
        .then((d) => {
          this.totalOutputPieDetail = d;
          this.popupData1Num = Math.max.apply(
            null,
            this.totalOutputPieDetail.map((item) => item.value)
          );
        });
    },
    getAttendance(popupSelect) {
      this.popupSelect = popupSelect;
      this.getPopData();
    },

    // 图表2
    OutputMonth12Click(params) {
      this.activeIndex2 = params.dataIndex;
      this.selectVal1 =this.selectVal0;
      this.startDate = "";
      this.endDate = "";
      this.popupShow2 = true;
      this.getAttendance2();
    },
    // 弹窗1
    handleProject(item) {
      this.gotoProject(item.search_key);
    },
    handleProject2(index) {
      this.gotoProject(this.troubleTypePieDetailSearchKey1[index]);
    },
    // 弹窗2
    changeDate(type) {
      if (type == 0) {
        var date1 = this.getMonths(this.popupSearchkeys[0]);

        this.startDate = date1[0];
        this.endDate = date1[date1.length - 2];
        this.getAttendance2();
      } else {
        var date2 = this.getAfterMonths(
          this.popupSearchkeys[this.popupSearchkeys.length - 2]
        );
        // var nowDate = new Date(this.getCurrentMonthFirstDay());
        // if (
        //   nowDate >
        //   new Date(this.popupSearchkeys[this.popupSearchkeys.length - 1])
        // ) {
        this.startDate = date2[0];
        this.endDate = date2[date2.length - 1];
        this.getAttendance2();
        // }
      }
    },
    getCurrentMonthFirstDay() {
      let date = new Date();
      date.setDate(1);
      let month = parseInt(date.getMonth() + 1);
      let day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return date.getFullYear() + "-" + month + "-" + day;
    },
    getMonths(date) {
      var dataArr = [];
      var data = new Date(date);
      // var year = data.getFullYear();
      data.setMonth(data.getMonth() + 1, 1); //获取到当前月份,设置月份
      for (var i = 0; i < 13; i++) {
        data.setMonth(data.getMonth() - 1); //每次循环一次 月份值减1
        var m = data.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        dataArr.unshift(data.getFullYear() + "-" + m);
      }
      return dataArr;
    },
    getAfterMonths(date) {
      var dataArr = [];
      var data = new Date(date);
      // var year = data.getFullYear();
      data.setMonth(data.getMonth() + 1, 1); //获取到当前月份,设置月份
      for (var i = 0; i < 12; i++) {
        data.setMonth(data.getMonth() + 1); //每次循环一次 月份值减1
        var m = data.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        dataArr.push(data.getFullYear() + "-" + m);
      }
      return dataArr;
    },
    getAttendance2() {
      const idx = this.companyNameList.indexOf(this.selectVal1);
      nation
        .getOutputMonth12Collect(this.startDate, this.endDate, this.companyIdList[idx])
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option5.xAxis[0].data = d.xAxis_data;
          this.popupData = d.series;
          d.series[this.activeIndex2] = {
            value: d.series[this.activeIndex2],
            itemStyle: {
              color: "#FFD153",
            },
          };
          this.option5.series[0].data = d.series;
          this.popupBottomName = `${d.search_keys[this.activeIndex2].replace('-', '/')} ${
            this.selectVal1
          } 产值`;
          this.OutputMonth12Detail = applyUpdate(chart, this.option5);

          this.getLaborAttendanceDateOrgProjectCollect(
            d.search_keys[this.activeIndex2]
          );
          this.popupSearchkeys = d.search_keys;
          this.popupXAxisData = d.xAxis_data;
        });
    },
    OutputMonth12DetailClick(params) {
      let subData = this.popupData;
      this.OutputMonth12Detail.series[0].data = subData.map(function (
        item,
        index
      ) {
        return index === params.dataIndex
          ? {
              value:
                Object.prototype.toString.call(item) === "[object Object]"
                  ? item.value
                  : item,
              itemStyle: {
                color: "#FFD153",
              },
            }
          : Object.prototype.toString.call(item) === "[object Object]"
          ? item.value
          : item;
      });
      this.popupBottomName = `${this.popupSearchkeys[params.dataIndex].replace('-', '/')} ${
        this.selectVal1
      } 产值`;
      this.activeIndex2=params.dataIndex;
      this.getLaborAttendanceDateOrgProjectCollect(
        this.popupSearchkeys[params.dataIndex]
      );
    },
    getLaborAttendanceDateOrgProjectCollect(search_keys) {
      const idx = this.companyNameList.indexOf(this.selectVal1);
      nation
        .getOutputMonth12CollectDetail(search_keys, 1, 1000000, this.companyIdList[idx])
        .then((d) => {
          this.popupData2 = d;

          this.popupData2Num = d.reduce((prev, cur) => {
            return prev > cur ? prev : cur;
          }, 0);
        });
    },

    // 弹窗3
    changeMon(type) {
      if (type == 0) {
        this.datePage--;
      } else {
        this.datePage++;
      }
      this.getValidDate();
    },
    changeActive(index) {
      this.activeMon = index;
      this.getPopData3();
    },
    getValidDate() {
      nation.getOutputValidDateCollect(1, this.datePage, 12).then((d) => {
        this.monList = d;
        this.activeMon = d.length-2;
        this.getPopData3();
      });
    },
    getPopData3() {
      nation
        .getOutputSerialCollect(this.monList[this.activeMon].value)
        .then((d) => {
          let chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
          this.option6.legend.data = d.legend;
          this.option6.xAxis.data = d.xAxis_data;
          this.option6.series[0].name = d.legend[0];
          this.option6.series[0].data = d.series[0];
          this.option6.series[1].name = d.legend[1];
          this.option6.series[1].data = d.series[1];
          this.option6.series[2].name = "完成率";
          this.option6.series[2].data = d.hide_series.rate;

          this.OutputSerialPopChart = applyUpdate(chart, this.option6);
        });
    },
  },
};
</script>

<style scoped lang="less">
.mon-change {
  display: flex;
  margin: 1rem;
  justify-content: space-between;
  align-items: center;
}
.mon-item {
  padding: 0.5rem;
  border: 1px solid #52b3ff;
  border-radius: 4px;
}
.active-mon {
  background-color: #52b3ff;
}
.img-left {
  position: absolute;
  top: 5rem;
  left: 3rem;
  width: 3rem;
  z-index: 9;
  cursor: pointer;
}
.img-right {
  position: absolute;
  top: 5rem;
  right: 3rem;
  width: 3rem;
  z-index: 9;
  cursor: pointer;
}
.img {
  width: 2.5rem;
  cursor: pointer;
}
.attendance-bottom {
  margin: 0 2rem;
}
.tooltips {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  // margin-bottom: 1rem;
  font-size: 0.6rem;
  color: #abbbbf;
  font-weight: bold;
}
.items {
  display: flex;
  align-items: center;
}
.tooltip-item {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.3rem;
}
.tooltip-item1 {
  background-color: #23cf9e;
}
.tooltip-item2 {
  background-color: #eab91e;
}
.tooltip-item3 {
  background-color: #e02f37;
}
.linebar {
  width: 100%;
}
.line-bar {
  display: flex;
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #4ebb8c;
  border-radius: 12px;
}
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  z-index: 99 !important;
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>