<template>
  <div id="left">
    <wrapper style="padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>新技术应用情况</div>
        </div>
        <div
          style="height:18rem;padding-top:2rem"
          class="machine_use"
        >
          <div style="display: flex;  font-size:14px;height: 2rem; align-items: center; justify-content: space-between;color:#4fb5da;background:#102855">
                <div style="width:15rem">新技术项目名称</div>
                <div style="width:10rem">应用时间</div>
            </div>
              <div style="height:15rem;font-size:14px;overflow:auto;">
                <div
                  v-for="(item) in newTechList.slice(0, 9)"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between"
                >
                  <div style="width:15rem">{{item.FFAMC}}</div>
                  <div style="width:10rem">
                    {{item.FDJRQ}}
                  </div>
                </div>
                <div
                  v-if="!(newTechList && newTechList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
        </div>
      </div>
    </wrapper>

    <wrapper style="margin-top: 1rem;padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>BIM应用情况</div>

        </div>
        <div style="height:16rem;display: flex;"
          class="machine_use">
          <div style="display: flex;margin-top:2rem;width:;font-size:14px;height: 2rem; align-items: center; justify-content: space-between;color:#4fb5da;background:#102855">
                <div style="width:15rem">BIM项名称</div>
                <div style="width:10rem">应用时间</div>
              </div>
              <div style="height:12rem;font-size:14px;overflow:auto;">
                <div
                  v-for="(item) in bimApplyList.slice(0, 9)"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between"
                >
                  <div style="width:15rem">{{item.xmmc}}</div>
                  <div style="width:10rem">
                    {{item.jhsj}}
                  </div>
                </div>
                <div
                  v-if="!(bimApplyList && bimApplyList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;
                  line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
        </div>
      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem;padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>科技成果完成情况</div>

        </div>
        <div style="height:16rem;display: flex;"
          class="machine_use">
          <div
          v-if="cnTechChart.series && [].concat.apply([], cnTechChart.series.map(x => x.data)).length > 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="cnTechChart" autoresize/>
        </div>
      </div>
    </wrapper>
  </div>
</template>

<script>
require("echarts-gl");
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { project } from "../../api";
import { tech } from "../../api_tech";
// import CustomSelect from '../../components/select'
import wrapper from "../../components/wrap";
import { SimpleBarChartTemplate,stackedBarChartTemplate } from "./barChartTemplates";
import { applyUpdate } from "../../utils";

export default {
  name: "techScienceLeftBar",
  components: {
    // 'CustomSelect': CustomSelect,
    wrapper,
  },
  props: {
    projectDetail: Object,
    showTrace: Function,
  },
  data() {
    return {
      projectId: null,
      deptNameList: ["全部部门"],
      deptIdList: [""],
      mgmStatsOption: "all",
      mgmStatsSelectedDeptIdx: null,
      mgmStatsBarChart: {},
      mgmDetailShow: false,
      mgmSubWindowUrl: null,
      mgmSubWindowShow: false,
      securityStarList: [],
      workerInOutList: [],
      personalInfoPopupShow: false,
      personalInfoWorkerId: null,
      personalInfoTabIndex: 1,
      securityStarPopupShow: false,
      securityStarPage: 1,
      securityStarPages: 1,
      securityStarInfo: [],
      inOutPopupShow: false,
      workerInOutPage: 1,
      workerInOutPages: 1,
      workerInOutInfo: [],
      companyAttendanceBarChart: {},
      timer: null,

      newTechList: [],
      bimApplyList:[],
      replyList:[],
      expertList:[],
      cnTechChart:{},
      workerTypePieChartHighlightIndex: 0,
      option2: {
        legend: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["30%", "60%"],
            center: ["50%", "60%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function (params) {
                  return `{value|${Math.round(params.percent)}%}\n{text|${
                    params.name
                  }}`;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
            xmid:""
          },
        ],
      },
      
      todayNewTroubleBarChart: {},
        option3: {
          tooltip: {
            trigger: 'axis',
            formatter: "{b}<br>{a0}: {c0}"
          },
          color: ['#4191e5', '#1bd080'],
          grid: {
            contentLabel: true,
            left: '12%',
            right: '6%',
            bottom:"18%",
            top: "13%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              max: 100,
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true,
              name: '巡检率:%'
            }
          ],
          series: [
            {
              name: '巡检率',
              type: 'bar',
              barWidth: 10,
              data: [],
              markLine: {
                symbol: ["none", "none"], //去掉箭头
                itemStyle: {
                  normal: {
                    lineStyle: {
                      type: "dashed",
                      color: "#238778",
                    },
                    label: {
                      show: false,
                      position: "middle",
                    },
                  },
                },
                data: [
                  {
                    yAxis: -1, //这里设置false是隐藏不了的，可以设置为-1
                  },
                ],
              }
            }
          ]
        },
    };
  },
  mounted() {
    // const self = this
    const { id: projectID } = this.$route.params;
    this.projectId = projectID;
    const token = this.$route.query.token;
    window.token = token;
    this.xmid = this.$route.query.xmid;
    this.getData();
  },
  methods: {
    getData() {
      // getSafetyMachineTypeCount
      tech
        .getProjectNewTech(this.xmid)
        .then((d) => {
          if(d[0].FFAMC == ""){
            d= [];
          }
          this.newTechList = d;
        })
        .catch((e) => console.error(e.msg || e.message));

      this.getEcharts2();
      this.getEcharts3();
    },
    getEcharts2() {
        tech.getProjectBimApply(this.xmid).then(res=>{
          if(res[0].xmmc == ""){
            res = [];
          }
          this.bimApplyList = res;
        })
    },
    dangerTypeMouseOver(params) {
      if (params.dataIndex != this.workerTypePieChartHighlightIndex) {
        this.$refs.dangerType.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.workerTypePieChartHighlightIndex,
        });
        this.workerTypePieChartHighlightIndex = params.dataIndex;
      }
    },
    dangerTypeMouseOut() {
      this.$refs.dangerType.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.workerTypePieChartHighlightIndex,
      });
    },
    getEcharts3() {
      tech.getCnTechResult()
          .then(d => {
            const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
            const typeList = [];
            const nums = [];
            for (let i = 0; i < d.length; i++) {
              typeList.push(d[i].zblx);
              nums.push(d[i].wcsl);
            }
            const option = {
              color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                }
              },
              legend: {},
              grid: {
                containLabel: true,
                left: '5%',
                right: '6%',
                bottom: "5%",
                top: "15%"
              },
              xAxis: {
                type: 'value',
                axisLabel:{ interval:3 }
              },
              yAxis: {
                type: 'category',
                data: typeList,
                axisLabel:{
                  color:"white"
                }
              },
              series: [
                {
                  name: '完成数量',
                  type: 'bar',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: nums
                }
              ]
            }
            this.cnTechChart = applyUpdate(chart, option);
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
    },

    updateMgmStatsChart(deptId) {
      project
        .getMgmStats(
          JSON.stringify({
            project_id: this.$route.params.id,
            department_id: deptId,
            is_only_leader: this.mgmStatsOption == "mgm" ? 1 : 0,
          })
        )
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          chart.legend.selectedMode = false;
          chart.tooltip.formatter = "{b}<br>{a0}: {c0}<br>{a1}: {c1}%";
          chart.xAxis[0].data = d.map((x) => x.date);
          chart.series[0].name = "时长";
          chart.series[1].name = "比例";
          chart.series[0].data = d.map((x) => x.avg_attendance_hour);
          chart.series[1].data = d.map((x) => x.percent_rate.toFixed(1));
          chart.grid.bottom = "15%";
          chart.yAxis[0].max =
            Math.ceil(
              Math.max.apply(
                null,
                d.map((x) => x.avg_attendance_hour)
              ) / 10
            ) * 10;
          chart.yAxis[1].splitLine = {
            show: true,
            lineStyle: { type: "dashed", color: "#333333" },
          };
          chart.yAxis[1].axisLabel.formatter =
            SimpleBarChartTemplate.yAxis[1].axisLabel.formatter;
          this.mgmStatsBarChart = chart;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    updateSecurityStarList(projectID) {
      project
        .getSecurityStars(projectID, 1, 9)
        .then((d) => {
          this.securityStarList = d;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    updateWorkerInOutList(projectID) {
      project
        .getWorkerInOuts(projectID, 1, 9)
        .then((d) => {
          this.workerInOutList = d;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    updateProjectCompanyAttendanceChart() {
      project
        .getProjectCompanyAttendance(this.$route.params.id)
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          chart.legend = null;
          chart.xAxis[0].data = d.map((x) => x.company_name.slice(0, 16));
          chart.xAxis[0].axisLabel.rotate = 30;
          chart.series = [chart.series[0]];
          chart.series[0].name = "人数";
          chart.series[0].data = d.map((x) => x.worker_count);
          chart.grid.top = "15%";
          chart.grid.left = "20%";
          chart.grid.right = "5%";
          chart.grid.bottom = "40%";
          chart.yAxis[0].name = "人数";
          chart.yAxis[0].splitLine = {
            show: true,
            lineStyle: { type: "dashed", color: "#333333" },
          };
          chart.yAxis[1].show = false;
          this.companyAttendanceBarChart = chart;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    deptSelect(option) {
      const idx = this.deptNameList.indexOf(option);
      if (idx >= 0) {
        this.mgmStatsSelectedDeptIdx = idx;
        this.updateMgmStatsChart(this.deptIdList[idx]);
      }
    },
    switchMgmStatsOption(option) {
      if (option != this.mgmStatsOption) {
        this.mgmStatsOption = option;
        this.updateMgmStatsChart(this.deptIdList[this.mgmStatsSelectedDeptIdx]);
      }
    },
    showMgmDetail() {
      this.mgmDetailShow = true;
    },
    showMgmSubWindow(url) {
      this.mgmSubWindowUrl = url;
      this.mgmSubWindowShow = true;
    },
    showMoreStars() {
      project
        .getSecurityStars(this.$route.params.id, 1, 10)
        .then((d) => {
          this.securityStarInfo = d;
          this.securityStarPage = 1;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
      this.securityStarPopupShow = true;
    },
    securityStarPageChange(currPage) {
      project
        .getSecurityStars(this.$route.params.id, currPage, 10)
        .then((d) => {
          this.securityStarInfo = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    showWorkerInfo(workerId) {
      this.personalInfoWorkerId = workerId;
      this.personalInfoTabIndex = 2;
      this.personalInfoPopupShow = true;
    },
    showMoreAttendance() {
      project
        .getWorkerInOuts(this.$route.params.id, 1, 10)
        .then((d) => {
          this.workerInOutInfo = d;
          this.workerInOutPage = 1;
          this.workerInOutPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
      this.updateProjectCompanyAttendanceChart();
      this.inOutPopupShow = true;
    },
    workerInOutPageChange(currPage) {
      project
        .getWorkerInOuts(this.$route.params.id, currPage, 10)
        .then((d) => {
          this.workerInOutInfo = d;
          this.workerInOutPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    cleanupInOutPopup() {
      this.companyAttendanceBarChart = {};
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.machine_use {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.machine_use_item {
  width: 40%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.8rem;
}
.machine_use_item_1 {
  border: 1px solid #29e8a8;
  background-color: rgba(41, 232, 168, 0.2);
}
.machine_num_1 {
  font-size: 1.5rem;
  color: #29e8a8;
}
.machine_use_item_2 {
  border: 1px solid #fee21d;
  background-color: rgba(254, 226, 29, 0.2);
}
.machine_num_2 {
  font-size: 1.5rem;
  color: #fee21d;
}
.machine_use_item_3 {
  border: 1px solid #4191e5;
  background-color: rgba(65, 145, 229, 0.2);
}
.machine_num_3 {
  font-size: 1.5rem;
  color: #4191e5;
}
.machine_use_item_4 {
  border: 1px solid #f38e2c;
  background-color: rgba(243, 142, 44, 0.2);
}
.machine_num_4 {
  font-size: 1.5rem;
  color: #f38e2c;
}
.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>