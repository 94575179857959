<template>
  <div style="width:100%;height:10rem;display:flex;justify-content:center">
    <div style="display:flex;align-items:center">
      <img :src="require(`../../../../assets/nation/icons/${broadcastIcons[0]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[0]}}</div>
        <div style="display:flex" @click="handleClick && handleClick(1)">
          <v-number
            v-model="(broadcastData || {}).value1"
            :speed="500"
            style="font-family:digital;font-size:3.5rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[0],cursor: 'pointer'}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:3rem">
      <img :src="require(`../../../../assets/nation/icons/${broadcastIcons[1]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[1]}}</div>
        <div style="display:flex" @click="handleClick && handleClick(2)">
          <v-number
            v-model="(broadcastData || {}).value2"
            :speed="500"
            style="font-family:digital;font-size:3.5rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[1]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:3rem">
      <img :src="require(`../../../../assets/nation/icons/${broadcastIcons[2]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[2]}}</div>
        <div style="display:flex" @click="handleClick && handleClick(3)">
          <v-number
            v-model="(broadcastData || {}).value3"
            :speed="500"
            style="font-family:digital;font-size:3.5rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[2]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div v-if="broadcastTitles.length >= 4" style="display:flex;align-items:center;margin-left:3rem">
      <img :src="require(`../../../../assets/nation/icons/${broadcastIcons[3]}.png`)" />
      <div>
        <div class="stats-label" >{{broadcastTitles[3]}}</div>
        <div style="display:flex" @click="handleClick && handleClick(4)">
          <v-number
            v-model="(broadcastData || {}).value4"
            :speed="500"
            style="font-family:digital;font-size:3.5rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[3]}"
          ></v-number>
        </div>
      </div>
    </div>
    <!-- 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
                <el-table style="background-color:#0E1328" height="25rem" :data="tableData" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column
                        prop="branchName"
                        label="分公司">
                    </el-table-column>
                     <el-table-column
                        prop="assessUnit"
                        label="考核分类机构">
                    </el-table-column>
                    <el-table-column label="截至年初资金余额（交收益后）">
                        <el-table-column
                        prop="byBalanceAmount"
                        label="合计"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                合计
                                <el-tooltip :aa="scope" class="item" effect="dark" content="在公司存/借款减去欠交收益加上受限资金" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceDeposit"
                        label="其中：公司存/借款"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：公司存/借款
                                <el-tooltip :aa="scope" class="item" effect="dark" content="分公司截止年初可上存资金减去累计上交收益余额。正数表示在公司存款，负数表示欠公司钱" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceUnpaid"
                        label="其中：欠交公司收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：欠交公司收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="从有现金形式上缴收益考核（2020年）开始每年预算数之和-每年实际已上缴数之和" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceLimited"
                        label="其中：受限资金"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：受限资金
                                <el-tooltip :aa="scope" class="item" effect="dark" content="监管户、农民工工资户、抵入未抵出房产余额" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="本年净流">
                        <el-table-column prop="netFlowAmount" :formatter="Formatter">
                          <template slot-scope="scope" slot="header">
                            <span>
                                合计
                            <el-tooltip :aa="scope" class="item" effect="dark" content="本年收款-支出-管理费" placement="top-start">
                                // class指定icon图标 style可以调图标颜色等样式
                                <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                            </el-tooltip>
                            </span>
                          </template>
                        </el-table-column>
                        <el-table-column prop="netFlowDeposit" :formatter="Formatter">
                          <template slot-scope="scope" slot="header">
                            <span>
                                其中：在公司存款/借款
                            <el-tooltip :aa="scope" class="item" effect="dark" content="本年净流减去本年受限资金增减额" placement="top-start">
                                // class指定icon图标 style可以调图标颜色等样式
                                <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                            </el-tooltip>
                            </span>
                          </template>
                        </el-table-column>
                        <el-table-column prop="netFlowLimited" :formatter="Formatter">
                          <template slot-scope="scope" slot="header">
                            <span>
                                其中：受限
                            <el-tooltip :aa="scope" class="item" effect="dark" content="本年受限资金增减额" placement="top-start">
                                // class指定icon图标 style可以调图标颜色等样式
                                <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                            </el-tooltip>
                            </span>
                          </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                    prop="paidCashIncome"
                    label="本年已交现金收益"
                    :formatter="Formatter">
                        <template slot-scope="scope" slot="header">
                            <span>
                                本年已交现金收益
                            <el-tooltip :aa="scope" class="item" effect="dark" content="根据分公司资金余额与收益情况，与分公司确认上交的本年收益" placement="top-start">
                                // class指定icon图标 style可以调图标颜色等样式
                                <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                            </el-tooltip>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="unpaidCashIncome"
                    label="本年欠交现金收益"
                    :formatter="Formatter">
                        <template slot-scope="scope" slot="header">
                            <span>
                                本年欠交现金收益
                            <el-tooltip :aa="scope" class="item" effect="dark" content="根据预算要求截止目前时点应交未交收益，此金额须经分公司领导确认" placement="top-start">
                                // class指定icon图标 style可以调图标颜色等样式
                                <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                            </el-tooltip>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="截止目前资金余额">
                        <el-table-column
                        prop="curBalanceAmount"
                        label="合计"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                合计
                                <el-tooltip :aa="scope" class="item" effect="dark" content="在公司存/借款减去欠交收益加上受限资金" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="curBalanceDeposit"
                        label="其中：公司存/借款"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：公司存/借款
                                <el-tooltip :aa="scope" class="item" effect="dark" content="分公司截止年初在公司存款/借款加上本年在公司存款/借款" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="curBalanceUnpaid"
                        label="其中：欠交公司收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：欠交公司收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="年初欠交收益加上本年欠缴现金收益-每年实际已上缴数之和" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="curBalanceLimited"
                        label="其中：受限资金"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：受限资金
                                <el-tooltip :aa="scope" class="item" effect="dark" content="分公司本月监管户、专户、抵入未抵出房产余额" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  import { finance } from "../../../../api_finance";
  import VNumber from "../../../../components/v-number";
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'capitalBroadCasts',
    components: {
      VNumber,
      wrapper
    },
    data() {
      return {
        broadcastTitles: ['资金余额','其中银行活期','其中冻结资金','其中监管户/专户'],
        broadcastColors: ['#fea453', '#ffd454', '#46a0ff','#FF8C8F'],
        broadcastIcons: ['目前可用资金余额', '其中活期', '其中受限','其中欠交收益'],
        popupShow:false,
        tableData:[]
      }
    },
    props: {
      broadcastData: Object
    },
    methods:{
       Formatter(row, column, cellValue){
            return finance.formatDecimal(cellValue,2);
        },
      tableRowStyle({rowIndex}){
        if(rowIndex%2 === 0)
            return {backgroundColor:'#213a77',height: '0'}; 
        else   
            return {backgroundColor:'#102855',height: '0'}; 
      },
      tableHeaderColor(){
        return 'color:#4fb5da;background:#102855';
      },
      handleClick(){
        if(window.$orgName != "中建一局集团第一建筑有限公司" && window.$orgName != undefined){
          finance.queryBranchCapitalStatisticLine().then((res=>{
            this.tableData = res;
            this.popupShow = true;
          }))
        }
      }
    },
    watch:{
      broadcastData: function(){
         if(window.$orgName == "中建一局集团第一建筑有限公司"||window.$orgName == undefined){
            this.broadcastTitles = ['资金余额','其中银行活期','其中冻结资金','其中监管户/专户'];
          }else{
            this.broadcastTitles =['目前可用资金余额', '其中活期', '其中受限','(减)其中欠交收益'];
          }
      }
    }
  }

  export function updateData(container,scheduleNextUpdate) {
        if(window.$orgName == "中建一局集团第一建筑有限公司"||window.$orgName == undefined){
          finance.queryCompanyCapitalStatistic().then((d=>{
            container.broadcastData.finance = d;
            container.broadcastData.finance.value1 = finance.formatDecimal(d.curBalance,2);
            container.broadcastData.finance.value2 = finance.formatDecimal(d.curBalanceDeposit,2);
            container.broadcastData.finance.value3 = finance.formatDecimal(d.curBalanceFrozen,2);
            container.broadcastData.finance.value4 = finance.formatDecimal(d.curBalanceWatched,2);
            container.statsData = d;
            scheduleNextUpdate();
          }))
        }else{
          finance.queryBranchCapitalStatisticSum().then((res)=>{
            container.broadcastData.finance = res[0];
            container.broadcastData.finance.value1 = finance.formatDecimal(res[0].curBalanceAmount,2);
            container.broadcastData.finance.value2 = finance.formatDecimal(res[0].curBalanceDeposit,2);
            container.broadcastData.finance.value3 = finance.formatDecimal(res[0].curBalanceLimited,2);
            container.broadcastData.finance.value4 = finance.formatDecimal(res[0].curBalanceUnpaid,2);
            container.statsData = res;
            // scheduleNextUpdate();
          })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
      }
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
