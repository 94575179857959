<template>
  <div id="left">
    <div id="project-base-info" style="margin-bottom: 1rem;">
      <wrapper style="padding: 0;">
        <div class="info-block" style="min-height:5rem">
          <div
            class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
          >
            <div>进出场记录</div>
          </div>
          <div style="height:13.3rem;padding:3rem 1rem 0;display:flex;flex-direction:column;font-size:0.7rem;overflow:hidden">
            <div v-for="(worker, index) in recordLists" :key="worker.worker_id + worker.check_time" :style="{ background: index%2 ? '#1b2d5d' : '#152554', height: '2rem', 'line-height': '2rem', display: 'flex', justifyContent: 'space-between', paddingLeft: '0.5rem' }">
              <div style="flex:2;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{worker.worker_name}}</div>
              <div style="flex:3;text-align:center;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{worker.team_name}}</div>
              <div :style="{flex:4, marginRight:'0.5rem', textAlign:'right', color: worker.type == 1 ? '#20ae53' : '#ba440d'}">{{worker.record}}</div>
            </div>
          </div>
          <div style="width:100%;text-align:right;height:2rem;padding-right: 0.5rem"><a @click="showDetail(1)" style="font-size:0.9rem;color:#48b6e2;text-decoration:underline;cursor:pointer;">更多>></a></div>
        </div>
      </wrapper>
    </div>
    <div id="people-status" style="margin-bottom: 1rem;">
      <wrapper style="padding: 0;">
        <div class="info-block" style="min-height:5rem">
          <div
            class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
          >
            <div>近七日考勤人数/考勤率</div>
            <div class="detail" @click="showDetail(2)">
              详情
            </div>
          </div>
          <div style="height:19rem;padding-top:3rem">
            <v-chart :options="attendanceOption" autoresize />
          </div>
        </div>
      </wrapper>
    </div>
    <div id="people-status">
      <wrapper style="padding: 0;">
        <div class="info-block" style="min-height:5rem">
          <div
            class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
          >
            <div>近10日所有劳务出勤天数分布</div>
            <div class="detail" @click="showDetail(3)">
              详情
            </div>
          </div>
          <div style="height:19rem;padding-top:3rem">
            <v-chart :options="laborAttenOption" ref="laborAttenType" @mouseover="laborAttenMouseOver" @mouseout="laborAttenMouseOut" autoresize />
          </div>
        </div>
      </wrapper>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="60rem"
      top="10rem"
      :destroy-on-close='true'
    >
      <wrapper style="height:42rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow = false"
        />
        <div>
            <div style="font-size: 20px;padding: 1rem 0 0 0.4rem;">今日劳务出勤总人数：<i style="color: #38DDE6;font-style:normal">{{sum}}人</i></div>
            <div style="height:19rem;padding-top:1rem">
              <v-chart :options="attendanceOption2" autoresize />
            </div>
            <div style="margin:1rem;padding:1rem;background-color:#051236;height: 12rem">
              <div style="font-size:1rem;margin:0 0 0.5rem 1rem;">今日劳务进出场记录</div>
              <div class="stats-chart-block" style="height:13rem;width:100%">
                <div style="margin:0.5rem 0.5rem 0 0.5rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
                  <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                    <div style="width:3rem">序号</div>
                    <div style="width:6rem">姓名</div>
                    <div style="width:10rem">班组</div>
                    <div style="width:10rem">进出场时间</div>
                  </div>
                  <div style="height:10rem;overflow:auto;">
                    <div v-for="(item, index) in recordLists2" :key="item.worker_name" style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem" :style="{ background: index%2 ? '#102855' : '#213a77'}">
                      <div style="width:3rem">{{(securityStarPage - 1) * 5 + index + 1}}</div>
                      <div style="width:6rem">{{item.worker_name}}</div>
                      <div style="width:10rem">{{item.team_name}}</div>
                      <div style="width:10rem" :style="{marginRight:'0.5rem', textAlign:'left', color: item.type == 1 ? '#20ae53' : '#ba440d'}">{{item.record}}</div>
                    </div>
                  </div>
                </div>
                <el-pagination background layout="prev, pager, next" :current-page.sync="securityStarPage" :page-count="securityStarPages" @current-change="securityStarPageChange" style="text-align:center"></el-pagination>
              </div>
            </div>
        </div>
      </wrapper>
     </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="110rem"
      top="6rem"
      :destroy-on-close='true'
    >
      <wrapper style="height:56rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow2 = false"
        />
        <el-radio-group v-model="tabPosition" size="medium" @change="onTabChange" style="margin:5px 0 0 20px;">
          <el-radio-button label="今日">今日</el-radio-button>
          <el-radio-button label="历史">历史</el-radio-button>
        </el-radio-group>

        <div v-if="tabPosition=='今日'" style="height:24.5rem;padding:1rem;">
          <div style="display:flex;">
            <div class="stats-chart-block">
              <div style="position:absolute;top:0.75rem;left:0.75rem;">
                <s-title3 title="各工种作业人数/时长"/>
              </div>
              <div v-if="todayWorkersPerTypeChart.series && [].concat.apply([], todayWorkersPerTypeChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 52rem; height: 22.2rem;margin-top: 0.5rem;">
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart v-else :options="todayWorkersPerTypeChart" autoresize @click="param => chartClick('todayWorkersPerType', param)" style="width: 52rem; height: 22.2rem;margin-top: 0.5rem;"/>
            </div>
            <div class="stats-chart-block" style="margin-left:2rem;">
              <div style="position:absolute;top:0.75rem;left:0.75rem;">
                <s-title3 title="各共建方作业人数/时长"/>
              </div>
              <div v-if="todayWorkersPerCompanyChart.series && [].concat.apply([], todayWorkersPerCompanyChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 52rem; height: 22.2rem;margin-top: 0.5rem;">
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart v-else :options="todayWorkersPerCompanyChart" autoresize @click="param => chartClick('todayWorkersPerCompany', param)" style="width: 52rem; height: 22.2rem;margin-top: 0.5rem;"/>
            </div>
          </div>
        </div>
        <div v-if="tabPosition=='今日'" style="height:24.5rem;margin-top:1rem;padding:1rem;">
          <div style="display:flex">
            <div class="stats-chart-block">
              <div style="position:absolute;top:0.75rem;left:0.75rem;">
                <s-title3 title="各区域作业人数/时长"/>
              </div>
              <div v-if="todayWorkersPerAreaChart.series && [].concat.apply([], todayWorkersPerAreaChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 52rem; height: 22.2rem;margin-top: 0.5rem;">
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart v-else :options="todayWorkersPerAreaChart" autoresize @click="param => chartClick('todayWorkersPerArea', param)" style="width: 52rem; height: 22.2rem;margin-top: 0.5rem;"/>
            </div>
            <div class="stats-chart-block" style="margin-left:2rem;">
              <div style="position:absolute;top:0.75rem;left:0.75rem;">
                <s-title3 title="今日未出勤人数分布"/>
              </div>
              <div style="position:absolute;top:0.75rem;right:1rem;">
                总人数：{{totalAbsentees}}人
              </div>
              <div v-if="todayAbsentWorkersChart.series && [].concat.apply([], todayAbsentWorkersChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 52rem; height: 22.2rem;margin-top: 0.5rem;">
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart v-else :options="todayAbsentWorkersChart" autoresize @click="param => chartClick('todayAbsentWorkers', param)" style="width: 52rem; height: 22.2rem;margin-top: 0.5rem;"/>
            </div>
          </div>
        </div>

        <div v-if="tabPosition=='历史'" style="height:24.5rem;padding:1rem;">
          <div style="display:flex;">
            <div class="stats-chart-block">
              <div style="position:absolute;top:0.75rem;left:0.75rem;">
                <s-title3 title="项目近七日出勤人数变化"/>
              </div>
              <div v-if="historyWorkerNumChart.series && [].concat.apply([], historyWorkerNumChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 52rem; height: 22.2rem;margin-top: 0.5rem;">
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart v-else :options="historyWorkerNumChart" autoresize style="width: 52rem; height: 22.2rem;margin-top: 0.5rem;"/>
            </div>
            <div class="stats-chart-block" style="margin-left:2rem;">
              <div style="position:absolute;top:0.75rem;left:0.75rem;">
                <s-title3 title="项目近七日出勤率统计"/>
              </div>
              <div v-if="historyWorkerPctChart.series && [].concat.apply([], historyWorkerPctChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 52rem; height: 22.2rem;margin-top: 0.5rem;">
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart v-else :options="historyWorkerPctChart" autoresize style="width: 52rem; height: 22.2rem;margin-top: 0.5rem;"/>
            </div>
          </div>
        </div>
        <div v-if="tabPosition=='历史'" style="height:24.5rem;margin-top:1rem;padding:1rem;">
          <div style="display:flex">
            <div class="stats-chart-block">
              <div style="position:absolute;top:0.75rem;left:0.75rem;">
                <s-title3 title="各共建方作业人数变化"/>
              </div>
              <div v-if="historyWorkersPerCompanyChart.series && [].concat.apply([], historyWorkersPerCompanyChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 52rem; height: 22.2rem;margin-top: 0.5rem;">
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart v-else :options="historyWorkersPerCompanyChart" autoresize style="width: 52rem; height: 22.2rem;margin-top: 0.5rem;"/>
            </div>
            <div class="stats-chart-block" style="margin-left:2rem;">
              <div style="position:absolute;top:0.75rem;left:0.75rem;">
                <s-title3 title="项目近七日未出勤人数统计"/>
              </div>
              <div v-if="historyAbsentWorkersChart.series && [].concat.apply([], historyAbsentWorkersChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 52rem; height: 22.2rem;margin-top: 0.5rem;">
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart v-else :options="historyAbsentWorkersChart" autoresize style="width: 52rem; height: 22.2rem;margin-top: 0.5rem;"/>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog custom-class="stats-layer" :visible.sync="popupShow3" width="56rem" top="15rem" @closed="cleanupPopup">
      <wrapper class="popup-bg" style="height:25rem;backgroundColor:#0e1328;position:relative;">
        <div class="stats-close-btn" @click="popupShow3 = false" />
        <div v-if="popupChart=='todayWorkersPerType'">
          <div style="position:absolute;top:0.75rem;left:0.75rem;">
            <s-title3 :title="`各工种作业人数/时长 - ${drillValue}`"/>
          </div>
          <v-chart :options="todayWorkersPerTypeDrillDownChart" autoresize style="width: 55rem; height: 22.2rem;margin-top: 0.5rem;"/>
        </div>
        <div v-if="popupChart=='todayWorkersPerCompany'">
          <div style="position:absolute;top:0.75rem;left:0.75rem;">
            <s-title3 :title="`各共建方作业人数/时长 - ${drillValue}`"/>
          </div>
          <v-chart :options="todayWorkersPerCompanyDrillDownChart" autoresize style="width: 55rem; height: 22.2rem;margin-top: 0.5rem;"/>
        </div>
        <div v-if="popupChart=='todayWorkersPerArea'">
          <div style="position:absolute;top:0.75rem;left:0.75rem;">
            <s-title3 :title="`各区域作业人数/时长 - ${drillValue}`"/>
          </div>
          <v-chart :options="todayWorkersPerAreaDrillDownChart" autoresize style="width: 55rem; height: 22.2rem;margin-top: 0.5rem;"/>
        </div>
        <div v-if="popupChart=='todayAbsentWorkers'">
          <div style="position:absolute;top:0.75rem;left:0.75rem;">
            <s-title3 :title="`今日未出勤人数分布 - ${drillValue}`"/>
          </div>
          <div style="position:absolute;top:0.75rem;right:1rem;">
            总人数：{{drillDownAbsentees}}人
          </div>
          <v-chart :options="todayAbsentWorkersDrillDownChart" autoresize style="width: 55rem; height: 22.2rem;margin-top: 0.5rem;"/>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow4"
      width="90rem"
      top="5rem"
      :destroy-on-close='true'
    >
      <wrapper style="height:59rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow4 = false"
        />
        <div style="display:flex;flex-wrap:wrap;margin:auto;height:57rem;overflow-y:scroll;">
          <div v-if="mainCharts && mainCharts.length === 0" style="display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%;height:60rem;">
            <div class="empty-page-icon" />
            <div style="margin-top: 60px;font-size: 2em;">暂无数据</div>
          </div>
          <wrapper v-for="chart in (mainCharts || [])" :key="chart.chartTitle" :style="{'height': `${mainChartHeight + 2.3}rem`}" style="backgroundColor:#000;position:relative;width: 31%;margin: 1rem 1rem">
            <!-- <v-chart :options="chart" autoresize :ref="'mainChart' + chart.chartTitle" @mouseover="param => mainChartMouseOver(chart.chartTitle, param)" @mouseout="mainChartMouseOut(chart.chartTitle)"
              @click="param => chartClick2(chart.chartTitle, param)" :style="{'width': `${mainChartWidth}rem`, 'height': `${mainChartHeight}rem`}" style="margin-top: 0.5rem;"/> -->
            <v-chart :options="chart" autoresize :ref="'mainChart' + chart.chartTitle" @mouseover="param => mainChartMouseOver(chart.chartTitle, param)" @mouseout="mainChartMouseOut(chart.chartTitle)"
              @click="param => chartClick2(chart.chartTitle, param)" :style="{'height': `${mainChartHeight}rem`}" style="margin-top: 0.5rem;"/>
            <div style="position:absolute;top:0.75rem;left:0.75rem;">
              <s-title3 :title="chart.chartTitle"/>
            </div>
            <div style="position:absolute;top:2.8rem;left:0.75rem;width:3rem;height:1.6rem;text-align:center;line-height:1.6rem;cursor:pointer"
              :style="{'background-color': mainChartShowDetails[chart.chartTitle] ? '#0078dc' : '#21325c'}" @click="toggleMainChartDetail(chart.chartTitle)">
              详情
            </div>
          </wrapper>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog custom-class="stats-layer" :visible.sync="popupShow5" width="90rem" top="6rem" @closed="cleanupPopup2">
      <wrapper style="height:45rem;backgroundColor:#0e1328;position:relative">
        <div class="stats-close-btn" @click="popupShow5 = false" />
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div class="stats-chart-block">
            <div style="position:absolute;top:0.75rem;left:0.75rem;">
              <s-title3 :title="`${mainChartData[popupChartTitle] && mainChartData[popupChartTitle].company_id ? mainChartData[popupChartTitle].company_name : '所有劳务'}近10天出勤${drillValue2}人员工种分布`"/>
            </div>
            <v-chart :options="popupWorkerTypeChart" autoresize ref="mainChartpopupWorkerTypeChart" @click="param => mainChartMouseOver('popupWorkerTypeChart', param)" @mouseover="param => mainChartMouseOver('popupWorkerTypeChart', param, true)" @mouseout="mainChartMouseOut('popupWorkerTypeChart')" style="width: 42rem; height: 21.2rem;margin-top: 0.5rem;"/>
          </div>
          <div class="stats-chart-block" style="margin-left:2rem;">
            <div style="position:absolute;top:0.75rem;left:0.75rem;">
              <s-title3 :title="`${mainChartData[popupChartTitle] && mainChartData[popupChartTitle].company_id ? mainChartData[popupChartTitle].company_name : '所有劳务'}近10天出勤${drillValue2}人员${mainChartData[popupChartTitle] && mainChartData[popupChartTitle].company_id ? '班组' : '共建方'}分布`"/>
            </div>
            <v-chart :options="popupTeamChart" autoresize ref="mainChartpopupTeamChart" @click="param => mainChartMouseOver('popupTeamChart', param)" @mouseover="param => mainChartMouseOver('popupTeamChart', param, true)" @mouseout="mainChartMouseOut('popupTeamChart')" style="width: 42rem; height: 21.2rem;margin-top: 0.5rem;"/>
          </div>
        </div>
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div class="stats-chart-block" style="height:17rem;width:86.25rem;">
            <div style="position:absolute;top:0.75rem;left:0.75rem;">
              <s-title3 :title="`${mainChartData[popupChartTitle] && mainChartData[popupChartTitle].company_id ? mainChartData[popupChartTitle].company_name : '所有劳务'}近10天出勤${drillValue2}人员详情`" />
            </div>
            <div style="margin:3rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:3rem">姓名</div>
                <div style="width:20rem">共建方</div>
                <div style="width:10rem">班组</div>
                <div style="width:5rem">工种</div>
                <div style="width:3rem">状态</div>
                <div style="width:8rem">进场时间</div>
                <div v-for="date in (popupWorkerInfo.length ? popupWorkerInfo[0].day_list.map(x => x.check_date) : [])" :key="date" style="width:3rem;text-align:center">{{date}}</div>
              </div>
              <div style="height:10rem;overflow:auto;">
                <div v-for="(item, index) in filteredPopupWorkerInfo" :key="item.worker_id" style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem" :style="{ background: index%2 ? '#102855' : '#213a77'}">
                  <div style="width:3rem">{{item.worker_name}}</div>
                  <div style="width:20rem">{{item.company_name}}</div>
                  <div style="width:10rem">{{item.team_name}}</div>
                  <div style="width:5rem">{{item.work_type}}</div>
                  <div style="width:3rem">{{item.status}}</div>
                  <div style="width:8rem">{{item.in_time}}</div>
                  <div v-for="entry in item.day_list" :key="entry.date" style="width:3rem;text-align:center"
                    :style="{'font-size': entry.is_attended ? '0.9em' : '1.2em', 'color': entry.is_attended ? '#47c49d' : '#db2866'}">
                    <font-awesome-icon :icon="entry.is_attended ? 'circle' : 'times'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="todayAbnormalAttendance"
      width="90rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:42rem;"
      >
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div
            style="height:36rem;width:86.25rem;"
          >
            <div
              class="stats-close-btn"
              @click="todayAbnormalAttendance = false"
            />
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">今日异常出勤数：{{statsData.attendance_err_count}}
            </div>

            <div style="margin:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:5rem">序号</div>
                <div style="width:8rem;text-align:center">安全帽编号</div>
                <div style="width:5rem">安全帽颜色</div>
                <div style="width:8rem">今日出勤时长（h）</div>
                <div style="width:8rem">领用人</div>
                <div style="width:8rem">领用人手机号</div>
                <div style="width:12rem;text-align:center">领用人所属共建方</div>
                <div style="width:10rem;text-align:center">所属班组</div>
              </div>
              <div style="height:32rem;overflow:auto;">
                <div
                  v-for="(item, index) in todayAbnormalAttendanceTable"
                  :key="item.trouble_id"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="display:flex; height: 3rem; align-items: center; justify-content: space-between; padding:0 1rem">
                    <div style="width:5rem;color:#4fb5da;">
                      {{attendanceSecurityStarPage>1?(attendanceSecurityStarPage-1)*10+index+1:(index+1>9?(index+1):'0'+(index+1))}}
                    </div>
                    <div style="width:8rem;text-align:center">{{item.code}}</div>
                    <div style="width:5rem">{{item.color}}</div>
                    <div style="width:8rem;">{{item.work_hour}}</div>
                    <div style="width:8rem;">{{item.name}}</div>
                    <div style="width:8rem;">{{item.mobile}}</div>
                    <div style="width:12rem;text-align:center">
                      {{item.company_name}}
                    </div>
                    <div style="width:10rem;text-align:center">
                      {{item.team_name}}
                    </div>
                  </div>

                </div>
                <div
                  v-if="!(todayAbnormalAttendanceTable && todayAbnormalAttendanceTable.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无共建方情况
                </div>
              </div>

              <el-pagination
                background
                layout="prev, pager, next"
                :current-page.sync="attendanceSecurityStarPage"
                :page-count="attendanceSecurityStarPages"
                @current-change="attendanceSecurityStarPageChange"
                style="text-align:center;margin-top: 0.5rem;"
              ></el-pagination>
            </div>

          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import wrapper from '../../components/wrap'
  import { project } from '../../api'
  import { SimpleBarChartTemplate, stackedBarChartTemplate, todayPerXChartTemplate,
  todayAbsentWorkersChartTemplate, historyWorkerNumChartTemplate,historyLineChartTemplate } from './barChartTemplates';
  import { applyUpdate } from "../../utils";
  import { attendancePieChartTemplate, mainChartTemplate, popupChartTemplate } from './pieChartTemplates';
  import smallTitle3 from '../../components/small-title3'

  function calcPieChartLegendStyles(numLegends, numColumns, widthInChars) {
    // Assume 360px total width and 340px total height for legends
    const numPerColumn = Math.ceil(numLegends / numColumns);
    let heightPct = 50;
    let fontSizeByHeight = 340 * heightPct / 100 / (2 * numPerColumn - 1);
    if (fontSizeByHeight < 14) {
      heightPct = Math.floor(Math.min(14 / fontSizeByHeight * 0.5, 0.8) * 100);
      fontSizeByHeight = 340 * heightPct / 100 / (2 * numPerColumn - 1);
    }
    fontSizeByHeight = Math.min(Math.floor(fontSizeByHeight), 14);

    if (widthInChars == null) {
      return {
        fontSize: fontSizeByHeight,
        heightPct: heightPct
      };
    } else {
      const fontSizeByWidth = Math.min(Math.floor(360 / widthInChars), 14);
      return {
        fontSize: Math.min(fontSizeByWidth, fontSizeByHeight),
        heightPct: heightPct
      };
    }
  }

  export default {
    name: 'LaborLeftBar',
    components: {
      'v-chart': ECharts,
      wrapper,
      's-title3': smallTitle3,
    },
    props: {
      projectDetail: Object,
      wearMap: Array,
      motionMap: Object,
      batteryMap: Function,
      statusColorMap: Object,
      retrieveStatusMap: Object,
      groupList: Array,
      statsData: Object,
      onlineWorkerErrorCounter: Number,
    },
    watch: {
      onlineWorkerErrorCounter(newValue, oldValue) {
        if (newValue > oldValue) {
          this.abnormalPopupShow();
        }
      },
    },
    data () {
      return {
        recordLists: [],
        attendanceOption: {},
        option: {
          tooltip: {
            trigger: 'axis',
            // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
            formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                  result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + params[0].data;
                  if(params[1]){
                      result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                  }

              }
              return result;
            }
          },
          color: ['#1bd080', '#FBC71E'],
          grid: {
            contentLabel: true,
            left: '12%',
            right: '12%',
            bottom:" 15%",
            top: "13%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true,
              name: ''
            },
            {
              name: '',
              min: 0,
              max: 100,
              interval: 25,
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // },
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value'
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              barWidth: 10,
              data: []
            },
            {
              name: '数量',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                  {offset: 1, color: 'rgba(27, 208, 128, 0)'}
                ])
              },
              symbolSize: 0,
              yAxisIndex: 1,
              data: [],
            }
          ]
        },
        laborAttenOption: {},
        laborAttenPieChartHighlightIndex: 0,
        popupShow: false,
        attendanceOption2: {},
        option2: {
          title: {
            text: '今日各共建方出勤人数统计',
            textStyle: {
              color: '#fff'
            }
          },
          color: ['#F59511'],
          grid: {
            left: '5%',
            right: '1%',
            top: '20%',
            bottom: '1%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
          },
          xAxis: [{
            axisLabel: {
              color: '#e6f2f3',
              rotate: 40,
              fontWeight: 'bold',
              formatter: function (params) {
                var val = "";
                if (params.length > 6) {
                    val = params.substr(0, 6) + '...';
                    return val;
                } else {
                    return params;
                }
              }
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#36445f",
                width: 1,
                type: "solid",
              },
            },
            axisTick: { show: false },
            type: 'category',
            data: []
          }],
          yAxis: [{
            type: 'value',
            show: true,
            name: '人数',
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: {
              show: true,
              lineStyle: { color: '#36445f' }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#36445f',
                type: [1, 1],
                width: 1,
              }
            },
            axisTick: { show: false },
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
              // formatter: function(value) {
              //   return value ? value + '%' : value;
              // }
            }
          }],
          dataZoom:[],
          series: [
            {
              type: 'bar',
              stack: 'samestack',
              barMaxWidth: 9,
              name: '人数',
              data: []
            }
          ]
        },
        sum: 0,
        recordLists2: [],
        securityStarPage: 1,
        securityStarPages: 0,
        popupShow2: false,
        tabPosition: '今日',
        popupChart: null,
        drillValue: '',
        totalAbsentees: 0,
        drillDownAbsentees: 0,
        todayWorkersPerTypeChart: {},
        todayWorkersPerTypeData: [],
        todayWorkersPerTypeDrillDownChart: {},
        todayWorkersPerCompanyChart: {},
        todayWorkersPerCompanyData: [],
        todayWorkersPerCompanyDrillDownChart: {},
        todayWorkersPerAreaChart: {},
        todayWorkersPerAreaData: [],
        todayWorkersPerAreaDrillDownChart: {},
        todayAbsentWorkersChart: {},
        todayAbsentWorkersData: [],
        todayAbsentWorkersDrillDownChart: {},
        historyWorkerNumChart: {},
        historyWorkerPctChart: {},
        historyWorkersPerCompanyChart: {},
        historyAbsentWorkersChart: {},
        popupShow3: false,
        popupShow4: false,
        popupShow5: false,
        popupChartTitle: null,
        drillValue2: '',
        mainCharts: null,
        mainChartData: {},
        mainChartHighlightIndexes: {},
        mainChartShowDetails: {},
        mainChartWidth: 0,
        mainChartHeight: 0,
        popupWorkerTypeChart: {},
        popupTeamChart: {},
        popupWorkerInfo: [],
        filteredPopupWorkerInfo: [],
        timer: null,
        todayAbnormalAttendance:false,
        todayAbnormalAttendanceTable:[],
        attendanceSecurityStarPage: 1,
        attendanceSecurityStarPages: 0,
      }
    },
    mounted () {
      // const self = this
      const { id: projectID } = this.$route.params;
      this.projectId = projectID;
      const token = this.$route.query.token;
      window.token = token;

      // this.projectId = '48146e7e-b027-4bf4-8520-0d935405b681';

      project.getDashProjLaborInoutCollect(1, 5, this.projectId)
        .then(d => {
          // console.log(d);
          this.recordLists = d;
        })

      this.timer = setInterval(() => {
        project.getDashProjLaborInoutCollect(1, 5, this.projectId)
          .then(d => {
            // console.log(d);
            this.recordLists = d;
          })
      }, 10000)

      project.getDashProjLaborAttenceDate7Collect(this.projectId)
        .then(d => {
          // console.log(d);
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option.legend.data = d.legend;
          this.option.xAxis[0].data = d.xAxis_data;
          this.option.series[0].data = d.bar_series;
          this.option.series[0].name = d.legend[0];
          this.option.yAxis[0].name = d.legend[0];
          this.option.series[1].data = d.line_series;
          this.option.series[1].name = d.legend[1];
          this.option.yAxis[1].name = `${d.legend[1]}：%`;
          this.attendanceOption = applyUpdate(chart, this.option);
        })

      project.getDashProjLaborAttenceDate10Collect(this.projectId)
        .then(ddata => {
          let d = ddata.data;
          let chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
          chart.series[0].label.normal.formatter = attendancePieChartTemplate.series[0].label.normal.formatter;

          const maxPieces = 14;
          if (d.length > maxPieces) {
            d[maxPieces - 1].value = d.slice(maxPieces - 1).reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
            d[maxPieces - 1].name = '其它';
            d = d.slice(0, maxPieces);
          }
          chart.series[0].data = d.map(x => ({
            name: x.name,
            value: x.value
          }));
          if (window.innerWidth < 2250) {
            chart.series[0].radius = [35, 65];
          }

          // 默认高亮第一个值不是0的分片，否则默认第一片
          this.laborAttenPieChartHighlightIndex = 0;
          for (let i = 0; i < d.length; i++) {
            if (d[i].people_count) {
              this.laborAttenPieChartHighlightIndex = i;
              break;
            }
          }

          chart.series.push(Object.assign({}, chart.series[0], { label: {
            fontSize: 11,
            formatter: '{b}：{c}人',
          }, silent: true }));

          setTimeout(
            () =>
              this.$refs.laborAttenType &&
              this.$refs.laborAttenType.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: this.laborAttenPieChartHighlightIndex
              }),
            100
          );
          this.laborAttenOption = chart;
        })
    },
    methods: {
      abnormalPopupShow(){
        this.todayAbnormalAttendance = true;
        this.getTodayAbnormalAttendanceTable()
      },
    getTodayAbnormalAttendanceTable() {
      project
        .getDashProjWorkerAttendanceList(
          this.attendanceSecurityStarPage,
          10,
        )
        .then((d) => {
          this.todayAbnormalAttendanceTable = d;
          this.attendanceSecurityStarPages = Math.ceil(d.__count__ / 10);
        });
    },
      attendanceSecurityStarPageChange(currPage) {
        this.attendanceSecurityStarPage=currPage;
          this.getTodayAbnormalAttendanceTable()
      },
      securityStarPageChange(currPage) {
        project.getDashProjLaborInoutCollect(currPage, 5, this.projectId)
          .then(d => {
            // console.log(d);
            this.recordLists2 = d;
            this.securityStarPages = Math.ceil(d.__count__ / 5);
          })
      },
      laborAttenMouseOver(params) {
        if (params.dataIndex != this.laborAttenPieChartHighlightIndex) {
          this.$refs.laborAttenType.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.laborAttenPieChartHighlightIndex
          });
          this.laborAttenPieChartHighlightIndex = params.dataIndex;
        }
      },
      laborAttenMouseOut() {
        this.$refs.laborAttenType.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.laborAttenPieChartHighlightIndex
        });
      },
      showDetail(val){
        // eslint-disable-next-line no-empty
        if(val === 1){
          this.popupShow = true;
          this.securityStarPage=1;
          project.getDashProjLaborAttendanceCompanyCollect(100000, this.projectId, 3)
            .then(d => {
              // console.log(d);
              const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
              this.option2.xAxis[0].data = [];
              this.option2.series[0].data = [];
              this.sum = 0;
              d.forEach(v => {
                this.option2.xAxis[0].data.push(v.company_name)
                this.option2.series[0].data.push(v.count)
                this.sum += v.count;
              })
              this.attendanceOption2 = applyUpdate(chart, this.option2);
            })

          this.securityStarPageChange(1);
        // eslint-disable-next-line no-empty
        }else if(val === 2){
         this.popupShow2 = true;
         this.updateTodayCharts();
        // eslint-disable-next-line no-empty
        }else if(val === 3){
          this.popupShow4 = true;
          project
            .getProjAttendance10Days(this.projectId)
            .then(d => {
              if (d.length <= 4) {
                this.mainChartWidth = 50;
                this.mainChartHeight = 22.2;
              } else if (d.length <= 9) {
                this.mainChartWidth = 31.5;
                this.mainChartHeight = 14.8;
              } else {
                this.mainChartWidth = 22.1;
                this.mainChartHeight = 14.8;
              }
              this.mainCharts = d.map((item, idx) => {
                let chart = JSON.parse(JSON.stringify(mainChartTemplate));
                chart.chartTitle = `近10日${item.company_id ? item.company_name : '所有劳务'}出勤天数分布`;
                this.mainChartData[chart.chartTitle] = item;
                chart.series[0].data = item.worker_info.map(x => ({
                  name: x.day_count + '天',
                  value: x.worker_count
                }));

                // 默认高亮第一个值不是0的分片，否则默认第一片
                this.mainChartHighlightIndexes[chart.chartTitle] = 0;
                for (let i = 0; i < item.worker_info.length; i++) {
                  if (item.worker_info[i].worker_count) {
                    this.mainChartHighlightIndexes[chart.chartTitle] = i;
                    break;
                  }
                }

                setTimeout(
                  () =>
                    this.$refs['mainChart' + chart.chartTitle][0] &&
                    this.$refs['mainChart' + chart.chartTitle][0].dispatchAction({
                      type: 'highlight',
                      seriesIndex: 0,
                      dataIndex: this.mainChartHighlightIndexes[chart.chartTitle],
                    }),
                  100 + idx * 30
                );

                return chart;
              });
            })
            .catch(e => console.error(e.msg || e.message))
        }
      },
      onTabChange(tab) {
        if (tab === '今日') {
          this.updateTodayCharts();
        } else {
          this.updateHistoryCharts();
        }
      },
      chartClick(chart, clicked) {
        // const { id: projectID } = this.$route.params;
        this.popupShow3 = true;
        this.popupChart = chart;
        this.drillValue = clicked.name;
        const data = this[chart + 'Data'];
        if (chart == 'todayWorkersPerType') {
          project
            .getProjLaborTodayWorkersPerType(this.projectId, JSON.stringify({
              worker_type_one: data[clicked.dataIndex].work_type_id
            }))
            .then(d => {
              let chart = JSON.parse(JSON.stringify(todayPerXChartTemplate));
              chart.xAxis.data = d.map(item => item.work_type);
              chart.series[0].data = d.map(item => item.worker_count);
              chart.series[1].data = d.map(item => item.plan_count);
              chart.series[2].data = d.map(item => item.avg_check_hours);
              this.todayWorkersPerTypeDrillDownChart = chart;
            })
            .catch(e => console.error(e.msg || e.message))
        } else if (chart == 'todayWorkersPerCompany') {
          project
            .getProjTodayWorkersPerTeam(this.projectId, JSON.stringify({
              company_id: data[clicked.dataIndex].company_id
            }))
            .then(d => {
              let chart = JSON.parse(JSON.stringify(todayPerXChartTemplate));
              chart.xAxis.name = '班组';
              chart.xAxis.axisLabel.rotate = 20;
              chart.xAxis.data = d.map(item => item.team_name);
              chart.series[0].data = d.map(item => item.worker_count);
              chart.series[1].data = d.map(item => item.plan_count);
              chart.series[2].data = d.map(item => item.avg_check_hours);
              this.todayWorkersPerCompanyDrillDownChart = chart;
            })
            .catch(e => console.error(e.msg || e.message))
        } else if (chart == 'todayWorkersPerArea') {
          project
            .getProjLaborTodayWorkersPerArea(this.projectId, JSON.stringify({
              build_id: data[clicked.dataIndex].build_id
            }))
            .then(d => {
              let chart = JSON.parse(JSON.stringify(todayPerXChartTemplate));
              chart.xAxis.name = '区域';
              chart.xAxis.data = d.map(item => item.area_name);
              chart.series[0].data = d.map(item => item.worker_count);
              chart.series[1].data = d.map(item => item.plan_count);
              chart.series[2].data = d.map(item => item.avg_check_hours);
              this.todayWorkersPerAreaDrillDownChart = chart;
            })
            .catch(e => console.error(e.msg || e.message))
        } else if (chart == 'todayAbsentWorkers') {
          project
            .getProjTodayAbsentWorkersPerTeam(this.projectId, JSON.stringify({
              company_id: data[clicked.dataIndex].company_id
            }))
            .then(d => {
              this.drillDownAbsentees = d[0] ? d[0].total_noattendacne_count : 0;
              let chart = JSON.parse(JSON.stringify(todayAbsentWorkersChartTemplate));
              chart.xAxis.name = '班组';
              chart.xAxis.axisLabel.rotate = 20;
              chart.xAxis.data = d.map(item => item.team_name);
              chart.series[0].data = d.map(item => item.worker_count);
              chart.series[1].data = d.map(item => item.noattendacne_count);
              this.todayAbsentWorkersDrillDownChart = chart;
            })
            .catch(e => console.error(e.msg || e.message))
        }
      },
      cleanupPopup() {
        this.popupShow3 = false;
        if (this.popupChart == 'todayWorkersPerType') {
          this.todayWorkersPerTypeDrillDownChart = {};
        } else if (this.popupChart == 'todayWorkersPerCompany') {
          this.todayWorkersPerCompanyDrillDownChart = {};
        } else if (this.popupChart == 'todayWorkersPerArea') {
          this.todayWorkersPerAreaDrillDownChart = {};
        } else if (this.popupChart == 'todayAbsentWorkers') {
          this.todayAbsentWorkersDrillDownChart = {};
        }
      },
      updateTodayCharts() {
        // const { id: projectID } = this.$route.params;
        project
          .getProjTodayWorkersPerType(this.projectId, '')
          .then(d => {
            this.todayWorkersPerTypeData = d;
            let chart = JSON.parse(JSON.stringify(todayPerXChartTemplate));
            chart.xAxis.data = d.map(item => item.work_type);
            chart.series[0].data = d.map(item => item.worker_count);
            chart.series[1].data = d.map(item => item.plan_count);
            chart.series[2].data = d.map(item => item.avg_check_hours);
            this.todayWorkersPerTypeChart = chart;
          })
          .catch(e => console.error(e.msg || e.message))
        project
          .getProjTodayWorkersPerCompany(this.projectId)
          .then(d => {
            this.todayWorkersPerCompanyData = d;
            let chart = JSON.parse(JSON.stringify(todayPerXChartTemplate));
            chart.xAxis.name = '共建方';
            chart.xAxis.axisLabel.rotate = 20;
            chart.grid.left = '12%';
            chart.xAxis.data = d.map(item => item.company_name);
            chart.series[0].data = d.map(item => item.worker_count);
            chart.series[1].data = d.map(item => item.plan_count);
            chart.series[2].data = d.map(item => item.avg_check_hours);
            this.todayWorkersPerCompanyChart = chart;
          })
          .catch(e => console.error(e.msg || e.message))
        project
          .getProjTodayWorkersPerArea(this.projectId, '')
          .then(d => {
            this.todayWorkersPerAreaData = d;
            let chart = JSON.parse(JSON.stringify(todayPerXChartTemplate));
            chart.xAxis.name = '区域';
            chart.xAxis.data = d.map(item => item.build_name);
            chart.series[0].data = d.map(item => item.worker_count);
            chart.series[1].data = d.map(item => item.plan_count);
            chart.series[2].data = d.map(item => item.avg_check_hours);
            this.todayWorkersPerAreaChart = chart;
          })
          .catch(e => console.error(e.msg || e.message))
        project
          .getProjTodayAbsentWorkersPerCompany(this.projectId)
          .then(d => {
            this.todayAbsentWorkersData = d;
            this.totalAbsentees = d[0] ? d[0].total_noattendacne_count : 0;
            let chart = JSON.parse(JSON.stringify(todayAbsentWorkersChartTemplate));
            chart.xAxis.axisLabel.rotate = 20;
            chart.grid.left = '12%';
            chart.xAxis.data = d.map(item => item.company_name);
            chart.series[0].data = d.map(item => item.worker_count);
            chart.series[1].data = d.map(item => item.noattendacne_count);
            this.todayAbsentWorkersChart = chart;
          })
          .catch(e => console.error(e.msg || e.message))
      },
      updateHistoryCharts() {
        // const { id: projectID } = this.$route.params;
        project
          .getProjHistoryWorkerNumData(this.projectId)
          .then(d => {
            let chart = JSON.parse(JSON.stringify(historyWorkerNumChartTemplate));
            chart.xAxis.data = d.map(item => item.check_date);
            chart.series[0].data = d.map(item => item.plan_count);
            chart.series[1].data = d.map(item => item.attendacne_count);
            chart.series[2].data = d.map(item => item.noattendacne_count);
            this.historyWorkerNumChart = chart;
          })
          .catch(e => console.error(e.msg || e.message))
        project
          .getProjHistoryWorkerDataPerCompany(this.projectId)
          .then(d => {
            let chart = JSON.parse(JSON.stringify(historyLineChartTemplate));
            chart.legend.data = d.map(item => item.company_name);
            chart.xAxis.data = d[0] ? d[0].worker_info.map(item => item.check_date) : [];
            chart.yAxis.name = '出勤率';
            chart.series = d.map(item => Object.assign({}, historyLineChartTemplate.series[0], {
              name: item.company_name,
              data: item.worker_info.map(x => x.attendacne_rate),
            }));
            this.historyWorkerPctChart = chart;

            chart = JSON.parse(JSON.stringify(historyLineChartTemplate));
            chart.legend.data = d.map(item => item.company_name);
            chart.xAxis.data = d[0] ? d[0].worker_info.map(item => item.check_date) : [];
            chart.yAxis.max = Math.max.apply(null, d[0] ? d[0].worker_info.map(item => item.worker_count) : []);
            chart.series = d.map(item => Object.assign({}, historyLineChartTemplate.series[0], {
              name: item.company_name,
              data: item.worker_info.map(x => x.attendacne_count),
            }));
            this.historyWorkersPerCompanyChart = chart;

            chart = JSON.parse(JSON.stringify(historyLineChartTemplate));
            chart.legend.data = d.map(item => item.company_name);
            chart.xAxis.data = d[0] ? d[0].worker_info.map(item => item.check_date) : [];
            chart.yAxis.max = Math.max.apply(null, d[0] ? d[0].worker_info.map(item => item.worker_count) : []);
            chart.series = d.map(item => Object.assign({}, historyLineChartTemplate.series[0], {
              name: item.company_name,
              data: item.worker_info.map(x => x.noattendacne_count),
            }));
            this.historyAbsentWorkersChart = chart;
          })
          .catch(e => console.error(e.msg || e.message))
      },
      chartClick2(chartTitle, clicked) {
        // const { id: projectID } = this.$route.params;
        this.popupShow5 = true;
        this.popupChartTitle = chartTitle;
        this.drillValue2 = clicked.name;
        const data = this.mainChartData[chartTitle];

        project
          .getProjAttendance10DaysPerWorkerType(this.projectId, JSON.stringify({
            company_id: data.company_id,
            day_count: this.drillValue2.slice(0, this.drillValue2.length - 1),
          }))
          .then(d => {
            let chart = JSON.parse(JSON.stringify(popupChartTemplate));
            chart.series[0].data = d.map(x => ({
              name: x.work_type,
              value: x.worker_count
            }));
            chart.legend.data = d.map(x => x.work_type);

            const workerTypeMap = {};
            let maxTypeLen = 0;
            d.forEach(x => {
              workerTypeMap[x.work_type] = x.worker_count;
              if (x.work_type.length > maxTypeLen) {
                maxTypeLen = x.work_type.length;
              }
            });
            chart.legend.formatter = (name) => {
              let fillStr = '';
              for (let i = 0; i < maxTypeLen + 1 - name.length; i++) {
                fillStr += '空';
              }
              return `{a|${name}}{c|${fillStr}}{b|${workerTypeMap[name]}}`;
            };

            // We allow max 2 columns for legends.
            let styles = calcPieChartLegendStyles(chart.legend.data.length, 2, (maxTypeLen + 5) * 2 + 1);
            const styles2 = calcPieChartLegendStyles(chart.legend.data.length, 1, maxTypeLen + 5);
            if (styles.fontSize < styles2.fontSize) {
              styles = styles2;
            }
            chart.legend.height = styles.heightPct + '%';
            chart.legend.itemWidth = Math.round(styles.fontSize * 0.85);
            chart.legend.itemHeight = Math.round(styles.fontSize * 0.85);
            chart.legend.itemGap = styles.fontSize;
            chart.legend.textStyle.rich.a.fontSize = styles.fontSize;
            chart.legend.textStyle.rich.b.fontSize = styles.fontSize;
            chart.legend.textStyle.rich.c.fontSize = styles.fontSize;

            this.popupWorkerTypeChart = chart;
          })
          .catch(e => console.error(e.msg || e.message));

        let methodName, fieldName;
        if (data.company_id) {
          // per team
          methodName = 'getProjAttendance10DaysPerTeam';
          fieldName = 'team_name';
        } else {
          // per company
          methodName = 'getProjAttendance10DaysPerCompany';
          fieldName = 'company_name';
        }

        project[methodName](this.projectId, JSON.stringify({
            company_id: data.company_id,
            day_count: this.drillValue2.slice(0, this.drillValue2.length - 1),
          }))
          .then(d => {
            let chart = JSON.parse(JSON.stringify(popupChartTemplate));
            chart.series[0].data = d.map(x => ({
              name: x[fieldName],
              value: x.worker_count
            }));
            chart.legend.data = d.map(x => x[fieldName]);

            chart.series[0].center[0] = '25%';
            chart.legend.left = '48%';
            if (fieldName == 'company_name') {
              chart.series[0].radius[0] = '55%';
            }

            const workerTypeMap = {};
            let maxTypeLen = 0;
            d.forEach(x => {
              workerTypeMap[x[fieldName]] = x.worker_count;
              if (x[fieldName].length > maxTypeLen) {
                maxTypeLen = x[fieldName].length;
              }
            });
            chart.legend.formatter = (name) => {
              let fillStr = '';
              for (let i = 0; i < maxTypeLen + 1 - name.length; i++) {
                fillStr += '空';
              }
              return `{a|${name}}{c|${fillStr}}{b|${workerTypeMap[name]}}`;
            };

            // We allow max 2 columns for legends.
            let styles = calcPieChartLegendStyles(chart.legend.data.length, 2, (maxTypeLen + 5) * 2 + 1);
            const styles2 = calcPieChartLegendStyles(chart.legend.data.length, 1, maxTypeLen + 5);
            if (styles.fontSize < styles2.fontSize) {
              styles = styles2;
            }
            chart.legend.height = styles.heightPct + '%';
            chart.legend.itemWidth = Math.round(styles.fontSize * 0.85);
            chart.legend.itemHeight = Math.round(styles.fontSize * 0.85);
            chart.legend.itemGap = styles.fontSize;
            chart.legend.textStyle.rich.a.fontSize = styles.fontSize;
            chart.legend.textStyle.rich.b.fontSize = styles.fontSize;
            chart.legend.textStyle.rich.c.fontSize = styles.fontSize;

            this.popupTeamChart = chart;
          })
          .catch(e => console.error(e.msg || e.message));

        project
          .getProjAttendance10DaysWorkerList(this.projectId, JSON.stringify({
            company_id: data.company_id,
            day_count: this.drillValue2.slice(0, this.drillValue2.length - 1),
          }))
          .then(d => {
            this.popupWorkerInfo = d;
            this.filteredPopupWorkerInfo = d;
          })
          .catch(e => console.error(e.msg || e.message));
      },
      cleanupPopup2() {
        this.popupWorkerTypeChart = {};
        this.popupTeamChart = {};
        this.popupWorkerInfo = [];
        this.filteredPopupWorkerInfo = [];
      },
      mainChartMouseOver(chartTitle, params, noChangeHighlightIndex) {
        if (params.dataIndex != this.mainChartHighlightIndexes[chartTitle]) {
          let component = this.$refs['mainChart' + chartTitle];
          if (Array.isArray(component)) {
            component = component[0];
          }
          if (this.mainChartHighlightIndexes[chartTitle] != null) {
            component.dispatchAction({
              type: 'downplay',
              seriesIndex: 0,
              dataIndex: this.mainChartHighlightIndexes[chartTitle]
            });
          }

          if (!noChangeHighlightIndex) {
            this.mainChartHighlightIndexes[chartTitle] = params.dataIndex;

            if (chartTitle.slice(0, 5) == 'popup') {
              let otherChartTitle, filterType;
              if (chartTitle == 'popupWorkerTypeChart') {
                filterType = 'workerType';
                otherChartTitle = 'popupTeamChart';
              } else {
                filterType = 'team';
                otherChartTitle = 'popupWorkerTypeChart';
              }
              this.filteredPopupWorkerInfo = this.filterPopupWorkerInfo(filterType, params.name);

              let comp = this.$refs['mainChart' + otherChartTitle];
              if (Array.isArray(comp)) {
                comp = comp[0];
              }
              if (this.mainChartHighlightIndexes[otherChartTitle] != null) {
                comp.dispatchAction({
                  type: 'downplay',
                  seriesIndex: 0,
                  dataIndex: this.mainChartHighlightIndexes[otherChartTitle]
                });
                this.mainChartHighlightIndexes[otherChartTitle] = null;
              }
            }
          }
        } else if (chartTitle.slice(0, 5) == 'popup' && !noChangeHighlightIndex) {
          let component = this.$refs['mainChart' + chartTitle];
          if (Array.isArray(component)) {
            component = component[0];
          }
          component.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.mainChartHighlightIndexes[chartTitle]
          });
          this.mainChartHighlightIndexes[chartTitle] = null;
          this.filteredPopupWorkerInfo = this.filterPopupWorkerInfo('', '');
        }
      },
      filterPopupWorkerInfo(filterType, filterValue) {
        if (filterType == 'workerType') {
          return this.popupWorkerInfo.filter(item => item.work_type == filterValue);
        } else if (filterType == 'team') {
          if (this.mainChartData[this.popupChartTitle].company_id) {
            return this.popupWorkerInfo.filter(item => item.team_name == filterValue);
          } else {
            return this.popupWorkerInfo.filter(item => item.company_name == filterValue);
          }
        } else {
          return this.popupWorkerInfo;
        }
      },
      mainChartMouseOut(chartTitle) {
        let component = this.$refs['mainChart' + chartTitle];
        if (Array.isArray(component)) {
          component = component[0];
        }
        if (this.mainChartHighlightIndexes[chartTitle] != null) {
          component.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: this.mainChartHighlightIndexes[chartTitle]
          });
        }
      },
      toggleMainChartDetail(chartTitle) {
        const chartIdx = this.mainCharts.findIndex(x => x.chartTitle == chartTitle);
        if (chartIdx >= 0) {
          this.mainChartShowDetails[chartTitle] = !this.mainChartShowDetails[chartTitle];
          this.mainCharts[chartIdx].series = [this.mainCharts[chartIdx].series[0]];
          if (this.mainChartShowDetails[chartTitle]) {
            this.mainCharts[chartIdx].series.push(Object.assign({}, this.mainCharts[chartIdx].series[0], { label: {
              formatter: '{b} {c}人',
            }, silent: true }));
          }

          this.mainChartShowDetails = Object.assign({}, this.mainChartShowDetails);
          this.mainCharts[chartIdx] = Object.assign({}, this.mainCharts[chartIdx]);
        }
      }
    }
  }
</script>

<style scoped lang="less">
#project-base-info {
  p {
    font-size: 0.875rem;
    line-height: 1.1rem;
    margin: 0.5rem 0;
    display: flex;

    .title {
      width: 4.6rem;
      color: #5accf1;
    }

    .value {
      flex: 1;
      text-align: right;
      color: #ffffff;
    }
  }
}

#people-status {
  #retreat-btn {
    margin: 1rem 0;
    height: 1.875rem;
    text-align: center;
    line-height: 1.875rem;
    cursor: pointer;
    background: linear-gradient(to right, #51bbf5, #5170f5);
  }

  .group {
    height: 31.2rem;
    overflow-y: scroll;

    .group-item {
      .group-title {
        margin-bottom: 1rem;

        .group-title-icon {
          width: 1rem;
          height: 1rem;
          display: inline-block;
          margin-right: 0.25rem;
          cursor: pointer;
          position: relative;

          &.active {
            transform: rotate(-90deg);
          }

          &::before {
            content: " ";
            position: absolute;
            width: 1rem;
            font-size: 1rem;
            transform: rotate(0deg);
            transition: transform .3s ease-in-out;
            height: 1rem;
            background: url("../../assets/sanjiao.png") no-repeat;
          }
        }
      }

      .people-card-list {
        .card-item {
          padding: 0.4rem 1rem;
          display: flex;
          margin-bottom: 0.75rem;
          background-color: #1e2c61;
          border-radius: 0.25rem;

          .left {
            flex: 1;
            font-size: 0.88rem;
            line-height: 1.7rem;

            .title {
              color: #999999;
            }
          }

          .right {
            width: 3.8rem;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .status-card {
              border-width: 0.5rem 1px 1px;
              border-style: solid;
              border-radius: .25rem;
              margin-bottom: 1rem;
              padding: .7rem;

              .zt {
                font-size: 0.88rem;
                color: #999;
                padding: 3px 0;
              }

              .status-text {
                padding-top: 5px;
                font-size: 1.13rem;
                font-weight: bold;
              }
            }

            .op {
              width: 100%;
              height: 1.5rem;
              background: linear-gradient(90deg, rgba(81, 189, 245, 1), rgba(81, 112, 245, 1));
              line-height: 1.5rem;
              cursor: pointer;
              font-size: 0.75rem;
              text-align: center;

              &.disabled {
                background: #8a8a8a;
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }
  }
}

.empty-chart-icon {
  width: 20.4rem;
  height: 15rem;
  background: url("../../assets/empty_chart.png") no-repeat;
  background-size: contain;
}

.stats-chart-block {
  height: 24.5rem;
  position: relative;
  border: 1px solid #334c88;
  background-image: url("../../assets/stats-bg.png");
}

.popup-bg {
  background-image: url("../../assets/stats-bg.png");
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}

.custom-select .selected {
  background-color: #0c1937;
  color: #12a0a2;
  font-weight: 300;
}
.custom-select .selected:after {
  border-color: #12a0a2 transparent transparent transparent;
}
.custom-select .items {
  color: #12a0a2;
  font-weight: 300;
  background-color: #0c1937;
}
.custom-select .items div {
  color: #12a0a2;
  font-weight: 300;
}
</style>

<style>
.el-radio-button__orig-radio:checked + .el-radio-button__inner{
  color: #fff !important;
  background-color: #00adff !important;
  border-color: #00adff !important;
}
.el-radio-button:last-child .el-radio-button__inner, .el-radio-button:first-child .el-radio-button__inner{
  background-color: #fff;
  color: #222933;
}
.stats-layer .el-dialog__header {
  display: none;
}
.stats-layer .el-dialog__body {
  padding: 0;
  color: unset;
}
.stats-layer .custom-select .selected {
  background-color: #0c1937;
  color: #12a0a2;
  font-weight: 300;
}
.stats-layer .custom-select .selected:after {
  border-color: #12a0a2 transparent transparent transparent;
}
.stats-layer .custom-select .items {
  color: #12a0a2;
  font-weight: 300;
  background-color: #0c1937;
}
.stats-layer .custom-select .items div {
  color: #12a0a2;
  font-weight: 300;
}
</style>