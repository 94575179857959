<template>
  <div id="left">
    <wrapper>
      <div style="display:flex;align-items:center">
        <div style="font-size:1em;flex:1">近七日现场管理统计</div>
        <div class="option-block" style="font-size:0.7rem;min-width:0" :class="{ 'option-block-selected': mgmStatsOption == 'all' }" @click="() => switchMgmStatsOption('all')">全员</div>
        <div class="option-block" style="font-size:0.7rem;min-width:0" :class="{ 'option-block-selected': mgmStatsOption == 'mgm' }" @click="() => switchMgmStatsOption('mgm')">领导</div>
      </div>
      <div style="max-height:23rem;min-height:12rem;padding-top:0.8rem">
        <div style="width:100%;display:flex;justify-content:space-between;align-items:center;margin-bottom:0.5rem">
          <CustomSelect :options="deptNameList" @input="deptSelect" style="width:6rem;line-height:1.6rem;font-size:0.7rem;font-weight:bold" />
          <div class="option-block option-block-selected" style="font-size:0.7rem;min-width:0" @click="showMgmDetail">详情</div>
        </div>
        <div style="height:11.5rem;line-height:1.6rem;font-size:0.9rem;color:#bfcfd2;font-weight:bold;overflow:hidden;margin-bottom:-0.5rem">
          <v-chart :options="mgmStatsBarChart" autoresize />
        </div>
      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem">
      <div style="font-size:1em">安全行为之星</div>
      <div style="height:14rem;margin:0.5rem 0;padding:0;display:flex;flex-direction:column;font-size:0.7rem;overflow:hidden">
        <div v-for="(star, index) in securityStarList" :key="star.innerid" :style="{ background: index%2 ? '#1b2d5d' : '#152554', height: '1.6rem', 'line-height': '1.6rem', display: 'flex', justifyContent: 'space-between' }">
          <div style="flex:1;margin:0 0.5rem">{{index + 1}}</div>
          <div style="flex:4;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{star.name}}</div>
          <div style="flex:6;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{star.team_name}}</div>
          <div style="flex:2;margin-right:0.5rem;text-align:right"><a @click="() => showWorkerInfo(star.innerid)" style="color:#48b6e2;text-decoration:underline;cursor:pointer">{{star.total_source}}分</a></div>
        </div>
      </div>
      <div style="width:100%;text-align:right"><a @click="showMoreStars" style="font-size:0.9rem;color:#48b6e2;text-decoration:underline;cursor:pointer">更多>></a></div>
    </wrapper>
    <wrapper style="margin-top: 1rem">
      <div style="font-size:1em">今日劳务进出场记录</div>
      <div style="height:14rem;margin:0.5rem 0;padding:0;display:flex;flex-direction:column;font-size:0.7rem;overflow:hidden">
        <div v-for="(worker, index) in workerInOutList" :key="worker.worker_id + worker.check_time" :style="{ background: index%2 ? '#1b2d5d' : '#152554', height: '1.6rem', 'line-height': '1.6rem', display: 'flex', justifyContent: 'space-between' }">
          <div style="flex:2;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{worker.worker_name}}</div>
          <div style="flex:3;text-align:center;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{worker.team_name}}</div>
          <div :style="{flex:4, marginRight:'0.5rem', textAlign:'right', color: worker.type == 1 ? '#20ae53' : '#ba440d'}">{{worker.type == 1 ? '进场' : '出场'}} {{worker.check_time.slice(5, -3)}}</div>
        </div>
      </div>
      <div style="width:100%;text-align:right"><a @click="showMoreAttendance" style="font-size:0.9rem;color:#48b6e2;text-decoration:underline;cursor:pointer">更多>></a></div>
    </wrapper>
    <el-dialog custom-class="stats-layer" :visible.sync="mgmDetailShow" width="1500px" top="3rem">
      <wrapper style="height:985px;backgroundColor:#0e1328;position:relative">
        <iframe ref="mgmDetailIframe" :src="`http://smarthat.comprehend.com.cn/html/screen/manager_attendance_collect.html?project_id=${$route.params.id}&token=${$route.query.token}`" width="1500px" height="985px" style="border:0;position:absolute;left:0;top:0"></iframe>
        <div class="stats-close-btn" @click="mgmDetailShow = false" />
      </wrapper>
    </el-dialog>
    <el-dialog custom-class="stats-layer" :visible.sync="mgmSubWindowShow" width="1200px" top="18rem">
      <wrapper style="height:370px;backgroundColor:#0e1328;position:relative">
        <iframe ref="mgmDetailIframe" :src="mgmSubWindowUrl" width="1200px" height="370px" style="border:0;position:absolute;left:0;top:0"></iframe>
        <div class="stats-close-btn" @click="mgmSubWindowShow = false" />
      </wrapper>
    </el-dialog>
    <el-dialog custom-class="stats-layer" :visible.sync="personalInfoPopupShow" width="1500px" top="15rem">
      <wrapper style="height:622px;backgroundColor:#0e1328;position:relative">
        <iframe :src="personalInfoPopupShow ? `http://smarthat.comprehend.com.cn/html/screen/worker_detail.html?worker_id=${personalInfoWorkerId}&type_index=${personalInfoTabIndex}&token=${$route.query.token}` : ''" width="1500px" height="622px" style="border:0;position:absolute;left:0;top:0"></iframe>
        <div class="stats-close-btn" @click="personalInfoPopupShow = false" />
      </wrapper>
    </el-dialog>
    <el-dialog custom-class="stats-layer" :visible.sync="securityStarPopupShow" width="50rem" top="15rem">
      <wrapper style="height:32.5rem;backgroundColor:#0e1328;position:relative">
        <div class="stats-close-btn" @click="securityStarPopupShow = false" />
        <div style="margin:1rem;padding-top:1rem;background-color:#051236">
          <div style="font-size:1rem;margin-left:1rem;">安全行为之星</div>
          <div class="stats-chart-block" style="height:26rem;width:46.75rem;">
            <div style="margin:1rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:3rem">序号</div>
                <div style="width:6rem">姓名</div>
                <div style="width:10rem">班组</div>
                <div style="width:3rem">得分</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div v-for="(item, index) in securityStarInfo" :key="item.worker_id" style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem" :style="{ background: index%2 ? '#102855' : '#213a77'}">
                  <div style="width:3rem">{{(securityStarPage - 1) * 10 + index + 1}}</div>
                  <div style="width:6rem">{{item.name}}</div>
                  <div style="width:10rem">{{item.team_name}}</div>
                  <div style="width:3rem">{{item.total_source}}</div>
                </div>
              </div>
            </div>
            <el-pagination background layout="prev, pager, next" :current-page.sync="securityStarPage" :page-count="securityStarPages" @current-change="securityStarPageChange" style="text-align:center"></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog custom-class="stats-layer" :visible.sync="inOutPopupShow" width="50rem" top="5rem" @closed="cleanupInOutPopup">
      <wrapper style="height:57rem;backgroundColor:#0e1328;position:relative">
        <div class="stats-close-btn" @click="inOutPopupShow = false" />
        <div style="font-size:1.2rem;margin-top:1rem;margin-left:1rem;">今日劳务出勤总人数：{{projectDetail.checkinCount}}人</div>
        <div style="margin:1rem;padding-top:1rem;background-color:#051236">
          <div style="font-size:1rem;margin-left:1rem;">今日各共建方出勤人数统计</div>
          <div style="width:46rem;height:19rem"><v-chart :options="companyAttendanceBarChart" autoresize /></div>
        </div>
        <div style="margin:1rem;padding-top:1rem;background-color:#051236">
          <div style="font-size:1rem;margin-left:1rem;">今日劳务进出场记录</div>
          <div class="stats-chart-block" style="height:26rem;width:46.75rem;">
            <div style="margin:1rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:3rem">序号</div>
                <div style="width:6rem">姓名</div>
                <div style="width:10rem">班组</div>
                <div style="width:10rem">进出场时间</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div v-for="(item, index) in workerInOutInfo" :key="item.worker_id + item.check_time" style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem" :style="{ background: index%2 ? '#102855' : '#213a77'}">
                  <div style="width:3rem">{{(workerInOutPage - 1) * 10 + index + 1}}</div>
                  <div style="width:6rem">{{item.worker_name}}</div>
                  <div style="width:10rem">{{item.team_name}}</div>
                  <div :style="{width: '10rem', color: item.type == 1 ? '#20ae53' : '#ba440d'}">{{item.type == 1 ? '进场' : '出场'}} {{item.check_time.slice(5)}}</div>
                </div>
              </div>
            </div>
            <el-pagination background layout="prev, pager, next" :current-page.sync="workerInOutPage" :page-count="workerInOutPages" @current-change="workerInOutPageChange" style="text-align:center"></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { project } from '../../api'
  import CustomSelect from '../../components/select'
  import wrapper from '../../components/wrap'
  import { SimpleBarChartTemplate }  from './barChartTemplates';

  export default {
    name: 'EmergencyLeftBar',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
      wrapper,
    },
    props: {
      projectDetail: Object,
      showTrace: Function,
    },
    data () {
      return {
        projectId: null,
        deptNameList: ['全部部门'],
        deptIdList: [''],
        mgmStatsOption: 'all',
        mgmStatsSelectedDeptIdx: null,
        mgmStatsBarChart: {},
        mgmDetailShow: false,
        mgmSubWindowUrl: null,
        mgmSubWindowShow: false,
        securityStarList: [],
        workerInOutList: [],
        personalInfoPopupShow: false,
        personalInfoWorkerId: null,
        personalInfoTabIndex: 1,
        securityStarPopupShow: false,
        securityStarPage: 1,
        securityStarPages: 1,
        securityStarInfo: [],
        inOutPopupShow: false,
        workerInOutPage: 1,
        workerInOutPages: 1,
        workerInOutInfo: [],
        companyAttendanceBarChart: {},
        timer: null,
      }
    },
    mounted () {
      // const self = this
      const { id: projectID } = this.$route.params;
      this.projectId = projectID;
      const token = this.$route.query.token;
      window.token = token;

      project.getDeptList()
        .then(d => {
          this.deptNameList = ['全部部门'].concat(d.map(item => item.value));
          this.deptIdList = [''].concat(d.map(item => item.innerid));
        })
        // eslint-disable-next-line
        .catch(e => console.error(e.msg || e.message));

      this.updateSecurityStarList(projectID);
      this.updateWorkerInOutList(projectID);

      window.addEventListener('message', (event) => {
        if (event.data.key == 'merge') {
          if (event.data.page == 'trace') {
            this.showTrace(event.data.query.worker_id, event.data.query.worker_name);
          } else {
            this.showMgmSubWindow(event.data.url);
          }
        }
      }, false);

      this.timer = setInterval(() => {
        this.updateSecurityStarList(projectID);
        this.updateWorkerInOutList(projectID);
      }, 1000*15);
    },
    methods: {
      updateMgmStatsChart(deptId) {
        project.getMgmStats(JSON.stringify({
            project_id: this.$route.params.id,
            department_id: deptId,
            is_only_leader: this.mgmStatsOption == 'mgm' ? 1 : 0,
          }))
          .then(d => {
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            chart.legend.selectedMode = false;
            chart.tooltip.formatter = '{b}<br>{a0}: {c0}<br>{a1}: {c1}%';
            chart.xAxis[0].data = d.map(x => x.date);
            chart.series[0].name = '时长';
            chart.series[1].name = '比例';
            chart.series[0].data = d.map(x => x.avg_attendance_hour);
            chart.series[1].data = d.map(x => x.percent_rate.toFixed(1));
            chart.grid.bottom = '15%';
            chart.yAxis[0].max = Math.ceil(Math.max.apply(null, d.map(x => x.avg_attendance_hour)) / 10) * 10;
            chart.yAxis[1].splitLine = { show: true, lineStyle: { type: 'dashed', color: '#333333' } };
            chart.yAxis[1].axisLabel.formatter = SimpleBarChartTemplate.yAxis[1].axisLabel.formatter;
            this.mgmStatsBarChart = chart;
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
      updateSecurityStarList(projectID) {
        project.getSecurityStars(projectID, 1, 9)
          .then(d => {
            this.securityStarList = d;
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
      updateWorkerInOutList(projectID) {
        project.getWorkerInOuts(projectID, 1, 9)
          .then(d => {
            this.workerInOutList = d;
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
      updateProjectCompanyAttendanceChart() {
        project.getProjectCompanyAttendance(this.$route.params.id)
          .then(d => {
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            chart.legend = null;
            chart.xAxis[0].data = d.map(x => x.company_name.slice(0, 16));
            chart.xAxis[0].axisLabel.rotate = 30;
            chart.series = [chart.series[0]];
            chart.series[0].name = '人数';
            chart.series[0].data = d.map(x => x.worker_count);
            chart.grid.top = '15%';
            chart.grid.left = '20%';
            chart.grid.right = '5%';
            chart.grid.bottom = '40%';
            chart.yAxis[0].name = '人数';
            chart.yAxis[0].splitLine = { show: true, lineStyle: { type: 'dashed', color: '#333333' } };
            chart.yAxis[1].show = false;
            this.companyAttendanceBarChart = chart;
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
      deptSelect(option) {
        const idx = this.deptNameList.indexOf(option);
        if (idx >= 0) {
          this.mgmStatsSelectedDeptIdx = idx;
          this.updateMgmStatsChart(this.deptIdList[idx]);
        }
      },
      switchMgmStatsOption(option) {
        if (option != this.mgmStatsOption) {
          this.mgmStatsOption = option;
          this.updateMgmStatsChart(this.deptIdList[this.mgmStatsSelectedDeptIdx]);
        }
      },
      showMgmDetail() {
        this.mgmDetailShow = true;
      },
      showMgmSubWindow(url) {
        this.mgmSubWindowUrl = url;
        this.mgmSubWindowShow = true;
      },
      showMoreStars() {
        project.getSecurityStars(this.$route.params.id, 1, 10)
          .then(d => {
            this.securityStarInfo = d;
            this.securityStarPage = 1;
            this.securityStarPages = Math.ceil(d.__count__ / 10);
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
        this.securityStarPopupShow = true;
      },
      securityStarPageChange(currPage) {
        project.getSecurityStars(this.$route.params.id, currPage, 10)
          .then(d => {
            this.securityStarInfo = d;
            this.securityStarPages = Math.ceil(d.__count__ / 10);
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
      showWorkerInfo(workerId) {
        this.personalInfoWorkerId = workerId;
        this.personalInfoTabIndex = 2;
        this.personalInfoPopupShow = true;
      },
      showMoreAttendance() {
        project.getWorkerInOuts(this.$route.params.id, 1, 10)
          .then(d => {
            this.workerInOutInfo = d;
            this.workerInOutPage = 1;
            this.workerInOutPages = Math.ceil(d.__count__ / 10);
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
        this.updateProjectCompanyAttendanceChart();
        this.inOutPopupShow = true;
      },
      workerInOutPageChange(currPage) {
        project.getWorkerInOuts(this.$route.params.id, currPage, 10)
          .then(d => {
            this.workerInOutInfo = d;
            this.workerInOutPages = Math.ceil(d.__count__ / 10);
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
      cleanupInOutPopup() {
        this.companyAttendanceBarChart = {};
      },
    },
    beforeDestroy() {
      clearInterval(this.timer);
    }
  }
</script>

<style scoped lang="less">
.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>