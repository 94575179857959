import ECharts from 'vue-echarts';

export const singleLineChartTemplate = {
  color: [
    '#1bd080',
    '#ffbd1d',
    '#53aeef',
  ],
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    icon: 'circle',
    itemWidth: 12,
    itemHeight: 12,
    itemGap: 12,
    textStyle: {
      color: '#ccc',
      fontWeight: 'bold',
      fontSize: '11'
    },
    padding: [8, 0, 0, 0],
    // top: '4%',
    // right: '3%',
  },
  grid: {
    left: '0%',
    right: '2%',
    top: '15%',
    bottom: '15%',
    containLabel: true
  },
  yAxis: [{
    type: 'value',
    show: true,
    axisLine: {
      show: false,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: [5, 10],
        color: '#14253f',
        width: 2,
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: '#e6f2f3',
      fontSize: 14,
      fontWeight: 'bold',
      formatter: function(value) {
        return value ? value + '%' : value;
      }
    }
  }],
  xAxis: {
    type: 'category',
    data: [],
    boundaryGap: true,
    splitNumber: 7,
    axisLabel: {
      color: '#e6f2f3',
      fontSize: 14,
      fontWeight: 'bold',
      rotate: 0,
    },
    splitLine: {
      show: false
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#22344b',
      }
    },
  },
  series: [{
    name: '',
    type: 'line',
    itemStyle: {
      normal: {
        lineStyle: {
          width: 2
        }
      }
    },
    areaStyle: {
      color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
        {offset: 0, color: 'rgba(27, 208, 128, 0.5)'},
        {offset: 1, color: 'rgba(27, 208, 128, 0)'}
      ])
    },
    symbolSize: 0,
    data: [],
  }]
};

export const singleLineScrollChartTemplate = {
  color: [
    '#1bd080',
    '#ffbd1d',
    '#53aeef',
  ],
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    type: 'scroll', // 添加这一行代码即可实现图例分页功能
    orient: 'horizontal', // 'vertical'
    x: 'center',
    icon: 'circle',
    itemWidth: 12,
    itemHeight: 12,
    itemGap: 12,
    textStyle: {
      color: '#ccc',
      fontWeight: 'bold',
      fontSize: '11'
    },
    padding: [8, 0, 0, 0],
    top: '4%',
    right: '3%',
  },
  grid: {
    left: '0%',
    right: '0%',
    top: '15%',
    bottom: '15%',
    containLabel: true
  },
  yAxis: [{
    type: 'value',
    show: true,
    axisLine: {
      show: false,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: [5, 10],
        color: '#14253f',
        width: 2,
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: '#e6f2f3',
      fontSize: 14,
      fontWeight: 'bold',
      formatter: function(value) {
        return value ? value + '%' : value;
      }
    }
  }],
  xAxis: {
    type: 'category',
    data: [],
    boundaryGap: true,
    splitNumber: 7,
    axisLabel: {
      color: '#e6f2f3',
      fontSize: 14,
      fontWeight: 'bold',
      rotate: 30,
    },
    splitLine: {
      show: false
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#22344b',
      }
    },
  },
  series: [{
    name: '',
    type: 'line',
    itemStyle: {
      normal: {
        lineStyle: {
          width: 2
        }
      }
    },
    areaStyle: {
      color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
        {offset: 0, color: 'rgba(27, 208, 128, 0.5)'},
        {offset: 1, color: 'rgba(27, 208, 128, 0)'}
      ])
    },
    symbolSize: 0,
    data: [],
  }]
};