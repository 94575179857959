<template>
  <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title">隐患类型</div>
    <div
      class="detail-select"
      style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
    >
      <CustomSelect
        :options="dangerCategoryNameList"
        :value="selectedDangerCategoryName2"
        @input="categorySelect2"
        style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
      />
    </div>
    <div style="height:25rem;position:relative">
      <v-chart :options="troubleCountsPieChart" ref="troubleCounts" @mouseover="troubleCountsMouseOver" @mouseout="troubleCountsMouseOut" autoresize />
      <div style="position:absolute;top:0.5rem;left:1rem;font-size:0.8rem">数量：个</div>
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { nation } from '../../../../api'
  import CustomSelect from '../../../../components/select'
  import { attendancePieChartTemplate, colors } from '../../pieChartTemplates';
  import { applyUpdate } from "../../../../utils";

  export default {
    name: 'TroubleCountsPieChart',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerTroubleCountsChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerTroubleCountsChart();
        }
      }
    },
    data () {
      return {
        dangerCategoryNameList: ['全部类别'],
        dangerCategoryIdList: [''],
        selectedDangerCategoryName2: '全部类别',

        troubleCountsPieChart: {},
        troubleCountsPieChartHighlightIndex: null,
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      nation.getSafetyDangerTypes()
        .then(d => {
          this.dangerCategoryNameList = ['全部类别'].concat(d.map(item => item.name));
          this.dangerCategoryIdList = [''].concat(d.map(item => item.innerid));
        })
        // eslint-disable-next-line
        .catch(e => console.error(e.msg || e.message))
    },
    methods: {
      categorySelect2(option) {
        this.selectedDangerCategoryName2 = option;
        this.updateSafetyDangerTroubleCountsChart();
      },
      updateSafetyDangerTroubleCountsChart() {
        let categoryId = '';
        const idx = this.dangerCategoryNameList.indexOf(this.selectedDangerCategoryName2);
        if (idx >= 0) {
          categoryId = this.dangerCategoryIdList[idx];
        }
        nation.getSafetyDangerTroubleTypeCounts(categoryId)
          .then((d) => {
            const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));

            const maxPieces = 14;
            if (d.series_data.length > maxPieces) {
              d.series_data[maxPieces - 1].name = '其它';
              d.series_data[maxPieces - 1].value = d.series_data
                .slice(maxPieces - 1)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue.value,
                  0
                );
              d.series_data = d.series_data.slice(0, maxPieces);
            }

            const option = {
              color: colors,
              legend: {
                show: false,
              },
              grid: {
                top: "5%",
                left: "10%",
                right: "9%",
                bottom: "5%",
                containLabel: true,
              },
              series: [
                {
                  type: "pie",
                  minAngle: 2,
                  radius: ["25%", "45%"],
                  center: ["50%", "50%"],
                  avoidLabelOverlap: true,
                  itemStyle: {
                    normal: {
                      borderColor: "#08122b",
                      borderWidth: 2,
                    },
                  },
                  label: {
                    normal: {
                      show: false,
                      position: "center",
                      //formatter: '{text|{b}}\n{c} ({d}%)',
                      //formatter: '{value|{d}%}\n{text|{b}}',
                      formatter: function (params) {
                        return `{value|${Math.round(params.percent)}%}\n{text|${
                          params.name
                        }}`;
                      },
                      padding: [20, 0, 0, 0],
                      rich: {
                        text: {
                          color: "#aaa",
                          fontSize: 12,
                          align: "center",
                          verticalAlign: "middle",
                          lineHeight: 22,
                        },
                        value: {
                          color: "#fff",
                          fontSize: 16,
                          fontWeight: "bold",
                          align: "center",
                          verticalAlign: "middle",
                        },
                      },
                    },
                    emphasis: {
                      show: true,
                      textStyle: {
                        fontSize: 14,
                      },
                    },
                  },
                },
              ],
            };


            option.series[0].data = d.series_data;
            if (this.troubleCountsPieChartHighlightIndex != null) {
              this.$refs.troubleCounts && this.$refs.troubleCounts.dispatchAction({
                type: 'downplay',
                seriesIndex: 0,
                dataIndex: this.troubleCountsPieChartHighlightIndex
              });
            }

            // 默认高亮第一个值不是0的分片，否则默认第一片
            this.troubleCountsPieChartHighlightIndex = 0;
            for (let i = 0; i < d.series_data.length; i++) {
              if (option.series[0].data[i].value) {
                this.troubleCountsPieChartHighlightIndex = i;
                break;
              }
            }

            option.series[1] = Object.assign({}, option.series[0], {
              label: {
                rich: {
                  text: {
                    fontSize: 11,
                  },
                },
                formatter: function (params) {
                  return `${params.name} ${Math.round(params.value)}`;
                },
              },
              silent: true,
            });

            setTimeout(
              () =>
                this.$refs.troubleCounts &&
                this.$refs.troubleCounts.dispatchAction({
                  type: "highlight",
                  seriesIndex: 0,
                  dataIndex: this.troubleCountsPieChartHighlightIndex,
                }),
              100
            );
            this.troubleCountsPieChart = applyUpdate(chart, option);
          })
          .catch((e) => console.error(e.msg || e.message));
      },
      troubleCountsMouseOver(params) {
        if (params.dataIndex != this.troubleCountsPieChartHighlightIndex) {
          this.$refs.troubleCounts.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.troubleCountsPieChartHighlightIndex
          });
          this.troubleCountsPieChartHighlightIndex = params.dataIndex;
        }
      },
      troubleCountsMouseOut() {
        this.$refs.troubleCounts.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.troubleCountsPieChartHighlightIndex
        });
      },
    },
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
