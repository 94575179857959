<template>
  <div id="left">
    <SeeSiteConditionChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <CheckRedListChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" :org-options="orgOptions" />
    <CheckBlackListChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
  </div>
</template>

<script>
  import SeeSiteConditionChart from "./seeSiteConditionChart";
  import CheckRedListChart from "./checkRedListChart";
  import CheckBlackListChart from "./checkBlackListChart";

  export default {
    name: 'QualityIndexLeftBar',
    components: {
      SeeSiteConditionChart,
      CheckRedListChart,
      CheckBlackListChart,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
  }
</script>

<style lang="less">
</style>
