<template>
  <div id="right">
    <OwnerAssessChart :district-level="districtLevel" :org-level="orgLevel" />
    <MaintenanceProjectChart :district-level="districtLevel" :org-level="orgLevel" />
  </div>
</template>

<script>
  import OwnerAssessChart from "./ownerAssessChart";
  import MaintenanceProjectChart from "./maintenanceProjectChart";

  export default {
    name: 'QualityIndexRightBar',
    components: {
      OwnerAssessChart,
      MaintenanceProjectChart,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
  }
</script>

<style lang="less">
</style>
