// import { data } from 'jquery';
const baseUrl = process.env.VUE_APP_API_BASE_ZGSHR_URL

const areaCodeMap = require('/src/area-code-map.json');

const handleRes = (res) => {
    if (res.statusCode < 200 || res.statusCode >= 300) {
      return res.response.text().then(e => Promise.reject(e))
    } else {
      return res.response.json().then(_d => {
        if (_d.code !== 0 && _d.code !== 200) {
          return Promise.reject(_d)
        } else if (_d.data) {
          _d.data.__count__ = _d.count;
          return _d.data;
        } else{
          return _d;
        }
      })
    }
  }
// function addFilterParams(url) {
//       // for(var key in areaCodeMap){
//       //   if(key == window.$districtLevel){
//       //     console.log(areaCodeMap[key]);
//       //   }
//       // }
//       verifyOrgId();
//       const containsArea = url.indexOf('areaLvl=') >= 0;
//       const containsOrg = url.indexOf('orgLvl=') >= 0;
//       if(url.indexOf("unit_list")!=-1)
//         return url;
//       if (!containsArea || !containsOrg) {
//         const sign = url.indexOf('?') >= 0 ? '&' : '?';
//         const areaStr = `areaLvl=${window.$districtLevel || '100000'}`;
//         const orgStr = `orgLvl=${window.$orgLevel || ''}`;
//         if (!containsArea && !containsOrg) {
//           return url + sign + areaStr + '&' + orgStr;
//         } else {
//           return url + sign + (!containsArea ? areaStr : orgStr);
//         }
//       } else {
//         return url;
//       }
//     // const sFIDArea = url.indexOf('sFID=') >= 0;
//     // if (!sFIDArea) {
//     //   // const sign = url.indexOf('?') >= 0 ? '&' : '?';
//     //   formattingOrgId();
//     //   return url;
//     //   // const orgStr = `orgLvl=${window.$sFid || ''}`;
//     //   // return url + sign + orgStr;
//     // } else {
//     //   return url;
//     // }
//   }

  function addFilterParams2(url,body) {
    verifyOrgId();
    var provinceName = ''; 
    var cityName = '';
    for(var key in areaCodeMap){
      if(key == window.$districtLevel){
        let str = areaCodeMap[key];
        if(str != '' && str != null){
          var datalist = str.split("/");
          if(datalist.length >= 1){
            provinceName = datalist[0];
            cityName = datalist[1];
          }
        }
      }
    }
    const provinceExist = body["province"] ==null
    const cityExist = body["city"] ==null
    if(provinceExist || cityExist) {
      body["province"] = provinceName;
      body["city"] = cityName;
      return body
    } else {
      return body;
    }
}

/** @param url {string}
 * @return Promise*/
//  const get = url => fetch(addFilterParams(baseUrl + url)).then(res => ({ response: res, statusCode: res.status })).then(handleRes)

const post = (url, body, type = 'json') => {
    let headers, _body = addFilterParams2(url,body);
    if (type === 'json') {
      headers = { 'Content-Type': 'application/json','traditional':true}
      // headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
      _body = JSON.stringify(body)
    } else if (type === 'file') {
      _body = new FormData()
      _body.append('files', body)
    } else {
      _body = body
    }
    if (url.slice(0, 4) != 'http') {
      url =baseUrl + url;
    }
    return fetch(url, { method: 'POST', headers, body: _body })
      .then(res => ({ response: res, statusCode: res.code })).then(handleRes)
  }
function verifyOrgId(){
  if(window.$sFid === '' || window.$sFid === undefined || window.$sFid === null)
    window.$sFid = "7B867F6775E143F8B1F3131BFA85C13E";
}
const projectMgr = {
  // 首次资源配置指标
  queryMapTitleInfo:()=>post(`bi/pm/resourceInit/queryMapTitleInfo`,{}),
  // 地图数据
  queryProvinceNewProjCount:()=>post(`bi/pm/resourceInit/queryProvinceNewProjCount`,{}),
  // 项目团队组建情况
  queryProjFormCondition:()=>post(`bi/pm/resourceInit/queryProjFormCondition`,{}),
  // 项目团队组建信息列表
  queryProjFormInfoList:(branchName,pageNum,pageSize)=>post(`bi/pm/resourceInit/queryProjFormInfoList`,{"branchName":branchName,"pageNum":pageNum,"pageSize":pageSize}),
  // 项目策划及时率
  queryProjPlanIntimeRate:()=>post(`bi/pm/resourceInit/queryProjPlanIntimeRate`,{}), 
  // 风险抵押金情况
  queryRiskGuaranteeChart:()=>post(`bi/pm/resourceInit/queryRiskGuaranteeChart`,{}),  
  // 风险抵押金信息列表
  queryRiskGuaranteeInfoList:(branchName,pageNum,pageSize)=>post(`bi/pm/resourceInit/queryRiskGuaranteeInfoList`,{"branchName":branchName,"pageNum":pageNum,"pageSize":pageSize}),
  // 目标责任书签订情况
  queryProjRespDocCondition:()=>post(`bi/pm/resourceInit/queryProjRespDocCondition`,{}),     
  // 目标责任书信息列表
  queryProjRespDocInfoList:(branchName,pageNum,pageSize)=>post(`bi/pm/resourceInit/queryProjRespDocInfoList`,{"branchName":branchName,"pageNum":pageNum,"pageSize":pageSize}),
  // 人才数量图表
  queryTalentEchelonChart:()=>post(`bi/pm/resourceInit/queryTalentEchelonChart`,{}),       
  // 人才数量信息列表
  queryTalentEchelonInfoList:(branchName,pageNum,pageSize)=>post(`bi/pm/resourceInit/queryTalentEchelonInfoList`,{"branchName":branchName,"pageNum":pageNum,"pageSize":pageSize}),
  // 共建方分级统计
  queryPartnerLevelChart:()=>post(`bi/pm/resourceInit/queryPartnerLevelChart`,{}),
  // 共建方分级信息列表
  queryPartnerLevelInfoList:(branchName,pageNum,pageSize)=>post(`bi/pm/resourceInit/queryPartnerLevelInfoList`,{"branchName":branchName,"pageNum":pageNum,"pageSize":pageSize}),
  // 共建方数量分布
  queryPartnerDistCountChart:()=>post(`bi/pm/resourceInit/queryPartnerDistCountChart`,{}),
  //  共建方数量分布列表
  queryPartnerDistInfoList:(branchName,pageNum,pageSize)=>post(`bi/pm/resourceInit/queryPartnerDistInfoList`,{"branchName":branchName,"pageNum":pageNum,"pageSize":pageSize}),
  // 共建方禁用情况
  queryPartnerBanChart:()=>post(`bi/pm/resourceInit/queryPartnerBanChart`,{}),
  // 共建方禁用信息列表
  queryPartnerBanInfoList:(branchName,pageNum,pageSize)=>post(`bi/pm/resourceInit/queryPartnerBanInfoList`,{"branchName":branchName,"pageNum":pageNum,"pageSize":pageSize}),
}

export { baseUrl, projectMgr }
