
export function isPlainObject(input){
   return input && !Array.isArray(input) && typeof input === 'object';
}

export function applyUpdate(initial, update){
    for (const prop in update) {
        if ({}.hasOwnProperty.call(update, prop)) {
            if ({}.hasOwnProperty.call(initial, prop)) {
                if (isPlainObject(initial[prop]) && isPlainObject(update[prop])) {
                    applyUpdate(initial[prop], update[prop]);
                } else {
                    initial[prop] = update[prop];
                }
            } else {
                initial[prop] = update[prop];
            }
        }
    }

    return initial;
}

export function truncateStr(str, maxLen) {
    if (str.length > maxLen) {
        str = str.slice(0, maxLen - 1) + '...';
    }
    return str;
}
