<template>
  <div id="left">
    <div style="margin-top: 2rem;">
      <div class="case" style="background-color: rgba(170, 42, 55, 0.2);border-color: #AA2A37">
        <div style="color: #AA2A37" class="num">{{lists[0] && lists[0].count}}</div>
        <div style="margin-top: -20px">{{lists[0] && lists[0].level}}色预警数</div>
      </div>
      <div class="case" style="background-color: rgba(184, 113, 47, 0.2);border-color: #B8712F">
        <div style="color: #F28E2C" class="num">{{lists[1] && lists[1].count}}</div>
        <div style="margin-top: -20px">{{lists[1] && lists[1].level}}色预警数</div>
      </div>
      <div class="case" style="background-color: rgba(192, 176, 36, 0.2);border-color: #C0B024">
        <div style="color: #F9DD20" class="num">{{lists[2] && lists[2].count}}</div>
        <div style="margin-top: -20px">{{lists[2] && lists[2].level}}色预警数</div>
      </div>
      <div class="case" style="background-color: rgba(51, 115, 185, 0.2);border-color: #3373B9">
        <div style="color: #4191E4" class="num">{{lists[3] && lists[3].count}}</div>
        <div style="margin-top: -20px">{{lists[3] && lists[3].level}}色预警数</div>
      </div>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="warningInfoShow"
      width="70rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="min-height:21rem;"
      >
        <div
            class="stats-close-btn"
            @click="warningInfoShow = false"
        />
        <div style="padding: 1rem 1rem 0;max-height: 45rem;overflow-y: scroll;">
          <div class="header">
            <div style="font-size: 18px;margin-bottom: 10px">{{detail.title}}</div>
            <div style="margin-bottom: 10px">{{detail.release_time}}</div>
            <div>{{detail.content}}</div>
          </div>
          <div class="content">
            <div v-for="(item, index) in demandLists" :key="item.require_id">
              <div class="title" style="display: flex;align-items: center;">
                <div style="font-size: 18px">要求完成措施{{index+1}}</div>
                <div class="menu" @click="changeMenu(item, index)">
                  {{item.isOpen ? '收起':'展开'}}<i class="el-icon-caret-top" v-if="item.isOpen"></i><i class="el-icon-caret-bottom" v-else></i>
                </div>
              </div>
              <div style="margin-top:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
                <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                  <div style="width:10rem;text-align:center">要求人</div>
                  <div style="width:8rem;text-align:center">所属单位</div>
                  <div style="width:8rem;text-align:center">要求防范措施</div>
                  <div style="width:8rem;text-align:center">完成项目/要求项目</div>
                </div>
                <div style="height:3rem;overflow:auto;">
                  <div
                    v-for="(item, index) in demandLists"
                    :key="item.require_id"
                    style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                    :style="{ background: index%2 ? '#102855' : '#213a77'}"
                  >
                    <div style="width:10rem;text-align:center">{{item.report_person}}</div>
                    <div style="width:8rem;text-align:center">
                      {{item.org_name}}
                    </div>
                    <div style="width:8rem;text-align:center">{{item.content}}</div>
                    <div
                      style="width:8rem;text-align:center"
                    >{{item.finish_count}}/{{item.total_count}}</div>
                  </div>
                  <div
                    v-if="!(demandLists && demandLists.length)"
                    style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                  >
                    暂无数据
                  </div>
                </div>
              </div>
              <div v-if="item.isOpen" style="display: flex;justify-content: space-between;flex-wrap: wrap;">
                <div v-for="h in item.handleLists" :key="h.project_id" class="panels">
                  <div style="font-size: 16px;margin-bottom: 10px;display: flex;justify-content: space-between;align-items:center;">
                    <div>{{h.project_name}}</div>
                    <div style="color:#3980B3;font-size:14px;display: flex;align-items:center;cursor: pointer;">
                      <!-- <el-image src="../../assets/shexiangtou.png" style="width:0.8rem;height:0.8rem;"></el-image> -->
                      <img src="../../assets/shexiangtou.png" style="width:0.6rem;height:0.8rem;margin-right:0.5rem" alt="">
                      实时监控
                    </div>
                  </div>
                  <div style="display: flex;">
                    <div style="flex: 1.5;">
                      <div>项目经理：{{h.head_person}}&nbsp;&nbsp;&nbsp;&nbsp;{{h.mobile}}</div>
                      <el-steps :active="h.active" align-center style="margin-top: 10px;margin-left: -30px;font-size: 14px;">
                        <el-step title="已读上级要求"></el-step>
                        <el-step title="完成管理措施"></el-step>
                      </el-steps>
                    </div>
                    <div style="flex: 1;" class="unread" v-if="!h.status">
                      未读
                    </div>
                    <div v-else style="flex: 1"></div>
                  </div>
                  <div style="margin-top: 10px;display: flex;justify-content: space-between;align-items:center;height: 2rem">
                    <div>{{h.alarm_plan_name}}</div>
                    <div class="menu2" v-if="h.active" @click="getDetail(h)">
                      要求完成情况详情
                    </div>
                  </div>
                  <div style="margin-top:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
                    <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                      <div style="width:10rem;text-align:center">预案内容</div>
                      <div style="width:8rem;text-align:center">执行完成({{h.num}}/{{h.plan_handle_contents.length}})</div>
                    </div>
                    <div style="height:6rem;overflow:auto;">
                      <div
                        v-for="(p, pIndex) in h.plan_handle_contents"
                        :key="pIndex"
                        style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                        :style="{ background: index%2 ? '#102855' : '#213a77'}"
                      >
                        <div style="width:10rem;text-align:center">{{item.content}}</div>
                        <div style="width:8rem;text-align:center">
                          <i class="el-icon-check" v-if="p.status" style="color:#33C19C;font-weight:bold;font-size: 18px;"></i>
                          <i class="el-icon-close" v-else style="color:#DA2F39;font-weight:bold;font-size: 18px;"></i>
                          <!-- <i style="color: #D5803C;font-size: 14px;">不涉及</i> -->
                        </div>
                      </div>
                      <div
                        v-if="!(h.plan_handle_contents && h.plan_handle_contents.length)"
                        style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                      >
                        暂无数据
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>


    <el-dialog
      custom-class="stats-layer"
      :visible.sync="detailInfoShow"
      width="70rem"
      top="18rem"
    >
      <wrapper
        class="popup-bg"
        style="min-height:11rem;"
      >
        <div
            class="stats-close-btn"
            @click="detailInfoShow = false"
        />
        <div style="padding: 1rem 1rem 0;">
          <div class="header">
            <div style="font-size: 18px;margin-bottom: 10px">{{alarm_plan_name}}</div>
          </div>
          <div class="content">
            <div style="margin-top:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:10rem;text-align:center">预案内容</div>
                <div style="width:8rem;text-align:center">执行完成({{num}}/{{detailLists.length}})</div>
                <div style="width:10rem;text-align:center">备注</div>
                <div style="width:10rem;text-align:center">照片</div>
              </div>
              <div style="height:10rem;overflow:auto;">
                <div
                  v-for="(item, index) in detailLists"
                  :key="index"
                  style="display:flex; min-height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:10rem;text-align:center">{{item.content}}</div>
                  <div style="width:8rem;text-align:center">
                    <i class="el-icon-check" v-if="item.status" style="color:#33C19C;font-weight:bold;font-size: 18px;"></i>
                    <i class="el-icon-close" v-else style="color:#DA2F39;font-weight:bold;font-size: 18px;"></i>
                    <!-- <i style="color: #D5803C;font-size: 14px;">不涉及</i> -->
                  </div>
                  <div style="width:10rem;text-align:center">{{item.remark}}</div>
                  <div style="width:10rem;text-align:center">
                     <el-image v-for="(img,imgIndex) in item.img_list" :key="imgIndex" :src="img" style="width:4rem;height:4rem;"></el-image>
                     <!-- <el-image src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"></el-image> -->
                  </div>
                </div>
                <div
                  v-if="!(detailLists && detailLists.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  // import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { nation } from '../../api'
  import wrapper from "../../components/wrap";
  // import CustomSelect from '../../components/select'
  // import { SimpleBarChartTemplate }  from './barChartTemplates';

  export default {
    name: 'WeatherLeftBar',
    components: {
      // 'v-chart': ECharts,
      // 'CustomSelect': CustomSelect,
      wrapper
    },
    props: {
      districtLevel: String,
      orgLevel: String,
      event: String,
      eventData: Number,
      eventCounter: Number,
    },
    data () {
      return {
        lists: [],
        warningInfoShow: false,
        detail: {},
        demandLists: [],
        detailLists: [],
        detailInfoShow: false,
        num: 0,
        name: ''
      }
    },
    watch: {
      orgLevel: function(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      },
      districtLevel: function(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      },
      eventCounter: function(newValue, oldValue) {
        if (newValue > oldValue) {
          if (this.event == 'weatherAlarmClick') {
            this.show(this.eventData);
          }
        }
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.getAll();
    },
    methods: {
      getAll(){
        nation.getEmergencyAlarmCountLevelCollect()
          .then(d => {
            // console.log(d);
            this.lists = d;
          })
      },
      show(emergencyId){
        this.warningInfoShow = true;
        nation.getEmergencyAlarmDetailCollect(emergencyId)
          .then(d => {
            // console.log(d);
            this.detail = d;
          })

        nation.getEmergencyAlarmRequireCollect(emergencyId)
          .then(d => {
            // console.log(d);
            this.demandLists = d;
            this.demandLists.forEach(v => {
              v.isOpen = false;
              v.handleLists = [];
            })
          })
      },
      changeMenu(item, index){
        item.isOpen = !item.isOpen;
        this.$set(this.demandLists, index, item);
        // eslint-disable-next-line no-empty
        if(item.isOpen){
          nation.getEmergencyAlarmHandleCollect(item.require_id)
            .then(d => {
              // console.log(d);
              item.handleLists = d;
              item.handleLists.forEach(v => {
                v.active = v.status;
                v.num = 0;
                v.plan_handle_contents.forEach(p => {
                   if(p.status) v.num++;
                })
              })
              this.$set(this.demandLists, index, item);
            })
        }
      },
      getDetail(h){
        this.detailInfoShow = true;
        this.alarm_plan_name = h.alarm_plan_name;
        nation.getEmergencyAlarmHandleFinishCollect(h.handle_id)
          .then(d => {
            // console.log(d);
            this.detailLists = d;
            this.num = 0;
            this.detailLists.forEach(v => {
              if(v.status) this.num++;
            })
          })
      }
    },
  }
</script>

<style scoped lang="less">
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
.case{
  width: 200px;
  text-align: center;
  height: 100px;
  line-height: 50px;
  border: 1px solid #fff;
  margin-bottom: 20px;
  font-size: 14px;
  color: #fff;
}
.num{
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
.header{
  font-size: 14px;
  border-bottom: 2px solid #5370B6;
  padding-bottom: 20px;
}
.content{
  padding-top: 20px;
}
.menu{
  background-color: #4191E5;
  padding: 4px 10px;
  border-radius: 2px;
  margin-left: 20px;
  cursor: pointer;

  i{
    margin-left: 5px;
  }
}

.panels{
  width: 49%;
  border: 1px solid #1a3281;
  padding: 1rem;
  border-radius: 2px;
  margin-bottom: 1rem;
}
.unread{
  border: 1px solid #9C2636;
  border-radius: 2;
  color: #DE2F37;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu2{
  background-color: #4191E5;
  padding: 6px 12px;
  border-radius: 2px;
  margin-left: 20px;
  cursor: pointer;
}
.el-step__title.is-process{
  color: #c0c4cc !important;
}
</style>
