<template>
  <div class="info-block" style="min-height:5rem">
    <div class="info-block-title">人才数</div>
    <div
      class="detail-select"
      style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
    >
    </div>
    <div style="height:18rem;padding-top:3rem">
      <div v-if="!talentNumsChart.series || !talentNumsChart.series[0].data.length">
        <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;">
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
      </div>
      <v-chart v-else :options="talentNumsChart" @click="talentNumsChartClick" autoresize />
    </div>
    <!-- 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                    <el-table style="background-color:#0E1328" height="400px" :data="projData" 
                    :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column type="index" :index="getIndex" label="序号">
                    </el-table-column>
                      <el-table-column
                            prop="branchName"
                            label="分公司">
                        </el-table-column>
                        <el-table-column
                            prop="category"
                            label="人才类别">
                        </el-table-column>
                        <el-table-column
                            prop="planName"
                            label="人才计划">
                        </el-table-column>
                        <el-table-column
                            prop="period"
                            label="期次">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="姓名">
                        </el-table-column>
                        <el-table-column
                            prop="status"
                            label="状态">
                        </el-table-column>
                    </el-table>
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :current-page.sync="currentPage"
                      :page-count="pageSize"
                      :total="totalCount"
                      @current-change="pageChange"
                      style="text-align:center;margin-top: 0.5rem;"
                    ></el-pagination>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
import { projectMgr } from '../../../../api_projectMgr'
  import { stackedBarChartTemplate } from "../../barChartTemplates";
  import { applyUpdate } from "../../../../utils";
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'TalentNumChart',
    components: {
      'v-chart': ECharts,
      wrapper
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateTalentNumsChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateTalentNumsChart();
        }
      }
    },
    data () {
      return {
        talentNumsChart:{},
        popupShow:false,
        currentPage:1,
        pageSize:10,
        totalCount:0,
        projData:[],
        branchName:''
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.updateTalentNumsChart();
    },
    methods: {
      pageChange(curPage){
        this.currentPage = curPage;
        this.getTableData();
      },
      talentNumsChartClick(op){
        this.currentPage = 1;
        this.branchName = op.name;
        this.getTableData();
        this.popupShow = true;
      },
      getTableData(){
        projectMgr.queryTalentEchelonInfoList(this.branchName,this.currentPage,this.pageSize).then((d=>{
          this.projData = d.data;
          this.totalCount = d.totalRecords;
        }))
      },
      getIndex(index){
        return (this.currentPage-1)*this.pageSize+index+1;
      },
      tableRowStyle({rowIndex}){
        if(rowIndex%2 === 0)
            return {backgroundColor:'#213a77',height: '0'}; 
        else   
            return {backgroundColor:'#102855',height: '0'}; 
      },
      tableHeaderColor(){
        return 'color:#4fb5da;background:#102855';
      },
      updateTalentNumsChart(){
        projectMgr.queryTalentEchelonChart().then((d=>{
          const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
            const option = {
              color: ["#46A0FF", "#FFED21", "#FF8521", "rgba(0,0,0,0)"],
              tooltip: {
                trigger: "axis",
              },
              legend:{

              },
              dataZoom: [{
              type: 'slider',
              show: true,
              start: 0,
              end: 100,
              yAxisIndex: 0,
              filterMode: 'empty',
              width: 6,
              height: '78%',
              left: '95%',
              handleSize: 0,
              zoomLock: true,
              top: '8%',
              fillerColor: '#323d86',
              borderColor: '#323d86',
              textStyle: {
                color: "#fff",
                fontSize: 10,
              }
            }, {
              type: 'inside',
              yAxisIndex: 0,
              start: 0,
              end: 100,
              zoomOnMouseWheel: false,
              moveOnMouseMove: true,
              moveOnMouseWheel: true
            }],
              grid: {
                top: "15%",
                left: "10%",
                right: "9%",
                bottom: "15%",
                containLabel: true,
              },
              xAxis: [
                {
                  type: "value",
                  axisLabel: {
                    color: "#ffffff",
                  },
                  axisTick: { show: false },
                  splitLine: {show: false,}
                },
              ],
              yAxis: 
                {
                  show: true,
                  type: "category",
                  nameTextStyle: {
                    padding: [0, 40, 0, 0],
                    color: "#e6f2f3",
                    fontWeight: "bold",
                    align: "left",
                  },
                  axisLabel: {
                    color: "#e6f2f3",
                    fontWeight: "normal",
                  },
                  axisLine: { show: false, lineStyle: { color: "#36445f" } },
                  splitLine: {
                    show: false,
                    lineStyle: {
                      color: "#36445f",
                      width: 1,
                      type: "solid",
                    },
                  },
                  axisTick: { show: false },
                  data:[]
                },
              series: [
                {
                  name: "人才数",
                  type: "bar",
                  data:[]
                }
              ],
            };
            for (let i = 0; i < d.length; i++) {
              option.yAxis.data.push(d[i].branchName);
              option.series[0].data.push(d[i].value);
            }
            if (option.yAxis.data.length <= 6) {
              option.dataZoom[0].show = false;
            } else {
              option.dataZoom[0].show = true;
              option.dataZoom[0].start = option.dataZoom[1].start = 100 - Math.min(100, (6 / option.yAxis.data.length) * 100);
            }
            this.talentNumsChart = applyUpdate(chart, option);
        }))
      }
    },
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
