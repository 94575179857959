import VueRouter from 'vue-router'
import building from './pages/building'
import disclosure from './pages/building/disclosure'
import exams from './pages/building/exams'
import nation from './pages/nation'
import project from './pages/project'
import project2 from './pages/project2'
import project_model from './pages/project2/model'
import disclosure2 from './pages/project2/disclosure2'
import path from './pages/project/path'
import attendance from './pages/attendance'
import beacon from './pages/beacon'
import beacon2 from './pages/beacon2'
import ebox from './pages/ebox/patrol.vue'
// import notFound from './pages/notFound'

export default new VueRouter({
  // mode: 'history',
  routes: [
    {
      path: '/beacon',
      component: beacon,
      name: 'beacon',
    },
    {
      path: '/beacon/:op',
      component: beacon2,
      name: 'beacon2',
    },
    {
      path: '/ebox/:projectID/:Date',
      component: ebox,
      name: 'beacon2',
    },
    {
      path: '/nation/:nav?/:subNav?',
      component: nation,
      name: 'nation',
    },
    {
      path: '/building/:buildingID/:projectID',
      component: building,
      name: 'building'
    },
    {
      path: '/disclosure/:buildingID/:projectID',
      component: disclosure,
      name: 'disclosure'
    },
    {
      path: '/disclosure2/:projectID',
      component: disclosure2,
      name: 'disclosure2'
    },
    {
      path: '/exams/:buildingID/:projectID',
      component: exams,
      name: 'exams'
    },
    {
      path: '/project/:id',
      component: project,
      name: 'project'
    },
    {
      path: '/project/path/:worker_id/:map_url',
      component: path,
      name: 'path'
    },
    {
      path: '/project2/:id/:nav?/:subNav?',
      component: project2,
      name: 'project2'
    },
    {
      path: '/project_model/:id',
      component: project_model,
      name: 'project_model'
    },
    {
      path: '/attendance/:id/:project_name?',
      component: attendance,
      name: 'attendance',
      // alias: '/:id/:project_name?'
    },
    {
      path: '/finance/:id',
      name: 'finance',
      component: ()=>
        import('./pages/project2/finance/index')
    }
    // {
    //   path: '*',
    //   component: notFound
    // }
  ]
})
