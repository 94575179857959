<template>
  <div style="width:100%;height:10rem;display:flex;justify-content:center">
    <div style="display:flex;align-items:center">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[0]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[0]}}</div>
        <div style="display:flex" @click="handleClick(1)">
          <v-number
            v-model="(broadcastData || {}).value1"
            :speed="500"
            style="font-family:digital;font-size:2rem;margin:-0.25rem 0 0 0.3rem"
            :style="{color: broadcastColors[0]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:0.2rem">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[1]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[1]}}</div>
        <div style="display:flex" @click="handleClick(2)">
          <v-number
            v-model="(broadcastData || {}).value2"
            :speed="500"
            style="font-family:digital;font-size:2rem;margin:-0.25rem 0 0 0.3rem"
            :style="{color: broadcastColors[1]}"
          ></v-number>
          <span v-if="(broadcastData || {}).value5 != null" style="font-size:1.25rem;color:#ffd454;line-height:2.5rem">万元</span>
          <span v-if="(broadcastData || {}).value2 != null" style="font-family:digital;font-size:2rem;color:#cececf;line-height:1.8rem">/</span>
          <v-number
            v-model="(broadcastData || {}).value5"
            :speed="500"
            style="font-family:digital;font-size:2rem;color:#cececf;margin:-0.25rem 0 0 0.3rem"
          ></v-number>
          <span v-if="(broadcastData || {}).value5 != null" style="font-size:1.25rem;color:#cececf;line-height:2.5rem">万元</span>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:0.2rem">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[2]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[2]}}</div>
        <div style="display:flex" @click="handleClick(3)">
          <v-number
            v-model="(broadcastData || {}).value3"
            :speed="500"
            style="font-family:digital;font-size:2rem;margin:-0.25rem 0 0 0.3rem"
            :style="{color: broadcastColors[2]}"
          ></v-number>
          <span v-if="(broadcastData || {}).value6 != null" style="font-size:1.25rem;color:#35c59e;line-height:2.5rem">万元</span>
          <span v-if="(broadcastData || {}).value3 != null" style="font-family:digital;font-size:2rem;color:#cececf;line-height:1.8rem">/</span>
          <v-number
            v-model="(broadcastData || {}).value6"
            :speed="500"
            style="font-family:digital;font-size:2rem;color:#cececf;margin:-0.25rem 0 0 0.3rem"
          ></v-number>
          <span v-if="(broadcastData || {}).value6 != null" style="font-size:1.25rem;color:#cececf;line-height:2.5rem">万元</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VNumber from "../../components/v-number";
  import { project } from "../../api";

  export default {
    name: 'ScheduleBroadcasts',
    components: {
      VNumber,
    },
    data() {
      return {
        broadcastTitles: ['工期延误天数', '今年实际/目标总产值', '今年实际/目标人均产值'],
        broadcastColors: ['#e02f37', '#ffd454', '#35c59e'],
        broadcastIcons: ['工期延误项目数', '今年实际目标总产值', '今年实际目标人均产值'],
        broadcastSep: '2rem',
      }
    },
    props: {
      broadcastData: Object,
    },
    methods: {
      handleClick() {
      }
    }
  }

  export function updateData(container, scheduleNextUpdate) {
    project
      .getScheduleBroadcast()
      .then((d) => {
        container.broadcastData.schedule = d[0];
        container.broadcastData.schedule.value1 = d[0].project_delayed_days;
        container.broadcastData.schedule.value2 = d[0].total_real_output;
        container.broadcastData.schedule.value5 = d[0].total_goal_output;
        container.broadcastData.schedule.value3 = d[0].avg_real_output;
        container.broadcastData.schedule.value6 = d[0].avg_goal_output;

        container.statsData = d[0];
        scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1rem;
  font-weight: bold;
  color: #cececf;
}
</style>
