<template>
  <div id = "left">
    <div class="info-block" style="min-height:5rem">
    <div class="info-block-title" 
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >技术应用情况
        <div class="detail" @click="showDetail()">
          详情
        </div>
      </div>
    <div style="height:20rem;padding-top:3rem">
       <div
          style="display:flex;justify-content:space-around;margin-bottom:0.6rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')"
          >
            新技术应用情况
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')"
          >
            BIM应用情况
          </div>
        </div>
        <div v-if="companyWorkersOption == '1'">
          <div style="margin-top:0; height:230px;overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:20rem;text-align:center">新技术项目名称</div>
                <div style="width:8rem">应用数量</div>
                <div style="width:5rem">应用率</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item) in newTechList"
                  :key="item.orgId"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                >
                  <div style="width:20rem"><a style="color:#fff" href = "#" v-bind:title = item.orgName>{{item.xjsmc}}</a></div>
                  <div style="width:8rem">
                    {{item.num}}
                  </div>
                  <div style="width:5rem;">{{item.rate}}%</div>
                </div>
                <div
                  v-if="!(newTechList && newTechList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>
        </div>
        <div v-if="companyWorkersOption == '2'">
          <div style="margin-top:0;height:230px;overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:15rem;text-align:center">分公司</div>
                <div style="width:15rem">应用数量</div>
                <div style="width:5rem">应用率</div>
              </div>
              <div style="height:20rem">
                <div
                  v-for="(item) in bimTechList"
                  :key="item.orgId"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                >
                  <div style="width:15rem;text-aligh:center">{{item.orgName}}</div>
                  <div style="width:15rem">
                    总-{{item.sum}} / A-{{item.suma}} / B-{{item.sumb}}
                  </div>
                  <div style="width:5rem;">{{item.rate}}%</div>
                </div>
                 <div
                  v-if="!(bimTechList && bimTechList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>
        </div>
      </div>
      <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold;width:50rem">{{popupTitle}}
            </div>
            <!-- <div class="tabbar" style="width:22rem">
              <div
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >正常（{{normal}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 5 }"
                @click="getAttendance(5)"
              >缓建（{{wait}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 4 }"
                @click="getAttendance(4)"
              >停工（{{stop}}）</div>
            </div> -->
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:8rem;text-align:center">新技术分类</div>
                <div style="width:8rem;text-align:center">新技术名称</div>
                <div style="width:8rem;text-align:center">应用数量</div>
                <div style="width:8rem;text-align:center">应用率</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popProjectsList"
                  :key="item.orgId"
                  style="display:flex; height: 2rem;text-align:center; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:8rem;text-align:center">{{item.xjsfl}}</div>
                  <div style="width:8rem;text-align:center">{{item.xjsmc}}</div>
                  <div style="width:8rem;text-align:center">{{item.num}}</div>
                  <div style="width:8rem;text-align:center">{{item.rate}}%</div>
                </div>
                <div
                  v-if="!(popProjectsList && popProjectsList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>
        </div>
      </wrapper>
    </el-dialog>
    <!-- 新技术应用情况穿透 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow2=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle}}
            </div>
            <!-- <div class="tabbar" style="width:22rem">
              <div
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >正常（{{normal}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 5 }"
                @click="getAttendance(5)"
              >缓建（{{wait}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 4 }"
                @click="getAttendance(4)"
              >停工（{{stop}}）</div>
            </div> -->
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:15rem;text-align:center">分公司</div>
                <div style="width:8rem;text-align:center">应用覆盖率</div>
                <div style="width:8rem;text-align:center">A级项目</div>
                <div style="width:8rem;text-align:center">B级项目</div>
                <div style="width:8rem;text-align:center">C级项目</div>
                <div style="width:8rem;text-align:center">项目总数</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popProjectsList"
                  :key="item.orgId"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:15rem;text-align:center">{{item.orgName}}</div>
                  <div style="width:8rem;text-align:center">{{item.rate}}</div>
                  <div style="width:8rem;text-align:center">{{item.suma}}</div>
                  <div style="width:8rem;text-align:center">{{item.sumb}}</div>
                  <div style="width:8rem;text-align:center">{{item.subc}}</div>
                  <div style="width:8rem;text-align:center">{{item.sum}}</div>
                </div>
                <div
                  v-if="!(popProjectsList && popProjectsList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { tech } from '../../../../api_tech'
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'newTechChart',
    components: {
      wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      orgOptions(newValue, oldValue) {
        if (newValue != oldValue) {
          if (newValue != oldValue) {
          this.switchCompanyWorkersOption(this.companyWorkersOption);
          }
        }
      },
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.switchCompanyWorkersOption(this.companyWorkersOption);
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.switchCompanyWorkersOption(this.companyWorkersOption);
        }
      }
    },
    data () {
      return {
        companyWorkersOption:"1",
        newTechList:[],
        bimTechList:[],
        popupShow:false,
        popupShow2:false,
        popProjectsList:[],
        popupTitle:""
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.switchCompanyWorkersOption("1");
    },
    methods: {
      switchCompanyWorkersOption(choose){
        this.companyWorkersOption = choose;
        if(this.companyWorkersOption == "1"){
          this.bimTechList = [];
          tech.getNewTech().then((d) => {
            this.newTechList = d;
            }).catch((e) => console.error(e.msg || e.message));
        }else{
          //getBimTech
          this.newTechList = [];
          tech.getBimTech().then((d) => {
            this.bimTechList = d;
            }).catch((e) => console.error(e.msg || e.message));
        }
      },
      showDetail(){
        this.popProjectsList = [];
        if(this.companyWorkersOption == "1"){
          this.popupShow = true;
          tech.getNewTech().then((d) => {
            this.popupTitle = "新技术应用情况";
            if(d.length == 0 || d[0].orgName == "")
              return;
            this.popProjectsList = d;
            }).catch((e) => console.error(e.msg || e.message));
        }else{
          tech.getBimTech().then((d) => {
            this.popupTitle = "BIM应用情况";
            if(d.length == 0 || d[0].orgName == "")
              return;
            this.popProjectsList = d;
            }).catch((e) => console.error(e.msg || e.message));
          this.popupShow2 = true;
        }
      }
    }
  }
</script>

<style lang="less">
</style>
