<template>
  <div id="right">
    <schemeClarificaitonChart :district-level="districtLevel" :org-level="orgLevel" />
    <SchemeCategoryChart :district-level="districtLevel" :org-level="orgLevel" />
    <GoodPlanChart :district-level="districtLevel" :org-level="orgLevel" />
  </div>
</template>

<script>
  import schemeClarificaitonChart from "./schemeClarificaitonChart";
  import SchemeCategoryChart from "./SchemeCategoryChart";
  import GoodPlanChart from "./goodPlanChart";

  export default {
    name: 'QualityIndexRightBar',
    components: {
      schemeClarificaitonChart,
      SchemeCategoryChart,
      GoodPlanChart
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
  }
</script>

<style lang="less">
</style>
