<template>
  <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>重点工程执行情况</div>
        <div class="detail" @click="showDetail()">
          详情
        </div></div>
    <div style="height:25rem;padding-top:2rem">
      <div v-if="!majorProjectChart.series || !majorProjectChart.series[0].data.length" style="padding-top:5rem">
        <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;">
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
      </div>
      <v-chart v-else :options="majorProjectChart" autoresize />
    </div>
   <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <!-- <div class="tabbar" style="width:22rem">
              <div
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >正常（{{normal}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 5 }"
                @click="getAttendance(5)"
              >缓建（{{wait}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 4 }"
                @click="getAttendance(4)"
              >停工（{{stop}}）</div>
            </div> -->
            <!-- <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem;">
              <CustomSelect :options="companyNameList" :value="selectVal1" @input="companySelect($event, 'val1')" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;" />
            </div> -->
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:15rem;text-align:center">分公司</div>
                <div style="width:20rem;text-align:center">重点/示范工程/课题数量</div>
                <div style="width:8rem;text-align:center">验收数量</div>
                <div style="width:8rem;text-align:center">成果形式</div>
                <div style="width:8rem;text-align:center">计划成果数量</div>
                <div style="width:8rem;text-align:center">完成成果数量</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popProjectsList"
                  :key="item.orgId"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:15rem;text-align:center">{{item.orgName}}</div>
                  <div style="width:20rem;text-align:center">{{item.zdgcNum}}</div>
                  <div style="width:8rem;text-align:center">{{item.zdgcYcNum}}</div>
                  <div v-for="(item) in popProjectsList.cgqk"
                  :key="item.orgId">
                    <div style="width:8rem;text-align:center">{{item.CGXS}}</div>
                    <div style="width:8rem;text-align:center">{{item.JHCGSUM}}</div>
                    <div style="width:8rem;text-align:center">{{item.WCCGSUM}}</div>
                  </div>
                </div>
                <div
                  v-if="!(popProjectsList && popProjectsList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { tech } from '../../../../api_tech'
  import { stackedBarChartTemplate } from "../../barChartTemplates";
  import { applyUpdate } from "../../../../utils";
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'majorProjectChart',
    components: {
      'v-chart': ECharts,
      wrapper
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateMajorProjectChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateMajorProjectChart();
        }
      }
    },
    data () {
      return {
        majorProjectChart: {},
        popupShow:false,
        popProjectsList:[]
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.updateMajorProjectChart();
    },
    methods: {
      updateMajorProjectChart() {
        tech.getMajorProject().then((res=>{
          const orgList = [];
          const total = [];
          const check = [];
          const planAchi = [];
          const completeAchi = [];
          for (let i = 0; i < res.length; i++) {
            orgList.push(res[i].orgName);
            total.push(res[i].zdgcNum);
            check.push(res[i].zdgcYcNum);
            planAchi.push(res[i].cgNum);
            completeAchi.push(res[i].cgwcNum);
          }
          const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
          const option = {
              color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                }
              },
              legend: {
                icon: 'rect',
                itemWidth: 12,
                itemHeight: 12,
                itemGap: 12,
                textStyle: {
                  color: '#ccc',
                  fontWeight: 'bold',
                  fontSize: '11'
                },
                padding:[30,0,0,0]
              },
              grid: {
                containLabel: true,
                left: '5%',
                right: '6%',
                bottom: "5%",
                top: "15%"
              },
              xAxis: {
                type: 'value',
                axisLabel:{ interval:3 }
              },
              yAxis: {
                type: 'category',
                data: orgList,
                axisLabel:{
                  color:"white"
                }
              },
              series: [
                {
                  name: '工程总数',
                  type: 'bar',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: total
                },{
                  name: '工程总数',
                  type: 'bar',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: total
                },{
                  name: '验收数量',
                  type: 'bar',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: check
                },{
                  name: '计划成果',
                  type: 'bar',
                  stack: 'achi',
                  label: {
                    show: planAchi
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: total
                },{
                  name: '完成成果',
                  type: 'bar',
                  stack: 'achi',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: completeAchi
                }
              ]
          }
          this.majorProjectChart = applyUpdate(chart, option);
        }))
      },
      showDetail(){
        tech.getMajorProject().then(res=>{
          this.popupShow = true;
          this.popProjectsList =res;
        })
      }
    }
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
