<template>
  <div id="left">
    <personalStructure :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <operatingRevenueChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" :org-options="orgOptions" />
    <wageRateOddsChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
  </div>
</template>

<script>
  import personalStructure from "./personalStructure.vue";
  import operatingRevenueChart from "./operatingRevenueChart";
  import wageRateOddsChart from "./wageRateOddsChart";

  export default {
    name: 'HrIndexLeftBar',
    components: {
      personalStructure,
      operatingRevenueChart,
      wageRateOddsChart,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
  }
</script>

<style lang="less">
</style>
