<template>
  <div id="right">
    <wrapper>
      <div style="display:flex;align-items:center">
        <div style="font-size:1em;flex:1">近七日报警数</div>
        <div class="option-block option-block-selected" style="font-size:0.7rem;min-width:0" @click="() => showAlarmDetail(1)">详情</div>
      </div>
      <div style="height:15.5rem;padding-top:1rem;margin-bottom:-0.3rem">
        <v-chart :options="sevenDayAlarmsLineChart" autoresize />
      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem">
      <div style="display:flex;align-items:center">
        <div style="font-size:1em;flex:1">近七日预警数</div>
        <div class="option-block option-block-selected" style="font-size:0.7rem;min-width:0" @click="() => showAlarmDetail(2)">详情</div>
      </div>
      <div style="height:15.5rem;padding-top:1rem;margin-bottom:-0.3rem">
        <v-chart :options="sevenDayWarningsLineChart" autoresize />
      </div>
    </wrapper>
    <div id="people-warning-list" style="margin-top: 1rem">
      <wrapper>
        <div style="font-size:1em">今日预警记录</div>
        <div style="height:3.5rem;background-color:#1b2d5d;margin:0.5rem 0;padding:0.3rem;display:flex;flex-direction:column;justify-content:space-around;font-size:0.75rem;">
          <div style="display:flex">
            <div style="margin:0.3rem 0.5rem;flex:1;display:flex">
              <div style="flex:3;font-weight:600;color:#42a9da">坠落报警</div>
              <div style="flex:1;text-align:right">{{warningStats['坠落']}}</div>
            </div>
            <div style="margin:0.3rem 0.5rem 0.3rem 2rem;flex:1;display:flex">
              <div style="flex:3;font-weight:600;color:#42a9da">紧急呼救</div>
              <div style="flex:1;text-align:right">{{warningStats['呼救']}}</div>
            </div>
          </div>
          <div style="display:flex">
            <div style="margin:0.3rem 0.5rem;flex:1;display:flex">
              <div style="flex:3;font-weight:600;color:#42a9da">危险预警</div>
              <div style="flex:1;text-align:right">{{warningStats['预警']}}</div>
            </div>
            <div style="margin:0.3rem 0.5rem 0.3rem 2rem;flex:1;display:flex">
              <div style="flex:3;font-weight:600;color:#42a9da">静止预警</div>
              <div style="flex:1;text-align:right">{{warningStats['静止']}}</div>
            </div>
          </div>
        </div>
        <div style="height: 11.2rem; overflow-y: scroll">
          <div class="warning-item" v-for="item in warningList" :key="item.id">
            <div>
              <div style="display:flex">
                <div class="name">姓名:</div>
                <div class="value">{{item.worker_name}}</div>
              </div>
              <div style="display:flex">
                <div class="name">位置:</div>
                <div class="value">{{item.location}}</div>
              </div>
            </div>
            <div style="margin-left:0.3rem;">
              <div style="display:flex">
                <div class="name">班组:</div>
                <div class="value">{{item.team_name}}</div>
              </div>
              <div style="display:flex">
                <div class="name">状态:</div>
                <div class="value" :class="'text-' + (statusColorMap[item.alarm_type] || {}).cls">{{item.alarm_type}}</div>
              </div>
            </div>
            <div style="width:2.5rem;margin-left:0.3rem;align-self:center"><time>{{item.createdtime}}</time></div>
          </div>
        </div>
      </wrapper>
      <el-dialog custom-class="stats-layer" :visible.sync="alarmDetailShow" width="1500px" top="15rem">
        <wrapper style="height:457px;backgroundColor:#0e1328;position:relative">
          <iframe :src="alarmDetailShow ? `http://smarthat.comprehend.com.cn/html/screen/warning_list.html?project_id=${$route.params.id}&type_index=${alarmDetailType}&token=${$route.query.token}` : ''" width="1500px" height="457px" style="border:0;position:absolute;left:0;top:0"></iframe>
          <div class="stats-close-btn" @click="alarmDetailShow = false" />
        </wrapper>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { project } from '../../api'
  import wrapper from '../../components/wrap'
  import { singleLineChartTemplate } from './lineChartTemplates';

  function addOverflowTooltip(selector) {
    setTimeout(() => {
      document.querySelectorAll(selector).forEach(function(item) {
        if (!item.getAttribute('listener')) {
          item.addEventListener('mouseenter', function() {
            let newAttribute;
            if (item.offsetWidth < item.scrollWidth) {
              if (item.getAttribute('title') !== item.innerHTML) {
                newAttribute = item.innerHTML;
              } else {
                return;
              }
            } else {
              newAttribute = '';
            }
            item.setAttribute('title', newAttribute);
          });
          item.setAttribute('listener', true);
        }
      });
    }, 30);
  }

  export default {
    name: 'EmergencyRightBar',
    components: {
      'v-chart': ECharts,
      wrapper,
    },
    data () {
      return {
        sevenDayAlarmsLineChart: {},
        sevenDayWarningsLineChart: {},
        warningStats: {},
        warningList: [],
        statusColorMap: {
          正常: { value: '#1fc86b', cls: 'green' },
          呼救: { value: '#f34e53', cls: 'red2' },
          坠落: { value: '#e62c31', cls: 'red1' },
          预警: { value: '#eebc34', cls: 'yellow2' },
          禁区预警: { value: '#eebc34', cls: 'yellow2' },
          危险预警: { value: '#eebc34', cls: 'yellow2' },
          闯入禁区: { value: '#eebc34', cls: 'yellow2' },
          静止: { value: '#eebc34', cls: 'yellow2' },
          倒地: { value: '#F89132', cls: 'yellow1' },
          离线: { value: '#9ca0aa', cls: 'grey' },
          没电: { value: '#eebc34', cls: 'yellow2' }
        },
        alarmDetailShow: false,
        alarmDetailType: 1,
        timer: null,
      }
    },
    mounted () {
      const { id: projectID } = this.$route.params;
      const token = this.$route.query.token;
      window.token = token;

      this.updateSevenDayAlarmsLineCharts(projectID);

      project.getWarningStats(projectID)
        .then(d => {
          for (const item of d) {
            this.warningStats[item.alarm_type] = item.count;
          }
        })
        .catch(e => console.error(e.msg || e.message))

      project
        .getWarningList(projectID)
        .then(d => {
          this.warningList = d;
          addOverflowTooltip('#people-warning-list .value');
        })
        .catch(e => console.error(e.msg || e.message))

      this.timer = setInterval(() => {
        this.updateSevenDayAlarmsLineCharts(projectID);
        project
          .getWarningList(projectID)
          .then(d => {
            this.warningList = d;
            addOverflowTooltip('#people-warning-list .value');
          })
          .catch(e => console.error(e.msg || e.message))
        project.getWarningStats(projectID)
          .then(d => {
            for (const item of d) {
              this.warningStats[item.alarm_type] = item.count;
            }
          })
          .catch(e => console.error(e.msg || e.message))
      }, 1000*15);
    },
    methods: {
      updateSevenDayAlarmsLineCharts(projectID) {
        project.getSevenDayAlarms(projectID)
          .then(d => {
            const rgb_colors = [
              ['227', '42', '44'],
              ['224', '132', '45'],
              ['40', '204', '232'],
              ['23', '166', '232']
            ];

            let chart = JSON.parse(JSON.stringify(singleLineChartTemplate));
            chart.color = ['#e32a2c', '#e0842d', '#28cce8', '#17a6e8'];
            chart.legend.top = 0;
            chart.grid.top = '26%';
            chart.grid.left = '8%';
            chart.grid.bottom = '0';
            chart.xAxis.data = d['active_alarm'].xAxis_data;
            chart.yAxis[0].minInterval = 1;
            chart.yAxis[0].name = '数量';
            chart.yAxis[0].nameTextStyle = {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            };
            chart.series[0] = Object.assign({}, chart.series[0], d['active_alarm'].series_data[0], {
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(' + rgb_colors[0].join(', ') + ', 0.5)'},
                  {offset: 1, color: 'rgba(' + rgb_colors[0].join(', ') + ', 0)'}
                ])
              }
            });
            for (let i = 1; i < d['active_alarm'].series_data.length; i++) {
              chart.series.push(Object.assign({}, chart.series[0], d['active_alarm'].series_data[i], {
                areaStyle: {
                  color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                    {offset: 0, color: 'rgba(' + rgb_colors[i % rgb_colors.length].join(', ') + ', 0.5)'},
                    {offset: 1, color: 'rgba(' + rgb_colors[i % rgb_colors.length].join(', ') + ', 0)'}
                  ])
                }
              }));
            }
            this.sevenDayAlarmsLineChart = chart;

            chart = JSON.parse(JSON.stringify(singleLineChartTemplate));
            chart.color = ['#e32a2c', '#e0842d', '#28cce8', '#17a6e8'];
            chart.legend.top = 0;
            chart.grid.top = '37%';
            chart.grid.left = '8%';
            chart.grid.bottom = '0';
            chart.xAxis.data = d['warning_alarm'].xAxis_data;
            chart.yAxis[0].minInterval = 1;
            chart.yAxis[0].name = '数量';
            chart.yAxis[0].nameTextStyle = {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            };
            chart.series[0] = Object.assign({}, chart.series[0], d['warning_alarm'].series_data[0], {
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(' + rgb_colors[0].join(', ') + ', 0.5)'},
                  {offset: 1, color: 'rgba(' + rgb_colors[0].join(', ') + ', 0)'}
                ])
              }
            });
            for (let i = 1; i < d['warning_alarm'].series_data.length; i++) {
              chart.series.push(Object.assign({}, chart.series[0], d['warning_alarm'].series_data[i], {
                areaStyle: {
                  color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                    {offset: 0, color: 'rgba(' + rgb_colors[i % rgb_colors.length].join(', ') + ', 0.5)'},
                    {offset: 1, color: 'rgba(' + rgb_colors[i % rgb_colors.length].join(', ') + ', 0)'}
                  ])
                }
              }));
            }
            this.sevenDayWarningsLineChart = chart;

          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
      showAlarmDetail(type) {
        this.alarmDetailType = type;
        this.alarmDetailShow = true;
      },
    },
    beforeDestroy() {
      clearInterval(this.timer);
    }
  }
</script>

<style scoped lang="less">
.text-green {
  color: #1fc86b !important;
}

.text-yellow1 {
  color: #f89132 !important;
  border-color:#f89132 !important;
}

.text-yellow2 {
  color: #eebc34 !important;
  border-color:#eebc34 !important;
}

.text-red1 {
  color: #e62c31 !important;
}

.text-red2 {
  color: #f34e53 !important;
}

.text-grey {
  color: #9ca0aa !important;
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}

#people-warning-list {
  .warning-item {
    margin-bottom: 0.75rem;
    padding: 8px 6px;
    font-size: 0.75rem;
    line-height: 1.5rem;
    background-color: #1e2c61;
    border-radius: 0.25rem;
    color: #ffffff;
    position: relative;
    display: flex;
    div.name {
      color: #999999;
      width: 2rem;
    }
    div.value {
      width: 3.7rem;
      padding-left: 0.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    time {
      background-color: #577acc;
      border-radius: 4px;
      padding: 5px;
    }
  }
}
</style>
