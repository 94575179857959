<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  />
</template>

<script>
  import Broadcasts from "../../../../components/broadcasts";
  import { quality } from "../../../../api_quality";

  export default {
    name: 'QualityIndexBroadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['高端观摩工地', '省级观摩工地', '市级观摩工地'],
        broadcastColors: ['#fea453', '#ffd454', '#46a0ff'],
        broadcastIcons: ['高端观摩工地', '省级观摩工地', '市级观摩工地'],
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    },
  }

  export function updateData(container, scheduleNextUpdate) {
    quality
      .getMeritOrders()
      .then((d) => {
        container.broadcastData.quality = d;
        container.broadcastData.quality.value1 = d.gdNum;
        container.broadcastData.quality.value2 = d.fsfNum;
        container.broadcastData.quality.value3 = d.fcsNum;
        container.statsData = d;
        // scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
