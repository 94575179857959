<template>
  <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title">工程整体合格率</div>
    <div
      class="detail-select"
      style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
    >
      <CustomSelect
        :options="dangerCategoryNameList"
        :value="selectedDangerCategoryName"
        @input="categorySelect"
        style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
      />
    </div>
    <div style="height:25rem">
      <div v-if="!passRateBarChart.series || !passRateBarChart.series[0].data.length" style="padding-top:5rem">
        <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;">
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
      </div>
      <v-chart v-else :options="passRateBarChart" autoresize />
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { nation } from '../../../../api'
  import CustomSelect from '../../../../components/select'
  import { SimpleBarChartTemplate } from "../../barChartTemplates";
  import { applyUpdate } from "../../../../utils";

  export default {
    name: 'PassRateBarChart',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerPassRateChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerPassRateChart();
        }
      }
    },
    data () {
      return {
        dangerCategoryNameList: ['全部类别'],
        dangerCategoryIdList: [''],
        selectedDangerCategoryName: '全部类别',

        passRateBarChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      nation.getSafetyDangerTypes()
        .then(d => {
          this.dangerCategoryNameList = ['全部类别'].concat(d.map(item => item.name));
          this.dangerCategoryIdList = [''].concat(d.map(item => item.innerid));
        })
        // eslint-disable-next-line
        .catch(e => console.error(e.msg || e.message))
    },
    methods: {
      categorySelect(option) {
        this.selectedDangerCategoryName = option;
        this.updateSafetyDangerPassRateChart();
      },
      updateSafetyDangerPassRateChart() {
        let categoryId = '';
        const idx = this.dangerCategoryNameList.indexOf(this.selectedDangerCategoryName);
        if (idx >= 0) {
          categoryId = this.dangerCategoryIdList[idx];
        }
        nation.getSafetyDangerPassRates(categoryId)
          .then(d => {
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));

            const option = {
              color: ["#2ad156"],
              tooltip: {
                trigger: "axis",
                formatter: "{b}<br>{a0}: {c0}",
              },
              grid: {
                top: "15%",
                left: "10%",
                right: "9%",
                bottom: "15%",
                containLabel: true,
              },
              xAxis: [
                {
                  type: "category",
                  data: [],
                  axisLabel: {
                    color: "#e6f2f3",
                    rotate: 45,
                    fontWeight: "normal",
                    formatter: function (value) {
                      return value.length > 8 ? value.slice(0, 8) + "..." : value;
                    },
                  },
                },
              ],
              yAxis: [
                {
                  show: true,
                  name: "合格率：%",
                  type: "value",
                  max: 100,
                  nameTextStyle: {
                    padding: [0, 40, 0, 0],
                    color: "#e6f2f3",
                    fontWeight: "bold",
                    align: "left",
                  },
                  boundaryGap: [0, "20%"],
                  axisLabel: {
                    color: "#e6f2f3",
                    fontWeight: "bold",
                  },
                  axisLine: { show: true, lineStyle: { color: "#36445f" } },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: "#36445f",
                      width: 1,
                      type: "solid",
                    },
                  },
                  axisTick: { show: false },
                },
              ],
              series: [
                {
                  name: "合格率",
                  type: "bar",
                  barWidth: 10,
                  barMaxWidth: 18,
                }
              ],
            };

            option.xAxis[0].data = d.xAxis_data;
            option.series[0].data = d.series_data;
            this.passRateBarChart = applyUpdate(chart, option);
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
    },
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
