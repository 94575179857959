<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  />
</template>

<script>
  import Broadcasts from "../../../../components/broadcasts";
  import { human } from "../../../../api_hr";

  export default {
    name: 'TalentTeamBroadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['D级/M级人数','项目班子(后备)','基础设施/海外'],
        broadcastColors: ['#40f8ff', '#ffd454', '#38c59e'],
        broadcastIcons: ['DM人员','项目班子','基础设施'],
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    },
  }

  export function updateData(container, scheduleNextUpdate) {
    let level = human.getOrgLevel();
    if (level == null || level == undefined)
      level = 1;
      human.getTalentStatistic(level).then(rs=>{
        container.broadcastData.talentTeam = rs;
        container.broadcastData.talentTeam.value1 = rs[0].value;
        container.broadcastData.talentTeam.value2 = rs[1].value;
        container.broadcastData.talentTeam.value3 = rs[2].value;
        container.statsData = rs;
        // scheduleNextUpdate();
      })
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
