<template>
  <div id="left">
    <wrapper style="padding:0">
      <div
        class="info-block"
        style="min-height:5rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>本月维保完成情况</div>

        </div>
        <div style="height:19rem;padding-top:3rem;display: flex;">
          <!-- <div style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"><CustomSelect :options="companyNameList" @input="companySelect" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold" /></div> -->

          <div
            v-if="!attendanceBarChart.length"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <div v-else>

            <div style="font-size:12px;color:#fff;padding-left:20px;">任务数：个</div>
            <div
              class="linebar"
              v-for="(item, index) in attendanceBarChart"
              :key="index"
            >
              <div style="color: #A7B6BC;width: 180px">
                {{item.company_name}}
              </div>
              <div
                style="flex: 5"
                class="line-bar"
              >
                <div
                  class="real-bar"
                  :style="{'width': (item.count / item.total_count * 100)  + '%' }"
                ></div>
              </div>
              <div style="color: #57C7B6;width:30px">
                {{item.count}}/{{item.total_count}}
              </div>
            </div>
          </div>
        </div>
      </div>

    </wrapper>
    <wrapper style="margin-top: 1rem;padding:0">

      <div
        class="info-block"
        style="min-height:5rem"
      >
        <div class="info-block-title">设备出厂年限</div>

        <div style="height:18rem;padding-top:3rem;display: flex; align-items: center;">

          <div
            v-if="machineOutYear.series && [].concat.apply([], machineOutYear.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart
            v-else
            :options="machineOutYear"
            ref="troubleType1"
            autoresize
          />
        </div>
      </div>
    </wrapper>

  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import wrapper from "../../components/wrap";
import { project } from "../../api";
import { stackedBarChartTemplate } from "./barChartTemplates";
import { applyUpdate } from "../../utils";

export default {
  name: "SecurityMechLeftBar",
  components: {
    "v-chart": ECharts,
    wrapper,
  },
  props: {
    projectDetail: Object,
    showTrace: Function,
  },
  data() {
    return {
      projectId: null,
      attendanceBarChart: [],
      machineOutYear: {},
      option1: {
        color: ["#29e8a8", "#ffed21", "#ff8521"],
        tooltip: {
          // show: false,
          // trigger: "none",
        },
        legend: {
          top: "2%",
          right: "30%",
          data: [],
        },
        dataZoom: [
          {
            type: "inside",
            // slider
            show: true,
            start: 60,
            end: 10,
            yAxisIndex: 0,
            filterMode: "empty",
            width: 10,
            height: "80%",
            left: "95%",
            handleSize: 8,
            zoomLoxk: true,
            top: "middle",
            textStyle: {
              color: "#fff",
              fontSize: 10,
            },
          },
        ],
        grid: {
          top: "10%",
          left: "2%",
          right: "9%",
          bottom: "15%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "#e6f2f3",
            rotate: 0,
            fontWeight: "normal",
          },
          // 显示分隔线
          splitLine: {
            show: true,
          },
        },
        yAxis: [
          {
            data: [],
            nameRotate: "",
            nameTextStyle: {
              color: "#ccc",
              align: "right",
            },
            axisTick: { show: false },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            type: "category",
            axisLabel: {
              color: "#ffffff",
              formatter: function (value) {
                return value.length > 8 ? value.slice(0, 8) + "..." : value;
              },
            },
          },
        ],
        series: [],
      },
    };
  },
  mounted() {
    // const self = this
    const { id: projectID } = this.$route.params;
    this.projectId = projectID;
    const token = this.$route.query.token;
    window.token = token;
    this.getAll();
  },
  methods: {
    getAll() {
      project.getProjMachineMaintenanceCollect(this.projectId).then((d) => {
        this.attendanceBarChart = d;
        console.log(d,'===')
      });

      this.getEcharts2();
    },
    getEcharts2() {
      project
        .getProjMachineProduceYearCollect(this.projectId)
        .then((d) => {
          // console.log(d, "1111111111111111111");
          const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));

          this.option1.yAxis[0].data = d.yAxis_data;
          this.option1.series = d.series.map((item, index) => {
            return {
              name: d.legend_data[index],
              type: "bar",
              stack: "total",
              barMaxWidth: 18,
              emphasis: {
                focus: "series",
              },
              data: item,
            };
          });
          this.option1.legend.data = d.legend_data;

          this.machineOutYear = applyUpdate(chart, this.option1);
          // console.log(this.machineOutYear, "----");
        })
        .catch((e) => console.error(e.msg || e.message));
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.linebar {
  display: flex;
  align-items: baseline;
  margin: 1.3rem auto 10px;
  font-size: 12px;
}
.line-bar {
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
  min-width: 12rem;
  border-radius: 12px;
}
.real-bar {
  height: 5px;
  background-color: #57c7b6;
  border-radius: 12px;
}
.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>