<template>
  <div id="project" class="global-bg">
    <div id="title">{{projectInfo.name}}</div>
    <div id="system-info">
      <div class="item">
        <p class="upper">{{systemInfo.time}}</p>
        <p class="lower">{{systemInfo.date}}</p>
      </div>
      <div class="item">
        <p class="upper">{{systemInfo.weekDay}}</p>
        <p class="lower">{{systemInfo.lunar}}</p>
      </div>
    </div>

    <div id="main">
      <div id="left">
        <div id="project-base-info" style="margin-bottom: 1rem;">
          <wrapper>
            <s-title :title="projectInfo.name" />
            <div style="height: 23rem;padding-top: 10px;">
              <div style="height: 14.25rem; overflow-y: scroll;">
                <p>
                  <span class="title">地址</span>
                  <span class="value">{{projectInfo.location}}</span>
                </p>
                <p>
                  <span class="title">负责人</span>
                  <span class="value">{{projectInfo.head_person}}</span>
                </p>
                <p>
                  <span class="title">负责人电话</span>
                  <span class="value">{{projectInfo.head_mobile}}</span>
                </p>
                <p>
                  <span class="title">类型</span>
                  <span class="value">{{projectInfo.type}}</span>
                </p>
                <p>
                  <span class="title">发布时间</span>
                  <span class="value">{{projectInfo.starttime}}</span>
                </p>
                <p>
                  <span class="title">建设周期</span>
                  <span class="value">{{projectInfo.endtime}}</span>
                </p>
                <p>
                  <span class="title">建筑面积</span>
                  <span class="value">{{projectInfo.built_up_area}}万平</span>
                </p>
              </div>
              <div>
                <p>
                  <span class="title">施工进度</span>
                </p>
                <p class="projectProcess">
                  <v-chart :options="projectProcess" ref="projectProcess" style="height: 4rem;" />
                </p>
                <p style="line-height: 1.5; display: flex; justify-content: space-between">
                  <span>开工时间 <br>{{projectInfo.starttime}}</span>
                  <span>预计竣工时间 <br>{{projectInfo.endtime}}</span>
                </p>
              </div>
            </div>
          </wrapper>
        </div>
        <div id="people-warning-list">
          <wrapper>
            <s-title title="预警报警列表" />
            <div style="height: 27rem; overflow-y: scroll; padding-top: 10px">
              <div class="warning-item" v-for="item in warningList" :key="item.id">
                <div style="flex: 1">
                  <p>
                    <span class="name">姓名：</span>
                    <span class="value">{{item.worker_name}}</span>
                  </p>
                  <p>
                    <span class="name">位置：</span>
                    <span class="value">{{item.location}}</span>
                  </p>
                </div>
                <div style="flex: 1">
                  <p>
                    <span class="name">班组：</span>
                    <span class="value">{{item.team_name}}</span>
                  </p>
                  <p>
                    <span class="name">状态：</span>
                    <span class="value" :class="'text-' + (statusColorMap[item.alarm_type] || {}).cls">{{item.alarm_type}}</span>
                  </p>
                </div>
                <time style="position:absolute; right: 0; top: 0;">{{item.createdtime}}</time>
                <div class="wxcallback" :style="item.is_recalled?'background-color: #9fabc1;':''" @click="wcallback(item)">{{item.recalled_txt}}</div>
              </div>
            </div>
          </wrapper>
        </div>
      </div>

      <div id="middle">
        <div id="map">
          <wrapper>
            <div class="project_map" id="mapid" :style="{backgroundImage: `url(${projectImage})`, height: isUp ? '35.5rem': '55rem'}">

              <!-- <div class="building-info" v-for="info in buildingInfo" :key="info.build_id">
            <div class="project_map" style="height: 35.5rem" :style="{backgroundImage: `url(${projectImage})`}">
             <div  v-if="buildingInfo.length<10">
              <div class="building-info"  v-for="info in buildingInfo" :key="info.build_id"
                   :style="{left: info.location.x / 2 + 'px', top: info.location.y / 2 + 'px'}">

                <h3>
                  <router-link class="building-link"
                               :to="`/building/${info.build_id}/${$route.params.id}?token=${$route.query.token}&project=${projectInfo.name}`">
                    {{info.build_name}}
                  </router-link>
                </h3>

                <p><span class="title">总高度：</span><span class="light-blue-text value">{{info.floor_height}}</span></p>
                <p><span class="title">总楼层：</span><span class="light-blue-text value">{{info.map_count}}F</span></p>
                <p><span class="title">当前工人数：</span><span class="light-blue-text value">{{info.worker_count}}</span></p>
                <div><p>人员分布<span class="light-blue-text">TOP3</span>：</p>
                  <p class="light-blue-text">{{info.map_worker_list}}</p></div>
              </div> -->
            </div>
            <div class="build_list" v-if="map_list.length<3">
              <div class="building-info" v-for="info in buildingInfo" :key="info.build_id">
                <h3>
                  <router-link class="building-link" :to="`/building/${info.build_id}/${$route.params.id}?token=${$route.query.token}&project=${projectInfo.name}`">
                    {{info.build_name}}
                  </router-link>
                </h3>

                <p>
                  <span class="title">总高度：</span>
                  <span class="light-blue-text value">{{info.floor_height}}</span>
                </p>
                <p>
                  <span class="title">总楼层：</span>
                  <span class="light-blue-text value">{{info.map_count}}F</span>
                </p>
                <p>
                  <span class="title">当前工人数：</span>
                  <span class="light-blue-text value">{{info.worker_count}}</span>
                </p>
                <!-- <div>
                  <p>人员分布
                    <span class="light-blue-text">TOP3</span>：</p>
                  <p class="light-blue-text">{{info.map_worker_list}}</p>
                </div> -->
              </div>
            </div>
            <div class="map_list">
              <div v-for="(m,index) in map_list" :key="m.name" @click="changeMap(m.image_url,m.type,m.build_id,index)" :style="(s_build_id==m.build_id || (index==0 && s_index==0))?'background-color: cornflowerblue;':''">
                <span>{{m.name}}</span>
                <!-- <img class="img" src="../../assets/zk.png" alt=""> -->
              </div>
            </div>
            <div class="menus">
              <!-- <div @click="changeTrouble">
                <span :style="{color: isSelect ? '#45EFDB':'#fff'}">危险源</span>
                <img class="img" v-if="isSelect" src="../../assets/wxy_hover.png" alt="">
                <img class="img" v-else src="../../assets/wxy_normal.png" alt="">
              </div> -->
              <div @click="changeChart" v-if="!isUp">
                <span>展开</span>
                <img class="img" src="../../assets/sq.png" alt="">
              </div>
              <div @click="changeChart" v-else>
                <span>收起</span>
                <img class="img" src="../../assets/zk.png" alt="">
              </div>
            </div>
            <!-- <div v-else class="building-info-other"  v-for="info in buildingInfo" :key="info.build_id"
                   :style="{left: info.location.x / 2 + 'px', top: info.location.y / 2 + 'px'}">
                   <router-link class="building-link"
                               :to="`/building/${info.build_id}/${$route.params.id}?token=${$route.query.token}&project=${projectInfo.name}`">
                    {{info.build_name}}
                  </router-link>
                  </div>
                </div> -->
          </wrapper>
        </div>
        <div id="middle-charts" v-if="isUp">

          <div style="flex: 1; margin-right: 1rem; ">
            <wrapper>
              <s-title title="工期进度" />
              <div style="height: 15.5rem; overflow-y: scroll; overflow-x: hidden">
                <v-chart :options="gongqijindu" ref="gongqijindu" :style="{height: gongqijindu.domHeight || '15.5rem'}" />
              </div>
            </wrapper>
          </div>

          <div style="flex: 1; margin-right: 1rem">
            <wrapper>
              <s-title title="近7日预警统计" />
              <v-chart :options="yujingtongji" ref="yujingtongji" style="height: 15.5rem" />
            </wrapper>
          </div>

          <div style="flex: 1; margin-right: 1rem">
            <wrapper>
              <s-title title="近7日隐患点巡检情况" />
              <v-chart :options="yinhuanxunjian" ref="yinhuanxunjian" style="height: 15.5rem" />
              <div>
              </div>
            </wrapper>
          </div>

          <div style="flex: 1">
            <wrapper>
              <s-title title="近7日考勤人数/考勤率" />
              <v-chart :options="kaoqin" ref="kaoqin" style="height: 15.5rem" />
              <div>
              </div>
            </wrapper>
          </div>

        </div>
      </div>

      <div id="right">

        <div id="live-data" style="margin-bottom: 1rem">
          <wrapper>
            <s-title title="人员/设备情况" />
            <div class="content">
              <p class="item" style="margin-bottom: 2.5rem">
                <span class="img"></span>
                <span class="value">
                  <span class="title">在场人数</span>
                  <span class="number">{{liveData.worker_count}}</span>
                </span>
              </p>
              <p class="item" style="margin-bottom: 2.5rem">
                <span class="img"></span>
                <span class="value">
                  <span class="title">进场人数</span>
                  <span class="number">{{liveData.check_worker_count}}</span>
                </span>
              </p>
              <p class="item">
                <span class="img"></span>
                <span class="value">
                  <span class="title">安全帽领取数</span>
                  <span class="number">{{liveData.hat_count}}</span>
                </span>
              </p>
              <p class="item">
                <span class="img"></span>
                <span class="value">
                  <span class="title">围栏设备数</span>
                  <span class="number">{{liveData.point_count}}</span>
                </span>
              </p>
            </div>
          </wrapper>
        </div>

        <div id="people-status" style="margin-bottom: 1rem">
          <wrapper>
            <s-title title="当日各工种出勤统计" />
            <v-chart :options="classEfficient" ref="classEfficient" @mouseover="classEfficientMouseOver" @mouseout="classEfficientMouseOut" style="height: 16.25rem" />
          </wrapper>
        </div>

        <!--        <div id="equip-status-pie" style="margin-bottom: 1rem">-->
        <!--          <wrapper>-->
        <!--            <s-title title="设备情况"/>-->
        <!--            <v-chart :options="equipStatusChart" ref="equipStatusChart" style="height: 10.625rem"/>-->
        <!--          </wrapper>-->
        <!--        </div>-->

        <div id="alert-bar">
          <wrapper>
            <s-title title="共建方本月考勤率" />
            <v-chart :options="gongjianfangkaoqin" ref="gongjianfangkaoqin" style="height: 17rem" />
          </wrapper>
        </div>

      </div>
    </div>
    <div id="logo">
      <span>智能安全帽系统</span>
      <img src="../../assets/logo.png" alt="" height="26">
    </div>
    <div class="mantle" v-if="show_alarm.is_show"></div>
    <div id="show_alarm" class="show_alarm" v-if="show_alarm.is_show">
      <div class="show_alarm_title">{{show_alarm.title}}</div>
      <div><img src="../../assets/sos.png" class="show_alarm_img" v-if="show_alarm.img_show_alarm"></div>
      <div><img src="../../assets/down.png" class="show_alarm_img" v-if="!show_alarm.img_show_alarm"></div>
      <div class="show_alarm_content">
        <div>{{show_alarm.name}}</div>
        <div>{{show_alarm.location}}</div>
        <div>{{show_alarm.mobile}}</div>
      </div>
      <div class="show_alarm_sure" @click="closealarm">确定</div>
    </div>
    <el-dialog :title="`人员轨迹 - ${pathWorkerName}`" :visible.sync="pathShow" width="60rem" top="10rem">
      <div style="margin: -30px -20px;height:40rem;">
        <iframe :src="`/#/project/path/${pathParams}`" style="width:100%;height:100%;border:0" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
require('echarts-gl')
import wrapper from '../../components/wrap'
import smallTitle from '../../components/small-title'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import { project, wsUrl } from '../../api'
import {
  init,
  changeMap,
  createWebSocket,
  setMapMarker,
  wcallback
} from '../../../public/leaflet.js'

const color = ['#FC619D', '#FF904D', '#48BFE3', '#1990ff', '#28abc2']
const rich = {}
color.forEach((c, i) => {
  rich['a' + i] = {
    color: '#fff',
    backgroundColor: c,
    borderRadius: 100,
    padding: [6, 8]
  }
})

export default {
  name: 'project',
  components: {
    wrapper,
    'v-chart': ECharts,
    's-title': smallTitle
  },
  data() {
    return {
      show_alarm: {
        is_show:false,
        title:"紧急呼救",
        name:"吴德龙（浇铸工组）",
        location:"3号楼32层",
        mobile:"18625893654",
        img_show_alarm:true,
      },
      s_build_id: '',
      s_index: 0,
      statusColorMap: {
        正常: { value: '#1fc86b', cls: 'green' },
        呼救: { value: '#f34e53', cls: 'red2' },
        坠落: { value: '#e62c31', cls: 'red1' },
        预警: { value: '#eebc34', cls: 'yellow2' },
        禁区预警: { value: '#eebc34', cls: 'yellow2' },
        危险预警: { value: '#eebc34', cls: 'yellow2' },
        闯入禁区: { value: '#eebc34', cls: 'yellow2' },
        静止: { value: '#eebc34', cls: 'yellow2' },
        倒地: { value: '#F89132', cls: 'yellow1' },
        离线: { value: '#9ca0aa', cls: 'grey' }
      },
      projectInfo: {
        name: '',
        location: '',
        head_person: '',
        head_mobile: '',
        type: '',
        starttime: '',
        endtime: '',
        built_up_area: '',
        summary: ''
      },
      warningList: [],
      liveData: {
        worker_count: 0,
        check_worker_count: 0,
        hat_count: 0,
        point_count: 0
      },
      classEfficient: {
        color: [
          '#37a2da',
          '#32c5e9',
          '#67e0e3',
          '#9fe6b8',
          '#ffdb5c',
          '#e7bcf3',
          '#8378ea',
          '#96bfff'
        ],
        legend: {
          orient: 'vertical',
          x: 'left',
          left: '78%',
          top: 'center',
          data: [],
          textStyle: {
            color: '#999'
          },
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 8,
          formatter: function(name) {
            return '' + name
          }
        },
        series: [
          {
            type: 'pie',
            minAngle: 2,
            radius: ['40%', '70%'],
            center: ['39%', '50%'],
            avoidLabelOverlap: false,
            itemStyle: {
              normal: {
                borderColor: '#08122b',
                borderWidth: 2
              }
            },
            label: {
              normal: {
                show: false,
                position: 'center',
                formatter: '{text|{b}}\n{c} ({d}%)',
                rich: {
                  text: {
                    color: '#666',
                    fontSize: 14,
                    align: 'center',
                    verticalAlign: 'middle',
                    padding: 8
                  },
                  value: {
                    color: '#8693F3',
                    fontSize: 14,
                    align: 'center',
                    verticalAlign: 'middle'
                  }
                }
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14
                }
              }
            },
            data: []
          }
        ]
      },
      equipStatusChart: {
        title: [
          {
            text: '安全帽',
            left: '23%',
            top: '88%',
            textAlign: 'center',
            textStyle: {
              fontWeight: 'normal',
              fontSize: '14',
              textAlign: 'center',
              color: '#fff'
            }
          },
          {
            text: '围栏设备',
            left: '77%',
            top: '88%',
            textAlign: 'center',
            textStyle: {
              fontWeight: 'normal',
              fontSize: '14',
              textAlign: 'center',
              color: '#fff'
            }
          }
        ],
        graphic: {
          elements: [
            {
              type: 'image',
              id: 'hat',
              style: {
                image: require('../../assets/anquanmao.png'),
                width: 44,
                height: 44
              },
              left: '15%',
              top: '35%'
            },
            {
              id: 'equip',
              type: 'image',
              style: {
                image: require('../../assets/shebei1.png'),
                width: 44,
                height: 44
              },
              left: '69%',
              top: '37%'
            }
          ]
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '58%'],
            center: ['23%', '50%'],
            color: ['#687387', '#db2866'],
            data: [],
            labelLine: {
              normal: {
                show: false,
                length: 20,
                length2: 20,
                lineStyle: {
                  color: '#12EABE',
                  width: 2
                }
              }
            },
            label: {
              normal: {
                show: false
              }
            }
          },
          {
            type: 'pie',
            radius: ['50%', '58%'],
            center: ['77%', '50%'],
            color: ['#687387', '#db2866'],
            data: [],
            labelLine: {
              normal: {
                show: false,
                length: 20,
                length2: 20,
                lineStyle: {
                  color: '#12EABE',
                  width: 2
                }
              }
            },
            label: {
              normal: {
                show: false
                // formatter: '{c|{c}}\n{hr|}\n{d|{d}%}',
              }
            }
          }
        ]
      },
      gongjianfangkaoqin: {
        grid: {
          top: 10,
          left: 0,
          bottom: -20,
          right: 0
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, '20%'],
          show: false
        },
        yAxis: {
          type: 'category',
          show: false,
          inverse: true
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}:{c}%'
        },
        series: [
          {
            data: [],
            type: 'bar',
            barWidth: 10,
            barBorderRadius: 30,
            showBackground: true,
            backgroundStyle: {
              color: '#344a7a',
              barBorderRadius: 30
            },
            itemStyle: {
              barBorderRadius: 30,
              color: val => color[val.dataIndex]
            },
            label: {
              show: true,
              position: [0, '-28px'],
              formatter: a => `{a${a.dataIndex}|${a.dataIndex + 1}} ${a.name} `,
              rich
            }
          }
        ]
      },
      projectProcess: {
        grid: {
          top: 0,
          bottom: -15,
          right: 0,
          contentLabel: true,
          left: 0
        },
        xAxis: {
          type: 'value',
          show: false,
          max: 1
        },
        yAxis: {
          type: 'category',
          show: false,
          data: ['']
        },
        series: [
          {
            showBackground: true,
            backgroundStyle: {
              color: '#344a7a',
              barBorderRadius: 30
            },
            type: 'bar',
            data: [0],
            barWidth: 11,
            itemStyle: {
              barBorderRadius: 30,
              color: '#51bcf5'
            },
            markPoint: {
              symbol: 'pin',
              symbolSize: 45,
              value: 0,
              itemStyle: {
                color: '#51bcf5'
              },
              data: [
                {
                  name: '进度',
                  type: 'max',
                  label: {
                    show: true,
                    formatter: '{c}%'
                  }
                }
              ]
            }
          }
        ]
      },
      mostAlertPeopleChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: '{b}<br/> {c}'
        },
        grid: {
          top: '9%',
          left: -25,
          right: 0,
          bottom: 0,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            color: '#ccc',
            fontSize: 14
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          show: false
        },
        label: {
          show: true,
          position: 'top',
          distance: 5,
          color: '#51bcf5',
          formatter: '{c}'
        },
        series: [
          {
            data: [],
            type: 'bar',
            itemStyle: {
              color: '#B43038',
              barBorderRadius: 100
            },
            barCategoryGap: '50%'
            // barWidth: 12,
          }
        ]
      },
      kaoqin: {
        color: ['#47c49d', '#f4860f'],
        grid: {
          contentLabel: true,
          top: '5%',
          left: '5%',
          right: '5%',
          bottom: '8%'
        },
        tooltip: {
          trigger: 'axis',
          formatter: '{b}<br>{a0}: {c0}<br>{a1}: {c1}%'
        },
        legend: {
          data: ['考勤人数', '考勤率'],
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: [
          {
            axisLabel: {
              color: '#ccc'
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'category',
            data: []
          }
        ],
        yAxis: [
          {
            boundaryGap: [0, '20%'],
            axisLabel: { color: '#ccc' },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
            name: '人数'
          },
          {
            boundaryGap: [0, '10%'],
            axisLabel: { color: '#ccc' },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
            name: '出勤率'
          }
        ],
        series: [
          {
            name: '考勤人数',
            type: 'bar',
            data: []
          },
          {
            name: '考勤率',
            type: 'line',
            symbol: 'circle',
            symbolSize: 8,
            showSymbol: true,
            yAxisIndex: 1,
            data: []
          }
        ]
      },
      yinhuanxunjian: {
        color: ['#47c49d', '#f4860f'],
        grid: {
          contentLabel: true,
          top: '5%',
          left: '5%',
          right: '5%',
          bottom: '8%'
        },
        tooltip: {
          trigger: 'axis',
          formatter: '{b}<br>{a0}: {c0}<br>{a1}: {c1}%'
        },
        legend: {
          data: ['巡检数', '巡检率'],
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: [
          {
            axisLabel: {
              color: '#ccc'
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'category',
            data: []
          }
        ],
        yAxis: [
          {
            boundaryGap: [0, '20%'],
            axisLabel: { color: '#ccc' },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
            name: '巡检数'
          },
          {
            boundaryGap: [0, '10%'],
            axisLabel: { color: '#ccc' },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
            name: '巡检率'
          }
        ],
        series: [
          {
            name: '巡检数',
            type: 'bar',
            data: [],
            itemStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#51b9f5' },
                { offset: 1, color: '#5175f5' }
              ])
            }
          },
          {
            name: '巡检率',
            type: 'line',
            symbolSize: 8,
            symbol: 'circle',
            showSymbol: true,
            yAxisIndex: 1,
            data: []
          }
        ]
      },
      yujingtongji: {
        tooltip: { trigger: 'axis' },
        color: [
          '#f06800',
          '#faf328',
          '#53aeef',
          '#9fd900',
          '#64d8b4',
          '#ecb530'
        ],
        legend: {
          data: [],
          textStyle: { color: '#999' }
        },
        grid: {
          left: 0,
          right: 0,
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          axisLine: { show: true, lineStyle: { color: '#36445f' } },
          axisLabel: { color: '#ccc' },
          splitLine: { show: false },
          axisTick: { show: false }
        },
        yAxis: {
          type: 'value',
          axisLabel: { color: '#ccc' },
          splitLine: { show: false },
          axisTick: { show: false }
        },
        series: []
      },
      classAttendanceChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: 0,
          right: 0,
          top: '9%',
          bottom: 0,
          containLabel: true
        },
        yAxis: {
          type: 'value',
          show: true,
          axisLine: { show: true, lineStyle: { color: '#36445f', width: 2 } },
          splitLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            color: '#fff',
            fontSize: 12
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: { color: '#36445f', width: 2 }
          }
        },
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: 30,
            itemStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#f99e34' },
                { offset: 1, color: '#f5c551' }
              ])
            },
            label: {
              normal: {
                show: true,
                position: 'top',
                color: '#eaa24e'
              }
            },
            data: []
          }
        ]
      },

      systemInfo: {
        time: '',
        date: '',
        weekDay: '',
        lunar: '',
        temperature: '',
        weather: '1',
        timer: null
      },
      buildingInfo: [
        {
          build_id: '',
          build_name: '',
          location: {},
          floor_height: '',
          map_count: 0,
          worker_count: 0,
          map_worker_list: ''
        }
      ],
      projectImage: '',
      gongqijindu: {
        domHeight: '15.5rem',
        xAxis: {
          max: 100,
          show: false
        },
        tooltip: {
          trigger: 'item',
          formatter: p =>
            `${p.data.name}: ${p.data.cur}层 / ${p.data.total}层 (${
              p.data.rate
            }%)`
        },
        grid: {
          left: 50,
          top: 0,
          right: 70,
          bottom: 0,
          contentLabel: true
        },
        yAxis: {
          inverse: true,
          type: 'category',
          show: false,
          data: []
        },
        series: [
          {
            type: 'bar',
            barWidth: 18,
            barMinWidth: 18,
            barGap: '30%',
            barCategoryGap: '30%',
            legendHoverLink: false,
            itemStyle: {
              normal: {
                color: {
                  type: 'linear',
                  colorStops: [
                    {
                      offset: 0,
                      color: '#2980d9'
                    },
                    {
                      offset: 1,
                      color: '#7440ea'
                    }
                  ]
                }
              }
            },
            label: {
              show: true,
              position: 'left',
              formatter: '{b}',
              color: 'white'
            },
            data: [],
            z: 1,
            animationEasing: 'elasticOut'
          },
          {
            type: 'pictorialBar',
            itemStyle: {
              normal: {
                color: '#000'
              }
            },
            label: {
              normal: {
                formatter: v => `${v.data.cur}层 / ${v.data.total}层`,
                color: 'white',
                position: 'right',
                show: true
              }
            },
            symbolRepeat: 'fixed',
            symbolMargin: 6,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [1, 21],
            symbolPosition: 'start',
            symbolOffset: [1, -1],
            symbolBoundingData: 100,
            data: [],
            z: 2,
            animationEasing: 'elasticOut'
          },
          {
            type: 'bar',
            barGap: '-120%',
            barCategoryGap: '20%',
            data: [],
            barWidth: 25,
            barMinWidth: 25,
            itemStyle: {
              color: 'transparent',
              barBorderColor: '#595ef5',
              barBorderWidth: 1,
              label: { show: false }
            },
            z: 0
          }
        ]
      },
      timer: null,
      isUp: false,
      isSelect: false,
      map_list: [],
      img_host: '',
      pathShow: false,
      pathParams: '',
      pathWorkerName: '',
      classEfficientHighlightIndex: 0,
    }
  },
  mounted() {
    const { id: projectID } = this.$route.params
    this.systemInfo.timer = setInterval(() => {
      const d = new Date()
      this.systemInfo.time =
        d
          .getHours()
          .toString()
          .padStart(2, '0') +
        ':' +
        d
          .getMinutes()
          .toString()
          .padStart(2, '0') +
        ':' +
        d
          .getSeconds()
          .toString()
          .padStart(2, '0')
      if (((d * 1) % 864000) * 1000 < 1000 || !this.systemInfo.date) {
        this.systemInfo.date =
          d.getFullYear() +
          '-' +
          (d.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          d
            .getDate()
            .toString()
            .padStart(2, '0')
        this.systemInfo.weekDay =
          '星期' + ['日', '一', '二', '三', '四', '五', '六'][d.getDay()]
        // eslint-disable-next-line no-undef
        const lunar = calendar.solar2lunar()
        this.systemInfo.lunar = lunar.gzYear + lunar.IMonthCn + lunar.IDayCn
      }
    }, 1000)
    project
      .getProjectInfo(projectID)
      .then(d => {
        this.projectInfo = d[0]
        const rate = Math.trunc(
          (new Date() - new Date(d[0].starttime)) /
            (new Date(d[0].endtime) - new Date(d[0].starttime)) *
            100
        )
        this.projectProcess.xAxis.max = 100
        this.projectProcess.series[0].data = [rate]
        this.projectProcess.series[0].markPoint.value = rate
      })
      .catch(e => console.error(e.msg || e.message))
    project
      .getJindu(projectID)
      .then(d => {
        if (!d.length) return
        const names = []
        const outer = []
        const inner = []
        d.forEach(v => {
          names.push(v.name)
          outer.push({
            name: v.name,
            total: v.map_count,
            cur: v.floor,
            rate: v.value,
            value: 100
          })
          inner.push({
            name: v.name,
            total: v.map_count,
            cur: v.floor,
            rate: v.value,
            value: v.value
          })
        })
        this.gongqijindu.domHeight =
          names.length > 6 ? 2.21 * names.length + 'rem' : '15.5rem'
        this.gongqijindu.yAxis.data = names
        this.gongqijindu.series[0].data = inner
        this.gongqijindu.series[1].data = inner
        this.gongqijindu.series[2].data = outer
        this.$nextTick(
          () => this.$refs.gongqijindu && this.$refs.gongqijindu.resize()
        )
      })
      .catch(console.error)
    project
      .getWarningList(projectID)
      .then(d => (this.warningList = d))
      .catch(e => console.error(e.msg || e.message))
    project
      .getLiveData(projectID)
      .then(d => (this.liveData = d))
      .catch(e => console.error(e.msg || e.message))
    project
      .getGongjianfangkaoqin(projectID)
      .then(
        d =>
          (this.gongjianfangkaoqin.series[0].data = d.map(v => ({
            name: v.name,
            value: v.rate
          })))
      )
      .catch(console.error)
    project
      .getKaoqin(projectID)
      .then(d => {
        this.kaoqin.xAxis[0].data = []
        this.kaoqin.series[0].data = []
        this.kaoqin.series[1].data = []
        d.forEach(v => {
          this.kaoqin.xAxis[0].data.push(v.date)
          this.kaoqin.series[0].data.push(v.attendacne_count)
          this.kaoqin.series[1].data.push(v.rate)
        })
      })
      .catch(console.error)
    project
      .getYinhuanxunjian(projectID)
      .then(d => {
        this.yinhuanxunjian.xAxis[0].data = []
        this.yinhuanxunjian.series[0].data = []
        this.yinhuanxunjian.series[1].data = []
        d.forEach(v => {
          this.yinhuanxunjian.xAxis[0].data.push(v.date)
          this.yinhuanxunjian.series[0].data.push(v.pa_count)
          this.yinhuanxunjian.series[1].data.push(v.rate)
        })
      })
      .catch(console.error)
    project.getYujing(projectID).then(d => {
      this.yujingtongji.xAxis.data = d.xAxis
      this.yujingtongji.series = d.series.map(v => {
        v.symbol = 'circle'
        v.symbolSize = 8
        v.showSymbol = true
        return v
      })
      this.yujingtongji.legend.data = d.legend
    })

    project
      .getClassAttendance2(projectID)
      .then(d => {
        this.classEfficient.legend.data = []
        this.classEfficient.series[0].data = []
        d
          .sort((a, b) => b.worker_type_count - a.worker_type_count)
          .forEach(m => {
            this.classEfficient.legend.data.push({ name: m.worker_type })
            this.classEfficient.series[0].data.push({
              name: m.worker_type,
              value: m.worker_type_count
            })
          })
        setTimeout(
          () =>
            this.$refs.classEfficient &&
            this.$refs.classEfficient.dispatchAction({
              type: 'highlight',
              seriesIndex: 0,
              dataIndex: this.classEfficientHighlightIndex
            }),
          1000
        )
      })
      .catch(e => console.error(e.msg || e.message))
    project
      .getBuildingBaseInfo(projectID)
      .then(d => {
        this.buildingInfo = d.build_list
        this.map_list = d.show_map
        this.img_host = d.img_host
        //创建ws socket连接
        createWebSocket(wsUrl, projectID)
        //加载地图
        init(
          'mapid',
          this.img_host + this.map_list[0].image_url,
          this.map_list[0].build_id,
          this,
          function(e) {
            //加载当前楼层的人员名单
            project
              .getWorkerList(e)
              .then(list => {
                setMapMarker(list)
              })
              .catch(e => console.error(e.msg || e.toString()))
          }
        )
      })
      .catch(e => console.error(e.msg || e.message))

    this.timer = setInterval(() => {
      project
        .getWarningList(projectID)
        .then(d => (this.warningList = d))
        .catch(e => console.error(e.msg || e.message))
      project
        .getLiveData(projectID)
        .then(d => (this.liveData = d))
        .catch(e => console.error(e.msg || e.message))
      project
        .getGongjianfangkaoqin(projectID)
        .then(d => {
          this.$refs.gongjianfangkaoqin && this.$refs.gongjianfangkaoqin.clear()
          this.gongjianfangkaoqin.series[0].data = d.map(v => ({
            name: v.name,
            value: v.rate
          }))
        })
        .catch(console.error)
      project
        .getKaoqin(projectID)
        .then(d => {
          this.$refs.kaoqin && this.$refs.kaoqin.clear()
          this.kaoqin.xAxis[0].data = []
          this.kaoqin.series[0].data = []
          this.kaoqin.series[1].data = []
          d.forEach(v => {
            this.kaoqin.xAxis[0].data.push(v.date)
            this.kaoqin.series[0].data.push(v.attendacne_count)
            this.kaoqin.series[1].data.push(v.rate)
          })
        })
        .catch(console.error)
      project
        .getYinhuanxunjian(projectID)
        .then(d => {
          this.$refs.yinhuanxunjian && this.$refs.yinhuanxunjian.clear()
          this.yinhuanxunjian.xAxis[0].data = []
          this.yinhuanxunjian.series[0].data = []
          this.yinhuanxunjian.series[1].data = []
          d.forEach(v => {
            this.yinhuanxunjian.xAxis[0].data.push(v.date)
            this.yinhuanxunjian.series[0].data.push(v.pa_count)
            this.yinhuanxunjian.series[1].data.push(v.rate)
          })
        })
        .catch(console.error)
      project.getYujing(projectID).then(d => {
        this.$refs.yujingtongji && this.$refs.yujingtongji.clear()
        this.yujingtongji.xAxis.data = d.xAxis
        this.yujingtongji.series = d.series.map(v => {
          v.symbol = 'circle'
          v.symbolSize = 8
          v.showSymbol = true
          return v
        })
        this.yujingtongji.legend.data = d.legend
      })
      project
        .getClassAttendance2(projectID)
        .then(d => {
          this.$refs.classEfficient && this.$refs.classEfficient.clear()
          this.classEfficient.legend.data = []
          this.classEfficient.series[0].data = []
          d
            .sort((a, b) => b.worker_type_count - a.worker_type_count)
            .forEach(m => {
              this.classEfficient.legend.data.push({ name: m.worker_type })
              this.classEfficient.series[0].data.push({
                name: m.worker_type,
                value: m.worker_type_count
              })
            })
          setTimeout(
            () =>
              this.$refs.classEfficient &&
              this.$refs.classEfficient.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: this.classEfficientHighlightIndex
              }),
            1000
          )
        })
        .catch(e => console.error(e.msg || e.message))
      project
        .getBuildingBaseInfo(projectID)
        .then(d => {
          this.buildingInfo = d.build_list
          this.map_list = d.show_map

          // L.imageOverlay(d.project_map, bounds).addTo(map)
          // map.fitBounds(bounds)
          // this.projectImage = d.project_map
        })
        .catch(e => console.error(e.msg || e.message))
      project
        .getJindu(projectID)
        .then(d => {
          if (!d.length) return
          const names = []
          const outer = []
          const inner = []
          d.forEach(v => {
            names.push(v.name)
            outer.push({
              name: v.name,
              total: v.map_count,
              cur: v.floor,
              rate: v.value,
              value: 100
            })
            inner.push({
              name: v.name,
              total: v.map_count,
              cur: v.floor,
              rate: v.value,
              value: v.value
            })
          })
          this.$refs.gongqijindu && this.$refs.gongqijindu.clear()
          this.gongqijindu.domHeight =
            names.length > 6 ? 2.21 * names.length + 'rem' : '15.5rem'
          this.gongqijindu.yAxis.data = names
          this.gongqijindu.series[0].data = inner
          this.gongqijindu.series[1].data = inner
          this.gongqijindu.series[2].data = outer
          this.$nextTick(
            () => this.$refs.gongqijindu && this.$refs.gongqijindu.resize()
          )
        })
        .catch(console.error)
    }, 15000)
  },
  methods: {
    changeChart() {
      this.isUp = !this.isUp
    },
    changeTrouble() {
      this.isSelect = !this.isSelect
    },
    getTrack() {
      // console.log('点击了轨迹');
    },
    callBack() {
      // console.log('点击了召回');
    },
    changeMap(map_image, type, build_id, m_index) {
      if (map_image && type && build_id) {
        this.s_index = m_index
        this.s_build_id = build_id
        changeMap(this.img_host + map_image, type, build_id)

        project
          .getWorkerList(build_id)
          .then(list => {
            setMapMarker(list)
          })
          .catch(e => console.error(e.msg || e.toString()))
      }
    },
    wcallback(item) {
      if(item.is_recalled){
        return
      }else{
        wcallback(item.hat_id, item.ctrl_mac, item.channel,item.hat_mac)
        item.recalled_txt="发送中"
        item.is_recalled=true;
      }
    },
    closealarm() {
      this.show_alarm.is_show = false
    },
    classEfficientMouseOver(params) {
      if (params.dataIndex != this.classEfficientHighlightIndex) {
        this.$refs.classEfficient.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: this.classEfficientHighlightIndex
        });
        this.classEfficientHighlightIndex = params.dataIndex;
      }
    },
    classEfficientMouseOut() {
      this.$refs.classEfficient.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.classEfficientHighlightIndex
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped lang="less">
.show_alarm_sure {
  margin-top: 20px;
  font-size: 15px;
  background-color: #3182ff;
  border-radius: 16px;
  width: 80px;
  height: 32px;
  line-height: 32px;
  margin-left: 120px;
}
.show_alarm_content {
  font-family: NotoSansHans-Medium;
  font-size: 14px;
  color: #ffffff;
  line-height: 23px;
}
.show_alarm_img {
  width: 185px;
  height: 135px;
  margin-top: 23px;
}
.mantle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 999;
}
.show_alarm_title {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  font-family: NotoSansHans-Bold;
  margin-top: 23px;
}
.show_alarm {
  text-align: center;
  top: 50%;
  position: absolute;
  border: 1px solid #151c48;
  left: 50%;
  height: 340px;
  margin-top: -160px;
  width: 320px;
  margin-left: -170px;
  z-index: 1000;
  background-color: #151c48;
  border-radius: 4px;
  opacity: 0.8;
  box-shadow: 0 0 0.2em #ffffff;
}
.build_list {
  z-index: 999;
  position: absolute;
  top: 0px;
  right: 5px;
  max-height: 93%;
  overflow-y: scroll;
  padding-right: 5px;
}
.pline {
  background-color: #399a75;
  border-radius: 24px;
  border: none;
  color: #fff;
  padding: 3px 10px;
  cursor: pointer;
  outline: none;
  margin-right: 8px;
}

.map_list {
  z-index: 999;
  position: absolute;
  background-color: #21315b;
  border: 1px solid #5e7ec9;
  cursor: pointer;
  align-items: center;
  display: flex;
  top: 0px;
  left: 0px;
  > div {
    padding: 0.58rem 0.73rem;
    font-size: 0.82rem;
    border-right: 1px solid #5e7ec9;
  }

  .img {
    margin-left: 0.47rem;
    width: 0.76rem;
    height: 0.7rem;
  }
}

.text-green {
  color: #1fc86b !important;
}

.text-yellow1 {
  color: #f89132 !important;
}

.text-yellow2 {
  color: #eebc34 !important;
}

.text-red1 {
  color: #e62c31 !important;
}

.text-red2 {
  color: #f34e53 !important;
}

.text-grey {
  color: #9ca0aa !important;
}

#project {
  background-size: cover;
}

.building-link,
.building-link:active,
.building-link:link,
.building-link:hover,
.building-link:visited {
  color: #51bbf5;
}

.building-info {
  margin-top: 5px;
  border: 1px solid #5f7ec9;
  padding: 0.5rem;
  z-index: 1;
  border-radius: 0.5rem;
  // display: inline-block;
  // position: absolute;
  // background-image: url("../../assets/bg1.png");
  background: #122040;

  h3 {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0;
  }

  p {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.375rem;

    .title {
      width: 7rem;
    }

    .value {
      width: 4rem;
      text-align: right;
    }
  }
}

.building-info-other {
  border: 1px solid #5f7ec9;
  padding: 0.23rem;
  z-index: 1;
  display: inline-block;
  position: absolute;
  background: #122040;
  border-radius: 50%;
  opacity: 0.1;

  h3 {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0;
  }

  p {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.375rem;

    .title {
      width: 7rem;
    }

    .value {
      width: 4rem;
      text-align: right;
    }
  }
}

#system-info {
  position: fixed;
  top: 3rem;
  padding: 0 3rem;
  left: 1.875rem;
  width: 17.5rem;
  display: flex;

  .item {
    position: relative;
    flex: 1;
    text-align: center;

    .upper {
      font-size: 1.25rem;
    }

    .lower {
      font-size: 0.75rem;
      color: #cccccc;
      line-height: 1rem;
    }
  }

  .left::after,
  .middle::after {
    content: ' ';
    width: 0.125rem;
    height: 0.625rem;
    background-color: #244871;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  .right {
    display: flex;
    justify-content: center;
  }
}

#main {
  display: flex;
  padding: 4.75rem 1.875rem 3.5rem 1.875rem;
  height: calc(100vh - 2rem);
  overflow-y: scroll;

  #left {
    width: 17.5rem;

    #project-base-info {
      p {
        font-size: 0.875rem;
        line-height: 1.875rem;
        display: flex;

        .title {
          width: 5.3rem;
          color: #5accf1;
        }

        .value {
          flex: 1;
          text-align: right;
          color: #ffffff;
        }
      }
    }

    #people-warning-list {
      margin-right: -8px;
      .warning-item {
        margin-bottom: 0.75rem;
        padding: 14px 20px 14px 12px;
        font-size: 0.75rem;
        line-height: 1.5rem;
        background-color: #151c48;
        border-radius: 0.25rem;
        color: #ffffff;
        position: relative;
        display: flex;
        span.name {
          color: #999999;
        }
        time {
          position: absolute;
          right: 0;
          background-color: #162e70;
          border-radius: 4px;
          top: 0;
          padding: 5px;
        }
      }
    }
  }

  #middle {
    flex: 1;
    position: relative;
    padding: 0 1rem;

    #map {
      // margin-bottom: 1rem;

      .project_map {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
      }

      .menus {
        z-index: 999;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #21315b;
        border: 1px solid #5e7ec9;
        cursor: pointer;

        > div {
          padding: 0.58rem 0.73rem;
          font-size: 0.82rem;
        }

        > div:first-child {
          border-right: 1px solid #5e7ec9;
        }

        .img {
          margin-left: 0.47rem;
          width: 0.76rem;
          height: 0.7rem;
        }
      }
    }

    #middle-charts {
      margin-top: 1rem;
      flex: 1;
      display: flex;
      justify-content: space-between;

      .danger-source-statistics {
        .content {
          height: 13.125rem;
          padding-top: 1.5rem;
          display: flex;

          .left {
            text-align: center;
            flex: 1;

            .circle {
              border: 2px dotted #df6921;
            }
          }

          .right {
            text-align: center;
            flex: 1;

            .circle {
              border: 2px dotted #df2124;
            }
          }

          .circle {
            margin-bottom: 1.25rem;
            width: 8.25rem;
            height: 8.25rem;
            border-radius: 50%;
            padding-top: 2rem;
          }

          .icon {
            width: 4rem;
            height: 4rem;
          }
        }
      }
    }
  }

  #right {
    width: 17.5rem;

    #live-data {
      div.content {
        padding: 2.5rem 0 2rem;
        margin: 0 -6px;
        overflow: hidden;

        p.item {
          display: flex;
          width: 50%;
          float: left;

          .img {
            height: 3rem;
            width: 3rem;
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 0.4rem;
          }

          .value {
            flex: 1;

            .title {
              font-size: 0.8rem;
              display: block;
              color: #999999;
            }

            .number {
              display: block;
              font-size: 1.6rem;
              color: #ffffff;
            }
          }

          &:first-of-type {
            .img {
              background-image: url(../../assets/01.png);
            }
          }

          &:nth-of-type(2) {
            .img {
              background-image: url(../../assets/02.png);
            }
          }

          &:nth-of-type(3) {
            .img {
              background-image: url(../../assets/03.png);
            }
          }

          &:nth-of-type(4) {
            .img {
              background-image: url(../../assets/04.png);
            }
          }
        }
      }
    }

    #equipment-status {
      .content {
        background: url('../../assets/shebei.png') no-repeat 4px 8px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 90px;
        height: 5.375rem;
        font-size: 0.875rem;

        p {
          line-height: 1.5rem;
        }
      }
    }

    #people-status {
      .content {
        display: flex;
        padding: 1rem 0 0.5rem;

        .left {
          width: 45%;
          text-align: center;

          .score {
            padding: 0.4rem 0;
            font-size: 1.875rem;
            color: #1fc86b;
          }

          .name {
            font-size: 0.875rem;
          }
        }

        .right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          font-size: 0.875rem;
        }
      }
    }
  }
}

#logo {
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  display: flex;
  align-items: center;
  line-height: 2rem;

  span {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.6);
    margin-right: 1rem;
  }
}

#mapid {
  width: 100%;
  height: 100vh;
}

.map-angle {
  background-color: red;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.leaflet-popup-content-wrapper {
  background-color: #122040;
  text-align: center;
  border: 2px solid #5f7ec9;
}

.leaflet-popup-content > div:not(:last-child) {
  margin-bottom: 5px;
}

.leaflet-popup-tip-container {
  display: none;
}

.wxcallback{
  position:absolute;
  right: 2px;
  bottom: 2px;
  padding-left:12px;
  padding-right:12px;
  background-color: cornflowerblue;
  cursor: pointer;
  border-radius: 13px
}

// .btn1{
//     background-color:#399A75;
//     border-radius:24px;
//     border:none;
//     color:#fff;
//     padding: 3px 10px;
//     cursor: pointer;
//     outline: none;
//     margin-right: 8px;
// }

// .btn2{
//     background-color:#5186F5;
//     border-radius:24px;
//     border:none;
//     color:#fff;
//     padding: 3px 10px;
//     cursor: pointer;
//     outline: none;
// }
</style>
