<template>
  <div id="right">
    <div id="live-data" style="margin-bottom: 1rem">
      <wrapper style="padding: 0">
        <div class="info-block" style="min-height:5rem">
          <div
            class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
          >
            <div>近一周实际劳动力与计划对比</div>
            <div class="detail" @click="showDetail(1)">
              详情
            </div>
          </div>
          <div style="height:19rem;padding-top:3rem">

        <div
          v-if="laborCompareChart.series && [].concat.apply([], laborCompareChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
            <v-chart v-else :options="laborCompareChart" autoresize />
          </div>
        </div>
      </wrapper>
    </div>
    <div id="people-warning-list">
      <wrapper style="padding: 0;height:36.7rem">
        <div class="info-block" style="min-height:5rem">
          <div
            class="info-block-title"
            style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
          >
            <div>各工种劳动力下阶段盈缺统计</div>
          </div>
          <div style="height:36.5rem;padding-top:3rem;3rem;position: relative;">
            <div class="chart-title">
              <div>缺</div>
              <div style="margin-left: 4rem">余</div>
            </div>
        <div
          v-if="laborSurplusChart.series && [].concat.apply([], laborSurplusChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
            <v-chart
              v-else
              :options="laborSurplusChart"
              @click="laborSurplusChartClick"
              autoresize
            />
          </div>
        </div>
      </wrapper>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="90rem"
      top="8rem"
      :destroy-on-close='true'
    >
      <wrapper style="height:52rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow = false"
        />
        <div class="attendance">
          <div class="attendance-top">
            <div class="tabbar" style="margin-bottom: 1rem;">
              <div :class="{ 'tabar-select': popupSelect == 1 }" @click="getAttendance(1)">按月</div>
              <div :class="{ 'tabar-select': popupSelect == 2 }" @click="getAttendance(2)">按周</div>
              <div :class="{ 'tabar-select': popupSelect == 3 }" @click="getAttendance(3)">按日</div>
            </div>
            <div style="border:1px solid #1a3281;padding:1rem 2rem">
              <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">平均计划/实际劳动力
              </div>
              <div style="height:16rem;padding-top:1rem">
                <v-chart
                  ref="laborCompareCollect"
                  :options="laborCompareCollect"
                  @click="attenceWorkersBarChartClick"
                  autoresize
                />
              </div>
            </div>
          </div>
          <div class="attendance-bottom">
            <div style="text-align:center;font-size: 18px;margin-top: 1rem;">{{popupBottomName}}</div>
            <div style="margin:1rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:15rem;text-align:center">共建方</div>
                <div style="width:8rem;text-align:center">实际计划比</div>
                <div style="width:15rem;text-align:center">备注</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popManagementTopProjects"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:15rem;padding-left: 1rem">{{item.company_name}}</div>
                  <div
                    style="width:8rem;text-align:center"
                    :style="{ color: item.value<1 ? '#E02F37' : '#1bd080'}"
                  >{{item.value}}</div>
                  <div style="width:15rem;color: #ffbd1d;text-align:center">{{item.note}}</div>
                </div>
                <div
                  v-if="!(popManagementTopProjects && popManagementTopProjects.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无记录
                </div>
              </div>
            </div>
            <!-- <el-pagination background layout="prev, pager, next" :current-page.sync="securityStarPage" :page-count="securityStarPages" @current-change="securityStarPageChange" style="text-align:center;margin-top: 16px"></el-pagination> -->
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import wrapper from '../../components/wrap'
  import { project } from '../../api';
  import { singleLineChartTemplate } from './lineChartTemplates'
  import { applyUpdate } from "../../utils";
  import { barNegativeChartTemplate } from './barChartTemplates'

  export default {
    name: 'ScheduleRightBar',
    components: {
      'v-chart': ECharts,
      wrapper,
    },
    props: {
      projectDetail: Object,
      liveData: Object,
      onSiteWorkerTypeInfo: Array,
      warningStats: Object,
      warningList: Array,
      statusColorMap: Object,
    },
    data () {
      return {
        laborCompareChart: {},
        option: {
          color: [
            '#15BBCF',
            '#18A1E1',
            '#53aeef',
          ],
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            icon: 'circle',
            itemWidth: 12,
            itemHeight: 12,
            itemGap: 12,
            textStyle: {
              color: '#ccc',
              fontWeight: 'bold',
              fontSize: '11'
            },
            padding: [8, 0, 0, 0],
            // top: '4%',
            // right: '3%',
            data: []
          },
          grid: {
            left: '2%',
            right: '2%',
            top: '15%',
            bottom: '5%',
            containLabel: true
          },
          yAxis: [{
            name: '人数：人',
            nameTextStyle: {
              color: '#fff'
            },
            type: 'value',
            show: true,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: [5, 10],
                color: '#14253f',
                width: 2,
              }
            },
            axisTick: { show: false },
            axisLabel: {
              color: '#e6f2f3',
              fontSize: 14,
              fontWeight: 'bold',
              // formatter: function(value) {
              //   return value ? value + '%' : value;
              // }
            }
          }],
          xAxis: {
            type: 'category',
            data: [],
            boundaryGap: true,
            splitNumber: 7,
            axisLabel: {
              color: '#e6f2f3',
              fontSize: 14,
              fontWeight: 'bold',
              rotate: 35,
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#22344b',
              }
            },
          },
          series: [
            {
              name: '',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(27, 208, 128, 0.5)'},
                  {offset: 1, color: 'rgba(27, 208, 128, 0)'}
                ])
              },
              // symbolSize: 0,
              data: [],
            },
            {
              name: '',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(27, 208, 128, 0.5)'},
                  {offset: 1, color: 'rgba(27, 208, 128, 0)'}
                ])
              },
              // symbolSize: 0,
              data: [],
            },
          ]
        },
        laborSurplusChart: {},
        option2: {
          color: ["#E02F37", "#34C59E"],
          tooltip: {
            trigger: "axis",
            formatter: params => `${params[0].name}<br>${params[0].seriesName}: ${-params[0].value}<br>${params[1].seriesName}: ${params[1].value}`
          },
          grid: {
            left: "5%",
            right: "5%",
            bottom: "3%",
            top: "6%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "value",
              splitLine:{
                show:false
              },
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              interval:5000,
              splitNumber:1
            },
          ],
          yAxis: [
            {
              // name: '人数：人',
              nameTextStyle: {
                color: "#cad6d9",
                padding: [0, 380, 0, 0]
              },
              type: "category",
              axisTick: {
                show: false,
              },
              offset:40,
              data: [],
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#cad6d9",
                  fontSize: 12,
                  fontWeight: "bold",
                },
                // rotate: 35,
              },
              axisLine: {
                show: false,
                lineStyle: { color: "#263851" },
              },
              boundaryGap: true,
              splitLine: {
                show: false,
              },
            },
            {
              // name: '人数：人',
              nameTextStyle: {
                color: "#cad6d9",
                padding: [0, 380, 0, 0]
              },
              position:'left',
              offset: -10,
              type: "category",
              axisTick: {
                show: false,
              },
              data: [],
              axisLabel: {
                formatter: params => `${(params/1000).toFixed(1)}k`,
                show: true,
                interval: 0,
                textStyle: {
                  color: "#cad6d9",
                  fontSize: 12,
                  fontWeight: "bold",
                },
                // rotate: 35,
              },
              axisLine: {
                show: false,
                lineStyle: { color: "#263851" },
              },
              boundaryGap: true,
              splitLine: {
                show: false,
              },
            },
            {
              // name: '人数：人',
              nameTextStyle: {
                color: "#cad6d9",
                padding: [0, 380, 0, 0]
              },
              type: "category",
              axisTick: {
                show: false,
              },
              offset:-40,
              data: [],
              axisLabel: {
                show: true,
                formatter: params => `${(params/1000).toFixed(1)}k`,
                textStyle: {
                  color: "#cad6d9",
                  fontSize: 12,
                  fontWeight: "bold",
                },
                // rotate: 35,
              },
              axisLine: {
                show: false,
                lineStyle: { color: "#263851" },
              },
              boundaryGap: true,
              splitLine: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: "",
              type: "bar",
              stack: "Total",
              barWidth: 10,
              label: {
                show: false,
                position: "left",
                textStyle: {
                  color: "#fff",
                },
                formatter: params => `${(-params.value/1000).toFixed(1)}k`
              },
              emphasis: {
                focus: "series",
              },
              data: [],
            },
            {
              name: "",
              type: "bar",
              stack: "Total",
              label: {
                show: false,
                position: "right",
                textStyle: {
                  color: "#fff",
                },
                formatter: params => `${(params.value/1000).toFixed(1)}k`,
              },
              emphasis: {
                focus: "series",
              },
              data: [],
            },
          ],
        },
        popupShow: false,
        laborCompareCollect: {},
        popupSelect: 1,
        popManagementTopProjects: [],
        option3: {
          color: ["#1bd080", "#53aeef"],
          tooltip: {
            trigger: "axis",
          },
          legend: {
            icon: "circle",
            top: "2%",
            right: "50%",
            data: [],
          },
          grid: {
            left: "2%",
            right: "0%",
            top: "15%",
            bottom: "1%",
            containLabel: true,
          },
          yAxis: [
            {
              name: "人数：人",
              nameTextStyle: {
                color: "#fff",
                align: "right",
              },
              type: "value",
              show: true,
              axisLine: {
                show: false,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: [5, 10],
                  color: "#14253f",
                  width: 2,
                },
              },
              axisTick: { show: false },
              axisLabel: {
                color: "#e6f2f3",
                fontSize: 14,
                fontWeight: "bold",
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // }
              },
            },
          ],
          xAxis: {
            type: "category",
            data: [],
            boundaryGap: true,
            splitNumber: 7,
            axisLabel: {
              color: "#e6f2f3",
              fontSize: 14,
              fontWeight: "bold",
              rotate: 30,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#22344b",
              },
            },
          },
          series: [],
        },
        popupBottomName: ''
      }
    },
    mounted () {
      // const self = this
      const { id: projectID } = this.$route.params;
      this.projectId = projectID;
      const token = this.$route.query.token;
      window.token = token;

      // this.projectId = '48146e7e-b027-4bf4-8520-0d935405b681';
      this.getAll();
    },
    methods: {
      getAll(){
        project.getProjBuildLaborCompareCollect(this.projectId)
          .then(d => {
            // console.log(d);
            let chart = JSON.parse(JSON.stringify(singleLineChartTemplate));
            this.option.legend.data = d.legend_data
            this.option.xAxis.data = d.xAxis_data;
            this.option.series[0].data = d.series[0]
            this.option.series[0].name = d.legend_data[0]
            this.option.series[1].data = d.series[1]
            this.option.series[1].name = d.legend_data[1]
            this.laborCompareChart = applyUpdate(chart, this.option);
          })

        project.getProjBuildLaborSurplusCollect(this.projectId)
          .then(d => {
            // console.log(d);
            const chart = JSON.parse(JSON.stringify(barNegativeChartTemplate));
            // this.option2.legend.data = d.legend_data;
            this.option2.yAxis[0].data = d.xAxis_data;
            this.option2.yAxis[1].data = d.series[0];
            this.option2.yAxis[2].data = d.series[1];
            this.option2.series[0].data = d.series[0];
            this.option2.series[0].data = this.option2.series[0].data.map((vv) => {
              return -vv;
            });
            this.option2.series[0].name = d.legend_data[0];
            this.option2.series[1].data = d.series[1];
            this.option2.series[1].name = d.legend_data[1];
            // this.option2.xAxis[0].min = -Math.max.apply(null, d.series[0]) * 1.2;
            // this.option2.xAxis[0].max = Math.max.apply(null, d.series[1]) * 1.2;


            this.laborSurplusChart = applyUpdate(chart, this.option2);
          })
      },
      laborSurplusChartClick(params){
        this.popupToShow = true;
        this.popupTotitle = params.name;
        this.popupToMin =
          -this.workerSurplusChart.series[0].data[params.dataIndex];
        this.popupToMax =
          this.workerSurplusChart.series[1].data[params.dataIndex];
      },
      showDetail(val){
        if(val === 1){
          this.popupShow = true;
          this.getAttendance(this.popupSelect)
        }
      },
      getAttendance(popupSelect) {
        this.popupSelect = popupSelect;

        project
          .getProjScheduleLaborCompareDateDetailCollect(
            this.projectId,
            this.popupSelect
          )
          .then((d) => {
            // console.log(d)
            const chart = JSON.parse(JSON.stringify(singleLineChartTemplate));
            this.popupData = d.series;
            this.option3.legend.data = d.legend_data;
            this.option3.xAxis.data = d.xAxis_data;
            this.option3.series = [];
            d.series.forEach((vv, index) => {
              this.option3.series.push({
                name: d.legend_data[index],
                type: "line",
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 2,
                    },
                  },
                },
                areaStyle: {
                  color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "rgba(27, 208, 128, 0.5)" },
                    { offset: 1, color: "rgba(27, 208, 128, 0)" },
                  ]),
                },
                // symbolSize: 0,
                data: vv,
              });
            });
            this.popupBottomName = `${
              d.xAxis_data[d.series.length - 1]
            }劳动力详情`;
            this.option3.series.push({
              type: "line",
              markLine: {},
            });
            this.laborCompareCollect = applyUpdate(chart, this.option3);
            this.popupXAxisData = d.xAxis_data;
            this.popupSearchkeys = d.search_keys;
            this.getLaborCompareOrgProjectCollect(
              d.search_keys[d.series.length - 1]
            );
            this.laborCompareCollect.series[2].markLine = {
              lineStyle: {
                color: "#ffbd1d",
              },
              name: "cc",
              data: [
                [
                  {
                    coord: [
                      d.series.length - 1,
                      this.laborCompareCollect.series[0].data[
                        d.series.length - 1
                      ] >
                      this.laborCompareCollect.series[1].data[d.series.length - 1]
                        ? this.laborCompareCollect.series[0].data[
                            d.series.length - 1
                          ]
                        : this.laborCompareCollect.series[1].data[
                            d.series.length - 1
                          ],
                    ],
                  },
                  {
                    coord: [d.series.length - 1, 0],
                    symbol: "none", //去掉箭头
                  },
                ],
              ],
            };
          });
      },
      attenceWorkersBarChartClick(params) {
        // console.log(params);

        this.laborCompareCollect.series[2].markLine = {
          lineStyle: {
            color: "#ffbd1d",
          },
          name: "cc",
          data: [
            [
              {
                coord: [
                  params.dataIndex,
                  this.laborCompareCollect.series[0].data[params.dataIndex] >
                  this.laborCompareCollect.series[1].data[params.dataIndex]
                    ? this.laborCompareCollect.series[0].data[params.dataIndex]
                    : this.laborCompareCollect.series[1].data[params.dataIndex],
                ],
              },
              {
                coord: [params.dataIndex, 0],
                symbol: "none", //去掉箭头
              },
            ],
          ],
        };
        this.popupBottomName = `${
          this.popupXAxisData[params.dataIndex]
        }劳动力详情`;
        this.getLaborCompareOrgProjectCollect(
          this.popupSearchkeys[params.dataIndex]
        );
      },
      getLaborCompareOrgProjectCollect(search_keys) {
      // window.$orgLevel = this.org_id;
        project
          .getProjScheduleLaborCompareCompanyDetailCollect(
            this.projectId,
            this.popupSelect,
            search_keys,
            1000000,
          )
          .then((d) => {
            // console.log(d);
            this.popManagementTopProjects = d;
          });
      },
    }
  }
</script>

<style scoped lang="less">
#live-data {
  div.content {
    padding-top: 1rem;
    margin: 0 -6px;
    overflow: hidden;

    p.item {
      display: flex;
      width: 50%;
      float: left;

      .img {
        height: 2.5rem;
        width: 2.5rem;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 0.4rem;
      }

      .value {
        flex: 1;

        .title {
          font-size: 0.8rem;
          display: block;
          color: #999999;
        }

        .number {
          display: block;
          font-size: 1.6rem;
          color: #ffffff;
        }
      }

      &:first-of-type {
        .img {
          background-image: url(../../assets/01.png);
        }
      }

      &:nth-of-type(2) {
        .img {
          background-image: url(../../assets/02.png);
        }
      }

      &:nth-of-type(3) {
        .img {
          background-image: url(../../assets/03.png);
        }
      }

      &:nth-of-type(4) {
        .img {
          background-image: url(../../assets/04.png);
        }
      }

      &:nth-of-type(5) {
        .img {
          background-image: url(../../assets/05.png);
        }
      }

      &:nth-of-type(6) {
        .img {
          background-image: url(../../assets/06.png);
        }
      }
    }
  }
}

#equipment-status {
  .content {
    background: url('../../assets/shebei.png') no-repeat 4px 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 90px;
    height: 5.375rem;
    font-size: 0.875rem;

    p {
      line-height: 1.5rem;
    }
  }
}

#people-warning-list {
  .warning-item {
    margin-bottom: 0.75rem;
    padding: 8px 6px;
    font-size: 0.75rem;
    line-height: 1.5rem;
    background-color: #1e2c61;
    border-radius: 0.25rem;
    color: #ffffff;
    position: relative;
    display: flex;
    div.name {
      color: #999999;
      width: 2rem;
    }
    div.value {
      width: 3.7rem;
      padding-left: 0.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    time {
      background-color: #577acc;
      border-radius: 4px;
      padding: 5px;
    }
  }
}
.chart-title{
  color: #cad6d9;
  font-size: 12px;
  display:flex;
  align-items:center;
  justify-content: flex-end;
  width: 20%;
  margin:auto;
  padding-left: 16rem;
  position: absolute;
  top: 10%;
  left: 5%;
}
</style>
