<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :sep-size="broadcastSep"
    :title-font-size="'1rem'"
    :value-font-size="'3rem'"
/>
</template>

<script>
  import Broadcasts from "../../components/broadcasts";
  import { project } from "../../api";

  export default {
    name: 'SecurityCheckBroadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['隐患总数', '今日新增隐患', '未整改隐患'],
        broadcastColors: ['#40f8ff', '#ffd454', '#e02f37'],
        broadcastIcons: ['隐患总数', '今日新增隐患', '未整改隐患数'],
        broadcastSep: '3rem',
      }
    },
    props: {
      broadcastData: Object,
    },
  }

  export function updateData(container, scheduleNextUpdate) {
    project
      .getSafetyCheckBroadcast()
      .then((d) => {
        container.broadcastData.securityCheck = d[0];
        container.broadcastData.securityCheck.value1 = d[0].trouble_count_total;
        container.broadcastData.securityCheck.value2 = d[0].trouble_count_today;
        container.broadcastData.securityCheck.value3 = d[0].trouble_undo_count;

        container.statsData = d[0];
        scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1rem;
  font-weight: bold;
  color: #cececf;
}
</style>
