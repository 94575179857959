<template>
  <div style="width:100%;height:10rem;display:flex;justify-content:center">
    <div style="display:flex;align-items:center">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[0]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[0]}}</div>
        <div style="display:flex" @click="handleClick(1)">
          <v-number
            v-model="(broadcastData || {}).value1"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[0], cursor: 'pointer'}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:3rem">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[1]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[1]}}</div>
        <div style="display:flex" @click="handleClick(2)">
          <v-number
            v-model="(broadcastData || {}).value2"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[1]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:3rem">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[2]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[2]}}</div>
        <div style="display:flex" @click="handleClick(3)">
          <v-number
            v-model="(broadcastData || {}).value3"
            :speed="500"
            style="font-family:digital;font-size:3rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[2]}"
          ></v-number>
          <span v-if="(broadcastData || {}).value3 != null" style="font-family:digital;font-size:3rem;color:#35c59e;line-height:2.4rem">%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VNumber from "../../components/v-number";
  import { project } from "../../api";

  export default {
    name: 'ManagerBroadcasts',
    components: {
      VNumber,
    },
    data() {
      return {
        broadcastTitles: ['项目管理人员数', '本月新增人员数', '管理人员今日现场出勤率'],
        broadcastColors: ['#40f8ff', '#ffd454', '#35c59e'],
        broadcastIcons: ['项目管理人员数', '本月新增人员数', '管理人员今日现场管理率'],
        broadcastSep: '2rem',
      }
    },
    props: {
      broadcastData: Object,
    },
    methods: {
      handleClick() {
      }
    }
  }

  export function updateData(container, scheduleNextUpdate) {
    project
      .getManagerBroadcast()
      .then((d) => {
        container.broadcastData.manager = d[0];
        container.broadcastData.manager.value1 = d[0].manager_count;
        container.broadcastData.manager.value2 = d[0].manager_new_count;
        container.broadcastData.manager.value3 = d[0].manager_attendance_rate;

        container.statsData = d[0];
        scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1rem;
  font-weight: bold;
  color: #cececf;
}
</style>
