<template>
  <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title" style="display:flex;width:100%;padding-right:3.1rem">
      <div style="flex:1">当年完成情况</div>
      <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '1' }"
              @click="() => switchAvgAttendanceTopListOption('1')"
            >月度显示</div>
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '2' }"
              @click="() => switchAvgAttendanceTopListOption('2')"
            >累计月度显示</div>
            <div
              class="detail"
              style="margin-left: 0.5rem;"
              @click=" showDetail()"
            >
              详情
            </div>
          </div>
    </div>
    <div style="height:19rem;padding-top:3rem">
      <div
          style="display:flex;justify-content:space-around;margin-bottom:0.6rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')"
          >
            当年净流完成情况
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')"
          >
            当年收款完成情况
          </div>
        </div>
      <div
        v-if="!dangerCheckRateChart.series || !dangerCheckRateChart.series[0].data.length"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart v-else :options="dangerCheckRateChart" autoresize />
    </div>
    
    <!-- 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
               <el-tabs id ="tabs">
                <el-tab-pane label="公司维度">
                  <el-table style="background-color:#0E1328" height="22rem" :data="tableData" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column
                        prop="branchName"
                        label="分公司">
                        </el-table-column>
                    <el-table-column
                        prop="assessUnit"
                        label="考核分类机构">
                    </el-table-column>
                    <template v-if="companyWorkersOption=='2'">
                      <el-table-column v-for="(item,index) in tableData[0].monthData" :key="index" :label="item.month"> 
                          <el-table-column label="计划回收">
                            <template slot-scope="scope">
                                {{scope.row.monthData[index].jhhs}}
                            </template>
                          </el-table-column>
                          <el-table-column label="实际回收">
                            <template slot-scope="scope">
                                {{scope.row.monthData[index].sjhs}}
                            </template>
                          </el-table-column>
                      </el-table-column>
                      <el-table-column label="当年">
                          <el-table-column
                            label="计划回收"
                            prop="yearGatherPlan"
                            :formatter="Formatter">
                            </el-table-column>
                            <el-table-column
                            prop="yearGatherActual"
                            label="实际回收"
                            :formatter="Formatter">
                            </el-table-column>
                            <el-table-column
                            prop="yearGatherOffset"
                            label="回收偏差"
                            :formatter="Formatter">
                            </el-table-column>
                        </el-table-column>
                      </template>
                      <template v-else>
                        <el-table-column v-for="(item,index) in tableData[0].monthData" :key="index" :label="item.month"> 
                          <el-table-column label="净流预算">
                            <template slot-scope="scope">
                                {{scope.row.monthData[index].jhhs}}
                            </template>
                          </el-table-column>
                          <el-table-column label="净流完成">
                            <template slot-scope="scope">
                                {{scope.row.monthData[index].sjhs}}
                            </template>
                          </el-table-column>
                      </el-table-column>
                      <el-table-column label="当年">
                          <el-table-column
                            label="净流预算"
                            prop="yearGatherPlan"
                            :formatter="Formatter">
                            </el-table-column>
                            <el-table-column
                            prop="yearGatherActual"
                            label="净流完成"
                            :formatter="Formatter">
                            </el-table-column>
                            <el-table-column
                            prop="yearGatherOffset"
                            label="预算偏差"
                            :formatter="Formatter">
                            </el-table-column>
                        </el-table-column>
                      </template>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane label="项目维度">
                      <el-table style="background-color:#0E1328" height="22rem" :data="projData" 
                    :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                        <el-table-column
                          prop="branchName"
                          label="分公司">
                          </el-table-column>
                          <el-table-column
                          prop="assessUnit"
                          label="考核分类机构">
                          </el-table-column>
                          <el-table-column
                          prop="projName"
                          label="项目名称">
                          </el-table-column>
                        <template v-if="companyWorkersOption=='1'">
                          <el-table-column
                          prop="netFlowBudget"
                          label="本年净流预算"
                          :formatter="Formatter">
                          </el-table-column>
                          <el-table-column
                          prop="netFlowFinished"
                          label="本年净流完成"
                          :formatter="Formatter">
                          </el-table-column>
                          <el-table-column
                          prop="netFlowOffset"
                          label="本年净流偏差"
                          :formatter="Formatter">
                          </el-table-column>
                        </template>
                        <template v-else>
                          <el-table-column
                          prop="gatherPlan"
                          label="本年收款预算"
                          :formatter="Formatter">
                          </el-table-column>
                          <el-table-column
                          prop="gatherActual"
                          label="本年收款完成"
                          :formatter="Formatter">
                          </el-table-column>
                          <el-table-column
                          prop="gatherOffset"
                          label="本年收款偏差"
                          :formatter="Formatter">
                          </el-table-column>
                        </template>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { finance } from '../../../../api_finance'
  import { SimpleBarChartTemplate } from "../../barChartTemplates";
  import { applyUpdate } from "../../../../utils";
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'CurrentYearNetFlowCompletionChart',
    components: {
      'v-chart': ECharts,
      wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateNetFlowChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateNetFlowChart();
        }
      }
    },
    data () {
      return {
        dangerCheckRateChart: {},
        mode:"1",
        companyWorkersOption:"1",
        popupShow:false,
        tableData:[],
        tableData2:[],
        projData:[]
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.switchCompanyWorkersOption(this.companyWorkersOption);
    },
    methods: {
       Formatter(row, column, cellValue){
            return finance.formatDecimal(cellValue,2);
        },
      tableRowStyle({rowIndex}){
        if(rowIndex%2 === 0)
            return {backgroundColor:'#213a77',height: '0'}; 
        else   
            return {backgroundColor:'#102855',height: '0'}; 
      },
      tableHeaderColor(){
        return 'color:#4fb5da;background:#102855';
      },
      switchCompanyWorkersOption(option){
        this.companyWorkersOption = option;
        this.updateNetFlowChart();
      },
      switchAvgAttendanceTopListOption(choose){
        this.mode = choose;
        this.updateNetFlowChart();
      },
      showDetail(){
        this.popupShow = true;
      },
      updateNetFlowChart() {
        finance.queryProjCapitalYearly("",new Date().getFullYear).then((d=>{
          this.projData = d;
        }))
        finance.queryBranchCapitalMonthlySum()
          .then(d => {
            this.tableData = d.branchCapitalMonthlyVOList;
            this.tableData2 = d.branchCapitalYearlyVO;
            var formatData = [];
            var monthData = [];
            if(this.companyWorkersOption=="2"){
              for (let i = 0; i < this.tableData.length; i++) {
                monthData.push({month:this.tableData[i].month+"月",
                                jhhs:finance.formatDecimal(this.tableData[i].gatherPlan,2),
                                sjhs:finance.formatDecimal(this.tableData[i].gatherActual,2)});
              }
              formatData.push({branchName:this.tableData2.branchName,
                              assessUnit:this.tableData2.assessUnit,
                              monthData:monthData,
                              yearGatherActual:finance.formatDecimal(this.tableData2.gatherActual,2),
                              yearGatherPlan:finance.formatDecimal(this.tableData2.gatherPlan,2),
                              yearGatherOffset:finance.formatDecimal(this.tableData2.gatherOffset,2)})
            }else{
              for (let i = 0; i < this.tableData.length; i++) {
                monthData.push({month:this.tableData[i].month+"月",
                                jhhs:finance.formatDecimal(this.tableData[i].netFlowBudget,2),
                                sjhs:finance.formatDecimal(this.tableData[i].netFlowFinished,2)});
              }
              formatData.push({branchName:this.tableData2.branchName,
                              assessUnit:this.tableData2.assessUnit,
                              monthData:monthData,
                              yearGatherActual:finance.formatDecimal(this.tableData2.netFlowFinished,2),
                              yearGatherPlan:finance.formatDecimal(this.tableData2.netFlowBudget,2),
                              yearGatherOffset:finance.formatDecimal(this.tableData2.netFlowOffset,2)})
            }
            this.tableData = formatData;
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            const option = {
              color:[
              '#f32c2c',
              '#f38e2c',
              '#2ce4ff',
              '#19b6fb',
              '#5dd5c3',
              '#ffed21',
              '#159172',
              '#fb9c5c'
            ],
              tooltip: {
                trigger: "axis",
                formatter: "{b}<br>{a0}: {c0}",
              },
              legend:{
                data:[]
              },
              grid: {
                top: "15%",
                left: "3%",
                right: "5%",
                bottom: "20%",
                containLabel: true,
              },
              xAxis: [
                {
                  type: "category",
                  data: [],
                  axisLabel: {
                    color: "#e6f2f3",
                    show:true,
                    lineStyle: {
                        color: "#rgb(77,83,141)",
                        width:4
                    },
                    rotate: 45,
                    fontWeight: "normal",
                    formatter: function (value) {
                      return value+"月";
                    },
                  },
                },
              ],
              yAxis: [
                {
                  show: true,
                  name: "单位：亿",
                  type: "value",
                  nameTextStyle: {
                    padding: [0, 40, 0, 0],
                    color: "#e6f2f3",
                    fontWeight: "bold",
                    align: "left",
                  },
                  boundaryGap: [0, "20%"],
                  axisLabel: {
                    color: "#e6f2f3",
                    fontWeight: "bold",
                  },
                  axisLine: { show: true, lineStyle: { color: "#36445f" } },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: "#36445f",
                      width: 1,
                      type: "solid",
                    },
                  },
                  axisTick: { show: false },
                },
              ],
              series: [
                {
                  name: "预算",
                  type: "line",
                  barWidth: 10,
                  barMaxWidth: 18,
                  markLine:{
                    symbol: ["none", "none"],
                    lineStyle:{
                      normal: {
                            width: 2,
                            color: "rgb(255,128,0)", // 线条颜色
                        },
                    },
                    itemStyle: {
                      normal: {
                        label: {
                          show: false,
                          position: "middle",
                        },
                      },
                    },
                    data: [],
                  }
                },{
                  name: "实际",
                  type: "line",
                  barWidth: 10,
                  barMaxWidth: 18,
                  markLine:{
                    symbol: ["none", "none"],
                    lineStyle: {
                        normal: {
                            width: 2,
                            color: "rgba(25,163,223,1)", // 线条颜色
                        },
                    },
                    itemStyle: {
                      normal: {
                        label: {
                          show: false,
                          position: "middle",
                        },
                      },
                    },
                    data: [],
                  }
                }
              ],
            };
            let xData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            if(this.companyWorkersOption == "1"){
              for (let i = 0; i < d.branchCapitalMonthlyVOList.length; i++) {
                xData.push(d.branchCapitalMonthlyVOList[i].month);
                seriesData1.push(finance.formatDecimal(d.branchCapitalMonthlyVOList[i].netFlowBudget,2));
                seriesData2.push(finance.formatDecimal(d.branchCapitalMonthlyVOList[i].gatherActual,2));
              }
              option.series[0].name = "预算";
              option.series[1].name = "实际";
              option.legend.data = ["预算","实际"];
            }else{
              for (let i = 0; i < d.branchCapitalMonthlyVOList.length; i++) {
                xData.push(d.branchCapitalMonthlyVOList[i].month);
                seriesData1.push(finance.formatDecimal(d.branchCapitalMonthlyVOList[i].gatherPlan,2));
                seriesData2.push(finance.formatDecimal(d.branchCapitalMonthlyVOList[i].gatherActual,2));
              }
              option.series[0].name = "计划";
              option.series[1].name = "实际";
              option.legend.data = ["计划","实际"];
            }
            var seriesData1_acc = [];
            var seriesData2_acc = [];
            var currNum = 0;
            if(this.mode == "2"){
              seriesData1.forEach((item,index)=>{
                if(index == 0){
                  seriesData1_acc.push(seriesData1[index]);
                }else{
                  if(currNum){
                    currNum = seriesData1[index]+currNum;
                  }else{
                    currNum = seriesData1[index] + seriesData1[index-1];
                  }
                  seriesData1_acc.push(currNum.toFixed(2));
                }
              })
              seriesData2.forEach((item,index)=>{
                if(index == 0){
                  seriesData2_acc.push(seriesData2[index]);
                }else{
                  if(currNum){
                    currNum = seriesData2[index]+currNum;
                  }else{
                    currNum = seriesData2[index] + seriesData2[index-1];
                  }
                  seriesData2_acc.push(currNum.toFixed(2));
                }
              })
            }
            option.xAxis[0].data = xData;
            if(this.mode == "1"){
              option.series[0].data = seriesData1;
              option.series[1].data = seriesData2;
            }else{
              option.series[0].data = seriesData1_acc;
              option.series[1].data = seriesData2_acc;
            }
            this.dangerCheckRateChart = applyUpdate(chart, option);
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
    },
  }
</script>

<style lang="less">
</style>
