<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  />
</template>

<script>
  import Broadcasts from "../../components/broadcasts";
  import { project } from "../../api";

  export default {
    name: 'IndexBroadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['在施项目数', '停工项目数', '缓建项目数'],
        broadcastColors: ['#40f8ff', '#e02f37', '#ffd454'],
        broadcastIcons: ['在施项目', '停工项目', '缓建项目'],
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    },
  }

  export function updateData(container, scheduleNextUpdate) {
    project
      .getDashboardBroadcastDetail()
      .then((d) => {
        container.broadcastData.project = d[0];
        container.broadcastData.project.value1 = d[0].project_on_count;
        container.broadcastData.project.value2 = d[0].project_off_count;
        container.broadcastData.project.value3 = d[0].project_delay_count;

        container.statsData = d[0];
        container.statsData.project_online_count = d[0].project_on_count;

        scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
