<template>
  <div id="right">
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div class="info-block-title">各月份公司项目数</div>
      <div style="height:22rem;padding-top:3rem">
        <v-chart
          :options="troubleDistributionPieChart"
          @click="projectListClick"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div class="info-block-title">今年新开项目</div>
      <div
        class="option-detail"
        @click=" showAlarmDetail()"
      >详情</div>
      <div style="padding-top:3rem">
        <div
          v-if="!threeDayManagementTopProjects.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <div v-else style="height:32rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;padding-left: 1rem;">
          <div
            v-for="project in threeDayManagementTopProjects"
            :key="project.name"
            style="height:2rem;display:flex;justify-content:space-between;cursor:pointer"
            @click="handleSelect(project)"
          >
            <div>{{project.project_name.length>17?project.project_name.slice(0,17)+'...':project.project_name}}</div>
            <div style="margin-left:0.5rem">{{project.starttime}}</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="90rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:32rem;"
      >
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div
            class="stats-chart-block"
            style="height:16rem;width:86.25rem;"
          >
            <div
              class="stats-close-btn"
              @click="popupShow = false"
            />
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">新开项目
            </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:5rem">序号</div>
                <div style="width:15rem;text-align:center">分公司</div>
                <div style="width:30rem">项目名称</div>
                <div style="width:8rem">开工时间</div>
                <div style="width:8rem">项目经理</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popThreeDayManagementTopProjects"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:5rem">
                    {{securityStarPage>1?(securityStarPage-1)*10+index+1:(index+1>9?(index+1):'0'+(index+1))}}
                  </div>
                  <div style="width:15rem;text-align:center">{{item.org_name}}</div>
                  <div style="width:30rem">{{item.project_name}}</div>
                  <div style="width:8rem;">{{item.starttime}}</div>
                  <div style="width:8rem;">{{item.head_person}}</div>
                </div>
                <div
                  v-if="!(popThreeDayManagementTopProjects && popThreeDayManagementTopProjects.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无新开项目
                </div>
              </div>
              <el-pagination
                background
                layout="prev, pager, next"
                :current-page.sync="securityStarPage"
                :page-count="securityStarPages"
                @current-change="securityStarPageChange"
                style="text-align:center;margin-top:0.5rem"
              ></el-pagination>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow2=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle}}
            </div>
            <div class="tabbar" style="width:22rem">
              <div
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >在施（{{normal}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 5 }"
                @click="getAttendance(5)"
              >缓建（{{wait}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 4 }"
                @click="getAttendance(4)"
              >停工（{{stop}}）</div>
            </div>
            <div style="width:10rem"></div>
          </div>


            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:5rem">序号</div>
                <div style="width:8rem;text-align:center">分公司</div>
                <div style="width:26rem">项目名称</div>
                <div style="width:10rem">现在项目状态</div>
                <div style="width:8rem">所在城市</div>
                <div style="width:12rem">建筑面积</div>
                <div style="width:8rem">开工时间</div>
                <div style="width:12rem;text-align:center">操作</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popProjectsList"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:5rem">
                    {{securityStarPage2>1?(securityStarPage2-1)*10+index+1:(index+1>9?(index+1):'0'+(index+1))}}
                  </div>
                  <div style="width:8rem;text-align:center">{{item.org_name}}</div>
                  <div style="width:26rem">{{item.project_name}}</div>
                  <div style="width:10rem">{{popupSelect==2?'在施':(popupSelect==5?'缓建':'停工')}}</div>
                  <div style="width:8rem;">{{item.city_name}}</div>
                  <div style="width:12rem;">{{item.built_up_area||'--'}} 万平</div>
                  <div style="width:8rem;">{{item.starttime}}</div>
                  <div style="width:12rem;text-align:center" @click="handleSelect(item)">
                    <a href="javascript:;" style="color:#4fb5da;">进入项目大屏</a>
                  </div>
                </div>
                <div
                  v-if="!(popProjectsList && popProjectsList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无{{popupSelect==2?'在施':(popupSelect==5?'缓建':'停工')}}项目
                </div>
              </div>
              <el-pagination
                background
                layout="prev, pager, next"
                :current-page.sync="securityStarPage2"
                :page-count="securityStarPages2"
                @current-change="securityStarPageChange2"
                style="text-align:center;margin-top:0.5rem"
              ></el-pagination>
            </div>

        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import wrapper from "../../components/wrap";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation, project } from "../../api";
import { singleLineScrollChartTemplate } from "./lineChartTemplates";
// import { cubeBarChartTemplate } from "./barChartTemplates";
import { applyUpdate } from "../../utils";

export default {
  name: "IndexRightBar",
  components: {
    "v-chart": ECharts,
    wrapper,
  },
  props: {
    broadcastDetails: Object,
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    gotoProject: Function,
  },
  data() {
    return {
      popupShow: false,
      troubleDistributionPieChart: {},

        option: {
            color: ["#23cf9e", "#eab91e", "#e02f37"],
          tooltip: {
            trigger: 'axis',
          },
          // legend: {
          //   type: 'scroll', // 添加这一行代码即可实现图例分页功能
          //   orient: 'horizontal', // 'vertical'
          //   width: '70%',
          //   left: '30%',
          //   x: 'center',
          //   icon: 'circle',
          //   itemWidth: 14,
          //   itemHeight: 14,
          //   itemGap: 12,
          //   textStyle: {
          //     color: '#ccc',
          //     fontWeight: 'bold',
          //     fontSize: '11'
          //   },
          //   pageIconColor: '#fff',
          //   // padding: [8, 0, 0, 60],
          //   // top: '4%',
          //   // right: '3%',
          //   data: []
          // },
          grid: {
            left: '7%',
            right: '4%',
            bottom: '1%',
            top: '19%',
            containLabel: true
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                 rotate: 40
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
           yAxis: {
              name: "项目数：个",
              minInterval: 1,
              nameTextStyle: {
                color: "#ccc",
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "#14253f",
                  width: 1,
                },
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              axisLabel: {
                color: "#fff",
                fontSize: 12,
              },
            },
            series: [
              {
                type: "line",
                name: "在施",
                data: [],
                areaStyle: {
                  color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "rgba(27, 208, 128, 0.5)" },
                    { offset: 1, color: "rgba(27, 208, 128, 0)" },
                  ]),
                },
              },
              {
                type: "line",
                name: "缓建",
                data: [],
                areaStyle: {
                  color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "rgba(234,185,30, 0.5)" },
                    { offset: 1, color: "rgba(234,185,30, 0)" },
                  ]),
                },
              },
              {
                type: "line",
                name: "停工",
                data:[],
                areaStyle: {
                  color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "rgba(224,47,55, 0.5)" },
                    { offset: 1, color: "rgba(224,47,55, 0)" },
                  ]),
                },
              },
            ],
        },
      threeDayManagementTopProjects: [],
      popThreeDayManagementTopProjects: [],
      securityStarPage: 1,
      securityStarPages: 1,

      search_keys:[],
      activeIndex:0,
      mapLevel: 0,
      popupShow2: false,
      popupSelect: 2,
      popupTitle: "",
      popProjectsList: [],
      securityStarPage2: 1,
      securityStarPages2: 1,
      normal:0,
      wait:0,
      stop:0
    };
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;
    window.$districtLevel = "";
    this.getData();

    window.setInterval(() => {
      this.getData();
    }, 5000);
  },
  watch: {
    orgLevel: function (newValue) {
      if (newValue) {
        this.getData();
      }
    },
    districtLevel: function (newValue) {
      if (newValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
        this.getData();
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.mapLevel = newValue;
        if (newValue == 2) {
          this.getData();
        }
      }
    },
  },
  methods: {
    handleSelect(item) {
      this.gotoProject(item.project_id);
    },

    projectListClick(params){
      // console.log(params)

      if(params.seriesIndex==0){
        this.popupSelect=2;
      }else if(params.seriesIndex==1){
        this.popupSelect=5;
      }else{
        this.popupSelect=4;
      }
      this.activeIndex=params.dataIndex;
      this.popupShow2 = true;
      this.securityStarPage2=1;
      this.popupTitle = params.name + "项目";
      this.normal=this.troubleDistributionPieChart.series[0].data[params.dataIndex]
      this.wait=this.troubleDistributionPieChart.series[1].data[params.dataIndex]
      this.stop=this.troubleDistributionPieChart.series[2].data[params.dataIndex]
      this.securityStarPageChange2(1);
    },
    securityStarPageChange2(currPage) {
      nation
        .getProjectHistoryStatusDetailCollect(this.popupSelect,currPage, 10,this.search_keys[this.activeIndex])
        .then((d) => {
          this.popProjectsList = d;
          this.securityStarPages2 = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },

    getAttendance(popupSelect) {
      this.popupSelect = popupSelect;
      this.securityStarPageChange2(1)
    },
    securityStarPageChange(currPage) {
      project
        .getDashboardProjectNewCollect(currPage, 10)
        .then((d) => {
          this.popThreeDayManagementTopProjects = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    showAlarmDetail() {
      this.popupShow = !this.popupShow;
      this.securityStarPage=1;
      this.securityStarPageChange(1);
    },
    getData() {
      project
        .getDashboardMonth12Collect()
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(singleLineScrollChartTemplate));

            this.search_keys=d.search_keys;
            this.option.xAxis[0].data = d.xAxis_data;
            this.option.series[0].data = d.series[0];
            this.option.series[1].data = d.series[1];
            this.option.series[2].data = d.series[2];
            this.troubleDistributionPieChart = applyUpdate(chart, this.option);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));

      project
        .getDashboardProjectNewCollect(1, 15)
        .then((d) => {
          this.threeDayManagementTopProjects = d;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
  },
};
</script>

<style scoped lang="less">
.alarm-icon {
  width: 60px;
  height: 59px;
}

.option-detail {
  position: absolute;
  color: #c8dbf4;
  font-weight: bold;
  right: 0.5rem;
  top: 0.6rem;
  font-size: 0.6rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  border: 1px solid #0d2b50;
  text-align: center;
  cursor: pointer;
  background-color: #4999d9;
  // background-color: #0a57be;
}
#alarm-drop {
  background: url("../../assets/nation/坠落报警.png");
  background-size: contain;
}
#alarm-help {
  background: url("../../assets/nation/紧急呼救.png");
  background-size: contain;
}
#alarm-danger {
  background: url("../../assets/nation/危险预警.png");
  background-size: contain;
}
#alarm-static {
  background: url("../../assets/nation/静止报警.png");
  background-size: contain;
}

.alarm-icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
