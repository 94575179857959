// import { data } from 'jquery';

const baseUrl = process.env.VUE_APP_API_BASE_ZGS_URL


const areaCodeMap = require('/src/area-code-map.json');

const handleRes = (res) => {
    if (res.statusCode < 200 || res.statusCode >= 300) {
      return res.response.text().then(e => Promise.reject(e))
    } else {
      return res.response.json().then(_d => {
        if (_d.code !== 0 && _d.code !== 200) {
          return Promise.reject(_d)
        } else if (_d.data) {
          _d.data.__count__ = _d.count;
          return _d.data;
        } else{
          return _d;
        }
      })
    }
  }
// function addFilterParams(url) {
//       // for(var key in areaCodeMap){
//       //   if(key == window.$districtLevel){
//       //     console.log(areaCodeMap[key]);
//       //   }
//       // }
//       formattingOrgId();
//       const containsArea = url.indexOf('areaLvl=') >= 0;
//       const containsOrg = url.indexOf('orgLvl=') >= 0;
//       if (!containsArea || !containsOrg) {
//         const sign = url.indexOf('?') >= 0 ? '&' : '?';
//         const areaStr = `areaLvl=${window.$districtLevel || '100000'}`;
//         const orgStr = `orgLvl=${window.$orgLevel || ''}`;
//         if (!containsArea && !containsOrg) {
//           return url + sign + areaStr + '&' + orgStr;
//         } else {
//           return url + sign + (!containsArea ? areaStr : orgStr);
//         }
//       } else {
//         return url;
//       }
//     // const sFIDArea = url.indexOf('sFID=') >= 0;
//     // if (!sFIDArea) {
//     //   // const sign = url.indexOf('?') >= 0 ? '&' : '?';
//     //   formattingOrgId();
//     //   return url;
//     //   // const orgStr = `orgLvl=${window.$sFid || ''}`;
//     //   // return url + sign + orgStr;
//     // } else {
//     //   return url;
//     // }
//   }

  function addFilterParams2(url) {
    // 忽略地区接口
    if(url.indexOf("user_num_of_all_cities")!=-1
     || url.indexOf("county_user_nums")!=-1 ){
      return url;
    }
    var provinceName = '';
    var cityName = '';
    for(var key in areaCodeMap){
      if(key == window.$districtLevel){
        let str = areaCodeMap[key];
        if(str != '' && str != null){
          var datalist = str.split("/");
          if(datalist.length >= 1){
            provinceName = datalist[0];
            cityName = datalist[1];
          }
        }
      }
    }
    const provinceExist = url.indexOf('provinceName=') >= 0;
    const cityExist = url.indexOf('cityName=') >= 0;
    if(!provinceExist || !cityExist) {
      const sign = url.indexOf('?') >= 0 ? '&' : '?';
      const provinceStr = `provinceName=${provinceName || ''}`;
      const cityStr = `cityName=${cityName || ''}`;
      if (!provinceExist && !cityExist) {
        return url + sign + provinceStr + '&' + cityStr;
      } else {
        return url + sign + (!provinceExist ? provinceStr : cityStr);
      }
    } else {
      return url;
    }
}

/** @param url {string}
 * @return Promise*/
 const get = url => fetch(addFilterParams2(baseUrl + url)).then(res => ({ response: res, statusCode: res.status })).then(handleRes)

// const post = (url, body, type = 'json') => {
//     let headers, _body
//     if (type === 'json') {
//       headers = { 'Content-Type': 'application/json','traditional':true}
//       // headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
//       _body = JSON.stringify(body)
//     } else if (type === 'file') {
//       _body = new FormData()
//       _body.append('files', body)
//     } else {
//       _body = body
//     }
//     if (url.slice(0, 4) != 'http') {
//       url =baseUrl + url;
//     }
//     return fetch(addFilterParams2(url), { method: 'POST', headers, body: _body })
//       .then(res => ({ response: res, statusCode: res.code })).then(handleRes)
//   }
// function formattingOrgId(){
//   let orgId = window.$sFid;
//   if(orgId !== null && orgId !== undefined && orgId!=='' ){
//     if(orgId.indexOf(".ogn")==0){
//         window.$sFid = '/'+window.$sFid+'.ogn';
//     }
//   }else{
//     window.$sFid = "/7B867F6775E143F8B1F3131BFA85C13E.ogn";
//   }
// }
// function verifyOrgId(sFID){
//   if(sFID === '' || sFID === undefined || sFID === null)
//       sFID = "/7B867F6775E143F8B1F3131BFA85C13E.ogn";
//   return sFID
// }
// 通过年处理日期范围
// function formattingDateIntervalByYear(year){
//   if(year != null && year !=""){
//     let dateInterval = new Array();
//     dateInterval.push(year+"-01");
//     dateInterval.push(year+"-12");
//     return dateInterval;
//   }
// }
const tech = {
  //在施项目统计 A、B类评审方案统计
  getSchemeStatistic: ()=> get(`jiShu/fanAnNumByorgId?orgId=${window.$sFid}`),
  // 查询各省份的A类方案
  getSchemeStatisticByMap: ()=> get(`jiShu/getOrgMap?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
  getSchemeStatisticByProvince: (provinceName)=> get(`jiShu/getProvinceMap?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }&provinceName=${provinceName}`),
  getSchemeStatisticByCity: (cityName)=> get(`jiShu/getCityMap?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }&cityName=${cityName}`),
  getSchemeApproval: ()=> get(`jiShu/queryFanAnSpqk?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
  // 方案滞后情况
  getSchemeLag: ()=> get(`jiShu/queryFanAnJiHuaQk?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
  // 专家问答排行
  getExpertRanking: ()=> get(`jiShu/queryExpertQk?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),  
  // 方案检查情况
  getSchemeClarificaiton: ()=> get(`jiShu/jiaoDiQkByOrgId?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
  // 方案分类
  getSchemeCategory: ()=> get(`jiShu/countFanAnByOrgId?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
  // 优秀方案统计
  getGoodPlan: ()=> get(`jiShu/queryFineFanAnByOrgId?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
  // 科技板块
  getScienceStatistic: ()=> get(`keji/kejiTitleByOrgId?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
   // 科技板块 地图展示
  getOutcomeGoalsByMap: ()=> get(`keji/kejiMapByOrgId?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
  // 投标情况总览
  getBindCondition: ()=> get(`keji/tbqkByOrgId?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
  // 新技术应用情况
  getNewTech: ()=> get(`keji/queryXjsqkbyOrgId?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
  // BIM应用情况
  getBimTech: ()=> get(`keji/queryBimqkByOrgId?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
  // 科技成果统计
  getCnTechResult: ()=> get(`keji/queryKejiCgByOrgId?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
  // 重点工程统计
  getMajorProject: ()=> get(`keji/queryZdCgByOrgId?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
  // 工作室项目统计
  getStudioProject: ()=> get(`keji/queryGzsXMByOrgId?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
   // 工作室成果统计
   getStudioAchi: ()=> get(`keji/queryGzsCGByOrgId?orgId=${window.$sFid =="7B867F6775E143F8B1F3131BFA85C13E"? '':window.$sFid }`),
  // 项目部层面接口
  // 项目方案统计
  getProjectSchemeStatic:(project_id)=>get(`jiShu/fanAnNumByXmId?xmId=${project_id}`),
  // 项目方案审批情况
  getProjectSchemeApproal:(project_id)=>get(`jiShu/queryFanAnSpqkByXmId?xmId=${project_id}`),
  // 项目方案滞后情况
  getProjectSchemeLag:(project_id)=>get(`jiShu/queryFanAnJiHuaQkByXmid?xmId=${project_id}`),
  // 项目方案交底情况
  getProjectSchemeClar:(project_id)=>get(`jiShu/jiaoDiQkByXmId?xmId=${project_id}`),
  // 项目方案分类统计
  getProjectSchemeCategory:(project_id)=>get(`jiShu/countFanAnByXmId?xmId=${project_id}`),
  // 科技成果导播数据
  getProjectScienceStatistic:(project_id)=>get(`keji/kejiTitleByXmId?xmId=${project_id}`),
   // 新技术应用统计
  getProjectNewTech:(project_id)=>get(`keji/queryXjsqkbyXmId?xmId=${project_id}`),
  // 项目方案BIM应用统计
  getProjectBimApply:(project_id)=>get(`keji/queryBimqkByXmId?xmId=${project_id}`),
  // 项目重点工程统计
  getProjectMajor:(project_id)=>get(`keji/queryZdCgByXmid?xmId=${project_id}`),  
  // 项目工作室统计
  getProjectStudio:(project_id)=>get(`keji/queryGzsCgByXmId?xmid=${project_id}`),
  // 处理小数点
  formatDecimal(num, decimal) {
    num = num.toString();
    let index = num.indexOf('.')
    if (index !== -1) {
        num = num.substring(0, decimal + index + 1)
    } else {
        num = num.substring(0)
    }
    return parseFloat(num).toFixed(decimal)
}
  
}

export { baseUrl, tech }
