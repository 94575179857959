<template>
<div id="left">
    <div class="info-block" style="min-height:5rem">
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>持证情况</div>
        <!-- <div class="detail" @click="showDetail(1)">
          详情
        </div> -->
      </div>
      <div style="height:25rem;padding-top:3rem">
        <div
          style="display:flex;justify-content:space-around;margin-bottom:0.6rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')"
          >
            一级建造师
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')"
          >
            职业资格证书
          </div>
        </div>
        <div
          v-if="companyWorkersPieChart.series && [].concat.apply([], companyWorkersPieChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyWorkersPieChart" @click="companyWorkersPieChartClick" autoresize/>
      </div>
    </div>
  </div>
  <!-- <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title">持证情况</div>
    <div
      class="detail-select"
      style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem">
    </div>
    <div style="height:17rem">
      <div v-if="!PermitStateChart.series || !PermitStateChart.series[0].data.length" style="padding-top:5rem">
        <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;">
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
      </div>
      <v-chart v-else :options="PermitStateChart" autoresize />
    </div>
  </div> -->
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { human } from '../../../../api_hr'
  import { colors } from '../../pieChartTemplates';
//  import CustomSelect from '../../../../components/select'
// import func from 'vue-editor-bridge'

  export default {
    name: 'PermitStateChart',
    components: {
      'v-chart': ECharts,
    //   'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.sFidList = this.orgOptions.map(item => item.sfid);
          this.getAll();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      }
    },
    data () {
      return {
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear(),
        companyWorkersData: {},
        companyWorkersOption: "1",
        companyWorkersPieChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.getAll();
    },
    methods: {
      getAll(){
          this.switchCompanyWorkersOption(this.companyWorkersOption);
      },
      switchCompanyWorkersOption(choose){
          this.companyWorkersOption = choose;
          switch (this.companyWorkersOption) {
              case "1":
                  this.updateArchitectChart();
                  break;
              case "2":
                  this.updatePermitStateChart();
                  break;
              default:
                  break;
          }
      },
      categorySelect(option) {
        this.selectedYear = option;
        this.updatePermitStateChart();
      },
      updateArchitectChart(){
        human.getArchitectPermit().then((res => {
          let Architect_option = {
            color:colors,
            backgroundColor: '',
              tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b}: {c} ({d}%)'
              },
              legend: {
                data: ['注册在一公司人数','注册在一局人数'],
                position:'inner',
                textStyle:{
                    fontSize:12,
                    color:"white"
                }
              },
              series: [
                  {
                      name: '持证情况',
                      type: 'pie',
                      radius: ['35%', '50%'],
                      labelLine: {
                          length: 30,
                      },
                      data: res['submitInfo'],
                      label: {
                          formatter: '{b}:{d}%  ',
                          borderWidth: 1,
                          borderRadius: 2,
                          fontSize:12
                      }
                  }
              ]
           };
           this.companyWorkersPieChart = Architect_option;
        }))
      },
      updatePermitStateChart() {
        human.getPermitState().then((result => {
            // const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            let datas = [];
            datas.push(
                {value: result[0]['一级注册造价工程师'].USER_NUM, name: '一级注册造价工程师'},
                {value: result[0]['注册安全工程师'].USER_NUM, name: '注册安全工程师'},
                {value: result[0]['注册会计师'].USER_NUM, name: '注册会计师'},
                {value: result[0]['法律职业资格'].USER_NUM, name: '法律职业资格'},
                {value: result[0]['其他注册类证书'].USER_NUM, name: '其他注册类证书'}
            );
            let certificate_option = {
                color:colors,
                backgroundColor: '',
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} {d}%'
                },
                series: [
                    {
                        name: '持证情况',
                        type: 'pie',
                        radius: '50%',
                        data: datas,
                        label: {
                            formatter: '{b}:{d}% ',
                            borderWidth: 1,
                            borderRadius: 2,
                            fontSize:14
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            // this.PermitStateChart = applyUpdate(chart, certificate_option);
            this.companyWorkersPieChart = certificate_option;
        }))
      },
      showDetails(param){
        console.log(param);
      },
      companyWorkersPieChartClick(){
        console.log("companyWorkersPieChartClick");
      }
    }
  }
</script>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}

.option-block2 {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3.4rem;
  border: 1px solid #1b8ebd;
  text-align: center;
  cursor: pointer;
}
.option-block-selected{
  background-color: transparent !important;
  border: 1px solid #274C7D !important;
}
.option-block-selected2 {
  background-color: #4191e5;
  color: #fff;
}
.attendance{

}
.attendance-top{
  padding-top: 10px;
}
.tabbar{
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  border: 1px solid #157EAB;
  width: 210px;
  margin: auto;
  cursor: pointer;
}
.tabbar div{
  flex: 1;
  text-align: center;
}
.tabar-select{
  background-color: #4191E5;
  font-weight: bold;
}
.items div{
  color: #fff !important;
}
.selected{
  color: #fff !important;
}
.attendance-bottom{

}
.linebar{
  margin-top: 5rem;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto 10px;
}
.real-bar{
  height: 5px;
  background-color: #57C7B6;
  border-radius: 12px;
}
</style>

