<template>
  <router-view/>
</template>

<script>
  export default {
    name: 'app',
    mounted () {
      document.querySelector('html').style.fontSize = window.innerWidth / 120 + 'px';
      window.addEventListener('resize', () => document.querySelector('html').style.fontSize = window.innerWidth / 120 + 'px');
      // if (this.$route.path.slice(0, 7) != '/beacon') {
        require('element-theme-dark');
      // }
    }
  }
</script>

<style lang="less">

  html, html * {
    scrollbar-width: none;
  }
  body .echarts {
    width: 100%;
    height: 100%;
  }

  body * {
    box-sizing: border-box;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
  }
  ::-webkit-resizer {
    width: 0;
  }
  ::-webkit-scrollbar-corner {
    width: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #323d86;
    border-radius: 5px;
  }

  #title-container {
    display:flex;
    justify-content:center;
  }

  #title-center {
    display: inline-block;
    line-height: 4rem;
    font-size: 1.75rem;
    text-align: center;
    height: 4rem;
    background: url("./assets/title-bar.png") transparent;
    background-size: contain;
  }

  #title-left {
    width: 94px;
    background: url("./assets/title-side.png") no-repeat transparent;
    background-size: contain;
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
  }

  #title-right {
    width: 94px;
    background: url("./assets/title-side.png") no-repeat transparent;
    background-size: contain;
  }

  #title {
    line-height: 4rem;
    font-size: 1.75rem;
    text-align: center;
    height: 4rem;
    margin-bottom: -2rem;
    background: url("./assets/title.png") no-repeat transparent;
    background-size: contain;
  }

  .light-blue-text {
    color: #5accf1
  }

  .light-grey-text {
    color: #535b6f
  }

  .red-text {
    color: #db2866;
  }

  .yellow-text {
    color: #d19a2b;
  }

  .green-text {
    color: #3ecb76;
  }

  .global-bg {
    user-select: none;
    height: 100vh;
    width: 100vw;
    color: #fff;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    background: url('./assets/bg.png');
    background-size: cover;
  }

  @font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'digital';
    src: url('./assets/nation/digital-7 (mono).ttf');
  }
</style>
