<template>
  <div id="right">
    <PassRateBarChart :district-level="districtLevel" :org-level="orgLevel" />
    <TroubleCountsPieChart :district-level="districtLevel" :org-level="orgLevel" />
  </div>
</template>

<script>
  import PassRateBarChart from "./passRateBarChart";
  import TroubleCountsPieChart from "./troubleCountsPieChart";

  export default {
    name: 'QualityIndexRightBar',
    components: {
      PassRateBarChart,
      TroubleCountsPieChart,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
  }
</script>

<style lang="less">
</style>
