import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
  };

  const buildingBoundaries = {
    '_': [[-10000, 10000],[10000, 10000],[10000, -10000],[-10000, -10000]],
  };

  const buildingFinishedHeights = {
    '_': -1000000,
  };

  const doneFloors = {
  };

  const traceViews = {
    '_': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    '1#': {
      center: [-7452.872678057295, 2707.84566374897],
      pitch: 80,
      zoom: 4.74,
      bearing: -28.6,
    },
  };

  function getUserZValue(user) {
    if (user.area == 19) {
      return -24 + 5;
    } else if (user.area == 20) {
      return -207 + 5;
    } else {
      return 0;
    }
    //return (user.floor == 'ground' ? 0 : user.height) * 2.9 + 5;
  }

  function getFloorFromZValue(bldg, zValue) {
    if (zValue >= -116) {
      return -2;
    } else {
      return -4;
    }
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/xian_subway_15.glb',
    modelType: 'gltf',
    modelSubType: 'draco',

    buildId: 'e559bdf7-ef51-11eb-a6b1-98039b887f1a',

    //fullOpacity: 0.85,
    //revealOpacity: 0.15,
    //deepRevealOpacity: 0,

    modelOptions: {
      scale: 30,
      rotZ: 0,
      moveX: -70,
      moveY: 300,
      moveZ: -15600,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: 0,
      z: 0
    },

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
