<template>
  <div id="left">
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div class="info-block-title">大型器械使用情况</div>
      <div style="height:18rem;min-height:12rem;padding-top:3rem">
        <div
          v-if="machineUse.series && [].concat.apply([], machineUse.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="machineUse"
          ref="troubleType"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div class="info-block-title">设备出厂年限</div>

      <div style="height:20rem;padding-top:3rem">

        <div style="display:flex;align-items:center;justify-content:center">
          <div
            v-for="(item,index) in machineList"
            :key="index"
            class="option-block2"
            style="min-width: 4rem;"
            :class="{ 'option-block-selected2': mode == index }"
            @click="() => swithAttendance(index)"
          >
            {{item.name}}
          </div>
        </div>

        <div
          v-if="machineOutYear.series && [].concat.apply([], machineOutYear.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="machineOutYear"
          ref="troubleType1"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div class="info-block-title">联采执行率</div>
      <div
        class="detail-select"
        style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
      >
        <CustomSelect
          :options="machineType"
          @input="companySelect2"
          style="width:8rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
        />
      </div>
      <div style="height:20rem;padding-top:.5rem">

        <div
          v-if="machineJointRate.series && [].concat.apply([], machineJointRate.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="machineJointRate"
          ref="troubleType2"
          autoresize
        />
      </div>
    </div>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import CustomSelect from "../../components/select";
import { project } from "../../api";
import {
  stackedBarChartTemplate,
  SimpleBarChartTemplate,
} from "./barChartTemplates";
import { applyUpdate } from "../../utils";

export default {
  name: "SecurityMechLeftBar",
  components: {
    "v-chart": ECharts,
    CustomSelect: CustomSelect,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
  },
  data() {
    return {
      machineType: ["全部设备"],
      machineIdList: [""],
      machineList: [],
      machineIndex: 0,
      machineOutYear: {},
      machineUse: {},
      machineJointRate: {},
      option: {
        color: ["#4270ee", "#46a0ff", "#159172", "#2ad156"],
        tooltip: {
          // show: false,
          // trigger: "none",
        },
        legend: {
          data: [],
        },
        dataZoom: [{
          type: 'slider',
          show: true,
          start: 0,
          end: 100,
          yAxisIndex: 0,
          filterMode: 'empty',
          width: 6,
          height: '78%',
          left: '95%',
          handleSize: 0,
          zoomLock: true,
          top: '8%',
          fillerColor: '#323d86',
          borderColor: '#323d86',
          textStyle: {
            color: "#fff",
            fontSize: 10,
          }
        }, {
          type: 'inside',
          yAxisIndex: 0,
          start: 0,
          end: 100,
          zoomOnMouseWheel: false,
          moveOnMouseMove: true,
          moveOnMouseWheel: true
        }],
        grid: {
          top: "25%",
          left: "2%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          // show: false,
          axisLabel: {
            color: "#e6f2f3",
            rotate: 0,
            fontWeight: "normal",
          },
          // 显示分隔线
          splitLine: {
            show: true,
            // lineStyle: {
            //   color: "#36445f",
            //   width: 1,
            //   type: "solid",
            // },
          },
        },
        yAxis: [
          {
            name: '数量：个',
            data: [],
            nameRotate: "",
            nameTextStyle: {
              color: "#ccc",
              align: "right",
            },
            type: "category",
            axisLabel: {
              color: "#ffffff",
              formatter: function (value) {
                return value.length > 8 ? value.slice(0, 8) + "..." : value;
              },
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            axisTick: { show: false },
          },
        ],
        series: [],
      },
      option1: {
        color: ["#46a0ff", "#ffed21", "#ff8521"],
        tooltip: {
          // show: false,
          // trigger: "none",
        },
        legend: {
          data: [],
        },
        dataZoom: [{
          type: 'slider',
          show: true,
          start: 0,
          end: 100,
          yAxisIndex: 0,
          filterMode: 'empty',
          width: 6,
          height: '69%',
          left: '95%',
          handleSize: 0,
          zoomLock: true,
          top: '8%',
          fillerColor: '#323d86',
          borderColor: '#323d86',
          textStyle: {
            color: "#fff",
            fontSize: 10,
          }
        }, {
          type: 'inside',
          yAxisIndex: 0,
          start: 0,
          end: 100,
          zoomOnMouseWheel: false,
          moveOnMouseMove: true,
          moveOnMouseWheel: true
        }],
        grid: {
          top: "10%",
          left: "2%",
          right: "9%",
          bottom: "15%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "#e6f2f3",
            rotate: 0,
            fontWeight: "normal",
          },
          // 显示分隔线
          splitLine: {
            show: true,
          },
        },
        yAxis: [
          {
            data: [],
            nameRotate: "",
            nameTextStyle: {
              color: "#ccc",
              align: "right",
            },
            axisTick: { show: false },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            type: "category",
            axisLabel: {
              color: "#ffffff",
              formatter: function (value) {
                return value.length > 8 ? value.slice(0, 8) + "..." : value;
              },
            },
          },
        ],
        series: [],
      },
      option2: {
        color: ["#2ad156"],
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0} {c0}",
        },
        grid: {
          top: "10%",
          left: "10%",
          right: "9%",
          bottom: "20%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisLabel: {
              color: "#e6f2f3",
              rotate: 45,
              fontWeight: "normal",
              formatter: function (value) {
                return value.length > 8 ? value.slice(0, 8) + "..." : value;
              },
            },
          },
        ],
        yAxis: [
          {
            show: true,
            name: "执行率：%",
            type: "value",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
              align: "left",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                width: 1,
                type: "solid",
              },
            },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: "执行率：",
            type: "bar",
            barWidth: 10,
            barMaxWidth: 18,
            data: [],
          },
        ],
      },
      mode: 0,
    };
  },
  watch: {
    orgLevel: function (newValue) {
      if (newValue) {
        this.getData();
      }
    },
    districtLevel: function (newValue) {
      if (newValue) {
        this.getData();
      }
    },
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;

    this.getData();
  },
  methods: {
    swithAttendance(mode) {
      this.mode = mode;
      this.getEcharts2();
    },
    getData() {
      project
        .getGeneralParamsSelect(31)
        .then((d) => {
          this.machineType = ["全部设备"].concat(d.map((item) => item.value));
          this.machineIdList = [""].concat(d.map((item) => item.innerid));
          this.machineList = d.map((item) => {
            return {
              name: item.value,
              id: item.innerid,
            };
          });
          this.getEcharts2();
          this.getEcharts3();
        })
        .catch((e) => console.error(e.msg || e.message));
      project
        .getDashboardMachineCollect()
        .then((d) => {
          //     d = d.slice(-7);
          const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
          // var ;
          this.option.yAxis[0].data = d.yAxis_data;
          this.option.series = d.series.map((item, index) => {
            return {
              name: d.legend_data[index],
              type: "bar",
              stack: "total",
              barMaxWidth: 18,
              emphasis: {
                focus: "series",
              },
              data: item,
            };
          });
          this.option.legend.data = d.legend_data;

          if (this.option.series[0].data.length <= 6) {
            this.option.dataZoom[0].show = false;
          } else {
            this.option.dataZoom[0].show = true;
            this.option.dataZoom[0].start = this.option.dataZoom[1].start = 100 - Math.min(100, (6 / this.option.series[0].data.length) * 100);
          }

          this.machineUse = applyUpdate(chart, this.option);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    companySelect2(option) {
      this.machineIndex = this.machineType.indexOf(option);
      this.getEcharts3();
    },
    getEcharts2() {
      project
        .getDashboardMachineProduceYearCollect(this.machineList[this.mode].id)
        .then((d) => {
          //     d = d.slice(-7);
          const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
          // var ;
          this.option1.yAxis[0].data = d.yAxis_data;
          this.option1.series = d.series.map((item, index) => {
            return {
              name: d.legend_data[index],
              type: "bar",
              stack: "total",
              barMaxWidth: 18,
              emphasis: {
                focus: "series",
              },
              data: item,
            };
          });
          this.option1.legend.data = d.legend_data;

          if (this.option1.series[0].data.length <= 6) {
            this.option1.dataZoom[0].show = false;
          } else {
            this.option1.dataZoom[0].show = true;
            this.option1.dataZoom[0].start = this.option1.dataZoom[1].start = 100 - Math.min(100, (6 / this.option1.series[0].data.length) * 100);
          }

          this.machineOutYear = applyUpdate(chart, this.option1);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    getEcharts3() {
      project
        .getDashboardMachineJointRateCollect(
          this.machineIdList[this.machineIndex]
        )
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));

          this.option2.xAxis[0].data = d.xAxis_data;
          // this.option2.series = [chart.series[0]];
          this.option2.series[0].data = d.series;
          this.machineJointRate = applyUpdate(chart, this.option2);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
  },
};
</script>

<style scoped lang="less">
// .empty-chart-icon {
//   width: 10rem;
//   height: 8rem;
//   background: url("../../assets/empty_chart.png") no-repeat;
//   background-size: contain;
// }
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
</style>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
