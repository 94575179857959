<template>
  <div id="right">
    <wrapper style="padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div class="info-block-title">工程整体合格率</div>
        <!-- <div
          class="detail-select"
          style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
        >
          <CustomSelect
            :options="dangerCategoryNameList"
            :value="selectedDangerCategoryName"
            @input="categorySelect"
            style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
          />
        </div> -->
        <div style="height:25rem;padding-top:3rem;display: flex; align-items: center;">

          <div
            v-if="!passRateBarChart.length"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <div
            v-else
            style="margin-top:1rem;width:100%;height:100%"
          >

            <div style="font-size:12px;color:#fff;padding-left:1rem;">合格率：%</div>
            <div
              class="linebar"
              v-for="(item, index) in passRateBarChart"
              :key="index"
            >
              <div style="display:flex;width:100%;justify-content: space-between;margin-bottom: 0.5rem;">

                <div style="color: #A7B6BC;width: 5rem">
                  {{item.eng_type_name}}
                </div>
                <div style="color: #57C7B6;width:30px;">
                  {{item.rate}}
                </div>
              </div>
              <div
                style="width:100%"
                class="line-bar"
              >
                <div
                  class="real-bar"
                  :style="{'width': item.rate + '%' }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem;padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div class="info-block-title">隐患类型</div>
        <div
          class="detail-select"
          style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
        >
          <CustomSelect
            :options="dangerCategoryNameList"
            :value="selectedDangerCategoryName2"
            @input="categorySelect2"
            style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
          />
        </div>
        <div style="height:25rem;padding-top:3rem;display: flex; align-items: center;">
           <div
            v-if="troubleCountsPieChart.series && [].concat.apply([], troubleCountsPieChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 24rem; height: 12rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          
          <div
            style="width:100%;height:100%"
            v-else
          >
          <v-chart
            style="width:100%;height:100%"
            :options="troubleCountsPieChart"
            ref="troubleCounts"
            @mouseover="troubleCountsMouseOver"
            @mouseout="troubleCountsMouseOut"
            autoresize
          />
            <div style="position:absolute;top:3rem;left:1rem;font-size:0.8rem">数量：个</div>

          </div>
        </div>
      </div>

    </wrapper>

  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { project, nation } from "../../api";
import wrapper from "../../components/wrap";
import CustomSelect from "../../components/select";
import { attendancePieChartTemplate, colors } from "./pieChartTemplates";
import { applyUpdate } from "../../utils";

export default {
  name: "SecurityDangerRightBar",
  components: {
    "v-chart": ECharts,
    wrapper,
    CustomSelect: CustomSelect,
  },
  data() {
    return {
      projectId: null,
      dangerCategoryNameList: ["全部类别"],
      dangerCategoryIdList: [""],
      selectedDangerCategoryName: "全部类别",
      selectedDangerCategoryName2: "全部类别",

      passRateBarChart: {},
      troubleCountsPieChart: {},
      troubleCountsPieChartHighlightIndex: null,
    };
  },
  mounted() {
    const { id: projectID } = this.$route.params;
    this.projectId = projectID;
    const token = this.$route.query.token;
    window.token = token;
    nation
      .getSafetyDangerTypes()
      .then((d) => {
        this.dangerCategoryNameList = ["全部类别"].concat(
          d.map((item) => item.name)
        );
        this.dangerCategoryIdList = [""].concat(d.map((item) => item.innerid));
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
    this.updateSafetyDangerPassRateChart();
    this.updateSafetyDangerTroubleCountsChart();
  },
  methods: {
    categorySelect(option) {
      this.selectedDangerCategoryName = option;
      this.updateSafetyDangerPassRateChart();
    },
    categorySelect2(option) {
      this.selectedDangerCategoryName2 = option;
      this.updateSafetyDangerTroubleCountsChart();
    },
    updateSafetyDangerPassRateChart() {
      let categoryId = "";
      const idx = this.dangerCategoryNameList.indexOf(
        this.selectedDangerCategoryName
      );
      if (idx >= 0) {
        categoryId = this.dangerCategoryIdList[idx];
      }
      // console.log(this.projectId, "==============");
      project
        .getProjMdTypePassRateCollect(this.projectId, categoryId)
        .then((d) => {
          // console.log(d, "00099999999999999999999");
          this.passRateBarChart = d;
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    updateSafetyDangerTroubleCountsChart() {
      let categoryId = "";
      const idx = this.dangerCategoryNameList.indexOf(
        this.selectedDangerCategoryName2
      );
      if (idx >= 0) {
        categoryId = this.dangerCategoryIdList[idx];
      }
      project
        .getProjMdTemplateTroubleCollect(this.projectId, categoryId)
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));

          const maxPieces = 14;
          if (d.series_data.length > maxPieces) {
            d.series_data[maxPieces - 1].name = "其它";
            d.series_data[maxPieces - 1].value = d.series_data
              .slice(maxPieces - 1)
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
              );
            d.series_data = d.series_data.slice(0, maxPieces);
          }

          const option = {
            color: colors,
            legend: {
              show: false,
            },
            grid: {
              top: "5%",
              left: "10%",
              right: "9%",
              bottom: "5%",
              containLabel: true,
            },
            series: [
              {
                type: "pie",
                minAngle: 2,
                radius: ["25%", "45%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: true,
                itemStyle: {
                  normal: {
                    borderColor: "#08122b",
                    borderWidth: 2,
                  },
                },
                label: {
                  normal: {
                    show: false,
                    position: "center",
                    //formatter: '{text|{b}}\n{c} ({d}%)',
                    //formatter: '{value|{d}%}\n{text|{b}}',
                    formatter: function (params) {
                      return `{value|${Math.round(params.percent)}%}\n{text|${
                        params.name
                      }}`;
                    },
                    padding: [20, 0, 0, 0],
                    rich: {
                      text: {
                        color: "#aaa",
                        fontSize: 12,
                        align: "center",
                        verticalAlign: "middle",
                        lineHeight: 22,
                      },
                      value: {
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: "bold",
                        align: "center",
                        verticalAlign: "middle",
                      },
                    },
                  },
                  emphasis: {
                    show: true,
                    textStyle: {
                      fontSize: 14,
                    },
                  },
                },
              },
            ],
          };

          option.series[0].data = d.series_data;
          if (this.troubleCountsPieChartHighlightIndex != null) {
            this.$refs.troubleCounts &&
              this.$refs.troubleCounts.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: this.troubleCountsPieChartHighlightIndex,
              });
          }

          // 默认高亮第一个值不是0的分片，否则默认第一片
          this.troubleCountsPieChartHighlightIndex = 0;
          for (let i = 0; i < d.series_data.length; i++) {
            if (option.series[0].data[i].value) {
              this.troubleCountsPieChartHighlightIndex = i;
              break;
            }
          }

          option.series[1] = Object.assign({}, option.series[0], {
            label: {
              rich: {
                text: {
                  fontSize: 11,
                },
              },
              formatter: function (params) {
                return `${params.name} ${params.value}`;
              },
            },
            silent: true,
          });

          setTimeout(
            () =>
              this.$refs.troubleCounts &&
              this.$refs.troubleCounts.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: this.troubleCountsPieChartHighlightIndex,
              }),
            100
          );
          this.troubleCountsPieChart = applyUpdate(chart, option);
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    troubleCountsMouseOver(params) {
      if (params.dataIndex != this.troubleCountsPieChartHighlightIndex) {
        this.$refs.troubleCounts.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.troubleCountsPieChartHighlightIndex,
        });
        this.troubleCountsPieChartHighlightIndex = params.dataIndex;
      }
    },
    troubleCountsMouseOut() {
      this.$refs.troubleCounts.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.troubleCountsPieChartHighlightIndex,
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.linebar {
  width: 92%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  margin: 1.3rem auto 10px;
  font-size: 12px;
}
.line-bar {
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #57c7b6;
  border-radius: 12px;
}
.text-green {
  color: #1fc86b !important;
}

.text-yellow1 {
  color: #f89132 !important;
  border-color: #f89132 !important;
}

.text-yellow2 {
  color: #eebc34 !important;
  border-color: #eebc34 !important;
}

.text-red1 {
  color: #e62c31 !important;
}

.text-red2 {
  color: #f34e53 !important;
}

.text-grey {
  color: #9ca0aa !important;
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}

#people-warning-list {
  .warning-item {
    margin-bottom: 0.75rem;
    padding: 8px 6px;
    font-size: 0.75rem;
    line-height: 1.5rem;
    background-color: #1e2c61;
    border-radius: 0.25rem;
    color: #ffffff;
    position: relative;
    display: flex;
    div.name {
      color: #999999;
      width: 2rem;
    }
    div.value {
      width: 3.7rem;
      padding-left: 0.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    time {
      background-color: #577acc;
      border-radius: 4px;
      padding: 5px;
    }
  }
}
</style>
