<template>
  <div id="left">
    <wrapper style="padding:0">

      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>危大工程类别</div>

        </div>

        <div style="height:19rem;padding-top:3rem;display: flex; align-items: center;">
          <div
            v-if="dangerTypePieChart.series && [].concat.apply([], dangerTypePieChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;width: 26rem; height: 12rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart
            v-else
            :options="dangerTypePieChart"
            ref="dangerType"
            @mouseover="dangerTypeMouseOver"
            @mouseout="dangerTypeMouseOut"
            autoresize
          />
          <div
            v-if="!dangerTypePieChart.series || ![].concat.apply([], dangerTypePieChart.series.map(x => x.data)).length == 0"
            style="position:absolute;top:3rem;left:1rem;font-size:0.9rem"
          >数量：个</div>
        </div>
      </div>

    </wrapper>
    <wrapper style="margin-top: 1rem;padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >

        <div class="info-block-title">危大工程巡检率</div>
        <div
          class="detail-select"
          style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
        >
          <CustomSelect
            :options="dangerCategoryNameList"
            :value="selectedDangerCategoryName"
            @input="categorySelect"
            style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
          />
        </div>
        <div style="height:19rem; display: flex; align-items: center;">
          <div
            v-if="dangerCheckRateChart.series && [].concat.apply([], dangerCheckRateChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart
            v-else
            :options="dangerCheckRateChart"
            autoresize
          />
        </div>
      </div>

    </wrapper>

  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import CustomSelect from "../../components/select";
import wrapper from "../../components/wrap";
import { nation, project } from "../../api";
import { attendancePieChartTemplate } from "./pieChartTemplates";
import { SimpleBarChartTemplate } from "./barChartTemplates";
import { applyUpdate } from "../../utils";

export default {
  name: "SecurityDangerLeftBar",
  components: {
    "v-chart": ECharts,
    CustomSelect: CustomSelect,
    wrapper,
  },
  props: {
    projectDetail: Object,
    showTrace: Function,
  },
  data() {
    return {
      projectId: null,

      dangerCategoryNameList: ["全部类别"],
      dangerCategoryIdList: [""],
      selectedDangerCategoryName: "全部类别",
      dangerTypePieChart: {},
      option2: {
        legend: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["30%", "60%"],
            center: ["50%", "60%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function (params) {
                  return `{value|${Math.round(params.percent)}%}\n{text|${
                    params.name
                  }}`;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },

      dangerTypePieChartHighlightIndex: 0,
      dangerCheckRateChart: {},
      option3: {
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0}: {c0}%",
        },
        color: ["#1bd080", "#1bd080"],
        grid: {
          contentLabel: true,
          left: "12%",
          right: "6%",
          bottom: "11%",
          top: "13%",
        },
        legend: {
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              rotate: 35,
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            max: 100,
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
            show: true,
            name: "巡检率:%",
          },
        ],
        series: [
          {
            name: "巡检率",
            type: "bar",
            barWidth: 10,
            data: [],
            markLine: {
              symbol: ["none", "none"], //去掉箭头
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "dashed",
                    color: "#238778",
                  },
                  label: {
                    show: false,
                    position: "middle",
                  },
                },
              },
              data: [
                {
                  yAxis: -1, //这里设置false是隐藏不了的，可以设置为-1
                },
              ],
            },
          },
        ],
      },
    };
  },
  mounted() {
    // const self = this
    const { id: projectID } = this.$route.params;
    this.projectId = projectID;
    const token = this.$route.query.token;
    window.token = token;

    nation
      .getSafetyDangerTypes()
      .then((d) => {
        this.dangerCategoryNameList = ["全部类别"].concat(
          d.map((item) => item.name)
        );
        this.dangerCategoryIdList = [""].concat(d.map((item) => item.innerid));
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));

    this.updateSafetyDangerTypePieChart();
    this.updateSafetyDangerCheckRateChart();
  },
  methods: {
    categorySelect(option) {
      this.selectedDangerCategoryName = option;
      this.updateSafetyDangerCheckRateChart();
    },
    updateSafetyDangerTypePieChart() {
      project
        .getSafetyMajorDangerousTypeCount(this.projectId)
        .then((d) => {
          if (this.$refs.dangerType) {
            //取消默认高亮
            this.$refs.dangerType.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: this.dangerTypePieChartHighlightIndex,
            });
          }

          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));

          this.option2.series[0].data = d.series_date;

          this.option2.series[1] = Object.assign({}, this.option2.series[0], {
            label: {
              rich: {
                text: {
                  fontSize: 11,
                },
              },
              formatter: function (params) {
                return `${params.name} ${Math.round(params.percent)}%`;
              },
            },
            silent: true,
          });

          setTimeout(
            () =>
              this.$refs.dangerType &&
              this.$refs.dangerType.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: this.dangerTypePieChartHighlightIndex,
              }),
            100
          );
          this.dangerTypePieChart = applyUpdate(chart, this.option2);
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    updateSafetyDangerCheckRateChart() {
      let categoryId = "";
      const idx = this.dangerCategoryNameList.indexOf(
        this.selectedDangerCategoryName
      );
      if (idx >= 0) {
        categoryId = this.dangerCategoryIdList[idx];
      }
      project
        .getProjMdMonth12CheckCollect(this.projectId, categoryId)
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option3.xAxis[0].data = d.xAxis_data;
          this.option3.series[0].data = d.series_data;
          if (d.config.theta) {
            this.option3.series[0].markLine.data[0].yAxis = d.config.theta;
          }
          this.dangerCheckRateChart = applyUpdate(chart, this.option3);
        });
    },
    companySelect(option) {
      this.companySelectedValue = option;
      this.updateSafetyDangerTypePieChart();
    },
    dangerTypeMouseOver(params) {
      if (params.dataIndex != this.dangerTypePieChartHighlightIndex) {
        this.$refs.dangerType.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.dangerTypePieChartHighlightIndex,
        });
        this.dangerTypePieChartHighlightIndex = params.dataIndex;
      }
    },
    dangerTypeMouseOut() {
      this.$refs.dangerType.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.dangerTypePieChartHighlightIndex,
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>