export const SimpleRadarChartTemplate = {

    color: [
        '#f1d624',
    ],
    tooltip: {
        trigger: "item"
    },
    legend: {
        data: [],
    },
    radar: {
        // shape: 'circle',
        name: {
            color: "#ffffff",
            fontSize: 12,
            formatter: function (text) {
                var strlength = text.length;
                if (strlength % 6 != 0) {
                    text = text.replace(/\S{4}/g, function (match) {
                        console.log(match);
                        return match + '\n'
                    })
                } else {
                    text = text.replace(/\S{4}/g, function (match) {
                        console.log(match);
                        return match + '\n'
                    });
                    strlength = text.length;
                    text = text.substring(0, strlength - 1);
                }
                return text
            }
        },

        axisLine: {
            lineStyle: {
                color: "#5fcbef"
            }
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: "#5fcbef"
            }
        },
        splitArea: {
            show: false,
        },
        indicator: [],
    },
    series: [{
        name: "Budget vs spending",
        type: "radar",
        data: [{
            value: [],
            // name: 'Allocated Budget',
            areaStyle: {
                color: "rgba(2, 99, 255, 0.6)",
            },
        }, ],
    }, ],
}