function ensure2(num) {
    return num.length >= 2 ? num : '0' + num;
}

export function hex2rgb(hex) {
    return [parseInt(hex.slice(1, 3), 16), parseInt(hex.slice(3, 5), 16), parseInt(hex.slice(5, 7), 16)];
}

export function rgb2hex(rgb) {
    return `#${ensure2(rgb[0].toString(16))}${ensure2(rgb[1].toString(16))}${ensure2(rgb[2].toString(16))}`;
}

export function pickHex(color1, color2, weight) {
    var w1 = 1 - weight;
    var w2 = weight;
    color1 = hex2rgb(color1);
    color2 = hex2rgb(color2);

    var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2)];

    return rgb2hex(rgb);
}

/**
 * 根据朝向(pt0->pt1)计算头像图片旋转角度
 * @param {*} pt0
 * @param {*} pt1
 */
export function calcRotationAngle(pt0, pt1) {
    let angle = Math.atan((pt1[1] - pt0[1]) / (pt1[0] - pt0[0])) / Math.PI * 180;
    if (angle < 0) {
        angle += 180;
    }
    if (pt1[1] - pt0[1] < 0) {
        angle += 180;
    }
    return angle - 90;
}
