<template>
  <div id="map">
    <div v-show="heatmapReady" id="heatmap_timeline" class="jtimeline" style="margin:0 30px">
        <ul class="jtimeline-events" id="heatmap_timeline_events">
            <li v-for="entry in timelineEntries" :key="entry" :class="`jtimeline-event${entry === selectedEntry ? ' is-active' : ''}`" :data-timestamp="new Date(entry) / 1000" @click="selectTimelineDate(entry)">
              {{entry}}
            </li>
        </ul>
    </div>
    <Heatmap :entry="selectedEntry" :heatmapReady="heatmapReady" :project-id="projectId" />
  </div>
</template>

<script>
import Heatmap from './heatmap';
import $ from 'jquery';
require('../../../public/jtimeline.js');
require('../../../public/jtimeline.css');

function prepend0(x) {
  return x >= 10 ? x : '0' + x;
}

function formatTime(dt) {
  return `${dt.getFullYear()}-${prepend0(dt.getMonth() + 1)}-${prepend0(dt.getDate())}`;
}

export default {
  name: 'HeatmapWrapper',
  components: {
    Heatmap,
  },
  props: {
    projectId: String,
    heatmapReady: Boolean,
  },
  data() {
    return {
      timelineEntries: [],
      selectedEntry: '',
    }
  },
  mounted() {
    const start_date = new Date();
    const end_date = new Date();
    start_date.setDate(start_date.getDate() - 30);
    this.loadTimelineEntries(start_date, end_date);
  },
  updated() {
    $('#heatmap_timeline').jTimeline({
      minimumSpacing: 80,
      step: 400,
    });
  },
  methods: {
    selectTimelineDate(entry) {
      if (this.selectedEntry != entry) {
        this.selectedEntry = entry;
      }
    },
    loadTimelineEntries(start_date, end_date) {
      this.timelineEntries = [];
      while (start_date <= end_date) {
        const date_str = formatTime(start_date);
        this.timelineEntries.push(date_str);
        start_date.setDate(start_date.getDate() + 1);
      }
      if (this.timelineEntries.length) {
        this.selectedEntry = this.timelineEntries[this.timelineEntries.length - 1];
      }
    },
  },
}
</script>
