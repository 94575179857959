<template>
  <div id="right">
    <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
      <div class="info-block-title" style="display: flex;align-items:center;width: 85%;justify-content: space-between;">
        <div>安全巡检率</div>
        <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode == 1 }"
            @click="() => getChart(1)"
          >分公司</div>
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode == 2 }"
            @click="() => getChart(2)"
          >项目</div>
        </div>
      </div>
      <div style="height:18rem;padding-top:3rem">
        <v-chart :options="companyTroublePieChart" @click="companyTroublePieChartClick" autoresize />
      </div>
    </div>
    <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
      <div class="info-block-title" style="display:flex;align-items:center;justify-content: space-between;width: 85%;">
        <div>带班检查情况</div>
        <div class="detail" @click="showDetail">
          详情
        </div>
      </div>
      <div style="height:18rem;padding-top:3rem">
        <div style="display:flex;align-items:center;justify-content:center;">
          <div
            v-if="orgType < 2"
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode2 == 1 }"
            @click="() => getChart2(1)"
          >总部</div>
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode2 == 2 }"
            @click="() => getChart2(2)"
          >分公司</div>
           <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode2 == 3 }"
            @click="() => getChart2(3)"
          >项目</div>
        </div>
        <div
          v-if="todayNewTroubleBarChart.series && [].concat.apply([], todayNewTroubleBarChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="todayNewTroubleBarChart" autoresize />
      </div>
    </div>
    <div class="info-block" style="min-height:5rem">
      <div class="info-block-title" style="display: flex;justify-content: space-between;align-items:center;width: 90%;margin-top: -5px;">
        <div>一级隐患点TOP10</div>
        <div style="display:flex;justify-content:flex-end;">
          <CustomSelect :options="companyNameList" :value="selectedVal1" @input="companySelect($event, 'val1')" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;" />
        </div>
      </div>
       <div style="padding-top:3rem">
        <div style="max-height:20rem;font-size:0.9rem;color:#abbbbf;font-weight:bold;overflow:scroll;">
          <div
          v-if="!noActionTroubleBarChartList.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 25rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc;font-size:1rem;font-weight:500;">暂无数据</div>
        </div>
          <div
            v-else
            v-for="project in noActionTroubleBarChartList"
            :key="project.name"
            style="height:1.6rem;display:flex;justify-content:space-between;align-items:center"
          >
            <div>{{project.name}}</div>
            <div style="margin-left:0.5rem;color:#32B7C0;">{{project.count}}</div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="90rem"
      top="12rem"
      @closed="cleanupPopup"
    >
      <wrapper style="height:42rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="cleanupPopup"
        />
        <div class="attendance">
          <div class="attendance-top">
            <div class="tabbar">
              <div style="border: 1px solid #157EAB" :class="{ 'tabar-select': mode2 == 3?popupSelect == '2':popupSelect == '1' }" @click="getAttendance(mode2 == 3 ? '2':'1')">按{{mode2 == 3 ? '月' : '季'}}</div>
              <div style="border: 1px solid #157EAB" :class="{ 'tabar-select': mode2 == 3?popupSelect == '3':popupSelect == '2' }" @click="getAttendance(mode2 == 3?'3':'2')">按{{mode2 == 3 ? '周' : '月'}}</div>
              <div style="border: 1px solid #157EAB" :class="{ 'tabar-select': popupSelect == '4' }" @click="getAttendance('4')" v-if="mode2 == 3">按日</div>
              <div style="border: 1px solid #157EAB" :class="{ 'tabar-select': popupSelect == '3' }" @click="getAttendance('3')" v-else>按周</div>
            </div>
            <div style="position:absolute;top:1rem;right:1rem;display:flex;justify-content:flex-end;" v-if="mode2 !== 1">
              <CustomSelect :options="companyNameList" :value="selectedVal2" @input="companySelect($event, 'val2')" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;" />
            </div>
          </div>
          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="font-size:1rem;font-weight:600;margin:1rem 0 0 2rem">{{mode2 == 3 ? '项目日常' : (mode2 == 1 ? '总部' : '分公司') + '领导'}}检查情况详情</div>
            <div style="height:15rem;padding-top:1rem">
              <v-chart :options="attenceWorkersBarChart" @click="attenceWorkersBarChartClick" autoresize />
            </div>
          </div>
          <div style="margin:0.5rem 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName}}</div>
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:12rem;text-align:center">{{mode2 == 3 ? head_name2 : (mode2 == 1 ? '领导名称' : head_name1)}}</div>
              <!-- <div style="width:12rem;text-align:center" v-else-if="mode2 === 2">{{head_name1}}</div>
              <div style="width:12rem;text-align:center" v-else-if="mode2 === 3">{{head_name2}}</div> -->
              <div style="width:12rem;text-align:center">{{(mode2 == 1 ? '平均次数' : '检查次数')}}</div>
              <div style="width:12rem;text-align:center">整改率</div>
            </div>
            <div style="height:10rem;overflow:auto;">
              <div
                v-for="(item, index) in popupData"
                :key="item.person_id"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;cursor: pointer;"
                :style="{ background: index%2 ? '#102855' : '#213a77'}" @click="getDetail(item)"
              >
                <div style="width:12rem;text-align:center">{{item.name}}</div>
                <div style="width:12rem;text-align:center">
                  {{item.count}}
                </div>
                <div style="width:12rem;text-align:center">{{item.rate}}</div>
              </div>
              <div
                v-if="!(popupData && popupData.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="securityStarPage"
              :page-count="securityStarPages"
              @current-change="securityStarPageChange"
              style="text-align:center;margin-top: 0.5rem;"
            ></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="companyTroublePieChartDetailShow"
      width="60rem"
      top="18rem"
    >
      <wrapper style="height:26rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="companyTroublePieChartDetailShow = false"
        />
        <div class="attendance">
          
          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="font-size:1rem;font-weight:600;margin:1rem 0 0 1rem">{{companyTroublePieDetailTitle}}</div>
            <div style="height:19rem;padding-top:1rem">
              <v-chart :options="companyTroublePieDetailChart" autoresize />
            </div>
          </div>
          
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="50rem"
      top="12rem"
      @closed="cleanupPopup2"
    >
      <wrapper style="height:42rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow2 = false"
        />
        <div class="attendance">
          <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName2}}</div>
          <div style="margin-top:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;" v-if="mode2 === 1 || mode2 === 2">
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:10rem;text-align:center">分公司</div>
              <div style="width:10rem;text-align:center">项目名称</div>
              <div style="width:5rem;text-align:center">项目经理</div>
              <div style="width:10rem;text-align:center">结果</div>
              <div style="width:5rem;text-align:center">整改率</div>
            </div>
            <div style="height:12rem;overflow:auto;">
              <div
                v-for="(item, index) in popupData22"
                :key="item.project_id"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:10rem;text-align:center">{{item.org_name}}</div>
                <div style="width:10rem;text-align:center">
                  {{item.project_name}}
                </div>
                <div style="width:5rem;text-align:center">{{item.head_person}}</div>
                <div style="width:10rem;text-align:center;color: #BF3143" v-if="item.level === 1">{{item.result}}</div>
                <div style="width:10rem;text-align:center;color: #fff" v-else-if="item.level === 2">{{item.result}}</div>
                <div style="width:10rem;text-align:center;color: #4191e5" v-else-if="item.level === 3">{{item.result}}</div>
                <div style="width:10rem;text-align:center;color: #33c09c" v-else>{{item.result}}</div>
                <div style="width:5rem;text-align:center">{{item.rate}}</div>
              </div>
              <div
                v-if="!(popupData22 && popupData22.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="securityStarPage2"
              :page-count="securityStarPages2"
              @current-change="securityStarPageChange2"
              style="text-align:center;margin-top: 0.5rem;"
            ></el-pagination>
          </div>
          <div style="margin-top:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;" v-else>
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:10rem;text-align:center">检查人</div>
              <div style="width:10rem;text-align:center">岗位</div>
              <div style="width:5rem;text-align:center">隐患数</div>
              <div style="width:5rem;text-align:center">整改率</div>
            </div>
            <div style="height:12rem;overflow:auto;">
              <div
                v-for="(item, index) in popupData3"
                :key="index"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:10rem;text-align:center">
                  {{item.person_name}}
                </div>
                <div style="width:10rem;text-align:center">{{item.position_name}}</div>
                <div style="width:5rem;text-align:center;">{{item.count}}</div>
                <div style="width:5rem;text-align:center">{{item.rate}}</div>
              </div>
              <div
                v-if="!(popupData3 && popupData3.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="securityStarPage3"
              :page-count="securityStarPages3"
              @current-change="securityStarPageChange3"
              style="text-align:center;margin-top: 0.5rem;"
            ></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow3"
      width="50rem"
      top="12rem"
      @closed="popupShow3 = false"
    >
      <wrapper style="height:42rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow3 = false"
        />
        <div class="attendance">
          <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName3}}</div>
          <div style="margin-top:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:10rem;text-align:center">领导名称</div>
              <div style="width:10rem;text-align:center">检查次数</div>
              <div style="width:10rem;text-align:center">整改率</div>
            </div>
            <div style="height:12rem;overflow:auto;">
              <div
                v-for="(item, index) in popupData4"
                :key="item.person_id"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;cursor: pointer;"
                :style="{ background: index%2 ? '#102855' : '#213a77'}" :class="{'select': index  === active}" @click="change(item, index)"
              >
                <div style="width:10rem;text-align:center">{{item.name}}</div>
                <div style="width:10rem;text-align:center">{{item.count}}</div>
                <div style="width:10rem;text-align:center">{{item.rate}}</div>
              </div>
              <div
                v-if="!(popupData4 && popupData4.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>
          </div>
          <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName4}}</div>
          <div style="margin-top:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:10rem;text-align:center">分公司</div>
              <div style="width:10rem;text-align:center">项目名称</div>
              <div style="width:10rem;text-align:center">项目经理</div>
              <div style="width:10rem;text-align:center">结果</div>
              <div style="width:5rem;text-align:center">整改率</div>
            </div>
            <div style="height:12rem;overflow:auto;">
              <div
                v-for="(item, index) in popupData5"
                :key="item.project_id"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:10rem;text-align:center">
                  {{item.org_name}}
                </div>
                <div style="width:10rem;text-align:center">{{item.project_name}}</div>
                <div style="width:10rem;text-align:center;">{{item.head_person}}</div>
                <div style="width:10rem;text-align:center;color: #BF3143" v-if="item.level === 1">{{item.result}}</div>
                <div style="width:10rem;text-align:center;color: #fff" v-else-if="item.level === 2">{{item.result}}</div>
                <div style="width:10rem;text-align:center;color: #4191e5" v-else-if="item.level === 3">{{item.result}}</div>
                <div style="width:10rem;text-align:center;color: #33c09c" v-else>{{item.result}}</div>
                <div style="width:5rem;text-align:center">{{item.rate}}</div>
              </div>
              <div
                v-if="!(popupData5 && popupData5.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="securityStarPage5"
              :page-count="securityStarPages5"
              @current-change="securityStarPageChange5"
              style="text-align:center;margin-top: 0.5rem;"
            ></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow4"
      width="50rem"
      top="12rem"
      @closed="popupShow4 = false"
    >
      <wrapper style="height:42rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow4 = false"
        />
        <div class="attendance">
          <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName5}}</div>
          <div style="margin-top:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:10rem;text-align:center">项目名称</div>
              <div style="width:10rem;text-align:center">检查次数</div>
              <div style="width:10rem;text-align:center">整改率</div>
            </div>
            <div style="height:12rem;overflow:auto;">
              <div
                v-for="(item, index) in popupData4"
                :key="item.project_id"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;cursor: pointer;"
                :style="{ background: index%2 ? '#102855' : '#213a77'}" :class="{'select': index  === active}" @click="change2(item, index)"
              >
                <div style="width:10rem;text-align:center">{{item.name}}</div>
                <div style="width:10rem;text-align:center">{{item.count}}</div>
                <div style="width:10rem;text-align:center">{{item.rate}}</div>
              </div>
              <div
                v-if="!(popupData4 && popupData4.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>
          </div>
          <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName6}}</div>
          <div style="margin-top:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:10rem;text-align:center">检查人</div>
              <div style="width:10rem;text-align:center">岗位</div>
              <div style="width:10rem;text-align:center">隐患数</div>
              <div style="width:10rem;text-align:center">整改率</div>
            </div>
            <div style="height:12rem;overflow:auto;">
              <div
                v-for="(item, index) in popupData6"
                :key="item.person_name"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:10rem;text-align:center">
                  {{item.person_name}}
                </div>
                <div style="width:10rem;text-align:center">{{item.position_name}}</div>
                <div style="width:10rem;text-align:center;">{{item.count}}</div>
                <div style="width:10rem;text-align:center">{{item.rate}}</div>
              </div>
              <div
                v-if="!(popupData6 && popupData6.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="securityStarPage6"
              :page-count="securityStarPages6"
              @current-change="securityStarPageChange5"
              style="text-align:center;margin-top: 0.5rem;"
            ></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow5"
      width="90rem"
      top="12rem"
    >
      <wrapper style="height:42rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow5=false"
        />
        <div class="attendance">
          
          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="font-size:1rem;font-weight:600;margin:1rem 0 0 2rem">{{companyTroublePieDetailTitle}}</div>
            <div style="height:15rem;padding-top:1rem">
              <v-chart :options="workerAgePieDetailChart" @click="workerAgePieDetailChartClick" autoresize />
            </div>
          </div>
          <div style="margin:0.5rem 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupShow5BottomName}}</div>
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:3rem;text-align:center">序号</div>
              <div style="width:20rem;">项目名称</div>
              <div style="width:5rem;text-align:center">巡检率</div>
            </div>
            <div style="height:14rem;overflow:auto;">
              <div
                v-for="(item, index) in popupShowData5"
                :key="item.person_id"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;cursor: pointer;"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:3rem;text-align:center">{{index>8?index+1:'0'+(index+1)}}</div>
                <div style="width:20rem;"> {{item.project_name}} </div>
                <div style="width:5rem;text-align:center"> {{item.rate}} </div>
              </div>
              <div
                v-if="!(popupShowData5 && popupShowData5.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>
            
          </div>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow6"
      width="90rem"
      top="18rem"
    >
      <wrapper style="height:22rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow6=false"
        />
        <div class="attendance">
          
          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="font-size:1rem;font-weight:600;margin:1rem 0 0 2rem">{{companyTroublePieDetailTitle}}</div>
            <div style="height:15rem;padding-top:1rem">
              <v-chart :options="workerAgePieDetailChart" @click="workerAgePieDetailChartClick" autoresize />
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { nation } from '../../api'
  // import { attendancePieChartTemplate, colors } from './pieChartTemplates';
  import { SimpleBarChartTemplate } from './barChartTemplates';
  import { applyUpdate, truncateStr } from "../../utils";
  import CustomSelect from '../../components/select';
  import wrapper from '../../components/wrap';

  export default {
    name: 'SecurityCheckRightBar',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
       wrapper
    },
    props: {
      districtLevel: String,
      orgLevel: String,
      orgOptions: Array,
      orgType: Number,
      districtScale: Number,
    },
    data () {
      return {
        popupShow6:false,
        companyTroublePieChartDetailShow:false,
        companyTroublePieDetailTitle:'',
        companyTroublePieDetailChart:{},
        companyTroublePieSearchkeys: [],
        activeSearchIndex:0,
        
        option4: {
          tooltip: {
            trigger: 'axis',
            formatter: "{b}<br>{a0}: {c0}"
          },
          color: ['#4191E5', '#1bd080'],
          grid: {
            contentLabel: true,
            left: '5%',
            right: '6%',
            bottom:"18%",
            top: "13%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35,
                
                formatter: function(value) {
                  return value.length >5 ? value.slice(0, 5) + '...' : value;
                },
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              max: 100,
              nameTextStyle: {
                // padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true,
              name: '巡检率：%'
            }
          ],
          series: [
            {
              name: '巡检率',
              type: 'bar',
              barWidth: 10,
              data: [],
              markLine: {
                symbol: ["none", "none"], //去掉箭头
                itemStyle: {
                  normal: {
                    lineStyle: {
                      type: "dashed",
                      color: "#238778",
                    },
                    label: {
                      show: false,
                      position: "middle",
                    },
                  },
                },
                data: [
                  {
                    yAxis: -1, //这里设置false是隐藏不了的，可以设置为-1
                  },
                ],
              }
            }
          ]
        },
        companyTroublePieChart: {},
        mode: 1,
        option: {
          tooltip: {
            trigger: 'axis',
            formatter: "{b}<br>{a0}: {c0}"
          },
          color: ['#4191E5', '#1bd080'],
          grid: {
            contentLabel: true,
            left: '12%',
            right: '6%',
            bottom:"11%",
            top: "13%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              max: 100,
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true,
              name: '巡检率:%'
            }
          ],
          series: [
            {
              name: '巡检率',
              type: 'bar',
              barWidth: 10,
              data: [],
              markLine: {
                symbol: ["none", "none"], //去掉箭头
                itemStyle: {
                  normal: {
                    lineStyle: {
                      type: "dashed",
                      color: "#238778",
                    },
                    label: {
                      show: false,
                      position: "middle",
                    },
                  },
                },
                data: [
                  {
                    yAxis: -1, //这里设置false是隐藏不了的，可以设置为-1
                  },
                ],
              }
            }
          ]
        },
        companyTroublePieChartHighlightIndex: 0,
        todayNewTroubleBarChart: {},
        mode2: 1,
        option2: {
          color: ["#23cf9e", "#fdc81e"],
          grid: {
            contentLabel: true,
            left: '12%',
            bottom: "30%",
            top: "15%",
            right: "10%",
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          tooltip: {
            trigger: 'axis',
            // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
            formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                  result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + (params[0].data.value?params[0].data.value:params[0].data);
                  if(params[1]){
                      result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                  }
                  
              }
              return result;
            }
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 45,
                formatter: function(value) {
                  return value.length >5 ? value.slice(0, 5) + '...' : value;
                },
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true,
              name: "平均次数"
            },
            {
              name: "整改率：%",
              show: true,
              min: 0,
              max: 100,
              interval: 25,
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // },
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              barWidth: 10,
              data: []
            },
            {
              name: '数量',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                  {offset: 1, color: 'rgba(27, 208, 128, 0)'}
                ])
              },
              symbolSize: 0,
              yAxisIndex: 1,
              data: [],
            }
          ]
        },
        companyNameList: [''],
        companyIdList: [''],
        selectedVal1: '',
        selectedVal2: '',
        noActionTroubleBarChartList: [],
        popupShow: false,
        popupSelect: '1',
        attenceWorkersBarChart: {},
        option3: {
          color: ["#23CF9E", "#14B0BB"],
          grid: {
            contentLabel: true,
            right: '5%',
            top: "13%",
            left: "5%",
            bottom: "15%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          tooltip: {
            trigger: 'axis',
            // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
            formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                  result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + (params[0].data.value?params[0].data.value:params[0].data);
                  if(params[1]){
                      result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                  }
                  
              }
              return result;
            }
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              name: "平均次数",
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true
            },
            {
              show: true,
              name: "整改率：%",
              min: 0,
              max: 100,
              interval: 25,
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // },
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              barWidth: 10,
              data: []
            },
            {
              name: '数量',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                  {offset: 1, color: 'rgba(27, 208, 128, 0)'}
                ])
              },
              symbolSize: 0,
              yAxisIndex: 1,
              data: [],
            }
          ]
        },
        org_id: '',
        popupBottomName: '',
        popupSearchkeys: [],
        popupXAxisData: [],
        popupBarData: [],
        popupData: [],
        securityStarPage: 1,
        securityStarPages: 1,
        dataIndex: -1,
        popupShow2: false,
        popupBottomName2: '',
        popupData2: [],
        popupData22: [],
        securityStarPage2: 1,
        securityStarPages2: 1,
        popupData3: [],
        securityStarPage3: 1,
        securityStarPages3: 1,
        person_id: '',
        isFirst: true,
        head_name1: '分公司',
        head_name2: '分公司',
        popupShow3: false,
        popupBottomName3: '',
        popupData4: [],
        active: 0,
        popupBottomName4: '',
        popupData5: [],
        securityStarPage5: 1,
        securityStarPages5: 1,
        popupShow4: false,
        popupData6: [],
        securityStarPage6: 1,
        securityStarPages6: 1,
        project_id_table: '',
        popupBottomName5: '',
        popupBottomName6: '',

      popupShow5: false,
      workerAgePieDetailChart:{},
      workerAgePieDetailChartTemplate:{
          color: ["#23CF9E", "#14B0BB"],
          grid: {
            contentLabel: true,
            right: '5%',
            top: "13%",
            left: "5%",
            bottom: "25%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          tooltip: {
            trigger: 'axis',
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                rotate: 35,
                
                formatter: function(value) {
                  return value.length >5 ? value.slice(0, 5) + '...' : value;
                },
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              name: "巡检率：%",
              max:100,
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true
            },
            
          ],
          series: [
            {
              name: '次数',
              type: 'bar',
              barWidth: 10,
              data: []
            },
          ]
        },
      popupShow5BottomName: '',
      popupSearchkeys5: [],
      popupXAxisData5: [],
      popupBarData5: [],
      popupShowData5: [],
      dataIndex5: -1,
        
      }
    },
    watch: {
      orgOptions(newValue, oldValue) {
        if (newValue != oldValue) {
          this.companyNameList = newValue.map(item => truncateStr(item.org_name, 10));
          this.companyIdList = newValue.map(item => item.org_id);

          let idx = this.companyIdList.indexOf(this.orgLevel);
          if (idx == -1) {
            idx = 0;
          }
          this.companySelect(this.companyNameList[idx], 'val1')
        }
      },
      orgLevel: function (newValue, oldValue) {
        if (newValue != oldValue) {
          const idx = this.companyIdList.indexOf(newValue);
          if (idx >= 0) {
            this.companySelect(this.companyNameList[idx], 'val1');
          }
          this.org_id=this.orgLevel;

          this.getAll();
        }
      },
      districtLevel: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      },
      orgType: function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (newValue == 2) {
            this.mode2 = 2;
            this.head_name1 = '领导名称'
          } else {
            this.mode2 = 1;
            this.head_name1 = '分公司'
          }
        }
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      if (this.orgType == 2) {
        this.mode2 = 2;
      }

      this.companyNameList = this.orgOptions.map(item => truncateStr(item.org_name, 10));
      this.companyIdList = this.orgOptions.map(item => item.org_id);

      let idx = this.companyIdList.indexOf(this.orgLevel);
      if (idx == -1) {
        idx = 0;
      }
      this.selectedVal1 = this.companyNameList[idx];
      this.org_id=this.orgLevel;

      this.getAll();
    },
    methods: {
      getAll(){
        this.getChart(this.mode);
        this.getChart2(this.mode2);

        this.getList();
      },
      companyTroublePieChartClick(params){
        this.activeSearchIndex=params.dataIndex;
        if(this.mode==1){
        this.companyTroublePieChartDetailShow=true;
        this.companyTroublePieDetailTitle=params.name+' - 分公司安全巡检率'
        
        nation.getSafetyInspectOrgCityCollect(this.mode,this.companyTroublePieSearchkeys[params.dataIndex])
          .then(d => {
            // console.log(d);
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option4.xAxis[0].data = d.xAxis_data;
            this.option4.series[0].data = d.bar_series;
            if (d.config.theta) {
              this.option4.series[0].markLine.data[0].yAxis = d.config.theta;
            }
            this.companyTroublePieDetailChart = applyUpdate(chart, this.option4);
          })

        }else{
          // console.log(this.districtScale,'---------------')
          if(this.districtScale==2){
            this.popupShow6 = true;
          }else{
            this.popupShow5 = true;
          }
          this.getAttendance5();
          this.companyTroublePieDetailTitle=params.name+' - 项目安全巡检率'

        }
      },
      
      getAttendance5(){
        
        nation.getSafetyInspectOrgCityCollect(this.mode,this.companyTroublePieSearchkeys[this.activeSearchIndex])
          .then(d => {
            
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.workerAgePieDetailChartTemplate.xAxis[0].data = d.xAxis_data;
            if(this.districtScale!==2){
            d.bar_series[d.bar_series.length-1] = {
              value: d.bar_series[d.bar_series.length-1],
              itemStyle: {
                color: '#FFD153'
              }
            };

            }
            this.workerAgePieDetailChartTemplate.series[0].data = d.bar_series;
            this.workerAgePieDetailChart = applyUpdate(chart, this.workerAgePieDetailChartTemplate);

            this.popupShow5BottomName = `${this.workerAgePieDetailChartTemplate.xAxis[0].data[this.workerAgePieDetailChartTemplate.xAxis[0].data.length-1]}项目安全巡检率`;
            this.getworkerAgePieDetailTableCollect(d.search_keys[d.search_keys.length-1]);
            this.popupSearchkeys5 = d.search_keys;
            this.popupXAxisData5 = d.xAxis_data;
            this.popupBarData5 = d.bar_series;
          })
      },
      workerAgePieDetailChartClick(params){
        // console.log(params);
        // console.log(params.dataIndex);
        let subData = this.popupBarData5;
        this.workerAgePieDetailChart.series[0].data = subData.map(function (item, index) {
          return index === params.dataIndex ? {
              value: Object.prototype.toString.call(item) === '[object Object]' ? item.value:item,
              itemStyle: {
                color: '#FFD153'
              }
            }:(Object.prototype.toString.call(item) === '[object Object]' ? item.value:item)
        });
        this.popupShow5BottomName = `${this.popupXAxisData5[params.dataIndex]}项目安全巡检率`;
        this.getworkerAgePieDetailTableCollect(this.popupSearchkeys5[params.dataIndex]);
        this.dataIndex5 = params.dataIndex;
      },
      getworkerAgePieDetailTableCollect(search_keys){
        nation.getSafetyInspectProjectCollect(this.companyTroublePieSearchkeys[this.activeSearchIndex], 1, 100,this.districtScale == 0&&this.orgType == 1
              ? search_keys
              : this.orgLevel,
              
            this.districtScale == 1||this.orgType !== 1
              ? search_keys
              : (this.orgType == 2?search_keys:this.districtLevel))
          .then(d => {
            this.popupShowData5 = d;
          })
      },
      getChart(mode){
        this.mode = mode;
        nation.getSafetyMonth12InspectionCollect(mode)
          .then(d => {
            // console.log(d);
            this.companyTroublePieSearchkeys=d.search_keys;
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option.xAxis[0].data = d.xAxis_data;
            this.option.series[0].data = d.series;
            if (d.config.theta) {
              this.option.series[0].markLine.data[0].yAxis = d.config.theta;
            }
            this.companyTroublePieChart = applyUpdate(chart, this.option);
          })
      },
      getChart2(mode){
        this.mode2 = mode;
        nation.getSafetyMonth12HeadCheckCollect(mode)
          .then(d => {
            // console.log(d);
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option2.legend.data = d.legend;
            this.option2.xAxis[0].data = d.xAxis_data;
            this.option2.series[0].name = d.legend[0];
            this.option2.series[0].data = d.bar_series;
            this.option2.series[1].data = d.line_series;
            this.option2.series[1].name = d.legend[1];
            this.todayNewTroubleBarChart = applyUpdate(chart, this.option2);
          })
      },
      companyTroubleMouseOver(params) {
        if (params.dataIndex != this.companyTroublePieChartHighlightIndex) {
          this.$refs.companyTrouble.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.companyTroublePieChartHighlightIndex
          });
          this.companyTroublePieChartHighlightIndex = params.dataIndex;
        }
      },
      companyTroubleMouseOut() {
        this.$refs.companyTrouble.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.companyTroublePieChartHighlightIndex
        });
      },
      companySelect(option, val) {
        const idx = this.companyNameList.indexOf(option);
        this.org_id = '';
        if (idx >= 0) {
          this.org_id = this.companyIdList[idx];
          if(val === 'val1'){
            // eslint-disable-next-line
            this.selectedVal1 = option;
            this.getList();
          }else if(val === 'val2'){
            this.selectedVal2 = option;
            if(this.mode2 === 2){
              // eslint-disable-next-line
              if(this.isFirst && this.selectedVal2 === '全部分公司'){
                this.head_name1 = '分公司';
              }else{
                this.head_name1 = '领导名称';
                this.getAttendance(this.popupSelect);
              }
              // this.popupBottomName = `${this.popupXAxisData[this.dataIndex !== -1 ? this.dataIndex:(this.popupXAxisData.length-1)]}${this.companyNameList[idx]}领导检查详情`;
            }else if(this.mode2 === 3){
              // eslint-disable-next-line
              if(this.isFirst && this.selectedVal2 === '全部分公司'){
                this.head_name2 = '分公司';
              }else{
                this.head_name2 = '项目名称';
                this.getAttendance(this.popupSelect);
              } 
              // this.popupBottomName = `${this.popupXAxisData[this.dataIndex !== -1 ? this.dataIndex:(this.popupXAxisData.length-1)]}${this.companyNameList[idx]}日常检查详情`;
            }
            if(this.selectedVal2 === '全部分公司' && !this.isFirst){
              this.head_name1 = '分公司';
            }
          }
        }
      },
      getList(){
        nation.getSafeTroubleTroubleItem10Collect(10, this.org_id ? this.org_id:'')
            .then(d => {
              // console.log(d)
              this.noActionTroubleBarChartList = d;
            })
      },
      showDetail(){
        const idx = this.companyIdList.indexOf(this.orgLevel);
        this.selectedVal2 = this.companyNameList[idx];
        this.org_id = this.orgLevel;
        this.popupShow = true;
        if(this.mode2==3){
          this.popupSelect='2'
        }else{
          this.popupSelect='1'
        }
        if(this.selectedVal2 === '全部分公司' && !this.isFirst){
          this.head_name1 = '分公司';
        }else{
          this.head_name1='领导名称'
        }
        if(!this.isFirst && this.selectedVal2 === '全部分公司'){
          this.head_name2 = '分公司';
        }else{
          this.head_name2 = '项目名称';
        } 
        this.getAttendance(this.popupSelect);
      },
      getAttendance(popupSelect){
        this.popupSelect = popupSelect;
        nation.getSafetyTroubleHeadCheckDateCollect(this.mode2, this.popupSelect, this.org_id ? this.org_id:'')
          .then(d => {
            // console.log(d);
            this.isFirst = false;
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.option3.legend.data = d.legend;
            this.option3.xAxis[0].data = d.xAxis_data;
            d.bar_series[d.bar_series.length-1] = {
              value: d.bar_series[d.bar_series.length-1],
              itemStyle: {
                color: '#FFD153'
              }
            };
            this.option3.series[0].data = d.bar_series;
            this.option3.series[0].name = d.legend[0];
            this.option3.series[1].data = d.line_series;
            this.option3.series[1].name = d.legend[1];
            this.attenceWorkersBarChart = applyUpdate(chart, this.option3);
            if(this.mode2 === 1){
              // eslint-disable-next-line
              this.popupBottomName = `${this.option3.xAxis[0].data[this.option3.xAxis[0].data.length-1]}总部领导检查详情`;
            }else if(this.mode2 === 2){
              // eslint-disable-next-line
              this.popupBottomName = `${this.option3.xAxis[0].data[this.option3.xAxis[0].data.length-1]}分公司领导检查详情`;
            }else{
              // eslint-disable-next-line
              this.popupBottomName = `${this.option3.xAxis[0].data[this.option3.xAxis[0].data.length-1]}项目日常检查详情`;
            }
            this.getLaborAttendanceDateOrgCountCollect(d.search_keys[d.search_keys.length-1]);
            this.popupSearchkeys = d.search_keys;
            this.popupXAxisData = d.xAxis_data;
            this.popupBarData = d.bar_series;
          })
      },
      attenceWorkersBarChartClick(params){
        // console.log(params);
        // console.log(params.dataIndex);
        let subData = this.popupBarData;
        this.attenceWorkersBarChart.series[0].data = subData.map(function (item, index) {
          return index === params.dataIndex ? {
              value: Object.prototype.toString.call(item) === '[object Object]' ? item.value:item,
              itemStyle: {
                color: '#FFD153'
              }
            }:(Object.prototype.toString.call(item) === '[object Object]' ? item.value:item)
        });
        if(this.mode2 === 1){
          // eslint-disable-next-line
          this.popupBottomName = `${this.popupXAxisData[params.dataIndex]}总部领导检查详情`;
        }else if(this.mode2 === 2){
          // eslint-disable-next-line
          this.popupBottomName = `${this.popupXAxisData[params.dataIndex]}分公司领导检查详情`;
        }else{
          // eslint-disable-next-line
          this.popupBottomName = `${this.popupXAxisData[params.dataIndex]}项目日常检查详情`;
        }
        this.getLaborAttendanceDateOrgCountCollect(this.popupSearchkeys[params.dataIndex]);
        this.dataIndex = params.dataIndex;
      },
      getLaborAttendanceDateOrgCountCollect(search_keys){
        // window.$orgLevel = this.org_id;
        nation.getSafetyTroubleHeadCheckCountCollect(this.mode2, search_keys, this.org_id ? this.org_id:'', this.securityStarPage, 10, this.popupSelect)
          .then(d => {
            // console.log(d);
            this.popupData = d;
            this.securityStarPages = Math.ceil(d.__count__ / 10);
          })
      },
      cleanupPopup() {
        this.popupShow = false;
        this.attenceWorkersBarChart = {};
        this.popupData = [];
        this.dataIndex = -1;
        this.popupSearchkeys = [];
        this.popupXAxisData = [];
        this.popupBarData = [];
        this.head_name1 = '分公司';
        this.head_name2 = '分公司';
      },
      securityStarPageChange(currPage) {
        // console.log(currPage);
        this.securityStarPage = currPage;
        this.getLaborAttendanceDateOrgCountCollect(this.popupSearchkeys[this.dataIndex]);
      },
      getDetail(item){
        // console.log(item);
        this.person_id = item.person_id;
        // eslint-disable-next-line no-empty
        if(this.mode2 === 1){
          this.popupShow2 = true;
          this.popupBottomName2 = `${item.name}${this.popupXAxisData[this.dataIndex !== -1 ? this.dataIndex:(this.popupXAxisData.length-1)]}日常检查项目情况`
          this.getLists(this.orgLevel);
          // eslint-disable-next-line no-empty
        }else if(this.mode2 === 2){
          // eslint-disable-next-line no-empty
          if(this.selectedVal2 !== '全部分公司'){
            this.popupShow2 = true;
            this.popupBottomName2 = `${item.name}${this.popupXAxisData[this.dataIndex !== -1 ? this.dataIndex:(this.popupXAxisData.length-1)]}领导带班检查项目情况`
            this.getLists(this.org_id);
            this.securityStarPage2 = 1;
          // eslint-disable-next-line no-empty
          }else{
            this.popupShow3 = true;
            this.popupBottomName3 = `${item.name}${this.popupXAxisData[this.dataIndex !== -1 ? this.dataIndex:(this.popupXAxisData.length-1)]}分公司领导检查情况`
            this.getLists3(item.org_id)
            this.securityStarPage5 = 1;
          }
          // eslint-disable-next-line no-empty
        }else{
          // eslint-disable-next-line no-empty
          if(this.selectedVal2 !== '全部分公司'){
            this.popupShow2 = true;
            this.popupBottomName2 = `${item.name}${this.popupXAxisData[this.dataIndex !== -1 ? this.dataIndex:(this.popupXAxisData.length-1)]}日常检查项目情况`
            this.getLists2(this.org_id);
             this.securityStarPage3 = 1;
          }else{
            // eslint-disable-next-line no-empty
            this.popupShow4 = true;
            this.popupBottomName5 = `${item.name}${this.popupXAxisData[this.dataIndex !== -1 ? this.dataIndex:(this.popupXAxisData.length-1)]}项目日常检查详情`
            this.getLists4(item.org_id)
            this.securityStarPage6 = 1;
          }
        }
      },
      getLists(org_id){
        nation.getSafetyTroubleHeadCheckDetailCollect(this.mode2, this.popupSearchkeys[this.dataIndex !== -1 ? this.dataIndex:(this.popupSearchkeys.length-1)] ,this.securityStarPage2, 10, this.person_id ? this.person_id:'', org_id, this.popupSelect)
          .then(d => {
            // console.log(d);
            this.popupData22 = d;
            this.securityStarPages2 = Math.ceil(d.__count__ / 10);
          })
      },
      getLists3(org_id){
        // console.log(org_id);
        nation.getSafetyTroubleHeadCheckCountCollect(this.mode2, this.popupSearchkeys[this.dataIndex !== -1 ? this.dataIndex:(this.popupSearchkeys.length-1)], org_id, this.securityStarPage, 100, this.popupSelect)
          .then(d => {
            this.popupData4 = d;
            // this.securityStarPages2 = Math.ceil(d.__count__ / 10);
            this.change(d[0], 0);
            this.popupBottomName4 = `${d[0].name}${this.popupXAxisData[this.dataIndex !== -1 ? this.dataIndex:(this.popupXAxisData.length-1)]}分公司领导检查情况`;
          })
      },
      getLists4(org_id){
        // console.log(org_id);
        nation.getSafetyTroubleHeadCheckCountCollect(this.mode2, this.popupSearchkeys[this.dataIndex !== -1 ? this.dataIndex:(this.popupSearchkeys.length-1)], org_id, this.securityStarPage, 100, this.popupSelect)
          .then(d => {
            // console.log(d);
            this.popupData4 = d;
            // this.securityStarPages2 = Math.ceil(d.__count__ / 10);
            this.change2(d[0], 0);
            this.popupBottomName6 = `${d[0].name}${this.popupXAxisData[this.dataIndex !== -1 ? this.dataIndex:(this.popupXAxisData.length-1)]}项目日常检查详情`;
          })
      },
      securityStarPageChange2(currPage) {
        // console.log(currPage);
        this.securityStarPage2 = currPage;
        this.getLists();
      },
      getLists2(org_id){
        nation.getSafetyTroubleHeadCheckProjectDetailCollect(this.popupSelect, this.popupSearchkeys[this.dataIndex !== -1 ? this.dataIndex:(this.popupSearchkeys.length-1)] ,this.securityStarPage3, 10, this.person_id ? this.person_id:'', org_id)
          .then(d => {
            // console.log(d);
            this.popupData3 = d;
            this.securityStarPages3 = Math.ceil(d.__count__ / 10);
          })
      },
      securityStarPageChange3(currPage) {
        // console.log(currPage);
        this.securityStarPage3 = currPage;
        this.getLists2();
      },
      cleanupPopup2() {
        this.popupData2 = [];
        this.popupData3 = [];
      },
      change(item, index){
        this.active = index;
        this.person_id = item.person_id;
        this.popupBottomName4 = `${item.name}${this.popupXAxisData[this.dataIndex !== -1 ? this.dataIndex:(this.popupXAxisData.length-1)]}分公司领导检查情况`;
        nation.getSafetyTroubleHeadCheckDetailCollect(this.mode2, this.popupSearchkeys[this.dataIndex !== -1 ? this.dataIndex:(this.popupSearchkeys.length-1)] ,this.securityStarPage5, 10, item.person_id ? item.person_id:'', '', this.popupSelect)
          .then(d => {
            // console.log(d);
            this.popupData5 = d;
            this.securityStarPages5 = Math.ceil(d.__count__ / 10);
          })
      },
      securityStarPageChange5(currPage){
        this.securityStarPage5 = currPage;
        nation.getSafetyTroubleHeadCheckDetailCollect(this.mode2, this.popupSearchkeys[this.dataIndex !== -1 ? this.dataIndex:(this.popupSearchkeys.length-1)] ,this.securityStarPage5, 10, this.person_id ? this.person_id:'', '', this.popupSelect)
          .then(d => {
            // console.log(d);
            this.popupData5 = d;
            this.securityStarPages5 = Math.ceil(d.__count__ / 10);
          })
      },
      change2(item, index){
        this.active = index;
        this.project_id_table = item.project_id;
        this.popupBottomName6 = `${item.name}${this.popupXAxisData[this.dataIndex !== -1 ? this.dataIndex:(this.popupXAxisData.length-1)]}项目日常检查详情`;
        nation.getSafetyTroubleHeadCheckProjectDetailCollect(this.mode2, this.popupSearchkeys[this.dataIndex !== -1 ? this.dataIndex:(this.popupSearchkeys.length-1)] ,this.securityStarPage6, 10, item.project_id ? item.project_id:'', '', this.popupSelect)
          .then(d => {
            // console.log(d);
            this.popupData6 = d;
            this.securityStarPages6 = Math.ceil(d.__count__ / 10);
          })
      },
      securityStarPageChange6(currPage){
        this.securityStarPage5 = currPage;
        nation.getSafetyTroubleHeadCheckDetailCollect(this.mode2, this.popupSearchkeys[this.dataIndex !== -1 ? this.dataIndex:(this.popupSearchkeys.length-1)] ,this.securityStarPage6, 10, this.project_id_table ? this.project_id_table:'', '', this.popupSelect)
          .then(d => {
            // console.log(d);
            this.popupData6 = d;
            this.securityStarPages6 = Math.ceil(d.__count__ / 10);
          })
      }
    },
  }
</script>

<style scoped>
  .attendance-top{
    padding-top: 10px;
    position: relative;
  }
  .tabbar{
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 40px;
    border: 1px solid #157EAB;
    width: 210px;
    margin: auto;
    cursor: pointer;
  }
  .tabbar div{
    flex: 1;
    text-align: center;
  }
  .tabar-select{
    background-color: #4191E5;
    font-weight: bold;
  }
  .items div{
    color: #fff !important;
  }
  .selected{
    color: #fff !important
  }
  .select{
    /* border: 1px solid rgba(27,221,244,1); */
    box-shadow: 0 0 10px rgba(27,221,244,0.8) inset;
  }
</style>
