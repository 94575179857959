<template>
  <!-- <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  /> -->
  <div style="width:100%;height:10rem;display:flex;justify-content:center">
    <div style="display:flex;align-items:center">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[0]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[0]}}</div>
        <div style="display:flex" @click="handleClick(1)">
          <v-number
            v-model="(broadcastData || {}).value1"
            :speed="500"
            style="font-family:digital;font-size:3.5rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[0]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:3rem">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[1]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[1]}}</div>
        <div style="display:flex" @click="handleClick(2)">
          <v-number
            v-model="(broadcastData || {}).value2"
            :speed="500"
            style="font-family:digital;font-size:3.5rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[1]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:3rem">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[2]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[2]}}</div>
        <div style="display:flex" @click="handleClick(3)">
          <v-number
            v-model="(broadcastData || {}).value3"
            :speed="500"
            style="font-family:digital;font-size:3.5rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[2]}"
          ></v-number>
        </div>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-left:3rem">
      <img :src="require(`../../assets/nation/icons/${broadcastIcons[3]}.png`)" />
      <div>
        <div class="stats-label">{{broadcastTitles[3]}}</div>
        <div style="display:flex" @click="handleClick(3)">
          <v-number
            v-model="(broadcastData || {}).value4"
            :speed="500"
            style="font-family:digital;font-size:3.5rem;margin:-0.5rem 0 0 0.3rem"
            :style="{color: broadcastColors[3]}"
          ></v-number>
          <span v-if="(broadcastData || {}).value4 != null" style="font-family:digital;font-size:3.5rem;line-height:3rem;color:#EFA453;">%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import Broadcasts from "../../components/broadcasts";
  import { nation } from "../../api";
  import VNumber from "../../components/v-number";

  export default {
    name: 'SecurityDangerBroadcasts',
    components: {
      // Broadcasts,
      VNumber,
    }, 
    data() {
      return {
        broadcastTitles: ['已安装设备塔机数', '塔机总数', '设备在线数', '设备在线率'],
        broadcastColors: ['#40F8FF', '#FFD454', '#50F194', '#EFA453'],
        broadcastIcons: ['塔吊总数', '施工升降梯总数', '物料提升机总数', '龙门吊总数'],
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    },
  }

  export function updateData(container, scheduleNextUpdate) {
    nation
      .getSafetyAntiCollision()
      .then((d) => {
        container.broadcastData.securityAntiCollision = d[0];
        container.broadcastData.securityAntiCollision.value1 = d[0].installed_device_count;
        container.broadcastData.securityAntiCollision.value2 = d[0].device_count;
        container.broadcastData.securityAntiCollision.value3 = d[0].device_online_count;
        container.broadcastData.securityAntiCollision.value4 = d[0].online_rate;

        container.statsData = d[0];
        scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
