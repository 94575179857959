<template>
  <div id="right">
    <gorwthSlowEmpChart :district-level="districtLevel" :org-level="orgLevel" />
    <TroubleCountsPieChart :district-level="districtLevel" :org-level="orgLevel" />
  </div>
</template>

<script>
  import gorwthSlowEmpChart from "./gorwthSlowEmpChart";
  import TroubleCountsPieChart from "./troubleCountsPieChart";

  export default {
    name: 'HrIndexRightBar',
    components: {
      gorwthSlowEmpChart,
      TroubleCountsPieChart,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
  }
</script>

<style lang="less">
</style>
