<template>
  <div id="building" class="global-bg">
    <div id="main">
      <div id="middle">
        <div class="iframe">
          <iframe src="/threejs/index.html" frameborder="0" id="iFrame" ref="frame" style="width: 100%; height: 100%; border: 0"
                  allowfullscreen="true"></iframe>
        </div>

        <div class="building-changer" v-if="building.buildingList.length">
          <floor-changer :list-data="building.buildingList" :init-id="building.currentBuildingID"
                         @change="changeBuilding"/>
          <floor-changer
              :list-data="(building.buildingList.find(b => b.innerid === building.currentBuildingID) || {floor: []}).floor"
              :key="building.currentBuildingID" check-length
              :cell-style="{width: '6rem', height: '3rem', lineHeight: '3rem', overflow: 'hidden'}" @change="changeFloor"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { project, building, wsUrl } from '../../api'
  import FloorChanger from '../../components/FloorChanger'
  import {mapRatio} from '../../conf';

  // building/e2a351a4-6509-11ea-ad9d-0242ac113d06/fc9f9bef-e0d5-45c6-9ce4-a29212a78557
  // const synth = window.speechSynthesis
  const workerOnThreeColorMap = {
    '': [0, 255 / 255, 0],
    '黄色': [233 / 255, 187 / 255, 30 / 255],
    '红色': [220 / 255, 40 / 255, 43 / 255],
    '白色': [255 / 255, 255 / 255, 255 / 255],
    '蓝色': [55 / 255, 162 / 255, 218 / 255],
    '已交底': [26 / 255, 160 / 255, 148 / 255],
    '未交底': [239 / 255, 76 / 255, 107 / 255],
    '优秀': [26 / 255, 160 / 255, 148 / 255],
    '良好': [92 / 255, 190 / 255, 242 / 255],
    '一般': [51 / 255, 51 / 255, 51 / 255],
    '未考': [239 / 255, 76 / 255, 107 / 255],
  }
  /*
  const workerOnThreeBlinkColorMap = {
    '正常': [0, 255, 0],
    '坠落': [255, 0, 0],
    '呼救': [255, 0, 0],
    '预警': [255, 255, 0],
    '倒地': [255, 255, 0],
    '离线': [100, 100, 100],
  }
  */
  export default {
    name: 'disclosure',
    components: {
      'floor-changer': FloorChanger
    },
    data () {
      return {
        statusColorMap: {
          '正常': { value: '#1fc86b', cls: 'green' },
          '呼救': { value: '#f34e53', cls: 'red2' },
          '坠落': { value: '#e62c31', cls: 'red1' },
          '预警': { value: '#eebc34', cls: 'yellow2' },
          '禁区预警': { value: '#eebc34', cls: 'yellow2' },
          '危险预警': { value: '#eebc34', cls: 'yellow2' },
          '闯入禁区': { value: '#eebc34', cls: 'yellow2' },
          '静止': { value: '#eebc34', cls: 'yellow2' },
          '倒地': { value: '#F89132', cls: 'yellow1' },
          '离线': { value: '#9ca0aa', cls: 'grey' },
          '没电': { value: '#eebc34', cls: 'yellow2' }
        },
        wearMap: [{ cls: 'red2', text: '未佩戴' }, { cls: 'green', text: '佩戴' }],
        retrieveStatusMap: {
          11: { text: '召回', cls: '' },
          7: { text: '已收到', cls: 'disabled' },
          8: { text: '已确认', cls: 'disabled' },
          10: { text: '发送中', cls: 'disabled' }
        },
        ws: null,
        building: {
          showBuildingList: false,
          buildingList: [],
          currentBuildingID: '',
        },

        groupList: [{ worker_list: [], show: true }],
        curFloorList: [],
        timer: null,
        workerMap: {},
      }
    },
    mounted () {
      document.addEventListener('click', () => {
        const tooltip = this.$refs.frame.contentDocument.querySelector('#tooltip')
        if (tooltip) tooltip.style.visibility = 'hidden'
      })
      const self = this
      const token = this.$route.query.token
      if (!token) {
        return this.$alert('缺少token', '警告', {
          showClose: false,
          type: 'warning',
          center: true,
        })
      }
      const { buildingID, projectID } = this.$route.params
      window.token = token
      const { contentWindow } = self.$refs.frame
      contentWindow.onload = function () {
        var win = this;
        win.ta = self;
        // building.getLegend()
        //   .then(data => {
        //     self.legends = data.worker_type_list.map(l => {
        //       l.icon = require(`../../assets/${l.icon}`)
        //       return l
        //     })
        //   })
        //   .catch(e => self.$message.error(e.msg || e.toString()))
        building.getBuildings()
          .then(d => {
            d.forEach(l => {
              const res = []
              let floorStart = ''
              let floorEnd = ''
              let ids = []
              l.floor.forEach((a, i) => {
                if (i % 5 === 0) {
                  ids = [a.innerid]
                  floorStart = a.cname
                } else {
                  ids.push(a.innerid)
                }
                if (i % 5 === 4 || i === l.floor.length - 1) {
                  floorEnd = a.cname
                  res.push({ name: `${floorStart}~${floorEnd}`, innerid: i, ids })
                }
              })
              l.floor = res
            })
            self.building.buildingList = d
            self.building.currentBuildingID = buildingID
            self.changeFloor(d.find(_ => _.innerid === buildingID).floor[0])
          })
          .catch(e => console.error(e.msg || e.toString()))

        self.updateData();

        project
          .getBuildingBaseInfo(projectID)
          .then(d => {
            win.mapUrl = d.img_host + d.show_map[0].image_url;
          });

        this.timer = setInterval(() => {
          self.updateData();
        }, 10000)

        self.conn(self)

        // const peopleStaticsData =

        // contentWindow.postMessage({
        //   type: 'peopleStatics', data: [
        //     {
        //       msg: '水泥工：5\n钢筋工：7\n钢筋工：8',
        //       floor: '1F',
        //       area: 1,
        //     },
        //     {
        //       msg: '水泥工：11\n钢筋工：11\n钢筋工：11',
        //       floor: '2F',
        //       area: 3,
        //     }
        //   ]
        // }, '*')
      }
    },
    methods: {
      dataParser (data, first) {
        if (data && data.errcode === 0) {
          switch (data.source) {
            case 'customer_update':
              if (this.groupList.length) {
                const user = data.data[0]
                this.groupList.forEach((group, oi) => {
                  group.show = group.show === undefined ? true : group.show
                  if (group.worker_list.length) {
                    group.worker_list.forEach((worker, ii) => {
                      if (worker.innerid === user.innerid) {
                        if (this.wearMap[user.wear] === undefined || this.statusColorMap[user.alarm]=== undefined || this.retrieveStatusMap[user.recalled] === undefined) {
                          throw new Error(JSON.stringify(user))
                        }
                        let orderID
                        switch (user.status_id) {
                          case 1:
                            orderID = 0
                            break
                          case 2:
                            orderID = 1
                            break
                          case 3:
                            orderID = 1
                            break
                          case 5:
                            orderID = 2
                            break
                          case 12:
                            orderID = 3
                            break
                          case 9:
                            orderID = 4
                            break
                          default:
                            orderID = 100
                        }
                        this.$set(this.groupList[oi].worker_list, ii, Object.assign({ orderID }, user))
                      }
                    })
                    group.worker_list.sort((a, b) => a.orderID - b.orderID)
                  }
                })
                if (this.workerMap[user.innerid]) {
                  this.workerMap[user.innerid] = user
                }
                if (!first) {
                  const workerPosition = Object.values(this.workerMap).filter(w => this.curFloorList.filter(id => id === w.floor).length).map(w => ({
                    id: w.innerid,
                    name: w.name,
                    floor: w.floor_name,
                    floorID: w.floor,
                    position: { x: w.x / mapRatio, y: w.y / mapRatio },
                    color: workerOnThreeColorMap[w.disclosure_status || ''],
                    group: w.worker_type || '',
                    status_id: w.status_id,
                    disclosure_status: w.disclosure_status || '未交底',
                    worker_type: w.worker_type || '',
                    company_name: w.company_name || '',
                    hat_serial: w.hat_serial || '',
                  }))

                  this.$refs.frame.contentWindow.postMessage({ type: 'addUser', data: workerPosition }, '*')
                }
              }
              break
            default:
              console.warn(data.source)
          }
        } else {
          console.error('error code', data.errcode)
        }
      },
      updateData () {
        building.getWorkListForBuild(this.$route.params.buildingID)
          .then(list => {
            this.groupList = list
            this.groupList.forEach(group => {
              if (group.worker_list.length) {
                group.show = true
                group.worker_list.forEach(worker => {
                  worker.orderID = 100
                  this.workerMap[worker.innerid] = worker
                  this.dataParser({ data: [worker], errcode: 0, source: 'customer_update' }, true)
                })
              }
            })
          })
          .catch(e => console.error(e.msg || e.toString()));
      },
      changeBuilding (building) {
        if (this.building.currentBuildingID === building.innerid) return
        this.$router.replace({ path: `/disclosure/${building.innerid}/${this.$route.params.projectID}?token=${this.$route.query.token}` });
        this.building.currentBuildingID = building.innerid;
        this.updateData();
        this.changeFloor(this.building.buildingList.find(b => b.innerid === this.building.currentBuildingID).floor[0]);
      },
      conn () {
        const self = this
        let lockReconnect = false

        function createWebSocket (url) {
          try {
            self.ws = new WebSocket(url)
            self.ws.id = self.$route.params.projectID
            initEventHandle()
          } catch (e) {
            reconnect(url)
          }
        }

        function initEventHandle () {
          self.ws.onclose = function () {
            reconnect(wsUrl)
          }
          self.ws.onerror = function () {
            reconnect(wsUrl)
          }
          self.ws.onopen = function () {
            self.ws.send(JSON.stringify({
              'action': 'init',
              'values': { 'project_id': self.$route.params.projectID }
            }))
          }
          self.ws.onmessage = function (e) {
            try {
              const message = JSON.parse(e.data)
              self.dataParser(message)
            } catch (e) {
              if (e.message === 'Cannot read property \'contentWindow\' of undefined') {
                return location.reload()
              } else {
                console.warn(e.message)
                // self.$message.error('服务器返回数据错误')
              }
            }
          }
        }

        function reconnect (url) {
          if (lockReconnect) return
          lockReconnect = true
          setTimeout(function () {
            createWebSocket(url)
            lockReconnect = false
          }, 2000)
        }

        createWebSocket(wsUrl)
      },
      changeFloor (floor) {
        if (this.curFloorList === floor.ids) return
        this.curFloorList = floor.ids
        building.getBuildingFloorData(floor.ids)
          .then(data => {
            const floorNameArr = []
            const floorDataArr = []
            const floorIDArr = []
            data.forEach(d => {
              floorDataArr.push(d.map_data)
              floorNameArr.push(d.cname)
              floorIDArr.push(d.innerid)
            })
            const workerPosition = Object.values(this.workerMap).filter(w => this.curFloorList.filter(id => id === w.floor).length).map(w => ({
              id: w.innerid,
              name: w.name,
              floor: w.floor_name,
              floorID: w.floor,
              position: { x: w.x / mapRatio, y: w.y / mapRatio },
              color: workerOnThreeColorMap[w.disclosure_status || ''],
              group: w.worker_type || '',
              status_id: w.status_id,
              disclosure_status: w.disclosure_status || '未交底',
              worker_type: w.worker_type || '',
              company_name: w.company_name || '',
              hat_code: w.hat_code || '',
            }));

            this.$refs.frame.contentWindow.postMessage({ type: 'addUser', data: workerPosition }, '*')
            this.$refs.frame.contentWindow.postMessage({ type: 'floorData', data: { floorNameArr, floorDataArr, floorIDArr } }, '*')
            this.$refs.frame.contentWindow.postMessage({
              type: 'addClickListener',
              data: `<div style="text-align: center;">
                      <div>
                        \${this.name}&nbsp;\${this.hat_code}
                        <br/>
                        \${this.worker_type}
                        <br/>
                        \${this.company_name}
                        <br/>
                        \${this.group}
                        <br/>
                        <span style="color: \${this.disclosure_status == '已交底' ? '#1aa094' : '#ef4c6b'}">\${this.disclosure_status[0]}进行安全技术交底</span>
                      </div>`
            }, '*');
          })
      },
    },
    beforeDestroy () {
      clearInterval(this.timer)
    },
  }
</script>

<style scoped lang="less">
  #main {
    /*perspective: 100px;*/
    display: flex;
    padding: 1.5rem 1.875rem 2rem 1.875rem;
    height: calc(100vh - 2rem);
    overflow-y: scroll;

    #middle {
      flex: 1;
      display: flex;
      flex-direction: column;

      .iframe {
        flex: 1;
      }

      .floor-changer {
        margin: 1rem 0 0 1.5rem;
        display: flex;
        position: relative;

        .button {
          background: url("../../assets/qiehuan.png") no-repeat;
          width: 4.5rem;
          height: 2rem;
          cursor: pointer;
          line-height: 1.9rem;
          background-size: cover;
          text-align: center;
          font-size: 0.8rem;
          margin-right: .5rem;
        }

        .floor-list {
          border: 0.125rem solid #51bbf5;
          line-height: 1.9rem;
          font-size: 0.75rem;
          padding: 0 0.625rem;
          position: absolute;
          left: 10rem;
          width: calc(100% - 12rem);

          span {
            color: #a5a0b1;
            cursor: pointer;
            margin-right: 0.75rem;

            &.active {
              color: #5accf1;
            }
          }
        }
      }

      .building-changer {
        position: fixed;
        left: 25rem;
        bottom: 4rem;
        display: flex;
        width: 9rem;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
</style>
