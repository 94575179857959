<template>
  <div id="right">
    <RightAndCollectionChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel"/>
    <CaseProgressChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel"/>
  </div>
</template>

<script>
  import RightAndCollectionChart from './rightAndCollectionChart';
  import CaseProgressChart from './caseProgressChart';
  export default {
    name: 'LawIndexRightBar',
    components: {
     RightAndCollectionChart,
     CaseProgressChart
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
  }
</script>

<style lang="less">
</style>
