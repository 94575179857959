import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    '科研办公室': [-331.89809042158896,-124.42053451590988,570],
    '工程技术楼': [313.6326880976719,-113.54095258264881,500],
  };

  const buildingBoundaries = {
    '科研办公室': [[-150.01592893889753,73.03610180977552],[-154.82502860183828,-300.3100863624574],[-545.1214036241678,-309.82994395166696],[-548.3199123600286,64.48377976074228]],
    '工程技术楼': [[539.111329354447,74.07706400749856],[537.0722091278637,-321.35136480250554],[-4.13515328463332,-314.5085560344041],[-1.9206934939991402,76.56978588848386]],
  };

  const buildingFinishedHeights = {
    '科研办公室': 44.67,
    '工程技术楼': 50.83,
  };

  const doneFloors = {
    '科研办公室': 1,
    '工程技术楼': 1,
  };

  const traceViews = {
    '_': {
      center: [0, 0],
      pitch: 0,
      zoom: 1.8,
    },
    '科研办公室': {
      center: [-4793.5101010431, 5203.411499274705],
      pitch: 75.2,
      zoom: 2.50,
      bearing: -21.4,
    },
    '工程技术楼': {
      center: [1840.8488230108644, 1255.2185990521425],
      pitch: 75.2,
      zoom: 2.8,
      bearing: -21.4,
    },
  };

  function getUserZValue(user) {
    let height;
    if (user.bim_z_height != null && user.bim_z_height != '') {
      height = user.bim_z_height;
    } else if (user.floor == 'ground') {
      height = 0;
    } else {
      height = user.height;
    }

    return height * 7.55 + 10;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 7.55;
  }

  function getZValueFromHeight(height) {
    return height * 7.55;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/shengze2.glb',
    modelType: 'gltf',
    modelSubType: 'draco',

    //fullOpacity: 1,
    revealOpacity: 0.25,
    deepRevealOpacity: 0,

    groundHeightRanges: [
      [-38, 8.4],
//      [-0.0001, 0],
//      [153.9731, 153.9732],
//      [-1425.0001, -1424.9999],
    ],

    modelOptions: {
      scale: 8,
      rotZ: 0,
      moveX: 0,
      moveY: 0,
      moveZ: 10,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: 0,
      z: 0
    },

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
