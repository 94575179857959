<template>
  <div id="right">
    <div class="panel">
      <div id="system-info" style="margin-bottom: 10px;font-weight: bold;">
        <div class="item" style="display: flex;align-items: center;">
          <p class="lower" style="margin-right: 0.5rem">{{systemInfo.date}}</p>
          <p class="upper">{{systemInfo.time}}</p>
        </div>
      </div>
      <div style="display: flex;margin-bottom: 10px;">当前{{areaName}}生效预警<div style="color: #C52D38;font-weight:bold;margin: 0 10px">{{provinceWorkers.length}}</div>条</div>
      <div style="margin-top:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
          <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
            <div style="width:10rem;text-align:center">类型</div>
            <div style="width:8rem;text-align:center">红色</div>
            <div style="width:8rem;text-align:center">橙色</div>
            <div style="width:8rem;text-align:center">黄色</div>
            <div style="width:8rem;text-align:center">蓝色</div>
          </div>
          <div style="height:20rem;overflow:auto;">
            <div
              v-for="(item, index) in provinceWorkers"
              :key="item.source"
              style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
              :style="{ background: index%2 ? '#102855' : '#213a77'}"
            >
              <div style="width:10rem;text-align:center">{{item.source}}</div>
              <div style="width:8rem;text-align:center">
                {{item.level_count[0]}}
              </div>
              <div style="width:8rem;text-align:center">{{item.level_count[1]}}</div>
              <div
                style="width:8rem;text-align:center"
              >{{item.level_count[2]}}</div>
              <div style="width:8rem;text-align:center">{{item.level_count[3]}}</div>
            </div>
            <div
              v-if="!(provinceWorkers && provinceWorkers.length)"
              style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
            >
              暂无预警数据
            </div>
          </div>
          <!-- <el-pagination
            background
            layout="prev, pager, next"
            :current-page.sync="securityStarPage"
            :page-count="securityStarPages"
            @current-change="securityStarPageChange"
            style="text-align:center;margin-top:0.5rem"
          ></el-pagination> -->
        </div>
        <div style="display: flex;">
          <div style="width: 100px;">
            <div style="margin-bottom: 1rem;font-size: 16px;">预警等级</div>
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="city in cities" :label="city.value" :key="city.id">{{city.value}}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div style="width: 100px;">
            <div style="margin-bottom: 1rem;font-size: 16px;">类型</div>
            <el-checkbox :indeterminate="isIndeterminate2" v-model="checkAll2" @change="handleCheckAllChange2">全选</el-checkbox>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="checkedCities2" @change="handleCheckedCitiesChange2">
              <el-checkbox v-for="city in cities2" :label="city.value" :key="city.id">{{city.value}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <!--<div style="height:18rem;padding-top:3rem">
        <v-chart :options="workerTypePieChart" ref="workerType" @mouseover="workerTypeMouseOver" @mouseout="workerTypeMouseOut" autoresize />
      </div> -->
    <!-- </div> -->
    <!-- <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
      <div class="info-block-title">工人年龄段分布</div>
      <div style="height:18rem;padding-top:3rem">
        <v-chart :options="workerAgePieChart" ref="workerAge" @mouseover="workerAgeMouseOver" @mouseout="workerAgeMouseOut" autoresize />
      </div>
    </div>
    <div class="info-block" style="min-height:5rem">
      <div class="info-block-title">各省劳务输出TOP10</div>
      <div style="padding-top:3rem">
        <div style="max-height:20rem;display:flex;line-height:1.6rem;font-weight:bold;overflow:scroll">
          <div style="font-size:0.9rem;color:#abbbbf">
            <div v-for="province in provinceWorkers" :key="province.province" style="height:1.6rem">{{province.province}}</div>
          </div>
          <div style="flex:1;margin:0 1rem">
            <div v-for="province in provinceWorkers" :key="province.province" style="height:1.6rem;display:flex">
              <div class="slider-trail" :style="{background: `linear-gradient(to right, #24dcf7 ${province.pcount / provinceWorkersMax * 100}%, #09192e 0)`}" style="width:100%;margin:auto" />
            </div>
          </div>
          <div style="text-align:right;color:#23d6f1">
            <div v-for="province in provinceWorkers" :key="province.province" style="height:1.6rem">{{province.pcount}}</div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
  require('echarts-gl')
  // import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { nation } from '../../api'
  // import { attendancePieChartTemplate, colors } from './pieChartTemplates';

  export default {
    name: 'WeatherRightBar',
    components: {
      // 'v-chart': ECharts,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
      areaName: String,
      changeWeatherAlarmLevels: Function,
      changeWeatherAlarmTypes: Function
    },
    data () {
      return {
        provinceWorkers: [],
        provinceWorkersMax: 1,
        workerTypePieChart: {},
        workerAgePieChart: {},
        workerTypePieChartHighlightIndex: 0,
        workerAgePieChartHighlightIndex: 0,
        checkAll: true,
        checkedCities: [],
        cities: [],
        cities2: [],
        checkedCities2: [],
        isIndeterminate: false,
        isIndeterminate2: false,
        checkAll2: true,
        securityStarPage: 0,
        securityStarPages: 0,
        systemInfo: {
          time: "",
          date: "",
          temperature: "",
          weather: "1",
          timer: null,
        },
      }
    },
    watch: {
      orgLevel: function (newValue) {
        if (newValue) {
          console.log(newValue);
          this.getAll();
        }
      },
      districtLevel: function (newValue) {
        if (newValue) {
           console.log(newValue);
          this.getAll();
        }
      },
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.getAll();

      this.systemInfo.timer = setInterval(() => {
        const d = new Date();
        this.systemInfo.time =
          d.getHours().toString().padStart(2, "0") +
          ":" +
          d.getMinutes().toString().padStart(2, "0") +
          ":" +
          d.getSeconds().toString().padStart(2, "0");
        if (((d * 1) % 864000) * 1000 < 1000 || !this.systemInfo.date) {
          this.systemInfo.date =
            d.getFullYear() +
            "-" +
            (d.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            d.getDate().toString().padStart(2, "0");
        }
      }, 1000);
    },
    methods: {
      getAll(){
        nation.getEmergencyAlarmCountSourceCollect()
          .then(d => {
            // console.log(d);
            this.provinceWorkers = d;
          })

        nation.getEmergencyParamsSelect(1)
          .then(d => {
            // console.log(d);
            this.cities = d;
            this.checkedCities = d.map(item => item.value);
            this.checkAll = true;
            this.isIndeterminate = false;
            this.changeWeatherAlarmLevels(this.checkedCities);
          })

        nation.getEmergencyParamsSelect(2)
          .then(d => {
            // console.log(d);
            this.cities2 = d;
            this.checkedCities2 = d.map(item => item.value);
            this.checkAll2 = true;
            this.isIndeterminate2 = false;
            this.changeWeatherAlarmTypes(this.checkedCities2);
          })
      },
      handleCheckAllChange(val) {
        this.checkedCities = val ? (this.cities.map(vv => {
          return vv.value
        })) : [];
        this.isIndeterminate = false;
        this.changeWeatherAlarmLevels(this.checkedCities);
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.cities.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
        this.changeWeatherAlarmLevels(this.checkedCities);
      },
      handleCheckAllChange2(val) {
        this.checkedCities2 = val ? (this.cities2.map(vv => {
          return vv.value
        })) : [];
        this.isIndeterminate2 = false;
        this.changeWeatherAlarmTypes(this.checkedCities2);
      },
      handleCheckedCitiesChange2(value) {
        let checkedCount = value.length;
        this.checkAll2 = checkedCount === this.cities2.length;
        this.isIndeterminate2 = checkedCount > 0 && checkedCount < this.cities2.length;
        this.changeWeatherAlarmTypes(this.checkedCities2);
      },
      securityStarPageChange(currPage) {
        this.securityStarPage = currPage;
      },
    },
  }
</script>

<style scoped>
  .panel{
    background-color: #0F1F3C;
    width: 360px;
    font-size: 14px;
    padding: 20px;
  }

  .el-checkbox + .el-checkbox{
    margin-left: 0 !important;
  }
</style>

<style>
  .el-checkbox__inner{
    border-color: #fff !important;
  }
  .el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color: transparent !important;
  }
  .el-checkbox__input.is-indeterminate .el-checkbox__inner::before{
    background-color: #4CB4DA !important;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: transparent !important;
  }
  .el-checkbox__inner::after{
    border-color: #4CB4DA !important;
  }
</style>
