<template>
  <div class="area_list">
    <div class="stop-button" @click="stop()">停止轨迹播放</div>
    <div style="margin:1rem">人员轨迹-{{workerName}}</div>
    <div class="area-info" v-for="(info, index) in areaInfo" :key="info.area_id">
      <div style="width: 1.8rem; margin-left: 0.5rem">
        <div class="line" style="height: 0.2rem" :style="{borderWidth: index == 0 ? '0' : '1px'}" />
        <div class="circle" />
        <div class="line" style="height: 100%" v-if="index != areaInfo.length - 1" />
      </div>
      <div style="width: 3.5rem">
        {{formatTime(info.start_time)}}
      </div>
      <div style="width: 9rem">
        <p>
          进入{{info.loc_name}}
        </p>
        <p>
          停留时间-{{formatMins(info.t)}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { project } from '../../api'

export default {
  name: 'TraceHistory',
  props: {
    workerId: String,
    workerName: String,
    stop: Function,
  },
  data() {
    return {
      areaInfo: []
    };
  },
  mounted() {
    //加载人员轨迹
    project.getWorkerTraceArea(this.workerId).then(d => this.areaInfo = d).catch(e => console.error(e.msg || e.toString()));
  },
  watch: {
    workerId: function(newWorkerId, oldWorkerId) {
      if (newWorkerId != oldWorkerId) {
        project.getWorkerTraceArea(newWorkerId).then(d => this.areaInfo = d).catch(e => console.error(e.msg || e.toString()));
      }
    }
  },
  methods: {
    formatTime(time) {
      const hour = time.slice(0, 2);
      const min = time.slice(-2);
      return `${hour}:${min}`;
    },
    formatMins(mins) {
      mins = Math.round(mins);
      if (mins >= 60) {
        return `${Math.floor(mins / 60)}小时${mins % 60}分钟`;
      } else {
        return `${mins}分钟`;
      }
    }
  }
}
</script>

<style scoped lang="less">
.area_list {
  z-index: 999;
  position: absolute;
  top: 0px;
  right: 5px;
  background: #1e2c61;
  margin: 5px 0.5rem;
  border: 1px solid #5f7ec9;
  border-radius: 0.2rem;
  max-height: 96%;
  overflow-y: auto;
}

.stop-button {
  background: #43A9F1;
  font-size: 1rem;
  line-height: 2rem;
  width: 12rem;
  height: 2rem;
  border-radius: 1rem;
  margin: 1rem auto;
  text-align: center;
  cursor: pointer;
}

.area-info {
  z-index: 1;
  display: flex;
  font-size: 0.8rem;
  line-height: 1.2rem;
  padding-bottom: 0.8rem;

  .circle {
    margin: auto;
    border: 1px solid #5f7ec9;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.4rem;
  }

  .line {
    margin: auto;
    border: 1px solid #5f7ec9;
    width: 1px;
  }
}
</style>
