<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  />
</template>

<script>
  import Broadcasts from "../../components/broadcasts";
  import { project } from "../../api";

  export default {
    name: 'LaborBroadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['在场工人数', '出勤工人数', '现场工人数'],
        broadcastColors: ['#40f8ff', '#ffd454', '#35c59e'],
        broadcastIcons: ['在场工人', '出勤工人', '当前在场工人'],
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    },
  }

  export function updateData(container, scheduleNextUpdate) {
    project
      .getLaborBroadcastDetail()
      .then((d) => {
        container.broadcastData.labor = d[0];
        container.broadcastData.labor.value1 = d[0].worker_online_count;
        container.broadcastData.labor.value2 = d[0].attendance_worker_count;
        container.broadcastData.labor.value3 = d[0].worker_now_online_count;

        container.statsData = d[0];
        scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
