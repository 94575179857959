<template>
    <div  style="height:100%;position:relative">
        <div id ="_chart">
            <div
            v-if="AuthenticChart.series && [].concat.apply([], AuthenticChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;height:30rem;justify-content:center;margin-top: 0.5rem;"
        >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="AuthenticChart" @click="AuthenticChartClick" autoresize />
        </div>
    </div>
</template>
<script>
require('echarts-gl')
  import ECharts from 'vue-echarts'
  import {law} from '../../../../api_law';
export default {
    name:"ChiefComplaintCaseChart",
    components:{
        'v-chart':ECharts
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateAuthenticChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateAuthenticChart();
        }
      }
    },
    data(){
        return{
            AuthenticChart:{}
        }
    },
    mounted(){
        // const self = this
      const token = this.$route.query.token;
      window.token = token;
      
      this.updateAuthenticChart();
    },
    methods:{
        AuthenticChartClick(){
            alert(11);
        },
        updateAuthenticChart(){
            law.queryAuthenticStatistics().then((res)=>{
                const option = {
                    grid: {
                        left: '5%',
                        right: '2%',
                        top: '10%',
                        bottom: '15%',
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            lineStyle: {
                                color: '#57617B',
                            },
                        },
                    },
                    legend: {
                        data: ["本年度确权金额","本年度回款金额"],
                        right: '8%',
                        top: '2%',
                        textStyle: {
                            color: '#5195da',
                        },
                    },
                    xAxis: {
                        type: 'category',
                        
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#3585d5',
                            },
                        },
                        offset: 20,
                        axisTick: {
                            show: false,
                            alignWithLabel: true,
                            lineStyle: {
                                color: '#3585d5',
                            },
                        },
                        axisLabel: {
                            color: 'white',
                            fontWeight: 'bold',
                            rotate: 40,
                            // formatter: value => truncateStr(value, 10),
                        },
                        data: [],
                    },
                    yAxis: {
                        name: '',
                        nameTextStyle:{
                            color: '#A2D4E6',
                        },
                        type: 'value',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#3585d5',
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                type: 'dotted',
                                color: '#3585d5',
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            fontSize: 16,
                            color:'#A2D4E6'
                        },
                        boundaryGap: ['20%', '20%'],
                    },
                    series: [
                        {
                            name: "本年度确权金额",
                            type: 'bar',
                            smooth: true,
                            symbol: 'none',
                            showSymbol: false,
                            symbolSize: 8,
                            itemStyle: {
                                color: {
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    type: 'linear',
                                    global: false,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#FFC31F',
                                        },
                                        {
                                            offset: 1,
                                            color: '#FF5A1F',
                                        },
                                    ],
                                },
                            },
                            data: [],
                        },
                        {
                            name: "本年度回款金额",
                            type: 'bar',

                            smooth: true,
                            symbol: 'none',
                            showSymbol: false,
                            symbolSize: 8,
                            itemStyle: {
                                color: {
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    type: 'linear',
                                    global: false,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#28D8E8',
                                        },
                                        {
                                            offset: 1,
                                            color: '#0489F0',
                                        },
                                    ],
                                },
                            },
                            data: [],
                        },
                    ],
                }
                for (let i = 0; i < res.length; i++) {
                    option.xAxis.data.push(res[i].orgName);
                    option.series[0].data.push(res[i].qqje);
                    option.series[1].data.push(res[i].hkje);
                }
                this.AuthenticChart = option;
            })
        }
    }
}
</script>
<style scoped>
    #_chart {
        width:100%;
        height: 70%;
        min-width: 500px;
    }
</style>