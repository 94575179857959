module.exports = {
  '4456ba6a-781e-11eb-8bc7-7cd30ae012b4': {
    // 慈溪正大-综合楼
    scale: 5.2,
    rotZ: 0,
    moveX: 130,
    moveY: 100,
    moveZ: -50
  },
  '98502bab-9868-11eb-8bc7-7cd30ae012b4': {
    // 永宁棉场 - A2
    scale: 0.9,
    rotZ: -15,
    moveX: 60,
    moveY: 210
  },
  '9cd039bc-9868-11eb-8bc7-7cd30ae012b4': {
    // 永宁棉场 - A3
    scale: 0.9,
    rotZ: -15,
    moveX: 260,
    moveY: 260,
  },
  'a03781cb-9868-11eb-8bc7-7cd30ae012b4': {
    // 永宁棉场 - A5
    scale: 1.3,
    rotZ: -15,
    moveX: 100,
    moveY: 280,
  },
  '93625147-9868-11eb-8bc7-7cd30ae012b4': {
    // 永宁棉场 - A1
    scale: 0.8,
    rotZ: -15,
    moveX: -90,
    moveY: 180,
  },
  'aa2481c0-9868-11eb-8bc7-7cd30ae012b4': {
    // 永宁棉场 - B1
    scale: 1,
    rotZ: -15,
    moveX: 205,
    moveY: -100,
  },
  'a44c2e78-9868-11eb-8bc7-7cd30ae012b4': {
    // 永宁棉场 - A6
    scale: 0.9,
    rotZ: -15,
    moveX: -20,
    moveY: 280,
  },
  'b179e581-9868-11eb-8bc7-7cd30ae012b4': {
    // 永宁棉场 - B2
    scale: 1,
    rotZ: -15,
    moveX: 75,
    moveY: -130,
  },
  'a7181668-9868-11eb-8bc7-7cd30ae012b4': {
    // 永宁棉场 - A7
    scale: 0.9,
    rotZ: -15,
    moveX: -170,
    moveY: 290,
  },
  'addd0ece-9868-11eb-8bc7-7cd30ae012b4': {
    // 永宁棉场 - B3
    scale: 0.9,
    rotZ: -13,
    moveX: 150,
    moveY: 0,
  },
  'b4e9bd50-9868-11eb-8bc7-7cd30ae012b4': {
    // 永宁棉场 - B5
    scale: 0.9,
    rotZ: -15,
    moveX: 20,
    moveY: -30,
  },
};
