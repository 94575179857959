import ECharts from 'vue-echarts';

export const colors = [
 '#618BF2',
 '#B6F261',
 '#F261E0',
 '#61F2DA',
 '#F2B061',
 '#8561F2',
 '#67F261',
 '#F26192',
 '#61BCF2',
 '#E6F261',
 '#D461F2',
 '#61F2A9',
 '#F27F61',
 '#616DF2',
 '#98F261',
 '#F261C2',
 '#61EDF2',
 '#F2CE61',
 '#A361F2',
 '#61F279',
];

export const attendanceColors = [
  '#2d7dff',
  '#33a5e2',
  '#42cea6',
  '#58b97a',
  '#89bf67',
  '#aebf67',
  '#dbb97f',
  '#dba47f',
  '#ed85a8',
  '#dc5c72',
  '#be52cf',
];

export const areaPieChartTemplate = {
  color: colors,
  tooltip: {
    trigger: 'item',
  },
  legend: {
    orient: 'vertical',
    x: 'left',
    left: '55%',
    top: 'center',
    itemHeight: 12,
    itemGap: 6,
    textStyle: {
      fontSize: 12,
      color: '#ffffff'
    }
  },
  series: [{
    type: 'pie',
    radius: ['8%', '10%'],
    center: ['32%', '50%'],
    label: {
      normal: {
        show: false,
      }
    },
    silent: true,
    data: [{name: '', value: 1, itemStyle: { color: '#809df5' }}]
  }].concat([20, 34, 48, 62, 76].map(radius => ({
    type: 'pie',
    radius: [radius + '%', radius + 1 + '%'],
    center: ['32%', '50%'],
    label: {
      normal: {
        show: false,
      }
    },
    silent: true,
    data: [{name: '', value: 1, itemStyle: { color: '#09315b' }}]
  }))).concat([{
    type: 'pie',
    radius: ['15%', '72%'],
    center: ['32%', '50%'],
    roseType: 'radius',
    label: {
      normal: {
        show: false,
      }
    },
    itemStyle: {
        borderRadius: 8
    },
    data: []
  }])
};

export const attendancePieChartTemplate = {
  color: attendanceColors,
  series: [
    {
      type: 'pie',
      minAngle: 2,
      radius: ['40%', '70%'],
      center: ['50%', '50%'],
      avoidLabelOverlap: true,
      itemStyle: {
        normal: {
          borderColor: '#08122b',
          borderWidth: 2
        }
      },
      label: {
        normal: {
          show: false,
          position: 'center',
          //formatter: '{text|{b}}\n{c} ({d}%)',
          //formatter: '{value|{d}%}\n{text|{b}}',
          formatter: function (params) {
            return `{value|${Math.round(params.percent)}%}\n{text|${params.name}}`;
          },
          padding: [20, 0, 0, 0],
          rich: {
            text: {
              color: '#aaa',
              fontSize: 12,
              align: 'center',
              verticalAlign: 'middle',
              lineHeight: 22,
            },
            value: {
              color: '#fff',
              fontSize: 16,
              fontWeight: 'bold',
              align: 'center',
              verticalAlign: 'middle',
            }
          }
        },
        emphasis: {
          show: true,
          textStyle: {
            fontSize: 14
          }
        }
      },
      data: []
    }
  ]
};

export const mainChartTemplate = {
  color: [
    '#2d7dff',
    '#33a5e2',
    '#42cea6',
    '#58b97a',
    '#89bf67',
    '#aebf67',
    '#dbb97f',
    '#dba47f',
    '#ed85a8',
    '#dc5c72',
    '#be52cf',
  ],
  series: [
    {
      type: 'pie',
      minAngle: 2,
      radius: ['40%', '70%'],
      center: ['50%', '55%'],
      avoidLabelOverlap: false,
      itemStyle: {
        normal: {
          borderColor: '#08122b',
          borderWidth: 2
        }
      },
      label: {
        normal: {
          show: false,
          position: 'center',
          //formatter: '{text|{b}}\n{c} ({d}%)',
          formatter: '{value|{d}%}\n{text|{b}}',
          padding: [20, 0, 0, 0],
          rich: {
            text: {
              color: '#aaa',
              fontSize: 12,
              align: 'center',
              verticalAlign: 'middle',
              lineHeight: 22,
            },
            value: {
              color: '#fff',
              fontSize: 16,
              fontWeight: 'bold',
              align: 'center',
              verticalAlign: 'middle',
            }
          }
        },
        emphasis: {
          show: true,
          textStyle: {
            fontSize: 14
          }
        }
      },
      data: []
    }
  ]
};

export const popupChartTemplate = {
  //tooltip: {
  //  trigger: 'item',
  //},
  grid: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    containLabel: false
  },
  // legend:{
  //   orient: 'vertical',
  //   left: 'right',
  //   textStyle:{
  //     color:'#fff'
  //   },
  //   data: []
  // },
  legend: {
    orient: 'vertical',
    left: '56%',  //图例距离左的距离
    height: '50%',
    y: 'center',  //图例上下居中
    symbol: 'rect',
    itemWidth: 12,
    itemHeight: 12,
    itemGap: 14,
    formatter: null,
    textStyle: {
      rich: {
          a: {
            color: '#878b94',
            fontSize: 14,
          },
          b: {
            color: '#ffffff',
            fontSize: 14,
          },
          c: {
            color: 'transparent',
            fontSize: 14,
          }
      }
    },
    data: []
  },
  color: colors,
  series: [{
    type: 'pie',
    radius: ['40%', '70%'],
    //center: ['46%', '60%'],
    center: ['30%', '53%'], //图的位置，距离左跟上的位置
    data: [],
    label: {
      normal: {
        show: false,
        position: 'center',
        //formatter: '{text|{b}}\n{c} ({d}%)',
        formatter: '{value|{d}%}\n{text|{b}}',
        padding: [20, 0, 0, 0],
        rich: {
          text: {
            color: '#aaa',
            fontSize: 12,
            align: 'center',
            verticalAlign: 'middle',
            lineHeight: 22,
          },
          value: {
            color: '#fff',
            fontSize: 16,
            fontWeight: 'bold',
            align: 'center',
            verticalAlign: 'middle',
          }
        }
      },
      emphasis: {
        show: true,
        textStyle: {
          fontSize: 14
        }
      }
    },
  }]
};
export const gaugeChartTemplate = {
  color: ["rgba(255,255,255,0)", "rgb(0, 224, 151)", "rgb(8, 48, 63)"], //  进度条颜色 进度条背景颜色
  series: [
    {
      name: "产值自施率占比",
      type: "pie",
      startAngle: 330,
      radius: ["70%", "95%"],
      legendHoverLink: false,
      hoverAnimation: false,
      avoidLabelOverlap: false,
      labelLine: {
        normal: {
          show: false,
        },
      },
      detail: {
        formatter: "{value}%",
        show: true
      },
      data: [
        {
          value: '',
          name: '',
          label: {
            position: "center",
            color: "#4ce0c9",
            fontSize: 24,
          },
        },
        {
          name: "产值自施率占比",
          value: '',
          itemStyle: {
              color: new ECharts.graphic.LinearGradient(0,0,1,0,[
                  {
                      offset: 0,
                      color: "#4ce0c9"
                  },
                  {
                      offset: 1,
                      color: "#3b87f8"
                  }
              ]),
          },
        },
        {
          value: '',
          itemStyle: {
            color: '#21325c',
          },
        }
      ],
    },
  ],
}
