<template>
  <div class="info-block" style="min-height:5rem">
    <div class="info-block-title">危大工程级别</div>
    <div style="height:20rem;padding-top:3rem">
      <div
        v-if="dangerLevelsChart.series && [].concat.apply([], dangerLevelsChart.series.map(x => x.data)).length == 0"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart v-else :options="dangerLevelsChart" autoresize />
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { nation } from '../../../../api'
  import { stackedBarChartTemplate } from "../../barChartTemplates";
  import { applyUpdate } from "../../../../utils";

  export default {
    name: 'DangerLevelsChart',
    components: {
      'v-chart': ECharts,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerLevelChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerLevelChart();
        }
      }
    },
    data () {
      return {
        dangerLevelsChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.updateSafetyDangerLevelChart();
    },
    methods: {
      updateSafetyDangerLevelChart() {
        nation.getSafetyDangerLevels()
          .then(d => {
            const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
            const option = {
              color: ["#46A0FF", "#FFED21", "#FF8521", "rgba(0,0,0,0)"],
              legend: {
              },
              grid: {
                right: "5%",
                bottom: "5%"
              },
              xAxis: {
                type: "value",
                axisLabel: {
                  color: "#e6f2f3",
                  rotate: 0,
                  fontWeight: "normal",
                },
                splitLine: {
                  show: true,
                },
              },
              yAxis: [
                {
                  nameRotate: "",
                  nameTextStyle: {
                    color: "#ccc",
                    align: "right",
                  },
                  type: "category",
                  axisLabel: {
                    color: "#ffffff",
                    formatter: function (value) {
                      return value.length > 8 ? value.slice(0, 8) + "..." : value;
                    },
                  },
                  axisLine: { show: true, lineStyle: { color: "#36445f" } },
                  axisTick: { show: false },
                }, {
                  name: '数量：个',
                  type: 'category',
                  position: 'right',
                  offset: 0,
                  nameGap: 0,
                  nameTextStyle: {
                    color: '#fff'
                  },
                  axisLine: {
                    show: false
                  },
                  axisLabel: {
                    show: true,
                    color: '#fff'
                  }
                }
              ],
            };

            const legends = d.legend_data.length;
            option.yAxis[0].data = d.yAxis_data;
            option.yAxis[1].data = d.series[legends];
            option.series = d.series.slice(0, legends).map((item, index) => {
              const res = {
                name: d.legend_data[index],
                type: "bar",
                stack: "total",
                barMaxWidth: 18,
                emphasis: {
                  focus: "series",
                },
                data: item,
              };

              return res;
            });

            this.dangerLevelsChart = applyUpdate(chart, option);
          })
          // eslint-disable-next-line
          .catch((e) => console.error(e.msg || e.message));
      },
    },
  }
</script>

<style lang="less">
</style>
