<template>
  <div id="map">
    <div id="patrolmap_timeline" class="jtimeline" style="margin:0 30px">
        <ul class="jtimeline-events" id="patrolmap_timeline_events">
            <li v-for="entry in timelineEntries" :key="entry" :class="`jtimeline-event${entry === selectedEntry ? ' is-active' : ''}`" :data-timestamp="new Date(entry) / 1000" @click="selectTimelineDate(entry)">
              {{entry}}
            </li>
        </ul>
    </div>
    <div class="project_map" id="patrol-mapid" :style="{backgroundImage: `url(${projectImage})`, height: '48rem'}"></div>
    <el-dialog custom-class="stats-layer" :modal="false" :visible.sync="patrolDetailShow" width="25rem" top="18rem" @closed="cleanupPopup">
      <wrapper class="popup-bg" style="height:20rem;backgroundColor:#0e1328;position:relative">
        <div class="stats-close-btn" @click="patrolDetailShow = false" />
        <div style="margin-left:1rem;font-size:1.4rem;font-weight:bold;margin-top:0.8rem">电箱{{patrolDetailDataItem.box_name}}</div>
        <div style="margin-left:1rem;font-size:1rem;font-weight:bold;margin-top:0.8rem;">{{this.selectedEntry}}电箱巡检情况</div>
        <div style="margin:1rem 1rem 0 1rem; overflow: scroll; font-size:0.9rem; font-weight: 600; position:relative;">
          <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#121d43">
            <div style="flex:3">巡检人</div>
            <div style="flex:4;text-align:center">巡检时间</div>
          </div>
          <div style="height:10rem;overflow:auto;">
            <div v-for="(item, index) in patrolDetailDataItem.worker_info" :key="index" style="display:flex; align-items: center; justify-content: space-between; padding:0 1rem; font-size:0.9rem; line-height:2rem;" :style="{ background: index%2 ? '#121d43' : '#081337', height: item.time_set.length * 2 + 'rem'}">
              <div style="flex:3">{{item.worker_name}}</div>
              <div style="flex:4;text-align:center">
                <div v-for="x in item.time_set" :key="x.start_time + x.end_time">
                  {{x.start_time}}~{{x.end_time}}
                </div>
              </div>
            </div>
            <div v-if="!(patrolDetailDataItem.worker_info && patrolDetailDataItem.worker_info.length)" style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center">
              暂无巡检记录
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
//require('echarts-gl')
//import ECharts from 'vue-echarts'
//import 'echarts/lib/chart/bar'
//import 'echarts/lib/component/tooltip'
import wrapper from '../../components/wrap'
import { project, wsUrl } from '../../api'
import {
  init,
  changeMap,
  createWebSocket,
  setElectricBoxes,
} from '../../../public/leaflet.js';
import $ from 'jquery';
require('../../../public/jtimeline.js');
require('../../../public/jtimeline.css');

function prepend0(x) {
  return x >= 10 ? x : '0' + x;
}

function formatTime(dt) {
  return `${dt.getFullYear()}-${prepend0(dt.getMonth() + 1)}-${prepend0(dt.getDate())}`;
}

export default {
  name: 'Patrol',
  components: {
    wrapper,
  },
  data() {
    return {
      s_build_id: '',
      s_index: 0,
      buildingInfo: [
        {
          build_id: '',
          build_name: '',
          location: {},
          floor_height: '',
          map_count: 0,
          worker_count: 0,
          map_worker_list: ''
        }
      ],
      projectImage: '',
      map_list: [],
      img_host: '',
      timelineEntries: [],
      selectedEntry: '',
      electricBoxes: [],
      patrolDetailShow: false,
      patrolDetailType: '',
      patrolDetailDataItem: {},
    }
  },
  mounted() {
    const { id: projectID } = this.$route.params
    const token = this.$route.query.token;
    window.token = token;

    const start_date = new Date();
    const end_date = new Date();
    start_date.setDate(start_date.getDate() - 30);
    this.loadTimelineEntries(start_date, end_date);

    project
      .getBuildingBaseInfo(projectID)
      .then(d => {
        this.buildingInfo = d.build_list
        this.map_list = d.show_map
        this.img_host = d.img_host
        this.s_index = 0;
        this.s_build_id = this.map_list[0].build_id;
        //创建ws socket连接
        createWebSocket('patrol-mapid', wsUrl, projectID)
        //加载地图
        init(
          'patrol-mapid',
          this.img_host + this.map_list[0].image_url,
          this.s_build_id,
          this,
          null,
          {
            enableMarkers: false,
            enableElectricBox: true,
            mapWidth: this.map_list[0].width,
            mapHeight: this.map_list[0].height,
          }
        );

        this.updatePatrolMapData({
          build_id: this.s_build_id,
          search_date: this.selectedEntry
        });
      })
      .catch(e => console.error(e.msg || e.message));
  },
  updated() {
    $('#patrolmap_timeline').jTimeline({
      minimumSpacing: 80,
      step: 400,
    });
  },
  methods: {
    changeMap(map_image, type, build_id, m_index) {
      if (map_image && type && build_id) {
        this.s_index = m_index
        this.s_build_id = build_id
        changeMap('patrol-mapid', this.img_host + map_image, type, build_id)

        this.updatePatrolMapData({
          build_id: this.s_build_id,
          search_date: this.selectedEntry
        });
      }
    },
    mapSelect(name) {
      const idx = this.map_list.findIndex(item => item.name == name);
      if (idx >= 0 && idx !== this.s_index) {
        const m = this.map_list[idx];
        this.changeMap(m.image_url, m.type, m.build_id, idx);
      }
    },
    selectTimelineDate(entry) {
      this.selectedEntry = entry;
      this.updatePatrolMapData({
        build_id: this.s_build_id,
        search_date: entry
      });
    },
    loadTimelineEntries(start_date, end_date) {
      this.timelineEntries = [];
      while (start_date <= end_date) {
        const date_str = formatTime(start_date);
        this.timelineEntries.push(date_str);
        start_date.setDate(start_date.getDate() + 1);
      }
      if (this.timelineEntries.length) {
        this.selectedEntry = this.timelineEntries[this.timelineEntries.length - 1];
      }
    },
    updatePatrolMapData(fields) {
      setElectricBoxes('patrol-mapid', []);
      var params = JSON.stringify(fields);
      project
        .getElectricBoxPatrolData(params)
        .then(list => {
          console.log(list);
          this.electricBoxes = list;
          setElectricBoxes('patrol-mapid', list);
        })
        .catch(e => console.error(e.msg || e.toString()))
    },
    showPatrolDetail(type, dataId) {
      if (type == 'elecbox') {
        this.patrolDetailType = type;
        this.patrolDetailDataItem = this.electricBoxes.find(item => item.deploy_id == dataId) || {};
        this.patrolDetailShow = true;
      }
    },
    cleanupPopup() {
      this.patrolDetailType = '';
      this.patrolDetailDataItem = {};
    },
  },
}
</script>

<style scoped lang="less">
#patrol-mapid {
  width: 100%;
  height: 100vh;
}

.popup-bg {
  background-image: url("../../assets/stats-bg.png");
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
</style>
<style>
.stats-layer .el-dialog__header {
  display: none;
}
.stats-layer .el-dialog__body {
  padding: 0;
  color: unset;
}
</style>
