<template>
  <div id="right">
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode2 == '1' }"
            @click="() => switchworkerTypePieChartOption('1')"
          >突击队</div>
          <div
            class="option-block2"
            style="min-width: 5rem;"
            :class="{ 'option-block-selected2': mode2 == '2' }"
            @click="() => switchworkerTypePieChartOption('2')"
          >零星用工</div>
        </div>
        <div class="detail" @click="showDetail(1)">
          详情
        </div>
      </div>
      <div style="height:18rem;padding-top:3rem">
        <div
          v-if="workerTypePieChart.series && [].concat.apply([], workerTypePieChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="workerTypePieChart"
          @click="workerTypePieChartClick"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>上访次数</div>
        <div class="detail" @click="showDetail(2)">
          详情
        </div>
      </div>
      <div style="height:18rem;padding-top:3rem">
        <div
          v-if="workerAgePieChart.series && [].concat.apply([], workerAgePieChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="workerAgePieChart"
          @click="workerAgePieChartClick"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div
        class="info-block-title"
        style="width: 95%;padding-right:3rem"
      >
        <div style="display:flex;align-items:center;justify-content: space-between;">
          <div style="flex:1">共建方情况</div>
          <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '1' }"
              @click="() => switchAvgAttendanceTopListOption('1')"
            >人</div>
            <div
              class="option-block2"
              :class="{ 'option-block-selected2': mode == '2' }"
              @click="() => switchAvgAttendanceTopListOption('2')"
            >项目</div>
            <div
              class="detail"
              style="margin-left: 0.5rem;"
              @click=" showCompanyDetail()"
            >
              详情
            </div>
          </div>
        </div>
      </div>
      <div style="padding-top:3rem">
        <div
          v-if="!avgAttendanceTopLists.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <div v-else style="max-height:20rem;line-height:1.6rem;font-size:14px;color:#abbbbf;font-weight:bold;overflow:scroll;padding-left: 1rem">
          <div
            v-for="project in avgAttendanceTopLists"
            :key="project.company_name"
            style="height:1.6rem;display:flex;justify-content:space-between"
          >
            <div>{{project.company_name}}</div>
            <div style="margin-left:0.5rem">{{project.count}}</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="companyDetailShow"
      :destroy-on-close='true'
      width="90rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:33rem;"
      >
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div
            class="stats-chart-block"
            style="height:16rem;width:86.25rem;"
          >
            <div
              class="stats-close-btn"
              @click="companyDetailShow = false"
            />
            <div style="display:flex;align-items:center;">
              <div
                class="option-block2"
                style="min-width: 5rem;"
                :class="{ 'option-block-selected2': mode3 == '1' }"
                @click="() => switchCompanyDetail('1')"
              >按工人数排序</div>
              <div
                class="option-block2"
                style="min-width: 5rem;"
                :class="{ 'option-block-selected2': mode3 == '2' }"
                @click="() => switchCompanyDetail('2')"
              >按合作项目排序</div>
            </div>
            <div style="display:flex;margin-top:1rem">

              <div style="display:flex;justify-content:flex-end;margin-bottom:0.5rem;margin-right:1rem">
                <CustomSelect
                  :options="companyNameList"
                  :value="selectedCompany"
                  @input="companySelect($event, 'val1')"
                  style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
                />
              </div>

              <div style="display:flex;justify-content:flex-end;margin-bottom:0.5rem">
                <CustomSelect
                  :options="proCategoryList"
                  @input="proSelect"
                  style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold"
                />
              </div>
            </div>
            <div style="margin-top:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:20rem;text-align:center">共建方名称</div>
                <div style="width:5rem">专业类别</div>
                <div style="width:8rem">工人数</div>
                <div style="width:8rem">合作项目数</div>
                <div style="width:12rem">联系人</div>
                <div style="width:10rem">联系方式</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in companyDetailList"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:20rem;padding-left: 2rem">{{item.company_name}}</div>
                  <div style="width:5rem">
                    {{item.pro_category_name}}
                  </div>
                  <div style="width:8rem">{{item.worker_count}}</div>
                  <div
                    style="width:8rem;color:#4fb5da;cursor: pointer;"
                    @click="showCompanyDetailInfo(item.company_id)"
                  >{{item.project_count}}</div>
                  <div style="width:12rem;">{{item.contacts}}</div>
                  <div style="width:10rem;">{{item.contact_mobile}}</div>
                </div>
                <div
                  v-if="!(companyDetailList && companyDetailList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无共建方情况
                </div>
              </div>
              <el-pagination
                background
                layout="prev, pager, next"
                :current-page.sync="securityStarPage"
                :page-count="securityStarPages"
                @current-change="securityStarPageChange"
                style="text-align:center;margin-top:0.5rem"
              ></el-pagination>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="companyDetailInfoShow"
      :destroy-on-close='true'
      width="60rem"
      top="18rem"
    >
      <wrapper
        class="popup-bg"
        style="height:21rem;"
      >
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div
            class="stats-chart-block"
            style="height:15rem;width:86.25rem;"
          >
            <div
              class="stats-close-btn"
              @click="companyDetailInfoShow = false"
            />
            <div></div>
            <div style="margin-top:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:15rem;text-align:center">项目名称</div>
                <div style="width:12rem">合作时间</div>
                <div style="width:10rem">联系人/联系方式</div>
              </div>
              <div style="height:14rem;overflow:auto;">
                <div
                  v-for="(item, index) in companyDetailInfoList"
                  :key="item.project_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:15rem;padding-left: 1rem">{{item.project_name}}</div>
                  <div style="width:12rem">
                    {{item.date_range}}
                  </div>
                  <div style="width:10rem;">{{item.head_person+'/'+item.head_mobile}}</div>
                </div>
                <div
                  v-if="!(companyDetailInfoList && companyDetailInfoList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无共建方详情
                </div>
              </div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>

    
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow4"
      width="90rem"
      top="12rem"
    >
      <wrapper style="height:42rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow4=false"
        />
        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="width:10rem"></div>
            <div class="tabbar" style="margin:0">
              <div style="border: 1px solid #157EAB" :class="{ 'tabar-select': popupSelect3 == '1' }" @click="getAttendance3('1')">按季</div>
              <div style="border: 1px solid #157EAB" :class="{ 'tabar-select': popupSelect3 == '2' }" @click="getAttendance3('2')">按月</div>
              <div style="border: 1px solid #157EAB" :class="{ 'tabar-select': popupSelect3 == '3' }" @click="getAttendance3('3')">按周</div>
            </div>
            <div style="display:flex;justify-content:flex-end;" v-if="mapLevel==0">
              <CustomSelect :options="companyNameList" :value="selectedVal2" @input="companySelect($event, 'val2')" style="width:10rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;" />
            </div>
            <div v-else style="width:10rem"></div>
          </div>
          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="font-size:1rem;font-weight:600;margin:1rem 0 0 2rem">{{mode2 == '1'?'突击队情况详情':'零星用工情况详情'}}</div>
            <div style="height:15rem;padding-top:1rem">
              <v-chart :options="workerMoneysBarChart" @click="workerMoneysBarChartClick" autoresize />
            </div>
          </div>
          <div style="margin:0.5rem 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName3}}</div>
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:20rem;text-align:center">{{popupData3cols[0]}}</div>
              <div style="width:12rem;text-align:center">{{popupData3cols[1]}}</div>
              <div style="width:12rem;text-align:center">{{popupData3cols[2]}}</div>
            </div>
            <div style="height:10rem;overflow:auto;">
              <div
                v-for="(item, index) in popupData3"
                :key="item.person_id"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;cursor: pointer;"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:20rem;text-align:center">{{item.name}}</div>
                <div style="width:12rem;text-align:center">
                  {{item.total_count}}
                </div>
                <div style="width:12rem;text-align:center">{{item.rate}}</div>
              </div>
              <div
                v-if="!(popupData3 && popupData3.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="securityStarPage3"
              :page-count="securityStarPages3"
              @current-change="securityStarPageChange3"
              style="text-align:center;margin-top: 0.5rem;"
            ></el-pagination>
          </div>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow5"
      width="90rem"
      top="12rem"
    >
      <wrapper style="height:42rem;backgroundColor:#0e1328;position:relative">
        <div
            class="stats-close-btn"
            @click="popupShow5=false"
        />
        <div class="attendance">
          
          <div style="border:1px solid #112563;margin:1.5rem 1rem">
            <div style="font-size:1rem;font-weight:600;margin:1rem 0 0 2rem">上访情况详情</div>
            <div style="height:15rem;padding-top:1rem">
              <v-chart :options="workerAgePieDetailChart" @click="workerAgePieDetailChartClick" autoresize />
            </div>
          </div>
          <div style="margin:0.5rem 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
            <div style="text-align:center;font-size: 18px;margin-bottom: 1rem;">{{popupBottomName5}}</div>
            <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
              <div style="width:3rem;text-align:center">序号</div>
              <div style="width:20rem;">相关项目</div>
              <div style="width:5rem;text-align:center">上访人</div>
              <div style="width:8rem;text-align:center">上访时间</div>
              <div style="width:8rem;text-align:center">接待处理人</div>
              <div style="width:18rem;">详情</div>
            </div>
            <div style="height:18rem;overflow:auto">
              <div
                v-for="(item, index) in popupData5"
                :key="item.person_id"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;cursor: pointer;"
                :style="{ background: index%2 ? '#102855' : '#213a77'}"
              >
                <div style="width:3rem;text-align:center">{{index>8?index+1:'0'+(index+1)}}</div>
                <div style="width:20rem;"> {{item.project_name}} </div>
                <div style="width:5rem;text-align:center"> {{item.petition_person}} </div>
                <div style="width:8rem;text-align:center"> {{item.petition_time}} </div>
                <div style="width:8rem;text-align:center"> {{item.deal_person}} </div>
                <div style="width:18rem;">{{item.note}}</div>
              </div>
              <div
                v-if="!(popupData5 && popupData5.length)"
                style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
              >
                暂无检查数据
              </div>
            </div>
            
          </div>
        </div>
      </wrapper>
    </el-dialog>

  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import wrapper from "../../components/wrap";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation, project } from "../../api";
// import { attendancePieChartTemplate, colors } from './pieChartTemplates';
import { SimpleBarChartTemplate } from "./barChartTemplates";
import CustomSelect from "../../components/select";
import { applyUpdate, truncateStr } from "../../utils";

export default {
  name: "LaborRightBar",
  components: {
    "v-chart": ECharts,
    wrapper,
    CustomSelect: CustomSelect,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    orgOptions: Array,
    districtScale: Number,
    orgType:Number
  },
  data() {
    return {
      provinceWorkers: [],
      provinceWorkersMax: 1,
      workerTypePieChart: {},
      option: {
        color: ["#23cf9e", "#fdc81e"],
        grid: {
          contentLabel: true,
          left: '12%',
          bottom: "15%",
          top: "15%",
          right: "8%",
        },
        legend: {
          data: [],
          textStyle:{
            fontSize: 11,//字体大小
            color: '#ffffff'//字体颜色
          },
        },
        tooltip: {
          trigger: 'axis',
          // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
          formatter: function (params) {
            var result = ``;
            if(params&&params.length){
                result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + (params[0].data.value?params[0].data.value:params[0].data);
                if(params[1]){
                    result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                }
                
            }
            return result;
          }
        },
        xAxis: [
          {
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
              rotate: 35
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'category',
            data: []
          }
        ],
        yAxis: [
          {
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
            show: true,
            name: "人数：人"
          },
          {
            name: "工时：h",
            show: true,
            nameTextStyle: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            barWidth: 10,
            data: []
          },
          {
            name: '数量',
            type: 'line',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2
                }
              }
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                {offset: 1, color: 'rgba(27, 208, 128, 0)'}
              ])
            },
            symbolSize: 0,
            yAxisIndex: 1,
            data: [],
          }
        ]
      },
      workerAgePieChart: {},
      option2: {
        color: ["#23cf9e", "#fdc81e"],
        grid: {
          contentLabel: true,
          left: '12%',
          bottom: "15%",
          top: "15%",
          right: "8%",
        },
        legend: {
          data: [],
          textStyle:{
            fontSize: 11,//字体大小
            color: '#ffffff'//字体颜色
          },
        },
        tooltip: {
          trigger: 'axis',
          formatter: "{b}<br>{a0}: {c0}"
        },
        xAxis: [
          {
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
              rotate: 45
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'category',
            data: []
          }
        ],
        yAxis: [
          {
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
            show: true,
            name: "次数"
          }
        ],
        series: [
          {
            name: '上访次数',
            type: 'bar',
            barWidth: 10,
            data: []
          }
        ]
      },
      workerTypePieChartHighlightIndex: 0,
      workerAgePieChartHighlightIndex: 0,
      avgAttendanceTopLists: {},
      avgAttendanceTopListOption: "project_avg_h_top",
      mode: "1",
      mode2: "1",
      mode3: "1",
      companyDetailShow: false,
      companyDetailInfoShow: false,
      companyDetailList: [],
      companyDetailInfoList: [],
      securityStarPage: 1,
      securityStarPages: 1,
      companyNameList: [""],
      proCategoryList: ["全部专业类别"],
      companyIdList: [""],
      selectedCompany: '',
      proIdList: [""],
      index1: 0,
      index2: 0,

      selectedVal2:'全部分公司',
      popupShow4: false,
      popupSelect3: '1',
        workerMoneysBarChart: {},
        workerMoneysBarChartTemplate: {
          color: ["#23CF9E", "#14B0BB"],
          grid: {
            contentLabel: true,
            right: '5%',
            top: "13%",
            left: "5%",
            bottom: "15%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          tooltip: {
            trigger: 'axis',
            // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
            formatter: function (params) {
              var result = ``;
              if(params&&params.length){
                result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + (params[0].data.value?params[0].data.value:params[0].data);
                if(params[1]){
                  result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                }
              }
              return result
            }
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              name: "人数：人",
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true
            },
            {
              show: true,
              name: "工时：h",
              min: 0,
              // max: 100,
              interval: 25,
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // },
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
            }
          ],
          series: [
            {
              name: '工资总额',
              type: 'bar',
              barWidth: 10,
              data: []
            },
            {
              name: '代付率',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                  {offset: 1, color: 'rgba(27, 208, 128, 0)'}
                ])
              },
              symbolSize: 0,
              yAxisIndex: 1,
              data: [],
            }
          ]
        },
        org_id: '',
        popupBottomName3: '',
        popupSearchkeys3: [],
        popupXAxisData3: [],
        popupBarData: [],
        popupData3: [],
        securityStarPage3: 1,
        securityStarPages3: 1,
        dataIndex: -1,

      popupShow5: false,
      workerAgePieDetailChart:{},
      workerAgePieDetailChartTemplate:{
          color: ["#23CF9E", "#14B0BB"],
          grid: {
            contentLabel: true,
            right: '5%',
            top: "13%",
            left: "5%",
            bottom: "15%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          tooltip: {
            trigger: 'axis',
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              name: "次数：次",
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true
            },
            
          ],
          series: [
            {
              name: '次数',
              type: 'bar',
              barWidth: 10,
              data: []
            },
          ]
        },
      popupBottomName5: '',
      popupSearchkeys5: [],
      popupXAxisData5: [],
      popupBarData5: [],
      popupData5: [],
      popupData3cols:[],
      dataIndex5: -1,
      
      mapLevel: 0,
    };
  },
  watch: {
    orgOptions(newValue, oldValue) {
      if (newValue != oldValue) {
        this.companyNameList = newValue.map(item => truncateStr(item.org_name, 10));
        this.companyIdList = newValue.map(item => item.org_id);
      }
    },
    orgLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.getAll();
      }
    },
    districtLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
        this.getAll();
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.mapLevel = newValue;
      }
    },
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;

    this.companyNameList = this.orgOptions.map(item => truncateStr(item.org_name, 10));
    this.companyIdList = this.orgOptions.map(item => item.org_id);

    this.getAll();
  },
  methods: {
    showDetail(val){
      if(val==1){
      const idx = this.companyIdList.indexOf(this.orgLevel);
        this.selectedVal2 = this.companyNameList[idx];
        this.org_id = this.orgLevel;
        this.securityStarPage3=1;
        this.popupShow4 = true;
        this.dataIndex=-1;
        this.getFirstPopData()

      }else{
        this.popupShow5 = true;
        this.dataIndex5 = -1;
        this.getAttendance5();
      }
    },
    workerTypePieChartClick(params){
      const idx = this.companyIdList.indexOf(this.orgLevel);
        this.selectedVal2 = this.companyNameList[idx];
        this.org_id = this.orgLevel;
        this.dataIndex = params.dataIndex;
        this.securityStarPage3=1;
        this.popupShow4 = true;
        this.popupSelect3 =2;
        this.getFirstPopData()

    },
    workerAgePieChartClick(params){
        this.dataIndex5 = params.dataIndex;
        this.popupShow5 = true;
        this.getAttendance5();
    },
      getAttendance3(popupSelect){
        this.popupSelect3 = popupSelect;
        this.dataIndex=-1;
        this.securityStarPage3=1;
        this.getFirstPopData()
      },
      getFirstPopData(){
        nation.getLaborSporadicDateCollect(this.mode2,this.popupSelect3, this.org_id ? this.org_id:'',1,this.popupSelect3==2?12:6)
          .then(d => {
            
            if(this.dataIndex<0){
              this.dataIndex=d.search_keys.length-1
            }

            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.workerMoneysBarChartTemplate.legend.data = d.legend;
            this.workerMoneysBarChartTemplate.xAxis[0].data = d.xAxis_data;
            d.bar_series[this.dataIndex] = {
              value: d.bar_series[this.dataIndex],
              itemStyle: {
                color: '#FFD153'
              }
            };
            this.workerMoneysBarChartTemplate.series[0].data = d.bar_series;
            this.workerMoneysBarChartTemplate.series[0].name = d.legend[0];
            this.workerMoneysBarChartTemplate.series[1].data = d.line_series;
            this.workerMoneysBarChartTemplate.series[1].name = d.legend[1];
            this.workerMoneysBarChart = applyUpdate(chart, this.workerMoneysBarChartTemplate);

            this.popupBottomName3 = `${this.workerMoneysBarChartTemplate.xAxis[0].data[this.dataIndex]}`+(this.mode2 == '1'?'突击队情况':'零星用工情况');
            this.getLaborAttendanceDateOrgCountCollect(d.search_keys[this.dataIndex]);
            this.popupSearchkeys3 = d.search_keys;
            this.popupXAxisData3 = d.xAxis_data;
            this.popupBarData = d.bar_series;
          })

      },
      workerMoneysBarChartClick(params){
        // console.log(params);
        // console.log(params.dataIndex);
        let subData = this.popupBarData;
        this.workerMoneysBarChart.series[0].data = subData.map(function (item, index) {
          return index === params.dataIndex ? {
              value: Object.prototype.toString.call(item) === '[object Object]' ? item.value:item,
              itemStyle: {
                color: '#FFD153'
              }
            }:(Object.prototype.toString.call(item) === '[object Object]' ? item.value:item)
        });
        this.popupBottomName3 = `${this.popupXAxisData3[params.dataIndex]}`+(this.mode2 == '1'?'突击队情况':'零星用工情况');
        this.getLaborAttendanceDateOrgCountCollect(this.popupSearchkeys3[params.dataIndex]);
        this.dataIndex = params.dataIndex;
        this.securityStarPages3=1;
      },
      getLaborAttendanceDateOrgCountCollect(search_keys){
        // window.$orgLevel = this.org_id;
        nation.getLaborSporadicOrgProjectCollect(this.mode2,this.popupSelect3,this.securityStarPage3, 5,this.org_id?this.org_id:'',search_keys)
          .then(d => {
            // console.log(d);
            this.popupData3 = d.data;
            this.popupData3cols=d.cols;
            this.securityStarPages3 = Math.ceil(d.__count__ / 5);
          })
      },
      securityStarPageChange3(currPage) {
        // console.log(currPage);
        this.securityStarPage3 = currPage;
        this.getLaborAttendanceDateOrgCountCollect(this.popupSearchkeys3[this.dataIndex]);
      },

      getAttendance5(){
        
        nation.getLaborPetitionDateCollect()
          .then(d => {
            if(this.dataIndex5<0){
              this.dataIndex5=d.search_keys.length-1
            }
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.workerAgePieDetailChartTemplate.xAxis[0].data = d.xAxis_data;
            d.line_series[this.dataIndex5] = {
              value: d.line_series[this.dataIndex5],
              itemStyle: {
                color: '#FFD153'
              }
            };
            this.workerAgePieDetailChartTemplate.series[0].data = d.line_series;
            this.workerAgePieDetailChart = applyUpdate(chart, this.workerAgePieDetailChartTemplate);

            this.popupBottomName5 = `${this.workerAgePieDetailChartTemplate.xAxis[0].data[this.dataIndex5]}上访情况`;
            this.getworkerAgePieDetailTableCollect(d.search_keys[this.dataIndex5]);
            this.popupSearchkeys5 = d.search_keys;
            this.popupXAxisData5 = d.xAxis_data;
            this.popupBarData5 = d.line_series;
          })
      },
      workerAgePieDetailChartClick(params){
        // console.log(params);
        // console.log(params.dataIndex);
        let subData = this.popupBarData5;
        this.workerAgePieDetailChart.series[0].data = subData.map(function (item, index) {
          return index === params.dataIndex ? {
              value: Object.prototype.toString.call(item) === '[object Object]' ? item.value:item,
              itemStyle: {
                color: '#FFD153'
              }
            }:(Object.prototype.toString.call(item) === '[object Object]' ? item.value:item)
        });
        this.popupBottomName5 = `${this.popupXAxisData5[params.dataIndex]}上访情况`;
        this.getworkerAgePieDetailTableCollect(this.popupSearchkeys5[params.dataIndex]);
        this.dataIndex5 = params.dataIndex;
      },
      getworkerAgePieDetailTableCollect(search_keys){
        // window.$orgLevel = this.org_id;
        nation.getLaborPetitionDetailCollect(search_keys, 1, 100)
          .then(d => {
            // console.log(d);
            this.popupData5 = d;
          })
      },
    showCompanyDetail() {
      this.companyDetailShow = !this.companyDetailShow;
      const idx = this.companyIdList.indexOf(this.orgLevel);
      this.selectedCompany = this.companyNameList[idx];
      this.index1 = idx;
      this.securityStarPageChange(1);
      this.getSelectInfo();
    },
    showCompanyDetailInfo(company_id) {
      this.companyDetailInfoShow = !this.companyDetailInfoShow;
      project
        .getLaborCompanyProjectList(1, 10000, company_id)
        .then((d) => {
          // console.log(d, "--------------------");
          this.companyDetailInfoList = d;
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    securityStarPageChange(currPage) {
      project
        .getLaborCompanyList(
          currPage,
          10,
          this.mode3,
          this.companyIdList[this.index1],
          this.proIdList[this.index2]
        )
        .then((d) => {
          // console.log(d, "--------------------");
          this.companyDetailList = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    switchCompanyDetail(mode) {
      this.mode3 = mode;
      this.securityStarPage = 0;
      this.securityStarPageChange(1);
    },
    companySelect(option, val) {
      const idx = this.companyNameList.indexOf(option);
      this.org_id = this.companyIdList[idx];
      
        if(val === 'val1'){

        this.selectedCompany = option;
        this.securityStarPage = 0;
        this.index1 = this.companyNameList.indexOf(option);
        this.securityStarPageChange(1);
        }else if(val === 'val2'){
          // 55555
          this.selectedVal2 = option; 
          this.getFirstPopData();
        this.securityStarPage3=1;

        }
    },
    proSelect(option) {
       this.securityStarPage = 0;
      this.index2 = this.proCategoryList.indexOf(option);
      this.securityStarPageChange(1);
    },
    getSelectInfo() {
      project
        .getGeneralParamsSelect(18)
        .then((d) => {
          this.proCategoryList = ["全部专业类别"].concat(
            d.map((item) => item.value)
          );
          this.proIdList = [""].concat(d.map((item) => item.innerid));
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    getAll() {
      this.updateCompanyAttendanceChart("");

      this.getList(this.mode);

      nation.getLaborMonth12PetitionCollect(2).then((d) => {
        // console.log(d);
        this.companyWorkersData = d;
        this.updateCompanyWorkersChart(d);
      });

      // nation
      //   .getLaborProvinceWorkers()
      //   .then((d) => {
      //     this.provinceWorkersMax =
      //       Math.ceil(
      //         Math.max.apply(
      //           null,
      //           d.map((x) => x.pcount)
      //         ) / 20
      //       ) * 20;
      //     this.provinceWorkers = d;
      //   })
      //   // eslint-disable-next-line
      //   .catch((e) => console.error(e.msg || e.message));
    },
    switchworkerTypePieChartOption(mode) {
      this.mode2 = mode;
      this.updateCompanyAttendanceChart(this.mode2);
    },
    switchAvgAttendanceTopListOption(mode) {
      this.mode = mode;
      this.mode3 = mode;
      this.getList(mode);
    },
    getList(mode) {
      nation
        .getLaborCompanyWorkerProjectInfo(mode)
        .then((d) => {
          // console.log(d);
          this.avgAttendanceTopLists = d;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    updateCompanyAttendanceChart(mode) {
      nation
        .getLaborMonth12SporadicCollect(mode)
        .then((d) => {
          // console.log(d);
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option.legend.data = d.legend;
          this.option.xAxis[0].data = d.xAxis_data;
          this.option.series[0].name = d.legend[0];
          this.option.series[0].data = d.bar_series;
          this.option.series[1].data = d.line_series;
          this.option.series[1].name = d.legend[1];
          // chart.yAxis[0].max = Math.max.apply(null, d.map(x => Math.round(x.people_count / x.attendance_rate * 100)))
          // chart.yAxis[1].position = 'left';
          // chart.yAxis[1].axisLabel.formatter = SimpleBarChartTemplate.yAxis[1].axisLabel.formatter;
          this.workerTypePieChart = applyUpdate(chart, this.option);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    updateCompanyWorkersChart(data) {
      const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
      this.option2.xAxis[0].data = data.xAxis_data;
      this.option2.series[0].data = data.series;
      // chart.series = [chart.series[0]];
      // chart.yAxis = [chart.yAxis[0]];
      this.workerAgePieChart = applyUpdate(chart, this.option2);
    },
    workerTypeMouseOver(params) {
      if (params.dataIndex != this.workerTypePieChartHighlightIndex) {
        this.$refs.workerType.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.workerTypePieChartHighlightIndex,
        });
        this.workerTypePieChartHighlightIndex = params.dataIndex;
      }
    },
    workerTypeMouseOut() {
      this.$refs.workerType.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.workerTypePieChartHighlightIndex,
      });
    },
    workerAgeMouseOver(params) {
      if (params.dataIndex != this.workerAgePieChartHighlightIndex) {
        this.$refs.workerAge.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.workerAgePieChartHighlightIndex,
        });
        this.workerAgePieChartHighlightIndex = params.dataIndex;
      }
    },
    workerAgeMouseOut() {
      this.$refs.workerAge.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.workerAgePieChartHighlightIndex,
      });
    },
  },
};
</script>

<style scoped>
.option-block2 {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3.4rem;
  border: 1px solid #1b8ebd;
  text-align: center;
  cursor: pointer;
}
.option-block-selected2 {
  background-color: #4191e5;
  color: #fff;
}
</style>

<style>
.stats-layer .custom-select .items {
  max-height: 20rem !important;
  overflow: auto !important;
}
</style>