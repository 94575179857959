const M = require('maptalks');
import { ThreeLayer } from 'maptalks.three';
import { HeatLayer } from 'maptalks.heatmap';
import { loadScene, setHeatmapData3D } from './scene_loader';
import { loadFloors } from './floors_loader';
import getConfig from './projects/index';
import echarts from 'echarts';
import { pickHex, calcRotationAngle } from './utils.js';

require('./blinkmarker.js')(M, convertPoint);
const paper = require('paper');
const Path = paper.Path;

const blinkColorMap = {
    //'_': '#1fc86b',
    '1': '#e94c50',
    //'2': '#e9bb1e',
    '3': '#dc282b',
    //'5': '#e9bb1e',
    //'9': '#f09022',
    //'12': '#e9bb1e',
};

const defaultMapWidth = 2427;
const defaultMapHeight = 1575;

const dataCache = {};
window.dataCache = dataCache;
const defaultDataItem = {
    map: null,
    ws: null,
    default_build_innerid: '',
    imageLayer: null,
    sceneLayer: null,
    markersLayer: null,
    heatmapLayer: null,
    heatmapAreas: [],
    heatmapInfoWindow: null,
    electricBoxLayer: null,
    infoWindow: null,
    marker_obj_list: [],
    ta: null,
    mapUrl: null,
    mapWidth: 0,
    mapHeight: 0,
    hideWorkerPopupButtons: null,
    hideBldgDetails: null,
    clickInInfoWindow: false,
    buildingData: {},
};
const random_list = [];

class InfoWindow3D extends M.ui.InfoWindow {
  _getViewPoint() {
    return this.options.getViewPoint();
  }
}

function getDataItem(mapid) {
    if (!dataCache[mapid]) {
        dataCache[mapid] = Object.assign({}, defaultDataItem);
    }
    return dataCache[mapid];
}

// Convert point from Raycasting ratio coords to Maptalks viewpoint.
export function convertRaycastRatio(mapid, coords) {
    // First convert Raycaster ratios to container point
    const wrapperDiv = document.getElementById(mapid);
    const canvasElem = wrapperDiv.getElementsByTagName('canvas')[0];
    const x = canvasElem.offsetWidth * (coords[0] + 1) / 2;
    const y = canvasElem.offsetHeight * (1 - (coords[1] + 1) / 2);

    const dataItem = getDataItem(mapid);
    return dataItem.map.containerPointToViewPoint(new M.Point(x, y));
}

// Convert point from Bg image pixel coords to Maptalks coords.
export function convertPoint(coords, mapData) {
    var x = coords[0];
    var y = coords[1];

    var newX = (y - Math.ceil(mapData.mapWidth / 2)) / mapData.mapWidth * 10000 * mapData.extentScaling;
    var newY = (x - Math.ceil(mapData.mapHeight / 2)) / mapData.mapHeight * 10000 * mapData.mapHeight / mapData.mapWidth * mapData.extentScaling;
    return [newX, newY];
}

// Convert point from Maptalks coords to Bg image pixel coords.
export function convertPointInverse(coords, mapData) {
    var newX = coords[0];
    var newY = coords[1];

    var x = newY * mapData.mapHeight / 10000 * mapData.mapWidth / mapData.mapHeight / mapData.extentScaling + Math.ceil(mapData.mapHeight / 2);
    var y = newX * mapData.mapWidth / 10000 / mapData.extentScaling + Math.ceil(mapData.mapWidth / 2);
    return [x, y];
}

window.setClickInInfoWindow = (mapid, bldgName) => {
    const dataItem = getDataItem(mapid);
    dataItem.clickInInfoWindow = true;
    if (bldgName) {
        window.updateInfoBoxContent(mapid, bldgName);
    }
};

export function getMapData(mapid) {
    const dataItem = getDataItem(mapid);
    return {
        mapWidth: dataItem.mapWidth || defaultMapWidth,
        mapHeight: dataItem.mapHeight || defaultMapHeight,
        extentScaling: dataItem.extentScaling || 1,
    };
}

window.showWorkerInfo = function(mapid, worker_id, ptype) {
    const dataItem = getDataItem(mapid);
    if (worker_id) {
        dataItem.ta.workerInfoId = worker_id;
        dataItem.ta.workerInfoPType = ptype;
        dataItem.ta.workerInfoShow = true;
    }
}

window.showPathClick = function(mapid, worker_id, worker_name) {
    const dataItem = getDataItem(mapid);
    if (worker_id) {
        dataItem.ta.pathWorkerId = worker_id;
        dataItem.ta.pathWorkerName = worker_name;
        dataItem.ta.pathDate = undefined;
        dataItem.ta.pathShow = true;
    }
}

window.patrolClick = function(mapid, type, dataId) {
    const dataItem = getDataItem(mapid);
    dataItem.ta.showPatrolDetail(type, dataId);
}

window.callBackClick = function(mapid, that, status_id, worker_name) {
    const dataItem = getDataItem(mapid);
    dataItem.ta.callBackFunc = function() {
        if (status_id != 7 && status_id != 8) {
            that.disabled = true;
            that.innerHTML = "发送中";
            that.style.backgroundColor = "#b4bed4";

            let worker_info = that.getAttribute('data-id');
            worker_info = worker_info ? JSON.parse(worker_info) : {};
            console.log(worker_info)
            //召回单个工人
            if ((worker_info.recalled == 11 || worker_info.recalled == 8) && worker_info.hat_id && worker_info.ctrl_mac && worker_info.ch && dataItem.ws) {
                dataItem.ws.send(JSON.stringify({
                    action: 'recall',
                    values: { hat_id: worker_info.hat_id, ctrl_mac: worker_info.ctrl_mac, ch: worker_info.ch }
                }))
            } else {
                that.innerHTML = "召回";
                that.disabled = false;
                that.style.backgroundColor = "#5186f5";
            }
        }
    };
    dataItem.ta.callBackPerson = worker_name;
    dataItem.ta.showCallBackDialog = true;
}

window.hideMe = function(mapid) {
    const dataItem = getDataItem(mapid);
    if (dataItem.infoWindow) {
        dataItem.infoWindow.hide();
        dataItem.infoWindow = null;
    }
};

window.revealBuilding = function(mapid, bldgName) {
    const dataItem = getDataItem(mapid);
    dataItem.ta.sceneToolbox.revealBuilding(bldgName);
};

window.renderWorkerAttendanceChart = (mapid, bldgName) => {
    let chartDom = document.querySelector(`#workerAttendanceChart_${bldgName}`);
    if (!chartDom) return;

    const workerAttendanceChartOptions = {
        grid: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            containLabel: false
        },
        legend: {
            orient: 'vertical',
            left: '62%',  //图例距离左的距离
            height: '80%',
            y: 'center',  //图例上下居中
            symbol: 'rect',
            itemWidth: 12,
            itemHeight: 12,
            itemGap: 15,
            formatter: null,
            textStyle: {
                rich: {
                    a: {
                        color: '#878b94',
                        fontSize: 12,
                    },
                    b: {
                        color: '#ffffff',
                        fontSize: 12,
                    }
                }
            },
            data: []
        },
        color: ['rgb(203,155,255)', 'rgb(149,162,255)', 'rgb(58,186,255)', 'rgb(119,168,249)', 'rgb(235,161,159)'],
        series: [{
            type: 'pie',
            radius: ['40%', '60%'],
            //center: ['46%', '60%'],
            center: ['30%', '53%'], //图的位置，距离左跟上的位置
            data: [],
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                fontWeight: 'bold',
                formatter: function (params) {
                  return `${params.value} (${Math.round(params.percent)}%)\n\n${params.name}`;
                },
                align: 'center'
              },
            }
        }]
    };

    const dataItem = getDataItem(mapid);
    const d = dataItem.buildingData[bldgName].workerAttendanceData;
    let workerAttendanceChartRef = echarts.init(chartDom);

    const kk = [];
    const legent = [];
    const workerTypeMap = {};
    let maxTypeLen = 0;
    d.forEach(a => {
      kk.push({name:a._id,value:a.count})
      legent.push(a._id)
      workerTypeMap[a._id] = a.count;
      if (a._id.length > maxTypeLen) {
        maxTypeLen = a._id.length;
      }
    });
    workerAttendanceChartOptions.series[0].data = kk
    workerAttendanceChartOptions.legend.data = legent
    workerAttendanceChartOptions.legend.formatter = (name) => {
      let fillStr = '';
      for (let i = 0; i < maxTypeLen + 1 - name.length; i++) {
        fillStr += '\u3000';
      }
      return `{a|${name}}` + fillStr + `{b|${workerTypeMap[name]}}`;
    };

    workerAttendanceChartRef.on('mouseover', (params) => {
        if (params.dataIndex != dataItem.buildingData[bldgName].workerAttendanceChartHighlightIndex) {
          workerAttendanceChartRef.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: dataItem.buildingData[bldgName].workerAttendanceChartHighlightIndex
          });
          dataItem.buildingData[bldgName].workerAttendanceChartHighlightIndex = params.dataIndex;
        }
    });

    workerAttendanceChartRef.on('mouseout', () => {
        workerAttendanceChartRef.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: dataItem.buildingData[bldgName].workerAttendanceChartHighlightIndex
        });
    });

    window.setTimeout(
      () =>
        workerAttendanceChartRef &&
        workerAttendanceChartRef.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: dataItem.buildingData[bldgName].workerAttendanceChartHighlightIndex,
        }),
      1000
    );

    workerAttendanceChartRef.setOption(workerAttendanceChartOptions);
};

window.renderWorkerCountChart = (mapid, bldgName) => {
    function normalize_floor_name(floor_name) {
      // Normalize floor name for sort
      const floor = parseInt(floor_name.slice(0, -1));
      if (isNaN(floor)) {
        return floor_name;
      } else {
        return Array(Math.max(0, 3 - ('' + floor).length + 1)).join('0') + floor_name;
      }
    }

    let chartDom = document.querySelector(`#workerCountChart_${bldgName}`);
    if (!chartDom) return;

    const workerCountChartOptions = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: '{b} <br/>{c}人'
      },
      grid: {
        left: 40,
        right: 0,
        top: 20,
        bottom: 50,
        containLabel: false
      },
      yAxis: {
        type: 'value',
        show: true,
        minInterval: 1,
        max: value => value.max * 1.05,
        axisLabel: {
          color: '#fff',
          fontSize: 12,
          showMaxLabel: false
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false,
        },
        axisLine: { show: true, lineStyle: { color: '#36445f', width: 2 } }
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          color: '#fff',
          fontSize: 12,
          interval: 0,
          rotate: 60,
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false,
        },
        axisLine: { show: true, lineStyle: { color: '#36445f', width: 2 } }
      },
      series: [
        {
          name: '',
          type: 'bar',
          barMaxWidth: 14,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: '#51baf5' },
                { offset: 1, color: '#5173f5' },
              ]
            ),
          },
          label: {
            normal: {
              show: false,
              position: 'top',
              color: '#eaa24e'
            }
          },
          data: []
        },
      ]
    };

    const dataItem = getDataItem(mapid);
    const d = dataItem.buildingData[bldgName].workerCountData;

    d.forEach(a => a.floor = normalize_floor_name(a.floor_name));
    d.sort((a, b) => a.floor.localeCompare(b.floor));
    workerCountChartOptions.xAxis.data = d.map(a => a.floor_name);
    workerCountChartOptions.series[0].data = d.map(a => a.worker_count);

    let chartRef = echarts.init(chartDom);
    chartRef.setOption(workerCountChartOptions);
};

window.switchInfoBoxContent = function(mapid, bldgName) {
    const dataItem = getDataItem(mapid);
    if (dataItem.buildingData[bldgName].infoWindow.options.width == 140) {
        dataItem.buildingData[bldgName].infoWindow.setOptions({
            width: 620,
        });
        dataItem.buildingData[bldgName].infoWindow.setContent(dataItem.buildingData[bldgName].longHtml);
    } else {
        dataItem.buildingData[bldgName].infoWindow.setOptions({
            width: 140,
        });
        dataItem.buildingData[bldgName].infoWindow.setContent(dataItem.buildingData[bldgName].shortHtml);
    }
};

window.updateInfoBoxContent = function(mapid, bldgName) {
    const dataItem = getDataItem(mapid);
    if (dataItem.buildingData[bldgName].infoWindow.options.width == 140) {
        dataItem.buildingData[bldgName].infoWindow.setContent(dataItem.buildingData[bldgName].shortHtml);
    } else {
        dataItem.buildingData[bldgName].infoWindow.setContent(dataItem.buildingData[bldgName].longHtml);
    }
};

window.updateBuildingCharts = function(mapid, bldg) {
    const dataItem = getDataItem(mapid);

    window.buildingAPI.getdWorkTypeForBuild(bldg.build_id)
        .then(d => {
            dataItem.buildingData[bldg.build_name].workerAttendanceData = d;
            window.renderWorkerAttendanceChart(mapid, bldg.build_name);
        })
        .catch(e => console.error(e.msg || e.toString()));

    window.buildingAPI.getFloorWorkerCount(bldg.build_id)
        .then(d => {
            dataItem.buildingData[bldg.build_name].workerCountData = d;
            window.renderWorkerCountChart(mapid, bldg.build_name);
        })
    .catch(e => console.error(e.msg || e.toString()));
};

export function load3DScene(mapid, sceneData, sceneConfig, callback) {
    const dataItem = getDataItem(mapid);
    var modelLoaded = false;

    // the ThreeLayer to draw buildings
    var threeLayer = new ThreeLayer('t', {
        doubleBuffer: true,
        zIndex: 10000,
        forceRenderOnMoving: true,
        forceRenderOnZooming: true,
        forceRenderOnRotating: true
    });

    threeLayer.prepareToDraw = function (gl, scene) {
        dataItem.ta.sceneToolbox = loadScene(mapid, threeLayer, scene, sceneData, sceneConfig);
        dataItem.ta.sceneToolbox.resetScene = function() {
            dataItem.map.setCenter([0, 0]);
            if (window.beaconMode) {
                dataItem.map.setPitch(0);
                dataItem.map.setBearing(0);
            } else {
                dataItem.map.setPitch(60);
                dataItem.map.setBearing(-40);
            }
            dataItem.map.setZoom(2);
        };

        modelLoaded = true;
        threeLayer.renderScene();
        threeLayer.config('animation', true);

        callback && callback();
    };

    threeLayer.draw = function () {
        if (modelLoaded) {
            this.renderScene();
        }
    }

    threeLayer.drawOnInteracting = function () {
        if (modelLoaded) {
            this.renderScene();
        }
    }

    threeLayer.addTo(dataItem.map);
    return threeLayer;
}

export function loadBuildingFloors(mapid, workerPosition, floorData) {
    var modelLoaded = false;

    // the ThreeLayer to draw buildings
    var threeLayer = new ThreeLayer(floorData.buildingId, {
        centerForDistance: [0, 0],
        doubleBuffer: true,
        zIndex: 10000,
        forceRenderOnMoving: true,
        forceRenderOnZooming: true,
        forceRenderOnRotating: true
    });

    threeLayer.prepareToDraw = function (gl, scene) {
        loadFloors(threeLayer, scene, workerPosition, floorData);

        modelLoaded = true;
        threeLayer.renderScene();
        threeLayer.config('animation', true);
    };

    threeLayer.draw = function () {
        if (modelLoaded) {
            this.renderScene();
        }
    }

    threeLayer.drawOnInteracting = function () {
        if (modelLoaded) {
            this.renderScene();
        }
    }

    const dataItem = getDataItem(mapid);
    threeLayer.addTo(dataItem.map);
}

export function showHideLayer(mapid, layerKey, showOrHide) {
    const dataItem = getDataItem(mapid);
    if (dataItem[layerKey]) {
        if (showOrHide) {
            dataItem[layerKey].show();
        } else {
            dataItem[layerKey].hide();
        }
    }
}

/*
function scaleExtent(extent, scaleX, scaleY) {
    extent.xmax *= scaleX;
    extent.xmin *= scaleX;
    extent.ymax *= scaleY;
    extent.ymin *= scaleY;
    return extent;
}
*/

export function init_mapid(mapid, default_img, build_innerid, t) {
    const dataItem = getDataItem(mapid);
    dataItem.ta = t;
    dataItem.default_build_innerid = build_innerid;
    dataItem.mapUrl = default_img;
}

/**
 * 初始化leaflet地图
 * @param {*} mapid
 * @param {*} default_img 默认地图
 */
export function init(mapid, default_img, build_innerid, t, callback, options) {
    const dataItem = getDataItem(mapid);
    dataItem.ta = t;
    dataItem.default_build_innerid = build_innerid;
    dataItem.mapUrl = default_img;

    if (!options) options = {};
    dataItem.hideWorkerPopupButtons = !!options.hideWorkerPopupButtons;
    dataItem.hideBldgDetails = !!options.hideBldgDetails;
    if (options.enableImage == null) {
        options.enableImage = true;
    }
    if (options.enableMarkers == null) {
        options.enableMarkers = true;
    }

    dataItem.mapWidth = options.mapWidth;
    dataItem.mapHeight = options.mapHeight;

    const mapData = getMapData(mapid);
    let extentScaling;
    if (mapData.mapWidth * 0.75 > mapData.mapHeight) {
        extentScaling = 1;
    } else {
        extentScaling = mapData.mapWidth / mapData.mapHeight * 0.75;
    }
    dataItem.extentScaling = extentScaling;

    const initialZoom = options.initialZoom || 3;
    const mapConfig = {
        spatialReference: {
            projection: 'identity',
            resolutions: [64, 32, 16, 8, 4, 2, 1],
            fullExtent: {
                'top': 10000,
                'left': -10000,
                'bottom': -10000,
                'right': 10000
            }
        },
        center: [0, 0],
        //pitch : 60,
        //bearing : -40,
        dragRotate: false,
        dragPitch: false,
        minZoom: initialZoom - 1,
        maxZoom: initialZoom + 3,
        zoom: initialZoom,
        zoomControl: false,
        attribution: false
    };

    if (options.enable3D) {
        dataItem.isModelLoading = true;
        if (!window.beaconMode) {
            mapConfig.pitch = 60;
            mapConfig.maxPitch = 75;
            mapConfig.maxVisualPitch = 70;
            mapConfig.bearing = -40;
            mapConfig.doubleClickZoom = false;
        } else {
            mapConfig.pitch = 0;
            mapConfig.bearing = 0;
            mapConfig.centerCross = true;
        }
        mapConfig.dragRotate = true;
        mapConfig.dragPitch = true;
        mapConfig.minZoom = 1;
        mapConfig.maxZoom = 7;
        mapConfig.zoom = 2;
    }

    if (dataItem.map) {
        if (dataItem.sceneLayer) {
            dataItem.sceneLayer.remove();
            dataItem.sceneLayer = null;
        }
        dataItem.map.remove();
    }
    dataItem.map = new M.Map(mapid, mapConfig);
    dataItem.ta.compassRotation = -dataItem.map.getView().bearing;
    //dataItem.map.setMaxExtent(scaleExtent(dataItem.map.getExtent(), 1.2, 1.6));

    //map.setZoom(map.getZoom() + 1, { animation: false });
    dataItem.map.on('moving', () => {
        if (!dataItem.isModelLoading) {
            const prjCenter = dataItem.map._getPrjCenter();
            if (dataItem.map._verifyExtent(prjCenter)) {
                dataItem.map._originCenter = prjCenter;
            }
        } else {
            dataItem.map.setCenter([0, 0]);
            setTimeout(() => {
                dataItem.map.setCenter([0, 0]);
            }, 100);
        }
    });

    let justClicked = false;
    if (options.enable3D) {
        dataItem.map.$animateTo = dataItem.map.animateTo;
        dataItem.map.animateTo = function() {
            if (!justClicked) {
                dataItem.map.$animateTo.apply(dataItem.map, arguments);
            }
        }
    }

    if (options.enableImage) {
        dataItem.imageLayer = new M.ImageLayer('bg', []);
        dataItem.map.addLayer(dataItem.imageLayer);
        setImageLayerUrl(mapid, dataItem.imageLayer, dataItem.mapUrl);
    }

    if (options.enableMarkers) {
        dataItem.markersLayer = new M.VectorLayer('markers', [], {
            enableAltitude: true,
            zIndex: 20000
        });
        dataItem.map.addLayer(dataItem.markersLayer);
    }

    if (options.enableHeatmap) {
        dataItem.heatmapLayer = new HeatLayer('heat', [], {
            radius: 12,
            blur: 15
        });
        dataItem.map.addLayer(dataItem.heatmapLayer);

        dataItem.heatmapInfoWindow = new M.ui.InfoWindow({
            width: 140,
            dx: 3,
            dy: 0,
            content: '',
            autoOpenOn: null,
            autoCloseOn: 'click',
        });
        dataItem.heatmapInfoWindow.on('showstart', function(obj) {
            dataItem.infoWindow = obj.target;
        });
        dataItem.heatmapInfoWindow.addTo(dataItem.map);
    }

    if (options.enableElectricBox) {
        dataItem.electricBoxLayer = new M.VectorLayer('electricBoxes', [], {
            enableAltitude: true,
            zIndex: 20000
        });
        dataItem.map.addLayer(dataItem.electricBoxLayer);
    }

    if (options.enable3D) {
        dataItem.clickInInfoWindow = false;
        options.sceneData.dataItem = dataItem;

        let getFloorClickPt;
        const floorInfoWindow = new InfoWindow3D({
            width: 130,
            dx: 0,
            dy: 0,
            content: '',
            single: false,
            autoCloseOn: 'click contextmenu',
            autoOpenOn: null,
            getViewPoint: function() {
                let pt;
                if (getFloorClickPt) {
                    pt = getFloorClickPt();
                    if (pt.z > 1 || pt.z < 0) {
                        pt = null;
                    }
                }
                if (!pt) {
                    pt = {
                        x: -100,
                        y: -100
                    };
                }
                return convertRaycastRatio(mapid, [pt.x, pt.y]);
            }
        });
        floorInfoWindow.on('showstart', function(obj) {
            dataItem.infoWindow = obj.target;
        });
        floorInfoWindow.addTo(dataItem.map);
        options.sceneData.floorInfoWindow = floorInfoWindow;

        let getUserClickPt;
        const userInfoWindow = new InfoWindow3D({
            width: 140,
            dx: 0,
            dy: 0,
            content: '',
            single: false,
            autoCloseOn: 'click contextmenu',
            autoOpenOn: null,
            getViewPoint: function() {
                let pt;
                if (getUserClickPt) {
                    pt = getUserClickPt();
                    if (pt.z > 1 || pt.z < 0) {
                        pt = null;
                    }
                }
                if (!pt) {
                    pt = {
                        x: -100,
                        y: -100
                    };
                }
                return convertRaycastRatio(mapid, [pt.x, pt.y]);
            }
        });
        userInfoWindow.on('showstart', function(obj) {
            dataItem.infoWindow = obj.target;
        });
        userInfoWindow.addTo(dataItem.map);
        options.sceneData.userInfoWindow = userInfoWindow;

        options.sceneData.showFloorInfo = function(build_name, floor_name, getPt) {
            // First get building id
            const building = options.sceneData.getBuildingList().find(bldg => bldg.build_name == build_name);
            if (building) {
                const buildingId = building.build_id;
                window.buildingAPI.getWorkTypePerFloor(buildingId)
                    .then(d => {
                        const floor = d.find(x => x.floor_name == floor_name) || { worker_list: [] };
                        let workerTypeList = '';
                        for (const item of floor.worker_list) {
                            workerTypeList += `<div style="display:flex"><div style="width:70px;text-align:right;">${item.worker_type}</div><div style="width: 60px">${item.count}人</div></div>`;
                        }
                        let html = `<div style="opacity:1;position:relative;" onclick="setClickInInfoWindow('${mapid}')">
                            <div style="position:relative;background-color:#122040;border:1px solid #526eb2"><div style="margin:6px 0">
                            <div style="font-size:10px;line-height:18px;text-align:center;">
                            <div style="display:flex"><div style="width:70px;text-align:right;">当前楼层</div><div style="width: 60px">${floor_name}</div></div>
                            <div style="display:flex"><div style="width:70px;text-align:right;">工人人数</div><div style="width: 60px">${floor.worker_list.reduce((acc, val) => acc + val.count, 0)}人</div></div>
                            ${workerTypeList}
                            </div></div><div />
                            </div><div class="leaflet-popup-tip-container"><div class="leaflet-popup-tip" style="background-color:#122040;border:1px solid #526eb2"></div></div>
                            <a style="position:absolute;top:0;right:0;padding:4px 4px 0 0;border:none;text-align:center;width:18px;height:14px;font:16px/14px Tahoma,Verdana,sans-serif;color:#c3c3c3;text-decoration:none;font-weight:700" onclick="hideMe('${mapid}')">×</a></div>`;

                        getFloorClickPt = getPt;
                        floorInfoWindow.setContent(html);
                        floorInfoWindow.show();
                    })
                    .catch(e => console.error(e.msg || e.message));
            }
        };

        options.sceneData.showUserInfo = function(html, getPt) {
            setTimeout(() => {
                getUserClickPt = getPt;
                userInfoWindow.setContent(html);
                userInfoWindow.show();
            }, 0);
        };

        const configData = {
            buildingFloorHeights: []
        };
        window.projectAPI.getBuildingFloorHeights(options.sceneData.projectId)
            .then(buildings => {
                configData.buildingFloorHeights = buildings;

                let sceneConfig = getConfig(options.sceneData.projectId, configData);
                if (Array.isArray(sceneConfig)) {
                    sceneConfig = sceneConfig[options.configIdx];
                }

                dataItem.sceneLayer = load3DScene(mapid, options.sceneData, sceneConfig, function() {
                    // Render the markers at the top of each building
                    function generateBldgInfoHtml(bldg) {
                        const floorHeightsEntry = buildings.find(item => item.build_id == bldg.build_id);
                        const doneFloorName = floorHeightsEntry.floor_info[floorHeightsEntry.now_floor_i].floor_name;

                        const bldgDetailsSwitch = dataItem.hideBldgDetails ? '' : `<a style="position:absolute;top:0;left:120px;padding:4px 4px 0 0;border:none;text-align:center;width:18px;height:14px;font:14px Tahoma,Verdana,sans-serif;color:#51bbf5;text-decoration:none;font-weight:700" onclick="switchInfoBoxContent('${mapid}', '${bldg.build_name}');">▼</a>`;
                        const html = `<div style="opacity:1;position:relative;" onclick="setClickInInfoWindow('${mapid}', '${bldg.build_name}')">
                            <div style="position:relative;background-color:#122040;border:1px solid #526eb2"><div style="margin:6px 15px">
                            <div style="font-size:10px;line-height:22px;text-align:center;">
                            <div style="font-size:16px;font-weight:bold;text-decoration:underline;color:#51bbf5;margin:4px 0;"><a onclick="revealBuilding('${mapid}', '${bldg.build_name}');">${bldg.build_name}</a></div>
                            <div style="display:flex"><div style="width:60px;text-align:left;">总楼层</div><div style="width:50px;text-align:right;">${bldg.map_count}层</div></div>
                            <div style="display:flex"><div style="width:60px;text-align:left;">已建楼层</div><div style="width:50px;text-align:right;">${doneFloorName}</div></div>
                            <div style="display:flex"><div style="width:60px;text-align:left;">当前人数</div><div id="buildingWorkerCount_${bldg.build_name}" style="width:50px;text-align:right;">${bldg.worker_count}</div></div>
                            </div></div><div />
                            </div><div class="leaflet-popup-tip-container"><div class="leaflet-popup-tip" style="background-color:#122040;border:1px solid #526eb2"></div></div>
                            ${bldgDetailsSwitch}
                            </div>`;

                        const longHtml = `<div style="opacity:1;position:relative;" onclick="setClickInInfoWindow('${mapid}', '${bldg.build_name}')">
                            <div style="position:relative;background-color:#122040;border:1px solid #526eb2">
                                <div style="margin:6px 15px;display:flex;height:200px;">
                                    <div style="font-size:10px;line-height:22px;text-align:center;margin-right:20px">
                                        <div style="font-size:16px;font-weight:bold;text-decoration:underline;color:#51bbf5;margin:4px 0;"><a onclick="revealBuilding('${mapid}', '${bldg.build_name}');">${bldg.build_name}</a></div>
                                        <div style="display:flex"><div style="width:60px;text-align:left;">总楼层</div><div style="width:50px;text-align:right;">${bldg.map_count}层</div></div>
                                        <div style="display:flex"><div style="width:60px;text-align:left;">已建楼层</div><div style="width:50px;text-align:right;">${doneFloorName}</div></div>
                                        <div style="display:flex"><div style="width:60px;text-align:left;">当前人数</div><div id="buildingWorkerCount_${bldg.build_name}" style="width:50px;text-align:right;">${bldg.worker_count}</div></div>
                                    </div>
                                    <div style="text-align:center;">
                                        <div style="font-size:16px;font-weight:bold;margin-top:4px;">各工种出勤情况</div>
                                        <div id="workerAttendanceChart_${bldg.build_name}" style="width: 260px; height: 200px"></div>
                                    </div>
                                    <div style="text-align:center;">
                                        <div style="font-size:16px;font-weight:bold;margin-top:4px;">各楼层总人数</div>
                                        <div id="workerCountChart_${bldg.build_name}" style="width: 200px; height: 200px"></div>
                                    </div>
                                </div>
                                <div />
                            </div><div class="leaflet-popup-tip-container"><div class="leaflet-popup-tip" style="background-color:#122040;border:1px solid #526eb2"></div></div>
                            <a style="position:absolute;top:0;left:120px;padding:4px 4px 0 0;border:none;text-align:center;width:18px;height:14px;font:14px Tahoma,Verdana,sans-serif;color:#51bbf5;text-decoration:none;font-weight:700" onclick="switchInfoBoxContent('${mapid}', '${bldg.build_name}');">▲</a>
                            </div>`;

                        return [html, longHtml];
                    }

                    if (!window.beaconMode) {
                        options.sceneData.getBuildingList().forEach(bldg => {
                            if (!sceneConfig.buildingTopCenters[bldg.build_name]) return;

                            const htmls = generateBldgInfoHtml(bldg);
                            dataItem.buildingData[bldg.build_name] = {
                                infoWindow: infoWindow,
                                shortHtml: htmls[0],
                                longHtml: htmls[1],
                                workerAttendanceChartHighlightIndex: 0,
                            };

                            const infoWindow = new InfoWindow3D({
                                width: 140,
                                dx: 3,
                                dy: 0,
                                content: htmls[0],
                                single: false,
                                autoOpenOn: null,
                                custom: true,
                                getViewPoint: function() {
                                    let v = dataItem.ta.sceneToolbox.getBuildingTopCenter(bldg.build_name);
                                    if (v.z > 1 || v.z < 0) {
                                        v = {
                                            x: -100,
                                            y: -100
                                        };
                                    }
                                    return convertRaycastRatio(mapid, [v.x, v.y]);
                                }
                            });
                            dataItem.buildingData[bldg.build_name].infoWindow = infoWindow;
                            infoWindow.on('showend', () => {
                                if (infoWindow.options.width != 140) {
                                    window.updateBuildingCharts(mapid, bldg);
                                    if (!dataItem.buildingData[bldg.build_name].intervalHandle) {
                                        dataItem.buildingData[bldg.build_name].intervalHandle = setInterval(() => {
                                            window.updateBuildingCharts(mapid, bldg);
                                        }, 10000);
                                    }
                                } else {
                                    if (dataItem.buildingData[bldg.build_name].intervalHandle) {
                                        clearInterval(dataItem.buildingData[bldg.build_name].intervalHandle);
                                        dataItem.buildingData[bldg.build_name].intervalHandle = null;
                                    }
                                }
                            });

                            infoWindow.addTo(dataItem.map);
                            infoWindow.show();
                        });

                        dataItem.ta.sceneToolbox.updateBuildingWorkerCount = () => {
                            options.sceneData.getBuildingList().forEach(bldg => {
                                if (!sceneConfig.buildingTopCenters[bldg.build_name]) return;
                                document.getElementById(`buildingWorkerCount_${bldg.build_name}`).innerHTML = bldg.worker_count;
                                const htmls = generateBldgInfoHtml(bldg);
                                dataItem.buildingData[bldg.build_name].shortHtml = htmls[0];
                                dataItem.buildingData[bldg.build_name].longHtml = htmls[1];
                            });
                        };
                    }

                    callback && callback(build_innerid);
                });
            })
            .catch(e => console.error(e.msg || e.message));
    }

    if (options.enable3D) {
        dataItem.map.on('click', function() {
            justClicked = true;
            setTimeout(() => {
                justClicked = false;
            }, 200);
        });

        dataItem.map.on('dragrotating', function() {
            dataItem.ta.compassRotation = -dataItem.map.getView().bearing;
        });

        dataItem.map.on('zoomend', function(e) {
            if (dataItem.ta.sceneToolbox) {
                dataItem.ta.sceneToolbox.mapZoomedTo(e.to);
            }
        });
    } else {
        // This is only used in 2D mode, in 3D mode we use the click handler in scene_loader.js
        dataItem.map.on('click', function(e) {
            if (options.enableHeatmap && dataItem.heatmapLayer) {
                let area_dists = dataItem.heatmapAreas.map(item => ({
                    dist: Math.min.apply(null, item.point_list.map(x => Math.sqrt(Math.pow(e.coordinate.x - x[0], 2) + Math.pow(e.coordinate.y - x[1], 2)))),
                    name: item.name,
                    count: item.count
                }));
                area_dists = area_dists.filter(item => item.dist <= 150);
                if (area_dists.length > 0) {
                    area_dists.sort((a, b) => a.dist - b.dist);

                    dataItem.heatmapInfoWindow.setContent(`<div style="opacity:1;position:relative;">
                        <div style="position:relative;background-color:#122040;border:1px solid #526eb2"><div style="margin:13px 0">
                        <div style="text-align: left;padding-left: 10px;">
                            <div>区域：${area_dists[0].name}</div>
                            <div>总人数：${area_dists[0].count}</div>
                        </div></div><div />
                        </div><div class="leaflet-popup-tip-container"><div class="leaflet-popup-tip" style="background-color:#122040;border:1px solid #526eb2"></div></div>
                        <a style="position:absolute;top:0;right:0;padding:4px 4px 0 0;border:none;text-align:center;width:18px;height:14px;font:16px/14px Tahoma,Verdana,sans-serif;color:#c3c3c3;text-decoration:none;font-weight:700" onclick="hideMe('${mapid}')">×</a></div>`
                    );
                    dataItem.heatmapInfoWindow.show(e.coordinate);
                }
            }

//            if (window.beaconMode) {
                random_list.push(convertPointInverse([e.coordinate.x, e.coordinate.y], getMapData(mapid)));
                console.log(JSON.stringify(random_list));
//            }
        });
    }

    if (!options.enable3D) {
        callback && callback(build_innerid);
    }
}

function setImageLayerUrl(mapid, imageLayer, url) {
    var bgImg = new Image();
    bgImg.crossOrigin = 'anonymous';
    bgImg.src = url;
    bgImg.onload = function() {
        var canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const mapData = getMapData(mapid);
        canvas.width = mapData.mapWidth;
        canvas.height = mapData.mapHeight;
        ctx.drawImage(bgImg, 0, 0, mapData.mapWidth, mapData.mapHeight);
        var dataUrl = canvas.toDataURL('image/png');

        imageLayer.setImages([
            {
                url: dataUrl,
                extent: [
                  -5000 * mapData.extentScaling,
                  -5000 * mapData.mapHeight / mapData.mapWidth * mapData.extentScaling,
                  5000 * mapData.extentScaling,
                  5000 * mapData.mapHeight / mapData.mapWidth * mapData.extentScaling
                ],
                opacity: 1
            }
        ]);
    };
}

/**
 * 切换地图
 * @param {*} map_img
 */
export function changeMap(mapid, map_img, type, build_id) {
    const dataItem = getDataItem(mapid);
    dataItem.default_build_innerid = build_id;
    dataItem.mapUrl = map_img;
    if (dataItem.markersLayer) {
        dataItem.marker_obj_list = [];
        dataItem.markersLayer.clear();
    }
    if (dataItem.imageLayer) {
        setImageLayerUrl(mapid, dataItem.imageLayer, dataItem.mapUrl);
    }
    if (dataItem.heatmapLayer) {
        clearHeatmapData(mapid);
    }
    if (dataItem.electricBoxLayer) {
        dataItem.electricBoxLayer.clear();
    }
}

/**
 * 设置地图上的marker
 * @param {*} list
 */
export function setMapMarker(mapid, list) {
    const dataItem = getDataItem(mapid);
    if (!dataItem.markersLayer) return;
    // <div><button class='pline' onclick="getTrack()">轨迹</button>
    for (let t = 0; t < list.length; t++) {
        let v = list[t];

        for (let i = 0; i < dataItem.marker_obj_list.length; i++) {
            if (dataItem.marker_obj_list[i].worker_id == v.innerid) {
                dataItem.markersLayer.removeGeometry(dataItem.marker_obj_list[i].m);
                for (let j = 0; j < dataItem.marker_obj_list[i].b.length; j++) {
                    dataItem.marker_obj_list[i].b[j].remove();
                }
                for (let j = 0; j < dataItem.marker_obj_list[i].timeout_handles.length; j++) {
                    clearTimeout(dataItem.marker_obj_list[i].timeout_handles[j]);
                }
                dataItem.marker_obj_list.splice(i, 1);
                break
            }
        }

        if ((v.status_id == 1 || v.status_id == 3)) {
            dataItem.ta.show_alarm.name = v.name;
            dataItem.ta.show_alarm.location = v.build_name + ' ' + (v.area_name ? v.area_name : v.floor_name);
            dataItem.ta.show_alarm.mobile = v.mobile;
            if (v.status_id == 1) {
                dataItem.ta.show_alarm.title = "紧急呼救";
                dataItem.ta.show_alarm.img_show_alarm = true;
            } else {
                dataItem.ta.show_alarm.title = "坠落呼救";
                dataItem.ta.show_alarm.img_show_alarm = false;
            }
            dataItem.ta.show_alarm.is_show = true;
        }

        if (v.x && v.y) {
            v.x = parseInt(v.x);
            v.y = parseInt(v.y);

            let btn_text = '召回'
            let bcolor = ' #5186f5'
            if (v.status_id == 7 || v.recalled == 8) {
                btn_text = "召回收到"
                bcolor = ' #b4bed4'
            } else if (v.status_id == 8 || v.recalled == 8) {
                btn_text = "召回确认"
                bcolor = ' #b4bed4'
            }

            let html;
            if (dataItem.hideWorkerPopupButtons) {
                let traceButton = '';
                if (window.withTrace) {
                    traceButton = `<button style='background-color:#399A75;border-radius: 24px;border: none;color: #fff;padding:
                    3px 10px;cursor: pointer;outline: none;margin-top: 7px;' id='showPath' onclick="showPathClick('${mapid}', '${v.innerid}', '${v.name}')">轨迹</button>`;
                }
                html = `<div style="opacity:1;position:relative;">
                    <div style="position:relative;background-color:#122040;border:1px solid #526eb2"><div style="margin:13px 0">
                    <div style="text-align: center;">
                    <div>${v.name}(${v.hat_code})</div>
                    <div>${v.worker_type || ''}</div>
                    <div>${v.mobile}</div>
                    ${traceButton}</div></div><div />
                    </div><div class="leaflet-popup-tip-container"><div class="leaflet-popup-tip" style="background-color:#122040;border:1px solid #526eb2"></div></div>
                    <a style="position:absolute;top:0;right:0;padding:4px 4px 0 0;border:none;text-align:center;width:18px;height:14px;font:16px/14px Tahoma,Verdana,sans-serif;color:#c3c3c3;text-decoration:none;font-weight:700" onclick="hideMe('${mapid}')">×</a></div>`;
            } else {
                html = `<div style="opacity:1;position:relative;">
                    <div style="position:relative;background-color:#122040;border:1px solid #526eb2"><div style="margin:13px 0">
                    <div style="text-align: center;">
                    <div><a style="cursor:pointer" onclick="showWorkerInfo('${mapid}', '${v.innerid}', ${v.ptype})">${v.name}(${v.hat_code})</a></div>
                    <div>${v.worker_type || ''}</div>
                    <div>${v.mobile}</div>
                    <button style='background-color:#399A75;border-radius: 24px;border: none;color: #fff;padding:
                    3px 10px;cursor: pointer;outline: none;margin-top: 7px;' id='showPath' onclick="showPathClick('${mapid}', '${v.innerid}', '${v.name}')">轨迹</button>
                    <button style='background-color:${bcolor};border-radius: 24px;border: none;color: #fff;padding:
                    3px 10px;cursor: pointer;outline: none;margin-top: 7px;' data-id='${JSON.stringify(v)}' id='callBack' onclick="callBackClick('${mapid}', this, ${v.status_id}, '${v.name}')">${btn_text}</button></div></div><div />
                    </div><div class="leaflet-popup-tip-container"><div class="leaflet-popup-tip" style="background-color:#122040;border:1px solid #526eb2"></div></div>
                    <a style="position:absolute;top:0;right:0;padding:4px 4px 0 0;border:none;text-align:center;width:18px;height:14px;font:16px/14px Tahoma,Verdana,sans-serif;color:#c3c3c3;text-decoration:none;font-weight:700" onclick="hideMe('${mapid}')">×</a></div>`;
            }

            const iconurl = getIconByColor(v.ptype, v.color)
            const marker = new M.Marker(convertPoint([v.x, v.y], getMapData(mapid)), {
                id: v.name + v.mobile,
                cursor: 'pointer',
                symbol: {
                    markerFile: require(`../src/assets/hat/${iconurl}`),
                    markerWidth: 20,
                    markerHeight: 20
                }
            });
            dataItem.markersLayer.addGeometry(marker);
            marker.setInfoWindow({
                width: 140,
                dx: 4,
                dy: 12,
                content: html,
                autoOpenOn: 'click',
                autoCloseOn: 'click'
            });
            marker.getInfoWindow().on('showstart', function(obj) {
                dataItem.infoWindow = obj.target;
            });

            const blink_objs = [];
            const timeout_handles = [];
            if (blinkColorMap['' + v.status_id]) {
                let mo = M.blinkMarker([v.x, v.y], {
                    iconSize: [40, 40],
                    diveColor: blinkColorMap['' + v.status_id],
                    level: '5',
                    speedTime: 3
                }, getMapData(mapid)).addTo(dataItem.map);
                blink_objs.push(mo);

                for (let i = 1; i < 4; i++) {
                    let handle = window.setTimeout(function() {
                        let mo = M.blinkMarker([v.x, v.y], {
                            iconSize: [40, 40],
                            diveColor: blinkColorMap['' + v.status_id],
                            level: '5',
                            speedTime: 3
                        }, getMapData(mapid)).addTo(dataItem.map);
                        blink_objs.push(mo);
                    }, 750 * i);
                    timeout_handles.push(handle);
                }
            }
            dataItem.marker_obj_list.push({ m: marker, b: blink_objs, worker_id: v.innerid, build_id: v.build, timeout_handles: timeout_handles })
        }
    }
}

export function setHeatmapData(mapid, data, heatmapAreas, max) {
    //console.log('abc', data, heatmapAreas, max);
    const dataItem = getDataItem(mapid);
    if (dataItem.sceneLayer) {
        setHeatmapData3D(dataItem.sceneLayer, data, max);
    } else if (dataItem.heatmapLayer.isVisible()) {
        if (dataItem.heatmapLayer) {
            if (!max) {
                max = 1;
            }

            try {
                dataItem.heatmapLayer.setData(data);
                dataItem.heatmapLayer.config({
                    max: Math.min(max, 1)
                });
            } catch(e) { console.log(e); }
            dataItem.heatmapAreas = heatmapAreas || [];
        }
    }
}

export function clearHeatmapData(mapid) {
    const dataItem = getDataItem(mapid);
    if (dataItem.heatmapLayer) {
        setHeatmapData(mapid, []);
        dataItem.heatmapAreas = [];
    }
}

export function removeHeatmap(mapid) {
    const dataItem = getDataItem(mapid);
    if (dataItem.heatmapLayer) {
        dataItem.map.removeLayer(dataItem.heatmapLayer);
        dataItem.heatmapLayer = null;
        dataItem.heatmapAreas = [];
    }
    if (dataItem.heatmapInfoWindow) {
        dataItem.heatmapInfoWindow.remove()
        dataItem.heatmapInfoWindow = null;
    }
}

/**
 * 设置地图上的电箱
 * @param {*} list
 */
export function setElectricBoxes(mapid, list) {
    const dataItem = getDataItem(mapid);
    if (!dataItem.electricBoxLayer) return;
    dataItem.electricBoxLayer.clear();

    for (let t = 0; t < list.length; t++) {
        let v = list[t];

        if (v.x && v.y) {
            v.x = Math.abs(parseInt(v.x));
            v.y = Math.abs(parseInt(v.y));

            const showData = [];
            for (const item of v.worker_info) {
                item.time_set.forEach((val, idx) => {
                    showData.push(`<div style="display:flex;margin-left:10px;margin-bottom:2px"><div style="width:60px">${idx == 0 ? item.worker_name : ''}</div><div style="width:60px">${val.start_time}</div></div>`);
                });
            }

            const html = `<div style="opacity:1;position:relative;cursor:pointer;">
                <div style="position:relative;background-color:#122040;border:1px solid #526eb2" onclick="patrolClick('${mapid}', 'elecbox', '${v.deploy_id}')"><div style="margin:13px 0">
                <div style="text-align: center;">
                <div style="font-weight:bold;margin-bottom:10px">电箱${v.box_name}</div>
                ${showData.length ? showData.join('\n') : '电箱未巡检'}
                </div></div><div />
                </div><div class="leaflet-popup-tip-container"><div class="leaflet-popup-tip" style="background-color:#122040;border:1px solid #526eb2"></div></div></div>`;

            const marker = new M.Marker(convertPoint([v.x, v.y], getMapData(mapid)), {
                id: v.deploy_id,
                symbol: {
                    markerFile: require(`../src/assets/elecbox-${v.is_patrolled ? 'green': 'red'}.png`),
                    markerWidth: 32,
                    markerHeight: 32
                }
            });
            dataItem.electricBoxLayer.addGeometry(marker);
            marker.setInfoWindow({
                single: false,
                width: 140,
                dx: 4,
                dy: 0,
                content: html,
            });
            marker.openInfoWindow();
        }
    }
}

/**
 * 初始化人员轨迹图
 * @param {*} mapid
 * @param {*} default_img 默认地图
 */
  export function initTraceMap(mapid, default_img, listPromise, worker_id, date, mapObjects, options) {
    const dataItem = getDataItem(mapid);

    if (!options) options = {};
    dataItem.mapWidth = options.mapWidth;
    dataItem.mapHeight = options.mapHeight;

    const mapData = getMapData(mapid);
    let extentScaling;
    if (mapData.mapWidth * 0.75 > mapData.mapHeight) {
        extentScaling = 1;
    } else {
        extentScaling = mapData.mapWidth / mapData.mapHeight * 0.75;
    }
    dataItem.extentScaling = extentScaling;

    const initialZoom = options.initialZoom || 3;
    const map = new M.Map(mapid, {
        spatialReference: {
            projection: 'identity',
            resolutions: [64, 32, 16, 8, 4, 2, 1],
            fullExtent: {
                'top': 10000,
                'left': -10000,
                'bottom': -10000,
                'right': 10000
            }
        },
        center: [0, 0],
        //pitch : 75,
        //bearing : -140,
        dragRotate: false,
        dragPitch: false,
        minZoom: initialZoom - 1,
        maxZoom: initialZoom + 3,
        zoom: initialZoom,
        zoomControl: false,
        attribution: false
    });

    //map.setMaxExtent(scaleExtent(map.getExtent(), 1.2, 1.6));
    //map.setZoom(map.getZoom() + 1, { animation: false });
    /*
    map.on('moving', () => {
        const prjCenter = map._getPrjCenter();
        if (map._verifyExtent(prjCenter)) {
            map._originCenter = prjCenter;
        }
    });
    */

    mapObjects['map'] = map;
    mapObjects['trace'] = {
        worker_id: worker_id,
        date: date,
    };

    const canvas = document.createElement('canvas');
    paper.setup(canvas);

    drawTrace(mapid, default_img, listPromise, worker_id, date, mapObjects);
}

/**
 * 绘制人员轨迹图
 * @param {*} mapid
 * @param {*} default_img 默认地图
 */
export function drawTrace(mapid, default_img, listPromise, worker_id, date, mapObjects) {
    const map = mapObjects['map'];

    let imageLayer, loadPromise;
    if (default_img !== (mapObjects['image'] && mapObjects['image'].img)) {
        if (mapObjects['image']) {
            map.removeLayer(mapObjects['image'].layer);
        }

        imageLayer = new M.ImageLayer('trace-bg', []);
        map.addLayer(imageLayer);
        setImageLayerUrl(mapid, imageLayer, default_img);

        loadPromise = new Promise((resolve) => {
            imageLayer.on('resourceload', function () {
                mapObjects['image'] = {
                    img: default_img,
                    layer: imageLayer
                };

                resolve();
            });
        });
    }

    if (listPromise && mapObjects['trace'] && mapObjects['trace'].layers) {
        for (const layer of mapObjects['trace'].layers) {
            if (layer) {
                map.removeLayer(layer);
            }
        }
        mapObjects['trace'].layers = null;
        clearInterval(mapObjects['trace'].animate);
    }

    Promise.all([listPromise, loadPromise]).then((values) => {
        if (imageLayer) {
            imageLayer.setOpacity(1);
        }

        if (listPromise && values[0].length) {
            const mapData = getMapData(mapid);
            const points = [];
            const colors = [];
            var totalLength = 0;
            if (values[0].length > 1) {
                var path = new Path({
                    segments: values[0].map(v => [parseInt(v.x), parseInt(v.y)]),
                    strokeColor: '#399A75',
                    strokeWidth: 8,
                    closed: false
                });
                //path.simplify();
                //path.smooth({ type: 'catmull-rom' });
                var svg = path.exportSVG({ asString: true });
                var svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
                svgElement.setAttribute('viewBox', `-5000 -5000 5000 5000`);
                svgElement.innerHTML = svg;

                for (const child of svgElement.children) {
                    const length = child.getTotalLength();
                    const step = 0.002 * Math.sqrt(mapData.mapWidth ** 2 + mapData.mapHeight ** 2);
                    for (let val = 0; val < length; val += step) {
                        const pt = child.getPointAtLength(val);
                        const newPt = convertPoint([pt.x, pt.y], mapData);
                        newPt.push(totalLength + val);
                        points.push(newPt);
                    }
                    totalLength += length;
                }
                for (const pt of points) {
                    pt[2] /= totalLength;
                    if (pt[2] <= 0.25) {
                        colors.push(pickHex('#46B173', '#9DD622', pt[2] / 0.25));
                    } else if (pt[2] <= 0.5) {
                        colors.push(pickHex('#9DD622', '#E4CF1B', (pt[2] - 0.25) / 0.25));
                    } else if (pt[2] <= 0.75) {
                        colors.push(pickHex('#E4CF1B', '#E39E1A', (pt[2] - 0.5) / 0.25));
                    } else {
                        colors.push(pickHex('#E39E1A', '#F35507', (pt[2] - 0.75) / 0.25));
                    }
                }
                svgElement.remove();
            } else {
                const pt = [parseInt(values[0][0].x), parseInt(values[0][0].y)];
                const newPt = convertPoint(pt, mapData);
                newPt.push(0);
                points.push(newPt);
                colors.push('#46B173');
            }

            if (points.length) {
                const traceLayer = new M.VectorLayer('trace-figure', [], {
                    enableAltitude: true,
                    zIndex: 20000
                });
                map.addLayer(traceLayer);

                const figureMark = new M.Marker(points[0], {
                    id: 'trace-figure',
                    symbol: {
                        markerFile: require('../src/assets/icon_man.png'),
                        markerWidth: 26,
                        markerHeight: 26,
                        markerVerticalAlignment: 'middle',
                        markerRotation: points.length > 1 ? calcRotationAngle(points[0], points[1]) : 0
                    },
                    zIndex: 30000
                });
                traceLayer.addGeometry(figureMark);

                let drawIdx = 0;
                let lastStepCalled = 0;
                const timeoutFunc = () => {
                    const now = new Date() / 1;
                    let stepJump = 1;
                    if (lastStepCalled) {
                        stepJump = Math.round((now - lastStepCalled) / 10);
                    }
                    lastStepCalled = now;

                    const lastDrawIdx = drawIdx;
                    drawIdx = Math.min(drawIdx + stepJump, points.length - 1);
                    figureMark.setCoordinates(points[drawIdx]);

                    if (drawIdx > lastDrawIdx) {
                        const startAngle = calcRotationAngle(points[lastDrawIdx], points[lastDrawIdx + 1]);
                        const endAngle = calcRotationAngle(points[drawIdx - 1], points[drawIdx]);
                        if (drawIdx - lastDrawIdx > 1 && Math.abs(startAngle - endAngle) < 0.01) {
                            const traceline = new M.LineString([points[lastDrawIdx], points[drawIdx]], {
                                symbol: {
                                    lineColor: colors[Math.round((lastDrawIdx + drawIdx - 1) / 2)],
                                    lineWidth: 3,
                                    lineCap: 'square',
                                },
                                zIndex: 20000
                            });
                            traceLayer.addGeometry(traceline);
                        } else {
                            for (let i = lastDrawIdx; i < drawIdx; i++) {
                                const traceline = new M.LineString([points[i], points[i + 1]], {
                                    symbol: {
                                        lineColor: colors[i],
                                        lineWidth: 3,
                                        lineCap: 'square',
                                    },
                                    zIndex: 20000
                                });
                                traceLayer.addGeometry(traceline);
                            }
                        }

                        if (drawIdx > 1) {
                            figureMark.updateSymbol({ markerRotation: endAngle });
                        }
                    }

                    if (drawIdx < points.length - 1) {
                        mapObjects['trace'].animate = setTimeout(timeoutFunc, 10);
                    }
                }
                const animate = setTimeout(timeoutFunc, 10);

                mapObjects['trace'] = {
                    worker_id: worker_id,
                    date: date,
                    layers: [traceLayer],
                    animate: animate,
                };
            }

            /*
            const svgLayer = M.svgOverlay(svgElement, bounds).addTo(map);
            mapObjects['trace'] = {
                worker_id: worker_id,
                date: date,
                layer: svgLayer
            };
            */
        }
    });
}

/**
 * 创建webSocket对接
 * @param {*} wsUrl
 * @param {*} projectID
 */
export function createWebSocket(mapid, wsUrl, projectID, onMessage) {
    const dataItem = getDataItem(mapid);
    try {
        dataItem.ws = new WebSocket(wsUrl);
        dataItem.ws.id = projectID;
        //连接成功后，发送当前项目的project_id
        dataItem.ws.onopen = function () {
            dataItem.ws.send(JSON.stringify({
                'action': 'init',
                'values': { 'project_id': projectID }
            }))
        }

        dataItem.ws.onclose = function () {
            createWebSocket(mapid, wsUrl, projectID, onMessage);
        }
        dataItem.ws.onerror = function () {
            createWebSocket(mapid, wsUrl, projectID, onMessage);
        }

        //获取ws服务器推送的数据
        dataItem.ws.onmessage = function (e) {
            try {
                const message = JSON.parse(e.data)
                if (onMessage) {
                    onMessage(message);
                } else {
                    dataParser(mapid, message);
                }
            } catch (e) {
                console.warn(e.message)
            }
        }
    } catch (e) {
        createWebSocket(mapid, wsUrl, projectID, onMessage);
    }
}

/**
 * 列表中召回工人
 * @param {*} hat_id
 * @param {*} ctrl_mac
 * @param {*} ch
 */
export function wcallback(mapid, hat_id, ctrl_mac, ch, hat_mac) {
    const dataItem = getDataItem(mapid);
    dataItem.ws && dataItem.ws.send(JSON.stringify({
        action: 'recall',
        values: { hat_id: hat_id, ctrl_mac: ctrl_mac, ch: ch, hat_mac: hat_mac }
    }))
}

/**
 * 数据解析
 */
export function dataParser(mapid, data) {
    const dataItem = getDataItem(mapid);
    if (data && data.errcode === 0) {
        if (data.source == "customer_update") {
            if (data.data[0].build == dataItem.default_build_innerid) {
                setMapMarker(mapid, data.data);
            } else if (dataItem.markersLayer) {
                for (let i = 0; i < dataItem.marker_obj_list.length; i++) {
                    if (dataItem.marker_obj_list[i].worker_id == data.data[0].innerid) {
                        dataItem.markersLayer.removeGeometry(dataItem.marker_obj_list[i].m);
                        for (let j = 0; j < dataItem.marker_obj_list[i].b.length; j++) {
                            dataItem.marker_obj_list[i].b[j].remove();
                        }
                        for (let j = 0; j < dataItem.marker_obj_list[i].timeout_handles.length; j++) {
                            clearTimeout(dataItem.marker_obj_list[i].timeout_handles[j]);
                        }
                        dataItem.marker_obj_list.splice(i, 1);
                        break
                    }
                }

                for (let i = 0; i < data.data.length; i++) {
                    let v = data.data[i];
                    if ((v.status_id == 1 || v.status_id == 3)) {
                        dataItem.ta.show_alarm.name = v.name;
                        dataItem.ta.show_alarm.location = v.build_name + ' ' + (v.area_name ? v.area_name : v.floor_name);
                        dataItem.ta.show_alarm.mobile = v.mobile;
                        if (v.status_id == 1) {
                            dataItem.ta.show_alarm.title = "紧急呼救";
                            dataItem.ta.show_alarm.img_show_alarm = true;
                        } else {
                            dataItem.ta.show_alarm.title = "坠落呼救";
                            dataItem.ta.show_alarm.img_show_alarm = false;
                        }
                        dataItem.ta.show_alarm.is_show = true;
                    }
                }
            }
        }
    }
}

/**
 * 根据用户类型，和状态返回对应的图标
 * @param {*} ptype
 * @param {*} color
 */
function getIconByColor(ptype, color) {
    let iconurl = 'wp'
    if (ptype == 1) {
        //管理人员
        iconurl = 'gp'
    }
    else if (ptype == 2) {
        //工人
        iconurl = 'wp'
    }

    let color_id;
    switch(color) {
        case '黄色':
            color_id = 2;
            break;
        case '红色':
            color_id = 3;
            break;
        case '蓝色':
            color_id = 101;
            break;
        case '白色':
            color_id = 102;
            break;
        default:
            color_id = ''
    }

    return iconurl + color_id + ".png"
}
