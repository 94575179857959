<template>
  <div id="path" class="global-bg">
    <div id="map">
      <div class="project_map" id="mapid">
      </div>
    </div>
    <div :key="$route.params.worker_id + $route.params.map_url" />
  </div>
</template>

<script>
import { initTraceMap, drawTrace } from '../../../public/leaflet.js'
import { project } from '../../api'

export default {
  name: 'worker_path',
  data() {
    return {
      mapObjects: {}
    };
  },
  mounted() {
    const { worker_id, map_url } = this.$route.params;
    //加载地图和人员轨迹
    initTraceMap('mapid', map_url, project.getWorkerTrace(worker_id), worker_id, this.mapObjects);
  },
  beforeUpdate() {
    const { worker_id, map_url } = this.$route.params;
    //更新地图和人员轨迹
    let listPromise;
    if (worker_id !== this.mapObjects['trace'].worker_id) {
      listPromise = project.getWorkerTrace(worker_id);
    }
    drawTrace('mapid', map_url, listPromise, worker_id, this.mapObjects);
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
#path {
  background-size: cover;
}

#map {
  .project_map {
    position: relative;
    background: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
  }
}

#mapid {
  width: 100%;
  height: 100vh;
}

.map-angle {
  background-color: red;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.leaflet-popup-content-wrapper {
  background-color: #122040;
  text-align: center;
  border: 2px solid #5f7ec9;
}

.leaflet-popup-content > div:not(:last-child) {
  margin-bottom: 5px;
}

.leaflet-popup-tip-container {
  display: none;
}

</style>
