<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  />
</template>

<script>
  import Broadcasts from "../../../../components/broadcasts";
  import { human } from "../../../../api_hr";

  export default {
    name: 'HrIndexBroadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['总人数', '自有员工', 'A派员工','B派员工'],
        broadcastColors: ['#fea453', '#40f8ff', '#ffd454','#38c59e'],
        broadcastIcons: ['总人数', '自有员工', 'A派员工','B派员工'],
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    },
  }

  export function updateData(container, scheduleNextUpdate) {
    human
      .getStaffScale(window.$sFid)
      .then((d) => {
        var res = {};
        let subdata;
        var ziyou = 0;
        var apai = 0;
        var bpai = 0;
        for (let k = 0; k < d.length; k++) {
          subdata = d[k].subData;
          for (let index = 0; index < subdata.length; index++) {
            if(subdata[index].name == "自有"){
              ziyou += subdata[index].value;
            }else if(subdata[index].name == "A类派遣"){
              apai += subdata[index].value;
            }else if(subdata[index].name == "B类派遣"){
              bpai += subdata[index].value;
            }
          }
        }
        res["theirOwnEmp"] = ziyou;
        res["dispath_A"] = apai;
        res["dispath_B"] = bpai;
        if( res["dispath_B"] == null){
           res["dispath_B"] = 0;
        }
        res["total"] = res["theirOwnEmp"]+res["dispath_A"]+res["dispath_B"];
        container.broadcastData.hr = res;
        container.broadcastData.hr.value1 = res["total"];
        container.broadcastData.hr.value2 = res["theirOwnEmp"];
        container.broadcastData.hr.value3 = res["dispath_A"];
        container.broadcastData.hr.value4 = res["dispath_B"];
        container.statsData = res;
        // scheduleNextUpdate();
      })
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
