<template>
  <div id="left">
    <div class="info-block" style="min-height:5rem">
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>人员结构</div>
        <!-- <div class="detail" @click="showDetail(1)">
          详情
        </div> -->
      </div>
      <div style="height:19rem;padding-top:3rem">
        <div
          style="display:flex;justify-content:space-around;margin-bottom:0.6rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')"
          >
            专业序列分析
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')"
          >
            各层级人员数量
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '3' }"
            @click="() => switchCompanyWorkersOption('3')"
          >
            年龄分析
          </div>
        </div>
        <div
          v-if="companyWorkersBarChart.series && [].concat.apply([], companyWorkersBarChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyWorkersBarChart" @click="companyWorkersBarChartClick" autoresize/>
      </div>
    </div>
  </div>
</template>
<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation } from "../../../../api";
import { human } from "../../../../api_hr"
import { SimpleBarChartTemplate} from "../../../project2/barChartTemplates";
import { colors as pieColors } from "../../../project2/pieChartTemplates";
import { applyUpdate, truncateStr } from "../../../../utils";


export default {
  name: "staffStructureChart",
  components: {
    "v-chart": ECharts
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    orgOptions: Array,
    districtScale: Number,
  },
  data() {
    return {
      companyNameList: [""],
      companyIdList: [""],
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      selectVal4: '',
      companyWorkersData: {},
      companyWorkersOption: "1",
      companyWorkersBarChart: {},
      educationData:[],
      option: {
        color: ['#1bd080', '#1bd080'],
        grid: {
          containLabel: true,
          left: '8%',
          right: '6%',
          bottom: "14%",
          top: "12%"
        },
        legend: {
          data: [],
          textStyle:{
            fontSize: 11,//字体大小
            color: '#ffffff'//字体颜色
          },
        },
      title: [],
			tooltip: {
        trigger: 'item'
			},
			series: [
        {
        name:'第一学历统计',
        type: 'pie',
        radius: '50%',
        center: ['50%', '50%'],
        data: [],
        label: {
            position: 'outer',
            bleedMargin: 5,
            fontSize:14
        },
        left: 0,
        right: '66.6667%',
        top: 0,
        bottom: 0
			}, {
        name:'最高学历统计',
        type: 'pie',
        radius: '50%',
        center: ['50%', '50%'],
        data: [],
        label: {
            position: 'outer',
            bleedMargin: 5,
            fontSize:14
        },
        left: '33.3333%',
        right: '33.3333%',
        top: 0,
        bottom: 0
			}, {
        name:'院校类别统计',
        type: 'pie',
        radius: "50%",
        center: ["50%", "50%"],
        data: [],
        label: {
            position: 'outer',
            margin: 20,
            fontSize:14
        },
        left: '66.6667%',
        right: 0,
        top: 0,
        bottom: 0
			}],
      xAxis: {
          axisLabel: {
            color: '#e6f2f3',
            fontWeight: 'bold',
            rotate: 40,
            formatter: value => truncateStr(value, 10),
          },
          show: true,
          axisLine: { show: true, lineStyle: { color: '#36445f' } },
          splitLine: { show: false },
          axisTick: { show: false },
          type: 'category',
          data: []
        },
        yAxis: [
          {
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: false, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
            show: true
          }
        ]
    },
      attendanceBarChart: {},
      option2: {
        color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter:function(param){
              let str = param[0].name+"<br/>";
              for (let index = 0; index < param.length; index++) {
                str += param[index].seriesName+":"+param[index].value+"<br/>";
              }
              return str;
            }
        },
        xAxis: [
          {
            name:"单位",
            data: [],
            axisLabel: {
              color: 'white',
              fontWeight: 'bold',
              rotate: 45,
              formatter: value => truncateStr(value, 10),
            },
            show: true,
            axisLine: { show: false, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'category',
          }
        ],
        yAxis: [
          {
            name:'人数',
            nameTextStyle: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value'
          },
          {
            nameTextStyle: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '10%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
              formatter: function(value) {
                return value ? value + '%' : value;
              },
            },
            saxisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
            show: false
          }
        ],
        series: [
          {
            name: '自有员工',
            type: 'bar',
            barWidth: 10,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: 'A类派遣',
            type: 'bar',
            barWidth: 10,
            emphasis: {
              focus: 'series'
            },
            data: [],
          },
          {
            name: 'B类派遣',
            type: 'bar',
            barWidth: 10,
            emphasis: {
              focus: 'series'
            },
            data: [],
          },
          {
            name: '合计',
            type: 'bar',
            barWidth: 10,
            emphasis: {
              focus: 'series'
            },
            data: [],
            label: {
                normal: {
                    show: true,
                    position: 'top',
                    formatter: '{c}',
                    textStyle: {
                        color: 'white',
                         fontWeight:'bold'
                    }
                }
            }
          }
        ]
      },
      personSource_option: {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend:{

        },
        yAxis:{
          show:false
        },
        series: [
          {
            name: '人员来源',
            type: 'pie',
            radius: '40%',
            label: {
              show: true,
              fontSize:'16',
              formatter: '{b} {d}%',
              },
            data: [],
            emphasis: {
              itemStyle: {
                fontSize: '16',
                fontWeight: 'bold'
                }
              }
            }
          ]
      },
      avgAttendanceTopLists: {},
      avgAttendanceTopListOption: "project_avg_h_top",
      WorkersBarChart: {},
      mode: "1",
      popupShow1: false,
      noActionTroubleBarChart: {},
      popupCount: 0,
      popupname: '在场工人数',
      workPieChart1: {},
      workPieChart2: {},
      workPieChart3: {},
      popupShow2: false,
      popupSelect: '2',
      attenceWorkersBarChart: {},
      popupBottomName: '',
      popupSearchkeys: [],
      popupData: [],
      popupXAxisData: [],
      popupData2: [],
      popupData2Num: 0,
      org_id: '',
      popupShow3: false,
      isFirst: true,

      mapLevel: 0,
      activeIndex:0,
      popupShow: false,
      popupSelect2: 1,
      popupShowSearchKey:[],
      popupTitle: "",
      popProjectsList: [],
      securityStarPage: 1,
      securityStarPages: 1,

      popupShow4: false,
      selectedVal2:'全部分公司',
        popupSelect3: '1',
        workerMoneysBarChart: {},
        workerMoneysBarChartTemplate: {
          color: ["#23CF9E", "#14B0BB"],
          grid: {
            contentLabel: true,
            right: '5%',
            top: "13%",
            left: "5%",
            bottom: "15%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          tooltip: {
            trigger: 'axis',
            formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              name: "工资总额：万元",
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true
            },
            {
              show: true,
              name: "代付率：%",
              min: 0,
              max: 100,
              interval: 25,
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // },
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
            }
          ],
          series: [
            {
              name: '工资总额',
              type: 'bar',
              barWidth: 10,
              data: []
            },
            {
              name: '代付率',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                  {offset: 1, color: 'rgba(27, 208, 128, 0)'}
                ])
              },
              symbolSize: 0,
              yAxisIndex: 1,
              data: [],
            }
          ]
        },
        // org_id: '',
        popupBottomName3: '',
        popupSearchkeys3: [],
        popupXAxisData3: [],
        popupBarData: [],
        popupData3: [],
        securityStarPage3: 1,
        securityStarPages3: 1,
        dataIndex: -1,
    };
  },
  watch: {
    orgOptions(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getAll();
      }
    },
    orgLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.getAll();
      }
    },
    districtLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
        this.getAll();
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.mapLevel = newValue;
      }
    },
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;
    this.getAll();
  },
  methods: {
    handleSelect(item) {
      nation.getProjectToken(item.project_id)
        .then((d) => {
          this.$router.push(`/project2/${item.project_id}/1/0?token=${d.token}&org_name=${item.project_name}`)
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    
    companyWorkersBarChartClick(params){
      if(this.mapLevel!==2){
        // console.log(params)
        this.popupSelect2=this.companyWorkersOption;
        this.popupShow = true;
        this.popupTitle = params.name ;
        this.securityStarPage=1;
        this.activeIndex=params.dataIndex;
        this.securityStarPageChange(1);
      }
      
    },
    securityStarPageChange(currPage) {
      nation
        .getLaborWorkerCountCollect(this.popupSelect2,currPage, 10,this.mapLevel==0?this.popupShowSearchKey[this.activeIndex]:'',this.mapLevel==1?this.popupShowSearchKey[this.activeIndex]:'')
        .then((d) => {
          this.popProjectsList = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },

    getAttendance2(popupSelect) {
      this.popupSelect2 = popupSelect;
      this.securityStarPage=1;
      this.securityStarPageChange(1)
    },
    getAll(){
      this.switchCompanyWorkersOption(this.companyWorkersOption);
    },
    queryPersonSenquence(){
        human.getPersonalSequence().then((result) => {
          result = result[0];
          var datas = [];
          var assist =0,grow=0,indep=0,exper=0,lead=0,company_expert = 0,trade_expert = 0;
          for (let index = 0; index < result.length; index++) {
              switch (result[index].NAME) {
                  case "P1":
                  case "P2":
                  case "P3":
                      assist += result[index].VALUE;
                      break;
                  case 'P4':
                  case 'P5':
                  case 'P6':
                      grow += result[index].VALUE;
                      break;
                  case 'P7':
                  case 'P8':
                  case 'P9':
                      indep += result[index].VALUE;
                      break;
                  case 'P10':
                  case 'P11':
                  case 'P12':
                      exper += result[index].VALUE;
                      break;
                  case 'P13':
                  case 'P14':
                  case 'P15':
                      lead += result[index].VALUE;
                      break;
                  case 'P16':
                  case 'P17':
                  case 'P18':
                      company_expert += result[index].VALUE;
                      break;
                  case 'P19':
                  case 'P20':
                  case 'P21':
                      trade_expert += result[index].VALUE;
                      break;
                  default:
                      break;
              }
          }
          datas.push(
                  {"name":"助理层","value":assist},
                  {"name":"成长层","value":grow},
                  {"name":"独立层","value":indep},
                  {"name":"经验层","value":exper},
                  {"name":"带队层","value":lead},
                  {"name":"公司专家","value":company_expert},
                  {"name":"行业专家","value":trade_expert}
              );
          let option = {
                color:pieColors,
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} {d}%'
                },
                series: [
                    {
                        name:"专业序列统计",
                        type: 'pie',
                        radius: '50%',
                        data: datas,
                        label: {
                            formatter: '{b}:{d}% ',
                            borderWidth: 1,
                            borderRadius: 2,
                            fontSize:14
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0
                            }
                        }
                    }
                ]
            };
            this.companyWorkersBarChart = option;
        })
    },
    queryUnitStaffSize(){
      var level = 1;
      human.getUnitList(3).then(rs=>{
        for (let i = 0; i < rs.length; i++) {
          if(rs[i].SID  === window.$sFid){
            level = rs[i].SLEVEL;
          }
        }
        human.getLevelPersonalNums(level).then(res=>{
          var seriesData = [];
          for (let i = 0; i < res.length; i++) {
            if(level == 1){
              if(res[i].name == "总部部门助理经理" || res[i].name == "总部部门经理" 
              || res[i].name == "总部部门副经理" || res[i].name == "公司副总师级"){
                seriesData.push(res[i]);
              }
            }else if(level ==2){
              if(res[i].name == "分公司班子成员" || res[i].name == "分公司副总师级" 
              || res[i].name == "分公司部门经理" || res[i].name == "项目经理"){
                seriesData.push(res[i]);
              }
            }
          }
          var option = {
            color:pieColors,
            tooltip:{
              trigger:'item'
            },
            series:[
              {
                type:'pie',
                radius: "50%",
                label:{
                    fontSize:14,
                    formatter:'{b}:{c}'
                },
                emphasis: {
                    label: {
                        show: true
                    }
                },
                itemStyle: {
                    borderRadius: 6
                },
                data: seriesData
              }
            ]
          }
          this.companyWorkersBarChart = option;
        })
      })
    },
    queryPersonalAge(){
      human.getPersonalAgeCondition().then((d =>{
        let datas = [
          {"name":"30岁以下","value":d[0].VALUE},
          {"name":"30-35岁","value":d[1].VALUE},
          {"name":"35-40岁","value":d[2].VALUE},
          {"name":"40岁及以上","value":d[3].VALUE},
        ]
        let age_option = {
          color:pieColors,
          tooltip:{
            trigger:'item'
          },
          series:[
            {
              type:'pie',
              radius: [20, 80],
              center: ['50%', '50%'],
              roseType: 'area',
              label:{
                  fontSize:14,
                  formatter:'{b}:{d}%'
              },
              emphasis: {
                  label: {
                      show: true
                  }
              },
              itemStyle: {
                  borderRadius: 6
              },
              data: datas
            }
          ]
        }
        this.companyWorkersBarChart = age_option;
      }))
    },
    switchCompanyWorkersOption(option) {
      this.companyWorkersOption = option;
      switch (this.companyWorkersOption) {
        case '1':
          this.queryPersonSenquence();
          break;
        case '2':
          this.queryUnitStaffSize();
          break;
        case '3':
          this.queryPersonalAge();
          break;
        default:
          this.queryPersonSenquence();
          break;
      }
    },
    companySelect(option, val) {
      const idx = this.companyNameList.indexOf(option);
      if (idx >= 0) {
        this.org_id = this.companyIdList[idx];
        // window.$orgLevel = this.org_id;
        if(val === 'val1'){
          this.selectVal1 = option;
          this.getAttendance(this.popupSelect);
        }else if(val === 'val2'){
          // eslint-disable-next-line
          this.selectVal2 = option;
          if(!this.isFirst){
            this.getPieChart();
          }
        }else if(val === 'val3'){
          // eslint-disable-next-line
          this.selectVal3 = option;
          if(!this.isFirst){
            this.getPieChart2();
          }
        }else if(val === 'val4'){
          this.selectVal4 = option;
          if(!this.isFirst){
            this.getPieChart3();
          }
          // eslint-disable-next-line
        }else{
          // 55555
          this.selectedVal2 = option; 
          this.getAttendance3(this.popupSelect3);

        }
        // console.log(this.companyIdList[idx]);
      }
    },
    showDetail(val) {
      // eslint-disable-next-line no-empty
      const idx = this.companyIdList.indexOf(this.orgLevel);
      if (val === 1) {
        this.org_id = this.orgLevel;
        this.selectVal2 = this.companyNameList[idx];
        this.selectVal3 = this.companyNameList[idx];
        this.selectVal4 = this.companyNameList[idx];
        this.popupShow1 = true;
        this.getPieChart();
        this.getPieChart2();
        this.getPieChart3();
        // eslint-disable-next-line no-empty
      } else if (val === 2) {
        this.org_id = this.orgLevel;
        this.selectVal1 = this.companyNameList[idx];
        this.popupShow2 = true;
        this.getAttendance(this.popupSelect);
        // eslint-disable-next-line no-empty
      } else {
        
        this.selectedVal2 = this.companyNameList[idx];
        this.org_id = this.orgLevel;
        this.popupShow4 = true;
        this.getAttendance3(this.popupSelect3);
      }
    },
    
      getAttendance3(popupSelect){
        this.popupSelect3 = popupSelect;
        nation.getLaborSalaryDateCollect( this.popupSelect3, this.org_id ? this.org_id:'')
          .then(d => {
            
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.workerMoneysBarChartTemplate.legend.data = d.legend;
            this.workerMoneysBarChartTemplate.xAxis[0].data = d.xAxis_data;
            d.bar_series[d.bar_series.length-1] = {
              value: d.bar_series[d.bar_series.length-1],
              itemStyle: {
                color: '#FFD153'
              }
            };
            this.workerMoneysBarChartTemplate.series[0].data = d.bar_series;
            this.workerMoneysBarChartTemplate.series[0].name = d.legend[0];
            this.workerMoneysBarChartTemplate.series[1].data = d.line_series;
            this.workerMoneysBarChartTemplate.series[1].name = d.legend[1];
            this.workerMoneysBarChart = applyUpdate(chart, this.workerMoneysBarChartTemplate);

            this.popupBottomName3 = `${this.workerMoneysBarChartTemplate.xAxis[0].data[this.workerMoneysBarChartTemplate.xAxis[0].data.length-1]}工资情况`;
            this.getLaborAttendanceDateOrgCountCollect(d.search_keys[d.search_keys.length-1]);
            this.popupSearchkeys3 = d.search_keys;
            this.popupXAxisData3 = d.xAxis_data;
            this.popupBarData = d.bar_series;
          })
      },
      workerMoneysBarChartClick(params){
        // console.log(params);
        // console.log(params.dataIndex);
        let subData = this.popupBarData;
        this.workerMoneysBarChart.series[0].data = subData.map(function (item, index) {
          return index === params.dataIndex ? {
              value: Object.prototype.toString.call(item) === '[object Object]' ? item.value:item,
              itemStyle: {
                color: '#FFD153'
              }
            }:(Object.prototype.toString.call(item) === '[object Object]' ? item.value:item)
        });
        this.popupBottomName3 = `${this.popupXAxisData3[params.dataIndex]}工资情况`;
        this.getLaborAttendanceDateOrgCountCollect(this.popupSearchkeys3[params.dataIndex]);
        this.dataIndex = params.dataIndex;
        this.securityStarPage3=1;
      },
      getLaborAttendanceDateOrgCountCollect(search_keys){
        // window.$orgLevel = this.org_id;
        nation.getLaborSalaryOrgProjectCollect(this.popupSelect3,this.securityStarPage3, 5, this.org_id ? this.org_id:'',search_keys)
          .then(d => {
            // console.log(d);
            this.popupData3 = d;
            this.securityStarPages3 = Math.ceil(d.__count__ / 5);
          })
      },
      securityStarPageChange3(currPage) {
        // console.log(currPage);
        this.securityStarPage3 = currPage;
        this.getLaborAttendanceDateOrgCountCollect(this.popupSearchkeys3[this.dataIndex]);
      },
      attenceWorkersBarChartClick(params){
        console.log(params);
      },
  },
};
</script>

<style scoped lang="less">
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}

.option-block2 {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3.4rem;
  border: 1px solid #1b8ebd;
  text-align: center;
  cursor: pointer;
}
.option-block-selected{
  background-color: transparent !important;
  border: 1px solid #274C7D !important;
}
.option-block-selected2 {
  background-color: #4191e5;
  color: #fff;
}
.attendance-top{
  padding-top: 10px;
}
.tabbar{
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  border: 1px solid #157EAB;
  width: 210px;
  margin: auto;
  cursor: pointer;
}
.tabbar div{
  flex: 1;
  text-align: center;
}
.tabar-select{
  background-color: #4191E5;
  font-weight: bold;
}
.items div{
  color: #fff !important;
}
.selected{
  color: #fff !important;
}
.attendance-bottom{

}
.linebar{
  margin-top: 5rem;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto 10px;
}
.real-bar{
  height: 5px;
  background-color: #57C7B6;
  border-radius: 12px;
}
</style>
