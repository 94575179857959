<template>
  <div id="left">
    <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
      <div class="info-block-title" style="display:flex;width:100%;padding-right:3.1rem">
        <div style="flex:1">人员结构</div>
        <!-- <div class="detail" @click="showDetail()">
          详情
        </div> -->
      </div>
      <div style="height:19rem;padding-top:3rem">
        <div
          style="display:flex;justify-content:space-around;margin-bottom:0.6rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')"
          >
            学历汇总
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')"
          >
            人员统计
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '3' }"
            @click="() => switchCompanyWorkersOption('3')"
          >
            人员来源
          </div>
        </div>
        <div
          v-if="companyWorkersBarChart.series && [].concat.apply([], companyWorkersBarChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyWorkersBarChart" @click="companyWorkersBarChartClick" autoresize/>
      </div>
    </div>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation } from "../../../../api";
import { human } from "../../../../api_hr"
import { SimpleBarChartTemplate, stackedBarChartTemplate } from "../../../project2/barChartTemplates";
import { attendancePieChartTemplate, colors } from '../../../project2/pieChartTemplates';
import { applyUpdate, truncateStr } from "../../../../utils";

export default {
  name: "personalStructureChart",
  components: {
    "v-chart": ECharts
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    orgOptions: Array,
    districtScale: Number,
  },
  data() {
    return {
      companyNameList: [""],
      companyIdList: [""],
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      selectVal4: '',
      companyWorkersData: {},
      companyWorkersOption: "1",
      companyWorkersBarChart: {},
      educationData:[],
      option: {
        color: ['rgb(131,249,103)', '#FBFE27', '#FE5050', '#1DB7E5'],
        grid: {
          containLabel: true,
          left: '8%',
          right: '6%',
          bottom: "14%",
          top: "12%"
        },
        legend: {
          data: [],
          textStyle:{
            fontSize: 11,//字体大小
            color: '#ffffff'//字体颜色
          },
        },
      title: [],
			tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
			},
			series: [
        {
        name:'第一学历统计',
        type: 'pie',
        radius: '50%',
        center: ['50%', '50%'],
        data: [],
        label: {
             formatter: '{b}:{d}% ',
            fontSize:14
        },
				itemStyle : {
					normal : {
						label : {
							show : false   //隐藏标示文字
						},
						labelLine : {
							show : false   //隐藏标示线
						}
					}
				},
        left: 0,
        right: '66.6667%',
        top: 0,
        bottom: 0
			}, {
        name:'最高学历统计',
        type: 'pie',
        radius: '50%',
        center: ['50%', '50%'],
        data: [],
        label: {
            position: 'outer',
             formatter: '{b}:{d}% ',
            bleedMargin: 5,
            fontSize:14
        },
				itemStyle : {
					normal : {
						label : {
							show : false   //隐藏标示文字
						},
						labelLine : {
							show : false   //隐藏标示线
						}
					}
				},
        left: '33.3333%',
        right: '33.3333%',
        top: 0,
        bottom: 0
			}, {
        name:'院校类别统计',
        type: 'pie',
        radius: "50%",
        center: ["50%", "50%"],
        data: [],
        label: {
            position: 'outer',
             formatter: '{b}:{d}% ',
            margin: 20,
            fontSize:14
        },
				itemStyle : {
					normal : {
						label : {
							show : false   //隐藏标示文字
						},
						labelLine : {
							show : false   //隐藏标示线
						}
					}
				},
        left: '66.6667%',
        right: 0,
        top: 0,
        bottom: 0
			}],
      xAxis: {
          axisLabel: {
            color: '#e6f2f3',
            fontWeight: 'bold',
            rotate: 40,
            formatter: value => truncateStr(value, 10),
          },
          show: true,
          axisLine: { show: true, lineStyle: { color: '#36445f' } },
          splitLine: { show: false },
          axisTick: { show: false },
          type: 'category',
          data: []
        },
        yAxis: [
          {
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: false, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
            show: true
          }
        ]
    },
      attendanceBarChart: {},
      option2: {
        color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter:function(param){
              let str = param[0].name+"<br/>";
              for (let index = 0; index < param.length; index++) {
                str += param[index].seriesName+":"+param[index].value+"<br/>";
              }
              return str;
            }
        },
        grid: {
          containLabel: true,
          left: '6%',
          right: '6%',
          bottom: "5%",
          top: "12%"
        },
        xAxis: [
          {
            name:"单位",
            data: [],
            axisLabel: {
              color: 'white',
              fontWeight: 'bold',
              rotate: 40,
              formatter: value => truncateStr(value, 10),
            },
            show: true,
            axisLine: { show: false, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'category',
          }
        ],
        yAxis: [
          {
            name:'人数',
            nameTextStyle: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value'
          },
          {
            nameTextStyle: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '10%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
              formatter: function(value) {
                return value ? value + '%' : value;
              },
            },
            saxisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
            show: false
          }
        ],
        series: [
          {
            name: '自有员工',
            type: 'bar',
            barWidth: 10,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: 'A类派遣',
            type: 'bar',
            barWidth: 10,
            emphasis: {
              focus: 'series'
            },
            data: [],
          },
          {
            name: 'B类派遣',
            type: 'bar',
            barWidth: 10,
            emphasis: {
              focus: 'series'
            },
            data: [],
          },
          {
            name: '合计',
            type: 'bar',
            barWidth: 10,
            emphasis: {
              focus: 'series'
            },
            data: [],
            label: {
                normal: {
                    show: true,
                    position: 'top',
                    formatter: '{c}',
                    textStyle: {
                        color: 'white',
                         fontWeight:'bold'
                    }
                }
            }
          }
        ]
      },
      personSource_option: {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          icon: 'circle',
          bottom: 0,
          itemGap: 20,
          itemWidth: 8,
        },
        yAxis:{
          show:false
        },
        series: [
          {
            name: '人员来源',
            type: 'pie',
            radius: '45%',
            label: {
              fontWeight:'bold',
              rich: {
                rich_blue: {
                  color: '#4D88FE',
                },
                rich_orange: {
                  color: '#FFBF3C',
                },
                rich_green: {
                  color: '#50CCCB',
                },
              },
            },
            data: [],
            labelLine: {
              length: 10,
              length2: 60,
            },
            itemStyle: {
              normal: {
                borderColor: '#fff',
                borderWidth: 1
              },
            },
          }
        ]
      },
      avgAttendanceTopLists: {},
      avgAttendanceTopListOption: "project_avg_h_top",
      WorkersBarChart: {},
      option3: {
        color: ["#23cf9e", "#fdc81e"],
        grid: {
          contentLabel: true,
          right: '10%',
          top: "13%",
          left: "15%",
          bottom: "13%"
        },
        legend: {
          data: [],
          textStyle:{
            fontSize: 11,//字体大小
            color: '#ffffff'//字体颜色
          },
        },
        tooltip: {
          trigger: 'axis',
          // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
          formatter: function (params) {
            var result = ``;
            if(params&&params.length){
                result += `${params[0].name}<br>`+params[0].marker + " " + params[0].seriesName + " : " + params[0].data;
                if(params[1]){
                    result += "</br>"+params[1].marker + " " + params[1].seriesName + " : " + params[1].data;
                }
                
            }
            return result;
          }
        },
        xAxis: [
          {
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
              rotate: 35
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'category',
            data: []
          }
        ],
        yAxis: [
          {
            name: "工资总额：万",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
            show: true
          },
          {
            show: true,
            name: "代付率：%",
            min: 0,
            max: 100,
            interval: 25,
            nameTextStyle: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            barWidth: 10,
            data: []
          },
          {
            name: '数量',
            type: 'line',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2
                }
              }
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                {offset: 1, color: 'rgba(27, 208, 128, 0)'}
              ])
            },
            symbolSize: 0,
            yAxisIndex: 1,
            data: [],
          }
        ]
      },
      mode: "1",
      popupShow1: false,
      noActionTroubleBarChart: {},
      option4: {
        color: ['#23cf9e', '#eab91e', '#20bbee'],
        grid: {
          top: '20%',
          bottom: '1%',
          left: "5%",
          right: "5%",
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          icon: 'rect',
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 12,
          textStyle: {
            color: '#ccc',
            fontWeight: 'bold',
            fontSize: '11'
          },
          padding: [8, 0, 0, 0],
          top: '4%',
          right: '3%',
        },
        xAxis: {
          axisLabel: {
            color: '#e6f2f3',
            rotate: 40,
            fontWeight: 'bold',
          },
          show: true,
          axisLine: { show: true, lineStyle: { color: '#36445f' } },
          splitLine: { show: false },
          axisTick: { show: false },
          type: 'category',
          data: []
        },
        yAxis: [{
          type: 'value',
          show: true,
          name: '人数：人',
          nameTextStyle: {
            padding: [0, 40, 0, 0],
            color: '#e6f2f3',
            fontWeight: 'bold',
          },
          minInterval: 1,
          axisLine: {
            show: true,
            lineStyle: { color: '#36445f' }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#36445f',
              type: [1, 1],
              width: 1,
            }
          },
          axisTick: { show: false },
          axisLabel: {
            color: '#e6f2f3',
            fontWeight: 'bold',
          }
        }],
        series: [
          {
            type: 'bar',
            stack: '',
            barMaxWidth: 9,
            barGap: 0,
            data: []
          },
          {
            type: 'bar',
            stack: '',
            barMaxWidth: 9,
            data: [],
          },
          {
            type: 'bar',
            stack: '',
            barMaxWidth: 9,
            data: [],
          }
        ]
      },
      popupCount: 0,
      popupname: '在场工人数',
      workPieChart1: {},
      workPieChart2: {},
      workPieChart3: {},
      popupShow2: false,
      popupSelect: '2',
      attenceWorkersBarChart: {},
      option5: {
        color: ['#1bd080', '#1bd080'],
        grid: {
          contentLabel: true,
          right: '6%',
          bottom: "15%",
          top: "13%",
          left: "8%"
        },
        legend: {
          data: [],
          textStyle:{
            fontSize: 11,//字体大小
            color: '#ffffff'//字体颜色
          },
        },
        tooltip: {
          trigger: 'axis',
          formatter: "{b}<br>{a0}: {c0}"
        },
        xAxis: [
          {
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'category',
            data: []
          }
        ],
        yAxis: [
          {
            show: true,
            name: "",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            boundaryGap: [0, '20%'],
            axisLabel: {
              color: '#e6f2f3',
              fontWeight: 'bold',
            },
            axisLine: { show: true, lineStyle: { color: '#36445f' } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: 'value',
          }
        ],
        series: [
          {
            name: "",
            type: 'bar',
            barWidth: 10,
            data: []
          }
        ]
      },
      popupBottomName: '',
      popupSearchkeys: [],
      popupData: [],
      popupXAxisData: [],
      popupData2: [],
      popupData2Num: 0,
      org_id: '',
      popupShow3: false,
      isFirst: true,

      mapLevel: 0,
      activeIndex:0,
      popupShow: false,
      popupSelect2: 1,
      popupShowSearchKey:[],
      popupTitle: "",
      popProjectsList: [],
      securityStarPage: 1,
      securityStarPages: 1,

      popupShow4: false,
      selectedVal2:'全部分公司',
        popupSelect3: '1',
        workerMoneysBarChart: {},
        workerMoneysBarChartTemplate: {
          color: ["#23CF9E", "#14B0BB"],
          grid: {
            contentLabel: true,
            right: '5%',
            top: "13%",
            left: "5%",
            bottom: "15%"
          },
          legend: {
            data: [],
            textStyle:{
              fontSize: 11,//字体大小
              color: '#ffffff'//字体颜色
            },
          },
          tooltip: {
            trigger: 'axis',
            formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
          },
          xAxis: [
            {
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // rotate: 35
              },
              show: true,
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'category',
              data: []
            }
          ],
          yAxis: [
            {
              name: "工资总额：万元",
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
              show: true
            },
            {
              show: true,
              name: "代付率：%",
              min: 0,
              max: 100,
              interval: 25,
              nameTextStyle: {
                color: '#e6f2f3',
                fontWeight: 'bold',
              },
              boundaryGap: [0, '20%'],
              axisLabel: {
                color: '#e6f2f3',
                fontWeight: 'bold',
                // formatter: function(value) {
                //   return value ? value + '%' : value;
                // },
              },
              axisLine: { show: true, lineStyle: { color: '#36445f' } },
              splitLine: { show: false },
              axisTick: { show: false },
              type: 'value',
            }
          ],
          series: [
            {
              name: '工资总额',
              type: 'bar',
              barWidth: 10,
              data: []
            },
            {
              name: '代付率',
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2
                  }
                }
              },
              areaStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: 'rgba(27, 208, 128, 0.3)'},
                  {offset: 1, color: 'rgba(27, 208, 128, 0)'}
                ])
              },
              symbolSize: 0,
              yAxisIndex: 1,
              data: [],
            }
          ]
        },
        // org_id: '',
        popupBottomName3: '',
        popupSearchkeys3: [],
        popupXAxisData3: [],
        popupBarData: [],
        popupData3: [],
        securityStarPage3: 1,
        securityStarPages3: 1,
        dataIndex: -1,

    };
  },
  watch: {
    orgOptions(newValue, oldValue) {
      if (newValue != oldValue) {
        this.companyNameList = newValue.map(item => truncateStr(item.org_name, 10));
        this.companyIdList = newValue.map(item => item.org_id);
      }
    },
    orgLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.getAll();
      }
    },
    districtLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
        this.getAll();
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.mapLevel = newValue;
      }
    },
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;
    this.getAll();
  },
  methods: {
    getAll(){
      this.switchCompanyWorkersOption(this.companyWorkersOption);
      this.swithAttendance(this.mode);
    },
    handleSelect(item) {
      nation.getProjectToken(item.project_id)
        .then((d) => {
          this.$router.push(`/project2/${item.project_id}/1/0?token=${d.token}&org_name=${item.project_name}`)
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    
    companyWorkersBarChartClick(params){
      if(this.mapLevel!==2){
        // console.log(params)
        this.popupSelect2=this.companyWorkersOption;
        this.popupShow = true;
        this.popupTitle = params.name ;
        this.securityStarPage=1;
        this.activeIndex=params.dataIndex;
        this.securityStarPageChange(1);
      }
      
    },
    securityStarPageChange(currPage) {
      nation
        .getLaborWorkerCountCollect(this.popupSelect2,currPage, 10,this.mapLevel==0?this.popupShowSearchKey[this.activeIndex]:'',this.mapLevel==1?this.popupShowSearchKey[this.activeIndex]:'')
        .then((d) => {
          this.popProjectsList = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },

    getAttendance2(popupSelect) {
      this.popupSelect2 = popupSelect;
      this.securityStarPage=1;
      this.securityStarPageChange(1)
    },
    swithAttendance(mode) {
      this.mode = mode;
    },
    switchCompanyWorkersOption(choose){
      this.companyWorkersOption = choose;
      switch (choose) {
        case "1":
          this.queryEducation(choose);
          break;
        case "2":
          this.queryUnitStaffSize(choose);
          break;
        case "3":
          this.queryPersonalSource(choose);
          break;
      }
    },
    queryEducation(choose){
            this.educationData = [];
            human.getPersonEducation().then((result) => {
            let first_edu = [];
            let highEdu = [];
            let schoolCategory = [];
            // 格式化学历数据
            let high_edu_list = result['最高学历汇总'];
            var dataMap = {"本科及以上":0,"大学专科":0,"中专及以上":0,};
            for(let k=0;k<high_edu_list.length;k++){
              let eduLevel = high_edu_list[k].USER_KIND;
              switch (eduLevel) {
                case "大学本科":
                case "硕士研究生":
                case "本科":
                  dataMap.本科及以上 += high_edu_list[k].USER_NUM;
                  break;
                case "大学专科":
                case "专科":
                  dataMap.大学专科 += high_edu_list[k].USER_NUM;
                  break;
                case "高中":
                case "中等专科":
                case "技工学校":
                case "职业高中":
                case "中技（中专/技校/职高）":
                  dataMap.中专及以上 += high_edu_list[k].USER_NUM;
                  break;
                default:
                  continue;
              }
            }
            highEdu.push({
              "name":"本科及以上",
              "value":dataMap.本科及以上
              },
              {"name":"大学专科",
              "value":dataMap.大学专科
              },
              {"name":"中专及以上",
              "value":dataMap.中专及以上,
            });
            let first_edu_list = result['第一学历汇总'];
            var first_edu_dataMap = {"本科及以上":0,"大学专科":0,"中专及以上":0};
            for(let n=0;n<first_edu_list.length;n++){
              let eduLevel = first_edu_list[n].USER_KIND;
              switch (eduLevel) {
                case "大学本科":
                case "硕士研究生":
                case "本科":
                  first_edu_dataMap.本科及以上 += first_edu_list[n].USER_NUM;
                  break;
                case "大学专科":
                case "专科":
                  first_edu_dataMap.大学专科 += first_edu_list[n].USER_NUM;
                  break;
                case "高中":
                case "中等专科":
                case "技工学校":
                case "职业高中":
                case "中技（中专/技校/职高）":
                  first_edu_dataMap.中专及以上 += first_edu_list[n].USER_NUM;
                  break;
                default:
                  continue;
              }
            }
            first_edu.push({
              "name":"本科及以上",
              "value":first_edu_dataMap.本科及以上
              },
              {"name":"大学专科",
              "value":first_edu_dataMap.大学专科
              },
              {"name":"中专及以上",
              "value":first_edu_dataMap.中专及以上,
            });
            let schoolCategory_list = result['学校类型汇总'];
            for(let p=0;p<schoolCategory_list.length;p++){
              let data_2 = {};
              data_2['name'] = schoolCategory_list[p].SCHOOLTYPE;
              data_2['value'] = schoolCategory_list[p].NUM;
              schoolCategory.push(data_2);
            }
            this.educationData.push(first_edu,highEdu,schoolCategory);
            this.updateCompanyWorkersChart(choose,this.educationData);
        })
    },
    queryUnitStaffSize(choose){
      // 查询各单位人员结构数量
      human.getStaffScale().then((list) =>{ 
          var sNames = [];
          let Their_own_emp = [];
          let dispath_emp = [];
          let B_dispath_emp = [];
          for(var i = 0;i<list.length;i++){
            sNames.push(list[i].name);
            let subData = list[i].subData;
            if (subData[0].value  === undefined){
              Their_own_emp.push(0);
            }else{
              Their_own_emp.push(subData[0].value);
            }
            if (subData[1] === undefined){
              dispath_emp.push(0);
            }else{
              dispath_emp.push(subData[1].value);
            }
            if (subData[2]  === undefined){
              B_dispath_emp.push(0);
            }else{
              B_dispath_emp.push(subData[2].value);
            } 
        }
        var totals = [];
        for(let i = 0;i<Their_own_emp.length;i++){
          totals.push(Their_own_emp[i] + dispath_emp[i] + B_dispath_emp[i]);
        }
        let data = [];
        data.push(sNames,Their_own_emp,dispath_emp,B_dispath_emp,totals);
        this.updateCompanyWorkersChart(choose,data);
      })
    },
    queryPersonalSource(choose){
      human.getPersonalSource().then((d =>{
        this.updateCompanyWorkersChart(choose,d);
      }))
    },
    updateCompanyWorkersChart(type, data) {
      const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
      // const data = this.companyWorkersData[type];
      if (type == '1') {
        this.popupname = "学历统计";
         for (let index =0; index < data.length; index++) {
           this.option.series[index].data = data[index];
           this.option.series[index].type = 'pie';
         }
        this.option.title = [{
            subtext: '第一学历统计',
            left: '15.67%',
            top:'5%',
            textAlign: 'center',
            subtextStyle:
            {
              fontSize:14,
              color:'white',
              fontWeight:'bold'
            }
          }, {
            subtext: '最高学历统计',
            left: '50%',
            top:'5%',
            textAlign: 'center',
            subtextStyle:{
              fontSize:14,
              color:'white',
              fontWeight:'bold'
            }
          }, {
            subtext: '院校类别统计',
            left: '83.33%',
            top:'5%',
            textAlign: 'center',
            subtextStyle:{
              fontSize:14,
              color:'white',
              fontWeight:'bold'
            }
			}]
        this.option.color = colors;
        this.option.xAxis.show = false;
        this.option.yAxis.show = false;
      } else if (type == '2') {
        this.popupname = "人员统计"
        this.option2.xAxis[0].data = data[0];
        for (let index = 1,i=0; index < data.length,i<4; index++,i++) {
          this.option2.series[i].data = data[index];
          this.option2.series[i].stack = 'total';
          this.option2.series[i].type = 'bar';
          this.option2.series[i].barWidth = 15;
        }
        this.option2.xAxis.show = true;
        this.option2.yAxis.show = true;
        this.companyWorkersBarChart = applyUpdate(chart, this.option2);
        return;
      } else if (type == '3') {
        this.personSource_option.series[0].name = "人员来源";
        let data_list = [];
        for(var i=0;i<data.length;i++){
          var rs= {};
          rs['name'] = data[i].USER_SOURCE;
          rs['value'] = data[i].USER_NUM;
          data_list.push(rs);
        }
        this.personSource_option.series[0].data = data_list;
        this.personSource_option.color = colors;
        this.companyWorkersBarChart = applyUpdate(chart, this.personSource_option);
        return;
      }
      this.companyWorkersBarChart = applyUpdate(chart, this.option);
    },
    companySelect(option, val) {
      const idx = this.companyNameList.indexOf(option);
      if (idx >= 0) {
        this.org_id = this.companyIdList[idx];
        // window.$orgLevel = this.org_id;
        if(val === 'val1'){
          this.selectVal1 = option;
          this.getAttendance(this.popupSelect);
        }else if(val === 'val2'){
          // eslint-disable-next-line
          this.selectVal2 = option;
          if(!this.isFirst){
            this.getPieChart();
          }
        }else if(val === 'val3'){
          // eslint-disable-next-line
          this.selectVal3 = option;
          if(!this.isFirst){
            this.getPieChart2();
          }
        }else if(val === 'val4'){
          this.selectVal4 = option;
          if(!this.isFirst){
            this.getPieChart3();
          }
          // eslint-disable-next-line
        }else{
          // 55555
          this.selectedVal2 = option; 
          this.getAttendance3(this.popupSelect3);

        }
        // console.log(this.companyIdList[idx]);
      }
    },
    showDetail(val) {
      console.log(val);
    },
    
      getAttendance3(popupSelect){
        this.popupSelect3 = popupSelect;
        nation.getLaborSalaryDateCollect( this.popupSelect3, this.org_id ? this.org_id:'')
          .then(d => {
            
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
            this.workerMoneysBarChartTemplate.legend.data = d.legend;
            this.workerMoneysBarChartTemplate.xAxis[0].data = d.xAxis_data;
            d.bar_series[d.bar_series.length-1] = {
              value: d.bar_series[d.bar_series.length-1],
              itemStyle: {
                color: '#FFD153'
              }
            };
            this.workerMoneysBarChartTemplate.series[0].data = d.bar_series;
            this.workerMoneysBarChartTemplate.series[0].name = d.legend[0];
            this.workerMoneysBarChartTemplate.series[1].data = d.line_series;
            this.workerMoneysBarChartTemplate.series[1].name = d.legend[1];
            this.workerMoneysBarChart = applyUpdate(chart, this.workerMoneysBarChartTemplate);

            this.popupBottomName3 = `${this.workerMoneysBarChartTemplate.xAxis[0].data[this.workerMoneysBarChartTemplate.xAxis[0].data.length-1]}工资情况`;
            this.getLaborAttendanceDateOrgCountCollect(d.search_keys[d.search_keys.length-1]);
            this.popupSearchkeys3 = d.search_keys;
            this.popupXAxisData3 = d.xAxis_data;
            this.popupBarData = d.bar_series;
          })
      },
      workerMoneysBarChartClick(params){
        // console.log(params);
        // console.log(params.dataIndex);
        let subData = this.popupBarData;
        this.workerMoneysBarChart.series[0].data = subData.map(function (item, index) {
          return index === params.dataIndex ? {
              value: Object.prototype.toString.call(item) === '[object Object]' ? item.value:item,
              itemStyle: {
                color: '#FFD153'
              }
            }:(Object.prototype.toString.call(item) === '[object Object]' ? item.value:item)
        });
        this.popupBottomName3 = `${this.popupXAxisData3[params.dataIndex]}工资情况`;
        this.getLaborAttendanceDateOrgCountCollect(this.popupSearchkeys3[params.dataIndex]);
        this.dataIndex = params.dataIndex;
        this.securityStarPage3=1;
      },
      getLaborAttendanceDateOrgCountCollect(search_keys){
        // window.$orgLevel = this.org_id;
        nation.getLaborSalaryOrgProjectCollect(this.popupSelect3,this.securityStarPage3, 5, this.org_id ? this.org_id:'',search_keys)
          .then(d => {
            // console.log(d);
            this.popupData3 = d;
            this.securityStarPages3 = Math.ceil(d.__count__ / 5);
          })
      },
      securityStarPageChange3(currPage) {
        // console.log(currPage);
        this.securityStarPage3 = currPage;
        this.getLaborAttendanceDateOrgCountCollect(this.popupSearchkeys3[this.dataIndex]);
      },
    getWorkCount(){
       nation.getLaborWorkerCountOrgCollect()
        .then(d => {
          // console.log(d);
          const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
          this.option4.xAxis.data = d.xAxis_data;
          this.option4.legend.data = d.legend_data;
          this.option4.series[0].name = d.legend_data[0]
          this.option4.series[0].data = d.series[0]
          this.option4.series[1].name = d.legend_data[1];
          this.option4.series[1].data = d.series[1]
          this.option4.series[2].name = d.legend_data[2];
          this.option4.series[2].data = d.series[2]
          this.popupCount = eval(d.series[Number(this.companyWorkersOption)-1].join("+"));
          this.noActionTroubleBarChart = applyUpdate(chart, this.option4);
        })
    },
    getPieChart(){
      nation.getLaborWorkerCountWorkerAttrCollect(1, this.org_id ? this.org_id:'')
        .then(d => {
          // console.log(d)
          this.isFirst = false;
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
          chart.series[0].label.normal.formatter = attendancePieChartTemplate.series[0].label.normal.formatter;
          chart.color = colors;

          const maxPieces = 14;
          if (d.length > maxPieces) {
            d[maxPieces - 1].value = d.slice(maxPieces - 1).reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
            d[maxPieces - 1].name = '其它工种';
            d = d.slice(0, maxPieces);
          }

          chart.series[0].data = d;

          // 默认高亮第一个值不是0的分片，否则默认第一片
          this.workerTypePieChartHighlightIndex = 0;
          for (let i = 0; i < d.length; i++) {
            if (d[i].value) {
              this.workerTypePieChartHighlightIndex = i;
              break;
            }
          }

          chart.series.push(Object.assign({}, chart.series[0], { label: {
            rich: {
              text: {
                fontSize: 11
              }
            },
            formatter: function (params) {
              return `${params.name} ${Math.round(params.percent)}%`;
            },
          }, silent: true }));

          setTimeout(
            () =>
              this.$refs.workerType &&
              this.$refs.workerType.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: this.workerTypePieChartHighlightIndex
              }),
            100
          );
          this.workPieChart1 = chart;
        })
    },
    getPieChart2(){
      nation.getLaborWorkerCountWorkerAttrCollect(2, this.org_id ? this.org_id:'')
        .then(d => {
          // console.log(d)
          this.isFirst = false;
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
          chart.series[0].label.normal.formatter = attendancePieChartTemplate.series[0].label.normal.formatter;
          chart.color = colors;

          const maxPieces = 14;
          if (d.length > maxPieces) {
            d[maxPieces - 1].value = d.slice(maxPieces - 1).reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
            d[maxPieces - 1].name = '其它';
            d = d.slice(0, maxPieces);
          }
          chart.series[0].data = d;

          // 默认高亮第一个值不是0的分片，否则默认第一片
          this.workerTypePieChartHighlightIndex = 0;
          for (let i = 0; i < d.length; i++) {
            if (d[i].value) {
              this.workerTypePieChartHighlightIndex = i;
              break;
            }
          }

          chart.series.push(Object.assign({}, chart.series[0], { label: {
            rich: {
              text: {
                fontSize: 11
              }
            },
            formatter: function (params) {
              return `${params.name} ${Math.round(params.percent)}%`;
            },
          }, silent: true }));

          setTimeout(
            () =>
              this.$refs.workerType &&
              this.$refs.workerType.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: this.workerTypePieChartHighlightIndex
              }),
            100
          );
          this.workPieChart2 = chart;
        })
    },
    getPieChart3(){
      nation.getLaborWorkerCountWorkerAttrCollect(3, this.org_id ? this.org_id:'')
        .then(d => {
          // console.log(d)
          this.isFirst = false;
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
          chart.series[0].label.normal.formatter = attendancePieChartTemplate.series[0].label.normal.formatter;
          chart.color = colors;

          const maxPieces = 14;
          if (d.length > maxPieces) {
            d[maxPieces - 1].value = d.slice(maxPieces - 1).reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
            d[maxPieces - 1].name = '其它';
            d = d.slice(0, maxPieces);
          }
          chart.series[0].data = d;

          // 默认高亮第一个值不是0的分片，否则默认第一片
          this.workerTypePieChartHighlightIndex = 0;
          for (let i = 0; i < d.length; i++) {
            if (d[i].value) {
              this.workerTypePieChartHighlightIndex = i;
              break;
            }
          }

          chart.series.push(Object.assign({}, chart.series[0], { label: {
            rich: {
              text: {
                fontSize: 11
              }
            },
            formatter: function (params) {
              return `${params.name} ${Math.round(params.percent)}%`;
            },
          }, silent: true }));

          setTimeout(
            () =>
              this.$refs.workerType &&
              this.$refs.workerType.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: this.workerTypePieChartHighlightIndex
              }),
            100
          );
          this.workPieChart3 = chart;
        })
    },
    getAttendance(popupSelect){
      this.popupSelect = popupSelect;
      nation.getLaborAttendanceDateCollect(this.popupSelect, this.org_id ? this.org_id:'', this.mode)
        .then(d => {
          // console.log(d);
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option5.xAxis[0].data = d.xAxis_data;
          this.popupData = d.series;
          d.series[d.series.length-1] = {
            value: d.series[d.series.length-1],
            itemStyle: {
              color: '#4191E5'
            }
          };
          this.option5.series[0].data = d.series;
          // chart.series = [chart.series[0]];
          // chart.xAxis[0].axisLabel.rotate = 45;
          // chart.yAxis = [chart.yAxis[0]];
          if(this.mode === '1'){
            this.option5.yAxis[0].name = '出勤率：%';
            this.option5.series[0].name = '出勤率';
            this.popupBottomName = `${d.xAxis_data[d.series.length-1]}考勤排行`;
          }else{
            this.option5.yAxis[0].name = '人数：人';
            this.option5.series[0].name = '人数';
            this.popupBottomName = `${d.xAxis_data[d.series.length-1]}月均值排行`;
          }
          this.attenceWorkersBarChart = applyUpdate(chart, this.option5);
          this.getLaborAttendanceDateOrgProjectCollect(d.search_keys[d.search_keys.length-1]);
          this.popupSearchkeys = d.search_keys;
          this.popupXAxisData = d.xAxis_data;
        })
    },
    attenceWorkersBarChartClick(params){
      // console.log(params);
      // console.log(params.dataIndex);
      let subData = this.popupData;
      // this.attenceWorkersBarChart = Object.assign({}, this.attenceWorkersBarChart, {
      //   series: {
      //     data: subData.map(function (item, index) {
      //       return index === params.dataIndex ? {
      //           value: Object.prototype.toString.call(item) === '[object Object]' ? item.value:item,
      //           itemStyle: {
      //             color: '#4191E5'
      //           }
      //         }:(Object.prototype.toString.call(item) === '[object Object]' ? item.value:item)
      //     }),
      //   }
      // });
      this.attenceWorkersBarChart.series[0].data = subData.map(function (item, index) {
        return index === params.dataIndex ? {
            value: Object.prototype.toString.call(item) === '[object Object]' ? item.value:item,
            itemStyle: {
              color: '#4191E5'
            }
          }:(Object.prototype.toString.call(item) === '[object Object]' ? item.value:item)
      });
      this.popupBottomName = `${this.popupXAxisData[params.dataIndex]}考勤排行`;
      this.getLaborAttendanceDateOrgProjectCollect(this.popupSearchkeys[params.dataIndex]);
    },
    getLaborAttendanceDateOrgProjectCollect(search_keys){
      console.log(11);
      // window.$orgLevel = this.org_id;
      nation.getLaborAttendanceDateOrgProjectCollect(this.popupSelect, search_keys, 1000000, this.org_id ? this.org_id:'', this.mode)
        .then(d => {
          // console.log(d);
          this.popupData2 = d.series.map((item,index) => {
              return {value: item, name: d.yAxis_data[index]};
          });
          // this.popupData2Num = eval(d.series.join("+"))
          this.popupData2Num = d.series.reduce((prev, cur) => {
              return prev + cur;
          },0);
        })
    }
  },
};
</script>

<style scoped lang="less">
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}

.option-block2 {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3.4rem;
  border: 1px solid #1b8ebd;
  text-align: center;
  cursor: pointer;
}
.option-block-selected{
  background-color: transparent !important;
  border: 1px solid #274C7D !important;
}
.option-block-selected2 {
  background-color: #4191e5;
  color: #fff;
}
.attendance-top{
  padding-top: 10px;
}
.tabbar{
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  border: 1px solid #157EAB;
  width: 210px;
  margin: auto;
  cursor: pointer;
}
.tabbar div{
  flex: 1;
  text-align: center;
}
.tabar-select{
  background-color: #4191E5;
  font-weight: bold;
}
.items div{
  color: #fff !important;
}
.selected{
  color: #fff !important;
}
.linebar{
  margin-top: 5rem;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto 10px;
}
.real-bar{
  height: 5px;
  background-color: #57C7B6;
  border-radius: 12px;
}
</style>