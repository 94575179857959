<template>
  <div class='v-number'>
    <transition :name='"v-number-current-value" + transNameSuffix'>
      <div
        v-if='currentValue !== null'
        :style='transitionDuration'
        class='v-number__animated'
      >
        {{ currentValue }}{{ suffix }}
      </div>
    </transition>
    <transition :name='"v-number-new-value" + transNameSuffix'>
      <div
        v-if='newValue !== null'
        :style='transitionDuration'
        class='v-number__animated'
      >
        {{ newValue }}{{ suffix }}
      </div>
    </transition>

    <!-- Always display the value to keep DIV dimensions up to date -->
    <span class='v-number__original-value'>
      {{ value }}{{ suffix }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'v-number',
  props: {
    value: {
      required: true,
      validator(value) {
        return value == null || !isNaN(value)
      }
    },
    // Animation speed
    speed: {
      type: Number,
      default: 500,
    },
    suffix: {
      type: String,
      default: '',
    }
  },
  watch: {
    value(newValue, oldValue) {
      /*
      if (this.isAnimating) {
        return
      }
      */

      if (newValue != oldValue) {
        const goUp = newValue > oldValue;
        this.transNameSuffix = 'none';
        this.isAnimating = true
        this.currentValue = null
        this.newValue = oldValue
        setTimeout(() => {
          this.currentValue = oldValue
          this.newValue = null
          this.transNameSuffix = goUp ? '2' : '';
          setTimeout(() => {
            this.currentValue = null
            this.newValue = newValue
            setTimeout(() => {
              this.currentValue = newValue
              this.newValue = null
              this.isAnimating = false
            }, this.speed)
          }, 0)
        }, 0)
      }
    }
  },
  data() {
    return {
      currentValue: null,
      newValue: null,
      isAnimating: false,
      transNameSuffix: '',
    }
  },

  created() {
    this.currentValue = this.value
  },
  computed: {
    transitionDuration() {
      return {
        transitionDuration: this.speed + 'ms'
      }
    }
  }
}
</script>

<style lang='less' scoped>
.v-number {
  overflow: hidden;
  position: relative;
  display: inline-block;

  &__original-value {
    visibility: hidden;
  }
  &__animated {
    position: absolute;
    transition-property: top;
    transition-timing-function: ease-in-out;
  }
  &-new-value {
    &-enter {
      top: -100%;
      &-to {
        top: 0;
      }
    }
  }
  &-current-value {
    &-leave {
      top: 0;
      &-to {
        top: 100%;
      }
    }
  }
  &-new-value2 {
    &-enter {
      top: 100%;
      &-to {
        top: 0;
      }
    }
  }
  &-current-value2 {
    &-leave {
      top: 0;
      &-to {
        top: -100%;
      }
    }
  }}
</style>
