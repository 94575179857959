<template>
  <div class="info-block" style="min-height:5rem">
    <div class="info-block-title" style="display:flex;width:100%;padding-right:3.1rem">
      <div style = "flex:1">截止年初可用资金余额</div>
      <div class="detail" style="cursor:pointer" @click="showDetail()">详情</div>
    </div>
    <div style="height:19rem;padding-top:3rem">
      <div
        v-if="!dangerTypePieChart.series || !dangerTypePieChart.series[0].data.length"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart v-else :options="dangerTypePieChart" ref="dangerType" autoresize />
    </div>
    <!-- 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                <el-table style="background-color:#0E1328" height="28rem" :data="tableData" :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column
                        prop="branchName"
                        label="分公司">
                        </el-table-column>
                    <el-table-column
                        prop="assessUnit"
                        label="考核分类机构">
                    </el-table-column>
                    <el-table-column label="截止年初净流情况">
                        <el-table-column
                        prop="byAccNetCapitalFlow"
                        label="截至年初累计资金净流"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                    截至年初累计资金净流
                                <el-tooltip :aa="scope" class="item" effect="dark" content="自分公司成立以来所有项目收款-支付-分公司管理费。此指标为负，说明分公司整体是垫资，入不敷出。注：办理信用证当月即算支出，已扣除，到期偿还时不再重复扣" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byLimitedCapital"
                        label="年初受限资金"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                    年初受限资金
                                <el-tooltip :aa="scope" class="item" effect="dark" content="监管户、农民工工资户、抵入未抵出房产余额" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byAccCanDepositCapital"
                        label="截至年初累计剩余可上存资金"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                    截至年初累计剩余可上存资金
                                <el-tooltip :aa="scope" class="item" effect="dark" content="可上存资金就是截至年初累计资金净流减去年初受限资金。
此指标可理解为银行活期资金，使用灵活，这部分优先交公司收益，如交完收益后有剩余可以作为分公司存款也可以请款使用。
比如南方分，资金净流为21.4亿，受限资金0.7亿，那么可上存资金为20.7亿，上交完公司收益10.9亿后还有9.8可用，9.8亿可作为定存也可以请款" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="截至年初收益情况">
                        <el-table-column
                        prop="byAccBranchIncome"
                        label="截至年初分公司累计收益"
                        :formatter="Formatter">
                        </el-table-column>
                        <el-table-column
                        prop="byPayableIncome"
                        label="截至年初应交收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                    截至年初应交收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="从有现金形式上缴收益考核（2020年）开始，考核开始年现金形式上交收益考核数加上2020年后每年预算数之和" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byAccPaidIncomeByCash"
                        label="截至年初累计现金形式上交收益"
                        :formatter="Formatter">
                        </el-table-column>
                        <el-table-column
                        prop="byUnpaidIncome"
                        label="截至年初欠交公司收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                截至年初欠交公司收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="从有现金形式上缴收益考核（2020年）开始每年预算数之和-每年实际已上缴数之和" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="截至年初资金余额（交收益后）">
                        <el-table-column
                        prop="byBalanceAmount"
                        label="合计"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                合计
                                <el-tooltip :aa="scope" class="item" effect="dark" content="在公司存/借款减去欠交收益加上受限资金" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceDeposit"
                        label="其中：公司存/借款"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：公司存/借款
                                <el-tooltip :aa="scope" class="item" effect="dark" content="分公司截止年初可上存资金减去累计上交收益余额。正数表示在公司存款，负数表示欠公司钱" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceUnpaid"
                        label="其中：欠交公司收益"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：欠交公司收益
                                <el-tooltip :aa="scope" class="item" effect="dark" content="从有现金形式上缴收益考核（2020年）开始每年预算数之和-每年实际已上缴数之和" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="byBalanceLimited"
                        label="其中：受限资金"
                        :formatter="Formatter">
                            <template slot-scope="scope" slot="header">
                                <span>
                                其中：受限资金
                                <el-tooltip :aa="scope" class="item" effect="dark" content="监管户、农民工工资户、抵入未抵出房产余额" placement="top-start">
                                    // class指定icon图标 style可以调图标颜色等样式
                                    <i class= 'el-icon-question' style="color:#409eff; margin-left:5px;'"> </i>
                                </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { finance } from '../../../../api_finance'
  import wrapper from "../../../../components/wrap";
import { colors } from '../../pieChartTemplates'
  // import { attendancePieChartTemplate, colors } from '../../pieChartTemplates';
  // import { applyUpdate } from "../../../../utils";

  export default {
    name: 'UsableFundBalanceChart',
    components: {
      'v-chart': ECharts,
      wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
    watch: {
      orgOptions(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateUsableFundBalanceChart();
        }
      },
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateUsableFundBalanceChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateUsableFundBalanceChart();
        }
      }
    },
    data () {
      return {
        dangerTypePieChart: {},
        dangerTypePieChartHighlightIndex: null,
        popupShow:false,
        tableData:[]
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.updateUsableFundBalanceChart();
    },
    methods: {
        toFixed(val){
            return (Math.floor(val * 100) / 100).toFixed(2);
        },
        Formatter(row, column, cellValue){
            return finance.formatDecimal(cellValue,2);
        },
      showDetail(){
        this.popupShow = true;
      },
      tableRowStyle({rowIndex}){
        if(rowIndex%2 === 0)
            return {backgroundColor:'#213a77',height: '0'}; 
        else   
            return {backgroundColor:'#102855',height: '0'}; 
      },
      tableHeaderColor(){
        return 'color:#4fb5da;background:#102855';
      },
      updateUsableFundBalanceChart() {
        var deepCopy = (newobj, oldobj) => {
            for (let k in oldobj) {
                let item = oldobj[k];
                if (item instanceof Array) {
                    newobj[k] = [];
                    deepCopy(newobj[k], item);
                } else if (item instanceof Object) {
                    newobj[k] = {};
                    deepCopy(newobj[k], item);
                } else {
                    newobj[k] = item;
                }
            }
        }

        finance.queryBranchCapitalStatisticLine().then((res=>{
            this.tableData = res;
            var data1 = 0.00;
            var data2 = 0.00;
            var data3 = 0.00;
            var data4 = 0.00;
            for (let i = 0; i < res.length; i++) {
                data1 += finance.formatDecimal(res[i].byUnpaidIncome,2);
                data2 += finance.formatDecimal(res[i].companyPayIn,2);
                data3 += finance.formatDecimal(res[i].byLimitedCapital,2);
                data4 += finance.formatDecimal(res[i].byBalanceAmount,2);
            }
            let {category } = {
            category: [
                { name: '欠交公司收益', value:this.toFixed(data1)},
                { name: '总部需下拨款', value: this.toFixed(data2) },
                { name: '受限账户', value: this.toFixed(data3) },
            ]
        };

        let changeData = category.map((item=>{
            let changeItem = {};
            deepCopy(changeItem,item);
            return changeItem;
        }))
        changeData.map((item) => {
        for (let key in item) {
          if (item[key] < 0) {
            item[key] = JSON.stringify(Math.abs(item[key]));
          }
        }
      });
      const arrName = this.getArrayValue(changeData, "name");
      const optionData = this.getSeriesData(changeData,category);
      const option = {
        legend: {
            show: true,
            icon:"circle",
            top: "center",
            left: '70%',
            data: arrName,
            width:50,
            formatter: function (params) {
                let str = '';
                category.forEach((item) => {
                    if (item.name === params) {
                        str = `{title|${params}}\n{value|${item.value}亿}`;
                    }
                });
                return str;
            },
            textStyle: {
                rich: {
                    title: {
                        fontSize: 14,
                        lineHeight: 15,
                        color: "rgb(0, 178, 246)"
                    },
                    value: {
                        fontSize: 18,
                        lineHeight: 20,
                        color: "#fff"
                    }
                }
            },
        },
        title:{
                text: '合计' + '\n{a|' + this.toFixed(data4) + '亿' + '}',
                left: '23%',
                top: '45%',
                icon: 'none',
                textStyle: {
                    fontWeight: 'normal',
                    fontSize: 14,
                    color: '#fff',
                    rich: {
                        a: {
                            fontSize: 16,
                            padding: [0, 0, 10, 0],
                        },
                    },
                },
        },
        tooltip: {
            show: true,
            trigger: "item",
            formatter: function (params) {
                    let str = '';
                    category.forEach((item) => {
                        if (item.name === params.name) {
                            str = `${params.name} ${item.value}亿`;
                        }
                    });
                    return str;
                },
        },
        color:colors,
        grid: {
            top: '16%',
            bottom: '53%',
            left: "30%",
            containLabel: false
        },
        yAxis: [{
            type: 'category',
            inverse: true,
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                interval: 0,
                inside: true,
                textStyle: {
                    color: "#fff",
                    fontSize: 14,
                },
                show: true,
            },
            data: optionData.yAxis
        }],
        xAxis: [{
            show: false
        }],
        series: optionData.series
      }
          this.dangerTypePieChart = option;
        }))
      },
      getArrayValue(array, key) {
        var _key = key || "value";
        var res = [];
        if (array) {
            array.forEach(function(t) {
                res.push(t[_key]);
            });
        }
        return res;
    }, 
    array2obj(array,key) {
      var resObj = {};
      for(var i=0;i<array.length;i++){
        resObj[array[i][key]] = array[i];
      }
      return resObj;
    },
    getSeriesData(data,category) {
      const arrValue = this.getArrayValue(data, "value");
      const sumValue = eval(arrValue.join('+'));
        var res = {
            series: [],
            yAxis: []
        };
        for (let i = 0; i < data.length; i++) {
            // console.log([70 - i * 15 + '%', 67 - i * 15 + '%']);
            res.series.push({
                name: '',
                type: 'pie',
                clockWise: false, //顺时加载
                hoverAnimation: false, //鼠标移入变大
                radius: [73 - i * 15 + '%', 68 - i * 15 + '%'],
                center: ["30%", "55%"],
                label: {
                    show: false
                },
                itemStyle: {
                    label: {
                        show: false,
                    },
                    labelLine: {
                        show: false
                    },
                    borderWidth: 5,
                },
                data: [{
                    value: data[i].value,
                    name: data[i].name
                }, {
                    value: sumValue - data[i].value,
                    name: '',
                    itemStyle: {
                        color: "rgba(0,0,0,0)",
                        borderWidth: 0
                    },
                    tooltip: {
                        show: false
                    },
                    hoverAnimation: false
                }]
            });
            res.series.push({
                name: '',
                type: 'pie',
                silent: true,
                z: 1,
                clockWise: false, //顺时加载
                hoverAnimation: false, //鼠标移入变大
                radius: [73 - i * 15 + '%', 68 - i * 15 + '%'],
                center: ["30%", "55%"],
                label: {
                    show: false
                },
                itemStyle: {
                    label: {
                        show: false,
                    },
                    labelLine: {
                        show: false
                    },
                    borderWidth: 5,
                },
                data: [{
                    value: 7.5,
                    itemStyle: {
                        color: "#ACACAC",
                        borderWidth: 0
                    },
                    tooltip: {
                        show: false
                    },
                    hoverAnimation: false
                }, {
                    value: 2.5,
                    name: '',
                    itemStyle: {
                        color: "rgba(0,0,0,0)",
                        borderWidth: 0
                    },
                    tooltip: {
                        show: false
                    },
                    hoverAnimation: false
                }]
            });
            res.yAxis.push(category[i].value + "亿");
        }
        return res;
     },
    },
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
