<template>
  <div id="left">
    <wrapper style="padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>重点/示范/课题成果完成</div>
        </div>
        <div
          style="height:25rem;padding-top:2rem;align-items: end;"
        >
            <div
            v-if="outcomeChart.series && [].concat.apply([], outcomeChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart v-else :options="outcomeChart" autoresize />
        </div>
      </div>
    </wrapper>

    <wrapper style="margin-top: 1rem;padding:0">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>工作室成果统计</div>

        </div>
        <div style="height:25rem;display: flex;"
          class="machine_use">
          <div
            v-if="majorProjectChart.series && [].concat.apply([], majorProjectChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart
            v-else
            :options="majorProjectChart"
            ref="dangerType"
            @mouseover="dangerTypeMouseOver"
            @mouseout="dangerTypeMouseOut"
            autoresize
          />
        </div>
      </div>
    </wrapper>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { project } from "../../api";
import { tech } from "../../api_tech";
// import CustomSelect from '../../components/select'
import wrapper from "../../components/wrap";
import { stackedBarChartTemplate } from "./barChartTemplates";
import { attendancePieChartTemplate, colors } from "./pieChartTemplates";
import {applyUpdate} from '../../utils'

export default {
  name: "techScienceRightBar",
  components: {
    "v-chart": ECharts,
    // 'CustomSelect': CustomSelect,
    wrapper,
  },
  props: {
    projectDetail: Object,
    showTrace: Function,
  },
  data() {
    return {
      projectId: null,
      deptNameList: ["全部部门"],
      deptIdList: [""],
      mgmStatsOption: "all",
      mgmStatsSelectedDeptIdx: null,
      mgmStatsBarChart: {},
      mgmDetailShow: false,
      mgmSubWindowUrl: null,
      mgmSubWindowShow: false,
      securityStarList: [],
      workerInOutList: [],
      personalInfoPopupShow: false,
      personalInfoWorkerId: null,
      personalInfoTabIndex: 1,
      securityStarPopupShow: false,
      securityStarPage: 1,
      securityStarPages: 1,
      securityStarInfo: [],
      inOutPopupShow: false,
      workerInOutPage: 1,
      workerInOutPages: 1,
      workerInOutInfo: [],
      companyAttendanceBarChart: {},
      timer: null,

      newTechList: [],
      bimApplyList:[],
      replyList:[],
      expertList:[],

      majorProjectChart: {},
      outcomeChart:{},
      workerTypePieChartHighlightIndex: 0,
      todayNewTroubleBarChart: {},
    };
  },
  mounted() {
    // const self = this
    const { id: projectID } = this.$route.params;
    this.projectId = projectID;
    const token = this.$route.query.token;
    window.token = token;
    this.xmid = this.$route.query.xmid;
    this.getData();
  },
  methods: {
    getData() {
      // getSafetyMachineTypeCount
      this.getEcharts2();
      this.getEcharts3();
    },
    getEcharts2() {
        tech.getProjectMajor(this.xmid).then(res=>{
          const planNum = [];
          const completeNum = [];
          planNum.push(res[0].zdgcNum);
          planNum.push(res[0].sfgcNum);
          planNum.push(res[0].ktNum);
          completeNum.push(res[0].zdgcWcNum);
          completeNum.push(res[0].sfgcWcNum);
          completeNum.push(res[0].ktWcNum);
          // if(planNum.some(item  => item !==0 ) && completeNum.some(item => item!==0)){
            
          // }
          const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
          const option = {
              color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  },
                  formatter:function(param){
                    let str = param[0].name+"<br/>";
                    for (let index = 0; index < param.length; index++) {
                      str += param[index].seriesName+":"+param[index].value+"<br/>";
                    }
                    return str;
                  }
              },
              grid: {
                containLabel: true,
                left: '5%',
                right: '6%',
                bottom: "5%",
                top: "12%"
              },
              xAxis: [
                {
                  name:"单位",
                  data: ['重点工程','示范工程','课题'],
                  axisLabel: {
                    color: 'white',
                    fontWeight: 'bold',
                    rotate: 40,
                    // formatter: value => truncateStr(value, 10),
                  },
                  show: true,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'category',
                }
              ],
              yAxis: [
                {
                  name:'单位:个',
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  boundaryGap: [0, '20%'],
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'value'
                }
              ],
              series: [
                {
                  name: '计划数量',
                  type: 'bar',
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: planNum
                },
                {
                  name: '完成数量',
                  type: 'bar',
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: completeNum,
                }
              ]
          }
          this.outcomeChart = applyUpdate(chart, option);
        })
    },
    dangerTypeMouseOver(params) {
      if (params.dataIndex != this.workerTypePieChartHighlightIndex) {
        this.$refs.dangerType.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.workerTypePieChartHighlightIndex,
        });
        this.workerTypePieChartHighlightIndex = params.dataIndex;
      }
    },
    dangerTypeMouseOut() {
      this.$refs.dangerType.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.workerTypePieChartHighlightIndex,
      });
    },
    getEcharts3() {
        tech.getProjectStudio(this.xmid).then(res=>{
          const seriesData= [];
          seriesData.push(
            {name:"深基坑工作室",value:res[0].sjk},
            {name:"钢结构工作室",value:res[0].gjg},
            {name:"装配式工作室",value:res[0].zps},
          )
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
          const option = {
            color: colors,
            legend: {
              show: true,
                textStyle: {
                  color: '#ccc',
                  fontWeight: 'bold',
                  fontSize: '11'
                },
              padding:[50,0,0,0]
            },
            grid: {
              top: "5%",
              left: "10%",
              right: "9%",
              bottom: "5%",
              containLabel: true,
            },
            series: [
              {
                type: "pie",
                minAngle: 2,
                radius: ["25%", "45%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: true,
                data:seriesData,
                itemStyle: {
                  normal: {
                    borderColor: "#08122b",
                    borderWidth: 2,
                  },
                },
                label: {
                  normal: {
                    show: false,
                    position: "center",
                    //formatter: '{text|{b}}\n{c} ({d}%)',
                    //formatter: '{value|{d}%}\n{text|{b}}',
                    formatter: function (params) {
                      return `{value|${Math.round(params.percent)}}\n{text|${
                        params.name
                      }}`;
                    },
                    padding: [20, 0, 0, 0],
                    rich: {
                      text: {
                        color: "#aaa",
                        fontSize: 12,
                        align: "center",
                        verticalAlign: "middle",
                        lineHeight: 22,
                      },
                      value: {
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: "bold",
                        align: "center",
                        verticalAlign: "middle",
                      },
                    },
                  },
                  emphasis: {
                    show: true,
                    textStyle: {
                      fontSize: 14,
                    },
                  },
                },
              },
            ],
          }
          this.majorProjectChart = applyUpdate(chart,option);
        })
    },
    updateSecurityStarList(projectID) {
      project
        .getSecurityStars(projectID, 1, 9)
        .then((d) => {
          this.securityStarList = d;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    updateWorkerInOutList(projectID) {
      project
        .getWorkerInOuts(projectID, 1, 9)
        .then((d) => {
          this.workerInOutList = d;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    updateProjectCompanyAttendanceChart() {},
    deptSelect(option) {
      const idx = this.deptNameList.indexOf(option);
      if (idx >= 0) {
        this.mgmStatsSelectedDeptIdx = idx;
      }
    },
    switchMgmStatsOption(option) {
      if (option != this.mgmStatsOption) {
        this.mgmStatsOption = option;
      }
    },
    showMgmDetail() {
      this.mgmDetailShow = true;
    },
    showMgmSubWindow(url) {
      this.mgmSubWindowUrl = url;
      this.mgmSubWindowShow = true;
    },
    showMoreStars() {
      project
        .getSecurityStars(this.$route.params.id, 1, 10)
        .then((d) => {
          this.securityStarInfo = d;
          this.securityStarPage = 1;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
      this.securityStarPopupShow = true;
    },
    securityStarPageChange(currPage) {
      project
        .getSecurityStars(this.$route.params.id, currPage, 10)
        .then((d) => {
          this.securityStarInfo = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    showWorkerInfo(workerId) {
      this.personalInfoWorkerId = workerId;
      this.personalInfoTabIndex = 2;
      this.personalInfoPopupShow = true;
    },
    showMoreAttendance() {
      project
        .getWorkerInOuts(this.$route.params.id, 1, 10)
        .then((d) => {
          this.workerInOutInfo = d;
          this.workerInOutPage = 1;
          this.workerInOutPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
      this.updateProjectCompanyAttendanceChart();
      this.inOutPopupShow = true;
    },
    workerInOutPageChange(currPage) {
      project
        .getWorkerInOuts(this.$route.params.id, currPage, 10)
        .then((d) => {
          this.workerInOutInfo = d;
          this.workerInOutPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    cleanupInOutPopup() {
      this.companyAttendanceBarChart = {};
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.machine_use {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.machine_use_item {
  width: 40%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.8rem;
}
.machine_use_item_1 {
  border: 1px solid #29e8a8;
  background-color: rgba(41, 232, 168, 0.2);
}
.machine_num_1 {
  font-size: 1.5rem;
  color: #29e8a8;
}
.machine_use_item_2 {
  border: 1px solid #fee21d;
  background-color: rgba(254, 226, 29, 0.2);
}
.machine_num_2 {
  font-size: 1.5rem;
  color: #fee21d;
}
.machine_use_item_3 {
  border: 1px solid #4191e5;
  background-color: rgba(65, 145, 229, 0.2);
}
.machine_num_3 {
  font-size: 1.5rem;
  color: #4191e5;
}
.machine_use_item_4 {
  border: 1px solid #f38e2c;
  background-color: rgba(243, 142, 44, 0.2);
}
.machine_num_4 {
  font-size: 1.5rem;
  color: #f38e2c;
}
.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>