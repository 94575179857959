<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  />
</template>

<script>
  import Broadcasts from "../../components/broadcasts";
  import { nation } from "../../api";

  export default {
    name: 'SecurityDangerBroadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: [ '年度新开工项目数', '年度已竣工项目数', '计划竣工项目数'],
        broadcastColors: [ '#FFD454', '#50F194', '#2D7DFF'],
        broadcastIcons: [ '年度新开工项目数', '年度已竣工项目数', '计划竣工项目数'],
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    },
  }

  export function updateData(container, scheduleNextUpdate) {
    nation
      .getOutputBroadcast()
      .then((d) => {
        container.broadcastData.outputValue = d[0];
        container.broadcastData.outputValue.value1 = d[0].new_project_count;
        container.broadcastData.outputValue.value2 = d[0].finished_project_count;
        container.broadcastData.outputValue.value3 = d[0].plan_finish_project_count;

        container.statsData = d[0];
        scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
