<template>
  <div class="info-block" style="min-height:5rem">
    <div class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
      <div>各层级人员考核分析</div>
       <div
      style="display:flex;justify-content:flex-end"
    >
      <CustomSelect
          :options="yearList"
          :value="selectedYear"
          @input="categorySelect"
          style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
        />
    </div>
    </div>
    <div style="height:20rem;padding-top:3rem">
      <div
        v-if="EmployeeCostChart.series && [].concat.apply([], EmployeeCostChart.series.map(x => x.data)).length == 0"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart v-else :options="EmployeeCostChart" autoresize />
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { human } from '../../../../api_hr'
  // import { stackedBarChartTemplate } from "../../barChartTemplates";
  // import { applyUpdate } from "../../../../utils";
 import CustomSelect from '../../../../components/select'

  export default {
    name: 'staffAppraisalChart',
    components: {
      'v-chart': ECharts,
      CustomSelect
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateEmployeeCostChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateEmployeeCostChart();
        }
      }
    },
    data () {
      return {
        EmployeeCostChart: {},
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear(),
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.updateEmployeeCostChart();
    },
    methods: {
      updateEmployeeCostChart() {
        human.getPersonalOfLevelsExamine(this.selectedYear,human.getOrgLevel()).then(res=>{
          var xList = [];
          var aData = [];
          var cData = [];
          for (let i = 0; i < res.length; i++) {
            xList.push(res[i].name);
            aData.push(res[i].subData[0].value);
            if(res[i].subData[1] == null){
              cData.push(0);
            }else{
              cData.push(res[i].subData[1].value);
            }
            
          }
          let option = {
            color:[
              '#f32c2c',
              '#f38e2c',
              '#2ce4ff',
              '#19b6fb',
              '#5dd5c3',
              '#ffed21',
              '#159172',
              '#fb9c5c'
            ],
            tooltip: {
              trigger: 'axis'
              },
              legend: {
                  textStyle: {
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '12'
                },
              },
              grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
              },
              xAxis: {
                  name:'层级',
                  type: 'category',
                  data:xList,
                  axisLabel:{
                    interval:0,
                    rotate:40,
                    color:'white'
                  }
              },
              yAxis: {
                  name:'数量',
                  nameTextStyle:{
                    color:"white",
                  },
                  axisLine: {
                    show: false,
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      type: [5, 10],
                      color: '#14253f',
                      width: 2,
                    }
                  },
                  type: 'value',
                  axisLabel: {
                     color: 'white'
                  }
              },
              series: [
                  {
                      name: "1年内3次及以上为A",
                      type: 'bar',
                      data: aData,
                      areaStyle:{}
                  },{
                      name: "一年内2次及以上为C",
                      type: 'bar',
                      data: cData,
                      areaStyle:{}
                  }
                ]
          }
          this.EmployeeCostChart = option;
        })
      },
      categorySelect(option){
        this.selectedYear = option;
        this.updateEmployeeCostChart();
      }
    },
  }
</script>

<style lang="less">
</style>
