<template>
  <Broadcasts
    :broadcast-titles="broadcastTitles"
    :broadcast-colors="broadcastColors"
    :broadcast-icons="broadcastIcons"
    :broadcast-data="broadcastData"
    :handle-click="handleClick"
  />
</template>

<script>
  import Broadcasts from "../../../../components/broadcasts";
  import { law } from "../../../../api_law";

  export default {
    name: 'QualityIndexBroadcasts',
    components: {
      Broadcasts,
    },
    data() {
      return {
        broadcastTitles: ['存量案件', '涉及金额', '本年度生效法律文书付款付款总额','生效法律文书本月应付总额'],
        broadcastColors: ['#40f8ff', '#ffd454', '#38c59e','#fea453'],
        broadcastIcons: ['存量案件数', '涉及金额', '付款总额','应付总额'],
      }
    },
    props: {
      broadcastData: Object,
      handleClick: Function,
    }
  }

  export function updateData(container, scheduleNextUpdate) {
    law.queryRespondentStatistics()
      .then((d) => {
        container.broadcastData.respondent = d;
        container.broadcastData.respondent.value1 = d.clCaseNum;
        container.broadcastData.respondent.value2 = law.formatToMyriadDecimal(d.ssje,2);
        container.broadcastData.respondent.value3 = law.formatToMyriadDecimal(d.fkjeYear,2);
        container.broadcastData.respondent.value4 = law.formatToMyriadDecimal(d.fkjeMonth,2);
        
        container.statsData = d;
        // scheduleNextUpdate();
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));
  }
</script>

<style lang="less">
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cececf;
}
</style>
