<template>
  <div id="left">
    <SchemeApprovaChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <SchemeLagChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <ExpertRankingChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
  </div>
</template>

<script>
  import SchemeApprovaChart from "./schemeApprova";
  import SchemeLagChart from "./SchemeLagChart";
  import ExpertRankingChart from "./ExpertRankingChart";

  export default {
    name: 'QualityIndexLeftBar',
    components: {
      SchemeApprovaChart,
      SchemeLagChart,
      ExpertRankingChart,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
  }
</script>

<style lang="less">
</style>
