<template>
  <div id = "right">
    <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title">项目全周期人员曲线</div>
    <div
      class="detail-select"
      style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
    >
      <CustomSelect
        :options="yearList"
        :value="selectedYear"
        @input="categorySelect"
        style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
      />
    </div>
    <div style="height:16rem;padding-top:1rem">
      <div v-if="!ProjectCycleCurveChart.series || !ProjectCycleCurveChart.series[0].data.length">
        <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; 
        height: 12rem">
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
      </div>
      <v-chart v-else :options="ProjectCycleCurveChart" autoresize />
    </div>
  </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { human } from '../../../../api_hr'
  import CustomSelect from '../../../../components/select'

  export default {
    name: 'ProjectCycleCurveChart',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateProjectCycleChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateProjectCycleChart();
        }
      }
    },
    data () {
      return {
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear(),

        ProjectCycleCurveChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.updateProjectCycleChart();
    },
    methods: {
      categorySelect(option) {
        this.selectedYear = option;
        this.updateProjectCycleChart();
      },
      updateProjectCycleChart() {
        human.getPersonnelAllocation(this.selectedYear).then((result) => {
          let real_nums = [];
          let dateStr = [];
          let plan_nums = [];
          for(let i = 0;i<result[0].value.length;i++){
            dateStr.push(result[0].value[i].DATA_MONTH);
            plan_nums.push(result[0].value[i].DATA_NUM);
            if(result[1].value[i] != null)
              real_nums.push(result[1].value[i].DATA_NUM);
            else
              real_nums.push(0);
          }
          let personnel_allocation_option = {
            color:[
              '#f32c2c',
              '#f38e2c',
              '#2ce4ff',
              '#19b6fb',
              '#5dd5c3',
              '#ffed21',
              '#159172',
              '#fb9c5c'
            ],
            tooltip: {
              trigger: 'axis'
              },
              legend: {
                  data: ['实际人数', '计划人员'],
                  textStyle: {
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '12'
                },
              },
              // grid: {
              //     left: '3%',
              //     right: '4%',
              //     bottom: '3%',
              //     containLabel: true
              // },
              xAxis: {
                  type: 'category',
                  data:dateStr,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisLabel:{
                    interval:0,
                    rotate:40,
                    color:'white'
                  }
              },
              yAxis: {
                  name:'人数',
                  nameTextStyle:{
                    color:"white",
                  },
                  axisLine: {
                    show: false,
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      type: [5, 10],
                      color: '#14253f',
                      width: 2,
                    }
                  },
                  type: 'value',
                  axisLabel: {
                     color: 'white'
                  }
              },
              series: [
                  {
                      name: '实际人数',
                      type: 'line',
                      data: real_nums,
                      areaStyle:{}
                  },{
                      name: '计划人员',
                      type: 'line',
                      data: plan_nums,
                      areaStyle:{}
                  }
                ]
          }
          this.ProjectCycleCurveChart = personnel_allocation_option;
        })
      }
    }
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
