<template>
  <div id="left">
    <personDimissionOddsChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <recruitStaticChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <contractTypeBarChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" :org-options="orgOptions" />
  </div>
</template>

<script>
  import contractTypeBarChart from "./contractTypeBarChart";
  import recruitStaticChart from "./recruitStaticChart";
  import personDimissionOddsChart from "../employeeRelation/personDimissionOdds";


  export default {
    name: 'HrIndexLeftBar',
    components: {
      personDimissionOddsChart,
      contractTypeBarChart,
      recruitStaticChart,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
  }
</script>

<style lang="less">
</style>
