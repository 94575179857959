<template>
  <div
    id="nation"
    class="global-bg"
    @click="selectOpen = false"
  >
    <div
      id="title-container"
      style="margin-bottom:-3rem;position:relative;width:570px;margin:auto"
    >
      <div id="title-left2" />
      <div
        id="title-center2"
        style="position:relative"
        :style="{'font-size': (orgOptions && orgLevel != orgOptions[0].org_id) ? '18px' : '22px'}"
      >{{title}}</div>
      <div id="title-right2" />
      <div
        id="select-arrow"
        :style="{transform: selectOpen ? '' : 'rotate(180deg)', display: orgOptions ? 'block' : 'none'}"
        @click.stop="toggleSelect"
      />
      <div v-if="curPage=='finance' && orgType==2"
        id="select-arrow2"
        :style="{transform: selectUnitOpen ? '' : 'rotate(180deg)', display: orgOptions ? 'block' : 'none'}"
        @click.stop="toggleSelectUnit"
      />
      <div
        class="custom-select"
        @blur="toggleSelectUnit = false"
      >
        <div
          class="select-items"
          :style="{
          maxHeight: selectUnitOpen ? '600px': '0',
          borderWidth: selectUnitOpen ? '2px': '0',
          transition: selectUnitOpen ? 'max-height 1s ease-out' : 'none'}"
        >
          <div
            v-for="(option, i) of unitOptions"
            class="select-item"
            :key="i"
            @click="selectUnit(option)"
          >
            {{ option.assessUnit }}
          </div>
        </div>
        <div
          id="select-light"
          :style="{display: selectOpen ? 'block': 'none'}"
        />
      </div>
      <div
        class="custom-select"
        @blur="selectOpen = false"
      >
        <div
          class="select-items"
          :style="{
          maxHeight: selectOpen ? '600px': '0',
          borderWidth: selectOpen ? '2px': '0',
          transition: selectOpen ? 'max-height 1s ease-out' : 'none'}"
        >
          <div
            v-for="(option, i) of orgOptions"
            class="select-item"
            :key="i"
            @click="selectOrg(option)"
          >
            {{ option.org_name }}
          </div>
        </div>
        <div
          id="select-light"
          :style="{display: selectOpen ? 'block': 'none'}"
        />
      </div>
      <div
        id="title-light"
        :style="{display: selectOpen ? 'none': 'block'}"
      />
    </div>

    <el-autocomplete
      class="inline-input"
      style="position:absolute;width:24rem;left:48rem;top:4.4rem;pointer-events:all;z-index:50000;"
      v-model="searchParams"
      :fetch-suggestions="querySearch"
      placeholder=""
      :trigger-on-focus="false"
      @select="handleSelect"
    >
      <i slot="suffix" class="el-input__icon"><img src="../../assets/nation/search.png" style="margin-right:0.7rem;margin-top:0.75rem" alt=""></i>
    </el-autocomplete>

    <div v-if="componentsReady">
      <IndexMain ref="indexMain"
        :org-type="orgType"
        :orgLevel="orgLevel"
        :orgSelectOptions="orgSelectOptions"
        :componentList="componentList"
        :subComponentLists="subComponentLists"
        :components="components"
        :compDict="compDict"
        :hasSubMenus="hasSubMenus"
        :navMenuLeftMargins="navMenuLeftMargins"
        :subMenuLeftMargins="subMenuLeftMargins"
        :subMenuRightMargins="subMenuRightMargins"
        :gotoProject="gotoProject"
        @changeName="getCurPage"
      />
    </div>
    <!-- <div id="logo">
      <img
        src="../../assets/logo.png"
        alt=""
        width="105"
        height="42"
      >
    </div> -->
  </div>
</template>

<script>
import { nation, project, filters } from "../../api";
import { finance } from '../../api_finance';
import IndexMain from './indexMain';
const componentRegistry = require("./components.json");

const navMenuLeftMargins = [1.5, 9.5, 17.5, 25.5, 33.5, 78.5, 86.5, 94.5, 102.5, 110.5];
const subMenuLeftMargins = ['1.5rem', '1.5rem', '1.5rem', null, null, '78.5rem', '78.5rem', '78.5rem', null, null];
const subMenuRightMargins = [null, null, null, '78.5rem', '78.5rem', null, null, null, '1.5rem', '1.5rem'];

export default {
  name: "nation",
  components: {
    IndexMain,
  },
  data() {
    return {
      componentsReady: false,
      componentList: [],
      subComponentLists: [],
      components: {},
      compDict: {},
      hasSubMenus: [],
      navMenuLeftMargins: [],
      subMenuLeftMargins: [],
      subMenuRightMargins: [],
      searchParams:'',
      projects: [],
      title: "一公司数据看板",
      orgLevel: "",
      selectOpen: false,
      selectUnitOpen: false,
      orgOptions: null,
      unitOptions: null,
      orgSelectOptions: null,
      curPage:"",
      orgType:1
    };
  },
  watch: {
    orgOptions: function(newValue, oldValue) {
      if (newValue != oldValue) {
        if (newValue.length && newValue[0].type == 1) {
          this.orgSelectOptions = [{
            org_name: '全部分公司',
            org_id: newValue[0].org_id,
            type: 1
          }].concat(newValue.slice(1));
        } else {
          this.orgSelectOptions = newValue;
        }
      }
    },
    curPage:function(){
      // console.log(this.orgType);
    }
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;


    filters
      .getMenuList()
      .then((d) => {
        const components = {};
        const componentList = [];
        for (const item of d) {
          componentList.push(item.name);
          if (item.children && item.children.length) {
            const children = {};
            for (const child of item.children) {
              if (componentRegistry[child.pagename]) {
                children[child.name] = Object.assign({pageName: child.pagename}, componentRegistry[child.pagename])
              }
            }
            components[item.name] = { children };
          } else if (componentRegistry[item.pagename]) {
            components[item.name] = Object.assign({pageName: item.pagename}, componentRegistry[item.pagename]);
          }
        }

        const subComponentLists = [];
        const hasSubMenus = [];
        const compDict = {};
        for (let i = 0; i < componentList.length; i++) {
          if (!components[componentList[i]].children) {
            subComponentLists.push([]);
            hasSubMenus.push(false);

            if (components[componentList[i]].map) {
              const mapComp = require(`./${components[componentList[i]].map}.vue`).default;
              components[componentList[i]].mapComp = mapComp;
              compDict[`Comp${i + 1}Sub0Map`] = mapComp;
            }

            if (components[componentList[i]].left) {
              const leftComp = require(`./${components[componentList[i]].left}.vue`).default;
              components[componentList[i]].leftComp = leftComp;
              compDict[`Comp${i + 1}Sub0Left`] = leftComp;
            }

            if (components[componentList[i]].right) {
              const rightComp = require(`./${components[componentList[i]].right}.vue`).default;
              components[componentList[i]].rightComp = rightComp;
              compDict[`Comp${i + 1}Sub0Right`] = rightComp;
            }

            if (components[componentList[i]].broadcast) {
              const broadcastModule = require(`./${components[componentList[i]].broadcast}.vue`);
              const broadcastComp = broadcastModule.default;
              components[componentList[i]].broadcastComp = broadcastComp;
              components[componentList[i]].updateBroadcastData = broadcastModule.updateData;
              compDict[`Comp${i + 1}Sub0Broadcast`] = broadcastComp;
            }
          } else {
            subComponentLists.push(Object.keys(components[componentList[i]].children));
            hasSubMenus.push(true);

            let k = 0;
            for (const key in components[componentList[i]].children) {
              if (components[componentList[i]].children[key].map) {
                const mapComp = require(`./${components[componentList[i]].children[key].map}.vue`).default;
                components[componentList[i]].children[key].mapComp = mapComp;
                compDict[`Comp${i + 1}Sub${k}Map`] = mapComp;
              }

              if (components[componentList[i]].children[key].left) {
                const leftComp = require(`./${components[componentList[i]].children[key].left}.vue`).default;
                components[componentList[i]].children[key].leftComp = leftComp;
                compDict[`Comp${i + 1}Sub${k}Left`] = leftComp;
              }

              if (components[componentList[i]].children[key].right) {
                const rightComp = require(`./${components[componentList[i]].children[key].right}.vue`).default;
                components[componentList[i]].children[key].rightComp = rightComp;
                compDict[`Comp${i + 1}Sub${k}Right`] = rightComp;
              }

              if (components[componentList[i]].children[key].broadcast) {
                const broadcastModule = require(`./${components[componentList[i]].children[key].broadcast}.vue`);
                const broadcastComp = broadcastModule.default;
                components[componentList[i]].children[key].broadcastComp = broadcastComp;
                components[componentList[i]].children[key].updateBroadcastData = broadcastModule.updateData;
                compDict[`Comp${i + 1}Sub${k}Broadcast`] = broadcastComp;
              }

              k++;
            }
          }
        }

        this.componentList = componentList;
        this.subComponentLists = subComponentLists;
        this.components = components;
        this.compDict = compDict;
        this.hasSubMenus = hasSubMenus;
        const startIdx = Math.floor((10 - componentList.length) / 2);
        const endIdx = startIdx + componentList.length;
        this.navMenuLeftMargins = navMenuLeftMargins.slice(startIdx, endIdx);
        this.subMenuLeftMargins = subMenuLeftMargins.slice(startIdx, endIdx);
        this.subMenuRightMargins = subMenuRightMargins.slice(startIdx, endIdx);
        this.componentsReady = true;
      })
      // eslint-disable-next-line
      .catch((e) => console.error(e.msg || e.message));

    this.getDashboardDetail();
    this.searchProject();
  },
  methods: {
    getCurPage(curPage){
      this.curPage = curPage;
    },
    searchProject(){
        project
          .getGeneralProjectSelect(this.searchParams)
          .then((d) => {
            this.projects=d.map(item=>{
              return {
                value:item.name,
                id:item.innerid
              }
            });
          })
          // eslint-disable-next-line
          .catch((e) => console.error(e.msg || e.message));
    },
    querySearch(queryString, cb) {
      var finance_projects = [];
      finance.queryProjInfo(queryString).then((res=>{
        finance_projects = res.map(item=>{
          return{
            value:"(资金)"+item.projName,
            id:item.profitCode
          }
        })
        var projects = this.projects.concat(finance_projects);
        var results = queryString ? projects.filter(this.createFilter(queryString)) : projects;
        // 调用 callback 返回建议列表的数据
        // console.log(results)
        cb(results);
      }))
    },
    createFilter(queryString) {
      return (project) => {
        return (project.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    handleSelect(item) {
      this.gotoProject(item.id,item.value);
    },
    gotoProject(projectId,projectName) {
      nation.getProjectToken(projectId)
        .then((d) => {
          if (d.fid == "") {
            this.$router.push({path:`/finance/${projectId}`,query:{projName:projectName,token:window.token}})
          } else {
            this.$router.push({
              path:`/project2/${projectId}/${parseInt(this.navMenuIndex) || 1}/${this.navSubMenuIndex || '0'}`,
              query: {
                token: d.token,
                from_org: 1,
                xmid: d.fid
              }
            })
          }
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    getDashboardDetail() {
      nation
        .getOrgSelect()
        .then((d) => {
          if (d.length) {
            this.orgOptions = d;
            this.title = d[0].org_name;
            this.orgLevel = d[0].org_id;
            this.orgType = d[0].type;
            window.$orgLevel = d[0].org_id;
            window.$sFid = d[0].sfid;
            window.$orgName = d[0].org_name;
          }
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    toggleSelect() {
      this.selectOpen = !this.selectOpen;
    },
    toggleSelectUnit(){
      this.selectUnitOpen = !this.selectUnitOpen;
    },
    getAssessUnit(){
      finance.queryAssessUnitList().then((res=>{
        this.unitOptions = res;
      }))
    },
    selectUnit(option){
      this.selectOpen = false;
      this.title = (this.orgOptions && this.orgOptions[0] != option) ?
      this.orgOptions[0].org_name + ' - ' + window.$orgName : window.$orgName;
      this.title = this.title+'-'+option.assessUnit;
      // console.log(this.title.split('-').length-1);
      this.orgLevel = option.fid;
      window.$assessUnit = option.assessUnit;
      this.selectUnitOpen = false;
    },
    selectOrg(option) {
      window.$orgLevel = option.org_id;
      window.$sFid = option.sfid;
      window.$orgName = option.org_name;
      window.$assessUnit = "";
      this.getAssessUnit();
      this.orgLevel = option.org_id;
      this.orgType = option.type;
      console.log(this.orgType);
      this.title = (this.orgOptions && this.orgOptions[0] != option) ? this.orgOptions[0].org_name + ' - ' + option.org_name : option.org_name;
      this.selectOpen = false;
      this.selectUnitOpen = false;
    },
  },
};
</script>

<style scoped lang="less">
#nation {
  overflow: hidden;
  background: url("../../assets/nation/bg.png");
  background-size: cover;
}

#title-center2 {
  width: 520px;
  height: 64px;
  display: inline-block;
  line-height: 64px;
  font-weight: bold;
  text-align: center;
  background: url("../../assets/nation/mid.png") transparent;
  background-size: contain;
}

#select-arrow {
  position: absolute;
  cursor: pointer;
  right: 60px;
  top: 20px;
  width: 24px;
  height: 24px;
  background: url("../../assets/nation/select_arrow.png") transparent;
}
#select-arrow2{
  position: absolute;
  cursor: pointer;
  right: 25px;
  top: 20px;
  width: 24px;
  height: 24px;
  background: url("../../assets/nation/select_arrow.png") transparent;
}

.custom-select {
  position: absolute;
  left: 60px;
  top: 64px;
}
.select-items {
  position: relative;
  width: 450px;
  font-size: 18px;
  line-height: 48px;
  text-align: center;
  border: 0 #254c83 solid;
  border-top: 0;
  background-color: #0b1f48;
  overflow: scroll;
  z-index: 100000;
}

.select-item {
  cursor: pointer;
}

.select-item:hover {
  background-color: #0a193a;
}

#select-light {
  position: absolute;
  width: 377px;
  height: 53px;
  background: url("../../assets/nation/light2.png") transparent;
  left: 36.5px;
  bottom: -26.5px;
  z-index: 20000;
}

#title-light {
  position: absolute;
  width: 377px;
  height: 53px;
  background: url("../../assets/nation/light2.png") transparent;
  bottom: -26.5px;
  z-index: 20000;
}

#title-left2 {
  width: 32px;
  height: 64px;
  background: url("../../assets/nation/left.png") no-repeat transparent;
  background-size: contain;
}

#title-right2 {
  width: 32px;
  height: 64px;
  background: url("../../assets/nation/right.png") no-repeat transparent;
  background-size: contain;
}
</style>

<style lang="less">
.inline-input {
  .el-input__inner{
    background-color: #0c2341 !important;
    border: 1px solid #1782a6 !important;
    height:2.5rem !important;
  }
}
.el-autocomplete-suggestion{
  // top: 3rem !important;
  border: .5px solid #1782a6 !important;
  margin-top: 0 !important;
}
.el-autocomplete-suggestion__wrap{
  background-color: #0c2341 !important;
}
#logo {
  position: absolute;
  right: 2rem;
  bottom: 1.5rem;
  display: flex;
  align-items: center;
  line-height: 2rem;

  span {
    font-size: 1.125rem;
    color: rgba(255, 255, 255, 0.6);
    margin-right: 0.5rem;
  }
}
</style>
