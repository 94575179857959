import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
  };

  const buildingBoundaries = {
    '_': [[-10000, 10000],[10000, 10000],[10000, -10000],[-10000, -10000]],
  };

  const buildingFinishedHeights = {
    '_': -1000000,
  };

  const doneFloors = {
  };

  const traceViews = {
    '_': {
      center: [0, 0],
      pitch: 60,
      zoom: 2,
      bearing: -40,
    },
  };

  function getUserZValue(user) {
    if (user.area == 19 || user.area == 68 || user.area == 69 || user.area == 70) {
      return 10 + 5;
    } else if (user.area == 20 || user.area == 76 || user.area == 77 || user.area == 78) {
      return -32 + 5;
    } else if (user.area == 66 || user.area == 67 || user.area == 74) {
      return 13.4 + 5;
    } else if (user.area == 75) {    // 75
      return -22 + 5;
    }
    //return (user.floor == 'ground' ? 0 : user.height) * 2.9 + 5;
  }

  function getFloorFromZValue(bldg, zValue) {
    if (zValue >= -5) {
      return -2;
    } else {
      return -4;
    }
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/xian_subway_15_hanzhong.glb',
    modelType: 'gltf',
    modelSubType: 'draco',

    buildId: 'ccb7b8e1-9de3-11ec-a6b1-98039b887f1a',

    //fullOpacity: 0.85,
    //revealOpacity: 0.15,
    //deepRevealOpacity: 0,

    modelOptions: {
      scale: 10,
      rotZ: 0,
      moveX: -1350,
      moveY: 0,
      moveZ: -5166,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: Math.PI * 0.95,
      z: 0
    },

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
