<template>
<div id="left">
    <div class="info-block" style="min-height:5rem">
      <div
        class="info-block-title">
        <div>流失情况</div>
       </div>
        <div
      style="display:flex;justify-content:flex-end"
    >
        <CustomSelect
          :options="yearList"
          :value="selectedYear"
          @input="categorySelect"
          style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
       />
      </div>
      <div style="height:19rem;padding-top:1rem">
        <div
          style="display:flex;justify-content:space-around;margin-bottom:-0.8rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')">
            离职率分析
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')">
            离职原因分析
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '3' }"
            @click="() => switchCompanyWorkersOption('3')">
            离职去向分析
          </div>
        </div>
        <div
          v-if="companyWorkersPieChart.series && [].concat.apply([], companyWorkersPieChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyWorkersPieChart" @click="companyWorkersPieChartClick" autoresize/>
      </div>
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { human } from '../../../../api_hr'
  import { colors } from '../../pieChartTemplates';
 import CustomSelect from '../../../../components/select'
  // import { truncateStr } from "../../../../utils";
// import func from 'vue-editor-bridge'

  export default {
    name: 'personDimissionOddsChart',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          // this.companyNameList = newValue.map(item => truncateStr(item.org_name, 10));
          // this.companyIdList = newValue.map(item => item.org_id);
          // this.sFidList = this.orgOptions.map(item => item.sfid);
          this.getAll();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      }
    },
    data () {
      return {
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear(),
        companyWorkersData: {},
        companyWorkersOption: "1",
        companyWorkersPieChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.getAll();
    },
    methods: {
      getAll(){
          this.switchCompanyWorkersOption(this.companyWorkersOption);
      },
      switchCompanyWorkersOption(choose){
          this.companyWorkersOption = choose;
          switch (this.companyWorkersOption) {
              case "1":
                  this.updatePersonDimissionOddsChart();
                  break;
              case "2":
                  this.updatePermitOddsChart();
                  break;
              case "3":
                  this.updatePromotionSourceChart();
                  break;
              default:
                  break;
          }
      },
      categorySelect(option) {
        this.selectedYear = option;
        this.switchCompanyWorkersOption(this.companyWorkersOption);
      },
      updatePersonDimissionOddsChart(){
        // console.log(window.$districtLevel);
        human.getPersonDimissionOdds(this.selectedYear).then((res)=>{
          let xAxis_data = [];
          let series_data = [];
          for (let index = 0; index < res.length; index++) {
            let name = res[index].name;
            xAxis_data.push(name.slice(0,name.length-3));
            series_data.push(Math.floor(res[index].value*100));
          }

          let person_quit_odds_option = {
            color:colors,
            tooltip: {
              trigger: 'axis'
              },
              grid: {
                  left: '4%',
                  right: '4%',
                  bottom: '4%',
                  containLabel: true
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data: xAxis_data,
                name:'维度',
                axisLabel:{
                  interval:0,
                  rotate:50,
                  color:'white'
                }
                },
                yAxis: {
                    type: 'value',
                    splitLine: {show: false},
                    axisLabel: {
                        formatter: '{value}%',
                        color:"white"
                    },
                    name:'离职率',
                    nameTextStyle:{
                    color:"white",
                  },
                },
                series: [
                    {
                        type: 'line',
                        data: series_data,
                        label:{
                            show:true,
                            formatter:'{c}%'
                        }
                    }
                ]
          }
          this.companyWorkersPieChart = person_quit_odds_option;
        })
      },
      updatePermitOddsChart() {
        // 离职原因分析
        human.getPersonDimissionReason(this.selectedYear).then(res=>{
          var reasonsForLeave_option = {
              color:colors,
              backgroundColor:'',
              tooltip: {
                trigger: 'item',
                formatter: ' {b} : {c} ({d}%)'
              },
              title: [{
                  subtext: res[0].name,
                  subtextStyle:{
                  fontSize:13,
                  color:'white'
                  },
                  left: '25%',
                  top: '2%',
                  textAlign: 'center'
              }, {
                  subtext: res[2].name,
                  subtextStyle:{
                      fontSize:13,
                color:'white'
                  },
                  left: '75%',
                  top: '2%',
                  textAlign: 'center'
              }, {
                  subtext: res[3].name,
                  subtextStyle:{
                  fontSize:13,
                  color:'white'
                  },
                  left: '25%',
                  top: '45%',
                  textAlign: 'center'
              }, {
                  subtext: res[4].name,
                  subtextStyle:{
                      fontSize:13,
                color:'white'
                  },
                  left: '75%',
                  top: '45%',
                  textAlign: 'center'
              }],
              series: [{
                  type: 'pie',
                  radius: '25%',
                  center: ['25%', '30%'],
                  data:res[0].subData,
                  itemStyle : {
                  normal : {
                  label : {
                    show : false   //隐藏标示文字
                  },
                  labelLine : {
                    show : false   //隐藏标示线
                  }
                }
              },
              }, {
                  type: 'pie',
                  radius: '25%',
                  center: ['75%', '30%'],
              data:res[2].subData,
              itemStyle : {
                normal : {
                  label : {
                    show : false   //隐藏标示文字
                  },
                  labelLine : {
                    show : false   //隐藏标示线
                  }
                }
              },
              }, {
                  type: 'pie',
                  radius: '25%',
                  center: ['25%', '75%'],
              data:res[3].subData,
              itemStyle : {
                normal : {
                  label : {
                    show : false   //隐藏标示文字
                  },
                  labelLine : {
                    show : false   //隐藏标示线
                  }
                }
              },
              }, {
                  type: 'pie',
                  radius: '25%',
                  center: ['75%', '75%'],
                  data:res[4].subData,
                  itemStyle : {
                   normal : {
                  label : {
                    show : false   //隐藏标示文字
                  },
                  labelLine : {
                    show : false   //隐藏标示线
                  }
                }
              },
            }]
          }
          this.companyWorkersPieChart = reasonsForLeave_option;
        })
      },
      updatePromotionSourceChart(){
        // 离职去向分析
        human.getPersonDimissionDirection(this.selectedYear).then((res) => {
             let personQuitDirectionOption = {
               color:colors,
               backgroundColor:'',
               tooltip: {
                  trigger: 'item',
                  formatter: ' {b} : {c} ({d}%)'
                },
                title: [{
                  subtext: res[0].name,
                  subtextStyle:{
                  fontSize:13,
                  color:'white'
                  },
                  left: '25%',
                  top: '2%',
                  textAlign: 'center'
              }, {
                  subtext: res[2].name,
                  subtextStyle:{
                      fontSize:13,
                color:'white'
                  },
                  left: '75%',
                  top: '2%',
                  textAlign: 'center'
              }, {
                  subtext: res[3].name,
                  subtextStyle:{
                  fontSize:13,
                  color:'white'
                  },
                  left: '25%',
                  top: '45%',
                  textAlign: 'center'
              }, {
                  subtext: res[4].name,
                  subtextStyle:{
                      fontSize:13,
                color:'white'
                  },
                  left: '75%',
                  top: '45%',
                  textAlign: 'center'
              }],
              series: [{
                  type: 'pie',
                  radius: '25%',
                  center: ['25%', '30%'],
                  data:res[0].subData,
                  itemStyle : {
                  normal : {
                  label : {
                    show : false   //隐藏标示文字
                  },
                  labelLine : {
                    show : false   //隐藏标示线
                  }
                }
              },
              }, {
                  type: 'pie',
                  radius: '25%',
                  center: ['75%', '30%'],
              data:res[2].subData,
              itemStyle : {
                normal : {
                  label : {
                    show : false   //隐藏标示文字
                  },
                  labelLine : {
                    show : false   //隐藏标示线
                  }
                }
              },
              }, {
                  type: 'pie',
                  radius: '25%',
                  center: ['25%', '75%'],
              data:res[3].subData,
              itemStyle : {
                normal : {
                  label : {
                    show : false   //隐藏标示文字
                  },
                  labelLine : {
                    show : false   //隐藏标示线
                  }
                }
              },
              }, {
                  type: 'pie',
                  radius: '25%',
                  center: ['75%', '75%'],
                  data:res[4].subData,
                  itemStyle : {
                   normal : {
                  label : {
                    show : false   //隐藏标示文字
                  },
                  labelLine : {
                    show : false   //隐藏标示线
                  }
                }
              },
            }]
          }
          this.companyWorkersPieChart = personQuitDirectionOption;
        })
      },
      updatePerformanceChart(){
      },
      showDetails(param){
        console.log(param);
      },
      companyWorkersPieChartClick(){
        console.log("companyWorkersPieChartClick");
      }
    }
  }
</script>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}

.option-block2 {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3.4rem;
  border: 1px solid #1b8ebd;
  text-align: center;
  cursor: pointer;
}
.option-block-selected{
  background-color: transparent !important;
  border: 1px solid #274C7D !important;
}
.option-block-selected2 {
  background-color: #4191e5;
  color: #fff;
}
.attendance-top{
  padding-top: 10px;
}
.tabbar{
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  border: 1px solid #157EAB;
  width: 210px;
  margin: auto;
  cursor: pointer;
}
.tabbar div{
  flex: 1;
  text-align: center;
}
.tabar-select{
  background-color: #4191E5;
  font-weight: bold;
}
.items div{
  color: #fff !important;
}
.selected{
  color: #fff !important;
}
.linebar{
  margin-top: 5rem;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto 10px;
}
.real-bar{
  height: 5px;
  background-color: #57C7B6;
  border-radius: 12px;
}
</style>

