<template>
  <div id="left">
    <bindConditionChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <newTechChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel"/>
    <cnTechResultChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
  </div>
</template>

<script>
  import bindConditionChart from "./bindConditionChart";
  import newTechChart from "./newTechChart";
  import cnTechResultChart from "./cnTechResultChart";

  export default {
    name: 'QualityIndexLeftBar',
    components: {
      bindConditionChart,
      newTechChart,
      cnTechResultChart,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
  }
</script>

<style lang="less">
</style>
