<template>
  <div id="left">
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div
        class="info-block-title"
        style="display: flex;align-items:center;width: 85%;justify-content: space-between;"
      >
        <div>各{{(districtScale == 0 && orgType == 1) ? '分公司' : (districtScale == 2 ? '项目' : '城市')}}隐患数</div>
        <div style="display:flex;align-items:center;">
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode == 1 }"
            @click="() => getTrouble(1)"
          >总</div>
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode == 2 }"
            @click="() => getTrouble(2)"
          >新增</div>
          <div
            class="option-block2"
            style="min-width: 3.5rem;"
            :class="{ 'option-block-selected2': mode == 3 }"
            @click="() => getTrouble(3)"
          >未整改</div>
        </div>
      </div>
      <div style="height:20rem;padding-top:3rem">
        <div
          v-if="troubleTypePieChart.series && [].concat.apply([], troubleTypePieChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="troubleTypePieChart"
          @click="troubleTypePieChartClick"
          ref="troubleType"
          @mouseover="troubleTypeMouseOver"
          @mouseout="troubleTypeMouseOut"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>各{{(districtScale == 0 && orgType == 1) ? '分公司' : (districtScale == 2 ? '项目' : '城市')}}未整改隐患数</div>

      </div>
      <div style="height:18rem;padding-top:3rem">
        <div
          v-if="troubleTypeNoPieChart.series && [].concat.apply([], troubleTypeNoPieChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="troubleTypeNoPieChart"
          @click="troubleTypeNoPieChartClick"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>各检查项隐患数</div>
        <!-- <div
          class="detail"
          @click="showDetail(2)"
        >
          详情
        </div> -->
      </div>
      <div style="height:20rem;padding-top:3rem">
        <div
          v-if="sevenDayAlarmsLineChart.series && [].concat.apply([], sevenDayAlarmsLineChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="sevenDayAlarmsLineChart"
          ref="checkType"
          @click="allCheckTroubleTypeChartClick"
          @mouseover="checkTypeMouseOver"
          @mouseout="checkTypeMouseOut"
          autoresize
        />
      </div>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="60rem"
      top="15rem"
    >
      <wrapper style="height:28rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle}}
            </div>
            <div class="tabbar">
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 1 }"
                @click="getAttendance(1)"
              >总</div>
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >新增</div>
              <div
                style="border: 1px solid #157EAB"
                :class="{ 'tabar-select': popupSelect == 3 }"
                @click="getAttendance(3)"
              >未整改</div>
            </div>
            <div style="width:10rem"></div>
          </div>
          <div style="display:flex; align-items: center;margin-top:1rem;justify-content: space-between;">
            <div></div>
            <div
              class="tooltips"
              v-if="popupSelect==3"
            >

              <div class="items">
                <div class="tooltip-item tooltip-item1"></div>正常未整改
              </div>
              <div class="items">
                <div class="tooltip-item tooltip-item2"></div>未整改预警
              </div>
              <div class="items">
                <div class="tooltip-item tooltip-item3"></div>逾期未整改
              </div>
            </div>
            <div style="font-size:12px;color:#fff;">隐患数：个</div>
          </div>
          <!-- <div style="border:1px solid #112563;margin:1.5rem 1rem"> -->
          <div style="height:20rem;padding:1rem 0 0 1rem">
            <div style="height:100%;overflow-y:scroll">
              <div
                v-for="(item, index) in troubleTypePieDetailTable"
                :key="index"
              >

                <div style="color: #A7B6BC;">
                  {{item.project_name}}
                </div>
                <div class="linebar">
                  <div
                    style="flex: 5"
                    class="line-bar"
                    v-if="popupSelect==3"
                  >
                    <div
                      class="real-bar"
                      :style="{'width': (item.value1 / avgAttendanceMax * 100) + '%' }"
                      style="background-color: #23cf9e;"
                    ></div>
                    <div
                      class="real-bar"
                      :style="{'width': (item.value2 / avgAttendanceMax * 100) + '%' }"
                      style="background-color: #eab91e;"
                    ></div>
                    <div
                      class="real-bar"
                      :style="{'width': (item.value3 / avgAttendanceMax * 100) + '%' }"
                      style="background-color: #d02c36;"
                    ></div>
                  </div>
                  <div
                    style="flex: 5"
                    class="line-bar"
                    v-else
                  >
                    <div
                      class="real-bar"
                      :style="{'width': (item.count / avgAttendanceMax * 100) + '%' }"
                    ></div>

                  </div>

                  <div style="color: #57C7B6;width:40px">
                    {{item.count}}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="allCheckTroubleTypePopShow"
      @close='dialogClose'
      width="90rem"
      top="15rem"
    >
      <wrapper
        class="popup-bg"
        style="height:38rem;"
      >
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div
            class="stats-chart-block"
            style="height:16rem;width:86.25rem;"
          >
            <div
              class="stats-close-btn"
              @click="allCheckTroubleTypePopShow = false"
            />

            <div style="height:19rem;border: 1px solid rgb(26, 50, 129); padding: 1rem ;">

              <div style="font-size:18px;font-weight:bold">{{allCheckTroubleTypeChartTitle}}隐患分布图
              </div>
              <div
                v-if="allCheckTroubleTypePopChart.series && [].concat.apply([], allCheckTroubleTypePopChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 24rem; height: 12rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <!--  -->
              <v-chart
                ref="safetyCheckPopItem"
                :options="allCheckTroubleTypePopChart"
                @click="allCheckTroubleTypePopChartClick"
                @mouseover="allCheckTroubleTypePopMouseOver"
                @mouseout="allCheckTroubleTypePopMouseOut"
                autoresize
              />
            </div>
            <div style=" overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">

              <div style="font-size:18px;font-weight:bold;margin:1rem 0;">
                【{{allCheckTroubleTypePopTableTitle}}】 问题点分布详情
              </div>
              <div style="display: flex;  height: 1.5rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:8rem">序号</div>
                <div style="width:22rem">问题点描述</div>
                <div style="width:8rem">隐患数</div>
              </div>
              <div style="height:12rem;overflow:auto;">
                <div
                  v-for="(item, index) in allCheckTroubleTypePopTable"
                  :key="item.trouble_id"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="display:flex; height: 3rem; align-items: center; justify-content: space-between; padding:0 1rem">
                    <div style="width:8rem;color:#4fb5da;">
                      {{index>8?index+1:'0'+(index+1)}}
                    </div>
                    <div style="width:22rem">{{item.description}}</div>
                    <div style="width:8rem">{{item.count}}</div>
                  </div>

                </div>
                <div
                  v-if="!(allCheckTroubleTypePopTable && allCheckTroubleTypePopTable.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无{{allCheckTroubleTypePopTableTitle}}问题点分布详情
                </div>
              </div>
            </div>

          </div>
        </div>
      </wrapper>
    </el-dialog>

  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation } from "../../api";
// import CustomSelect from '../../components/select'
import { attendancePieChartTemplate, colors } from "./pieChartTemplates";
// import { singleLineChartTemplate } from './lineChartTemplates';
import { stackedBarChartTemplate } from "./barChartTemplates";
import { applyUpdate, truncateStr } from "../../utils";
import wrapper from "../../components/wrap";

export default {
  name: "SecurityCheckLeftBar",
  components: {
    "v-chart": ECharts,
    wrapper,
    // 'CustomSelect': CustomSelect,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
   districtScale: Number,
    orgType: Number,
  },
  data() {
    return {
      levelOneTroublePoints: [],
      troubleTypePieChart: {},
      mode: 1,
      troubleTypePieChartHighlightIndex: 0,
      troubleTypeNoPieChart: {},
      option: {
        color: ["#20bbee", "#eab91e", "#d02c36"],
        grid: {
          left: "5%",
          right: "5%",
          top: "24%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "rect",
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 12,
          textStyle: {
            color: "#ccc",
            fontWeight: "bold",
            fontSize: "11",
          },
        },
        xAxis: {
          axisLabel: {
            color: "#e6f2f3",
            rotate: 40,
            fontWeight: "bold",
            formatter: (value) => truncateStr(value, 10),
          },
          show: true,
          axisLine: { show: true, lineStyle: { color: "#36445f" } },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#36445f",
              width: 1,
              type: "solid",
            },
          },
          axisTick: { show: false },
          type: "category",
          data: [],
        },
        yAxis: [
          {
            type: "value",
            show: true,
            name: "数量：个",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#36445f" },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                type: [1, 1],
                width: 1,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
          },
        ],
        dataZoom: [],
        series: [
          {
            type: "bar",
            stack: "samestack",
            barMaxWidth: 9,
            data: [],
          },
          {
            type: "bar",
            stack: "samestack",
            data: [],
          },
          {
            type: "bar",
            stack: "samestack",
            data: [],
          },
        ],
      },
      popupShow: false,
      popupSelect: 1,
      popupTitle: "",
      troubleTypePieDetailSearchKey1: [],
      troubleTypePieDetailSearchKey2: [],
      troubleTypePieDetailTable: [],
      sevenDayAlarmsLineChart: {},
      checkTypePieChartHighlightIndex: 0,

      avgAttendanceMax:0,
      // districtScale: 0,
      activeIndex: 0,
      allCheckTroubleTypeChartTitle: "",
      troubleTypeAllSearchKey: [],
      allCheckTroubleTypePopShow: false,
      allCheckTroubleTypePopChart: {},
      allCheckTroubleTypePopOption: {
        legend: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["40%", "70%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function (params) {
                  return `{value|${Math.round(params.percent)}%}\n{text|${
                    params.name
                  }}`;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },
      allCheckTroubleTypePopHighlightIndex: 0,
      troubleTypeAllPopSearchKey: [],
      allCheckTroubleTypePopTableTitle: "",
      allCheckTroubleTypePopTable: [],
      echartsPop:1
    };
  },
  watch: {
    orgLevel: function (newValue) {
      if (newValue) {
        this.getAll();
      }
    },
    districtLevel: function (newValue) {
    // console.log(newValue)
      if (newValue) {
        if (newValue == "100000") {
          this.districtScale = 0;
        }
        this.getAll();
      }
    },
   districtScale: function (newValue) {
    // console.log(newValue,oldValue)
      if (newValue) {
        this.districtScale = newValue;
      }
    },
  },
  mounted() {
    // const self = this
    // console.log(this.districtScale)
    const token = this.$route.query.token;
    window.token = token;

    this.getAll();
  },
  methods: {
    allCheckTroubleTypeChartClick(params) {
      this.allCheckTroubleTypePopShow = !this.allCheckTroubleTypePopShow;
      this.allCheckTroubleTypePopHighlightIndex = 0;
      this.allCheckTroubleTypePopTableTitle = "";
      this.allCheckTroubleTypeChartTitle =
        this.sevenDayAlarmsLineChart.series[0].data[params.dataIndex].name;
      //
      this.getPopEcharts(params.dataIndex);
    },
    getPopEcharts(mode) {
      nation
        .getSafetyTroubleCheckItem2Collect(
          this.troubleTypeAllSearchKey[mode]
        )
        .then((d) => {
          this.troubleTypeAllPopSearchKey = d.search_keys;
          if (this.$refs.safetyCheckPopItem) {
            //取消默认高亮
            this.$refs.safetyCheckPopItem.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: this.allCheckTroubleTypePopHighlightIndex,
            });
          }
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));

          this.allCheckTroubleTypePopOption.series[0].data = d.data;

          this.allCheckTroubleTypePopOption.series[1] = Object.assign(
            {},
            this.allCheckTroubleTypePopOption.series[0],
            {
              label: {
                rich: {
                  text: {
                    fontSize: 11,
                  },
                },
                formatter: function (params) {
                  return `${params.name} ${params.value}`;
                },
              },
              silent: true,
            }
          );

          setTimeout(
            () =>
              this.$refs.safetyCheckPopItem &&
              this.$refs.safetyCheckPopItem.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: this.allCheckTroubleTypePopHighlightIndex,
              }),
            100
          );
          this.allCheckTroubleTypePopChart = applyUpdate(
            chart,
            this.allCheckTroubleTypePopOption
          );
          this.getList(this.allCheckTroubleTypePopHighlightIndex);

          this.allCheckTroubleTypePopTableTitle =
            this.allCheckTroubleTypePopChart.series[0].data[
              this.allCheckTroubleTypePopHighlightIndex
            ].name;
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    getList(mode) {
      nation
        .getSafetyTroubleCheckItem3Collect(
          this.troubleTypeAllPopSearchKey[mode],
          1,
          100
        )
        .then((d) => {
          this.allCheckTroubleTypePopTable = d;
        });
    },
    allCheckTroubleTypePopChartClick(params) {
      if (this.$refs.safetyCheckPopItem) {
        //取消默认高亮
        this.$refs.safetyCheckPopItem.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.allCheckTroubleTypePopHighlightIndex,
        });
      }
      this.allCheckTroubleTypePopHighlightIndex = params.dataIndex;
      this.$refs.safetyCheckPopItem.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.allCheckTroubleTypePopHighlightIndex,
      });
      this.allCheckTroubleTypePopTableTitle =
        this.allCheckTroubleTypePopChart.series[0].data[params.dataIndex].name;

      this.getList(this.allCheckTroubleTypePopHighlightIndex);
    },
    allCheckTroubleTypePopMouseOver(params) {
      if (params.dataIndex != this.allCheckTroubleTypePopHighlightIndex) {
        this.$refs.safetyCheckPopItem.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.allCheckTroubleTypePopHighlightIndex,
        });
      }
    },
    allCheckTroubleTypePopMouseOut() {
      this.$refs.safetyCheckPopItem.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.allCheckTroubleTypePopHighlightIndex,
      });
    },
    dialogClose() {
      this.allCheckTroubleTypePopTable = [];
    },

    troubleTypePieChartClick(params) {
      if (this.districtScale !== 2) {
        this.echartsPop=1;
        this.activeIndex = params.dataIndex;
        this.popupSelect = this.mode;
        this.popupShow = true;
        this.popupTitle = params.name + "隐患分布";
        this.getPopData();
      }
    },
    troubleTypeNoPieChartClick(params) {
      if (this.districtScale !== 2) {
        this.echartsPop=2;
        this.activeIndex = params.dataIndex;
        this.popupSelect = 3;
        this.popupShow = true;
        this.popupTitle = params.name + "隐患分布";
        this.getPopData();
      }
    },
    getPopData() {
      if (this.popupSelect == 3) {
        nation
          .getSafetyProjectTroubleUndoCollect(
            this.echartsPop==1?(this.districtScale == 0&&this.orgType == 1
              ? this.troubleTypePieDetailSearchKey1[this.activeIndex]
              : ""):(this.districtScale == 0&&this.orgType == 1
              ? this.troubleTypePieDetailSearchKey2[this.activeIndex]
              : "")
            ,
            this.echartsPop==1?(this.districtScale == 1||this.orgType !== 1
              ? this.troubleTypePieDetailSearchKey1[this.activeIndex]
              : ""):(this.districtScale == 1||this.orgType !== 1
              ? this.troubleTypePieDetailSearchKey2[this.activeIndex]
              : "")
            
          )
          .then((d) => {
            this.troubleTypePieDetailTable = d.series[0].map((item, index) => {
              return {
                value1: item,
                value2: d.series[1][index],
                value3: d.series[2][index],
                count: d.series[3][index],
                project_name: d.xAxis_data[index],
              };
            });
            this.avgAttendanceMax = Math.max.apply(
              null,
              this.troubleTypePieDetailTable.map((item) => item.count)
            );
          });
      } else {
        nation
          .getSafetyProjectTroubleCollect(
            this.popupSelect,
            1,
            100,
            this.echartsPop==1?(this.districtScale == 0&&this.orgType == 1
              ? this.troubleTypePieDetailSearchKey1[this.activeIndex]
              : ""):(this.districtScale == 0&&this.orgType == 1
              ? this.troubleTypePieDetailSearchKey2[this.activeIndex]
              : ""),
            this.echartsPop==1?(this.districtScale == 1||this.orgType !== 1
              ? this.troubleTypePieDetailSearchKey1[this.activeIndex]
              : ""):(this.districtScale == 1||this.orgType !== 1
              ? this.troubleTypePieDetailSearchKey2[this.activeIndex]
              : "")
          )
          .then((d) => {
            this.troubleTypePieDetailTable = d;
            this.avgAttendanceMax = Math.max.apply(
              null,
              this.troubleTypePieDetailTable.map((item) => item.count)
            );
          });
      }
    },

    getAttendance(popupSelect) {
      this.popupSelect = popupSelect;
      this.getPopData();
    },
    getAll() {
      this.getTrouble(1);

      nation.getSafetyOrgCityUndoCollect().then((d) => {
        this.troubleTypePieDetailSearchKey2 = d.search_keys;
        let chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
        this.option.legend.data = d.legend_data;
        this.option.xAxis.data = d.xAxis_data;
        this.option.series[0].name = d.legend_data[0];
        this.option.series[0].data = d.series[0];
        this.option.series[1].name = d.legend_data[1];
        this.option.series[1].data = d.series[1];
        this.option.series[2].name = d.legend_data[2];
        this.option.series[2].data = d.series[2];
        if (this.districtScale == 2) {
          this.option.grid.left = "15%";
        } else {
          this.option.grid.left = "5%";
        }
        this.troubleTypeNoPieChart = applyUpdate(chart, this.option);
      });

      nation.getSafetyOrgCityCheckItemCollect().then((data) => {
        let d = data.data;

        this.troubleTypeAllSearchKey = data.search_keys;

        let chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
        chart.series[0].label.normal.formatter =
          attendancePieChartTemplate.series[0].label.normal.formatter;
        chart.color = colors;

        const maxPieces = 14;
        if (d.length > maxPieces) {
          d[maxPieces - 1].value = d
            .slice(maxPieces - 1)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue.value,
              0
            );
          d[maxPieces - 1].name = "其它检查项";
          d = d.slice(0, maxPieces);
        }
        chart.series[0].data = d.map((x) => ({
          name: x.name,
          value: x.value,
        }));
        if (window.innerWidth < 2250) {
          chart.series[0].radius = [35, 65];
        }

        if (this.checkTypePieChartHighlightIndex != null) {
          this.$refs.checkType &&
            this.$refs.checkType.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: this.checkTypePieChartHighlightIndex,
            });
        }

        // 默认高亮第一个值不是0的分片，否则默认第一片
        this.checkTypePieChartHighlightIndex = 0;
        for (let i = 0; i < d.length; i++) {
          if (d[i].people_count) {
            this.checkTypePieChartHighlightIndex = i;
            break;
          }
        }

        chart.series.push(
          Object.assign({}, chart.series[0], {
            label: {
              fontSize: 11,
              formatter: "{b}：{c}",
            },
            silent: true,
          })
        );

        setTimeout(
          () =>
            this.$refs.checkType &&
            this.$refs.checkType.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: this.checkTypePieChartHighlightIndex,
            }),
          100
        );
        this.sevenDayAlarmsLineChart = chart;
      });
    },
    getTrouble(mode) {
      this.popupSelect = mode;
      this.mode = mode;
      nation.getSafetyOrgCityTroubleCollect(mode).then((data) => {
        // console.log(data);
        let d = data.data;
        this.troubleTypePieDetailSearchKey1 = data.search_keys;
        let chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
        if (this.districtScale == 2) {
          chart.series[0].label.normal.formatter = function (params) {
            return `{value|${Math.round(params.value)}}`;
          };
          chart.series[0].label.normal.padding[0] = 0;
        } else {
          chart.series[0].label.normal.formatter =
            attendancePieChartTemplate.series[0].label.normal.formatter;
        }

        chart.color = colors;

        const maxPieces = 14;
        if (d.length > maxPieces) {
          d[maxPieces - 1].value = d
            .slice(maxPieces - 1)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue.value,
              0
            );
          d[maxPieces - 1].name = "其它";
          d = d.slice(0, maxPieces);
        }
        chart.series[0].data = d.map((x) => ({
          name: x.name,
          value: x.value,
        }));
        if (window.innerWidth < 2250) {
          chart.series[0].radius = [35, 65];
        }

        if (this.troubleTypePieChartHighlightIndex != null) {
          this.$refs.troubleType &&
            this.$refs.troubleType.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: this.troubleTypePieChartHighlightIndex,
            });
        }

        // 默认高亮第一个值不是0的分片，否则默认第一片
        this.troubleTypePieChartHighlightIndex = 0;
        for (let i = 0; i < d.length; i++) {
          if (d[i].people_count) {
            this.troubleTypePieChartHighlightIndex = i;
            break;
          }
        }

        chart.series.push(
          Object.assign({}, chart.series[0], {
            label: {
              fontSize: 11,
              formatter: "{b}：{c}",
            },
            silent: true,
          })
        );

        setTimeout(
          () =>
            this.$refs.troubleType &&
            this.$refs.troubleType.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: this.troubleTypePieChartHighlightIndex,
            }),
          100
        );
        this.troubleTypePieChart = chart;
      });
    },
    updateLevelOneTroublePoints(orgID) {
      nation
        .getTop10LevelOneTroublePoints(orgID)
        .then((d) => {
          this.levelOneTroublePoints = d;
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    // updateSevenDayAlarmsLineChart(orgID) {
    //   nation.get7DayAlarms(orgID)
    //     .then(d => {
    //       const chart = JSON.parse(JSON.stringify(singleLineChartTemplate));
    //       chart.color = ['#e32a2c', '#e0842d', '#28cce8', '#17a6e8'];
    //       const rgb_colors = [
    //         ['227', '42', '44'],
    //         ['224', '132', '45'],
    //         ['40', '204', '232'],
    //         ['23', '166', '232']
    //       ];
    //       chart.legend.top = 0;
    //       chart.grid.top = '14%';
    //       chart.grid.left = '5%';
    //       chart.xAxis.data = d.xAxis_data;
    //       chart.yAxis[0].minInterval = 1;
    //       chart.yAxis[0].name = '数量';
    //       chart.yAxis[0].nameTextStyle = {
    //         padding: [0, 40, 0, 0],
    //         color: '#e6f2f3',
    //         fontWeight: 'bold',
    //       };
    //       chart.series[0] = Object.assign({}, chart.series[0], d.series[0], {
    //         areaStyle: {
    //           color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
    //             {offset: 0, color: 'rgba(' + rgb_colors[0].join(', ') + ', 0.5)'},
    //             {offset: 1, color: 'rgba(' + rgb_colors[0].join(', ') + ', 0)'}
    //           ])
    //         }
    //       });
    //       for (let i = 1; i < d.series.length; i++) {
    //         chart.series.push(Object.assign({}, chart.series[0], d.series[i], {
    //           areaStyle: {
    //             color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
    //               {offset: 0, color: 'rgba(' + rgb_colors[i % rgb_colors.length].join(', ') + ', 0.5)'},
    //               {offset: 1, color: 'rgba(' + rgb_colors[i % rgb_colors.length].join(', ') + ', 0)'}
    //             ])
    //           }
    //         }));
    //       }
    //       this.sevenDayAlarmsLineChart = chart;
    //     })
    //     // eslint-disable-next-line
    //     .catch(e => console.error(e.msg || e.message))
    // },
    troubleTypeMouseOver(params) {
      if (params.dataIndex != this.troubleTypePieChartHighlightIndex) {
        this.$refs.troubleType.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.troubleTypePieChartHighlightIndex,
        });
        this.troubleTypePieChartHighlightIndex = params.dataIndex;
      }
    },
    troubleTypeMouseOut() {
      this.$refs.troubleType.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.troubleTypePieChartHighlightIndex,
      });
    },
    checkTypeMouseOver(params) {
      if (params.dataIndex != this.checkTypePieChartHighlightIndex) {
        this.$refs.checkType.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.checkTypePieChartHighlightIndex,
        });
        this.checkTypePieChartHighlightIndex = params.dataIndex;
      }
    },
    checkTypeMouseOut() {
      this.$refs.checkType.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.checkTypePieChartHighlightIndex,
      });
    },
    showDetail(val) {
      // eslint-disable-next-line no-empty
      if (val === 1) {
        // eslint-disable-next-line no-empty
      } else {
        // eslint-disable-next-line no-empty
      }
    },
  },
};
</script>

<style scoped lang="less">
.tooltips {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  // margin-bottom: 1rem;
  font-size: 0.6rem;
  color: #abbbbf;
  font-weight: bold;
}
.items {
  display: flex;
  align-items: center;
}
.tooltip-item {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.3rem;
}
.tooltip-item1 {
  background-color: #23cf9e;
}
.tooltip-item2 {
  background-color: #eab91e;
}
.tooltip-item3 {
  background-color: #e02f37;
}
.linebar {
  width: 100%;
}
.line-bar {
  display: flex;
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #57c7b6;
  border-radius: 12px;
}
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>