<template>
  <div id="right">
    <majorProjectChart :district-level="districtLevel" :org-level="orgLevel" />
    <studioAchiChart :district-level="districtLevel" :org-level="orgLevel" />
  </div>
</template>

<script>
  import majorProjectChart from "./majorProjectChart";
  import studioAchiChart from "./studioAchiChart";

  export default {
    name: 'QualityIndexRightBar',
    components: {
      majorProjectChart,
      studioAchiChart,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
  }
</script>

<style lang="less">
</style>
