import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    '药科楼': [-922, 297, 170],
    '基础实验楼、医技楼': [-902, -83, 170],
    '中药车间': [-850, 470, 140],
    '针灸护理楼': [-341, 293, 170],
    '动物解剖楼': [-947, 478, 170],
    '1号宿舍楼': [226, 507, 180],
    '2号宿舍楼': [427, 475, 180],
    '3号宿舍楼': [641, 507, 180],
    '4号宿舍楼': [235, 269, 180],
    '5号宿舍楼': [432, 269, 180],
    '6号宿舍楼': [689, 269, 180],
    '7号宿舍楼': [909, -20, 180],
    '宿舍配套楼': [748, 447, 180],
    '8号教学楼': [-346, -98, 185],
    '食堂': [-44, 472, 170],
    '1#教师周转宿舍': [880, 300, 180],
    '2#教师周转宿舍': [886, 166, 180],
    '学生活动中心': [630, -2, 170],
    '体育馆': [1000, -257, 170],
    '研发楼': [-626, 468, 170],
    '图书馆': [-631, 114, 180],
  };

  const buildingBoundaries = {
    '药科楼': [[-1013.5625000291169,364.87500010709596],[-803.562499882002,364.8750001070962],[-799.5624998791998,162.87499996558563],[-1007.5625000249134,162.87499996558563]],
    '基础实验楼、医技楼': [[-1021.220399474496,69.60423462792926],[-1018.4802842427961,-245.50901701756754],[-785.5704895482984,-253.72936271266747],[-782.8304389023373,-48.219661192685976],[-865.0338312675975,66.86411939622928]],
    '中药车间': [[-895.5624999786772,546.8750002752274],[-809.5624999184303,548.8750002766285],[-805.5624999156281,406.8750001771509],[-893.5624999772762,404.87500017574985]],
    '针灸护理楼': [[-457.5625001748306,360.87500007066774],[-257.5625000347211,362.8750000720688],[-245.5625000263144,164.87499993336053],[-457.5625001650228,164.87499993336053]],
    '动物解剖楼': [[-1015.7243682329225,546.4232445459252],[-1015.7243682329224,403.93725249752674],[-914.3401046600235,403.93725249752674],[-914.3401046600236,546.4232445459254]],
    '1号宿舍楼': [[148.43241884965187,564.8276309329383],[314.4324189659426,564.8276309329383],[316.4324189673441,380.8276308040374],[146.4324188482508,378.82763080263635]],
    '2号宿舍楼': [[337.12621848543813,568.3511096949669],[339.8663337171381,376.5430434759687],[512.493593314236,379.2831587076687],[515.2337085459362,565.6109944632667]],
    '3号宿舍楼': [[554.4169848325423,564.8197360892593],[720.416984948833,562.8197360878582],[726.4169849530363,380.8197359603589],[556.4169848339434,378.81973595895784]],
    '4号宿舍楼': [[142.4296241571053,330.7180898129718],[310.4296242747971,326.71808981016966],[312.4296242761984,146.71808968407117],[144.4296241585066,144.7180896826701]],
    '5号宿舍楼': [[348.42409844037593,328.7092081124324],[516.4240985580677,326.70920811103133],[518.4240985594688,148.70920798633415],[352.4240984431783,146.70920798493285]],
    '6号宿舍楼': [[600.4148888485097,328.7101949678922],[770.414888967603,328.7101949678922],[772.4148889690041,146.71019484039266],[600.4148888485097,144.7101948389918]],
    '7号宿舍楼': [[805.4971961759716,80.55486593702005],[810.9818111575069,-100.2946747658566],[986.3491859863047,-103.03478999755669],[983.6090707546045,83.29304575804156]],
    '宿舍配套楼': [[720.6765143477105,519.0128345016701],[720.6765143477105,384.74718814837115],[764.5183580549101,384.74718814837115],[769.99858851831,516.2727192699699]],
    '8号教学楼': [[-485.56561260952617,60.64308860887911],[-227.56561242878524,64.64308861168138],[-231.5656124315874,-253.35691161109264],[-483.5656126081253,-249.35691160829037]],
    '食堂': [[-169.56167441821503,566.818749235201],[70.4383257499162,560.8187492309976],[66.43832574711382,400.8187491189103],[-167.56167441681396,400.8187491189103]],
    '1#教师周转宿舍': [[817.106809099816,339.33923671052025],[814.8884379342762,277.14681040581354],[934.8392979501286,277.14681040581354],[939.280902768734,334.89650649418513]],
    '2#教师周转宿舍': [[821.5523746018239,194.95825817272953],[819.3310623793072,139.4254526098348],[954.8311079527707,137.20414038731917],[952.609795730255,194.95825817272942]],
    '学生活动中心': [[490.6344944968089,79.40890205321023],[495.07711894184104,-84.96820241295791],[739.4214634185773,-91.63213908050523],[741.6427756410931,74.96627760817864]],
    '体育馆': [[861.4345890564678,-171.61048007570298],[861.4345890564678,-355.97939454451324],[1119.106806868299,-353.7580823219975],[1119.106806868299,-171.61048007570298]],
    '研发楼': [[-750.4129635358378,517.0541591057811],[-750.4129635358378,412.65248464753927],[-514.9538679491643,417.09510909257074],[-517.17518017168,519.2754713282968]],
    '图书馆': [[-754.8641273956601,186.040175384466],[-754.8641273956601,48.31881758848726],[-514.9624073639552,50.540129811003],[-514.9624073639552,190.4827998294976]],
  };

  const buildingFinishedHeights = {
    '药科楼': 200,
    '基础实验楼、医技楼': 200,
    '中药车间': 200,
    '针灸护理楼': 200,
    '动物解剖楼': 200,
    '1号宿舍楼': 200,
    '2号宿舍楼': 200,
    '3号宿舍楼': 200,
    '4号宿舍楼': 200,
    '5号宿舍楼': 200,
    '6号宿舍楼': 200,
    '7号宿舍楼': 200,
    '宿舍配套楼': 200,
    '8号教学楼': 200,
    '食堂': 200,
    '1#教师周转宿舍': 200,
    '2#教师周转宿舍': 200,
    '学生活动中心': 200,
    '体育馆': 200,
    '研发楼': 200,
    '图书馆': 200,
  };

  const doneFloors = {
    '药科楼': 2,
    '基础实验楼、医技楼': 2,
    '中药车间': 2,
    '针灸护理楼': 2,
    '动物解剖楼': 2,
    '1号宿舍楼': 2,
    '2号宿舍楼': 2,
    '3号宿舍楼': 2,
    '4号宿舍楼': 2,
    '5号宿舍楼': 2,
    '6号宿舍楼': 2,
    '7号宿舍楼': 2,
    '宿舍配套楼': 2,
    '8号教学楼': 2,
    '食堂': 2,
    '1#教师周转宿舍': 0,
    '2#教师周转宿舍': 0,
    '学生活动中心': 0,
    '体育馆': 0,
    '研发楼': 0,
    '图书馆': 0,
  };

  const traceViews = {
    '_': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    '药科楼': {
      center: [-7452.872678057295, 2707.84566374897],
      pitch: 80,
      zoom: 4.74,
      bearing: -28.6,
    },
    '基础实验楼、医技楼': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    '中药车间': {
      center: [-6714.872021019633, 3600.039413167942],
      pitch: 61.6,
      zoom: 4.73,
      bearing: -44.2,
    },
    '针灸护理楼': {
      center: [-3420.551270085001, 2896.2701914340005],
      pitch: 80,
      zoom: 4.62,
      bearing: -50.4,
    },
    '动物解剖楼': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    '1号宿舍楼': {
      center: [940.2821696385859, 5482.143475917781],
      pitch: 80,
      zoom: 4.28,
      bearing: -30.4,
      needsTransparent: ['4号宿舍楼'],
    },
    '2号宿舍楼': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    '3号宿舍楼': {
      center: [3951.1203074277996, 5293.242968534134],
      pitch: 80,
      zoom: 4.28,
      bearing: -43.6,
      needsTransparent: ['6号宿舍楼'],
    },
    '4号宿舍楼': {
      center: [973.3289686505651, 3594.5560047356944],
      pitch: 80,
      zoom: 4.28,
      bearing: -31,
    },
    '5号宿舍楼': {
      center: [2306.047549372714, 3383.774091198922],
      pitch: 80,
      zoom: 4.28,
      bearing: -43.6,
      needsTransparent: ['4号宿舍楼'],
    },
    '6号宿舍楼': {
      center: [4492.427492113707, 3339.209448946763],
      pitch: 80,
      zoom: 4.28,
      bearing: -43.6,
      needsTransparent: ['4号宿舍楼'],
    },
    '7号宿舍楼': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    '宿舍配套楼': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    '8号教学楼': {
      center: [-3078.939290811023, -150.5892016424390],
      pitch: 80,
      zoom:  4.28,
      bearing: -36.4,
    },
    '食堂': {
      center: [-187.71369870522767, 4068.7471157599925],
      pitch: 80,
      zoom: 4.74,
      bearing: 34.4,
    },
    '1#教师周转宿舍': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    '2#教师周转宿舍': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    '学生活动中心': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    '体育馆': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    '研发楼': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    '图书馆': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
  };

  function getUserZValue(user) {
    return (user.floor == 'ground' ? 0 : user.height) * 2.9 + 5;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 2.9;
  }

  function getZValueFromHeight(height) {
    return height * 2.9;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/bozhou.glb',
    modelType: 'gltf',
    modelSubType: 'draco',

    //fullOpacity: 0.85,
    //revealOpacity: 0.15,
    //deepRevealOpacity: 0,

    modelOptions: {
      scale: 0.0025,
      rotZ: 0,
      moveX: -1550,
      moveY: -773,
      moveZ: 1.0815,
    },

    sceneRotation: {
      x: 0,
      y: 0,
      z: 0
    },

    lastBldgSwitchIndicesFile: '/bozhou.json',
    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
