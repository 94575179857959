<template>
  <div style="width: 100%;margin:auto">
    <div style="display:flex;padding-left:3rem;margin-bottom:1rem">
      <div v-for="(project, index) in projectList" class="option-block" :class="{ 'option-block-selected': currProjectIdx == index }" style="margin-right:1.5rem" @click="currProjectIdx = index" :key="project.innerid">
        {{project.name}}
      </div>
    </div>
    <HeatmapWrapper v-if="!!projectList.length" :project-id="projectList[currProjectIdx].innerid" :key="currProjectIdx" />
  </div>
</template>

<script>
import { nation } from '../../api';
import HeatmapWrapper from '../project2/heatmap_wrapper';

export default {
  name: 'Heatmap',
  components: {
    HeatmapWrapper,
  },
  data() {
    return {
      projectList: [],
      currProjectIdx: 0,
    }
  },
  mounted() {
    const token = this.$route.query.token;
    window.token = token;

    nation.getHeatmapProjects()
      .then(d => {
        this.projectList = d;
      })
      // eslint-disable-next-line
      .catch(e => console.error(e.msg || e.message))
  },
  methods: {
  },
}
</script>

<style scoped lang="less">
</style>
