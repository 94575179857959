import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    '1#': [474.7975076324048,73.28169323407843, 650],
    '2#': [51.0903987743529,75.07374152751873, 550],
    '3#': [-328.96660217337825,-108.64172545019503, 600],
    '4#': [524.7816965184869,-344.5434182895388, 550],
    '5#': [94.90869418855561,-420.5116707843172, 650],
    '6#': [-336.8665773417126,-504.4551260041566, 550],
    //'7#':
    '8#': [-327.00684126701447,101.30011503895425, 200],
  };

  const buildingBoundaries = {
    '1#': [[254.9387890398714,169.21627018285676],[692.6951168661757,171.21515752452933],[704.6884409162112,-38.66801335109591],[252.9399016981989,-36.66912600942334]],
    '2#': [[-110.63157551516656,165.0260702675784],[214.8304513161359,163.23190080701],[218.82822599948108,-18.6668472851984],[-114.98596005984672,-18.6668472851984]],
    '3#': [[-540.8491823621662,-10.696245708236575],[-113.08729124422516,-10.696245708236518],[-111.08840390255259,-224.57719126720707],[-538.1612911349408,-226.16657830016854]],
    '4#': [[312.89911632969915,-248.59682588925298],[734.6643454226223,-250.59571323092567],[736.663232764295,-446.4866727148425],[316.8968910130444,-450.4844473981879]],
    '5#': [[-106.97944929186909,-324.56480118636773],[290.7991317009825,-320.5670265030225],[287.2072484528242,-536.2883823434117],[-108.97833663354174,-542.4435214286834]],
    '6#': [[-510.8653502655868,-410.52859918016395],[-155.06340344786025,-408.52971183849127],[-155.06340344786025,-606.4195586640808],[-514.8631249489321,-604.420671322408]],
    //'7#': [],
    '8#': [[-519.3702444454759,183.73427938719618],[-550.4439163665171,38.01691382780962],[-125.07852740830148,25.312554998890278],[-125.24208263232023,187.15367301259698]],
 };

  const buildingFinishedHeights = {
    '1#': 0,
    '2#': 0,
    '3#': 0,
    '4#': 0,
    '5#': 0,
    '6#': 0,
    //'7#': 200,
    '8#': 0,
  };

  const doneFloors = {
    '1#': 0,
    '2#': 0,
    '3#': 0,
    '4#': 0,
    '5#': 0,
    '6#': 0,
    //'7#': 10,
    '8#': 0,
  };

  const traceViews = {
    '_': {
      center: [0, 0],
      pitch: 0,
      zoom: 2,
    },
    '1#': {
      center: [-490.6327694896288, 10619.581147454486],
      pitch: 77.6,
      zoom: 2.20,
      bearing: -29.2,
    },
    '2#': {
      center: [-1680.5392803242896, 6636.419686342342],
      pitch: 72.8,
      zoom: 2.78,
      bearing: -23.8,
    },
    '3#': {
      center: [-4932.986204856222, 5484.315633410989],
      pitch: 73.2,
      zoom: 2.71,
      bearing: -25,
    },
    '4#': {
      center: [2212.056664261967, 3287.4480427477943],
      pitch: 73.2,
      zoom: 2.71,
      bearing: -25,
    },
    '5#': {
      center: [3031.3575514708427, 5538.983816786525],
      pitch: 76,
      zoom: 2.39,
      bearing: 9.2,
    },
    '6#': {
      center: [-4394.649286554297, 2384.8453266692445],
      pitch: 73.6,
      zoom: 2.70,
      bearing: -22,
    },
    '8#': {
      center: [2646.8921272194084, -9226.568956560674],
      pitch: 82.8,
      zoom: 2.18,
      bearing: 147.8,
    },
  };

  function getUserZValue(user) {
    return (user.floor == 'ground' ? 0 : user.height) * 7 + 11;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 7;
  }

  function getZValueFromHeight(height) {
    return height * 7;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/ghy.glb',
    modelType: 'gltf',
    modelSubType: 'draco',

    show2DVariant: true,

    //fullOpacity: 0.85,
    //revealOpacity: 0.15,
    //deepRevealOpacity: 0,

    modelOptions: {
      scale: 7,
      rotZ: 0,
      moveX: -520,
      moveY: 250,
      moveZ: 0,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: 0,
      z: 0
    },

    lastBldgSwitchIndicesFile: '/ghy.json',
    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
