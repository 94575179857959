<template>
  <div
    id="right"
    class="anti"
  >
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>告警统计</div>
        <div
          class="detail"
          @click="showAlarmDetail"
        >
          详情
        </div>
      </div>
      <div style="height:24rem;padding-top:3rem">
        <div style="display:flex;justify-content:flex-end;">
          <CustomSelect
            :options="antiList"
            :value="selectedVal1"
            @input="antiSelect($event, 'val1')"
            style="width:8rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;"
          />

          <el-date-picker
            v-model="selectdate1"
            @change="dataChange($event, 'val1')"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div
          v-if="alarmStatisticsChart.series && [].concat.apply([], alarmStatisticsChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="alarmStatisticsChart"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem;margin-bottom:1rem"
    >
      <div class="info-block-title">报警情况</div>
      <div style="height:24rem;padding-top:3rem">
        <div style="display:flex;justify-content:flex-end;">
          <CustomSelect
            :options="companyNameList"
            :value="selectedVal2"
            @input="companySelect($event, 'val1')"
            style="width:8rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;"
          />

          <el-date-picker
            v-model="selectdate2"
            @change="dataChange($event, 'val2')"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div
          v-if="option2.series && [].concat.apply([], option2.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="warnChart"
          autoresize
        />
      </div>
    </div>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="90rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:32rem;"
      >
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div
            class="stats-chart-block"
            style="height:16rem;width:86.25rem;"
          >
            <div
              class="stats-close-btn"
              @click="popupShow = false"
            />
            <div style="margin:0 1rem 0 1rem;display:flex;justify-content:space-between;">

              <div style="font-size:18px;font-weight:bold">告警情况
              </div>

              <div style="display:flex;justify-content:flex-end;">
                <CustomSelect
                  :options="companyNameList"
                  :value="selectedVal4"
                  @input="companySelect($event, 'val2')"
                  style="width:8rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;"
                />
                <CustomSelect
                  :options="antiList"
                  :value="selectedVal3"
                  @input="antiSelect($event, 'val2')"
                  style="width:8rem;line-height:1.6rem;font-size:0.8rem;font-weight:bold;margin-left:1rem"
                />

                <el-date-picker
                  v-model="selectdate3"
                  @change="dataChange($event, 'val3')"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  size="mini"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </div>
            </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:5rem">序号</div>
                <div style="width:30rem">项目名称</div>
                <div style="width:8rem">预警数</div>
                <div style="width:8rem">报警数</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popThreeDayManagementTopProjects"
                  :key="item.project_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:5rem">
                    {{securityStarPage>1?(securityStarPage-1)*10+index+1:(index+1>9?(index+1):'0'+(index+1))}}
                  </div>
                  <div style="width:30rem">{{item.project_name}}</div>
                  <div style="width:8rem;">{{item.warning_count}}</div>
                  <div style="width:8rem;">{{item.alarm_count}}</div>
                </div>
                <div
                  v-if="!(popThreeDayManagementTopProjects && popThreeDayManagementTopProjects.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无告警情况
                </div>
              </div>
              <el-pagination
                background
                layout="prev, pager, next"
                :current-page.sync="securityStarPage"
                :page-count="securityStarPages"
                @current-change="securityStarPageChange"
                style="text-align:center;margin-top:0.5rem"
              ></el-pagination>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import wrapper from "../../components/wrap";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { nation } from "../../api";
import { SimpleBarChartTemplate } from "./barChartTemplates";
import { SimpleRadarChartTemplate } from "./radarChartTemplates";
import CustomSelect from "../../components/select";
// import { cubeBarChartTemplate } from "./barChartTemplates";
import { applyUpdate, truncateStr } from "../../utils";

export default {
  name: "IndexRightBar",
  components: {
    "v-chart": ECharts,
    wrapper,
    CustomSelect: CustomSelect,
  },
  props: {
    broadcastDetails: Object,
    districtLevel: String,
    orgLevel: String,
    orgOptions: Array,
    orgType: Number,
    districtScale: Number,
    gotoProject: Function,
  },
  data() {
    const eDate = this.getDate({
      format: true,
    });
    const sDate = this.getDate("sDate");
    return {
      antiList: ["防碰撞"],
      antiIdList: ["111"],
      selectedVal1: "",
      selectedId1: "",
      selectedVal3: "",
      selectedId3: "",

      companyNameList: ["全部分公司"],
      companyIdList: [""],
      selectedVal2: "",
      selectedId2: "",
      selectedVal4: "",
      selectedId4: "",
      selectdate1: [sDate, eDate],
      selectdate2: [sDate, eDate],
      selectdate3: [sDate, eDate],

      alarmStatisticsChart: {},
      option: {
        color: ["#F1904B", "#DE3139"],
        grid: {
          contentLabel: true,
          left: "12%",
          bottom: "15%",
          top: "20%",
          right: "12%",
        },
        legend: {
          top: 20,
          data: [],
          textStyle: {
            fontSize: 11, //字体大小
            color: "#ffffff", //字体颜色
          },
        },
        tooltip: {
          trigger: "axis",
          // formatter: "{b}<br>{a0}: {c0}<br>{a1}: {c1}"
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}<br>` +
                params[0].marker +
                " " +
                params[0].seriesName +
                " : " +
                (params[0].data.value ? params[0].data.value : params[0].data);
              if (params[1]) {
                result +=
                  "</br>" +
                  params[1].marker +
                  " " +
                  params[1].seriesName +
                  " : " +
                  params[1].data;
              }
            }
            return result;
          },
        },
        xAxis: [
          {
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              rotate: 35,
            },
            show: true,
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "预警数：次",
            minInterval: 1,
            nameTextStyle: {
              padding: [0, 40, 0, 40],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#1E2573",
                width: 1,
              },
            },
            axisLine: { show: false },
            axisTick: { show: false },
          },
          {
            type: "value",
            name: "报警数：次",
            minInterval: 1,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: false },
            splitLine: { show: false },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: "数量",
            type: "bar",
            barWidth: 10,
            data: [],
          },
          {
            name: "数量",
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                },
              },
            },
            symbolSize: 0,
            yAxisIndex: 1,
            data: [],
          },
        ],
      },

      warnChart: {},
      option2: {
        legend: {
          data: [],
        },
        radar: {
          // shape: 'circle',
          indicator: [],
          name: {
            color: "#ffffff",
            fontSize: 12,
            formatter: function (text) {
              var strlength = text.length;
              if (strlength % 6 != 0) {
                text = text.replace(/\S{6}/g, function (match) {
                  return match + "\n";
                });
              } else {
                text = text.replace(/\S{6}/g, function (match) {
                  return match + "\n";
                });
                strlength = text.length;
                text = text.substring(0, strlength - 1);
              }
              return text;
            },
          },
        },
        series: [
          {
            name: "报警情况",
            type: "radar",
            data: [
              {
                value: [],
                // name: 'Allocated Budget',
                areaStyle: {
                  color: "rgba(241,214,36, 0.6)",
                },
              },
            ],
          },
        ],
      },

      popupShow: false,

      popThreeDayManagementTopProjects: [],
      securityStarPage: 1,
      securityStarPages: 1,
    };
  },
  watch: {
    orgOptions(newValue, oldValue) {
      if (newValue != oldValue) {
        this.companyNameList = newValue.map((item) =>
          truncateStr(item.org_name, 10)
        );
        this.companyIdList = newValue.map((item) => item.org_id);

        let idx = this.companyIdList.indexOf(this.orgLevel);
        if (idx == -1) {
          idx = 0;
        }
        this.selectedVal2 = this.companyNameList[idx];
        this.selectedVal4 = this.companyNameList[idx];
        this.selectedId2 = this.orgLevel;
        this.selectedId4 = this.orgLevel;
        this.getData();
      }
    },
    orgLevel: function (newValue) {
      if (newValue) {
        const idx = this.companyIdList.indexOf(newValue);
        if (idx >= 0) {
          this.selectedVal2 = this.companyNameList[idx];
          this.selectedVal4 = this.companyNameList[idx];
          this.selectedId2 = this.orgLevel;
          this.selectedId4 = this.orgLevel;
        }
        this.getData();
      }
    },
    districtLevel: function (newValue) {
      if (newValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
        this.getData();
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.mapLevel = newValue;
        if (newValue == 2) {
          this.getData();
        }
      }
    },
  },
  mounted() {
    // const self = this;
    const token = this.$route.query.token;
    window.token = token;

    this.companyNameList = this.orgOptions.map((item) =>
      truncateStr(item.org_name, 10)
    );
    this.companyIdList = this.orgOptions.map((item) => item.org_id);

    let idx = this.companyIdList.indexOf(this.orgLevel);
    if (idx == -1) {
      idx = 0;
    }
    this.selectedVal2 = this.companyNameList[idx];
    this.selectedVal4 = this.companyNameList[idx];

    this.getData();

    // window.setInterval(() => {
    //   self.getData();
    // }, 5000);
  },
  methods: {
    getDate(type) {
      const date = new Date();
      if (type == "sDate") {
        date.setDate(date.getDate() - 6);
      }
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      month = month > 9 ? month : "0" + month;
      day = day > 9 ? day : "0" + day;
      return `${year}-${month}-${day}`;
    },
    companySelect(option, val) {
      const idx = this.companyNameList.indexOf(option);
      if (idx >= 0) {
        if (val === "val1") {
          this.selectedVal2 = option;
          this.selectedId2 = this.companyIdList[idx];
          this.getSeconedChart();
        } else {
          this.selectedVal4 = option;
          this.selectedId4 = this.companyIdList[idx];
          this.securityStarPage = 1;
          this.securityStarPageChange(1);
        }
      }
    },
    antiSelect(option, val) {
      const idx = this.antiList.indexOf(option);
      if (idx >= 0) {
        this.selectedId1 = this.antiIdList[idx];
        if (val === "val1") {
          this.selectedVal1 = option;
          this.getFirstChart();
        } else {
          this.selectedVal3 = option;
          this.securityStarPage = 1;
          this.securityStarPageChange(1);
        }
      }
    },
    dataChange(option, val) {
      if (val == "val1") {
        this.selectdate1 = option;
        this.getFirstChart();
      } else if (val == "val2") {
        this.selectdate2 = option;
        this.getSeconedChart();
      } else {
        this.selectdate3 = option;
        this.securityStarPage = 1;
        this.securityStarPageChange(1);
      }
    },

    getData() {
      this.getFirstChart();
      this.getSeconedChart();
    },
    getFirstChart() {
      nation
        .getSafetyAlarm7DaysList(this.selectdate1[0], this.selectdate1[1])
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option.legend.data = d.legend_data;
          this.option.xAxis[0].data = d.xAxis_data;
          this.option.series[0].name = d.legend_data[0];
          this.option.series[0].data = d.series[0].data;
          this.option.series[1].data = d.series[1].data;
          this.option.series[1].name = d.legend_data[1];

          this.alarmStatisticsChart = applyUpdate(chart, this.option);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
    getSeconedChart() {
      nation
        .getSafetyAlarmTypeCollect(
          this.selectdate2[0],
          this.selectdate2[1],
          this.selectedId2
        )
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleRadarChartTemplate));
          this.option2.radar.indicator = d.alarm_list.map((item) => {
            return {
              name: item.name,
              max: d.max,
            };
          });
          this.option2.series[0].data[0].value = d.alarm_list.map((item) => {
            return item.value;
          });
          this.warnChart = applyUpdate(chart, this.option2);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },

    showAlarmDetail() {
      this.popupShow = !this.popupShow;
      const idx = this.companyIdList.indexOf(this.orgLevel);
      this.selectedVal4 = this.companyNameList[idx];
      this.securityStarPage = 1;
      this.securityStarPageChange(1);
    },
    securityStarPageChange(currPage) {
      nation
        .getSafetyAntiProjectAlarmTypeCollect(
          this.selectdate3[0],
          this.selectdate3[1],
          this.selectedId4,
          currPage,
          10
        )
        .then((d) => {
          this.popThreeDayManagementTopProjects = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .el-date-editor {
  justify-content: space-around;
  margin-left: 1rem;
  width: 12rem !important;
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}
/deep/ .el-range-input {
  background-color: #0b1d39 !important;
}
/deep/ .el-date-editor .el-range-input {
    color: #eee !important;
}
/deep/ .el-date-editor .el-range-separator {
    color: #ccc !important;
}
/deep/ .el-range-separator {
  width: 12% !important;
  text-align: center !important;
}
/deep/ .el-date-editor i {
  display: none !important;
}

.alarm-icon {
  width: 60px;
  height: 59px;
}

.option-detail {
  position: absolute;
  color: #c8dbf4;
  font-weight: bold;
  right: 0.5rem;
  top: 0.6rem;
  font-size: 0.6rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  border: 1px solid #0d2b50;
  text-align: center;
  cursor: pointer;
  background-color: #4999d9;
  // background-color: #0a57be;
}
#alarm-drop {
  background: url("../../assets/nation/坠落报警.png");
  background-size: contain;
}
#alarm-help {
  background: url("../../assets/nation/紧急呼救.png");
  background-size: contain;
}
#alarm-danger {
  background: url("../../assets/nation/危险预警.png");
  background-size: contain;
}
#alarm-static {
  background: url("../../assets/nation/静止报警.png");
  background-size: contain;
}

.alarm-icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
