<template>
  <div class="info-block" style="min-height:5rem">
    <div class="info-block-title" style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >最佳方案排行
      <div class="detail" @click="showDetail()">
        详情
      </div>  
    </div>
    <div style="height:17rem;padding-top:3rem">
      <div style="margin-top:0; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:25rem;text-align:center">方案名称</div>
                <div style="width:8rem">方案级别</div>
                <div style="width:8rem">质量评价</div>
                <div style="width:8rem">编制人</div>
              </div>
              <div style="height:18rem;overflow:auto;">
                <div
                  v-for="(item) in schemeDetailList"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                >
                  <div style="width:25rem"><a style="color:#fff" href = "#" v-bind:title = item.FXMMC>{{item.FFAMC}}</a></div>
                  <div style="width:8rem">
                    {{item.FMC}}
                  </div>
                  <div style="width:8rem;">{{item.FFAZL}}</div>
                  <div style="width:8rem;">{{item.FBZR}}</div>
                </div>
                <div
                  v-if="!(schemeDetailList && schemeDetailList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>
    </div>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">最佳方案排行
            </div>
            <!-- <div class="tabbar" style="width:22rem">
              <div
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >正常（{{normal}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 5 }"
                @click="getAttendance(5)"
              >缓建（{{wait}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 4 }"
                @click="getAttendance(4)"
              >停工（{{stop}}）</div>
            </div> -->
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:8rem;text-align:center">序号</div>
                <div style="width:12rem;text-align:center">分公司</div>
                <div style="width:20rem;text-align:center">项目名称</div>
                <div style="width:20rem;text-align:center">方案名称</div>
                <div style="width:8rem;text-align:center">方案级别</div>
                <div style="width:8rem;text-align:center">质量评价</div>
                <div style="width:8rem;text-align:center">编制人</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popProjectsList"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:8rem;text-align:center">{{index+1}}</div>
                  <div style="width:12rem;text-align:center">{{item.ORGNAME}}</div>
                  <div style="width:20rem;text-align:center">{{item.FXMMC}}</div>
                  <div style="width:20rem;text-align:center">{{item.FFAMC}}</div>
                  <div style="width:8rem;text-align:center">{{item.FMC}}</div>
                  <div style="width:8rem;text-align:center">{{item.FFAZL}}</div>
                  <div style="width:8rem;text-align:center">{{item.FBZR}}</div>
                </div>
                <div
                  v-if="!(popProjectsList && popProjectsList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>

        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  // import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { tech } from '../../../../api_tech'
  // import { stackedBarChartTemplate } from "../../barChartTemplates";
  // import { applyUpdate } from "../../../../utils";
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'GoodPlanChart',
    components: {
      wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateGoodPlanChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateGoodPlanChart();
        }
      }
    },
    data () {
      return {
        goodPlanChart: {},
        schemeDetailList:[],
        popupShow:false,
        popProjectsList:[]
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.updateGoodPlanChart();
    },
    methods: {
      updateGoodPlanChart() {
        tech.getGoodPlan()
          .then(d => {
            this.schemeDetailList = d;
          })
          // eslint-disable-next-line
          .catch((e) => console.error(e.msg || e.message));
      },
      showDetail(){
        this.popupShow =true;
        tech.getGoodPlan().then(res=>{
          this.popProjectsList = res;
        })
      }
    },
  }
</script>

<style lang="less">
</style>
