<template>
  <div id="left">
    <div
      class="info-block"
      style="min-height:5rem"
    >

      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>近3日管理现场出勤率</div>
        
      </div>
      <div style="height:24rem;padding-top:3rem">
        <div style="display:flex;align-items:center;justify-content:right;margin-right:1rem">
          <div
            class="option-block2"
            style="min-width: 3rem;"
            :class="{ 'option-block-selected2': mode == 0 }"
            @click="() => swithAttendance(0)"
          >
            全部
          </div>
          <div
            class="option-block2"
            style="min-width: 3rem;"
            :class="{ 'option-block-selected2': mode == 1 }"
            @click="() => swithAttendance(1)"
          >
            部门领导
          </div>
        </div>
        <div
          v-if="managerAttendanceRate.series && [].concat.apply([], managerAttendanceRate.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          @click="managerAttendanceRateClick"
          :options="managerAttendanceRate"
          autoresize
        />
      </div>
    </div>
    <div
      class="info-block"
      style="min-height:5rem"
    >

      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>领导下沉出勤率</div>
        <div
          class="detail"
          @click="showDetail(1)"
        >
          详情
        </div>
      </div>
      <div style="height:20rem;padding-top:3rem">
        <div v-if="orgType < 2" style="display:flex;align-items:center;justify-content:flex-end;margin-right:1rem">
          <div
            class="option-block2"
            style="min-width: 3rem;"
            :class="{ 'option-block-selected2': mode2 == 1 }"
            @click="() => swithAttendance2(1)"
          >
            总部
          </div>
          <div
            class="option-block2"
            style="min-width: 3rem;"
            :class="{ 'option-block-selected2': mode2 == 2 }"
            @click="() => swithAttendance2(2)"
          >
            分公司
          </div>
        </div>
        <div
          v-if="managerHeadSinkAttendance.series && [].concat.apply([], managerHeadSinkAttendance.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart
          v-else
          :options="managerHeadSinkAttendance"
          autoresize
        />
      </div>
    </div>

    
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="60rem"
      top="15rem"
    >
      <wrapper style="height:28rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style="display: flex;align-items: center;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle}}
            </div>
            <div class="tabbar" style="width:18rem;margin:0">
              <div
                :class="{ 'tabar-select': popupSelect == 0 }"
                @click="getAttendance(0)"
              >全部</div>
              <div
                :class="{ 'tabar-select': popupSelect == 1 }"
                @click="getAttendance(1)"
              >部门领导</div>
            </div>
          </div>
          <div style="display:flex; align-items: center;margin-top:1rem;justify-content: space-between;">
            <div></div>
            <div>

            </div>
            <div style="font-size:12px;color:#fff;">出勤率：%</div>
          </div>
          <!-- <div style="border:1px solid #112563;margin:1.5rem 1rem"> -->
          <div style="height:20rem;padding:1rem 0 0 1rem">
            <div style="height:100%;overflow-y:scroll">
              <div
                v-for="(item, index) in warningTable"
                :key="index"
              >

                <div style="color: #A7B6BC;">
                  {{item.name}}
                </div>
                <div class="linebar">

                  <div
                    style="flex: 5"
                    class="line-bar"
                  >
                    <div
                      class="real-bar"
                      :style="{'width': (item.value / avgAttendanceMax * 100) + '%' }"
                    ></div>

                  </div>

                  <div style="color: #57C7B6;width:60px">
                    {{item.value}}%
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { project,nation } from "../../api";
import { applyUpdate, truncateStr } from "../../utils";
import { SimpleBarChartTemplate } from "./barChartTemplates";
import wrapper from "../../components/wrap";

export default {
  name: "ManagerLeftBar",
  components: {
    "v-chart": ECharts,
      wrapper,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    orgType: Number,
    districtScale: Number,
  },
  data() {
    return {
      managerAttendanceRate: {},
      managerHeadSinkAttendance: {},
      mode: 0,
      mode2: 1,
      option1: {
        color: ["#5dd5c3"],
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0}: {c0}",
        },
        grid: {
          top: "12%",
          left: "12%",
          right: "9%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            axisTick: {
              //y轴刻度线
              show: false,
            },
            axisLabel: {
              color: "#e6f2f3",
              rotate: 40,
              fontWeight: "normal",
              formatter: value => truncateStr(value, 10),
            },
          },
        ],
        yAxis: [
          {
            max: 100,
            show: true,
            name: "出勤率：%",
            type: "value",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
              align: "left",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: {
              show: false,
            },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: "出勤率",
            type: "bar",
            barWidth: 10,
            barMaxWidth: 18,
            data: [],
          },
        ],
      },
      option2: {
        color: ["#46a0ff"],
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br>{a0}: {c0}",
        },
        grid: {
          top: "12%",
          left: "12%",
          right: "9%",
          bottom: "15%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            axisTick: {
              //y轴刻度线
              show: false,
            },
            axisLabel: {
              color: "#e6f2f3",
              rotate: 45,
              fontWeight: "normal",
              formatter: function (value) {
                return value.length > 8 ? value.slice(0, 8) + "..." : value;
              },
            },
          },
        ],
        yAxis: [
          {
            show: true,
            name: "出勤率：%",
            type: "value",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
              align: "left",
            },
            max: 100,
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                width: 1,
                type: "solid",
              },
            },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: "出勤率",
            type: "bar",
            barWidth: 10,
            barMaxWidth: 18,
            data: [],
          },
        ],
      },

      
      mapLevel: 0,
      popupShow: false,
      popupTitle: "",
      popupSelect: 0,
      avgAttendanceMax:100,
      warningTable:[],
      search_keys:[],
      activeIndex:0
    };
  },
  watch: {
    orgLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.getData();
      }
    },
    districtLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
        this.getData();
      }
    },
    districtScale: function (newValue) {
      if (newValue) {
        this.mapLevel = newValue;
      }
    },
    orgType: function (newValue, oldValue) {
      if (newValue != oldValue) {
        if (newValue == 2) {
          this.mode2 = 2;
        } else {
          this.mode2 = 1;
        }
      }
    }
  },
  mounted() {
    // const self = this
    const token = this.$route.query.token;
    window.token = token;

    if (this.orgType == 2) {
      this.mode2 = 2;
    }

    this.getData();
  },
  methods: {
    
      managerAttendanceRateClick(params){
        if(this.mapLevel!==2){
          this.popupShow=true;
          this.activeIndex=params.dataIndex;
          this.popupTitle = params.name + "近3日项目管理人员出勤率";
          // this.popupSelect = this.mode+1
          this.getAttendance(0)
        }
      },
      getAttendance(popupSelect) {
        this.popupSelect = popupSelect;
        this.getPopData();
      },
      getPopData() {
        nation.getManagerAttendanceRateDetailCollect(this.popupSelect, 1, 100000, this.mapLevel==0&&this.orgType == 1?this.search_keys[this.activeIndex]:'',this.mapLevel==1||this.orgType !== 1?this.search_keys[this.activeIndex]:'').then((d) => {
          // console.log(d);
          this.warningTable = d.map((item) => {
            return { value: item.count, name: item.project_name };
          });
          // this.avgAttendanceMax = Math.max.apply(
          //   null,
          //   this.warningTable.map((item) => item.value)
          // );
        });
      },
    getData() {
      this.getEcharts1();
      this.getEcharts2();
    },
    getEcharts1() {
      project
        .getDashboardManagerAttendanceRateCollect(this.mode)
        .then((d) => {
          this.search_keys=d.search_keys;
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option1.xAxis[0].data = d.xAxis_data;
          // this.option1.series = [chart.series[0]];
          this.option1.series[0].data = d.series_data;
          if (this.districtScale == 2) {
            this.option1.grid.left = '15%';
          } else {
            this.option1.grid.left = '8%';
          }
          this.managerAttendanceRate = applyUpdate(chart, this.option1);
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    getEcharts2() {
      project
        .getDashboardManagerHeadSinkAttendanceCollect(this.mode2)
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
          this.option2.xAxis[0].data = d.xAxis_data;
          // this.option1.series = [chart.series[0]];
          this.option2.series[0].data = d.series_data;
          this.managerHeadSinkAttendance = applyUpdate(chart, this.option2);
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    swithAttendance(mode) {
      this.mode = mode;
      this.getEcharts1();
    },
    swithAttendance2(mode) {
      this.mode2 = mode;
      this.getEcharts2();
    },
  },
};
</script>
<style scoped>
.linebar {
  width: 100%;
}
.line-bar {
  display: flex;
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #57c7b6;
  border-radius: 12px;
}
</style>
<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
