/* eslint-disable */
import * as THREE from 'three';
window.THREE = THREE;
//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { loadModel as loadFbxModel } from './fbx_loader';
import { loadModel as loadGltfModel } from './gltf_loader';
import { loadModel as loadObjModel } from './obj_loader';
import { pickHex, hex2rgb, calcRotationAngle } from './utils.js';
import { WEBGL } from './threejs/WebGL.js';
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
require('three/examples/js/lines/LineMaterial.js');
require('three/examples/js/lines/LineSegmentsGeometry.js');
require('three/examples/js/lines/LineGeometry.js');
require('three/examples/js/lines/LineSegments2.js');
require('three/examples/js/lines/Line2.js');
const isPointInPolygon = require('robust-point-in-polygon');

THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

const random_list = [];

let mouse = new THREE.Vector2();
let raycaster = new THREE.Raycaster();
raycaster.firstHitOnly = true;

let clickListener, mouseMoveListener, mouseDownListener;

/**
 * {userID: {modelID, name, position, floor, color}}
 * */
THREE.Object3D.prototype.clear = function () {
  const children = this.children
  for (let i = children.length - 1; i >= 0; i--) {
    const child = children[i]
    child.clear()
    this.removeChild(child)
  }
}

function getRobotSizeMultiplier(zoom) {
  if (zoom >= 2.8) {
    return 1;
  } else if (zoom <= 2) {
    return 1.4;
  } else {
    return 2.8 / zoom;
  }
}

function getMaxCommonPrefix(arr1, arr2) {
  const commLen = Math.min(arr1.length, arr2.length);
  let last = 0;
  while (last < commLen) {
    if (arr1[last] == arr2[last]) {
      last++;
    } else {
      break;
    }
  }
  return arr1.slice(0, last);
}

// pt should be a THREE.Vector3 instance
function getAbsPos(node, pt) {
  return pt.applyMatrix4(node.matrixWorld);
}

function getGroupFromVertexIndex(groups, idx) {
  if (groups.length) {
    const mid = Math.round((groups.length - 1) / 2);

    if (idx < groups[mid].start) {
      return getGroupFromVertexIndex(groups.slice(0, mid), idx);
    } else if (idx < (groups[mid].start + groups[mid].count)) {
      return groups[mid];
    } else {
      return getGroupFromVertexIndex(groups.slice(mid + 1), idx);
    }
  } else {
    return null;
  }
}

function buildMeshMap(node, meshMap) {
  for (let i = 0; i < node.children.length; i++) {
    if (node.children[i].type == 'Mesh') {
      if (meshMap[node.children[i].name]) {
        if (Array.isArray(meshMap[node.children[i].name])) {
          meshMap[node.children[i].name].push(node.children[i]);
        } else {
          meshMap[node.children[i].name] = [meshMap[node.children[i].name], node.children[i]];
        }
      } else {
        meshMap[node.children[i].name] = node.children[i];
      }
    } else if (node.children[i].type == 'Group') {
      buildMeshMap(node.children[i], meshMap);
    }
  }
}

function buildBoundsTrees(node) {
  for (let i = 0; i < node.children.length; i++) {
    if (node.children[i].type == 'Mesh') {
      node.children[i].geometry.computeBoundsTree();
    } else if (node.children[i].type == 'Group') {
      buildBoundsTrees(node.children[i]);
    }
  }
}

export function traverseNodes(node, callback) {
  for (let i = 0; i < node.children.length; i++) {
    const cont = callback && callback(node.children[i]);
    if (cont && node.children[i].type == 'Group') {
      traverseNodes(node.children[i], callback);
    }
  }
}

export function setOpacity(node, opacity) {
  for (let i = 0; i < node.children.length; i++) {
    if (node.children[i].type == 'Mesh') {
      let materials = node.children[i].material;
      if (!Array.isArray(materials)) {
        materials = [materials];
      }
      for (let j = 0; j < materials.length; j++) {
        materials[j].transparent = opacity < 1;
        materials[j].opacity = opacity;
      }
    } else if (node.children[i].type == 'Group') {
      setOpacity(node.children[i], opacity);
    }
  }
}

// 支持的options:
// 1. options.rotZ: 模型围绕Z轴顺时针旋转的角度
// 2. options.scale: 模型缩放比例
// 3. options.moveX/moveY/moveZ: 模型在三个轴上的移动距离
export function loadScene(mapid, threeLayer, scene, sceneData, sceneConfig) {
  if (mouseMoveListener) {
    document.removeEventListener('mousemove', mouseMoveListener);
  }
  if (mouseDownListener) {
    document.removeEventListener('mousedown', mouseDownListener);
  }
  if (clickListener) {
    document.removeEventListener('click', clickListener);
  }

  if (WEBGL.isWebGL2Available() === false) {
    document.body.appendChild(WEBGL.getWebGL2ErrorMessage())
  }

  function convertUserColors(workerPosition) {
    for (const user of workerPosition) {
      if (typeof(user.color) == 'string') {
        const color = hex2rgb(sceneData.ta.getWorkerTypeColor(user.group));
        user.color = [color[0] / 255, color[1] / 255, color[2] / 255];
      }
    }
  }

  const meshMap = {};

  let isGlobalReveal = false;
  const sceneRotation = sceneConfig.sceneRotation;
  const buildingTopCenters = sceneConfig.buildingTopCenters;
  const buildingBoundaries = sceneConfig.buildingBoundaries;
  const buildingBlurBoundaries = sceneConfig.buildingBlurBoundaries || sceneConfig.buildingBoundaries;
  const buildingFinishedHeights = sceneConfig.buildingFinishedHeights;
  const traceViews = sceneConfig.traceViews;
  const buildingFloorHeights = sceneConfig.buildingFloorHeights;
  const getUserZValue = sceneConfig.getUserZValue;
  const getZValueFromHeight = sceneConfig.getZValueFromHeight;
  const getFloorFromZValue = sceneConfig.getFloorFromZValue;
  const workerSizeMultiplier = sceneConfig.workerSizeMultiplier || 1;
  const traceSpeedMultiplier = sceneConfig.traceSpeedMultiplier || 1;
  const lastBldgSwitchIndicesFile = sceneConfig.lastBldgSwitchIndicesFile;
  const bldgGroups = sceneConfig.bldgGroups;
  let lastBldgSwitchIndices;

  const buildingFinishedFloorZValues = {}
  for (const key in buildingBoundaries) {
    const floorHeightsEntry = buildingFloorHeights.find(item => item.build_name == key);
    if (floorHeightsEntry) {
      let floorIdx = floorHeightsEntry.now_floor_i;
      if (floorIdx + 1 < floorHeightsEntry.floor_info.length) {
        floorIdx++;
      }
      buildingFinishedFloorZValues[key] = getZValueFromHeight(floorHeightsEntry.floor_info[floorIdx].elevation_height);
    } else if (buildingFinishedHeights && buildingFinishedHeights[key] != null) {
      buildingFinishedFloorZValues[key] = buildingFinishedHeights[key];
    } else {
      buildingFinishedFloorZValues[key] = -100000000000;
    }
  }

  // Returns a hierarchy of regions, currently two levels max:
  // 1. building name
  // 2. region in building: currently either 'finished' or 'unfinished'
  //
  // Retruns [] if the position doesn't belong to any region.
  const bldgKeys = Object.keys(buildingBoundaries);
  let lastBldgKey = bldgKeys[0];
  let lastBldgSwitchPointer = 0;
  let lastPointInBldg = false;
  let lastPointIdx = 0;
  window.lastBldgSwitches = [];
  window.totalCalls = 0;
  window.totalLoops = 0;
  function getRegionFromPos(x, y, z, pointIdx) {
    window.totalCalls++;
    if (z != null) {
      if (sceneConfig.groundHeightRanges) {
        for (const range of sceneConfig.groundHeightRanges) {
          if (z >= range[0] && z <= range[1]) {
            return ['ground'];
          }
        }
      }

      if (sceneConfig.revealUnderground != null && z <= sceneConfig.revealUnderground) {
        return ['underground']
      }
    }

    let resKey;
    if (lastBldgSwitchIndices && pointIdx != null) {
      if (lastBldgSwitchPointer >= lastBldgSwitchIndices.length || pointIdx != lastBldgSwitchIndices[lastBldgSwitchPointer] + lastPointIdx) {
        if (lastPointInBldg) {
          resKey = lastBldgKey;
        } else {
          return [];
        }
      } else {
        lastPointIdx = pointIdx;
        lastBldgSwitchPointer++;
      }
    }

    if (resKey == null && bldgKeys.length > 0) {
      for (let i = -1; i < bldgKeys.length; i++) {
        let key;
        if (i == -1) {
          key = lastBldgKey;
        } else {
          key = bldgKeys[i];
          if (key == lastBldgKey) {
            continue
          }
        }
        window.totalLoops++;
        const coords = (pointIdx != null ? buildingBlurBoundaries : buildingBoundaries)[key];
        if (isPointInPolygon(coords, [x, y]) <= 0) {
          if (!lastBldgSwitchIndices && pointIdx != null) {
            if (i >= 0 || !lastPointInBldg) {
              window.lastBldgSwitches.push(pointIdx - lastPointIdx);
              lastPointIdx = pointIdx;
            }
          }

          resKey = key;
          lastPointInBldg = true;
          if (i >= 0) {
            lastBldgKey = key;
          }

          break;
        }
      }
    }

    if (resKey != null) {
      if (z == null) {
        return [resKey];
      } else {
        if (z <= buildingFinishedFloorZValues[resKey]) {
          return [resKey, 'finished']; //, getFloorFromZValue(resKey, z + (sceneConfig.getHeightErrorMargin ? sceneConfig.getHeightErrorMargin(resKey, z) : 0))];
        } else {
          return [resKey, 'unfinished']; //, getFloorFromZValue(resKey, z + (sceneConfig.getHeightErrorMargin ? sceneConfig.getHeightErrorMargin(resKey, z) : 0))];
        }
      }
    }

    if (pointIdx != null && lastPointInBldg) {
      if (!lastBldgSwitchIndices) {
        window.lastBldgSwitches.push(pointIdx - lastPointIdx);
        lastPointIdx = pointIdx;
      }
      lastPointInBldg = false;
    }
    return [];
  }

  // `coords` should be an array of 9 coordinates
  function getRegionFromTriangle(mesh, coords, triangleIdx) {
    let res = [];

    for (let j = 0; j < 3; j++) {
      const pos = new THREE.Vector3(coords[j * 3], coords[j * 3 + 1], coords[j * 3 + 2]);
      const absPos = getAbsPos(mesh, pos);

      const region = getRegionFromPos(absPos.x, absPos.y, absPos.z, triangleIdx != null ? triangleIdx * 3 + j : null);
      if (j == 0) {
        res = region;
      } else {
        if (res.length >= 2 && region.length >= 2 && res[0] == region[0] && res[2] != region[2]) {
          const t = isFloorEqualOrBelow(res[0], res[2], region[2]);
          if (t) {
            res = region;
          }
        } else {
          res = getMaxCommonPrefix(res, region);
        }
      }

      if (res.length == 0) {
        break;
      }
    }

    return res;
  }

  const geometryGroupBuildingMap = {};
  function getRegionFromGeometryGroup(mesh, k) {
    const key = mesh.uuid + '/' + k;
    if (geometryGroupBuildingMap[key] == null) {
      const geometry = mesh.geometry;
      let res = [];
      for (let j = 0; j < geometry.groups[k].count; j += 3) {
        const coords = geometry.attributes.position.array.slice(geometry.groups[k].start * 3 + j * 3, geometry.groups[k].start * 3 + j * 3 + 9);
        const region = getRegionFromTriangle(mesh, coords);

        if (j == 0) {
          res = region;
        } else {
          res = getMaxCommonPrefix(res, region);
        }

        if (res.length == 0) {
          break;
        }
      }

      geometryGroupBuildingMap[key] = res;
      return res;
    } else {
      return geometryGroupBuildingMap[key];
    }
  }

  let totalTriangleIdx;
  function regenerateGroups(mesh) {
    if (mesh.geometry.index) {
      mesh.geometry = mesh.geometry.toNonIndexed();
    }

    const groups = mesh.geometry.groups;
    mesh.geometry.clearGroups();

    const regionTriangles = {};
    const regionIndices = {};
    let regionCount = 0;
    const position = mesh.geometry.getAttribute('position');
    const flags = new Uint16Array(position.count / 3);
    for (let i = 0; i < position.count; i += 3) {
      const coords = position.array.slice(i * 3, i * 3 + 9);
      let region = getRegionFromTriangle(mesh, coords, totalTriangleIdx++);

      const grp = getGroupFromVertexIndex(groups, i);
      region.push('' + (grp ? grp.materialIndex : 0));
      region = region.join('\n');

      if (!regionTriangles[region]) {
        regionTriangles[region] = 0;
        regionIndices[region] = regionCount;
        regionCount++;
      }
      regionTriangles[region]++;
      flags[i / 3] = regionIndices[region];
    }

    const triangleCounts = new Uint32Array(regionCount + 1);
    const materialIndices = new Uint32Array(regionCount);
    triangleCounts[0] = 0;
    for (const region in regionTriangles) {
      const regionArr = region.split('\n');

      triangleCounts[regionIndices[region] + 1] = regionTriangles[region];
      materialIndices[regionIndices[region]] = parseInt(regionArr[regionArr.length - 1]);

      const cacheKey = mesh.uuid + '/' + regionIndices[region];
      geometryGroupBuildingMap[cacheKey] = regionArr.slice(0, -1);
    }
    for (let i = 0; i < regionCount; i++) {
      mesh.geometry.addGroup(triangleCounts[i] * 3, triangleCounts[i + 1] * 3, materialIndices[i]);
      triangleCounts[i + 1] += triangleCounts[i];
    }

    const newPositionArr = new Float32Array(position.count * 3);
    const normal = mesh.geometry.getAttribute('normal');
    const uv = mesh.geometry.getAttribute('uv');
    let newNormalArr, newUvArr;
    if (normal) {
      newNormalArr = new Float32Array(normal.count * 3);
    }
    if (uv) {
      newUvArr = new Float32Array(uv.count * 2);
    }
    for (let i = 0; i < flags.length; i++) {
      for (let j = 0; j < 9; j++) {
        newPositionArr[triangleCounts[flags[i]] * 9 + j] = position.array[i * 9 + j];
        if (newNormalArr) {
          newNormalArr[triangleCounts[flags[i]] * 9 + j] = normal.array[i * 9 + j];
        }
        if (newUvArr && j < 6) {
          newUvArr[triangleCounts[flags[i]] * 6 + j] = uv.array[i * 6 + j];
        }
      }
      triangleCounts[flags[i]]++;
    }

    position.copyArray(newPositionArr);
    position.needsUpdate = true;
    if (newNormalArr) {
      normal.copyArray(newNormalArr);
      normal.needsUpdate = true;
    }
    if (newUvArr) {
      uv.copyArray(newUvArr);
      uv.needsUpdate = true;
    }
  }

  // Returns if `floor` is equal to or below floor `target` in the specified building.
  function isFloorEqualOrBelow(bldgName, floor, target) {
    if (floor == target) {
      return true;
    } else {
      for (const bldg of buildingFloorHeights) {
        if (bldg.build_name == bldgName) {
          let floorFound = false;
          for (let i = 0; i < bldg.floor_info.length; i++) {
            if (bldg.floor_info[i].floor_name == floor) {
              floorFound = true;
            } else if (bldg.floor_info[i].floor_name == target) {
              return floorFound;
            }
          }
        }
      }
    }
  }

  function revealBuilding(node, bldgName, revealIdx) {
    if (bldgKeys.length == 0) return;
    if (!buildingBoundaries[bldgName] && bldgName != 'ground' && bldgName != 'underground') return;

    revealIdx = revealIdx || 0;

    let bldgGroup = [];
    if (bldgGroups) {
      for (const group of bldgGroups) {
        const idx = group.indexOf(bldgName);
        if (idx >= 0) {
          bldgGroup = group;
          break;
        }
      }
    }

    for (let i = 0; i < node.children.length; i++) {
      if (node.children[i].type == 'Mesh') {
        const geometry = node.children[i].geometry;
        for (let k = 0; k < geometry.groups.length; k++) {
          const region = getRegionFromGeometryGroup(node.children[i], k);
          if (region[0] == bldgName || bldgGroup.indexOf(region[0]) >= 0) {
            const mNum = node.children[i].material.length / 3;
            let chosenIdx = revealIdx;
            if (bldgName != 'ground' && bldgName != 'underground' && revealIdx != 1 && region.length > 1 && region[1] == 'unfinished') {
              chosenIdx = 1;
            }

            if (window.selectedBldg && window.selectedFloor && bldgName != 'ground' && bldgName != 'underground') {
              if (window.selectedBldg == bldgName && region.length > 2 && isFloorEqualOrBelow(bldgName, region[2], window.selectedFloor)) {
                geometry.groups[k].materialIndex = geometry.groups[k].materialIndex % mNum + chosenIdx * mNum;
              } else {
                geometry.groups[k].materialIndex = geometry.groups[k].materialIndex % mNum + 2 * mNum;
              }
            } else {
              geometry.groups[k].materialIndex = geometry.groups[k].materialIndex % mNum + chosenIdx * mNum;
            }
          }
        }
      } else if (node.children[i].type == 'Group') {
        revealBuilding(node.children[i], bldgName, revealIdx);
      }
    }
  }

  function revealAll(node, isReveal) {
    for (let i = 0; i < node.children.length; i++) {
      if (node.children[i].type == 'Mesh') {
        const geometry = node.children[i].geometry;
        for (let k = 0; k < geometry.groups.length; k++) {
          const mNum = node.children[i].material.length / 3;
          geometry.groups[k].materialIndex = geometry.groups[k].materialIndex % mNum + (isReveal ? mNum : 0);
        }
      } else if (node.children[i].type == 'Group') {
        revealAll(node.children[i]);
      }
    }
  }

  function addTransparentMaterials(node) {
    const meshes = node.children.filter(child => child.type == 'Mesh');
    meshes.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
    for (let i = 0; i < meshes.length; i++) {
      let materials = meshes[i].material;

      if (!Array.isArray(materials)) {
        if (bldgKeys.length > 0) {
          //materials.depthTest = false;
          const newMaterial = materials.clone();
          newMaterial.transparent = true;
          newMaterial.opacity = Math.min(newMaterial.opacity, sceneConfig.revealOpacity || 0.35);
          meshes[i].material = [materials, newMaterial];
          materials = meshes[i].material;
        } else {
          materials.transparent = true;
          materials.opacity = Math.min(materials.opacity, sceneConfig.revealOpacity || 0.35);
        }
      } else {
        const mNum = materials.length;
        if (bldgKeys.length > 0) {
          for (let j = 0; j < mNum; j++) {
            //materials[j].depthTest = false;
            const newMaterial = materials[j].clone();
            newMaterial.transparent = true;
            newMaterial.opacity = Math.min(newMaterial.opacity, sceneConfig.revealOpacity || 0.35);
            materials.push(newMaterial);
          }
        } else {
          for (let j = 0; j < mNum; j++) {
            materials[j].transparent = true;
            materials[j].opacity = Math.min(newMaterial.opacity, sceneConfig.revealOpacity || 0.35);
          }
        }
      }

      if (bldgKeys.length > 0) {
        const mNum = materials.length / 2;
        for (let j = 0; j < mNum; j++) {
          const newMaterial = materials[j].clone();
          newMaterial.transparent = true;
          newMaterial.opacity = Math.min(newMaterial.opacity, sceneConfig.deepRevealOpacity || 0);
          materials.push(newMaterial);
        }

        if (sceneConfig.fullOpacity) {
          materials[0].transparent = true;
          materials[0].opacity = sceneConfig.fullOpacity;
        }

        regenerateGroups(meshes[i]);

        // Display unfinished parts transparently
        const geometry = meshes[i].geometry;
        for (let k = 0; k < geometry.groups.length; k++) {
          const region = getRegionFromGeometryGroup(meshes[i], k);
          if (region.length >= 2 && region[1] == 'unfinished') {
            const mNum = meshes[i].material.length / 3;
            geometry.groups[k].materialIndex = geometry.groups[k].materialIndex % mNum + mNum;
          }
        }
      }
    }

    const groups = node.children.filter(child => (child.type == 'Group' || (child.type == 'Object3D' && child.children && child.children.length)));
    groups.sort((a, b) => {
      const ak = a.name + '-' + a.children.length;
      const bk = b.name + '-' + b.children.length;
      if (ak < bk) {
        return -1;
      } else if (ak > bk) {
        return 1;
      } else {
        return 0;
      }
    });
    for (let i = 0; i < groups.length; i++) {
      addTransparentMaterials(groups[i]);
    }
  }

  const highlightedMeshes = {};
  function highlightMeshes(nameOrIdxArr) {
    let meshes = [];
    let retVal = [];

    for (const nameOrIdx of nameOrIdxArr) {
      if (typeof(nameOrIdx) === 'number') {
        const meshList = Object.keys(meshMap);
        meshes.push(meshMap[meshList[nameOrIdx]]);
      } else {
        meshes.push(meshMap[nameOrIdx]);
      }
    }

    // First restore existing highlights
    for (const key of Object.keys(highlightedMeshes)) {
      const m = meshMap[key];
      const mNum = m.material.length / 3;
      for (let i = 0; i < mNum; i++) {
        m.material[i].color.r = highlightedMeshes[key][i][0];
        m.material[i].color.g = highlightedMeshes[key][i][1];
        m.material[i].color.b = highlightedMeshes[key][i][2];
      }
      delete highlightedMeshes[key];
    }

    for (const mesh of meshes) {
      const mNum = mesh.material.length / 3;
      highlightedMeshes[mesh.name] = [];
      for (let i = 0; i < mNum; i++) {
          highlightedMeshes[mesh.name].push([
            mesh.material[i].color.r,
            mesh.material[i].color.g,
            mesh.material[i].color.b
          ]);
          mesh.material[i].color.r = 1;
          mesh.material[i].color.g = 0;
          mesh.material[i].color.b = 0;
      }

      retVal.push(mesh.name);
    }

    return retVal;
  }

  const hiddenMeshes = {};
  function hideMeshes(nameOrIdxArr) {
    let meshes = [];
    let retVal = [];

    for (const nameOrIdx of nameOrIdxArr) {
      if (typeof(nameOrIdx) === 'number') {
        const meshList = Object.keys(meshMap);
        meshes.push(meshMap[meshList[nameOrIdx]]);
      } else {
        meshes.push(meshMap[nameOrIdx]);
      }
    }

    // First restore existing hides
    for (const key of Object.keys(hiddenMeshes)) {
      const m = meshMap[key];
      const mNum = m.material.length;
      for (let i = 0; i < mNum; i++) {
        m.material[i].transparent = false;
        m.material[i].opacity = 1;
      }
      delete hiddenMeshes[key];
    }

    for (const mesh of meshes) {
      const mNum = mesh.material.length;
      hiddenMeshes[mesh.name] = 1;
      for (let i = 0; i < mNum; i++) {
          mesh.material[i].transparent = true;
          mesh.material[i].opacity = 0;
      }

      retVal.push(mesh.name);
    }

    return retVal;
  }

  /*
  var aspectRatio = 1;
  var cameraDefaults = {
      posCamera: new THREE.Vector3( 0.0, 175.0, 500.0 ),
      posCameraTarget: new THREE.Vector3( 0, 0, 0 ),
      near: 0.1,
      far: 10000,
      fov: 45
  };
  */
  //var cameraTarget = this.cameraDefaults.posCameraTarget;
  //var camera = new THREE.PerspectiveCamera( cameraDefaults.fov, aspectRatio, cameraDefaults.near, cameraDefaults.far );
  //this.resetCamera();

  const ambientLight = new THREE.AmbientLight(0x404040);
  const directionalLight1 = new THREE.DirectionalLight(0xC0C090);
  const directionalLight2 = new THREE.DirectionalLight(0xC0C090);

  ambientLight.intensity = 2.3;
  directionalLight1.position.set(-100, -50, 100);
  directionalLight2.position.set(100, 50, -100);

  if (sceneConfig.directionalLight1Intensity) {
    directionalLight1.intensity = sceneConfig.directionalLight1Intensity;
  }

  scene.add(directionalLight1);
  scene.add(directionalLight2);
  scene.add(ambientLight);

  //const helper = new THREE.GridHelper(1200, 60, 0xFF4444, 0x404040);
  //scene.add(helper);

  const UserCache = new Map();

  const modelGroup = new THREE.Group();
  scene.add(modelGroup);

  let fbxGroup;
  window.layer = threeLayer;
  window.scene = scene;

  window.meshMap = meshMap;
  window.getAbsPos = getAbsPos;
  window.geometryGroupBuildingMap = geometryGroupBuildingMap;
  window.getRegionFromTriangle = getRegionFromTriangle;
  window.getRegionFromGeometryGroup = getRegionFromGeometryGroup;
  window.highlightMeshes = highlightMeshes;
  window.hideMeshes = hideMeshes;

  let loadModel;
  if (sceneConfig.modelType == 'gltf') {
    loadModel = loadGltfModel;
  } else {
    loadModel = loadFbxModel;
  }

  function loadBimModel() {
    loadModel(scene, sceneConfig.modelPath, { ...sceneConfig.modelOptions, subtype: sceneConfig.modelSubType }, () => {
      fbxGroup = scene.children[scene.children.length - 1];
      fbxGroup.rotation.x += sceneRotation.x;
      fbxGroup.rotation.y += sceneRotation.y;
      fbxGroup.rotation.z += sceneRotation.z;

      setTimeout(() => {
        const EVENTS = [
            'mousemove',
            'click',
            'mousedown',
            'mouseup',
            'dblclick',
            'contextmenu',
            'touchstart',
            'touchmove',
            'touchend'
        ];
        EVENTS.forEach(event => {
            threeLayer.map.off(event, threeLayer._identifyBaseObjectEvents, threeLayer);
        });

        buildMeshMap(fbxGroup, meshMap);

        if (sceneConfig.modelPostProc) {
          sceneConfig.modelPostProc(scene, meshMap);
        }

        const start = Date.now() / 1;
        totalTriangleIdx = 0;
        addTransparentMaterials(fbxGroup);
        console.log('Loading:', Date.now() / 1 - start)

        buildBoundsTrees(fbxGroup);

        for (const key in buildingTopCenters) {
          exposedRevealBuilding(key, window.beaconMode ? 1 : null);
        }
        exposedRevealBuilding('underground', 1);

        sceneData.dataItem.isModelLoading = false;
        window.sceneChildrenCt = scene.children.length;
      }, 100);
    });
  }

  if (lastBldgSwitchIndicesFile) {
    // Load the indices file
    fetch(lastBldgSwitchIndicesFile, {headers: { 'Content-Type': 'application/json' }}).then(res => {
      if (res && res.ok) {
        res.json().then(d => {
          lastBldgSwitchIndices = d;
          loadBimModel();
        });
      } else {
        loadBimModel();
      }
    });
  } else {
    loadBimModel();
  }

  /**
   * @param users {[{name: string, color: [number, number, number], position: {x: number, y: number}, floor: string, id: string, floorID: string}]}
   * */
  const loadModels = (users) => {
    for (let user of users) {
      const cached = UserCache.get(user.id)
      if (cached) {
        cached.traversed = true
        const object = modelGroup.getObjectByName(user.id)
        //console.log('user object:', object)
        if (cached.floor !== user.floor) {
          console.log(`update ${user.name}(${user.id}) floor: ${cached.floor} => ${user.floor}`)
          object.position.z = user.position.z || getUserZValue(user);
          cached.floor = user.floor
        }
        if (cached.position.x !== user.position.x || cached.position.y !== user.position.y) {
          console.log(`update ${user.name}(${user.id}) x/y: ${cached.position.x}/${cached.position.y} => ${user.position.x}/${user.position.y}`);

          object.position.x = user.position.x;
          object.position.y = user.position.y;

          cached.position.x = user.position.x;
          cached.position.y = user.position.y;
        }
        if (cached.color[0] !== user.color[0] || cached.color[1] !== user.color[1] || cached.color[2] !== user.color[2]) {
          console.log(`update ${user.name}(${user.id}) color: ${cached.color} => ${user.color}`)
          object.traverse(function (child) {
            if (child.type == 'Mesh') child.material.color.setRGB(user.color[0], user.color[1], user.color[2])
          })
          cached.color = user.color
        }
        if (cached.status_id !== user.status_id) {
          console.log(`update ${user.name}(${user.id}) status_id: ${cached.status_id} => ${user.status_id}`);
          if (object.emissiveAnimationInterval) {
            clearInterval(object.emissiveAnimationInterval);
          }
          object.traverse(function (child) {
            if (child.type == 'Mesh') {
              child.material.emissiveIntensity = 0;
            }
          });
          if (user.status_id == 1 || user.status_id == 3) {
            sceneData.ta.show_alarm.name = user.name;
            sceneData.ta.show_alarm.location = user.build_name + ' ' + (user.area_name ? user.area_name : user.floor);
            sceneData.ta.show_alarm.mobile = user.mobile;
            if (user.status_id == 1) {
                sceneData.ta.show_alarm.title = "紧急呼救";
                sceneData.ta.show_alarm.img_show_alarm = true;
            } else {
                sceneData.ta.show_alarm.title = "坠落呼救";
                sceneData.ta.show_alarm.img_show_alarm = false;
            }
            sceneData.ta.show_alarm.is_show = true;

            object.traverse(function (child) {
              if (child.type == 'Mesh') {
                child.material.emissive.setRGB(1, 0, 0);
                child.material.emissiveIntensity = 1;
              }
            });
            object.emissiveAnimationInterval = setInterval(function() {
              object.traverse(function (child) {
                if (child.type == 'Mesh') {
                  if (child.material.emissiveIntensity) {
                    child.material.emissiveIntensity = 0;
                  } else {
                    child.material.emissiveIntensity = 1;
                  }
                }
              });
            }, 500);
          }
        } else {
          //console.log(`${user.name}(${user.id}) on ${user.floor} nothing changed`)
        }
      } else {
        // 新增
        console.log(`add new worker: ${user.name}(${user.id}) on floor ${user.floor}`)
        loadObjModel('threejs/robot.obj', '', function(object) {
          object.name = user.id;
          const multiplier = getRobotSizeMultiplier(threeLayer.map.getZoom()) * workerSizeMultiplier;
          object.scale.set(3.2 * multiplier, 2 * multiplier, 3.2 * multiplier);
          object.rotation.x = Math.PI / 2;
          //object.children[0].material.color.needsUpdate = true
          object.traverse(function (child) {
            if (child.type == 'Mesh') {
              if (child.material.color) {
                child.material.color.setRGB(user.color[0], user.color[1], user.color[2]);
              }
            }
          })
          if (user.status_id == 1 || user.status_id == 3) {
            sceneData.ta.show_alarm.name = user.name;
            sceneData.ta.show_alarm.location = user.build_name + ' ' + (user.area_name ? user.area_name : user.floor);
            sceneData.ta.show_alarm.mobile = user.mobile;
            if (user.status_id == 1) {
                sceneData.ta.show_alarm.title = "紧急呼救";
                sceneData.ta.show_alarm.img_show_alarm = true;
            } else {
                sceneData.ta.show_alarm.title = "坠落呼救";
                sceneData.ta.show_alarm.img_show_alarm = false;
            }
            sceneData.ta.show_alarm.is_show = true;

            object.traverse(function (child) {
              if (child.type == 'Mesh') {
                child.material.emissive.setRGB(1, 0, 0);
                child.material.emissiveIntensity = 1;
              }
            });
            object.emissiveAnimationInterval = setInterval(function() {
              object.traverse(function (child) {
                if (child.type == 'Mesh') {
                  if (child.material.emissiveIntensity) {
                    child.material.emissiveIntensity = 0;
                  } else {
                    child.material.emissiveIntensity = 1;
                  }
                }
              });
            }, 500);
          }
          object.userData = { ...user};
          object.position.x = user.position.x;
          object.position.y = user.position.y;
          object.position.z = user.position.z || getUserZValue(user);
          user.traversed = true;
          UserCache.set(user.id, user);
          modelGroup.add(object);
        });
      }
    }

    for (let cached of UserCache.values()) {
      if (cached.traversed) {
        cached.traversed = false
      } else {
        const obj = modelGroup.children.find(obj => obj.name === cached.id)
        modelGroup.remove(obj)
        if (obj.emissiveAnimationInterval) {
          clearInterval(obj.emissiveAnimationInterval);
        }
        console.log(`remove ${cached.name}(${cached.id}) from cache, find in modelGroup: ${obj !== undefined}`)
        UserCache.delete(cached.id)
      }
    }
  }

  function getCoordAtRatio(src, dest, ratio) {
    return [
      src[0] * (1 - ratio) + dest[0] * ratio,
      src[1] * (1 - ratio) + dest[1] * ratio,
      src[2] * (1 - ratio) + dest[2] * ratio,
    ];
  }

  function refineTrace(pts) {
    if (pts.length) {
      let currBldg = getRegionFromPos(pts[0]['3d_x'], pts[0]['3d_y'])[0];
      const res = [[pts[0]['3d_x'], pts[0]['3d_y'], Math.max(0, getUserZValue(pts[0])), 1, currBldg]];
      let i = 1;
      while (i < pts.length) {
        const endBldg = getRegionFromPos(pts[i]['3d_x'], pts[i]['3d_y'])[0];
        const dist = Math.sqrt(Math.pow(pts[i]['3d_x'] - pts[i - 1]['3d_x'], 2) +
                                Math.pow(pts[i]['3d_y'] - pts[i - 1]['3d_y'], 2) +
                                Math.pow(getUserZValue(pts[i]) - getUserZValue(pts[i - 1]), 2));
        for (let d = 50 * traceSpeedMultiplier; d < dist; d += 50 * traceSpeedMultiplier) {
          const pt = getCoordAtRatio([pts[i - 1]['3d_x'], pts[i - 1]['3d_y'], getUserZValue(pts[i - 1])],
                                    [pts[i]['3d_x'], pts[i]['3d_y'], getUserZValue(pts[i])],
                                    d / dist);
          pt[2] = Math.max(0, pt[2]);
          pt.push(0);
          const bldg = getRegionFromPos(pt[0], pt[1])[0];
          if (bldg == endBldg) {
            currBldg = endBldg;
          }
          pt.push(currBldg);
          res.push(pt);
        }
        res.push([pts[i]['3d_x'], pts[i]['3d_y'], Math.max(0, getUserZValue(pts[i])), 1, endBldg]);
        currBldg = endBldg;
        i++;
      }

      return res;
    } else {
      return [];
    }
  }

  const bldgRevealStatus = {};

  window.show3DPathClick = function(workerId, workerName) {
    //setOpacity(fbxGroup, 0.4);
    //setTimeout(() => setOpacity(fbxGroup, 1), 1000);
    if (sceneData.ta.tracePlaying3D) return;

    let lineGroup;
    let shouldStop;
    sceneData.ta.pathWorkerId3D = workerId;
    sceneData.ta.pathWorkerName3D = workerName;
    sceneData.ta.pathDate = undefined;
    sceneData.ta.tracePlaying3D = true;
    sceneData.ta.stopTracePlaying3D = function() {
      if (lineGroup) {
        scene.remove(lineGroup);
      }
      shouldStop = true;
    };

    window.projectAPI.getWorkerTrace3D(workerId)
      .then(pts => {
        pts = refineTrace(pts);

        window.hideMe(mapid);
        modelGroup.visible = false;
        for (const key in sceneData.dataItem.buildingData) {
          sceneData.dataItem.buildingData[key].infoWindow.hide();
        }

        lineGroup = new THREE.Group();
        scene.add(lineGroup);

        const user = UserCache.get(workerId);

        loadObjModel('threejs/robot.obj', '', function(object) {
          try {
            object.name = user.id;
            object.scale.set(4.8 * workerSizeMultiplier, 3 * workerSizeMultiplier, 4.8 * workerSizeMultiplier);
            object.rotation.x = Math.PI / 2;
            //object.children[0].material.color.needsUpdate = true
            object.traverse(function (child) {
              if (child.type == 'Mesh') {
                if (child.material.color) {
                  child.material.color.setRGB(user.color[0], user.color[1], user.color[2]);
                }
              }
            })
            if (user.status_id == 1 || user.status_id == 3) {
              object.traverse(function (child) {
                if (child.type == 'Mesh') {
                  child.material.emissive.setRGB(1, 0, 0);
                  child.material.emissiveIntensity = 1;
                }
              });
              object.emissiveAnimationInterval = setInterval(function() {
                object.traverse(function (child) {
                  if (child.type == 'Mesh') {
                    if (child.material.emissiveIntensity) {
                      child.material.emissiveIntensity = 0;
                    } else {
                      child.material.emissiveIntensity = 1;
                    }
                  }
                });
              }, 500);
            }

            if (pts.length > 0) {
              object.position.x = pts[0][0];
              object.position.y = pts[0][1];
              object.position.z = pts[0][2];
              lineGroup.add(object);
            }
          } catch(e) { console.error(e.msg || e.message); }

          let i = 1;
          const switchedBldgs = [];
          const bldgTransparentStatus = {};
          let originalView;
          let currView = null;
          const size = threeLayer.map.getSize();
          let finalIters = 36000;
          shouldStop = false;
          function callback() {
            if (i < pts.length && !shouldStop) {
              const bldg = pts[i][4];
              if (bldg) {
                const newView = traceViews[bldg] ? bldg : '_';
                if (currView != newView) {
                  if (currView != '_') {
                    for (const key of Object.keys(bldgTransparentStatus)) {
                      if (bldgTransparentStatus[key]) {
                        bldgTransparentStatus[key] = false;
                        revealBuilding(fbxGroup, key, bldgRevealStatus[key] ? 1 : 0);
                      }
                    }
                  }

                  currView = newView;
                  threeLayer.map.animateTo(traceViews[currView], {
                    duration: 1500,
                    easing: 'out'
                  });

                  for (const key of (traceViews[currView].needsTransparent || [])) {
                    bldgTransparentStatus[key] = true;
                    revealBuilding(fbxGroup, key, 2);
                  }
                }

                if (!bldgRevealStatus[bldg]) {
                  revealBuilding(fbxGroup, bldg, 1);
                  bldgRevealStatus[bldg] = true;
                  switchedBldgs.push(bldg);
                }
              } else {
                if (currView != '_') {
                  for (const key of Object.keys(bldgTransparentStatus)) {
                    if (bldgTransparentStatus[key]) {
                      bldgTransparentStatus[key] = false;
                      revealBuilding(fbxGroup, key, bldgRevealStatus[key] ? 1 : 0);
                    }
                  }

                  currView = '_';
                  threeLayer.map.animateTo(traceViews[currView], {
                    duration: 1500,
                    easing: 'out'
                  });
                }
              }

              const points = [
                pts[i-1][0], pts[i-1][1], pts[i-1][2],
                pts[i][0], pts[i][1], pts[i][2]
              ];
              const geometry = new THREE.LineGeometry().setPositions(points);

              const ratio = i / (pts.length - 1);
              let color;
              if (ratio <= 0.25) {
                  color = pickHex('#46B173', '#9DD622', ratio / 0.25);
              } else if (ratio <= 0.5) {
                  color = pickHex('#9DD622', '#E4CF1B', (ratio - 0.25) / 0.25);
              } else if (ratio <= 0.75) {
                  color = pickHex('#E4CF1B', '#E39E1A', (ratio - 0.5) / 0.25);
              } else {
                  color = pickHex('#E39E1A', '#F35507', (ratio - 0.75) / 0.25);
              }

              const material = new THREE.LineMaterial({ color: parseInt('0x' + color.slice(1)), linewidth: 5 });

              material.resolution.set(size.width, size.height);
              const line = new THREE.Line2(geometry, material);

              object.rotation.y = (calcRotationAngle([pts[i-1][0], pts[i-1][1]], [pts[i][0], pts[i][1]]) + 180) / 180 * Math.PI || 0;
              object.position.x = pts[i][0];
              object.position.y = pts[i][1];
              object.position.z = pts[i][2];
              lineGroup.add(line);
              i++;
              if (sceneData.ta.tracePlaying3D) {
                setTimeout(callback, 100);
              }
            } else {
              if (shouldStop) {
                finalIters = 0;
              }

              if (finalIters > 0) {
                finalIters--;
                if (sceneData.ta.tracePlaying3D) {
                  setTimeout(callback, 100);
                }
              } else {
                if (lineGroup) {
                  scene.remove(lineGroup);
                }
                while (scene.children.length > window.sceneChildrenCt) {
                  scene.remove(scene.children[scene.children.length - 1]);
                }

                threeLayer.map.animateTo(originalView, {
                  duration: 1500,
                  easing: 'out'
                });
                modelGroup.visible = true;
                for (const key in sceneData.dataItem.buildingData) {
                  window.updateInfoBoxContent(mapid, key);
                  sceneData.dataItem.buildingData[key].infoWindow.show();
                }
                for (const key of Object.keys(bldgTransparentStatus)) {
                  if (bldgTransparentStatus[key]) {
                    revealBuilding(fbxGroup, key, bldgRevealStatus[key] ? 1 : 0);
                  }
                }
                for (const bldg of switchedBldgs) {
                  revealBuilding(fbxGroup, bldg, 0);
                  bldgRevealStatus[bldg] = false;
                }

                sceneData.ta.tracePlaying3D = false;
              }
            }
          }

          if (sceneData.ta.tracePlaying3D) {
            originalView = threeLayer.map.getView();
            currView = '_';
            threeLayer.map.animateTo(Object.assign({}, originalView, traceViews['_']), {
              duration: 1500,
              easing: 'out'
            });

            setTimeout(callback, 100);
          }
        });
      })
      .catch(e => console.error(e.msg || e.message));
  }

  function fillTemplate(templateString, templateVars){
    return new Function("return `"+templateString +"`;").call(templateVars);
  }

  let html;
  if (sceneData.dataItem.hideWorkerPopupButtons) {
    let traceButton = '';
    if (window.withTrace) {
      traceButton = `<button style='background-color:#399A75;border-radius: 24px;border: none;color: #fff;padding:
        3px 10px;cursor: pointer;outline: none;margin-top: 7px;' id='showPath' onclick="show3DPathClick('\${this.id}', '\${this.name}')">轨迹</button>`;
    }
    html = `<div style="opacity:1;position:relative;" onclick="setClickInInfoWindow('${mapid}')">
      <div style="position:relative;background-color:#122040;border:1px solid #526eb2"><div style="margin:13px 0">
      <div style="text-align: center;">
      <div><a style="cursor:pointer" onclick="showWorkerInfo('${mapid}', '\${this.id}', \${this.ptype})">\${this.name}(\${this.hat_code})</a></div>
      <div>\${this.group}</div>
      <div>\${this.mobile}</div>
      ${traceButton}</div></div><div />
      </div><div class="leaflet-popup-tip-container"><div class="leaflet-popup-tip" style="background-color:#122040;border:1px solid #526eb2"></div></div>
      <a style="position:absolute;top:0;right:0;padding:4px 4px 0 0;border:none;text-align:center;width:18px;height:14px;font:16px/14px Tahoma,Verdana,sans-serif;color:#c3c3c3;text-decoration:none;font-weight:700" onclick="hideMe('${mapid}')">×</a></div>`;
  } else {
    html = `<div style="opacity:1;position:relative;" onclick="setClickInInfoWindow('${mapid}')">
      <div style="position:relative;background-color:#122040;border:1px solid #526eb2"><div style="margin:13px 0">
      <div style="text-align: center;">
      <div><a style="cursor:pointer" onclick="showWorkerInfo('${mapid}', '\${this.id}', \${this.ptype})">\${this.name}(\${this.hat_code})</a></div>
      <div>\${this.group}</div>
      <div>\${this.mobile}</div>
      <button style='background-color:#399A75;border-radius: 24px;border: none;color: #fff;padding:
      3px 10px;cursor: pointer;outline: none;margin-top: 7px;' id='showPath' onclick="show3DPathClick('\${this.id}', '\${this.name}')">轨迹</button></div></div><div />
      </div><div class="leaflet-popup-tip-container"><div class="leaflet-popup-tip" style="background-color:#122040;border:1px solid #526eb2"></div></div>
      <a style="position:absolute;top:0;right:0;padding:4px 4px 0 0;border:none;text-align:center;width:18px;height:14px;font:16px/14px Tahoma,Verdana,sans-serif;color:#c3c3c3;text-decoration:none;font-weight:700" onclick="hideMe('${mapid}')">×</a></div>`;
  }

  let lastMouseMove = 0;
  mouseMoveListener = function(event) {
    if (!sceneData.is3DSceneVisible()) {
      return;
    }

    const now = new Date() / 1;
    if (now - lastMouseMove < 60) {
      return;
    } else {
      lastMouseMove = now;

      const wrapperDiv = document.getElementById(mapid);
      const canvasElem = wrapperDiv.getElementsByTagName('canvas')[0];
      const boundingBox = canvasElem.getBoundingClientRect();
      const relX = event.clientX - boundingBox.left;
      const relY = event.clientY - boundingBox.top;

      const x = (relX / canvasElem.offsetWidth) * 2 - 1;
      const y = -(relY / canvasElem.offsetHeight) * 2 + 1;
      mouse.x = x;
      mouse.y = y;
      raycaster.setFromCamera(mouse, threeLayer.getCamera());

      let intersects = raycaster.intersectObjects(modelGroup.children, true);
      if (intersects.length) {
        threeLayer.map.setCursor('pointer');
      } else {
        threeLayer.map.resetCursor('default');
      }
    }
  };
  document.addEventListener('mousemove', mouseMoveListener);

  let downEvent;
  mouseDownListener = function(event) {
    downEvent = event;
  };
  document.addEventListener('mousedown', mouseDownListener);

  clickListener = function (event) {
    let clickInInfoWindow = false;
    if (sceneData.dataItem.clickInInfoWindow) {
      clickInInfoWindow = true;
      sceneData.dataItem.clickInInfoWindow = false;
    }

    if (!sceneData.is3DSceneVisible()) {
      return;
    }

    if (!downEvent) {
      return;
    } else if (Math.abs(event.clientX - downEvent.clientX) > 3 || Math.abs(event.clientY - downEvent.clientY) > 3) {
      return;
    }

    //event.preventDefault();

    const wrapperDiv = document.getElementById(mapid);
    const canvasElem = wrapperDiv.getElementsByTagName('canvas')[0];
    const boundingBox = canvasElem.getBoundingClientRect();
    const relX = event.clientX - boundingBox.left;
    const relY = event.clientY - boundingBox.top;

    if (window.beaconMode) {
      const x = (relX / canvasElem.offsetWidth) * 2 - 1;
      const y = -(relY / canvasElem.offsetHeight) * 2 + 1;
      mouse.x = x;
      mouse.y = y;
      raycaster.setFromCamera(mouse, threeLayer.getCamera());

      let modelObjects = fbxGroup.children;
      if (window.extraObjects) {
        modelObjects = modelObjects.concat(window.extraObjects);
      }
      const intersects = raycaster.intersectObjects(modelObjects, true);
      if (intersects.length) {
        const pt = intersects[0].point;
        random_list.push([pt.x, pt.y]);
        console.log(JSON.stringify(random_list));
        console.log('Height:', pt.z);
      } else {
        console.log('请在工地模型上点击以添加信标点位。')
      }
    } else {
      if (!clickInInfoWindow) {
        const x = (relX / canvasElem.offsetWidth) * 2 - 1;
        const y = -(relY / canvasElem.offsetHeight) * 2 + 1;
        mouse.x = x;
        mouse.y = y;
        raycaster.setFromCamera(mouse, threeLayer.getCamera());

        let intersects = raycaster.intersectObjects(modelGroup.children, true);
        //console.log(intersects);
        if (intersects.length) {
          const pt = intersects[0].point;
          const userData = intersects[0].object.parent.userData;
          console.log(userData);
          sceneData.showUserInfo(fillTemplate(html, userData), function() {
            const vector = pt.clone();
            vector.project(threeLayer.getCamera());
            return vector;
          });
        } else {
          intersects = raycaster.intersectObjects(fbxGroup.children, true);
          if (intersects.length) {
            const pt = intersects[0].point;
            const bldg = getRegionFromPos(pt.x, pt.y)[0];
            if (bldg) {
              const floor = getFloorFromZValue(bldg, pt.z);
              console.log(bldg, floor, pt.z);
              sceneData.showFloorInfo(bldg, floor, function() {
                const vector = pt.clone();
                vector.project(threeLayer.getCamera());
                return vector;
              });
            }
          }
        }
      }
    }
  };
  document.addEventListener('click', clickListener);

  const exposedRevealBuilding = (bldgName, revealIdx) => {
    if (isGlobalReveal) {
      exposedGlobalReveal(false);
      sceneData.setGlobalTransparencyChecked(false);
    }
    if (!buildingBoundaries[bldgName] && bldgName != 'ground' && bldgName != 'underground') return;
    if (revealIdx == null) {
      revealIdx = bldgRevealStatus[bldgName] ? 0 : 1;
    }
    revealBuilding(fbxGroup, bldgName, revealIdx);
    bldgRevealStatus[bldgName] = revealIdx;
  };

  const exposedGlobalReveal = (isReveal) => {
    if (isReveal != isGlobalReveal) {
      isGlobalReveal = isReveal;
      revealAll(fbxGroup, isReveal);
      if (!isReveal) {
        for (const bldgName in bldgRevealStatus) {
          exposedRevealBuilding(bldgName, bldgRevealStatus[bldgName]);
        }
      }
    }
  };

  return {
    updateWorkers: (workerPosition) => {
      if (!window.beaconMode) {
        convertUserColors(workerPosition);
        loadModels(workerPosition);
      }
    },
    revealBuilding: exposedRevealBuilding,
    switchGlobalTransparency: exposedGlobalReveal,
    getBuildingTopCenter: (bldgName) => {
      const pt = buildingTopCenters[bldgName];
      const vector = new THREE.Vector3(pt[0], pt[1], pt[2]);
      vector.project(threeLayer.getCamera());
      return vector;
    },
    mapZoomedTo: (zoom) => {
      const multiplier = getRobotSizeMultiplier(zoom) * workerSizeMultiplier;
      for (const object of modelGroup.children) {
        object.scale.set(3.2 * multiplier, 2 * multiplier, 3.2 * multiplier);
      }
    },
  }
}

export function setHeatmapData3D(/*threeLayer, data, heatmapAreas, max*/) {
  //console.log(threeLayer, data, max);
  /*
  const scene = threeLayer.getScene();

  var heatmap = h337.create({
     container: document.getElementById("heatmap-canvas"),
     width:256,
     height:256,
     blur:'.8',
     radius:10
  });

  var i = 0,max=10,data=[];
  while(i<2000){
    data.push({x:getRandom(1,256),y:getRandom(1,256),value:getRandom(1,6)});
    i++;
  }

  heatmap.setData({
      max:max,
      data: data
  });
  texture = new THREE.Texture(heatmap._renderer.canvas);
  geometry = new THREE.PlaneBufferGeometry( 50, 50,1000,1000 );
  geometry.rotateX(-Math.PI * 0.5 );
  material = new THREE.ShaderMaterial({
    uniforms: {
      heightMap: {value: texture},
        heightRatio: {value:5}
    },
    vertexShader: document.getElementById('vertexShader').textContent,
    fragmentShader: document.getElementById('fragmentShader').textContent,
    transparent: true,
  });
  mesh = new THREE.Mesh( geometry, material );
  scene.add( mesh );
  */
}
