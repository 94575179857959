<template>
  <div class="info-block" style="min-height:5rem">
    <div class="info-block-title">人均利润、对比</div>
    <div
      class="detail-select"
      style="width:100%;display:flex;justify-content:flex-end"
    >
      <CustomSelect
        :options="yearList"
        :value="selectedYear"
        @input="categorySelect"
        style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
      />
    </div>
    <div style="height:19rem;padding-top:1rem">
      <div
          style="display:flex;justify-content:space-around;margin-bottom:-0.8rem"
        >
      <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')"
          >
            人均利润
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')"
          >
            对比情况
          </div>
      </div>
      <div v-if="!passRateBarChart.series || !passRateBarChart.series[0].data.length">
        <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem;
         height: 12rem;margin-top: 0.5rem;">
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
      </div>
      <v-chart v-else :options="passRateBarChart" autoresize />
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import CustomSelect from '../../../../components/select'
  import {human} from '../../../../api_hr'
  import { colors } from '../../pieChartTemplates'

  export default {
    name: 'perCapitaProfitsChart',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.switchCompanyWorkersOption(this.companyWorkersOption);
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.switchCompanyWorkersOption(this.companyWorkersOption);
        }
      }
    },
    data () {
      return {
         yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear()-1,

        passRateBarChart: {},
        companyWorkersOption:"1"
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.switchCompanyWorkersOption(this.companyWorkersOption);
    },
    methods: {
      categorySelect(option) {
        this.selectedYear = option;
        this.switchCompanyWorkersOption(this.companyWorkersOption);
      },
      updatePerprofitChart() {
        human.getPerCapitaProfit(this.selectedYear)
        .then(res=>{
          var gszt = res[0]==null?null:res[0].whole;
          var xList = [];
          var seriesData = [];
          for (let index = 0; index < res.length; index++) {
            xList.push(res[index].name);
            seriesData.push(res[index].value);
          }
          const option = {
            color:colors,
            title:{
              subtext:"公司整体人均利润:"+gszt+"%",
              subtextStyle:{
                fontSize:14,
                color:'white'
              }
            },
            tooltip: {
              trigger: 'axis'
              },
              xAxis: {
                  name:'单位',
                  type: 'category',
                  data:xList,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisLabel:{
                    interval:0,
                    rotate:40,
                    color:'white'
                  }
              },
              yAxis: {
                  nameTextStyle:{
                    color:"white",
                  },
                  axisLine: {
                    show: false,
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      type: [5, 10],
                      color: '#14253f',
                      width: 2,
                    }
                  },
                  type: 'value',
                  axisLabel: {
                     color: 'white'
                  }
              },
              series: [
                  {
                      name: '人均利润',
                      type: 'line',
                      data: seriesData,
                      areaStyle:{}
                  }
                ]
          }
          this.passRateBarChart = option;
        })
      },
      updatePerprofitCompare(){
        human.getPerCapitaProfitCompare(this.selectedYear)
          .then(res=>{
            var xList = [];
            var seriesData = [];
            for (let i = 0; i < res.length; i++) {
              xList.push(res[i].name);
              seriesData.push(res[i].value);
              
            }
            const option = {
            color:colors,
            tooltip: {
              trigger: 'axis'
              },
              grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
              },
              xAxis: {
                  name:'单位',
                  type: 'category',
                  boundaryGap: false,
                  data:xList,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisLabel:{
                    interval:0,
                    rotate:40,
                    color:'white'
                  }
              },
              yAxis: {
                  nameTextStyle:{
                    color:"white",
                  },
                  axisLine: {
                    show: false,
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      type: [5, 10],
                      color: '#14253f',
                      width: 2,
                    }
                  },
                  type: 'value',
                  axisLabel: {
                     color: 'white'
                  }
              },
              series: [
                  {
                      name: '人均利润',
                      type: 'line',
                      data: seriesData,
                      areaStyle:{}
                  }
                ]
            }
            this.passRateBarChart = option;
          })
      },
      switchCompanyWorkersOption(choose){
        this.companyWorkersOption = choose;
        if(choose == "1"){
          this.updatePerprofitChart();
        }else{
          this.updatePerprofitCompare();
        }
      }
    },
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
