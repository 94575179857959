<template>
  <div id="left">
    <RiskProjectNumChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel"/>
    <MajorClientRiskProjChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel"/>
    <OutputRiskSlowProjChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel"/>
  </div>
</template>

<script>
  import RiskProjectNumChart from './riskProjectNumChart'
  import MajorClientRiskProjChart from './majorClientRiskProjChart'
  import OutputRiskSlowProjChart from './outputRiskSlowProjChart'

  export default {
    name: 'LawRespondLeftBar',
    components: {
      RiskProjectNumChart,
      MajorClientRiskProjChart,
      OutputRiskSlowProjChart
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
  }
</script>

<style lang="less">
</style>
