<template>
  <div class="info-block" style="min-height:5rem">
    <div class="info-block-title">项目团队组建完成情况</div>
    <div style="height:20rem;padding-top:3rem">
      <div
        v-if="projectTeamChart.series && [].concat.apply([], projectTeamChart.series.map(x => x.data)).length == 0"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart v-else :options="projectTeamChart" @click="projectTeamChartClick" autoresize />
    </div>
    <!-- 详情页面 -->
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">
            </div>
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600;">
                    <el-table style="background-color:#0E1328" height="400px" :data="projData" 
                    :row-style="tableRowStyle" :header-cell-style="tableHeaderColor">
                    <el-table-column type="index" :index="getIndex" label="序号">
                    </el-table-column>
                      <el-table-column
                            prop="branchName"
                            label="分公司">
                        </el-table-column>
                        <el-table-column
                            prop="projName"
                            label="项目名称">
                        </el-table-column>
                        <el-table-column
                            prop="interviewType"
                            label="面试方式">
                        </el-table-column>
                        <el-table-column
                            prop="isFormFinisth"
                            label="组建流程是否完成">
                        </el-table-column>
                        <el-table-column
                            prop="formFinishRate"
                            label="组建完成率">
                        </el-table-column>
                        <el-table-column
                            prop="status"
                            label="组建状态">
                        </el-table-column>
                    </el-table>
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :current-page.sync="currentPage"
                      :page-count="pageSize"
                      :total="totalCount"
                      @current-change="pageChange"
                      style="text-align:center;margin-top: 0.5rem;"
                    ></el-pagination>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { stackedBarChartTemplate } from "../../barChartTemplates";
  import { applyUpdate } from "../../../../utils";
import { projectMgr } from '../../../../api_projectMgr'
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'ProjectTeamFormationChart',
    components: {
      'v-chart': ECharts,
      wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateProjectTeamFormationChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateProjectTeamFormationChart();
        }
      }
    },
    data () {
      return {
        projectTeamChart: {},
        currentPage:1,
        pageSize:10,
        totalCount:0,
        branchName:"",
        projData:[],
        popupShow:false
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.updateProjectTeamFormationChart();
    },
    methods: {
      projectTeamChartClick(param){
        this.popupShow = true;
        this.branchName = param.name;
        projectMgr.queryProjFormInfoList(this.branchName,this.currentPage,this.pageSize).then((d=>{
          this.projData = d.data;
          this.totalCount = d.totalRecords;
        }))
      },
      pageChange(curPage){
        this.currentPage = curPage;
        projectMgr.queryProjFormInfoList(this.branchName,this.currentPage,this.pageSize).then((d=>{
          this.projData = d.data;
          this.totalCount = d.totalRecords;
        }))
      },
      getIndex(index){
        return (this.currentPage-1)*this.pageSize+index+1;
      },
      tableRowStyle({rowIndex}){
        if(rowIndex%2 === 0)
            return {backgroundColor:'#213a77',height: '0'}; 
        else   
            return {backgroundColor:'#102855',height: '0'}; 
      },
      tableHeaderColor(){
        return 'color:#4fb5da;background:#102855';
      },
      updateProjectTeamFormationChart() {
        projectMgr.queryProjFormCondition()
          .then(d => {
            const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
            const option = {
              color: ["#46A0FF", "#FFED21", "#FF8521", "rgba(0,0,0,0)"],
              legend: {
              },
              grid: {
                bottom: "5%"
              },
              xAxis: {
                show:true,
                type: "category",
                axisLabel: {
                  color: "#e6f2f3",
                    show:true,
                    lineStyle: {
                        color: "#rgb(77,83,141)",
                    },
                    rotate: 40,
                    fontWeight: "normal",
                },
                axisLine: { show: false, lineStyle: { color: '#36445f' } },
                splitLine: { show: false },
                axisTick: { show: false },
                data:[]
              },
              yAxis: [
                {
                  nameTextStyle: {
                    color: "#ccc",
                    align: "right",
                  },
                  type: "value",
                  axisLabel: {
                    color: "#ffffff"
                  },
                  axisLine: { show: false, lineStyle: { color: "#36445f" } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  boundaryGap: [0, '20%'],
                },
              ],
              series: [
                {
                  name: "已组建",
                  type: "bar",
                  data:[]
                },{
                  name: "未组建",
                  type: "bar",
                  data:[]
                },{
                  name: "已超期",
                  type: "bar",
                  data:[]
                }
              ],
            };

            for (let i = 0; i < d.length; i++) {
              option.xAxis.data.push(d[i].branchName);   
              option.series[0].data.push(d[i].finishedCount);         
              option.series[1].data.push(d[i].unfinishedCount);         
              option.series[2].data.push(d[i].expiredCount);         
            }
            this.projectTeamChart = applyUpdate(chart, option);
          })
          // eslint-disable-next-line
          .catch((e) => console.error(e.msg || e.message));
      },
    },
  }
</script>

<style lang="less">
</style>
