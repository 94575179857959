import Vue from 'vue'
const baseUrl = process.env.VUE_APP_API_BASE_URL
// const baseUrl = 'http://smarthat.comprehend.com.cn:10001/'

const handleRes = (res) => {
  if (res.statusCode < 200 || res.statusCode >= 300) {
    return res.response.text().then(e => Promise.reject(e))
  } else {
    return res.response.json().then(_d => {
      if (_d.code !== 0) {
        return Promise.reject(_d)
      } else {
        return _d.data
      }
    })
  }
}

/** @param url {string}
 * @return Promise*/
function get (url) {
  url = url.includes('?') ? url + '&' : url + '?'
  return fetch(baseUrl + url + 'token=' + this.token)
    .then(res => ({ response: res, statusCode: res.status }))
    .then(handleRes)
    .catch(e => Vue.prototype.$message.error(e.msg || e.message || e))
}

function post (url, body, type = 'json')  {
  let headers, _body

  if (type === 'json') {
    headers = { 'Content-Type': 'application/json' }
    _body = JSON.stringify(body)
  } else if (type === 'file') {
    _body = new FormData()
    _body.append('files', body)
  }
  return fetch(baseUrl + url + '?token=' + this.token, { method: 'POST', headers, body: _body })
    .then(res => ({ response: res, statusCode: res.status }))
    .then(handleRes)
    .catch(e => Vue.prototype.$message.error(e.msg || e.message || e))
}

const admin = {
  getManagerList: function () {
    return get.call(this, 'pc/manager/list?page=1&limit=1000000')
  },
  getWorkerList: function () {
    return get.call(this, 'pc/worker/list?page=1&limit=1000000')
  },
  getCompanyList: function () {
    return get.call(this, 'pc/company/list?page=1&limit=1000000')
  },

  getDanerousInfo: function (sourceID, dangerID) {
    return get.call(this, `pc/major/dangerous/list?source_id=${sourceID}&innerid=${dangerID}`);
  },

  getBuildingList: function () {
    return get.call(this, 'pc/build/list')
  },
  getFloorData: function( floorID ) {
    return get.call(this, `app/map/data/${floorID}`)
  },
  getAllBeacons: function({ buildingID, floorID }) {
    const url = `pc/dangerous/deploy/list?build_id=${buildingID}&map_id=${floorID}`;
    return get.call(this, url).then(a => a.map(d => {
      d.beaconType = d.iconid + '-' + d.status
      return d
    }))
  },

  deleteBeacon: function (id) {return  post.call(this, `pc/dangerous/deploy/del/${id}`)},

  getBeaconTypes: function() {
    return get.call(this, 'pc/dangerous/type/list')
  },

  getBeaconMeta: function(sourceID) {
    return get.call(this, `pc/major/dangerous/plan/list?source_id=${sourceID}`);
  },

  addNewBeacon: function (info) {return  post.call(this, `pc/dangerous/deploy/edit`, info)},
  token: '',

}

export { baseUrl, admin }
