<template>
  <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title">业主第三方评估</div>
    <div
      class="detail-select"
      style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
    >
    </div>
    <div style="height:20em;padding-top:1.5rem">
        <div style="display: flex;  font-size:15px;height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
          <div style="width:12rem">项目部</div>
          <div style="width:8rem">分数</div>
          <div style="width:8rem">排名</div>
        </div>
        <div style="height:16rem;overflow:auto;">
          <div
            v-for="(item) in expertList.slice(0, 9)"
            :key="item.worker_id"
            style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
          >
            <div style="width:12rem">
              {{item.xmmc}}
            </div>
            <div style="width:8rem;">{{item.jifen}}</div>
            <div style="width:8rem;">{{item.num}}</div>
          </div>
          <div
            v-if="!(expertList && expertList.length)"
            style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
          >
            暂无数据
          </div>
        </div>
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'

  export default {
    name: 'OwnerAssessChart',
    components: {
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerPassRateChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerPassRateChart();
        }
      }
    },
    data () {
      return {
        expertList:[]
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

    },
    methods: {
      updateSafetyDangerPassRateChart() {
        
      },
    },
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
