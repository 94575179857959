<template>
  <div id="left">
    <wrapper style="padding:0">

      <div
        class="info-block"
        style="min-height:5rem"
      >
        <div
          class="info-block-title"
          style="display: flex;justify-content: space-between;align-items:center;width: 85%"
        >
          <div>各等级隐患数</div>
          <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              style="min-width: 5rem;"
              :class="{ 'option-block-selected2': troubleMode == 1 }"
              @click="() => getTrouble(1)"
            >总</div>
            <div
              class="option-block2"
              style="min-width: 5rem;"
              :class="{ 'option-block-selected2': troubleMode == 2 }"
              @click="() => getTrouble(2)"
            >新增</div>
            <div
              class="option-block2"
              style="min-width: 5rem;"
              :class="{ 'option-block-selected2': troubleMode == 3 }"
              @click="() => getTrouble(3)"
            >未整改</div>
          </div>
        </div>
        <div style="height:17rem;padding-top:3rem;display: flex; align-items: center;">
          <div
            v-if="troubleTypePieChart.series && [].concat.apply([], troubleTypePieChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 24rem; height: 12rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          
          <div
            style="width:100%;height:100%"
            v-else
          >
          <v-chart
            style="width:100%;height:100%"
            :options="troubleTypePieChart"
            ref="troubleType"
            @click="troubleTypePieChartClick"
            @mouseover="troubleTypeMouseOver"
            @mouseout="troubleTypeMouseOut"
            autoresize
          />
            <div style="position:absolute;top:3rem;left:1rem;font-size:0.8rem">数量：个</div>

          </div>
        </div>
      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem;padding:0">
      <div
        class="info-block"
        style="min-height:5rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>管理人员未整改隐患数</div>
          <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              style="min-width: 5rem;"
              :class="{ 'option-block-selected2': pdUndoTroubleMode == 1 }"
              @click="() => getUndoTrouble(1)"
            >按人</div>
            <div
              class="option-block2"
              style="min-width: 5rem;"
              :class="{ 'option-block-selected2': pdUndoTroubleMode == 2 }"
              @click="() => getUndoTrouble(2)"
            >按部门</div>
          </div>
        </div>
        <div style="height:17rem;padding-top:3rem;display: flex; align-items: center;">
          <div
            v-if="troubleTypeNoPieChart.series && [].concat.apply([], troubleTypeNoPieChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 24rem; height: 12rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart
          v-else
            :options="troubleTypeNoPieChart"
            @click="troubleTypeNoPieClick"
            autoresize
          />
        </div>
      </div>
    </wrapper>
    <wrapper style="margin-top: 1rem;padding:0">

      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>各检查项隐患数</div>
        </div>
        <div style="height:19rem;padding-top:3rem;display: flex; align-items: center;">
          <div
            v-if="allCheckTroubleTypeChart.series && [].concat.apply([], allCheckTroubleTypeChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 24rem; height: 12rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>

          <div
            style="width:100%;height:100%"
            v-else
          >
            <v-chart
              style="width:100%;height:100%"
              ref="safetyCheckItem"
              :options="allCheckTroubleTypeChart"
              @click="allCheckTroubleTypeChartClick"
              @mouseover="workerTypeMouseOver"
              @mouseout="workerTypeMouseOut"
              autoresize
            />
            <div style="position:absolute;top:3rem;left:1rem;font-size:0.8rem">数量：个</div>

          </div>
        </div>
      </div>
    </wrapper>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="troubleTypeNoPieDetailShow"
      width="90rem"
      top="15rem"
    >
      <wrapper
        class="popup-bg"
        style="height:32rem;"
      >
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div
            class="stats-chart-block"
            style="height:16rem;width:86.25rem;"
          >
            <div
              class="stats-close-btn"
              @click="troubleTypeNoPieDetailShow = false"
            />
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{troubleTypeNoPieTitle}}未整改隐患
            </div>

            <div
              style="height:8rem;"
              class="machine_use"
            >
              <div class="machine_use_item machine_use_item_1">
                <div class="machine_num_1">{{undoTypeCount1}}</div>
                <div>逾期未整改</div>
              </div>
              <div class="machine_use_item machine_use_item_2">
                <div class="machine_num_2">{{undoTypeCount2}}</div>
                <div>未整改预警</div>
              </div>
              <div class="machine_use_item machine_use_item_3">
                <div class="machine_num_3">{{undoTypeCount3}}</div>
                <div>正常未整改</div>
              </div>
            </div>
            <div style="margin:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:8rem">序号</div>
                <div style="width:8rem;text-align:center">类型</div>
                <div style="width:8rem">上报人</div>
                <div style="width:10rem">隐患状态</div>
                <div style="width:12rem">要求完成时间</div>
                <div style="width:16rem">任务名称</div>
                <div style="width:6rem;text-align:center">隐患照片</div>
              </div>
              <div style="height:12rem;overflow:auto;">
                <div
                  v-for="(item, index) in safetyTroubleUndoDetail"
                  :key="item.trouble_id"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="display:flex; min-height: 3rem; align-items: center; justify-content: space-between; padding:0 1rem">
                    <div style="width:8rem;color:#4fb5da;">
                      {{index>9?index+1:'0'+(index+1)}}
                    </div>
                    <div style="width:8rem;text-align:center">{{item.type}}</div>
                    <div style="width:8rem">{{item.report_person_name}}</div>
                    <div style="width:10rem;">{{item.undo_type_name}}</div>
                    <div style="width:12rem;">{{item.complete_time}}</div>
                    <div style="width:16rem;">{{item.trouble_description}}</div>
                    <div style="width:6rem;text-align:center">
                      <div
                        v-if="item.img_list.length>0"
                        class="show-button"
                        @click="showPhotos(item, index)"
                      >查看</div>
                      <div
                        v-else
                        class="show-button2"
                      >查看</div>
                    </div>
                  </div>

                </div>
                <div
                  v-if="!(safetyTroubleUndoDetail && safetyTroubleUndoDetail.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无共建方情况
                </div>
              </div>
            </div>

          </div>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="photoShow"
      width="30rem"
      top="16rem"
    >
      <wrapper
        class="popup-bg"
        style="height:30rem;"
      >
        <!-- <div style="display:flex;margin-top:1rem;margin-left:1rem;"> -->
        <div
          class="stats-chart-block"
          style="height:28rem;width:28rem;"
        >
          <div
            class="stats-close-btn"
            @click="photoShow = false"
          />
          <el-carousel height="150px" arrow="never">
            <el-carousel-item
              v-for="item in photoList"
              :key="item"
            >
              <!-- <h3 class="small">{{ item }}</h3> -->
              <img
                :src="item"
                alt=""
                class="photo"
              />
            </el-carousel-item>
          </el-carousel>

        </div>
        <!-- </div> -->
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="allCheckTroubleTypePopShow"
      @close='dialogClose'
      width="90rem"
      top="15rem"
    >
      <wrapper
        class="popup-bg"
        style="height:38rem;"
      >
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div
            class="stats-chart-block"
            style="height:16rem;width:86.25rem;"
          >
            <div
              class="stats-close-btn"
              @click="allCheckTroubleTypePopShow = false"
            />

            <div style="height:19rem;border: 1px solid rgb(26, 50, 129); padding: 1rem ;">

              <div style="font-size:18px;font-weight:bold">{{allCheckTroubleTypeChartTitle}}隐患分布图
              </div>
              <div
                v-if="allCheckTroubleTypePopChart.series && [].concat.apply([], allCheckTroubleTypePopChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 24rem; height: 12rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <!--  -->
              <v-chart
                ref="safetyCheckPopItem"
                :options="allCheckTroubleTypePopChart"
                @click="allCheckTroubleTypePopChartClick"
                @mouseover="allCheckTroubleTypePopMouseOver"
                @mouseout="allCheckTroubleTypePopMouseOut"
                autoresize
              />
            </div>
            <div style=" overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">

              <div style="font-size:18px;font-weight:bold;margin:1rem 0;">
                {{allCheckTroubleTypePopTableTitle}}问题点分布详情
              </div>
              <div style="display: flex;  height: 1.5rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:8rem">序号</div>
                <div style="width:22rem">问题点描述</div>
                <div style="width:8rem">隐患数</div>
              </div>
              <div style="height:12rem;overflow:auto;">
                <div
                  v-for="(item, index) in allCheckTroubleTypePopTable"
                  :key="item.trouble_id"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="display:flex; height: 3rem; align-items: center; justify-content: space-between; padding:0 1rem">
                    <div style="width:8rem;color:#4fb5da;">
                      {{index>8?index+1:'0'+(index+1)}}
                    </div>
                    <div style="width:22rem">{{item.description}}</div>
                    <div style="width:8rem">{{item.count}}</div>
                  </div>

                </div>
                <div
                  v-if="!(allCheckTroubleTypePopTable && allCheckTroubleTypePopTable.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无{{allCheckTroubleTypePopTableTitle}}问题点分布详情
                </div>
              </div>
            </div>

          </div>
        </div>
      </wrapper>
    </el-dialog>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="allCompanyTroubleShow"
      width="90rem"
      top="15rem"
    >
      <wrapper
        class="popup-bg"
        style="height:42rem;"
      >
        <div style="display:flex;margin-top:1rem;margin-left:1rem;">
          <div
            class="stats-chart-block"
            style="height:36rem;width:86.25rem;"
          >
            <div
              class="stats-close-btn"
              @click="allCompanyTroubleShow = false"
            />
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{allCompanyTroubleTitle}}列表
            </div>

            <div style="margin:1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:5rem">序号</div>
                <div style="width:8rem;text-align:center">类型</div>
                <div style="width:5rem">上报人</div>
                <div style="width:6rem">隐患状态</div>
                <div style="width:12rem">要求完成时间</div>
                <div style="width:30rem">任务名称</div>
                <div style="width:6rem;text-align:center">隐患照片</div>
              </div>
              <div style="height:32rem;overflow:auto;">
                <div
                  v-for="(item, index) in allCompanyTroubleTable"
                  :key="item.trouble_id"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="display:flex; height: 3rem; align-items: center; justify-content: space-between; padding:0 1rem">
                    <div style="width:5rem;color:#4fb5da;">
                      {{securityStarPage>1?(securityStarPage-1)*10+index+1:(index+1>9?(index+1):'0'+(index+1))}}
                    </div>
                    <div style="width:8rem;text-align:center">{{item.type}}</div>
                    <div style="width:5rem">{{item.report_person_name}}</div>
                    <div style="width:6rem;">{{item.status_name}}</div>
                    <div style="width:12rem;">{{item.complete_time}}</div>
                    <div style="width:30rem;">{{item.trouble_description}}</div>
                    <div style="width:6rem;text-align:center">
                      <div
                        v-if="item.img_list.length>0"
                        class="show-button"
                        @click="showPhotos(item, index)"
                      >查看</div>
                      <div
                        v-else
                        class="show-button2"
                      >查看</div>
                    </div>
                  </div>

                </div>
                <div
                  v-if="!(allCompanyTroubleTable && allCompanyTroubleTable.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无共建方情况
                </div>
              </div>

              <el-pagination
                background
                layout="prev, pager, next"
                :current-page.sync="securityStarPage"
                :page-count="securityStarPages"
                @current-change="securityStarPageChange"
                style="text-align:center;margin-top: 0.5rem;"
              ></el-pagination>
            </div>

          </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import wrapper from "../../components/wrap";
import { project } from "../../api";
import { applyUpdate } from "../../utils";
import { attendancePieChartTemplate, colors } from "./pieChartTemplates";
import { stackedBarChartTemplate } from "./barChartTemplates";

export default {
  name: "SecurityCheckLeftBar",
  components: {
    "v-chart": ECharts,
    // 'CustomSelect': CustomSelect,
    wrapper,
  },
  props: {
    projectDetail: Object,
    showTrace: Function,
  },
  data() {
    return {
      projectId: null,

      troubleMode: 1,
      troubleTypePieChartHighlightIndex: 0,
      troubleTypePieChart: {},
      troubleTypePieChartTemplate: {
        color: colors,
        legend: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["0%", "60%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                // position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function () {
                  return ``;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },
      allCompanyTroubleShow: false,
      allCompanyTroubleTitle: "",
      allCompanyTroubleTable: [],
      allCompanyTroubleSearchKey: [],
      allCompanyTroubleActiveSearchKey: "",
      securityStarPage: 1,
      securityStarPages: 1,

      pdUndoTroubleMode: 1,
      troubleTypeNoPieChart: {},
      troubleTypeNoPieSearchKey: [],
      troubleTypeNoPieDetailShow: false,
      troubleTypeNoPieTitle: "",
      troubleTypeNoPieChartOption: {
        color: ["#20bbee", "#eab91e", "#d02c36"],
        grid: {
          left: "5%",
          right: "1%",
          top: "20%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "rect",
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 12,
          textStyle: {
            color: "#ccc",
            fontWeight: "bold",
            fontSize: "11",
          },
          // padding: [8, 0, 0, 0],
          top: "4%",
          right: "20%",
        },
        xAxis: {
          axisLabel: {
            color: "#e6f2f3",
            rotate: 40,
            fontWeight: "bold",
          },
          show: true,
          axisLine: { show: true, lineStyle: { color: "#36445f" } },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#36445f",
              width: 1,
              type: "solid",
            },
          },
          axisTick: { show: false },
          type: "category",
          data: [],
        },
        yAxis: [
          {
            type: "value",
            show: true,
            name: "数量:个",
            nameTextStyle: {
              padding: [0, 0, 0, 10],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#36445f" },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                type: [1, 1],
                width: 1,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              // formatter: function (value) {
              //   return value ? value + "%" : value;
              // },
            },
          },
        ],
        dataZoom: [],
        series: [
          {
            type: "bar",
            stack: "samestack",
            barMaxWidth: 9,
            data: [],
          },
          {
            type: "bar",
            stack: "samestack",
            barMaxWidth: 9,
            data: [],
          },
          {
            type: "bar",
            stack: "samestack",
            barMaxWidth: 9,
            data: [],
          },
        ],
      },
      undoTypeCount1: 0,
      undoTypeCount2: 0,
      undoTypeCount3: 0,
      safetyTroubleUndoDetail: [],
      photoShow: false,
      photoList: [],

      allCheckTroubleTypeChart: {},
      allCheckTroubleTypeChartTitle: "",
      troubleTypeAllSearchKey: [],
      allCheckTroubleTypeChartHighlightIndex: 0,
      allCheckTroubleTypeChartOption: {
        color: colors,
        legend: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "0%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["40%", "70%"],
            center: ["55%", "60%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function (params) {
                  return `{value|${Math.round(params.percent)}%}\n{text|${
                    params.name
                  }}`;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },
      allCheckTroubleTypePopShow: false,
      allCheckTroubleTypePopChart: {},
      allCheckTroubleTypePopOption: {
        legend: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "9%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            minAngle: 2,
            radius: ["40%", "70%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#08122b",
                borderWidth: 2,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                //formatter: '{text|{b}}\n{c} ({d}%)',
                //formatter: '{value|{d}%}\n{text|{b}}',
                formatter: function (params) {
                  return `{value|${Math.round(params.percent)}%}\n{text|${
                    params.name
                  }}`;
                },
                padding: [20, 0, 0, 0],
                rich: {
                  text: {
                    color: "#aaa",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                    lineHeight: 22,
                  },
                  value: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
              },
            },
            data: [],
          },
        ],
      },
      allCheckTroubleTypePopHighlightIndex: 0,
      troubleTypeAllPopSearchKey: [],
      allCheckTroubleTypePopTableTitle: "",
      allCheckTroubleTypePopTable: [],
    };
  },
  mounted() {
    // const self = this
    const { id: projectID } = this.$route.params;
    this.projectId = projectID;
    const token = this.$route.query.token;
    window.token = token;

    this.getData();
  },
  methods: {
    getData() {
      this.getEcharts1();
      this.getEcharts2();
      this.getEcharts3();
    },
    getEcharts1() {
      project
        .getSafetyPsTroubleLevelCollect(this.projectId, this.troubleMode)
        .then((d) => {
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));
          this.troubleTypePieChartTemplate.series[0].data = d.data;
          this.allCompanyTroubleSearchKey = d.search_keys;
          this.troubleTypePieChartTemplate.series[0].label = {
            rich: {
              text: {
                fontSize: 11,
              },
            },
            formatter: function (params) {
              return `${params.name} ${params.value}`;
            },
          };

          this.troubleTypePieChart = applyUpdate(
            chart,
            this.troubleTypePieChartTemplate
          );
        });
    },
    getTrouble(mode) {
      this.troubleMode = mode;

      this.getEcharts1();
    },
    troubleTypeMouseOver(params) {
      if (params.dataIndex != this.troubleTypePieChartHighlightIndex) {
        this.$refs.troubleType.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.troubleTypePieChartHighlightIndex,
        });
        this.troubleTypePieChartHighlightIndex = params.dataIndex;
      }
    },
    troubleTypeMouseOut() {
      this.$refs.troubleType.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.troubleTypePieChartHighlightIndex,
      });
    },
    getEcharts2() {
      project
        .getSafetyPsTroublePdUndoCollect(this.projectId, this.pdUndoTroubleMode)
        .then((d) => {
          let chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
          this.troubleTypeNoPieSearchKey = d.search_keys;
          this.troubleTypeNoPieChartOption.legend.data = d.legend_data;
          this.troubleTypeNoPieChartOption.xAxis.data = d.xAxis_data;
          this.troubleTypeNoPieChartOption.series[0].name = d.legend_data[0];
          this.troubleTypeNoPieChartOption.series[0].data = d.series[0];
          this.troubleTypeNoPieChartOption.series[1].name = d.legend_data[1];
          this.troubleTypeNoPieChartOption.series[1].data = d.series[1];
          this.troubleTypeNoPieChartOption.series[2].name = d.legend_data[2];
          this.troubleTypeNoPieChartOption.series[2].data = d.series[2];
          this.troubleTypeNoPieChart = applyUpdate(
            chart,
            this.troubleTypeNoPieChartOption
          );
        });
    },
    getUndoTrouble(mode) {
      this.pdUndoTroubleMode = mode;
      this.getEcharts2();
    },
    troubleTypeNoPieClick(param) {
      this.troubleTypeNoPieDetailShow = !this.troubleTypeNoPieDetailShow;
      this.troubleTypeNoPieTitle =
        this.troubleTypeNoPieChart.xAxis.data[param.dataIndex];

      project
        .getSafetyTroubleUndoDetailCollect(
          this.projectId,
          this.pdUndoTroubleMode,
          this.troubleTypeNoPieSearchKey[param.dataIndex],
          1,
          1000
        )
        .then((d) => {
          this.safetyTroubleUndoDetail = d;
          this.undoTypeCount1 = 0;
          this.undoTypeCount2 = 0;
          this.undoTypeCount3 = 0;
          this.safetyTroubleUndoDetail.forEach((item) => {
            if (item.undo_type == 1) {
              this.undoTypeCount1++;
            } else if (item.undo_type == 2) {
              this.undoTypeCount2++;
            } else if (item.undo_type == 3) {
              this.undoTypeCount3++;
            }
          });
        });
    },
    showPhotos(item) {
      this.photoShow = !this.photoShow;
      this.photoList = item.img_list;

      // console.log(item, index);
    },
    getEcharts3() {
      project
        .getSafetyCheckItemCollect(this.projectId)
        .then((d) => {
          // console.log(d, "-----555--------");
          this.troubleTypeAllSearchKey = d.search_keys;
          if (this.$refs.safetyCheckItem) {
            //取消默认高亮
            this.$refs.safetyCheckItem.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: this.allCheckTroubleTypeChartHighlightIndex,
            });
          }
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));

          this.allCheckTroubleTypeChartOption.series[0].data = d.data;

          this.allCheckTroubleTypeChartOption.series[1] = Object.assign(
            {},
            this.allCheckTroubleTypeChartOption.series[0],
            {
              label: {
                rich: {
                  text: {
                    fontSize: 11,
                  },
                },
                formatter: function (params) {
                  return `${params.name} ${params.value}`;
                },
              },
              silent: true,
            }
          );

          setTimeout(
            () =>
              this.$refs.safetyCheckItem &&
              this.$refs.safetyCheckItem.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: this.allCheckTroubleTypeChartHighlightIndex,
              }),
            100
          );
          this.allCheckTroubleTypeChart = applyUpdate(
            chart,
            this.allCheckTroubleTypeChartOption
          );
        })
        .catch((e) => console.error(e.msg || e.message));
    },
    workerTypeMouseOver(params) {
      if (params.dataIndex != this.allCheckTroubleTypeChartHighlightIndex) {
        this.$refs.safetyCheckItem.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.allCheckTroubleTypeChartHighlightIndex,
        });
        this.allCheckTroubleTypeChartHighlightIndex = params.dataIndex;
      }
    },
    workerTypeMouseOut() {
      this.$refs.safetyCheckItem.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.allCheckTroubleTypeChartHighlightIndex,
      });
    },
    dialogClose() {
      this.allCheckTroubleTypePopTable = [];
    },
    allCheckTroubleTypeChartClick(params) {
      this.allCheckTroubleTypePopShow = !this.allCheckTroubleTypePopShow;
      this.allCheckTroubleTypePopHighlightIndex=0;
      this.allCheckTroubleTypePopTableTitle ='';
      this.allCheckTroubleTypeChartTitle =
        this.allCheckTroubleTypeChart.series[0].data[params.dataIndex].name;
      //
      this.getPopEcharts(params.dataIndex);
    },
    getPopEcharts(mode) {
      project
        .getSafetyCheckItem2Collect(
          this.projectId,
          this.troubleTypeAllSearchKey[mode]
        )
        .then((d) => {
          this.troubleTypeAllPopSearchKey = d.search_keys;
          if (this.$refs.safetyCheckPopItem) {
            //取消默认高亮
            this.$refs.safetyCheckPopItem.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: this.allCheckTroubleTypePopHighlightIndex,
            });
          }
          const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));

          this.allCheckTroubleTypePopOption.series[0].data = d.data;

          this.allCheckTroubleTypePopOption.series[1] = Object.assign(
            {},
            this.allCheckTroubleTypePopOption.series[0],
            {
              label: {
                rich: {
                  text: {
                    fontSize: 11,
                  },
                },
                formatter: function (params) {
                  return `${params.name} ${params.value}`;
                },
              },
              silent: true,
            }
          );

          setTimeout(
            () =>
              this.$refs.safetyCheckPopItem &&
              this.$refs.safetyCheckPopItem.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: this.allCheckTroubleTypePopHighlightIndex,
              }),
            100
          );
          this.allCheckTroubleTypePopChart = applyUpdate(
            chart,
            this.allCheckTroubleTypePopOption
          );
          this.getList(this.allCheckTroubleTypePopHighlightIndex);

          this.allCheckTroubleTypePopTableTitle =
            this.allCheckTroubleTypePopChart.series[0].data[
              this.allCheckTroubleTypePopHighlightIndex
            ].name;
        })
        .catch((e) => console.error(e.msg || e.message));
    },

    getList(mode) {
      project
        .getSafetyCheckItem3Collect(
          this.projectId,
          this.troubleTypeAllPopSearchKey[mode]
        )
        .then((d) => {
          this.allCheckTroubleTypePopTable = d;
        });
    },
    allCheckTroubleTypePopChartClick(params) {
      if (this.$refs.safetyCheckPopItem) {
        //取消默认高亮
        this.$refs.safetyCheckPopItem.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.allCheckTroubleTypePopHighlightIndex,
        });
      }
      this.allCheckTroubleTypePopHighlightIndex = params.dataIndex;
      this.$refs.safetyCheckPopItem.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.allCheckTroubleTypePopHighlightIndex,
      });
      this.allCheckTroubleTypePopTableTitle =
        this.allCheckTroubleTypePopChart.series[0].data[params.dataIndex].name;

      this.getList(this.allCheckTroubleTypePopHighlightIndex);
    },
    allCheckTroubleTypePopMouseOver(params) {
      if (params.dataIndex != this.allCheckTroubleTypePopHighlightIndex) {
        this.$refs.safetyCheckPopItem.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.allCheckTroubleTypePopHighlightIndex,
        });
      }
    },
    allCheckTroubleTypePopMouseOut() {
      this.$refs.safetyCheckPopItem.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.allCheckTroubleTypePopHighlightIndex,
      });
    },

    troubleTypePieChartClick(params) {
      // console.log(params.dataIndex);
      this.allCompanyTroubleShow = true;
      this.allCompanyTroubleTitle =
        this.troubleTypePieChart.series[0].data[params.dataIndex].name;

      this.allCompanyTroubleActiveSearchKey =
        this.allCompanyTroubleSearchKey[params.dataIndex];
      this.securityStarPage = 1;
      this.getAllCompanyTroubleTable();
    },
    getAllCompanyTroubleTable() {
      project
        .getSafetyPsTroubleLevelDetailCollect(
          this.projectId,
          this.troubleMode,
          this.allCompanyTroubleActiveSearchKey,
          this.securityStarPage,
          10
        )
        .then((d) => {
          this.allCompanyTroubleTable = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        });
    },

    securityStarPageChange(currPage) {
      // console.log(currPage);
      this.securityStarPage = currPage;
      this.getAllCompanyTroubleTable();
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.photo {
  margin-top: 1rem;
  height: 26rem;
  width: 28rem;
}
.show-button {
  width: 40px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #4191e5;
  color: #fff;
  margin: auto;
  cursor: pointer;
}
.show-button2 {
  width: 40px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #999999;
  color: #fff;
  margin: auto;
  cursor: pointer;
}
.machine_use {
  display: flex;
  flex-wrap: nowrap;
  width: 50%;
  justify-content: space-around;
  align-items: center;
}
.machine_use_item {
  width: 30%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.8rem;
}
.machine_use_item_1 {
  border: 1px solid #29e8a8;
  background-color: rgba(41, 232, 168, 0.2);
}
.machine_num_1 {
  font-size: 1.5rem;
  color: #29e8a8;
}
.machine_use_item_2 {
  border: 1px solid #fee21d;
  background-color: rgba(254, 226, 29, 0.2);
}
.machine_num_2 {
  font-size: 1.5rem;
  color: #fee21d;
}
.machine_use_item_3 {
  border: 1px solid #4191e5;
  background-color: rgba(65, 145, 229, 0.2);
}
.machine_num_3 {
  font-size: 1.5rem;
  color: #4191e5;
}
.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
</style>

<style lang="less">
// .el-carousel__button{
//   background-color: #222933;
// }
// .el-carousel__arrow{
//   color: #222933 !important;
// }
.el-carousel {
  height: 100% !important;
}
.el-carousel__container {
  height: 100% !important;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>