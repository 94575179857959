<template>
  <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title">培训情况</div>
    <div
      class="detail-select"
      style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
    >
      <CustomSelect
        :options="yearList"
          :value="selectedYear"
          @input="categorySelect"
        style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
      />
    </div>
    <div style="height:25rem;position:relative">
      <div v-if="!troubleCountsPieChart.series || !troubleCountsPieChart.series[0].data.length" style="padding-top:5rem">
        <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;">
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
      </div>
      <v-chart v-else :options="troubleCountsPieChart" autoresize />
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import CustomSelect from '../../../../components/select'

  export default {
    name: 'TroubleCountsPieChart',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerTroubleCountsChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerTroubleCountsChart();
        }
      }
    },
    data () {
      return {
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear(),

        troubleCountsPieChart: {},
        troubleCountsPieChartHighlightIndex: null,
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

    },
    methods: {
      categorySelect(option) {
        this.selectedDangerCategoryName2 = option;
        this.updateSafetyDangerTroubleCountsChart();
      },
      updateSafetyDangerTroubleCountsChart() {

      },
      troubleCountsMouseOver(params) {
        if (params.dataIndex != this.troubleCountsPieChartHighlightIndex) {
          this.$refs.troubleCounts.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.troubleCountsPieChartHighlightIndex
          });
          this.troubleCountsPieChartHighlightIndex = params.dataIndex;
        }
      },
      troubleCountsMouseOut() {
        this.$refs.troubleCounts.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.troubleCountsPieChartHighlightIndex
        });
      },
    },
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
