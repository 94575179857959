<template>
  <div class="info-block" style="min-height:5rem">
      <div
        class="info-block-title"
        style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
      >
        <div>投标情况</div>
        <div class="detail" @click="showDetail()">
          详情
        </div>
      </div>
      <div style="height:19rem;padding-top:3rem">
        <div
          style="display:flex;justify-content:space-around;margin-bottom:0.6rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')"
          >
            投标情况总览
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')"
          >
            竞争性技术标第一名
          </div>
        </div>
        <div
          v-if="companyWorkersPieChart.series && [].concat.apply([], companyWorkersPieChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyWorkersPieChart" @click="companyWorkersPieChartClick" autoresize/>
      </div>
      <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow"
      width="80rem"
      top="15rem"
    >
      <wrapper style="height:33rem;backgroundColor:#0e1328;position:relative">
        <div
          class="stats-close-btn"
          @click="popupShow=false"
        />

        <div class="attendance">
          <div class="attendance-top" style=" display: flex; margin: 0 1rem; align-items: center;justify-content: space-between;">
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">{{popupTitle}}
            </div>
            <!-- <div class="tabbar" style="width:22rem">
              <div
                :class="{ 'tabar-select': popupSelect == 2 }"
                @click="getAttendance(2)"
              >正常（{{normal}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 5 }"
                @click="getAttendance(5)"
              >缓建（{{wait}}）</div>
              <div
                :class="{ 'tabar-select': popupSelect == 4 }"
                @click="getAttendance(4)"
              >停工（{{stop}}）</div>
            </div> -->
            <div style="width:10rem"></div>
          </div>
            <div style="margin:2rem 1rem 0 1rem; overflow: scroll; font-size:0.875rem; font-weight: 600; position:relative;">
              <div style="display: flex;  height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color:#4fb5da;background:#102855">
                <div style="width:15rem;text-align:center">分公司</div>
                <div style="width:8rem;text-align:center">投标次数</div>
                <div style="width:8rem;text-align:center">中标次数</div>
                <div style="width:8rem;text-align:center">中标率</div>
                <div style="width:15rem;text-align:center">技术标第一名次数</div>
                <div style="width:8rem;text-align:center">第一名占比</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in popProjectsList"
                  :key="item.worker_id"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem"
                  :style="{ background: index%2 ? '#102855' : '#213a77'}"
                >
                  <div style="width:15rem;text-align:center">{{item.orgName}}</div>
                  <div style="width:8rem;text-align:center">{{item.tbNum}}</div>
                  <div style="width:8rem;text-align:center">{{item.zbNum}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(parseFloat(item.zbRate)*100,2)}}%</div>
                  <div style="width:15rem;text-align:center">{{item.fristNum}}</div>
                  <div style="width:8rem;text-align:center">{{toFixed(parseFloat(item.fristRate)*100,2)}}%</div>
                </div>
                <div
                  v-if="!(popProjectsList && popProjectsList.length)"
                  style="height:2rem;padding:0 1rem;font-size:0.9rem;line-height:2rem;background:#081337;text-align:center"
                >
                  暂无数据
                </div>
              </div>
            </div>
        </div>
      </wrapper>
    </el-dialog>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { tech } from '../../../../api_tech'
  import wrapper from "../../../../components/wrap";

  export default {
    name: 'bindConditionChart',
    components: {
      'v-chart': ECharts,
      wrapper
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      }
    },
    data () {
      return {
        bindConditionChart: {},
        companyWorkersOption: "1",
        companyWorkersPieChart: {},
        popupShow:false,
        popProjectsList:[],
        popupTitle:"投标情况总览"
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.getAll();
    },
    methods: {
      getAll(){
          this.switchCompanyWorkersOption(this.companyWorkersOption);
      },
      switchCompanyWorkersOption(choose){
        this.companyWorkersOption = choose;
          switch (this.companyWorkersOption) {
              case "1":
                  this.updateBidConditionChart();
                  break;
              case "2":
                  this.updateBidRankChart();
                  break;
              default:
                  break;
          }
      },
      updateBidConditionChart() {
        tech.getBindCondition()
          .then(d => {
            const orgList = [];
            const bidList = [];
            const outBidList = [];
            const gainRate = [];
            for (let i = 0; i < d.length; i++) {
              orgList.push(d[i].orgName);
              bidList.push(d[i].tbNum);
              outBidList.push(d[i].zbNum);
              gainRate.push(d[i].zbRate == "NaN"?0:d[i].zbRate);
            }
            const option = {
              color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
              legend: {
                icon: 'rect',
                itemWidth: 12,
                itemHeight: 12,
                itemGap: 12,
                textStyle: {
                  color: '#ccc',
                  fontWeight: 'bold',
                  fontSize: '11'
                },
                padding: [8, 0, 0, 0],
              },
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  },
                  formatter:function(param){
                    let str = param[0].name+"<br/>";
                    for (let index = 0; index < param.length; index++) {
                      str += param[index].seriesName+":"+param[index].value+"<br/>";
                    }
                    return str;
                  }
              },
              grid: {
                containLabel: true,
                left: '5%',
                right: '6%',
                bottom: "5%",
                top: "12%"
              },
              xAxis: [
                {
                  name:"单位",
                  data: orgList,
                  axisLabel: {
                    color: 'white',
                    fontWeight: 'bold',
                    rotate: 40,
                    // formatter: value => truncateStr(value, 10),
                  },
                  show: true,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'category',
                }
              ],
              yAxis: [
                {
                  name:'数量: 个',
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  boundaryGap: [0, '20%'],
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'value'
                },
                {
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                    formatter: function(value) {
                      return value ? value + '%' : value;
                    },
                  },
                  saxisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'value',
                  show: true
                }
              ],
              series: [
                {
                  name: '投标数量',
                  type: 'bar',
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: bidList
                },
                {
                  name: '中标数量',
                  type: 'bar',
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: outBidList
                },
                {
                  name: '中标率',
                  type: 'line',
                  yAxisIndex: 1,
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: gainRate
                }
              ]
            };
            this.companyWorkersPieChart = option;
          })
          // eslint-disable-next-line
          .catch((e) => console.error(e.msg || e.message));
      },
      updateBidRankChart(){
        tech.getBindCondition()
          .then(d => {
            const orgList = [];
            const fristNumList = [];
            const bidList = [];
            const fristRateList = [];
            for (let i = 0; i < d.length; i++) {
              orgList.push(d[i].orgName);
              bidList.push(d[i].tbNum);
              fristNumList.push(d[i].fristNum);
              fristRateList.push(d[i].fristRate == "NaN"?0:d[i].zbRate);
            }
            const option = {
              color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
                legend: {
                icon: 'rect',
                itemWidth: 12,
                itemHeight: 12,
                itemGap: 12,
                textStyle: {
                  color: '#ccc',
                  fontWeight: 'bold',
                  fontSize: '11'
                },
                padding: [8, 0, 0, 0],
              },
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  },
                  formatter:function(param){
                    let str = param[0].name+"<br/>";
                    for (let index = 0; index < param.length; index++) {
                      str += param[index].seriesName+":"+param[index].value+"<br/>";
                    }
                    return str;
                  }
              },
              grid: {
                containLabel: true,
                left: '5%',
                right: '6%',
                bottom: "5%",
                top: "12%"
              },
              xAxis: [
                {
                  name:"单位",
                  data: orgList,
                  axisLabel: {
                    color: 'white',
                    fontWeight: 'bold',
                    rotate: 40,
                    // formatter: value => truncateStr(value, 10),
                  },
                  show: true,
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'category',
                }
              ],
              yAxis: [
                {
                  name:'数量: 个',
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  boundaryGap: [0, '20%'],
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'value'
                },
                {
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                  },
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                    formatter: function(value) {
                      return value ? value + '%' : value;
                    },
                  },
                  saxisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  type: 'value',
                  show: true
                }
              ],
              series: [
                {
                  name: '投标数',
                  type: 'bar',
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: bidList
                },
                {
                  name: '第一数量',
                  type: 'bar',
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: fristNumList
                },
                {
                  name: '第一占比',
                  type: 'line',
                  yAxisIndex: 1,
                  barWidth: 10,
                  emphasis: {
                    focus: 'series'
                  },
                  data: fristRateList
                }
              ]
            };
            this.companyWorkersPieChart = option;
          })
          // eslint-disable-next-line
          .catch((e) => console.error(e.msg || e.message));
      
      },
      showDetail(){
        tech.getBindCondition().then(res=>{
          this.popupShow = true;
          this.popProjectsList = res;
        })
      },
      toFixed(value,ptn){
        return tech.formatDecimal(value,ptn);
      },
      companyWorkersPieChartClick(){

      }
    },
  }
</script>

<style lang="less">
</style>
