import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';

// 支持的options:
// 1. options.rotZ: 模型围绕Z轴逆时针旋转的角度
// 2. options.scale: 模型缩放比例
// 3. options.moveX/moveY/moveZ: 模型在三个轴上的移动距离
export function loadModel(scene, modelPath, options, callback) {
    const loader = new FBXLoader();
    loader.load(modelPath, function(object) {
        object.traverse(function(child) {
            if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;
            }
        });

        options = options || {};

        if (options.scale) {
            object.scale.x = options.scale;
            object.scale.y = options.scale;
            object.scale.z = options.scale;
        }

        if (options.rotX) {
            object.rotation.x = options.rotX / 180 * Math.PI;
        }
        if (options.rotY) {
            object.rotation.y = options.rotY / 180 * Math.PI;
        }
        if (options.rotZ) {
            object.rotation.z = options.rotZ / 180 * Math.PI;
        }

        var box = new THREE.Box3().setFromObject(object);
        var center = new THREE.Vector3();
        box.getCenter(center);
        object.position.sub(center);

        if (options.moveX || options.moveY || options.moveZ) {
            object.position.add({x: options.moveX || 0, y: options.moveY || 0, z: options.moveZ || 0});
        }

        scene.add(object);

        callback && callback();
    });
}
