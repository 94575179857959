<template>
  <div id="left">
    <ProjectTeamFormationChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
    <ProjectPlanCompletionRateChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" :org-options="orgOptions" />
    <ProductionResourceRateChart :district-level="districtLevel" :district-scale="districtScale" :org-level="orgLevel" />
  </div>
</template>

<script>
  import ProjectTeamFormationChart from "./projectTeamFormationChart";
  import ProjectPlanCompletionRateChart from "./projectPlanCompletionRateChart";
  import ProductionResourceRateChart from "./productionResourceRateChart";

  export default {
    name: 'QualityIndexLeftBar',
    components: {
      ProjectTeamFormationChart,
      ProjectPlanCompletionRateChart,
      ProductionResourceRateChart,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
  }
</script>

<style lang="less">
</style>
