import { addFloorNames } from '../scene_helpers';

export default function getConfig(data) {
  const buildingTopCenters = {
    'T1塔楼': [142.23924143842976,-381.33757653502823,700],
  };

  const buildingBoundaries = {
    'T1塔楼': [[550.8251468741103,-220.681946100763],[630.9057913264834,-476.8242752988115],[110.92692512075251,-635.9136588265142],[26.492755754556924,-342.1078527615948]],
    //'T1塔楼': [[-100000000, -10000000],[-100000000, 10000000],[100000000, 10000000],[100000000, -10000000],],
  };

  const buildingFinishedHeights = {
    'T1塔楼': 650,
  };

  const doneFloors = {
    'T1塔楼': 30,
  };

  const traceViews = {
    '_': {
      center: [2408.171465977029, -3225.5325851875405],
      pitch: 0,
      zoom: 2.58,
      bearing: -33.4,
    },
    'T1塔楼': {
      center: [-3166.2807559791027, 3837.8607089323878],
      pitch: 74.4,
      zoom: 2,
      bearing: -40.6,
    },
  };

  function getUserZValue(user) {
    return (user.floor == 'ground' ? 0 : user.height) * 2.58 + 3.2;
  }

  function getBuildingFloor(build_name, height) {
    for (const bldg of data.buildingFloorHeights) {
      if (bldg.build_name == build_name) {
        for (let i = 0; i < bldg.floor_info.length; i++) {
          if (height < bldg.floor_info[i].elevation_height) {
            return bldg.floor_info[Math.max(i - 1, 0)].floor_name;
          }
        }
        return bldg.floor_info[bldg.floor_info.length - 1].floor_name;
      }
    }
  }

  function getHeightFromZValue(zValue) {
    return zValue / 2.58;
  }

  function getZValueFromHeight(height) {
    return height * 2.58;
  }

  function getFloorFromZValue(bldg, zValue) {
    return getBuildingFloor(bldg, getHeightFromZValue(zValue));
  }

  function getHeightErrorMargin(/*bldg, zValue*/) {
    return 0.5;
  }

  function modelPostProc(scene/*, meshMap*/) {
    addFloorNames(scene, [
//      ['5F', 135, 0, -10],
//      ['10F', 135, 0, 63.8],
//      ['15F', 135, 0, 137.6],
//      ['20F', 135, 0, 211.4],
//      ['25F', 135, 0, 285.2],
//      ['30F', 135, 0, 359],
    ]);
  }

  return {
    //modelPath: 'http://127.0.0.1/op105.fbx',
    modelPath: '/zheshang.glb',
    modelType: 'gltf',
    modelSubType: 'draco',

    //fullOpacity: 1,
    revealOpacity: 0.2,
    deepRevealOpacity: 0,

    /*
    groundHeightRanges: [
      [-0.0001, 0],
      [153.9731, 153.9732],
      [-1425.0001, -1424.9999],
    ],
    */

    revealUnderground: -0.1,

    modelOptions: {
      scale: 2.6,
      rotZ: 0,
      moveX: 580,
      moveY: -180,
      moveZ: 0,
    },

    sceneRotation: {
      x: Math.PI / 2,
      y: Math.PI * 1.1,
      z: 0
    },

    buildingTopCenters: buildingTopCenters,
    buildingBoundaries: buildingBoundaries,
    buildingFinishedHeights: buildingFinishedHeights,
    doneFloors: doneFloors,
    traceViews: traceViews,

    buildingFloorHeights: data.buildingFloorHeights,
    getUserZValue: getUserZValue,
    getZValueFromHeight: getZValueFromHeight,
    getFloorFromZValue: getFloorFromZValue,
    getHeightErrorMargin: getHeightErrorMargin,
    modelPostProc: modelPostProc,
  };
}
