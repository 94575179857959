<template>
    <div id="project" class="global-bg" style="overflow:hidden">
        <div
            id="title-container"
            style="margin-bottom:-3rem;position:relative;width:570px;margin:auto"
            >
            <div id="title-left2" />
            <div
                id="title-center2"
                style="position:relative"
            >
                <div>{{projNameFormatter(projName)}}</div>
                <div style="position:absolute;top:0.45rem;left:0;cursor:pointer;width:40px" @click.stop="$router.back()">
                  <img src="../../../assets/back.png" style="width:28px;height:28px;margin-left:0.1rem;margin-top:0.5rem" alt="" />
                </div>
            </div>
            <div id="title-right2" />
            <div
                id="title-light"
            />
            </div>
            <el-menu
      class="menu-block"
      mode="horizontal"
      background-color="transparent"
      text-color="#fff"
      active-text-color="#fff"
    >
      <el-autocomplete
        class="inline-input"
        style="width:18rem;margin-left:55rem;pointer-events: all;"
        v-model="searchParams"
        :fetch-suggestions="querySearch"
        placeholder=""
        :trigger-on-focus="false"
        @select="handleSelect"
      >
          <i slot="suffix" class="el-input__icon"><img src="../../../assets/nation/search.png" style="margin-right:0.7rem;margin-top:1rem" alt=""></i>
      </el-autocomplete>
    </el-menu>
    <div id ="main">
        <div>
            <financeLeftBar :projcode="projectId"/>
        </div>
        <div id = "middle">
            <middleBar :projcode="projectId" />
        </div>
        <div>
            <financeRightBar :projcode="projectId"/>
        </div>
    </div>
    </div>
</template>
<script>
import financeLeftBar from "./index/leftBar"
import financeRightBar from "./index/rightBar"
import middleBar from "./index/middleBar"
import { finance } from '../../../api_finance'
import { truncateStr } from '../../../utils'
export default {
    name:"finance",
    components:{
        financeLeftBar,
        financeRightBar,
        middleBar
    },
    data(){
        return {
            projectId:"",
            projName:"",
            searchParams:''
        }
    },
    watch:{
       $route: function(newValue, oldValue) {
        if (newValue.params.id != oldValue.params.id) {
          this.initProject();
        }
      },
    },
    mounted(){
       this.initProject();
    },
    methods:{
      initProject(){
        this.projectId = this.$route.params.id;
        const token = this.$route.query.token;
        window.token = token;
        this.projName = this.$route.query.projName;
      },
      projNameFormatter(str){
        const tempName = str.replace("(资金)","");
        return truncateStr(tempName,20);
      },
        querySearch(queryString, cb){
            var finance_projects = [];
            finance.queryProjInfo(queryString).then((res=>{
                finance_projects = res.map(item=>{
                    return{
                        value:item.projName,
                        id:item.profitCode
                    }
                })
                var projects = finance_projects;
                var results = queryString ? projects.filter(this.createFilter(queryString)) : projects;
                // 调用 callback 返回建议列表的数据
                // console.log(results)
                cb(results);
            }))
        },
        createFilter(queryString) {
             return (project) => {
                return (project.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
            };
        },
        handleSelect(item){
            this.$router.replace({path:`/finance/${item.id}`,query:{projName:item.value,token:window.token}})
        }
    },
    
}
</script>
<style scoped lang= "less">
        #project {
        background-size: cover;
        }
        #title-center2 {
  width: 620px;
  height: 64px;
  display: inline-block;
  line-height: 64px;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  background: url("../../../assets/nation/mid.png") transparent;
  background-size: contain;
}
    #title-left2 {
    width: 32px;
    height: 64px;
    background: url("../../../assets/nation/left.png") no-repeat transparent;
    background-size: contain;
    }
    #title-right2 {
        width: 32px;
        height: 64px;
        background: url("../../../assets/nation/right.png") no-repeat transparent;
        background-size: contain;
        }
        #title-light {
  position: absolute;
  width: 377px;
  height: 53px;
  background: url("../../../assets/nation/light2.png") transparent;
  bottom: -26.5px;
  z-index: 20000;
}
.info-block {
  position: relative;
  width: 100%;
  background: url("../../../assets/nation/title_finance.png") no-repeat;
  background-size: contain;
}
.menu-block {
  position: fixed;
  top: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: none !important;
  pointer-events: none;

  .el-menu-item {
    pointer-events: all;
    padding: 0 12px;
    font-size: 15px;
    border-bottom: none;
    width: 120px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    background: url("../../../assets/nation/button.png");
  }

  .el-menu-item.is-active {
    border-bottom: none;
    background: url("../../../assets/nation/button_select.png");
  }

  .el-menu-item.suppress-active {
    background: url("../../../assets/nation/button.png") !important;
  }

  .el-menu-item:not(.is-disabled):hover,
  .el-menu-item:not(.is-disabled):focus {
    border-bottom: none;
    background-color: transparent !important;
  }
}
#main {
  display: flex;
  // padding: 4.75rem 1.875rem 3.5rem 1.875rem;
  padding: 0 1.875rem 3.5rem 1.875rem;
  margin-top: 2.75rem;
  height: calc(100vh - 10rem);
  overflow-y: scroll;

  #left {
    width: 35rem;
  }

  #right {
    width: 35rem;
  }

  #middle {
    width: 50rem;
    position: relative;
    padding: 0 1rem;
    margin: 0 auto;

    #map {
      // margin-bottom: 1rem;
      .project_map {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
      }

      .menus {
        z-index: 999;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #21315b;
        cursor: pointer;

        > div {
          padding: 0.58rem 0.73rem;
          font-size: 0.82rem;
        }

        > div:first-child {
          border-right: 1px solid #5e7ec9;
        }

        .img {
          margin-left: 0.47rem;
          width: 0.76rem;
          height: 0.7rem;
        }
      }
    }
  }
}
</style>