<template>
  <div id="building" class="global-bg">
    <div id="title-container">
      <div id="title-left" />
      <div id="title-center">{{decodeURIComponent($route.query.project)}}</div>
      <div id="title-right" />
    </div>
    <div id="main">
      <div id="left" style="transform: rotate3d(0, 100, 0, 0.5deg) translate(18px)">
        <div id="group-container">
          <wrapper>
            <s-title title="出勤人员状态"/>
            <div id="retreat-btn" @click="showRecallDialog = true" v-if="groupList.length">全员召回</div>
            <div class="group">
              <div class="group-item" v-for="group in groupList" :key="group.team_id">
                <div class="group-title light-blue-text"><span class="group-title-icon"
                                                               :class="[group.show ? '' : 'active']"
                                                               @click="toggleGroup(group)"> </span>{{group.team_name}}（总人数：{{group.worker_list.length}}）
                </div>
                <ul class="people-card-list" v-show="group.show">
                  <li class="card-item" v-for="worker in group.worker_list" :key="worker.innerid">
                    <div class="left">
                      <p>
                        <span class="title">姓名：</span>
                        <span class="value">{{worker.name}}（{{worker.hat_code}}）</span>
                      </p>
                       <p>
                        <span class="title">工种：</span>
                        <span
                            class="value">{{worker.worker_type || ''}}</span>
                      </p>
                      <p>
                        <span class="title">区域：</span>
                        <span
                            class="value">{{worker.area === 0 ? `地面` : `${worker.build_name} - ${worker.floor_name}`}}</span>
                      </p>
                      <p v-show="worker.warning">
                        <span class="title">危险源预警：</span>
                        <span class="value">{{worker.warning}}</span>
                      </p>
                      <p>
                        <span class="title">是否佩戴安全帽：</span>
                        <span class="value" :class="'text-' + wearMap[worker.wear || 0].cls">{{wearMap[worker.wear || 0].text}}</span>
                      </p>
                      <p>
                        <span class="title">运动状态：</span>
                        <span class="value" :class="'text-' + motionMap[worker.motion]">{{worker.motion || '--'}}</span>
                      </p>
                      <p>
                        <span class="title">剩余电量：</span>
                        <span class="value" :class="'text-' + batteryMap(worker.battery_level)">{{worker.battery_level}}%</span>
                      </p>
                      <!-- <p v-show="worker.temp || worker.humidity">
                        <span class="title">温度/湿度：</span>
                        <span class="value">{{worker.temp || '--'}}/{{worker.humidity || '--'}}</span>
                      </p> -->
                    </div>
                    <div class="right">
                      <div class="status-card" :class="'borderColor-' + statusColorMap[worker.alarm].cls">
                        <p class="zt">状态</p>
                        <p class="status-text" :class="'text-' + statusColorMap[worker.alarm].cls">{{worker.alarm}}</p>
                      </div>
                      <div class="op" :class="retrieveStatusMap[worker.recalled].cls" @click="prepareRecall(worker)">
                        {{retrieveStatusMap[worker.recalled].text}}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </wrapper>
        </div>

        <div id="people-by-status">
          <wrapper>
            <s-title title="当前楼栋工种分布"/>
            <div class="chart">
              <v-chart :options="workerStatusChart" ref="workerStatusChart" @mouseover="workerStatusChartMouseOver" @mouseout="workerStatusChartMouseOut" autoresize/>
            </div>
          </wrapper>
        </div>
      </div>

      <div id="middle">
        <div class="project-link">
          <a v-if="!!fromOrg" style="cursor:pointer" @click="$router.back()">
            返回
          </a>
          <router-link v-else :to="`/project2/${$route.params.projectID}/${$route.query.nav}/${$route.query.sub || '0'}/?token=${$route.query.token}&from_org=${fromOrg}&use3d=${$window.use3D}`">
            返回
          </router-link>
        </div>

        <div class="iframe">
          <iframe src="/threejs/index.html" frameborder="0" id="iFrame" ref="frame" style="width: 100%; height: 100%; border: 0"
                  allowfullscreen="true"></iframe>
        </div>

        <div class="building-changer" v-if="building.buildingList.length">
          <floor-changer :list-data="building.buildingList" :init-id="building.currentBuildingID"
                         @change="changeBuilding"/>
          <floor-changer
              :list-data="(building.buildingList.find(b => b.innerid === building.currentBuildingID) || {floor: []}).floor"
              :key="building.currentBuildingID" check-length
              :cell-style="{width: '6rem', height: '3rem', lineHeight: '3rem', overflow: 'hidden'}" @change="changeFloor"/>
        </div>
      </div>

      <div id="right" style="transform: rotate3d(0, 0.000001, 0, -0.5deg) translate(-18px)">
        <div id="per-floor-worker-types">
          <wrapper>
            <s-title title="各楼层工种人数"/>
            <div class="list-table">
              <div class="list-item" v-for="floor in workerTypesPerFloor" :key="floor.floor_name" style="margin-top:12px;">
                <div :style="{ height: (floor.worker_list.length * 20 + 42) + 'px' }" style="background-color: #1c2048;">
                  <div style="height:35px;text-align:center;padding:10px;font-size:14px;font-weight:600">{{floor.floor_name}}</div>
                  <div :style="{ height: (floor.worker_list.length * 20 + 15) + 'px' }">
                    <v-chart :options="floor.chart" autoresize />
                  </div>
                </div>
              </div>
            </div>
          </wrapper>
        </div>
        <div id="people-by-floor">
          <wrapper>
            <s-title title="各楼层总人数"/>
            <div class="chart" style="padding-top:1rem">
              <v-chart :options="peopleByFloorChart" ref="peopleByFloorChart" autoresize />
            </div>
          </wrapper>
        </div>
      </div>
      <el-dialog custom-class="stats-layer" :visible.sync="pathShow" :close-on-click-modal="true" width="90rem" top="5rem">
        <wrapper style="backgroundColor:#0e1328;position:relative;padding:0">
          <div class="stats-close-btn" @click="pathShow = false" />
          <div style="position:absolute;top:1rem;left:1rem;font-size:1.5em;">人员轨迹 - {{pathWorkerName}}</div>
          <WorkerPath :workerId="pathWorkerId" :date="pathDate" :mapUrl="pathMapUrl" :mapOptions="extraMapOptions" />
        </wrapper>
      </el-dialog>
      <div id="callback-dialog">
        <el-dialog :visible.sync="showCallBackDialog" :show-close="false" center custom-class="recall-dialog">
          <div style="display:flex;align-items:center">
            <div style="flex:1" />
            <p class="warning-icon"></p>
            <p style="margin-bottom:3.5rem;margin-left:3rem;">是否确认召回{{callBackPerson}}？</p>
            <div style="flex:1" />
          </div>
          <div style="text-align:center">
            <div class="cancel-button" @click="showCallBackDialog = false">取消</div>
            <div class="confirm-button" @click="callBack">确定</div>
          </div>
        </el-dialog>
      </div>
      <div id="recall-dialog">
        <el-dialog :visible.sync="showRecallDialog" :show-close="false" center custom-class="recall-dialog">
          <div style="display:flex;align-items:center">
            <div style="flex:1" />
            <p class="warning-icon"></p>
            <p style="margin-bottom:3.5rem;margin-left:3rem;">执行全体撤离操作？</p>
            <div style="flex:1" />
          </div>
          <div style="text-align:center">
            <div class="cancel-button" @click="showRecallDialog = false">取消</div>
            <div class="confirm-button" @click="retrieveAll">确定</div>
          </div>
        </el-dialog>
      </div>
      <div id="logo">
        <span>星璇项目管理系统</span>
        <img src="../../assets/logo.png" alt="" height="28">
      </div>
    </div>
  </div>
</template>

<script>
  import WorkerPath from '../project2/path';
  import wrapper from '../../components/wrap'
  import smallTitle from '../../components/small-title'
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/line'
  import 'echarts/lib/component/polar'
  import { project, building, wsUrl } from '../../api'
  import FloorChanger from '../../components/FloorChanger'
  import {mapRatio} from '../../conf';
  import _ from 'lodash';

  const workerTypeColors = ['#595EF5', '#619B3A', '#4EC255', '#4EC28D', '#4EC1B9', '#58BBF5', '#588CF5'];

  // building/e2a351a4-6509-11ea-ad9d-0242ac113d06/fc9f9bef-e0d5-45c6-9ce4-a29212a78557
  // const synth = window.speechSynthesis
  const workerOnThreeColorMap = {
    '': [0, 255 / 255, 0],
    '黄色': [233 / 255, 187 / 255, 30 / 255],
    '红色': [220 / 255, 40 / 255, 43 / 255],
    '白色': [255 / 255, 255 / 255, 255 / 255],
    '蓝色': [55 / 255, 162 / 255, 218 / 255],
  }
  /*
  const workerOnThreeBlinkColorMap = {
    '正常': [0, 255, 0],
    '坠落': [255, 0, 0],
    '呼救': [255, 0, 0],
    '预警': [255, 255, 0],
    '倒地': [255, 255, 0],
    '离线': [100, 100, 100],
  }
  */
  export default {
    name: 'building',
    components: {
      WorkerPath,
      wrapper,
      'v-chart': ECharts,
      's-title': smallTitle,
      'floor-changer': FloorChanger
    },
    data () {
      return {
        fromOrg: 0,
        statusColorMap: {
          '正常': { value: '#1fc86b', cls: 'green' },
          '呼救': { value: '#f34e53', cls: 'red2' },
          '坠落': { value: '#e62c31', cls: 'red1' },
          '预警': { value: '#eebc34', cls: 'yellow2' },
          '禁区预警': { value: '#eebc34', cls: 'yellow2' },
          '危险预警': { value: '#eebc34', cls: 'yellow2' },
          '闯入禁区': { value: '#eebc34', cls: 'yellow2' },
          '静止': { value: '#eebc34', cls: 'yellow2' },
          '倒地': { value: '#F89132', cls: 'yellow1' },
          '离线': { value: '#9ca0aa', cls: 'grey' },
          '没电': { value: '#eebc34', cls: 'yellow2' }
        },
        wearMap: [{ cls: 'red2', text: '未佩戴' }, { cls: 'green', text: '佩戴' }],
        retrieveStatusMap: {
          11: { text: '召回', cls: '' },
          7: { text: '已收到', cls: 'disabled' },
          8: { text: '已确认', cls: 'disabled' },
          10: { text: '发送中', cls: 'disabled' }
        },
        motionMap: {
          运动: 'green',
          静止: 'green',
          倒地: 'yellow1',
          坠落: 'red1',
          没电:'yellow1',
        },
        showCallBackDialog: false,
        callBackPerson: '',
        callBackFunc: null,
        showRecallDialog: false,
        showPrompt: false,
        ws: null,
        building: {
          showBuildingList: false,
          buildingList: [],
          currentBuildingID: '',
        },
        peopleByFloorChart: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: '{b} <br/>{c}人'
          },
          grid: {
            left: 30,
            right: '5%',
            top: 1,
            bottom: 40,
            containLabel: false
          },
          yAxis: {
            type: 'value',
            show: true,
            minInterval: 1,
            max: value => value.max * 1.05,
            axisLabel: {
              color: '#fff',
              fontSize: 12,
              showMaxLabel: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false,
            },
            axisLine: { show: true, lineStyle: { color: '#36445f', width: 2 } }
          },
          xAxis: {
            type: 'category',
            data: [],
            axisLabel: {
              color: '#fff',
              fontSize: 12,
              interval: 0,
              rotate: 25,
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false,
            },
            axisLine: { show: true, lineStyle: { color: '#36445f', width: 2 } }
          },
          series: [
            {
              name: '',
              type: 'bar',
              barMaxWidth: 14,
              itemStyle: {
                color: new ECharts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: '#51baf5' },
                    { offset: 1, color: '#5173f5' },
                  ]
                ),
              },
              label: {
                normal: {
                  show: false,
                  position: 'top',
                  color: '#eaa24e'
                }
              },
              data: []
            },
          ]
        },
        workerStatusChart: {
          //tooltip: {
          //  trigger: 'item',
          //},
          grid: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            containLabel: false
          },
          // legend:{
          //   orient: 'vertical',
          //   left: 'right',
          //   textStyle:{
          //     color:'#fff'
          //   },
          //   data: []
          // },
          legend: {
            orient: 'vertical',
            left: '58%',  //图例距离左的距离
            height: '80%',
            y: 'center',  //图例上下居中
            symbol: 'rect',
            itemWidth: 12,
            itemHeight: 12,
            itemGap: 15,
            formatter: null,
            textStyle: {
                rich: {
                    a: {
                        color: '#878b94',
                        fontSize: 12,
                    },
                    b: {
                        color: '#ffffff',
                        fontSize: 12,
                    }
                }
            },
            data: []
          },
          color: ['rgb(203,155,255)', 'rgb(149,162,255)', 'rgb(58,186,255)',
                'rgb(119,168,249)', 'rgb(235,161,159)'],
          series: [{
            type: 'pie',
            radius: ['40%', '60%'],
            //center: ['46%', '60%'],
            center: ['30%', '53%'], //图的位置，距离左跟上的位置
            data: [],
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                fontWeight: 'bold',
                formatter: "{c} ({d}%)\n\n{b}",
                align: 'center'
              },
            }
          }]
        },
        workerTypesPerFloorChart: {
          grid: {
            left: '5%',
            right: 7,
            top: 1,
            bottom: 0,
            containLabel: true
          },
          xAxis: {
            show: false,
            max: 'dataMax'
          },
          yAxis: {
            type: 'category',
            data: [],
            axisLabel: {
              color: '#fff',
              fontSize: 12,
              fontWeight: 600,
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false,
            },
            axisLine: { show: false }
          },
          series: [
            {
              name: '',
              type: 'bar',
              stack: 'samestack',
              barMaxWidth: 9,
              itemStyle: {
                color: params => workerTypeColors[params.dataIndex % workerTypeColors.length],
              },
              data: []
            },
            {
              name: '',
              type: 'bar',
              stack: 'samestack',
              itemStyle: {
                color: '#1c2048'
              },
              data: [],
              label: {
                normal: {
                  show: true,
                  position: 'insideRight',
                  color: '#4bc1e5',
                  fontWeight: 600,
                }
              },
            }
          ]
        },
        workerStatusChartHighlightIndex: 0,
        groupList: [{ worker_list: [], show: true }],
        workerTypesPerFloor: [],
        curFloorList: [],
        timer: null,
        workerMap: {},
        pathShow: false,
        pathWorkerId: '',
        pathWorkerName: '',
        pathDate: undefined,
        pathMapUrl: '',
        extraMapOptions: {},
        beingDestroyed: false,
      }
    },
    mounted () {
      this.fromOrg = parseInt(this.$route.query.from_org || 0);
      window.use3D = parseInt(this.$route.query.use3d || 0);
      document.addEventListener('click', () => {
        if (this.$refs.frame) {
          const tooltip = this.$refs.frame.contentDocument.querySelector('#tooltip');
          if (tooltip) tooltip.style.visibility = 'hidden';
        }
      })
      const self = this
      const token = this.$route.query.token
      if (!token) {
        return this.$alert('缺少token', '警告', {
          confirmButtonText: '返回上一页',
          showClose: false,
          type: 'warning',
          center: true,
          callback: () => {
            history.go(-1)
          }
        })
      }
      const { buildingID, projectID } = this.$route.params
      window.token = token
      const { contentWindow } = self.$refs.frame
      contentWindow.onload = function () {
        var win = this;
        win.ta = self;
        // building.getLegend()
        //   .then(data => {
        //     self.legends = data.worker_type_list.map(l => {
        //       l.icon = require(`../../assets/${l.icon}`)
        //       return l
        //     })
        //   })
        //   .catch(e => self.$message.error(e.msg || e.toString()))
        self.updateData();

        setTimeout(() => {
          building.getBuildings()
            .then(d => {
              d.forEach(l => {
                const res = []
                let floorStart = ''
                let floorEnd = ''
                let ids = []
                l.floor.forEach((a, i) => {
                  if (i % 5 === 0) {
                    ids = [a.innerid]
                    floorStart = a.cname
                  } else {
                    ids.push(a.innerid)
                  }
                  if (i % 5 === 4 || i === l.floor.length - 1) {
                    floorEnd = a.cname
                    res.push({ name: `${floorStart}~${floorEnd}`, innerid: i, ids })
                  }
                })
                l.floor = res
              })
              self.building.buildingList = d
              self.building.currentBuildingID = buildingID
              self.changeFloor(d.find(_ => _.innerid === buildingID).floor[0])
            })
            .catch(e => console.error(e.msg || e.toString()))
        }, 100);

        project
          .getBuildingBaseInfo(projectID)
          .then(d => {
            win.mapUrl = d.img_host + (d.travel_url || d.show_map[0].image_url);
            self.pathMapUrl = win.mapUrl;
            self.extraMapOptions = {
              mapWidth: d.show_map[0].width,
              mapHeight: d.show_map[0].height,
            };
          });

        this.timer = setInterval(() => {
          self.updateData();
        }, 10000)

        self.conn(self)

        // const peopleStaticsData =

        // contentWindow.postMessage({
        //   type: 'peopleStatics', data: [
        //     {
        //       msg: '水泥工：5\n钢筋工：7\n钢筋工：8',
        //       floor: '1F',
        //       area: 1,
        //     },
        //     {
        //       msg: '水泥工：11\n钢筋工：11\n钢筋工：11',
        //       floor: '2F',
        //       area: 3,
        //     }
        //   ]
        // }, '*')
      }
    },
    methods: {
      dataParser (data) {
        if (data && data.errcode === 0) {
          switch (data.source) {
            case 'customer_update':
              if (this.groupList.length) {
                const user = data.data[0]
                this.groupList.forEach((group, oi) => {
                  group.show = group.show === undefined ? true : group.show
                  if (group.worker_list.length) {
                    group.worker_list.forEach((worker, ii) => {
                      if (worker.innerid === user.innerid) {
                        if (this.wearMap[user.wear] === undefined || this.statusColorMap[user.alarm]=== undefined || this.retrieveStatusMap[user.recalled] === undefined) {
                          throw new Error(JSON.stringify(user))
                        }
                        let orderID
                        switch (user.status_id) {
                          case 1:
                            orderID = 0
                            break
                          case 2:
                            orderID = 1
                            break
                          case 3:
                            orderID = 1
                            break
                          case 5:
                            orderID = 2
                            break
                          case 12:
                            orderID = 3
                            break
                          case 9:
                            orderID = 4
                            break
                          default:
                            orderID = 100
                        }
                        this.$set(this.groupList[oi].worker_list, ii, Object.assign({ orderID }, user))
                      }
                    })
                    group.worker_list.sort((a, b) => a.orderID - b.orderID)
                  }
                })
                if (this.workerMap[user.innerid]) {
                  if (user.x != null && user.y != null) {
                    this.workerMap[user.innerid] = user;
                  } else {
                    delete this.workerMap[user.innerid];
                  }
                }

                const workerPosition = Object.values(this.workerMap).filter(w => this.curFloorList.filter(id => id === w.floor).length).map(w => ({
                  id: w.innerid,
                  name: w.name,
                  floor: w.floor_name,
                  floorID: w.floor,
                  position: { x: w.x / mapRatio, y: w.y / mapRatio },
                  color: workerOnThreeColorMap[w.color || ''],
                  group: w.worker_type || '',
                  status_id: w.status_id,
                  hat_code: w.hat_code || '',
                  mobile: w.mobile,
                }))

                this.$refs.frame.contentWindow.postMessage({ type: 'addUser', data: workerPosition }, '*')
              }
              break
            default:
              console.warn(data.source)
          }
        } else {
          console.error('error code', data.errcode)
        }
      },
      normalize_floor_name(floor_name) {
        // Normalize floor name for sort
        const floor = parseInt(floor_name.slice(0, -1));
        if (isNaN(floor)) {
          return floor_name;
        } else {
          return Array(Math.max(0, 3 - ('' + floor).length + 1)).join('0') + floor_name;
        }
      },
      loadPeopleByFloorChartData(d) {
        // First sort floors in d
        d.forEach(a => a.floor = this.normalize_floor_name(a.floor_name));
        d.sort((a, b) => a.floor.localeCompare(b.floor));
        this.peopleByFloorChart.xAxis.data = d.map(a => a.floor_name);
        this.peopleByFloorChart.series[0].data = d.map(a => a.worker_count);
      },
      loadWorkerTypePerFloorChartData(d) {
        // First sort floors in d
        d.forEach(a => a.floor = this.normalize_floor_name(a.floor_name));
        d.sort((a, b) => a.floor.localeCompare(b.floor));
        this.workerTypesPerFloor = d.map(a => {
          a.chart = _.cloneDeep(this.workerTypesPerFloorChart);
          a.chart.yAxis.data = a.worker_list.map(x => x.worker_type || '');
          a.chart.series[0].data = a.worker_list.map(x => x.count);
          const maxVal = Math.max.apply(this, a.chart.series[0].data);
          a.chart.series[1].data = a.worker_list.map(x => maxVal * 1.2 - x.count);
          a.chart.series[1].label.normal.formatter = params => {
            return a.chart.series[0].data[params.dataIndex];
          };
          return a;
        });
      },
      loadWorkTypeChartData(d) {
        const kk = [];
        const legent = [];
        const workerTypeMap = {};
        let maxTypeLen = 0;
        d.forEach(a => {
          kk.push({name:a._id,value:a.count})
          legent.push(a._id)
          workerTypeMap[a._id] = a.count;
          if (a._id.length > maxTypeLen) {
            maxTypeLen = a._id.length;
          }
        });
        this.workerStatusChart.series[0].data = kk
        this.workerStatusChart.legend.data = legent
        this.workerStatusChart.legend.formatter = (name) => {
          let fillStr = '';
          for (let i = 0; i < maxTypeLen + 1 - name.length; i++) {
            fillStr += '\u3000';
          }
          return `{a|${name}}` + fillStr + `{b|${workerTypeMap[name]}}`;
        };
        window.setTimeout(
          () =>
            this.$refs.workerStatusChart &&
            this.$refs.workerStatusChart.dispatchAction({
              type: 'highlight',
              seriesIndex: 0,
              dataIndex: this.workerStatusChartHighlightIndex
            }),
          1000
        )
      },
      updateData () {
        building.getWorkListForBuild(this.$route.params.buildingID)
          .then(list => {
            this.groupList = list
            this.groupList.forEach(group => {
              if (group.worker_list.length) {
                group.show = true
                group.worker_list.forEach(worker => {
                  worker.orderID = 100
                  this.workerMap[worker.innerid] = worker
                  this.dataParser({ data: [worker], errcode: 0, source: 'customer_update' })
                })
              }
            })
          })
          .catch(e => console.error(e.msg || e.toString()));
        building.getFloorWorkerCount(this.$route.params.buildingID)
          .then(d => {
            this.loadPeopleByFloorChartData(d);
          })
          .catch(e => console.error(e.msg || e.toString()));
        building.getdWorkTypeForBuild(this.$route.params.buildingID)
          .then(d => {
            this.$refs.workerStatusChart && this.$refs.workerStatusChart.clear();
            this.loadWorkTypeChartData(d);
          })
          .catch(e => console.error(e.msg || e.toString()));
        building.getWorkTypePerFloor(this.$route.params.buildingID)
          .then(d => {
            this.loadWorkerTypePerFloorChartData(d);
          })
          .catch(e => console.error(e.msg || e.toString()));
      },
      changeBuilding (building) {
        if (this.building.currentBuildingID === building.innerid) return
        this.$router.replace({ path: `/building/${building.innerid}/${this.$route.params.projectID}?token=${this.$route.query.token}&project=${this.$route.query.project}&nav=${this.$route.query.nav}&sub=${this.$route.query.sub}&from_org=${this.fromOrg}&use3d=${window.use3D}` });
        this.building.currentBuildingID = building.innerid;
        this.updateData();
        this.changeFloor(this.building.buildingList.find(b => b.innerid === this.building.currentBuildingID).floor[0]);
      },
      conn () {
        const self = this
        let lockReconnect = false

        function createWebSocket (url) {
          try {
            self.ws = new WebSocket(url)
            self.ws.id = self.$route.params.projectID
            initEventHandle()
          } catch (e) {
            reconnect(url)
          }
        }

        function initEventHandle () {
          self.ws.onclose = function () {
            reconnect(wsUrl)
          }
          self.ws.onerror = function () {
            reconnect(wsUrl)
          }
          self.ws.onopen = function () {
            self.ws.send(JSON.stringify({
              'action': 'init',
              'values': { 'project_id': self.$route.params.projectID }
            }))
          }
          self.ws.onmessage = function (e) {
            try {
              const message = JSON.parse(e.data)
              self.dataParser(message)
            } catch (e) {
              if (e.message === 'Cannot read property \'contentWindow\' of undefined') {
                if (!self.beingDestroyed) {
                  location.reload();
                }
                return;
              } else {
                console.warn(e.message)
                // self.$message.error('服务器返回数据错误')
              }
            }
          }
        }

        function reconnect (url) {
          if (lockReconnect) return
          lockReconnect = true
          setTimeout(function () {
            createWebSocket(url)
            lockReconnect = false
          }, 2000)
        }

        createWebSocket(wsUrl)
      },
      toggleGroup (group) {
        group.show = !group.show
        this.$forceUpdate()
      },
      batteryMap (number) {
        if (number * 1 <= 20) {
          return 'red2'
        } else if (number * 1 > 20 && number * 1 < 60) {
          return 'yellow2'
        } else {
          return 'green'
        }
      },
      changeFloor (floor) {
        if (this.curFloorList === floor.ids) return
        this.curFloorList = floor.ids
        building.getBuildingFloorData(floor.ids)
          .then(data => {
            const floorNameArr = []
            const floorDataArr = []
            const floorIDArr = []
            data.forEach(d => {
              floorDataArr.push(d.map_data)
              floorNameArr.push(d.cname)
              floorIDArr.push(d.innerid)
            })
            const workerPosition = Object.values(this.workerMap).filter(w => this.curFloorList.filter(id => id === w.floor).length).map(w => ({
              id: w.innerid,
              name: w.name,
              floor: w.floor_name,
              floorID: w.floor,
              position: { x: w.x / mapRatio, y: w.y / mapRatio },
              color: workerOnThreeColorMap[w.color || ''],
              group: w.worker_type || '',
              status_id: w.status_id,
              hat_code: w.hat_code || '',
              mobile: w.mobile,
            }))

            this.$refs.frame.contentWindow.postMessage({ type: 'addUser', data: workerPosition }, '*')
            this.$refs.frame.contentWindow.postMessage({ type: 'floorData', data: { floorNameArr, floorDataArr, floorIDArr } }, '*')
            this.$refs.frame.contentWindow.postMessage({
              type: 'addClickListener',
              data: `<div style="text-align: center;">
                      <div>\${this.name}(\${this.hat_code})
                      <br/>\${this.group}
                      <br/>\${this.mobile}</div>
                      <button style='background-color:#399A75;border-radius: 4px;border: none;color: #fff;font-size:13px;line-height:18px;padding-bottom:3px;
                      cursor: pointer;outline: none;margin-top: 4px;' id='showPath' onclick="showPathClick('\${this.id}', '\${this.name}')">轨迹</button>`
            }, '*');
          })
      },
      retrieveAll () {
        this.showRecallDialog = false
        if (this.ws) {
          this.ws.send(JSON.stringify({ action: 'retreat', values: {project_id: this.ws.id}}))
          this.showPrompt = false
        }
      },
      callBack() {
        // console.log('点击了召回');
        this.showCallBackDialog = false;
        this.callBackFunc && this.callBackFunc();
      },
      prepareRecall(worker) {
        this.callBackFunc = () => { this.recall(worker); };
        this.callBackPerson = worker.name;
        this.showCallBackDialog = true;
      },
      recall (worker) {
        if (worker.recalled !== 11) return
        if (worker.hat_id === 'null' || worker.hat_id === null) return this.$message.warning('用户帽子id不正确')
        if (this.ws) {
          this.ws.send(JSON.stringify({
            action: 'recall',
            values: { hat_id: worker.hat_id, ctrl_mac: worker.ctrl_mac, ch: worker.ch }
          }))
        }
      },
      workerStatusChartMouseOver(params) {
        if (params.dataIndex != this.workerStatusChartHighlightIndex) {
          this.$refs.workerStatusChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.workerStatusChartHighlightIndex
          });
          this.workerStatusChartHighlightIndex = params.dataIndex;
        }
      },
      workerStatusChartMouseOut() {
        this.$refs.workerStatusChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.workerStatusChartHighlightIndex
        });
      },
    },
    beforeDestroy () {
      this.beingDestroyed = true;
      clearInterval(this.timer);
    },
  }
</script>

<style scoped lang="less">
  .text-green {
    color: #1fc86b !important;
  }

  .text-yellow1 {
    color: #F89132 !important;
  }

  .text-yellow2 {
    color: #eebc34 !important;
  }

  .text-red1 {
    color: #e62c31 !important;
  }

  .text-red2 {
    color: #f34e53 !important;
  }

  .text-grey {
    color: #9ca0aa !important;
  }

  .borderColor-green {
    border-color: #1fc86b !important;
  }

  .borderColor-grey {
    border-color: #9ca0aa !important;
  }

  .borderColor-yellow1 {
    border-color: #F89132 !important;
  }

  .borderColor-yellow2 {
    border-color: #eebc34 !important;
  }

  .borderColor-red1 {
    border-color: #e62c31 !important;
  }

  .borderColor-red2 {
    border-color: #f34e53 !important;
  }

  .stats-close-btn {
    position: absolute;
    background-image: url("../../assets/close.png");
    width: 36px;
    height: 36px;
    top: -18px;
    right: -18px;
    cursor: pointer;
    z-index: 30000;
  }

  #main {
    /*perspective: 100px;*/
    display: flex;
    padding: 1.5rem 1.875rem 2rem 1.875rem;
    height: calc(100vh - 2rem);
    overflow-y: scroll;

    #left {
      width: 20rem;

      #group-container {
        margin-bottom: 1.5rem;

        #retreat-btn {
          margin: 1rem 0;
          height: 1.875rem;
          text-align: center;
          line-height: 1.875rem;
          cursor: pointer;
          background: linear-gradient(to right, #51bbf5, #5170f5);
        }

        .group {
          height: 34.5rem;
          overflow-y: scroll;

          .group-item {

            .group-title {
              margin-bottom: 1rem;

              .group-title-icon {
                width: 1rem;
                height: 1rem;
                display: inline-block;
                margin-right: 0.25rem;
                cursor: pointer;
                position: relative;

                &.active {
                  transform: rotate(-90deg);
                }

                &::before {
                  content: " ";
                  position: absolute;
                  width: 1rem;
                  font-size: 1rem;
                  transform: rotate(0deg);
                  transition: transform .3s ease-in-out;
                  height: 1rem;
                  background: url("../../assets/sanjiao.png") no-repeat;
                }
              }
            }

            .people-card-list {
              .card-item {
                padding: 0.4rem 1rem;
                display: flex;
                margin-bottom: 0.75rem;
                background-color: #151C48;
                border-radius: 0.25rem;

                .left {
                  flex: 1;
                  font-size: 0.88rem;
                  line-height: 1.7rem;

                  .title {
                    color: #999999;
                  }
                }

                .right {
                  width: 4rem;
                  text-align: center;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;

                  .status-card {
                    border-width: 0.5rem 1px 1px;
                    border-style: solid;
                    border-radius: .25rem;
                    margin-bottom: 1rem;
                    padding: .7rem;

                    .zt {
                      font-size: 0.88rem;
                      color: #999;
                      padding: 3px 0;
                    }

                    .status-text {
                      padding-top: 5px;
                      font-size: 1.13rem;
                      font-weight: bold;
                    }
                  }

                  .op {
                    width: 100%;
                    height: 1.5rem;
                    background: linear-gradient(90deg, rgba(81, 189, 245, 1), rgba(81, 112, 245, 1));
                    line-height: 1.5rem;
                    cursor: pointer;
                    font-size: 0.75rem;
                    text-align: center;

                    &.disabled {
                      background: #8a8a8a;
                      cursor: not-allowed;
                    }
                  }
                }
              }
            }
          }
        }
      }

      #people-by-status {
        .chart {
          height: 16rem;
        }
      }

    }

    #middle {
      flex: 1;
      display: flex;
      flex-direction: column;

      .iframe {
        flex: 1;
      }

      .floor-changer {
        margin: 1rem 0 0 1.5rem;
        display: flex;
        position: relative;

        .button {
          background: url("../../assets/qiehuan.png") no-repeat;
          width: 4.5rem;
          height: 2rem;
          cursor: pointer;
          line-height: 1.9rem;
          background-size: cover;
          text-align: center;
          font-size: 0.8rem;
          margin-right: .5rem;
        }

        .floor-list {
          border: 0.125rem solid #51bbf5;
          line-height: 1.9rem;
          font-size: 0.75rem;
          padding: 0 0.625rem;
          position: absolute;
          left: 10rem;
          width: calc(100% - 12rem);

          span {
            color: #a5a0b1;
            cursor: pointer;
            margin-right: 0.75rem;

            &.active {
              color: #5accf1;
            }
          }
        }
      }

      .project-link {
        position: fixed;
        left: 25rem;
        top: 4rem;
        width: 9rem;

        a {
          font-size: 1.1em;
          color: #5accf1;
          text-decoration: none;
          border: 1px solid #5accf1;
          padding: 1px 12px;
          border-radius: 20px;
        }
      }

      .building-changer {
        position: fixed;
        left: 25rem;
        bottom: 4rem;
        display: flex;
        width: 9rem;
        align-items: center;
        justify-content: space-between;
      }
    }

    #right {
      width: 20rem;

      #people-by-floor {
        margin-top: 1.5rem;

        .chart {
          height: 18rem;
          overflow-y: scroll;
        }
      }

      #per-floor-worker-types {
        .list-table {
          height: 36.5rem;
          overflow-y: scroll;
        }
      }

    }
  }

  #logo {
    position: absolute;
    right: 2rem;
    bottom: 1.5rem;
    display: flex;
    align-items: center;
    line-height: 2rem;

    span {
      font-size: 1.125rem;
      color: rgba(255, 255, 255, .6);
      margin-right: .5rem;
    }
  }
</style>
<style>
.stats-layer .el-dialog__header {
  display: none;
}
.stats-layer .el-dialog__body {
  padding: 0;
  color: unset;
}
</style>
