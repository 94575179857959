<template>
  <div id="right">
    <wrapper style="padding: 0;margin-bottom: 1rem;">
      <div
        class="info-block"
        style="min-height:5rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>劳务安全帽无电人员名单</div>
          <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              style="min-width: 3.5rem;"
              :class="{ 'option-block-selected2': mode1 == 2 }"
              @click="getLaborAttendanceList(2)"
            >近七日</div>
            <div
              class="option-block2"
              style="min-width: 3.5rem;"
              :class="{ 'option-block-selected2': mode1 == 1 }"
              @click="getLaborAttendanceList(1)"
            >今日</div>
            <div
              class="detail"
              style="margin-left: 0.5rem;"
              @click="showDetail(1)"
            >
              详情
            </div>
          </div>

        </div>
        <div style="padding-top: 3rem; font-size:0.875rem; font-weight: 500;height: 16rem;">
          <div v-if="laborNeWorker.length>0">
            <div style="display: flex;  height: 1.4rem; align-items: center; justify-content: space-between; padding:0 1rem;  margin-bottom: 3px;">
              <div style="width:3rem;text-align:center">姓名</div>
              <div style="width:10rem;text-align:center">单位</div>
              <div style="width:6rem;text-align:center">班组</div>
              <div style="width:6rem;text-align:center">电话</div>
            </div>
            <div style="height:10.6rem;overflow:auto;">
              <div
                v-for="(item, index) in laborNeWorker"
                :key="item.name"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color: #42a9da;"
                :style="{ background: index%2 ? '#1b2d5d' : '#152554'}"
              >
                <div style="width:3rem;text-align:center">{{item.name}}</div>
                <div
                  style="width:10rem;text-align:center"
                  :title="item.company_name"
                >{{item.company_name.length>8?item.company_name.slice(0,8)+'...':item.company_name}}</div>
                <div style="width:6rem;text-align:center">{{item.team_name}}</div>
                <div style="width:6rem;text-align:center">{{item.mobile}}</div>
              </div>
            </div>
          </div>
          <div
            v-if="!laborNeWorker.length"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
        </div>
      </div>
    </wrapper>
    <wrapper style="padding: 0;margin-bottom: 1rem;">
      <div
        class="info-block"
        style="min-height:5rem"
      >
        <div
          class="info-block-title"
          style="display: flex;align-items:center;width: 85%;justify-content: space-between;"
        >
          <div>劳务人员安全帽无电情况</div>
          <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              style="min-width: 3.5rem;"
              :class="{ 'option-block-selected2': mode2 ==2 }"
              @click="() => getlaborHatNoEleChart(2)"
            >近七日</div>
            <div
              class="option-block2"
              style="min-width: 3.5rem;"
              :class="{ 'option-block-selected2': mode2 ==1 }"
              @click="() => getlaborHatNoEleChart(1)"
            >今日</div>
            <div
              class="detail"
              style="margin-left: 0.5rem;"
              @click="showDetail(2)"
            >
              详情
            </div>
          </div>
        </div>
        <div style="height:20rem;padding-top:3rem">
          <div
            v-if="laborHatNoEleChart.series && [].concat.apply([], laborHatNoEleChart.series.map(x => x.data)).length == 0"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
          <v-chart
            v-else
            :options="laborHatNoEleChart"
            autoresize
          />
        </div>

      </div>
    </wrapper>
    <wrapper style="padding: 0;">
      <div
        class="info-block"
        style="min-height:5rem;margin-bottom:1rem"
      >
        <div
          class="info-block-title"
          style="display:flex;align-items:center;justify-content: space-between;width: 85%;"
        >
          <div>管理安全帽无电人员名单</div>
          <div style="display:flex;align-items:center;">
            <div
              class="option-block2"
              style="min-width: 3.5rem;"
              :class="{ 'option-block-selected2': mode3 == 2 }"
              @click="() => getManagerHatNoEleList(2)"
            >近七日</div>
            <div
              class="option-block2"
              style="min-width: 3.5rem;"
              :class="{ 'option-block-selected2': mode3 == 1 }"
              @click="() => getManagerHatNoEleList(1)"
            >今日</div>
            <div
              class="detail"
              style="margin-left: 0.5rem;"
              @click="showDetail(3)"
            >
              详情
            </div>
          </div>
        </div>
        <div style="padding-top: 3rem; font-size:0.875rem; font-weight: 500;height: 16rem;">
          <div v-if="managerHatNoEleList.length>0">
            <div style="display: flex;  height: 1.4rem; align-items: center; justify-content: space-between; padding:0 1rem;  margin-bottom: 3px;">
              <div style="width:3rem;text-align:center">姓名</div>
              <div style="width:6rem;text-align:center">部门</div>
              <div style="width:6rem;text-align:center">电话</div>
            </div>
            <div style="height:10.6rem;overflow:auto;">
              <div
                v-for="(item, index) in managerHatNoEleList"
                :key="item.name"
                style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color: #42a9da;"
                :style="{ background: index%2 ? '#1b2d5d' : '#152554'}"
              >
                <div style="width:3rem;text-align:center">{{item.name}}</div>
                <div style="width:6rem;text-align:center">{{item.department_name}}</div>
                <div style="width:6rem;text-align:center">{{item.mobile}}</div>
              </div>
            </div>
          </div>
          <div
            v-if="!managerHatNoEleList.length"
            style="display:flex;flex-direction:column;align-items:center;justify-content:center; height: 12rem;margin-top: 0.5rem;"
          >
            <div class="empty-chart-icon" />
            <div style="margin-top:20px;color:#ccc">暂无数据</div>
          </div>
        </div>
      </div>
    </wrapper>

    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow1"
      width="60rem"
      top="12rem"
      :destroy-on-close='true'
    >
      <wrapper
        class="popup-bg"
        style="height:32rem;backgroundColor:#0e1328;position:relative"
      >
        <div
          class="stats-close-btn"
          @click="popupShow1 = false"
        />
        <div class="attendance">
          <div style="display:flex;justify-content: space-between; margin: 1rem;">
            <div style="min-width:7rem"></div>
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">劳务安全帽无电人员名单
            </div>
            <div style="display:flex;align-items:center;">
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode4 == 2 }"
                @click=" getpopData1(2)"
              >近七日</div>
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode4 == 1 }"
                @click="getpopData1(1)"
              >今日</div>
            </div>

          </div>
          <div style="padding-top: 1rem; font-size:0.875rem; font-weight: 500;height: 37rem;">
            <div v-if="laborNeWorkerDetailList.length>0">
              <div style="display: flex;  height: 1.4rem; align-items: center; justify-content: space-between; padding:0 1rem;  margin-bottom: 3px;">
                <div style="width:3rem;text-align:center">姓名</div>
                <div style="min-width:15rem;text-align:center">单位</div>
                <div style="width:6rem;text-align:center">班组</div>
                <div style="width:6rem;text-align:center">电话</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in laborNeWorkerDetailList"
                  :key="item.name"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color: #42a9da;"
                  :style="{ background: index%2 ? '#1b2d5d' : '#152554'}"
                >
                  <div style="width:3rem;text-align:center">{{item.name}}</div>
                  <div style="min-width:15rem;text-align:center">{{item.company_name}}</div>
                  <div style="width:6rem;text-align:center">{{item.team_name}}</div>
                  <div style="width:6rem;text-align:center">{{item.mobile}}</div>
                </div>
              </div>
              <el-pagination
                background
                layout="prev, pager, next"
                :current-page.sync="securityStarPage"
                :page-count="securityStarPages"
                @current-change="securityStarPageChange"
                style="text-align:center;margin-top:0.5rem"
              ></el-pagination>
            </div>
            <div
              v-if="!laborNeWorkerDetailList.length"
              style="display:flex;flex-direction:column;align-items:center;justify-content:center; height: 16rem;margin-top: 0.5rem;"
            >
              <div class="empty-chart-icon" />
              <div style="margin-top:20px;color:#ccc">暂无数据</div>
            </div>
          </div>
        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow2"
      width="60rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:42rem;backgroundColor:#0e1328;position:relative"
      >
        <div
          class="stats-close-btn"
          @click="popupShow2 = false"
        />
        <div class="attendance">
          <div style="display:flex;justify-content: space-between; margin: 1rem;">
            <div style="min-width:7rem"></div>
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">劳务人员安全帽无电情况
            </div>
            <div style="display:flex;align-items:center;">
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode5 == 2 }"
                @click="() => getPopData2(2)"
              >近七日</div>
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode5 == 1 }"
                @click="() => getPopData2(1)"
              >今日</div>
            </div>

          </div>

          <div style="margin:1.5rem 1rem">
            <div style="height:37rem;padding-top:1rem">
              <div
                v-if="laborHatNoEleDetailChart.series && [].concat.apply([], laborHatNoEleDetailChart.series.map(x => x.data)).length == 0"
                style="display:flex;flex-direction:column;align-items:center;justify-content:center; height: 30rem;margin-top: 0.5rem;"
              >
                <div class="empty-chart-icon" />
                <div style="margin-top:20px;color:#ccc">暂无数据</div>
              </div>
              <v-chart
                v-else
                :options="laborHatNoEleDetailChart"
                autoresize
              />
            </div>
          </div>

        </div>
      </wrapper>
    </el-dialog>
    <el-dialog
      custom-class="stats-layer"
      :visible.sync="popupShow3"
      width="60rem"
      top="12rem"
    >
      <wrapper
        class="popup-bg"
        style="height:32rem;backgroundColor:#0e1328;position:relative"
      >
        <div
          class="stats-close-btn"
          @click="popupShow3 = false"
        />
        <div class="attendance">
          <div style="display:flex;justify-content: space-between; margin: 1rem;">
            <div style="min-width:7rem"></div>
            <div style="margin:0 1rem 0 1rem;font-size:18px;font-weight:bold">管理人员安全帽无电情况
            </div>
            <div style="display:flex;align-items:center;">
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode6 == 2 }"
                @click="() => getPopData3(2)"
              >近七日</div>
              <div
                class="option-block2"
                style="min-width: 3.5rem;"
                :class="{ 'option-block-selected2': mode6 == 1 }"
                @click="() => getPopData3(1)"
              >今日</div>
            </div>

          </div>

          <div style="padding-top: 1rem; font-size:0.875rem; font-weight: 500;height: 16rem;">
            <div v-if="managerHatNoEleDetailList.length>0">
              <div style="display: flex;  height: 1.4rem; align-items: center; justify-content: space-between; padding:0 1rem;  margin-bottom: 3px;">
                <div style="width:3rem;text-align:center">姓名</div>
                <div style="width:6rem;text-align:center">部门</div>
                <div style="width:6rem;text-align:center">电话</div>
              </div>
              <div style="height:20rem;overflow:auto;">
                <div
                  v-for="(item, index) in managerHatNoEleDetailList"
                  :key="item.name"
                  style="display:flex; height: 2rem; align-items: center; justify-content: space-between; padding:0 1rem;color: #42a9da;"
                  :style="{ background: index%2 ? '#1b2d5d' : '#152554'}"
                >
                  <div style="width:3rem;text-align:center">{{item.name}}</div>
                  <div style="width:6rem;text-align:center">{{item.department_name}}</div>
                  <div style="width:6rem;text-align:center">{{item.mobile}}</div>
                </div>
              </div>
              <el-pagination
                background
                layout="prev, pager, next"
                :current-page.sync="securityStarPage2"
                :page-count="securityStarPages2"
                @current-change="securityStarPageChange2"
                style="text-align:center;margin-top:0.5rem"
              ></el-pagination>
            </div>
            <div
              v-if="!managerHatNoEleDetailList.length"
              style="display:flex;flex-direction:column;align-items:center;justify-content:center; height: 16rem;margin-top: 0.5rem;"
            >
              <div class="empty-chart-icon" />
              <div style="margin-top:20px;color:#ccc">暂无数据</div>
            </div>
          </div>

        </div>
      </wrapper>
    </el-dialog>

  </div>
</template>

<script>
require("echarts-gl");
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { project } from "../../api";
// import CustomSelect from "../../components/select";
// import { popupChartTemplate, colors } from "./pieChartTemplates";
import { SimpleBarChartTemplate } from "./barChartTemplates";
import { applyUpdate, truncateStr } from "../../utils";
import wrapper from "../../components/wrap";

export default {
  name: "SecurityCheckLeftBar",
  components: {
    "v-chart": ECharts,
    wrapper,
    // CustomSelect: CustomSelect,
  },
  props: {
    districtLevel: String,
    orgLevel: String,
    districtScale: Number,
    orgType: Number,
    orgOptions: Array,
    gotoProject: Function,
  },
  data() {
    return {
      mapLevel: 0,
      org_id: "",
      companyNameList: [""],
      companyIdList: [""],
      selectVal1: "",
      selectVal0: "",
      // 图表1
      mode1: 2,
      laborNeWorker: [],

      // 图表2
      mode2: 2,
      laborHatNoEleChart: {},
      option2: {
        color: ["#FF8645", "#23CF9E", "#20bbee"],
        grid: {
          left: "8%",
          right: "5%",
          top: "24%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data;
              if (params[1]) {
                result +=
                  "</br>" + " " + params[1].seriesName + " : " + params[1].data;
              }
              if (params[2]) {
                result +=
                  "</br>" + " " + params[2].seriesName + " : " + params[2].data;
              }
            }
            return result;
          },
        },
        legend: {
          data: [],
        },
        xAxis: {
          axisLabel: {
            color: "#e6f2f3",
            rotate: 40,
            fontWeight: "bold",
            formatter: (value) => truncateStr(value, 10),
          },
          show: true,
          axisLine: { show: true, lineStyle: { color: "#36445f" } },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#36445f",
              width: 1,
              type: "solid",
            },
          },
          axisTick: { show: false },
          type: "category",
          data: [],
        },
        yAxis: [
          {
            type: "value",
            show: true,
            name: "数量",
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#36445f" },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#36445f",
                type: [1, 1],
                width: 1,
              },
            },
            axisTick: { show: false },
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
          },
          {
            min: 0,
            max: 100,
            name: "百分率",
            interval: 25,
            nameTextStyle: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            boundaryGap: [0, "20%"],
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
            type: "value",
          },
        ],
        dataZoom: [],
        series: [
          {
            type: "bar",
            barMaxWidth: 15,
            barGap: 0,
            stack: "Ad",
            data: [],
          },
          {
            type: "bar",
            barMaxWidth: 15,
            data: [],
          },
          {
            type: "line",
            yAxisIndex: 1,
            data: [],
          },
        ],
      },
      // 图表3
      mode3: 2,
      managerHatNoEleList: [],

      // 弹窗1
      mode4: 2,
      popupShow1: false,
      laborNeWorkerDetailList: [],
      securityStarPage: 1,
      securityStarPages: 1,

      // 弹窗2
      mode5: 2,
      popupShow2: false,
      laborHatNoEleDetailChart: {},
      option5: {
        color: ["#2662f9", "#4ab27c", "#ff9308", "#ecd61d"],
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var result = ``;
            if (params && params.length) {
              result +=
                `${params[0].name}` +
                "</br>" +
                " " +
                params[0].seriesName +
                " : " +
                params[0].data;
              if (params[1]) {
                result +=
                  "</br>" + " " + params[1].seriesName + " : " + params[1].data;
              }
              if (params[2]) {
                result +=
                  "</br>" + " " + params[2].seriesName + " : " + params[2].data;
              }
            }
            return result;
          },
        },
        legend: {
          data: [],
        },
        grid: {
          left: "3%",
          top: "8%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside", // 放大和缩小
            orient: "vertical",
            maxValueSpan: 5,
            minValueSpan: 5,
          },
        ],
        xAxis: [
          {
            name: "数量",
            type: "value",
            position: "top",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            //   splitLine: { show: false },
            axisTick: { show: false },
          },
          {
            name: "百分率:%",
            min: 0,
            max: 100,
            type: "value",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            nameTextStyle: {
              padding: [0, 40, 0, 0],
              color: "#e6f2f3",
              fontWeight: "bold",
            },
            axisLine: { show: true, lineStyle: { color: "#36445f" } },
            splitLine: { show: false },
            axisTick: { show: false },
          },
        ],
        yAxis: [
          {
            type: "category",
            axisLabel: {
              color: "#e6f2f3",
              fontWeight: "bold",
              formatter: (value) => truncateStr(value, 15),
            },
            axisTick: {
              show: false,
            },
            data: [],
          },
        ],
        series: [
          {
            xAxisIndex: 0,
            name: "",
            type: "bar",
            barMaxWidth: 15,
            barGap: 0,
            data: [],
          },
          {
            xAxisIndex: 0,
            name: "",
            type: "bar",
            barMaxWidth: 15,
            data: [],
          },
          {
            name: "",
            xAxisIndex: 1,
            data: [],
            type: "line",
          },
        ],
      },

      // 弹窗3
      mode6: 2,
      popupShow3: false,
      managerHatNoEleDetailList: [],
      securityStarPage2: 1,
      securityStarPages2: 1,
    };
  },
  watch: {
    orgOptions(newValue, oldValue) {
      if (newValue != oldValue) {
        this.companyNameList = newValue.map((item) =>
          truncateStr(item.org_name, 10)
        );
        this.companyIdList = newValue.map((item) => item.org_id);

        let idx = this.companyIdList.indexOf(this.orgLevel);
        if (idx == -1) {
          idx = 0;
        }
      }
    },
    orgLevel: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.org_id = this.orgLevel;
        this.getAll();
      }
    },
    districtLevel: function (newValue) {
      // console.log(newValue)
      if (newValue) {
        if (newValue == "100000") {
          this.mapLevel = 0;
        }
        this.getAll();
      }
    },
    districtScale: function (newValue) {
      // console.log(newValue,oldValue)
      if (newValue) {
        this.mapLevel = newValue;
      }
    },
  },
  mounted() {
    const token = this.$route.query.token;
    window.token = token;
    this.getAll();
  },
  methods: {
    showDetail(val) {
      if (val === 1) {
        this.popupShow1 = true;
        this.getpopData1(2);
      } else if (val === 2) {
        this.popupShow2 = true;
        this.getPopData2(2);
      } else if (val === 3) {
        this.popupShow3 = true;
        this.getPopData3(2);
        //
      }
    },
    // 获取页面图表左边三个
    getAll() {
      this.getLaborAttendanceList(2);
      this.getlaborHatNoEleChart(2);
      this.getManagerHatNoEleList(2);
    },
    getLaborAttendanceList(mode) {
      this.mode1 = mode;
      project.getProjHatNeWorkerList(this.mode1, 1, 10).then((d) => {
        this.laborNeWorker = d;
      });
    },
    getlaborHatNoEleChart(mode) {
      this.mode2 = mode;
      project.getHatNeWorkerCollect(2, "", this.mode2).then((d) => {
        // this.troubleTypeAllSearchKey = d.search_keys;
        let chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
        this.option2.legend.data = d.legend;
        this.option2.xAxis.data = d.xAxis_data;
        this.option2.series[0].name = d.legend[1];
        this.option2.series[0].data = d.bar_series[0];
        this.option2.series[1].name = d.legend[2];
        this.option2.series[1].data = d.bar_series[1];
        this.option2.series[2].name = d.legend[0];
        this.option2.series[2].data = d.line_series;

        this.laborHatNoEleChart = applyUpdate(chart, this.option2);
      });
    },
    getManagerHatNoEleList(mode) {
      this.mode3 = mode;
      project.getProjHatNeManagerList(this.mode3, 1, 10).then((d) => {
        console.log(d, "dddd");
        this.managerHatNoEleList = d;
      });
    },

    // 弹窗1

    getpopData1(mode) {
      this.mode4 = mode;
      this.securityStarPages = 1;
      this.securityStarPageChange(1);
    },
    securityStarPageChange(currPage) {
      project
        .getProjHatNeWorkerList(this.mode4, currPage, 10)
        .then((d) => {
          this.laborNeWorkerDetailList = d;
          this.securityStarPages = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },

    // 弹窗2
    getPopData2(mode) {
      this.mode5 = mode;
      project.getHatNeWorkerCollect(1, "", this.mode5).then((d) => {
        console.log(d, "10000000000000000000000008888888");
        let chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));
        this.option5.legend.data = d.legend;
        this.option5.yAxis[0].data = d.xAxis_data;
        this.option5.series[0].name = d.legend[1];
        this.option5.series[0].data = d.bar_series[0];
        this.option5.series[1].name = d.legend[2];
        this.option5.series[1].data = d.bar_series[1];
        this.option5.series[2].name = d.legend[0];
        this.option5.series[2].data = d.line_series;

        this.laborHatNoEleDetailChart = applyUpdate(chart, this.option5);
      });
    },

    // 弹窗3
    getPopData3(mode) {
      this.mode6 = mode;
      this.securityStarPages2 = 1;
      this.securityStarPageChange2(1);
    },
    securityStarPageChange2(currPage) {
      project
        .getProjHatNeManagerList(this.mode6, currPage, 10)
        .then((d) => {
          this.managerHatNoEleDetailList = d;
          this.securityStarPages2 = Math.ceil(d.__count__ / 10);
        })
        // eslint-disable-next-line
        .catch((e) => console.error(e.msg || e.message));
    },
  },
};
</script>

<style scoped lang="less">
.mon-change {
  display: flex;
  margin: 1rem;
  justify-content: space-between;
  align-items: center;
}
.mon-item {
  padding: 0.5rem;
  border: 1px solid #52b3ff;
  border-radius: 4px;
}
.active-mon {
  background-color: #52b3ff;
}
.img-left {
  position: absolute;
  top: 5rem;
  left: 3rem;
  width: 3rem;
  z-index: 9;
  cursor: pointer;
}
.img-right {
  position: absolute;
  top: 5rem;
  right: 3rem;
  width: 3rem;
  z-index: 9;
  cursor: pointer;
}
.img {
  width: 2.5rem;
  cursor: pointer;
}
.attendance-bottom {
  margin: 0 2rem;
}
.tooltips {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  // margin-bottom: 1rem;
  font-size: 0.6rem;
  color: #abbbbf;
  font-weight: bold;
}
.items {
  display: flex;
  align-items: center;
}
.tooltip-item {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.3rem;
}
.tooltip-item1 {
  background-color: #23cf9e;
}
.tooltip-item2 {
  background-color: #eab91e;
}
.tooltip-item3 {
  background-color: #e02f37;
}
.linebar {
  width: 100%;
}
.line-bar {
  display: flex;
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #4ebb8c;
  border-radius: 12px;
}
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
</style>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  z-index: 99 !important;
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>