<template>
  <div class="info-block" style="min-height:5rem">
    <div class="info-block-title">项目策划月内及时率</div>
    <div style="height:19rem;padding-top:3rem">
      <div
        v-if="!projectPlanRateChart.series || !projectPlanRateChart.series[0].data.length"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart v-else :options="projectPlanRateChart" autoresize />
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { stackedBarChartTemplate } from '../../barChartTemplates';
  import { applyUpdate } from "../../../../utils";
import { projectMgr } from '../../../../api_projectMgr'

  export default {
    name: 'ProjectPlanCompletionRateChart',
    components: {
      'v-chart': ECharts
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
    watch: {
      orgOptions(newValue, oldValue) {
        if (newValue != oldValue) {
           this.updateProjectPlanRateChart();
        }
      },
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateProjectPlanRateChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateProjectPlanRateChart();
        }
      }
    },
    data () {
      return {
        projectPlanRateChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.updateProjectPlanRateChart();
    },
    methods: {
      updateProjectPlanRateChart() {
        projectMgr.queryProjPlanIntimeRate()
          .then((d) => {
            const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
            const option = {
              color: ["#46A0FF", "#FFED21", "#FF8521", "rgba(0,0,0,0)"],
              legend: {
              },
              grid: {
                bottom: "5%"
              },
              xAxis: {
                show:true,
                type: "category",
                axisLabel: {
                  color: "#e6f2f3",
                    show:true,
                    lineStyle: {
                        color: "#rgb(77,83,141)",
                    },
                    rotate: 45,
                    fontWeight: "normal",
                },
                axisLine: { show: false, lineStyle: { color: '#36445f' } },
                splitLine: { show: false },
                axisTick: { show: false },
                data:[]
              },
              yAxis: [
                {
                  nameTextStyle: {
                    color: "#ccc",
                    align: "right",
                  },
                  type: "value",
                  axisLabel: {
                    color: "#ffffff"
                  },
                  axisLine: { show: false, lineStyle: { color: "#36445f" } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                  boundaryGap: [0, '20%'],
                },
              ],
              series: [
                {
                  name: "项目策划月内及时率",
                  type: "line",
                  data:[]
                }
              ],
            };
            for (let i = 0; i < d.length; i++) {
              option.xAxis.data.push(d[i].branchName);              
              option.series[0].data.push(d[i].value);              
            }
            this.projectPlanRateChart = applyUpdate(chart, option);
          })
          .catch((e) => console.error(e.msg || e.message));
      }
    },
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>
