<template>
<div id="left">
    <div class="info-block" style="min-height:5rem">
      <div
        class="info-block-title">
        <div>管理人员情况</div>
      </div>
        <div
          style="display:flex;justify-content:flex-end"
        >
            <CustomSelect
              :options="yearList"
              :value="selectedYear"
              @input="categorySelect"
              style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
            />
      </div>
      
      <div style="height:19rem;padding-top:1rem">
        <div
          style="display:flex;justify-content:space-around;margin-bottom:-0.8rem"
        >
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '1' }"
            @click="() => switchCompanyWorkersOption('1')">
            管理序列分析
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '2' }"
            @click="() => switchCompanyWorkersOption('2')">
            年龄分析
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '3' }"
            @click="() => switchCompanyWorkersOption('3')">
            学历分析
          </div>
          <div
            class="option-block"
            :class="{ 'option-block-selected': companyWorkersOption == '4' }"
            @click="() => switchCompanyWorkersOption('4')">
            任职情况
          </div>
        </div>
        <div
          v-if="companyWorkersPieChart.series && [].concat.apply([], companyWorkersPieChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="companyWorkersPieChart" @click="companyWorkersPieChartClick" autoresize/>
      </div>
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { human } from '../../../../api_hr'
  import { colors } from '../../pieChartTemplates';
 import CustomSelect from '../../../../components/select'
  // import { truncateStr } from "../../../../utils";
// import func from 'vue-editor-bridge'

  export default {
    name: 'managerStatucBarChart',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          // this.companyNameList = newValue.map(item => truncateStr(item.org_name, 10));
          // this.companyIdList = newValue.map(item => item.org_id);
          // this.sFidList = this.orgOptions.map(item => item.sfid);
          this.getAll();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getAll();
        }
      }
    },
    data () {
      return {
        yearList: [new Date().getFullYear(),new Date().getFullYear()-1,new Date().getFullYear()-2],
        selectedYear: new Date().getFullYear(),
        companyWorkersData: {},
        companyWorkersOption: "1",
        companyWorkersPieChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;
      this.getAll();
    },
    methods: {
      getAll(){
          this.switchCompanyWorkersOption(this.companyWorkersOption);
      },
      switchCompanyWorkersOption(choose){
          this.companyWorkersOption = choose;
          switch (this.companyWorkersOption) {
              case "1":
                  this.updateManagerSequenceChart();
                  break;
              case "2":
                  this.updateMgrAgeChart();
                  break;
              case "3":
                  this.updateMgrEducationChart();
                  break;
              case "4":
                  this.updateMgrServiceChart();
                  break;
              default:
                  break;
          }
      },
      categorySelect(option) {
        this.selectedYear = option;
        this.switchCompanyWorkersOption(this.companyWorkersOption);
      },
      updateManagerSequenceChart(){
        var orgLevel;
        human.getUnitList(3).then(rs=>{
          for (let i = 0; i < rs.length; i++) {
            if(rs[i].SID  == window.$sFid){
              orgLevel = rs[i].SLEVEL;
            }
          }
          if (orgLevel == null || orgLevel == undefined)
           orgLevel = 1;
          human.getManagerSequenceAnalyse(orgLevel).then((res)=>{
            if(res.length < 1)
              return;
          // const xData = [];
          const option = {
              color:colors,
              backgroundColor:'',
              tooltip: {
                trigger: 'item',
                formatter: ' {b} : {c} ({d}%)'
              },
              title: [{
                subtext:  res[0]!=null?res[0].name:"",
                subtextStyle:{
                fontSize:13,
                color:'white'
                },
                left: '25%',
                top: '2%',
                textAlign: 'center'
            }, {
                subtext:  res[1]!=null?res[1].name:"",
                subtextStyle:{
                    fontSize:13,
              color:'white'
                },
                left: '75%',
                top: '2%',
                textAlign: 'center'
            }, {
                subtext: res[2]!=null?res[2].name:"",
                subtextStyle:{
                fontSize:13,
                color:'white'
                },
                left: '25%',
                top: '45%',
                textAlign: 'center'
            }, {
                subtext: res[3]!=null?res[3].name:"",
                subtextStyle:{
                    fontSize:13,
              color:'white'
                },
                left: '75%',
                top: '45%',
                textAlign: 'center'
            }],
            series: [{
                type: 'pie',
                radius: '25%',
                center: ['25%', '30%'],
                data:res[0]!=null?res[0].list:[],
                itemStyle : {
                normal : {
                label : {
                  show : false   //隐藏标示文字
                },
                labelLine : {
                  show : false   //隐藏标示线
                }
              }
            },
            }, {
                type: 'pie',
                radius: '25%',
                center: ['75%', '30%'],
                data:res[1]!=null?res[1].list:[],
                itemStyle : {
                normal : {
                  label : {
                    show : false   //隐藏标示文字
                  },
                labelLine : {
                  show : false   //隐藏标示线
                }
              }
            },
            }, {
                type: 'pie',
                radius: '25%',
                center: ['25%', '75%'],
                data:res[2]!=null?res[2].list:[],
                itemStyle : {
               normal : {
                label : {
                  show : false   //隐藏标示文字
                },
                labelLine : {
                  show : false   //隐藏标示线
                }
              }
            },
            }, {
                type: 'pie',
                radius: '25%',
                center: ['75%', '75%'],
                data:res[3]!=null?res[3].list:[],
                itemStyle : {
                  normal : {
                label : {
                  show : false   //隐藏标示文字
                },
                labelLine : {
                  show : false   //隐藏标示线
                }
              }
            },
          }]
          }
          this.companyWorkersPieChart = option;
          })
        })
      },
      // 管理人员年龄分析
      updateMgrAgeChart() {
        human.getManagerAgeAnalyse(this.selectedYear).then(res=>{
          const option = {
            backgroundColor: '',
              tooltip: {
                  trigger: 'item',
                  formatter: '{b}: {c} ({d}%)'
              },
              legend: {
                data: [],
                position:'inner',
                textStyle:{
                    fontSize:12,
                    color:"white"
                }
              },
              series: [
                  {
                      type: 'pie',
                      selectedMode: 'single',
                      radius: ['35%', '50%'],
                      label: {
                          position: 'inner',
                          fontSize: 12,
                          color:"white"
                      },
                      labelLine: {
                          show: false
                      },
                      data: res
                  }
              ]
          }
          this.companyWorkersPieChart = option;
        })
      },
      // 管理人员-学历分析
      updateMgrEducationChart(){
        human.getManagerEducationAnalyse(this.selectedYear).then((res) => {
             let personQuitDirectionOption = {
              backgroundColor: '',
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                  data: [],
                  position:'inner',
                  textStyle:{
                      fontSize:12,
                      color:"white"
                  }
                },
              series: [
                  {
                      name: '学历分析',
                      type: 'pie',
                      selectedMode: 'single',
                      radius: '50%',
                      label: {
                          position: 'inner',
                          fontSize: 12,
                          color:"white"
                      },
                      labelLine: {
                          show: false
                      },
                      data: res
                  }
              ]
             }
          this.companyWorkersPieChart = personQuitDirectionOption;
        })
      },
      updateMgrServiceChart(){
         human.getUnitList(3).then(rs=>{
            var level = 1;
            for (let i = 0; i < rs.length; i++) {
              if(rs[i].SID  == window.$sFid){
                level = rs[i].SLEVEL;
              }
            }
            human.getManagerServeceAnalyse(level,this.selectedYear).then(res=>{
              const option = {
                color:["#46A0FF", "#FFED21", "#FF8521", "#25F5BA"],
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  }
                },
                xAxis: {
                  type: 'category',
                  name:'人员层级',
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                    },
                  data: [],
                  axisLabel: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                    rotate: 40
                  },
                  show: true,
                  axisLine: { show: false, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                },
                yAxis: {
                  type: 'value',
                  min:0,
                  max:10,
                  interval: 1,
                  name:'人数',
                  nameTextStyle: {
                    color: '#e6f2f3',
                    fontWeight: 'bold',
                    },
                  axisLabel:{
                    color:'white'
                  },
                  axisLine: { show: true, lineStyle: { color: '#36445f' } },
                  splitLine: { show: false },
                  axisTick: { show: false },
                },
                series: [
                  {
                    name: "任职超过3年（含）以上人数",
                    type: 'bar',
                    stack: 'total',
                    emphasis: {
                      focus: 'series'
                    },
                    data:[]
                  },{
                    name: "任职超过6年（含）以上人数",
                    type: 'bar',
                    stack: 'total',
                    emphasis: {
                      focus: 'series'
                    },
                    data:[]
                  }
                ]
              }
              for (let k = 0; k < res.length; k++) {
                option.xAxis.data.push(res[k].name);
                option.series[0].data.push(res[k].subData.length>=1 ?res[k].subData[0].value:0);
                option.series[1].data.push(res[k].subData.length>1 ?res[k].subData[0].value:0);
              }
              this.companyWorkersPieChart = option;
            })  
          })
        
      },
      updatePerformanceChart(){
      },
      showDetails(param){
        console.log(param);
      },
      companyWorkersPieChartClick(){
        console.log("companyWorkersPieChartClick");
      }
    }
  }
</script>

<style lang="less">
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}

.option-block2 {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3.4rem;
  border: 1px solid #1b8ebd;
  text-align: center;
  cursor: pointer;
}
.option-block-selected{
  background-color: transparent !important;
  border: 1px solid #274C7D !important;
}
.option-block-selected2 {
  background-color: #4191e5;
  color: #fff;
}
.attendance-top{
  padding-top: 10px;
}
.tabbar{
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  border: 1px solid #157EAB;
  width: 210px;
  margin: auto;
  cursor: pointer;
}
.tabbar div{
  flex: 1;
  text-align: center;
}
.tabar-select{
  background-color: #4191E5;
  font-weight: bold;
}
.items div{
  color: #fff !important;
}
.selected{
  color: #fff !important;
}
.linebar{
  margin-top: 5rem;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto 10px;
}
.real-bar{
  height: 5px;
  background-color: #57C7B6;
  border-radius: 12px;
}
</style>

