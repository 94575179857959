<template>
  <div id="left">
    <div class="info-block" style="min-height:5rem">
      <div class="info-block-title">危大工程级别</div>
      <div style="height:20rem;padding-top:3rem">
        <div
          v-if="dangerLevelsChart.series && [].concat.apply([], dangerLevelsChart.series.map(x => x.data)).length == 0"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="dangerLevelsChart" autoresize />
      </div>
    </div>
    <div class="info-block" style="min-height:5rem">
      <div class="info-block-title">危大工程类别</div>
      <div
        class="detail-select"
        style="width:100%;display:flex;justify-content:flex-end;margin-bottom:0.5rem"
      >
        <CustomSelect
          :options="companyNameList"
          :value="companySelectedValue"
          @input="companySelect"
          style="width:10rem;line-height:1.8rem;font-size:0.8rem;font-weight:bold"
        />
      </div>
      <div style="height:17rem;position:relative">
        <div
          v-if="!dangerTypePieChart.series || !dangerTypePieChart.series[0].data.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart v-else :options="dangerTypePieChart" ref="dangerType" @mouseover="dangerTypeMouseOver" @mouseout="dangerTypeMouseOut" autoresize />
        <div style="position:absolute;top:0.5rem;left:1rem;font-size:0.8rem">数量：个</div>
      </div>
    </div>
    <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
      <div class="info-block-title" style="display:flex;width:100%;padding-right:3.1rem">
        <div style="flex:1">危大工程巡检率</div>
        <div class="detail" style="cursor:pointer">项目</div>
      </div>
      <div style="height:19rem;padding-top:3rem">
        <div
          v-if="!dangerCheckRateChart.series || !dangerCheckRateChart.series[0].data.length"
          style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
        >
          <div class="empty-chart-icon" />
          <div style="margin-top:20px;color:#ccc">暂无数据</div>
        </div>
        <v-chart :options="dangerCheckRateChart" autoresize />
      </div>
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import { nation } from '../../api'
  import CustomSelect from '../../components/select'
  import { attendancePieChartTemplate, colors } from './pieChartTemplates';
  import { stackedBarChartTemplate, SimpleBarChartTemplate } from "./barChartTemplates";
  import { applyUpdate, truncateStr } from "../../utils";

  export default {
    name: 'SecurityDangerLeftBar',
    components: {
      'v-chart': ECharts,
      'CustomSelect': CustomSelect,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
      orgOptions: Array,
    },
    watch: {
      orgOptions(newValue, oldValue) {
        if (newValue != oldValue) {
          this.companyNameList = newValue.map(item => truncateStr(item.org_name, 10));
          this.companyIdList = newValue.map(item => item.org_id);

          let idx = this.companyIdList.indexOf(this.orgLevel);
          if (idx == -1) {
            idx = 0;
          }
          this.companySelect(this.companyNameList[idx])
        }
      },
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyDangerLevelChart();
          this.updateSafetyDangerTypePieChart();
          this.updateSafetyDangerCheckRateChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          const idx = this.companyIdList.indexOf(newValue);
          if (idx >= 0) {
            this.companySelect(this.companyNameList[idx]);
          }

          this.updateSafetyDangerLevelChart();
          this.updateSafetyDangerTypePieChart();
          this.updateSafetyDangerCheckRateChart();
        }
      }
    },
    data () {
      return {
        companyNameList: [''],
        companyIdList: [''],
        companySelectedValue: '',
        dangerLevelsChart: {},
        dangerTypePieChart: {},
        dangerTypePieChartHighlightIndex: null,
        dangerCheckRateChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.companyNameList = this.orgOptions.map(item => truncateStr(item.org_name, 10));
      this.companyIdList = this.orgOptions.map(item => item.org_id);

      let idx = this.companyIdList.indexOf(this.orgLevel);
      if (idx == -1) {
        idx = 0;
      }
      this.companySelectedValue = this.companyNameList[idx];

      this.updateSafetyDangerLevelChart();
      this.updateSafetyDangerTypePieChart();
      this.updateSafetyDangerCheckRateChart();
    },
    methods: {
      updateSafetyDangerLevelChart() {
        nation.getSafetyDangerLevels()
          .then(d => {
            const chart = JSON.parse(JSON.stringify(stackedBarChartTemplate));
            const option = {
              color: ["#46A0FF", "#FFED21", "#FF8521", "rgba(0,0,0,0)"],
              legend: {
              },
              grid: {
                right: "5%",
                bottom: "5%"
              },
              xAxis: {
                type: "value",
                axisLabel: {
                  color: "#e6f2f3",
                  rotate: 0,
                  fontWeight: "normal",
                },
                splitLine: {
                  show: true,
                },
              },
              yAxis: [
                {
                  nameRotate: "",
                  nameTextStyle: {
                    color: "#ccc",
                    align: "right",
                  },
                  type: "category",
                  axisLabel: {
                    color: "#ffffff",
                    formatter: function (value) {
                      return value.length > 8 ? value.slice(0, 8) + "..." : value;
                    },
                  },
                  axisLine: { show: true, lineStyle: { color: "#36445f" } },
                  axisTick: { show: false },
                }, {
                  name: '数量：个',
                  type: 'category',
                  position: 'right',
                  offset: 0,
                  nameGap: 0,
                  nameTextStyle: {
                    color: '#fff'
                  },
                  axisLine: {
                    show: false
                  },
                  axisLabel: {
                    show: true,
                    color: '#fff'
                  }
                }
              ],
            };

            const legends = d.legend_data.length;
            option.yAxis[0].data = d.yAxis_data;
            option.yAxis[1].data = d.series[legends];
            option.series = d.series.slice(0, legends).map((item, index) => {
              const res = {
                name: d.legend_data[index],
                type: "bar",
                stack: "total",
                barMaxWidth: 18,
                emphasis: {
                  focus: "series",
                },
                data: item,
              };

              return res;
            });

            this.dangerLevelsChart = applyUpdate(chart, option);
          })
          // eslint-disable-next-line
          .catch((e) => console.error(e.msg || e.message));
      },
      updateSafetyDangerTypePieChart() {
        let orgId = '';
        const idx = this.companyNameList.indexOf(this.companySelectedValue);
        if (idx >= 0) {
          orgId = this.companyIdList[idx];
        }
        nation.getSafetyDangerTypeCounts(orgId)
          .then((d) => {
            const chart = JSON.parse(JSON.stringify(attendancePieChartTemplate));

            const maxPieces = 14;
            if (d.series_date.length > maxPieces) {
              d.series_date[maxPieces - 1] = d.series_date
                .slice(maxPieces - 1)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                );
              d.legend_date[maxPieces - 1] = "其它";
              d.legend_date = d.legend_date.slice(0, maxPieces);
              d.series_date = d.series_date.slice(0, maxPieces);
            }

            const option = {
              color: colors,
              legend: {
                show: false,
              },
              grid: {
                top: "5%",
                left: "10%",
                right: "9%",
                bottom: "5%",
                containLabel: true,
              },
              series: [
                {
                  type: "pie",
                  minAngle: 2,
                  radius: ["40%", "70%"],
                  center: ["50%", "50%"],
                  avoidLabelOverlap: true,
                  itemStyle: {
                    normal: {
                      borderColor: "#08122b",
                      borderWidth: 2,
                    },
                  },
                  label: {
                    normal: {
                      show: false,
                      position: "center",
                      //formatter: '{text|{b}}\n{c} ({d}%)',
                      //formatter: '{value|{d}%}\n{text|{b}}',
                      formatter: function (params) {
                        return `{value|${Math.round(params.percent)}%}\n{text|${
                          params.name
                        }}`;
                      },
                      padding: [20, 0, 0, 0],
                      rich: {
                        text: {
                          color: "#aaa",
                          fontSize: 12,
                          align: "center",
                          verticalAlign: "middle",
                          lineHeight: 22,
                        },
                        value: {
                          color: "#fff",
                          fontSize: 16,
                          fontWeight: "bold",
                          align: "center",
                          verticalAlign: "middle",
                        },
                      },
                    },
                    emphasis: {
                      show: true,
                      textStyle: {
                        fontSize: 14,
                      },
                    },
                  },
                },
              ],
            };

            option.series[0].data = d.series_date.map((item, idx) => ({
              name: d.legend_date[idx],
              value: item,
            }));

            if (this.dangerTypePieChartHighlightIndex != null) {
              this.$refs.dangerType && this.$refs.dangerType.dispatchAction({
                type: 'downplay',
                seriesIndex: 0,
                dataIndex: this.dangerTypePieChartHighlightIndex
              });
            }

            // 默认高亮第一个值不是0的分片，否则默认第一片
            this.dangerTypePieChartHighlightIndex = 0;
            for (let i = 0; i < d.series_date.length; i++) {
              if (option.series[0].data[i].value) {
                this.dangerTypePieChartHighlightIndex = i;
                break;
              }
            }

            option.series[1] = Object.assign({}, option.series[0], {
              label: {
                rich: {
                  text: {
                    fontSize: 11,
                  },
                },
                formatter: function (params) {
                  return `${params.name} ${Math.round(params.value)}`;
                },
              },
              silent: true,
            });

            setTimeout(
              () =>
                this.$refs.dangerType &&
                this.$refs.dangerType.dispatchAction({
                  type: "highlight",
                  seriesIndex: 0,
                  dataIndex: this.dangerTypePieChartHighlightIndex,
                }),
              100
            );
            this.dangerTypePieChart = applyUpdate(chart, option);
          })
          .catch((e) => console.error(e.msg || e.message));
      },
      updateSafetyDangerCheckRateChart() {
        nation.getSafetyDangerCheckRates()
          .then(d => {
            const chart = JSON.parse(JSON.stringify(SimpleBarChartTemplate));

            const option = {
              color: ["#2ad156"],
              tooltip: {
                trigger: "axis",
                formatter: "{b}<br>{a0}: {c0}",
              },
              grid: {
                top: "15%",
                left: "3%",
                right: "5%",
                bottom: "20%",
                containLabel: true,
              },
              xAxis: [
                {
                  type: "category",
                  data: [],
                  axisLabel: {
                    color: "#e6f2f3",
                    rotate: 45,
                    fontWeight: "normal",
                    formatter: function (value) {
                      return value.length > 8 ? value.slice(0, 8) + "..." : value;
                    },
                  },
                },
              ],
              yAxis: [
                {
                  show: true,
                  name: "巡检率：%",
                  type: "value",
                  max: 100,
                  nameTextStyle: {
                    padding: [0, 40, 0, 0],
                    color: "#e6f2f3",
                    fontWeight: "bold",
                    align: "left",
                  },
                  boundaryGap: [0, "20%"],
                  axisLabel: {
                    color: "#e6f2f3",
                    fontWeight: "bold",
                  },
                  axisLine: { show: true, lineStyle: { color: "#36445f" } },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: "#36445f",
                      width: 1,
                      type: "solid",
                    },
                  },
                  axisTick: { show: false },
                },
              ],
              series: [
                {
                  name: "巡检率",
                  type: "bar",
                  barWidth: 10,
                  barMaxWidth: 18,
                  markLine:{
                    symbol: ["none", "none"],
                    itemStyle: {
                      normal: {
                        lineStyle: {
                          type: "dashed",
                          color: "#238778",
                        },
                        label: {
                          show: false,
                          position: "middle",
                        },
                      },
                    },
                    data: [{}],
                  }
                }
              ],
            };

            option.xAxis[0].data = d.xAxis_data;
            option.series[0].data = d.series_data;
            option.series[0].markLine.data[0].yAxis = d.config.theta;
            this.dangerCheckRateChart = applyUpdate(chart, option);
          })
          // eslint-disable-next-line
          .catch(e => console.error(e.msg || e.message))
      },
      companySelect(option) {
        this.companySelectedValue = option;
        this.updateSafetyDangerTypePieChart();
      },
      dangerTypeMouseOver(params) {
        if (params.dataIndex != this.dangerTypePieChartHighlightIndex) {
          this.$refs.dangerType.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: this.dangerTypePieChartHighlightIndex
          });
          this.dangerTypePieChartHighlightIndex = params.dataIndex;
        }
      },
      dangerTypeMouseOut() {
        this.$refs.dangerType.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.dangerTypePieChartHighlightIndex
        });
      },
    },
  }
</script>

<style lang="less">
.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
</style>