<template>
  <div class="info-block" style="min-height:5rem;margin-bottom:1rem">
    <div class="info-block-title" style="display:flex;width:100%;padding-right:3.1rem">
      <div style="flex:1">生产资源配置完成率</div>
    </div>
    <div style="height:19rem;padding-top:3rem">
      <div
        v-if="!productionResourceRateChart.series || !productionResourceRateChart.series[0].data.length"
        style="display:flex;flex-direction:column;align-items:center;justify-content:center;width: 26rem; height: 12rem;margin-top: 0.5rem;"
      >
        <div class="empty-chart-icon" />
        <div style="margin-top:20px;color:#ccc">暂无数据</div>
      </div>
      <v-chart :options="productionResourceRateChart" autoresize />
    </div>
  </div>
</template>

<script>
  require('echarts-gl')
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'

  export default {
    name: 'ProductionResourceRateChart',
    components: {
      'v-chart': ECharts,
    },
    props: {
      districtLevel: String,
      districtScale: Number,
      orgLevel: String,
    },
    watch: {
      districtLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyproductionResourceRateChart();
        }
      },
      orgLevel(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateSafetyproductionResourceRateChart();
        }
      }
    },
    data () {
      return {
        productionResourceRateChart: {},
      }
    },
    mounted () {
      // const self = this
      const token = this.$route.query.token;
      window.token = token;

      this.updateSafetyproductionResourceRateChart();
    },
    methods: {
      updateSafetyproductionResourceRateChart() {
          this.productionResourceRateChart = {};
      },
    },
  }
</script>

<style lang="less">
</style>
