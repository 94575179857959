<template>
    <div class="oblongframe" :style="{'width': `${width}rem`, 'height': `${height}rem`}">
      <div class="oblongframe-bg" />
      <div class="oblongframe-bg2" />
      <div class="oblongframe-tl" />
      <div class="oblongframe-br" />
      <div class="oblongframe-border1" />
      <div class="oblongframe-border2" />
      <div class="oblongframe-border3" />
      <div class="oblongframe-border4" />
      <div class="oblongframe-border5" />
      <div class="oblongframe-border6" />
      <slot></slot>
    </div>
</template>

<script>
export default {
  name: "oblongframe",
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.oblongframe{
  position: relative;
}
.oblongframe-bg{
  position:absolute;
  background: rgba(0,0,0,0.3);
  top: 34px;
  left: 8px;
  width: calc(100% - 18px);
  height: calc(100% - 86px);
}
.oblongframe-bg2{
  position:absolute;
  border-top: 32px solid rgba(0,0,0,0.3);
  border-right: 32px solid transparent;
  top: calc(100% - 52px);
  left: 8px;
  width: calc(100% - 18px);
  height: 0;
}
.oblongframe-tl{
  position:absolute;
  background: url('../assets/divhead02.png') no-repeat;
  background-size: 300px;
  top: 15px;
  left: 6px;
  width: 210px;
  height: 40px;
}
.oblongframe-br{
  position:absolute;
  background: url('../assets/divhead02.png') no-repeat;
  background-size: 300px;
  background-position: bottom right;
  bottom: 18px;
  right: 9px;
  width: 90px;
  height: 73px;
}
.oblongframe-border1{
  box-shadow: 5px -5px 6px -3px #21c1f5;
  position: absolute;
  left: 206px;
  top: 35px;
  width: calc(100% - 217px);
  height: calc(100% - 80px);
}
.oblongframe-border2{
  box-shadow: -5px 5px 6px -3px #21c1f5;
  position: absolute;
  left: 8px;
  top: 26px;
  width: calc(100% - 43px);
  height: calc(100% - 46px);
}
.oblongframe-border3{
  box-shadow: 0px -5px 3px -3px #21c1f5;
  position: absolute;
  left: 21px;
  top: 16px;
  width: 178px;
  height: 16px;
}
.oblongframe-border4{
  box-shadow: 0px -5px 3px -3px #21c1f5;
  position: absolute;
  left: 5px;
  top: 23px;
  width: 32px;
  height: 16px;
  transform: rotate(-44deg);
}
.oblongframe-border5{
  box-shadow: 0px -5px 3px -3px #21c1f5;
  position: absolute;
  left: 183px;
  top: 23px;
  width: 34px;
  height: 16px;
  transform: rotate(45deg);
}
.oblongframe-border6{
  box-shadow: 0px -5px 3px -3px #21c1f5;
  position: absolute;
  left: calc(100% - 58px);
  top: calc(100% - 49px);
  width: 52px;
  height: 16px;
  transform: rotate(135deg);
}
</style>
